import React from "react";
import axios from 'axios';
import ENVIRONMENTS from '../../../../environments'
import { TreeSelect, Rate, Button, message, Row, Col, Modal, Form, Select, Input, Typography, Table, Card } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Country, City }  from 'country-state-city';
import AdminMainLayout from '../AdminMainLayout';
import DottedLoader from "../../DottedLoader";
import * as Utilities from "../../Utilities";
import * as Styles from "../../../../Styles";
import FileConstants from "../../../../FileConstants";
import EditSkillMatrixModal from "../EditSkillMatrixModal/EditSkillMatrixModal";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const categoryStyle = Styles.categoryStyle;
const days_of_week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

class ArtistDetails extends React.Component {
  formRef = React.createRef();

  state = {
    artist_username: '',
    profile_name: '',
    email: '',
    billing_type: '',
    is_active: false,
    is_superartist: false,
    hourly_rate: "",
    rating: 0,
    availability: {},
    expertise_level: '',
    ar_proficiency: '',
    vray_proficiency: '',
    room_proficiency: '',
    material_proficiency: '',
    variation_proficiency: '',
    fix_proficiency: '',
    proficient_categories: [],
    all_categories: [],
    tree_formatted_catagories: [],
    data_source: [],
    set_info_modal: false,
    buttonLoader: false,
    artist_data_fetched: false,
    edit_availability: false, 
    edit_button_loader: false,
    city: '',
    country: '',
    country_code: '',
    timezone: '',
    timezones_list: [],
    edit_skill_matrix: false,
    reviews: [],
    current_admin_review: {
      'rating': '',
      'review': ''
    },
    submit_review_enabled: false,
    display_review_component: false,
    skill_list: [],
    artist_skills: {}
  };

  componentDidMount() {
    let artist_id = this.props.match.params.id;
    this.getSkillList();
    this.getArtistProfileAndReviewsData(artist_id);
  }

  getSkillList = () => {
    // Get the skills that we are tracking for artists
    axios.post(ENVIRONMENTS.SKILL_GET_BATCH, {      
    })
    .then(res => {
      if(res['data']){
        this.setState({skill_list: res['data']})
      }
    })
  }

  getArtistProfileAndReviewsData = (artist_id) => {
    // Fetch artist profile and review and set the appropriate state values
    
    axios.post(ENVIRONMENTS.GET_ARTIST_PROFILE, {
      artist_id: artist_id,
    })
      .then(res => {
        this.setState({
          artist_username: res.data['artist_username'],
          profile_name: res.data['profile_name'],
          email: res.data['email'],
          billing_type: res.data['billing_type'],
          hourly_rate: res.data['hourly_rate'],
          is_active: res.data['is_active'],
          rating: res.data['rating'],
          availability: res.data['availability'],
          city: res.data['city'],
          country: res.data['country'],
          timezone: res.data['timezone'],
          artist_skills: res.data['skills'],
          proficient_categories: res.data['proficient_categories'],
          expertise_level: FileConstants.ARTIST_EXPERTISE_LEVEL[res.data["expertise_level"]] || "N/A",
          avg_product_rejections: res.data['avg_product_rejections'],
          skype_username: res.data['skype_username'],
          artist_data_fetched: true,
          all_reviews_visible: false
        }, () => {
          this.fetchAllCategories();
          this.setDataSource(); 
          this.setCountryCodeAndTimezoneList(this.state.country);
          let payload = {
            'artist_username': this.state.artist_username
          }
          axios.post(ENVIRONMENTS.ARTIST_REVIEW_GET, payload)
          .then(res=>{
            this.setCurrentAdminReview(res.data);
            this.setState({reviews: res.data, display_review_component: true})
          })
        })
      })
  }

  fetchAllCategories = () => {

    let payload = {
      output: 'serial'
    };
    axios.post(ENVIRONMENTS.ADMIN_CATEGORY_GET_BATCH, payload)
    .then(res => {
      this.setState({
        all_categories: res.data
      })
    });

    payload = {
      output: 'tree',
      disable_parents: 'true'
    };
    axios.post(ENVIRONMENTS.ADMIN_CATEGORY_GET_BATCH, payload)
    .then(res => {
      this.setState({
        tree_formatted_catagories: res.data
      })
    });
  }

  setCurrentAdminReview = (reviews) => {
    let admin_username = FileConstants.CUSTOMER_USERNAME;
    reviews.map(ele => {
      if(ele['admin_username'] == admin_username){
        this.setState({current_admin_review: ele})
      }
    })
  }

  handleStateUpdateForSkillMatrix = (payload) => {
    payload['expertise_level'] = FileConstants.ARTIST_EXPERTISE_LEVEL[payload['expertise_level']]
    // renaming the key skills to artist_skills so that it can be directly assigned to the state variable
    payload['artist_skills'] = payload['skills']
    delete payload['skills']
    this.setState(payload);
  }

  handleChange = (value, elem_id) => {
    let availability_hours = this.state.availability;
    availability_hours[elem_id] = value;
    this.setState({
      availability: availability_hours
    })

  }

  setSkillMatrixModalVisibility = (visible) => {
    this.setState({edit_skill_matrix: visible})
  }

  setCountryCodeAndTimezoneList = (country) => {
    let timezones = new Set();
    if(country){
      Country.getAllCountries().map((ele, index) => {
        if(ele.name == country){
          this.setState({
            country_code: ele.isoCode
          });
          ele.timezones.map((item) => {
            timezones.add(item.gmtOffsetName)
          })
          timezones = [...timezones];
          this.setState({timezones_list: timezones});
        }
      })

    }
  }

  getReadableAvailabilityValue = (value) => {
    if(value == 0)
      return 'off';
    else
      return value;
  }

  setDataSource = () => {
    let table_data = [];
    days_of_week.map((ele, index) => {
      table_data.push({
        key: index,
        day: ele,
        hours: 
          <React.Fragment>
            {/* if editing, display the select  */}
            {this.state.edit_availability ? 
            <span>
              <Select id={ele} onChange={(value, elem) => this.handleChange(value, ele)} defaultValue={this.state.availability[ele]} className="availability-hours-select" size={"large"}>
                {
                ['off' , 1, 2, 3, 4, 5, 6, 7, 8].map((element, array_index) => {
                  return <Option value={array_index}>{element}</Option>
                })
              }
              </Select>

            </span> : this.getReadableAvailabilityValue(this.state.availability[ele])
        }
          </React.Fragment>
      })
    })
    this.setState({
      data_source: table_data
    })
  }

  getBillingRateString = () => {
    let billing_rate = "N/A";
    if (this.state.hourly_rate && this.state.hourly_rate != 0 && this.state.hourly_rate != ""){
      billing_rate = "$" + this.state.hourly_rate;
      if (this.state.billing_type == "hourly")
        billing_rate += " / hour";
      else if(this.state.billing_type == "per_model")
        billing_rate += " / model";
    }
    return billing_rate;
  }


  updateUserInfo = (e) => {
    // update user's country, city and timezone
    let payload = {
      'username': this.state.artist_username
    };
    if(e.city)
      payload['city'] = e.city;
    if(e.country)
      payload['country'] = e.country
    if(e.timezone)
      payload['timezone'] = e.timezone
    axios.post(ENVIRONMENTS.USER_PROFILE_UPDATE, payload)
    .then(res => {
      this.setState({
        buttonLoader: false,
        set_info_modal: false,
        city: e.city,
        country: e.country,
        timezone: e.timezone
      })
      message.info('Artist Profile Updated Successfully.');
    })

  }

  updateArtistInfo = (e) => {
    this.setState({buttonLoader: true})
    let payload = {
      'profile_id': this.props.match.params.id,
      'billing_type': e.billing_type,
      'hourly_rate': e.billing_rate,
    }
    axios.post(ENVIRONMENTS.UPDATE_ARTIST_PROFILE, payload)
      .then(res => {
        this.setState({
          billing_type: e.billing_type,
          hourly_rate: e.billing_rate,
        }, () => this.updateUserInfo(e))
      })
  }

  updateAvailabilityHours = () => {
    this.setState({
      edit_button_loader: true
    })
    let payload = {
      'profile_id': this.props.match.params.id,
      'availability': this.state.availability
    }

    axios.post(ENVIRONMENTS.UPDATE_ARTIST_PROFILE, payload).then(
      res=> {
        // Update state and re-render the table
        this.setState({
          edit_availability: false,
          edit_button_loader: false
        }, ()=> this.setDataSource());
        message.info('Artist Availability Updated.')
      }
    )

  }


  display_edit_save_availability_button = () => {
    if(this.state.edit_availability){
      return <Button disabled={this.state.edit_button_loader} className="save-button font-12" onClick={() => this.updateAvailabilityHours()}>
        Save &nbsp;{this.state.edit_button_loader ? <LoadingOutlined/>: ''}
      </Button> 
    }
    else{
      return <Button className="save-button font-12" onClick={()=>{this.setState({edit_availability: true}, () => {this.setDataSource()})}}>
        Edit
      </Button>
    }
  }

  columns = [
    {
      title: 'Days',
      dataIndex: 'day',
      width: '50%',
      align: 'center'
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      width: '50%',
      align: 'center'
    },
  ]

  hoursSum = () => {
    let total_hours = 0;
    for (const [key, value] of Object.entries(this.state.availability)){
      if(!isNaN(value))
        total_hours += value;
    } 
    return total_hours;
  }

  getReadbleLocation = () => {
    let location = 'N/A';
    if(this.state.city && this.state.country){
      location = this.state.city + ', ' + this.state.country;
    }
    else if(this.state.city || this.state.country) {
      location = this.state.city || this.state.country;
    }
    return location
  }

  updateReviewList = (review) => {
    let reviews = this.state.reviews;
    let reviews_list_updated = false;
    reviews.map(item => {
      if(item['id'] == review['id']){
        item['rating'] = review['rating'];
        item['review'] = review['review']
        reviews_list_updated = true;
      }
    })
    this.setState({reviews: reviews})
  }

  submitReview = (e) => {
    this.setState({buttonLoader: true})
    if(this.state.current_admin_review && this.state.current_admin_review['id']){
      // update exiting review
      let payload = e;
      payload['id'] = this.state.current_admin_review['id']
      axios.post(ENVIRONMENTS.ARTIST_REVIEW_UPDATE, payload)
      .then(()=> {
        this.updateReviewList(payload);
        this.setState({buttonLoader: false, submit_review_enabled: false});
        message.info('Review Updated Successfully.')
      })
    }
    else{
      // post new review
      let payload = e;
      payload['artist_username'] = this.state.artist_username;
      payload['admin_username'] = FileConstants.CUSTOMER_USERNAME;
      axios.post(ENVIRONMENTS.ARTIST_REVIEW_CREATE, payload)
      .then(()=> {
        // Fetch all reviews, so that we can have the ID and admin name for the newly submitted review obj
        axios.post(ENVIRONMENTS.ARTIST_REVIEW_GET, payload)
          .then(res=>{
            this.setCurrentAdminReview(res.data);
            this.setState({reviews: res.data, buttonLoader: false, submit_review_enabled: false})
            message.info('Review Posted Successfully.')
          })
      })

    }
  }

  render() {

    return (
      <AdminMainLayout selected={"8"}>
        {this.state.artist_data_fetched ? 
        <React.Fragment>
          <Row>
            <Col span={17}>
              <h3 style={{marginTop: 25, fontSize: 32, fontWeight: 800}}>Artist Details</h3>
            </Col>

            <Col span={7} style={{alignSelf: 'center', display: 'flex', justifyContent: 'flex-end'}}>
                <Button className="save-button okay font-16" style={{marginLeft: 5}} href={'../admin_artist_time_log/' + this.props.match.params.id}>
                  View Artist Time Log
                </Button>
              </Col>
          </Row>
          <Card
            title="General Information"
            extra={<a onClick={()=>{this.setState({set_info_modal: true})}}>Edit Information</a>}
          >
            <Row style={{padding: 30}}>
              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Name</p>
                  <p className="artist-details-value">{this.state.profile_name}</p>
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Username</p>
                  <p className="artist-details-value">{this.state.artist_username}</p>
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Email</p>
                  <p className="artist-details-value">{this.state.email}</p>
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Timezone</p>
                  <p className="artist-details-value">{this.state.timezone || 'N/A'}</p>
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Location</p>
                  <p className="artist-details-value">{this.getReadbleLocation()}</p>
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Billing Rate</p>
                  <p className="artist-details-value">{this.getBillingRateString()}</p>
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Skype Username</p>
                  <p className="artist-details-value">{this.state.skype_username}</p>
                </div>
              </Col>
              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Status</p>
                  <p className="artist-details-value" style={{color: this.state.is_active ? "#00BF4D" : "red"}}>{this.state.is_active ? "Active": "Inactive"}</p>
                </div>
              </Col>
          </Row> 
        </Card>

        {/* Artist Skill matrix details */}
        <Card
            style={{marginTop: 30}}
            title="Artist Skill Matrix"
            extra={<a onClick={()=>{this.setSkillMatrixModalVisibility(true)}}>Edit Skill Matrix</a>}
          >
            <Row style={{padding: 30}}>
              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Expertise Level</p>
                  <p className="artist-details-value">{this.state.expertise_level || 'N/A'}</p>
                </div>
              </Col>

              <Col span={6}>
                <div>
                  <p className="artist-details-heading">Avg. Product Rejections</p>
                  <p className="artist-details-value">{this.state.avg_product_rejections || 'N/A'}</p>
                </div>
              </Col>

              {/* Display artist skills  */}
              {this.state.skill_list.map(ele => {
                return (<Col span={6}>
                    <div>
                      <p className="artist-details-heading">{ele['display_name']}</p>
                      <p className="artist-details-value">{this.state.artist_skills[ele['name']] || 'N/A'}</p>
                    </div>
                </Col>)
              })}

              <Col span={5}>
                <div>
                  <p className="artist-details-heading">Product Categories</p>
                  <TreeSelect
                    className="manrope f-10 black-55"
                    style={{ width: "100%" }}
                    selectable={false}
                    dropdownStyle={categoryStyle}
                    placeholder="Show List"
                    treeData={Utilities.getTreeFormattedCategories(this.state.proficient_categories)}
                    notFoundContent={
                      <span style={{ textAlign: "center" }}>
                        <h5
                          style={{ textAlign: "center" }}
                          className="manrope f-10 black-55"
                        >
                          No Category Found
                        </h5>
                      </span>
                    }
                  ></TreeSelect>
                </div>
              </Col>
          </Row> 
            
          </Card>
         <Row>
          <Col style={{paddingRight: 10}} span={12}>
            {/* Artist Availability info */}
            <Card
              style={{marginTop: 30}}
              title="Artist Availability"
              extra={this.display_edit_save_availability_button()}
            >
              <div style={{padding: 18}}>
                <Table
                  columns={this.columns}
                  bordered={true}
                  dataSource={this.state.data_source}
                  pagination={false}

                  // sum of days in the footer
                  summary={pageData => {
                    let total_hours = 0;
                    total_hours = this.hoursSum();
            
                    return (
                      <>
                        <Table.Summary.Row style={{backgroundColor: '#FAFAFA', textAlign: 'center', fontWeight: 'bold'}}>
                          <Table.Summary.Cell>Total</Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <Text>{total_hours}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                  >
                </Table>
              </div>
              
            </Card>
          </Col>
          <Col style={{paddingLeft: 10}} span={12}>
            <Card
              loading={!this.state.display_review_component}
              title='Admin Rating & Review'
              style={{marginTop: 30}}
              extra={this.state.reviews && this.state.reviews.length > 0 ? <a onClick={()=>{this.setState({all_reviews_visible: true})}}>See All Ratings & Reviews</a> : ''}
            >
              <Form
                onFieldsChange={()=>this.setState({submit_review_enabled: true})}
                ref={this.formRef}
                name="material-form2"
                onFinish={this.submitReview}
                initialValues={{
                  rating: this.state.current_admin_review['rating'],
                  review: this.state.current_admin_review['review']
                }}
              >
                <div style={{padding: 20}}>
                  <span style={{display: "block", fontWeight: 500}}>Rating</span>
                  <Form.Item name="rating" className="material-category-form"
                    rules={[{ required: true, message: 'Please Rate Artist' }]}>
                    <Rate />
                  </Form.Item>
                  <span style={{display: "block", fontWeight: 500}}>Review</span>
                  <Form.Item name="review" className="material-category-form">
                    <TextArea rows={4}/>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="modal-okay square font-14"
                      type="primary"
                      htmlType="submit"
                      disabled={!this.state.submit_review_enabled || this.state.buttonLoader}
                      style={{float: "right"}}
                    >
                      Submit {this.state.buttonLoader ? <LoadingOutlined /> : ''}
                    </Button>
                  </Form.Item>
                </div>

              </Form>
            </Card>
          </Col>
         </Row>
          

        </React.Fragment>
        : <DottedLoader/> }

        <Modal
          visible={this.state.set_info_modal}
          width={500}
          onCancel={() => this.setState({set_info_modal: false})}
          maskClosable={true}
          footer={[
            <div className="justify-in-end">
                <Button className="modal-okay-gray square font-14"  htmlType="submit" onClick={() => this.setState({set_info_modal: false})}>
                    Cancel
                </Button>
                <Button disabled={this.state.buttonLoader} className="modal-okay square font-14" htmlType="submit" onClick={() => document.getElementById("update_artist_data").click()}>
                    Save&nbsp;{this.state.buttonLoader ? <LoadingOutlined/>: ''}
                </Button>
                </div>
            ]}>
          <div style={{padding:"32px 32px 24px 32px"}}>
          <div className="manrope f-20 black-14" style={{marginBottom: 16,textTransform:"capitalize"}}>
            Edit Profile
          </div>
          <Form
              ref={this.formRef}
              name="material-form"
              onFinish={this.updateArtistInfo}
              initialValues={{
                billing_type: this.state.billing_type == 'hourly' || this.state.billing_type == 'per_model' ? this.state.billing_type : undefined,
                billing_rate: this.state.hourly_rate && this.state.hourly_rate != "" && this.state.hourly_rate != 0 ? this.state.hourly_rate : undefined,
                active_status: this.state.is_active,
                artist_rating: this.state.rating,
                timezone: this.state.timezone,
                country: this.state.country,
                city: this.state.city
                }}>
                <div className="manrope f-14 black-55" style={{marginBottom: 8}}>Billing Type</div>
                <Form.Item name="billing_type" className="material-category-form"
                  rules={[{ required: true, message: 'Please Select the Billing Type of Artist!' }]}>
                    <Select
                        className="tree-select-material"
                        style={{color:"#333333"}}
                        placeholder="Please Select Billing Type">
                        <Option key={1} value='hourly'>Hourly</Option>
                        <Option key={2} value='per_model'>Per Model</Option>
                    </Select>
                </Form.Item>
              <div className="manrope f-14 black-55" style={{marginBottom: 8,marginTop: 22}}>Billing Rate</div>
              <Form.Item style={{marginBottom: 32}} name="billing_rate" className="material-category-form"
                rules={[{ required: true, message: 'Enter Amount' }]}>
                <Input className="manrope f-14" style={{lineHeight: '40px'}} placeholder="Enter Billing Rate" />
              </Form.Item>

              <div className="manrope f-14 black-55" style={{marginBottom: 8,marginTop: 22}}>Country</div>
              <Form.Item style={{marginBottom: 32}} name="country" className="material-category-form"
                rules={[{ required: true, message: 'Please Select Country' }]}>
                <Select className="tree-select-material"
                        style={{color:"#333333"}}
                        placeholder="Country"
                        showSearch={true}
                        onSelect = {(e)=>{this.setCountryCodeAndTimezoneList(e); this.formRef.current.setFieldsValue({
                          city: '',
                          timezone: '',
                        });}}

                        >
                  <Option key={0} value=''> Select Country </Option>
                  {Country.getAllCountries().map((ele, index) => {
                     return (<Option key={index + 1} value={ele.name}> {ele.name} </Option>)
                  })}
                </Select>
              </Form.Item>

              <div className="manrope f-14 black-55" style={{marginBottom: 8,marginTop: 22}}>City</div>
              <Form.Item style={{marginBottom: 32}} name="city" className="material-category-form"
                rules={[{ required: false}]}>
                <Select id='city-select'
                        className="tree-select-material"
                        style={{color:"#333333"}}
                        placeholder="City"
                        disabled={this.state.country_code == "" ? true : false}
                        showSearch={true}
                        >
                  <Option key={0} value=''> Select City </Option>
                  {City.getCitiesOfCountry(this.state.country_code).map((ele, index) => {
                     return (<Option key={index + 1} value={ele.name}> {ele.name} </Option>)
                  })}
                </Select>
              </Form.Item>

              <div className="manrope f-14 black-55" style={{marginBottom: 8,marginTop: 22}}>Timezone</div>
              <Form.Item style={{marginBottom: 32}} name="timezone" className="material-category-form"
                rules={[{ required: false}]}>
                <Select className="tree-select-material"
                        style={{color:"#333333"}}
                        placeholder="Timezone"
                        disabled={this.state.timezones_list == [] ? true : false}
                        showSearch={true}
                        >
                  <Option key={0} value=''> Select Timezone </Option>
                  {this.state.timezones_list.map((ele, index) => {
                    return (<Option key={index + 1} value={ele}> {ele} </Option>)
                  })}
                </Select>
              </Form.Item>

                <Form.Item style={{display: 'none'}}>
                  <Button id="update_artist_data" style={{display: "none"}} className="modal-okay square font-14" htmlType="submit">
                    Submit&nbsp;{this.state.buttonLoader ? <LoadingOutlined/>: ''}
                  </Button>
                </Form.Item>
             </Form>

          </div> 
        </Modal>

        <Modal
          title='Reviews and Ratings from Admins'
          visible={this.state.all_reviews_visible}
          closable={true}
          maskClosable={true}
          footer={false}
          onCancel={()=>this.setState({all_reviews_visible: false})}
        >
          <div>
            {this.state.reviews.map(ele => {
              return(
                <div style={{marginBottom: 35, paddingBottom: 18, borderBottom: '1px solid', borderColor: '#dad6d6'}}>
                    <span style={{display: "block", fontWeight: 500, marginTop: 10}}>Admin Name</span>
                    <span>{ele['admin_name']}</span> 

                    <span style={{display: "block", fontWeight: 500,  marginTop: 13}}>Rating</span>
                    <Rate style={{marginBottom: 10}} disabled value={ele['rating']} />
                    {ele['review'] ? 
                      <React.Fragment>
                          <span style={{display: "block", fontWeight: 500,  marginTop: 5}}>Review</span>
                          <span>{ele['review']}</span> 
                      </React.Fragment>
                    : ''}
                </div>
              )
            })}
          </div>

        </Modal>

        <EditSkillMatrixModal
          profile_id={this.props.match.params.id}
          artist_username={this.state.artist_username}
          inputInviteDetails={false}
          loading_data={false}
          visible={this.state.edit_skill_matrix}
          expertise_level={this.state.expertise_level}
          artist_skills={this.state.artist_skills}
          skill_list={this.state.skill_list}
          proficient_categories={this.state.proficient_categories}
          all_categories={this.state.all_categories}
          set_visibility={this.setSkillMatrixModalVisibility}
          hideBillingFormInput={true}
          update_parent_state={this.handleStateUpdateForSkillMatrix}
        />
      </AdminMainLayout>
    );
  }
}

export default ArtistDetails;