import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import axios from 'axios';
import SuccessModal from '../../SuccessModal/SuccessModal';
import SpaceVariationRequestModal from '../SpaceVariationRequestModal/SpaceVariationRequestModal';
import ENVIRONMENT from '../../../../environments'

const AutomatedSpaceVariationRequestModal = (props) => {

    const [variationSuccessModal, setVariationSuccessModal] = useState(false);
    const [existingVariationID, setExistingVariationID] = useState(null);
    const [variationRequestModal, setVariationRequestModal] = useState(false);

    const closeVariationSuccessModal = () => {
        props.setVariationModal(false);
    }

    const closeVariationExistsModal = () => {
        props.setVariationModal(false);
    }

    const closeSpaceVariationRequestModal = () => {
        props.setVariationModal(false);
    }

    const submitVariation = () => {
        setVariationRequestModal(false);
        setVariationSuccessModal(true);
    }

    const checkForExistingVariants = () => {
        let payload = {
            room_id: props.space_id
        }
        if (props.username) {
            payload["customer_username"] = props.username 
        }
        if (props.company_id){
            payload["company_id"] = props.company_id
        }
        axios.post(ENVIRONMENT.GET_AUTOMATED_ROOM_VARIATIONS, payload)
        .then(res => {
            if (res && res.data.statusCode == 200) {
                checkIfVariationExists(res.data.body);
            }
            else {
                setVariationRequestModal(true);
            }
            props.setLoader(false);
        })
    }

    const compareObjects = (obj1, obj2) => {
        if (obj1 === obj2) return true; // Check for strict equality
        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;
        
        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);
        
        if (keys1.length !== keys2.length) return false;
        
        for (let key of keys1) {
            if (!keys2.includes(key) || !compareObjects(obj1[key], obj2[key])) return false;
        }
        return true;
    }

    const compareConfigurations = (config1, config2) => {
        if (config1.length !== config2.length) return false;
        
        // Sort and compare each object in the arrays
        const sortedArr1 = config1.slice().sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
        const sortedArr2 = config2.slice().sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));
        
        return sortedArr1.every((obj, index) => compareObjects(obj, sortedArr2[index]));
    }

    const checkIfVariationExists = (exsitingSpaceVariants) => { 
        const currentSpaceConfiguration = props.space_variation;
        let existingVariantID = null;
        for (let variation of exsitingSpaceVariants) {
            if (compareConfigurations(currentSpaceConfiguration,variation.request_details)) {
                existingVariantID = variation.room_id;
                break;
              }
        }
        if (existingVariantID) {
            setVariationRequestModal(false);
            setExistingVariationID(existingVariantID);
        }
        else {
            setVariationRequestModal(true);
        }
    }

    useEffect(()=> {
        props.setLoader(true);
        checkForExistingVariants();
    },[]);

    return <div>
        <SpaceVariationRequestModal
            requestType={"variation_room_model"}
            savedID={-1}
            submitVariation={submitVariation}
            variationLoader={false}
            automated_variation={true}
            variationModal={variationRequestModal}
            closeVariationModal={closeSpaceVariationRequestModal}
            space_details={props.space_details}
            space_id={props.space_id}
            space_variation={props.space_variation}
        />

        <SuccessModal
            visible={variationSuccessModal}
            onCancel={closeVariationSuccessModal}
            heading={"Space Model Variation Requested Successfully!"}
            text={"Your Space Model's Variation request has been submitted. You will be notified via email once your Space Model is ready to be reviewed."}
            footer={[
                <div className="justify-in-center">

                    <Button className="modal-okay square font-14" htmlType="submit" onClick={() => closeVariationSuccessModal()}>
                    Okay
                    </Button>
                </div>
            ]}
        />

        <SuccessModal
            visible={existingVariationID}
            onCancel={closeVariationExistsModal}
            heading={"Space Model Variation Already Exists!"}
            text={
                <>
                A Space Model Variation already exists with your current selected configuration. 
                Go to{" "}
                <a target="_blank" href={`/rooms/${existingVariationID}`}>
                    link
                </a>{" "}
                to view space.
                </>
            }
            footer={[
                <div className="justify-in-center" key="footer-button">
                <Button
                    className="modal-okay square font-14"
                    htmlType="submit"
                    onClick={closeVariationExistsModal}
                >
                    Okay
                </Button>
                </div>
            ]}
        />
    </div>
}

export default AutomatedSpaceVariationRequestModal;

