import {
  REORDER,
  RENAME_FOLDER,
  CREATE_FOLDER,
  MOVE_TO_FOLDER,
  GET,
  SUCCESS,
  FAILURE
} from '../actions';

const initialState = {
  data: {},
  entity_arrangement: [],
  folder_arrangement: [],
  company_arrangement: false,
  isLoading: true,
  isCreated: false,
  isUpdated: false,
  error: null,
  isReordered: false
};

export default function entityArrangementReducer(state = initialState, action) {
  switch (action.type) {
    case REORDER:
      return {
        data: { ...action.payload },
        entity_arrangement: state.entity_arrangement,
        folder_arrangement: state.folder_arrangement,
        company_arrangement: state.company_arrangement,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        error: null,
        isReordered: false
      };
    case RENAME_FOLDER:
      return {
        data: { ...action.payload },
        entity_arrangement: state.entity_arrangement,
        folder_arrangement: state.folder_arrangement,
        company_arrangement: state.company_arrangement,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        error: null,
        isReordered: false
      };
    case CREATE_FOLDER:
      return {
        data: { ...action.payload },
        entity_arrangement: state.entity_arrangement,
        folder_arrangement: state.folder_arrangement,
        company_arrangement: state.company_arrangement,
        isLoading: false,
        isCreated: true,
        isUpdated: false,
        error: null,
        isReordered: false
      };
    case MOVE_TO_FOLDER:
      return {
        data: { ...action.payload },
        entity_arrangement: state.entity_arrangement,
        folder_arrangement: state.folder_arrangement,
        company_arrangement: state.company_arrangement,
        isLoading: false,
        isCreated: true,
        isUpdated: false,
        error: null,
        isReordered: false
      };
    case GET:
      return {
        data: { ...action.payload },
        entity_arrangement: state.entity_arrangement,
        folder_arrangement: state.folder_arrangement,
        company_arrangement: state.company_arrangement,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        error: null,
        isReordered: false
      };
    case SUCCESS:
      return {
        data: { ...action.payload, ...state },
        entity_arrangement: action.payload.entity_arrangement || state.entity_arrangement,
        folder_arrangement: action.payload.folder_arrangement || state.folder_arrangement,
        company_arrangement: action.payload.company_arrangement || state.company_arrangement,
        isLoading:  action.payload.isLoading,
        isCreated: action.payload.isCreated,
        isUpdated: action.payload.isUpdated,
        error: null,
        isReordered: action.payload.isReordered
      };
    case FAILURE:
      return {
        entity_arrangement: state.entity_arrangement,
        folder_arrangement: state.folder_arrangement,
        company_arrangement: state.company_arrangement,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        error: action.payload.message,
        isReordered: action.payload.isReordered
      };

    default:
      return state;
  }
}
