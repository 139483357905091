import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider, Switch, Card, Modal, Button, Slider, Tooltip } from 'antd'
import React, { useState } from 'react'
import { SketchPicker } from "react-color";

export default function LightingSetting(props) {
    
    const [showColorModal, setShowColorModal] = useState(false);
    const [customColor, setCustomColor] = useState("#CCCCCC");
        
    const lightTemperatureMarks = {
        1600: {
            label: <span className='manrope f-10 black'>Candle</span>
        },
        3600: {
            label: <span className='manrope f-10 black'>Warm</span>
        },
        5000: {
            label: <span className='manrope f-10 black'>Daylight</span>
        },
        7000: {
            label: <span className='manrope f-10 black'>Cloudy</span>
        }
      };
    
  return (
    <>
        <div>
            <span className='manrope f-14 black silo-heading-main'>
                Background
            </span>
            <div className='silo-background-radio' style={{display:"flex", flexWrap:"wrap", marginTop:"3%"}} value={props.backgroundColor} onChange={props.changeBackgroundColor} defaultValue={0} buttonStyle="solid">
                {
                    props.allBackgroundColors.map((color, index) => {
                        return <div className={ props.hexColor == "default" ? (index == 0 ? "silo-background-bordered":"silo-background-not-bordered" ) : (color == props.hexColor ? "silo-background-bordered":"silo-background-not-bordered" ) } onClick={() => {props.setHexColor(color)}} value={index} style={{width:"3vw", height:"3vw", background:color, borderRadius:"3vw", marginLeft:"6px", marginTop:"6px"}} />
                    })
                }
                <div onClick={() => setShowColorModal(true)} style={{width:"3vw", height:"3vw", borderRadius:"3vw", marginLeft:"6px", textAlign:"center", padding:"1vw 0px", border:"solid 1px black", cursor:"pointer", marginTop:"6px"}} className='manrope f-10'>
                    <PlusOutlined/>
                    Add
                </div>
                
            </div>
        </div>
        <div style={{marginTop:"5px"}}>
            <div style={{display:"flex", alignItems:"center", position:"relative"}}>
                <span className='manrope f-14 black silo-heading-main'>
                    Lighting
                </span>
                <Tooltip title={<span className='manrope f-12'>The lighting is to show direction and not intensity of light. The lighting here is more intense than what will be in the rendered images.</span>}>
                    <InfoCircleOutlined style={{marginLeft:"5px"}} />
                </Tooltip>
                <div style={{display:"flex", alignItems:"center", position:"absolute", right:"8%"}}>
                    <span className='manrope f-14'>
                        Preview
                    </span>
                    <Switch 
                        checkedChildren={<span className='manrope f-12'>Lighting Mode</span>}
                        unCheckedChildren={<span className='manrope f-12'>Model Mode</span>}
                        style={{marginLeft: 8}}
                        checked={props.greyMat}
                        onChange={(val) => {
                            props.setGreyMat(val)
                        }}
                    />
                    <Tooltip title={<span className='manrope f-12'>Change Material to see a better effect of lighting on the model</span>}>
                        <InfoCircleOutlined style={{marginLeft:"5px"}} />
                    </Tooltip>
                    
                </div>
            </div>
            <div className='custom-scroll' style ={{display:"flex", flexWrap:'wrap', height:'20vh', alignContent:"flex-start", marginTop:"3%", overflowY:"scroll"}}>
            <Card 
                hoverable 
                style={{height:"17vh", width:"22%", borderRadius:"4px", margin:"4px", boxShadow:"0px 2px 0px 0px #0000000B"}}                                
                className={props.camera[props.perspective]['rig_name'] == 'default' && !props.shadowsRig && "default" == props.camera[props.perspective]['lighting'] ? "bordered-card" : "non-bordered-card"}
                onClick={() => { props.setShadowsRig(false);props.setLight("default")}}
            >
                <img src={`/img/default.jpg`} style={{width:"90%", borderRadius:"4px", marginTop:"6%"}} alt ={'Silo Lighting - Default'} />
                <span style={{textAlign:'center', height:"5vh", padding:"8%"}} className='manrope f-10 black'>DEFAULT</span>
            </Card>
            <Card 
                hoverable 
                style={{height:"17vh", width:"22%", borderRadius:"4px", margin:"4px", boxShadow:"0px 2px 0px 0px #0000000B"}}                                
                className={props.camera[props.perspective]['rig_name'] == 'default' && props.shadowsRig && "default" == props.camera[props.perspective]['lighting'] ? "bordered-card" : "non-bordered-card"}
                onClick={() => { props.setShadowsRig(true); props.setLight("default"); props.setDropShadows(false)}}
            >
                <img src={`/img/default.jpg`} style={{width:"90%", borderRadius:"4px", marginTop:"6%"}} alt ={'Silo Lighting - Default Shadoes' } />
                <span style={{textAlign:'center', height:"5vh", padding:"8%"}} className='manrope f-10 black'>DEFAULT W SHADOWS</span>
            </Card>
                {
                    props.allCustomLightingRigs != undefined && props.allCustomLightingRigs.length > 0 && props.allCustomLightingRigs.map((light, index) => {
                        return <Card 
                            hoverable 
                            style={{width:"22%", borderRadius:"4px", margin:"4px", boxShadow:"0px 2px 0px 0px #0000000B"}}                                
                            className={props.camera[props.perspective]['rig_name']!= 'default' && light.rig_name == props.camera[props.perspective]['rig_name'] ? "bordered-card" : "non-bordered-card"}
                            onClick={() => {
                                props.setRig(light.id, light.rig_name)
                            }}
                        >
                            <img src={'/img/custom_rig.png'} style={{width:"90%", borderRadius:"4px", marginTop:"6%"}} alt ={'Silo Custom Lighting - ' + index} />
                            {
                                light.rig_name.length > 8 ?
                                <Tooltip title={light.rig_name}>
                                    <span style={{textAlign:'center', height:"5vh", padding:"8%"}} className='manrope f-12 black'>{light.rig_name.slice(0,8)}...</span>
                                </Tooltip>
                                :
                                <span style={{textAlign:'center', height:"5vh", padding:"8%"}} className='manrope f-12 black'>{light.rig_name}</span>                                
                            }
                        </Card>
                    })
                }
                {
                    Object.keys(props.lightingData).map((light, index) => {
                        return <Card 
                            hoverable 
                            style={{height:"17vh", width:"22%", borderRadius:"4px", margin:"4px", boxShadow:"0px 2px 0px 0px #0000000B"}}                                
                            className={props.camera[props.perspective]['rig_name'] == 'default' && light == props.camera[props.perspective]['lighting'] ? "bordered-card" : "non-bordered-card"}
                            onClick={() => {props.setLight(light)}}
                        >
                            <img src={`/img/${light}.jpg`} style={{width:"90%", borderRadius:"4px", marginTop:"6%"}} alt ={'Silo Lighting - ' + index} />
                            <span style={{textAlign:'center', height:"5vh", padding:"8%"}} className='manrope f-10 black'>{(light.replaceAll("_", " ")).toUpperCase()}</span>
                        </Card>
                    })
                }
            </div>
            <Button onClick={() => {props.setCustomLightingModal(true)}} style={{marginTop:"15px"}} className='modal-okay-black square invert'>
                <span className={'modal-okay-text white manrope f-14'}>
                    <PlusOutlined style={{marginRight:"5px"}}/>
                    Add Custom Lighting
                </span>
                
            </Button>
        </div>    
        <div style={{marginTop:"5px"}}>
            <span className='manrope f-14 black silo-heading-main'>
                Lighting Temperature
            </span>
            <span style={{marginLeft:"2px"}} className='manrope f-12'>
                Drag and release the slider to change the color of the lighting
            </span>
            <div style={{display:'flex', flexDirection:"row", alignItems:'baseline'}}>                
                <Slider marks={lightTemperatureMarks} tooltip={{ formatter: null }} value={props.lightTemperature} onChange={(val) => {props.setLightTemperature(val)}} onAfterChange={(value) => {props.onChangeTemperature(value)}} step={200} className='color-temp-slider' min={1000} max={10000} style={{background: 'linear-gradient(270deg, #C4D2F9 3%, #FAE5CE 50%, #EB4A27 100%)',marginTop:"2vh", borderRadius:"4px", height:"5px", width:"70%"}}/>
                <Button style={{marginLeft:"5px"}} onClick={() => {
                    props.onChangeTemperature(5000)
                }} className='modal-okay-blue square medium font-14'>
                    Reset
                </Button>
            </div>

        </div>    
        <Modal 
            footer = {false}
            onCancel={() => setShowColorModal(false)}
            visible={showColorModal} >
            <div>
                <span className='manrope f-16'>
                    Choose Custom Background Color
                </span>
            </div>
            <Divider/>
            
            <div className="overflow-hidden">
                <SketchPicker
                onChange={(color) => {
                    setCustomColor(color.hex);
                }}
                width="fit-content"
                color={customColor}
                presetColors={[]}
                disableAlpha = {true}
                />
            </div>
            <Divider/>
            <div>
                <Button 
                onClick={() => {
                    let backgroundColors = props.allBackgroundColors;
                    backgroundColors.push(customColor);
                    props.setAllBackgroundColors(backgroundColors);
                    props.setHexColor(customColor);
                    setShowColorModal(false);
                }} 
                className="modal-okay-blue square font-14">
                    Add
                </Button>
            </div>
        </Modal>
    </>
  )
}
