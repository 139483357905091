import React from 'react';
import { Modal, Col, Row } from 'antd';
import ENVIRONMENT from '../../../environments';
import FileConstants from '../../../FileConstants';

const GroupItemsModal = (props) => {

    return (

        <Modal
            visible={props.visible}
            className="group-items-modal"
            maskClosable={false}
            onCancel={props.onCancel}
            footer={null}
            >
            <Row style={{ padding: '20px 25px', display: 'block' }}>
                <h4 style={{marginBottom: 30}}>Group Details  <span style={{color: 'darkgray', fontWeight: 400}}> > </span> <span style={{fontWeight: 400, color: 'darkgray'}}>{props.group_id}</span></h4>
                <table className="stats-table dimension-table" style={{ marginTop: 12, width: "100%" }}>
                    <tr>
                        <th>Product Name</th>
                        <th>Assigned Artist</th>
                        <th>Status</th>
                    </tr>
                    {props.groupedProducts.map((item) => {
                        return (
                        <tr>
                            <td><a href={ENVIRONMENT.PORTAL_LINK + 'admin_products/' + item.product_id} style={{ paddingBottom: 15 }}>{item.name}</a> </td>
                            <td>{item.assigned_artist ? item.assigned_artist : 'N/A'}</td>
                            <td>{item.model_status ? FileConstants.MODEL_STATUS[parseInt(item.model_status)] : 'N/A'}</td>
                        </tr>
                        )
                    })}
                </table>
            </Row>
        </Modal>
    );
}

export default GroupItemsModal;