import React, { useState, useContext  } from 'react';
import {
    Row,
    Col,
    Button,
    Modal,
    Upload,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import * as Constants from "../Constants";
import * as UTIF from 'utif';

const CollaborateRenderOverride = (props) => {
    const [resolutionErrorMessage, setResolutionErrorMessage] = useState('')
    const { getFieldDecorator } = props.form;
    const { platform, collabRenderOverrideModal, setCollabRenderOverrideModal, overrideRender, handleOverrideRenderChange, handleRenderOverride, renderOverrideLoader, resolution, getAspectRatio} = useContext(CollaborateContext);
    
    const areAspectRatiosEqual = (aspectRatio1, aspectRatio2) => {
        const [width1, height1] = aspectRatio1.split(':').map(Number);
        const [width2, height2] = aspectRatio2.split(':').map(Number);
    
        return width1 / height1 === width2 / height2;
    }

    return <Modal bodyStyle={{ paddingTop: "2px" }}
        title="Collaboration Render Override"
        visible={collabRenderOverrideModal}
        width={500}
        centered={true}
        onCancel={() => { setCollabRenderOverrideModal(false); setResolutionErrorMessage('') }}
        footer={[
            <Button disabled={renderOverrideLoader} key="back" className="modal-okay" onClick={() => handleRenderOverride()}>
                <div className="modal-okay-text">Replace Render
                <span style={{marginLeft: 8}}>{renderOverrideLoader && <LoadingOutlined/>}</span>
                </div>
            </Button>   
        ]}
    >
        <div>
            <Form className="input-form">
            <Row type="flex">
                <Col span={24} style={{padding: 12}}>
                    <p>Please upload a render with one of the following properties<br></br>
                    1- Resolution: <b>{resolution.width}x{resolution.height}</b><br></br>
                    2- Aspect Ratio similar to: <b>{resolution.aspectRatio}</b></p>
                    <div className="d-flex" style={{justifyContent:"center"}}>
                        <div style={{textAlign:"center",minWidth:"100%"}}>
                            <Form.Item name={'override_render'} label="" colon={false}>
                            {getFieldDecorator('override_render', {
                                valuePropName: 'override_model',
                                getValueFromEvent: e => e && e.fileList,
                                rules: [{ required: true, message: 'Please upload render to replace' }]
                            })(
                                <Upload
                                    accept=".jpg,.png,.tiff"
                                    {...Constants.getUploadProps(platform)}
                                    multiple="true"
                                    listType="text"
                                    fileList = {overrideRender}
                                    beforeUpload={file => {
                                        return new Promise((resolve, reject) => {
                                            const reader = new FileReader();
                                            reader.onload = e => {
                                                if (file.name.includes(".tiff")) {
                                                    // Convert base64/URLEncoded data component to raw binary data held in a string
                                                    let base64 = e.target.result.split(',')[1];
                                                    let binary_string = window.atob(base64);
                                                    let len = binary_string.length;
                                                    let bytes = new Uint8Array(len);
                                                    for (let i = 0; i < len; i++) {
                                                        bytes[i] = binary_string.charCodeAt(i);
                                                    }
                                                    const buffer = bytes.buffer;
                                                    const ifds = UTIF.decode(buffer);
                                                    UTIF.decodeImage(e.target.result, ifds);
                                                    const tiffImage = ifds[0]; // Assuming the first image in the TIFF file
                                                    const width = tiffImage.t256[0];
                                                    const height = tiffImage.t257[0];
                                                    const minWidth = resolution.width; // Example minimum width
                                                    const minHeight = resolution.height; // Example minimum height
                                                    const aspectRatio = getAspectRatio(width, height);
                                                    if (width == minWidth && height == minHeight) {
                                                        setResolutionErrorMessage('');
                                                    } else if (areAspectRatiosEqual(resolution.aspectRatio, aspectRatio)) {
                                                        setResolutionErrorMessage('');
                                                    } else {
                                                        setResolutionErrorMessage(`Warning: None of the above properties match\n Resolution: ${width}x${height}\nAspect Ratio: ${aspectRatio}`);
                                                    }
                                                    resolve(file);
                                                } else {
                                                    const img = new Image();
                                                    img.src = e.target.result;
                                                    img.onload = () => {
                                                        // Check the resolution here (example: width and height)
                                                        const minWidth = resolution.width; // Example minimum width
                                                        const minHeight = resolution.height; // Example minimum height
                                                        const aspectRatio = getAspectRatio(img.width, img.height)
                                        
                                                        if (img.width == minWidth && img.height == minHeight) {
                                                            setResolutionErrorMessage('')
                                                        } else if (areAspectRatiosEqual(resolution.aspectRatio, aspectRatio)) {
                                                            setResolutionErrorMessage('')
                                                        } else {
                                                            setResolutionErrorMessage(`Warning: None of the above properties match\n Resolution: ${img.width}x${img.height}\nAspect Ratio: ${aspectRatio}`)
                                                        }
                                                        resolve(file);
                                                    };
                                                }
                                            };
                                            reader.readAsDataURL(file);
                                        });

                                    }}
                                    onChange = {handleOverrideRenderChange}
                                    className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                                >
                                <div className="d-flex">
                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                    <div className="ant-upload-text">
                                    <div className="up-info">Drop your Files</div>
                                    <div>Support: JPG, PNG, TIFF</div>
                                    </div>
                                </div>
                                </Upload>
                            )}
                            </Form.Item>
                        </div>
                    </div>
                    <p className="text-danger">
                        {resolutionErrorMessage.split('\n').map((line, index) => (
                            <span key={index}>
                            {line}
                            <br />
                            </span>
                        ))}
                    </p>
                </Col>
                </Row>
            </Form>
        </div>
    </Modal>
}

export default Form.create({ name: 'render-override-form' })(CollaborateRenderOverride);