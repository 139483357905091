import React from 'react';
import 'antd/dist/antd.css';
import {
    Row,
    Col,
    Layout,
    Menu,
    Button,
    Radio,
    message,
    Card,
    Modal,
    Popover,
    Tooltip,
    Dropdown,
    Switch
} from 'antd';
import {
    CloseOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    WarningOutlined,
    UndoOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import axios from 'axios';
import * as THREE from "three";
import jsPDF from 'jspdf';
import SceneCreator from '../../../../SceneCreatorAgent';
import CameraControls from '../CameraControls';
import FocalControls  from '../FocalControl/FocalControls.jsx'
import SpaceConfigurator  from '../SpaceConfigurator/SpaceConfigurator.jsx'
import ENVIRONMENT from '../../../../environments'
import SceneCreatorOnboarding from '../SceneCreatorOnboarding';
import * as Settings from './settingsConstans';
import { SceneCreatorModals } from './SceneCreatorModals';
import * as Utilities from '../../Utilities';
import AWS from "aws-sdk";
import { ASSET_GLB_URL, ROOM_ASSET_GLB } from '../../../../environments/env';
import { BUCKET_NAME, CONFIG_PRODUCT_FILE_KEY, CONFIG_SPACE_FILE_KEY } from '../../../../environments/env';
import FileConstants from '../../../../FileConstants'
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import ClippingControls from '../ClippingControls/ClippingControls';
import TransformControls from '../TransformControls/TransformControls';
import AddProductsToScene from '../AddProductsToScene/AddProductsToScene';
import { BASE_URI, getBaseURL } from '../../../../environments/env';
import CollaborateConstants from '../CollaborateTool/Constants.js';
import SwapSpace from '../SwapSpace/SwapSpace.jsx';
import SwapProduct from '../SwapProduct/SwapProduct.jsx';
import SunDirection from '../../SunControls/SunDirection.jsx';
import SunElevation from '../../SunControls/SunElevation.jsx';
import UndoLostAsset from '../UndoLostAsset/UndoLostAsset.jsx';
import './SceneCreator.scss';
const { confirm } = Modal;
const { Footer } = Layout;

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
const DEFAULT_SPACE_MATERIALS = FileConstants.DEFAULT_SPACE_MATERIALS;
const DEFAULT_ADMIN_SPACE_MATERIALS = FileConstants.DEFAULT_ADMIN_SPACE_MATERIALS;
const CAMERA_MODES = FileConstants.SCENE_CREATOR_CAMERA_MODES;
window.productPlatform = {};
class SceneCollaboratorWrapper extends React.Component{

    sceneCreator = null;
    sunControls = null;

constructor(props) {
    super(props);
    this.state = {
        visible: false,
        thumbnails: [],
        menu_products: [],
        cameras: [],
        room_max_archive: '',
        sun_controls_view: 0,
        grid_controls_view: 0,
        sun_azimuthal: 0,
        sun_polar: 0,
        update_camera: '',
        isLoading: true,
        isUpdating: false,
        fullscreen: false,
        scene_name: '',
        scene_status: 0,
        selecting_perspective: false,
        shortcuts_visible: false,
        submit_360_visible: false,
        submit_360_done_visible: false,
        submit_collaboration_render_done_visbile:false,
        submit_tour_visible: false,
        submit_tour_done_visible: false,
        submit_plain_done_visible: false,
        submit_plain_visible: false,
        submit_plain_error_visible: false,
        taking_snapshot_visible: false,
        camera_index: 1,
        sliderPosition2D: 0,
        sliderPosition3D: 35,
        cameraAngle: 0,
        tempSnapshot: '',
        resolutions: ["High (3200 x 1800)", "Medium (1600 x 900)", "Low (800 x 450)", "Custom"],
        resolution_value: '1024x640',
        aspect_ratio_selection_visible: false,
        aspect_ratio_value: '16:10',
        aspect_ratio_selected: '16:10',
        aspect_width: 16,
        aspect_height: 10,
        image_width: 1,
        image_height: 1,
        image_format: 'jpg',
        fov_value: 'medium',
        onboarding_modal: false,
        loading_progress_message: "Initiating Scene Load",
        edit_mode: 'edit',   //value can be 'edit' or 'view'
        scene_view_state: 'design',  // value can be 'design' or 'images' or 'create360'
        previous_edit_mode: null,
        previous_scene_view_state: null,
        space_areas: ['Default'],
        selected_360s: ['Room 360', 'Default'],
        selected_360s_resolutions: {'Room 360':'2k', 'Default':'2k'},
        final_renders: [],
        three_sixties: [],
        camera_hotspots: [],
        hotspots: [],
        mode_360: 'preview',
        reset_true: false,
        preview_true: true,
        selected_area_preview: null,
        camera_obj: [],
        tour_flag: false,
        progress_loader_360: false,
        progress_loader_3d: false,
        progress_loader_exit: false,
        image_property: [],
        save_scene_flag: false,
        saved_scene_data: [],
        show_camera_modal: false,
        confirm_360_loader: false,
        confirm_3d_loader: false,
        confirm_image_loader: false,
        hide_camera: false,
        dpi: 300,
        autosave_routine_running: false,
        aspect_ratio_modal: false,
        orientation: 'Landscape',
        image_width_res: 1,
        image_height_res: 1,
        resolution_status: 3,
        imageResArr: [],
        aspect_clicked: false,
        orientatio_clicked: false,
        setting_done: false,
        admin_scene_creator: false,
        reset_configuration: {},
        preview_renders: [],
        admin_scene_test_mode: false, // True when scene creator is to be launched for testing user provided JSON
        current_placement_id: null,
        current_placement_info : null,
        placement_versions: {},
        scene_check: false,
        complaint_id: 0,
        json_expired: false,
        current_scene_placement : null,
        grid_settings_modal: false,
        overlay_settings_modal:false,
        default_overlay_settings: true,
        temp_snapshot_overlay : '',
        show_grid: false,
        show_grid_switch: true,
        show_overlay_switch:false,
        grid_tile_width: 1,
        grid_tile_height: 1,
        overlay_width:100,
        overlay_height:100,
        overlay_opacity:50,
        grid_tile_unit: 'feet',
        overlay_size_unit: 'pixels',
        grid_color: '#FFFFFF',
        overlay_color:'#500000',
        grid_line_thickness: 1,
        collection_products: [],
        restore_products_modal: false,
        deleted_asset_ids: [],
        enable_generate_lifestyle: false,
        camera_zoom_options: true,
        api_finished: true,
        api_loader:false,
        camera_height: 0,
        snapshot_clicked: false,
        camera_Fov:0,
        camera_Focal:0,
        default_camera_Focal: 25,
        room_height:0,
        previous_mode: null,
        render_name_editable: false,
        scene_render_name: 'Untitled',
        default_scene_render_name: 'Untitled',
        display_names: [],
        overlay_limit: 200,
        request_status: '',
        card_status: '',
        scene_payload: '',
        scene_tour: false,
        request_id: -1,
        card_save_drawer: false,
        card_save_failure: false,
        payment_request_id: -1,
        request_type: '',
        prices: [],
        summary_modal_cameras: [],
        subscription_modal: false,
        subscription_loader: false,
        current_package: '',
        subscription_plan: '',
        selected_categories: [],
        new_final_renders: [],
        requested_render_categories: [],
        confirm_scene_request: false,
        show_disclaimer : null,
        used_tokens : 0,
        available_tokens : 0,
        threed_loader : false ,
        three_price : false,
        payment_failure_message : '',
        payment_failure_modal : false,
        card_save_flow_triggered: false,
        in_app_limit_exceeded_modal: false,
        scene_template_id: -1,
        user_added_products: [],
        show_space_configurator_menu: false,
        enable_space_configurator_mode: false,
        space_configuration_applied: false,
        enable_measurement_tool: false,
        material_info:[],
        texture_info: [],
        space_selection_config: {},
        space_selection_type: '',
        spaceSelectionColorConfigurable: false,
        is_space_configurable: false,
        is_tour_open: false,
        clipping: false,
        clipping_value : 0,
        is_subscription_active: false,
        collaboration_id:null,
        collaboration_scene_id: null,
        collaboration_original_camera: null,
        collaboration_active_camera: null,
        collaboration_save_camera: null,
        collaboration_design: null,
        collaboration_image_url: null,
        collaboration_state: null,
        collaboration_platform: 'aws',
        collaboration_signed_url: '',
        collaboration_comments: [],
        render_name: null,
        submit_collaboration_render_modal: false,
        platform: 'aws',
        thumbnail_signed_url: '',
        edit_resolution_enabled: true,
        collab_camera_prefix : "collabcamera_",
        add_product_modal: false,
        collection_id: -1,
        camera_mode: CAMERA_MODES.TopDown,
        productSize: {
            "length": 0,
            "height": 0,
            "depth": 0
        },
        enableProductSizeControls: false,
        freeModeState: "off",
        space_swap_modal: false,
        product_swap_modal: false,
        room_id: null,
        selected_product_id: null,
        room_parent: '-1',
        parent_platform: 'aws',
        swap_space_loading: false,
        swap_product_loading: false,
        swapButtonRef: React.createRef(),
        room_img: '',
        show_snapshots: false,
        is_gray_mode: false,
        project_ids : -1,
        undo_modal: false
    };
}

    toggleSpaceSwapModal = (value) => {
        this.setState({
            space_swap_modal: value
        })
    }

    toggleProductSwapModal = (value) => {
        this.setState({
            product_swap_modal: value
        })
    }

    handleSwapProductClick = (id) => {
        this.setState({
            product_swap_modal: true,
            selected_product_id: id
        })
    }

    loadUserRequestStatus = (request_category=null,request_type = null, confirm_request = null) => {
        let payload = {
        "username": localStorage.getItem('username'),
        "action": "check",
        "request_type" : "scene_lifestyle_image",
        "categories" : ['1k']
        }
        if (request_category!=null && request_type != null) {
            payload.categories = request_category;
            payload.request_type = request_type;
        }

        console.log("payload payment", payload)

        if (payload.categories.length != 0) {
            axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                console.log('USERCHECK',res)
                this.setState({
                    request_status: res.data['request_allowed'],
                    card_status: res.data['card_status'],
                    current_package: res.data['subscription_plan'],
                    available_tokens :res.data['available_lifestyle_tokens'],
                    used_tokens:res.data['used_lifestyle_tokens'],
                    is_subscription_active: res.data['is_subscription_active']

                }
                ,() => {
                    this.loadPayments();
                    if (confirm_request != null) {
                        this.processSceneRequest(this.state.scene_payload,this.state.new_final_renders, this.state.scene_tour);
                    }
                })
                if(request_type == '3d_tour'){
                    this.setState({
                        three_price : res.data['price'],
                        threed_loader : false
                    })
                }
            });
        }
    }

    setInAppLimitExceededModal = (value) => {
        this.setState({
            in_app_limit_exceeded_modal: value
        });

        this.setState({
            submit_tour_visible: false,
            threed_loader: false,
            confirm_3d_loader: false
        });
    }

    setSubscriptionPlan = (plan) => {
        this.setState({
            subscription_plan: plan
        })
    }

    toggleAddProductModal = (value) => {
        this.setState({
            add_product_modal: value
        })
    }

    loadPayments = () => {
        let payload = {
            "request_type": 'scene_lifestyle',
        }

        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
        .then(res => {
            console.log('Prices',res.data);
            if (res.data) {
                this.setState({
                    prices: res.data
                })
            }
        })
    }

    returnPrice = (resolution, request_name, type='price') => {
        let width = resolution.split('x')[0]
        let height = resolution.split('x')[1]
        let prices = [];
        if (this.state.prices.length > 0) {
            if (Utilities.nearestThousand(height) == 4000 || Utilities.nearestThousand(width) == 4000 || height >= 4000 || width >= 4000) {
                prices =  this.state.prices.filter((request) => request_name == request.request_type && request.category == "4k")
            } else if(Utilities.nearestThousand(height) == 3000 || Utilities.nearestThousand(width) == 3000) {
                prices =  this.state.prices.filter((request) => request_name == request.request_type && request.category == "2k")
            } else if (Utilities.nearestThousand(height) == 2000 || Utilities.nearestThousand(width) == 2000) {
                prices =  this.state.prices.filter((request) => request_name == request.request_type && request.category == "2k")
            } else if ((height >0 && width >0)) {
                prices =  this.state.prices.filter((request) => request_name == request.request_type && request.category == "1k")
            }
            console.log(prices)
            if (prices.length > 0 && prices[0]) {
                if (type == 'price') {
                    return parseInt(prices[0].price);
                } else {
                    return prices[0].category;
                }

            }
        }
    }


    returnPrice360 = (x,request_name, type ='price') => {
        let prices = [];
        if (this.state.prices.length > 0) {
            prices =  this.state.prices.filter((request) => request_name == request.request_type && request.category == this.state.selected_360s_resolutions[x])
            if (type == 'price') {
                return parseInt(prices[0].price);
            } else {
                return prices[0].category;
            }
        }
    }

    updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            this.setState({
                card_save_flow_triggered: false
            });
            if (action == 'save_card') {
              message.success('Payment Method Updated Successfully.')
            }
        }, false);

        this.setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
        if(child_window.closed) {
            this.setState({
                card_save_flow_triggered: false,
            });
            clearInterval(refreshInterval);
        }
        }, 500);
        this.setState({
            card_save_flow_triggered: true
        });
    }

    setPaymentFailureModal = (value) => {
        this.setState({
            payment_failure_modal : value
        })
    }

    setPaymentFailureMessage = (value) => {
        this.setState({
            payment_failure_message : value
        })
    }

    totalPrice = (type) => {
        let totalPrice = 0;
        if (type == 'still') {
            this.state.summary_modal_cameras.slice(0).reverse().map((element,index) => {
                if ( element['name'] != "360Camera" && element['name'] != "helper" ) {
                    totalPrice = totalPrice + this.returnPrice(element.resolution,'scene_lifestyle_image');
                }
            })
        }
        if (type == '360') {
            console.log(this.state.selected_360s)
            this.state.selected_360s.map((element,index) => {
                if (element != 'Room 360') {
                    totalPrice = totalPrice + this.returnPrice360(element,'scene_lifestyle_360');
                }
            })
        }

        return totalPrice;
    }

    onChangeCameraAngle= (val) => {
        if(val>=0 && val<=180){
            val = 90 - val
            let slider_handle = document.getElementsByClassName("ant-slider-handle")[0];
            slider_handle.style.transform = "rotate("+val+"deg)";
            this.setCameraAngle(val)
            this.setPolarAngle(val + 90);
            window.$("#slider").roundSlider({
                value: val
            })
        }
    }

    setCardSaveFailure = (value) => {
        this.setState({
            card_save_failure: value
        });
    }

    setCardSaveDrawer = (value) => {
        this.setState({
            card_save_drawer: value
        });
    }

    reset3DCameraHeight = () => {
        let val = 35;
        this.setState( {
            sliderPosition3D: val,
            camera_height: Math.round(( ( (val/150) *(this.state.room_height*39.37))))
        })
        this.sceneCreator.setCameraHeight(val/450)
    }
    
    is3DModeActive = () => {
        return this.state.camera_mode == CAMERA_MODES.ThreeD;
    }

    isTopDownModeActive = () => {
        return this.state.camera_mode == CAMERA_MODES.TopDown;
    }

    isFlatViewModeActive = () => {
        return this.state.camera_mode == CAMERA_MODES.FlatView;
    }


    SetOverlayLines = () => {
        try{
            if(this.is3DModeActive() || this.state.scene_view_state == "images"){
                var canvas_image = document.getElementById('scene-creator').getElementsByTagName('canvas')[1]

                if(canvas_image && this.state.show_overlay_switch){
                    var image_width =  parseFloat(this.state.resolution_value.split('x')[0]);
                    var image_height = parseFloat(this.state.resolution_value.substring(this.state.resolution_value.indexOf('x') + 1));
                    //Setting top and bottom of top bottom overlay
                    document.getElementById('overlay-blur-top').style.top = canvas_image.offsetTop +'px'
                    document.getElementById('overlay-blur-top').style.left = canvas_image.offsetLeft +'px'
                    document.getElementById('overlay-blur-bottom').style.left = canvas_image.offsetLeft +'px'


                    //setting width of top and bottom
                    document.getElementById('overlay-blur-top').style.width = canvas_image.getBoundingClientRect().width + "px"
                    document.getElementById('overlay-blur-bottom').style.width = canvas_image.getBoundingClientRect().width  + "px"

                    //Setting left and right of left right overlay
                    document.getElementById('overlay-blur-left').style.left = canvas_image.offsetLeft +'px'
                    document.getElementById('overlay-blur-right').style.right = canvas_image.offsetLeft +'px'


                    //handling case for in case of pixels
                    if (this.state.overlay_size_unit == 'pixels'){
                        //for lines on top and bottom we just set the height
                        document.getElementById("overlay-blur-top").style.height = (canvas_image.getBoundingClientRect().height/image_height*  this.state.overlay_height) + "px";
                        document.getElementById("overlay-blur-bottom").style.height = (canvas_image.getBoundingClientRect().height/image_height* this.state.overlay_height) + "px";

                        var height_of_top = 2* document.getElementById("overlay-blur-top").getBoundingClientRect().height;

                        //for lines on left and right, first we adjust their position and height, according to the lines on top and bottom
                        document.getElementById("overlay-blur-left").style.height = (canvas_image.getBoundingClientRect().height - height_of_top) + "px"
                        document.getElementById("overlay-blur-left").style.top = (canvas_image.getBoundingClientRect().height/image_height* this.state.overlay_height) +"px";
                        document.getElementById("overlay-blur-right").style.height = (canvas_image.getBoundingClientRect().height - height_of_top) + "px"
                        document.getElementById("overlay-blur-right").style.top = (canvas_image.getBoundingClientRect().height/image_height* this.state.overlay_height) +"px";

                        //then we adjust their width
                        document.getElementById("overlay-blur-left").style.width = (canvas_image.getBoundingClientRect().width/image_width* this.state.overlay_width) + "px"
                        document.getElementById("overlay-blur-right").style.width = (canvas_image.getBoundingClientRect().width/image_width* this.state.overlay_width) + "px"
                    }
                    else{
                        //for lines on top and bottom we just set the height
                        document.getElementById("overlay-blur-top").style.height = canvas_image.getBoundingClientRect().height * this.state.overlay_height /200 + "px";
                        document.getElementById("overlay-blur-bottom").style.height = canvas_image.getBoundingClientRect().height * this.state.overlay_height /200 + "px    ";

                        var height_factor = canvas_image.getBoundingClientRect().height * this.state.overlay_height /200
                        //for lines on left and right, first we adjust their position and height, according to the lines on top and bottom
                        document.getElementById("overlay-blur-left").style.height = canvas_image.getBoundingClientRect().height-(2*height_factor) +"px"
                        document.getElementById("overlay-blur-left").style.top = document.getElementById('overlay-blur-top').style.height
                        document.getElementById("overlay-blur-right").style.height = canvas_image.getBoundingClientRect().height-(2*height_factor) +"px"
                        document.getElementById("overlay-blur-right").style.top = document.getElementById('overlay-blur-top').style.height;

                        //then we adjust their width
                        document.getElementById("overlay-blur-left").style.width =  canvas_image.getBoundingClientRect().width * this.state.overlay_width /200 + "px";
                        document.getElementById("overlay-blur-right").style.width =  canvas_image.getBoundingClientRect().width * this.state.overlay_width /200 + "px";
                    }
                    document.getElementById('overlay-blur-bottom').style.top = canvas_image.offsetTop + canvas_image.getBoundingClientRect().height - parseFloat(document.getElementById("overlay-blur-top").style.height,10) +'px'
                }
            }
        }
        catch{
            console.log("Window resize error")
        }
    }

    factor_height_pixels = 0;
    factor_width_pixels = 0;

    setRenderNameEditable = (editable) => {
        this.setState({
            render_name_editable: editable
        })
    }

    setSceneRenderName = (renderName) => {
        this.setState({
            scene_render_name: renderName
        })
    }

    setShowGridSwitch = (checked) => {
        this.setState({
            show_grid_switch: checked
        });

    }

    hideTempOverlayLines = () => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i=0 ; i<overlay_lines.length ; i++){
            overlay_lines[i].style.display='none'
        }
    }

    setTempOverlayLines = () => {
        const default_settings = this.state.default_overlay_settings;
        if (default_settings) {
            this.applyOverlayWidth(this.state.overlay_width);
            this.applyOverlayHeight(this.state.overlay_height);
            this.applyOverlayColor(this.state.overlay_color);
            this.applyOverlaySwitch(true);
            this.setState({
                default_overlay_settings: false,
                show_overlay_switch: true
            })
        }
        if(document.getElementById('overlay-temp-top')){
            //DEFINING SOME VARIABLES TO BE USED
            var height_of_top_bottom = document.getElementById('overlay-temp-top').getBoundingClientRect().height;
            var bottom_offset =  document.getElementsByClassName('img-container')[0].getBoundingClientRect().height - document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().height
            var right_offset = document.getElementsByClassName('img-container')[0].getBoundingClientRect().width - document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().width

            //setting width
            document.getElementById('overlay-temp-top').style.width = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width + "px";
            document.getElementById('overlay-temp-bottom').style.width = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width + "px";

            //setting height
            document.getElementById('overlay-temp-right').style.height = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height - (2*height_of_top_bottom) + "px"
            document.getElementById('overlay-temp-left').style.height = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height - (2*height_of_top_bottom) + "px"

            //setting top
            document.getElementById('overlay-temp-right').style.top = height_of_top_bottom + "px"
            document.getElementById('overlay-temp-left').style.top = height_of_top_bottom + "px"

            //setting bottom and right
            document.getElementById('overlay-temp-bottom').style.bottom = bottom_offset +"px"
            document.getElementById('overlay-temp-right').style.right = right_offset +"px"
        }

    }

    hideOverlayLines = () => {
        var overlay_lines = document.getElementsByClassName("overlay-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            overlay_lines[i].style.display = "none";
        }
    }


    setOverlayLimit = (value='default') => {
        if(value=='default'){
            if(this.state.overlay_size_unit == 'pixels'){
                var image_width =  parseFloat(this.state.resolution_value.split('x')[0]);
                var image_height = parseFloat(this.state.resolution_value.substring(this.state.resolution_value.indexOf('x') + 1));
                this.setState({
                    overlay_limit : Math.min(image_width/2,image_height/2)
                })
            }
            else{
                this.setState({
                    overlay_limit : 100
                })
            }
        }
        else{
            if(value == 'pixels'){
                var image_width =  parseFloat(this.state.resolution_value.split('x')[0]);
                var image_height = parseFloat(this.state.resolution_value.substring(this.state.resolution_value.indexOf('x') + 1));
                this.setState({
                    overlay_limit : Math.min(image_width/2,image_height/2)
                })
            }
            else{
                this.setState({
                    overlay_limit : 100
                })
            }
        }

    }

    showOverlayLines = () => {
        var overlay_lines = document.getElementsByClassName("overlay-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            overlay_lines[i].style.display = "inherit";
        }
    }

    showTempOverlayLines = () => {
        if(this.state.show_overlay_switch){
            var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
            for (var i = 0 ; i < overlay_lines.length ; i++){
                overlay_lines[i].style.display = "inherit";
            }
        }
    }

    applyOverlaySwitch = (checked) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            if(checked)
                overlay_lines[i].style.display = "inherit";
            else
                overlay_lines[i].style.display = "none";
        }
    }

    setShowOverlaySwitch = (checked) => {
        this.applyOverlaySwitch(checked)
        this.setState({
            show_overlay_switch: checked,
        })
        this.setTempOverlayLines();
    }

    handleDiscardGridSettings = e => {
        this.setState({
        grid_settings_modal: false,
        });
    };

    handleDiscardOverlaySettings = e =>{
        this.setState({
            overlay_settings_modal: false,
        })
        this.hideTempOverlayLines();
    }

    toggleCameraZoom = () => {
        this.setState({
            camera_zoom_options: !this.state.camera_zoom_options
        });
        console.log('in here %')
    }

    handleApplyGridSettings = e => {

        if (this.state.grid_tile_width != null) {
            document.getElementById("grid-error").style.display = "none";
            this.setState({
                grid_settings_modal: false,
                });
            this.sceneCreator.handleApplyGridSettings(this.state.show_grid_switch,  this.state.grid_tile_height, this.state.grid_color,
            this.state.grid_line_thickness, this.state.grid_tile_unit);
        }
        else {
            document.getElementById("grid-error").style.display = "inline";
        }

    };

    handleApplyOverlaySettings = e => {
        this.SetOverlayLines();
        this.setOverlayLimit();
        var overlay_lines = document.getElementsByClassName("overlay-blur");
        if(this.state.show_overlay_switch){
            for (var i = 0 ; i < overlay_lines.length ; i++) {
                overlay_lines[i].style.display = 'inherit';
                overlay_lines[i].style.opacity = this.state.overlay_opacity/100;
                overlay_lines[i].style.background = this.state.overlay_color;
            }
        }
        else{
            for (var i = 0 ; i < overlay_lines.length ; i++) {
                overlay_lines[i].style.display = 'none'
            }
        }
        this.setState({
            overlay_settings_modal: false
        });
        this.hideTempOverlayLines();
    }

    onChangeGridDimension = (value) => {
        this.setState({
            grid_tile_height: value,
            grid_tile_width: value
        });
    }

    applyOverlayHeight = (value) => {
        if(this.state.overlay_size_unit == 'pixels'){
                
            var canvas_image = document.getElementById('scene-creator').getElementsByTagName('canvas')[1]        
            var image_height = parseFloat(this.state.resolution_value.substring(this.state.resolution_value.indexOf('x') + 1));
        
            this.factor_height_pixels = document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().height / document.getElementById('scene-creator').getBoundingClientRect().height ;
        

            //changing the height of lines on top and bottom 
            document.getElementById("overlay-temp-top").style.height = this.factor_height_pixels*canvas_image.getBoundingClientRect().height/image_height*value +"px";
            document.getElementById("overlay-temp-bottom").style.height = this.factor_height_pixels*canvas_image.getBoundingClientRect().height/image_height*value +"px";
        
        }
        else{
            
            //calculating the height of top,bottom lines
            var height_of_snapshot = parseInt(document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height*value/100);

            //for lines on top and bottom we set the height
            document.getElementById("overlay-temp-top").style.height = height_of_snapshot/2 +"px";
            document.getElementById("overlay-temp-bottom").style.height = height_of_snapshot/2 +"px";

        }
    }

    onChangeOverlayHeight = (value) => {
        if(value<=this.state.overlay_limit){
            this.applyOverlayHeight(value);
            this.setTempOverlayLines();
            this.setState({
                overlay_height : value
            })

        }
    }

    applyOverlayWidth = (value) => {
        if(this.state.overlay_size_unit == 'pixels'){
            var canvas_image = document.getElementById('scene-creator').getElementsByTagName('canvas')[1]        
            var image_width =  parseFloat(this.state.resolution_value.split('x')[0]);
            this.factor_width_pixels =  document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().width / document.getElementById('scene-creator').getBoundingClientRect().width ;

            //changing the height of lines on top and bottom 
            document.getElementById("overlay-temp-right").style.width = this.factor_width_pixels*canvas_image.getBoundingClientRect().width/image_width*value +"px";
            document.getElementById("overlay-temp-left").style.width = this.factor_width_pixels*canvas_image.getBoundingClientRect().width/image_width*value +"px";
        }
        else{
            
            //calculating the height of left,right lines
            var width_of_snapshot = parseInt(document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width*value/100);

            //for lines on left and right we just set the width
            document.getElementById("overlay-temp-right").style.width = width_of_snapshot/2 +"px";
            document.getElementById("overlay-temp-left").style.width = width_of_snapshot/2 +"px";

        }
    }

    onChangeOverlayWidth = (value) => {
        if(value<=this.state.overlay_limit){
            this.applyOverlayWidth(value);
            this.setTempOverlayLines();
            this.setState({
                overlay_width : value
            })
        }    
    }

    onChangeOverlayOpacity = (value) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            overlay_lines[i].style.opacity = value/100;
        }
        this.setState({
            overlay_opacity : value,
        })
    }

    onChangeGridUnit = (e) => {
        this.setState({
            grid_tile_unit: e.target.value
        });
    }

    onChangeOverlayUnit = (e) => {
        this.setState({
            overlay_size_unit: e.target.value
        })
        this.setOverlayLimit(e.target.value)
    }

    applyOverlayColor = (value) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            overlay_lines[i].style.background = value;
        }
    }

    onChangeOverlayColor = (value) => {
        this.applyOverlayColor(value);
        this.setState({
            overlay_color: value
        })
    }

    onChangeGridColor = (value) => {
        this.setState({
            grid_color: value
        });
    }

    onChangeGridThickness = (value) => {
        this.setState({
            grid_line_thickness: value
        });
    }

    setRestoreProductsModal = (value) => {
        this.setState({
            restore_products_modal: value
        });
    }

    unHideItem = (id) => {
        console.log('item to unhide');
        console.log(id);
        this.sceneCreator.unHideTrigger(id);
        let deletedAssetIds = this.state.deleted_asset_ids.filter((ele) => ele.toString() != id.toString());
        console.log(deletedAssetIds)
        this.setState({
            deleted_asset_ids: deletedAssetIds
        });
    }

    
    removeFromDeletedAssets = (assets) => {
        let assetIdsToRemove = assets.map(asset => asset.id.toString());
        let deletedAssetIds = this.state.deleted_asset_ids.filter(deletedId => !assetIdsToRemove.includes(deletedId.toString()));
        this.setState({
            deleted_asset_ids: deletedAssetIds
        });
    }

    removeFromCollectionProducts = (product_id) => {
        let collection_products = this.state.collection_products;
        collection_products = collection_products.filter(id => id.toString() != product_id.toString())
        this.setState({
            collection_products: collection_products
        });
    }

    restoreAssets = (assetsList) => {
        if (this.sceneCreator != null) {
            
            let assetCategoryMaping = {}
            let assetMaterialTypeMapping = {}
            let assetPlacementTypeMapping = {}
            for (let asset of assetsList) {
                const assetId = asset.id.toString();

                window.productPlatform[assetId] = asset.platform
                
                if (asset.category) {
                    assetCategoryMaping[assetId] = asset.category
                }
                
                if (asset.material_type) {
                    assetMaterialTypeMapping[assetId] = asset.material_type
                }
                
                if (asset.placement_type) {
                    assetPlacementTypeMapping[assetId] = asset.placement_type 
                }
            }
            this.sceneCreator.updateProductPlatforms(window.productPlatform)
            this.sceneCreator.restoreAssets(assetsList, assetPlacementTypeMapping, assetMaterialTypeMapping, assetCategoryMaping, () => {
                this.removeFromDeletedAssets(assetsList);
                this.saveScene(true);
            })
        }
    }

    restoreInformation = (check) => {
        this.setState({
            tempSnapshot: this.state.reset_configuration.img,
            dpi: this.state.reset_configuration.dpi,
            image_format: this.state.reset_configuration.image_format,
            resolution_status: this.state.reset_configuration.resolution_status,
            orientation: this.state.reset_configuration.orientation,
            resolution_value:this.state.reset_configuration.resolution,
            aspect_ratio_selected: parseFloat(this.state.reset_configuration.aspect_ratio),
            image_width: this.state.reset_configuration.image_width,
            image_height: this.state.reset_configuration.image_height,
            image_height_res: this.state.reset_configuration.custom_height,
            image_width_res: this.state.reset_configuration.custom_width,
        });

        if (this.state.reset_configuration.orientation == 'Custom') {
            let new_aspect = this.state.reset_configuration.custom_width/this.state.reset_configuration.custom_height;
            this.setState({
                aspect_ratio_selected: parseFloat(new_aspect)
            });
            if (check) {
                this.sceneCreator.setCustomAspect(parseFloat(new_aspect));
            }
        }
        else {
            if (this.state.reset_configuration.resolution_status == 4){
                this.setState({
                    aspect_ratio_selected: this.state.reset_configuration.aspect_ratio,
                });

            }
            else {
                this.changeAspectRatio(this.state.reset_configuration.aspect_ratio,this.state.reset_configuration.orientation);
            }
            if (check) {
                this.sceneCreator.setCustomAspect(this.state.reset_configuration.aspect_ratio);
            }
        }
    };

    onDPIChange = (e) => {
        this.setState({
            dpi: e.target.value
        });
    }

    getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    set3DTourCategory = () => {
        this.setState({
            threed_loader : true
        })
        let resolutions = [];
        let highest_resolution = '1k'
        let resolution_category = ['2_1k']
        for(let i =0; i < this.state.selected_360s.length;i++){
            resolutions.push(this.state.selected_360s_resolutions[this.state.selected_360s[i]])
        }

        for(let i=0;i<resolutions.length;i++){
            if(resolutions[i] == '1k' && highest_resolution !='4k' && highest_resolution !='2k'){
                highest_resolution = resolutions[i]
                resolution_category[0] = resolutions.length+ "_" + resolutions[i]
            }
            if(resolutions[i] == '2k' && highest_resolution !='4k'){
                highest_resolution = resolutions[i]
                resolution_category[0] = resolutions.length+ "_" + resolutions[i]
            }
            if(resolutions[i] == '4k'){
                highest_resolution = resolutions[i];
                resolution_category[0] = resolutions.length+ "_" + resolutions[i]
                break;
            }
        }
        return resolution_category
    }

    setSelected360s = (name, value, threed_tour_request = false) => {
        if(value == true) {
            const {selected_360s} = this.state
            this.setState({
                selected_360s: selected_360s.concat(name),
            },
            ()=>{
                if (threed_tour_request == true){
                    if(this.state.selected_360s.length > 1) {
                        this.loadUserRequestStatus(this.set3DTourCategory(),"3d_tour",null )
                    }
                }
                else{
                    let three_sixty_resolutions = []
                    for (let i = 0 ; i < this.state.selected_360s.length; i++){
                        three_sixty_resolutions.push(this.state.selected_360s_resolutions[this.state.selected_360s[i]])
                    }
                    if (three_sixty_resolutions != [])
                    if (this.state.selected_360s.length > 0) {
                        this.loadUserRequestStatus(three_sixty_resolutions,"scene_lifestyle_image",null )
                    }
                }
            }
            );
        }
        else{
            let selected_360s_temp = [];
            for (var i=0; i<this.state.selected_360s.length; i++){
                if (this.state.selected_360s[i] != name)
                {
                    selected_360s_temp.push(this.state.selected_360s[i]);
                }
            }
            this.setState({
                selected_360s: selected_360s_temp
            },
            ()=>{
                if (threed_tour_request == true){
                    if(this.state.selected_360s.length > 1) {
                        this.loadUserRequestStatus(this.set3DTourCategory(),"3d_tour",null )
                    }
                }
                else{
                    let three_sixty_resolutions = []
                    for (let i = 0 ; i < this.state.selected_360s.length; i++){
                        three_sixty_resolutions.push(this.state.selected_360s_resolutions[this.state.selected_360s[i]])
                    }
                    if (this.state.selected_360s.length > 0) {
                        this.loadUserRequestStatus(three_sixty_resolutions,"scene_lifestyle_image",null )
                    }
                }
            });
        }

    }

    set360Resolution = (area, resolution) => {
        let selected_360s_resolutions = this.state.selected_360s_resolutions;
        selected_360s_resolutions[area] = resolution;
        this.setState({
            selected_360s_resolutions: selected_360s_resolutions
        },
        ()=>{
            if(this.state.submit_tour_visible == true){
                if (this.state.selected_360s.length > 1) {
                    this.loadUserRequestStatus(this.set3DTourCategory(),"3d_tour",null )
                }
            }
            else{
                let three_sixty_resolutions = []
                for (let i = 0 ; i < this.state.selected_360s.length; i++){
                    three_sixty_resolutions.push(this.state.selected_360s_resolutions[this.state.selected_360s[i]])
                }
                if (three_sixty_resolutions != [])
                if (this.state.selected_360s.length > 0) {
                    this.loadUserRequestStatus(three_sixty_resolutions,"scene_lifestyle_image",null )
                }
            }
        });
    }


    setLoadingProgressMessage = (value) => {
        this.setState({
            loading_progress_message: value
        });
        this.forceUpdate();
    }

    setSliderPosition2D = (value) => {
        this.setState({
            sliderPosition2D: 300 - (value*300)
        });
    }
    
    getSliderPosition3D = () => {
        return this.state.sliderPosition3D;
    }

    setSliderPosition3D = (value) => {
        this.setState({
            sliderPosition3D: value
        });
    }


    getCameraAngle = () =>{
        return this.state.cameraAngle;
    }
 
    getCameraHeight = () =>{
        if(this.sceneCreator){
            return this.sceneCreator.getCameraHeightActive();
        }
    }

    setCameraAngle =  (value) => {
        this.setState({
            cameraAngle: value
        })
    }

    setSliderValue = (value) => {
        window.$('#slider').roundSlider('setValue', value-90);
        let slider_handle = document.getElementsByClassName("ant-slider-handle")[0];
        if (slider_handle != undefined) {
            slider_handle.style.transform = "rotate("+(value-90)+"deg)";
        }
        this.setCameraAngle(value-90);
    }

    handleSunModeSwitch = (state) => {
        if (this.state.is_gray_mode != state) {
            if (state) {
                if (this.sceneCreator) {
                    this.sceneCreator.enableGrayMode();
                }
                const disclaimer = "You have entered the gray mode to view sun lighting effects"
                this.setDisclaimer(disclaimer);
            }
            else {
                if (this.sceneCreator) {
                    this.sceneCreator.disableGrayMode();
                }
            }
            this.setState({
                is_gray_mode: state
            })
        }
    };

    getAzimuthal = () => {
        let azimuth = 180 - this.sceneCreator.getSunAzimuthal();
        if (azimuth < 0)
        {
            azimuth = 360 + azimuth;
        }
        return azimuth;
    }

    getPolar = () => {
        let polar = this.sceneCreator.getSunPolar();
        return polar;
    }

    setAzimuthal = (value) => {
        this.sceneCreator.sunControls.setAzimuthalAngle(180 - value);
        this.setState({
            sun_azimuthal: value
        })
    }

    setPolar = (value) => {
        this.sceneCreator.sunControls.setPolarAngle(value);
        this.setState({
            sun_polar: value
        })
    }

    setSunPosition = () => {
        this.setState({
            sun_azimuthal: this.getAzimuthal(),
            sun_polar: this.getPolar()
        })
    }

    resetSun = () => {
        this.sceneCreator.resetSun();
        this.setSunPosition();
    }

    zoomIn = () => {
        this.sceneCreator.zoomIn();
    }

    zoomOut = () => {
        this.sceneCreator.zoomOut();
    }

    routeToSceneRendersPage = () => {
        window.location.href = "/scene_renders/" + this.state.collaboration_scene_id
    }

    setSpaceCamera = (space) => {
        this.setState({
            selected_area_preview: space
        });
        this.sceneCreator.setAreaCamera(space);
    }

    setSpaceCameraCallback = () => {
        this.setState({
            selected_area_preview: this.state.space_areas[0]
        });
        this.sceneCreator.setAreaCamera(this.state.space_areas[0]);
        this.setState({
            progress_loader_360: false
        });
    }

    uploadSceneThumbnail = (scene_id, blobData, callback = undefined) => {
        if (this.state.platform == 'google') {
            this.uploadSceneThumbnailtoGoogle(scene_id, blobData, callback = callback)
        } else {
            this.uploadSceneThumbnailtoAWS(scene_id, blobData, callback = callback)
        }
    }

    uploadSceneThumbnailtoAWS = (scene_id, blobData, callback = undefined) => {

        let buf = Buffer.from(blobData.replace(/^data:image\/\w+;base64,/, ""),'base64')

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });
            const S3 = new AWS.S3();
            const objParams = {
                Bucket: BUCKET_NAME,
                Key: "scene_renders/thumbnail/" + scene_id + ".jpg",
                ACL: 'public-read',
                Body: buf,
                ContentEncoding: 'base64',
                ContentType: "image/jpeg",
                CacheControl: 'no-cache'
            };


            S3.upload(objParams, (err, data) => {

                if (callback != undefined){
                    if(!err) {
                        callback(true);
                    }
                    else{
                        callback(false);
                    }
                }
            });
        });
    }

    isSignedURLvalid = (url) => {
        if (url == '')
            return false;
        const urlSearchParams = new URLSearchParams(new URL(url).search);
        const expirationTimeString = urlSearchParams.get('X-Goog-Expires');
        if (!expirationTimeString) {
          console.error('Invalid signed URL: No expiration time found.');
          return false;
        }
        const expirationTime = new Date(parseInt(expirationTimeString) * 1000);
        const currentTime = new Date();
        return currentTime > expirationTime;
      }

    uploadSceneThumbnailtoGoogle = (scene_id, blobData, callback = undefined) => {
        let buf = Buffer.from(blobData.replace(/^data:image\/\w+;base64,/, ""),'base64')
        let thumbnail_path = "scene_renders/thumbnail/" + scene_id + ".jpg";

        console.log(this.isSignedURLvalid(this.state.thumbnail_signed_url))
        if (this.isSignedURLvalid(this.state.thumbnail_signed_url)) {
            let signedUrl = this.state.thumbnail_signed_url;
            const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response);
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': thumbnail_path
                    })
                    if (callback != undefined){
                        callback(true);
                    }
                  } else {
                    if (callback != undefined){
                        callback(false);
                    }
                  }
              };
        
              xhr.send(buf);
        }
        else
        {
            axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                'action': 'get_asigned_urls',
                'file_uri': thumbnail_path
            })
              .then(res => {
                  let signedUrl = res.data;
                  this.setState({
                    thumbnail_signed_url: signedUrl
                    })
                  const xhr = new XMLHttpRequest();
                  xhr.open('PUT', signedUrl, true); 
            
                  xhr.onload = function() {
                      console.log(xhr)
                      if (xhr.status === 200) {
                        console.log(xhr);
                        console.log("SEND FINISHED", xhr.response);
                        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                          'action': 'make_file_public',
                          'file_uri': thumbnail_path
                        })
                        if (callback != undefined){
                            callback(true);
                        }
                      } else {
                        if (callback != undefined){
                            callback(false);
                        }
                      }
                  };
            
                  xhr.send(buf);
              });
        }
    }

    saveScene =  (showMessage = false) => {

        var scene_json = this.sceneCreator.getSceneInfoJSON();
        var scene_data = JSON.parse(scene_json)
        delete scene_data['hotspots']
        delete scene_data['room_id']
        delete scene_data['room_name']

        let placement_cameras = [];

        for(let camera of scene_data['cameras']) {
            if(camera.camera_name == this.state.collaboration_save_camera ) {
                placement_cameras.push(camera)
            }
        }
        scene_data['cameras'] = placement_cameras
        console.log("Scene to be saved data", scene_data)
        
        let payload = {
            'collaboration_id': this.state.collaboration_id,
            'design': scene_data
        }

        axios.post(ENVIRONMENT.UPDATE_COLLABORATE_DESIGN, payload)
            .then(res => {
                if (showMessage) {
                    message.info('Scene Saved successfully');
                }
                delete scene_data['cameras'];
                this.updateSceneDesignFromCollab(scene_data)
            })
            .catch(err => {
                message.error('Error in saving scene');
            });
    }

    updateSceneDesignFromCollab = (collab_design) => {
        let payload = {
            'scene_id': this.state.collaboration_scene_id,
            'username': localStorage.getItem('username'),
            'collaboration_design': collab_design,
            'collaboration_id': this.state.collaboration_id
        }
        axios.post(ENVIRONMENT.COLLABORATION_DESIGN_PROPAGATE, payload)
            .then(res => {
                
            })
            .catch(err => {
                message.error('Error in updating master scene');
            });
    }

    exitScene = () => {
        let url = "/collaborate-tool/" + this.state.collaboration_id
        if (this.state.project_ids) {
            url = `${url}?project_id=${this.state.project_ids[0]}`
        }
        window.location.href = url
    }

    checkIfRenderedAlready = (final_renders, camera_name) => {
        let renderedAlready = false;

        for(let final_render of final_renders) {
            if( final_render.filename == (camera_name + '.jpg') ||
            final_render.filename == (camera_name + '.png') ||
            final_render.filename == (camera_name + '.tiff')){
                renderedAlready = true;
            }
        }

        return renderedAlready;

    }

    sendRenderRequest = (camera_name, tour_request = false, isPdfUpload = false ) => {
        var final_renders = [];
        var three_sixties = [];
        var requested_render_categories = [];
        var scene_payload = {"scene_id" : this.state.collaboration_scene_id};
        axios.post(ENVIRONMENT.GET_SCENE_DETAIL, scene_payload).then(res => {
            var final_renders_string = res.data['final_renders'];
            var scene_original_design = res.data['design']
            if (final_renders_string != "")
            {
                var final_renders_obj = JSON.parse(final_renders_string);
                if(final_renders_obj.final_renders != undefined) {
                    final_renders = final_renders_obj.final_renders;
                }
            }
            console.log("Final Renders &", final_renders);

            var three_sixties_string = res.data['three_sixties'];
            if (three_sixties_string != "")
            {
                var three_sixties_obj = JSON.parse(three_sixties_string);
                if(three_sixties_obj.three_sixties != undefined) {
                    three_sixties = three_sixties_obj.three_sixties;
                }
            }

            var scene_id = this.state.collaboration_scene_id;
            var scene_json = scene_original_design;
            var scene_data = JSON.parse(scene_json);
            scene_data = scene_data['design']
    
            scene_data['scene_id'] = scene_id;
            scene_data['room_max_archive'] = this.state.room_max_archive;
            scene_data['username'] = localStorage.getItem('username');

            var selected_categories = [];
            
            let camera = this.sceneCreator.getCameraInfoJSON( camera_name )
            let height = camera['image_height']
            let width = camera['image_width']
            let camera_res = width.toString() + 'x' + height.toString();
            camera['scene_state_index'] = -1;
            camera['price_category'] = this.returnPrice(camera_res,'scene_lifestyle_image','category');
            camera['selected'] = true;
            camera['scene_config_id'] = this.state.current_placement_id.toString()
            requested_render_categories.push(camera['price_category'])
            let new_display_name = ''
            for (let final_render of final_renders) {
                if (final_render.filename == this.state.collaboration_original_camera) {
                    final_render["status"] = "hidden"
                    if (final_render.display_name) {
                        new_display_name = final_render.display_name
                    }
                    else {
                        let camera_display_name = final_render.filename.split(".")[0]
                        new_display_name = 'Untitled ' + camera_display_name.split("_")[1];
                    }
                    
                }
            }

            final_renders.push({
                filename: camera.camera_name + '.' + camera.image_format,
                display_name: new_display_name,
                image_width: camera.image_width,
                image_height: camera.image_height,
                type: camera.camera_type,
                image_dpi: camera.image_dpi,
                captured_by: localStorage.getItem("username"),
                is_msprovider: FileConstants.isMSProvider,
                time_updated: Date.now(),
                preview_render: camera.camera_name + '_preview.png',
                status: 'pending',
                collaboration_id: this.state.collaboration_id,
                camera_mode: camera.camera_mode
            });

            selected_categories.push(camera.price_category);
            for(let scene_camera of scene_data['cameras']) {
                if (scene_camera['selected'] != undefined) {
                    scene_camera['selected'] = false
                }
            }
            scene_data['cameras'].push(camera)

            console.log(final_renders);
            console.log(three_sixties);
            console.log(JSON.stringify(scene_data))
            
            scene_data['generate_360'] = 0;
            scene_data['generate_still'] = 1;
            scene_data['generate_tour'] = 0;

            let payload = {
                "scene_id": scene_id,
                "username": localStorage.getItem('username'),
                "design": scene_data,
                "final_renders": final_renders,
                "three_sixties": three_sixties,
                "genertate_renders": 1,
            };

            if(this.state.scene_status == 0 || this.state.scene_status == 1) {
                payload['scene_status'] = 4;
            }

            let scene_asset = 'scene_lifestyle_image';
           
            this.setState({
                request_type: scene_asset
            });

            let requestPayload = payload;
            requestPayload['username'] = localStorage.getItem('username');
            requestPayload['update_thumbnail'] = false
            let gateway_payload = {
                "username": localStorage.getItem('username'),
                "request_type": scene_asset,
                "action": "perform",
                "categories": selected_categories,
                "request_payload": requestPayload,
                "request_id": Date.now().toString()
            }

            console.log(JSON.stringify(gateway_payload),"request payload");
            this.setState({
                scene_payload: gateway_payload,
                scene_tour: tour_request,
                new_final_renders: final_renders,
                requested_render_categories: requested_render_categories
            }, () => {
                if (this.state.request_status == "allowed" || this.state.request_status == "always_allowed") {
                    this.processSceneRequest(gateway_payload, final_renders, tour_request, isPdfUpload);
                }
                if (this.state.request_status == "payment_required") {
                    if (this.state.card_status == 1) {
                        this.processSceneRequest(gateway_payload, final_renders, tour_request, isPdfUpload);
                    }
                    if (this.state.card_status == 0) {
                        window.addEventListener('card_saved', (e) => {
                            let action = e.detail.action;
                            this.setState({
                                card_save_flow_triggered: false
                            });
                            if (action == 'scene_lifestyle_image') {
                                this.processSceneRequest(gateway_payload, final_renders, tour_request, isPdfUpload);
                            }

                        }, false);

                        let child_window = window.open('/save-card?action=scene_lifestyle_image', '', "width=600,height=600");
                        let refreshInterval = setInterval(() => {
                        if(child_window.closed) {
                            this.setState({
                                card_save_flow_triggered: false,
                                confirm_image_loader: false,
                                threed_loader: false,
                                confirm_360_loader: false
                            });
                            clearInterval(refreshInterval);
                        }
                        }, 500);
                        this.setState({
                            card_save_flow_triggered: true
                        });
                    }

                }
                if (this.state.request_status == "not_allowed") {
                    this.setInAppLimitExceededModal(true);
                }
            })
        });
    }

    submitRenderRequest = async ( e = null, tour_request = false) => {
        const isPdfUpload = true;
        if(this.state.request_status != 'always_allowed'){
            this.setState({
                in_app_limit_exceeded_modal : true
            })
        }
        else{
            let camera_name = this.state.collab_camera_prefix + this.state.camera_index
            this.setState({
                confirm_360_loader: true,
                confirm_3d_loader: true,
                confirm_image_loader: true,
                enable_generate_lifestyle : false,
                collaboration_save_camera: camera_name
            }, 
            ()=> {
                this.handleSavingPlacementInfo(camera_name, () => {
                    this.saveScene();
                    this.sendRenderRequest(camera_name, tour_request, isPdfUpload)
                });
            })

        }
    }

    upgradeSubscriptionPlan = () => {
        if (this.state.card_status == 1) {
            this.upgradeSubscriptionPlanPerform();
        }
        if (this.state.card_status == 0) {
            window.addEventListener('card_saved', (e) => {
                let action = e.detail.action;
                this.setState({
                    card_save_flow_triggered: false
                });
                if (action == 'subscription_update') {
                    this.upgradeSubscriptionPlanPerform();
                }
            }, false);
            let child_window = window.open('/save-card?action=subscription_update', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
            if(child_window.closed) {
                this.setState({
                    card_save_flow_triggered: false,
                });
                clearInterval(refreshInterval);
            }
            }, 500);
            this.setState({
                card_save_flow_triggered: true
            });
        }
    }

    upgradeSubscriptionPlanPerform = () => {
        this.setState({
            subscription_loader: true
        })

        let payload ={
            username: localStorage.getItem('username'),
            subscription_type: this.state.subscription_plan
        }
        console.log('Subscription payload',payload);
        axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
        .then( (response) => {
            console.log(response.data);
            this.setState({
                subscription_plan: false,
                subscription_modal: false
            })

            this.loadUserRequestStatus(this.state.requested_render_categories,this.state.request_type);
            message.success('Your subscription plan has been successfully upgraded.');
        })
          .catch((error) => {
          });

    }

    cancelSubscriptionModal = () => {
        this.setState({
            subscription_modal: false
        });
    }

    showSubscriptionModal = () => {
        this.setState({
            subscription_modal: true
        });
    }

    saveRequestIntermedietely = (callback = null) => {
        if (this.state.request_status =='payment_required' && this.state.card_status == 0) {
            this.setState({
                confirm_360_loader: true,
                confirm_3d_loader: true,
                confirm_image_loader: true
            });
        }

        console.log(JSON.stringify(this.state.scene_payload))
        let payload = {
            'request_type': this.state.request_type,
            'request_body': this.state.scene_payload,
            'processed': false
        }
        if (this.state.payment_request_id == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
                .then(res => {
                    console.log(res);
                    this.setState({
                        loader: false,
                    });


                    if (callback!= null)
                    {
                    callback(res.data[0].request_id,this.state.request_type);
                    }
                });
        } else {
            this.setState({
                loader: false,
            });
            if (callback!= null)
            {
                callback(this.state.payment_request_id,this.state.request_type);
            }
        }
    }

    processSceneRequest = (gateway_payload, final_renders, tour_request, isPdfUpload = false) => {

        this.setState({
            confirm_360_loader: true,
            confirm_3d_loader: true,
            confirm_image_loader: true,
            enable_generate_lifestyle : false
        });
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY,gateway_payload)
        .then(res => {
            if (res.data.status!=undefined && res.data.status == 1) {
                if(this.state.scene_view_state == 'images'){
                        this.setState({
                            submit_plain_visible: false,
                            submit_plain_done_visible: false,
                            submit_collaboration_render_done_visbile: true,
                            submit_collaboration_render_modal: false,
                            selected_categories:[]
                        });
            }
            else{
                if(tour_request) {
                    this.setState({
                        submit_tour_visible: false,
                        submit_tour_done_visible: true,
                    });
                } else {
                        this.setState({
                            submit_360_visible: false,
                            submit_360_done_visible: false,
                            submit_collaboration_render_done_visbile: true,
                            submit_collaboration_render_modal: false
                        });
                }
            }
            this.setState({
                confirm_360_loader: false,
                confirm_3d_loader: false,
                final_renders: final_renders
            });
            }
        else{
            if (this.state.request_status == "payment_required") {
                this.setPaymentFailureMessage(res.data.error_message);
                this.setPaymentFailureModal(true);
            } else if (this.state.request_status == "not_allowed") {
                this.setInAppLimitExceededModal(false);
            }
            else {
                message.error('Something went wrong, Please try again.');
            }
            this.setState({
                confirm_360_loader: false,
                confirm_3d_loader: false,
                enable_generate_lifestyle:true,
                threed_loader : false
            });
        }
        }).catch((error) => {
            message.error('Error in submitting request!');
            this.setState({
                confirm_360_loader: false,
                confirm_3d_loader: false,
                confirm_image_loader: false
            });

        });
    }

    redirectToHome = () => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        if(onboarding != undefined){
            window.location.href = '/home?step_no=15';
        }
        else{
            window.location.href = '/home';
        }
    }

    removeLoader = () => {
        this.setState({isLoading: false});
        if (this.sceneCreator != null){
            this.sceneCreator.snappingEnabled = false;
            this.resetFlatViewModeState();
            this.resetTopDownModeState(true);
            this.setState({
                room_img: this.sceneCreator.getSceneScreenShot()
            })
        }
    }

    setAddProductsLoader = (state) => {
        this.setState({isLoading: state, isUpdating: state});
    }

    showAddProductsError = () => {
        message.error("Error adding products to scene")
    }

    addProductsToScene = ( assetsList ) => {
        if(this.sceneCreator) {
            let assetsInfo = this.sceneCreator.parseAssetsPlacementInfo(assetsList, this.state.space_areas.length > 0 ? this.state.space_areas[0] : 'Default')
            this.updateProductPlatforms(assetsInfo.assetsPlatform)
            this.sceneCreator.addProductsToScene(assetsInfo.assetsPlacementAreas, 
                assetsInfo.assetsPlacementTypes,
                assetsInfo.assetsCategories, assetsInfo.assetsMaterialTypes, () => {
                this.setAddProductsLoader(false);
                this.saveScene();
            })
        }
    }

    swapProduct = ( assetDetails, callback ) => {
        //obtains the details of new asset that will replace selected asset
        //update it's platform, placement , category and material type information in scene creator
        //call Scene Creator three js functionality to perform swap
        window.productPlatform[assetDetails.id] = assetDetails.platform
        this.sceneCreator.updateProductPlatforms(window.productPlatform)
        let assetCategoryMaping = {}
        if (assetDetails.category) {
            assetCategoryMaping[assetDetails.id] = assetDetails.category
        }
        let assetMaterialTypeMapping = {}
        if (assetDetails.material_type) {
            assetMaterialTypeMapping[assetDetails.id] = assetDetails.material_type
        }
        let assetPlacementTypeMapping = {}
        if (assetDetails.placement_type) {
            assetPlacementTypeMapping[assetDetails.id] = assetDetails.placement_type 
        }
        this.removeProductFromCollection(this.state.selected_product_id, this.state.collection_id);
        this.sceneCreator.swapProductInScene(assetDetails.id, assetCategoryMaping, assetMaterialTypeMapping, assetPlacementTypeMapping, () => {
            callback()
            this.setSwapProductLoading(false)
        })
    }

    setSwapSpaceLoading = (state) => {
        this.setState({
            isLoading: state,
            swap_space_loading: state
        });
    }
    
    setSwapProductLoading = (state) => {
        this.setState({
            isLoading: state,
            swap_product_loading: state
        });
    }

    handleSwapSpace = (selectedSpace, isResetSwap, action) => {
        const roomUrl = ENVIRONMENT.getBaseURL(selectedSpace.platform) + ENVIRONMENT.ROOM_ASSET_GLB_URI + selectedSpace.room_id + ".glb";
        const roomName = selectedSpace.room_id;
        this.setupAndLoadScene(false);
        const spaceObj = {
            'name': roomName,
            'url': roomUrl
        }
        if (!isResetSwap) {
            this.setState({
                parent_platform: this.state.room_platform
            })
        }
        this.setupAndLoadScene(false)
        this.sceneCreator.swapSpace(spaceObj, isResetSwap, ()=>{
            this.fetchSpaceConfigurableStatus(roomUrl);
            this.setSwapSpaceLoading(false);
            action();
        });
    }

    updateProductPlatforms = (productPlatforms) => {
        for(let product in productPlatforms) {
            window.productPlatform[product] = productPlatforms[product]
        }
        this.sceneCreator.updateProductPlatforms(window.productPlatform)
    }

    updateCollectionProducts = (collectionProducts) => {
        this.setState({
            collection_products: collectionProducts
        })
    }

    launchSceneCreator(room_model_file, design, collection_list, spaceitemsList, product_cats, placementTypeList, materialType, product_platform) {

        let sceneJObj = {};
        let spaceURL = this.getSpaceURL(room_model_file)
        sceneJObj = {
            baseURL			: ASSET_GLB_URL,
            assetURL 		: spaceURL,
            sceneInfoStr 	: JSON.stringify(design),
            type 			: 'load',
            collectionItems : collection_list
        };

        console.log('Scene Obj',sceneJObj);
        let sceneAspect = 0.55;

        let duplicateButton = document.getElementById("duplicate-button");
        let deleteButton = document.getElementById("delete-button");
        let sizeButton = document.getElementById("size-button");
        let expandButton = document.getElementById("expand-button");
        let hoverImage = document.getElementById("hover-image");
        let freezeButton = document.getElementById("freeze-button");

        let initialEditModel = false;
        if(this.state.edit_mode == 'edit') {
            initialEditModel = true;
        }

        this.sceneCreator = new SceneCreator( sceneJObj, sceneAspect,
                                            this.removeLoader,
                                            this.setSliderPosition2D,
                                            this.setSliderValue,
                                            this.showDeleteConfirm,
                                            initialEditModel,
                                            spaceitemsList,
                                            product_cats,
                                            placementTypeList,
                                            materialType,
                                            product_platform,
                                            this.setLoadingProgressMessage,
                                            this.setValidBaseCategories,
                                            this.setRugDisclaimer,
                                            this.reset3DCameraHeight,
                                            this.setSpaceConfiguratorMenuState,
                                            this.setSpaceConfigurationOfSelection,
                                            this.setSpaceTypeOfSelection,
                                            this.setSpaceSelectionColorConfigurable,
                                            this.handleSwapProductClick,
                                            this.showProductSizeControls,
                                            this.showFreeModeState,
                                            this.updateCameraHeight,
                                            this.setArrowKeysDisclaimer
                                            );
        if (this.sceneCreator != null) {
            
            this.sceneCreator.setSelectedAssetUIButtons(duplicateButton, deleteButton, expandButton, sizeButton, freezeButton);
            this.sceneCreator.setHoverImage(hoverImage);
        }

        this.sceneCreator.disablePreviewMode();
        this.changeOrientation('Landscape');
        this.sceneCreator.setCustomAspect(1.6);
    }

    componentWillUnmount(){
        delete this.sceneCreator;
        this.sceneCreator = null;
    }

    checkIfCollabCameraInDesign = (camera_name, cameras) => {
        let collabCameraFound = false;
        for (let camera of cameras) {
            if ((camera.camera_name + "." + camera.image_format) == camera_name) {
                collabCameraFound = true
            }
        }
        return collabCameraFound;
    }

    findLatestCameraInCollabDesign = (cameras) => {
        if (cameras.length > 0) {
            let latestCamera = cameras[cameras.length - 1]
            return latestCamera.camera_name + "." + latestCamera.image_format
        }
        return null;
    }

    getCollaboration = (onComplete, onFailed) => {

        let payload = {
            'collaboration_id': this.props.match.params.id
        }
        axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('Collaboration details: ', res)
                if (res.data) {
                    let collaboration_id = res.data.collaboration_entity_id;
                    let render_name = res.data.camera_name;
                    let scene_id = res.data.scene_id;
                    let platform = res.data.platform;
                    let base_url = ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI
                    let url = base_url + scene_id + '/' + render_name;
                    let design = {
                        "design": res.data.design
                    }
                    let finalCamera = render_name;
                    if (!this.checkIfCollabCameraInDesign(render_name, design["design"]["cameras"])) {
                        finalCamera = this.findLatestCameraInCollabDesign(design["design"]["cameras"])
                    }
                    if (finalCamera != null) {
                        this.setState({
                            collaboration_id: collaboration_id,
                            collaboration_scene_id: scene_id,
                            collaboration_original_camera: render_name,
                            collaboration_active_camera: finalCamera,
                            collaboration_design: design,
                            collaboration_image_url: url,
                            collaboration_platform: platform,
                            collaboration_state: res.data.state,
                        }, ()=> {
                            onComplete()
                        })
                    }
                    else {
                        if (onFailed) {
                            onFailed();
                        }
                    }
                    
                }
            })
            .catch(err => {
                console.log('err', err);
                if (onFailed) {
                    onFailed();
                }
            });

    }

    getSpaceURL = (roomURL) => {
        let spaceURL = roomURL
        try {
            let spaceName = this.getSpaceName(roomURL)
            if (spaceName != null) {
                spaceURL = ENVIRONMENT.getBaseURL(this.state.room_platform) + ENVIRONMENT.ROOM_ASSET_GLB_URI + spaceName + ".glb"
            }
        }
        catch (err) {
            console.log("Error parsing space url", err)
        }
        return spaceURL
    }

    getSpaceName = (roomURL) => {
        try {
            const roomID = roomURL.split(/[#?]/)[0].split('/').pop().split('.glb')[0];
            return roomID
        }
        catch (err) {
            return null
        }
    }

    fetchSpaceConfigurableStatus = (roomURL) => {
        try {
            const roomID = this.getSpaceName(roomURL)
            let payload = {
                room_id: String(roomID)
            }
            axios.post(ENVIRONMENT.FETCH_ROOM, payload)
            .then(res => {
                if (res && res.data) {
                    this.setState({room_parent: res.data.variant_of, room_id: String(roomID)})
                    if (res.data.is_room_configurable) {
                        this.setState({is_space_configurable: true})
                        this.fetchMaterialsFromCustomerLibrary();
                        this.fetchSpaceMaterialsFromCustomerLibrary();
                    }
                }
            });
        }
        catch (e) {
            console.log("error fetching space configurable status")
        }

    }

    fetchMaterialsFromCustomerLibrary = () => {

        let payload = {
            status: "5",
            username: CUSTOMER_USERNAME
        }
        let shared_entities = SHARED_ENTITIES;
        let company_id = COMPANY_ID;
        if (shared_entities != undefined && company_id != undefined) {
            if (shared_entities.includes('material')) {
                payload.company_id = company_id
            }
        }
        axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, payload)
        .then(res => {
            if (res && res.data && res.data.body) {
                let parsedData = JSON.parse(res.data.body);
                this.parseFetchedMaterialInfo(parsedData);
            }
        });
    }

    fetchSpaceMaterialsFromCustomerLibrary = () => {

        let payload = {
            username: CUSTOMER_USERNAME,
            space_material_ids: DEFAULT_SPACE_MATERIALS
        }
        let shared_entities = SHARED_ENTITIES;
        let company_id = COMPANY_ID;
        if (shared_entities != undefined && company_id != undefined) {
            if (shared_entities.includes('material')) {
                payload.company_id = company_id
            }
        }
        axios.post(ENVIRONMENT.SPACE_MATERIAL_GET_BATCH, payload)
        .then(res => {
            let parsedData = JSON.parse(res.data.body);
            this.parseFetchedSpaceMaterialInfo(parsedData);
        })
    }

    checkIfSwatchExists = (renders) => {
        if (renders && renders.length > 0) {
            let swatchList = renders.filter ( render => render.includes('Swatch'));
            if (swatchList.length > 0) {
                return true
            }
        }
        return false;
    }

    parseFetchedMaterialInfo = (materialInfo) => {
        let parsedMaterialInfo = []
        materialInfo.map((material,index) => {
            if (material.status == 5 && material.renders && material.renders.data && (this.checkIfSwatchExists(material.renders.data.artist_renders) ||
                this.checkIfSwatchExists(material.renders.data.QA_renders))) {
                let materialData = {};
                materialData["id"] = material.id;
                materialData["name"] = material.name;
                materialData["type"] = "material";
                let swatch ="Swatch" + FileConstants.MATERIAL_TYPES_RENDERS_EXT[material.material_output] + ".jpg"
                materialData["baseURL"] = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/'
                materialData["swatch"] = swatch;
                parsedMaterialInfo.push(materialData);
            }
        })
        let scope = this;
        this.setState({
            material_info: scope.state.material_info.concat(parsedMaterialInfo)
        })
    }

    parseSpaceMaterialInfo = (material) => {
        if (material && material.material_files && material.material_files.data
            && material.material_files.data.swatch) {
            let materialData = {};
            materialData["id"] = material.id;
            materialData["name"] = material.name;
            materialData["type"] = "texture";
            let swatch = material.material_files.data.swatch;
            materialData["baseURL"] = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.SPACE_MATERIAL_FILE_URI + material.id + '/'
            materialData["swatch"] = swatch;
            return materialData;
        }
        return null;
    }

    parseFetchedSpaceMaterialInfo = (materialInfo) => {

        let parsedMaterialInfo = []
        materialInfo.map((material,index) => {
            let materialData = this.parseSpaceMaterialInfo(material);
            if (materialData) {
                parsedMaterialInfo.push(materialData);
            }
        })
        let scope = this;
        this.setState({
            material_info: scope.state.material_info.concat(parsedMaterialInfo)
        })
    }

    setValidBaseCategories = () => {
        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });

        var scope = this;
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });

            let S3 = new AWS.S3();
            S3.getObject({
                Bucket: BUCKET_NAME,
                Key: CONFIG_PRODUCT_FILE_KEY
            }, function(err, data) {
                if (err) {
                    console.log(err, err.stack);
                } else {
                    let configJSON = data.Body.toString().trim(); // Trim for removing white spaces
                    try {
                        configJSON = JSON.parse(configJSON);
                        if(typeof configJSON === 'object' && configJSON !== null) {
                            if ('valid_base_items' in configJSON) {
                                scope.sceneCreator.setValidBaseItems(configJSON['valid_base_items'])
                            }
                        }

                    } catch (error) {
                    }
                }

            }.bind(this));
        });
    }

    initialPaymentPopups = () => {
        let payment_redirected = new URLSearchParams(window.location.search).get("payment_redirected");
        let request_type = new URLSearchParams(window.location.search).get("request_type");
        let payment_failure = new URLSearchParams(window.location.search).get("payment_failure");
        let request_id_payment = new URLSearchParams(window.location.search).get("request_id");
        this.setState({
            request_type: request_type
        });
        if (payment_redirected) {
            // if (request_type == 'scene_lifestyle_image') {
            //     this.setState({
            //         submit_plain_done_visible: true,
            //     });
            // } else if (request_type == 'scene_lifestyle_360') {
            //     this.setState({
            //         submit_360_done_visible: true,
            //     });
            // } else if (request_type == 'scene_lifestyle_3dtour') {
            //     this.setState({
            //         submit_tour_done_visible: true,
            //     });
            // }
        }
        if (payment_failure) {
            // this.setState({
            //     card_save_failure: true
            // })
            if (request_id_payment) {
                this.setState({
                    payment_request_id: request_id_payment,
                    card_save_drawer: true
                })
            }
        }
    }

    checkIfNewUser = () => {
        let user_visits = Utilities.getCookie("user_scene_visits")
        console.log('user', user_visits)
        if (user_visits && parseInt(user_visits) < 1) {
            Utilities.setCookie("user_scene_visits",parseInt(user_visits) + 1, 365)
            this.setState({
                is_tour_open: true
            })
        } else if (!user_visits) {
            Utilities.setCookie("user_scene_visits",0, 365)
            this.setState({
                is_tour_open: true
            })
        }
    }

    fetchCollectionProducts = (onLoadAction) => {
        let payload = {collection_id: this.state.collection_id}
        axios.post(ENVIRONMENT.FETCH_COLLECTION_PRODUCTS, payload)
        .then(res => {
            if (res && res.data) {
                let products = []
                res.data.map((product) => {
                    products.push(product.id.toString())
                })
                onLoadAction(products);
            }
        })
    }

    setupAndLoadScene = (launchScene = true) => {
        var scene_original_design = "";
        const collaboration_design = this.state.collaboration_design;
        var room_model_file = "";
        var final_renders = [];
        var payload = {"scene_id" : this.state.collaboration_scene_id};
        let project_id = new URLSearchParams(window.location.search).get("project_id");
        if(project_id != undefined)
        {
            this.setState({
                project_ids: [project_id]
            })
        }

        // clear the camera state on space swap
        if (!launchScene) {
            this.setState({
                cameras: [],
                camera_index: 1,
                camera_obj: [],
            });
        }

        // here get the collaboration design information
        axios.post(ENVIRONMENT.GET_SCENE_DETAIL, payload)
            .then(res => {
                console.log('scene design',res.data)
                this.setState({
                    platform: res.data.platform,
                    room_platform: res.data.room_platform,
                    collection_id: res.data['collection_id']
                });
                if (!project_id && res.data['project_ids']?.length > 0) {
                    this.setState({
                        project_ids: res.data['project_ids']
                    })
                }
                scene_original_design = JSON.parse(res.data['design'])
                if (res.data['scene_template_id']) {
                    this.setState({
                        scene_template_id: res.data['scene_template_id']
                    })
                }
                if (res.data['scene_template_id'] && res.data['template_collection_id']) {
                    this.checkCollectionProducts(res.data['collection_id'], res.data['template_collection_id']);
                }

                var final_renders_string = res.data['final_renders'];
                if (final_renders_string)
                {
                    console.log(final_renders_string)
                    var final_renders_obj = JSON.parse(final_renders_string);
                    if(final_renders_obj.final_renders != undefined) {
                        final_renders = final_renders_obj.final_renders;
                        this.setLatestFinalRenderIndex(final_renders)
                    }
                }

                console.log("Final Renders &", final_renders);

                this.setState({
                    scene_status: res.data['scene_status'],
                    room_max_archive: res.data['room_max_archive'],
                    scene_name: res.data['name'],
                    final_renders: final_renders
                });

                room_model_file = res.data['room_model_file'];
                console.log(res.data['room_model_file'])

                var collection_id = {"collection_id" : res.data['collection_id'].toString()};
                this.getCollectionProducts(collection_id);
                var apiEndpoint = ENVIRONMENT.FETCH_COLLECTION_PRODUCTS;
                var spaceAreas = res.data["space_areas"];
                if(spaceAreas.length > 0){
                    apiEndpoint = ENVIRONMENT.FETCH_COLLECTION_SPACE_ALLOCATION;
                } 

                if (spaceAreas.length == 0) {
                    this.setState({
                        space_areas: ['Default']
                    })
                }

                axios.post(apiEndpoint, collection_id)
                .then(res => {

                    let collectionInfo = this.parseCollectionInfo(spaceAreas, res.data)

                    this.setState({
                        menu_products: collectionInfo.idsList,
                    });

                    this.addCollaborationCamera(collaboration_design['design']['cameras'])
                    this.setLatestCameraIndex(scene_original_design['design']['cameras'])
                    if (launchScene) {
                        this.setDeletedAssets(collaboration_design['design']['assets'])
                        this.launchSceneCreator(room_model_file, collaboration_design['design'], collectionInfo.idsList, collectionInfo.spaceItemsList, collectionInfo.productCategories, collectionInfo.placementTypeList, collectionInfo.materialType, collectionInfo.productPlatform)
                    }
                    this.fetchSpaceConfigurableStatus(room_model_file)
                })
            })
    }

    parseCollectionInfo(spaceAreas, collection_data){
        let collectionProducts = []
        let spaceItemsList = {};
        let productCategories = {};
        let placementTypeList = {};
        let materialType = {};
        let productPlatform = {};
        let collectionInfo = {}
        let idsList = [];

        if (spaceAreas.length == 0) {
            spaceItemsList['Default'] = [];
            for(var i=0;i<collection_data.length;i++){
                if(collection_data[i]['id'] != null)
                {
                    var item_data = {}
                    item_data['id'] = collection_data[i]['id'].toString();
                    item_data['name'] = collection_data[i]['id'].toString();
                    item_data['glb'] = ENVIRONMENT.getBaseURL(collection_data[i]['platform']) + ENVIRONMENT.ASSET_GLB_URI + collection_data[i]['id'] + ".glb";
                    idsList.push(item_data);
                    spaceItemsList['Default'].push(collection_data[i]['id'].toString());
                    productCategories[item_data['id']] = collection_data[i]['category'];
                    placementTypeList[item_data['id']] = collection_data[i]['placement_type'];
                    productPlatform[item_data['id']] = collection_data[i]['platform'];
                    materialType[item_data['id']] = collection_data[i]['material_type'];
                    collectionProducts.push(collection_data[i]);
                    window.productPlatform[item_data['id']] = collection_data[i]['platform'];
                }
            }
        }
        else{

            for (let space of spaceAreas){
                spaceItemsList[space] = []
                if(space in collection_data)
                {
                    for(var i=0;i<collection_data[space].length;i++){
                        if(collection_data[space][i]['id'] != null)
                        {
                            var item_data = {}
                            item_data['id'] = collection_data[space][i]['id'].toString()
                            item_data['name'] = collection_data[space][i]['id'].toString();
                            item_data['glb'] = ENVIRONMENT.getBaseURL(collection_data[space][i]['platform']) + ENVIRONMENT.ASSET_GLB_URI + collection_data[space][i]['id'] + ".glb";
                            idsList.push(item_data);
                            spaceItemsList[space].push(collection_data[space][i]['id'].toString());
                            productCategories[item_data['id']] = collection_data[space][i]['category'];
                            placementTypeList[item_data['id']] = collection_data[space][i]['placement_type'];
                            materialType[item_data['id']] = collection_data[space][i]['material_type'];
                            productPlatform[item_data['id']] = collection_data[space][i]['platform'];
                            collectionProducts.indexOf(collection_data[space][i]) === -1 ? collectionProducts.push(collection_data[space][i]):console.log('already exists');
                            window.productPlatform[item_data['id']] = collection_data[space][i]['platform'];
                        }
                    }
                }
            }

            let default_resolutions = {}
            for(let space_area of spaceAreas){
                default_resolutions[space_area] = '2k';
            }

            this.setState({
                space_areas: spaceAreas,
                selected_360s: [],
                selected_360s_resolutions: default_resolutions
            });

        }

        collectionInfo['spaceItemsList'] = spaceItemsList;
        collectionInfo['productCategories'] = productCategories;
        collectionInfo['placementTypeList'] = placementTypeList;
        collectionInfo['materialType'] = materialType;
        collectionInfo['productPlatform'] = productPlatform;
        collectionInfo['collectionProducts'] = collectionProducts;
        collectionInfo['idsList'] = idsList;

        return collectionInfo;
    }

    setLatestFinalRenderIndex(final_renders){
        let render_preview = [];
        final_renders.map((final_render,index) => {
            let camera_name = final_render.filename.split(".");
            if (camera_name[0].toLowerCase().includes("collab")) {
                render_preview[camera_name[0]] = false;
                let camera_tokens = final_render.filename.split("_");
                if(camera_tokens[1] != undefined){
                    let cameraIndex = parseInt(camera_tokens[1]);
                    if(cameraIndex >= this.state.camera_index){
                        this.setState({
                            camera_index: cameraIndex + 1
                        });
                    }
                }
            }
            
        });

        this.setState({
            preview_renders: render_preview
        }, () => {
            console.log("Preview Renders on Scene Load (false if render is generated) &", this.state.preview_renders);
        });
        
        console.log("Final Renders &", final_renders);

        console.log("Camera index", this.state.camera_index)
    }

    setLatestCameraIndex(camerasList){
        camerasList.forEach( ( cameraObj ) => {
            if(cameraObj.camera_type == "still" && cameraObj['camera_name'].toLowerCase().includes("collab")){
                let cameraToks = cameraObj['camera_name'].split("_");
                if(cameraToks[1] != undefined){
                    let cameraIndex = parseInt(cameraToks[1]);
                    if(cameraIndex >= this.state.camera_index){
                        this.setState({
                            camera_index: cameraIndex + 1
                        });
                    }
                }
        }
            
        } );

        console.log("Camera index updated", this.state.camera_index)
    }

    addCollaborationCamera(camerasList) {
        camerasList.forEach( ( cameraObj ) => {
            if(cameraObj.camera_type == "still"){
                let camera_render_file_name = cameraObj['camera_name'] + "." + cameraObj['image_format'];
                if (camera_render_file_name == this.state.collaboration_active_camera) {
                    this.setState({
                        collaboration_save_camera: cameraObj['camera_name']
                    })
                    let camera_render = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.state.collaboration_scene_id + '/' + cameraObj['camera_name'] + "_preview.png";
                    let camera_mode = CAMERA_MODES.ThreeD;
                    if (cameraObj['camera_mode'] != null) {
                        camera_mode = cameraObj['camera_mode'];
                    }
                    this.addCamera(cameraObj['camera_name'], '', cameraObj.image_width, cameraObj.image_height, cameraObj['clipping_value'], camera_mode, camera_render );
                }
            }
            } );
    }

    setDeletedAssets(assetsList) {
        let deleted_asset_ids = [];
        assetsList.forEach( ( assetObj ) => {
            if (assetObj['asset_visible'] != undefined) {
                if (assetObj['asset_visible'] == false) {
                    if (!deleted_asset_ids.includes(assetObj['asset_name'])) {
                        deleted_asset_ids.push(assetObj['asset_name'])
                    }
                }
            }
        });

        this.setState({
            deleted_asset_ids: deleted_asset_ids
        })
        
    }

    componentDidMount(){
        this.initialPaymentPopups();
        this.loadUserRequestStatus(null,null,null);
        this.getCollaboration(this.setupAndLoadScene, this.onCollabSetupFailed);
        this.getCommentHistory();
        document.addEventListener("fullscreenchange", this.handleFullscreenChange, false);
        document.addEventListener("keydown", this.escFunction, false);
        document.addEventListener("resize", this.SetOverlayLines())

    }

    onCollabSetupFailed = () => {
        this.setState({
            isLoading : true,
            loading_progress_message : "Loading Failed!"
        })
    }

    componentDidUpdate(){
        if(!this.is3DModeActive() && this.state.grid_controls_view==1){
            this.disableGridForSnapshot()
        }
        var limit = this.state.overlay_limit;
        if(this.state.overlay_height>this.state.overlay_limit){
            this.setState({
                overlay_height: limit,
            },() => this.SetOverlayLines())
        }
        if(this.state.overlay_width>this.state.overlay_limit){
            this.setState({
                overlay_width: limit,
            },() => this.SetOverlayLines())
        }
    }

    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.handleFullscreenChange, false);
        document.removeEventListener("keydown", this.escFunction, false);
    }

    setupJoyStick = () =>{
        if(this.sunControls == null){
            let parameters  = {
                "internalFillColor" : "#f9d71c",
                "externalStrokeColor": "#003300",
                "autoReturnToCenter": false
            };
            this.sunControls = new window.JoyStick('joyDiv', parameters, this.changeSunPosition);
        }

    }

    addCamera = (camera_name, img_data,width,height, clipping_value, camera_mode, camera_render = '') => {
        const camera_obj = this.setCamera(camera_name, img_data,width,height, clipping_value, camera_mode, camera_render)
        const {cameras} = this.state;
        this.setState({
            cameras: cameras.concat(camera_obj),
        })
    }

    setCamera = (camera_name, img_data,width,height, clipping_value, camera_mode, camera_render = '') => {
        let resolution = Math.round(width) + 'x' + Math.round(height);
        let camera_obj = {
            name: camera_name,
            thumbnail: img_data,
            camera_render: camera_render,
            resolution: resolution,
            clipping_value : clipping_value,
            camera_mode: camera_mode
        };
        return camera_obj
    }

    placeCustomCamera = (image_format, image_width, image_height, dpi, clipping_value, camera_mode) => {
        var img_data = this.sceneCreator.getSceneScreenShot();
        let camera_index = this.state.camera_index;
        var camera_name = this.state.collab_camera_prefix + camera_index;
        this.sceneCreator.removeCameraByName(camera_name)
        if (camera_mode == CAMERA_MODES.ThreeD) {
            this.sceneCreator.setUpCameraFromHelper(camera_name, camera_mode, image_format, image_width, image_height, dpi, clipping_value);
        }
        else if (camera_mode == CAMERA_MODES.TopDown) {
            this.sceneCreator.setUpCameraFromTopDown(camera_name, camera_mode, image_format, image_width, image_height, dpi, clipping_value);
        }
        let new_camera = this.setCamera(camera_name,img_data ,image_width,image_height, clipping_value, camera_mode);
        this.setState ({
            update_camera: camera_name,
        });

        this.uploadPreviewImagetoCloud(camera_name, img_data);

        return new_camera;
    }

    uploadPreviewImagetoCloud = (camera_name, img_data) => {
        if (this.state.platform == 'google') {
            this.uploadPreviewImagetoGoogle(camera_name, img_data)
        } else {
            this.uploadPreviewImagetoAWS(camera_name, img_data)
        }
    }

    uploadPreviewImagetoAWS = (camera_name, img_data) => {
        let buf = Buffer.from(img_data.replace(/^data:image\/\w+;base64,/, ""),'base64')
        let camera_render_upload_path = "scene_renders/" + this.state.collaboration_scene_id + '/' + camera_name + "_preview.png";

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });

            const S3 = new AWS.S3();
            const objParams = {
                Bucket: BUCKET_NAME,
                Key: camera_render_upload_path,
                ACL: 'public-read',
                Body: buf,
                ContentEncoding: 'base64',
                ContentType: "image/png",
                CacheControl: 'no-cache'
            };

            S3.upload(objParams, (err, data) => {
                if(!err) {
                    console.log("Render preview uploaded successfully");
                }
                else{
                    console.log("Could not upload render preview");
                }
            });
        });

    }

    uploadPreviewImagetoGoogle = (camera_name, img_data) => {
        let buf = Buffer.from(img_data.replace(/^data:image\/\w+;base64,/, ""),'base64')
        let camera_render_upload_path = "scene_renders/" + this.state.collaboration_scene_id + '/' + camera_name + "_preview.png";

        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': camera_render_upload_path
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response);
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': camera_render_upload_path
                    })
                  }
              };
        
              xhr.send(buf);
          });
    }


    changeCameraControls = (camera_name, camera_mode) => {
        if (camera_mode == CAMERA_MODES.TopDown) {
            this.setState ({
                update_camera: camera_name
            });
            this.sceneCreator.updateTopDownFromCustomCamera(camera_name);
            this.changeCameraToTopDown();
        }
        else if (camera_mode == CAMERA_MODES.ThreeD) {
            this.setState ({
                update_camera: camera_name
            });
            this.sceneCreator.updateHelperFromCustomCamera(camera_name);
            this.changeCameraTo3D();
        }
        let width = 0;
        let height = 0;
        let ratio = 0;
        let type = '';
        if (this.state.cameras != undefined && this.state.cameras.length > 0) {
            this.state.cameras.map((camera) => {
                if (camera.name == camera_name) {
                    if (typeof camera.resolution === 'string' || camera.resolution instanceof String) {
                        width = camera.resolution.split('x')[0];
                        height = camera.resolution.substring(camera.resolution.indexOf('x') + 1);
                        ratio = parseFloat((width/height).toFixed(2));
                        let resForOrientation = width;
                        if (ratio == 0.56 || ratio == 0.63 || ratio == 0.52) {
                            resForOrientation = height;
                        }

                        if (ratio == 1.60 || ratio == 1.78 || ratio == 1.91) {
                            type = 'Landscape';
                            this.changeOrientation('Landscape');
                            this.setAspects(type,ratio);
                        }
                        else if (ratio == 0.56 || ratio == 0.63 || ratio == 0.52) {
                            type = 'Portrait';
                            this.changeOrientation('Portrait');
                            this.setAspects(type,ratio);
                        }
                        else if (ratio == 1) {
                            this.changeOrientation('Square');
                            this.setAspects(type,ratio);
                        }
                        else {
                            type = 'Custom';
                            this.changeOrientation('Custom');
                            this.setAspects(type,ratio);
                            this.sceneCreator.setCustomAspect(ratio);
                        }

                        if (resForOrientation == '4096') {
                            this.setState({
                                resolution_status: 1
                            });
                        } else if (resForOrientation == '2048') {
                            this.setState({
                                resolution_status: 2
                            });
                        } else if (resForOrientation == '1024') {
                            this.setState({
                                resolution_status: 3
                            });
                        } else {
                            this.setState({
                                resolution_status: 4
                            }, () => {
                                if (type == 'Portrait') {
                                    this.onImageHeightChange(parseFloat(resForOrientation));
                                }
                                else if (type == 'Square' || type == 'Landscape') {
                                    this.onImageWidthChange(parseFloat(resForOrientation));
                                }
                            });
                        }
                        this.changeAspectRatio(ratio,type, height,width);
                        this.setAspects(type,ratio);
                    }
                    if (camera.clipping_value != undefined){
                        this.setClipping(camera.clipping_value)
                        if (camera.clipping_value > FileConstants.cameraNearDefaultValue){
                            this.setState({
                                clipping: true
                            });
                        }
                        else{
                            this.setState({
                                clipping: false
                            });
                        }
                    }
                }

            })
        }

        if (camera_mode != CAMERA_MODES.FlatView) {
            this.setState({
                snapshot_clicked: true,
                camera_Focal: this.sceneCreator.getCameraFocal()
            })
        }
        this.onChangeCameraAngle(180 - this.sceneCreator.getCameraPolarAngle())
        this.SetOverlayLines()
    }

    toggleSnapshotTaken = () => {
        this.setState({
            snapshot_clicked: false
        })
    }

    toggleClipping = () => {
        let clip = this.state.clipping
        this.setState({
            clipping: !clip
        },()=>{
          if (!this.state.clipping && !this.isTopDownModeActive()){
            this.sceneCreator.resetCameraPositionToLastFloorPosition();
            document.getElementsByClassName('rs-handle')[0].style.backgroundImage = 'url("/img/camera_move.png")'
          }
          else{
            document.getElementsByClassName('rs-handle')[0].style.backgroundImage = 'url("/img/camera_move_clipped.png")'
          }
          this.sceneCreator.toggleClipping(this.state.clipping);
          this.setClipping(0);
        })
    }

    setClipping = (clipping_value) => {
        let value = clipping_value
        if ( clipping_value / 39.3701 < FileConstants.cameraNearDefaultValue ){
            value = 0
        }
        this.setState({
            clipping_value: value
        }, () => {
            if ( clipping_value / 39.3701 < FileConstants.cameraNearDefaultValue ){
                this.sceneCreator.setCameraNear(FileConstants.cameraNearDefaultValue)
            }
            else{
                this.sceneCreator.setCameraNear(clipping_value / 39.3701)
            }
        })
    }

    resetClipping = () => {
        this.setState({
            clipping: false,
        }, ()=> {
            this.setClipping(0);
        })
    }

    updateClippingFromCamera() {
        let clipping_value = this.sceneCreator.getCameraNear();
        this.setState({
            clipping: clipping_value > FileConstants.cameraNearDefaultValue ? true: false,
            clipping_value: clipping_value > FileConstants.cameraNearDefaultValue ? Math.ceil(clipping_value * 39.3701): 0
        })
    }

    
    // enable / disable the size controls menu
    showProductSizeControls = ( state ) => {
        this.setState({
            enableProductSizeControls: state
        }, () => {
            if (state) {
                this.updateProductSizeControls();
            }
            if(!this.state.isLoading){
                this.sceneCreator.showTransformMenu(state);
                this.setFreeMode("off");
            }
        })
    }

    // set custom values on the product size controls UI 
    setProductSizeControls = (length, height, depth) => {
        let productSize = {
            'length': length,
            'height': height,
            'depth': depth
        }
        this.setState({
            productSize: productSize
        })
    }

    // update the product size controls with the size of the currently selected product
    updateProductSizeControls = () => {
        let conversionToInches = 1 / 39.3701;
        let productSize = this.sceneCreator.getProductSize();
        this.setProductSizeControls(Math.floor(productSize.length / conversionToInches), 
        Math.floor(productSize.height/ conversionToInches), Math.floor(productSize.depth/ conversionToInches));
    }

    // set the selected product to custom size and also update the UI for size controls
    setProductSize = (length, height, depth) => {
        this.setProductSizeControls(length, height, depth);
        let conversionToInches = 1 / 39.3701;
        this.sceneCreator.setProductSize(length * conversionToInches, 
            height * conversionToInches, 
            depth * conversionToInches);
    }

    // reset the selected product to its original size and also update the UI for size controls 
    resetProductSize = () => {
        this.sceneCreator.resetProductSize();
        this.updateProductSizeControls();
    }

    changeCameraHeight = () => {
        let val = this.sceneCreator.getActualCameraHeight();
        let room_height = this.state.room_height
        val = Math.round(val * 3 * 39.37 * room_height)
        this.setState({
            camera_height: val,
        })
    }

    // 3D cameras
    changeCameraTo3D = () => {
        this.sceneCreator.switchToCameraByName('helper');
        this.setSliderValue(this.sceneCreator.getCameraPolarAngle())
        this.changeCameraHeight()
        this.setCameraHeight(this.sceneCreator.getActualCameraHeight())
        this.setState({
            room_height: this.sceneCreator.getSpaceHeight()
        })
        this.setState ({
            camera_mode: CAMERA_MODES.ThreeD
        });
    }

    // 2D cameras
    changeCameraToTopDown = () => {
        this.deactivateSpaceConfiguratorMode();
        this.sceneCreator.switchToCameraByName('topDown');
        this.setSliderValue(this.sceneCreator.getCameraPolarAngle());
        this.changeCameraHeight();
        this.setCameraHeight(this.sceneCreator.getActualCameraHeight());
        this.setState({
            room_height: this.sceneCreator.getSpaceHeight()
        }, () => {
            this.changeCameraHeight();
            this.setCameraHeight(this.sceneCreator.getActualCameraHeight());
        })
        this.setState ({
            camera_mode: CAMERA_MODES.TopDown
        });
        if (this.state.enable_space_configurator_mode) {
            this.setSpaceConfiguratorModeState(false);
        }
    }

    changeCameraToFlatView = () => {
        this.deactivateSpaceConfiguratorMode();
        this.sceneCreator.switchToCameraByName('topDownOrtho');
        this.setSliderValue(this.sceneCreator.getCameraPolarAngle());
        this.changeCameraHeight();
        this.setCameraHeight(this.sceneCreator.getActualCameraHeight());
        this.setState({
            room_height: this.sceneCreator.getSpaceHeight()
        })
        this.setState ({
            camera_mode: CAMERA_MODES.FlatView
        });
        if (this.state.enable_space_configurator_mode) {
            this.setSpaceConfiguratorModeState(false);
        }
    }

    resetTopDownModeState = (resetCamera = false) => {
        this.changeCameraToTopDown();
        if (resetCamera) {
            this.setCameraFOV("medium")
            this.sceneCreator.setAspectRatio(0.55); 
            this.sceneCreator.resetZoom();
            this.setFocalOfCamera(this.state.default_camera_Focal);
            this.resetClipping();
            this.detachSunControls(); 
            this.hideOverlayLines();
        }
        else {
            // images mode
            this.setCameraFOV("high");
            this.updateClippingFromCamera();
            this.setFocalOfCamera(this.state.camera_Focal);
            this.sceneCreator.setAspectRatio(0.55);
            this.setSliderPosition3D(150);
            this.SetOverlayLines(); 
            if(this.state.show_overlay_switch)
            {
                this.showOverlayLines();
            }
            this.setOverlayLimit();
        }
    }

    resetFlatViewModeState = () => {
        this.changeCameraToFlatView();
        this.resetClipping();
        this.sceneCreator.setAspectRatio(0.55); 
        this.sceneCreator.resetZoom();
        this.setSliderPosition3D(150);
        this.hideOverlayLines();
        this.setState({ sun_controls_view: 0, hide_camera: false });
        this.handleSunModeSwitch(false);
    }

    reset3DModeState = (resetCamera = false) => {
        this.changeCameraTo3D();
        if (resetCamera) {
            this.sceneCreator.setAspectRatio(0.55);
            this.sceneCreator.resetZoom();
            this.setCameraFOV("high");
        }
        else {
            this.setCameraFOV("medium");
        }
        this.updateClippingFromCamera();
        this.sceneCreator.setCameraFocal(this.state.camera_Focal)
        this.setState({hide_camera : false, sun_controls_view: 0},
            ()=>{
                this.SetOverlayLines(); 
                if(this.state.show_overlay_switch)
                {
                    this.showOverlayLines();
                }
                this.setOverlayLimit();
            }); 
        this.handleSunModeSwitch(false);
    }

    viewCameraControls = () => {
        if (this.state.sun_controls_view == 1) {
            this.setState({
                sun_controls_view: 0
            });
        }
        if (this.state.hide_camera){
            this.setState({
                hide_camera: false
            });
       }
        else if (this.state.hide_camera == false) {
            this.setState({
                hide_camera: true
            });
        }
        this.handleSunModeSwitch(false);
    }

    viewSunControls = () => {
        if (this.state.sun_controls_view == 0)
        {
            this.setState({
                sun_controls_view: 1
            }, this.setupJoyStick())
            if (this.state.hide_camera == false) {
                this.setState({
                    hide_camera: true
                });
            }
        }
        this.appendSunControls();
        this.handleSunModeSwitch(true);

    }

    appendSunControls = () => {
        if (this.sceneCreator.isCustomLighting()) {
            document.getElementById('joyDiv1').innerHTML = "The Space Model contains custom lighting so Sun Controls are disabled in the Scene Creator.";
            document.getElementById('joyDiv').style.display = "none";
            document.getElementById('joyDiv1').style.paddingTop = "70px";
        }
        else if (document.getElementById('joyDiv')) {
            document.getElementById('joyDiv').style.display = "block";
            document.getElementById('joyDiv1').appendChild(document.getElementById('joyDiv'));
            document.getElementById('joyDiv').style.paddingTop = "0px";
        }

    }

    detachSunControls = () => {
        this.handleSunModeSwitch(false);
        if (document.getElementById('joyDiv')) {
            document.getElementById('joyDiv').style.display = "none";
            document.getElementById('scene-creator').appendChild(document.getElementById('joyDiv'));
        }
    }

    changeSunPosition = (azimuthalAngle, polarAngle) => {
        this.setState({
            sun_azimuthal: azimuthalAngle,
            sun_polar: polarAngle
        });
        this.sceneCreator.sunControls.setAzimuthalAngle(azimuthalAngle);
        this.sceneCreator.sunControls.setPolarAngle(polarAngle);
    }

    handleShortcustsOk = e => {
        this.setState({
        shortcuts_visible: false,
        });
    };

    handleShortcustsCancel = e => {
        this.setState({
        shortcuts_visible: false,
        });
    };

    showShortcutsModel = () => {
        this.setState({
            shortcuts_visible: true,
        });
    }

    handle360Ok = e => {
        this.setState({
        submit_360_visible: false,
        });
    };

    handle360Cancel = e => {
        this.setState({
            submit_360_visible: false,
        });
    };

    handleCameraModalCancel = () => {
        this.setState({
            show_camera_modal: false
        });
    }

    rebuildCustomCamera = () => {
        let objCamera = null;
        let cams = null;
            this.state.space_areas.map((area)=>{
                objCamera = this.state.camera_obj[area];
                if (objCamera != undefined){
                    if (this.state.camera_hotspots[area] != undefined) {
                        cams = this.sceneCreator.set360Camera(objCamera.camera_name, objCamera.camera_position)
                        let camPoint = new THREE.Vector3();
                        camPoint.copy(this.state.camera_hotspots[area][0]);
                        cams.lookAt(camPoint);
                    }
                    else if (objCamera.camera_name == "Bath" || objCamera.camera_name == "Closet") {
                        cams = this.sceneCreator.set360Camera(objCamera.camera_name, objCamera.camera_position)
                        let camPoint = new THREE.Vector3();
                        camPoint.copy(objCamera.camera_position);
                        cams.lookAt(camPoint);
                    }
                    else {
                        if ((objCamera.camera_name == "Outdoor" || objCamera.camera_name == "Outside") && this.sceneCreator.isOutdoorCamera()){
                            cams = this.sceneCreator.set360Camera(objCamera.camera_name, objCamera.camera_position)
                            let camPoint = new THREE.Vector3();
                            camPoint.copy(objCamera.camera_position);
                            cams.lookAt(camPoint);
                        }
                    }
                }
            });

    }

    create360Flow = () => {
        this.setState({
            progress_loader_360: true
        });
        if (this.state.save_scene_flag && this.state.saved_scene_data != []){
            this.setState({
                camera_hotspots: this.state.saved_scene_data
            }, () => {
                if (this.state.camera_hotspots !== undefined && this.state.camera_hotspots !== null && this.state.camera_hotspots !== [] && this.state.camera_hotspots.length !== 0){
                    if (typeof (this.state.camera_hotspots) == "object" && Object.keys(this.state.camera_hotspots).length == 0) {
                    }
                    else if (typeof (this.state.camera_hotspots) == "object" && Object.keys(this.state.camera_hotspots).length != 0) {
                        if (this.sceneCreator != null){
                            this.sceneCreator.setCameraHotspot(this.state.camera_hotspots);
                        }
                    }
                    else if (typeof (this.state.camera_hotspots) !== "object"){
                        if (this.sceneCreator != null){
                            this.sceneCreator.setCameraHotspot(this.state.camera_hotspots);
                        }
                    }
                }

            });
        }
        setTimeout(() => {
            if ((this.state.camera_hotspots !== undefined && this.state.camera_hotspots !== null && this.state.camera_hotspots !== [] && this.state.camera_hotspots.length !== 0) && !this.sceneCreator.getSaveSceneEditFlag()){
                if (typeof (this.state.camera_hotspots) == "object" && Object.keys(this.state.camera_hotspots).length == 0) {
                }
                else if (typeof (this.state.camera_hotspots) == "object" && Object.keys(this.state.camera_hotspots).length != 0) {
                    this.rebuildCustomCamera();
                }
                else if (typeof (this.state.camera_hotspots) !== "object"){
                    this.rebuildCustomCamera();
                }
            }
            this.setState({
                scene_view_state: "create360",
                mode_360: 'reset',
                preview_true: false,
                reset_true: true,
            }, () => {
                this.sceneCreator.disablePreview();
                this.setSceneViewState("create360");
            });
        }, 0);

    }


    handleTourOk = e => {
        this.setState({
            submit_tour_visible: false,
        });
    };

    handleTourCancel = e => {
        this.setState({
            submit_tour_visible: false,
        });
    };

    createTourFlow = () => {
        this.setState({
            progress_loader_3d: true,
            tour_flag: true
        });
        if (this.state.save_scene_flag && this.state.saved_scene_data != []){
            this.setState({
                camera_hotspots: this.state.saved_scene_data
            }, () => {
                if (this.state.camera_hotspots !== undefined && this.state.camera_hotspots !== null && this.state.camera_hotspots !== [] && this.state.camera_hotspots.length !== 0){
                    if (typeof (this.state.camera_hotspots) == "object" && Object.keys(this.state.camera_hotspots).length == 0) {
                    }
                    else if (typeof (this.state.camera_hotspots) == "object" && Object.keys(this.state.camera_hotspots).length != 0) {
                        if (this.sceneCreator != null){
                            this.sceneCreator.setCameraHotspot(this.state.camera_hotspots);
                        }
                    }
                    else if (typeof (this.state.camera_hotspots) !== "object"){
                        if (this.sceneCreator != null){
                            this.sceneCreator.setCameraHotspot(this.state.camera_hotspots);
                        }
                    }
                }

            });
        }
        setTimeout(() => {
            if ((this.state.camera_hotspots !== undefined && this.state.camera_hotspots !== null && this.state.camera_hotspots !== [] && this.state.camera_hotspots.length !== 0) && !this.sceneCreator.getSaveSceneEditFlag()){
                if (typeof (this.state.camera_hotspots) == "object" && Object.keys(this.state.camera_hotspots).length == 0) {
                }
                else if (typeof (this.state.camera_hotspots) == "object" && Object.keys(this.state.camera_hotspots).length != 0) {
                    this.rebuildCustomCamera();
                }
                else if (typeof (this.state.camera_hotspots) !== "object"){
                    this.rebuildCustomCamera();
                }
            }
            this.setState({
                scene_view_state: "create360",
                mode_360: 'reset',
                preview_true: false,
                reset_true: true,
                tour_flag: true
            }, () => {
                this.sceneCreator.disablePreview();
                this.setSceneViewState("create360");
            });
        }, 0);
    }

    handleCreatingNewPlacement = (camera_name, scene_placement_state, onComplete) => {
        let scene_id = this.state.collaboration_scene_id;
        let cameras = []
        if (camera_name != null) {
            cameras = [camera_name]
        }
        let payload = {
            scene_id: scene_id,
            cameras: cameras,
            placement_info: scene_placement_state
        }
        console.log("API RENDERED")

        axios.post(ENVIRONMENT.SCENE_CONFIG_CREATE, payload)
            .then( (response) => {
                console.log(response.data)
                let scene_configuration_id = response.data;
                
                this.setState({
                    current_placement_id: scene_configuration_id,
                    current_placement_info: scene_placement_state,
                    api_finished: true
                }, ()=> {
                    if (onComplete) {
                        onComplete()   
                    }
                })
                let product_ids = this.sceneCreator.getObjectsInCamera();
                let product_camera_mapping_payload = {
                    scene_id : scene_id,
                    configuration_id : scene_configuration_id,
                    product_ids : product_ids,
                    camera_name : camera_name,
                    action : 'set_camera_product_mapping'
                }
                console.log("camera in products", product_ids,camera_name)
                axios.post(ENVIRONMENT.CAMERA_PRODUCT_MAP_CONTROLLER, product_camera_mapping_payload)
                .then((res) => {
                    console.log('Scene Products Mapped Successfully');
                })
            })
            .catch((error) => {
            });
    }

    handleCreatingNewPlacement360 = (cameras_to_assign, scene_placement_state, callback = null) => {
        let scene_id = this.state.collaboration_scene_id;

        let payload = {
            scene_id: scene_id,
            cameras: cameras_to_assign,
            placement_info: scene_placement_state
        }

        axios.post(ENVIRONMENT.SCENE_CONFIG_CREATE, payload)
            .then( (response) => {
                console.log(response.data)
                let scene_configuration_id = response.data.toString();

                let placement_versions = this.state.placement_versions;

                let placement_version = {
                    cameras: cameras_to_assign,
                    scene_config_id: scene_configuration_id
                }
                placement_versions[scene_configuration_id] = placement_version;

                this.setState({
                    placement_versions: placement_versions,
                    current_placement_id: scene_configuration_id
                }, ()=> {

                    // Delete these cameras from previous placement versions
                    for (let camera of cameras_to_assign) {
                        this.delete360CameraFromOtherPlacements(camera, scene_configuration_id);
                    }

                    if (callback != null) {
                        callback();
                    }
                })
            })
            .catch((error) => {
            });

    }

    handleUpdatingPlacement = (new_cameras, scene_placement_state) => {
        let scene_id = this.state.collaboration_scene_id;
        console.log('update case');
        let payload = {
            scene_configuration_id: this.state.current_placement_id,
            cameras: new_cameras,
            placement_info: scene_placement_state,

        }

        axios.post(ENVIRONMENT.SCENE_CONFIG_UPDATE, payload)
            .then( (response) => {
                let scene_configuration_id = response.data.toString();
                console.log(response.data)
                let placement_versions = this.state.placement_versions
                placement_versions[this.state.current_placement_id].cameras = new_cameras
                placement_versions[this.state.current_placement_id].scene_config_id = scene_configuration_id
                this.setState({
                    placement_versions: placement_versions,
                    api_finished:true
                })
            })
            .catch((error) => {
            });

   }

   handleUpdatingPlacement360 = (new_cameras_to_assign, scene_placement_state, callback = null) => {

    let cameras_against_placement = this.state.placement_versions[this.state.current_placement_id].cameras;

    for (let camera of new_cameras_to_assign) {
        if (!cameras_against_placement.includes(camera)) {
            cameras_against_placement.push(camera);
        }

        this.delete360CameraFromOtherPlacements(camera, this.state.current_placement_id);
    }

    let payload = {
        scene_configuration_id: this.state.current_placement_id,
        cameras: cameras_against_placement,
        placement_info: scene_placement_state
    }
    console.log(payload)

    axios.post(ENVIRONMENT.SCENE_CONFIG_UPDATE, payload)
        .then( (response) => {
            console.log(response.data)
            let placement_versions = this.state.placement_versions
            placement_versions[this.state.current_placement_id].cameras = cameras_against_placement
            this.setState({
                placement_versions: placement_versions
            }, () => {
                if (callback != null) {
                    callback();
                }
            })
        })
        .catch((error) => {
        });
    }

    delete360CameraFromOtherPlacements(camera_to_delete, new_placement_id_against_camera) {
        for (let key of Object.keys(this.state.placement_versions)) {
            let placement_version = this.state.placement_versions[key];
            if (key !== new_placement_id_against_camera && placement_version.cameras.includes(camera_to_delete)) {
                let camera_index = placement_version.cameras.indexOf(camera_to_delete);
                placement_version.cameras.splice(camera_index, 1);
                //remove above code

                let payload = {
                    scene_configuration_id: key,
                    cameras: placement_version.cameras
                }

                console.log("Deleting 360 camera " + camera_to_delete + " from " + key);
                console.log(payload)

                axios.post(ENVIRONMENT.SCENE_CONFIG_UPDATE, payload)
                    .then( (response) => {
                        console.log("Removed " + camera_to_delete);
                        console.log(response.data)
                    })
                    .catch((error) => {
                    });
            }
        }
    }

    handleSavingPlacementInfo = (camera_name, onComplete = null) => {

        let scene_json = this.sceneCreator.getSceneInfoJSON();
        let scene_data = JSON.parse(scene_json);

        let assets_placement_info = scene_data['assets'];
        let sun_placement_info = scene_data['sun'];
        let lights_placement_info = scene_data['lights'];
        let space_configuration_info = scene_data['space_configuration'];
        let cameras_info = scene_data['cameras'];

        let placement_cameras = [];
        for (let camera of cameras_info) {
            if (camera.camera_name == camera_name) {
                placement_cameras.push(camera)
            }
        }

        let scene_placement_state = {
            assets: assets_placement_info,
            sun: sun_placement_info,
            lights: lights_placement_info,
            space_configuration: space_configuration_info,
            cameras: placement_cameras
        }

        if (this.state.current_placement_info == null || JSON.stringify(this.state.current_placement_info) != JSON.stringify(scene_placement_state)) {
            this.deletePreExistingSnapshotConfigs(camera_name, ()=> {
                this.handleCreatingNewPlacement(camera_name, scene_placement_state, onComplete);   
            })  
        }
    }

    handleSavePlacementAndSubmitRender = (e = null, tour_request = false) => {
        this.setState({
            confirm_360_loader: true,
            confirm_3d_loader: true,
        });
        let scene_json = this.sceneCreator.getSceneInfoJSON();
        let scene_data = JSON.parse(scene_json);

        let assets_placement_info = scene_data['assets'];
        let sun_placement_info = scene_data['sun'];
        let lights_placement_info = scene_data['lights'];
        let space_configuration_info = scene_data['space_configuration'];
        let cameras_info = scene_data['cameras'];

        let scene_placement_state = {
            assets: assets_placement_info,
            sun: sun_placement_info,
            lights: lights_placement_info,
            space_configuration: space_configuration_info
        }

        let cameras_to_render = []
        for (let camera of scene_data['cameras']) {
            if(camera.camera_type == '360'){
                if(this.state.selected_360s.includes(camera.camera_name)){
                    cameras_to_render.push(camera.camera_name);
                }

                if(camera.camera_name == '360Camera'){
                    if(this.state.selected_360s.includes('Room 360')){
                        cameras_to_render.push(camera.camera_name);
                    }
                }
            }
        }

        let placement_changed = true;
        if (this.state.current_placement_id != null) {
            let previous_placement_json = this.state.current_placement_info;
            let previous_placement_wo_cameras_json = {
                assets: previous_placement_json['assets'],
                sun: previous_placement_json['sun'],
                lights: previous_placement_json['lights'],
                space_configuration: previous_placement_json['space_configuration']
            }
            if (JSON.stringify(previous_placement_wo_cameras_json) == JSON.stringify(scene_placement_state)) {
                placement_changed = false;
            }
        }

        if (this.state.current_placement_id == null || placement_changed == true ) {
            // Add info of cameras
            let placement_cameras = [];
            for (let camera of cameras_info) {
                if (cameras_to_render.includes(camera.camera_name)) {
                    placement_cameras.push(camera)
                }
            }
            scene_placement_state['cameras'] = placement_cameras;

            this.handleCreatingNewPlacement360(cameras_to_render, scene_placement_state, () => this.submitRenderRequest(e, tour_request));
        } else {
            let placement_cameras = [];

            // Copy info of already stored cameras
            let previous_placement_json = this.state.current_placement_info;
            for (let camera of previous_placement_json['cameras']) {
                if (!cameras_to_render.includes(camera.camera_name)) {
                    placement_cameras.push(camera)
                }
            }

            // Add info of new cameras and/or updated info of already stored cameras
            for (let camera of cameras_info) {
                if (cameras_to_render.includes(camera.camera_name)) {
                    placement_cameras.push(camera)
                }
            }
            scene_placement_state['cameras'] = placement_cameras;

            this.handleUpdatingPlacement360(cameras_to_render, scene_placement_state, () => this.submitRenderRequest(e, tour_request));
        }
    }

    handleTakeSnapshotOk = e => {
        let image_format = this.state.image_format;
        let image_width = this.state.image_width;
        let image_height = this.state.image_height;
        let dpi = this.state.dpi;
        let clipping_value = this.state.clipping_value

        if (this.state.orientation == 'Custom') {
            image_width = parseFloat(this.state.image_width_res);
            image_height = parseFloat(this.state.image_height_res);
        }
        else if (this.state.resolution_status == 4 && this.state.orientation != 'Custom') {
            image_width = parseFloat(this.state.image_width);
            image_height = parseFloat(this.state.image_height);
        }
        else if (this.state.resolution_status != 4 && this.state.orientation != 'Custom') {
            image_width =  parseFloat(this.state.resolution_value.split('x')[0]);
            image_height = parseFloat(this.state.resolution_value.substring(this.state.resolution_value.indexOf('x') + 1));
        }
        let camera_name = this.placeCustomCamera(image_format, image_width, image_height,parseInt(dpi), clipping_value, this.state.camera_mode);
        this.setState({
            enable_generate_lifestyle: true
        });
        this.setState({
            api_finished:false,
            api_loader: true,
        })
        this.handleSavingPlacementInfo(camera_name);
        this.setState({
            taking_snapshot_visible: false,
        });
        this.sceneCreator.enableKey();
        this.saveScene(true);
        this.setState({
            scene_render_name: this.state.default_scene_render_name
        })
        let camera_res = image_width.toString() + 'x' + image_height.toString();
        let camera_category = this.returnPrice(camera_res,'scene_lifestyle_image','category');
        let camera_categories = this.state.selected_categories;
        camera_categories.push(camera_category);
        this.setState({
            selected_categories: camera_categories
        });
        this.loadUserRequestStatus(camera_categories,'scene_lifestyle_image',null);
        this.checkIfNewUser();
    };

    handleTakeSnapshotCancel = e => {
        this.setState({
            taking_snapshot_visible: false,
            api_finished:true
        });
        this.sceneCreator.enableKey();
        this.restoreInformation(true);
    };

    handleTakeSnapshotCancelWithAspect = e => {
        this.setState({
            taking_snapshot_visible: false,
        });
        this.triggerAspectDropdown();
        this.sceneCreator.enableKey();
    };

    alignCameraForSnapshot = () => {
        this.sceneCreator.alignCameraForSnapshot();
    }

    lookAtObjectHeadOn = () => {
        this.sceneCreator.lookAtObjectHeadOn();
        this.onChangeCameraAngle(180 - this.sceneCreator.getCameraPolarAngle());
        this.changeCameraHeight()
        this.setCameraHeight(this.sceneCreator.getActualCameraHeight())
    }

    lookAtObjectCenter = () => {
        this.sceneCreator.lookAtObjectCenter();
        this.onChangeCameraAngle(180 - this.sceneCreator.getCameraPolarAngle());
        this.changeCameraHeight()
    }

    alignCameraHorizontallyForSnapshot = () => {
        this.sceneCreator.alignCameraHorizontallyForSnapshot();
    }


    scrollFunction = () => {
        let controlsDiv = document.getElementById('camera-sun-controls');
        let controlsDivButton = document.getElementById('camera-sun-controls-button')
        if (controlsDiv != null && controlsDivButton != null){
            if (controlsDiv.scrollTop < 80 ) {
                controlsDivButton.style.display = "block";
            } else {
                controlsDivButton.style.display = "none";
            }
        }
    }

    scrollToBottom = () => {
        let controlsDiv = document.getElementById('camera-sun-controls');
        if (controlsDiv != null){
            controlsDiv.scrollTo(0, controlsDiv.scrollHeight)
        }
      }

    showGridForSnapshot = () => {
        if (this.state.grid_controls_view == 0) {
            this.setState({
                grid_controls_view : 1
            })
            document.getElementById('grid_span').innerHTML='Grid On'
        }
        else {
            this.setState({
                grid_controls_view : 0
            })
            document.getElementById('grid_span').innerHTML='Grid Off'
        }
        this.sceneCreator.showGridForSnapshot();

    }

    openGridSettingsModal = () => {
        this.setState({
            grid_settings_modal: true
        });
    }

    OpenOverlaySettingsModal = () => {
        
        var img_data = this.sceneCreator.getSceneScreenShot();
        this.setState({
            overlay_settings_modal: true,
            temp_snapshot_overlay : img_data,
        },
            () => {
                this.showTempOverlayLines();
                
            }
        )
           
    }

    handle360DoneOk = e => {
        this.setState({
        submit_360_done_visible: false,
        submit_collaboration_render_done_visbile: false
        });
    };

    handleTourDoneOk = e => {
        this.setState({
        submit_tour_done_visible: false,
        });
    };

    handlePlainDoneOk = e => {
        this.setState({
        submit_plain_done_visible: false,
        });
    };

    handlePlainOk = e => {
        this.setState({
        submit_plain_visible: false,
        });
    };

    handlePlainCancel = e => {
        this.setState({
            submit_plain_visible: false,
            submit_collaboration_render_modal: false
        });
        this.sceneCreator.removeCameraByName(this.state.update_camera)
    };

    placeNewCollabCamera = () => {
        let image_format = this.state.image_format;
        let image_width = this.state.image_width;
        let image_height = this.state.image_height;
        let dpi = this.state.dpi;
        let clipping_value = this.state.clipping_value

        if (this.state.orientation == 'Custom') {
            image_width = parseFloat(this.state.image_width_res);
            image_height = parseFloat(this.state.image_height_res);
        }
        else if (this.state.resolution_status == 4 && this.state.orientation != 'Custom') {
            image_width = parseFloat(this.state.image_width);
            image_height = parseFloat(this.state.image_height);
        }
        else if (this.state.resolution_status != 4 && this.state.orientation != 'Custom') {
            image_width =  parseFloat(this.state.resolution_value.split('x')[0]);
            image_height = parseFloat(this.state.resolution_value.substring(this.state.resolution_value.indexOf('x') + 1));
        }
        let new_camera = this.placeCustomCamera(image_format, image_width, image_height,parseInt(dpi), clipping_value, this.state.camera_mode);

        return new_camera;
        
    };

    requestNewCollabCamera = () => {
        this.sceneCreator.updatingClonedCamera()
        this.sceneCreator.resetSelection();
        this.handleSunModeSwitch(false);
        this.enableMeasurementTool(false);
        let new_camera = this.placeNewCollabCamera();
        this.sceneCreator.enableKey();
        let camera_res = new_camera.resolution;
        let camera_category = this.returnPrice(camera_res,'scene_lifestyle_image','category');
        this.loadUserRequestStatus([camera_category],'scene_lifestyle_image',null);
        this.checkIfNewUser();
        this.showNewCollabCamera(new_camera)
    }

    showNewCollabCamera = (camera) => {
        this.setState({
            summary_modal_cameras: [camera]
        }, () => {
            this.setState({
                submit_collaboration_render_modal: true,
            });
        })
    }

    updateNewCollabCamera = () => {
        if (this.state.submit_collaboration_render_modal) {
            let new_camera = this.placeNewCollabCamera();
            this.showNewCollabCamera(new_camera);
        }
    }

    handlePlainErrorOk = e => {
        this.setState({
            submit_plain_error_visible: false,
        });
    };

    handlePlainErrorCancel = e => {
        this.setState({
            submit_plain_error_visible: false,
        });
    };

    checkIfLatestConfigContainsSnapshot = (camera_name) => {
        const current_placement_id = this.state.current_placement_id;
        if (current_placement_id) {
            const placement_version = this.state.placement_versions[this.state.current_placement_id];
            for (let camera of placement_version.cameras) {
                if (camera == camera_name) {
                    return true;
                }
            }
        }
        return false;
    }
    

    removeSnapshotFromLatestConfig = (camera_name) => {
        let latest_placement_version = this.state.placement_versions[this.state.current_placement_id]
        let cameras = latest_placement_version.cameras.filter(camera => camera != camera_name);
        latest_placement_version.cameras = cameras;
        return latest_placement_version
    }

    deleteSnapshotConfiguration = (camera_name) => {
        if (!this.checkIfRenderedAlready(this.state.final_renders, camera_name)) {
            /**
            *  if current placement is being deleted so set it to null else scene creator will keep pointing to
            *  a deleted configuration as latest.
            *  scene creator will automatically create a new placement for the new requested camera or when auto save is called
            */
            if (this.checkIfLatestConfigContainsSnapshot(camera_name)) {
                // if camera is in latest config remove it from the list
                let latest_placement_version = this.removeSnapshotFromLatestConfig(camera_name);
                if (latest_placement_version.cameras.length > 0) {
                    /**
                     * if there are other cameras besides deleted snapshot in latest config then 
                     * the config won't be deleted from db just updated
                     */
                    let placement_versions = this.state.placement_versions;
                    placement_versions[this.state.current_placement_id] = latest_placement_version;
                    this.setState({
                        placement_version: placement_versions
                    }, ()=> {
                        console.log("delete snapshot and update placement", latest_placement_version)
                        this.deletePreExistingSnapshotConfigs(camera_name)
                    })
                }
                else {
                    /**
                     * if there are no cameras besides deleted snapshot in latest config then 
                     * config will be deleted from db
                     */
                    this.setState({
                        current_placement_id: null
                    }, ()=> {
                        console.log("delete snapshot and reset placement", this.state.current_placement_id)
                        this.deletePreExistingSnapshotConfigs(camera_name)
                    })
                }   
            }
            else {
                this.deletePreExistingSnapshotConfigs(camera_name)
            }
       }
    }

    deletePreExistingSnapshotConfigs = (camera_name, onComplete = null) => {
        let scene_id = this.state.collaboration_scene_id;
        let payload = {
            'scene_id': scene_id,
            'camera_name': camera_name
        }
        axios.post(ENVIRONMENT.SCENE_CONFIG_DELETE_BY_CAMERA, payload)
        .then(res => {
            console.log("delete pre existing scene configs", res)
            if (onComplete != null) {
                onComplete();
            }
        })
    }

    deleteSnapshot = (camera_name) =>{
        let scene_id = this.state.collaboration_scene_id;
        let cameras = [];
        let summary_modal_cameras = []
        for (var i=0; i<this.state.cameras.length; i++){
            if (this.state.cameras[i]['name'] != camera_name)
            {
                cameras.push(this.state.cameras[i]);
                if (!this.checkIfRenderedAlready(this.state.final_renders, this.state.cameras[i]['name'])) {
                    summary_modal_cameras.push(this.state.cameras[i]);
                }
            }
            else{
                this.sceneCreator.removeCameraByName(camera_name);
                let camera_categories = this.state.selected_categories
                let this_category = this.returnPrice(this.state.cameras[i]['resolution'],'scene_lifestyle_image','category')
                for(let i=0; i<camera_categories.length;i++ ){
                    if(camera_categories[i] == this_category){
                        camera_categories.splice(i, 1);
                        break;
                    }
                }
                this.setState({
                    selected_categories : camera_categories
                })
                this.loadUserRequestStatus(camera_categories,'scene_lifestyle_image',null);
                this.deleteSnapshotConfiguration(camera_name);
            }
        }


        let all_cameras_found = true

        cameras.map( (cam) => {
            let is_found = false;
            for (var i = 0; i < this.state.final_renders.length; i++){
                if( cam.name == this.state.final_renders[i].filename.split(".")[0]){
                    is_found = true
                }
            }
            if(is_found == false){
                all_cameras_found = false
            }
        })

        
        this.setState({
            cameras: cameras,
            summary_modal_cameras : summary_modal_cameras
        }, () => {
            this.saveScene();
        });

        if (all_cameras_found) {
            this.setState({
                enable_generate_lifestyle: false
            });
        }

        let enabled_render_flag = false;
        console.log("Delete Camera Function &", cameras);

        console.log("total cams", summary_modal_cameras.length)

        if (summary_modal_cameras.length == 0) {
            this.setState({
                submit_plain_visible: false,
                submit_collaboration_render_modal: false
            });
        }

        let product_camera_mapping_payload = {
            action : 'delete_camera_product_mapping',
            scene_id : scene_id,
            camera_name : camera_name
        }
        axios.post(ENVIRONMENT.CAMERA_PRODUCT_MAP_CONTROLLER, product_camera_mapping_payload)
        .then((res) => {
            console.log("product camera mapping deleted", res)
        })

    }

    setPolarAngle = (angle) => {
        this.sceneCreator.setCameraPolarAngle(angle);
    }

    setCameraHeight = (value) => {
        if(this.sceneCreator){
            this.sceneCreator.setCameraHeight(value);
            this.setState({
                sliderPosition3D: (value * 450).toFixed(0)
            })
        }
    }

    updateCameraHeight = (value) => {
        this.setCameraHeight(value);
        this.changeCameraHeight();

    }

    setCameraStateHeight = (value) => {
        this.setState({
            camera_height : value
        })
    }

    setFocalOfCamera = ( value ) => {
        var fov;
        if(this.sceneCreator){
            fov= this.sceneCreator.setCameraFocal(value);
            this.setState({
                snapshot_clicked: true,
                camera_Focal: this.sceneCreator.getCameraFocal(),
            })
            return fov;
        }
    }

    deactivateSpaceConfiguratorMode = () => {
        this.setSpaceConfiguratorMenuState(false);
        this.sceneCreator.deactivateSpaceConfigurator();
    }

    setSpaceConfiguratorMenuState = (state) => {
        if (state) {
            this.detachSunControls();
        }
        else if (!state) {
            this.setState({hide_camera : false, sun_controls_view: 0, space_configuration_applied: true})
            this.handleSunModeSwitch(false);
        }
        this.setState({
            show_space_configurator_menu: state
        });
    }

    setSpaceConfiguratorModeState = (state) => {
        this.setState({
            enable_space_configurator_mode: state
        });
        this.sceneCreator.setSpaceConfiguratorModeState(state);
        if (!state && this.state.show_space_configurator_menu) {
            this.setSpaceConfiguratorMenuState(false);
        }
        
        let disclaimer = null;
        if (state) {
            disclaimer = "Click on any wall, floor or ceiling to select and change colors or materials on it";
        }
        this.setDisclaimer(disclaimer);
    }

    setSpaceConfigurationOfSelection = (configInfo) => {
        this.setState({space_selection_config : configInfo});
    }

    setSpaceTypeOfSelection = (selectionType) => {
        this.setState({space_selection_type: selectionType});
        this.setDisclaimer('');
    }

    setSpaceSelectionColorConfigurable = ( status ) => {
        this.setState({ spaceSelectionColorConfigurable: status });
    }

    setPreserveTextureOption = ( preserveTexture ) => {
        this.sceneCreator.setPreserveTextureOption(preserveTexture);
    }

    applyColorToSpaceSelection = ( color ) => {
        // call scene creator function to set color for selected space component
        this.sceneCreator.applyColorToSpaceSelection( color );
    }

    applyColorToAllSpaceSelection = ( color ) => {
        // call scene creator function to set color for selected space component
        this.sceneCreator.applyColorToAllSpaceSelection( color );
    }

    applyMaterialToSpaceSelection = ( texture, horizontalRepeat, verticalRepeat, glossiness, horizontalOffset, verticalOffset, rotation ) => {
        // call scene creator function to set color for selected space component
        this.setState({space_configuration_applied: false})
        this.sceneCreator.applyMaterialToSpaceSelection( texture, horizontalRepeat, verticalRepeat, 1 - glossiness, horizontalOffset, verticalOffset, rotation, () => {this.setState({space_configuration_applied: true}) } );
    }

    applyMaterialToAllSpaceSelection = ( texture, horizontalRepeat, verticalRepeat, glossiness, horizontalOffset, verticalOffset, rotation ) => {
        // call scene creator function to set color for selected space component
        // this.setState({space_configuration_applied: false})
        this.sceneCreator.applyMaterialToAllSpaceSelection( texture, horizontalRepeat, verticalRepeat, 1 - glossiness, horizontalOffset, verticalOffset, rotation, () => {} );
    }

    restoreMaterialToAllSpaceSelection = () => {
        this.sceneCreator.restoreMaterialToAllSpaceSelection()
    }

    createSpaceMaterial = (textureConfigInfo, onTextureCreated) => {
        let materialFiles = {}
        materialFiles["data"] = {}
        materialFiles["data"]["swatch"] = textureConfigInfo["base_map"];
        let payload = {
            username: CUSTOMER_USERNAME,
            company_id: COMPANY_ID,
            thumbnail: textureConfigInfo['thumbnail'],
            material_files: materialFiles
        }
        axios.post(ENVIRONMENT.SPACE_MATERIAL_CREATE, payload)
        .then(res => {
            let spaceMaterialInfo = this.parseSpaceMaterialInfo(res.data.body);
            if (spaceMaterialInfo) {
                let parsedMaterialInfo = this.state.material_info;
                parsedMaterialInfo.unshift(spaceMaterialInfo)
                this.setState({
                    material_info: parsedMaterialInfo
                })
                spaceMaterialInfo["target_request"] = textureConfigInfo["target_request"]
                onTextureCreated(spaceMaterialInfo);
            }
            else {
                this.setState({
                    space_configuration_applied: true
                })
                message.error("Failed to apply configuration. Please Try Again!")
            }
        })
    }

    setUploadedTexturesList = (textures) => {
        this.setState({
            texture_info: textures
        })
    }

    updateSpaceConfigurationSettings = (configInfo, onUploadTextureCompletion) => {
        if (configInfo && configInfo.upload == true) {
            configInfo["target_request"] = Date.now();
            this.sceneCreator.setSpaceConfiguratorTargetRequest(configInfo["target_request"]);
            this.setState({
                space_configuration_applied: false
            }, () => {
                this.createSpaceMaterial(configInfo, (materialInfo) => {
                    if (materialInfo["target_request"] == this.sceneCreator.getSpaceConfiguratorTargetRequest()) {
                        materialInfo["update"] = true;
                        onUploadTextureCompletion(materialInfo);
                        configInfo["id"] = materialInfo["id"]
                        delete configInfo["thumbnail"];
                        delete configInfo["upload"];
                        this.sceneCreator.updateConfigurationToSpaceSelection(configInfo);
                        message.success("Configuration Settings Updated!")
                        this.setState({
                            space_configuration_applied: true
                        })
                        if (!this.state.admin_scene_creator) {
                            this.saveScene(true);
                        }
                    }
                    else {
                        materialInfo["update"] = false;
                        onUploadTextureCompletion(materialInfo);
                    }
                })
            })
        }
        else if (configInfo) {
            this.sceneCreator.updateConfigurationToSpaceSelection(configInfo);
            message.success("Configuration Settings Updated!")
            if (!this.state.admin_scene_creator) {
                this.saveScene(true);
            }
        }
        else {
            message.warning("Please select color or texture first!")
        }
        
    }

    discardSpaceConfigurationSettings = () => {
        this.sceneCreator.discardConfigurationToSpaceSelection();
        this.setSpaceConfiguratorMenuState(false);
    }

    resetSpaceConfigurationSettings = () => {
        this.sceneCreator.resetConfigurationToSpaceSelection();
        message.success("Configuration Settings Reset!")
    }

    enableMeasurementTool = (state) =>  {
        this.setState({
            enable_measurement_tool: state
        })
        this.sceneCreator.enableMeasurementTool(state);
        if (state) {
            const disclaimer = "Click and select two points to measure the distance between them."
            this.setDisclaimer(disclaimer);
        }
        else {
            this.setDisclaimer("");
        }
    }

    showDeleteConfirm = (productId) => {
        let deleted_asset_ids = this.state.deleted_asset_ids;
        let deleted_user_added_products =  this.state.user_added_products.filter(x => !deleted_asset_ids.includes(x));
        console.log(deleted_user_added_products, deleted_asset_ids, productId)

        if (this.state.scene_template_id
            && (deleted_user_added_products && deleted_user_added_products.length == 1 && deleted_user_added_products.includes(productId))) {
                console.log('come here?')
            message.error('Cannot delete this product. You should have at least one of your own products in the scene!')
        } else {
            this.setState({
                deleted_asset_ids: deleted_asset_ids.concat(productId.toString())
            });
            let performDelete = () => this.sceneCreator.objectPlacementManager.deleteSelectedAsset();
            let removeProduct = () => this.removeProductFromCollection(productId, this.state.collection_id);
            confirm({
                title: <div>
                <div className='manrope f-14'>Are you sure you want to remove this product from the scene?</div>
                <div><img src={require("../../../../assets/images/warning.png")} style={{width:"25px", height:"25px", display: "inline" , position: "fixed" , marginTop: "20px", marginLeft: "-40px"}} /></div>
                <div className='manrope f-14' style = {{ marginTop: "20px" }}>After deleting the item, move the items that were on the deleted item to the floor!</div>
            </div>,
            icon: <ExclamationCircleOutlined />,
            content: null,
            okText: <span className='manrope f-12'>Yes</span>,
            okType: 'danger',
            cancelText: <span className='manrope f-12'>No</span>,
            onOk() {
                removeProduct();
                performDelete();
            },
            onCancel() {
            },
            });
        }
    }

    checkAssetCount(assetName) {
        let scene_json = this.sceneCreator.getSceneInfoJSON();
        let scene_data = JSON.parse(scene_json);
        let assets_placement_info = scene_data['assets'];
        let filteredAssets = assets_placement_info.filter(asset => asset.asset_name === assetName);
        return filteredAssets.length;
    };

    removeProductFromCollection = (product_id, collection_id) => {
        let asset_count = this.checkAssetCount(product_id);
        if (asset_count == 1) {
            axios.post(ENVIRONMENT.DELETE_COLLECTION_PRODUCT, {
                collection_id: collection_id,
                product_id: product_id
            })
            .then((response) => {
                this.triggerPostCollectionUpdate(collection_id);
                this.removeFromCollectionProducts(product_id);
                this.updateDeletedAssets(product_id);
                this.saveScene(true);
            })
            .catch((error) => {
                console.error('An error occurred:', error);
            });
        }
    };

    triggerPostCollectionUpdate = (collection_id) => {
        let collection_payload= {
            "collection_id": collection_id
        }
        axios.post(ENVIRONMENT.POST_COLLECTION_UPDATE, collection_payload)
        .then((res) => {
        })
    };

    updateDeletedAssets = (product_id) => {
        let deleted_asset_ids = this.state.deleted_asset_ids;
        if (!deleted_asset_ids.includes(product_id.toString())) {
            deleted_asset_ids.push(product_id.toString())
        }
        this.setState({
            deleted_asset_ids: deleted_asset_ids
        });
        this.saveScene(true);
    };

    checkCollectionProducts = (collection_id, collection_template_id) => {
        let payload = {collection_id: collection_id}
        axios.post(ENVIRONMENT.FETCH_COLLECTION_PRODUCTS, payload)
        .then(res => {
            if (res && res.data) {
                let products = []
                res.data.map((product) => {
                    products.push(product.id.toString())
                })
                let product_payload = {collection_id: collection_template_id }
                axios.post(ENVIRONMENT.FETCH_COLLECTION_PRODUCTS, product_payload)
                .then(template_res => {
                    if (template_res && template_res.data) {
                        let template_products = []
                        template_res.data.map((product) => {
                            template_products.push(product.id.toString())
                        })
                        console.log(products,template_products)
                        let products_added_by_user = products.filter(x => !template_products.includes(x));
                        console.log(products_added_by_user, 'dif')
                        this.setState({
                            user_added_products: products_added_by_user
                        });
                    }

                })
            }
        })
    }

    resetCamera = () => {
        this.sceneCreator.resetCamera();
    }

    setUpHotspot = (area) => {
        let images = [];
        let default_position = null;
        let camera_length = 0;
        let camera_position = [];
        if (this.state.space_areas != undefined){
            if ((area == "Outdoor" || area == "Outside" ) && this.sceneCreator.isOutdoorCamera()) {
                images.push(<img id={`hotspot-camera0${area}`} key={`0${area}`} cameraName={`${area} Camera`} cameraPosition={this.sceneCreator.getAreaCameraPos(area)} spaceArea={area} src="/img/active-camera.svg" style={{height:"32px",width:"32px",borderRadius:"50%",cursor:"pointer",zIndex:"1"}}/>);
                this.setState({ hotspots: images });
                this.state.image_property[this.state.selected_area_preview] = `${area} Camera (Default)`;
                this.sceneCreator.setupCameraHelper(this.state.selected_area_preview,`hotspot-camera`);
                return;
            }
            if (area == "Bath" || area == "Closet") {
                images.push(<img id={`hotspot-camera0${area}`} cameraName={`${area} Camera`} key={`0${area}`} cameraPosition={this.sceneCreator.getAreaCameraPos(area)} spaceArea={area} src="/img/active-camera.svg" style={{height:"32px",width:"32px",borderRadius:"50%",cursor:"pointer",zIndex:"1"}}/>);
                this.setState({ hotspots: images });
                this.state.image_property[this.state.selected_area_preview] = `${area} Camera (Default)`;
                this.sceneCreator.setupCameraHelper(this.state.selected_area_preview,`hotspot-camera`);
                return;
            }
            camera_length = this.sceneCreator.getCameraPosLength(area);
            camera_position = this.sceneCreator.getAllHotspots(area);
            default_position = this.sceneCreator.getSelectedHotspot(area);
            if (camera_length !== undefined && camera_position !== undefined){
                for (let i = 1; i <= camera_length; i++){
                    if ((default_position !== undefined) && (default_position !== null) && (this.sceneCreator.compareVectors(default_position,camera_position[i]))) {
                        images.push(<img id={`hotspot-camera${i}${area}`} cameraName={`${area} Camera ${i}`} key={`${i}${area}`} cameraPosition={camera_position[i]} spaceArea={area} src="/img/active-camera.svg" style={{height:"32px",width:"32px",borderRadius:"50%",zIndex:"1"}}/>);
                        this.state.image_property[this.state.selected_area_preview] = `${area} Camera ${i}`;
                        if (this.sceneCreator.compareVectors(this.sceneCreator.getDefaultPositionValue(this.state.selected_area_preview),camera_position[i]))
                        {
                            this.state.image_property[this.state.selected_area_preview] = `${area} Camera ${i} (Default)`;
                        }
                        this.sceneCreator.setupCameraHelper(this.state.selected_area_preview,`hotspot-camera${i}${area}`);
                    }
                    else {
                        images.push(<img id={`hotspot-camera${i}${area}`} cameraName={`${area} Camera ${i}`} key={`${i}${area}`} cameraPosition={camera_position[i]} spaceArea={area} src="/img/Cam.svg" style={{animation:"pulse 2s infinite",height:"32px",width:"32px",borderRadius:"50%",cursor:"pointer",zIndex:"1"}}/>);
                    }
                    this.setState({ hotspots: images });
                }
            }
        }
    }

    setZoomCamera = (area_name) => {
        this.setState({
            selected_area_preview: area_name
        }, ()=> {
            this.sceneCreator.zoomCamera(area_name);
            this.setUpHotspot(area_name);
            this.sceneCreator.selectedAreaSpace(area_name);
        });
    }

    setZoomCameraCallback = () => {
        this.setState({
            selected_area_preview: this.state.space_areas[0]
        })
        this.sceneCreator.zoomCamera(this.state.space_areas[0]);
        if (this.state.tour_flag) {
            this.setState({
                progress_loader_3d: false
            });
        }
        else {
            this.setState({
                progress_loader_360: false
            });
        }
    }

    handleAspectRatioCancel = () =>
    {
        this.setState({
            aspect_ratio_value: this.state.aspect_ratio_aspect,
            aspect_ratio_selection_visible: false
        });

    }

    onAspectWidthChange = (value) => {
        this.setState({
            aspect_width: value
        });
    }

    onImageWidthChange = (value) => {
        let widthR = 1;
        let heightR = 1;
        let aspect_ratio = 1;
        if (this.state.aspect_ratio_selected == 1.6) {
            widthR = 16;
            heightR = 10;
        } else if (this.state.aspect_ratio_selected == 1.78) {
            widthR = 16;
            heightR = 9;
        } else if (this.state.aspect_ratio_selected == 1.91) {
            widthR = 1.91;
            heightR = 1;
        } else if (this.state.aspect_ratio_selected == 0.56) {
            widthR = 9;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.63) {
            widthR = 10;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.52) {
            widthR = 1;
            heightR = 1.91;
        }
        else if (this.state.aspect_ratio_selected == 1) {
            widthR = 1;
            heightR = 1;
        }
        aspect_ratio = widthR / heightR;
        if (typeof value != 'number') {
            value = 1; // this is done because validation isnt being placed in antd, so manually set the value to 1 if string is entered
        }
        this.setState({
            image_width: value,
            image_height: value / aspect_ratio
        }, ()=> {
            this.updateNewCollabCamera();
        });
    }

    onImageHeightChange = (value) => {
        let widthR = 1;
        let heightR = 1;
        let aspect_ratio = 1;
        if (this.state.aspect_ratio_selected == 1.6) {
            widthR = 16;
            heightR = 10;
        } else if (this.state.aspect_ratio_selected == 1.78) {
            widthR = 16;
            heightR = 9;
        } else if (this.state.aspect_ratio_selected == 1.91) {
            widthR = 1.91;
            heightR = 1;
        } else if (this.state.aspect_ratio_selected == 0.56) {
            widthR = 9;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.63) {
            widthR = 10;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.52) {
            widthR = 1;
            heightR = 1.91;
        }
        else if (this.state.aspect_ratio_selected == 1) {
            widthR = 1;
            heightR = 1;
        }
        aspect_ratio = widthR / heightR;
        if (typeof value != 'number') {
            value = 1
        }
        let recalculated_width = value * aspect_ratio
        this.setState({
            image_width: recalculated_width,
            image_height: value,
        },()=> {
            this.updateNewCollabCamera();
        });
    }

    onChangeWidthResolution = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            this.setState({
                image_width_res: 1
            });
            new_aspect = 1/this.state.image_height_res;
            res = '1x' + this.state.image_height_res;
        } else {
            this.setState({
                image_width_res: value
            });
            new_aspect = value/this.state.image_height_res;
            res = value + 'x' + this.state.image_height_res;
        }
        this.setState({
            resolution_value: res,
            aspect_ratio_selected: new_aspect
        });
        this.sceneCreator.setCustomAspect(new_aspect);
        var img_data = this.sceneCreator.getSceneScreenShot();
        this.setState({
            tempSnapshot: img_data,
        });
    }

    onChangeHeightResolution = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            this.setState({
                image_height_res: 1
            });
            new_aspect = this.state.image_width_res/1;
            res = this.state.image_width_res + 'x1';
        } else {
            this.setState({
                image_height_res: value
            });
            new_aspect = this.state.image_width_res/value;
            res = this.state.image_width_res + 'x' + value;
        }
        this.setState({
            resolution_value: res,
            aspect_ratio_selected: new_aspect
        });
        // this.changeAspectRatio(new_aspect);
        this.sceneCreator.setCustomAspect(new_aspect);
        var img_data = this.sceneCreator.getSceneScreenShot();
        this.setState({
            tempSnapshot: img_data,
        });
    }

    onChangeWidth = (value) => {
        this.setState({
            image_width_res: value
        });
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            this.setState({
                image_width_res: 1
            });
            new_aspect = 1/this.state.image_height_res;
            res = '1x' + this.state.image_height_res;
        } else {
            this.setState({
                image_width_res: value
            });
            new_aspect = value/this.state.image_height_res;
            res = value + 'x' + this.state.image_height_res;
        }
        this.setState({
            resolution_value: res,
        });
    }

    onChangeHeight = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            this.setState({
                image_height_res: 1
            });
            new_aspect = this.state.image_width_res/1;
            res = this.state.image_width_res + 'x1';
        } else {
            this.setState({
                image_height_res: value
            });
            new_aspect = this.state.image_width_res/value;
            res = this.state.image_width_res + 'x' + value;
        }
        this.setState({
            resolution_value: res,
        });
    }

    onImageFormatChange = (e) => {
        this.setState({
            image_format: e.target.value
        });
    }

    setAspects = (value,ratio = null) => {
        let aspect_ratio = this.state.aspect_ratio_selected;

        if (ratio != null) {
            aspect_ratio = ratio;
        }

        if (value != 'Custom') {
            this.sceneCreator.setCustomAspect(aspect_ratio);
        }
        var img_data = this.sceneCreator.getSceneScreenShot();
        this.setState({
            tempSnapshot: img_data,
        });
    }

    onResolutionChange = (value) => {
        this.setState({
            resolution_status: value
        }, ()=> {
            this.updateNewCollabCamera();
        });
        if (value == 1) {
            this.setState({
                resolution_value: this.state.imageResArr[0]
            }, ()=> {
                this.updateNewCollabCamera();
            });
        }
        else if (value == 2) {
            this.setState({
                resolution_value: this.state.imageResArr[1]
            },()=> {
                this.updateNewCollabCamera();
            });
        }
        else if (value == 3){
            this.setState({
                resolution_value: this.state.imageResArr[2]
            },()=> {
                this.updateNewCollabCamera();
            });
        }

    }


    changeOrientation = (value) => {
        if(value == 'Landscape')
        {
            this.setState({
                orientation: 'Landscape'
            });
            this.changeAspectRatio("16:10","Landscape");
        }
        else if(value == 'Portrait')
        {
            this.setState({
                orientation: 'Portrait'
            });
            this.changeAspectRatio("9:16","Portrait");
        }
        else if(value == 'Square')
        {
            this.setState({
                orientation: 'Square'
            });
            this.changeAspectRatio("10:10","Square");
        }
        else if (value == 'Custom') {
            this.setState({
                orientation: 'Custom'
            });
        }
    }

    changeAspectRatio = (ratio,type, resHeight, resWidth) => {
        let widthR = 1;
        let heightR = 1;
        let newAspect = 1;
        if (typeof ratio === 'string' || ratio instanceof String) {
			widthR = ratio.split(':')[0];
            heightR = ratio.substring(ratio.indexOf(':') + 1);
            widthR = widthR * 100;
            heightR = heightR * 100;
            newAspect = (widthR/heightR).toFixed(2);
            this.setState({
                aspect_ratio_selected: parseFloat(newAspect),
                image_width_res: widthR,
                image_width: widthR,
                image_height: Math.round(widthR / newAspect),
                image_height_res: heightR,
                imageResArr: this.sceneCreator.setResolutions(widthR,heightR,type)
            },() => {
                if (this.state.resolution_status == 1) {
                    this.setState({
                       resolution_value: this.state.imageResArr[0]
                    });
                }
                else if (this.state.resolution_status == 2) {
                    this.setState({
                        resolution_value: this.state.imageResArr[1]
                    });
                }
                else if (this.state.resolution_status == 3){
                    this.setState({
                        resolution_value: this.state.imageResArr[2]
                    });
                }
            });
        }
        else {
            if (ratio == 1.6 || ratio == 1.60) {
                widthR = 16;
                heightR = 10;
            } else if (ratio == 1.78) {
                widthR = 16;
                heightR = 9;
            } else if (ratio == 1.91) {
                widthR = 1.91;
                heightR = 1;
            } else if (ratio == 0.56) {
                widthR = 9;
                heightR = 16;
            } else if (ratio == 0.63) {
                widthR = 10;
                heightR = 16;
            } else if (ratio == 0.52) {
                widthR = 1;
                heightR = 1.91;
            }
            else if (ratio == 1) {
                widthR = 1;
                heightR = 1;
            } else {
                if (type == 'Custom') {
                    widthR = resWidth;
                    heightR = resHeight;
                }
            }
            newAspect = (widthR/heightR).toFixed(2);
            this.setState({
                aspect_ratio_selected: parseFloat(newAspect),
                image_width_res: widthR,
                image_width: widthR,
                image_height: Math.round(widthR / newAspect),
                image_height_res: heightR,
                imageResArr: this.sceneCreator.setResolutions(widthR,heightR,type)
            },() => {
                if (this.state.resolution_status == 1) {
                    this.setState({
                       resolution_value: this.state.imageResArr[0]
                    });
                }
                else if (this.state.resolution_status == 2) {
                    this.setState({
                        resolution_value: this.state.imageResArr[1]
                    });
                }
                else if (this.state.resolution_status == 3){
                    this.setState({
                        resolution_value: this.state.imageResArr[2]
                    });
                }
            });
        }
    }

    closeAspectRatioModal = () => {
        if (this.state.setting_done == false) {
            this.setState({
                aspect_clicked: false,
                orientation_clicked: false
            })
        }
        this.setState({
            aspect_ratio_modal: false
        });
        this.restoreInformation(false);
        this.sceneCreator.enableKey();
    }

    orientationSettings = () => {
        if (this.state.orientation == 'Landscape') {
            this.sceneCreator.setCustomAspect(parseFloat(this.state.aspect_ratio_selected));
        }
        else if(this.state.orientation == 'Portrait')
        {
            this.sceneCreator.setCustomAspect(parseFloat(this.state.aspect_ratio_selected));
        }
        else if(this.state.orientation == 'Square')
        {
            this.sceneCreator.setCustomAspect(parseFloat(this.state.aspect_ratio_selected));
        }
        else if (this.state.orientation == 'Custom') {
            let newAspect = this.state.image_width_res/this.state.image_height_res;
            this.setState({
                aspect_ratio_selected: newAspect
            });
            this.sceneCreator.setCustomAspect(parseFloat(newAspect));
        }
        this.setState({
            aspect_ratio_modal: false,
            setting_done: true
        });
        this.sceneCreator.enableKey();
        this.setOverlayLimit();
        this.SetOverlayLines();


    }

    selectAspectRatio = (aspect_ratio, type) => {
        this.setState({
            aspect_clicked: true
        });
        this.forceUpdate();
        this.changeAspectRatio(aspect_ratio, type);
    }

    selectOrientation = (orientation) => {
        this.changeOrientation(orientation);
        this.setState({
            orientation_clicked: true
        });
    }

    setCameraFOV = (value) => {
        this.setState({
            fov_value: value
        });
        if(value == "low"){
            this.sceneCreator.setCameraFOV( 20 );
        }
        if(value == "medium"){
            this.sceneCreator.setCameraFOV( 45 );
        }
        if(value == "high"){
            this.sceneCreator.setCameraFOV( 65 );
        }
    }

    setEditMode = (e) => {
        this.setState({edit_mode: e.target.value});
        if(e.target.value == 'edit') {
            this.sceneCreator.setEditMode(true);
        }
        else{
            this.sceneCreator.setEditMode(false);
        }
    }

    disableGridForSnapshot() {
        if (this.state.grid_controls_view == 1) {
            this.sceneCreator.disableGridForSnapshot();
            this.setState({
                grid_controls_view : 0
            })
        }
    }


    setSceneViewState = (state) => {
        this.setupJoyStick();
        this.detachSunControls();
        this.sceneCreator.releaseRestrictSlider();
        this.sceneCreator.connectCameraControls();
        if(state == 'design') {
            this.sceneCreator.setAspectRatio(0.44);
            this.disableGridForSnapshot();
            this.sceneCreator.setSceneState('design');
            if (this.is3DModeActive()) {
                this.reset3DModeState(true);
            }
            else if (this.isTopDownModeActive()) {
                this.resetTopDownModeState(true);
            }
            else {
                this.resetFlatViewModeState();
            }
            this.sceneCreator.disablePreview();
            this.sceneCreator.resetZoom();
            this.setState({
                scene_view_state: state,
                edit_mode: 'edit',
                submit_360_done_visible: false,
                submit_collaboration_render_done_visbile: false,
                submit_plain_done_visible: false,
                submit_tour_done_visible: false,
            });
            this.sceneCreator.setEditMode(true);
            if (this.state.scene_check) {
                this.setState({
                    edit_mode: 'view',
                });

                this.sceneCreator.setEditMode(false);

            }
            if (this.state.tour_flag) {
                this.setState({tour_flag: false});
            }
        }
        if(state == 'images') {
            this.deactivateSpaceConfiguratorMode();
            // this.sceneCreator.isRestrictSlider();
            if (this.state.admin_scene_test_mode) {
                this.sceneCreator.disconnectCameraControls();
            }
            this.sceneCreator.setSceneState('images');
            this.sceneCreator.setAspectRatio(0.55);
            if (this.is3DModeActive()) {
                this.reset3DModeState();
            }
            else {
                this.resetTopDownModeState();
            }
            this.sceneCreator.disablePreview();
            this.setState({
                scene_view_state: state,
                // orientation:'Landscape',
                // aspect_ratio_selected: 1.6,
                // resolution_value: '1024x640',
                // resolution_status: 3,
                submit_360_done_visible: false,
                submit_collaboration_render_done_visbile: false,
                submit_plain_done_visible: false,
                submit_tour_done_visible: false,
            });

        }
        if (state=='create360') {
            this.deactivateSpaceConfiguratorMode();
            this.disableGridForSnapshot();
            this.sceneCreator.setSceneState('create360');
            this.sceneCreator.setAspectRatio(0.44);
            this.resetTopDownModeState(true);
            let toggle = true;
            if (this.getCookie("cam-placement")) {
                toggle = false
            }
            this.setState({
                scene_view_state: state,
                mode_360: 'reset',
                submit_360_done_visible: false,
                submit_collaboration_render_done_visbile: false,
                submit_plain_done_visible: false,
                submit_tour_done_visible: false,
                preview_true: false,
                reset_true: true,
                show_camera_modal: toggle
            });

            this.sceneCreator.place360Cameras(this.setZoomCameraCallback);
            this.state.space_areas.map((area)=> {
                this.setUpHotspot(area);
                this.sceneCreator.selectedAreaSpace(area);
                this.sceneCreator.getDefaultPosition(area);
            });
            this.setZoomCamera(this.state.space_areas[0]);
        }

    }

    triggerAspectDropdown = () => {
        let element = document.getElementById("aspect-dropdown");
        if(element != undefined){
            element.click();
        }
    }

    handleFullscreenChange = (event) => {
        let elem = event.target;
        let isFullscreen = document.fullscreenElement === elem;
        this.setState({
          fullscreen: isFullscreen
        },
        ()=>this.SetOverlayLines()
        );
     }

    setFullscreen = () => {
        if(this.state.fullscreen == false)
        {
            if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                document.body.webkitRequestFullScreen()
            } else {
                document.body.requestFullscreen();
            }

        }
        if(this.state.fullscreen == true)
        {
            if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                document.webkitCancelFullScreen();
              } else {
                document.exitFullscreen();
              }
        }

        this.sceneCreator.onWindowResize();
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            if(this.state.fullscreen == true)
            {
                if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
                    document.webkitCancelFullScreen();
                  } else {
                    document.exitFullscreen();
                  }
            }
        }
      }

    place360Cameras = () => {
        this.sceneCreator.place360Cameras();
    }

    getCameraPosLength = (area) => {
        return (this.sceneCreator.getCameraPosLength(area));
    }

    selectCamera = (property) => {
        this.state.image_property[property.spaceArea] = property.cameraName;
        if (this.sceneCreator.compareVectors(this.sceneCreator.getDefaultPositionValue(property.spaceArea),property.cameraPosition)) {
            this.state.image_property[property.spaceArea] = property.cameraName.concat(" (Default)");
        }
        this.sceneCreator.setSelectedHotspot(property.spaceArea, property.cameraPosition);
        this.setUpHotspot(property.spaceArea);
        this.sceneCreator.selectedAreaSpace(property.spaceArea);
    }

    setDisclaimer = (message) => {
        if (message != this.state.show_disclaimer) {
            this.setState({
                show_disclaimer: message
            })
        }   
    }

    setRugDisclaimer = (state) => {
        let disclaimer = null;
        if (state) {
            disclaimer = "After moving the rug, move the items that were on the rug to the floor";
        }
        this.setDisclaimer(disclaimer);
    }

    setArrowKeysDisclaimer = (state) => {
        let disclaimer = null;
        if (state) {
            disclaimer = "Use the arrow keys to move the object around in the scene.";
        }
        this.setDisclaimer(disclaimer);
    }

    handleCreateImages = () => {
        this.setState({
            previous_scene_view_state: this.state.scene_view_state,
            previous_edit_mode: this.state.edit_mode,
            previous_mode: this.state.camera_mode,
            show_snapshots: true,
            hide_camera: true // hiding camera here to re-render the camera controls
        },()=>{
        this.setSceneViewState('images');
        this.setState({ sun_controls_view: 0, hide_camera: false });  
        this.handleSunModeSwitch(false); 
        this.SetOverlayLines();
        this.setOverlayLimit();
        this.sceneCreator.resetSelection();
        if (this.is3DModeActive()) {
            this.forceUpdate(()=>this.changeCameraControls(this.state.collaboration_save_camera, CAMERA_MODES.ThreeD));
        }
        })
        if (this.state.enable_space_configurator_mode) {
            this.setSpaceConfiguratorModeState(false);
        }
    }

    handleBackBtn = () => {
        this.setSceneViewState(this.state.previous_scene_view_state);
        this.setState({edit_mode: this.state.previous_edit_mode});
        this.setState({ camera_mode: this.state.previous_mode, hide_camera: true }, // hiding camera here to re-render the camera controls
            () => {
                this.setSceneViewState("design")
                this.setState({ hide_camera: false, sun_controls_view: 0 });
                this.handleSunModeSwitch(false);
        });
        this.setState({
            previous_edit_mode: null,
            previous_mode: null,
            previous_scene_view_state: null,
            show_snapshots: false
        })
    }
	
	populatePDFComments(pdf, comment, yPos, start, lineHeight, multiplier) {
        pdf.text("Date: " + Utilities.convertToCustomFormat(comment.created_on) + "   |   By: " + comment.created_by, 15, yPos + (lineHeight * (multiplier + 1)));
        // Wrap text for Message to handle horizontal overflow
        let messageWrappedText = pdf.splitTextToSize("Message: " + comment.message, pdf.internal.pageSize.width - 30, { fontSize: 12 });
        let mult = (multiplier + 2)
        // Check if the text exceeds the available width
        let i = 0;
        while (messageWrappedText.length > 1 && i != messageWrappedText.length - 1) {
            pdf.text(messageWrappedText[i], 15, yPos + (lineHeight * mult));
            i = i + 1;
            mult = mult + 1;
        }
        if (messageWrappedText.length == 1) {
            pdf.text(messageWrappedText, 15, yPos + (lineHeight * mult));
        }
        let start_index = mult + 1;
        if (comment.attachment && comment.attachment.length > 0) {
            comment.attachment.map((file, index) => {
                if (yPos + (lineHeight * start_index) > 275) {
                    pdf.addPage();
                    yPos = start;
                    start_index = 1;
                }
                let link = getBaseURL(this.platform) + BASE_URI + encodeURIComponent(file.uid + '/' + file.name);
                start_index = start_index + index;
                this.addLink(pdf, 15, yPos + (lineHeight * start_index), link);
            });
            yPos = yPos + (lineHeight * start_index);
        } else {
            yPos = yPos + (lineHeight * start_index - 1);
        }
        return yPos;
    }

	// Function to add a link with specified options
	addLink(pdf, x, y, url) {
		// Set link options
		var options = {
			url: url,
			color: '#007bff', // Set the color of the link (adjust as needed)
			underline: true, // Set to true to underline the link
		};

		pdf.setTextColor(options.color);
		pdf.textWithLink(url, x, y, options);
		pdf.setTextColor(0, 0, 0); // Reset text color to black
	}

	async convertRenderFeedbackToPDF(canvasData, id, save, loader = null) {
        const pdf = new jsPDF();

        let width = window.innerWidth;
		let height = window.innerHeight - CollaborateConstants.HEIGHT_OFFSET;
		if (width / height >= CollaborateConstants.ASPECT_RATIO) {
			height = height;
			width = height * CollaborateConstants.ASPECT_RATIO;
		}
		else {
			width = width;
			height = width * CollaborateConstants.ASPECT_RATIO_MULTIPLIER;
		}

        const processNextCanvas = async () => {
            if (canvasData && (Object.entries(canvasData).length !== 0)) {
                let url = this.state.collaboration_image_url;
                // Create a hidden canvas
                const hiddenCanvas = document.createElement('canvas');
                hiddenCanvas.width = 1600;
                hiddenCanvas.height = 900;
                const hiddenCanvasCtx = hiddenCanvas.getContext('2d');
                // Render the canvas based on JSON data
                await this.constructRenderPDF(url, canvasData, '#FFF', hiddenCanvasCtx, hiddenCanvas, pdf);
                pdf.addPage();
            }

            // All canvases processed, save the PDF
            if (save) {
                if (loader != null) {
                    setTimeout(loader);
                }
                pdf.save(id + '_Feedback.pdf');
            } else {
                let data_url_pdf = pdf.output('datauristring');
                return data_url_pdf;
            }
        }
        // Start processing the canvases
        return processNextCanvas();
    }

	async constructRenderPDF(imageUrl, canvasData, backgroundColor, context, canvas, pdf) {
        const img = new Image();
        const resize = async () => {
            // create a canvas element to manipulate    
            // setup some resizing definitions
            let { width, height } = img;
            var maxWidth = context.canvas.width;
            var maxHeight = context.canvas.height;
            var widthScale = maxWidth / width;
            var heightScale = maxHeight / height;
            var scale = Math.min(widthScale, heightScale);
            img.width *= scale;
            img.height *= scale;
            var imgLeft = (maxWidth - img.width) / 2;
            var imgTop = (maxHeight - img.height) / 2;

            context.drawImage(img, imgLeft, imgTop, img.width, img.height); // place the image on the canvas
            const data = context.getImageData(0, 0, maxWidth, maxHeight); // get the current ImageData for the canvas
            const compositeOperation = context.globalCompositeOperation; // store the current globalCompositeOperation          
            context.globalCompositeOperation = 'destination-over'; // set to draw behind current content
            context.fillStyle = backgroundColor; // set background color
            context.fillRect(0, 0, maxWidth, maxHeight); // draw background / rect on the entire canvas
            context.globalCompositeOperation = compositeOperation; // reset the globalCompositeOperation to what it was
            let state = canvasData;
            // let group_objects = canvasData.group_objects;
            let original_width = state.canvas_width;
            let original_height = state.canvas_height;

            // Calculate scaling factors
            var scaleX = context.canvas.width / original_width;
            var scaleY = context.canvas.height / original_height;

            // Load and scale objects
            state.objects.forEach(objData => {
                if (objData.type == "group") {
                    window.fabric.util.enlivenObjects(objData.objects, enlivenedObjects => {
                        const group = new window.fabric.Group(enlivenedObjects, {
                            // Set group options
                            scaleX: objData.scaleX * scaleX,
                            scaleY: objData.scaleY * scaleY,
                            left: objData.left * scaleX,
                            top: objData.top * scaleY,
                            width: objData.width * scaleX,
                            height: objData.height * scaleY,
                            annotation_id: objData.annotation_id,
                            sequence_no: objData.sequence_no,
                            is_resolved: objData.is_resolved
                        });
                        window.fabric.util.enlivenObjects(objData.objects, (enlivenedObjects) => {
                            group._objects = enlivenedObjects;
                            group.setCoords();
                            group.render(context);
                        });
                    });
                } else {
                    window.fabric.util.enlivenObjects([objData], ([obj]) => {
                        obj.height = objData.height * scaleY;
                        obj.width = objData.width * scaleX;
                        obj.scaleX = objData.scaleX * scaleX;
                        obj.scaleY = objData.scaleY * scaleY;
                        obj.left = objData.left * scaleX;
                        obj.top = objData.top * scaleY;
                        obj.setCoords();
                        obj.render(context);
                    });
                }
            });

            const imageData = canvas.toDataURL('image/png'); // get the image data from the canvas
            context.clearRect(0, 0, maxWidth, maxHeight); // clear the canvas
            context.putImageData(data, 0, 0); // restore it with original / cached ImageData
            const desiredWidth = 200; // adjust as needed (size for the image in the PDF)
            const desiredHeight = 200; // adjust as needed (size for the image in the PDF)
            // Calculate the scaling factors to fit the image inside the desired size
            const scaleFactor = Math.min(desiredWidth / context.canvas.width, desiredHeight / context.canvas.height);
            // Calculate the scaled width and height
            const scaledWidth = context.canvas.width * scaleFactor;
            const scaledHeight = context.canvas.height * scaleFactor;
            // Add the scaled image to the PDF
            let x = (pdf.internal.pageSize.width - scaledWidth) / 2;
            let y = (pdf.internal.pageSize.height - scaledHeight) / 2;
            pdf.addImage(imageData, 'png', x, y, scaledWidth, scaledHeight);
            this.addCommentsLogicInPDF(pdf, canvasData, 'scene_render');
        };

        return new Promise((resolve, reject) => {
            img.onload = () => resolve(resize());
            img.onerror = (err) => {reject(new Error('Error loading image from URL'))};
            img.crossOrigin = 'anonymous';
            img.src = imageUrl;
        });
    }

	addCommentsLogicInPDF(pdf, canvasData, type = 'product') {
		pdf.setFontSize(10);
		let lineHeight = 8; // Adjust this value based on the desired spacing between lines
		let start = 16;
		// Add text to the PDF
		let all_comments = this.state.collaboration_comments.filter(comment => (!comment.is_hidden));
		if (type == 'product') {
			all_comments = this.state.collaboration_comments.filter(comment => (comment.product_qa_collab_id == canvasData.id) && ( !comment.is_hidden));
		}
		if (all_comments.length > 0) {
			all_comments.sort((a, b) => a.sequence_no - b.sequence_no || b.created_on.localeCompare(a.created_on));
			pdf.addPage();
			pdf.setFont('helvetica', 'bold');
			pdf.text("Comments for this image:", 15, 8);
			let sequence_printed = [];
			let yPos = start;
			all_comments.forEach((comment, index) => {
				// Check if there's enough space on the current page
				if (yPos > 275) {
					pdf.addPage();
					yPos = start;
				}
				if (!sequence_printed.includes(comment.sequence_no)) {
					sequence_printed.push(comment.sequence_no);
					pdf.setFont('helvetica', 'bold');
					pdf.text("Comment No: " + comment.sequence_no, 15, yPos + lineHeight);
					pdf.setFont('helvetica', 'normal');
					yPos = this.populatePDFComments(pdf, comment, yPos, start, lineHeight, 1);
				} else {
					yPos = this.populatePDFComments(pdf, comment, yPos, start, lineHeight, 0);
				}
			});
		}
	}

    getCommentHistory = () => {
        let payload = {
            "required_fields": ["comment_id", "annotation_id", "message", "sequence_no",
                "created_by", "created_on", "attachment", "tagged_users", "is_resolved"],
            "filter_string": `(collaboration_id__exact=${this.props.match.params.id}&&is_hidden__exact=false&&version_number__isnull=true)`,
            "order_by": "created_on desc"
        }

        axios.post(ENVIRONMENT.COMMENT_GET_BATCH, payload)
            .then(res => {
                if (res && res.data && res.data.length > 0) {
                    this.setState({
                        collaboration_comments: res.data
                    });
                }
            });
    }

    generateVersionPDF = async () => {
        const camera_name = this.state.collaboration_original_camera.split('.')[0]
        let blobi = await this.convertRenderFeedbackToPDF(this.state.collaboration_state["data"],this.state.collaboration_id,false);
        this.uploadVersionPDF(blobi,camera_name,null)
    }

    uploadPDFToGoogle = (blobData, name, reject, callback = undefined) => {
        // Create a Buffer from the Uint8Array
        let buf = Buffer.from(this.convertToProperFormat(blobData));

        let thumbnail_path = ENVIRONMENT.COLLABORATION_VERSION_PDF + this.state.collaboration_id + "/" + name + '.pdf';

        if (this.isSignedURLvalid(this.state.collaboration_signed_url)) {
            let signedUrl = this.state.collaboration_signed_url;
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', this.state.collaboration_signed_url, true);

            xhr.onload = function () {
                if (xhr.status === 200) {
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                        'action': 'make_file_public',
                        'file_uri': thumbnail_path
                    })
                    this.setState({
                        submit_collaboration_render_done_visbile: true,
                        submit_collaboration_render_modal: false,
                        confirm_image_loader: false
                    });
                }
            };

            xhr.send(buf);
        }
        else {
            axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                'action': 'get_asigned_urls',
                'file_uri': thumbnail_path
            })
                .then(res => {
                    let signedUrl = res.data;
                    this.setState({
                        collaboration_signed_url: signedUrl
                    });

                    const xhr = new XMLHttpRequest();
                    xhr.open('PUT', signedUrl, true);

                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                                'action': 'make_file_public',
                                'file_uri': thumbnail_path
                            })
                            this.setState({
                                submit_collaboration_render_done_visbile: true,
                                submit_collaboration_render_modal: false,
                                confirm_image_loader: false
                            });
                        }
                    };

                    xhr.send(buf);
                });
        }
    }

    uploadPDFToAWS = (blobData, name, reject, callback = undefined) => {

        // Create a Buffer from the Uint8Array
        let buf = Buffer.from(this.convertToProperFormat(blobData));

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
            () => {
                AWS.config.update({
                    region: 'us-west-2',
                    credentials: cognito_credentials
                });
                const S3 = new AWS.S3();
                const objParams = {
                    Bucket: BUCKET_NAME,
                    Key: ENVIRONMENT.COLLABORATION_VERSION_PDF + this.state.collaboration_id + "/" + name + '.pdf',
                    ACL: 'public-read',
                    Body: buf,
                    ContentEncoding: 'base64',
                    ContentType: "application/pdf",
                    CacheControl: 'no-cache'
                };


                S3.upload(objParams, (err, data) => {
                    console.log(err, data, objParams)
                    this.setState({
                        submit_collaboration_render_done_visbile: true,
                        submit_collaboration_render_modal: false,
                        confirm_image_loader: false
                    });
                });
            });
    }

    uploadVersionPDF = (blobData, name, reject, callback = undefined) => {
        if (this.state.collaboration_platform == 'google') {
            this.uploadPDFToGoogle(blobData, name, reject, callback)
        } else {
            this.uploadPDFToAWS(blobData, name, reject, callback)
        }
    }

    convertToProperFormat = (blobData) => {
        // Ensure blobData is a base64-encoded string
        let base64Data = blobData.split(',')[1];

        // Use atob to decode base64
        let binaryData = atob(base64Data);

        // Create a Uint8Array from the binary data
        let array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            array[i] = binaryData.charCodeAt(i);
        }

        return array;
    }

    checkExistingSnapshots = () => {
        let snapshots = []
        this.state.cameras.map((camera,index) => {
            if (!this.checkIfRenderedAlready(this.state.final_renders,camera['name'])) {
                snapshots.push(camera);
            }
        })
        
        return snapshots;
    }
    
    getCollectionProducts(collection_id) {
        axios.post(ENVIRONMENT.FETCH_COLLECTION_PRODUCTS, collection_id)
        .then(res => { 
            if (res.data?.length) {
                const collectionAssetIds = res.data.map((asset)=>{
                    return asset.id;
                });
                this.setState({
                    collection_products: collectionAssetIds
                })
            }
        })
    }
    
    changePlacementType = (placementType) => {
        this.sceneCreator.changePlacementOfObject(placementType)
    }

    resetPlacementType = () => {
        const originalPlacement = this.sceneCreator.getSelectionOriginalPlacement();
        if (originalPlacement) {
            this.sceneCreator.changePlacementOfObject(originalPlacement)
        }
    }

    resetFreeModeTransform = () => {
        this.sceneCreator.resetFreeModeTransform(this.state.freeModeState);
    }

    // enable / disable the free rotation controls menu
    showFreeModeState = ( state ) => {
        this.setState({
            freeModeState : state
        })
        if (state != "off") {
            this.setDisclaimer("To select other products, turn off Free mode")
        }
        else {
            this.setDisclaimer("");
        }
    }

    setFreeMode = (mode) => {
        this.sceneCreator.setFreeModeControls(mode);
    }

    flipProduct = () => {
        this.sceneCreator.flipProduct();
    }

    handleUndoPositionClick = (value) => {
        this.setState({
            undo_modal: value,
        })
    }

    UndoPosition = (items) => {
        this.sceneCreator.autoPlaceLostAssets(items)
    }

    render() {

        return (
            <div>
            <Row type="flex" justify="space-between" >
                <div className="scene-creator-header">
                    <Row type="flex" className={this.state.scene_view_state == "create360" ? "camera-placement-align" : ""} style={{width: '100%'}}>
                       { this.state.scene_view_state == 'create360' ?
                       <Col span={6} style={{display: 'flex',justifyContent:"flex-start",marginLeft: 20}}>
                        <Popover placement="bottomLeft" content={'Position Camera for Your 360'}>
                           <div className="scene-creator-room-name">Position Camera for Your 360</div>
                        </Popover>
                       </Col> :
                       <Col span={3} style={{display: 'flex',justifyContent:"center"}}>
                            <Popover placement="bottomLeft" content={this.state.scene_name}>
                                <div className="scene-creator-room-name" style={{minWidth: 77}}>{this.state.scene_name}</div>
                            </Popover>
                            <div className="separator"></div>
                        </Col>}

                        {this.state.scene_view_state == 'create360' ? <Col span={14}>
                            <div style={{marginRight: 30, background: "rgba(39, 109, 215, 0.06)",borderRadius: "4px",position:"absolute",top:"14px",right:"0px",padding:"10px 16px"}}>
                                <span className="scene-note">
                                    Note: You can select and requesting specific 360s by post clicking on {this.state.tour_flag ? "Request 3D Tour" : "Request 360"}
                                </span>
                            </div>
                            </Col>: ""}
                        <Col span={2} style={{display:(this.state.scene_view_state == 'create360') ? "none" : "inline-block"}}>
                            <div className="container-box" style={{marginLeft: "25px"}}>
                                <span style={{cursor: !this.state.admin_scene_creator ? "pointer" : "not-allowed"}}>
                                    <span  style={{pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d || this.state.admin_scene_creator ||  this.state.scene_check) ?  "none" : "auto"}}  onClick={() => this.saveScene(true)}>
                                        <img src={require("../../../../assets/images/save-icon.svg")} className="contained-icon"></img>
                                        <div className="icon-label-text6">Save</div>
                                    </span>
                                </span>
                            </div>
                        </Col>
                        <Col span={!!this.state.swapButtonRef?.current ? 4 : 3} style={{display:"flex"}} style={{display:"flex"}}>
                            <div className="container-box" style={{marginRight: "24px"}}>
                                {(!this.state.admin_scene_creator)?
                                <span  style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d || this.state.admin_scene_creator ||  this.state.scene_check) ?  "none" : "auto"}}  
                                onClick={() => this.toggleAddProductModal(true)}>
                                    <img src={require("../../../../assets/images/add-product.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text7">Add Products</div>
                                </span>:
                                <span  style={{cursor: "not-allowed"}}>
                                    <img src={require("../../../../assets/images/add-product.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text7">Add Products</div>
                                </span>
                                }
                            </div>
                            <div className="container-box">
                                <Tooltip placement="bottom" title="Swap this space">
                                    <span  style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d || this.state.admin_scene_creator ||  this.state.scene_check) ?  "none" : "auto"}}  
                                    onClick={() => this.toggleSpaceSwapModal(true)}>
                                        <img src={require("../../../../assets/images/swap-space.svg")} className="contained-icon"></img>
                                        <div className="icon-label-text7 m-12">Swap Space</div>
                                    </span>
                                </Tooltip>
                            </div>
                            {!this.state.customer_dummy_scene && !this.state.space_variation_mode &&
                                <div className="container-box" style={{marginLeft: "24px"}}> 
                                <Tooltip placement="bottom" title="Reset Lost Product Position">
                                    <span  style={{cursor: "pointer", pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d || this.state.admin_scene_creator ||  this.state.scene_check) ?  "none" : "auto"}}  
                                    onClick={() => {
                                        this.handleUndoPositionClick(true)
                                    }}>
                                        <RollbackOutlined className="contained-icon"/>
                                        <div className="icon-label-text7 m-12">Reset Product</div>
                                    </span>
                                </Tooltip>
                            </div>
                            }
                            {!!this.state.swapButtonRef?.current &&
                                <div className="container-box" style={{marginLeft: "24px"}}> 
                                <Tooltip placement="bottom" title="Undo swap space">
                                    <span  style={{cursor: "pointer", pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d || this.state.admin_scene_creator ||  this.state.scene_check) ?  "none" : "auto"}}  
                                    onClick={() => {
                                        this.state.swapButtonRef.current.click()
                                    }}>
                                        <UndoOutlined className="contained-icon"/>
                                        <div className="icon-label-text6">Reset</div>
                                    </span>
                                </Tooltip>
                            </div>
                            }
                        </Col>
                        <Col span={!!this.state.swapButtonRef?.current ? 3 : 4} style={{display:(this.state.scene_view_state == 'create360') ? "none" : "flex",justifyContent:"flex-end"}}>
                            <div className="container-box" style={{marginRight:"14px"}}>
                                { (this.state.camera_mode == CAMERA_MODES.FlatView) ?
                                    <div style={{ cursor: this.state.scene_view_state != "images" ? "pointer":"not-allowed"}}>
                                        <span className='camera-mode-sc' style={{ cursor: "pointer", pointerEvents: (this.state.scene_view_state == "images" || this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto" }} onClick={() => { this.resetFlatViewModeState(); }}>
                                        <img src={require("../../../../assets/images/flat-view-controls-selected-icon.svg")} className="contained-icon"></img>
                                        <div className="icon-label-text text-wrap" style={{color: '#276dd7'}}>Flat View</div>
                                        </span>
                                    </div> :
                                    <div style={{ cursor: this.state.scene_view_state != "images" ? "pointer":"not-allowed"}}>
                                        <span className='camera-mode-sc' style={{cursor: "pointer",pointerEvents:(this.state.scene_view_state == "images" || this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => { this.resetFlatViewModeState(); }}>
                                            <img src={require("../../../../assets/images/flat-view-controls-icon.svg")} className="contained-icon"></img>
                                            <div className="icon-label-text text-wrap">Flat View</div>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className="container-box" style={{marginRight:"14px"}}>
                                { (this.state.camera_mode == CAMERA_MODES.TopDown) ?
                                        <span className='camera-mode-sc' style={{ cursor: "pointer", pointerEvents: (this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto" }} onClick={() => { this.resetTopDownModeState(this.state.scene_view_state != "images") }}>
                                    <img src={require("../../../../assets/images/2d-controls-selected-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text text-wrap" style={{color: '#276dd7'}}>Top Down</div>
                                </span> :
                                <span className='camera-mode-sc' style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => { this.resetTopDownModeState(this.state.scene_view_state != "images") }}>
                                    <img src={require("../../../../assets/images/2d-controls-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text text-wrap">Top Down</div>
                                </span>
                                }
                            </div>
                            <div className="container-box">
                            { (this.state.camera_mode == CAMERA_MODES.ThreeD) ?
                                <span className='camera-mode-sc' style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => { this.reset3DModeState(this.state.scene_view_state != 'images') }}>
                                    <img src={require("../../../../assets/images/3d-controls-selected-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text text-wrap" style={{color: '#276dd7'}}>3D View</div>
                                </span>:
                                <span className='camera-mode-sc' style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => {this.reset3DModeState(this.state.scene_view_state != 'images') }}>
                                    <img src={require("../../../../assets/images/3d-controls-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text text-wrap">3D View</div>
                                </span>
                            }

                            </div>
                            </Col>

                            <Col span={7} style={{display:(this.state.scene_view_state == 'create360') ? "none" : "flex", justifyContent: 'center'}}>
                                <div className="container-box" style={{marginRight: 15}}>
                                    {this.state.enable_measurement_tool == false ?
                                    <span onClick={()=> this.enableMeasurementTool(true) } style={{cursor:"pointer", display:"flex",flexDirection:"column",alignItems:"center"}}>
                                        <img src={require("../../../../assets/images/ruler.svg")} className="contained-icon"></img>
                                        <div className="icon-label-text">Measure</div>
                                    </span>
                                    :
                                    <span onClick={()=>this.enableMeasurementTool(false)} style={{cursor:"pointer", display:"flex",flexDirection:"column",alignItems:"center"}}>
                                        <img src={require("../../../../assets/images/ruler-selected.svg")} className="contained-icon"></img>
                                        <div className="icon-label-text" style={{color: '#276dd7'}}>Measure</div>
                                    </span>}
                                </div>
                                
                                <span style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <div className="container-box">
                                        <Switch disabled={(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d || this.state.scene_check) ? true : false} style={{marginLeft:4.5 , marginTop: 11}} size="small" onChange={(checked) => this.sceneCreator.snappingEnabled = checked} />
                                    </div>
                                    <div className="icon-label-text5">Snapping</div>
                                </span>
                                <div className="container-box" onClick={this.openGridSettingsModal}
                                style={{cursor: "pointer",marginLeft: 15,display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <img src={require("../../../../assets/images/grid.svg")} className="contained-icon-grid"></img>
                                    <div className="icon-label-text5" style={{marginTop: 6}}>Grid</div>
                                </div>
                                <div  className="container-box" onClick={ this.is3DModeActive() || this.state.scene_view_state == "images" ? () => { this.OpenOverlaySettingsModal()}:''}
                                style={{ cursor: this.is3DModeActive() || this.state.scene_view_state == "images" ? "pointer":"not-allowed",marginLeft: 15,display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <img src={require("../../../../assets/images/safeZone.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text5" style={{whiteSpace:'nowrap', marginTop: 5}}>Safe Zone</div>
                                </div>
                            { this.state.is_space_configurable ?
                                <div className="container-box" style={{marginLeft: 15}}>
                            { (this.is3DModeActive() && this.state.enable_space_configurator_mode == true) ?
                                <span style={{cursor: (this.is3DModeActive() && this.state.scene_view_state == "design" ) ? "pointer":"not-allowed"
                                            ,pointerEvents:"auto"}} onClick={() => { if (this.is3DModeActive() && this.state.scene_view_state == "design" ) this.setSpaceConfiguratorModeState(false) }}>
                                    <img src={require("../../../../assets/images/space-configuration-icon-selected.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text" style={{color: '#276dd7'}}>Space</div>
                                </span>
                                :
                                <div>
                                {!this.is3DModeActive() ?
                                <Popover overlayStyle={{width: "12vw"}} placement="bottom" content={"Move to 3D mode to change color or materials on walls, floors and ceilings"}>
                                <span  style={{cursor: (this.is3DModeActive() && this.state.scene_view_state == "design"  ) ? "pointer":"not-allowed",
                                                pointerEvents:"auto"}} onClick={() => { if ( this.is3DModeActive() && this.state.scene_view_state == "design"  ) this.setSpaceConfiguratorModeState(true)}}>
                                    <img src={require("../../../../assets/images/space-configuration-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text">Space</div>
                                </span>
                                </Popover>
                                :
                                <Popover overlayStyle={{width: "12vw"}} placement="bottom" content={"Space Configuration Mode allows to click and select walls, floors and ceilings to change their colors or materials."}>
                                <span style={{cursor: (this.is3DModeActive() && this.state.scene_view_state == "design" ) ? "pointer":"not-allowed",
                                                pointerEvents:"auto"}} onClick={() => {if (this.is3DModeActive() && this.state.scene_view_state == "design" ) this.setSpaceConfiguratorModeState(true)}}>
                                    <img src={require("../../../../assets/images/space-configuration-icon.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text">Space</div>
                                </span>
                                </Popover>
                                }
                                </div>
                            }
                            </div>
                            : ""
                            }
                            </Col>

                            <Col span={2} style={{display:(this.state.scene_view_state == 'create360') ? "none" : "flex", justifyContent: 'center'}}>
                            <div >
                                <div className="double-container-box scene-creator-zoom">
                                    <span style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}}  onClick={() => this.zoomIn()}>
                                        <img src={require("../../../../assets/images/zoom-in-icon.svg")} className="contained-icon-bigger"></img>
                                    </span>
                                    <div className="separator-no-margin"></div>
                                    <span style={{cursor: "pointer",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}}  onClick={() => this.zoomOut()}>
                                        <img src={require("../../../../assets/images/zoom-out-icon.svg")} className="contained-icon-bigger"></img>
                                    </span>
                                </div>

                                <div className="icon-label-text2" style={{textAlign: 'center',width: '100%'}} >Zoom</div>
                            </div>
                            </Col>
                            <Col span={1} style={{display:(this.state.scene_view_state == 'create360') ? "none" : "flex", justifyContent: "center"}}>
                                {/* <span style={{cursor: "pointer"}} onClick={() => this.showShortcutsModel()}>
                                    <div className="container-box">
                                    <img src={require("../../../../assets/images/shortcuts-icon.svg")} class="contained-icon"></img>
                                        <div className="icon-label-text3">Shortcuts</div>
                                    </div>
                                </span> */}
                                <div className="container-box">
                                    <div style={{cursor: "pointer",display:"flex",flexDirection:"column",alignItems:"center",pointerEvents:(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? "none" : "auto"}} onClick={() => {this.setFullscreen();}}>
                                        <img src={this.state.fullscreen == false ? require("../../../../assets/images/full-screen-icon.svg") : require("../../../../assets/images/full-screen-selected-icon.svg")} class="contained-icon"></img>
                                        <div className="icon-label-text3">Fullscreen</div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col span={3} style={{display: 'flex', marginTop: 22, justifyContent: 'center'}}>
                                <div style={{display: "inline"}}>
                                    <SceneCreatorUserButton></SceneCreatorUserButton>
                                </div>
                            </Col> */}
                    </Row>
                </div>
                <Col span={((this.isTopDownModeActive() && this.sceneCreator && !this.state.isLoading) || (this.state.scene_view_state == 'design' && !this.isTopDownModeActive()) || this.state.scene_view_state == 'images') ? 19: 24} style={{overflow: 'hidden'}}>
                {this.state.isLoading ? this.state.loading_progress_message == "Loading Failed!" ?
                <div className="load-container">
                    {this.state.isUpdating ? 
                    <div>
                        <WarningOutlined style={{textAlign: "center", fontSize:"24px",color:"red",marginBottom: 10}} size="large"/>
                        <h3 className="load-message large">Oops! Something went wrong!</h3>
                        <h3 className="load-message">There was an error while placing products in your Scene. </h3>
                        <h3 className="load-message">Please refresh your browser tab to reload your Scene.</h3>
                    </div>
                    : 
                    <div>
                        <WarningOutlined style={{textAlign: "center", fontSize:"24px",color:"red",marginBottom: 10}} size="large"/>
                        <h3 className="load-message large">Oops! Something went wrong!</h3>
                        <h3 className="load-message">There was an error while loading your Scene. </h3>
                        <h3 className="load-message">Please refresh your browser tab to reload your Scene.</h3>
                    </div>
                    }
                </div>:
                <div className="load-container">
                    {this.state.swap_product_loading ?
                    <div>
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">Swapping out your Product </h3>
                    <h3 className="load-message">This should only take a few moments...</h3>
                    </div>:
                    this.state.swap_space_loading ?
                    <div>
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">Swapping out your Space </h3>
                    <h3 className="load-message">This should only take a few moments...</h3>
                    </div>:
                    this.state.isUpdating ?
                    <div>
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">Placing products in Scene </h3>
                    <h3 className="load-message">Please wait a few moments while we place products in your scene.</h3>
                    </div>:
                    <div>
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">Setting up your Scene </h3>
                    <h3 className="load-message">Please wait a few moments while we set up your scene in the Scene Creator.</h3>
                    <h3 id="loadingMessage" className="load-message">{this.state.loading_progress_message}</h3>
                    </div>
                    }
                </div>: ""}
                { this.is3DModeActive() &&
                    <div className='camera-reticle'>
                        <img src='/img/reticle.svg' />
                    </div>
                }
                {this.state.progress_loader_360 ?
                <div className="load-container">
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">Setting up Camera Placement Flow </h3>
                    <h3 className="load-message">Please wait while camera hotspots are being placed in space. </h3>
                </div>
                : ""}
                {this.state.progress_loader_3d ?
                <div className="load-container">
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">Setting up Camera Placement Flow </h3>
                    <h3 className="load-message">Please wait while camera hotspots are being placed in space. </h3>
                </div>
                : ""}

                {this.state.scene_check && this.state.json_expired ?
                <div className="load-container">
                    <LoadingOutlined style={{textAlign: "center", fontSize:"24px"}} size="large" spin />
                    <h3 className="load-message large">The JSON Has Expired</h3>
                    <h3 className="load-message">Please reupload the JSON to continue testing. </h3>
                </div>
                : ""}
                    <div id="cddrawer-container" style={{position: "relative" , filter: ( this.state.isLoading?"blur(12px)": (this.state.progress_loader_360 || this.state.progress_loader_3d ? "blur(12px)" : "")) }}>
                            <div id="scene-creator"
                            onMouseLeave={() => {
                                    if (this.sceneCreator) { 
                                        this.sceneCreator.resetSpaceConfiguratorFocus();
                                    }
                                }
                            }>
                                { this.state.show_disclaimer ? 
                                    <div style = {{ backgroundColor:"#ffffff", border:"2px solid #d48b02", borderRadius:"5px", position: 'absolute', bottom: "2%", right: "2%", padding: "4px 4px 4px 4px"}}>
                                        <img src={require("../../../../assets/images/warning.png")} style={{width:"25px", height:"25px", display: "block" , position: "absolute" , marginTop: "5px", marginLeft: "5px"}} />
                                        <div style = {{marginLeft:"35px", marginTop:"5px", marginRight:"10px", marginBottom : "10px", fontFamily:"manrope", fontSize: "15px"}}>{this.state.show_disclaimer}</div>
                                    </div>
                                : ''}
                                {(this.state.sun_controls_view == 1) ?
                                <div style={{position: 'absolute', top: 50, left:'19%',backgroundColor: "white",borderRadius: "4px" }}>
                                    <div id="joyDiv" className="sun-positioning"></div>
                                </div> :
                                <div style={{visibility: "hidden", height: 0, width: 0}}>
                                    <div id="joyDiv" className="sun-positioning"></div>
                                </div>
                                }
                                <div id = "grid">
                                    <img id="grid-snapshot" src={require("../../../../assets/images/grid.png")} style={{display: "none" , position: "absolute" ,zIndex:"1"}} />
                                </div>
                                <Tooltip title="Clone this product" placement="top">
                                    <input id="duplicate-button" type="image" src={require("../../../../assets/images/plus-circle-white.svg")} />
                                </Tooltip>
                                <Tooltip title="Swap this product" placement="top">
                                    <input id="expand-button" type="image" src={require("../../../../assets/images/expand-circle-white.svg")} />
                                </Tooltip>
                                <Tooltip title="Delete this product" placement="top">
                                    <input id="delete-button" type="image" src={require("../../../../assets/images/x-circle-white.svg")} />
                                </Tooltip>
                                <Tooltip title="Transform this product" placement="top">
                                    <input id="size-button" type="image" src={require("../../../../assets/images/resize-circle-white.svg")} />
                                </Tooltip>
                                <Tooltip title={"Lock positioning of this product"} placement="top">
                                    <input id="freeze-button" style={{ width:"1.5vw "}} type="image" src={'/img/unlock.svg'} />
                                </Tooltip>
                                <img id="hover-image" style={{height: 100, width: 100, display: "none", border: "2px solid black"}} />
                                {(this.state.scene_view_state == 'create360') ? ((this.state.mode_360 == 'preview') ? "" : (this.state.hotspots.length > 1 ?
                                this.state.hotspots.map((ele) => (
                                    <img src={ele.props.src} id={ele.props.id} style={ele.props.style} onClick={() => this.selectCamera(ele.props)}/>
                                )):
                                this.state.hotspots)): ""}

                                <div className="cursorLink" style={{cursor: 'grabbing', display:"none",animationDelay:"3s",zIndex:"4"}} id="camera">
                                    {(this.state.scene_view_state == 'create360') ? ((this.state.mode_360 == 'preview') ? "" :
                                        <img src="/img/active-camera.svg" id="camIcon" style={{height:"32px",width:"32px",borderRadius:"50%"}} className="cursorLink"/>)
                                    : ""
                                    }
                                </div>
                                <div id="preview-container" style={{display: "none",zIndex:"11",background: "white",flexWrap:"wrap-reverse"}}>
                                    <p id="selected-camera" className="hover-btn-text" style={{display:"none",marginLeft: 0}}>Selected Camera:</p>
                                    <p id="default-camera" className="hover-btn-text" style={{display:"none", filter:"blur(4px)"}}>{this.state.image_property[this.state.selected_area_preview]}</p>
                                    <input type="image" id="enlarge-preview" src="/img/Enlarge.png"  style={{width:"24px", height:"24px", position: "absolute", top: "8px", right:"8px", zIndex:"14", display:"none"}}/>
                                    <input type="image" id="close-preview" src="/img/closeIcon.png"  style={{width:"24px", height:"24px", position: "absolute", top: "8px", right:"8px", zIndex:"14", display:"none"}}/>
                                </div>
                                <div id="overlay-blur-top" className='overlay-blur'>
                                </div>
                                <div id="overlay-blur-bottom" className='overlay-blur'>
                                </div>
                                <div id="overlay-blur-left" className='overlay-blur'>
                                </div>
                                <div id="overlay-blur-right" className='overlay-blur'>
                                </div>
                            </div>

                            {(this.state.scene_view_state == 'create360') ? ((this.state.mode_360 == 'preview') ?
                            <div style={{ position: "absolute", display: "flex", bottom: "32px",left:"32px",zIndex:"10" }}>
                               {this.state.space_areas.length > 1 ? (this.state.space_areas.map((area) => (
                                    ((area == "Outdoor" || area == "Outside" ) && !this.sceneCreator.isOutdoorCamera()) ? "" :
                                    <Card bodyStyle={{ padding: "0px" }} style={{marginRight:"10px",cursor:"pointer"}} onClick={()=>{this.setSpaceCamera(area)}} bordered={false}>
                                        <div className="card-360-btn" style={{border: this.state.selected_area_preview == area ? "1px solid #FFFFFF" : "none"}}>
                                                {this.state.selected_area_preview == area ? <img src="/img/eye.svg" height="24px" width="24px" style={{marginBottom:"10px"}}/> : ""}
                                                <div className="card-360-btn-text">{area}</div>
                                        </div>
                                    </Card>
                                ))) : ""}
                            </div> :
                            <div style={{ position: "absolute", display: "flex", bottom: "32px",left:"32px",zIndex:"10" }}>
                               {this.state.space_areas.length > 1 ? (this.state.space_areas.map((area) => (
                                    ((area == "Outdoor" || area == "Outside" ) && !this.sceneCreator.isOutdoorCamera()) ? "" :
                                    <Card bodyStyle={{ padding: "0px" }} style={{marginRight:"10px",cursor:"pointer"}} onClick={()=>{this.setZoomCamera(area)}} bordered={false}>
                                        <div className="card-360-btn"  style={{border: this.state.selected_area_preview == area ? "1px solid #ffffff" : "none"}}>
                                                {this.state.selected_area_preview == area ? <img src="/img/eye.svg" height="24px" width="24px" style={{marginBottom:"10px"}}/> : ""}
                                                <div className="card-360-btn-text">{area}</div>
                                        </div>
                                    </Card>
                                ))) : ""}
                            </div>):""}

                    </div>

                    {this.state.enableProductSizeControls &&
                        <div className='size-controls-sc'>
                            <TransformControls 
                                showProductSizeControls={this.showProductSizeControls} 
                                productSize={this.state.productSize} 
                                setProductSize={this.setProductSize} 
                                resetProductSize={this.resetProductSize} 
                                placementType = {this.sceneCreator.getSelectionPlacementType()}
                                changePlacementType = {this.changePlacementType} 
                                resetPlacementType = {this.resetPlacementType}
                                resetFreeModeTransform = {this.resetFreeModeTransform}
                                freeMode={this.state.freeModeState}
                                setFreeMode = {this.setFreeMode}
                                flipProduct ={this.flipProduct}
                            />
                        </div>
                    }

                </Col>
                {(this.state.scene_view_state == 'create360') ? "" :
                (!this.state.swap_space_loading && ((this.isTopDownModeActive() && this.sceneCreator) || (this.state.scene_view_state == 'images') || (!this.isTopDownModeActive() && !this.state.show_space_configurator_menu)))?
                <Col  span={5} style={{display: (this.state.scene_view_state == 'images' || this.state.scene_view_state == 'design' ) ? "block" : "none", backgroundColor: "white"}}>
                    <Card id ='camera-sun-controls' onScroll={() => this.scrollFunction()} onMouseOut={()=> {this.sceneCreator.enableKey()}}  style={{height:this.state.show_snapshots ? "70%":"100%", overflowY:'auto', overflowX:'hidden', width:"100%"}}>
                    <div style={{width:"100%", height:"100%"}}>
                        {this.state.show_snapshots ? <Button style={{display: this.isFlatViewModeActive() || this.state.isLoading ? "none": "block"}} id ='camera-sun-controls-button' onClick={() => this.scrollToBottom()} className='scroll-bottom'>
                            <span>
                                Scroll to Bottom
                            </span>
                            <DownOutlined/>
                        </Button> : '' }
                        <div style={{position: 'absolute', left:"10%", top:"1%", width:'80%', height:"10%"}}>
                            <Radio.Group style={{width:"100%",height:this.state.show_snapshots ? "80%" : "70%"}} value={this.state.sun_controls_view?"sun" : this.state.hide_camera? '': 'camera'} buttonStyle="solid" >
                                <Radio.Button className="control-button" style={{width:"50%", height:this.state.show_snapshots ? "80%" : "70%",fontSize:"0.7vw",whiteSpace:"nowrap",textAlign:'center'}} value='camera' content={"Camera Controls"}   onClick={() => {this.viewCameraControls()}}>
                                            Camera Controls
                                    </Radio.Button>
                                    <Radio.Button className="control-button" style={{width:"50%",height:this.state.show_snapshots ? "80%" : "70%", fontSize:"0.7vw",whiteSpace:"nowrap",marginBottom:"1%",textAlign:'center'}} content={"Sun Controls"} value='sun' onClick={() => {this.viewSunControls()}}>
                                            Sun Controls
                                    </Radio.Button>
                            </Radio.Group>
                        </div>
                        {(this.state.sun_controls_view == 1) ?
                            (this.sceneCreator.isCustomLighting()) ?
                            <div id="sun-control-div">
                                <span>The Space Model contains custom lighting so Sun Controls are disabled in the Scene Creator.</span>
                            </div> :
                            <>
                                <div className='sun-mode-switch-container'>
                                    <Switch checkedChildren="Gray mode" unCheckedChildren="Default Mode" defaultChecked
                                    checked={this.state.is_gray_mode}
                                    onChange={this.handleSunModeSwitch} 
                                    size = "large"
                                    style={{backgroundColor: this.state.is_gray_mode && '#276DD7',
                                    color: this.state.is_gray_mode && '#ffffff'
                                    }}/>
                                    <Tooltip title={this.state.is_gray_mode ? "Gray mode helps to view the sun effects" : "Switch to Gray mode to view the sun effects"} placement="top">
                                        <img className="align-icon medium-size" src={require("../../../../assets/images/help-icon-2.svg")}></img>
                                    </Tooltip>
                                </div>
                                <div style={ { margin: "auto", marginTop: "5%", position:"absolute", width:"100%" } }>
                                    <p style={{ fontSize: "16px", textAlign:'center', userSelect: 'none', marginBottom: 0 }}>Direction</p>
                                    <SunDirection
                                    roomImg = {this.state.room_img}
                                    value={this.state.sun_azimuthal}
                                    onChange={value => this.setAzimuthal(value)}/>
                                </div>
                                <div style={ { margin: "auto", marginTop: "90%", position: "absolute", width:"100%"  } }>
                                    <p style={{ fontSize: "16px", textAlign:'center', userSelect: 'none', marginBottom: "5%" }}>Elevation</p>
                                    <SunElevation
                                    value={this.state.sun_polar}
                                    onChange={value => this.setPolar(value)}/>
                                    <div className='reset-sun-container'>
                                        <Button className="reset-sun-button" onClick={()=>this.resetSun()} >
                                            Reset To Default
                                        </Button>
                                    </div>
                                </div>
                            </> :
                            <div style={{visibility: "hidden", height: 0, width: 0}}>
                                <div id="joyDiv1" className="sun-positioning"></div>
                            </div>
                        }


                        {this.state.hide_camera?"" :
                        <div className="camera-controls-div" style={{position:"absolute", width:"100%", top:"10%" ,height:this.state.show_snapshots ? "85%":"75%"}}>
                            <div style={{height:"100%", width:"100%"}}>
                                <div style={{ top:"2%",height:"70%"}}>
                                    <CameraControls
                                        onChangeCameraAngle={this.onChangeCameraAngle}
                                        camera_angle={this.state.cameraAngle}
                                        room_height={this.state.room_height}
                                        sliderPosition3D={this.state.sliderPosition3D}
                                        changeCameraHeight={this.changeCameraHeight}
                                        toggleSnapshotTaken={this.toggleSnapshotTaken}
                                        snapshot_clicked={this.state.snapshot_clicked}
                                        camera_height={this.state.camera_height}
                                        scene_view_state={this.state.scene_view_state}
                                        toggleCameraZoom={this.toggleCameraZoom}
                                        camera_zoom_options={this.state.camera_zoom_options}
                                        setPolarAngle={this.setPolarAngle}
                                        setCameraHeight={this.setCameraHeight}
                                        setCameraStateHeight={this.setCameraStateHeight}
                                        getCameraAngle={this.getCameraAngle}
                                        setCameraAngle={this.setCameraAngle}
                                        clipping={this.state.clipping}
                                        showPositionSliderMarks = {this.is3DModeActive()}
                                        showRotationSlider = {!this.isFlatViewModeActive()}
                                        disableCameraAngle = {!this.is3DModeActive()}
                                    />
                                </div>
                            <div style={{position:"absolute" , left:"58%", top:"30%", width:"100%", height:"22%"}}>
                                {this.is3DModeActive() ? 
                                    <div className='align-button pointer' onClick={() => {this.showGridForSnapshot()}} >
                                        <img style={{margin:3}} src={this.state.grid_controls_view == 0 ?"/img/gridIcon.png":"/img/grid-active.svg"} class="icon-scene-creator-camera-controls"/>
                                        <span id="grid_span" style={{fontSize:"0.4vw", fontWeight:"bold"}} >Grid Off</span>
                                    </div> 
                                : ''}
                                {this.is3DModeActive() ?
                                    <div className='align-button pointer' onClick={() => {this.alignCameraForSnapshot()}}>
                                        <img style={{margin:3}} src={"/img/Vertical Align.svg"} class="icon-scene-creator-camera-controls"/>
                                        <span style={{fontSize:"0.4vw", fontWeight:"bold"}}>Vert. Camera Align</span>
                                    </div>
                                : ''}
                                {this.is3DModeActive() ?
                                    <div className='align-button pointer' onClick={() => {this.alignCameraHorizontallyForSnapshot()}}>
                                        <img style={{margin:3}} src={"/img/Horizontal Align.svg"} class="icon-scene-creator-camera-controls"/>
                                        <span style={{fontSize:"0.4vw", fontWeight:"bold"}}>Hor. Camera Align</span>
                                    </div>
                                : ''}
                                {
                                    !this.is3DModeActive()?
                                    ''
                                    :
                                        <>
                                            <div className={!this.sceneCreator.selection.object ? 'align-button not-allowed' :'align-button pointer'} onClick={() => {this.lookAtObjectHeadOn()}}>
                                                <img style={{margin:3}} src={"/img/Hero.svg"} class="icon-scene-creator-camera-controls"/>
                                                <span style={{fontSize:"0.4vw", fontWeight:"bold"}}>Hero</span>
                                            </div>
                                            <div className={!this.sceneCreator.selection.object ? 'align-button not-allowed' :'align-button pointer'} onClick={() => {this.lookAtObjectCenter()}}>
                                                <img style={{margin:3}} src={"/img/Look_at.svg"} class="icon-scene-creator-camera-controls"/>
                                                <span style={{fontSize:"0.4vw", fontWeight:"bold"}}>Look At</span>
                                            </div>
                                        </>
                                }

                            </div>
                            {!this.isFlatViewModeActive() ?
                                <div style={{position:"absolute", width:"100%",marginTop:"1%", height:"30%"}}>
                                <FocalControls defaultCameraFocal={this.state.default_camera_Focal} snapshot_clicked={this.state.snapshot_clicked} toggleSnapshotTaken={this.toggleSnapshotTaken} cameraFocal={this.state.camera_Focal} setFocalOfCamera={this.setFocalOfCamera} />
                                </div>
                            : ''}
                            {!this.isFlatViewModeActive() ?
                            <div style={{position:"absolute", width:"100%",marginTop: this.isFlatViewModeActive() ? "1%" : "35%", height:"30%"}}>
                                <ClippingControls clipping={this.state.clipping} clipping_value={this.state.clipping_value} toggleClipping={this.toggleClipping} setClipping={this.setClipping} />
                            </div>
                            : ''}
                        </div>
                        </div>
                        }
                    </div>

                    </Card>
                    {this.state.scene_view_state == 'images'?
                    <Card style={{position:"absolute", height:this.state.show_snapshots ? "30%" : "0%" , "overflow":"auto", width:"100%"}}>
                    <div id="angles-images" style={{"padding-top": "2px"}}>
                    </div>
                    <div id="camera_names" style={{paddingTop: "2px"}}>
                        <h7 style={{padding:"0px 5px", fontWeight:"bold"}}>Original Shot</h7>
                        <Tooltip title={<span className='manrope f-12 white'>Click on snapshot to restore original angle</span>}> 
                            <img className="align-icon right" src={require("../../../../assets/images/help-icon-2.svg")}></img>
                        </Tooltip>
                        <div className="scene-controls" style={{padding:"0px 5"}}>
                            {this.state.cameras.slice(0).reverse().map(x => (
                                ( x['name'] != "360Camera" && x['name'] != "helper" ) ?
                                    <Row type="flex" justify="space-around" >
                                        <div style = {{padding: 5}}>
                                            {
                                                (x['camera_render'] != "") ?
                                                    <div style={{position:"relative"}}>
                                                        <a onClick={() => this.changeCameraControls(x['name'], x['camera_mode'])}>
                                                            <img src={x['camera_render']} style={ this.state.update_camera == x['name'] ? Settings.selected_angle_style : Settings.angle_style }>
                                                            </img>
                                                        </a>
                                                    </div>
                                                    :
                                                    <div style={{position:"relative"}}>
                                                        <a onClick={() => this.changeCameraControls(x['name'], x['camera_mode'])}>
                                                            <img src={x['thumbnail']} style={ this.state.update_camera == x['name'] ? Settings.selected_angle_style : Settings.angle_style }>
                                                            </img>
                                                        </a>
                                                    </div>
                                            }

                                        </div>
                                    </Row>
                                    :
                                    ""
                            ))}
                        </div>

                    </div>
                </Card>
                :""}

                </Col>
                :(this.is3DModeActive() && this.state.show_space_configurator_menu)?
                    <Col span={5} style= {{pointerEvents:this.state.edit_mode == 'edit' ? "fill" : "none"}}>
                       <SpaceConfigurator
                       spaceSelectionType = {this.state.space_selection_type}
                       spaceSelectionColorConfigurable = {this.state.spaceSelectionColorConfigurable}
                       spaceSelectionConfig={this.state.space_selection_config}
                       setUploadedTexturesList={this.setUploadedTexturesList}
                       textureInfo={this.state.texture_info}
                       spaceConfigurationApplied={this.state.space_configuration_applied}
                       updateSpaceConfigurationSettings={this.updateSpaceConfigurationSettings}
                       discardSpaceConfigurationSettings={this.discardSpaceConfigurationSettings}
                       resetSpaceConfigurationSettings={this.resetSpaceConfigurationSettings}
                       applyColorToSpaceSelection={this.applyColorToSpaceSelection}
                       applyColorToAllSpaceSelection={this.applyColorToAllSpaceSelection}
                       applyMaterialToSpaceSelection={this.applyMaterialToSpaceSelection}
                       applyMaterialToAllSpaceSelection={this.applyMaterialToAllSpaceSelection}
                       restoreMaterialToAllSpaceSelection={this.restoreMaterialToAllSpaceSelection}
                       setPreserveTextureOption={this.setPreserveTextureOption}
                       materialInfo={this.state.material_info}
                       enableMaterialUpload={!this.state.admin_scene_creator} 
                       setDisclaimer={this.setDisclaimer}
                       />
                       
                    </Col>
                    :
                    ""
                }

                </Row>
                <Footer style={{display: "flex", padding: 12}}>
                        {(this.state.scene_view_state =='design')?
                            <span style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                                <div>
                                <Button block className="sc-exit-button square" type="d-inline" disabled={(this.state.isLoading || this.state.progress_loader_360 || this.state.progress_loader_3d) ? true : false} shape="round" onClick={() => this.exitScene()}>
                                        <span className="sc-exit-button-pd">Exit Scene Creator {(this.state.progress_loader_exit) ? <LoadingOutlined spin/> : ""}</span>
                                    </Button>
                                </div>
                            </span>
                            :
                            <span style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                                <div>
                                    <Button block className="sc-exit-button square" type="d-inline" shape="round" onClick={this.handleBackBtn}>
                                        <span className="sc-exit-button-pd">Back</span>
                                    </Button>
                                </div>
                            </span>
                        }
                        {this.state.scene_view_state == "images" ?
                         <div style={{display:"flex",justifyContent:"center"}}>
                         <Tooltip title={(this.state.api_loader && !this.state.api_finished ? "Adding Cameras to your scene":"")}>
                             <span>
                                 <Button data_tut="reactour__generateOption" block className="sc-action-button square"
                                 disabled={!this.state.api_finished || this.isFlatViewModeActive()}
                                 type="danger d-inline" shape="round" style={{marginRight: 5}}
                                 onClick={()=>{this.requestNewCollabCamera()}}>
                                     <span className="sc-exit-button-pd ">Confirm Request {!this.state.api_finished?<LoadingOutlined/>:""}</span>
                                 </Button>
                             </span>
                         </Tooltip>
                        </div>
                        :
                        <div style={{display:"flex",justifyContent:"center"}}>
                            <Tooltip title={(this.state.api_loader && !this.state.api_finished ? "Adding Cameras to your scene":"")}>
                                <span>
                                    <Button data_tut="reactour__generateOption" block className="sc-action-button square"
                                    disabled={!this.state.api_finished}
                                    type="danger d-inline" shape="round" style={{marginRight: 5}}
                                    onClick={this.handleCreateImages}>
                                        <span className="sc-exit-button-pd ">Re-Render Image {!this.state.api_finished?<LoadingOutlined/>:""}</span>
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        }
                        <PaymentFailureModal
                        paymentFailureMessage={this.state.payment_failure_message}
                        setPaymentFailureModal={this.setPaymentFailureModal}
                        updatePaymentDetailsOnFailure={this.updatePaymentDetailsOnFailure}
                        paymentFailureModal={this.state.payment_failure_modal}
                        />
                </Footer>
                <SceneCreatorModals
                    current_package={this.state.current_package}
                    subscription_plan={this.state.subscription_plan}
                    setSubscriptionPlan={this.setSubscriptionPlan}
                    is_subscription_active={this.state.is_subscription_active}
                    upgradeSubscriptionPlan={this.upgradeSubscriptionPlan}
                    subscription_loader={this.state.subscription_loader}
                    cancelSubscriptionModal={this.cancelSubscriptionModal}
                    showSubscriptionModal={this.showSubscriptionModal}
                    request_status={this.state.request_status}
                    subscription_modal={this.state.subscription_modal}
                    returnPrice360={this.returnPrice360}
                    setCardSaveDrawer={this.setCardSaveDrawer}
                    setCardSaveFailure={this.setCardSaveFailure}
                    saveRequestIntermedietely={this.saveRequestIntermedietely}
                    card_save_failure={this.state.card_save_failure}
                    totalPrice={this.totalPrice}
                    returnPrice={this.returnPrice}
                    cameras={this.state.cameras}
                    setFocalOfCamera={this.setFocalOfCamera}
                    deleteSnapshot={this.deleteSnapshot}
                    tempSnapshot={this.state.tempSnapshot}
                    aspect_ratio_selected={this.state.aspect_ratio_selected}
                    onImageFormatChange={this.onImageFormatChange}
                    onResolutionChange={this.onResolutionChange}
                    imageResArr={this.state.imageResArr}
                    image_width={this.state.image_width}
                    image_height={this.state.image_height}
                    resolution_status={this.state.resolution_status}
                    resolution_value={this.state.resolution_value}
                    onImageWidthChange={this.onImageWidthChange}
                    onImageHeightChange={this.onImageHeightChange}
                    closeAspectRatioModal={this.closeAspectRatioModal}
                    onChangeWidthResolution={this.onChangeWidthResolution}
                    onChangeHeightResolution={this.onChangeHeightResolution}
                    onChangeWidth={this.onChangeWidth}
                    onChangeHeight={this.onChangeHeight}
                    selectOrientation={this.selectOrientation}
                    orientation_clicked={this.state.orientation_clicked}
                    aspect_clicked={this.state.aspect_clicked}
                    selectAspectRatio={this.selectAspectRatio}
                    orientationSettings={this.orientationSettings}
                    aspect_ratio_modal={this.state.aspect_ratio_modal}
                    changeAspectRatio={this.changeAspectRatio}
                    changeOrientation={this.changeOrientation}
                    setAspects={this.setAspects}
                    orientation={this.state.orientation}
                    image_width_res={this.state.image_width_res}
                    image_height_res={this.state.image_height_res}
                    confirm_3d_loader={this.state.confirm_3d_loader}
                    confirm_360_loader={this.state.confirm_360_loader}
                    confirm_image_loader={this.state.confirm_image_loader}
                    shortcuts_visible={this.state.shortcuts_visible}
                    showCameraModal={this.state.show_camera_modal}
                    handleCameraModalCancel={this.handleCameraModalCancel}
                    handleShortcustsCancel={this.handleShortcustsCancel}
                    handleShortcustsOk={this.handleShortcustsOk}
                    submit_360_visible={this.state.submit_360_visible}
                    handle360Ok={this.handle360Ok}
                    dpi={this.state.dpi}
                    onChangeDPI={this.onDPIChange}
                    handle360Cancel={this.handle360Cancel}
                    submitRenderRequest={this.submitRenderRequest}
                    handleSavePlacementAndSubmitRender={this.handleSavePlacementAndSubmitRender}
                    submit_360_done_visible={this.state.submit_360_done_visible}
                    submit_collaboration_render_done_visbile={this.state.submit_collaboration_render_done_visbile}
                    redirectToHome={this.redirectToHome}
                    submit_plain_done_visible={this.state.submit_plain_done_visible}
                    submit_plain_visible={this.state.submit_plain_visible}
                    submit_collaboration_render_modal = {this.state.submit_collaboration_render_modal}
                    routeToSceneRendersPage={this.routeToSceneRendersPage}
                    handlePlainOk={this.handlePlainOk}
                    handlePlainCancel={this.handlePlainCancel}
                    submit_plain_error_visible={this.state.submit_plain_error_visible}
                    handlePlainErrorOk={this.handlePlainErrorOk}
                    handlePlainErrorCancel={this.handlePlainErrorCancel}
                    aspect_ratio_selection_visible={this.state.aspect_ratio_selection_visible}
                    handleAspectRatioCancel={this.handleAspectRatioCancel}
                    aspect_ratio_value={this.state.aspect_ratio_value}
                    onAspectWidthChange={this.onAspectWidthChange}
                    taking_snapshot_visible={this.state.taking_snapshot_visible}
                    handleTakeSnapshotOk={this.handleTakeSnapshotOk}
                    handleTakeSnapshotCancel={this.handleTakeSnapshotCancel}
                    handleTakeSnapshotCancelWithAspect={this.handleTakeSnapshotCancelWithAspect}
                    resolutions={this.state.resolutions}
                    space_areas={this.state.space_areas}
                    selected_360s={this.state.selected_360s}
                    setSelected360s={this.setSelected360s}
                    set360Resolution={this.set360Resolution}
                    submit_tour_visible={this.state.submit_tour_visible}
                    threed_loader = {this.state.threed_loader}
                    handleTourOk={this.handleTourOk}
                    image_format={this.state.image_format}
                    handleTourCancel={this.handleTourCancel}
                    submit_tour_done_visible={this.state.submit_tour_done_visible}
                    setSceneViewState={this.setSceneViewState}
                    handle360DoneOk={this.handle360DoneOk}
                    handleTourDoneOk={this.handleTourDoneOk}
                    handlePlainDoneOk={this.handlePlainDoneOk}
                    grid_settings_modal={this.state.grid_settings_modal}
                    overlay_settings_modal={this.state.overlay_settings_modal}
                    temp_snapshot_overlay={this.state.temp_snapshot_overlay}
                    handleDiscardGridSettings={this.handleDiscardGridSettings}
                    handleApplyGridSettings={this.handleApplyGridSettings}
                    handleDiscardOverlaySettings={this.handleDiscardOverlaySettings}
                    handleApplyOverlaySettings={this.handleApplyOverlaySettings}
                    show_grid_switch={this.state.show_grid_switch}
                    show_overlay_switch={this.state.show_overlay_switch}
                    setShowGridSwitch={this.setShowGridSwitch}
                    setShowOverlaySwitch={this.setShowOverlaySwitch}
                    grid_tile_height={this.state.grid_tile_height}
                    grid_tile_width={this.state.grid_tile_width}
                    overlay_width = {this.state.overlay_width}
                    overlay_height = {this.state.overlay_height}
                    overlay_opacity={this.state.overlay_opacity}
                    overlay_limit={this.state.overlay_limit}
                    grid_color={this.state.grid_color}
                    overlay_color={this.state.overlay_color}
                    grid_tile_unit={this.state.grid_tile_unit}
                    overlay_size_unit={this.state.overlay_size_unit}
                    grid_line_thickness={this.state.grid_line_thickness}
                    onChangeGridDimension={this.onChangeGridDimension}
                    onChangeOverlayOpacity={this.onChangeOverlayOpacity}
                    onChangeGridColor={this.onChangeGridColor}
                    onChangeOverlayColor={this.onChangeOverlayColor}
                    onChangeGridUnit={this.onChangeGridUnit}
                    onChangeOverlayUnit={this.onChangeOverlayUnit}
                    onChangeOverlayHeight={this.onChangeOverlayHeight}
                    onChangeOverlayWidth={this.onChangeOverlayWidth}
                    onChangeGridThickness={this.onChangeGridThickness}
                    collectionProducts={this.state.collection_products}
                    restore_products_modal={this.state.restore_products_modal}
                    setRestoreProductsModal={this.setRestoreProductsModal}
                    unHideItem={this.unHideItem}
                    deleted_asset_ids={this.state.deleted_asset_ids}
                    render_name_editable={this.state.render_name_editable}
                    scene_render_name={this.state.scene_render_name}
                    setRenderNameEditable={this.setRenderNameEditable}
                    setSceneRenderName={this.setSceneRenderName}
                    default_scene_render_name={this.state.default_scene_render_name}
                    setTempOverlayLines={this.setTempOverlayLines}
                    SetOverlayLines={this.SetOverlayLines}
                    setOverlayLimit={this.setOverlayLimit}
                    create360Flow={this.create360Flow}
                    createTourFlow={this.createTourFlow}
                    card_status={this.state.card_status}
                    payment_type={this.state.payment_type}
                    card_save_drawer={this.state.card_save_drawer}
                    summary_modal_cameras={this.state.summary_modal_cameras}
                    used_tokens={this.state.used_tokens}
                    available_tokens={this.state.available_tokens}
                    three_price={this.state.three_price}
                    card_save_flow_triggered={this.state.card_save_flow_triggered}
                    in_app_limit_exceeded_modal={this.state.in_app_limit_exceeded_modal}
                    setInAppLimitExceededModal={this.setInAppLimitExceededModal}
                    edit_resolution_enabled={this.state.edit_resolution_enabled}
                />
                <AddProductsToScene
                    collection_id={this.state.collection_id}
                    space_areas={this.state.space_areas.length > 1 ? this.state.space_areas: []}
                    addProductsToScene = {this.addProductsToScene}
                    updateSceneCollection = {this.updateCollectionProducts}
                    showAddProductsError = {this.showAddProductsError}
                    setAddProductsLoader = {this.setAddProductsLoader}
                    updateSceneProductsList = {this.fetchCollectionProducts}
                    collection_products={this.state.collection_products}
                    deletedProducts={this.state.deleted_asset_ids}
                    restoreProducts={this.restoreAssets}
                    add_product_modal={this.state.add_product_modal}
                    toggleAddProductModal={this.toggleAddProductModal}
                    project_ids = {this.state.project_ids}
                />
                <SwapSpace
                    room_id = {this.state.room_id}
                    room_parent = {this.state.room_parent}
                    collection_id = {this.state.collection_id}
                    scene_id = {this.state.collaboration_scene_id}
                    swap_space_modal = {this.state.space_swap_modal}
                    room_platform = {this.state.parent_platform}
                    toggleSwapSpaceModal = {this.toggleSpaceSwapModal}
                    handleSwapSpace = {this.handleSwapSpace}
                    setLoading = {this.setSwapSpaceLoading}
                    checkExistingSnapshots = {this.checkExistingSnapshots}
                    deleteSnapshot = {this.deleteSnapshot}
                    swapButtonRef = {this.state.swapButtonRef}
                />
                <SwapProduct
                    room_id = {this.state.room_id}
                    product_id = {this.state.selected_product_id}
                    collection_id = {this.state.collection_id}
                    swap_space_modal = {this.state.product_swap_modal}
                    toggleSwapProductModal = {this.toggleProductSwapModal}
                    swapProduct = {this.swapProduct}
                    setLoading = {this.setSwapProductLoading}
                    space_area = {this.state.space_areas[0]}
                    project_ids = {this.state.project_ids}
                />
                <UndoLostAsset
                    collection_id = {this.state.collection_id}
                    undo_product_modal = {this.state.undo_modal}
                    toggleSwapProductModal = {this.handleUndoPositionClick}
                    UndoPosition = {this.UndoPosition}
                />
            </div>
        );
    }
}

export default SceneCollaboratorWrapper;