import 'rxjs-compat/add/operator/switchMap';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/catch';
import { ajax } from 'rxjs-compat/observable/dom/ajax';
import ENVIRONMENT from '../../environments'
import {
  FETCH_STORE_PRODUCT,
  GET_SCANNED_STORE_PRODUCTS,
  RENDER_STORE_PRODUCT,
  CREATE_STORE_PRODUCT,
  LIST_STORE_PRODUCT,
  LIST_FOR_STORE_PRODUCT,
  UPDATE_STORE_PRODUCT,
  REJECT_STORE_PRODUCT,
  storeProductFailureAction,
  storeProductSuccessAction,
  storeProductRejectSuccessAction, getStoreProductRender,
} from '../actions';

export const fetchStoreProduct = (action$) => {
  return action$
      .ofType(FETCH_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.STORE_LIST,
          { username: localStorage.getItem('username'), item_type: 'product' }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => storeProductSuccessAction({ ...data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(storeProductFailureAction({ message: 'Unable to get store product.' })));
      });
};

export const renderStoreProduct = (action$) => {
  return action$
      .ofType(RENDER_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.RENDER_STORE_PRODUCT,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => storeProductSuccessAction({ ...data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(storeProductFailureAction({ message: 'Unable to get product.' })));
      });
};


export const listStoreProduct = (action$) => {
  return action$
      .ofType(LIST_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.STORE_LIST,
            { username: localStorage.getItem('username'), item_type: 'product' }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => storeProductSuccessAction({ products: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(storeProductFailureAction({ message: 'Unable to create product. Fill missing fields.' })));
      });
};

export const listForStoreProducts = (action$) => {
  return action$
      .ofType(LIST_FOR_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.STORE_LIST,
            {username: localStorage.getItem('username'), item_type: 'product' }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => storeProductSuccessAction({ products: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(storeProductFailureAction({ message: 'Unable to create product. Fill missing fields.' })));
      });
};

export const getScannedStoreProducts = (action$) => {
  return action$
      .ofType(GET_SCANNED_STORE_PRODUCTS)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.GET_SCANNED_STORE_PRODUCTS,
            { username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => storeProductSuccessAction({ scannedProducts: data.response.models, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(storeProductFailureAction({ message: 'Unable to get product' })));
      });
};


export const createStoreProduct = (action$) => {
  return action$
      .ofType(CREATE_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.CREATE_STORE_PRODUCT,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => storeProductSuccessAction({ ...data.response, isCreated: true, isUpdated: false }))
            .catch((error) => Promise.resolve(storeProductFailureAction({ message: 'Unable to create product. Fill missing fields.' })));
      });
};


export const updateStoreProduct = (action$) => {
  return action$
      .ofType(UPDATE_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.UPDATE_STORE_PRODUCT,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => storeProductSuccessAction({ ...data.response, isCreated: false, isUpdated: true }))
            .catch((error) => Promise.resolve(storeProductFailureAction({ message: 'Unable to update product. Fill missing fields.' })));
      });
};

export const rejectStoreProduct = (action$) => {
  return action$
      .ofType(REJECT_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.REJECT_STORE_PRODUCT,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => storeProductRejectSuccessAction({ ...data.response, isCreated: false, isUpdated: false }))
            .map( (data) => getStoreProductRender(payload))
            .catch((error) => Promise.resolve(storeProductFailureAction({ message: 'Unable to update product. Fill missing fields.' })));
      });
};
