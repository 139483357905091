import React, { useState, useEffect,  useCallback  }  from 'react';
import { withRouter, Link } from 'react-router-dom'
import {connect} from "react-redux";
import axios from 'axios';
import '@ant-design/compatible/assets/index.css';
import { CheckOutlined, InfoCircleFilled, LoadingOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Table, message, Row, Col, Modal, Select, Button, Form, Input, Collapse, Tooltip } from 'antd';
import 'rc-color-picker/assets/index.css';
import 'product-icon-small.svg'
const Option = { Select };
const { TextArea } = Input;
const { Panel } = Collapse;



const AssignArtist = (props) => {
 
  const [form] = Form.useForm();

  useEffect(() => {
    if (form && form.current) {
      form.current.setFieldsValue({
      artist_username: props.parentAssignedArtist
      });
    }

  },[props.parentAssignedArtist]);

    return (
        <Modal  bodyStyle={{padding: 0}}
          visible={props.assignmentModelVisible}
          onCancel={props.handleCancel}
          className="aspect-ratio-modal small"
          footer={[

            <div className="justify-in-end">
              <Button  className="modal-okay-gray square font-14" onClick={props.handleCancel}>
                Cancel
              </Button>
              <Button disabled={props.buttonLoader || props.load_assigned_artist} className="modal-okay square font-14" onClick={() => document.getElementById("assignment-submit-button").click()}>
                Assign&nbsp;{props.buttonLoader ? <LoadingOutlined/> : ""}
              </Button>
            </div>
            
        ]}
          >
          <div style={{padding:"32px 32px 24px 32px"}}>
            {props.load_assigned_artist ? 
            <div div className="justify-in-center" style={{width: '100%'}}>
              <LoadingOutlined style={{fontSize: 24}}/>
            </div>
            :
            <>
              {props.reject_message ?
              <div className="manrope f-16 black-55" >
                To reject a material,you need to assign an artist first
              </div>
              : 
              <div className="manrope f-16 black-55" >
              Please select artist to assign {props.entity} to
              </div>}
              {props.parentAssignedArtist  ?
                <Row style={{marginBottom:10,marginTop: 10}}>
                  <Col className="justify-in-start" span={24}>
                    <div className ="note-bg-artist gray">
                      <div className="manrope f-14 black-55">The artist of the parent model of this variation is preselected</div>
                    </div>
                  </Col>
                </Row>: ''}
              {props.groupHaveUnassignedItems ? 
                <Row style={{marginBottom:10,marginTop: 10}}>
                  <Col className="justify-in-start" span={24}>
                    <div className ="note-bg-artist red">
                      <div className="manrope f-14 black-55">This model is part of a group. If you assign it to an Artist the unassigned models of this group will be automatically assigned to the same artist.</div>
                    </div>
                  </Col>
                </Row>
              : ''}
              <Form  ref={form} className="input-form" onFinish={props.handleAssign} style={{marginTop: 24}}>
              <div className="manrope f-16 black-55" style={{marginBottom: 8}}>Select An Artist</div>

                <Form.Item  className="manrope f-14 black-55" name="artist_username" colon={false} rules={[{ required: true, message: 'Please select an Artist' }]}>

                  <Select
                    showSearch 
                    className="manrope f-14 black-55"
                    placeholder="Select Artist">
                    {props.artists.map((ele, index) => {
                      return <Option key={index} className="manrope f-12 select-view assign-artist-dropdown" value={ele.artist_profile}>{ele.artist_profile + " at rate " + ele.hourly_rate}
                      {ele.is_test_artist ? 
                      <span style={{color: '#E19B12'}}>(Test Artist)</span>  
                        : "" }
                    <span style={{float: 'right'}} className='model_count_row'>
                      {ele.model_count['product_count'] != 0 ? <Tooltip placement='top' title='Currently Assigned Products'><span className='model_count'> <img src='/img/product-icon-small.svg' /> {ele.model_count['product_count']}</span> </Tooltip>
                      : ''}
                      {ele.model_count['room_count'] != 0 ? <Tooltip placement='top' title='Currently Assigned Rooms'><span className='model_count'> <img src='/img/room-icon-small.svg' /> {ele.model_count['room_count']}</span></Tooltip>
                      : ''}
                      {ele.model_count['material_count'] != 0 ? <Tooltip placement='top' title='Currently Assigned Materials'><span className='model_count'> <img src='/img/material-icon-small.svg' /> {ele.model_count['material_count']}</span> </Tooltip>
                      : ''}
                      </span>
                      
                      </Option>
                    })}
                  </Select>
                </Form.Item>
                {props.entity == "product" ?
                <Collapse style={{marginTop: 32}} defaultActiveKey={['0']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                  <Panel header={<span className="manrope f-16 grey-77 w-700">Modelling Guidelines</span>} key="1">
                      <h4 className="manrope f-16 black-55" style={{marginTop: 12,marginBottom: 12}}><b>Dimensions (Inches)</b></h4>
                      <div className="manrope f-12 black-55" style={{marginBottom: 8}}>Height</div>
                      <Form.Item name="height" label="" colon={false}
                        rules= {[{ required: false, message: 'Please enter height' }]}
                      >
                          <Input
                          className="input-number manrope f-12 grey-77"
                              placeholder="Enter Height" type="number"
                          />
                      </Form.Item>
                      <div className="manrope f-12 black-55" style={{marginBottom: 8}}>Width</div>

                        <Form.Item name="width" label="" colon={false}
                          rules= {[{ required: false, message: 'Please enter width' }]}
                        >
                          <Input
                          className="input-number manrope f-12 grey-77"
                              placeholder="Enter Width" type="number"
                          />
                        </Form.Item>
                        <div className="manrope f-12 black-55" style={{marginBottom: 8}}>Depth</div>

                        <Form.Item name="length" label="" colon={false}
                          rules= {[{ required: false, message: 'Please enter depth'}]}
                        >
                          <Input
                          className="input-number manrope f-12 grey-77"
                              placeholder="Enter length" type="number"
                          />
                        </Form.Item>
                        <div className="manrope f-12 black-55" style={{marginBottom: 8}}>Comments/Guidelines</div>
                        <Form.Item name="admin_text_guidelines" label="" colon={false}
                          rules= {[{ required: false, message: 'Please enter depth'}]}
                        >
                          <TextArea 
                          className="input-number manrope f-12 grey-77"
                              placeholder = "Enter Guidelines"
                              rows={2}
                              style={{height: "auto !important"}}
                          />
                        </Form.Item>
                        </Panel>  
                    </Collapse>: ''}
                    <Button id="assignment-submit-button" type="primary" htmlType="submit" style={{"display": "none"}}>
                      Submit
                    </Button>
              </Form>
            </>}
          </div>
        </Modal>
    );

}

export default AssignArtist;