import React, { useState,useEffect,useRef} from 'react';
import 'antd/dist/antd.css';
import {Row, Col, Card,Checkbox, Menu, Dropdown, Button, Tooltip, Empty } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { LoadingOutlined } from '@ant-design/icons';
import '../../../../styles/helper.scss'
import {Link} from 'react-router-dom'
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'
import { SCENE_THUMBNAIL_URI, getBaseURL, SCENE_PREVIEW_THUMBNAIL_URI } from '../../../../environments/env';
import * as Utilities from '../../Utilities'
import FileConstants from '../../../../FileConstants'
import _ from "lodash";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMove } from "react-sortable-hoc";
import { reorderData } from "../../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LibraryGrid from '../LibraryGrid/LibraryGrid';
import AddEntityToProject from '../AddEntityToProject/AddEntityToProject';


const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;

let get_scene_options = (onDeleteScene, id, customerUsername,moveToCustomerScene, collection_id, managedCustomersExist, addToStore, type, scene_company_id, onDuplicateScene, scene_name, render_generation, scene_template_id, copyProductToNewSpace, setProjectModalVisible, setSelectedProductId) => {
    return <Menu>
    {!(render_generation == false && scene_template_id) ?
    <Menu.Item>
      <a className="manrope f-14" onClick={() => onDuplicateScene(id, scene_name) }>Duplicate Scene</a>  
    </Menu.Item> : ''}
    { !(render_generation == false && scene_template_id) ?
        <Menu.Item>
            <a className="manrope f-14" onClick={() => copyProductToNewSpace(id, collection_id)}>Copy Product To New Space</a>  
        </Menu.Item> : ''}
    <Menu.Item>
      <a className="manrope f-14" onClick={() => onDeleteScene(id, customerUsername, scene_company_id) }>Delete Scene</a>  
    </Menu.Item>
    {FileConstants.isMSProvider && type=='owned' && managedCustomersExist ?
    <Menu.Item>
      <a className="manrope f-14" onClick={() => moveToCustomerScene(id,customerUsername, collection_id)}>Set as Managed Scene</a>  
    </Menu.Item>: ''}
    {FileConstants.isMSProvider && customerUsername == CUSTOMER_USERNAME && (!MANAGED_CUSTOMER_USERNAME && !MANAGED_COMPANY_ID) ?
    <Menu.Item>
      <a className="manrope f-14" onClick={() => addToStore(id)}>Add Scene To Store</a>  
    </Menu.Item>: ''}
    <Menu.Item>
      <a className="manrope f-14" onClick={() => {setProjectModalVisible(true); setSelectedProductId(id)} }>Add to Project</a>  
    </Menu.Item>
  </Menu>
  };

let get_incomplete_scene_option = (onDelete, id) =>{
    return (<Menu>
        <Menu.Item>
            <a className="manrope f-14" onClick={() => onDelete(id) }>Delete Request</a> 
        </Menu.Item>
    </Menu>)
};

let get_folder_options = (
  renameFolderOnclick,
  unGroupFolderItemsOnclick,
  ele
) => {
  return (
    <Menu>
      <Menu.Item>
        <a className="manrope f-14" onClick={() => renameFolderOnclick(ele)}>
          Rename Folder
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          className="manrope f-14"
          onClick={() => unGroupFolderItemsOnclick(ele)}
        >
          Ungroup Items
        </a>
      </Menu.Item>
    </Menu>
  );
};

const ScenesList = (props) => {
  const [items, setItems] = useState([]);
  const [entityArrangement, setEntityArrangement] = useState(null);
  const [folderArrangement, setFolderArrangement] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [orderUpdated, setOrderUpdated] = useState(false);
  const [columnCount, setColumnCount] = useState(4);
  const [rowCount, setRowCount] = useState(1);
  const [data, setData] = useState([]);
  const previousScenesLength = useRef(null);
  const maxColumnWidth = 280;
  const rowSize = 330;

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const itemIndex = (rowIndex * columnCount) + columnIndex;
    const ele = data[itemIndex] && data[itemIndex];
    
    if (!ele) {
        return null;
    }

    return (
      <div key={key} style={style}>
        <SceneItems
          key = {key}
          type={props.type}
          addToStore={props.addToStore}
          managedCustomersExist={props.managedCustomersExist}
          selectedCollection={props.selectedCollection}
          setSceneCompanyID={props.setSceneCompanyID}
          setSelectedCollection={props.setSelectedCollection}
          moveToCustomerSceneModal={props.moveToCustomerSceneModal}
          setMoveToCustomerSceneModal={props.setMoveToCustomerSceneModal}
          onboarding={props.onboarding}
          selectable={props.selectable}
          selectedSceneIds={props.selectedSceneIds}
          setSelectedSceneIds={props.setSelectedSceneIds}
          searchValue={props.searchValue}
          libraryScenes={props.libraryScenes}
          setDeleteSceneModal={props.setDeleteSceneModal}
          setCurrentScene={props.setCurrentScene}
          setCurrentSceneCustomer={props.setCurrentSceneCustomer}
          folderState={props.folderState}
          enterFolderState={props.enterFolderState}
          groupedScenes={props.groupedScenes}
          ele={ele}
          reorderItems={props.reorderItems}
          setSelectedFolderScenes={props.setSelectedFolderScenes}
          selectedFolderScenes={props.selectedFolderScenes}
          folderArrangement={props.folderArrangement}
          renameFolderOnclick={props.renameFolderOnclick}
          unGroupFolderItemsOnclick={props.unGroupFolderItemsOnclick}
          setDuplicateSceneModal={props.setDuplicateSceneModal}
          setCurrentSceneName={props.setCurrentSceneName}
          setDeleteModal={props.setDeleteModal}
          projects={props.projects}
        />
    </div>)
};

  useEffect(()=>{
    console.log(props.libraryScenes);
   if (!props.saveOrderedItems) {
        populateArrangementInfo();
    }
    },[props.libraryScenes])

    const populateArrangementInfo = () => {
        const entityArr = _.cloneDeep(props.entityArrangement);
        const folderArr = _.cloneDeep(props.folderArrangement);
        setEntityArrangement(entityArr);
        setFolderArrangement(folderArr);
        props.setHashUpdated(false);
        forceUpdate();
    }

    useEffect(() => {
        if (props.hashUpdated && !props.saveOrderedItems) {
            populateArrangementInfo();
        }
      }, [props.hashUpdated, props.reorderItems]);

      useEffect(() => {
        if (props.libraryScenes) {
            setItems(props.libraryScenes);
        }
      }, [props.libraryScenes]);

  //called when user presses back button to save rearrangement
  const updateEntitiesArrangement = () => {
    let new_entity_arrangement = [];
    let new_folder_arrangement = [];
    let arrangement_info = {};
    let folder_arrangement_info = {};

    items.map((item, index) => {
        if (Array.isArray(item)) {
            folder_arrangement_info = {
                folder_id: parseInt(item[0].folder_id),
                ordering_number: index,
                folder_name: item[0].folder_name,
                sequence_id: props.sceneTab == "owned" ? props.sequence_id_owned : props.sequence_id_other
            }
            new_folder_arrangement.push(folder_arrangement_info);
            item.map((folder_item, folder_index) => {
                arrangement_info = {
                    entity_id: parseInt(folder_item.id),
                    ordering_number: folder_index,
                    parent_folder_id: parseInt(folder_item.folder_id),
                    sequence_id: props.sceneTab == "owned" ? props.sequence_id_owned : props.sequence_id_other
                }
                new_entity_arrangement.push(arrangement_info);
            })
        } else {
            arrangement_info = {
                entity_id: parseInt(item.id),
                ordering_number: index,
                parent_folder_id: item.folder_id,
                sequence_id: props.sceneTab == "owned" ? props.sequence_id_owned : props.sequence_id_other
            }
            new_entity_arrangement.push(arrangement_info);
        }
    })

    setEntityArrangement(new_entity_arrangement);
    setFolderArrangement(new_folder_arrangement);
    console.log(new_entity_arrangement,new_folder_arrangement)
  };

  useEffect(() => {
    if (props.saveOrderedItems && (entityArrangement!==null  || folderArrangement !== null)) {
      props.setSaveOrderedItems(false);
      if (props.sceneTab == "owned" && props.type == "owned") {
        props.reorderArrangementData(entityArrangement, folderArrangement);
        props.setOrderUpdated(false);
      } else if (props.sceneTab == "shared" && props.type == "shared") {
        props.reorderArrangementData(entityArrangement, folderArrangement);
        props.setOrderUpdated(false);
      } else if (props.type == 'folder') {
        props.reorderArrangementData(entityArrangement, folderArrangement);
        props.setOrderUpdated(false);
      }
    }
  }, [props.saveOrderedItems, entityArrangement, folderArrangement]);

  useEffect(() => {
    if (
      items.length > 0 &&
      entityArrangement &&
      !props.saveOrderedItems &&
      orderUpdated
    ) {
      updateEntitiesArrangement();
      setOrderUpdated(false);
    }
  }, [orderUpdated]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
    setOrderUpdated(true);
    if (oldIndex != newIndex) {
        props.setOrderUpdated(true);
    }
  };

  const getFolderName = (ele) => {
    if (Array.isArray(ele)) {
        return ele[0].folder_name;
    }
    return ele.folder_name;
  };

  const addFolderNameInScenes = (scenes) => {
    console.log(' in add foldername')
    console.log(scenes)
    scenes.map(scene => {
      let folder_name = '';
      folder_name = getFolderName(scene)
      if (folder_name) {
        console.log('foldername found  = ', folder_name)
        if(Array.isArray(scene))
        {
            scene.map(element => {
                var words = folder_name.split(' ');
                words.forEach(function(word) {
                    element.keywords.push(word.toLowerCase())
                  });
            })
        }
        else
        {
            var words = folder_name.split(' ');
                words.forEach(function(word) {
                    scene.keywords.push(word.toLowerCase())
                  });
        }
      }

    })

  }

  useEffect( () => {
      let scenesData = [];
      if(props.searchValue == '') {
        scenesData = props.libraryScenes;
        if (!props.folderState && scenesData.length !== data.length) {
          props.updateTabCount(-1, props.tabKey)
        }
      } else {
        if (!FileConstants.isMSProvider)
        {
          addFolderNameInScenes(props.libraryScenes)
        }
      
        // commenting this logic out for now, but this performs search on the entire
        // library instead of just 1 tab 
        // commenting becuase of this edge case: if the user wants to search in the current tab, but search results show data in another tab?
        // if (!props.folderState && scenesData.length == 0) {
        scenesData = props.switchTabIfEmpty(props.libraryScenes);
        // }   
        const newSceneListLength = scenesData?.length;
        // Call updateTabCount if new length is different or new length is 0 and previous length is not 0
        if(!props.folderState) {
            if (newSceneListLength !== previousScenesLength.current) {
              props.updateTabCount(newSceneListLength, props.tabKey);
            }
        }
      }
      console.log(scenesData, props.tabKey)
      // Update the ref after the check
      previousScenesLength.current = scenesData?.length;
      setData(scenesData);
      setRowCount(Math.ceil(scenesData.length / columnCount));
  },[props.searchValue, props.libraryScenes]);
  
  if(!props.reorderItems){
    if (props.loader) {
        return (
        <div className="justify-in-center" style={{width: '100%'}}>
          <p className="empty-section-text manrope f-16 black-55">
              Please wait while we load all your scenes.
              <LoadingOutlined style={{fontSize: 24, marginLeft: 14}}/>
          </p>
        </div> )
    } else {
      return (
        data.length > 0 ?
        <LibraryGrid
          setColumnCount = {setColumnCount}
          setRowCount = {setRowCount}
          maxColumnWidth = {maxColumnWidth}
          data = {data}
          cellRenderer={cellRenderer}
          columnCount={columnCount}
          getColumnWidth={()=>{}}
          rowCount={rowCount}
          getEstimatedRowSize={rowSize}
          handleColumnWidthChange={()=>{}}
        /> : 
        <div className="no-models-div" style={{ marginTop: "25%"}}>
          <Empty description={"No Scenes To Show"} className="manrope f-14 no-models-span" />
        </div>)
    }
  } else {
    console.log(items);
    return items && <SortableList 
    axis={'xy'} props={props} 
    items={items} onSortEnd={onSortEnd} 
    getContainer={() => document.getElementById('scene-scrollable-'+props.type)}
    />
  }
};

const SortableList = SortableContainer(({ items, props }) => {
  return (
    <Row
      id={`scene-scrollable-${props.type}`}
      className="product-tab-content-scrollable draggable-div"
      type="flex"
      style={{ justifyContent: "start" }}
    >
      {items &&
        items.map((item, index) =>
          props.searchValue === "" ? (
            <SortableListItem
              props={props}
              useWindowAsScrollContainer={true}
              axis={"xy"}
              key={`item-${index}`}
              index={index}
              item={item}
            />
          ) : Utilities.isMatching(
              props.searchValue.toLowerCase(),
              item.name.toLowerCase()
            ) || 
            props.searchValue === item.id ? (
            <SortableListItem
            useWindowAsScrollContainer={true}
              props={props}
              key={index}
              index={index}
              item={item}
            />
          ) : (
            ""
          )
        )}
    </Row>
  );
});

const SortableListItem = SortableElement(({ item, index, props }) => {
  return (
    <Col
      className="draggable-element"
      lg={6} sm={24} md={8} offset={0}
      style={{
          display: "inline-block"
      }}>
    <SceneItems
      key={`item-${index}`}
      type={props.type}
      addToStore={props.addToStore}
      managedCustomersExist={props.managedCustomersExist}
      selectedCollection={props.selectedCollection}
      setSceneCompanyID={props.setSceneCompanyID}
      setSelectedCollection={props.setSelectedCollection}
      moveToCustomerSceneModal={props.moveToCustomerSceneModal}
      setMoveToCustomerSceneModal={props.setMoveToCustomerSceneModal}
      onboarding={props.onboarding}
      selectable={props.selectable}
      selectedSceneIds={props.selectedSceneIds}
      setSelectedSceneIds={props.setSelectedSceneIds}
      searchValue={props.searchValue}
      libraryScenes={props.libraryScenes}
      setDeleteSceneModal={props.setDeleteSceneModal}
      setCurrentScene={props.setCurrentScene}
      setCurrentSceneCustomer={props.setCurrentSceneCustomer}
      folderState={props.folderState}
      enterFolderState={props.enterFolderState}
      groupedScenes={props.groupedScenes}
      ele={item}
      reorderItems={props.reorderItems}
      setSelectedFolderScenes={props.setSelectedFolderScenes}
      selectedFolderScenes={props.selectedFolderScenes}
      folderArrangement={props.folderArrangement}
      renameFolderOnclick={props.renameFolderOnclick}
      unGroupFolderItemsOnclick={props.unGroupFolderItemsOnclick}
      projects={props.projects}
    />
    </Col>
  );
});

const SceneItems = (props) => {
    const { ele } = props;
    let selectable = props.selectable;
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [imageCount, setImageCount] = useState(0);
    const [projectModalVisible, setProjectModalVisible] = useState(false)
    const [selectedProduct, setSelectedProductId] = useState(null)
    const isCopyInProgress = ele.scene_created_from == 'duplication_inprogress';

    useEffect(() => {
      let count = 0;
      if(!isFolder())
      {
        let img_render_count = ele['img_render_count'];
        let three_sixty_count = ele['three_sixty_count'];
        let threed_tour_count = ele['threed_tour_count'];
        count = img_render_count + three_sixty_count + threed_tour_count;
        setImageCount(count);
      }
    }, []);


    const deleteScene = (id, customerUsername, sceneCompanyID) => {
      props.setCurrentScene(id);
      props.setSceneCompanyID(sceneCompanyID);
      props.setCurrentSceneCustomer(customerUsername);
      props.setDeleteSceneModal(true);
    };

    const duplicateScene = (id, scene_name) => {
      let duplicate_scene_name = 'Copy of ' + scene_name
      props.setCurrentScene(id);
      props.setCurrentSceneName(duplicate_scene_name);
      props.setDuplicateSceneModal(true)
    }

    const copyProductToNewSpace = (id, collection_id) => {
        window.location.href = `/create-new-scene-from-existing-products?collection_id=${collection_id}&scene_id=${id}`
    }

    const onChange = (e) => {
        Utilities.updateEntityInUrl(e.target.sceneSelected);
      let sceneIds = props.selectedSceneIds;
      if (e.target.checked) {
        sceneIds.push(e.target.sceneSelected);
      } else {
        sceneIds = props.selectedSceneIds.filter(
          (ele) => ele != e.target.sceneSelected
        );
      }
      props.setSelectedSceneIds([...sceneIds]);
      setTriggerUpdate(!triggerUpdate);
      forceUpdate();
      let url = new URL(window.location);
      url.searchParams.delete("folder_id");
      url.searchParams.delete("library_folder_id");
      url.searchParams.set("entity_id", e.target.sceneSelected);
      window.history.replaceState({}, "", url);
    };

    const toggleChecked = (scene) => {
      if (!isFolder()) {
        if (props.reorderItems) {
          let sceneIds = props.selectedFolderScenes;
          if (props.selectedFolderScenes.includes(scene.id)) {
            sceneIds = props.selectedFolderScenes.filter((ele) => ele != parseInt(scene.id));
          } else {
            sceneIds.push(scene.id);
          }
          props.setSelectedFolderScenes([...sceneIds]);
        } else if (props.selectable) {
          let sceneIds = props.selectedSceneIds;
          if (props.selectedSceneIds.includes(scene.id)) {
            sceneIds = props.selectedSceneIds.filter((ele) => ele != scene.id);
          } else {
            sceneIds.push(scene.id);
          }
          props.setSelectedSceneIds(sceneIds);
        }
        Utilities.updateEntityInUrl(scene.id);
        setTriggerUpdate(!triggerUpdate);
        forceUpdate();
      }
      else{
        Utilities.updateFolderIDInUrl(scene[0].folder_id);
      }
    };

    const onMouseOut = () => {
      let element = document.getElementById("add-products-btn-" + ele.id);
      if (element) {
        element.style.display = "none";
      }
    };

    const onMouseOver = () => {
      let element = document.getElementById("add-products-btn-" + ele.id);
      if (element) {
        element.style.display = "block";
      }
    };

    const moveToCustomerScene = (id, customerUsername, collection_id) => {
        props.setCurrentScene(id);
        props.setSelectedCollection(collection_id);
        props.setCurrentSceneCustomer(customerUsername);
        props.setMoveToCustomerSceneModal(true);
    };

    // select scenes for folder
    const selectForFolder = (e) => {
        Utilities.updateEntityInUrl(e.target.sceneSelected);
        let sceneIds = props.selectedFolderScenes;
        if (e.target.checked) {
        sceneIds.push(parseInt(e.target.sceneSelected));
        } else {
        sceneIds = props.selectedFolderScenes.filter(
            (ele) => {return ele != e.target.sceneSelected}
        );
        }
        // url.searchParams.delete("folder_id");
        props.setSelectedFolderScenes([...sceneIds]);
    };

    // get folder id of the scene selected
    const getFolderID = (element) => {
        if (Array.isArray(element)) {
            return element[0].folder_id;
        }
        return element.folder_id;
    }

    const checkIfParentModelByFolder = (scene) => {
      //if scene is a parent scene, and it's clicked, then enter folder state
      if (isFolder()) {
        props.enterFolderState(scene);
      }
    };

    const checkIfNewFolderHasName = (element) => {
        if (Array.isArray(element)) {
            if(element[0].folder_name)
                return true;
        }
    return false;
    };

    const getFolderName = (element) => {
        if (Array.isArray(element)) {
            return element[0].folder_name;
        }
        return element.folder_name;
    };

    const isFolder = () => {
        if (Array.isArray(ele)) {
                return true;
            }
            return false;
      }

  let thumbnail_link = "";

  if(isFolder())
  {
    thumbnail_link = getBaseURL(ele[0].platform) + SCENE_THUMBNAIL_URI + ele[0]['id'] + '/' + ele[0].thumbnail;
  }
  else{
    if(props.type == 'incomplete' && ele.preview_thumbnail){
        thumbnail_link = getBaseURL(ele.platform) + SCENE_PREVIEW_THUMBNAIL_URI + ele['preview_thumbnail']
    }
    else if (ele.thumbnail) {
        thumbnail_link = getBaseURL(ele.platform) + SCENE_THUMBNAIL_URI + ele['id'] + '/' + ele.thumbnail;
    }    
  }

  const getID = () => {
    //return folder id if item is a folder, otherwise return
    // scene id
    if (isFolder()) {
      return `folder-${getFolderID(ele)}`
    } else {
      return `element-${ele.id}`
    }
  }

  const getLink = (ele) => {
    let link = '#'
    if(props.onboarding){
        if(props.type == 'incomplete'){
            link = `/scene_builder/${ele.id}?onboarding=true${window.location.search}`
        }else {
            link = `/scene_renders/${ele.id}?onboarding=true${window.location.search}`
        }
    }
    else{
        if(props.type == 'incomplete'){
            link = `/scene_builder/${ele.id}${window.location.search}`
        }else {
            link = `/scene_renders/${ele.id}${window.location.search}`
        }
    }
    return link
  }

  const performAction = (ele) => {
    // perform action when user clicks on a card
    if (selectable || props.reorderItems || !isFolder()) {
      toggleChecked(ele);
    } else {
      checkIfParentModelByFolder(ele)
    }
    if (selectable || props.reorderItems || isFolder()) {
        window.location.href = "#";
      } else {
        window.location.href = getLink(ele);
      }
  }

    return (

        <><Card
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            id={getID()}
            bodyStyle={{ padding: "5px" }}
            className={`${((!props.folderState && isFolder() && props.searchValue == '')
                    || (props.searchValue != '' && isFolder()))
                    ? `stacked-card stacked-card-top-right card-margins stack`
                    : `card-margins`} card-shadow-product`}
            bordered={false}
            data-tut="reactour__approved_scene"
            style={((!props.folderState && isFolder() && props.searchValue == '')
                || (props.searchValue != '' && isFolder()))
                ? {
                    width: "94%",
                    border: props.selectedSceneIds.includes(ele.id)
                        ? "solid 2px #276DD7"
                        : "solid 1px #e3e3e3",
                }
                : {
                    width: "97%",
                    border: props.type != 'incomplete' && props.selectedFolderScenes.includes(ele.id)
                        ? "solid 1px #276DD7"
                        : props.type != 'incomplete' && props.selectedSceneIds.includes(ele.id)
                            ? "solid 1px #333333"
                            : "solid 1px #e3e3e3",
                }}
        >
            {props.reorderItems ? (
                <span>
                    <div className="group-settings more-icon" onClick={() => { } }>
                        <img src={require("../../../../assets/images/Move.svg")} />
                    </div>
                    {isFolder() ? (
                        <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                            <span className={`group-settings-checkbox-reorder-items`}>
                                <img src={'/img/folder_white_bg.svg'} />
                            </span>
                        </Tooltip>
                    ) : (
                        <span className={`group-settings-checkbox-reorder-items`}>
                            <Checkbox
                                className="blue-checkbox"
                                onChange={selectForFolder}
                                sceneSelected={ele.id}
                                checked={props.selectedFolderScenes.includes(parseInt(ele.id))} />
                        </span>
                    )}
                </span>
            ) :
                props.selectable ?
                    ((!props.folderState && isFolder() && props.searchValue == '')
                        || (props.searchValue != '' && isFolder())) ? (
                        <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                            <span className={`group-settings-checkbox-reorder-items`}>
                                <img src={'/img/folder_white_bg.svg'} />
                            </span>
                        </Tooltip>
                    ) : (
                        <span className="group-settings-checkbox" style={{ zIndex: "10" }}>
                            <Checkbox
                                className="black-checkbox"
                                onChange={onChange}
                                checked={props.selectedSceneIds.includes(ele.id)}
                                sceneSelected={ele.id} />
                        </span>
                    ) : (<>
                        {((!props.folderState && isFolder() && props.searchValue == '')
                            || (props.searchValue != '' && isFolder())) ? (
                            <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                                <span className={`group-settings-checkbox-reorder-items`}>
                                    <img src={'/img/folder_white_bg.svg'} />
                                </span>
                            </Tooltip>
                        ) :
                            (props.type != 'incomplete' && !isCopyInProgress) ? <Tooltip title={<span className='manrope f-12 white'>This scene has {imageCount} renders.</span>}>
                                <span className={`group-settings-checkbox-reorder-items scenery-icon`}>
                                    <span className='bg-white'>
                                        <span className='manrope f-14 black-33'>{imageCount}</span> &nbsp; <img className='card-icon' src={'/img/scenery.png'} />
                                    </span>
                                </span>
                            </Tooltip> : ''}
                        {
                          !isCopyInProgress && 
                          <Dropdown
                            className="group-settings more-icon"
                            overlay={((!props.folderState && isFolder() && props.searchValue == '')
                                || (props.searchValue != '' && isFolder()))
                                ? get_folder_options(
                                    props.renameFolderOnclick,
                                    props.unGroupFolderItemsOnclick,
                                    ele
                                )
                                : props.type == 'incomplete' ?
                                    get_incomplete_scene_option(props.setDeleteModal, ele.id)
                                    :
                                    get_scene_options(
                                        deleteScene,
                                        ele.id,
                                        ele.customer_username,
                                        moveToCustomerScene,
                                        ele.collection_id,
                                        props.managedCustomersExist,
                                        props.addToStore,
                                        props.type,
                                        ele.company_id,
                                        duplicateScene,
                                        ele.name,
                                        ele.render_generation,
                                        ele.scene_template_id,
                                        copyProductToNewSpace,
                                        setProjectModalVisible,
                                        setSelectedProductId
                                    )}
                            trigger={["click"]}
                        >
                            <Button
                                shape="circle"
                                size={"large"}
                                className="more-option-library font-14 w-900"
                                icon={<MoreIcon />}
                            ></Button>
                        </Dropdown>}
                    </>
                    )}
            <div className="stacked-card-inner">
            <Link to={(selectable || props.reorderItems) || (((!props.folderState && isFolder() && props.searchValue == '')
                    || (props.searchValue != '' && isFolder()))) ?
                    "#"
                    : getLink(ele)} style ={{pointerEvents: isCopyInProgress ? 'none' : ''}}>
                    <div
                        className="suggested-product"
                        onMouseDown={(e) => {
                            console.log('Mouse Button Pressed = ', e.button);
                            console.log('Is folder = ', isFolder(ele));
                            // on left click call the performAction function instead of directly navigating to link
                            if ((e.button === 0 && !(e.ctrlKey || e.metaKey)) || isFolder(ele)) {
                                performAction(ele);
                            }
                        } }
                        onClick={(e) => {
                            if (e.button === 0 && !(e.ctrlKey || e.metaKey)) {
                                e.preventDefault();
                            }
                        } }
                        style={{ zIndex: "owned", cursor: "pointer" }}
                    >
                      {
                        isCopyInProgress &&
                        <div style = {{    
                          position: "absolute",
                          zIndex: 2,
                          top: "40%",
                          width: "100%"
                        }}>
                        <span style = {{
                            padding: "6px 10px",
                            border: "1px dashed white",
                            borderRadius: "6px",
                            color: "white"
                          }}
                        >Copy in Progress
                        </span>
                        <span style ={{
                            color: "white",
                            fontSize: "14px",
                            display: "block",
                            margin: "10px"
                          }}
                        >Est. 2 min</span>
                      </div>}
                        <div className="box">
                            {thumbnail_link == "" ? (
                                <img
                                    src={require("../../../../assets/images/scene_incomplete.svg")}
                                    style={{ objectFit: "cover", width: "100%", height: 269 }} />
                            ) : (
                                <img
                                    src={thumbnail_link}
                                    style={{ objectFit: "cover", width: "100%", height: 269, filter: isCopyInProgress ? "blur(2px) brightness(.7)" : props.type == 'incomplete' ? 'blur(2px)' : 'none' }} />
                            )}
                            {((!props.folderState && isFolder() && props.searchValue == '')
                                || (props.searchValue != '' && isFolder())) ? '' :
                                (props.reorderItems || props.selectable) ? '' :
                                    <div className="error-id" id={`add-products-btn-${ele.id}`}>
                                        {ele.render_generation == false && ele.scene_template_id && (
                                            <div className="btn">
                                                <Button
                                                    className="modal-okay square font-14 pd-10-14"
                                                    onClick={() => {
                                                        window.location.href = `/scene_renders/${ele.id}?collection_view=true`;
                                                    } }
                                                >
                                                    Add Products to Scene
                                                </Button>
                                            </div>
                                        )}
                                    </div>}
                            {props.type == 'incomplete' ?
                                <div className="thumbnail-btn-bg blur"><span className="thumbnail-btn">Incomplete Submission</span></div>
                                : ''}
                        </div>
                        <div
                            className="justify-in-start"
                            style={{
                                margin: "12px 12px 0px 12px",
                                marginBottom: ((!props.folderState && isFolder() && props.searchValue == '')
                                    || (props.searchValue != '' && isFolder()))
                                    ? checkIfNewFolderHasName(ele)
                                        ? "8px" : "18px" : "18px"
                            }}
                        >
                            <Tooltip
                                title={<span className="manrope f-12 white">
                                    {((!props.folderState && isFolder() && props.searchValue == '')
                                        || (props.searchValue != '' && isFolder()))
                                        ? checkIfNewFolderHasName(ele)
                                            ? getFolderName(ele)
                                            : ele.name
                                        : ele.name}
                                </span>}
                            >
                                {((!props.folderState && isFolder() && props.searchValue == '')
                                    || (props.searchValue != '' && isFolder())) ? (
                                    checkIfNewFolderHasName(ele) ? (
                                        <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100 z-1">
                                            {getFolderName(ele)}
                                        </span>
                                    )
                                        :
                                        <span className="justify-in-start manrope f-14 w-500 grey-6b clamp-text w-100">
                                            <span className="manrope f-14 w-500 black-00 z-1">
                                                {ele.name}
                                            </span>
                                        </span>

                                ) : (
                                    <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100 z-1">
                                        {ele.name}
                                    </span>
                                )}
                              </Tooltip>
                              {isFolder() || (isFolder() == false && ele.last_modified_stamp == '') ? '' :
                                <Tooltip title={<span className="manrope f-12 white"> {Utilities.timestampToTimeSince(ele.last_modified_stamp, "tooltip")} </span>}>
                                  <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                                    <img src="/img/carbon_time.svg" style={{ marginRight: 2 }} /> {Utilities.timestampToTimeSince(ele.last_modified_stamp)}
                                  </span>
                                </Tooltip>}
                        </div>
                    </div>
                </Link>
            </div>
        </Card>
        <AddEntityToProject
            entityId={selectedProduct}
            entityType='Scene'
            modalVisible={projectModalVisible}
            setModalVisible={setProjectModalVisible}
            projects={props.projects} /></>
)

};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
  reorderData: (payload) => {
    dispatch(reorderData(payload));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScenesList)
);