import React, {useEffect, useState} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Modal, Input, Select } from 'antd';
import {listCollections, updateCollection} from "../../../../redux/actions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import FileConstants from '../../../../FileConstants';
const { Option } = Select;

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;

const AddCollection = (props) =>  {
    const [visible, setVisible] = useState(false);
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched , getFieldValue , setFieldsValue, resetFields} = props.form;
    const [addNew, setAddNew] = useState(false);
    const [libraryCollection, setLibraryCollection] = useState([]);

    const handleCancel = () => {
        setVisible(false);
        props.setCollectionModalVisible(false);
        resetFields();
    }

    useEffect(() => {
        setVisible(props.visible);
        if(props.visible === false) {
            resetFields();
        }
    }, [props.visible])

    const getCollectionPayload = () => {
        let payload = {
            "required_fields": ["id", "name", 
            "created_on", "spaces_count", "products_count", "template_collection_id", 
            "product_thumbnails", "thumbnail_link", "designed_for"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"
    
        if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("collection")) {
            company_id = "company_id__exact=" + COMPANY_ID
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")&&(template_collection_id__is=null)"
        } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")&&(template_collection_id__is=null)"
        }
        filter_string = filter_string + "||(" + has_access_to + ")||(template_collection_id__not=NULL&&" + customer_username+ "&&"+ is_hidden +")&&(designed_for__is=null)"
       
        payload['filter_string'] = filter_string
    
        return payload;
      }

      const getMSPCollectionPayload = () =>
      {
        let payload = {
            "required_fields": ["id", "name", "customer_username", 
            "created_on", "spaces_count", "products_count", 
            "product_thumbnails", "space_areas", "thumbnail_link", "designed_for"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let designed_for = "designed_for__exact='" + MANAGED_CUSTOMER_USERNAME + "'"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"

        if (MANAGED_COMPANY_ID) {
            company_id = "company_id__exact=" + parseInt(MANAGED_COMPANY_ID)
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden + ")&&(" + designed_for + ")"   
          } else {
            filter_string = "("+customer_username+ "&&" + is_hidden + ")&&(" + designed_for + ")" 
          }
          filter_string = filter_string + "||(" + has_access_to + ")||(" + customer_username+ "&&"+ is_hidden +"&&designed_for__is=null)"

          payload['filter_string'] = filter_string
      
          return payload;
    }

    useEffect(() => {
        let payload = {};
        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
            payload = getMSPCollectionPayload();
        } else {
            payload = getCollectionPayload();
        }
        props.listCollections(payload);
    }, []);

    useEffect(() => {
        if (props.collectionReducer.collections) {
            let collections = [];
            if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
                if (props.collectionReducer.collections['msprovider'] && props.collectionReducer.collections['customer']){
                    collections = props.collectionReducer.collections;
                    let unique = Array.from(new Set(collections.map(JSON.stringify))).map(JSON.parse);
                    let filtered_collection = unique.filter((collection) => (collection.designed_for == localStorage.getItem('managed_customer_username')) || collection.designed_for == "");
                    setLibraryCollection(filtered_collection);
                }
            } else {
                collections = props.collectionReducer.collections;
                if (FileConstants.isMSProvider) {
                    collections = props.collectionReducer.collections.filter((collection) => collection.designed_for == "");
                }
                setLibraryCollection(collections);
            }
        }
    },[props]);

    const resetSelectBox = () => {
      setFieldsValue({
          collection_name: getFieldValue("collection_name"),
          collection_id: null
      });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setVisible(false);
                props.setCollectionModalVisible(false);
                let payload = {product_id: props.productId};
                if(values.collection_name) {
                    payload.collection_name = values.collection_name;
                }
                if(values.collection_id) {
                    payload.collection_id = values.collection_id;
                }
                props.updateCollection(payload);
                message.info('Product added in collection');
            }
        });
    }

    return (
        <div>
            <Modal bodyStyle={{paddingTop: "2px"}}
                   title="Add this product to collection"
                   visible={visible}
                   onOk={handleSubmit}
                   onCancel={handleCancel}>
                <div>
                    <Form className="input-form" onSubmit={handleSubmit}>
                        <Form.Item name="collection_id"  label="Collection" colon={false}>
                            {getFieldDecorator('collection_id', {
                                rules: [{ required: false, message: 'Please select collection' }],
                            })(
                                <Select
                                    showSearch
                                    placeholder="Select Collection">
                                    {libraryCollection.map((ele, index) => {
                                        return   <Option key={index} value={ele.id}>{ele.name}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <div style={{float :"right"}}><a onClick={() => setAddNew(true)}>Add New</a></div>
                        {addNew == true ?
                            <Form.Item name="collection_name" label="Collection Name" colon={false}>
                                {getFieldDecorator('collection_name', {
                                    rules: [{ required: false, message: 'Please input collection name' }],
                                })(
                                    <Input style={{width: "95%", marginRight: "5px"}}
                                        placeholder="Please type your collection name"
                                           onChange={() => resetSelectBox()}
                                    />
                                )}
                                <CloseOutlined onClick={() => setAddNew(false)} />
                            </Form.Item> :""
                        }
                    </Form>
                </div>
            </Modal>
        </div>
    );

}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    listCollections: (payload) => {
        dispatch(listCollections(payload));
    },
    updateCollection: (payload) => {
        dispatch(updateCollection(payload));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(Form.create({ name: 'add-collection' })(AddCollection))
)
