import { ControlOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { getBaseURL, BASE_URI } from '../../../../environments/env';
import FileConstants from '../../../../FileConstants'

const ComplaintInfo = (props) => {

    const { status, admin_message, reference_images, submitted_on, platform } = props;

    const mapStatus = FileConstants.mapComplaintStatus;

    return (
        <div className="align-col-left manrope f-14 w-500 complaint-card-details" style={{ width: "100%" }} >

            <div className="display-flex-row">
                <div className="align-col-left " >
                    <p style={{ paddingLeft: 15 }}>Status</p>
                    <p className={`complaint-status ${mapStatus[status] ? mapStatus[status].color : 'red'}`} >{mapStatus[status] ? mapStatus[status].name : 'untitled'}</p>
                </div>
                <div className="align-col-left" style={{ marginLeft: 90 }}>
                    <p style={{ paddingLeft: 15 }}>Date Submitted</p>
                    <p style={{ paddingLeft: 15 }}>{submitted_on ? submitted_on.split(' ')[0] : "-"}</p>
                </div>
            </div>

            {admin_message && status === 'rejected' ? <div className="align-col-left" style={{ paddingTop: 15 }}>
                <p style={{ paddingLeft: 15 }}>Message from System Admin</p>
                <p style={{ paddingLeft: 15 }}>{admin_message}</p>

            </div> : ''}

            {reference_images && reference_images.length > 0 && <div className="align-col-left" style={{ paddingTop: 15, width: "100%" }}>
                <p style={{ paddingLeft: 15 }}>Reference Images</p>
                <div className="display-flex-row" style={{ width: "100%", paddingLeft: 8  }}>
                    {reference_images.map((image, index) => {
                        return image.name.toLowerCase().includes('.jpg') || image.name.toLowerCase().includes('.jpeg') || image.name.toLowerCase().includes('.png') || image.name.toLowerCase().includes('.svg') ?
                            <img className={`lifestyle-image-settings-custom h-150 reference-files ${index===0 ? '':'no-left-margin'}`} src={getBaseURL(platform) + BASE_URI + image.uid + `/` + image.name.replace('tiff', 'jpg')} />
                            :
                            <a className={`reference-files ${index===0 ? '':'no-left-margin'}`} href={getBaseURL(platform) + BASE_URI + image.uid + '/' + image.name} target='_blank'>
                                <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                    <img
                                        className="product-photo-artist-complaint" src={'/img/file-icon.png'}
                                    />
                                </Tooltip>
                            </a>
                    })}
                </div>
            </div>}

        </div>
    )
}

export default ComplaintInfo;