import React from 'react';
import 'antd/dist/antd.css';
import SceneName from '../AddCollectionFlow/SceneName';
import ModelsSelection from '../AddCollectionFlow/ModelsSelection';
import CustomerMainLayout from '../CustomerMainLayout/CustomerMainLayout';
import RoomSelection from '../SceneCreationFlowUtilities/RoomSelection';
import CustomerCollections from './CollectionSelection';
import MspWarningModal from '../MspWarningModal/MspWarningModal';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import NewCollection from '../SceneCreationFlowUtilities/NewCollection';
import FirstCollection from './FirstCollection';
import { Link } from 'react-router-dom';
import FileConstants from '../../../../FileConstants';

class AddSceneFlow extends React.Component {
    state={
        step: 1,
        collection_id: 291, //204, //null,
        scene_name: '',
        room_id: null,
        space_areas: [],
        model_selection_stage: 1,
        redirection_path: null,
        isMspUserWarningModal: false,
        project_id: null
    }

    componentDidMount() {
        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') == null)) {
            this.setState({
                isMspUserWarningModal: true
        })}

        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        const project_id = new URLSearchParams(window.location.search).get("project_id");
        this.setState({
            redirection_path:redirection_path,
            project_id: project_id
        })
    }
    setCollectionId = (id) => {
        this.setState({
            collection_id: id
        });
    }

    getCollectionId = () => {
        return this.state.collection_id;
    }

    setRoomId = (id) => {
        this.setState({
            room_id: id
        });
    }

    getRoomId = () => {
        return this.state.room_id;
    }

    setSceneName = (name) => {
        this.setState({
            scene_name: name
        });
    }

    getSceneName = () =>{
        return this.state.scene_name;
    }

    getSpaceAreas = () => {
        return this.state.space_areas;
    }

    setSpaceAreas = (space_areas) => {
        this.setState({
            space_areas: space_areas
        });
    }

    getModelSelectionStage = () => {
        return this.state.model_selection_stage;
    }

    setModelSelectionStage = (stage) => {
        return this.setState({
            model_selection_stage: stage
        });
    }

    setCollectionSelectionView = () =>
    {
        this.setState({
            step: 2
        });
    }
    
    setNewCollectionView = () =>
    {
        if (this.state.redirection_path == "/projects" || this.state.project_id) {
            window.location.href = "/add_collection?scene="+this.getSceneName()+"&project_id="+this.state.project_id
        } else {
            window.location.href = "/add_collection?scene="+this.getSceneName()
        }
    }

    setFirstCollectionView = () =>
    {
        window.location.href = "/add_collection?scene="+this.getSceneName()
    }

    handleWarningModalClose = () => {
        this.setState( {
            isMspUserWarningModal: false
        })
    }

    render() {
        return ( 
        <CustomerMainLayout selected='6'>
            <div>
                {(this.state.step == 1)?
                    <Row style={{marginTop: 50}}>
                        <Col span={12} offset={6}>
                            <SceneName
                            redirection_path={this.state.redirection_path}
                            scene_flow={true}
                            setCollectionId={this.setCollectionId}
                            setSceneName={this.setSceneName} 
                            setCollectionSelectionView={this.setCollectionSelectionView}
                            setFirstCollectionView={this.setFirstCollectionView}
                            />
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                    :
                    ""
                }
                {(this.state.step == 2)?
                    <div style={{padding: '20px 0px'}}>
                        <CustomerCollections 
                        redirection_path={this.state.redirection_path}
                        scene_flow={true}
                        setNewCollectionView={this.setNewCollectionView}
                        getSceneName={this.getSceneName}
                        setCollectionId={this.setCollectionId}
                        setModelSelectionStage={this.setModelSelectionStage}
                        setSpaceAreas={this.setSpaceAreas}
                        />
                    </div>
                    :
                    ""
                }
                <MspWarningModal
                    visible={this.state.isMspUserWarningModal}
                    handleModalClose={this.handleWarningModalClose}
                />
            </div> 
        </CustomerMainLayout>
        )
    }
}

export default AddSceneFlow;