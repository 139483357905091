import React from "react";
import { Layout } from "antd";
import "./SystemThroughput.scss";
import ActiveSubscriptionUsers from "../ActiveSubscriptionUsers/ActiveSubscriptionUsers";
import ModelingThroughput from "../ModelingThroughput/ModelingThroughput";
import RendersThroughput from "../RendersThroughput/RendersThroughput";
import SLAHealthReport from "../SLAHealthReport/SLAHealthReport";

const { Content } = Layout;

const SystemThroughput = () => {
  return (
    <>
      <Layout>
        <Content className="analytics-content">
          <ActiveSubscriptionUsers />
          <ModelingThroughput />
          <SLAHealthReport />
          <RendersThroughput />
        </Content>
      </Layout>
    </>
  );
};

export default SystemThroughput;
