import React, {useState, useEffect, useCallback} from 'react';
import ENVIRONMENT from '../../../../environments';
import { DownloadOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons';
import DottedLoader from "../../DottedLoader";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    Row,
    Col,
    Button,
    Tabs,
    message,
    Menu,
    Dropdown,
    Card,
    Tooltip,
    Modal,
    Upload,
    Typography
} from 'antd';
import { Form } from '@ant-design/compatible';
import AdminMainLayout from '../AdminMainLayout';
import {updateRoom, fetchRoom, getRoomRender, rejectRoom} from "../../../../redux/actions";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import ReviewProduct from "../../CustomerComponents/ReviewForm/ReviewProductForm";
import axios from 'axios';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as Constants from "../../CustomerComponents/Constants";
import RoomDetailsView from './RoomDetailsView';
import { SPACE_GUIDELINES } from '../../../../environments/env';

const { TabPane } = Tabs;
const { Paragraph } = Typography;


function saveToZip (filename, urls) {
    message.info('Generating Images Archive, your download will start shortly.', 6);
    const zip = new JSZip()
    urls.forEach((url)=> {
        const blobPromise = fetch(url, {cache: 'no-store'}).then(r => {
            if (r.status === 200) return r.blob()
            return Promise.reject(new Error(r.statusText))
        })
        const name = url.split('/').slice(-1)[0]
        zip.file(name, blobPromise)
    })

    zip.generateAsync({type:"blob"})
        .then(blob => saveAs(blob, filename))
        .catch(e => console.log(e));
}

const RoomDetails = (props) =>  {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [sharingModal, setSharingModal] = useState(false);
    const [rejectionDetails, setRejectionDetails] = useState([]);
    const [fixesDetails, setFixesDetails] = useState([]);
    const [roomDetails, setRoomDetails] = useState([]);
    const [archiveSize, setArchiveSize] = useState(0);
    const [itemHistory, setItemHistory] = useState([]);
    const [itemHistoryModal, setItemHistoryModal] = useState(false);
    const [activitySortAscending, setActivitySortAscending] = useState(false);
    const [glbSize, setGlbSize] = useState(0);
    const [adminModelStatusChange, setAdminModelStatusChange] = useState(false);
    const [resetRequestInProgress, setResetRequestInProgress] = useState(false);
    const [retriesReset, setRetriesReset] = useState(false);
    const [showRetriesModal, setShowRetriesModal] = useState(false);
    const [renderOverrideModal, setRenderOverrideModal] = useState(false);
    const [renderOverrideModalConfirm, setRenderOverrideModalConfirm] = useState(false);
    const [perspectiveRenders, setPerspectiveRenders] = useState([]);
    const [productModelOverrideMadal, setProductModelOverrideMadal] = useState(false);
    const [productModelOverrideMadalConfirm, setProductModelOverrideMadalConfirm] = useState(false);
    const [overideProductModel, setOverideProductModel] = useState([]);
    const [renderOverrideLoader, setRenderOverrideLoader] = useState(false);
    const [modelRegernateLoader, setModelRegernateLoader] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [modelArchiveValid, setModelArchiveValid] = useState(false);
    const [modelOverrideLoader, setModelOverrideLoader] = useState(false);
    const [productRegenerateConfirm, setProductRegenerateConfirm] = useState(false);
    const [labelledFloorPlanExist, setLabelledFloorPlanExist] = useState(false);
    const [embedLink, setEmbedLink] = useState(false);
    const [validation_report, setValidationReport] = useState([]);
    const [space_valid ,setSpaceValid] = useState(false);
    const [validation_check, setValidationCheck] = useState(false);
    const [validation_generated,setValidationGenerated] = useState(false);
    const [automatically_fixed_high, setAutoFixHigh] = useState([]);
    const [invalid_data_high, setInvalidDataHigh] = useState([]);
    const [successfully_validated_high, setSuccessHigh] = useState([]);
    const [success_val_length, setSuccessLength] = useState(0);
    const [invalid_val_length, setInvalidLength] = useState(0);
    const [auto_val_length, setAutoLength] = useState(0);
    const [render360_4k_success, setRender3604KSucess] = useState(false);
    const [confirm3604K, setConfirm3604K] = useState(false);
    const [compareView, setCompareView] = useState(false);
    const [space360RegenerateLoader, setSpace360RegenerateLoader] = useState(false)
    const [rejectionLoader, setRejectionLoader] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [approveToCustomerLibrary, setApproveToCustomerLibrary] = useState(false)


    const { match: { params } } = props;
    const { getFieldDecorator } = props.form;

    const setFullScreen = (value) => {
        if (value == true){
            message.info('Press ESC to exit full screen');
        }
        setIsFullScreen(value);
    }

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [isFullScreen]);

    useEffect(() => {
        props.getRoomAssets({room_id: params.id});
    }, [params]);

    const onReject = (values) => {
        let assigned_artist = props.roomReducer.room.assigned_artist;
        let payload = {
            "room_id": params.id,
            "rejection_details": values.msg,
            "rejection_against_artist": assigned_artist,
            "reference_files": values.reference_files,
            "username": localStorage.getItem('username'),
            "qa_role": "admin"
        }
        axios.post(ENVIRONMENT.ENTITY_MODEL_REJECT, payload)
            .then(res => {
                let update_payload = {
                    room_id: params.id,
                    model_status: "-4",
                    username: localStorage.getItem('username'),
                    assigned_artist: values.assigned_artist
                }
                axios.post(ENVIRONMENT.UPDATE_ROOM, update_payload)
                    .then(res => {
                        window.location.href = "/admin_rooms";
                    })
                    .catch((error) => {
                        message.error('Error in rejecting room.');
                    });

            }).catch((error) => {
                message.error('Error in rejecting Room');
            });
    }

    const onApprove = () => {
        let payload = {
            room_id: params.id,
            model_status: "4",
            username: localStorage.getItem('username')
        }
        if (roomDetails.requested_for && roomDetails.requested_for != "") {
            // Space is created by an MSP and needs to be transferred to the customer
            payload.customer_username = roomDetails.requested_for
            axios.post(ENVIRONMENT.MSP_UPDATE_SPACE, payload)
            .then(res => {
                window.location.reload();
                message.info("Room status updated successfully!")
            })
        } else {
            props.updateRoom(payload);
        }
    }

    useEffect(() => {
        console.log("approveToCustomerLibrary",approveToCustomerLibrary)
        if (approveToCustomerLibrary) {
            let payload = {
                room_id: params.id,
                model_status: "5",
                username: localStorage.getItem('username')
            }
            if (roomDetails.requested_for && roomDetails.requested_for != "") {
                // Space is created by an MSP and needs to be transferred to the customer
                payload.customer_username = roomDetails.requested_for
                axios.post(ENVIRONMENT.MSP_UPDATE_SPACE, payload)
                .then(res => {
                    window.location.reload();
                    message.info("Room status updated successfully!");
                })
            } else {
                props.updateRoom(payload);
                message.info("Room status updated successfully!");
            }
        }
    }, [approveToCustomerLibrary]);

    useEffect(() => {
        if(props.roomReducer.isUpdated) {
            window.history.back();
        }
    }, [props.roomReducer.isUpdated]);

    useEffect(() => {
        var img = document.createElement('img');
        img.onload = function() {
            // image exists and is loaded
            setLabelledFloorPlanExist(true);
        }
        img.src = ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.FLOOR_PLAN_URI + params.id + '.jpg';
    })

    const handleEmbedCancel = e => {
        setEmbedLink(false);
    };

    const downloadSceneImagesArchive = () => {
        let downloadData = [];
        if (props.roomReducer.room.three_sixties != undefined) {
          if(props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length == 0) {
              downloadData.push(ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/360Camera.jpg');
            }
            else {
                if (props.roomReducer.room.three_sixties) {
                    props.roomReducer.room.three_sixties.map((url) => {
                        downloadData.push(ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/' + url);
                    });
                }
            }
        }
        saveToZip ('Space Images.zip', downloadData);
    }

    const escFunction = useCallback((event) => {
        if(event.keyCode === 27) {
            setIsFullScreen(false);
        }
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.FETCH_ROOM, { room_id: params.id })
        .then(res => {
            console.log("Res",res.data);
            setRoomDetails(res.data);
            setValidationReport(res.data['validation_report']);

        })
    }, []);

    useEffect(() => {
        setValidationData();
    },[validation_report]);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_ROOM_FIXES_LIST, { room_id: params.id })
        .then(res => {
            setFixesDetails(res.data);
        })
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_ROOM_REJECTIONS, { room_id: params.id })
        .then(res => {
            console.log(res);
            console.log(res.data);
            setRejectionDetails(res.data);
        })
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_BATCH_ITEM_HISTORY, { entity_id: params.id, entity_type: 'Room' })
        .then(res => {
            setItemHistory(res.data);
        })
    }, []);

    const cancelRenderOverrideModal = () => {
        props.form.resetFields();
        setUploadError('');
        setRenderOverrideModal(false);
        setPerspectiveRenders([]);
    }

    const cancelRenderOverrideModalConfirm = () => {
        props.form.resetFields();
        setUploadError('');
        setRenderOverrideModalConfirm(false);
        setPerspectiveRenders([]);
    }

    const cancelProductModelOverrideMadal = () => {
        props.form.resetFields();
        setUploadError('');
        setProductModelOverrideMadal(false);
        setOverideProductModel([]);
    }

    const cancelProductModelOverrideMadalConfirm = () => {
        props.form.resetFields();
        setUploadError('');
        setProductModelOverrideMadalConfirm(false);
        setOverideProductModel([]);
    }

    const sendSpaceToAdminQA = () => {
        const hideMessage = message.loading('Moving Space to Admin QA', 0)
        let payload = {
            model_status: 3,
            room_id: params.id,
            admin_status_change: true,
            username: localStorage.getItem('username')
        }
        axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
              .then(res => {
                setAdminModelStatusChange(true);
                hideMessage()
            })
    }

    const validateFileUploads = (files) => {
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                if (file['status'] == 'error') {
                    return 'error';
                }
            }
        }
        return 'success';
    }


    const handlePerspectiveRenderChange = info => {
        let fileList = [...info.fileList];
        setPerspectiveRenders(fileList);
    };

    const handleOverrideModelChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setOverideProductModel(fileList);
    };

    const handleSubmitRenderReplaceForm = (e, confirm = false) => {
        e.preventDefault();
        props.form.validateFields(['perspective_images'], (err, values) => {
            if (!err) {

                let uploadStatus = validateFileUploads(perspectiveRenders);
                if (uploadStatus == 'uploading') {
                    setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                }
                else if (uploadStatus == 'error') {
                    setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
                }
                else if (uploadStatus == 'success'){
                    setUploadError('');

                    if(confirm == false){
                        setRenderOverrideModal(false);
                        setRenderOverrideModalConfirm(true);
                    }
                    else {
                        setRenderOverrideLoader(true);
                        let payload = {
                            room_id: params.id,
                            override_threesixties: perspectiveRenders,
                            username: localStorage.getItem('username')
                        }
                        console.log(payload)
                        console.log(JSON.stringify(payload))
                        axios.post(ENVIRONMENT.ROOM_RENNDERS_OVERRIDE, payload)
                        .then(res => {
                            console.log(res);
                            console.log(res.data);
                            window.location.href = '/admin_rooms/' + params.id;
                        })
                    }
                }
            }
        });
    }

    const handleSubmitModelOverrideForm = (e, confirm = false) => {
        e.preventDefault();
        props.form.validateFields(['override_model'], (err, values) => {
            if (!err) {

                let uploadStatus = validateFileUploads(overideProductModel);
                if (modelArchiveValid == false) {}
                else if (uploadStatus == 'uploading') {
                    setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                }
                else if (uploadStatus == 'error') {
                    setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
                }
                else if (uploadStatus == 'success'){
                    setUploadError('');

                    if (confirm == false) {
                        setProductModelOverrideMadal(false);
                        setProductModelOverrideMadalConfirm(true);
                    }
                    else {
                        setModelOverrideLoader(true);
                        let payload = {
                            room_id: params.id,
                            override_model: overideProductModel,
                            username: localStorage.getItem('username')
                        }
                        axios.post(ENVIRONMENT.ADMIN_MODEL_OVERRIDE, payload)
                        .then(res => {
                                console.log(res);
                                console.log(res.data);
                                regenerateModel();
                        })
                    }
                }
            }
        });
    }

    const regenerateModelConfirm = () => {
        setModelRegernateLoader(true);
        regenerateModel();
    }
    const convertByteSize = (size_in_mbs) => {
        let result = size_in_mbs.toFixed(2) + " MBs";
        if(size_in_mbs/1024 > 1024) // means more then 1 GB
        {
            size_in_mbs = (size_in_mbs/(1024)).toFixed(2); // size in GBs
            result =  size_in_mbs+" GBs";
        }
        return result;
    }

    const convertTimeToHoursString = (hours) => {
        hours = Math.round(hours * 1000) / 1000 // rounding to 2 decimal places only if needed
        var result = "";
        if(hours > 0){
            result += hours;
            result += hours == 1 ? " Hour " : " Hours ";
        }
        else{
            result="0 Hours"
        }
        return result;
    }

    const totalModelingTime = () => {
        var hours = +props.roomReducer.room.time1 +
            +props.roomReducer.room.time2 +
            +props.roomReducer.room.time3 +
            +props.roomReducer.room.time4;
            return convertTimeToHoursString(hours);
    }

    const totalFixesTime = () => {
        var hours = 0;
        for(var i=0; i<fixesDetails.length; i++){
            hours += +fixesDetails[i].fix_time;
        }
        return convertTimeToHoursString(hours)
    }

    const getArchiveSize = () => {
        let link = (props.roomReducer.room.download_links || []).find((ele) => ele.display_name === 'MAX');
        let size = 0;

        if(link)
        {
            axios.head(link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']))).then(res => {
                size = parseInt(res.headers['content-length']);
                setArchiveSize(size);
            });
        }
    }

    const getGlbSize = () => {
        let link = (props.roomReducer.room.download_links || []).find((ele) => ele.display_name === 'GLB');
        let size = 0;
        if(link)
        {
            axios.head(link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']))).then(res => {
                size = parseInt(res.headers['content-length']);
                setGlbSize(size);
            });
        }
    }

    const regenerateModel = () => {
        let room_id = params.id;
        let body = {};
        let archive_name = room_id + '.zip';
        body['room_id'] = room_id;
        body['model_type'] = 'low_poly_max';
        body['model_archive'] = archive_name;
        body['admin_override'] = true;
        body['username'] = localStorage.getItem('username')
        console.log('data to be sent is ');
        console.log(body);
        axios.post(ENVIRONMENT.ROOM_MODEL_CREATE, { body })
        .then(res => {
            console.log(res);
            console.log(res.data);

            //updating product status - will be removed once we have added status updated in model create API
            let payload = {
              model_status : 6,
              room_id: room_id
            }
            axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
              .then(res => {
                window.history.back();
            })
        });
    }

    const render360_4K = () => {
        setSpace360RegenerateLoader(true);
        let room_id = params.id;
        let body = {};
        let archive_name = room_id + '.zip';
        body['room_id'] = room_id;
        body['model_type'] = 'low_poly_max';
        body['model_archive'] = archive_name;
        body['4K_360'] = true;
        body['admin_override'] = true;
        body['username'] = localStorage.getItem('username');
        console.log('data to be sent is ');
        console.log(body);
        axios.post(ENVIRONMENT.ROOM_MODEL_CREATE, { body })
        .then(res => {
            console.log(res);
            console.log(res.data);

            //updating product status - will be removed once we have added status updated in model create API
            let payload = {
              model_status : 6,
              room_id: room_id,
            }
            axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
              .then(res => {
                setRender3604KSucess(true);
                setSpace360RegenerateLoader(false);
                setConfirm3604K(false);
            })
        });
    }
    const reset_model_retries = () => {
        setResetRequestInProgress(true);
        let room_id = params.id;
        let payload = {
            room_id: room_id,
            model_retries: 0,
            username: localStorage.getItem('username')
        }
        axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
        .then(res => {
            console.log('res = ', res)
            if(res.status == 200){
                setRetriesReset(true);
                setResetRequestInProgress(false);
                setShowRetriesModal(true);
            }
        })
    }


    // if the veriable sort_asc is true, sort in ascending order, not needed the first time,
    const sortActivity = (sort_asc) => {
        setActivitySortAscending(sort_asc);
        if(sort_asc == true)
            itemHistory.sort((a, b) => (a.id < b.id) ? -1 : 1);
        else
            itemHistory.sort((a, b) => (a.id < b.id) ? 1 : -1);

        forceUpdate();
    }



    const setValidationData = () => {
        let automatically_fixed_high = [];
        let successfully_validated_high = [];
        let invalid_data_high = [];
        let success_val_length = 0;
        let invalid_val_length = 0;
        let auto_val_length = 0;

        if (validation_report != undefined) {
            if (validation_report['High'] != undefined && Object.keys(validation_report['High']).length != 0) {
              if (validation_report['High']['dimensions']) {
                successfully_validated_high.push(`Depth is ${validation_report['High']['dimensions'].depth} inches`);
                successfully_validated_high.push(`Height is ${validation_report['High']['dimensions'].height} inches`);
                successfully_validated_high.push(`Width is ${validation_report['High']['dimensions'].width} inches`);
              }
              if (validation_report['High'].vertexCount) {
                successfully_validated_high.push(`Vertex Count is ${validation_report['High'].vertexCount}`);
              }
              if (validation_report['High']['space_information']) {
                if (validation_report['High']['space_information']['sun']) {
                  if (validation_report['High']['space_information']['sun'].valid && !validation_report['High']['space_information']['sun'].fixed) {
                    successfully_validated_high.push('Sun Node');
                  }
                  if (!validation_report['High']['space_information']['sun'].valid && validation_report['High']['space_information']['sun'].fixed) {
                    automatically_fixed_high.push('Sun Node');
                  }
                  if (!validation_report['High']['space_information']['sun'].valid && !validation_report['High']['space_information']['sun'].fixed) {
                    invalid_data_high.push(<span>Sun Node (Refer to Section 8 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                  }
                }
                if (validation_report['High']['space_information']['lights'] != undefined) {
                  if (validation_report['High']['space_information']['lights'].valid) {
                    successfully_validated_high.push('Lights');
                  } else if (!validation_report['High']['space_information']['lights'].valid) {
                    invalid_data_high.push(<span>Lights are Missing (Refer to Section 8 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                  }
                }

                if (validation_report['High']['space_information']['asset_node']) {
                  if (!validation_report['High']['space_information']['asset_node'].valid && validation_report['High']['space_information']['asset_node'].fixed) {
                    automatically_fixed_high.push('Space Node');
                  }

                  if (validation_report['High']['space_information']['asset_node'].valid && !validation_report['High']['space_information']['asset_node'].fixed) {
                    successfully_validated_high.push('Space Node');
                  }

                  if (!validation_report['High']['space_information']['asset_node'].valid && !validation_report['High']['space_information']['asset_node'].fixed) {
                    invalid_data_high.push(<span>Space Node (Refer to Section 5 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                  }
                }

                if (validation_report['High']['space_information']['areas']) {
                  validation_report['High']['space_information']['areas'].map((area) => {
                    if (area && area.valid) {

                    } else if (area && !area.valid && !area.fixed) {
                      if (area.structure && !area.structure.valid) {
                        invalid_data_high.push(<span>Invalid Structure for {area.name} (Refer to section 6 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                      }
                      if (area.window) {
                        if (!area.window.valid) {
                          invalid_data_high.push(<span>Invalid Window for {area.name} (Refer to section 6 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                        }
                      }
                    }
                    else if (area && !area.valid && area.fixed) {
                      if (area.structure && area.structure.fixed) {
                        automatically_fixed_high.push(`Fixed Structure for ${area.name}`);
                      }
                      if (area.window) {
                        if (area.structure &&  area.structure.fixed) {
                          automatically_fixed_high.push(`Fixed Window for ${area.name}`);
                        }
                      }
                    }
                  });
                }
              }
              if (validation_report['High']['vray_materials']) {
                validation_report['High']['vray_materials'].map((material) => {
                  if (!material.valid) {
                    invalid_data_high.push(<span> {material.name} is not a valid VRay Material (Refer to section 10 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                  }
                });
              }

              if (validation_report['High']['vray_textures']) {
                validation_report['High']['vray_textures'].map((texture) => {
                  if (!texture.found) {
                    invalid_data_high.push(<span>{texture.name} was missing in VRay Textures (Refer to section 10 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                  }
                });
              }
            }
          }
        success_val_length = successfully_validated_high.length;
        auto_val_length = automatically_fixed_high.length;
        invalid_val_length = invalid_data_high.length;




        if (validation_report != undefined  && (success_val_length != 0 || auto_val_length != 0 || invalid_val_length != 0)) {
            if (validation_report.length == 0) {
                setValidationGenerated(false);
            } else {
                setValidationGenerated(true);
                if (validation_report) {
                    if (validation_report['High']) {
                        if (validation_report['High'].report) {
                            setValidationCheck(true);

                        }
                        else {
                            setValidationCheck(false);
                            if (!validation_report['High'].space_valid) {
                                setSpaceValid(false);
                            }
                        }
                    }
                }
            }
            } else {
                if (validation_report) {
                    if (validation_report['High']) {
                        if (validation_report['High'].report) {
                            setValidationCheck(true);
                        }
                        else {
                            setValidationCheck(false);
                            if (!validation_report['High'].space_valid) {
                            setSpaceValid(false);
                            }
                        }
                    }
                }

            }


        setAutoFixHigh(automatically_fixed_high);
        setSuccessHigh(successfully_validated_high);
        setInvalidDataHigh(invalid_data_high);
        setSuccessLength(success_val_length);
        setInvalidLength(invalid_val_length);
        setAutoLength(auto_val_length);

    }

    return (
        <RoomDetailsView {...props}
        setFullScreen={setFullScreen}
        roomDetails={roomDetails}
        onReject={onReject}
        onApprove={onApprove}
        escFunction={escFunction}
        archiveSize={archiveSize}
        glbSize={glbSize}
        getArchiveSize={getArchiveSize}
        getGlbSize={getGlbSize}
        setProductModelOverrideMadal={setProductModelOverrideMadal}
        labelledFloorPlanExist={labelledFloorPlanExist}
        setRenderOverrideModal={setRenderOverrideModal}
        setProductRegenerateConfirm={setProductRegenerateConfirm}
        cancelRenderOverrideModal={cancelRenderOverrideModal}
        cancelRenderOverrideModalConfirm={cancelRenderOverrideModalConfirm}
        cancelProductModelOverrideMadal={cancelProductModelOverrideMadal}
        cancelProductModelOverrideMadalConfirm={cancelProductModelOverrideMadalConfirm}
        validateFileUploads={validateFileUploads}
        handlePerspectiveRenderChange={handlePerspectiveRenderChange}
        handleOverrideModelChange={handleOverrideModelChange}
        handleSubmitRenderReplaceForm={handleSubmitRenderReplaceForm}
        handleSubmitModelOverrideForm={handleSubmitModelOverrideForm}
        regenerateModelConfirm={regenerateModelConfirm}
        regenerateModel={regenerateModel}
        render360_4K={render360_4K}
        render360_4k_success={render360_4k_success}
        setConfirm3604K={setConfirm3604K}
        confirm3604K={confirm3604K}
        space360RegenerateLoader={space360RegenerateLoader}

        setSharingModal={setSharingModal}
        sharingModal={sharingModal}
        rejectionDetails={rejectionDetails}
        fixesDetails={fixesDetails}
        renderOverrideModal={renderOverrideModal}
        renderOverrideModalConfirm={renderOverrideModalConfirm}
        productModelOverrideMadal={productModelOverrideMadal}
        productModelOverrideMadalConfirm={productModelOverrideMadalConfirm}
        renderOverrideLoader={renderOverrideLoader}
        modelRegernateLoader={modelRegernateLoader}
        uploadError={uploadError}
        setUploadError={setUploadError}
        setModelArchiveValid={setModelArchiveValid}
        modelArchiveValid={modelArchiveValid}
        modelOverrideLoader={modelOverrideLoader}
        productRegenerateConfirm={productRegenerateConfirm}
        params={params}
        itemHistory={itemHistory}
        setItemHistoryModal={setItemHistoryModal}
        setActivitySortAscending={setActivitySortAscending}
        activitySortAscending={activitySortAscending}
        sortActivity={sortActivity}
        itemHistoryModal={itemHistoryModal}
        convertTimeToHoursString={convertTimeToHoursString}
        totalModelingTime={totalModelingTime}
        totalFixesTime={totalFixesTime}
        convertByteSize={convertByteSize}
        isFullScreen={isFullScreen}
        embedLink={embedLink}
        setEmbedLink={setEmbedLink}
        handleEmbedCancel={handleEmbedCancel}
        setValidationData={setValidationData}
        validation_check={validation_check}
        space_valid={space_valid}
        validation_generated={validation_generated}
        automatically_fixed_high={automatically_fixed_high}
        invalid_data_high={invalid_data_high}
        successfully_validated_high={successfully_validated_high}
        success_val_length={success_val_length}
        invalid_val_length={invalid_val_length}
        auto_val_length={auto_val_length}
        reset_model_retries={reset_model_retries}
        retriesReset={retriesReset}
        showRetriesModal={showRetriesModal}
        setShowRetriesModal={setShowRetriesModal}
        resetRequestInProgress={resetRequestInProgress}
        compareView={compareView}
        setCompareView={setCompareView}
        sendSpaceToAdminQA={sendSpaceToAdminQA}
        adminModelStatusChange={adminModelStatusChange}
        setAdminModelStatusChange={setAdminModelStatusChange}
        validation_report={validation_report}
        rejectionLoader={rejectionLoader}
        setRejectionLoader={setRejectionLoader}
        setApproveToCustomerLibrary={setApproveToCustomerLibrary}
        />
    )


}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
    getRoom: (payload) => {
        dispatch(fetchRoom(payload))
    },
    getRoomAssets: (room) => {
        dispatch(getRoomRender(room))
    },
    rejectRoom: (room) => {
        dispatch(rejectRoom(room))
    }
});


export default Form.create({ name: 'render-override-form' })(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(RoomDetails)
))
