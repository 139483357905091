import React from 'react';
import { Table,Modal,Button, Form, Select, Input, Space, Tag, Row, Tooltip, Radio } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import { DEACTIVATE_USER } from '../../../../environments/env';
import { EditOutlined, InfoCircleOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };
const tagStyles = {
  'Good': {
    'borderColor': '#B7EB8F',
    'backgroundColor': '#F6FFED',
    'color': '#52C41A'
  },
  'Average': {
    'borderColor': '#FFE58F',
    'backgroundColor': '#FFFBE6',
    'color': '#FAAD14'
  },
  'Bad': {
    'borderColor': '#FFBB96',
    'backgroundColor': '#FFF2E8',
    'color': '#FA541C'
  }
}

const { Option } = Select;

class ArtistsTable extends React.Component {
  formRef = React.createRef();

  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    showHeader,
    footer: false,
    tableLayout: 'fixed',
    scroll: undefined,
    hasData: true,
    dataSource: null,
    confirm_deactivation_modal: false,
    selected_artist: '',
    button_loader: false,
    promote_to_super_artist_modal_visible: false,
    demote_to_artist_modal: false,
    selected_artist_id: null,
    button_loader: false,
    promote_test_artist_modal: false,
    selected_artists_billing_type: '',
    selected_artists_hourly_rate: '',
    set_info_modal: false,
    artist_promotion_modal: null,
    selected_artist_name: null,
    edit_icon_id: null
  };

  getColumns = () => [
    {
      title: 'Artist Username',
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name'),
      render: (text, record) => <span>{<a style={{marginRight: 10}} className="onhover-underline" href={'../admin_artist_details/' + record.artist_id}>{text}</a>}</span>,
    },
    {
      title: 'Billing Rate',
      dataIndex: 'hourly_rate',
      key: 'hourly_rate',
      sorter:(a,b)=>a.hourly_rate-b.hourly_rate,
      render: (text,record) => <span>{this.getBillingRateString(record.hourly_rate, record.billing_type)}</span>
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      sorter: (a, b, sortOrder) => {
        if (sortOrder === 'ascend') {
            if (a.rating == null || a.rating === '') return 1;  
            if (b.rating == null || b.rating === '') return -1; 
            return a.rating - b.rating; 
        }
        if (sortOrder === 'descend') {
          return a.rating - b.rating; 
        }
        return 0;
    }
    
    },
    {
      title: 'Type',
      dataIndex: 'is_superartist',
      key: 'is_superartist',
      filters: [
          {
            text: 'Test Artist',
            value: 'Test Artist',
          },
          {
            text: 'Artist',
            value: 'Artist',
        },
        {
          text: 'Super Artist',
          value: 'Super Artist'
        }
      ],
      onFilter: (value, record) => this.getArtistType(record) === value,
      render: (text,record) => <span>{this.getArtistType(record)}</span>
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.jobDeskAction,
      render: (text, record) => (
        <span>
          {(record.is_active == true || record.is_active == "True") ?
          <a style={{color: 'red'}} className="onhover-underline" onClick={() => this.openDeactivationModal(record.artist_id,record.name)}>Disable Account</a>

          : <span>Disabled</span>}
          {/* Option for making an artist a super artist */}
          {(record.is_active == true || record.is_active == "True") ?
          <React.Fragment>
            <span style={{marginRight: 10, marginLeft: 10}}>|</span>
            {((record.is_superartist == false || record.is_superartist == "False" )&& !record.is_test_artist) ?
              <a className="onhover-underline" onClick={() => this.handleBillingInfoAndPromotion(record, 'super_artist')}>Promote to Super Artist</a>
              : record.is_test_artist ?
              <a className="onhover-underline" onClick={() => this.handleBillingInfoAndPromotion(record, 'test_artist')}>Promote to Artist</a>
            : <a className="onhover-underline" onClick={() => this.openDemoteToArtistModal(record.artist_id,record.name)}>Demote to Artist</a>}
          </React.Fragment>
          : ""}
        </span>
      ),
    },
  ];

  componentDidUpdate = (prevProps) => {
    if(!this.props.is_loading && prevProps!=this.props)
      this.handleDataSource();
  }

  componentDidMount = () => {
    this.handleDataSource();
  }

  getTagStyle = (text) => {
    let style = {}
    if(text == 'Bad')
      style= tagStyles['Bad']
    else if(text == 'Average')
      style= tagStyles['Average']
    else if(text == 'Good')
      style= tagStyles['Good']
    style['cursor'] = 'pointer'
    return style;
  }

  getBillingRateString = (rate, billing_type) => {
    let billing_rate = "N/A";
    if (rate && rate != 0 && rate != ""){
      billing_rate = "$" + rate;
      if (billing_type == "hourly")
        billing_rate += " / hour";
      else if(billing_type == "per_model")
        billing_rate += " / model";
    }
    return billing_rate;
  }

  handleBillingInfoAndPromotion = (record, artist_promotion_modal) => {
    console.log('LOG = ', record)
    if(!record.billing_type || !record.hourly_rate){
      this.setState({
        selected_artists_billing_type: record.billing_type,
        selected_artists_hourly_rate: record.hourly_rate,
        set_info_modal: true,
        selected_artist_id: record.artist_id,
        selected_artist_name: record.name,
        artist_promotion_modal: artist_promotion_modal
      })
    }
    else{
      if(artist_promotion_modal == 'super_artist')
        this.openPromoteToSuperArtistModal(record.artist_id, record.name);
      if(artist_promotion_modal == 'test_artist')
        this.openPromoteTestArtistModal(record.artist_id, record.name);
    }
  }


  getArtistType = (record) => {
    let artist_type = 'Artist'
    if(record.is_superartist)
      artist_type = 'Super Artist'
    else if(record.is_test_artist)
      artist_type = 'Test Artist'

    return artist_type
  }

  handleDataSource = () => {
    let data = []
  
    for (let i = 1; i <= (this.props.artists_data).length; i++) {
      if(this.props.artists_data[i - 1]['is_active'] == this.props.is_active){
        data.push({
          key: i,
          artist_id: this.props.artists_data[i - 1]['id'],
          name: this.props.artists_data[i - 1]['artist_profile'],
          hourly_rate: this.props.artists_data[i - 1]['hourly_rate'],
          billing_type: this.props.artists_data[i - 1]['billing_type'],
          rating: this.props.artists_data[i - 1]['rating'],
          is_active: this.props.artists_data[i - 1]['is_active'],
          is_superartist: this.props.artists_data[i - 1]['is_superartist'],
          is_test_artist: this.props.artists_data[i - 1]['is_test_artist'],
        });
      }
    }
    // sort alpahabatically
    data = data.sort(function(a, b) {
      return a.name.localeCompare(b.name);
    });
    this.setState({
      dataSource: data
    })
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };


  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  cancelDeactivationModal = () => {
    this.setState({
      confirm_deactivation_modal: false
    });
  }

  openDeactivationModal = (id,name) => {
    console.log(name);
    this.setState({
      confirm_deactivation_modal: true,
      selected_artist: name
    });
  }

  openPromoteToSuperArtistModal = (id, name) =>{
    console.log(name);
    this.setState({
      promote_to_super_artist_modal_visible: true,
      selected_artist: name,
      selected_artist_id: id
    });
  }

  openDemoteToArtistModal = (id, name) => {
    console.log(name);
    this.setState({
      demote_to_artist_modal: true,
      selected_artist: name,
      selected_artist_id: id
    });
  }

  openPromoteTestArtistModal = (id, name) => {
    this.setState({
      promote_test_artist_modal: true,
      selected_artist: name,
      selected_artist_id: id
    })
  }

  updateTestArtistStatusToArtist = () => {
    this.setState({
      button_loader: true
    });
    let payload = {
      profile_id: this.state.selected_artist_id,
      is_test_artist: false
    }
    console.log('test artist payload = ', payload)
    axios.post(ENVIRONMENT.UPDATE_ARTIST_PROFILE, payload)
    .then(res=>{
      console.log(res);
      this.setState({
        promote_test_artist_modal: false,
        button_loader: false
      })
      window.location.reload();
    }).catch(err => {
      console.log('An error occured while trying to promote the test artist to Artist ', err);
    })
  }


  deactivateAccount = () => {
    this.setState({
      button_loader: true
    });
    let payload = {
      username: this.state.selected_artist,
    }
    console.log(payload);
    axios.post(DEACTIVATE_USER,payload)
    .then(res => {
      console.log(res);
      this.setState({
        confirm_deactivation_modal: false,
        button_loader: false
      });
      window.location.reload();
    }).catch(err => {
      console.log('An error occured while trying to deactivate account. ', err);
    })
  }

  updateSuperArtistStatus = (superartist_status) => {
    this.setState({
      button_loader: true
    });
    let payload = {
      profile_id: this.state.selected_artist_id,
      is_superartist: superartist_status
    }
    console.log('super artist payload = ', payload)
    axios.post(ENVIRONMENT.UPDATE_ARTIST_PROFILE, payload)
    .then(res=>{
      console.log(res);
      this.setState({
        promote_to_super_artist_modal_visible: false,
        demote_to_artist_modal: false,
        button_loader: false
      })
      window.location.reload();
    }).catch(err => {
      console.log('An error occured while trying to promote the artist to Super Artist ', err);
    })
  }

  updateArtistInfo = (e) => {
    this.setState({buttonLoader: true})
    console.log('e = ', e)
    let payload = {
      'profile_id': this.state.selected_artist_id,
      'billing_type': e.billing_type,
      'hourly_rate': e.billing_rate,
    }
    console.log('payload = ', payload)
    axios.post(ENVIRONMENT.UPDATE_ARTIST_PROFILE, payload)
      .then(res => {
      this.setState({
        set_info_modal: false
      }, ()=>{
        if(this.state.artist_promotion_modal=='super_artist'){
          this.openPromoteToSuperArtistModal(this.state.selected_artist_id, this.state.selected_artist_name);
        }
        else if(this.state.artist_promotion_modal=='test_artist'){
         this.openPromoteTestArtistModal(this.state.selected_artist_id, this.state.selected_artist_name);
       }
      })
      })
  }
  // Search in table functionality
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  updateSkillMatrix = () => {
    this.setState({
      button_loader: true
    })
    axios.post(ENVIRONMENT.UPDATE_ARTIST_PROFILE, {
      profile_id: this.state.selected_artist_id
    }).then(res => {
      this.setState({
        button_loader: false
      }, ()=>window.location.reload())
    })
  }

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource &&
          <Table {...this.state} loading={{indicator: <LoadingOutlined/>, spinning: this.props.is_loading}} columns={this.getColumns()} dataSource={dataSource} />
        }
         <Modal
            visible={this.state.confirm_deactivation_modal}
            className="silo-success-modal"
            closable={false}
            width={788}
            onCancel={this.cancelDeactivationModal}
            footer={[
              <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14" htmlType="submit" onClick={this.cancelDeactivationModal}>
                    Cancel
                </Button>
                <Button disabled={this.state.button_loader} className="modal-okay square font-14" htmlType="submit" onClick={this.deactivateAccount}>
                    Yes, I am Sure&nbsp;{this.state.button_loader ? <LoadingOutlined/> : ''}
                </Button>
                </div>
            ]}>
              <div style={{padding:"16px 6px 8px 14px"}}>
                <h2 className="share-modal-heading" style={{textAlign:"center",marginBottom: 8}}>Are you sure you want to disable {this.state.selected_artist}'s' account?</h2>
                <div className="modal-heading-text" style={{textAlign:"center"}}>
                  The artist won't be able to log in to their account after you perform this action.
                </div>
              </div>
          </Modal>

          {/* Modal for promoting an artist to a super Artist */}
          <Modal
            visible={this.state.promote_to_super_artist_modal_visible}
            className="silo-success-modal"
            closable={false}
            width={788}
            onCancel={()=> {this.setState({promote_to_super_artist_modal_visible: false})}}
            footer={[
              <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14" htmlType="submit" onClick={()=> {this.setState({promote_to_super_artist_modal_visible: false})}}>
                    Cancel
                </Button>
                <Button disabled={this.state.button_loader} className="modal-okay square font-14" htmlType="submit" onClick={()=>{this.updateSuperArtistStatus(true)}}>
                    Yes, I am Sure&nbsp;{this.state.button_loader ? <LoadingOutlined/> : ''}
                </Button>
                </div>
            ]}>
              <div style={{padding:"16px 6px 8px 14px"}}>
                <h2 className="share-modal-heading" style={{textAlign:"center",marginBottom: 8}}>Are you sure you want to promote {this.state.selected_artist} to Super Artist?</h2>
                <div className="modal-heading-text" style={{textAlign:"center"}}>
                  This artist will have higher privileges than a normal Artist. They will be able to:
                  <li>Work on customer complaints</li>
                </div>
              </div>
          </Modal>

          {/* Modal for Demoting a Super artist to an Artist */}
          <Modal
            visible={this.state.demote_to_artist_modal}
            className="silo-success-modal"
            closable={false}
            width={788}
            onCancel={()=> {this.setState({demote_to_artist_modal: false})}}
            footer={[
              <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14" htmlType="submit" onClick={()=> {this.setState({demote_to_artist_modal: false})}}>
                    Cancel
                </Button>
                <Button disabled={this.state.button_loader} className="modal-okay square font-14" htmlType="submit" onClick={()=>{this.updateSuperArtistStatus(false)}}>
                    Yes, I am Sure&nbsp;{this.state.button_loader ? <LoadingOutlined/> : ''}
                </Button>
                </div>
            ]}>
              <div style={{padding:"16px 6px 8px 14px"}}>
                <h2 className="share-modal-heading" style={{textAlign:"center",marginBottom: 8}}>Are you sure you want to demote {this.state.selected_artist} to an Artist?</h2>
                <div className="modal-heading-text" style={{textAlign:"center"}}>
                  This artist will have less privileges than a Super Artist. They won't be able to:
                  <li>Work on customer complaints</li>
                </div>
              </div>
          </Modal>

          {/* Modal for Promoting test artist to an Artist */}
          <Modal
            visible={this.state.promote_test_artist_modal}
            className="silo-success-modal"
            closable={false}
            width={788}
            onCancel={()=> {this.setState({promote_test_artist_modal: false})}}
            footer={[
              <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14" htmlType="submit" onClick={()=> {this.setState({promote_test_artist_modal: false})}}>
                    Cancel
                </Button>
                <Button disabled={this.state.button_loader} className="modal-okay square font-14" htmlType="submit" onClick={()=>{this.updateTestArtistStatusToArtist()}}>
                    Yes, I am Sure&nbsp;{this.state.button_loader ? <LoadingOutlined/> : ''}
                </Button>
                </div>
            ]}>
              <div style={{padding:"16px 6px 8px 14px"}}>
                <h2 className="share-modal-heading" style={{textAlign:"center",marginBottom: 8}}>Are you sure you want to promote {this.state.selected_artist} to an Artist?</h2>
                <div className="modal-heading-text" style={{textAlign:"center"}}>
                  This artist will have higher privileges than a test artist. They will be able to:
                  <li>Pick items from job desk</li>
                </div>
              </div>
          </Modal>

          {/* Modal for updating artists profile Info */}
        <Modal
          visible={this.state.set_info_modal}
          width={500}
          closable={false}
          footer={[
            <div className="justify-in-end">
                <Button className="modal-okay-gray square font-14"  htmlType="submit" onClick={() => this.setState({set_info_modal: false})}>
                    Cancel
                </Button>
                <Button disabled={this.state.buttonLoader} className="modal-okay square font-14" htmlType="submit" onClick={() => document.getElementById("update_artist_data").click()}>
                    Save&nbsp;{this.state.buttonLoader ? <LoadingOutlined/>: ''}
                </Button>
                </div>
            ]}>
          <div style={{padding:"32px 32px 24px 32px"}}>
          <div className="manrope f-20 black-14" style={{marginBottom: 16,textTransform:"capitalize"}}>
            Provide Billing Info
          </div>
          <Form
              ref={this.formRef}
              name="material-form"
              onFinish={this.updateArtistInfo}
              initialValues={{
                billing_type: this.state.selected_artists_billing_type == 'hourly' || this.state.selected_artists_billing_type == 'per_model' ? this.state.selected_artists_billing_type : undefined,
                billing_rate: this.state.selected_artists_hourly_rate != "" && this.state.selected_artists_hourly_rate != 0 ? this.state.selected_artists_hourly_rate : undefined,
                }}>
                <div className="manrope f-14 black-55" style={{marginBottom: 8}}>Billing Type<span style={{color: 'red'}}> *</span></div>
                <Form.Item name="billing_type" className="material-category-form"
                  rules={[{ required: true, message: 'Please Select the Billing Type of Artist!' }]}>
                    <Select
                        className="tree-select-material"
                        style={{color:"#333333"}}
                        placeholder="Please Select Billing Type">
                        <Option key={1} value='hourly'>Hourly</Option>
                        <Option key={2} value='per_model'>Per Model</Option>
                    </Select>
                </Form.Item>
              <div className="manrope f-14 black-55" style={{marginBottom: 8,marginTop: 22}}>Billing Rate<span style={{color: 'red'}}> *</span></div>
              <Form.Item style={{marginBottom: 32}} name="billing_rate" className="material-category-form"
                rules={[{ required: true, message: 'Enter Amount' }]}>
                <Input className="manrope f-14" style={{lineHeight: '40px'}} placeholder="Enter Billing Rate" />
              </Form.Item>

                <Form.Item style={{display: 'none'}}>
                  <Button id="update_artist_data" style={{display: "none"}} className="modal-okay square font-14" htmlType="submit">
                    Submit&nbsp;{this.state.buttonLoader ? <LoadingOutlined/>: ''}
                  </Button>
                </Form.Item>
             </Form>

          </div>
        </Modal>

      </div>
    );
  }
}

export default ArtistsTable;
