import React, { useState } from 'react';
import {  Row, Col,Form, Upload, Button,Card, Tooltip,Dropdown,Menu,Modal,Radio, Input,Tabs,  Collapse,List,Typography, Carousel} from 'antd';
import {
  ArrowRightOutlined,
  CheckOutlined,
  DownloadOutlined,
  DownOutlined,
  EyeOutlined,
  LoadingOutlined,
  WarningOutlined,
  RightOutlined,
  LeftOutlined
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import * as JSZip from 'jszip';
import ENVIRONMENT  from '../../../../environments'
import DottedLoader from '../../../App/DottedLoader/DottedLoader';
import * as Constants from "../../CustomerComponents/Constants";
import PanoramaThumbnailViewer from '../../CustomerComponents/PanoramaThumbnailViewer';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { FLAG_IF_UNHAPPY_GUIDELINES } from '../../../../environments/env';
import ComplaintImageViewer from './ComplaintImageViewer';
import ComplaintRejectionModal from './ComplaintRejectionModal';

const { TextArea } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const { SPACE_GUIDELINES } = ENVIRONMENT;


const ComplaintDetailsComponent = (props) => {

  const [complaintUnassignmentModal, setComplaintUnassignmentModal] = useState(false);
  const [complaintUnassignmentSucess, setComplaintUnassignmentSucess] = useState(false);
  const [complaintUnassignmentLoader, setComplaintUnassignmentLoader] = useState(false);
  const [rejectionConfirmationModal, setRejectionConfirmationModal] = useState(false);
  const [detailedRejectionModal, setDetailedRejectionModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [showImage, setShowImage] = useState(false);

  const handleComplaintUnassignment = () => {
    setComplaintUnassignmentLoader(true);
    var pathname = window.location.pathname,
    complaint_id = pathname.substr(pathname.lastIndexOf('/') + 1);
    let payload = {
      'complaint_id': complaint_id,
      'assigned_to': '', 
      'assigned_by': localStorage.getItem('username')
    }
    axios.post(ENVIRONMENT.UPDATE_FLAG_STATUS, payload)
      .then(res => {
        setComplaintUnassignmentModal(false);
        setComplaintUnassignmentLoader(false);
        setComplaintUnassignmentSucess(true);
      })
  }

  const totalFixesTime = () => {
    console.log(props)
    let total_time = 0;
    if(props.complaint_time_log !=[]) {
     props.complaint_time_log.map((ele) => {
       total_time += parseFloat(ele['time_spent'])
     })

     total_time = total_time.toFixed(2);
    }
    return total_time;
  }

    const menu = ( 
      <Menu style={{ width: "250px",fontFamily:"Avenir" }}>
          <Menu.Item key={0}>
            <a target="_blank" onClick={() => props.downloadSiloFlaggedArchive()}>Flagged Images Only <span style={{ float: "right", color:"#1681E5" }}>Download</span></a>
          </Menu.Item>
          {(props.productReducer.product.download_links || []).map((ele, index) => {
            if (ele.display_name.includes('MAX')) {
              return (
                <Menu.Item key={index}>
                    <a target="_blank" href={ele.model_url} style={{fontFamily:"Avenir"}}>{ele.display_name} Download Link <span style={{ float: "right", color:"#1681E5"  }}>Download</span></a>
                </Menu.Item>
              );
            }
            })}
            <Menu.Item key={3}>
            <a target="_blank" onClick={() => props.downloadImagesArchive()}> All Images <span style={{ float: "right" , color:"#1681E5" }}>Download</span></a>
          </Menu.Item>
          
          <Menu.Item key={4}>
            <a target="_blank" onClick={() => props.downloadCryptoImagesArchive()}> Cryptomatte <span style={{ float: "right" , color:"#1681E5" }}>Download</span></a>
          </Menu.Item>
      </Menu>
    );

    const override_menu = (
      <Menu style={{ width: "250px",fontFamily:"Avenir" }}>
        <Menu.Item key={0} onClick={props.openReplaceModal}>
          Replace {props.type == "scene_360" ? '360s' : (props.type == "scene_lifestyle" ? "Images" : "")}
        </Menu.Item>
          <Menu.Item key={1} disabled={!props.config_exist} onClick={props.replaceJSONModal}>
            <Tooltip title={props.config_exist ? '' : <span className="manrope f-12 white"> Scene Config not available for this image. Please contact admin.</span>}>
              Replace Scene JSON File
            </Tooltip>
          </Menu.Item>
          <Menu.Item key={2}  disabled={!props.config_exist} onClick={props.replace3dsMaxModal}>
            <Tooltip title={props.config_exist ? '' : <span className="manrope f-12 white"> Scene Config not available for this image. Please contact admin.</span>}>
              Replace Max and JSON File
            </Tooltip>
          </Menu.Item>
          <Menu.Item key={3}  disabled={!props.config_exist} onClick={props.openSpaceBundleModal}>
            <Tooltip title={props.config_exist ? '' : <span className="manrope f-12 white"> Scene Config not available for this image. Please contact admin.</span>}>
            Replace Space File
            </Tooltip>
          </Menu.Item>
      </Menu>
    )

    const scene_menu = ( 
      <Menu style={{ width: "250px",fontFamily:"Avenir" }}>
          <Menu.Item key={0}>
            <a target="_blank" onClick={() => props.downloadSceneFlaggedArchive()}>{props.type =="scene_lifestyle" ? "Flagged Images Only" : "Flagged 360s Only"}<span style={{ float: "right", color:"#1681E5" }}>Download</span></a>
          </Menu.Item>
          <Menu.Item key={1}>
            <a target="_blank" onClick={() => props.downloadSceneImagesArchive()}>{props.type =="scene_lifestyle" ? "All Images" : "All 360s"}<span style={{ float: "right" , color:"#1681E5" }}>Download</span></a>
          </Menu.Item>
          {props.scene_configurations ? 
          <Menu.Item key={2} onClick={props.show3dsDownload}>
              <a target="_blank" >            
                <Tooltip placement="left" title={'This bundle contains product and space max files along with the JSON for the scene.'}>
                  Scene Bundle
                  <span style={{ float: "right" , color:"#1681E5" }}>Download</span>
                </Tooltip>
              </a>
          </Menu.Item> : ''}
          <Menu.Item key={3}>
            <a target="_blank"  href={props.room_max_archive}>            
                <Tooltip placement="left" title={'This bundle contains the max file of the space used in the scene.'}>
                  Space Bundle
                  <span style={{ float: "right" , color:"#1681E5" }}>Download</span>
                </Tooltip>
              </a>
          </Menu.Item>
          {props.crypto_exists &&
          <Menu.Item key={4}>
            <a target="_blank" onClick={() => props.downloadCryptoImagesArchive()}> Cryptomatte <span style={{ float: "right" , color:"#1681E5" }}>Download</span></a>
          </Menu.Item>}
      </Menu>
    );

    const tour_menu = (
      <Menu style={{ width: "250px",fontFamily:"Avenir" }}>
        <Menu.Item key={1}>
            <a target="_blank" href={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/maxScene.zip'}>            
              <Tooltip placement="bottom" title={'This Bundle contains all 360s and their associated 3DS MAX Files'}>
                Download 3ds Max Scene Bundle
              </Tooltip>
              <span style={{ float: "right" , color:"#1681E5" }}><DownloadOutlined/></span>
            </a>
          
        </Menu.Item>
      </Menu>
    )

    const handleImage = (event) => {
      event.preventDefault();
      setImageUrl(event.currentTarget.getAttribute('href'));
      setShowImage(true);
    }

    const generateProductSiloLink = (camera_name, img_format) => {
      let img_url = ''
      if(props.product_guidelines && props.product_guidelines.company_id && (props.product_guidelines.company_id == props.company_id)){
        img_url = ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.QA_IMG_URI  + props.scene_id + '/company/' + props.product_guidelines.company_id + '/' + camera_name + "." + img_format.replace('tiff', 'jpg')
      }
      else {
        img_url = ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.QA_IMG_URI  + props.scene_id + '/' + props.customer_name + '/' + camera_name + "." + img_format.replace('tiff', 'jpg')
      }
      return img_url
    }

    const displayAdminOverrideButton = (status, type, superArtist) => {
      var display = false;
      if(type == "scene_360" || type == "scene_lifestyle"){
      if(superArtist){
          if(status == 'pending' || status == 'sent_back_to_artist'){
            display = true;
          }
        }
        else{
          if(status == 'pending' || status == 'sent_back_to_artist' || status == 'admin_qa'){
            display = true;
          }
        }
      }
      return display
    }

    // Rejection History for Complaints
    {console.log('props = ', props)}
    let rejections_data = '';
    if (props.rejectionDetails && props.rejectionDetails.length > 0) {
      let ele = props.rejectionDetails[0];
      rejections_data = (
        <div style={{marginTop: 20}}>
          <div className='artist-upload-txt small align-text'>Date <span style={{color:"#276DD7"}}>{moment(ele.rejection_time, 'YYYY-MM-DD HH:mm:ss').toDate().toLocaleString('en-us')|| '-'}</span></div>
          <div style={{display: "flex"}}>
              <Col span={(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ? 16 : 24}>
              <span style={{paddingRight: 5, overflow: 'hidden'}}>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7}}>
                    Comment
                </span>
                <span style={{display: 'flex'}}>
                <span className="category-artist" style={{marginBottom: 30, fontSize: 16, width: '90%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'left',color:"#276DD7"}}>
                    {ele.rejection_message}
                </span>
                </span>
              </span>
              </Col>
             
            
            {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?
                <Col span={8}>
                    <div className="category-artist" style={{textAlign: 'left', marginBottom: 7}}>
                        Uploads
                    </div>
                    {ele.reference_files && ele.reference_files.length > 0 ?
                        <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name'])}>
                        {(ele.reference_files[0].name.includes('.jpg') || ele.reference_files[0].name.includes(".png") || ele.reference_files[0].name.includes(".PNG") || ele.reference_files[0].name.includes(".svg")) ? 
                        <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(ele.reference_files[0]['uid'] + '/' + ele.reference_files[0]['name'])}/> :
                        (ele.reference_files[0].name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> : 
                        <img className="upload-image-settings" src={'/img/ele.reference_files[0]-icon.png'}/> }
                        <span className="category-artist" style={{fontSize: 14, textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', width: '90%', whiteSpace: 'nowrap'}}>{ele.reference_files[0].name}</span>
                        </a>
                    : ''}
                </Col>
            : ''}
          </div>
        </div>
      );
    }
    
    // Detailed Rejection history
    let detailed_rejection_data = '';
    console.log(props.rejectionDetails, "rejection det")
    if (props.rejectionDetails && props.rejectionDetails.length > 0) {
     detailed_rejection_data = props.rejectionDetails.map((ele, index) => (
        <div style={{marginTop:2, maxWidth:"941px"}}>
        <span style={{marginTop:15, display:"block" }} className='note-bg-artist'>Date <span style={{color:"#276DD7", paddingLeft: 3, marginRight:25}}>{moment(ele.rejection_time, 'YYYY-MM-DD HH:mm:ss').toDate().toLocaleString('en-us') || '-'}</span>  Activity By: <span style={{marginLeft:5,color:"#276dd7"}}>{ele.admin_username}</span></span>
        {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?
        <div style={{display: "block", margin: 2, padding:2, width:'100%'}} >
              <div>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7, width:"50%", float:'left'}}>
                    Comments:
                </span>
                <span className="category-artist" style={{ marginBottom: 7, width:"50%", float:'left'}}>
                    Uploads:
                </span>
              </div>
              <div style={{marginBottom:10, width:'100%'}}>
                <span className="" style={{textAlign:"left", fontSize: 14, color:"#333333", width:"51%" , float:'left'}}>
                    {ele.rejection_message}
                </span>
              <div style={{float:'left', width:'47%'}}>
                <Carousel style={{width:"100%"}} arrows={true} nextArrow={<RightOutlined />} prevArrow={<LeftOutlined/>}>
                
                    {ele.reference_files && ele.reference_files.map((file,index) => (
                        
                        <a onClick={handleImage} style={{color:"#276DD7", lineHeight: '120%'}} className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                        <div style={{width:"100%", display:"flex", justifyContent:"center"}}> 
                        {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ? 
                        <img className="upload-image-settings-reject" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                        (file.name.includes(".tiff") ) ?  <img className="upload-image-settings-reject" src={'/img/tiff_icon.png'}/> : 
                        <img className="upload-image-settings-reject" src={'/img/file-icon.png'}/> }
                        </div>
                        <p className="category-artist" style={{fontSize: 14, textAlign: 'center', color: '#555555'}}>{file.name}</p>
                        </a>
                        
                    ))}
                
                </Carousel>
              </div>
             
          </div>
        </div>
        :
        <div>
            <span className="category-artist" style={{textAlign: 'left', width:"10%", float:'left', marginTop:'4px'}}>
                Comments:
            </span>
            <span className="" style={{textAlign:"left", fontSize: 14, color:"#333333", width:"90%" , float:'left'}}>
                {ele.rejection_message}    
            </span>
        </div>
        }
      </div>
     ));
    }
    
    return(<React.Fragment>
    {props.loading_state ? <DottedLoader/> : 
    
    <Row style={{marginBottom: 60}}>
      <Col span={24}>
      <h3 className="flag-complaint-heading">
        <span style={{alignSelf: 'center'}}><Link className="store-bd " to={`/admin`} style={{color:"#999999"}}>Home</Link> / <Link className="store-bd " to={`/complaints`} style={{color:"#999999"}}>Customer Complaints</Link> / <span className="active-route-link-color">{ props.type == "Product" ?  props.product_guidelines.parent_category && props.product_guidelines.parent_category + ' / ' + props.product_guidelines.category :  props.scene_name}</span></span>
        {props.type == "Product" ? (!props.isFullScreen ? 
          <div id="components-dropdown-demo-dropdown-button">
            <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" disabled={props.status=="rejected"}>
                <Button className="modal-okay-gray square font-14">Download Options <DownOutlined /></Button> 
            </Dropdown>
          </div> : "")
        :
        (props.type == "scene_lifestyle" || props.type =="scene_360" || props.type == "scene_3dtour") ?
        <span style={{display:"flex",justifyContent:"space-between"}}>
        {!props.isFullScreen ? 
          <div id="components-dropdown-demo-dropdown-button">
            <Dropdown overlay={props.type == "scene_3dtour" ? tour_menu : scene_menu} trigger={['click']} placement="bottomRight" disabled={props.status=="rejected"}>
                <Button className="modal-okay-gray square font-14">Download Options <DownOutlined /></Button> 
            </Dropdown>
          </div> : ""}
          </span> : ''
          
          }
          
      </h3>
      {props.status== "admin_qa" ?
      ''
      : (props.selected && props.flagged_images.includes(props.selected.split('.')[0]) || (props.selected_image && props.flagged_images.includes(props.selected_image.split('.')[0]))) ? 
      <div className={(props.status=="resolved") ? `bg-model green bg-model-position`: (props.status=="pending" || props.status=="rejected" || props.status=="sent_back_to_artist") ? `bg-model red bg-model-position` : ''}>
        <img className="flag-img" src="/img/flag_selected.png"/>
        <div className="bg-model-text white">
        {props.status=="resolved" ? "Issue Resolved" : (props.status=="pending") ? "Flagged By Customer" : props.status=="rejected" ? 'Flag Rejected' : ''}
        </div>
      </div>: props.status=="rejected" ?
      <div className="bg-model red bg-model-position">
      <img className="flag-img" src="/img/flag_selected.png"/>
      <div className="bg-model-text white">
       Admin Rejected Flag
      </div>
    </div>
      : ''}
      {
        props.type == "Product" ? 
        <div id="fullscreen-image">
          <Card className={( props.isFullScreen) ? "full-img img-width" : "panorama-default-styles auto-height"}>
            <Tooltip title={( props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
              <img src={ props.isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}  alt="Fullscreen Icon" className={ props.isFullScreen ?`fullscreen-360 exit dim` :`fullscreen-360 exit dim`} 
              onClick={ props.change360FullScreen} type={( props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
            </Tooltip>
      
            {/* {Array.isArray(props.unhappy_images) == true ? */}
            <img className={(props.isFullScreen) ? "full-img img-width" : "panorama-default-styles image"} alt={ props.selected_image} style={{objectFit:"scale-down"}} src={ props.selected_image.includes("camera") ? generateProductSiloLink(props.selected_image, 'jpg') : ( props.selected_image.includes("_Dimensions") ? ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.IMAGE_DIMENSIONAL_URI +  props.customer_name +'/'  + props.selected_image + '.jpg' : generateProductSiloLink(props.selected_image, 'jpg'))} /> : 
            {/* <img className={(props.isFullScreen) ? "full-img img-width" : "panorama-default-styles image"} alt={ props.selected_image} style={{objectFit:"scale-down"}} src={ props.selected_image} /> } */}
            {((Array.isArray(props.productReducer.product['silo_data']) == true &&  props.productReducer.product['silo_data'].length > 1)) ? 
            <div style={{display:"flex"}} className="unhappy-div"> 
              {props.productReducer.product.silo_data.map((ele,index) => (
                <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="unhappy-selected-card" style={{border: ele.camera_name ==  props.selected_image ? "1px solid #333333" : "1px solid #f0f0f0"}}>
                  {props.flagged_bool_check[ele.camera_name] && props.status != 'resolved' ?
                  <Tooltip placement='right' title={'Flagged By Customer'}>
                    <div className="note-bg-artist deep-red flag-note-pos">
                      <img className="flag-img" src="/img/flag_selected.png"/>
                    </div>
                  </Tooltip> :props.flagged_bool_check[ele.camera_name] && props.status == "resolved" ?
                   <Tooltip placement='right' title={'Flagged By Customer'}>
                   <div className="note-bg-artist green flag-note-pos">
                     <img className="flag-img" src="/img/flag_selected.png"/>
                   </div>
                 </Tooltip>: ''} 
                  <img onClick={() =>  props.changeSelectedAsset(ele.camera_name)}  className="unhappy-image-property"  
                  src={ele.camera_name.includes("camera") ? 
                  generateProductSiloLink(ele.camera_name, ele.image_data.img_format): 
                  (ele.camera_name.includes("_Dimensions") ? 
                  ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.IMAGE_DIMENSIONAL_URI + props.customer_name + '/' + ele.camera_name +"."+  ele.image_data.img_format : 
                  generateProductSiloLink(ele.camera_name, ele.image_data.img_format))} alt={ele.camera_name}/>
                  <span className="artist-upload-txt scene-name">{ele.camera_name}</span>
                </Card>))}
            </div>
          : ""}
          </Card>
          
        </div> : ""
      }
      {props.type == "scene_lifestyle" || props.type == "scene_360" ?
      <ComplaintImageViewer
      superArtistComplaintPage={props.superArtistComplaintPage}
      selected_format={props.selected_format}
      isFullScreen={props.isFullScreen}
      type={props.type}
      change360FullScreen={props.change360FullScreen}
      status={props.status}
      compare_state={props.compare_state}
      toggleCompareButton={props.toggleCompareButton}
      selected_image={props.selected_image}
      three_sixties={props.three_sixties}
      changeSelectedAsset={props.changeSelectedAsset}
      lifestyle_renders={props.lifestyle_renders}
      flagged_bool_check={props.flagged_bool_check}
      selected={props.selected}
      new_renders={props.new_renders}
      approve_render={props.approve_render}
      approveRender={props.approveRender}
      original_renders={props.original_renders}
      useOriginalRender={props.useOriginalRender}
      complaint_id={props.complaint_id}
      scene_id={props.scene_id}
      complaint_360={props.complaint_360}
      changeSelectedRender={props.changeSelectedRender}
      selectForApproval={props.selectForApproval}
      complaint_lifestyle={props.complaint_lifestyle}
      generated_renders={props.generated_renders}
      platform={props.platform}
      />: ''}

      {
        props.type == "scene_3dtour" ? 
        <span>
          <Tooltip title={( props.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
            <img src={ props.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"}  alt="Fullscreen Icon" className={ props.isFullScreen ?`fullscreen-360 exit-pos` :`fullscreen-360`} 
            onClick={ props.getFullScreenTour} type={( props.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
          </Tooltip>
        
        <Row gutter={16}>
           <Col span={24}>
            <Card id={ props.isFullScreen ? `vs-container-admin-fullscreen`:`vs-container-admin`} className={ props.isFullScreen ? "tour-fullscreen" : "flag-col-margin"}>          
              <div className="hotspot" id="hotspot-admin">
                  <img className="hotspotImage" src="https://all3d-demos.s3.amazonaws.com/Virtual-Showroom/Resources/hotspot.gif" alt="Hotspot" />
                  <span className="tooltiptext" id="toolTip"></span>
              </div>
            </Card>
            <div id="loading-container">
              <DottedLoader id="loadingImage" />
            </div>
          </Col>
        </Row>
        </span> : ""
      }
        <Row type="flex" style={{ justifyContent: "space-between", marginTop: "30px" }}>
          <Col>
            <h3 className="product-name-h3 black" style={{marginBottom:"48px"}}>{ props.type == "Product" ?  props.product_name :  props.scene_name}</h3>
          </Col>
          <Col style={{display:'flex'}}>
          {props.type == "Product" ?
            <Button className="modal-okay square font-14" onClick={props.openReplaceModal} disabled={props.status == "resolved" || props.status == "rejected"}>
              Update and Replace Renders
            </Button> : 
              displayAdminOverrideButton(props.status, props.type, props.superArtistComplaintPage) ? 
            <span>
            <Tooltip placement="bottom" title={props.status == "in_progress" ? 'A new override cannot be initiated while the assets from the previous override are in progress.' : ''}>
                <Dropdown overlay={override_menu} trigger={['click']} disabled={props.status == "in_progress" || props.status == "resolved" || props.status=="rejected" || (props.assigned_to != '' && !props.superArtistComplaintPage)} placement="bottomRight">
                  <Button className="modal-okay square font-14">
                    Admin Overrides&nbsp;<DownOutlined/>
                  </Button>
                </Dropdown>
            </Tooltip>
            <Tooltip placement="bottom" title={'Not sure which override to use?'}><span onClick={props.openHelp} className="flag-help">See Help</span></Tooltip>

            </span>
            : ''}
            
            {/* <Button className="modal-okay-gray square" style={{ marginLeft: 10 }} disabled={true}>
                <div className="modal-okay-text gray">Assign Artist</div>
            </Button> */}
            {props.status == "pending" && !props.assigned_to && !props.superArtistComplaintPage ?
            <Button className='outline-red-btn square font-14' style={{ marginLeft: 10 }} onClick={props.openRejectFlag}>
              Reject Flagging
            </Button>: ''}
            {console.log(props)}
            {props.assigned_to != '' && !props.superArtistComplaintPage && props.status != 'resolved' ? 
              <Button className='modal-okay square font-14' style={{ marginLeft: 10 }} onClick={()=>{setComplaintUnassignmentModal(true)}}>
                Unassign
              </Button>: ''}
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={24}>
                <h5 className="product-category-h3 grey" style={{marginBottom:"48px",display:"flex",justifyContent:"flex-start"}}>
                    <span className="product-category-h3 grey" style={{marginRight:30}} > Type: <span className="product-category-h3 black">{ props.type == "scene_lifestyle" ? `Lifestyle Images` : ( props.type == "scene_360" ? `Lifestyle 360` : ( props.type == "scene_3dtour" ? `3D Tour` :  props.type == "Product" ? `Product` : ""))}</span></span>
                    <span className="product-category-h3 grey" > ID: <span className="product-category-h3 black">{ props.scene_id}</span></span>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <h5 className="product-category-h3 grey" style={{marginBottom:"20px",display:"flex",alignItems:"center"}}>
                    <div className="product-category-h3 black" style={{marginRight: 7}}> Customer: </div>
                    <div className ="note-bg-artist gray">
                        <span className="note-text gray">{props.customer_name}</span>
                    </div>
                </h5>
              </Col>
            </Row>
            {props.assigned_to && props.assigned_to != '' && !props.superArtistComplaintPage ? 
            <Row>
              <Col span={24}>
                <h5 className="product-category-h3 grey" style={{marginBottom:"20px",display:"flex",alignItems:"center"}}>
                    <div className="product-category-h3 black" style={{marginRight: 7}}> Assignee: </div>
                    <div className ="note-bg-artist gray">
                        <span className="note-text gray">{props.assigned_to}</span>
                    </div>
                </h5>
              </Col>
            </Row>
            : ''}
            <Row>
              <Col span={24}>
                <h5 className="product-category-h3 grey" style={{marginBottom:"20px",display:"flex",alignItems:"center"}}>
                    <div className="product-category-h3 black" style={{marginRight: 7}}> Status: </div>
                    <div className="flagged-bg"><span className="flagged-txt">{props.status_text}</span></div>
                </h5>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={22}>
                <Row>
                  <Col span={24} style={{marginBottom: 30}}>
                    <div className="flagged-bg border">
                      <div className="flagged-txt bold" style={{marginBottom: 30}}>Customer Flagged because:</div>
                      <div className="flagged-txt gray">
                        Comment
                      </div>
                      <div className="flagged-txt black">
                      { props.unhappy_details}
                      </div>
                      {( props.references != [] &&  props.references != undefined) && (typeof ( props.references) == "object" && Object.keys( props.references).length != 0) ? <span>
                        <div className="flagged-txt gray" style={{marginTop: 30}}>
                        Uploads
                        </div>
                        {props.references.map((file,index) => (
                            <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.BASE_URI + file.uid + `/` + file.name}>
                            {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".svg")) ? 
                            <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.BASE_URI + file.uid + `/` + file.name}/> :
                            (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> : 
                            <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                            <p className="flagged-txt upload">{file.name}</p>
                            </a>
                        ))}
                      </span> : ""}
                    </div>
                  </Col>
                </Row>

                {/* Time Log for the complaint */}
                <Row style={{marginBottom: 40}}>
                  <Col span={24}>
                  {props.complaint_time_log.length > 0 && !props.superArtistComplaintPage? 
                    <React.Fragment>
                      <div>
                        <span className="product-txt-heading" style={{fontSize: 20}}>Total Fixing Time</span>
                        <span className="note-text blue" style={{paddingLeft: 10}}>{totalFixesTime()} Hours</span>
                      </div>

                      <table className="stats-table" style={{marginTop: 13, display: 'block'}}>
                          <tr>
                          {
                              (() => {
                                  var fixes_array = []
                                  for(var i=0; i<props.complaint_time_log.length; i++)
                                  {
                                    fixes_array.push(
                                          <td style={{display: 'inline-block'}}>
                                              <span className="note-text gray" style={{display: 'block', color: '#777777', fontSize: 14, paddingTop: 7}}>Fix {props.complaint_time_log.length - i}</span>
                                              <span style={{fontSize: 16, paddingTop: 7}}>{props.complaint_time_log[i].time_spent} Hours</span>
                                          </td>                                                     
                                        )
                                  }
                                  return fixes_array
                              })()
                          }
                          </tr>
                          
                      </table>
                    
                    </React.Fragment>
                  : ""}
                  </Col>
                </Row>
              </Col>
              
            </Row>
          </Col>
        
          <Col span={12}>
          {rejections_data && (props.status == 'admin_qa' || props.status == 'sent_back_to_artist') ?
                <Row className="flagged-bg border" style={{marginBottom: 16, marginTop: 15}}>
                    <Col sm={24} lg={24} xl={24} className="note-bg-artist red">
                    <div>
                        <div className='note-text red' style={{display: 'inline'}}>
                          Rejection Messages From Admin:
                        </div>
                        <Button type="link" onClick={() => {setDetailedRejectionModal(true)}} className='note-text blue rejection-history-view' style={{display: 'inline', position: 'absolute', right: 20}}>
                            View Details
                        </Button>
                    </div>
                        {rejections_data} 
                    </Col>
                </Row> : ""}
          </Col>
        </Row>
        {/* TO DO ---------------- */}
        {/* { props.type == "scene_360" ?
        <Row>
          <Col span={12}>
            <h5 className="product-category-h3 grey" style={{marginBottom:"48px",display:"flex",alignItems:"flex-start",flexDirection:"column"}}>
                <div className="product-category-h3 black"> Download Link: </div>
                <a className="flagged-bg" target="_blank" href={SCENE_BASE_URL +  props.scene_id + '/maxScene.zip'}><span className="flagged-txt" style={{color:"#276DD7",textTransform:"none"}}>{SCENE_BASE_URL +  props.scene_id + '/maxScene.zip'}</span></a>
            </h5>
          </Col>
        </Row> : ""} */}
      </Col>
    </Row>}
    {props.status=="admin_qa" && !props.superArtistComplaintPage ?
    <div className="product-status-review-admin white">
      <Row type="flex" style={{justifyContent:"flex-end"}}>
        <Col span={24} style={{display:"flex",justifyContent:"flex-end"}}>
        {props.status == "admin_qa" && !props.superArtistComplaintPage && props.assigned_to != '' ? 
          <span>
            <Button className="outline-red-btn square font-14" htmlType="submit" onClick={() => {setRejectionConfirmationModal(true)}} style={{marginRight: 8}}>
              Send Back to Super Artist
            </Button>
            <ComplaintRejectionModal 
              complaint_id={props.complaint_id}
              setRejectionConfirmationModal={setRejectionConfirmationModal}
              rejectionConfirmationModal={rejectionConfirmationModal}
              assigned_to={props.assigned_to}
              platform={props.platform}
            />
          </span>
        : props.status == "admin_qa" && !props.superArtistComplaintPage && props.assigned_to == '' ?
          <span>
            <Button className="outline-red-btn square font-14" htmlType="submit" onClick={() => {setRejectionConfirmationModal(true)}} style={{marginRight: 8}}>
              Assign To Super Artist For Fix
            </Button>
            <ComplaintRejectionModal 
              complaint_id={props.complaint_id}
              setRejectionConfirmationModal={setRejectionConfirmationModal}
              rejectionConfirmationModal={rejectionConfirmationModal}
              assigned_to={props.assigned_to}
              props={props.platform}
            />
          </span>
        : ""}
        
        <Tooltip placement="top" title={props.status=="admin_qa" && !props.enable_resolve_btn ? `You will be able to resolve this complaint once all updated ${props.type == "scene_lifestyle" ? `images` : `360s`} have been individually approved.`: ``}>
        <span><Button className="modal-okay square font-14" htmlType="submit"  disabled={(props.status != "admin_qa" || props.type == "scene_3dtour" || (props.enable_resolve_btn== false && props.status =="admin_qa" || props.mark_resolve_button))} onClick={props.markAsResolve}>
          Mark As Resolved&nbsp;{props.mark_resolve_button ? <LoadingOutlined/> : ''}
        </Button></span>
        </Tooltip>
        </Col>
      </Row>
    </div>: ''}
    <Modal
    destroyOnClose={true}
    maskClosable={false}
    visible={props.replace_modal}
    className="modal-flag-override">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large">Upload New Images to Replace Current Ones</h2>
        <h2 className="modal-text gray">Only the images uploaded here will be updated and replace the current ones. Please make sure you update all the images that might need to be updated to resolve this complaint.</h2>
        <Row>
          <Col span={24} className="justify-in-end">
          <div className='show-m-text show-less' onClick={props.showFlagged}>{props.show_more ? 'Show Flagged Images Only' : ''}</div>
          </Col>
        </Row>
        <Form onFinish={props.handleSubmit}>
        {
          props.type == "Product" ? 
          (Array.isArray( props.productReducer.product['silo_data']) == true) ?
          <Row type="flex" style={{marginTop: props.show_more ? 12 : 32}} >
          {props.productReducer.product['silo_data'].map((ele,index) => (
              props.show_more ?
              <Col span={8} style={{display:"grid",paddingLeft:"16px",paddingBottom: 16}}>
                  <Form.Item className={`reference-file-form scene-3ds solid-border ${props.uploading[ele.camera_name] ? `bg-grey` : `no-bg`}`}
                  valuePropName={`${ele.camera_name}.${ele.image_data.img_format}`}
                  getValueFromEvent={props.normFile} 
                  name ={`${ele.camera_name}.${ele.image_data.img_format}`} colon={false}>
                    <Upload {...Constants.getUploadProps(props.platform)} 
                    progress= {{
                      strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                      },
                      strokeWidth: 3,
                      format: percent => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                      beforeUpload={(file) => {
                        props.changeUploadStatus(ele.camera_name,true);
                        return props.checkImageFormat(file,ele.camera_name,ele.image_data.img_format);

                      }}
                      onChange={(info) => {
                        props.handleImageChange(info, ele.camera_name);
                        props.getFileStatus(ele.camera_name,info); 
                      }}
                      onRemove={() => {
                        props.changeUploadStatus(ele.camera_name,false);
                      }}
                    multiple={false} 
                    className={`${props.uploading[ele.camera_name] ? `image-uploading-box` :`image-upload-box`}  image-replace-field flag-setting`}> 
                    {props.uploading[ele.camera_name] == false ? 
                    <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                        {props.flagged_bool_check[ele.camera_name] ?
                          <span style={{height: 0, width: 0}}>
                            <Tooltip placement='right' title={'Flagged By Customer'}>
                              <div className="note-bg-artist deep-red flag-note-pos">
                                <img className="flag-img" src="/img/flag_selected.png"/>
                              </div>
                            </Tooltip>
                          </span>
                            : ''} 
                          <div className="note-bg-artist dark-blue note-text-pos">
                            <div className="note-text white">Browse or Drop Image to Replace</div>
                          </div>
                          <Card className='upload-card-properties' bordered={false}>
                            <img className='upload-image-property' src={generateProductSiloLink(ele.camera_name, ele.image_data.img_format)}/>
                            <span className="artist-upload-txt scene-name">{ele.camera_name}</span>
                          </Card>
                      </div> : 
                      <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                        <div className="ant-upload-text">
                          <div className="flag-scene-card-txt blue">{props.uploading_progress[ele.camera_name] == "uploading" ? "Uploading..." : (props.uploading_progress[ele.camera_name]  == "error" ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.uploading_progress[ele.camera_name]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>{props.uploaded_text}</span>{props.uploaded_text == "Copying Files" ? <LoadingOutlined/> : <img src="/img/success_upload.jpg" className="img-upload-text"></img>}</span> : '')}</div>
                        </div> 
                      </div>}
                    </Upload>
                  </Form.Item>
                  {props.upload_failure_message[ele.camera_name + '.' + ele.image_data.img_format] ? 
                    <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                      <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[ele.camera_name + '.' + ele.image_data.img_format]}</div>
                    </div>: ''}
                </Col> :
                props.flagged_bool_check[ele.camera_name] ?
                <Col span={8} style={{display:"grid",paddingLeft:"16px",paddingBottom: 16}}>
                  <Form.Item className={`reference-file-form scene-3ds solid-border ${props.uploading[ele.camera_name] ? `bg-grey` : `no-bg`}`}
                  valuePropName={`${ele.camera_name}.${ele.image_data.img_format}`}
                  getValueFromEvent={props.normFile} name ={`${ele.camera_name}.${ele.image_data.img_format}`} colon={false}>
                    <Upload {...Constants.getUploadProps(props.platform)} 
                    progress= {{
                      strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                      },
                      strokeWidth: 3,
                      format: percent => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                      beforeUpload={(file) => {
                        props.changeUploadStatus(ele.camera_name,true);
                        return props.checkImageFormat(file,ele.camera_name,ele.image_data.img_format);
                      }}
                      onChange={(info) => {
                        props.handleImageChange(info, ele.camera_name);
                        props.getFileStatus(ele.camera_name,info); 
                      }}
                      onRemove={() => {
                        props.changeUploadStatus(ele.camera_name,false);
                      }}
                    multiple={false} 
                    className={`${props.uploading[ele.camera_name] ? `image-uploading-box` :`image-upload-box`}  image-replace-field flag-setting`}> 
                    {props.uploading[ele.camera_name] == false ? 
                    <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                        <Tooltip placement='right' title={'Flagged By Customer'}>
                          <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20}}>
                            <img className="flag-img" src="/img/flag_selected.png"/>
                          </div>
                        </Tooltip>
                        <div className="note-bg-artist dark-blue note-text-pos">
                          <div className="note-text white">Browse or Drop Image to Replace</div>
                        </div>
                        <Card className='upload-card-properties' bordered={false}>
                          <img className='upload-image-property' src={generateProductSiloLink(ele.camera_name, ele.image_data.img_format)}/>
                          <span className="artist-upload-txt scene-name">{ele.camera_name}</span>
                        </Card>
                      </div> : 
                      <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                      <div className="ant-upload-text">
                        <div className="flag-scene-card-txt blue">{props.uploading_progress[ele.camera_name] == "uploading" ? "Uploading..." : (props.uploading_progress[ele.camera_name]  == "error" ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.uploading_progress[ele.camera_name]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>{props.uploaded_text}</span>{props.uploaded_text == "Copying Files" ? <LoadingOutlined/> : <img src="/img/success_upload.jpg" className="img-upload-text"></img>}</span> : '')}</div>
                      </div> 
                    </div>}
                    </Upload> 
                  </Form.Item>
                  {props.upload_failure_message[ele.camera_name + '.' + ele.image_data.img_format] ? 
                    <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                      <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[ele.camera_name + '.' + ele.image_data.img_format]}</div>
                    </div>: ''}
                </Col> : 
              ''
          ))}
            <Col span={8} style={{display: props.show_more ? 'none' : 'block',paddingLeft: 16}}>
              <Card className='upload-card-properties show' bordered={false} onClick={props.showMore}>
                <div className='show-more-flex'>
                  <EyeOutlined className='eye-out-color'/>
                  <span className='show-m-text'>
                    Show Other Images
                  </span>
                </div>
              </Card>
            </Col>
          </Row> : ""
        : (props.type == "scene_lifestyle" ?
        <Row type="flex" style={{marginTop: props.show_more ? 12 : 32}} >
          {props.lifestyle_renders.map((x,index) => (
              props.show_more ?
              <Col span={8} style={{display:"grid",paddingLeft:"16px",paddingBottom: 16}}>
                  <Form.Item className={`reference-file-form scene-3ds solid-border ${props.uploading[x.filename.split('.')[0]] ? `bg-grey` : `no-bg`}`}
                  valuePropName={`${x.filename}`}
                  getValueFromEvent={props.normFile} 
                  name ={`${x.filename}`} colon={false}>
                    <Upload {...Constants.getUploadProps(props.platform)} 
                    progress= {{
                      strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                      },
                      strokeWidth: 3,
                      format: percent => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                      beforeUpload={(file) => {
                        props.changeUploadStatus(x.filename.split('.')[0],true);
                        return props.checkImageFormat(file,x.filename.split('.')[0],x.filename.split('.')[1]);

                      }}
                      onChange={(info) => {
                        props.handleImageChange(info, x.filename);
                        props.getFileStatus(x.filename.split('.')[0],info); 
                      }}
                      onRemove={() => {
                        props.changeUploadStatus(x.filename.split('.')[0],false);
                      }}
                    multiple={false} 
                    className={`${props.uploading[x.filename.split('.')[0]] ? `image-uploading-box` :`image-upload-box`}  image-replace-field flag-setting`}> 
                    {props.uploading[x.filename.split('.')[0]] == false ? 
                    <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                        {props.flagged_bool_check[x.filename.split('.')[0]] ?
                          <span style={{height: 0, width: 0}}>
                            <Tooltip placement='right' title={'Flagged By Customer'}>
                              <div className="note-bg-artist deep-red flag-note-pos">
                                <img className="flag-img" src="/img/flag_selected.png"/>
                              </div>
                            </Tooltip>
                          </span>
                            : ''} 
                          <div className="note-bg-artist dark-blue note-text-pos">
                            <div className="note-text white">Browse or Drop Image to Replace</div>
                          </div>
                          <Card className='upload-card-properties' bordered={false}>
                            <img className='upload-image-property' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + x.filename.replace('tiff', 'jpg')}/>
                            <span className="artist-upload-txt scene-name">{x.filename}</span>
                          </Card>
                      </div> : 
                    <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                      <div className="ant-upload-text">
                        <div className="flag-scene-card-txt blue">{props.uploading_progress[x.filename.split('.')[0]] == "uploading" ? "Uploading..." : (props.uploading_progress[x.filename.split('.')[0]]  == "error" ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.uploading_progress[x.filename.split('.')[0]]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>{props.uploaded_text}</span>{props.uploaded_text == "Copying Files" ? <LoadingOutlined/> : <img src="/img/success_upload.jpg" className="img-upload-text"></img>}</span> : '')}</div>
                      </div> 
                    </div>}
                    </Upload>
                  </Form.Item>
                  {props.upload_failure_message[x.filename] ? 
                    <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                      <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[x.filename]}</div>
                    </div>: ''}
                </Col> :
                props.flagged_bool_check[x.filename.split('.')[0]] ?
                <Col span={8} style={{display:"grid",paddingLeft:"16px",paddingBottom: 16}}>
                  <Form.Item className={`reference-file-form scene-3ds solid-border ${props.uploading[x.filename.split('.')[0]] ? `bg-grey` : `no-bg`}`}
                  valuePropName={`${x.filename}`}
                  getValueFromEvent={props.normFile} name ={`${x.filename}`} colon={false}>
                    <Upload {...Constants.getUploadProps(props.platform)} 
                    progress= {{
                      strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                      },
                      strokeWidth: 3,
                      format: percent => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                      beforeUpload={(file) => {
                        props.changeUploadStatus(x.filename.split('.')[0],true);
                        return props.checkImageFormat(file,x.filename.split('.')[0],x.filename.split('.')[1]);
                      }}
                      onChange={(info) => {
                        props.handleImageChange(info, x.filename);
                        props.getFileStatus(x.filename.split('.')[0],info); 
                    }}                      
                    onRemove={() => {
                        props.changeUploadStatus(x.filename.split('.')[0],false);
                      }}
                    multiple={false} 
                    className={`${props.uploading[x.filename.split('.')[0]] ? `image-uploading-box` :`image-upload-box`}  image-replace-field flag-setting`}> 
                  {props.uploading[x.filename.split('.')[0]] == false ? 
                    <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                        <Tooltip placement='right' title={'Flagged By Customer'}>
                          <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20}} >
                            <img className="flag-img" src="/img/flag_selected.png"/>
                          </div>
                        </Tooltip>
                        <div className="note-bg-artist dark-blue note-text-pos">
                          <div className="note-text white">Browse or Drop Image to Replace</div>
                        </div>
                        <Card className='upload-card-properties' bordered={false}>
                          <img className='upload-image-property' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + x.filename.replace('tiff', 'jpg')}/>
                          <span className="artist-upload-txt scene-name">{x.filename}</span>
                        </Card>
                      </div> :
                      <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                        <div className="ant-upload-text">
                          <div className="flag-scene-card-txt blue">{props.uploading_progress[x.filename.split('.')[0]] == "uploading" ? "Uploading..." : (props.uploading_progress[x.filename.split('.')[0]]  == "error" ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.uploading_progress[x.filename.split('.')[0]]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>{props.uploaded_text}</span>{props.uploaded_text == "Copying Files" ? <LoadingOutlined/> : <img src="/img/success_upload.jpg" className="img-upload-text"></img>}</span> : '')}</div>
                        </div> 
                      </div> }
                    </Upload>
                  </Form.Item>
                  {props.upload_failure_message[x.filename] ? 
                    <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                      <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[x.filename]}</div>
                    </div>: ''}
                </Col> : 
              ''
          ))}
            <Col span={8} style={{display: props.show_more ? 'none' : 'block',paddingLeft: 16}}>
              <Card className='upload-card-properties show' bordered={false} onClick={props.showMore}>
                <div className='show-more-flex'>
                  <EyeOutlined className='eye-out-color'/>
                  <span className='show-m-text'>
                    Show Other Images
                  </span>
                </div>
              </Card>
            </Col>
            <Col span={24}>
            {props.uploadStatus != '' ? 

              <div className="val-note-bg red"  style={{textAlign:"left"}}>
                <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.uploadStatus}</div>
              </div>: ''}
              
            </Col>
        </Row>: '')}
        {props.superArtistComplaintPage ?
        <div style={{marginTop: 10}}>
          <h4>Time Spent (In Hours) <span style={{color: 'red'}}> *</span></h4>
          <Form.Item
            name="time_spent"
            rules={[{ required: true, message: 'Please Enter the time spent (in hours) to fix the complaint' }]}
            >
            <Input
              type="number"
              placeholder="Time Spent to fix the complaint"
            />
          </Form.Item>
        </div> : "" }
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            <Button className="modal-okay-gray square font-14" style={{marginRight: 16,marginBottom: 10}} disabled={props.button_loader} onClick={props.cancelReplaceModal}>
                Cancel
            </Button>
            <Form.Item>
              <Button className="modal-okay square font-14" htmlType="submit" disabled={props.enable_upload_button || props.button_loader || !props.file_copied}>
                Replace Images&nbsp;{props.button_loader ? <LoadingOutlined/> : ''}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
    <Modal
    destroyOnClose={true}
    maskClosable={false}
    visible={props.replace_scene_modal}
    className="modal-flag-override">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large">Upload New Scene 360s to Replace Current Ones</h2>
        <h2 className="modal-text gray">Only the scene 360s you upload here will be updated and replace the current ones. Please make sure you update all the scene 360s that might need to be updated to resolve this complaint.</h2>
        <Row>
          <Col span={24} className="justify-in-end">
          <div className='show-m-text show-less' onClick={props.showFlagged}>{props.show_more ? 'Show Flagged 360s Only' : ''}</div>
          </Col>
        </Row>        
        <Form onFinish={props.handleSubmit}>
        {
          props.type == "scene_360" ? 
          <Row type="flex" style={{marginTop: props.show_more ? 12 : 32}} >
              {props.three_sixties.map((x,index) => (
                props.show_more ?
              
                <Col span={12} style={{display:"grid",paddingLeft:"16px",paddingBottom: 16}}>
                  <Form.Item className={`reference-file-form scene-replace ${props.uploading[x.filename.split('.')[0]] ? `bg-grey` : `no-bg`} `}
                  valuePropName={`${x.filename}`}
                  getValueFromEvent={props.normFile} 
                  name ={`${x.filename}`} colon={false}>
                    <Upload {...Constants.getUploadProps(props.platform)} 
                    beforeUpload={(e) => {
                      props.changeUploadStatus(x.filename.split('.')[0],true);
                    }}
                    onChange={(info) => {
                        props.handleImageChange(info, x.filename);
                        props.getFileStatus(x.filename.split('.')[0],info); 
                    }}
                    onRemove={() => {
                      props.changeUploadStatus(x.filename.split('.')[0],false);
                    }}
                    multiple={false} 
                    progress= {{
                      strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                      },
                      strokeWidth: 3,
                      format: percent => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                    className={`${props.uploading[x.filename.split('.')[0]] ? `image-panorama-uploading-box` :`image-panorama-upload-box`} image-replace-field flag-setting`}>
                      {props.uploading[x.filename.split('.')[0]] == false ? 
                      <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                        {props.flagged_bool_check[x.filename.split('.')[0]] ? 
                          <Tooltip placement='right' title={'Flagged By Customer'}>
                            <div className="note-bg-artist deep-red flag-note-pos" style={{top: 10}}>
                              <img className="flag-img" src="/img/flag_selected.png"/>
                            </div>
                          </Tooltip> : ''}
                          <div className="note-bg-artist dark-blue note-text-pos">
                            <div className="note-text white">Browse or Drop Image to Replace</div>
                          </div>
                          <Card className='upload-card-properties' bordered={false}>
                            <img className='upload-image-property cover-area' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + x.filename.replace('tiff', 'jpg')}/>
                            <span className="artist-upload-txt scene-name">{x.filename.split('.')[0]}</span>
                          </Card>
                          {/* <PanoramaThumbnailViewer threeSixtyUrl={SCENE_URL + props.scene_id +'/'+ x.filename } customClass="flag-override-panorama max-3ds-single" customStyle="custom-flag-style"/>
                          <span className="artist-upload-txt scene-name">{x.filename.split('.')[0]}</span>  */}
                          </div> :
                          <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                            <div className="ant-upload-text">
                              <div className="flag-scene-card-txt blue">{props.uploading_progress[x.filename.split('.')[0]] == "uploading" ? "Uploading..." : (props.uploading_progress[x.filename.split('.')[0]]  == "error" ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.uploading_progress[x.filename.split('.')[0]]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>{props.uploaded_text}</span>{props.uploaded_text == "Copying Files" ? <LoadingOutlined/> : <img src="/img/success_upload.jpg" className="img-upload-text"></img>}</span> : '')}</div>
                            </div> 
                          </div>
                         }
                    </Upload>
                  </Form.Item>
                  {props.upload_failure_message[x.filename] ? 
                    <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                      <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[x.filename]}</div>
                    </div>: ''}
                </Col>  :
              props.flagged_bool_check[x.filename.split('.')[0]] ?             
                <Col span={12} style={{display:"grid",paddingLeft:"16px",paddingBottom: 16}}>
                  <Form.Item className={`reference-file-form scene-replace ${props.uploading[x.filename.split('.')[0]] ? `bg-grey` : `no-bg`} `}
                  valuePropName={`${x.filename}`}
                  getValueFromEvent={props.normFile} name ={`${x.filename}`} colon={false}>
                    <Upload {...Constants.getUploadProps(props.platform)} 
                    beforeUpload={(e) => {
                      props.changeUploadStatus(x.filename.split('.')[0],true);
                    }}
                    onChange={(info) => {
                      props.handleImageChange(info,x.filename);
                      props.getFileStatus(x.filename.split('.')[0],info); 
                  }}
                    onRemove={() => {
                      props.changeUploadStatus(x.filename.split('.')[0],false);
                    }}
                    multiple={false} 
                    progress= {{
                      strokeColor: {
                      '0%': '#108ee9',
                      '100%': '#87d068',
                      },
                      strokeWidth: 3,
                      format: percent => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                      className={`${props.uploading[x.filename.split('.')[0]] ? `image-panorama-uploading-box` :`image-panorama-upload-box`} image-replace-field flag-setting`}>
                    {props.uploading[x.filename.split('.')[0]] == false ? 
                      <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                      <Tooltip placement='right' title={'Flagged By Customer'}>
                          <div className="note-bg-artist deep-red flag-note-pos" style={{top: 10}}>
                            <img className="flag-img" src="/img/flag_selected.png"/>
                          </div>
                        </Tooltip>
                        <div className="note-bg-artist dark-blue note-text-pos">
                          <div className="note-text white">Browse or Drop Image to Replace</div>
                        </div>
                        <Card className='upload-card-properties' bordered={false}>
                            <img className='upload-image-property cover-area' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + x.filename.replace('tiff', 'jpg')}/>
                            <span className="artist-upload-txt scene-name">{x.filename.split('.')[0]}</span>
                        </Card>
                        {/* <PanoramaThumbnailViewer threeSixtyUrl={SCENE_URL + props.scene_id +'/'+ x.filename  } customClass="flag-override-panorama max-3ds-single" customStyle="custom-flag-style"/>
                        <span className="artist-upload-txt scene-name">{x.filename.split('.')[0]}</span> */}
                      </div>
                      :
                      <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                        <div className="ant-upload-text">
                        <div className="flag-scene-card-txt blue">{props.uploading_progress[x.filename.split('.')[0]] == "uploading" ? "Uploading..." : (props.uploading_progress[x.filename.split('.')[0]]  == "error" ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.uploading_progress[x.filename.split('.')[0]]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>{props.uploaded_text}</span>{props.uploaded_text == "Copying Files" ? <LoadingOutlined/> : <img src="/img/success_upload.jpg" className="img-upload-text"></img>}</span> : '')}</div>
                        </div> 
                      </div>}
                    </Upload>
                  </Form.Item>
                  {props.upload_failure_message[x.filename] ? 
                  <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                    <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[x.filename]}</div>
                  </div>: ''}
                </Col> : ''
              ))}
         
            <Col span={24} style={{display: props.show_more ? 'none' : 'block'}}>
              <Card className='upload-card-properties show scene' bordered={false} onClick={props.showMore}>
                <div className='show-more-flex row'>
                  <EyeOutlined className='eye-out-color'/>&nbsp;
                  <span className='show-m-text'>
                    Show Other Scene 360s
                  </span>
                </div>
              </Card>
            </Col>
            <Col span={24}>
            {props.uploadStatus != '' ? 

            <div className="val-note-bg red"  style={{textAlign:"left"}}>
              <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.uploadStatus}</div>
            </div>: ''}
              
            </Col>
          </Row> : ""}
          {props.superArtistComplaintPage ?
          <div style={{marginTop: 10}}>
            <h4>Time Spent (In Hours) <span style={{color: 'red'}}> *</span></h4>
            <Form.Item
              name="time_spent"
              rules={[{ required: true, message: 'Please Enter the time spent (in hours) to fix the complaint' }]}
              >
              <Input
                type="number"
                placeholder="Time Spent to fix the complaint"
              />
            </Form.Item>
          </div> : "" }
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            <Button className="modal-okay-gray square font-14" style={{marginRight: 16,marginBottom: 10}} onClick={props.cancelReplaceModal}>
                Cancel
            </Button>
            <Form.Item>
              <Button className="modal-okay square font-14" htmlType="submit" disabled={props.enable_upload_button || !props.file_copied}>
                Replace Scene 360s&nbsp;{props.button_loader ? <LoadingOutlined/> : ''}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
    <Modal
    maskClosable={false}
    visible={props.replace_3dsmax}
    destroyOnClose={props.destroy_modal}
    className="modal-flag-override">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large">Upload Bundle for {props.type == 'scene_lifestyle' ? 'Lifestyle Images' : props.type == "scene_360" ? 'Scene 360' : ''}</h2>
        <h2 className="modal-text gray" style={{marginBottom: 10}}>Upload the Bundle (Max and JSON) for the flagged {props.type == 'scene_lifestyle' ? 'image(s)' : props.type == "scene_360" ? '360(s)' : ''} below. Make sure you upload the correct bundle against its respective {props.type == 'scene_lifestyle' ? 'image(s)' : props.type == "scene_360" ? '360(s)' : ''}.</h2>
        <div className="note-bg-artist gray width">
          <span className="note-text blue small">Upload Max Archive in .zip format. Keep all VRAY Texture images and the .max file at root, and compress all of them directly into .zip format.</span>   
        </div>
        <Row>
          <Col span={24} className="justify-in-end">
          <div className='show-m-text show-less' onClick={props.toggleShowMore}>{props.type == "scene_360" ? (props.show_more ? 'Show Flagged 360s Only' : 'Show All 360s') : props.type == "scene_lifestyle" ? (props.show_more ? 'Show Flagged Images Only' : 'Show All Images') : ''}</div>
          </Col>
        </Row>
        <Form onFinish={props.handleSubmit3dsMax}>
          {props.config_data.map((config,index) => ( 
            config.cameras.length >= 1 ?
          <Tabs defaultActiveKey="1" className="tab-flag-override" tabBarStyle={{color:"#777777",fontSize: 14,lineHeight:"120%"}}>
            <TabPane tab={<span>Upload Max Archive<span style={{color: "#E54547"}}>&nbsp;*</span></span>} key="1" className="tabpane-flag-override">
              <Row className="scene-flag-columns">
                <Col span={12}>
                {(config.cameras.length > 1)? 
                <Row className="image-group">
                    {config.cameras.map((camera_name)=> (
                    <Col span={12} style={{paddingRight: 16,paddingBottom: 16}}>
                      <Card className="flag-card-pd">
                      {props.flagged_bool_check[camera_name] ?
                        <Tooltip placement='right' title={'Flagged By Customer'}>
                          <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                            <img className="flag-img" src="/img/flag_selected.png"/>
                          </div>
                        </Tooltip>: ''}
                        {props.type == "scene_360" ? 
                        <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+ camera_name + ".jpg"  } customClass="flag-override-panorama max-3ds" customStyle="custom-flag-style"/>
                        : props.type == "scene_lifestyle" ? 
                        <img className='upload-image-property max-3ds' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + camera_name + ".jpg"}/> : ''}
                        <span className="artist-upload-txt scene-name">{camera_name}</span>
                      </Card>
                    </Col>
                    ))}
                  </Row> : config.cameras.length == 1 ?
                  <Card className="flag-card-pd">
                  {props.flagged_bool_check[config.cameras[0]] ?
                    <Tooltip placement='right' title={'Flagged By Customer'}>
                      <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                        <img className="flag-img" src="/img/flag_selected.png"/>
                      </div>
                    </Tooltip> : ''}
                    {props.type == "scene_360" ?
                    <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+   config.cameras[0] + ".jpg"} customClass="flag-override-panorama max-3ds-single " customStyle="custom-flag-style"/>
                    : props.type == "scene_lifestyle" ?  
                    <img className='upload-image-property max-3ds-single' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + config.cameras[0] + ".jpg"}/> : ''}
                    <span className="artist-upload-txt scene-name">{config.cameras[0]}</span>
                  </Card> : ''}
                </Col>
                <Col span={12} style={{display:"grid",paddingLeft:"16px"}}>
                    <Form.Item className='reference-file-form scene-3ds'
                    valuePropName={`reference_filelist_${config.scene_config_id}`}
                    getValueFromEvent={props.normFile} name ={`reference_files_${config.scene_config_id}`} colon={false}>
                      <Upload {...Constants.getUploadProps(props.platform)} 
                      progress= {{
                        strokeColor: {
                        '0%': '#108ee9',
                        '100%': '#87d068',
                        },
                        strokeWidth: 3,
                        format: percent => `${parseFloat(percent.toFixed(2))}%`,
                        }}
                      accept=".zip"
                      fileList={props.defaultFileList[config.scene_config_id]}
                      beforeUpload={(file) => {
                        props.changeConfigUploadStatus(config.scene_config_id,true,'max');
                        props.setBundleUploadStatus(config.scene_config_id,'','max');
                        props.setUploadProgress('');
                        if (file.name.includes('.zip')) {
                          if (file.name.includes('.zip')) {
                            let return_check = false;
                            let parent = this;
                            let new_zip = new JSZip();
                            new_zip.loadAsync(file)
                            .then(function(zip) {
                              for(let key in zip['files']){
                                if (key.includes('.max')) {
                                  if (key == (props.room_id + '.max')) {
                                    return_check = true;
                                  } 
                                }
                              }
                              if (!return_check) {
                                props.setBundleUploadStatus(config.scene_config_id,'Incorrect folder structure! Max file should be named as SpaceID.max and should be present at root','max');
                                props.setUploadProgress('error');
                                
                                return false;
                              } else {
                                props.setBundleUploadStatus(config.scene_config_id,'','max');
                                props.setUploadProgress('');
                                return true;
                              }
                            });
                          } 
                        } else {
                          props.setUploadProgress('error');
                          props.setUploadStatus(config.scene_config_id,'Error! File type must either be zip or json.','max');
                          return false;
                        }
                      }}
                      onChange={(info) => {
                        props.handleMaxChange(info,config.scene_config_id);
                        props.getConfigFileStatus(config.scene_config_id,info); 
                      }}
                      onRemove={() => {
                        props.setBundleUploadStatus(config.scene_config_id,'','max');
                        props.changeConfigUploadStatus(config.scene_config_id,false,'','max');
                        props.removeFile(config.scene_config_id,'max');
                      }}
                      multiple={false} 
                      className="flag-scene-upload-box-color image-replace-field flag-setting">
                        <span>
                      {props.config_uploading[config.scene_config_id] == false ? 
                        <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                          <img src="/img/exclude.png"></img>
                          <div className="ant-upload-text" style={{marginTop:20}}>
                            <div className="flag-scene-card-txt grey">Drop your Scene Max Archive folder here , or <span style={{color:"#276DD7"}}>Browse it</span>.</div>
                            <div className="flag-scene-card-txt grey">Supported formats: .zip file</div>
                          </div>
                        </div> : 
                        props.upload_validation_status[config.scene_config_id] == '' ? 
                        <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                          <div className="ant-upload-text">
                            <div className="flag-scene-card-txt blue">{props.config_progress[config.scene_config_id] == "uploading" ? "Uploading.." : (props.config_progress[config.scene_config_id]  == "error" || props.upload_error != '' ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.config_progress[config.scene_config_id]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>Uploaded</span><img src="/img/success_upload.jpg" className="img-upload-text"></img></span> : '')}</div>
                          </div> 
                        </div>: props.upload_validation_status[config.scene_config_id] == 'in_progress' ? <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                          <LoadingOutlined className="loader-validation"/>
                          <div className="ant-upload-text">
                            <div className="flag-scene-card-txt blue font-12 margin">Please wait. We are running some validation checks on your upload. </div>
                          </div> 
                        </div> : props.upload_validation_status[config.scene_config_id] == 'successful' ? <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                          <img src="/img/success_upload.jpg" className="img-upload-text large"/>
                          <div className="ant-upload-text">
                            <div className="flag-scene-card-txt blue font-12 margin" style={{color: "#4DD2AC"}}>Your upload has been successfully validated. Please Submit your file to proceed.</div>
                          </div> 
                        </div>: ''}
                        </span>
                      </Upload>
                    </Form.Item>              
                    {((props.config_progress[config.scene_config_id] == "uploading" || props.config_progress[config.scene_config_id] == "error") && props.uploadStatus != '') ? 
                    <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                    <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.uploadStatus}</div>
                    </div>: ''}
                      {(props.bundle_upload_status[config.scene_config_id]) ? 
                    <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                      <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.bundle_upload_status[config.scene_config_id]}</div>
                    </div>: ''}
                    {props.upload_failure_message[config.scene_config_id] ? 
                  <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                    <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[config.scene_config_id]}</div>
                  </div>: ''}
                  
                </Col>
              </Row> 
          </TabPane>
           <TabPane tab={<span>Upload JSON File<span style={{color: "#E54547"}}>&nbsp;*</span></span>} key="2"  className="tabpane-flag-override"> 
           <Row className="scene-flag-columns">
                <Col span={12}>
                {(config.cameras.length > 1)? 
                <Row className="image-group">
                    {config.cameras.map((camera_name)=> (
                    <Col span={12} style={{paddingRight: 16,paddingBottom: 16}}>
                      <Card className="flag-card-pd">
                      {props.flagged_bool_check[camera_name] ?
                        <Tooltip placement='right' title={'Flagged By Customer'}>
                          <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                            <img className="flag-img" src="/img/flag_selected.png"/>
                          </div>
                        </Tooltip>: ''}
                        {props.type == "scene_360" ? 
                        <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+ camera_name + ".jpg"  } customClass="flag-override-panorama max-3ds" customStyle="custom-flag-style"/>
                        : props.type == "scene_lifestyle" ? 
                        <img className='upload-image-property max-3ds' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + camera_name + ".jpg"}/> : ''}
                        <span className="artist-upload-txt scene-name">{camera_name}</span>
                      </Card>
                    </Col>
                    ))}
                  </Row> : config.cameras.length == 1 ?
                  <Card className="flag-card-pd">
                  {props.flagged_bool_check[config.cameras[0]] ?
                    <Tooltip placement='right' title={'Flagged By Customer'}>
                      <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                        <img className="flag-img" src="/img/flag_selected.png"/>
                      </div>
                    </Tooltip> : ''}
                    {props.type == "scene_360" ?
                    <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+   config.cameras[0] + ".jpg"} customClass="flag-override-panorama max-3ds-single " customStyle="custom-flag-style"/>
                    : props.type == "scene_lifestyle" ?  
                    <img className='upload-image-property max-3ds-single' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + config.cameras[0] + ".jpg"}/> : ''}
                    <span className="artist-upload-txt scene-name">{config.cameras[0]}</span>
                  </Card>: ''}
                </Col>
                <Col span={12} style={{display:"grid",paddingLeft:"16px"}}>
                    <Form.Item className='reference-file-form scene-3ds'
                    valuePropName={`reference_json_${config.scene_config_id}`}
                    getValueFromEvent={props.normFile} name ={`reference_files_${config.scene_config_id}`} colon={false}>
                      <Upload {...Constants.getUploadProps(props.platform)} 
                      progress= {{
                        strokeColor: {
                        '0%': '#108ee9',
                        '100%': '#87d068',
                        },
                        strokeWidth: 3,
                        format: percent => `${parseFloat(percent.toFixed(2))}%`,
                        }}
                      fileList={props.jsonFileList[config.scene_config_id]}
                      accept=".json"
                      beforeUpload={(file) => {
                        props.setBundleUploadStatus(config.scene_config_id,'','max_json');
                        props.changeConfigUploadStatus(config.scene_config_id,true,'max_json');
                        props.setUploadProgress('');
                        if (file.name.includes('.json')) {
                          props.setBundleUploadStatus(config.scene_config_id,'','max_json');
                          props.setUploadProgress(''); 
                        } else {
                          props.setUploadProgress('error');
                          props.setBundleUploadStatus(config.scene_config_id,'Error! File type must either be json.','max_json');
                          return false;
                        }
                      }}
                      onChange={(info) => {
                        props.handleMaxChange(info,config.scene_config_id);
                        props.getConfigFileStatus(config.scene_config_id,info); 
                      }}
                      onRemove={() => {
                        props.setBundleUploadStatus(config.scene_config_id,'','max_json');
                        props.changeConfigUploadStatus(config.scene_config_id,false,'max_json');
                        props.removeFile(config.scene_config_id,'json');
                      }}
                      multiple={false} 
                      className="flag-scene-upload-box-color image-replace-field flag-setting">
                      <span>
                        {props.json_uploading[config.scene_config_id] == false ? 
                          <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                            <img src="/img/exclude.png"></img>
                            <div className="ant-upload-text" style={{marginTop:20}}>
                              <div className="flag-scene-card-txt grey">Drop your JSON file here , or <span style={{color:"#276DD7"}}>Browse it</span>.</div>
                              <div className="flag-scene-card-txt grey">Supported formats: .json</div>
                            </div>
                          </div> : 
                          props.upload_validation_status[config.scene_config_id] == '' ? 
                          <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                            <div className="ant-upload-text">
                              <div className="flag-scene-card-txt blue">{props.config_progress[config.scene_config_id] == "uploading" ? "Uploading.." : (props.config_progress[config.scene_config_id]  == "error" || props.upload_error != '' ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.config_progress[config.scene_config_id]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>Uploaded</span><img src="/img/success_upload.jpg" className="img-upload-text"></img></span> : '')}</div>
                            </div> 
                          </div>: props.upload_validation_status[config.scene_config_id] == 'in_progress' ? <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                            <LoadingOutlined className="loader-validation"/>
                            <div className="ant-upload-text">
                              <div className="flag-scene-card-txt blue font-12 margin">Please wait. We are running some validation checks on your upload. </div>
                            </div> 
                          </div> : props.upload_validation_status[config.scene_config_id] == 'successful' ? <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                            <img src="/img/success_upload.jpg" className="img-upload-text large"/>
                            <div className="ant-upload-text">
                              <div className="flag-scene-card-txt blue font-12 margin" style={{color: "#4DD2AC"}}>Your upload has been successfully validated. Please Submit your file to proceed.</div>
                            </div> 
                          </div>: ''}
                      </span>
                      </Upload>
                    </Form.Item>              
                  {((props.config_progress[config.scene_config_id] == "uploading" || props.config_progress[config.scene_config_id] == "error") && props.uploadStatus != '') ? 
                  <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                  <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.uploadStatus}</div>
                  </div>: ''}
                    {props.bundle_upload_status[config.scene_config_id] ? 
                  <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                    <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.bundle_upload_status[config.scene_config_id]}</div>
                  </div>: ''}    
                  {props.upload_failure_message[config.scene_config_id] ? 
                  <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                    <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[config.scene_config_id]}</div>
                  </div>: ''}           
                </Col>
              </Row>  
           </TabPane> 
         </Tabs> : ''
        )) }
          <Col span={24}>
            
          </Col>
          {props.superArtistComplaintPage ?
          <div style={{marginTop: 10}}>
            <h4>Time Spent (In Hours) <span style={{color: 'red'}}> *</span></h4>
            <Form.Item
              name="time_spent"
              rules={[{ required: true, message: 'Please Enter the time spent (in hours) to fix the complaint' }]}
              >
              <Input
                type="number"
                placeholder="Time Spent to fix the complaint"
              />
            </Form.Item>
          </div> : "" }
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            <Button className="modal-okay-gray square font-14" style={{marginRight: 16,marginBottom: 10}} onClick={props.upload_type == '' ? props.cancelReplace3dsMaxModal : props.setDiscardUploadTrue}>
              Cancel
            </Button>
            <Form.Item>
              <Button className="modal-okay square font-14" htmlType="submit" disabled={props.enable_upload_button || props.upload_error != ''}>
                Upload Bundle&nbsp;{props.button_loader ? <LoadingOutlined/> : ''}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
    <Modal destroyOnClose={true}
    maskClosable={false}
    visible={props.scene_3ds_download}
    className="modal-flag-override">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large">Download Scene Bundle File</h2>
        <h2 className="modal-text gray">Download the scene bundle you need to fix or download the complete bundle for the complete scene.</h2>
        <Row>
          <Col span={24} className="justify-in-end">
          <div className='show-m-text show-less' onClick={props.toggleShowMore}>{props.type == "scene_360" ? (props.show_more ? 'Show Flagged 360s Only' : 'Show All 360s') : props.type == "scene_lifestyle" ? (props.show_more ? 'Show Flagged Images Only' : 'Show All Images') : ''}</div>
          </Col>
        </Row>
          {props.config_data.map((config,index) => (
          <Row className="scene-flag-columns" style={{display:config.cameras.length == 1 ? "none" : "flex"}}>
            <Col span={12}>
            {(config.cameras.length > 1)? 
              <Row className="image-group">
                {config.cameras.map((camera_name)=> (
                <Col span={12} style={{paddingRight: 16,paddingBottom: 16}}>
                  <Card className="flag-card-pd">
                    {props.flagged_bool_check[camera_name] ?
                    <Tooltip placement='right' title={'Flagged By Customer'}>
                      <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                        <img className="flag-img" src="/img/flag_selected.png"/>
                      </div>
                    </Tooltip>: ''}
                    {props.type == "scene_360" ?                     
                    <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+ camera_name + ".jpg"  } customClass="flag-override-panorama max-3ds" customStyle="custom-flag-style"/> : 
                    props.type == "scene_lifestyle" ? 
                    <img className='upload-image-property max-3ds' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + camera_name + ".jpg"}/> : ''}
                    <span className="artist-upload-txt scene-name">{camera_name}</span>
                  </Card>
                </Col>
                ))}
              </Row> : ''
              }
            </Col>
            {config.cameras.length > 1 ? 
            <Col span={12} style={{display:"grid",paddingLeft:"16px"}}>
              <div className='reference-file-form scene-3ds'>
                  <div className="d-flex upload-flag-scene " style={{width:"100%",margin: 0}}>
                  <a  onClick={() => props.downloadConfigurationBundle(config.scene_config_id)}>
                    <Button className="modal-okay square font-14">
                      Download Bundle {props.download_loader[config.scene_config_id] ? <LoadingOutlined/> : ''}
                    </Button>
                  </a>
                  </div>
              </div>
            </Col> : ''}
          </Row>  
        )) }
        <Row gutter={[32]}>
        {props.config_data.map((config,index) => (
          config.cameras.length == 1 ? 
          <Col span={12} className="scene-flag-columns-single">
            <Card className="flag-card-pd">
              {props.flagged_bool_check[config.cameras[0]] ?
              <Tooltip placement='right' title={'Flagged By Customer'}>
                <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                  <img className="flag-img" src="/img/flag_selected.png"/>
                </div>
              </Tooltip>: ''}
              {props.type == "scene_360" ?               
              <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+   config.cameras[0] + ".jpg"} customClass="flag-override-panorama max-3ds-single " customStyle="custom-flag-style"/>
              : props.type == "scene_lifestyle" ? <img className='upload-image-property max-3ds-single' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + config.cameras[0] + ".jpg"}/> : ''}
              <span className="artist-upload-txt scene-name">{config.cameras[0]}</span>
            </Card>
            <a onClick={() => props.downloadConfigurationBundle(config.scene_config_id)}>
              <Button className="modal-okay square font-14" style={{ marginTop: 25 }}>
                Download Bundle  {props.download_loader[config.scene_config_id] ? <LoadingOutlined/> : ''}
              </Button>
            </a>
          </Col>: '' ))}
          </Row>
          <Col span={24}>
            {props.uploadStatus != '' ? 
            <div className="val-note-bg red"  style={{textAlign:"left"}}>
              <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.uploadStatus}</div>
            </div>: ''}
              
           
          </Col>
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            <Button className="modal-okay-gray square font-14" disabled={props.download_complete_loader} style={{marginRight: 16}} onClick={props.cancel3dsDownload}>
                Cancel
            </Button>
            <Button className="modal-okay square font-14" disabled={props.download_complete_loader} onClick={() => props.downloadCompleteBundle()}>
              Download Complete Bundle {props.download_complete_loader ? <LoadingOutlined/> : ''}
            </Button>
          </div>
      </div>
    </Modal>

    <Modal
    maskClosable={false}
    destroyOnClose={props.destroy_modal}
    visible={props.space_bundle_modal}
    className="modal-flag-override">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large">Upload Space Bundle</h2>
        <h2 className="modal-text gray" style={{marginBottom: 10}}>Upload the 3ds Max file (Space Bundle) for the Space below. Make sure you upload the correct, validated max file.</h2>
        <Form onFinish={props.handleSubmitSpaceBundle}>
          <Row className="scene-flag-columns white">
            <Row  className="scene-flag-columns flag-width" style={{justifyContent:"flex-start"}}>
            {props.type == "scene_lifestyle" ? 
            props.lifestyle_renders.map((x,index) => (
              <Col span={6} style={{paddingRight: 16,paddingBottom: 16}}>
                <Card className="flag-card-pd">
                  {props.flagged_bool_check[x.filename.split('.')[0]] ?
                  <Tooltip placement='right' title={'Flagged By Customer'}>
                    <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20}}>
                      <img className="flag-img" src="/img/flag_selected.png"/>
                    </div>
                  </Tooltip>: ''}
                  <img className='upload-image-property max-3ds' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + x.filename}/>
                  <span className="artist-upload-txt scene-name">{x.filename.split('.')[0]}</span>
                </Card>
              </Col>
            )) : props.type == "scene_360" ? props.three_sixties.map((x,index) => (
              <Col span={6} style={{paddingRight: 16,paddingBottom: 16}}>
                <Card className="flag-card-pd">
                  {props.flagged_bool_check[x.filename.split('.')[0]] ?
                  <Tooltip placement='right' title={'Flagged By Customer'}>
                    <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                      <img className="flag-img" src="/img/flag_selected.png"/>
                    </div>
                  </Tooltip>: ''}
                  <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+ x.filename } customClass="flag-override-panorama max-3ds" customStyle="custom-flag-style"/>
                  <span className="artist-upload-txt scene-name">{x.filename.split('.')[0]}</span>
                </Card>
              </Col>
            )): ''}
            </Row>
            <Col span={24} style={{marginBottom: 32}}>
              <a target="_blank" href={props.room_max_archive}>
                <Button className="modal-okay-blue square font-14">
                  Download Space File
                </Button>
              </a>
            </Col>
            <Col span={24} style={{display:"grid"}}>
              <Form.Item className='reference-file-form scene-3ds'
              valuePropName={`space_bundle`}
              getValueFromEvent={props.normFile} name ={`space_bundle`} colon={false}>
                <Upload {...Constants.getRoomModelUploadProp(props.room_platform)}
                progress= {{
                  strokeColor: {
                  '0%': '#108ee9',
                  '100%': '#87d068',
                  },
                  strokeWidth: 3,
                  format: percent => `${parseFloat(percent.toFixed(2))}%`,
                  }}
                accept=".zip" 
                beforeUpload={(file) => {
                  props.changeBundleUploadStatus(true);
                  props.setUploadProgress("");
                  if (file.name != (props.room_id + '.zip')) {
                    props.setUploadProgress("error");
                    props.setUploadStatus('Incorrect file name. File should be named as SpaceID.zip');
                    return false;
                  } else {
                    return props.checkSpaceFolder(file);
                  }

                }}
                fileList={props.bundlefileList}
                onChange={(info) => {
                  props.getBundleFileStatus(info); 
                }}
                onRemove={() => {
                  props.changeBundleUploadStatus(false);
                }}
                multiple={false} 
                className="flag-scene-upload-box-color image-replace-field flag-setting">
                  {props.bundle_uploading == false ? 
                  <div className="d-flex upload-flag-scene " style={{width:"100%",margin: 0}}>
                    <img src="/img/exclude.png"></img>
                    <div className="ant-upload-text" style={{marginTop:20}}>
                      <div className="flag-scene-card-txt grey">Drop your Space Model's Max Archive folder here , or <span style={{color:"#276DD7"}}>Browse it</span>.</div>
                      <div className="flag-scene-card-txt grey">Supported formats: .zip file</div>
                    </div>
                  </div> : 
                  props.space_validation == 0 ?
                  <div className="d-flex upload-flag-scene " style={{width:"100%",margin: 0}}>
                    <div className="ant-upload-text">
                      <div className="flag-scene-card-txt blue">{props.bundle_progress == "uploading" ? "Uploading..." : (props.bundle_progress  == "error" || props.upload_error != '' ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.bundle_progress == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>Uploaded</span><img src="/img/success_upload.jpg" className="img-upload-text"></img></span> : '')}</div>
                    </div> 
                  </div> : 
                  props.space_validation == 1 ? <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                  <LoadingOutlined className="loader-validation"/>
                  <div className="ant-upload-text">
                    <div className="flag-scene-card-txt blue font-12 margin">Please wait. We are running some validation checks on your upload. </div>
                  </div> 
                </div> : props.space_validation == 2 ? <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                  <img src="/img/success.png" className="img-upload-text large"/>
                  <div className="ant-upload-text">
                    <div className="flag-scene-card-txt blue font-12 margin" style={{color: "#4DD2AC"}}>Your upload has been validated. Please review summary details.</div>
                  </div> 
                </div>: ''}
                </Upload>
              </Form.Item>
              {props.uploadStatus != '' ? 
              <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.uploadStatus}</div>
              </div>
              : ''}
              {props.upload_bundle_failure_message != '' ? 
                  <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                    <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_bundle_failure_message}</div>
                  </div>: ''}
              {props.space_validation == 2 ?<div className='justify-space-between' style={{marginTop: '16px'}}>
              { props.validation_generated ?
                  props.validation_report ? 
                  props.validation_check &&  props.invalid_val_length == 0  ?
                  <div className="val-note-bg green" >
                    <div className="note-text green"><CheckOutlined className="validation-check"/>&nbsp;File Validated Successfylly!</div>
                  </div>:  
                <div className="val-note-bg red" >
                  <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp;Few issues were found in the space file you uploaded. Please fix and try uploading again.</div>
                </div> : ''
                 :   
                <div className="val-note-bg red" style={{marginBottom: 16}}>
                 <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp;Validation Summary could not be generated. Please reupload your file.</div>
                </div>}
                <div className="share-modal-input" style={{cursor: "pointer"}} onClick={props.toggleSummary}>{props.toggle_summary ? 'Hide' : 'Show'} Summary Details</div>
              </div>: ''}
              {props.space_validation == 2 && props.toggle_summary ?
              <Row  style={{marginTop: "16px"}}>
                { props.validation_generated ?
                <Col span={24}>
        
                     {!props.space_valid &&  props.validation_check ?                        
                        <div className="val-note-bg red" style={{marginBottom: 16}}>
                          <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp;The space bundle you uploaded is invalid. Please re-upload the correct bundle.</div>
                        </div>
                        : !props.space_valid && !props.validation_check ?
                        <div className="val-note-bg red" style={{marginBottom: 16}}>
                         <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp;The system was unable to detect space root nodes. Please re-upload the correct bundle. (Refer to Section 5 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>.)</div>
                       </div> : ''}
                    { props.invalid_val_length == 0 ? '' :
                    <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => <div className="draw-full-circle red" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{ props.invalid_val_length}</span></div>}
                    className="collapse-validation">
                    <Panel header={<span className="modal-text red">What Went Wrong?</span>} key="1" className="site-collapse-custom-panel">
                        <div className="collapse-validation-collapse">                        
                        { props.invalid_data_high.length == 0 ? '' :
                            <span>
                                <div className='val-sub-heading width-height-label'>High Poly</div>                                <List
                                bordered={false}
                                dataSource={ props.invalid_data_high}
                                className="list-validation"
                                renderItem={item => (
                                <List.Item className="list-item-validation">
                                    <Typography.Text mark className="mark-style" style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-triangle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                </List.Item>
                                )}
                            />
                            </span>}                        
                        </div>
                    </Panel>
                    </Collapse>}
                    {props.auto_val_length == 0 ? '' :
                    <Collapse
                    bordered={false}
                    defaultActiveKey={props.invalid_val_length == 0 ? ['1']: ['0']}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => <div className="draw-full-circle yellow" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{ props.auto_val_length}</span></div>}
                    className="collapse-validation">
                        <Panel header={<span className="modal-text yellow">Automatically Fixed</span>} key="1" className="site-collapse-custom-panel">
                            <div className="collapse-validation-collapse">
                            {props.automatically_fixed_high.length == 0 ? '' :
                            <span>
                                <div className='val-sub-heading width-height-label'>High Poly</div>                                <List
                                bordered={false}
                                dataSource={props.automatically_fixed_high}
                                className="list-validation"
                                renderItem={item => (
                                <List.Item className="list-item-validation">
                                    <Typography.Text mark className="mark-style" style={{marginRight: 10}}><img style={{backgroundColor: "#fafafa"}} src="/img/alert-circle.png"/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                                </List.Item>
                                )}/>
                            </span>}
                        
                            </div>
                        </Panel>
                    </Collapse>}
                    {props.success_val_length == 0 ? '' : 
                    <Collapse
                    bordered={false}
                    defaultActiveKey={props.invalid_val_length == 0  &&  props.auto_val_length == 0 ? ['1']: ['0']}
                    expandIconPosition={'right'}
                    expandIcon={({ isActive }) => <div className="draw-full-circle" style={{padding: "9px 0 0 0",top: 7}}><span className="artist-upload-txt small white">{ props.success_val_length}</span></div>}
                    className="collapse-validation">
                    <Panel header={<span className="modal-text green">Successfully Validated</span>} key="1" className="site-collapse-custom-panel">
                        <div className="collapse-validation-collapse">
                        {props.successfully_validated_high.length == 0 ? '' :
                        <span>
                            <div className='val-sub-heading width-height-label'>High Poly</div>
                            <List
                            bordered={false}
                            dataSource={props.successfully_validated_high}
                            className="list-validation"
                            renderItem={item => (
                            <List.Item className="list-item-validation">
                                <Typography.Text mark className="mark-style" style={{marginRight: 10}}><Checkbox className="green-checkbox" checked={true}/></Typography.Text><span className="flag-text grayish-black">{item}</span>
                            </List.Item>
                            )}/>
                        </span>}
                        
                        </div>
                    </Panel>
                    </Collapse>}
                </Col>: ''}
                </Row>  : ''}
              
            </Col>
          </Row>
          {props.superArtistComplaintPage ?
          <div style={{marginTop: 10}}>
            <h4>Time Spent (In Hours) <span style={{color: 'red'}}> *</span></h4>
            <Form.Item
              name="time_spent"
              rules={[{ required: true, message: 'Please Enter the time spent (in hours) to fix the complaint' }]}
              >
              <Input
                type="number"
                placeholder="Time Spent to fix the complaint"
              />
            </Form.Item>
          </div> : "" }
        
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            <Button className="modal-okay-gray square font-14" style={{marginRight: 16,marginBottom: 10}} onClick={props.upload_type == '' ? props.cancelSpaceBundleModal : props.setDiscardUploadTrue} disabled={props.button_loader}>
                Cancel
            </Button>
            <Form.Item>
              <Button className="modal-okay square font-14" htmlType="submit" 
                disabled={props.enable_upload_button || props.button_loader || props.upload_error != ''}>
                   {/* || (props.space_validation != 2 || props.reupload_flag)}> */}
                Upload Space File&nbsp;{props.button_loader ? <LoadingOutlined/> : ''}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>

    <Modal destroyOnClose={true}
    maskClosable={false}
    onCancel={props.cancelGenerateNewRenders}
    visible={props.generate_new_renders}
    className="modal-flag-override">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large">Select {props.type=="scene_lifestyle" ? 'Images' : '360s'} to Render</h2>
        <h2 className="modal-text gray" style={{marginBottom: 10}}>The space file has been uploaded successfully. We will run a job on it and generate new {props.type=="scene_lifestyle" ? 'Images' : '360s'}  for you to review and resolve the complaint. Select the {props.type=="scene_lifestyle" ? 'Images' : '360s'} you want to render.</h2>
        <Row>
          <Col span={24} className="justify-in-end">
          <div className='show-m-text show-less' onClick={props.toggleShowMore}>{props.type == "scene_360" ? (props.show_more ? 'Show Flagged 360s Only' : 'Show All 360s') : props.type == "scene_lifestyle" ? (props.show_more ? 'Show Flagged Images Only' : 'Show All Images') : ''}</div>
          </Col>
        </Row>
          <Row className="scene-flag-columns white">
            <Row  className="scene-flag-columns flag-width" style={{justifyContent:"flex-start"}}>
            {props.type == "scene_lifestyle" ? 
            props.lifestyle_renders.map((x,index) => (
              (props.flagged_bool_check[x.filename.split('.')[0]] && !props.show_more) || (props.show_more) ?
              <Col span={6} style={{paddingRight: 16,paddingBottom: 16}}>
                <Card className="flag-card-pd">
                  {props.flagged_bool_check[x.filename.split('.')[0]] ?
                  <Tooltip placement='right' title={'Flagged By Customer'}>
                    <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20}}>
                      <img className="flag-img" src="/img/flag_selected.png"/>
                    </div>
                  </Tooltip>: ''}
                  <img className='upload-image-property max-3ds' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + x.filename}/>
                  <span className="justify-in-start artist-upload-txt scene-name" style={{color:"#276DD7",paddingLeft: 0}}>
                    <Checkbox checked={props.selected_renders[x.filename.split('.')[0]]} selected_render={x.filename.split('.')[0]} onChange={props.selectRenders} style={{color:"#276DD7",marginRight: 10}}/>
                    <span>{x.filename.split('.')[0]}</span>
                  </span>    
                  </Card>
              </Col>: ''
            )) : props.type == "scene_360" ? props.three_sixties.map((x,index) => (
              (props.flagged_bool_check[x.filename.split('.')[0]] && !props.show_more) || props.show_more ?
              <Col span={6} style={{paddingRight: 16,paddingBottom: 16}}>
                <Card className="flag-card-pd">
                  {props.flagged_bool_check[x.filename.split('.')[0]] ?
                  <Tooltip placement='right' title={'Flagged By Customer'}>
                    <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                      <img className="flag-img" src="/img/flag_selected.png"/>
                    </div>
                  </Tooltip>: ''}
                  <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+ x.filename  } customClass="flag-override-panorama max-3ds" customStyle="custom-flag-style"/>
                  <span className="justify-in-start artist-upload-txt scene-name" style={{color:"#276DD7",paddingLeft: 0}}>
                    <Checkbox checked={props.selected_renders[x.filename.split('.')[0]]} selected_render={x.filename.split('.')[0]} onChange={props.selectRenders} style={{color:"#276DD7",marginRight: 10}}/>
                    <span>{x.filename.split('.')[0]}</span>
                  </span>                    
                </Card>
              </Col>  : ''
            )): ''}
            </Row>
          </Row>  
    
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            {/* <Button className="modal-okay-gray square font-14" disabled={props.button_loader} style={{marginRight: 16}} onClick={props.cancelGenerateNewRenders}>
                Cancel
            </Button> */}
            <Button className="modal-okay square font-14" disabled={props.button_loader || props.enable_request_render} onClick={props.requestRenders}>
              Request Renders&nbsp;{props.button_loader ? <LoadingOutlined/> : ''}
            </Button>
          </div>
      </div>
    </Modal>

    <Modal
      className="modal-lifestyle-request"
      centered={true}
      visible={props.success_modal}
      width={750}
      bodyStyle={{padding: 0}}
      centered={true}
      footer={[
        <div className="justify-in-center">
         {!props.submit_json_success && !props.submit_scene_success && !props.submit_bundle_success ? '' :  <Button style={{marginRight: 10}}  className="modal-okay-gray square font-14" htmlType="submit" onClick={props.backToUpload}>
              Cancel
          </Button>}
            <Button className="modal-okay square font-14" key="submit" onClick={() => (props.submit_json_success || props.submit_scene_success || props.submit_bundle_success) ? props.openRequestRenderModal() : window.location.reload(true)}>
              {!props.submit_json_success && !props.submit_scene_success && !props.submit_bundle_success ? `Okay` : `Request Renders` }
            </Button>
           
        </div>
        
      ]}>
        <div style={{padding:"40px"}}>
            <div style={{textAlign: "center"}}>
              <img style={{width: 85,height:85}} src={"/img/success.png"}></img>
            </div>
            {props.submit_json_success ? 
            <div style={{textAlign: "center",margin: "20px 0"}}>
              <h2 className="modal-heading">JSON uploaded successfully!</h2>
              <p className="modal-text font-16">The JSON file(s) have been uploaded successfully. We will run a job on it and generate new {props.type == "scene_lifestyle" ? 'Lifestyle Images' : props.type == "scene_360" ? '360s' : ''} for you to review and resolve the complaint.</p>
              <hr></hr>
              <div className="render-override-settings"><h2 className="modal-heading gray">Override Render Settings:
                <Tooltip title={<span className='manrope f-12 white'>Select render settings that you want to override</span>}>                                                  
                  <img className="align-icon center" src={require("../../../../assets/images/help-icon-2.svg")}></img>
                </Tooltip>
                </h2>
                <Checkbox checked={props.use_default_sun} onChange={props.setDefaultSunSettings} className="render-override-checkbox"/>
                  <span className="modal-text font-14 gray">Use Default Sun Settings in the Renders</span>
              </div>
            </div> : props.submit_scene_success ?
            <div style={{textAlign: "center",margin: "20px 0"}}>
              <h2 className="modal-heading">Scene Bundle Uploaded Successfully</h2>
              <p className="modal-text font-16">The scene bundle file has been uploaded successfully. We will run a job on it and generate new {props.type == "scene_lifestyle" ? 'Lifestyle Images' : props.type == "scene_360" ? '360s' : ''} for you to review and resolve the complaint.</p>
              <hr></hr>
              <div className="render-override-settings"><h2 className="modal-heading gray">Override Render Settings:
                <Tooltip title={<span className='manrope f-12 white'>Select render settings that you want to override</span>}>                                                  
                  <img className="align-icon center" src={require("../../../../assets/images/help-icon-2.svg")}></img>
                </Tooltip>
                </h2>
                <Checkbox checked={props.use_default_sun} onChange={props.setDefaultSunSettings} className="render-override-checkbox"/>
                  <span className="modal-text font-14 gray">Use Default Sun Settings in the Renders</span>
              </div>
            </div> 
            :props.submit_bundle_success ? 
            <div style={{textAlign: "center",margin: "20px 0"}}>
              <h2 className="modal-heading">Space Bundle Uploaded Successfully!</h2>
              <p className="modal-text font-16" style={{marginBottom: 30}}>The space file has been uploaded successfully. We will run a job on it and generate new {props.type == "scene_lifestyle" ? 'Lifestyle Images' : props.type == "scene_360" ? '360s' : ''} for you to review and resolve complaint.</p>
              <hr></hr>
              <div className="render-override-settings"><h2 className="modal-heading gray">Override Render Settings:
                <Tooltip title={<span className='manrope f-12 white'>Select render settings that you want to override</span>}>                                                  
                  <img className="align-icon center" src={require("../../../../assets/images/help-icon-2.svg")}></img>
                </Tooltip>
                </h2>
                <Checkbox checked={props.use_default_sun} onChange={props.setDefaultSunSettings} className="render-override-checkbox"/>
                  <span className="modal-text font-14 gray">Use Default Sun Settings in the Renders</span>
              </div>
            </div> 
            : 
            <div style={{textAlign: "center",margin: "20px 0"}}>
              <h2 className="modal-heading">{props.type == "scene_360" ? "Scene 360s Replaced Successfully!" : (props.type == "scene_lifestyle" || props.type == "Product") ? "Renders Replaced Successfully!" : ""}</h2>
              <p className="modal-text font-16">{props.type == "scene_360" ? "Scene 360s" : (props.type == "scene_lifestyle" || props.type == "Product") ? "Renders" : ""} have been replaced successfully. Click 'Okay' to review your changes.</p>
            </div>  }  
        </div>       
    </Modal>
    <Modal
      className="modal-lifestyle-request"
      centered={true}
      visible={props.render_ready_email && !props.superArtistComplaintPage}
      width={750}
      bodyStyle={{padding: 0}}
      centered={true}
      footer={[
        <div className="justify-in-center">
        <Button className="modal-okay square font-14" key="submit" onClick={props.cancelRenderReadyModal}>
          Okay
        </Button>
        </div>
      ]}>
        <div style={{padding: "40px 40px 0 40px"}}>
          <div style={{textAlign: "center",margin: "20px 0"}}>
            <h2 className="modal-heading">Renders are Ready for Review!</h2>
            <h2 className="modal-heading">Review and Click “Mark As Resolved”</h2>
            <p className="modal-text font-16">We have generated renders with the new files you uploaded. Please review the renders and mark the complaint as “Resolved” in order to make the renders available to the customer.</p>
          </div>     
        </div>       
    </Modal>

    <Modal
      className="modal-lifestyle-request"
      centered={true}
      visible={props.download_wait_modal}
      width={750}
      bodyStyle={{padding: 0}}
      centered={true}
      footer={[
        <div className="justify-in-center">
          <Button className="modal-okay square font-14" key="submit" onClick={props.cancelDownloadWaitModal}>
          Okay
          </Button>
        </div>
        
      ]}>
        <div style={{padding:"40px"}}>
          <div style={{textAlign: "center"}}>
            <h2 className="modal-heading">Download in Progress...</h2>
            <p className="modal-text font-16">Please wait while we prepare your scene bundle for download.</p>
            <p className="modal-text font-16">We will notify you via email when it's ready.</p>
          </div>     
        </div>       
    </Modal>

    <Modal
      className="modal-lifestyle-request"
      centered={true}
      visible={props.issue_resolve_modal}
      width={750}
      bodyStyle={{padding: 0}}
      centered={true}
      footer={[
        <div className="justify-in-center">
          <Button className="modal-okay square font-14" key="submit" onClick={props.cancelResolveSuccessModal}>
            Okay
          </Button>
        </div>
      ]}>
        <div style={{padding:"40px"}}>
          <div style={{textAlign: "center"}}>
              <img style={{width: 85,height:85}} src={"/img/success.png"}></img>
          </div>
          <div style={{textAlign: "center",margin: "20px 0"}}>
              <h2 className="modal-heading">Complaint Resolved Successfully!</h2>
              <p className="modal-text font-16">You have successfully resolved this complaint and its status will be updated soon. The new renders are being replaced with the old ones and will be available to the customer shortly.</p>
          </div>     
        </div>       
    </Modal>
    <Modal
      className="modal-lifestyle-request"
      centered={true}
      visible={props.reject_flag_success}
      width={750}
      bodyStyle={{padding: 0}}
      centered={true}
      footer={[
        <div className="justify-in-center">
          <Button className="modal-okay square font-14" key="submit" onClick={props.cancelRejectFlag}>
            Okay
          </Button>
        </div>
      ]}>
        <div style={{padding:"40px"}}>
          <div style={{textAlign: "center"}}>
              <img style={{width: 85,height:85}} src={"/img/success.png"}></img>
          </div>
          <div style={{textAlign: "center",margin: "20px 0"}}>
              <h2 className="modal-heading">Flag Rejected!</h2>
              <p className="modal-text font-16">You have rejected this complaint. The customer will be notified via email.</p>
          </div>     
        </div>       
    </Modal>
    <Modal
    maskClosable={false}
    onCancel={props.cancelJSONModal}
    destroyOnClose={props.destroy_modal}
    visible={props.replace_json_modal}
    className="modal-flag-override">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large">Upload JSON File for {props.type == 'scene_lifestyle' ? 'Lifestyle Images' : props.type == "scene_360" ? 'Scene 360' : ''}</h2>
        <h2 className="modal-text gray" style={{marginBottom: 10}}>Upload JSON file for the flagged {props.type == 'scene_lifestyle' ? 'image(s)' : props.type == "scene_360" ? '360(s)' : ''} below. Make sure you upload the correct JSON file against its respective {props.type == 'scene_lifestyle' ? 'image(s)' : props.type == "scene_360" ? '360(s)' : ''}.</h2>
        <Row>
          <Col span={24} className="justify-in-end">
          <div className='show-m-text show-less' onClick={props.toggleShowMore}>{props.type == "scene_360" ? (props.show_more ? 'Show Flagged 360s Only' : 'Show All 360s') : props.type == "scene_lifestyle" ? (props.show_more ? 'Show Flagged Images Only' : 'Show All Images') : ''}</div>
          </Col>
        </Row>
        <Form onFinish={props.handleSubmitJSON}>
          {props.config_data.map((config,index) => (
          config.cameras.length >= 1 ?
          <Row className="scene-flag-columns">
            <Col span={12}>
            {(config.cameras.length > 1)? 
            <Row className="image-group">
                {config.cameras.map((camera_name)=> (
                <Col span={12} style={{paddingRight: 16,paddingBottom: 16}}>
                  <Card className="flag-card-pd">
                  {props.flagged_bool_check[camera_name] ?
                    <Tooltip placement='right' title={'Flagged By Customer'}>
                      <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                        <img className="flag-img" src="/img/flag_selected.png"/>
                      </div>
                    </Tooltip>: ''}
                    {props.type == "scene_360" ? 
                    <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+ camera_name + ".jpg"  } customClass="flag-override-panorama max-3ds" customStyle="custom-flag-style"/>
                    : props.type == "scene_lifestyle" ? 
                    <img className='upload-image-property max-3ds' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + camera_name + ".jpg"}/> : ''}
                    <span className="artist-upload-txt scene-name">{camera_name}</span>
                  </Card>
                </Col>
                ))}
              </Row> :
              <Card className="flag-card-pd">
               {props.flagged_bool_check[config.cameras[0]] ?
                <Tooltip placement='right' title={'Flagged By Customer'}>
                  <div className="note-bg-artist deep-red flag-note-pos" style={{top: 20, left: 20, zIndex:2}}>
                    <img className="flag-img" src="/img/flag_selected.png"/>
                  </div>
                </Tooltip> : ''}
                {props.type == "scene_360" ?
                <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.scene_id +'/'+   config.cameras[0] + ".jpg"} customClass="flag-override-panorama max-3ds-single " customStyle="custom-flag-style"/>
                : props.type == "scene_lifestyle" ?  
                <img className='upload-image-property max-3ds-single' src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI +  props.scene_id + '/' + config.cameras[0] + ".jpg"}/> : ''}
                <span className="artist-upload-txt scene-name">{config.cameras[0]}</span>
              </Card>}
              
            </Col>
            <Col span={12} style={{display:"grid",paddingLeft:"16px"}}>
              <Form.Item className='reference-file-form scene-3ds'
              valuePropName={`reference_filelist_${config.scene_config_id}`}
              getValueFromEvent={props.normFile} name ={`reference_files_${config.scene_config_id}`} colon={false}>
                <Upload {...Constants.getUploadProps(props.platform)} 
                progress= {{
                  strokeColor: {
                  '0%': '#108ee9',
                  '100%': '#87d068',
                  },
                  strokeWidth: 3,
                  format: percent => `${parseFloat(percent.toFixed(2))}%`,
                  }}
                accept=".json"
                beforeUpload={(file) => {
                  props.changeConfigUploadStatus(config.scene_config_id,true,'json');
                  props.setBundleUploadStatus(config.scene_config_id,'','json');
                  props.setUploadProgress('');
                  if(!file.name.includes('.json')){
                    props.setUploadProgress('error');
                    props.setBundleUploadStatus(config.scene_config_id,'Currently only .json format is supported. Kindly upload your file as json','json');
                    return false;
                  } else {
                    props.setUploadProgress('');
                    props.setBundleUploadStatus(config.scene_config_id,'','json');
                  }
                }}
                fileList={props.jsonFileList[config.scene_config_id]}
                onChange={(info) => {
                  props.handleJSONFileChange(info,config.scene_config_id);
                  props.getConfigFileStatus(config.scene_config_id,info); 
                }}
                onRemove={() => {
                  props.setBundleUploadStatus(config.scene_config_id,'','json');
                  props.changeConfigUploadStatus(config.scene_config_id,false);
                  props.removeFile(config.scene_config_id,'json');
                }}
                multiple={false} 
                className="flag-scene-upload-box-color image-replace-field flag-setting">
                  <span>
                {props.config_uploading[config.scene_config_id] == false ? 
                  <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                    <img src="/img/exclude.png"></img>
                    <div className="ant-upload-text" style={{marginTop:20}}>
                      <div className="flag-scene-card-txt grey">Drop your Scene {props.type == "scene_360" ? '360s' : 'Images'} JSON file here , or <span style={{color:"#276DD7"}}>Browse it</span>.</div>
                      <div className="flag-scene-card-txt grey">Supported format: .json</div>
                    </div>
                  </div> : 
                  props.upload_validation_status[config.scene_config_id] == '' ? 
                  <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                    <div className="ant-upload-text">
                      <div className="flag-scene-card-txt blue">{props.config_progress[config.scene_config_id] == "uploading" ? "Uploading.." : (props.config_progress[config.scene_config_id]  == "error" || props.upload_error != '' ? <span style={{color: "#d93025"}}>An Error Occured!</span> : props.config_progress[config.scene_config_id]  == "done" ? <span style={{color: "#4DD2AC"}} className="justify-upload-text"><span style={{marginRight: 10}}>Uploaded</span><img src="/img/success_upload.jpg" className="img-upload-text"></img></span> : '')}</div>
                    </div> 
                  </div>: props.upload_validation_status[config.scene_config_id] == 'in_progress' ? 
                  <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                    <LoadingOutlined className="loader-validation"/>
                    <div className="ant-upload-text">
                      <div className="flag-scene-card-txt blue font-12 margin">Please wait. We are running some validation checks on your upload. </div>
                    </div> 
                  </div> : props.upload_validation_status[config.scene_config_id] == 'successful' ? <div className="d-flex upload-flag-scene" style={{width:"100%",margin: 0}}>
                    <img src="/img/success_upload.jpg" className="img-upload-text large"/>
                    <div className="ant-upload-text">
                      <div className="flag-scene-card-txt blue font-12 margin" style={{color: "#4DD2AC"}}>Your upload has been successfully validated. Please Submit your file to proceed.</div>
                    </div> 
                  </div>: ''}
                  {/* <div className='justify-in-start'>
                    <div className="val-note-bg green">
                      <div className="note-text green"><CheckOutlined className="validation-check"/>&nbsp;Correct Space File</div>
                    </div>
                    <div className="val-note-bg green">
                      <div className="note-text green"><CheckOutlined className="validation-check"/>&nbsp;File Validation Successful</div>
                    </div>
                  </div> */}
                  </span>
                </Upload>
              </Form.Item>
              {props.config_progress[config.scene_config_id]  == "done" ? 
              <a style={{textAlign:"center"}} target="_blank" href={(props.superArtistComplaintPage ? '/artist_scene_test/' : '/admin_scene_test/') + props.scene_id + '?complaint_id='+props.complaint_id+'&config_id='+config.scene_config_id}>
                <Button className="modal-okay-blue square font-14" style={{ marginTop: 25}} disabled={props.upload_error != '' }>
                  Test Your JSON in Scene Creator&nbsp;<ArrowRightOutlined/>
                </Button>
              </a>: ''}

            

              {((props.config_progress[config.scene_config_id] == "uploading" || props.config_progress[config.scene_config_id] == "error") && props.uploadStatus != '') ? 
                 <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                 <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.uploadStatus}</div>
                </div>: ''}

                {(props.bundle_upload_status[config.scene_config_id]) ? 
                 <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                  <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.bundle_upload_status[config.scene_config_id]}</div>
                </div>: ''}
                {props.upload_failure_message[config.scene_config_id] ? 
                  <div className="val-note-bg red"  style={{textAlign:"left",marginTop: 10}}>
                    <div className="note-text error-red"><WarningOutlined className="validation-check error"/>&nbsp; {props.upload_failure_message[config.scene_config_id]}</div>
                  </div>: ''}
              
            </Col>
          </Row>  : ''
        )) }
        {props.superArtistComplaintPage ?
         <div style={{marginTop: 10}}>
          <h4>Time Spent (In Hours)<span style={{color: 'red'}}> *</span></h4>
          <Form.Item
            name="time_spent"
            rules={[{ required: true, message: 'Please Enter the time spent (in hours) to fix the complaint' }]}
            >
            <Input
              type="number"
              placeholder="Time Spent to fix the complaint"
            />
          </Form.Item>
        </div> : ""}
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            <Button className="modal-okay-gray square font-14" style={{marginRight: 16,marginBottom: 10}} onClick={props.upload_type == '' ? props.cancelJSONModal : props.setDiscardUploadTrue}>
            Cancel            
            </Button>
            <Form.Item>
              <Button className="modal-okay square font-14" htmlType="submit" disabled={props.enable_upload_button || props.upload_error != ''}>
               Submit JSON Files&nbsp;{props.button_loader ? <LoadingOutlined/> : ''}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
    <Modal destroyOnClose={true}
    maskClosable={false}
    width={520}
    onCancel={props.cancelRejectModal}
    visible={props.reject_flag_modal}
    className="modal-flag-override reject">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large" style={{textAlign:"left",justifyContent:"flex-start",marginBottom: 16}}>Reject Flag Request</h2>
        <Form onFinish={props.rejectFlag}>
          <Form.Item 
            rules={[{ required: true, message: 'Please input a reject message.' }]}
            name ={`reject_message`} colon={false}>
            <TextArea
              placeholder="Please write a message for the customer specifying why you are rejecting their flagging"
              rows={5}
              style={{height: "auto !important"}}
            />
          </Form.Item>
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            <Button className="modal-okay-gray square font-14" style={{marginRight: 16,marginBottom:10}} onClick={props.cancelRejectModal}>
              Cancel
            </Button>
            
            <Form.Item>
              <Button className="modal-okay square font-14" htmlType="submit">
                Send&nbsp;{props.button_loader ? <LoadingOutlined/> : ''}
              </Button>
            </Form.Item>
          </div>
        </Form>

      </div>

    </Modal>

    <Modal
      className="modal-lifestyle-request"
      centered={true}
      visible={props.render_request_success}
      width={750}
      bodyStyle={{padding: 0}}
      centered={true}
      footer={[
        <div className="justify-in-center">
          <Button className="modal-okay square font-14" key="submit" onClick={props.cancelRenderRequestModal}>
            Okay
          </Button>
        </div>
      ]}>
        <div style={{padding:"40px"}}>
          <div style={{textAlign: "center"}}>
              <img style={{width: 85,height:85}} src={"/img/success.png"}></img>
          </div>
          <div style={{textAlign: "center",margin: "20px 0"}}>
              <h2 className="modal-heading">Renders Requested Successfully!</h2>
              <p className="modal-text font-16">You have successfully requested renders. We will let you know via email when the new renders are ready for your review.</p>
          </div>     
        </div>       
    </Modal>

    <Modal destroyOnClose={true}
    maskClosable={false}
    width={520}
    onCancel={props.cancelOriginalModal}
    visible={props.send_back_original}
    className="modal-flag-override reject">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large" style={{textAlign:"left",justifyContent:"flex-start",marginBottom: 16}}>Use Original Render</h2>
        <Form onFinish={props.sendBackOriginal}>
          <Form.Item 
            rules={[{ required: true, message: 'Please input a message.' }]}
            name ={`reason_message`} colon={false}>
            <TextArea
              placeholder="Please write a message for the customer specifying why you are sending back some of the original renders"
              rows={5}
              style={{height: "auto !important"}}
            />
          </Form.Item>
          <div style={{display:'flex',justifyContent:"flex-end",marginTop: 32}}>
            <Button className="modal-okay-gray square font-14" style={{marginRight: 16,marginBottom: 16}} onClick={props.cancelOriginalModal}>
                Cancel
            </Button>
            <Form.Item>
              <Button className="modal-okay square font-14" htmlType="submit">
                Submit&nbsp;{props.button_loader ? <LoadingOutlined/> : ''}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>

    <Modal destroyOnClose={true}
    maskClosable={false}
    width={520}
    closable={true}
    onCancel={props.cancelHelp}
    visible={props.help_button}
    className="help-override">
      <div style={{padding: 0}}>
        <h2 className="share-modal-heading greyish-black large" style={{textAlign:"center",justifyContent:"center",marginBottom: 16}}>Admin Overrides Guidelines</h2>
        <h2 className="modal-text gray" style={{marginBottom: 20}}>
          Hi, Welcome to our Admin Override Tool! If you are new to this tool, please refer to the <a target="_blank" style={{textDecoration:"underline"}} href={FLAG_IF_UNHAPPY_GUIDELINES}>guidelines</a> and instructions provided below.
        </h2>

        <h2 className="modal-text bold" style={{marginBottom: 10}}>1. Download</h2>
        <div className="justify-space-between align-start"  style={{marginBottom: 25}}>
          <span style={{maxWidth:"60%"}}>
            <p className="modal-text small gray">You can download items that you need through this download option.
            </p>
            <p className="modal-text small gray">
            <ol className="list-gap">
              <li>
                If you need to look into issues with the rendered images, you can simply click on <b>Flagged 360/Images Only</b> or <b>All Images/360s</b> option.
              </li>
              <li>
                If you need the entire scene bundle (space file, assets, json file) which specifies how the scene is laid out, click on <b>Scene Bundle</b> option. We will create the bundle on the fly which will take some time, and send out an email to you when it's ready.
                <div className="note-bg-artist gray width" >
                  <span className="note-text blue small">
                    <span style={{fontWeight:"bold"}}>How to open scene bundle?
                    </span>&nbsp;
                    <ul className="sub-list-gap">
                      <li>Open 3ds Max. Make sure it’s fully loaded and ready to use.</li>
                      <li>Click the <b>Load Scene</b> button.
                        <img src="/img/load_scene.png" className="load-scene-img"/>
                      </li>
                      <li>Choose your bundle zip file that you’ve downloaded from the ALL3D Portal.</li>
                      <li>Wait for the plugin to load your bundle. Once it’s ready, it will notify you with an alert.</li>
                      </ul>            
                  </span>   
                </div>
              </li>
              <li>
                If you only need the space bundle, click on <b>Space Bundle</b> option.
              </li>
            </ol>
            </p>
          </span>
          <span><img src='/img/download.jpg' className="flag-image-help"></img></span>
        </div>
       

        <h2 className="modal-text bold" style={{marginBottom: 10}}>2. Replace Images/360s</h2>
        <div className="justify-space-between align-start">
          <span style={{maxWidth:"60%"}}  style={{marginBottom: 25}}>
            <p className="modal-text small gray" >This action is to be used when you need to replace the lifestyle/360 image(s) with the new image(s) that you have fixed.</p>
            <div className="note-bg-artist gray width" >
              <span className="note-text blue small"><span style={{fontWeight:"bold"}}>Upload Guidelines:</span>&nbsp;Upload the image that you want to replace against the render.</span>   
            </div>
          </span>
          <span>
            <img src='/img/replace_images.jpg' className="flag-image-help"></img>
          </span>
        </div>

        <h2 className="modal-text bold" style={{marginBottom: 10}}>3. Replace Scene JSON File</h2>
        <div className="justify-space-between align-start">
          <span style={{maxWidth:"60%"}}  style={{marginBottom: 25}}>
            <p className="modal-text small gray">Use this option when you need to make positioning fixes or change placement of items/cameras. This override can be used for lifestyle images and 360s.</p>
            <div className="note-bg-artist gray width" >
              <span className="note-text blue small"><span style={{fontWeight:"bold"}}>Upload Guidelines:</span>&nbsp;
                  Export your JSON after making your changes by following the steps given below.
                    <ul className="sub-list-gap">
                      <li>Click the <b>Export Scene</b> button.
                      <img src="/img/export.png" className="load-scene-img"/>
                      </li>
                      <li>Choose your output folder location.</li>
                      <li>Wait for plugin to export the scene and prepare the archive and JSON file for you.</li>
                      <li>Once it's done, it will notify you with an alert.</li>
                      <li>Upload only the JSON file here.</li>
                      <li>Refer to <a target="_blank" style={{textDecoration:"underline"}} href={FLAG_IF_UNHAPPY_GUIDELINES}>Flag If Unhappy Guidelines</a> to read detailed instructions.</li>
                    </ul>
              </span>   
            </div>
          </span>
          <span>
            <img src='/img/replace_json.jpg' className="flag-image-help"></img>
          </span>
        </div>

        <h2 className="modal-text bold" style={{marginBottom: 10}}>4. Replace Max and JSON File</h2>
        <div className="justify-space-between align-start">
          <span style={{maxWidth:"60%"}}  style={{marginBottom: 25}}>
          <p className="modal-text small gray">Use this option when you need to make geometry changes to one or multiple assets/products like changing the position or rotation of cameras. This override can be used for lifestyle images and 360s.</p>
            <div className="note-bg-artist gray width" >
              <span className="note-text blue small"><span style={{fontWeight:"bold"}}>Upload Guidelines:</span>&nbsp;
                  Export your scene bundle after making your changes by following the steps given below.
                    <ul className="sub-list-gap">
                      <li>Click the <b>Export Scene</b> button.
                      <img src="/img/export.png" className="load-scene-img"/>
                      </li>
                      <li>Choose your output folder location.</li>
                      <li>Wait for plugin to export the scene and prepare the archive and JSON file for you.</li>
                      <li>Once it's done, it will notify you with an alert.</li>
                      <li>Refer to <a target="_blank" style={{textDecoration:"underline"}} href={FLAG_IF_UNHAPPY_GUIDELINES}>Flag If Unhappy Guidelines</a> to read detailed instructions.</li>
                    </ul>
              </span>   
            </div>
          </span>
          <span>
          <img src='/img/replace_scene.jpg' className="flag-image-help"></img>
          </span>
        </div>

        <h2 className="modal-text bold" style={{marginBottom: 10}}>5. Replace Space File</h2>
        <div className="justify-space-between align-start">
          <span style={{maxWidth:"60%"}}  style={{marginBottom: 25}}>
            <p className="modal-text small gray">Use this option when you need to make changes to the space. These could be lighting changes, wall paper changes, add/remove curtains etc. This override can be used for lifestyle images and 360s.</p>
            <div className="note-bg-artist gray width" >
              <span className="note-text blue small">
                <span style={{fontWeight:"bold"}}>Upload Guidelines:</span>
                <ul className="sub-list-gap">
                  <li>Upload Space File in .zip format. </li>
                  <li>Your zip file's name and .max file's name (present inside High folder of zip) should be the same as the space ID. </li>
                  <li>Make sure relevant files are placed in High and VRayTextures folders. </li>
                  <li>Both of these folders should be at root, like shown below, and compressed directly. 
                  <img src="/img/space_format.png" className="load-scene-img"/>
                  </li>
                  <li>Refer to <a target="_blank" style={{textDecoration:"underline"}} href={SPACE_GUIDELINES}>Space Upload Guidelines</a> to read detailed instructions.</li>
                  </ul>
              </span>   
            </div>
          </span>
          <span>
          <img src='/img/replace_space.jpg' className="flag-image-help"></img>
          </span>
          {/* <span>
          <img src='/img/space_download.png' className="flag-image-help"></img>
          </span> */}
        </div>
      </div>
    </Modal>

    <Modal
      className="modal-lifestyle-request"
      centered={true}
      visible={props.discard_uploads}
      width={750}
      bodyStyle={{padding: 0}}
      centered={true}
      footer={[
        <div className="justify-in-center">
          <Button className="modal-okay square font-14" key="submit" onClick={props.backToUpload}>
              Go Back
            </Button>
            <Button className='outline-red-btn square font-14' style={{ marginLeft: 10 }} onClick={props.discardUploads}>
            Discard Uploads and Exit
          </Button>
        </div>
          
      ]}>
        <div style={{padding:"40px 40px 32px 40px"}}>
            <div style={{textAlign: "center"}}>
              <img style={{width: 50,height:50}} src={"/img/alert-triangle.svg"}></img>
            </div>
            <div className="modal-heading-text" style={{marginTop:32,textAlign:"center"}}>
            Your uploads will be discarded. Are you sure to exit?
            </div>
        </div>

    </Modal>

      {/* Success Modal for complaint unassignment */}
      <Modal
        className="modal-share-success"
        width={620}
        visible={complaintUnassignmentSucess}
        closable={false}
        footer={[
          <React.Fragment>
            <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={()=>{window.location.reload()}}>
                <div className="modal-okay square font-14">Okay</div>
            </Button>
          </React.Fragment>
        ]}
        centered={true}
        > 
        <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
            <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
            <div>
                <h2 className="success-modal-text">Unassigned successfully!</h2>
                <p className="modal-text">The complaint has been unassgined successfully. You can now make changes to the complaint.</p>
            </div>
        </div>           
      </Modal>


     {/* Unassign Confirmation */}
     <Modal
        className="modal-share-success"
        width={620}
        visible={complaintUnassignmentModal}
        closable={false}
        footer={[
          <React.Fragment>
            <Button key="back" style={{textAlign:"center"}} disabled={complaintUnassignmentLoader} className="modal-okay" onClick={()=> handleComplaintUnassignment()}>
                <div className="modal-okay square font-14">Yes&nbsp;{complaintUnassignmentLoader ? <LoadingOutlined/> : ""}</div>
            </Button>
            <Button key="back" style={{textAlign:"center"}} className="modal-okay" onClick={()=>{setComplaintUnassignmentModal(false)}}>
                <div className="modal-okay square font-14">No</div>
            </Button>

          </React.Fragment>
        ]}
        centered={true}
        > 
        <div style={{padding:"48px 48px 0 48px",textAlign:"center"}}>
            <img src='/img/success.png' style={{width:"120px",height:"120px",marginBottom:"40px"}} alt="Alert"/>
            <div>
                <h2 className="success-modal-text">Are you Sure?</h2>
                <p className="modal-text">Are you sure you want to unassign the complaint? The Artist might have already started working on it</p>
            </div>
        </div>           
      </Modal>

      {/* Detailed Rejection History Modal */}
      <Modal 
                
        closeIcon={
            <img src={"/img/closeIcon.png"}  alt="Close Modal Icon" 
            style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}} 
            />
        }
        visible={detailedRejectionModal} onCancel={()=> {setDetailedRejectionModal(false)}} footer={false} width={1000}>
            <div style={{fontSize: 24, color: '#141414'}}>
                    Rejection Comments
            </div>
            <div className="rejection-modal" style={{height: '60vh', overflowY: 'scroll'}}>
                <div style={{display:'grid'}}>
                    {detailed_rejection_data}
                </div>
                
            </div>
            
        </Modal>
    </React.Fragment>)
}

export default ComplaintDetailsComponent;