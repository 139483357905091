import React from 'react';
import { Modal, Button, Carousel, Row, Col, Checkbox } from 'antd';
import { withRouter } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

class SceneCreatorOnboarding extends React.Component {
    constructor(props){
        super(props);
        // this.state = {
        //     scene_creator_placement: true
        // }
        this.carousel = React.createRef();
        this.state = {
            checked: false
        };
    }

    componentDidMount() {
        if (this.getCookie("sc-onboard")) {
            this.setState({
                checked: true
            });
        }
    }

    onChange = (before,to) => {
        if (before == 7 && to == 0 ) {
            this.closeOnboarding();
        }
    }

    next = () => {
        this.carousel.next();
    }

    closeOnboarding = () =>{
        this.props.setOnboardingModal(false);
        // if (this.state.checked) {
        //     this.setCookie("sc-onboard", true, 365);
        // }
        // else if (this.state.checked == false) {
        //     this.setCookie("sc-onboard", false, 365);
        // }
    }

    onChangeCheckbox = (e) => {
        this.setState({
            checked: e.target.checked
        });
        if (e.target.checked) {
            this.setCookie("sc-onboard", true, 365);
        }
        else if (e.target.checked == false) {
            this.setCookie("sc-onboard", false, 365);
        }
    }

    setCookie =(cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
      
    getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
      
    checkCookie = () => {
        var cookie = this.getCookie("sc-onboard");
        console.log(cookie);
        if (cookie != "") {
          console.log("Do nothing");
        } else {
            //this.setCookie("sc-onboard", true, 365);
        }
    }

    render() {
        return (
            <span>
                <Modal
                    title={null}
                    closable={false}
                    footer={null}
                    visible={(this.props.getOnboardingModal())}
                    bodyStyle={{paddingTop:24, paddingLeft: 12, paddingBottom: 12, paddingRight: 12}}
                    width={820}
                    centered={true}
                    >

                    <div style={{width:796, textAlign: "center"}}>
                        <Carousel ref={node => (this.carousel = node)} beforeChange={this.onChange}>
                            <div>
                                <h3 className='manrope font-16'>Welcome to 3D Scene Creator</h3>
                                <p className='manrope font-14' style={{paddingLeft: 80, paddingRight: 80}}>The 3D Scene Creator will help you design your Space. Your products will be automatically placed. Drag and rotate them to create your desired look in the Edit Mode. Using the Scene Creator, you can generate <b>Lifestyle 360s</b>, <b>3D Tour</b> and <b>Photorealistic Images</b>.</p>
                                <img style={{marginTop: 30, width: 796, height:385}} src={require("../../../../assets/images/scene_creator_onboarding_1.jpg")}></img>
                                <div style={{height: 70}}></div>
                            </div>
                            <div>
                                <h3 className='manrope font-16'>Product Placement</h3>
                                <p className='manrope font-14' style={{paddingLeft: 80, paddingRight: 80}}>Use the Top down (2D) or First Person (3D) view to place products in your Scene. <b>Click</b> and <b>Drag</b> products to change their position. <b>Rotate</b> products by clicking and dragging the Rotation Gizmo.</p>
                                <img style={{marginTop: 30, width: 796, height:385}} src={require("../../../../assets/images/scene_creator_onboarding_2.jpg")}></img>
                                <div style={{height: 70}}></div>
                            </div>
                            <div>
                                <h3 className='manrope font-16'>Product Stacking</h3>
                                <p className='manrope font-14' style={{paddingLeft: 80, paddingRight: 80}}>You can stack one product on top of another by dragging it on top of it. Enabling Snapping helps you to place Products next to each other and the walls precisely.</p>
                                <img style={{marginTop: 30, width: 796, height:385}} src={require("../../../../assets/images/scene_creator_onboarding_3.jpg")}></img>
                                <div style={{height: 70}}></div>
                            </div>
                            <div>
                                <h3 className='manrope font-16'>Camera Widget</h3>
                                <p  className='manrope font-14' style={{paddingLeft: 80, paddingRight: 80}}>Click to expand the Camera Widget and use it for finer control over your Camera. The Camera Widget allows you to view your Scene through different camera angles and heights.</p>
                                <img style={{marginTop: 30, width: 796, height:385}} src={require("../../../../assets/images/scene_creator_onboarding_4.jpg")}></img>
                                <div style={{height: 70}}></div>
                            </div>
                            <div>
                                <h3 className='manrope font-16'>Lighting Widget</h3>
                                <p className='manrope font-14' style={{paddingLeft: 80, paddingRight: 80}}>Click to expand the Lighting Widget. The Lighting Widget helps you change the position of the Sun which is by default directly above your Space.</p>
                                <img style={{marginTop: 30, width: 796, height:385}} src={require("../../../../assets/images/scene_creator_onboarding_5.jpg")}></img>
                                <div style={{height: 70}}></div>
                            </div>
                            <div>
                                <h3 className='manrope font-16'>Select Aspect Ratio and Request Photorealistic Imagery</h3>
                                <p className='manrope font-14' style={{paddingLeft: 80, paddingRight: 80}}>Every Snapshot you take can be of a unique Aspect Ratio. You can also change the Camera View Type from the <b>Camera Widget</b>. You can even set Custom Aspect Ratios.</p>
                                <img style={{marginTop: 30, width: 796, height:385}} src={require("../../../../assets/images/scene_creator_onboarding_6.jpg")}></img>
                                <div style={{height: 70}}></div>
                            </div>
                            <div>
                                <h3 className='manrope font-16'>Lifestyle 360s</h3>
                                <p className='manrope font-14'style={{paddingLeft: 80, paddingRight: 80}}>Click on “Create 360” and select the Spaces and their respective resolutions to request Lifestyle 360s.</p>
                                <img style={{marginTop: 30, width: 796, height:385}} src={require("../../../../assets/images/scene_creator_onboarding_7.jpg")}></img>
                                <div style={{height: 70}}></div>
                            </div>
                            <div>
                                <h3 className='manrope font-16'>3D Tour</h3>
                                <p className='manrope font-14' style={{paddingLeft: 80, paddingRight: 80}}>Click on “Create 3D Tour” and select the Spaces and their respective resolutions to request 3D Tours.</p>
                                <img style={{marginTop: 30, width: 796, height:385}} src={require("../../../../assets/images/scene_creator_onboarding_8.jpg")}></img>
                                <div style={{height: 70}}></div>
                            </div>

                        </Carousel>
                        
                        
                    </div>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                        <Checkbox
                            style={{fontFamily:"Avenir"}}
                            checked={this.state.checked}
                            onChange={this.onChangeCheckbox}
                        >
                            Don't Show Again
                        </Checkbox>
                        <Button type="primary" style={{fontFamily:"Avenir"}} onClick={() =>this.next()} >Next</Button>
                    </div>
                    <div className="model-cross-button" onClick={() => this.closeOnboarding()}><CloseOutlined /></div>
                </Modal>
            </span>
        );
    }
}

export default withRouter(SceneCreatorOnboarding);