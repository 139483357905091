import { message, Image } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';

import { saveAs } from 'file-saver';

const ImageWithCustomMask = (props) => {

    const [imageVisible, setImageVisible] = useState({});
    const [expandRender, setExpandRender] = useState(false);

    const downloadFile = (url, filename) => {

        let loader = message.loading('Preparing to download..', 0)

        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            if (this.status == 200) {
                setTimeout(loader);

                var myBlob = this.response;
                saveAs(myBlob, filename);

            }
        };
        xhr.send();

    }

    const visibilityChange = (visible, prevVisible) => {
        let temp_arr = {};
        if (expandRender) {
            if (imageVisible[props.image_name] && !visible) {
                temp_arr[props.image_name] = false;
            }
            else if (imageVisible[props.image_name] && visible) {
                temp_arr[props.image_name] = true;
            } else {
                temp_arr[props.image_name] = false;
            }
            setImageVisible(temp_arr)
            setExpandRender(false)
        }
    }

    const expandImage = (name) => {
        let temp_arr = imageVisible;
        if (props.image_name == name) {
            temp_arr[props.image_name] = true;
        } else {
            temp_arr[props.image_name] = false;
        }
        setImageVisible(temp_arr);
        setExpandRender(true);
    }


    return (
        <Image
            style={{ height: props.height }}
            preview={{
                visible: imageVisible[props.image_name],
                onVisibleChange: visibilityChange,
                maskClassName: 'customize-mask',
                mask: (
                    <span>
                        <img
                            className="image-thumbnail"
                            src="/img/expand_thumbnail.png"
                            onClick={() => {
                                expandImage(props.image_name);
                            }}
                            style={{ marginRight: 16 }}
                        />
                        <a onClick={(e) => {
                            e.stopPropagation();
                            let filename = props.image_name.split('.')[0];
                            downloadFile(props.base_url + props.image_name, filename)
                        }}>
                            <img className="image-thumbnail" src="/img/download.png" />
                        </a>
                    </span>
                ),
            }}
            className={props.image_class}
            src={props.base_url + props.image_name.replace('tiff', 'jpg')}
        />

    )
}

export default ImageWithCustomMask;