import React from 'react';
import { connect } from 'react-redux';
import {
  CognitoState,
  Login,
  NewPasswordRequired,
  EmailVerification,
  Confirm,
} from 'react-cognito';
import WrappedNormalLoginForm from '../AuthComponents/LoginFormAnt';
import WrappedNormalLoginFormDummy from '../AuthComponents/LoginFormAntDummy';
import EmailVerificationForm from '../AuthComponents/EmailVerificationForm';
import NewPasswordRequiredForm from '../AuthComponents/NewPasswordRequiredForm';
import ConfirmForm from '../AuthComponents/ConfirmForm';
import UserMenuOptions from '../UserMenuOptions';
import FileConstants from '../../../FileConstants';

class LoggedInPage extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      loader: true
    }
  }

  removeLoader = () => {
    console.log('attemptong to hide loader');
    document.getElementById("all3d-loader").style.display = "none";
  }

  render(){
    
    localStorage.setItem('all3d_jwt_token', this.props.user['signInUserSession']['idToken']['jwtToken']);
    if(!localStorage.getItem('super_admin_username')){
      localStorage.setItem('username', this.props.user ? this.props.user.username : "")
    }
    
    if(this.props.attributes != undefined) {
      console.log(this.props.attributes)
      if (this.props.attributes['custom:company_id'] != undefined) {
        localStorage.setItem('company_id', this.props.attributes['custom:company_id'])
        console.log(this.props.attributes['custom:company_id'])
      } else {
        if(!localStorage.getItem('super_admin_username')){
          // If it is a super msp flow and a user is selected do not delete company id
          localStorage.removeItem('company_id');
        }
      }

      if (this.props.attributes['custom:shared_entities'] != undefined) {
        localStorage.setItem('shared_entities', this.props.attributes['custom:shared_entities'])
        console.log(this.props.attributes['custom:shared_entities'])
      } else {
        if(!localStorage.getItem('super_admin_username')){
          // If it is a super msp flow and a user is selected do not delete shared_entities
          localStorage.removeItem('shared_entities');
        }
      }

    } else {
      if(!localStorage.getItem('super_admin_username')){
        // remove these attributes when it is a normal customer flow and not the super MSP flow.
        localStorage.removeItem('company_id');
        localStorage.removeItem('shared_entities');
      }
    }

    FileConstants.init();
    return (
        <UserMenuOptions removeLoader={this.removeLoader} />
    );
  }
}

const loggedOutPage = () => (
  <div>
    <Login>
      <WrappedNormalLoginForm />
    </Login>
  </div>
);

const loggedOutPageDummy = (registerUsername, registerPassword) => (
  <div>
    <Login>
      <WrappedNormalLoginFormDummy registerUsername={registerUsername} registerPassword={registerPassword}/>
    </Login>
  </div>
);

const newPasswordPage = () => (
  <div>
    <NewPasswordRequired>
      <NewPasswordRequiredForm />
    </NewPasswordRequired>
  </div>
);

const emailVerificationPage = () => (
  <div>
    <EmailVerification>
      <EmailVerificationForm />
    </EmailVerification>
  </div>
);

const confirmForm = (loginUser) => (
  <div>
    <Confirm>
      <ConfirmForm loginUser={loginUser}/>
    </Confirm>
  </div>
);

const mfaPage = () => (
  <div>
    <p>You need to enter an MFA, but this library does not yet support them.</p>
  </div>
);

class BaseDashboard extends React.Component {


  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props.state);
    if(this.props.state == CognitoState.LOGGED_IN || this.props.state == CognitoState.LOGGING_IN){
      document.getElementById("all3d-loader").style.display = "block";
    }
  }

  componentDidUpdate() {
    if(this.props.state == CognitoState.LOGGED_OUT){
      document.getElementById("all3d-loader").style.display = "none";
    }
  }

  render() {
    
    switch (this.props.state) {
      case CognitoState.LOGGED_IN:
        // return loggedInPage(this.props.user, this.state.loader, this.removeLoader );
        return <LoggedInPage user={this.props.user} attributes={this.props.attributes}/>
      case CognitoState.AUTHENTICATED:
      case CognitoState.LOGGING_IN:
        return (
          <div>
            <img src="ajax-loader.gif" alt="" />
          </div>
        )
      case CognitoState.LOGGED_OUT:
      case CognitoState.LOGIN_FAILURE:
        if(this.props.registerUsername != undefined && this.props.registerPassword != undefined){
          return loggedOutPageDummy(this.props.registerUsername, this.props.registerPassword);
        } else {
          return loggedOutPage();
        }
      case CognitoState.MFA_REQUIRED:
        return mfaPage();
      case CognitoState.NEW_PASSWORD_REQUIRED:
        return newPasswordPage();
      case CognitoState.EMAIL_VERIFICATION_REQUIRED:
        return emailVerificationPage();
      case CognitoState.CONFIRMATION_REQUIRED:
        return confirmForm(this.props.loginUser);
      default:
        return (
          <div>
            <p>Unrecognised cognito state</p>
          </div>
        );
    }
  }
};

const mapStateToProps = state => ({
    state: state.cognito.state,
    user: state.cognito.user,
    attributes: state.cognito.attributes,
  });


const Dashboard = connect(mapStateToProps, null)(BaseDashboard);

export default Dashboard;
