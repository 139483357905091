import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash";
import axios from 'axios';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Select, message } from 'antd';
import ENVIRONMENT from '../../../../environments';
import ProductMP4Modal from './ProductMP4Modal';
import ProductDimModal from './ProductDimModal';
import Product360Spin from './Product360Spin';
import OpenVariationModal from './OpenVariationModal';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import { getProductRender } from '../../../../redux/actions';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const { Option } = Select;

const ProductAssetModals = (props) => {
    const [prices, setPrices] = useState([]);
    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [mp4DetailsModal, setMp4DetailsModal] = useState(false);
    const [productData, setProductData] = useState(null);
    const [mp4Price, setMp4Price] = useState(-1);
    const [dimensionalPrice, setDimensionalPrice] = useState(-1);
    const [dimensionalResolution, setDimensionalResolution] = useState('1k');
    const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
    const [statusOfMp4Video, setStatusOfMp4Video] = useState("");
    const [currentProductId, setCurrentProductId] = useState(0);
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [threesixtyDetailsModal, setThreesixtyDetailsModal] = useState(false);
    const [dimensionalDetailsModal, setDimensionalDetailsModal] = useState(false);
    const [customerDefaultDimensionSettings, setCustomerDefaultDimensionSettings] = useState({});
    const [customerDefaultDimDPI, setCustomerDefaultDimDPI] = useState('300');
    const [customerDefaultDimResolution, setCustomerDefaultDimResolution] = useState('2k');
    const [productAssetData, setProductAssetData] = useState(null);
    const [threeSixtyPrice, setThreeSixtyPrice] = useState(-1);
    const [statusOf360Spin, setStatusOf360Spin] = useState("");
    const [statusLoader, setStatusLoader] = useState(true);
    const [res360, setRes360] = useState('2.0');
    const [variationProductData, setVariationProductData] = useState(null);
    const [variationModal, setVariationModal] = useState(false);
    const [variationStep, setVariationStep] = useState(0)
    const [modelWidth, setModelWidth] = useState("N/A");
    const [modelDepth, setModelDepth] = useState("N/A");
    const [modelHeight, setModelHeight] = useState("N/A");
    const [requestSaved, setRequestSaved] = useState(false);
    const [variationType, setVariationType] = useState('color');
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [arVariationType, setARVariationType] = useState('');
    const [savedID, setSavedID] = useState(-1);
    const [variationProductDetails, setVariationProductDetails] = useState({});
    const [variationAssetData, setVariationAssetData] = useState(null);
    const [variantSelected, setVariantSelected] = useState(false);
    const [savedVariationProductDetails, setSavedVariationProductDetails] = useState(null);

    useEffect(() => {
        loadPayments();
        loadUserRequestStatus();
    }, []);

    useEffect(() => {
        if (props.selectedAssetModal && props.productBasicInfo) {
            if (props.selectedAssetModal == 'silo') {
                takeToSiloScreen(props.productBasicInfo.id);
            } else if (props.selectedAssetModal == 'lifestyle') {
                goToAddLifestyle(props.productBasicInfo.id)
            } else if (props.selectedAssetModal == 'mp4') {
                openVideoModal(props.productBasicInfo.id, props.productBasicInfo.company_id, props.productBasicInfo.customer_username);
            } else if (props.selectedAssetModal == 'dim') {
                openDimensionalModal(props.productBasicInfo.id, props.productBasicInfo.company_id, props.productBasicInfo.customer_username);
            } else if (props.selectedAssetModal == '360') {
                openThreeSixtyModal(props.productBasicInfo.id, props.productBasicInfo.company_id, props.productBasicInfo.customer_username);
            } else if (props.selectedAssetModal == 'variant') {
                openVariationModal(props.productBasicInfo.id, props.productBasicInfo.company_id, props.productBasicInfo.customer_username, props.productBasicInfo.folder_id)
            }
        }
    }, [props, props.productBasicInfo, props.selectedAssetModal, props.currentProductId])

    const takeToSiloScreen = (product_id) => {
        window.location.href = "/silo-tool/" + product_id + "?origin=" + props.currentPage;
    }

    const goToAddLifestyle = (product_id) => {
        window.location.href = "/add-lifestyle/" + product_id + "?origin=" + props.currentPage;
    }

    const loadPayments = () => {
        let payload = {
            "request_type": 'product',
        }

        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
            .then(res => {
                if (res.data) {
                    setPrices(res.data);
                }
            })
    }

    const loadUserRequestStatus = (requestType = null) => {
        let payload = {
            "username": localStorage.getItem('username'),
            "action": "check",
            "request_type": "variation_product_model",
            "category": "color"
        }
        if (requestType != null) {
            payload = {
                "username": localStorage.getItem('username'),
                "action": "check",
                "request_type": requestType,
            }
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                console.log('USERCHECK', res)
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
                loadPayments();
            });
    }


    const getDimensionalData = (productAssetData) => {
        let model_height = "N/A", model_depth = "N/A", model_width = "N/A";
        if (productAssetData.model_info) {
            // If model_info has low poly info
            if (productAssetData.model_info.low) {
                model_height = productAssetData.model_info.low.height || "N/A";
                model_depth = productAssetData.model_info.low.depth || "N/A";
                model_width = productAssetData.model_info.low.width || "N/A";
            }
            else if (productAssetData.model_info.high) {
                // if model_info has high poly info
                model_height = productAssetData.model_info.high.height || "N/A";
                model_depth = productAssetData.model_info.high.depth || "N/A";
                model_width = productAssetData.model_info.high.width || "N/A";
            }
            else {
                // for backward compatibility, where model_info only contains model_dimenions and vertex count
                model_height = productAssetData.model_info.height || "N/A";
                model_depth = productAssetData.model_info.depth || "N/A";
                model_width = productAssetData.model_info.width || "N/A";
            }
        } else if (model_depth == 'N/A' && model_height == 'N/A' && model_height == 'N/A' && productAssetData.width && productAssetData.height && productAssetData.length) {
            model_depth = parseInt(productAssetData.length);
            model_height = parseInt(productAssetData.height);
            model_width = parseInt(productAssetData.width);
        }

        setModelDepth(model_depth);
        setModelHeight(model_height);
        setModelWidth(model_width);
    }

    const openVideoModal = (id, company_id, username) => {
        setStatusLoader(true);
        getProductData(id, company_id, username, true, false);
        setCurrentProductId(id.toString())
        let pricesmp4 = [];
        if (prices.length > 0) {
            pricesmp4 = prices.filter((request) => request.request_type == 'product_video' && request.category == "1k");
        }
        if (pricesmp4 && pricesmp4[0]) {
            setMp4Price(parseInt(pricesmp4[0].price));
            setSelectedPriceCategory(pricesmp4[0].category);
        }
        setMp4DetailsModal(true);
    }

    const getProductData = (id, company_id = undefined, username = undefined, asset_required = false, product_data_required = false) => {
        if (product_data_required) {
            getSavedProduct(id)
            let payload = { product_id: id };
            axios.post(ENVIRONMENT.FETCH_PRODUCT, payload)
                .then(res => {
                    setProductData(res.data);
                    if (res.data.variation_type) {
                        setVariationType(res.data.variation_type);
                    }
                })
        }
        else {
            let data = { company_id: company_id };
            setProductData(data);
        }
        if (asset_required)
            loadProductAssets(id, company_id, username);
    }

    const checkIfMp4VideoExists = () => {
        if (productAssetData != null) {
            if (productAssetData.mp4_details != undefined) {
                if (Object.entries(productAssetData.mp4_details).length !== 0) {
                    setStatusOfMp4Video(productAssetData['mp4_details'].asset_status);
                } else {
                    setStatusOfMp4Video('');
                }
            } else {
                setStatusOfMp4Video('');
            }
        }
    }

    const openDimensionalModal = (id, company_id, username) => {
        setStatusLoader(true);
        getCustomerDefaultSettings()
        getProductData(id, company_id, username, false, false);
        setCurrentProductId(id.toString())
        let pricesdim = [];
        if (prices.length > 0) {
            pricesdim = prices.filter((request) => request.request_type == 'product_dimensional' && request.category == dimensionalResolution);
        }
        if (pricesdim && pricesdim[0]) {
            setDimensionalPrice(parseInt(pricesdim[0].price));
            setSelectedPriceCategory(pricesdim[0].category);
        }
        setDimensionalDetailsModal(true);
        setStatusLoader(false);
    }

    const openThreeSixtyModal = (id, company_id, username) => {
        setStatusLoader(true);
        setCurrentProductId(id.toString());
        getProductData(id, company_id, username, true, false);
        let prices360 = [];
        if (prices.length > 0) {
            if (res360 == "2.0") {
                prices360 = prices.filter((request) => request.request_type == 'product_360_spin' && request.category == "2k");
            } else {
                prices360 = prices.filter((request) => request.request_type == 'product_360_spin' && request.category == "4k");
            }
        }
        if (prices360 && prices360[0]) {
            setThreeSixtyPrice(parseInt(prices360[0].price));
            setSelectedPriceCategory(prices360[0].category);
        }
        setThreesixtyDetailsModal(true);
    }

    const checkIf360Exists = () => {
        if (productAssetData && productAssetData.threesixty_spin_details != undefined) {
            if (Object.entries(productAssetData.threesixty_spin_details).length !== 0) {
                setStatusOf360Spin(productAssetData['threesixty_spin_details'].asset_status);
            } else {
                setStatusOf360Spin('');
            }
        } else {
            setStatusOf360Spin('');
        }
    }

    const getCustomerDefaultSettings = () => {
        axios.post(ENVIRONMENT.GET_CUSTOMER_DEFAULT_SETTINGS, { customer_username: localStorage.getItem("username") })
            .then(res => {
                if (res.data.dimensional_settings != undefined) {
                    setCustomerDefaultDimensionSettings(res.data.dimensional_settings);
                    if (res.data.dimensional_settings.dpi != undefined) {
                        setCustomerDefaultDimDPI(res.data.dimensional_settings.dpi);
                    }
                    if (res.data.dimensional_settings.resolution != undefined) {
                        setCustomerDefaultDimResolution(res.data.dimensional_settings.resolution);
                    }
                }
            });
    }

    const loadProductAssets = (product_id, company_id, username) => {
        let payload = { product_id: product_id.toString(), username: username }
        if (company_id != null) {
            payload.company_id = company_id.toString()
        }
        axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
            .then(res => {
                setProductAssetData(res.data);
                getDimensionalData(res.data);
            })
    }

    useEffect(() => {
        checkIfMp4VideoExists();
        checkIf360Exists();
        setStatusLoader(false);

    }, [productAssetData]);

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'save_card') {
                message.success('Payment Method Updated Successfully.');
                loadUserRequestStatus();
            }
        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if (child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    const closeVariationModal = () => {
        setVariationModal(false);
        setVariationStep(0);
        setSavedID(-1);
    }

    const openVariationModal = (id, company_id, username, folder_id) => {
        if (props.accessed_from == 'library') {
            props.setFolderParentID(folder_id);
        }
        setStatusLoader(true);
        getProductData(id, company_id, username, true, true);
        setCurrentProductId(id.toString());
        setVariationModal(true);
    }

    const variationCreated = () => {
        setVariationStep(0);
        setVariationModal(false);
        setRequestSubmitted(true);
        setSavedID(-1);
    }

    const variationSaved = () => {
        setVariationStep(0);
        setVariationModal(false);
        setRequestSaved(true);
        setSavedID(-1);
    }

    const onChangeVariationType = (e) => {
        setVariationType(e.target.value);
        if (e.target.value == 'ar_conversion' && arVariationType == '') {
            setARVariationType('ar');
        }
        if (e.target.value != 'ar_conversion') {
            setARVariationType('');
        }
    }

    const onChangeARVariationType = (e) => {
        setARVariationType(e.target.value)
    }

    const redirectPage = () => {
        window.location.href = '/list-products?entity_id=' + currentProductId + "&origin=" + props.currentPage;;
    }

    const getSavedProduct = (id) => {
        if (savedID == -1) {
            let payload = {
                variant_of: id.toString(),
                model_status: "1"
            }
            axios.post(ENVIRONMENT.GET_PRODUCT_VARIANTS, payload)
                .then(res => {
                    console.log(res.data, 'Saved');
                    if (res.data && res.data[0]) {
                        setSavedID(res.data[0].id);
                        fetchProduct(res.data[0].id);
                    }
                }).catch(err => {
                    console.log(err, 'An error occured in getting variants');
                });
        }
    }

    const fetchProduct = (id) => {

        let product_id = id;
        console.log('Progress Previosuly Saved')

        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                console.log(res.data);
                if (res.data.variation_type) {
                    setVariationType(res.data.variation_type);
                }
                setVariationProductDetails(res.data);
            });
    }


    return (<CustomerProductContext.Provider
        value={{
            product_id: currentProductId,
            mp4DetailsModal: mp4DetailsModal,
            setMp4DetailsModal: setMp4DetailsModal,
            requestStatus: requestStatus,
            cardStatus: cardStatus,
            productData: productData,
            setStatusOfMp4Video: setStatusOfMp4Video,
            statusOfMp4Video: statusOfMp4Video,
            selectedPriceCategory: selectedPriceCategory,
            mp4Price: mp4Price,
            setInAppLimitExceededModal: setInAppLimitExceededModal,
            setPaymentFailureModal: setPaymentFailureModal,
            setCardSaveFlowTriggered: setCardSaveFlowTriggered,
            setPaymentFailureMessage: setPaymentFailureMessage,
            cardSaveFlowTriggered: cardSaveFlowTriggered,
            openDimensionalModal: openDimensionalModal,
            openThreeSixtyModal: openThreeSixtyModal,
            dimensionalDetailsModal: dimensionalDetailsModal,
            setDimensionalDetailsModal: setDimensionalDetailsModal,
            dimensionalPrice: dimensionalPrice,
            openVideoModal: openVideoModal,
            customerDefaultDimensionSettings: customerDefaultDimensionSettings,
            customerDefaultDimDPI: customerDefaultDimDPI,
            customerDefaultDimResolution: customerDefaultDimResolution,
            threesixtyDetailsModal: threesixtyDetailsModal,
            setThreesixtyDetailsModal: setThreesixtyDetailsModal,
            threeSixtyPrice: threeSixtyPrice,
            productAssetData: productAssetData,
            statusOf360Spin: statusOf360Spin,
            setStatusOf360Spin: setStatusOf360Spin,
            statusLoader: statusLoader,
            folderParentID: props.folderParentID,
            requestSaved: requestSaved,
            setRequestSaved: setRequestSaved,
            variationModal: variationModal,
            closeVariationModal: closeVariationModal,
            variationStep: variationStep,
            variationType: variationType,
            setRequestSubmitted: setRequestSubmitted,
            setVariationStep: setVariationStep,
            onChangeVariationType: onChangeVariationType,
            variationSaved: variationSaved,
            variationCreated: variationCreated,
            onChangeARVariationType: onChangeARVariationType,
            arVariationType: arVariationType,
            savedID: savedID,
            variationProductDetails: variationProductDetails,
            requestSubmitted: requestSubmitted,
            setVariationProductData: setVariationProductData,
            setVariationAssetData: setVariationAssetData,
            setVariantSelected: setVariantSelected,
            setSavedVariationProductDetails: setSavedVariationProductDetails
        }}>
        {<ProductMP4Modal />}
        {<ProductDimModal />}
        {<Product360Spin />}
        {(productData || variationProductDetails) &&
            <OpenVariationModal
                product_id={currentProductId}
                folderParentID={props.folderParentID}
                productData={productData}
                modelDepth={modelDepth}
                modelWidth={modelWidth}
                modelHeight={modelHeight}
                requestStatus={requestStatus}
                cardStatus={cardStatus}
                requestSaved={requestSaved}
                setRequestSaved={setRequestSaved}
                variationModal={variationModal}
                closeVariationModal={closeVariationModal}
                variationStep={variationStep}
                variationType={variationType}
                setRequestSubmitted={setRequestSubmitted}
                setVariationStep={setVariationStep}
                onChangeVariationType={onChangeVariationType}
                variationSaved={variationSaved}
                variationCreated={variationCreated}
                onChangeARVariationType={onChangeARVariationType}
                arVariationType={arVariationType}
                savedID={savedID}
                redirectPage={redirectPage}
                variationProductDetails={variationProductDetails}
                requestSubmitted={requestSubmitted}
                statusLoader={statusLoader} />}
        <PaymentFailureModal
            paymentFailureMessage={paymentFailureMessage}
            setPaymentFailureModal={setPaymentFailureModal}
            updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
            paymentFailureModal={paymentFailureModal}
        />
        <InAppPurchaseLimitModal visible={inAppLimitExceededModal} setVisible={setInAppLimitExceededModal} />
    </CustomerProductContext.Provider>)
}

export default ProductAssetModals