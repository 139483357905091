import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader.js";


const style = {
//   height: 420 // we can control scene size by setting container dimensions
  width: '70vw'
};

class ModelPLYViewer extends Component {
  componentDidMount() {
      this.init();
	  setTimeout(() => {document.styleSheets[0].insertRule('canvas { outline:none; border:none; }', 0)}, 3000)
}

  componentWillUnmount() {
  
  }

  componentDidUpdate() {
	while(this.scene.children.length > 0){
		console.log(this.scene.children) 
		this.scene.remove(this.scene.children[0]); 
	}
	this.setupSceneLights();
	console.log('component update called');
	// this.setupScene();
	this.loadAsset(this.props.model_url);
  }

  init = () => {
    // this.sceneContainer 	= document.getElementById('item-viewer');
    this.sceneContainer 	= this.el;
	this.loader 			= this.buildPLYLoader();
	this.raycaster 		= new THREE.Raycaster();

	this.updateScreenProps();
	this.setupScene();
	this.addEventListeners( this.sceneRenderer );
	let url = null;
	if (this.props.model_url != undefined) {
		url = this.props.model_url;
	}
	else {
		url = new URLSearchParams(window.location.search).get("model_url")
	}
	// let url = "https://all3d-reconstruction.s3.amazonaws.com/temp_results/1592848713_AppTest3_971_0.ply";
	console.log("model url: ", url);
	this.loadAsset( url)
	this.animate();
}

buildScene = () => {
	const scene 		= new THREE.Scene();
	scene.background 	= new THREE.Color("#ffffff");
	return scene;
}

buildSceneRenderer = ( width, height ) => {
	const renderer 				= new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
	renderer.gammaOutput 		= true;
	renderer.gammaFactor 		= 2.0;
	renderer.shadowMap.enabled	= true;
	renderer.shadowMap.type 	= THREE.PCFSoftShadowMap;
	renderer.setClearColor( 0x000000 );
	renderer.setPixelRatio( window.devicePixelRatio );
	renderer.setSize( width, height );
	return renderer;
}

buildPLYLoader = () => {

	// Following commented snippet contains inprogress changes to handle loading ply from arhive
	// const manager = new THREE.LoadingManager();
	// manager.onStart = function ( url, itemsLoaded, itemsTotal ) {

	// 	console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
	
	// };
	
	// manager.onLoad = function ( ) {
	
	// 	console.log( 'Loading complete!');
	
	// };
	
	// manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {

	// 	console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
	
	// };
	
	// manager.onError = function ( url ) {
	
	// 	console.log( 'There was an error loading ' + url );
	
	// };

	// manager.setURLModifier( ( url ) => {

	// 	const normalizedURL = decodeURI(url)
    //       .replace('https://all3d-reconstruction.s3.amazonaws.com/temp_results/', '')
    //       .replace(/^(\.?\/)/, '');

    //     if (assetMap.has(normalizedURL)) {
    //       const blob = assetMap.get(normalizedURL);
    //       const blobURL = URL.createObjectURL(blob);
    //       blobURLs.push(blobURL);
    //       return blobURL;
    //     }

	// 	console.log(url)
    //     return url;
	
	// } );

	// const plyLoader = new PLYLoader( manager);
	const plyLoader = new PLYLoader();
    return plyLoader;
}

buildOrbitControls = ( camera, renderer ) => {
	const controls = new OrbitControls( camera, renderer.domElement );
	return controls;
}

buildOrbitCamera = ( width, height ) => {
	const orbitCamera = new THREE.PerspectiveCamera( 45, width / height, 0.25, 20 );
	orbitCamera.position.set( 0, 0, 0 );
	return orbitCamera;
}

addEventListeners = ( renderer ) => {
	window.addEventListener( 'resize', this.onWindowResize, false );
}

loadEnvironment = ( url ) => {
	const format 	= '.jpg';
	const env 		= new THREE.CubeTextureLoader().load( [
		url + 'px' + format, url + 'nx' + format,
		url + 'py' + format, url + 'ny' + format,
		url + 'pz' + format, url + 'nz' + format
	] );
	return env;
}

setupSceneLights = () => {
	var lightGroup 		= new THREE.Group()
	var keyLight 		= new THREE.DirectionalLight( 0xffffff, 1 );
    keyLight.position.set( -7, 5, 5 );
    keyLight.name 	= 'key_light';

    var fillLight = new THREE.DirectionalLight( 0xffffff, 0.6 );
    fillLight.position.set( 5, 3, 5 );
    fillLight.name 	= 'fill_light';

    var backLight = new THREE.DirectionalLight( 0xffffff, 0.6 );
    backLight.position.set( 0, 3, -5 );
    backLight.name 	= 'back_light';

    lightGroup.add( keyLight );
    lightGroup.add( fillLight );
    lightGroup.add( backLight );
    this.scene.add( lightGroup );
}

setupSceneCameras = () => {
	this.orbitCamera = this.buildOrbitCamera( this.sceneWidth, this.sceneHeight );
	this.scene.add( this.orbitCamera );
}

setupControls = () => {
	this.orbitControls 	= this.buildOrbitControls( this.orbitCamera, this.sceneRenderer );
}

setupScene = () => {
	this.scene 			= this.buildScene();
	this.sceneRenderer 	= this.buildSceneRenderer( this.sceneWidth, this.sceneHeight );
	this.envMap			= this.loadEnvironment( '/models/maps/' );

	this.setupSceneLights();
	this.setupSceneCameras();
	this.setupControls();

	this.sceneContainer.appendChild( this.sceneRenderer.domElement );
}

loadAsset = ( assetURL ) => {

	this.loader.load( assetURL ,  ( geometry ) => {
		geometry.computeVertexNormals();
		var material = new THREE.MeshPhongMaterial( { color: 0xffffff, specular: 0x000000, shininess: 0, reflectivity:0,  vertexColors: THREE.VertexColors} );
		var mesh = new THREE.Mesh( geometry, material );

		let object 	= mesh;
	    object.updateMatrixWorld();
	    
	    const box 		= new THREE.Box3().setFromObject( object );
	    const size 		= box.getSize( new THREE.Vector3() ).length();
	    const center 	= box.getCenter( new THREE.Vector3() );

	    this.orbitControls.reset();

	    object.position.x 			+= ( object.position.x - center.x );
	    object.position.y 			+= ( object.position.y - center.y );
	    object.position.z 			+= ( object.position.z - center.z );
	    this.orbitControls.minDistance 	= size/2;
	    this.orbitControls.maxDistance 	= size * 10;
	    this.orbitCamera.near 			= size / 100;
	    this.orbitCamera.far 			= size * 100;

	    this.orbitCamera.updateProjectionMatrix();
	    this.orbitCamera.position.copy( center );

	    this.orbitCamera.position.x -= size;
	    this.orbitCamera.position.y += size / 3.0;
	    this.orbitCamera.position.z += size;

	    this.orbitCamera.lookAt( center );
	    this.orbitControls.saveState();

        this.scene.add( object );
        this.onWindowResize();
		if (this.props.loaderId != undefined) {
			document.getElementById(this.props.loaderId).style.display = 'None';
		}
	} );
}

updateScreenProps = () => {
	this.sceneWidth 		= this.sceneContainer.clientWidth;
	this.sceneHeight 	= this.sceneWidth*0.5625;;
	this.aspect 			= this.sceneWidth / this.sceneHeight;
}

onWindowResize = () => {
	this.updateScreenProps();

	this.sceneRenderer.setSize( this.sceneWidth, this.sceneHeight );

	this.orbitCamera.aspect = this.aspect;
	this.orbitCamera.updateProjectionMatrix();
}

update = () => {

}

render3 = () => {
	this.orbitControls.update();
	this.sceneRenderer.clear();
	this.sceneRenderer.render( this.scene, this.orbitCamera );
}

animate = () => {
	window.requestAnimationFrame( this.animate );
	this.render3();
	this.update();
}

  render() {
    return <div style={{width: (this.props.width != undefined)?this.props.width:'70vw'}} ref={ref => (this.el = ref)} />;
  }
}

class ProductPLYViewer extends React.Component {
  state = { isMounted: true };

  render() {
    const { isMounted = true } = this.state;
    return (
     <ModelPLYViewer/>
    );
  }
}

export default ModelPLYViewer;