import React from 'react';
import { Button, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const DeleteImageModal = (props) => {
    const { deleteModal, setDeleteModal, deleteImageType, deleteLifestyle, deleteSilo
        , deleteLoader } = props
    return (
        <Modal
            className="modal-share-success"
            width={600}
            visible={deleteModal}
            closable={false}
            footer={[
                <span className="justify-in-center">
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay-gray square font-14" onClick={() => setDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => deleteImageType == 'lifestyle' ? deleteLifestyle() : deleteSilo()}>
                        {deleteLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span> Okay </span>}
                    </Button>
                </span>
            ]}
            centered={true}
        >
            <div style={{ margin: "0px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <h2 className="manrope f-16 black-00 w-600">You're about to delete a {deleteImageType.charAt(0).toUpperCase() + deleteImageType.slice(1)} Image</h2>
                    <p className="manrope f-14 black-55">This will remove your {deleteImageType.charAt(0).toUpperCase() + deleteImageType.slice(1)} image from your product. Are you sure you want to delete?</p>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteImageModal;