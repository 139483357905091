import React from "react";
import { SearchOutlined,InfoCircleOutlined } from '@ant-design/icons';
import { Tabs, Input,Modal } from "antd";
import FileConstants from "../../../../FileConstants";
import RoomsTable from '../../RoomsTable';

const { TabPane } = Tabs;

class ArtistRoomsData extends React.Component {
  state = { size: "small", key :1 ,diagram_modal:false};

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  componentDidMount = () => {
    if(document.getElementsByClassName("ant-tabs")[0] != undefined){
      document.getElementsByClassName("ant-tabs")[0].insertBefore(document.getElementsByClassName('description-products')[0], document.getElementsByClassName("ant-tabs")[0].children[1])
    }
  }

  onOpenModal = () => {
    this.setState({
      diagram_modal: true
    })
  }

  render() {
    const { size,key } = this.state;
    return (
      <div className="artist-product-data">
        <span style={{marginBottom:20, display:"flex"}}>
          <span  className="manrope f-24 33">
            Rooms Page
          </span>  
            <div className = 'step-diagram-wrapper'>
              <span className="step-diagram">
                <a className="manrope f-12 w-500" onClick={()=>{this.onOpenModal()}}>View the step-by-step process diagram</a>
              </span>
            </div>
        </span>
        <div style={{marginBottom:16, marginTop:16}} className="description-products">
          <span className="product-artist-description manrope f-12">
            <InfoCircleOutlined/> {FileConstants.DescriptionText[this.state.key]}
          </span>
        </div>
        <Tabs  className='items-table' defaultActiveKey="1" size={size} tabBarStyle={{ textAlign: 'center' }} animated={false}  onTabClick={(e)=> {this.setState({key:e})}}>
          <TabPane tab="Pending" key="1">
            <RoomsTable state="pending" />
          </TabPane>
          <TabPane tab="Rejected" key="2">
            <RoomsTable state="artist_rejected" />
          </TabPane>
          <TabPane tab="Artist Review" key="3">
            <RoomsTable state="artist_review" />
          </TabPane>
          <TabPane tab="Being Processed" key="4">
            <RoomsTable state="being_processed" />
          </TabPane>
          <TabPane tab="Admin Review" key="5">
            <RoomsTable state="admin_review" />
          </TabPane>
          <TabPane tab="Failed & Aborted" key="6">
            <RoomsTable state="artist_failed_aborted"/>
          </TabPane>

        </Tabs>

        <Modal width={800} footer={false} visible={this.state.diagram_modal} onCancel={()=> {this.setState({diagram_modal:false})}}>

          <img style={{width:"100%"}} src="/img/Artist-Diagram.png" />
        </Modal>
      </div>
    );
  }
}

export default ArtistRoomsData;