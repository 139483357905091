import * as THREE from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js'
import { getBaseURL, ASSET_GLB_URI } from "./environments/env.js";

export default class CustomModelViewer {
    sceneContainer 	= null;
    sceneWidth 		= null;
    sceneHeight 	= null;
    aspect			= null;
    frustumSize		= null;
    envMap 			= null;
    scene 			= null;
    sceneRenderer 	= null;
    orbitControls 	= null;
    loader 			= null;
    camera          = null;
    selectedComponent = null;
    materialBackup = null;
    componentPositions = {};
    modelComponents = [];
    customTagPosition = null;
    labelPositionsArray = [];
   
    constructor(productID, setModelComponents, platform = 'aws') {

        this.setModelComponents = setModelComponents;
        this.sceneContainer 	= document.getElementById('material-variation-model-viewer');
        this.frustumSize		= 10;
        this.baseURL        =  getBaseURL(platform) + ASSET_GLB_URI;
        this.loader 		= this.buildGLTFLoader();
        this.scene = this.buildScene();
        this.sceneRenderer = this.buildSceneRenderer( this.sceneWidth, this.sceneHeight );
        this.sceneContainer.appendChild( this.sceneRenderer.domElement );
        this.buildSceneLights();
        this.addEventListeners(this.sceneRenderer);
        
        this.camera = new THREE.PerspectiveCamera( 37.8, this.sceneWidth / this.sceneHeight, 1, 1000 ); // 37.8 vertical fov == 35mm lens focal length
        this.controls = this.buildOrbitControls(this.camera, this.sceneRenderer)
        this.updateScreenProps();
        
        this.loadModel(productID);

        this.pointer = new THREE.Vector2();
		this.raycaster = new THREE.Raycaster();
		

        let animate = () => {
            requestAnimationFrame( animate );
            this.render();
            this.update();
        }

        animate();
    }

    setSelectedComponent(component) {
        this.restoreCurrentComponent();
        this.selectedComponent = component;
        if (component) {
            this.highlightSelectedComponent(component);
        }
    }

    highlightSelectedComponent(component) {
        let currentComponent = this.scene.getObjectByName( component )
        if (currentComponent.isMesh) {
            this.materialBackup = currentComponent.material.clone();
            currentComponent.material = new THREE.MeshStandardMaterial();
            currentComponent.material.color.set("#ff0000");
        }
        else {
            currentComponent.traverse( ( child ) => {
                if ( child.isMesh ) {
                    this.materialBackup = child.material.clone();
                    child.material = new THREE.MeshStandardMaterial();
                    child.material.color.set("#ff0000");
                }
            } );
        }
    }

    restoreCurrentComponent() {
        if (this.selectedComponent) {
            let currentComponent = this.scene.getObjectByName( this.selectedComponent )
            if (currentComponent.isMesh) {
                currentComponent.material = this.materialBackup;
            }
            else {
                currentComponent.traverse( ( child ) => {
                    if ( child.isMesh ) {
                        child.material = this.materialBackup;
                    }
                } );
            }
        }
    }

    buildSceneLights () {

		RectAreaLightUniformsLib.init();
		
		this.keyLight = new THREE.RectAreaLight( 0xffffff, 8, 5, 5 );
		this.fillLight = new THREE.RectAreaLight( 0xffffff, 3, 5, 5 );
		this.rimLight = new THREE.RectAreaLight( 0xffffff, 3, 5, 5 );

		this.keyLight.position.set( -3.75, 2.5, 5.0 );
		this.fillLight.position.set( 5.0, 0.0, 1.25 );
		this.rimLight.position.set( 0.0, 5.0, -1.25 );

		let origin = new THREE.Vector3( 0, 0, 0 );
		this.keyLight.lookAt(origin);
		this.fillLight.lookAt(origin);
		this.rimLight.lookAt(origin);

		this.scene.add( this.keyLight );
		this.scene.add( this.fillLight );
		this.scene.add( this.rimLight );
	}

    loadModel ( id ) {
		this.loader.load( this.baseURL + "low/" + id + ".glb",
			( data ) => this.onModelLoaded( id, data ),
			( xhr ) => {},
			( error ) => {});

    }


    updateScreenProps(){
        let height = this.sceneContainer.clientHeight;
        let width = this.sceneContainer.clientWidth;

        this.sceneWidth = width;
        this.sceneHeight= height;
        this.aspect = this.sceneWidth/this.sceneHeight;
        this.setRendererParam();	
    }
    
    onWindowResize () {
		this.updateScreenProps();
		this.sceneRenderer.domElement.style.margin = "0 auto"
		this.sceneRenderer.domElement.style.display = "flex";
		this.sceneRenderer.domElement.style.flexDirection = "row";
		this.sceneRenderer.domElement.style.justifyContent = "center";
        this.sceneRenderer.domElement.style.alignItems = "center";
        this.updateDivsInScene();
        this.configureCameraAndControls();
    }

	setRendererParam() { 
		this.sceneRenderer.setSize(this.sceneWidth, this.sceneHeight);
		this.camera.aspect = this.aspect;
		this.camera.updateProjectionMatrix();
		this.update();
		this.render();	
		
    }

   
    
    onModelLoaded ( id, data )  {
		if (data) {
			this.model = data.scene;
			this.model.name = id;
	
			this.model.bBox = new THREE.Box3().setFromObject( this.model );
			this.model.size = new THREE.Vector3();
			this.model.bBox.getSize( this.model.size );
	
			this.configureCameraAndControls();
			
            this.scene.add( this.model );
            
            this.getModelComponents(id);

		}
		
	}

    resetZoomingFactors() {
		if (!this.model || !this.model.size) {
			return;
		}
		let length = this.model.size.length();
		this.model.bBox.getCenter( this.controls.target );
		this.controls.minDistance = length * 1.5;
        this.controls.maxDistance = this.controls.minDistance;
		this.camera.near = length / 100.0;
        this.camera.far = length * 100.0;
        this.controls.update();
        this.setCameraAzimuthAngle();
        this.setCameraPolarAngle();
        this.controls.update();
		
    }

    /**
	 * Set up angle for the camera around the model
	 */
	setCameraAzimuthAngle(angle = 0) {
		const spherical = new THREE.Spherical();
		const quat = new THREE.Quaternion().setFromUnitVectors( this.controls.object.up, new THREE.Vector3( 0, 1, 0 ) );
		const quatInverse = quat.clone().inverse();
		const offset = new THREE.Vector3();
		offset.copy( this.camera.position ).sub( this.controls.target );
		offset.applyQuaternion( quat );
		spherical.setFromVector3( offset );
		spherical.theta = (-angle*Math.PI/180);
		spherical.makeSafe();
		offset.setFromSpherical( spherical );
		offset.applyQuaternion( quatInverse );
		this.camera.position.copy( this.controls.target ).add( offset );
	}

    /**
	 * Set up angle for the camera around the model
	 */
	setCameraPolarAngle(angle = 90) {
		const spherical = new THREE.Spherical();
		const quat = new THREE.Quaternion().setFromUnitVectors( this.controls.object.up, new THREE.Vector3( 0, 1, 0 ) );
		const quatInverse = quat.clone().inverse();
		const offset = new THREE.Vector3();
		offset.copy( this.camera.position ).sub( this.controls.target );
		offset.applyQuaternion( quat );
		spherical.setFromVector3( offset );
		spherical.phi = (angle*Math.PI/180);
		spherical.makeSafe();
		offset.setFromSpherical( spherical );
		offset.applyQuaternion( quatInverse );
        this.camera.position.copy( this.controls.target ).add( offset );
	}

    get2DScreenPosition(position) {
        var targetPosition = position.clone();
        var vector = targetPosition.project(this.camera);

        vector.x = (vector.x + 1) / 2 * this.sceneWidth;
        vector.y = -(vector.y - 1) / 2 * this.sceneHeight;

        return vector;
    }


    updateDivsInScene() {
        for(let componentName of this.modelComponents) {
            let div = document.getElementById(componentName);
            let targetPosition = this.componentPositions[componentName]
            let vector = this.get2DScreenPosition(targetPosition);
            if (div!=null) {
                div.style.left = vector.x + 'px';
                div.style.top = vector.y + 'px';
            }
        }
    }
    
    getOffsetPosition(currPos) {
        let newPos = currPos.clone();
        for (let existingLabelPos of this.labelPositionsArray ) {
            let distance = existingLabelPos.distanceTo(newPos)
            if (distance <= 0.1) {
                newPos.y += 0.1;
                newPos.x += 0.1;
            }
        }
        return newPos;
    }

    findRootNode(id) {
        let rootNode = null;
        this.model.traverse( ( child ) => {
            if (child.name == id) 
            {
                rootNode = child
            }
        })
        return rootNode;
    }

    getModelComponents(id) {

        let scope = this;
        this.modelComponents = [];
        let rootNode = this.findRootNode(id);
        if (rootNode) {
            for (let child of rootNode.children) {
                scope.modelComponents.push(child.name);
                if (child.isMesh) {
                    child.geometry = child.geometry.toNonIndexed();
                }
                let bbox = new THREE.Box3().setFromObject(child);
                let center = bbox.getCenter(new THREE.Vector3());
                let newPos = scope.getOffsetPosition(center.clone());
                scope.labelPositionsArray.push(newPos);
                scope.componentPositions[child.name] = newPos
            }
            scope.setModelComponents(scope.modelComponents, scope.modelChildComponents)
        }
    }


    configureCameraAndControls () {
		if (!this.model || !this.model.size) {
			return;
		}
		this.resetZoomingFactors();
		let vFov = this.camera.fov * ( Math.PI / 180 );
		let hFov = 2 * Math.atan ( ( this.sceneWidth / this.sceneHeight ) * Math.tan( vFov / 2 ) );
		let hFactor = 1.1 + ( hFov / 100.0 );
		let vFactor = 1.2 + ( vFov / 100.0 );
		let hFovLen = Math.abs ( this.model.size.x * hFactor );
		let vFovLen = Math.abs ( this.model.size.y * vFactor );
		let halfDepth = Math.abs( this.model.size.z / 2.0) ;
		
		let hDolly = hFovLen / Math.tan( hFov );
		hDolly *= ( hDolly / ( hDolly - halfDepth ) );
		let vDolly = vFovLen / Math.tan( vFov );
		vDolly *= ( vDolly / ( vDolly - halfDepth ) );

		if ( hDolly > vDolly )
		{
            this.camera.position.set( this.camera.position.x, this.camera.position.y, hDolly );
		}
		else
		{
            this.camera.position.set( this.camera.position.x, this.camera.position.y, vDolly);
		}
		
	}
    
    buildScene() {
        const scene 		= new THREE.Scene();
        // scene.background 	= new THREE.Color("#989898");
        return scene;
    }

    buildSceneRenderer( width, height ) {
        const renderer 				= new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true, alpha: true } );
        renderer.gammaOutput 		= true;
        renderer.gammaFactor 		= 2.2;
        renderer.shadowMap.enabled	= true;
        renderer.shadowMap.type 	= THREE.PCFSoftShadowMap;
        renderer.setClearColor( 0x000000, 0.0 );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        return renderer;
    }

    buildGLTFLoader() {
        const gltfLoader 	= new GLTFLoader();
        const dracoLoader 	= new DRACOLoader();
        dracoLoader.setDecoderPath( '/js/libs/draco/gltf/' );
        gltfLoader.setDRACOLoader( dracoLoader );
        return gltfLoader;
    }

    buildOrbitControls( camera, renderer ) {
        const controls = new OrbitControls( camera, renderer.domElement );
        return controls;
    }

    /**
	 * Get Snapshot buffer info for capturing screenshot
	 */
	getSnapshotBuffer() {
		let strMime = "image/jpeg";
		let imgData = this.sceneContainer.toDataURL( strMime );
		return imgData;
	}

	/**
	 * 
	 * @param {String} strData - The snapshot buffer data
	 * @param {String} filename - The designated name for the file being saved
	 */
	saveFile (strData, filename) {
        let link = document.createElement( 'a' );
        if ( typeof link.download === 'string' ) {
            document.body.appendChild( link );
            link.download = filename;
            link.href = strData;
            link.click();
            document.body.removeChild( link );
        } 
        // else {
        //     location.replace( uri );
        // }
    }

	/**
	 * Get screen snapshot of the renderer
	 * @param {String} imgName - The image angle name
	 */
	saveScreenshot(imgName) {
		this.update()
		this.render()
        let imgData = this.getSnapshotBuffer();
        try {
            let strMime = "image/jpeg";
            let strDownloadMime = "image/octet-stream";
            this.saveFile( imgData.replace( strMime, strDownloadMime ), imgName + ".jpg" );

        } catch (e) {
            console.log(e);
            return;
        }

    }
    
    // set manual panning and its constrains
	onPointerMove = ( event ) => {
		const bbox = event.target.getBoundingClientRect();
		const layerX = event.clientX - bbox.x;
		const layerY = event.clientY - bbox.y;
		this.updatePointerPosition( layerX, layerY );
    }
    
    updatePointerPosition ( offsetX, offsetY ) {

    	// calculate pointer position in normalized device coordinates
        // (-1 to +1) for both components
        this.pointer.x = ( offsetX / this.sceneWidth ) * 2 - 1;
        this.pointer.y = - ( offsetY / this.sceneHeight ) * 2 + 1;

	}
	

    addEventListeners( renderer ) {
        window.addEventListener( 'resize', ()=> {
            this.onWindowResize()
        }, false );
        renderer.domElement.addEventListener( 'mousemove', this.onPointerMove, false );

    }

    loadEnvironment( url ) {
        const format 	= '.jpg';
        const env 		= new THREE.CubeTextureLoader().load( [
            url + 'px' + format, url + 'nx' + format,
            url + 'py' + format, url + 'ny' + format,
            url + 'pz' + format, url + 'nz' + format
        ] );
        return env;
    }

    setupSceneLights() {
        let ambientLight = new THREE.AmbientLight( 0xffffff );
        ambientLight.intensity 	= 0.5;
        this.scene.add( ambientLight );
    }

    render() {
        this.updateDivsInScene();
        this.sceneRenderer.render( this.scene, this.camera );
    }

    update() {

        this.controls.update();
    }
}



