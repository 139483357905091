import React from "react";
import AdminMainLayout from '../AdminMainLayout';
import ArtistMonthlyHoursTable from '../ArtistMonthlyHoursTable';

class ArtistMonthlyHours extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"8"}>
        <br />
        <ArtistMonthlyHoursTable artist={this.props.match.params.artist}/>
      </AdminMainLayout>
    );
  }
}

export default ArtistMonthlyHours;