import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Button, Card, message } from 'antd';
import axios from 'axios';
import PanoramaViewer from '../PanoramaViewer';
import ENVIRONMENT from '../../../../environments'
import FileConstants from '../../../../FileConstants';

class RoomSelection extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            rooms_data: [],
            my_rooms: [],
            my_collection_rooms: [],
            isLoading: false
        };
    }

    componentDidMount() {
        this.setState({isLoading: true})
        axios.post(ENVIRONMENT.COLLECTION_GET_ROOMS, {
            collection_id: this.props.getCollectionId().toString()

        })
            .then( (response) => {
                this.setState({
                    my_collection_rooms: response.data, isLoading: false
                });
            })
            .catch((error) => {
            });
        
    }

    createScene = (payload) => {
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then( (res) => {
                message.info('Scene created successfully');
                var scene_id = res.data["scene_id"];
                window.location.href = '/scene_builder/' + scene_id;
            })
            .catch((error) => {
                message.error('Error in creating scene');
            });
    }

    onRoomSelect = (e) => {
        let payload = {
            "scene_name": this.props.getSceneName(),
            "collection_id": this.props.getCollectionId(),
            "room_id": e.target.id,
            "username": localStorage.getItem('username')
        };
        let company_id = localStorage.getItem('company_id');
        if (company_id != undefined) {
            payload.company_id = company_id;
        }
        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null || localStorage.getItem('managed_company_id') != null)) {
            payload.designed_for = localStorage.getItem('managed_customer_username');
        }
        this.createScene(payload);
    }

    render() {
        return (
            <div>
            <h4 style={{marginLeft: 10}}><b>Select Room</b></h4>
            <h5 style={{marginLeft: 10}}>Looks like you have multiple rooms in your collection, please select one to create scene with</h5>
                
            <Row className="product-tab-content-scrollable" type="flex"  style={{marginTop:"10px", justifyContent: "start"}}>
                
                {this.state.isLoading ? <div style={{textAlign: "center"}}> <LoadingOutlined style={{textAlign: "center", fontSize:"20px"}} size="large" spin /> </div>  : this.state.my_collection_rooms.map((ele, index) => (
                    <Col key={index} span={12} style={{padding: "10px"}}>
                        <Card>
                            <span style={{padding: "5px"}}>Room Name: {ele.room_name}</span>
                            <PanoramaViewer id={'360Camera'} threeSixtyUrl={ENVIRONMENT.getBaseURL(ele.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + ele.room_id + '/360Camera.jpg' } />
                            <Button style={{padding: "5px", marginTop: "10px"}} type="danger" id={ele.room_id} onClick={this.onRoomSelect}>Select this room</Button>
                        </Card>
                    </Col>
                ))}
            </Row>
            </div>
        );
    }
}

export default RoomSelection;