import * as THREE from "three"
import {isConvexHull} from "../../../HelperFunctions"
export default class MatrixGrid {

    baseObj = null;
    inverseBaseObj = null;
    planebBox = null;
    planeSize = null;
    intersectableObjects = null;
    raycaster = null;
    gridCols = 0;
    gridRows = 0;
    scene = null;
    markerPosition = 0;
    unitConversion = 5;
    gridMatrix = {};
    occupiedGrids = {};
    traversedGrids = [];
    planeHeight = 0;
    directionVector = new THREE.Vector3(0,-1,0);
    defaultGrid = true;

    constructor(baseObj, raycaster, scene, inverseBaseObj, defaultGrid = true) {
        this.baseObj = baseObj;
        this.inverseBaseObj = inverseBaseObj;
        this.raycaster = raycaster;
        this.scene = scene;
        this.defaultGrid = defaultGrid;
        this.setupPlane();
        this.setupGrid();
    }

    setupPlane() {
        this.planebBox = new THREE.Box3();
        this.planebBox = new THREE.Box3().setFromObject(this.baseObj);
        this.planeSize = this.planebBox.getSize(new THREE.Vector3());
        this.markerPosition = this.planebBox.min.clone();
        this.planeHeight = this.planebBox.max.y;
        if (this.defaultGrid == false) {
            this.directionVector = new THREE.Vector3(0,1,0);
            this.planeHeight = this.planebBox.min.y;
        }   
    }

    setupGrid() {
        this.gridCols = Math.ceil(this.planeSize.x * this.unitConversion );
        this.gridRows = Math.ceil(this.planeSize.z * this.unitConversion);
        let rowCol = this.getRowCol(0);
        this.gridMatrix[0] = {"row": this.gridRows - rowCol[0], "col": this.gridCols - rowCol[1]};
    }

    placeOnGrid(object) {
        let objectbBox = new THREE.Box3().setFromObject(object);
        let objectSize = objectbBox.getSize(new THREE.Vector3());
        let cols = (Math.ceil((objectSize.x + 0.05) * this.unitConversion)) ;
        let rows = (Math.ceil((objectSize.z + 0.05) * this.unitConversion)) ;
        this.traversedGrids = [];
        let isPlaced = this.getGrid(cols, rows, object, objectSize);
        return isPlaced;
    }

    getGrid(cols, rows, object, objectSize) {
        for (const [key, value] of Object.entries(this.gridMatrix)) {
            if (!this.traversedGrids.includes(parseInt(key))) {
                let gridRow = value["row"];
                let gridCol = value["col"];
                let currentGrid = parseInt(key);
                this.traversedGrids.push(currentGrid);
                if (gridRow >= rows && gridCol >= cols) {
                    let isPlaced = this.findGrid(object, currentGrid, gridRow, gridCol, rows, cols, objectSize);
                    if (isPlaced) {
                        return isPlaced;
                    }
                }
            }
        }
        return false;
    }

    findGrid(object, gridNumber, parentRow, parentCol, rows, cols, objectSize) {
        const gridNum = gridNumber;
        let currentGrid = gridNum;
        for (let i = 0; i < parentRow; i++) {
            for (let j = 0; j < parentCol; j++) {
                let rowSize = parentRow - i;
                let colSize = parentCol - j;
                if (rowSize >= rows && colSize >= cols) {
                    currentGrid = gridNumber + (i * this.gridCols) + j;
                    let markerPosition = this.updateMarkerPosition(currentGrid);
                    object.position.copy(markerPosition.clone());
                    object.position.x += objectSize.x/2;
                    object.position.z += objectSize.z/2;
                    object.updateMatrixWorld();
                    if (this.intersectsPlane(object)) {
                        let matrix1_grid = gridNum;
                        let matrix1_row = i;
                        let matrix1_col = j + cols ;
                        if (matrix1_row > 0 && matrix1_col > 0) {
                            this.gridMatrix[matrix1_grid] = {"row" :matrix1_row, "col": matrix1_col};
                        }
                        let matrix2_grid = gridNum + i * this.gridCols; 
                        let matrix2_row = rows;
                        let matrix2_col = j;
                        if (matrix2_row > 0 && matrix2_col > 0) {
                            this.gridMatrix[matrix2_grid] = {"row" : matrix2_row, "col": matrix2_col};
                        }
                        this.divideGrid(gridNum, matrix1_row + rows,  matrix1_col,  parentRow, parentCol);
                        this.occupiedGrids[currentGrid] = {"row" :rows, "col": cols};
                        delete this.gridMatrix[currentGrid];
                        return true;
                    }
                }
            }
        }
        return false;
    }

    divideGrid(gridNumber, subRow, subCol, gridRow, gridCol) {
        let subMatrix1_row = subRow;
        let subMatrix1_col = gridCol - subCol;
        let subMatrix2_row = gridRow - subRow;
        let subMatrix2_col = gridCol;
        let gridNumber1 = 0;
        let gridNumber2 = 0;
        if (subMatrix1_row > 0 && subMatrix1_col > 0) {
            gridNumber1 = gridNumber + subCol;
            this.gridMatrix[gridNumber1] = {"row" : subMatrix1_row, "col": subMatrix1_col};
        }
        if(subMatrix2_row > 0 && subMatrix2_col > 0) {
            gridNumber2 = gridNumber + (this.gridCols * subRow);
            this.gridMatrix[gridNumber2] = {"row" : subMatrix2_row, "col": subMatrix2_col};
        } 
    }

    intersectsPlane(object) { 
        let raycastResult = null;
        let pvb = object.userData.pvb;
        let cornersList = pvb.getCorners();
        let corners = [cornersList.c1.clone(), cornersList.c4.clone() ];
        corners[0].y += pvb.halfHeight/20;
        corners[1].y += pvb.halfHeight/20;

        for ( let j = 0 ; j < corners.length; j++ ) {
            let originVector = corners[j].clone();
            raycastResult  = this.raycaster.setAndIntersect(originVector, this.directionVector, [this.baseObj]);
            if (raycastResult == false) {
                this.raycaster.resetFarValue();
                return false;
            }
            else  {
                object.position.y = raycastResult.point.y;
                object.updateMatrixWorld();
            }
        }

        if (this.inverseBaseObj) {
            for ( let j = 0 ; j < corners.length; j++ ) {
                let originVector = corners[j].clone();
                raycastResult  = this.raycaster.setAndIntersect(originVector, this.directionVector.clone().negate(), [this.inverseBaseObj]);
                if (raycastResult == false) {
                    this.raycaster.resetFarValue();
                    return false;
                }
            }
        }
        
        if (this.intersectableObjects != null) {
            corners.push(cornersList.c1.clone());
            corners[2].x += pvb.halfWidth;
            corners[2].z += pvb.halfDepth;
            corners[2].y += pvb.halfHeight/20;
            for ( let j = 0 ; j < corners.length; j++ ) {
                let originVector = corners[j].clone();
                raycastResult  = this.raycaster.setAndIntersect(originVector, new THREE.Vector3(0,1,0), this.intersectableObjects);
                if (raycastResult != false) {
                    this.raycaster.resetFarValue();
                    return false;
                }
            }
        }

        if (this.intersectableObjects != null) {
            corners = [cornersList.c1.clone(),cornersList.c2.clone(),cornersList.c3.clone(),cornersList.c4.clone() ,cornersList.c5.clone(),cornersList.c6.clone(),cornersList.c7.clone(),cornersList.c8.clone() ];
            corners[0].y += pvb.halfHeight/20;
            corners[1].y += pvb.halfHeight/20;
            corners[2].y += pvb.halfHeight/20;
            corners[3].y += pvb.halfHeight/20;
            for ( let j = 0 ; j < corners.length; j++ ) {
                for ( let i = j + 1; i < corners.length; i++ ) {
                    let directionVector = new THREE.Vector3().subVectors(corners[j], corners[i]).normalize().negate();
                    let lineOrigin = corners[j].clone();
                    let raycastLength = corners[j].distanceTo(corners[i]);
                    this.raycaster.updateRaycasterProperties(lineOrigin, directionVector, raycastLength);
                    let results = this.raycaster.setAndIntersect( lineOrigin, directionVector, this.intersectableObjects );
                    if (results != false) {
                        this.raycaster.resetFarValue();
                        return false;
                    }
                    
                }
            }

        }
        this.raycaster.resetFarValue();
        return true;
    }
    
    updateMarkerPosition(currentGrid) {
        let markerPosition = new THREE.Vector3();
        let xzIndices = this.getRowCol(currentGrid);
        markerPosition.x = this.markerPosition.x + (xzIndices[1] / (this.unitConversion)) ;
        markerPosition.z = this.markerPosition.z + (xzIndices[0] / (this.unitConversion)) ;
        markerPosition.y = this.planeHeight;
        return markerPosition;
    }

    getRowCol(gridNumber) {
        let col = (gridNumber % this.gridCols);
        let row = Math.floor(gridNumber / this.gridCols);
        return [row, col];
    }

    setIntersectableObjects(intersectableObjects) {
        this.intersectableObjects = []
        intersectableObjects.forEach(item => {
            if (!isConvexHull(item)) {
                this.intersectableObjects.push(item);
            }
        })
    }
}