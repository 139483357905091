/* eslint-disable linebreak-style */
import React from 'react';
import ENVIRONMENT from '../../../../environments'
import axios from 'axios';
import {Card,Menu, Dropdown, Button, Table} from 'antd';
import { CheckOutlined,DownOutlined} from '@ant-design/icons';

import '../../../../styles/helper.scss'

const COLUMNS = [
  {
    title: "Resolution",
    dataIndex: "resolution",
    key: "resolution"
  },
  {
    title: "Minimum Cost",
    dataIndex: "cost",
    key: "cost",
    sorter: (a, b) => parseFloat((a.cost).replace("$","")) - parseFloat((b.cost).replace("$",""))
  },
  {
    title: "Frequency",
    dataIndex: "count",
    key: "count",
    sorter: (a, b) => a.count - b.count
  }
]



class CostBreakdown extends React.Component {
  state = {
    loading : true,
    costData: "",
    month : 2
  }

  getCostData = (payload) => {
    axios.post(ENVIRONMENT.GET_COST_AUTOMATION_REQUEST,payload)
      .then(res => {
        this.setState({
          costData : res.data,
          loading : false
        })
      })
  }

  getSiloData = () => {
    let siloData = []
    for (let key in this.state.costData){
      if (this.state.costData[key].unit_cost_type == 'silo_render'){
        siloData.push(this.state.costData[key])
      }
    }
    return siloData;
  }

  getLifestyleData= () => {
    let lifestyleData = []
    for (let key in this.state.costData){
      if (this.state.costData[key].unit_cost_type == 'scene_lifestyle_render'){
        lifestyleData.push(this.state.costData[key])
      }
    }
    return lifestyleData;
  }

  componentDidMount = () => {
    let payload = {"batch_get" : "true"}
    this.getCostData(payload)
  }

  getDataSource= (costData) => {
    let dataSource = [];
    costData.map((value,index)=> {
      let dataSourceObject = {
        "key":  index+1,
        "resolution": `${value.render_width} * ${value.render_height}`,
        "cost" : `${(value.cost/value.count).toFixed(2)}$`,
        "count" : value.count
      }
      dataSource.push(dataSourceObject)
    })
    return dataSource
  }

  handleButtonClick = (e) => {
    let payload = {"batch_get" : "true","days":parseInt(e.key)}
      this.setState({
        month:parseInt(e.key)/30,
        loading: true
      })
      this.getCostData(payload)
  }

  render() {
    const lifestyleData = this.getLifestyleData();
    const siloData = this.getSiloData();
    const menu = (
      <Menu   onClick={this.handleButtonClick}>
        <Menu.Item key="30" icon={this.state.month == 1? <CheckOutlined />: ''}>
          1 Month
        </Menu.Item>
        <Menu.Item key="60" icon={this.state.month == 2? <CheckOutlined />: ''}>
          2 Months
        </Menu.Item>
        <Menu.Item key="90" icon={this.state.month == 3? <CheckOutlined />: ''}>
          3 Months
        </Menu.Item>
      </Menu>
    );
    return (
      <div>
        <Dropdown overlay={menu}>
          <Button>
            {this.state.month} { this.state.month == 1 ? 'Month':'Months' } <DownOutlined />
          </Button>
        </Dropdown>
      
        <div className='cost-container' >
          <div className='cost-lifestyle' >
          <Card loading={this.state.loading} style={{marginBottom:"2%"}} active>
            <Card title="Lifestyle Renders" >
              <div>
                <p style={{marginTop:"2%"}}>
                  <Table  dataSource={this.getDataSource(lifestyleData)} columns={COLUMNS} pagination={{position:['bottomCenter']}}/>
                </p>  
              </div>
            </Card>
          </Card>
          </div>

          <div className='cost-silo' >
          <Card loading={this.state.loading}  style={{marginBottom:"2%"}} active>
            <Card title="Silo Renders" >
              <div>
                <p style={{marginTop:"2%"}}>
                  <Table style={{margin:"0.5%"}}  dataSource={this.getDataSource(siloData)} columns={COLUMNS} pagination={{position:['bottomCenter']}} />
                </p>  
              </div>
            </Card>
          </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default CostBreakdown;