import React from 'react';
import { Modal, DatePicker, Col, Button } from 'antd';
import moment from 'moment';
import ENVIRONMENT from '../../../../environments';
import { LoadingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const DATE_FORMAT = 'MM/DD/YYYY';

const InvoiceDatePicker = (props) => {

    return (
        <Modal
        className="modal-share"
        width={700}
        closable={true}
        maskClosable={false}
        onCancel={props.onCancel}
        visible={props.visible}
        bodyStyle={{padding: 0}}
        footer={[<div className="justify-in-end">
        <Button className="modal-okay font-14 square" onClick={() => props.exportData()}>
          {props.single_invoice ? 'Download Invoice' : 'Download Invoices'}
          {props.single_invoice && props.loader ? <LoadingOutlined/> : ''}
        </Button>
      </div>]}>
        <div style={{padding:"40px 30px 0 30px"}}>
          <h2 className="manrope f-18 black-00 w-600">Pick A Date Range</h2>
          <p className="manrope f-14 black-55">By default, start and end date for previous month are selected. You can find more guidelines <a target='_blank' href={ENVIRONMENT.CUSTOMER_INVOICE_RULES}>here</a>.</p>
          <Col>
          <RangePicker size={"large"} defaultValue={[moment(props.startDate, DATE_FORMAT), moment(props.endDate, DATE_FORMAT)]}
          format={DATE_FORMAT} className="date-picker" popupStyle={{ fontFamily: 'Manrope', fontSize: 14, color: "#40a9ff", background: "#e6efff" }} placeholder={['Date (From)', 'Date (To)']}
          value={[moment(props.startDate, DATE_FORMAT), moment(props.endDate, DATE_FORMAT)]}
          onCalendarChange={val => props.onCalenderChange(val)}
           />
          </Col>
        </div>    
    </Modal>
      );
}

export default InvoiceDatePicker;