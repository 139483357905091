import React from 'react';
import PropTypes from 'prop-types';
import {changePassword, updateAttributes} from 'react-cognito';
import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Layout, Row, Col, Input, Checkbox, Button } from "antd";
import CustomHeader from "../../CustomHeader";
const { Header } = Layout;

class UpdateEmailForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      email: '',
    };
  }

  componentDidMount = () => {
    const { store } = this.context;
    const state = store.getState();
    this.setState({ email: state.cognito.attributes.email });
    const {setFieldsValue } = this.props.form;
    setFieldsValue({email: state.cognito.attributes.email});
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { store } = this.context;
        const state = store.getState();
        const user = state.cognito.user;
        const config = state.cognito.config;
        event.preventDefault();
        updateAttributes(user, {
          email: this.state.email,
        }, config).then(
            (action) => {
              store.dispatch(action);
              this.setState({ error: 'email changed' });
            },
            error => this.setState({ error }),
        );
      }
    });
  }

  changeEmail = (event) => {
    this.setState({ email: event.target.value });
  }

  render = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Header className="header">
          <CustomHeader></CustomHeader>
        </Header>
        <Row type="flex" justify="space-around">
          <Col span={8}>
            <Form onSubmit={this.onSubmit} className="login-form input-form" style={{marginTop: 32}}>
              <h3 className="text-center text-login-logo"> <span>Change Email</span> </h3>
              <Form.Item name="email">
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your email!' }],
                })(
                    <Input
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="email"
                        placeholder="Email"
                        onChange={this.changeEmail}
                    />,
                )}
              </Form.Item>
              <Form.Item>
                <Button type="danger" htmlType="submit" className="login-form-button">
                  Update
                </Button>
                <span className="text-danger"> {this.state.error}</span>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
UpdateEmailForm.contextTypes = {
  store: PropTypes.object,
};

export default  Form.create({ name: 'update_email_form' })(UpdateEmailForm);
