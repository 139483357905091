
import * as THREE from 'three'
import {getRootNodeFromObject, getNormalForIntersect, isConvexHull, degrees_to_radians} from "../../HelperFunctions.js"
import Constants from '../../Constants.js'
import { PVB } from '../../../PVB.js';
import { PolyhedronBufferGeometry } from 'three';
import { RotationControls } from '../../../RotationControls.js';

export default class PillowPlacementManager {

    sceneCreator = null;
    sceneAssets = null;

    spaceManager = null;
    raycastManager = null;
    debugEngine = null;
    LFAAreaManager = null;

    /**
     * Pillow asset which is currently being placed.
     */
    currentPillowObject = null;
    
    /**
     * Base asset on which the pillow is being placed (if any).
     */
    currentBaseObject = null;

    // Normals for each side last time when the distance threshold was hit.
    lastValidNormalForRightSide = new THREE.Vector3();
    lastValidNormalForLeftSide = new THREE.Vector3();
    lastValidNormalForBackSide = new THREE.Vector3();
    // Intersection points for each side last time when the distance threshold was hit.
    lastValidIntersectionPointForRightSide = new THREE.Vector3();
    lastValidIntersectionPointForLeftSide = new THREE.Vector3();
    lastValidIntersectionPointForBackSide = new THREE.Vector3();

    // Fixed normal for base side
    normalForBase = new THREE.Vector3(0, 0.75, 0);

    /**
     * Vector that holds a copy of the current position of the selected pillow.
     */
    currentPositionOfSelectedPillow = new THREE.Vector3();

    /**
     * Vector that holds a copy of the position of the selected pillow at the time when distance threshold was hit.
     */
    lastPositionOfSelectedPillow = new THREE.Vector3(1000, 1000, 1000);

    /**
     * Vector that holds a copy of the last rotation of the selected pillow on the asset.
     */
    lastRotationOfSelectedPillow = new THREE.Quaternion();

    /**
     * Pillow placement manager is used for auto orientation and placement of pillows relative to their surroundings (when snapping is enabled).
     * And to check wether the current position is valid or not.
     * Review the pillow placement section of the doc to get complete understanding of the algorithm (https://docs.google.com/document/d/1R3L1E9HNm_wOM5zgNpQPJKv5zKjgG7Es-Fz2lO_oJkY/edit?usp=sharing)
     * @param {SceneCreator} sceneCreator - The main scene creator instance.
     * @param {Array} sceneAssets - An array that contains references to all the assets currently in the scene.
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    constructor(sceneCreator, sceneAssets, managersDict) {
        this.sceneCreator = sceneCreator;
        this.sceneAssets = sceneAssets;

        this.setupRequiredManagers(managersDict);
    }

    /**
     * Setup the managers required by this module to work.
     */
    setupRequiredManagers(managersDict) {
        this.spaceManager = managersDict[Constants.Manager.SpaceManager];
        this.raycastManager = managersDict[Constants.Manager.RaycastManager];
        this.debugEngine = managersDict[Constants.Manager.DebugEngine];
        this.LFAAreaManager = managersDict[Constants.Manager.LostAndFoundAreaManager];
    }

    /**
     * Set the base object i.e. the object pillow is currently stacked on.
     */
    setBaseObject(object) {
        // preserve the world quaternion rotation of pillow before moving from asset to scene
        if (object == null && this.currentPillowObject != null) {
            this.lastRotationOfSelectedPillow.copy(this.currentPillowObject.getWorldQuaternion(new THREE.Quaternion()));
        }
        this.currentBaseObject = object;
    }

    /**
     * Set the current position of selected pillow in the position holder variable used by pillow placement algorithm.
     */
    setCurrentPositionHolder(newPosition) {
        this.currentPositionOfSelectedPillow.copy(newPosition);
    }

    /**
     * Get averged normal & intersection point for an array of intersects.
     * @param {Array} intersects - Array of intersects for which averged info is required.
     * @param {Boolean} isBase - Flag to indicate wether the intersects are for the base side.
     */
    getAveragedInfoFromIntersects(intersects, isBase = false) {
        let finalPoint = new THREE.Vector3();
        let finalNormal = new THREE.Vector3();

        for (let index = 0; index < intersects.length; index++) {
            const element = intersects[index];

            this.debugEngine.debugLog(element.object.name);

            let worldNormal, localNormal;
            let quaternion = new THREE.Quaternion();
            localNormal = getNormalForIntersect(element, this.LFAAreaManager);
            let obj = getRootNodeFromObject(this.sceneCreator.scene, element.object);
            if(obj != null && obj != undefined) {
                obj.getWorldQuaternion(quaternion);
                localNormal.applyQuaternion(quaternion);
            }

            worldNormal = localNormal;

            this.debugEngine.debugLog(index, worldNormal)

            // Cap Y values according to intersect side
            if(!isBase) {
                worldNormal.y = 0;
            }
            else {
                worldNormal.set(0,0,0);
                worldNormal.y = 1;
            }

            this.debugEngine.debugLog(index, worldNormal)

            finalNormal.add(worldNormal);
            finalPoint.add(element.point);
        }
        finalNormal.divideScalar(intersects.length);
        finalPoint.divideScalar(intersects.length);

        return [finalNormal, finalPoint];
    }

    /**
     * Get size to distance percentage (for a given dimension) for the intersected point. 
     * The size to distance percentage indicates how far (1 when max) the intersected point is from the pillow center relative to
     * the size in this dimension.
     * Returned value is normalized between 0 & 1. 
     * @param {THREE.Vector3} intersectedPoint - Vector containing the position of intersected point.
     * @param {Number} size - Size of pillow in the given dimension.
     * @param {THREE.Vector3} objCenter - Vector containing the center point of pillow.
     * @param {Number} upperBound - Max possible percentage value. (Should possibly be used to cap max value to less than 1)
     * @param {Number} lowerBound - Min possible percentage value. (Should possibly be used to cap min value to greater than 1)
     */
    getSizeToDistancePercentage(intersectedPoint, size, objCenter, upperBound = 1, lowerBound = 0) {
        let distance = intersectedPoint.distanceToSquared(objCenter);
        let sizeToDistancePercentage = 1 - (distance / size);
        if (sizeToDistancePercentage > upperBound) sizeToDistancePercentage = upperBound;
        if (sizeToDistancePercentage < lowerBound) sizeToDistancePercentage = lowerBound;
        this.debugEngine.debugLog("Distance: ", distance, " Size: ", size);
        this.debugEngine.debugLog("Percentage:", sizeToDistancePercentage);

        return sizeToDistancePercentage;
    }

    /**
     * Get all possible intersect targets for pillow placement (according to current state).
     */
    getIntersectableObjectsForPillowPlacement() {
        let intersectableObjects = [];
        if(this.currentBaseObject != null && this.currentBaseObject != undefined) {
            intersectableObjects.push(this.currentBaseObject);
        }
        this.sceneAssets.forEach(element => {
            if(element != this.currentPillowObject && element.userData.isPillow) {
                intersectableObjects.push(element);
            }
        });
        intersectableObjects = intersectableObjects.concat(this.spaceManager.floors);
        intersectableObjects = intersectableObjects.concat(this.spaceManager.walls);

        return intersectableObjects;
    }

    /**
     * The function to reset pillow rotation upon stacking on a new item
     * @param {THREE.Scene} pillow 
     */
    resetPillowRotation(pillow) {
        if (pillow.userData.isStacked) {
            this.currentPillowObject = pillow;
            // if pillow is being stacked on an asset, reset rotation
            const parent = pillow.parent;
            //Attach object to world to set world transformations
            this.sceneCreator.scene.attach(pillow);
            pillow.rotation.set(0,0,0);
            //Re-attach object to original parent
            parent.attach(pillow);
        }
    }

    /**
     * The function to validate rotation of pillow.
     * * @param {THREE.Scene} pillow - The pillow asset on which rotation validation is to be run.
     */
    validatePillowRotation(pillow) {
        if (this.orientAndOffsetPillow(pillow, true) == false ) {
            return false;
        }
        if (this.currentBaseObject != null) { 
            this.currentPillowObject = pillow;
            let pvb = pillow.userData.pvb;
            let data = pvb.getDataForSnapTest();
            let pos = pillow.getWorldPosition(new THREE.Vector3());
            let intersectableObjects = this.getIntersectableObjectsForPillowPlacement();
            let cornersList = pvb.getCorners();
            let corners = [cornersList.c5, cornersList.c6];
            for (let j = 0 ; j < corners.length; j++) {
                let sourceVector = corners[j].clone();
                if (sourceVector.y <= pos.y) {
                    return false;
                }
                sourceVector = sourceVector.lerp(pos, 0.5);
                let direction = data.topDir.clone();
                this.raycastManager.updateRaycasterProperties(sourceVector, direction, pvb.halfHeight * 1.0);
                let results = this.raycastManager.setAndIntersect(sourceVector, direction, intersectableObjects);
                this.raycastManager.resetFarValue();
                if (results != false && !isConvexHull(results.object)) {
                    return false;
                }
            }  
        }
        return true;   
    }

    /**
     * The function to adjust pillow position on the base object to deal with the floating issue
     * * @param {THREE.Scene} pillow - The pillow asset to be adjusted
     */
    adjustPillowHeight(pillow) {
        if (this.currentBaseObject != null) {
            const parent = pillow.parent;
            //Attach object to world to set world transformations
            this.sceneCreator.scene.attach(pillow);
            this.currentPillowObject = pillow;
            let pillowToPlace = this.currentPillowObject;
            let pvb = pillowToPlace.userData.pvb;
            let corners = pvb.getCorners();
            let center = new THREE.Box3().setFromObject(pillow).getCenter(new THREE.Vector3());
            let intersectableObjects = [this.currentBaseObject];
            let centerToDownDir = pvb.getDataForSnapTest().bottomDir.clone();
            let intersectWithPillowCurve = this.raycastManager.setAndIntersect( center, centerToDownDir, [this.currentPillowObject]);
            let intersectsWithBaseObject = this.raycastManager.setAndIntersectAll( center, centerToDownDir, intersectableObjects);
            let intersectWithBaseObject = false;
            intersectsWithBaseObject = intersectsWithBaseObject.filter( ( item ) => {
                return ( !isConvexHull(item.object) ) ;
            } );
            if (intersectsWithBaseObject != null && intersectsWithBaseObject.length > 0) {
                intersectWithBaseObject = intersectsWithBaseObject[0];
            }
            if (intersectWithPillowCurve != false  &&  intersectWithBaseObject != false ) {
                let heightOffset = 0;
                /*  check for slope - i.e if the pillow is intersecting at a lower point on the surface from the center as compared to the corners, 
                    then height should be adjusted relative to the difference of intersection height of the corners from the pillow curve instead 
                    of the center else the pillow will smudge deeper into the geometry. \__/
                    In case the slope forms in the opposite manner i.e if center intersection is close to the curve, then it will automatically pick
                    that for height adjustment  */
                let centerToRightCornerDir = new THREE.Vector3().subVectors(corners.c4, center).normalize();
                let cornerHeight = [];
                cornerHeight.push(intersectWithPillowCurve.point.y - intersectWithBaseObject.point.y);
                let intersectWithBaseObjectRightCorner = this.raycastManager.setAndIntersect( center, centerToRightCornerDir, [intersectWithBaseObject.object]);
                if (intersectWithBaseObjectRightCorner != false) {
                    cornerHeight.push(intersectWithPillowCurve.point.y - intersectWithBaseObjectRightCorner.point.y);
                }
                let centerToLeftCornerDir = new THREE.Vector3().subVectors(corners.c3, center).normalize();
                let intersectWithBaseObjectLeftCorner = this.raycastManager.setAndIntersect( center, centerToLeftCornerDir, [intersectWithBaseObject.object]);
                if (intersectWithBaseObjectLeftCorner != false) {
                    cornerHeight.push(intersectWithPillowCurve.point.y - intersectWithBaseObjectLeftCorner.point.y);
                }
                heightOffset = Math.min(...cornerHeight);
                if (pillowToPlace.position.y - heightOffset < pillowToPlace.position.y) {
                    pillowToPlace.position.y = pillowToPlace.position.y - heightOffset; 
                    this.setCurrentPositionHolder(pillowToPlace.position.clone());   
                } 
            }
            //Re-attach object to original parent
            parent.attach(pillow);
        }
    }
    
    
    /**
     * The main function that runs the pillow placement algorithm.
     * It is used for auto orientation and placement of pillows relative to their surroundings (when snapping is enabled).
     * And to check wether the current position is valid or not.
     * @param {THREE.Scene} pillow - The pillow asset on which the pillow placement algorithm is to be run.
     * @param {boolean} validateRotation - The boolean to verify if the function call is made to validate rotation or not
     */
    orientAndOffsetPillow(pillow, validateRotation = false){
        //Attach object to world to set world transformations
        const parent = pillow.parent;
        this.sceneCreator.scene.attach(pillow);
        this.currentPillowObject = pillow;

        let pillowToPlace = this.currentPillowObject;
        let pvb = pillowToPlace.userData.pvb;
        let objBoundsData = pvb.getDataForSnapTest();
        let corners = pvb.getCorners();

        let offsetForRaycast = 0.0;
        let raycastLength = pvb.halfHeight * 1.5 + offsetForRaycast;

        let intersectsWithRightSide = [];
        let intersectsWithLeftSide = [];
        let intersectsWithBackSide = [];
        let intersectsWithFrontSide = [];

        let center = new THREE.Vector3();
        let pos = new THREE.Vector3();
        pillowToPlace.getWorldPosition(pos);
        center.copy(pos);
        center.y += pvb.halfHeight; 
        

        
        let validPlacement = true;
        let bBox = pillowToPlace.userData.convexGeometry;


        let intersectableObjects = this.getIntersectableObjectsForPillowPlacement();
        let isStacked = pillowToPlace.userData.isStacked;
        if(isStacked) {
            let centers = [];
            // detect multiple intersections from the lower half of the pillow to better estimate the averaged normal for orientation detection
            centers.push(center);
            centers.push(center.clone().set(center.x, pos.y + pvb.halfHeight / 8.0, center.z));
            centers.push(center.clone().set(center.x, pos.y + pvb.halfHeight / 4.0, center.z));
            // limiting excess raycasting for just rotation validation
            let limit = 1;
            if (!validateRotation) {
                limit = 3;
            }
            for (let i = 0; i < limit; i++) {
                // Raycast towards back & front side
                this.raycastManager.intersectObjectsOverFaceDiagonal(corners.c5, corners.c6, corners.c1, corners.c2, centers[i], objBoundsData.frontDir, raycastLength, intersectsWithBackSide, intersectableObjects, 0.5);
                if(intersectsWithBackSide.length === 0) {
                    this.raycastManager.intersectObjectsOverFaceDiagonal(corners.c7, corners.c8, corners.c3, corners.c4, centers[i], objBoundsData.backDir, raycastLength, intersectsWithFrontSide, intersectableObjects, 0.5);
                }
                
                // Raycast towards left & right sides
                raycastLength = (pvb.halfWidth ) + offsetForRaycast;
                this.raycastManager.intersectObjectsOverFaceDiagonal(corners.c7, corners.c5, corners.c3, corners.c1, centers[i], objBoundsData.rightDir, raycastLength, intersectsWithLeftSide, intersectableObjects, 0.5);
                this.raycastManager.intersectObjectsOverFaceDiagonal(corners.c6, corners.c8, corners.c2, corners.c4, centers[i], objBoundsData.leftDir, raycastLength, intersectsWithRightSide, intersectableObjects, 0.5);
         
            }
        }
        else {
            // Raycast towards back & front side
            this.raycastManager.intersectObjectsOverFaceDiagonal(corners.c5, corners.c6, corners.c1, corners.c2, center, objBoundsData.backDir, raycastLength, intersectsWithBackSide, intersectableObjects, 0.5);
            if(intersectsWithBackSide.length === 0) {
                this.raycastManager.intersectObjectsOverFaceDiagonal(corners.c7, corners.c8, corners.c3, corners.c4, center, objBoundsData.frontDir, raycastLength, intersectsWithFrontSide, intersectableObjects, 0.5);
            }
            
            // Raycast towards left & right sides
            raycastLength = (pvb.halfWidth ) + offsetForRaycast;
            this.raycastManager.intersectObjectsOverFaceDiagonal(corners.c7, corners.c5, corners.c3, corners.c1, center, objBoundsData.leftDir, raycastLength, intersectsWithLeftSide, intersectableObjects, 0.5);
            this.raycastManager.intersectObjectsOverFaceDiagonal(corners.c6, corners.c8, corners.c2, corners.c4, center, objBoundsData.rightDir, raycastLength, intersectsWithRightSide, intersectableObjects, 0.5);
        }

        if(!validateRotation && this.sceneCreator.snappingEnabled && validPlacement) {

            let averagedInfoForRightSide, averagedInfoForLeftSide, averagedInfoForBackSide;
            
            // Only orient if object was moved greater than defined threshold
            let distanceToLastPos = this.currentPositionOfSelectedPillow.distanceToSquared(this.lastPositionOfSelectedPillow); 
            this.debugEngine.debugLog("Obj distance to last pos: ", distanceToLastPos);

            if(distanceToLastPos > 0.015) {

                if(intersectsWithRightSide.length > 0) {
                    averagedInfoForRightSide = this.getAveragedInfoFromIntersects(intersectsWithRightSide);
                }
                if(intersectsWithLeftSide.length > 0) {
                    averagedInfoForLeftSide = this.getAveragedInfoFromIntersects(intersectsWithLeftSide);
                }
                if(intersectsWithBackSide.length > 0) {
                    averagedInfoForBackSide = this.getAveragedInfoFromIntersects(intersectsWithBackSide);
                }

                this.lastPositionOfSelectedPillow.copy(this.currentPositionOfSelectedPillow);

                if(averagedInfoForRightSide) {
                    this.lastValidNormalForRightSide.copy(averagedInfoForRightSide[0]);
                    this.lastValidIntersectionPointForRightSide.copy(averagedInfoForRightSide[1]);
                    this.debugEngine.debugLog("updated normal for right: ", this.lastValidNormalForRightSide);
                }
                else {
                    this.lastValidNormalForRightSide.set(0,0,0);
                }
                if(averagedInfoForLeftSide) {
                    this.lastValidNormalForLeftSide.copy(averagedInfoForLeftSide[0]);
                    this.lastValidIntersectionPointForLeftSide.copy(averagedInfoForLeftSide[1]);
                    this.debugEngine.debugLog("updated normal for left: ", this.lastValidNormalForLeftSide);
                }
                else {
                    this.lastValidNormalForLeftSide.set(0,0,0);
                }
                if(averagedInfoForBackSide) {
                    this.lastValidNormalForBackSide.copy(averagedInfoForBackSide[0]);
                    this.lastValidIntersectionPointForBackSide.copy(averagedInfoForBackSide[1]);
                    this.debugEngine.debugLog("updated normal for back: ", this.lastValidNormalForBackSide);
                }
                else {
                    this.lastValidNormalForBackSide.set(0,0,0);
                }
            }    

            let finalAveragedNormal = new THREE.Vector3(), finalAveragedPoint = new THREE.Vector3();
            let scaledNormal = new THREE.Vector3();
            let countIntersectingSides = 0;
            if(this.lastValidNormalForRightSide.lengthSq() != 0) {
                this.debugEngine.debugLog("RightSide")
                let sizeToDistancePercentage = this.getSizeToDistancePercentage(this.lastValidIntersectionPointForRightSide, pvb.halfWidth, center);
                scaledNormal.copy(this.lastValidNormalForRightSide).multiplyScalar(sizeToDistancePercentage);

                finalAveragedNormal.add(scaledNormal);
                finalAveragedPoint.add(this.lastValidIntersectionPointForRightSide);
                countIntersectingSides += 1;
            }
            if(this.lastValidNormalForLeftSide.lengthSq() != 0) {
                this.debugEngine.debugLog("LeftSide")
                let sizeToDistancePercentage = this.getSizeToDistancePercentage(this.lastValidIntersectionPointForLeftSide, pvb.halfWidth, center);
                scaledNormal.copy(this.lastValidNormalForLeftSide).multiplyScalar(sizeToDistancePercentage);

                finalAveragedNormal.add(scaledNormal);
                finalAveragedPoint.add(this.lastValidIntersectionPointForLeftSide);
                countIntersectingSides += 1;
            }
            if(this.lastValidNormalForBackSide.lengthSq() != 0) {
                this.debugEngine.debugLog("BackSide")
                let sizeToDistancePercentage = this.getSizeToDistancePercentage(this.lastValidIntersectionPointForBackSide, pvb.halfDepth, center);
                scaledNormal.copy(this.lastValidNormalForBackSide).multiplyScalar(sizeToDistancePercentage);

                finalAveragedNormal.add(scaledNormal);
                finalAveragedPoint.add(this.lastValidIntersectionPointForBackSide);
                countIntersectingSides += 1;
            }

            if(intersectsWithFrontSide.length > 0) {
                let averagedInfoForFrontSide = this.getAveragedInfoFromIntersects(intersectsWithFrontSide);
                finalAveragedNormal.add(averagedInfoForFrontSide[0]);
                countIntersectingSides += 1;
            }

            if(countIntersectingSides > 0) {
                finalAveragedPoint.divideScalar(countIntersectingSides);
                finalAveragedNormal.divideScalar(countIntersectingSides);

                // For including effect of base intersects
                let upperBoundForY = 0.9;
                let sizeToDistancePercentage = this.getSizeToDistancePercentage(finalAveragedPoint, pvb.halfHeight, center, upperBoundForY);
                scaledNormal.copy(this.normalForBase).multiplyScalar(upperBoundForY - sizeToDistancePercentage);

                finalAveragedNormal.add(scaledNormal);

                // To stop pillows from going into a laid down position
                let xAbs = Math.abs(finalAveragedNormal.x);
                let yAbs = Math.abs(finalAveragedNormal.y);
                let zAbs = Math.abs(finalAveragedNormal.z);
                let maxVal = Math.max(xAbs, zAbs); 
                if(yAbs > maxVal) {
                    finalAveragedNormal.y = maxVal;
                }

                let normalToUse = new THREE.Vector3().copy(finalAveragedNormal);
                this.debugEngine.debugLog("Normal to apply:", normalToUse);

                if(normalToUse.lengthSq() != 0 ) {
                    pillowToPlace.lookAt( pos.add(normalToUse) );
                }
            } 
        }

        // Check if valid position after orientation
        let allIntersects = [];
        allIntersects = allIntersects.concat(intersectsWithRightSide);
        allIntersects = allIntersects.concat(intersectsWithLeftSide);
        allIntersects = allIntersects.concat(intersectsWithBackSide);
        for (let index = 0; index < allIntersects.length; index++) {
            const element = allIntersects[index];
            if(bBox.children[0].geometry.containsPoint(pillowToPlace, element.point)) {
                validPlacement = false;
            }
        }
        //Re-attach object to original parent
        parent.attach(pillow);

        return validPlacement;
    }

}