import React, { useState } from 'react'
import { Button, Divider, Modal, Menu, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons';

export default function SiloPhotographyPresetsModal(props) {
    
    const [presetName, setPresetName] = useState('')
        
    const menu = (
        <Menu style={{ width: "480px", fontFamily: "Avenir" }}>
            {
                props.allPresets.map((preset, index) => {
                    return <Menu.Item  key={preset['id']} onClick={(event => {props.setSelectedPreset(preset['id']); setPresetName(preset['preset_name'])})} >
                        <span style={{ cursor: "pointer" }}>{preset['preset_name']}</span>
                    </Menu.Item>
                })
            }
        </Menu>
    )
    
  return (
    <Modal
        visible = {props.openSiloPresetModal}
        width={520}
        onCancel={()=>{props.setOpenSiloPresetModal(false)}}
        footer={false}        
        centered={true}
        className='silo-tool-modal'
    >
        <div>
            <div style={{marginTop:"50px"}}>
                <p className='manrope f-20 black'>
                    Select a Saved Preset
                </p>
                <p className='manrope f-14 black'>
                    Load the preset you want to use for these images. You’ll be able to make edits and review the settings before confirming.
                </p>
            </div>
            <div style={{marginTop:"20px"}}>
                <Dropdown className='presets-dropdown' overlay={menu} trigger={['click']} >
                    <span className=' manrope f-14 black'>
                        {props.selectedPreset == -1 ? 'Select...'  : (presetName)}
                        <DownOutlined style={{position:"absolute", right:"50px"}} />
                    </span>
                </Dropdown>
            </div>
            <div className='justify-in-end' style={{display:'flex', margin:"20px"}}>
                <Button style={{marginRight:"12px"}} onClick={()=> {props.setOpenSiloPresetModal(false)}} className='modal-okay-red square invert font-14'>
                    Cancel
                </Button>
                <Button disabled={props.selectedPreset==-1} onClick={()=> {window.location.href = "/silo-tool/" + props.id + "?preset=" + props.selectedPreset}} className={props.selectedPreset==-1? "modal-okay-gray square-disabled font-14":"modal-okay-blue square invert"}>
                    Use This Preset
                </Button>
            </div>
        </div> 
    </Modal>
  )
}
