import React, { useContext } from 'react';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import { LoadingOutlined, LockOutlined, WarningOutlined } from '@ant-design/icons';

const LoadingContainer = (props) => {
    return (<div className="load-container-qa-page">
        {props.icon}
        <h3 className="load-message large">{props.heading}</h3>
        <h3 className="load-message">{props.sub_text}</h3>
    </div>)
}

const ProductQACanvasLoader = () => {
    const { initialLoader, access_level, productData } = useContext(CollaborateQAContext);

    return (
        initialLoader && productData && productData.model_status == 4 ?
            <div className='canvas-load-height qa'>
                <LoadingContainer
                    icon={<LoadingOutlined className="on-start-load loading-icon" size="large" spin />}
                    heading={"Loading Images"}
                    sub_text={"Please wait a few moments..."} />
            </div>
            : access_level == "restricted" && productData && productData.model_status == 4 ?
                <div className='canvas-load-height qa'>
                    <LoadingContainer
                        icon={<LockOutlined className='on-start-load loading-icon' size="large" />}
                        heading={"Oops! You don't have access!"}
                        sub_text={"Please request the owner of this product collaboration to grant you access."} />
                </div> : ""
    );
}


export default ProductQACanvasLoader;
