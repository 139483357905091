import React, { useState, useEffect }  from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import CustomerMainLayout from '../CustomerMainLayout';
import { Button, Row, Col } from 'antd';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import {Link, withRouter} from 'react-router-dom'

import { connect, useSelector,useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {fetchDashboard, FETCH_DASHBOARD, createProduct, CREATE_PRODUCT} from '../../../../redux/actions';
import graphOptions  from "../../../../utils/graph"
import barOptionsConfig  from "../../../../utils/barchart"
let barOptions = barOptionsConfig;
let options = graphOptions;


const Analytics = (props) => {
    barOptions.chart.width = window.innerWidth * 0.70;
    options.chart.width = window.innerWidth * 0.35;
    useEffect(() => {
        props.fetchDashboardData();
    }, []);
    const camel2title = (camelCase) => camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase());
    let productGraph = JSON.parse(JSON.stringify(options));
    let roomsGraph = JSON.parse(JSON.stringify(options));
    let scenesGraph = JSON.parse(JSON.stringify(barOptions));
    productGraph.series[0].data = [];
    roomsGraph.series[0].data = [];
    if(props.rootReducer.dashboardData.productsGraph) {
        Object.keys(props.rootReducer.dashboardData.productsGraph).forEach(key => {
            if(props.rootReducer.dashboardData.productsGraph[key] && key != 'total') {
                productGraph.series[0].data.push({
                    name: camel2title(key),
                    y: props.rootReducer.dashboardData.productsGraph[key],
                    color: "#DA3926"
                });
            }
        });
        Object.keys(props.rootReducer.dashboardData.roomsGraph).forEach(key => {
            if(props.rootReducer.dashboardData.roomsGraph[key] && key != 'total') {
                roomsGraph.series[0].data.push({
                    name: camel2title(key),
                    y: props.rootReducer.dashboardData.roomsGraph[key],
                    color: "#DA3926"
                });
            }
        });
        Object.keys(props.rootReducer.dashboardData.scenesGraph).forEach(key => {
            if(props.rootReducer.dashboardData.scenesGraph[key] && key != 'total') {
                scenesGraph.xAxis.categories.push(camel2title(key));
                scenesGraph.series[0].data.push(props.rootReducer.dashboardData.scenesGraph[key]);
            }
        });

        productGraph.title.text = `Products<br>total<br>${props.rootReducer.dashboardData.productsGraph.total}`
        roomsGraph.title.text = `Products<br>total<br>${props.rootReducer.dashboardData.roomsGraph.total}`
    }


    if (props.rootReducer.dashboardData.counts && props.rootReducer.dashboardData.counts.products == 0
        && props.rootReducer.dashboardData.counts.rooms == 0
        && props.rootReducer.dashboardData.counts.scenes == 0
        && props.rootReducer.dashboardData.counts.collections == 0)
    {
        return (
            <CustomerMainLayout selected='9'>
                <Row style={{display:"flex", justifyContent: "space-around", marginTop: 180}}><h1>Hi, {localStorage.getItem("username")}</h1></Row>
                    <Row style={{display:"flex", justifyContent: "space-around"}}><h3>Create your first Product or Room Model.</h3></Row>
                    <Row>
                        <Col span={8}></Col>
                        <Col span={8} style={{display:"flex", justifyContent: "space-between"}}>
                            <Link to="/add-new-product">
                                <Button type="danger" className="d-inline" size="large">
                                    <PlusOutlined /> Product Model
                                </Button>
                            </Link>
                            <Link to="/rooms">
                                <Button  type="danger" className="d-inline" size="large">
                                    <PlusOutlined /> Room Model
                                </Button>
                            </Link>
                        </Col>
                        <Col span={8}></Col>
                    </Row>
            </CustomerMainLayout>
        );
    }

    return <CustomerMainLayout selected='9'>
      <div style={{marginTop: "10px"}}>
          <Row type="flex" style={{justifyContent: "space-between"}}>
              <Col span={6} style={{padding : "10px"}}>
                  <Card className="count-card card-shadow" bordered={false}>
                      <Row>
                          <Col span={6}>
                              <img className="count-icon" src={require("../../../../assets/images/sofa.svg")} />
                          </Col>
                          <Col span={18}>
                              <div className="heading">Total Products</div>
                              <div className="count">{props.rootReducer.dashboardData.counts.products}</div>
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={6} style={{padding : "10px"}}>
                  <Card className="count-card card-shadow" bordered={false}>
                      <Row>
                          <Col span={6}>
                              <img className="count-icon" src={require("../../../../assets/images/room.svg")} />
                          </Col>
                          <Col span={18}>
                              <div className="heading">Total Rooms</div>
                              <div className="count">{props.rootReducer.dashboardData.counts.rooms}</div>
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={6} style={{padding : "10px"}}>
                  <Card className="count-card card-shadow" bordered={false}>
                      <Row>
                          <Col span={6}>
                              <img className="count-icon" src={require("../../../../assets/images/scene-icon.svg")} />
                          </Col>
                          <Col span={18}>
                              <div className="heading">Total Scenes</div>
                              <div className="count">{props.rootReducer.dashboardData.counts.scenes}</div>
                          </Col>
                      </Row>
                  </Card>
              </Col>
              <Col span={6} style={{padding : "10px"}}>
                  <Card className="count-card card-shadow" bordered={false}>
                      <Row>
                          <Col span={6}>
                              <img className="count-icon" src={require("../../../../assets/images/collection-icon.svg")} />
                          </Col>
                          <Col span={18}>
                              <div className="heading">Total Colections</div>
                              <div className="count">{props.rootReducer.dashboardData.counts.collections}</div>
                          </Col>
                      </Row>
                  </Card>
              </Col>
          </Row>
          <Row type="flex" style={{justifyContent: "space-between"}} className="analytics-graphs-section">
              <Col span={12} style={{padding : "10px"}}>
                  <Card className="card-shadow" bordered={false}>
                      <div className="heading">Products</div>
                      {(props.rootReducer.dashboardData.counts.products > 0)?
                      <HighchartsReact
                          highcharts={Highcharts}
                          options={productGraph}
                      />
                      :""}
                  </Card>
              </Col>
              <Col span={12} style={{padding : "10px"}}>
                  <Card className="card-shadow" bordered={false}>
                      <div className="heading">Rooms</div>
                      {(props.rootReducer.dashboardData.counts.rooms > 0)?
                      <HighchartsReact
                          highcharts={Highcharts}
                          options={roomsGraph}
                      />
                      :""}
                  </Card>
              </Col>
          </Row>

          <Row className="analytics-graphs-section">
              <Col span={24} style={{padding : "10px"}}>
                  <Card className="card-shadow" bordered={false}>
                      <div className="heading">My Scenes</div>
                      {(props.rootReducer.dashboardData.counts.scenes > 0)?
                      <HighchartsReact
                          highcharts={Highcharts}
                          options={scenesGraph}
                      />
                      :""}
                  </Card>
              </Col>
          </Row>
          {/*<Row type="flex" style={{margin: "10px"}}>*/}
          {/*    <h4>Suggested Products</h4>*/}
          {/*</Row>*/}
          {/*<Row type="flex" style={{justifyContent: "start"}}>*/}
          {/*    {props.rootReducer.dashboardData.suggestedProducts.map((product, index) => (*/}
          {/*        <Col span={6} style={{padding : "10px"}} key={index}>*/}
          {/*            <Card className="card-shadow" bordered={false}>*/}
          {/*                <div className="suggested-product">*/}
          {/*                    <img className="product-image" src={product.product_thumbnail} />*/}
          {/*                    <div className="product-name"> {product.product_name}</div>*/}
          {/*                    <div className="product-detail"> Astronomy Or Astrology</div>*/}
          {/*                    <div className="product-price"> $ 19,999</div>*/}
          {/*                </div>*/}
          {/*            </Card>*/}
          {/*        </Col>*/}
          {/*    ))}*/}
          {/*</Row>*/}
      </div>
    </CustomerMainLayout>
};
const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
    fetchDashboardData: () => {
        dispatch(fetchDashboard(FETCH_DASHBOARD));
    },
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Analytics))