import React, { useEffect, useState, useRef } from 'react';
import {  Row, Col,Form, Upload, Button,Card, Tooltip,Dropdown,Menu,Modal,Radio, Input,Tabs,  Collapse,List,Typography} from 'antd';
import ENVIRONMENT  from '../../../../environments'
import ArtistMainLayout from '../../ArtistComponents/ArtistMainLayout';
import AdminMainLayout from '../AdminMainLayout';
import ComplaintDetailsComponent from './ComplaintDetailsComponent';
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const ComplaintDetailView = (props) => {
    
    return(<React.Fragment>
      {props.superArtistComplaintPage ?
        <ArtistMainLayout selected={"7"}>
          <ComplaintDetailsComponent {...props}></ComplaintDetailsComponent>
        </ArtistMainLayout> : 

        <AdminMainLayout selected={"13"}>
          <ComplaintDetailsComponent {...props}></ComplaintDetailsComponent>
        </AdminMainLayout>}
    </React.Fragment>

    )
}

export default ComplaintDetailView;