import React from 'react';
import { Layout, Progress } from 'antd';
import './ArtistAssignmentResults.scss';

const { Content } = Layout;

function ArtistTestFail({
  colorAccuracy,
  dimensionalAccuracy,
  uvAccuracy,
  vrayAccuracy,
  pbrAccuracy,
  geometryAccuracy,
  additionalNotes,
}) {
  const getWidth = (value) => {
    return value * 10;
  };
  const getColor = (value) => {
    if (value) {
      if (value > 0 && value <= 5) {
        return '#E19B12';
      } else {
        return '#29B862';
      }
    } else {
      return '#e0e0e0';
    }
  };
  return (
    <Content className="layout-container layout-snow" id="layout-section">
      <div className="scored-results-container">
        <img
          className="scored-results-img"
          alt="Artist Test Failed"
          src={require("../../../../assets/images/sad.png")}
        />
        <h1 className="manrope f-16 w-600 mb-10 ">
          Sorry {localStorage.getItem("username")}!
        </h1>
        <p className="manrope f-16 w-400 mb-28 text-center letter-spacing-pt2">
          We are sorry to inform that you were unable to pass our test. Please
          review a summary of your test results below.
        </p>
        <div className="test-results-container mb-20">
          <div className="manrope w-500 f-16 title">Test Results</div>
          <div className="matrix-container">
            <div className="head-container">
              <div>Geometry Accuracy</div>
              <div>{geometryAccuracy}/10</div>
            </div>
            <div>
              <Progress
                percent={getWidth(geometryAccuracy)}
                showInfo={false}
                strokeColor={getColor(geometryAccuracy)}
                trailColor="#e0e0e0"
              />
            </div>
          </div>
          <div className="matrix-container">
            <div className="head-container">
              <div>PBR Accuracy</div>
              <div>{pbrAccuracy}/10</div>
            </div>
            <div>
              <Progress
                percent={getWidth(pbrAccuracy)}
                showInfo={false}
                strokeColor={getColor(pbrAccuracy)}
                trailColor="#e0e0e0"
              />
            </div>
          </div>
          <div className="matrix-container">
            <div className="head-container">
              <div>VRAY Accuracy</div>
              <div>{vrayAccuracy}/10</div>
            </div>
            <div>
              <Progress
                percent={getWidth(vrayAccuracy)}
                showInfo={false}
                strokeColor={getColor(vrayAccuracy)}
                trailColor="#e0e0e0"
              />
            </div>
          </div>
          <div className="matrix-container">
            <div className="head-container">
              <div>Color Accuracy</div>
              <div>{colorAccuracy}/10</div>
            </div>
            <div>
              <Progress
                percent={getWidth(colorAccuracy)}
                showInfo={false}
                strokeColor={getColor(colorAccuracy)}
                trailColor="#e0e0e0"
              />
            </div>
          </div>
          <div className="matrix-container">
            <div className="head-container">
              <div>Dimensional Accuracy</div>
              <div>{dimensionalAccuracy}/10</div>
            </div>
            <div>
              <Progress
                percent={getWidth(dimensionalAccuracy)}
                showInfo={false}
                strokeColor={getColor(dimensionalAccuracy)}
                trailColor="#e0e0e0"
              />
            </div>
          </div>
          <div className="matrix-container">
            <div className="head-container">
              <div>UV</div>
              <div>{uvAccuracy}/10</div>
            </div>
            <div>
              <Progress
                percent={getWidth(uvAccuracy)}
                showInfo={false}
                strokeColor={getColor(uvAccuracy)}
                trailColor="#e0e0e0"
              />
            </div>
          </div>
          {additionalNotes === null || additionalNotes === "" ? (
            ""
          ) : (
            <>
              <div className="matrix-container">
                <div className="head-container manrope f-16 w-500">
                  <div className="mb-10">Note</div>
                </div>
                <div className="notes-container manrope f-14 w-600">
                  {additionalNotes}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="fail-results-container manrope f-12 w-600">
          Want to apply again? Reach out to us at <a href="#">support@all3d.ai</a>
        </div>
      </div>
    </Content>
  );
}

export default ArtistTestFail;
