import React, { useEffect } from 'react';
import SaveCardFlow from '../CustomerComponents/SaveCardFlow';
 
const CardSaveWindow = (props) => {

    return (
        <div style={{padding:"40px"}}>
            <h1 className="manrope f-24 black-14 w-600" style={{marginBottom: 30}}>Card Information</h1>
            <SaveCardFlow saveRequestIntermedietely={null} card_save_window={true}/>
        </div>       
      );
}

export default CardSaveWindow;