import './EntityUpdate.scss'
import React, { useState, useCallback } from 'react';
import { Col, Row, Menu, Dropdown, Button, Modal} from "antd";
import { ArrowRightOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments'
import axios from 'axios';
import _ from "lodash";
import * as Utilites from '../../Utilities';

const ActivityTimeline = ({platform, itemHistory, itemHistoryModal, setItemHistoryModal}) => {
    const [activitySortAscending, setActivitySortAscending] = useState(false);
    const [_, setDummyState] = useState(0)
    const forceUpdate = useCallback(() => setDummyState(prev => prev + 1), []);

    const sortActivity = (sort_asc) => {
        setActivitySortAscending(sort_asc);
        if(sort_asc == true)
            itemHistory.sort((a, b) => (a.id < b.id) ? -1 : 1);
        else
            itemHistory.sort((a, b) => (a.id < b.id) ? 1 : -1);

        forceUpdate();
    }

    const getItemHistoryAction = (item) => {

        let result = ""
        // model was deleted by artist
        if(item.additional_message == "model_delete_request"){
            result = "Model was Deleted by Artist"
        }

        // admin model status change
        else if(item.additional_message == "admin_status_change" && item.after_value == "3"){
            result = "Model was moved to Admin QA"
        }

        // Handle Rejection cases
        else if(item.additional_message == "rejected_by_customer"){
            result = "Model was Rejected by Customer"
        }
        else if(item.additional_message == "rejected_by_admin"){
            result = "Model was Rejected by Admin"
        }
        // Messages for customer requests i.e. silo, mp4, threesixty, dimension render
        else if(item.additional_message == "mp4_generated"){
            result = "MP4 Video was generated by the System"
        }
        else if(item.additional_message == "mp4_request"){
            result = "MP4 Video was requested by the Customer"
        }
        else if(item.additional_message == "dim_image_generated"){
            result = "Dimensional Image was generated by the System"
        }
        else if(item.additional_message == "dim_image_requested"){
            result = "Dimensional Image was requested by the Customer"
        }
        else if(item.additional_message == "silo_images_generated"){
            result = "Silo Image(s) were generated by the System"
        }
        else if(item.additional_message == "silo_images_request"){
            result = "Silo Image(s) were requested by the Customer"
        }
        else if(item.additional_message == "threesixty_generated"){
            result = "360 Spin was generated by the System"
        }
        else if(item.additional_message == "threesixty_request"){
            result = "360 Spin was requested by the Customer"
        }
        else if(item.additional_message == "perspective_renders_generated"){
            result = "Perspective Renders were Generated by the System"
        }
        else if(item.additional_message == "auto_unassign"){
            result = "Model was Auto Unassigned By System"
        }
        else if(item.additional_message == "transfer_msp_product") {
            result = `Model was moved from MSP(${item.before_value}) to customer`;
        }
        // Handle cases for model approval and rejection denied
        else if(item.attribute_name && item.attribute_name=="model_status" && item.username != "System"){
            if(item.before_value == "3" && item.after_value == "4"){
                result = "Model was Approved by Admin"
            }
            else if(item.before_value == "3" && item.after_value == "5"){
                result = "Model was Approved and Added to Customer's Library by Admin"
            }
            else if(item.before_value == "4" && item.after_value == "5"){
                result = "Model was Approved by Customer"
            }
            else if(item.before_value == "-5" || item.before_value == "-4" && item.after_value == "4"){
                result = "Customer rejection was denied by Admin"
            }
            else if(item.after_value == "6" && item.additional_message=="quick_render_request"){
                result = "Artist Uploaded the model for Validation and Quick Renders"
            }
            else if(item.after_value == "6" && item.additional_message=="model_submission_request"){
                result = "Artist submitted the model and Preview Renders were requested."
            }
            else if(item.after_value == "11"){
                result = "Model's automation job failed."
            }
            else if(item.after_value == "12"){
                result = "Model's automation job aborted."
            }
        }
        // Return appropriate admin override action
        else if(item.attribute_name == "" && item.admin_override_action != ""){
            if(item.admin_override_action == "perspective_renders_override"){
                result = "Admin Override was used to Replace the Preview Renders"
            }
            else if(item.admin_override_action == "product_custom_rig"){
                result = "Admin Override was used to generate Preview Renders with a custom lighting rig"
            }
            else if(item.admin_override_action == "regenerate_qa_assets"){
                result = "Admin Override was used to Regenerate QA Assets"
            }
            else if(item.admin_override_action == "model_override"){
                result = "Admin Override was used to Model Override"
            }
        }
        // Return Assigned to artist or unasssigned from artist
        else if(item.attribute_name && item.attribute_name == "assigned_artist"){
            if(item.before_value == "" && item.after_value != ""){
                if(item.username != ""){
                    result = "Model was assigned to Artist by Admin"
                }
                else{
                    result = "Model was self assigned by Artist"
                }
            }
            else if(item.before_value != "" && item.after_value == ""){
                if(item.username != ""){
                    result = "Model was unassigned by Admin"
                }
            }
            else if(item.before_value != "" && item.after_value != ""){
                if(item.username != ""){
                    result = "Admin assigned the model to Another artist"
                }
            }
        }
        // Track the system actions
        else if(item.attribute_name && item.attribute_name == "model_status" && item.username == "System"){
            if(item.before_value == "6" && item.after_value == "8"){
                if(item.additional_message == "quick_renders_generated")
                    result = "Model was Valid and Quick Renders were generated."
                if(item.additional_message == "quick_renders_not_generated")
                    result = "Model was Invalid and returned to the artist."
            }
            else if(item.before_value == "6" && item.after_value == "3"){
                result = "System generated the Perspective Renders and moved the Product to QA Pending"
            }
            else if(item.before_value == "6" && item.after_value == "4"){
                result = "System generated the Perspective Renders and moved the Product to Customer QA"
            }
        }

        else if(item.attribute_name == "model_retries" && item.after_value == "0"){
            result = "Model retries were reset by Admin"
        }
        return result
    };

    const getAdditionalDetails = (item) => {

        return (
            <div>
                { item.additional_message == "silo_images_request" || item.additional_message == "silo_images_generated" ?
                    <div style={{display:'flex'}}>
                        <div style={{paddingTop: 10, paddingLeft: 30}}>No. of Silo Images: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.additional_data.length : ''}</span></div>
                        <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.rig}</span></div>
                    </div>
                    : ""
                }

                {
                item.additional_message == "dim_image_requested" || item.additional_message == "dim_image_generated" ?
                    <div style={{display: "flex"}}>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Image Resolution: <span style={{color:"#333333", paddingLeft: 5}}> { item.additional_attachment.additional_data? item.additional_attachment.additional_data.resolution : ''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Image DPI: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data ? item.additional_attachment.additional_data.dpi : ''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.rig}</span></div>
                    </div> : ""
                }

                { item.additional_message == "mp4_request" || item.additional_message == "mp4_generated" ?
                    <div style={{display: "flex"}}>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>MP4 Resolution: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.additional_data.resolution : ''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Frame Count: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.additional_data.frame_count:''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Frame Rate: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.additional_data.frame_rate :''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data? item.additional_attachment.rig :''}</span></div>
                    </div> : ""
                }

                { item.additional_message == "threesixty_request" || item.additional_message == "threesixty_generated" ?
                    <div style={{display: "flex"}}>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>360 Spin Resolution: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data?  parseInt(item.additional_attachment.additional_data.frame_resolution) :'' }K</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Frame Count: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data?  item.additional_attachment.additional_data.frame_count : ''}</span></div>
                         <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.additional_data?  item.additional_attachment.rig : ''}</span></div>
                    </div> : ""
                }
                { item.additional_message =="perspective_renders_generated" && item.additional_attachment.rig?
                    <div style={{paddingTop: 10, paddingLeft: 30}}>Rig: <span style={{color:"#333333", paddingLeft: 5}}> {item.additional_attachment.rig}</span></div>
                    :''
                }
            </div>
        )
    }

    const getRejectionDetailsForActivityModel = (ele) => {
        return (
            <div style={{marginTop: 20}}>
            {ele != "" && ele.rejection_details != "" ?
                <span style={{paddingRight: 5}}>
                <span className="category-artist" style={{textAlign: 'left',display: 'block', marginBottom: 7, paddingLeft: 5}}>
                    Other Information
                </span>
                <span>
                <span className="category-artist" style={{marginBottom: 30, fontSize: 17, paddingLeft: 5, color: '#555555'}}>
                    {ele.rejection_details}
                </span>
                </span>
            </span> : ""}

      {( ele != "" && ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?

          <span>
          <div className="category-artist" style={{textAlign: 'left', marginBottom: 7, marginTop: 20, paddingLeft: 5}}>
          Uploads / Files
          </div>
          <div style={{display: 'flex', flexWrap: 'wrap', paddingLeft: 5}}>
            {ele.reference_files && ele.reference_files.map((file,index) => (
                <a className="upload-a-settings" style={{display: 'flex'}} target="_blank" href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}/> :
                (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                <div className="category-artist" style={{fontSize: 14, textAlign: 'left', marginRight: 10, color: '#555555'}}>{file.name}</div>
                </a>
            ))}
          </div>

      </span>: ''}
    </div>
        )
    }

    const getActorUsername = (item) => {
        let result = "";
        if(item.username != ""){
            result = item.username;
        }
        else if(item.username == ""){
            // No username readily available, figure out who performed the action.

            // CASE: Artist assigned a product to himself
            if(item.attribute_name == "assigned_artist" && item.after_value != ""){
                result = item.after_value;
            }
        }
        return result;
    }
    
    const activity_sort_menu = (
        <Menu style={{ width: "205px",fontFamily:"Avenir" }}>
            <Menu.Item key={1}>
                <span style={{cursor: "pointer"}} onClick = {() => sortActivity(true)}>Oldest Activity First</span>
            </Menu.Item>
            <Menu.Item key={2}>
                <span style={{cursor: "pointer"}} onClick = {() => sortActivity(false)}>Most Recent Activity First</span>
            </Menu.Item>
        </Menu>
    );

    return (
        <Modal visible={itemHistoryModal}
            maskClosable={true}
            closeIcon={
                <img src={"/img/closeIcon.png"}  alt="Close Modal Icon"
                style={{marginTop: 28, marginRight: 20, cursor:"pointer",borderRadius:"4px"}}
                onClick={() => setItemHistoryModal(false)}
                />

            }
            onCancel={() => setItemHistoryModal(false)}
            footer={false}
            width={1000}>
                <Row>
                    <Col span={18} style={{fontSize: 24, color: '#141414'}}>
                            Activity Timeline
                    </Col>
                    <Col span={5}>
                    <Dropdown overlay={activity_sort_menu} trigger={['click']} style={{backgroundColor: '#FAFAFC', borderRadius: 2}} >
                        <Button id="item-modal-dropdown" style={{float: 'right', marginRight: 10}}>
                            <span >{activitySortAscending == true ? "Oldest Activity First" : "Most Recent Activity First"}<DownOutlined/></span>
                        </Button>
                    </Dropdown>
                    </Col>
                </Row>

                <div className="rejection-modal" style={{height: '60vh', overflowY: 'scroll'}} >
                    <div>
                    {itemHistory.map((ele, index) => (
                        <div style={{margin:5}}>
                            <div style={{marginTop: 25}}>
                                <div className='note-bg-artist'>Date <span style={{color:"#276DD7", paddingLeft: 5}}>{Utilites.convertUtcToLocalTimezone(ele.action_time) || '-'}</span>
                                <span style={{paddingLeft: 25}}>Activity</span><span style={{color:"#276DD7", paddingLeft: 5}}>{getItemHistoryAction(ele)}</span>
                                </div>
                            </div>

                            <div style={{display: "flex"}}>
                                <div style={{paddingTop: 10, paddingLeft: 4}}>Activity By: <span style={{color:"#333333", paddingLeft: 5}}> {getActorUsername(ele)}</span></div>
                                {

                                ele.additional_attachment && ele.additional_attachment.rejection_details?
                                <div style={{paddingTop: 10, paddingLeft: 40}}>
                                    <span>Rejection Comments: <span style={{color:"#333333", paddingLeft: 5}}> {ele.additional_attachment.rejection_details} </span></span>
                                    {(ele.additional_attachment.reference_files != [] && ele.additional_attachment.reference_files != undefined) && (typeof (ele.additional_attachment.reference_files) == "object" && Object.keys(ele.additional_attachment.reference_files).length != 0)?
                                    ele.additional_attachment.reference_files.map((file) => (
                                        <span style={{display:'block'}}>
                                        <a style={{color:"#276DD7", lineHeight: '120%'}} className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                                        {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                                        <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                                        (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> :
                                        <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                                        </a>
                                        </span>
                                    ))
                                :''}
                                </div>
                                :''
                                }
                                {/* If model assigned to artist by admin, show assigned artist name as well */}
                                {ele.attribute_name == "assigned_artist" && ele.after_value != "" && ele.username != "" ?
                                    <div style={{paddingTop: 10, paddingLeft: 30}}>Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.after_value}</span></div> : ""
                                }

                                {/* If admin unassigned a model from artist, show before artist name as well */}
                                {ele.attribute_name == "assigned_artist" && ele.after_value == "" && ele.username != "" ?
                                    <div style={{paddingTop: 10, paddingLeft: 30}}>Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.before_value}</span></div> : ""
                                }

                                {/* Admin changed the artist, show the previous artist */}
                                {ele.attribute_name == "assigned_artist" && ele.after_value != "" && ele.before_value != "" && ele.username != "" ?
                                    <div style={{paddingTop: 10, paddingLeft: 30}}> Previous Artist: <span style={{color:"#333333", paddingLeft: 5}}> {ele.before_value}</span></div> : ""
                                }

                                {/* If it was an MP4, silo or dimensional request show additional details */}
                                {getAdditionalDetails(ele)}
                            </div>

                            {/* If the item was rejected show rejection details */}
                            {ele.attribute_name == "model_status" && (ele.after_value == "-4" || ele.after_value == "-5") ? getRejectionDetailsForActivityModel(ele.additional_attachment) : ""}

                            </div>
                        ))
                    }
                    </div>

                </div>

            </Modal>
    )
}

export default ActivityTimeline;