import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Col, Button, Modal } from 'antd';
import '@ant-design/compatible/assets/index.css';
import '../../../../styles/helper.scss'
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import _ from "lodash"; // Import the entire lodash library
import SuccessModal from '../../SuccessModal/SuccessModal';

const CollaborationModals = (props) => {
    const { resolveConfirmModal, setResolveConfirmModal, selectedCollab, setSelectedCollab,
    deleteConfirmModal, setDeleteConfirmModal} = props;

    const [deleteLoader, setDeleteLoader] = useState(false);
    const [resolveLoader, setResolveLoader] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [resolveSuccess, setResolveSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const cancelDeleteCollabModal = () => {
        setSelectedCollab(null);
        setDeleteConfirmModal(false);
    }

    const cancelResolveModal = () => {
        setSelectedCollab(null);
        setResolveConfirmModal(false);
    }

    const deleteCollaboration = () => {
        let collab_payload = {
            'is_hidden': true,
            'last_modified_by': localStorage.getItem('username'),
        }
        if (selectedCollab.type == 'product') {
            if (!props.homepage) {
                collab_payload['product_id'] = selectedCollab.product_id;
            } else {
                collab_payload['product_id'] = selectedCollab.id;
            }
            collab_payload['action'] = 'delete_collaboration_product';
        } else if (selectedCollab.type == "scene") {
            collab_payload['action'] = 'delete_collaboration';
            collab_payload['collaboration_id'] = selectedCollab.id;
        }
        setDeleteLoader(true);
        axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
            .then(res => {
                console.log('response', res);
                setDeleteLoader(false);
                setSelectedCollab(null);
                setDeleteConfirmModal(false);
                setDeleteSuccess(true);
            });
    }

    const markCollaborationAsResolved = () => {
        let collab_payload = {
            'is_resolved': true,
            'last_modified_by': localStorage.getItem('username'),
        }
        if (selectedCollab.type == 'product') {
            if (!props.homepage) {
                collab_payload['product_id'] = selectedCollab.product_id;
            } else {
                collab_payload['product_id'] = selectedCollab.id;
            }
            collab_payload['is_hidden'] = true;
            collab_payload['action'] = 'resolve_collaboration_product';
        } else if (selectedCollab.type == "scene") {
            collab_payload['action'] = 'resolve_collaboration';
            collab_payload['collaboration_id'] = selectedCollab.id;
        }
        setResolveLoader(true);
        axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
            .then(res => {
                console.log('response', res);
                setResolveLoader(false);
                setSelectedCollab(null);
                setResolveConfirmModal(false);
                setResolveSuccess(true);
            });
    }

    const refreshWindow = () => {
        window.location.reload();
    }


    return (
        <>
            <Modal
                visible={deleteConfirmModal}
                onCancel={cancelDeleteCollabModal}
                width={700}
                className="modal-space"
                bodyStyle={{ padding: 0 }}
                footer={[
                    <div className="justify-in-end">
                        <Button disabled={deleteLoader} className="modal-okay-gray square font-12" key="back" onClick={cancelDeleteCollabModal}>
                            Cancel
                        </Button>
                        <Button disabled={deleteLoader} loading={deleteLoader}
                            className="modal-okay square font-12" key="ok" onClick={deleteCollaboration}>
                            Delete
                        </Button>
                    </div>
                ]}>
                <div style={{ padding: "30px", display: "flex" }}>
                    <Col span={24}>
                        <h2 className="modal-heading manrope f-14 black-00 w-600">Delete Collaboration</h2>
                        <p className='manrope f-12 black-55'>Are you sure you want to delete this collaboration?</p>
                    </Col>
                </div>
            </Modal>
            <Modal
                visible={resolveConfirmModal}
                onCancel={cancelResolveModal}
                width={700}
                className="modal-space"
                bodyStyle={{ padding: 0 }}
                footer={[
                    <div className="justify-in-end">
                        <Button disabled={resolveLoader} className="modal-okay-gray square font-12" key="back" onClick={cancelResolveModal}>
                            Cancel
                        </Button>
                        <Button disabled={resolveLoader} loading={resolveLoader}
                            className="modal-okay square font-12" key="ok" onClick={markCollaborationAsResolved}>
                            Resolve
                        </Button>
                    </div>
                ]}>
                <div style={{ padding: "30px", display: "flex" }}>
                    <Col span={24}>
                        <h2 className="modal-heading manrope f-14 black-00 w-600">Mark Collaboration as Resolved</h2>
                        <p className='manrope f-12 black-55'>Are you sure you want to mark this collaboraton as resolved?</p>
                    </Col>
                </div>
            </Modal>
            <SuccessModal
                visible={resolveSuccess}
                heading={"Collaboration Resolved Successfully!"}
                text={""}
                footer={[
                    <div className='justify-in-center'>
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-16" onClick={refreshWindow}>
                            Okay
                        </Button>
                    </div>
                ]}
            />
            <SuccessModal
                visible={deleteSuccess}
                heading={"Collaboration Deleted Successfully!"}
                text={""}
                footer={[
                    <div className='justify-in-center'>
                        <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-16" onClick={refreshWindow}>
                            Okay
                        </Button>
                    </div>
                ]}
            />
        </>
    );
}


export default CollaborationModals;
