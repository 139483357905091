import React, { useState, useEffect, useRef } from "react";
import { Button, Layout, Table, Space, Input } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";

const { Header, Content, Footer } = Layout;

function Agencies(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    axios.post(ENVIRONMENT.AGENCY_GET_BATCH, {}).then((res) => {
      if (res["data"]) {
        setAgencies(res["data"]);
      }
      setIsLoading(false);
    });
  }, []);

  const viewAgencyDetails = (agency_id) => {};

  const getColumnSearchProps = (dataIndex,placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: "Agency Name",
      key: 1,
      dataIndex: "agency_name",
      ...getColumnSearchProps('agency_name','Agency Name'),
    },
    {
      title: "Agency Admin",
      key: 2,
      dataIndex: "admin_username",
      ...getColumnSearchProps('admin_username','Agency Admin'),
    },
    {
      title: "",
      key: 3,
      dataIndex: "id",
      render: (text) => (
        <span>
          <a href={'/admin_agency_details/'+text}>View Agency Details</a>
        </span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={agencies}
        bordered={true}
        loading={{
          indicator: <LoadingOutlined />,
          spinning: isLoading,
        }}
      />
    </React.Fragment>
  );
}

export default Agencies;
