import React from 'react';
import { Collapse, Modal, Progress} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
 const {Panel} = Collapse
const PaymentConfirmationModal = (props) => {

    return (
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={696}
        onCancel={props.onCancel}
        visible={props.visible}
        bodyStyle={{padding: 0}}
        footer={props.footer}>
        <div style={{padding:"40px"}}>
            <div style={{textAlign: "center"}}>
                <img style={{width: 85,height:85}} src={require("../../../assets/images/exclamation_icon.png")}></img>
            </div>
        
            <div style={{textAlign: "center",margin: "20px auto 12px auto"}}>
                <h2  className="manrope f-24 blue w-700 justify-in-center">
                    {props.purchaseType=='in-app' || (props.usedCount == (props.usedCount + props.availableCount))
                    ? "Confirmation Request" : "Payment Confirmation"}</h2>
                    <p className="manrope f-18 grey-77">{props.text}</p>
            </div> 
              
            <div className="payment-modal-border justify-space-between">
                <span className="manrope f-16 black-55">
                    Name
                </span>
                <span className="manrope f-16 black-55">
                    {props.name}
                </span>
            </div>   
            {
                props.userModelType && props.userModelType =='high_res_and_ar'
                ?
                <div className='price-wrapper-payment'>
                    <Collapse defaultActiveKey={['1']} expandIconPosition={'right'}>
                        <Panel key ='1' header = {
                            <div className='payment-modal-border full details justify-space-between'>
                                <span className="manrope f-16 black-55">
                                    Price
                                </span>
                                <span style={{display:"inherit"}}>
                                <span className="manrope f-16 green w-700 justify-in-end">
                                    {props.amount}
                                </span>
                                <span style={{marginLeft:10,color:"#276DD7"}} className="manrope f-14 w-500 justify-in-end">
                                    Details
                                </span>
                                </span>
                            </div>
                        }>
                            <div className="payment-modal-border full price-wrapper">
                                <span className="manrope f-14 black-55">
                                    High Resolution Modal
                                </span>
                                <span className="manrope f-14 green w-700" style={{position:"absolute", right:"70px"}}>
                                    ${props.highResPrice}
                                    <DeleteOutlined onClick={()=> props.changeModelOutput(['ar'])} style={{color:"#EE2233"}}/>
                                </span>
                            </div>
                            <div className="payment-modal-border full price-wrapper">
                                <span className="manrope f-14 black-55">
                                    AR Model
                                </span>
                                <span className="manrope f-14 green w-700" style={{position:"absolute", right:"70px"}}>
                                    ${props.arPrice}
                                    <DeleteOutlined onClick={()=> props.changeModelOutput(['high_res'])} style={{color:"#EE2233"}}/>
                                </span>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                :
                <div style={{marginTop: 12}} className="payment-modal-border full  justify-space-between">
                    <span className="manrope f-16 black-55">
                        Price
                    </span>
                    <span className="manrope f-16 green w-700 justify-in-end">
                        {props.amount}
                    </span>
                </div>
            }
            
            { (props.setSubscriptionModal != undefined) ?
            <div style={{textAlign: 'center',marginTop: 8}}>
                Click <a onClick={() => props.setSubscriptionModal(true)}>here</a> to check subscription Packages for discounts
            </div>
            :''
            }
        </div>       
    </Modal>
      );
}

export default PaymentConfirmationModal;