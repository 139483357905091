import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { setupCognito, cognito } from 'react-cognito';
import App from './components/App';
import config from './configCognito.json';

import 'fabric-webpack';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './redux/epics';
import rootReducer from './redux/reducers/root';
import productReducer from './redux/reducers/productReducer';
import storeReducer from './redux/reducers/storeReducer';
import roomReducer from './redux/reducers/roomReducer';
import sceneReducer from './redux/reducers/sceneReducer';
import collectionReducer from './redux/reducers/collectionReducer';
import entityArrangementReducer from './redux/reducers/entityArrangentReducer';

import { QueryClient, QueryClientProvider } from 'react-query';

const reducers = combineReducers({
  cognito, rootReducer, productReducer, sceneReducer, roomReducer, collectionReducer,storeReducer, entityArrangementReducer
});

const epicMiddleware = createEpicMiddleware();

const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware(thunk, epicMiddleware));

epicMiddleware.run(rootEpic);
// config.group = 'admins'; // Uncomment this to require users to be in a group 'admins'
setupCognito(store, config);

// inititalizing react query
const queryClient = new QueryClient();

ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>,
    document.getElementById('root'),
);


//
