/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Collapse } from "antd";
import SpaceDetails from "./SpaceDetails";
import ModelSpecifications from "./ModelSpecifications";

const { Panel } = Collapse;

const CUSTOMER_USERNAME = localStorage.getItem("username");
const COMPANY_ID = localStorage.getItem("company_id");

const SpaceInfoCollapse = ({ model_info, performStoreAction,platform }) => {
  return (
    <Collapse
      className="product-info-collapse mt-20 w-full"
      bordered={false}
      expandIconPosition={"right"}
      defaultActiveKey={["1"]}
    >
      <Panel
        header={
          <span className="manrope f-20 black-26">Space Details</span>
        }
        key="1"
      >
        <SpaceDetails performStoreAction={performStoreAction} platform={platform}/>
      </Panel>
      <Panel
        header={
          <span className="manrope f-20 black-26">
            Model Specifications
          </span>
        }
        key="2"
      >
        <ModelSpecifications model_info={model_info} />
      </Panel>
    </Collapse>
  );
};

export default SpaceInfoCollapse;
