import React from 'react';
import 'antd/dist/antd.css';
import '@ant-design/compatible/assets/index.css';
import { Row, Col,message,Button} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SceneName from './SceneName';
import ModelsSelection from './ModelsSelection';
import RoomSelection from '../SceneCreationFlowUtilities/RoomSelection';
import CustomerMainLayout from '../CustomerMainLayout/CustomerMainLayout';
import NewCollection from '../SceneCreationFlowUtilities/NewCollection';
import CustomerOnboarding from './../../CustomerComponents/CustomerOnboarding';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import PaymentConfirmationModal from '../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import FileConstants from '../../../../FileConstants';
import MspWarningModal from '../MspWarningModal/MspWarningModal';
const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;

class AddCollectionFlow extends React.Component {
    state={
        step: 3,
        collection_id: 684, //204, //null,
        scene_name: '',
        collection_name: '',
        room_id: null,
        space_areas: [],
        model_selection_stage: 1,
        onboarding: false,
        purchase_type_room: '',
        used_count_room : -1,
        available_limit_room : -1,
        subscription_type_room : '',
        requestStatusRoom : '',
        card_status_room : '',
        entity_price_room:'',
        purchase_type_product: '',
        used_count_product : -1,
        available_limit_product : -1,
        subscription_type_product : '',
        requestStatusProduct : '',
        card_status_product : '',
        entity_price_product:'',
        library_payload : {},
        entity_type : '',
        entity_name : '',
        payment_confirmation_modal : false,
        button_loader : false,
        callback_function : null,
        entity_id : null,
        payment_failure_message : '',
        payment_failure_modal : false,
        card_save_flow_triggered: false,
        loading_cancel:false,
        subscriptionModal : false,
        subscriptionPlan : '',
        cardSaveFlowTriggered : false,
        isSubscriptionUpdateRequest : false,
        inAppLimitExceededModal : false,
        update_collection: false,
        scene_id: null,
        is_subscription_active: false,
        redirection_path: null,
        is_msp_user_warning_modal: false,
    }


    componentDidMount() {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        this.loadUserRequestStatusProduct()
        this.loadUserRequestStatusRooms()

        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') == null)) {
            this.setState({
                is_msp_user_warning_modal: true
            })}
        
        if(onboarding != undefined){
            if (typeof(onboarding == 'string') || onboarding instanceof String) {
                onboarding = (onboarding.toLowerCase() === 'true');
            }

            if (onboarding == false) {
                this.setState({
                    onboarding: false
                });
            }
            else {
                this.setState({
                    onboarding: true
                });
            }
        }
        if (this.props.update_collection) {
            this.setState({
                step: this.props.step,
                collection_id: this.props.collection_id,
                scene_name: this.props.scene_name,
                model_selection_stage: this.props.model_selection_stage,
                collection_name: this.props.collection_name,
                update_collection: this.props.update_collection,
                room_id: this.props.room_id,
                scene_id: this.props.scene_id,
                space_areas: this.props.space_areas
            })
        }
        else{
            let scene_name = new URLSearchParams(window.location.search).get('scene');
            if(scene_name){
                this.createCollection(scene_name)
            }
        }
        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        this.setState({
            redirection_path:redirection_path,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            let onboarding = new URLSearchParams(window.location.search).get("onboarding");
            if(onboarding != undefined){
                if (typeof(onboarding == 'string') || onboarding instanceof String)
                {
                    onboarding = (onboarding.toLowerCase() === 'true');
                }
                if (onboarding == false) {
                    this.setState({
                        onboarding: false
                    });
                }
                else{  
                    this.setState({
                        onboarding: true
                    });
                }
            }
        }
    }

    async createCollection(collection_name) {
        let payload = {
            'collection_name': collection_name,
            'username': localStorage.getItem('username')
        }
        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
            payload.designed_for = localStorage.getItem('managed_customer_username');
        }

        let company_id = localStorage.getItem("company_id");
        if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
            company_id = localStorage.getItem("managed_company_id");
        }
        if (company_id != undefined) {
            payload.company_id = company_id
        }
        axios.post(ENVIRONMENT.UPDATE_COLLECTION, payload)
        .then(res => {
            console.log('+++ collection = ', res)
            this.setState(
                {
                    step: 4, 
                    collection_id: res.data.collection_id, 
                    collection_name: collection_name
                })
        })
    }

    loadUserRequestStatusRooms = () => {
        let check_payload = {
          "username": localStorage.getItem('username'),
          "action": "check",
          'request_type': 'room_prop'
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload)
        .then(res => {
            this.setState({
                purchase_type_room : res.data['purchase_type'],
                used_count_room : res.data['usage_count'],
                available_limit_room : res.data['available_limit'],
                subscription_type_room : res.data['subscription_plan'],
                requestStatusRoom : res.data['request_allowed'],
                card_status_room : res.data['card_status'],
                entity_price_room: res.data['price']
            })
            if(this.state.entity_type != 'product'){
                this.setState({
                    payment_confirmation_modal:false,
                    loading_cancel : false
                })
            }
        })
    }

    loadUserRequestStatusProduct = () => {
        let check_payload = {
          "username": localStorage.getItem('username'),
          "action": "check",
          'request_type': 'product_prop'
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload)
        .then(res => {
            this.setState({
                purchase_type_product : res.data['purchase_type'],
                used_count_product : res.data['usage_count'],
                available_limit_product : res.data['available_limit'],
                subscription_type_product : res.data['subscription_plan'],
                requestStatusProduct : res.data['request_allowed'],
                card_status_product : res.data['card_status'],
                entity_price_product: res.data['price'],
                is_subscription_active: res.data['is_subscription_active']
            })
            if(this.state.entity_type == 'product'){
                this.setState({
                    payment_confirmation_modal:false,
                    loading_cancel : false
                })
            }
        })
    }

    processRequest = (payload, entity_type, is_library_item, Callback, selectedAreas= null) => {
        this.setState({
            entity_type : entity_type
        },()=>{
            if(entity_type === 'product'){
                this.setState({
                    entity_name: payload.product.name
                })
                if(payload.product.company_display_name != FileConstants.ALL3D_COMPANY && (payload.product.price != 0 || payload.product.price != '')  ){
                    this.setState({
                        requestStatusProduct : 'payment_required'
                    },()=>{
                        this.processRequestProduct(payload,is_library_item,Callback ,selectedAreas)
                    })
                }
                else{
                    this.processRequestProduct(payload,is_library_item,Callback ,selectedAreas)
                }
            }
            else{
                this.setState({
                    entity_name: payload.room.room_name
                })
                if(payload.room.company_display_name != FileConstants.ALL3D_COMPANY && (payload.room.price != 0 || payload.room.price != '') ){
                    this.setState({
                        requestStatusRoom : 'payment_required'

                    },()=>{
                        this.processRequestRoom(payload,is_library_item, Callback)
                    })
                }
                else{
                    this.processRequestRoom(payload,is_library_item, Callback)
                }
            }
        })
    }

    processRequestProduct = (product_payload,is_library_item,addProductToCollectionCallback,selectedAreas) => {
        let payload = {product_id: product_payload.product_id};
        payload.collection_id = product_payload.collection_id;
        if(is_library_item == true || product_payload.product.price == 0 || product_payload.product.price == ''){
            axios.post(ENVIRONMENT.UPDATE_COLLECTION, payload)
            .then((response) => {
                if(selectedAreas == null){
                    addProductToCollectionCallback(true,product_payload.product_id)
                    this.loadUserRequestStatusProduct()
                    message.info('Product is added to your scene.');
                }
                else{
                    payload = {}
                    payload.product_id = product_payload.product_id;
                    payload.collection_id = product_payload.collection_id;
                    payload.area_names = selectedAreas;
                    axios.post(ENVIRONMENT.UPDATE_COLLECTION_SPACE, payload)
                    .then((response) => {
                        this.loadUserRequestStatusProduct()
                        message.info('Product is added to your scene.');
                        addProductToCollectionCallback(true,product_payload.product_id)
                    })
                    .catch((error) => {
                        message.info('We failed to add this product to your scene. Try Again.');
                        addProductToCollectionCallback(false,product_payload.product_id)
                    });
                }
                
            })
            .catch((error) => {
                addProductToCollectionCallback(false,product_payload.product_id)
                message.info('We failed to add this product to your scene. Try Again.');
            });
        }
        else{
            payload.collection_name = this.getCollectionName();
            payload['add_to_library'] = {
                "product_id": product_payload.product_id,
                "username": localStorage.getItem('username')
            }
            let requestPayload = payload;
            requestPayload['username'] = localStorage.getItem('username');
            let gateway_payload = {
                "username": localStorage.getItem('username'),
                "request_type": "product_prop",
                "action": "perform",
                "update": "",
                "request_payload": requestPayload,
                "request_id": Date.now().toString()
            }
            if ( selectedAreas!=null ){
                gateway_payload.request_payload.area_names = selectedAreas
            }
            if(this.state.requestStatusProduct == 'update_subscription'){
                // update subscription plan modal here
                this.setSubscriptionModal(true)
                this.setState({
                    callback_function : addProductToCollectionCallback
                })
            }
            else if (this.state.requestStatusProduct == "always_allowed" || this.state.requestStatusProduct == "allowed" ) {
                this.setState({
                    library_payload : gateway_payload,
                    callback_function : addProductToCollectionCallback,
                    entity_id : product_payload.product_id
                },()=>{
                    this.processSubmission()
                })
                
            }
            // Processing request with payment
            else if (this.state.requestStatusProduct == "payment_required" ) {
                this.setState({
                    library_payload : gateway_payload,
                    entity_price_product : product_payload.product.price,
                    callback_function : addProductToCollectionCallback,
                    entity_id : product_payload.product_id
                },()=>{
                    this.setState({
                        payment_confirmation_modal : true
                    })
                })
                    
            }
        }
    }

    triggerPostCollectionUpdate = (collection_id) => {
        console.log("collection id", collection_id)
        let collection_payload= {
            "collection_id": collection_id  
        }
        axios.post(ENVIRONMENT.POST_COLLECTION_UPDATE, collection_payload)
            .then((res) => {
            })
    }

    processRequestRoom = (room_payload,is_library_item, addRoomToCollectionCallback) => {
        let payload = {room_id: room_payload.room_id.toString()};
        payload.collection_id = room_payload.collection_id.toString();
        if(is_library_item == true || room_payload.room.price==0 ||room_payload.room.price ==''){
            axios.post(ENVIRONMENT.COLLECTION_ADD_ROOM, payload)
            .then((response) => {
                this.setSpaceAreas(response.data.space_areas);
                message.info('Space is added to your scene.');
                this.triggerPostCollectionUpdate(payload.collection_id)
                this.loadUserRequestStatusRooms()
                addRoomToCollectionCallback(true,room_payload.room_id)
            })
            .catch((error) => {
                
            });
        }
        else{
            payload.collection_name = this.getCollectionName();
            payload['add_to_library'] = {
                "room_id": room_payload.room_id,
                "username": localStorage.getItem('username')
            }
            let requestPayload = payload;
            requestPayload['username'] = localStorage.getItem('username');
            let gateway_payload = {
                "username": localStorage.getItem('username'),
                "request_type": "room_prop",
                "action": "perform",
                "update": "",
                "request_payload": requestPayload,
                "request_id": Date.now().toString()
            }
            if(this.state.requestStatusRoom == 'update_subscription'){
                this.setSubscriptionModal(true)
                this.setState({
                    callback_function : addRoomToCollectionCallback
                })
            }
            else if (this.state.requestStatusRoom == "always_allowed" || this.state.requestStatusRoom == "allowed" ) {
                this.setState({
                    library_payload : gateway_payload,
                    callback_function : addRoomToCollectionCallback,
                    entity_id : room_payload.room_id
                },()=>{
                    this.processSubmission()
                })
                
            }
            // Processing request with payment
            else if (this.state.requestStatusRoom == "payment_required" ) {
                this.setState({
                    library_payload : gateway_payload,
                    callback_function : addRoomToCollectionCallback,
                    entity_id : room_payload.room_id,
                    entity_price_room : room_payload.room.price
                },()=>{
                    this.setState({
                        payment_confirmation_modal : true
                    })
                })
            }
        }
    }

    upgradeSubscriptionPlan = () => {
        let payload ={
            username: localStorage.getItem('username'),
            subscription_type: this.state.subscriptionPlan
          }
          console.log('Subscription payload',payload);
          this.setButtonLoader(true);
          axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
          .then( (response) => {
            console.log(response.data);
            this.setButtonLoader(false);
            this.setSubscriptionModal(false);
            // setPaymentConfirmationModal(false);
            message.success('Your subscription plan has been successfully upgraded.');
            this.loadUserRequestStatusProduct();
            this.loadUserRequestStatusRooms()
        })
          .catch((error) => {
          });
    
    }

    initiateSubscriptionConfirmation = () => {
        window.addEventListener('card_saved', (e) => {
          let action = e.detail.action;
          this.setCardSaveFlowTriggered(false);
          if (action == 'subscription_update') {
            this.upgradeSubscriptionPlan();
          }
          
        }, false);
        
        if (this.state.entity_type == 'product') {
          if (this.state.card_status_product == 1) {
              this.upgradeSubscriptionPlan();
          } 
          if (this.state.card_status_product == 0) {
              this.setIsSubscriptionUpdateRequest(true);
              let child_window = window.open('/save-card?action=subscription_update', '', "width=600,height=600");
                let refreshInterval = setInterval(() => {
                  if(child_window.closed) {
                    this.setCardSaveFlowTriggered(false);
                    clearInterval(refreshInterval);  
                  }
              }, 500);
              this.setCardSaveFlowTriggered(true);
          }
        }
        else {
          if (this.state.card_status_product == 1) {
              this.upgradeSubscriptionPlan();
          } 
          if (this.state.card_status_product == 0) {
              this.setIsSubscriptionUpdateRequest(true);
              let child_window = window.open('/save-card?action=subscription_update', '', "width=600,height=600");
                let refreshInterval = setInterval(() => {
                  if(child_window.closed) {
                    this.setCardSaveFlowTriggered(false);
                    clearInterval(refreshInterval);  
                  }
              }, 500);
              this.setCardSaveFlowTriggered(true);
          }
        }
    }

    setIsSubscriptionUpdateRequest = (val) => {
        this.setState({
            isSubscriptionUpdateRequest : val
        })
    }

    setCardSaveFlowTriggered = (val) => {
        this.setState({
            cardSaveFlowTriggered : val
        })
    }

    setButtonLoader = (val) => {
        this.setState({
            button_loader : val
        })
    }

    setSubscriptionModal = (val) => {
        this.setState({
            subscriptionModal : val,
        },()=>{
            if(val == false){
                this.state.callback_function(false)
            }
        })
    }

    setSubscriptionPlan = (val) => {
        this.setState({
            subscriptionPlan : val
        })
    }

    setCollectionId = (id) => {
        this.setState({
            collection_id: id
        });
    }   

    getCollectionId = () => {
        return this.state.collection_id;
    }

    setRoomId = (id) => {
        this.setState({
            room_id: id
        });
    }

    getRoomId = () => {
        return this.state.room_id;
    }

    setSceneName = (name) => {
        this.setState({
            scene_name: name
        });
    }

    getSceneName = () =>{
        return this.state.scene_name;
    }

    setCollectionName = (name) => {
        this.setState({
            collection_name: name
        });
    }

    getCollectionName = () => {
        return this.state.collection_name;
    }

    getSpaceAreas = () => {
        return this.state.space_areas;
    }

    setSpaceAreas = (space_areas) => {
        this.setState({
            space_areas: space_areas
        });
    }

    getModelSelectionStage = () => {
        return this.state.model_selection_stage;
    }

    setModelSelectionStage = (stage) => {
        return this.setState({
            model_selection_stage: stage
        });
    }

    setCollectionSelectionView = () =>
    {
        this.setState({
            step: 2
        });
    }
    
    setNewCollectionView = () =>
    {
        this.setState({
            step: 3
        });
    }
    
    setModelSelectionView = () =>
    {
        this.setState({
            step: 4
        });
    }

    setRoomsSelectionView = () =>
    {
        this.setState({
            step: 5
        });
    }

    setSpaceUnitAllocationView = () =>
    {
        this.setState({
            step: 6
        });
    }

    saveCardAndProcessPayment = () => {
        if (this.state.card_status_product == 0){
            window.addEventListener('card_saved', (e) => {
                let action = e.detail.action;
                this.setState({
                    card_save_flow_triggered: false
                });
                if (action == 'card_saved') {
                    this.setState({
                        card_status_product: true,
                        card_status_room: true
                    });
                    this.processSubmission();
                }
                
              }, false);
        
              let child_window = window.open('/save-card?action=card_saved', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                      if(child_window.closed) {
                        this.setState({
                            card_save_flow_triggered: false
                        });
                        clearInterval(refreshInterval);  
                      }
                    }, 500);
                    this.setState({
                        card_save_flow_triggered: true
                    });
                  
        } else {
            this.processSubmission();        
        }
    }
    
    getCollectionPayload = () => {
        let payload = {
            "required_fields": ["id", "space_areas"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"
    
        if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("collection")) {
            company_id = "company_id__exact=" + COMPANY_ID
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")&&(template_collection_id__is=null)"
        } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")&&(template_collection_id__is=null)"
        }
        filter_string = filter_string + "||(" + has_access_to + ")||(template_collection_id__not=NULL&&" + customer_username+ "&&"+ is_hidden +")"
        filter_string = filter_string + "&&(id__exact=" + this.getCollectionId() + ")"
        payload['filter_string'] = filter_string
    
        return payload;
      }

    processSubmission = () => {
        this.setState({
            button_loader : true
        })
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY,this.state.library_payload)
            .then( (response) => {
              if (response.data.status!=undefined && response.data.status == 1) {
                this.setState({
                    button_loader : false,
                    payment_confirmation_modal : false
                },()=>{
                    this.state.callback_function(true,this.state.entity_id)
                    if(this.state.entity_type == 'product'){
                        this.loadUserRequestStatusProduct()
                        message.info("Product is added to your scene and library.")
                    }
                    else{
                        message.info("Space is added to your scene and library.")
                        this.loadUserRequestStatusRooms()
                        let payload = this.getCollectionPayload()
                        axios.post(ENVIRONMENT.LIST_COLLECTION, payload)
                        .then( res => {
                            res.data.map(col => {
                                if(col.id == this.getCollectionId()){
                                    this.setSpaceAreas(col.space_areas)
                                }
                            })
                        })
                    }
                })
              }
              else{
                if ('request_allowed' in response.data && response.data['request_allowed'] == 'not_allowed') {
                    this.setState({
                        button_loader:false,
                        inAppLimitExceededModal : true,
                        payment_confirmation_modal: false,
                    })
                    this.setState({loading_cancel:true});
                    this.loadUserRequestStatusProduct();
                    this.loadUserRequestStatusRooms();
                    this.state.callback_function(false,this.state.entity_id);
                }
                else{
                    this.setState({
                        payment_failure_modal : true,
                        payment_failure_message:response.data.error_message,
                        button_loader:false
                    })
                }
                
              }
              })
            .catch((error) => {
            });
      }

    countLimitFull = () => {
        if (this.state.entity_type == 'product') {
          return this.state.used_count_product == (this.state.used_count_product + this.state.available_limit_product);
        } else {
          return this.state.used_count_room == (this.state.used_count_room + this.state.available_limit_room);
        }
      }

    updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            this.setState({
                card_save_flow_triggered: false
            });
            if (action == 'save_card') {
                message.success('Payment Method Updated Successfully.')
                this.setState({
                    card_status_product: true,
                    card_status_room: true
                });
            }      
        }, false);
    
        this.setState({payment_failure_modal: false});
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
        if(child_window.closed) {
            this.setState({
                card_save_flow_triggered: false,
            });
            clearInterval(refreshInterval);  
        }
        }, 500);
        this.setState({
            card_save_flow_triggered: true
        });
    }

    handleWarningModalClose = () => {
        this.setState({
                is_msp_user_warning_modal: false
            })
    }

    render() {
        return ( 
        <CustomerMainLayout selected='13'>
            <div>
                {(this.state.step == 1)?
                    <Row style={{marginTop: 50}}>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <SceneName
                            redirection_path={this.state.redirection_path}
                            scene_flow={false}
                            setModelSelectionView={this.setModelSelectionView}
                            setRoomsSelectionView={this.setRoomsSelectionView}
                            setCollectionId={this.setCollectionId}
                            setSceneName={this.setSceneName} 
                            setCollectionSelectionView={this.setCollectionSelectionView}
                            />
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                    :
                    ""
                }
                {(this.state.step == 3)?
                    <Row style={{marginTop: 50}}>
                        <Col span={12} offset={6}>
                            <NewCollection
                            redirection_path={this.state.redirection_path}
                            scene_flow={false}
                            setModelSelectionView={this.setModelSelectionView}
                            setRoomsSelectionView={this.setRoomsSelectionView}
                            setCollectionId={this.setCollectionId}
                            setSceneName={this.setSceneName} 
                            setCollectionSelectionView={this.setCollectionSelectionView}
                            getSceneName={this.getSceneName}
                            setCollectionName={this.setCollectionName}
                            />
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                    :
                    ""
                }

                {(this.state.step == 4)?
                    <div>
                        <ModelsSelection
                        redirection_path={this.state.redirection_path}
                        setCollectionView={this.state.update_collection ? this.props.setCollectionView : ''}
                        scene_id={this.state.scene_id}
                        room_id={this.state.room_id}
                        update_collection={this.state.update_collection}
                        onboarding={this.state.onboarding}
                        scene_flow={false}
                        setRoomsSelectionView={this.setRoomsSelectionView}
                        getCollectionId={this.getCollectionId}
                        getSceneName={this.getSceneName}
                        setSpaceUnitAllocationView = {this.setSpaceUnitAllocationView}
                        setRoomId={this.setRoomId}
                        getSpaceAreas={this.getSpaceAreas}
                        setSpaceAreas={this.setSpaceAreas}
                        getModelSelectionStage={this.getModelSelectionStage}
                        setModelSelectionStage={this.setModelSelectionStage}
                        getCollectionName={this.getCollectionName}
                        processRequest={this.processRequest}
                        />
                    </div>
                    :
                    ""
                }
                {(this.state.step == 5)?
                    <div>
                        <RoomSelection
                        getCollectionId={this.getCollectionId}
                        getSceneName={this.getSceneName}
                        />
                    </div>
                    :
                    ""
                }
                <PaymentFailureModal 
                    paymentFailureMessage={this.state.payment_failure_message}
                    setPaymentFailureModal={(val) => {this.setState({payment_failure_modal: val})}}
                    updatePaymentDetailsOnFailure={this.updatePaymentDetailsOnFailure}
                    paymentFailureModal={this.state.payment_failure_modal}
                />
                <InAppPurchaseLimitModal visible={this.state.inAppLimitExceededModal} setVisible={ (val) => {this.setState( { inAppLimitExceededModal : val } )} } />
                <PaymentConfirmationModal
                    storeProp={true}
                    itemType={this.state.entity_type}
                    purchaseType={this.state.entity_type == 'product' ? this.state.purchase_type_product : this.state.purchase_type_room}
                    usedCount = {this.state.entity_type == 'product' ? this.state.used_count_product : this.state.used_count_room}
                    availableCount = {this.state.entity_type == 'product' ? this.state.available_limit_product : this.state.available_limit_room}
                    name={this.state.entity_name}
                    visible={this.state.payment_confirmation_modal}
                    // setSubscriptionModal={isALL3DEntity ? setSubscriptionModal : undefined}
                    amount={<span>${this.state.entity_type == "product" ? this.state.entity_price_product : this.state.entity_price_room}</span>}
                    // onCancel={() => { this.setState({loading_cancel:true}); this.loadUserRequestStatusProduct(); this.loadUserRequestStatusRooms(); this.state.callback_function(false,this.state.entity_id)}}
                    text={this.countLimitFull()  ? "In order to proceed with the request for adding this entity to your library, please pay the required amount." : 'Are you sure you want to add this item to your library?'}
                    footer={[
                    <span style={{display: 'flex', justifyContent:'center'}}>
                        <Button 
                        loading={this.state.loading_cancel}
                        disabled={this.state.button_loader} 
                        className="modal-okay-gray font-14 square" onClick={() => {this.setState({loading_cancel:true});this.loadUserRequestStatusProduct(); this.loadUserRequestStatusRooms(); this.state.callback_function(false,this.state.entity_id)}}>
                        Cancel
                        </Button>
                        {this.state.card_status_product == 1?
                        <Button 
                        disabled={this.state.button_loader || this.state.card_save_flow_triggered} 
                        className="modal-okay font-14 square" 
                        onClick={this.processSubmission}>
                        <span>
                        {
                            this.state.entity_type == 'product' ? 
                            (this.state.requestStatusProduct == "payment_required" ? "Confirm & Process Payment" : "Confirm")
                            :
                            (this.state.requestStatusRoom == "payment_required" ? "Confirm & Process Payment" : "Confirm" )
                        }
                        {this.state.button_loader == true ? <LoadingOutlined type="sync" spin />: ''}
                        </span>
                        </Button>
                        :
                        <Button 
                        disabled={this.state.button_loader || this.state.card_save_flow_triggered} 
                        className="modal-okay font-14 square" 
                        onClick={this.saveCardAndProcessPayment}>
                        {
                            this.state.entity_type == 'product' ? 
                            (this.state.requestStatusProduct == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm") 
                            :
                            (this.state.requestStatusRoom == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm") 
                        }
                        {this.state.button_loader == true ? <LoadingOutlined type="sync" spin />: ''}
                        </Button>
                        }
                    </span>]}
                    />
                    <SubscriptionPackageUpdate
                        subscriptionPlan={this.state.subscriptionPlan}
                        setSubscriptionPlan={this.setSubscriptionPlan}
                        currentPackage={''}
                        currentSubscriptionPlan={this.state.subscription_type_product}
                        visible={this.state.subscriptionModal}
                        isSubscriptionActive={this.state.is_subscription_active}
                        requestStatus={this.state.entity_type == 'product' ? this.state.requestStatusProduct : this.state.requestStatusRoom}
                        onCancel={() => this.setSubscriptionModal(false)}
                        heading={<span>You have succeeded the quota for adding {this.state.entity_type == 'product' ? 'product props' : 'space props'} to your library. Do you want to upgrade your current plan?</span>}
                        text={<span>Your current package is selected by default. Select another package to upgrade. </span>}
                        footer={[
                            <div>
                            { 
                            this.state.entity_type== 'product' && this.state.requestStatusProduct == 'update_subscription' ?
                            <span style={{color:"#276DD7"}} className='justify-in-start manrope f-14'>
                                Please upgrade your package to keep using this service. 
                            </span>
                            :
                            this.state.entity_type != 'product' && this.state.requestStatusRoom == 'update_subscription' ?
                            <span style={{color:"#276DD7"}} className='justify-in-start manrope f-14'>
                                Please upgrade your package to keep using this service. 
                            </span>
                            :
                            ''
                            }
                            <span className="justify-in-end">
                            <Button key="back" className="modal-okay-gray square font-14" onClick={() => this.setSubscriptionModal(false)}>
                                Cancel
                            </Button>
                                {(this.state.card_status_product == 1)?
                                    <Button disabled={this.state.subscriptionPlan == '' || this.state.cardSaveFlowTriggered || this.state.button_loader} key="back"  className="modal-okay square font-14" onClick={this.upgradeSubscriptionPlan}>
                                    {this.state.button_loader? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Process Payment </span>}
                                    </Button>
                                    :
                                    <Button disabled={this.state.subscriptionPlan == '' || this.state.cardSaveFlowTriggered || this.state.button_loader} key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={this.initiateSubscriptionConfirmation}>
                                    {this.state.button_loader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Enter Payment Details </span>}
                                    </Button>
                                    }
                            </span>
                            </div>
                        ]}
                        />
                <MspWarningModal 
                    visible = {this.state.is_msp_user_warning_modal}
                    handleModalClose = {this.handleWarningModalClose} />
            </div>
        </CustomerMainLayout>
        )
    }
}

export default AddCollectionFlow;