import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'antd/dist/antd.css';
import '@ant-design/compatible/assets/index.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import './AddProuctLifestyle.scss';
import CustomerMainLayout from '../CustomerMainLayout/CustomerMainLayout';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import RoomTypeSelect from './RoomTypeSelect';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import PaymentConfirmationModal from '../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import SceneName from './SceneName';
import SelectSpace from './SelectSpace';
import SelectScene from './SelectScene';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate';

const AddProductLifestyleFlow = (props) => {
  const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
  const CUSTOMER_USERNAME = localStorage.getItem('username');
  const COMPANY_ID = localStorage.getItem('company_id');;
  const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
  const [step, setStep] = useState(1);
  const [isStore, setIsStore] = useState(false);
  const [roomType, setRoomType] = useState('');
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
  const [paymentFailureModal, setPaymentFailureModal] = useState(false);
  const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
  const [relatedScenes, setRelatedScenes] = useState([]);
  const [inAppPurchaseLimitModal, setInAppPurchaseLimitModal] = useState(false);
  const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [itemType, setItemType] = useState('space');
  const [requestStatusProduct, setRequestStatusProduct] = useState(null);
  const [cardStatusProduct, setCardStatusProduct] = useState(null);
  const [requestStatusRoom, setRequestStatusRoom] = useState(null);
  const [requestStatusScene, setRequestStatusScene] = useState(null);
  const [requestType, setRequestType] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityRoomPrice, setEntityRoomPrice] = useState(-1);
  const [entityProductPrice, setEntityProductPrice] = useState(-1);
  const [entityScenePrice, setEntityScenePrice] = useState(-1);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [disablePopup, setDisablePopup] = useState(false);
  const [currentPackage, setCurrentPackage] = useState('');
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [purchaseTypeProduct, setPurchaseTypeProduct] = useState('');
  const [purchaseTypeRoom, setPurchaseTypeRoom] = useState('');
  const [usedCountProducts, setUsedCountProducts] = useState(0);
  const [availableCountProducts, setAvailableCountProducts] = useState(0);
  const [usedCountRooms, setUsedCountRooms] = useState(0);
  const [availableCountRooms, setAvailableCountRooms] = useState(0);
  const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
  const [isALL3DEntity, setIsALL3DEntity] = useState(true);
  const [purchaseTypeTemplate, setPurchaseTypeTemplate] = useState(null);
  const [usedCountTemplates, setUsedCountTemplates] = useState(null);
  const [availableCountTemplates, setAvailableCountTemplates] = useState(null);
  const [cardStatusTemplate, setCardStatusTemplate] = useState(null);
  const [paymentRequestID, setPaymentRequestID] = useState(-1);
  const [libraryPayload, setLibraryPayload] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [isSubscriptionUpdateRequest, setIsSubscriptionUpdateRequest] = useState(false);
  const [subsriptionTypeRoom, setSubscriptionTypeRoom] = useState('');
  const [subscriptionTypeTemplates, setSubscriptionTypeTemplates] = useState(null);
  const [cardStatusRoom, setCardStatusRoom] = useState(null);
  const [gatewayPayload, setGatewayPayload] = useState(null);
  const [redirectionPath, setRedirectionPath] = useState(null);

  const {
    match: { params },
  } = props;
  const productId = params?.id ?? '';

  useEffect(() => {
    let redirection_path = new URLSearchParams(window.location.search).get("origin");
    setRedirectionPath(redirection_path);
}, []);

  const handleSubmit = (sceneName) => {
    setLoading(true);
    if (roomType === 'space') {
      const roomId = selectedRoom.room_id;
      createCollection(sceneName, roomId);
    } else if (!isStore && roomType === 'scene') {
      const { collection_id, id } = selectedRoom;
      addProductToCollection(collection_id, sceneName, null, null, id);
    } else getSceneRelatedDetail();
  };

  const createCollection = (collectionName, roomId) => {
    const payload = {
      collection_name: collectionName,
      username: localStorage.getItem('username'),
    };

    if (
      FileConstants.isMSProvider &&
      localStorage.getItem('managed_customer_username') != null
    ) {
      payload.designed_for = localStorage.getItem('managed_customer_username');
    }


    if (COMPANY_ID != undefined) {
      payload.company_id = COMPANY_ID;
    }

    axios
        .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
        .then((res) => {
          addRoomToCollection(res.data.collection_id, collectionName, roomId);
        })
        .catch(() => {
          setLoading(false);
        });
  };

  const addProductToCollection = (
      collectionId,
      sceneName,
      roomId,
      areas,
      sceneId,
  ) => {
    const payload = {
      product_id: productId,
      collection_id: collectionId,
    };

    if (areas?.length) {
      payload.area_names = [areas[0]];
    }

    axios
        .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
        .then((response) => {
          if (roomType == 'space') {
            createScene(collectionId, sceneName, roomId);
          } else {
            window.location.href = '/scene_builder/' + sceneId + '?origin=/home';
          }
        })
        .catch(() => {
          setLoading(false);
        });
  };

  const createScene = (collectionId, sceneName, roomId) => {
    // setCreateSceneLoader(true);
    {
      const payload = {
        scene_name: sceneName,
        collection_id: collectionId,
        room_id: roomId,
        username: localStorage.getItem('username'),
      };

      if (
        FileConstants.isMSProvider &&
        (localStorage.getItem('managed_customer_username') != null ||
          MANAGED_COMPANY_ID != null)
      ) {
        payload.designed_for = localStorage.getItem('managed_customer_username');
      }

      if (COMPANY_ID != undefined) {
        payload.company_id = COMPANY_ID;
      }

      axios
          .post(ENVIRONMENT.UPDATE_SCENE, payload)
          .then((res) => {
            const sceneId = res.data['scene_id'];
            window.location.href = '/scene_builder/' + sceneId + '?origin=/home';
          })
          .catch(() => {
            setLoading(false);
          });
    }
  };

  const processSubmission = (payload = libraryPayload) => {
    setButtonLoader(true);
    axios
        .post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then((response) => {
          setButtonLoader(false);
          if (response.data.status != undefined && response.data.status == 1) {
            const { collection_id, scene_id } = response.data.action_response;
            addProductToCollection(
                collection_id,
                libraryPayload.request_payload.name,
                null,
                null,
                scene_id,
            );
          } else {
            if (
              'request_allowed' in response.data &&
            response.data['request_allowed'] == 'not_allowed'
            ) {
              setPaymentConfirmationModal(false);
              setInAppLimitExceededModal(true);
              setSelectedRoom(null);
            } else {
              setPaymentFailureMessage(response.data.error_message);
              setPaymentFailureModal(true);
            }
          }
        })
        .catch((error) => {
          setButtonLoader(false);
        });
  };

  const updatePaymentDetailsOnFailure = () => {
    window.addEventListener(
        'card_saved',
        (e) => {
          const action = e.detail.action;
          setCardSaveFlowTriggered(false);
          if (action == 'save_card') {
            message.success('Payment Method Updated Successfully.');
          }
        },
        false,
    );

    setPaymentFailureModal(false);
    const child_window = window.open(
        '/save-card?action=save_card',
        '',
        'width=600,height=600',
    );
    const refreshInterval = setInterval(() => {
      if (child_window.closed) {
        setCardSaveFlowTriggered(false);
        clearInterval(refreshInterval);
      }
    }, 500);
    setCardSaveFlowTriggered(true);
  };

  const loadUserRequestStatusRooms = (confirm_request = null) => {
    const check_payload = {
      username: localStorage.getItem('username'),
      action: 'check',
      request_type: 'room_prop',
    };
    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
      setPurchaseTypeRoom(res.data['purchase_type']);
      setUsedCountRooms(res.data['usage_count']);
      setAvailableCountRooms(res.data['available_limit']);
      setSubscriptionTypeRoom(res.data['subscription_plan']);
      setRequestStatusRoom(res.data['request_allowed']);
      setCardStatusRoom(res.data['card_status']);
      setEntityRoomPrice(res.data['price']);
      if (requestType == 'room_prop' && confirm_request != null) {
        setConfirmRequest(true);
      }
    });
  };

  const loadUserRequestStatusScenes = (confirm_request = null) => {
    const check_payload = {
      username: localStorage.getItem('username'),
      action: 'check',
      request_type: 'scene_template_prop',
    };
    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
      setPurchaseTypeTemplate(res.data['purchase_type']);
      setUsedCountTemplates(res.data['usage_count']);
      setAvailableCountTemplates(res.data['available_limit']);
      setSubscriptionTypeTemplates(res.data['subscription_plan']);
      setRequestStatusScene(res.data['request_allowed']);
      setCardStatusTemplate(res.data['card_status']);
      setEntityScenePrice(res.data['price']);
      if (requestType == 'scene_template_prop' && confirm_request != null) {
        setConfirmRequest(true);
      }
    });
  };

  const countLimitFull = () => {
    if (itemType == 'products') {
      return usedCountProducts == usedCountProducts + availableCountProducts;
    } else if (itemType == 'spaces') {
      return usedCountRooms == usedCountRooms + availableCountRooms;
    } else {
      return usedCountTemplates == usedCountTemplates + availableCountTemplates;
    }
  };

  const upgradeSubscriptionPlan = () => {
    const payload = {
      username: localStorage.getItem('username'),
      subscription_type: subscriptionPlan,
    };
    setButtonLoader(true);
    axios
        .post(ENVIRONMENT.UPGRADE_SUBSCRIPTION, payload)
        .then((response) => {
          setSubscriptionModal(false);
          setButtonLoader(false);
          setPaymentConfirmationModal(false);
          setConfirmationModal(true);
          setTimeout(() => {
            loadUserRequestStatusRooms();
            loadUserRequestStatusScenes();
          }, 3000);
          message.success(
              'Your subscription plan has been successfully upgraded.',
          );
          processSubmission();
        })
        .catch((error) => {});
  };

  const initiateConfirmation = () => {
    window.addEventListener(
        'card_saved',
        (e) => {
          const action = e.detail.action;
          setCardSaveFlowTriggered(false);
          if (
            action == 'product_prop' ||
          action == 'room_prop' ||
          action == 'scene_template_prop'
          ) {
            processSubmission();
          }
        },
        false,
    );

    if (requestType == 'room_prop') {
      if (
        requestStatusRoom == 'allowed' ||
        requestStatusRoom == 'always_allowed'
      ) {
        processSubmission();
      }
      if (requestStatusRoom == 'payment_required') {
        if (cardStatusProduct == 0) {
          const child_window = window.open(
              '/save-card?action=' + requestType,
              '',
              'width=600,height=600',
          );
          const refreshInterval = setInterval(() => {
            if (child_window.closed) {
              setCardSaveFlowTriggered(false);
              clearInterval(refreshInterval);
            }
          }, 500);
          setCardSaveFlowTriggered(true);
        }
        if (cardStatusProduct == 1) {
          processSubmission();
        }
      }
    }
    if (requestType == 'scene_template_prop') {
      if (
        requestStatusScene == 'allowed' ||
        requestStatusScene == 'always_allowed'
      ) {
        processSubmission();
      }
      if (requestStatusScene == 'payment_required') {
        if (cardStatusTemplate == 0) {
          const child_window = window.open(
              '/save-card?action=' + requestType,
              '',
              'width=600,height=600',
          );
          const refreshInterval = setInterval(() => {
            if (child_window.closed) {
              setCardSaveFlowTriggered(false);
              clearInterval(refreshInterval);
            }
          }, 500);
          setCardSaveFlowTriggered(true);
        }
        if (cardStatusTemplate == 1) {
          processSubmission();
        }
      }
    }
  };

  const initiateSubscriptionConfirmation = () => {
    window.addEventListener(
        'card_saved',
        (e) => {
          const action = e.detail.action;
          setCardSaveFlowTriggered(false);
          if (action == 'subscription_update') {
            upgradeSubscriptionPlan();
          }
        },
        false,
    );

    if (
      requestType == 'product_prop' ||
      requestType == 'room_prop' ||
      requestType == 'scene_template_prop'
    ) {
      if (cardStatusProduct == 1) {
        upgradeSubscriptionPlan();
      }
      if (cardStatusProduct == 0) {
        setIsSubscriptionUpdateRequest(true);
        const child_window = window.open(
            '/save-card?action=subscription_update',
            '',
            'width=600,height=600',
        );
        const refreshInterval = setInterval(() => {
          if (child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);
          }
        }, 500);
        setCardSaveFlowTriggered(true);
      }
    }
  };

  const addRoomToCollection = (collectionId, sceneName, roomId) => {
    const payload = {
      room_id: roomId,
      collection_name: sceneName,
      collection_id: collectionId,
      add_to_library: {
        room_id: roomId,
        username: localStorage.getItem('username'),
      },
      username: localStorage.getItem('username'),
    };
    const gateway_payload = {
      username: localStorage.getItem('username'),
      request_type: 'room_prop',
      action: 'perform',
      update: '',
      request_payload: payload,
      price_category: '',
      request_id: Date.now().toString(),
    };
    setGatewayPayload(gatewayPayload);
    if (!isStore || selectedRoom.price == 0 || selectedRoom.price == '') {
      processRequestSubmission(gateway_payload);
    } else {
      if (
        requestStatusRoom == 'always_allowed' ||
        requestStatusRoom == 'allowed'
      ) {
        processRequestSubmission(gateway_payload);
      } else if (requestStatusRoom == 'payment_required') {
        if (cardStatusRoom == 1) {
          processRequestSubmission(gateway_payload);
        }
        if (cardStatusRoom == 0) {
          setGatewayPayload(gateway_payload);
          setLoading(false);
          window.addEventListener(
              'card_saved',
              (e) => {
                const action = e.detail.action;
                setCardSaveFlowTriggered(false);
                if (action == 'room_prop') {
                  processRequestSubmission(gateway_payload);
                }
              },
              false,
          );

          const child_window = window.open(
              '/save-card?action=room_prop',
              '',
              'width=600,height=600',
          );
          const refreshInterval = setInterval(() => {
            if (child_window.closed) {
              setCardSaveFlowTriggered(false);
              clearInterval(refreshInterval);
            }
          }, 500);
          setCardSaveFlowTriggered(true);
        }
      }
    }
  };

  const getCollectionPayload = (collectionID) => {
    const payload = {
      required_fields: ['id', 'space_areas'],
      order_by: 'id desc',
    };
    let filter_string = '';
    const customer_username =
      'customer_username__exact=\'' + CUSTOMER_USERNAME + '\'';
    let company_id = '';
    const is_hidden = 'is_hidden__not=true';
    const has_access_to =
      'has_access_to__like=\'%"' + CUSTOMER_USERNAME + '"%\'';

    if (
      SHARED_ENTITIES != undefined &&
      COMPANY_ID &&
      SHARED_ENTITIES.split('_').includes('collection')
    ) {
      company_id = 'company_id__exact=' + COMPANY_ID;
      filter_string =
        '(' +
        company_id +
        '||' +
        customer_username +
        ')&&(' +
        is_hidden +
        ')&&(template_collection_id__is=null)';
    } else {
      filter_string =
        '(' +
        customer_username +
        '&&' +
        is_hidden +
        ')&&(template_collection_id__is=null)';
    }
    filter_string =
      filter_string +
      '||(' +
      has_access_to +
      ')||(template_collection_id__not=NULL&&' +
      customer_username +
      '&&' +
      is_hidden +
      ')';
    filter_string = filter_string + '&&(id__exact=' + collectionID + ')';
    payload['filter_string'] = filter_string;

    return payload;
  };

  const processRequestSubmission = (gateway_payload = gatewayPayload) => {
    if (!isStore || selectedRoom.price == 0 || selectedRoom.price == '0') {
      axios
          .post(ENVIRONMENT.UPDATE_COLLECTION, gateway_payload.request_payload)
          .then((res) => {
            const areas = res.data.space_areas;
            addProductToCollection(
                gateway_payload.request_payload.collection_id,
                gateway_payload.request_payload.collection_name,
                gateway_payload.request_payload.room_id,
                areas,
                null,
            );
          });
    } else {
      axios
          .post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
          .then((response) => {
            if (
              'request_allowed' in response.data &&
            response.data['request_allowed'] == 'not_allowed'
            ) {
              setInAppLimitExceededModal(true);
              setLoading(false);
            } else if (
              response.data.status != undefined &&
            response.data.status == 1
            ) {
              loadUserRequestStatusRooms();
              collectionRoomUpdateCallback(
                  gateway_payload.request_payload.collection_id,
                  gateway_payload.request_payload.collection_name,
                  gateway_payload.request_payload.room_id,
              );
            } else {
              setPaymentFailureMessage(response.data.error_message);
              setPaymentFailureModal(true);
              setLoading(false);
            }
          })
          .catch((error) => {
            message.error('An error occured while adding space to collection.');
          });
    }
  };

  const collectionRoomUpdateCallback = (
      collectionId,
      collectionName,
      roomId,
  ) => {
    const payload = getCollectionPayload(collectionId);
    axios.post(ENVIRONMENT.LIST_COLLECTION, payload).then((res) => {
      let areas = [];
      res.data.map((col) => {
        if (col.id == collectionId) {
          areas = col.space_areas;
        }
      });
      addProductToCollection(collectionId, collectionName, roomId, areas, null);
    });
  };

  const getSceneRelatedDetail = (id) => {
    const payload = {
      action: 'check',
      scene_template_id: selectedRoom.scene_id,
      customer_username: CUSTOMER_USERNAME,
    };
    if (COMPANY_ID) {
      payload.company_id = COMPANY_ID;
    }

    axios
        .post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
        .then((res) => {
          setRelatedScenes(res.data);
          addToLibraryScene();
        })
        .catch((error) => {});
  };

  const addToLibraryScene = () => {
    const { id, price, brand, name } = selectedRoom;
    setEntityName(name);
    setIsALL3DEntity(true);
    setEntityScenePrice(price);
    setRequestType('scene_template_prop');
    const payload = {
      action: 'add_to_library',
      id: id,
      name: name,
      requested_by: localStorage.getItem('username'),
    };
    if (COMPANY_ID) {
      payload.company_id = COMPANY_ID;
    }
    const gateway_payload = {
      username: localStorage.getItem('username'),
      request_type: 'scene_template_prop',
      action: 'perform',
      request_payload: payload,
      category: '',
      request_id: Date.now().toString(),
    };
    if (price == 0) {
      addFreeScene(gateway_payload);
    } else {
      setEntityScenePrice(price);
      if (brand == FileConstants.ALL3D_COMPANY) {
        if (requestStatusScene == 'update_subscription') {
          // update subscription plan modal here
          setSubscriptionModal(true);
          setLibraryPayload(gateway_payload);
          setLoading(false);
        }
        if (
          requestStatusScene == 'allowed' ||
          requestStatusScene == 'always_allowed' ||
          (relatedScenes && relatedScenes.length > 0)
        ) {
          setLibraryPayload(gateway_payload);
          setLoading(false);
        }
        if (requestStatusScene == 'payment_required') {
          setPaymentConfirmationModal(true);
          setLibraryPayload(gateway_payload);
          setLoading(false);
        }
      } else {
        setIsALL3DEntity(false);
        setRequestStatusScene('payment_required');
        setPaymentConfirmationModal(true);
        setLibraryPayload(gateway_payload);
        setLoading(false);
      }
    }
  };

  const addFreeScene = (gatewayPayload) => {
    const payload = gatewayPayload.request_payload;
    const id = payload.id;
    payload.action = 'add_to_library_free';

    axios.post(ENVIRONMENT.ADD_FREE_ITEMS_TO_LIBRARY, payload).then((res) => {
      setPaymentConfirmationModal(false);
      addProductToCollection(
          res.data.collection_id,
          gatewayPayload.request_payload.name,
          null,
          null,
          res.data.scene_id,
      );
    });
  };

  useEffect(() => {
    if (confirmRequest) {
      initiateConfirmation();
    }
  }, [confirmRequest]);

  useEffect(() => {
    if (roomType === 'space') {
      loadUserRequestStatusRooms();
    } else loadUserRequestStatusScenes();
  }, [roomType]);

  return (
    <CustomerMainLayout selected="4">
      <div>
        {step == 1 && (
          <div>
            <RoomTypeSelect
              productId={productId}
              setStep={setStep}
              setRoomType={setRoomType}
            />
          </div>
        )}
        {step == 2 && (
          <div>
            {roomType == 'space' ? (
              <SelectSpace
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
                setStep={setStep}
                setLoading={setLoading}
                loading={loading}
                roomType={roomType}
                setIsStore={setIsStore}
                requestStatusRoom={requestStatusRoom}
              />
            ) : (
              <SelectScene
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
                setStep={setStep}
                handleSubmit={handleSubmit}
                setLoading={setLoading}
                loading={loading}
                roomType={roomType}
                setIsStore={setIsStore}
              />
            )}
          </div>
        )}
        {step == 3 && (
          <div>
            <SceneName handleSubmit={handleSubmit} setStep={setStep} loading = {loading} />
          </div>
        )}
        <InAppPurchaseLimitModal
          visible={inAppLimitExceededModal}
          setVisible={setInAppLimitExceededModal}
        />
        <SubscriptionPackageUpdate
          subscriptionPlan={subscriptionPlan}
          setSubscriptionPlan={setSubscriptionPlan}
          currentPackage={currentPackage}
          currentSubscriptionPlan={currentPackage}
          isSubscriptionActive={isSubscriptionActive}
          visible={subscriptionModal}
          requestStatus={
            requestType == 'product_prop' ?
              requestStatusProduct :
              requestStatusRoom == 'room_prop' ?
              requestStatusRoom :
              requestStatusScene
          }
          onCancel={() => setSubscriptionModal(false)}
          heading={
            <span>
              You have succeeded the quota for adding{' '}
              {requestType == 'product_prop' ? 'product props' : 'space props'}{' '}
              to your library. Do you want to upgrade your current plan?
            </span>
          }
          text={
            <span>
              Your current package is selected by default. Select another
              package to upgrade.{' '}
            </span>
          }
          footer={[
            <div key="footer">
              {requestType == 'product_prop' &&
              requestStatusProduct == 'update_subscription' ? (
                <span
                  style={{ color: '#276DD7' }}
                  className="justify-in-start manrope f-14"
                >
                  Please upgrade your package to keep using this service.
                </span>
              ) : requestType == 'room_prop' &&
                requestStatusRoom == 'update_subscription' ? (
                <span
                  style={{ color: '#276DD7' }}
                  className="justify-in-start manrope f-14"
                >
                  Please upgrade your package to keep using this service.
                </span>
              ) : requestType == 'scene_template_prop' &&
                requestStatusScene == 'update_subscription' ? (
                <span
                  style={{ color: '#276DD7' }}
                  className="justify-in-start manrope f-14"
                >
                  Please upgrade your package to keep using this service.
                </span>
              ) : (
                ''
              )}
              <span className="justify-in-end">
                <Button
                  key="back"
                  className="modal-okay-gray square font-14"
                  onClick={() => setSubscriptionModal(false)}
                >
                  Cancel
                </Button>
                {cardStatusProduct == 1 ? (
                  <Button
                    disabled={
                      subscriptionPlan == '' ||
                      cardSaveFlowTriggered ||
                      buttonLoader
                    }
                    key="back"
                    className="modal-okay square font-14"
                    onClick={upgradeSubscriptionPlan}
                  >
                    {buttonLoader ? (
                      <span>
                        Upgrading Plan <LoadingOutlined spin />
                      </span>
                    ) : (
                      <span>Confirm & Process Payment </span>
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={
                      subscriptionPlan == '' ||
                      cardSaveFlowTriggered ||
                      buttonLoader
                    }
                    key="back"
                    style={{ textAlign: 'center' }}
                    className="modal-okay square font-14"
                    onClick={initiateSubscriptionConfirmation}
                  >
                    {buttonLoader ? (
                      <span>
                        Upgrading Plan <LoadingOutlined spin />
                      </span>
                    ) : (
                      <span>Confirm & Enter Payment Details </span>
                    )}
                  </Button>
                )}
              </span>
            </div>,
          ]}
        />
        <PaymentFailureModal
          paymentFailureMessage={paymentFailureMessage}
          setPaymentFailureModal={setPaymentFailureModal}
          updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
          paymentFailureModal={paymentFailureModal}
        />
        <PaymentConfirmationModal
          storeProp={true}
          itemType={itemType}
          purchaseType={
            itemType == 'products' ?
              purchaseTypeProduct :
              itemType == 'space' ?
              purchaseTypeRoom :
              purchaseTypeTemplate
          }
          usedCount={
            itemType == 'products' ?
              usedCountProducts :
              itemType == 'space' ?
              usedCountRooms :
              usedCountTemplates
          }
          availableCount={
            itemType == 'products' ?
              availableCountProducts :
              itemType == 'space' ?
              availableCountRooms :
              availableCountTemplates
          }
          name={entityName}
          disable={disablePopup}
          visible={paymentConfirmationModal}
          setSubscriptionModal={
            isALL3DEntity ? setSubscriptionModal : undefined
          }
          amount={
            <span>
              $
              {itemType == 'products' ?
                entityProductPrice :
                itemType == 'space' ?
                entityRoomPrice :
                entityScenePrice}
            </span>
          }
          onCancel={() => {
            setPaymentConfirmationModal(false);
            loadUserRequestStatusRooms();
            loadUserRequestStatusScenes();
          }}
          text={
            countLimitFull() ?
              'In order to proceed with the request for adding this entity to your library, please pay the required amount.' :
              'Are you sure you want to add this item to your library?'
          }
          footer={[
            <span
              key="footer"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                disabled={buttonLoader}
                className="modal-okay-gray font-14 square"
                onClick={() => {
                  setPaymentConfirmationModal(false);
                  loadUserRequestStatusRooms();
                  loadUserRequestStatusScenes();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={buttonLoader || disablePopup || cardSaveFlowTriggered}
                className="modal-okay font-14 square"
                onClick={initiateConfirmation}
              >
                {itemType == 'products' ?
                  cardStatusProduct == 1 &&
                    requestStatusProduct == 'payment_required' ?
                    'Confirm & Process Payment' :
                    cardStatusProduct == 0 &&
                      requestStatusProduct == 'payment_required' ?
                    'Confirm & Enter Payment Details' :
                    'Confirm' :
                  itemType == 'spaces' ?
                  cardStatusProduct == 1 &&
                    requestStatusRoom == 'payment_required' ?
                    'Confirm & Process Payment' :
                    cardStatusProduct == 0 &&
                      requestStatusRoom == 'payment_required' ?
                    'Confirm & Enter Payment Details' :
                    'Confirm' :
                  cardStatusTemplate == 1 &&
                    requestStatusScene == 'payment_required' ?
                  'Confirm & Process Payment' :
                  cardStatusTemplate == 0 &&
                    requestStatusScene == 'payment_required' ?
                  'Confirm & Enter Payment Details' :
                  'Confirm'}
                {buttonLoader == true ? (
                  <LoadingOutlined type="sync" spin />
                ) : (
                  ''
                )}
              </Button>
            </span>,
          ]}
        />
      </div>
    </CustomerMainLayout>
  );
};

export default AddProductLifestyleFlow;
