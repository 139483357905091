import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import DottedLoader from '../../DottedLoader';
import { connect } from 'react-redux';
import CustomHeader from '../../CustomHeader';
import { Layout } from 'antd';
import './ArtistAssignmentResults.scss';
import ArtistTestPass from './ArtistTestPass';
import ArtistTestFail from './ArtistTestFail';
import FileConstants from '../../../../FileConstants';

const { Header, Content } = Layout;
const TEST_STATE = FileConstants.ARTIST_TEST_STATES

function ArtistAssignmentResultsContainer() {
  const [status, setStatus] = useState(TEST_STATE.PENDING);
  const [isLoading, setIsLoading] = useState(false);
  const [colorAccuracy, setColorAccuracy] = useState(null);
  const [dimensionalAccuracy, setDimensionalAccuracy] = useState(null);
  const [uvAccuracy, setUvAccuracy] = useState(null);
  const [vrayAccuracy, setVrayAccuracy] = useState(null);
  const [pbrAccuracy, setPbrAccuracy] = useState(null);
  const [geometryAccuracy, setGeometryAccuracy] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [billingType, setBillingType] = useState(null);

  useEffect(() => {
    const payload = {
      username: localStorage.getItem('username'),
    };
    setIsLoading(true);
    axios.post(ENVIRONMENT.GET_ARTIST_TEST, payload).then((res) => {
      console.log(res.data);
      console.log(res.data.status);

      setColorAccuracy(res.data.color_accuracy);
      setVrayAccuracy(res.data.vray_accuracy);
      setPbrAccuracy(res.data.pbr_accuracy);
      setDimensionalAccuracy(res.data.dimension_accuracy);
      setAdditionalNotes(res.data.additional_notes);
      setUvAccuracy(res.data.uv_accuracy);
      setGeometryAccuracy(res.data.geometry_accuracy);
      setHourlyRate(res.data.hourly_rate);
      setBillingType(res.data.billing_type);
      const timeStarted = new Date(res.data.time_started);
      let deadline = new Date(res.data.time_started);
      let timeNow = new Date();
      deadline.setDate(timeStarted.getDate() + 2);
      let offset=timeNow.getTimezoneOffset();
      deadline.setMinutes(deadline.getMinutes()-offset);
      deadline = deadline - timeNow;
      if (res.data.status === TEST_STATE.SUCCESSFUL) {
        setIsLoading(false);
        setStatus(TEST_STATE.SUCCESSFUL);
      } else if (res.data.status === TEST_STATE.FAILED) {
        setIsLoading(false);
        setStatus(TEST_STATE.FAILED);
      } else if (res.data.status === TEST_STATE.IN_PROGRESS && deadline > 0) {
        window.location.href='/artist_assignment';
      } else if (res.data.status === TEST_STATE.IN_PROGRESS && deadline <= 0) {
        setIsLoading(false);
        setStatus(TEST_STATE.IN_PROGRESS);
      } else if (res.data.status === TEST_STATE.NOT_STARTED) {
        window.location.href = '/artist_test';
      } else {
        setIsLoading(false);
        setStatus(TEST_STATE.PENDING);
      }
    });
  }, []);

  // 0 Wait - 1 Pass - 2 Fail - 3 Time Expired
  return (
    <>
      {isLoading ? (
        <DottedLoader />
      ) : (
        <>
          <Header className="header">
            <CustomHeader></CustomHeader>
          </Header>
          <Layout style={{ minHeight: "calc(100vh - 55px)" }}>
            {status == TEST_STATE.PENDING ? (
              <Content
                className="layout-container overflowY-hidden layout-snow"
                id="layout-section"
              >
                <div className="results-container">
                  <img
                    className="results-img"
                    alt="Artist Results Waiting"
                    src={require("../../../../assets/images/waiting.png")}
                  />
                  <h1 className="manrope f-16 w-600 mb-10 ">
                    Your test submission is being evaluated.
                  </h1>
                  <p className="manrope f-16 w-400 mb-28 text-center letter-spacing-pt2">
                    This may take a while. Thank you for your patience.
                  </p>
                </div>
              </Content>
            ) : status == TEST_STATE.SUCCESSFUL ? (
              <ArtistTestPass
                colorAccuracy={colorAccuracy}
                dimensionalAccuracy={dimensionalAccuracy}
                uvAccuracy={uvAccuracy}
                vrayAccuracy= {vrayAccuracy}
                pbrAccuracy = {pbrAccuracy}
                geometryAccuracy={geometryAccuracy}
                additionalNotes={additionalNotes}
                hourlyRate={hourlyRate}
                billingType={billingType}
              />
            ) : status == TEST_STATE.FAILED ? (
              <ArtistTestFail
                colorAccuracy={colorAccuracy}
                dimensionalAccuracy={dimensionalAccuracy}
                uvAccuracy={uvAccuracy}
                vrayAccuracy= {vrayAccuracy}
                pbrAccuracy = {pbrAccuracy}
                geometryAccuracy={geometryAccuracy}
                additionalNotes={additionalNotes}
              />
            ) : (
               <Content
                 className="layout-container overflowY-hidden layout-snow"
                 id="layout-section"
               >
                 <div className="results-container">
                   <img
                     className="results-img"
                     alt="Artist Results Waiting"
                     src={require("../../../../assets/images/waiting.png")}
                   />
                   <h1 className="manrope f-16 w-600 mb-10 ">
                    Time for your test has been expired.
                   </h1>
                   <p className="manrope f-16 w-400 mb-28 text-center letter-spacing-pt2">
                    Want to apply again? Reach out to us at <a href="#">support@all3d.ai</a>
                   </p>
                 </div>
               </Content>
            )}
          </Layout>
        </>
      )}
    </>
  );
}

ArtistAssignmentResultsContainer.propTypes = {
  user: PropTypes.object,
  attributes: PropTypes.object,
  state: PropTypes.string,
};

const mapStateToProps = (state) => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});

const ArtistAssignmentResults = connect(
    mapStateToProps,
    null,
)(ArtistAssignmentResultsContainer);

export default ArtistAssignmentResults;
