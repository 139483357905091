import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Row, Col, Button, Select } from "antd";
import AdminMainLayout from '../AdminMainLayout';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';

import ComplaintsTable from "../ComplaintsTable";

const { TabPane } = Tabs;
const { Option } = Select;


class AdminComplaints extends React.Component {
  constructor(props){
    super(props)
  
    this.state = {
      size: "small"
    };  
  }
 

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"13"}>
        
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false} style={{marginTop: 30}}>
          <TabPane tab="Unassigned" key="1">
                <ComplaintsTable state="pending_unassigned"/>
          </TabPane>
          <TabPane tab="Assigned" key="2">
                <ComplaintsTable state="pending_assigned"/>
          </TabPane>
          <TabPane tab="In Progress" key="3">
                <ComplaintsTable state="in_progress"/>
          </TabPane>
          <TabPane tab="Rejected" key="4">
                <ComplaintsTable state="rejected"/>
          </TabPane>
          <TabPane tab="Admin QA" key="5">
              <ComplaintsTable state="admin_qa"/>
          </TabPane>
          <TabPane tab="Resolved" key="6">
              <ComplaintsTable state="resolved"/>
          </TabPane>
        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminComplaints;