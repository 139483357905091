import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Tabs, DatePicker, Select, Collapse, Tooltip, Button, message } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import SystemUsage from '../../AdminComponents/SystemUsage/SystemUsage';
import moment from 'moment';
import ManagedServices from '../../ManagedServices/ManagedServices';
import { InfoCircleOutlined,ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import SharedItemUsage from '../../SharedItemUsage/SharedItemUsage';
import SystemUsageSummary from '../../SystemUsageSummary/SystemUsageSummary';
import _ from "lodash" // Import the entire lodash library
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
//mport backArrow from 'C:/Users/Tintash/Desktop/All3D-Frontend/all3d-frontend/public/img/arrow-back.png'

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const IS_MS_PROVIDER = FileConstants.isMSProvider;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const DATE_FORMAT = 'MM/DD/YYYY';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const CustomerAccountUsage = (props) => {
    const [startDate, setStartDate] = useState(moment().startOf('month').format(DATE_FORMAT));
    const [endDate, setEndDate] = useState(moment().endOf('month').format(DATE_FORMAT));
    const [customerUsername, setCustomerUsername] = useState('');
    const [companyID, setCompanyID] = useState(-1);
    const [accountType, setAccountType] = useState("customer");
    const [dateFilterType, setDateFilterType] = useState('created_on');
    const [tabKey, setTabKey] = useState('usage_details');
    const [collapseKey, setCollapseKey] = useState(["details"]);
    const [displayData, setDisplayData] = useState(true);
    const [totalCount, setTotalCount] = useState([]);
    const [summaryLoader, setSummaryLoader] = useState(true);
    const [dataType, setDataType] = useState("overall");
    const [exportedDataLoader, setExportedDataLoader] = useState(false);
    
    const handlebackclick=()=>{
        props.history.goBack();
    }

    useEffect(() => {
        if (props.account_type == 'admin') {
            setAccountType(props.account_type);
            setCustomerUsername(props.username);
            setCompanyID(props.company_id);
        } else {
            // Show data for selected customer in managed service provider account
            if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null)) {
                setCustomerUsername(MANAGED_CUSTOMER_USERNAME);
                if (MANAGED_COMPANY_ID != null) {
                    setCompanyID(MANAGED_COMPANY_ID);
                }
            } else {
                if (COMPANY_ID) {
                    setCompanyID(COMPANY_ID);
                } else if (CUSTOMER_USERNAME) {
                    setCustomerUsername(CUSTOMER_USERNAME);
                }
            }
        }
    }, []);

    const onCalenderChange = (date_values) => {
        if (date_values) {
            if (date_values[0]) {
                setStartDate(date_values[0].format(DATE_FORMAT));
            }
            if (date_values[1]) {
                setEndDate(date_values[1].format(DATE_FORMAT));
            }
        }
    }

    const checkIfDateIsSameMonth = (new_start_date, new_end_date) => {
        if (new_start_date && new_end_date && dateFilterType == 'billing_date') {
            let start_date = moment(new_start_date, DATE_FORMAT);
            let startOfCurrentMonth = moment().startOf('month').format(DATE_FORMAT)
            let isCurrentMonthYearRange = start_date.isSameOrAfter(startOfCurrentMonth, 'day');
            setDisplayData(!isCurrentMonthYearRange);
        } else {
            setDisplayData(true);
        }
    }
    
    useEffect(() => {
        checkIfDateIsSameMonth(startDate, endDate)
    }, [endDate, startDate, dateFilterType])
   
    const onChangeDateType = (key) => {
        setDateFilterType(key);
    }

    const onChangeTab = (key) => {
        setTabKey(key);
    }

    const prepareSystemUsageCounts = (usage_counts) => {
        let regex = /(1k|2k|3k|4k)/;
        let total_count = {};
        let request_type_count = {};
        // Initialize total count for each type of service to 0
        FileConstants.typesOfServices.map((key) => {
            total_count[key] = 0;
        });

        let originalArray = _.cloneDeep(usage_counts); // Create a deep clone of the original usage_counts array

        // Iterate through each type of service
        FileConstants.typesOfServices.map((key) => {
            let objectsToMove = [];
            let new_object = {};

            // Iterate through each count_data object in usage_counts
            usage_counts.map(count_data => {

                // Extract the base request type without resolution value
                // e.g., for scene_lifestyle_512x321, extract 'scene_lifestyle'
                const lastIndex = count_data['request_type'].lastIndexOf('_');
                const result = count_data['request_type'].substring(0, lastIndex);
                const containsSearchString = FileConstants.SERVICES_RESOLUTION_REQUESTS[key].some(searchString => result.startsWith(searchString) && !regex.test(count_data['request_type']));
                // if request has custom res, then add custom res request data to request_type+'_custom' key in dictionary
                if (containsSearchString) {
                    if (!objectsToMove[result+"_custom"]) {
                        objectsToMove[result+"_custom"] = [];
                    } 
                    objectsToMove[result+"_custom"].push(count_data);
                    
                    
                    if (!new_object[result + "_custom"]) {
                        new_object[result+"_custom"] = {};
                    } 
                    // get total count of request
                    let totalRequestCount = objectsToMove[result+"_custom"].reduce((acc, obj) => acc + obj.request_count, 0);
                    new_object[result+"_custom"] = {
                        "request_type": result + "_custom",
                        "request_count": totalRequestCount,
                        "children": objectsToMove[result+"_custom"]
                    }

                    // Remove original objects with custom resolution from originalArray because they are being added to _custom attribute
                    objectsToMove[result+"_custom"].forEach(obj => {
                        const index = originalArray.findIndex(arrObj => arrObj.request_type === obj.request_type);
                        if (index !== -1) {
                            originalArray.splice(index, 1);
                        }
                    });

                    // Replace or add the new object to originalArray
                    originalArray = replaceObjectByRequestType(originalArray, new_object[result+"_custom"]);
                }
            });
        });

        // Iterate through originalArray to compute total counts and request_type_count
        originalArray.map((count_data) => {
            FileConstants.typesOfServices.map((key) => {
                const containsSearchString = FileConstants.SERVICES_RESOLUTION_REQUESTS[key].some(searchString => count_data['request_type'].startsWith(searchString) && !regex.test(count_data['request_type']));
                if (!request_type_count[key]) {
                    request_type_count[key] = []
                }
                if (FileConstants.SERVICES_LIST[key].includes(count_data['request_type']) || (containsSearchString)) {
                    total_count[key] = total_count[key] + parseInt(count_data['request_count']);
                    request_type_count[key].push(count_data);
                }
            })
        })

        // Set the total count and request_type_count using provided props
        setTotalCount(total_count);
        return request_type_count;
    }


    const replaceObjectByRequestType = (array, newObject) => {
        // Find the index of the object with the matching request_type
        const index = array.findIndex(obj => obj.request_type === newObject.request_type);
        
        if (index !== -1) {
            // If an object with the same request_type exists, replace it
            array[index] = newObject;
        } else {
            // Otherwise, add the new object to the array
            array.push(newObject);
        }
        return array;
    }

    const convertToCSV = (data, includeHeaders = true) => {
        if (data.length === 0) {
            return null;
        }
        const header = Object.keys(data[0]);
        const csv = includeHeaders ? [header.join(',')] : [];
        for (const item of data) {
            const row = header.map((key) => {
                let cell = item[key];
                if (typeof cell === 'object' && cell !== null) {
                    // If the value is an object (e.g., 'raw_files' or 'perspective_renders'), flatten and stringify it
                    cell = JSON.stringify(cell);
                } else if (typeof cell != 'string') {
                    cell = cell.toString();
                } else if (cell === null) {
                    // If the value is null, convert it to an empty string
                    cell = 'NULL';
                }

                // Wrap the cell in double quotes and handle escaping
                cell = `"${cell.replace(/"/g, '""')}"`;
                cell = cell.replace(/,/g, ' , ');
                cell = `"${cell.replace(/[\[\]"']/g, '')}"`;
                return cell;
            });
            csv.push(row.join(','));
        }

        const csvString = csv.join('\n');
        return csvString;
    };

    const handleDownloadMultipleCSV = async (records, entityType, zip) => {
        if (records.length > 0) {
            records = records.reduce((acc, val) => acc.concat(val), []);

        }
        const combinedCSVData = records.map((record, index) => {
            const includeHeaders = index === 0;
            return convertToCSV([record], includeHeaders);
        });

        if (combinedCSVData.length > 0) {
            const combinedCSV = combinedCSVData.join('\n');
            zip.file(`${entityType}.csv`, combinedCSV);
        }
    };

    const exportedData = () => {
        setExportedDataLoader(true);
        let loader = message.loading("Please wait, while we prepare data for export...", 0);

        const zip = new JSZip();
        const promises = FileConstants.typesOfServices.map(async (service, index) => {
            if (index == 0) {
                let summary_payload = {
                    action: "get_counts",
                    date_filter_type: dateFilterType,
                    start_date: startDate,
                    end_date: endDate,
                    data_type: "overall"
                };
                if (companyID != -1) {
                    summary_payload.company_id = companyID;
                } else {
                    summary_payload.customer_username = customerUsername;
                }
                const resSummary = await axios.post(ENVIRONMENT.GET_SYSTEM_USAGE, summary_payload);
                if (resSummary.data) {
                    await handleDownloadMultipleCSV(resSummary.data, "summary", zip);
                }
            }
            if (totalCount[service] > 0) {
                let detail_payload = {}
                detail_payload.request_type = service;
                detail_payload.date_filter_type = dateFilterType;
                detail_payload.action = 'get_details';
                if (companyID != -1) {
                    detail_payload.company_id = companyID;
                } else {
                    detail_payload.customer_username = customerUsername;
                }
                detail_payload.start_date = startDate;
                detail_payload.end_date = endDate;
                detail_payload.data_type = "overall";

                try {
                    const res = await axios.post(ENVIRONMENT.GET_SYSTEM_USAGE, detail_payload);
                    if (res.data && service !== "managed_services") {
                        await handleDownloadMultipleCSV(res.data[service], service, zip);
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        });


        Promise.all(promises).then(() => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, startDate+'_'+endDate+'.zip');
                setTimeout(loader);
                setExportedDataLoader(false);
            });
        }).catch(err => {
            console.log(err);
            setExportedDataLoader(false);
        });
    }

    return (
        <>
            <Row className="justify-space-between" style={{ marginTop: "20px", marginBottom: 20 }}>
                <Col span={24} className='justify-space-between'>
                    <Col>
                        <div className='manrope f-24 w-700' style={{ display: 'flex', alignItems: "center" }}>
                        <img src={`${process.env.PUBLIC_URL}/img/back_arrow.png`} alt="Arrow Back" style={{cursor:'pointer',marginRight:5}} onClick={handlebackclick} />
                            Usage History
                        </div>
                    </Col>
                    <Col lg={10} xl={10} className="justify-in-end">
                        <div className='manrope f-14 black-55' style={{ marginRight: 8 }}>
                            Date Filter
                        </div>
                        <RangePicker size={"large"} defaultValue={[moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT)]}
                            format={DATE_FORMAT} className="date-picker" popupStyle={{ fontFamily: 'Manrope', fontSize: 14, color: "#40a9ff", background: "#e6efff" }} placeholder={['Date (From)', 'Date (To)']}
                            renderExtraFooter={() => <p className='date-footer'><InfoCircleOutlined />&nbsp; {FileConstants.DATE_FILTER_INFO[tabKey]}</p>}
                            value={[moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT)]}
                            onCalendarChange={val => onCalenderChange(val)} />

                        <Select
                            className="manrope f-14 black-55 tree-select-material"
                            placeholder="Filter By Date"
                            onChange={onChangeDateType}
                            defaultValue={"created_on"}
                            defaultActiveFirstOption={true}
                            style={{ width: 160, marginLeft: 10 }}
                            dropdownMatchSelectWidth={false}
                            placement={"bottomRight"}
                            options={accountType == "admin" ? FileConstants.DATE_FILTERS_ADMIN : FileConstants.DATE_FILTERS_CUSTOMER} />
                            <Button 
                            className="modal-okay square font-16 ml-10"
                            onClick={exportedData}
                            disabled={exportedDataLoader || !displayData || summaryLoader}
                            loading={exportedDataLoader}>Export to CSV</Button>
                    </Col>
                </Col>
            </Row>

            <Collapse
                destroyInactivePanel={false}
                defaultActiveKey={collapseKey} style={{ marginTop: 32 }} className="collapse-panel bg-df" ghost
                expandIconPosition={'right'}>
                <Panel
                    forceRender={true}
                    style={{ marginBottom: 10 }}
                    header={<span className="manrope f-16 black-55 w-700">Summary &nbsp; <Tooltip
                        title={<span className='manrope f-14 white'>This summary is only for your Usage.</span>}>
                        <InfoCircleOutlined />
                    </Tooltip></span>}
                    key={"summary"}>
                    <SystemUsageSummary
                        prepareSystemUsageCounts={prepareSystemUsageCounts}
                        dataType={dataType}
                        summaryLoader={summaryLoader}
                        setSummaryLoader={setSummaryLoader}
                        totalCount={totalCount}
                        setTotalCount={setTotalCount}
                        display_data={displayData}
                        date_filter_type={dateFilterType}
                        username={customerUsername}
                        company_id={companyID}
                        start_date={startDate}
                        end_date={endDate} />
                </Panel>
                <Panel
                    forceRender={true}
                    style={{ marginBottom: 10 }}
                    header={<span className="manrope f-16 black-55 w-700">Details</span>}
                    key={"details"}>
                    <Tabs onChange={onChangeTab}
                        style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }} defaultActiveKey={['usage_details']} className="product-tab library" tabBarGutter={16} size="large">
                        <TabPane tab={`${IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null) ? MANAGED_CUSTOMER_USERNAME + "'s" : "Your"} Usage`} key={"usage_details"} style={{ padding: "10px" }}>
                            <SystemUsage
                                dataType={dataType}
                                summaryLoader={summaryLoader}
                                totalCount={totalCount}
                                display_data={displayData}
                                date_filter_type={dateFilterType}
                                start_date={startDate}
                                end_date={endDate}
                                username={customerUsername}
                                account_type={accountType}
                                company_id={companyID} />
                        </TabPane>

                        {/* Commenting code for now - might need it later */}
                        {/* <TabPane tab={'Managed Services'} key={"managed_services"} style={{ padding: "10px" }}>
                            <ManagedServices start_date={startDate} end_date={endDate}
                                account_type={accountType}
                                usage_type={companyID == -1 ? "customer" : "company"}
                                data_value={companyID == -1 ? customerUsername : companyID} />
                        </TabPane> */}

                        <TabPane tab={'Shared Items'} key={"shared_items"} style={{ padding: "10px" }}>
                            <SharedItemUsage
                                display_data={displayData}
                                date_filter_type={dateFilterType}
                                start_date={startDate}
                                end_date={endDate}
                                username={customerUsername}
                                account_type={accountType}
                                company_id={companyID} />
                        </TabPane>
                    </Tabs>
                </Panel>
            </Collapse>
        </>
    );

}

export default withRouter(CustomerAccountUsage);
