import React, {useState, useEffect} from 'react';
import { InfoCircleOutlined} from '@ant-design/icons';
import { Modal, Button, Tooltip} from 'antd';

const CheckListModal = (props) => {
    const [checkedState, setCheckedState] = useState([]);
    const [enableSubmit, setEnableSubmit] = useState(false)

    const setCheckedValues = (index) => {
        let newCheckedState = [...checkedState];
        newCheckedState[index] = !checkedState[index]
        setCheckedState(newCheckedState)
        let areAllChecked = true
        for(let i in newCheckedState) {
            areAllChecked = areAllChecked && newCheckedState[i]
        }   
        setEnableSubmit(areAllChecked)
    }

    useEffect(() => {
        setCheckedState(new Array(props.checklistItems.length).fill(false))
      }, []);

    return (
        <Modal
        className="checklist-modal"
        centered={true}
        width={600}
        visible={props.isVisible}
        bodyStyle={{padding: 0}}
        onCancel={props.hideModal}
        footer={[
            <div className="justify-in-end">
                <Button  className="modal-okay-orange square font-14 pd-20" onClick={props.hideModal}>
                    Cancel
                </Button>
                <Button disabled={!enableSubmit} className="modal-okay square font-14" htmlType="submit" onClick={props.onSubmit}>
                    {props.submitText}
                </Button>
            </div>
         ]}
        >  
        <div className="d-flex p-20 p-all" style={{justifyContent:"space-between"}}>
        <h2 className="manrope f-18 black-33 w-700">Verify Checklist 
            <Tooltip title={<span className='manrope f-12 white' >Click on the tutorial link to learn how to QA a space</span>}>
            <InfoCircleOutlined style={{marginLeft:10}}></InfoCircleOutlined>
            </Tooltip>
        </h2>
        <a href={props.tutorialLink} target="_blank" style={{marginRight: 30, color:"#276DD7"}}>
            <img style={{width: 16,height:16, marginRight:5}} src={require("../../../assets/images/redirect-icon.png")}></img>
            tutorial
        </a>
        </div>
        <div className="checklist-container">
        {props.checklistItems.map((item, index)  => (
            <div className="p-10 p-bottom">
                <input id={"checklist-checkbox" + index} className="styled-checkbox" style={{width:15, height:15}} type="checkbox"
                name={item}
                value={item}
                checked={checkedState[index]}
                onChange={()=>{setCheckedValues(index)}}
                />
                <label for={"checklist-checkbox" + index}></label>
                <span className="side-drawer-text medium">{item}</span>
                <Tooltip title={<span className='manrope f-12 white' >{props.checklistInstructionLogs[index]}</span>}>
                    <InfoCircleOutlined style={{marginLeft:10, opacity:0.6}}></InfoCircleOutlined>
                </Tooltip>
            </div>
        ))}
        </div>
    </Modal>
      );
}

export default CheckListModal;