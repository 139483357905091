import React from 'react';
import { Table, Modal, message, Menu, Dropdown, Spin,Button,Input,Space } from 'antd';
import axios from 'axios';
import { ExclamationCircleOutlined, DownOutlined, SearchOutlined} from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader';
import FileConstants from '../../../../FileConstants'
import { bold } from 'colorette';

const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

const INSTANCE_TYPE_PRICES = {
    'c5.2xlarge':0.8,
    'c5.4xlarge':1.7,
    'c5.9xlarge':2.9,
    'c5.12xlarge':4.7,
    'c5.24xlarge':9.6,
    "c6a.2xlarge" : 0.77,
    "c6a.4xlarge" : 1.63,
    "c6a.12xlarge" : 4.5,
    "c6a.24xlarge" : 9.19,
    't2.large': 0.1,
    't2.medium':0.05,
}

const PRIORITY_LABELS = {
  '1': 'High',
  '2': 'Medium',
  '3': 'Low'
}
const PRIORITY_FILTER_OPTIONS = Object.keys(PRIORITY_LABELS).map(key => ({
  text: PRIORITY_LABELS[key],
  value: PRIORITY_LABELS[key]
}));
const COST_TYPE_LABELS= {
  'plain_render': 'Plain Render',
  'scene_lifestyle_render': 'Scene LifeStyle Render',
  'scene_lifestyle_360': 'Scene 360 Render',
  'custom_dimensional_render': 'Custom Dimensional Render',
  'silo_render' : 'Silo Render',
  'dimensional_render' :'Dimensional Render',
  'perspective_render' : 'Perspective Render',
  'test_render': 'Test Render',
  'exporting_optimized_glb': 'Optimized GLB',
  'exporting_glb' : 'GLB',
  'exporting_obj' : 'OBJ',
  'quick_render' : "Quick Render",
  'room_360' : 'Room 360 Render',
  'product_configuration_silo': 'Product Configuration Renders'
}

const COLUMNS = [
  {
    title: "Step Number",
    dataIndex: "step",
    key: "step"
  },
  {
    title: "Step",
    dataIndex : "type",
    key: "type"
  },
  {
    title: "Time",
    dataIndex: "time",
    key:"time"
  },
  {
    title: "Render Height",
    dataIndex : "render_height",
    key:"render_height"
  },
  {
    title:"Render Width",
    dataIndex :"render_width",
    key:"render_width"
  }
]

const UPDATE_STATUS_REQUEST_TABLE = ['material_renders', 'product_base_assets', 'product_asset_convertor', 'product_quick_renders', 'room_base_assets', 'room_quick_renders']

class AutomationRequestsTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    showHeader,
    footer: false,
    scroll: undefined,
    tableLayout: 'fixed',
    hasData: true,
    dataSource: null,
    jobStartTime: 'hide',
    jobEndTime: 'hide',
    jobCompletionTime: 'hide',
    regenerateAction: 'hide',
    awaitingJonCacnelAction: 'hide',
    inprogressJonCacnelAction: 'hide',
    showPriority:'hide',
    costRequestData:null,
    showCostModal: false,
    loader : true,
    filter_entity_types:[],
    filter_request_types:[],
    filter_customer_types:[],
    filter_priority_types:PRIORITY_FILTER_OPTIONS,
  };

  getColumns = () => [
    {
      title: 'Request ID',
      dataIndex: 'request_id',
      key: 'request_id',
      sorter: (a, b) => a.request_id - b.request_id,
      ...this.getColumnSearchProps('request_id','Request ID'),
      render: text => this.state.jobCompletionTime == "show"? <span><a onClick={()=>{this.getCostIndividual(text)}}>{text}</a></span>: <span>{text}</span> ,
    },
    {
      title: 'Entity Type',
      dataIndex: 'entity_type',
      key: 'entity_type',
      filters: this.state.filter_entity_types,
      onFilter: (value, record) => record.entity_type === value,
      render: text => <span>{text}</span>,
    },
    {
        title: 'Entity Id',
        dataIndex: 'entity_id',
        key: 'entity_id',
        sorter: (a, b) => {
          const entityIdA = a.entity_id.split('/').slice(-1)[0];
          const entityIdB = b.entity_id.split('/').slice(-1)[0];
          return parseFloat(entityIdA) - parseFloat(entityIdB);
        },      
        ...this.getColumnSearchProps('entity_id','Entity ID'),
        render: text => <span><a href={text} target="_blank">{text.split('/').slice(-1)[0]}</a></span>,
    },
    {
        title: 'Request Type',
        dataIndex: 'request_type',
        key: 'request_type',
        filters: this.state.filter_request_types,
        //onFilter: (value, record) => record.request_type === value,
        onFilter: (value, record) => {
            return record.request_type === value;
        },
      
        render: (text, record) => {
          const values = text.split('+');
          return (
            <span>
            {(FileConstants.REQUEST_TYPE_LABELS[text] != undefined) ? FileConstants.REQUEST_TYPE_LABELS[text]: text} 
            <br/> {record.additional_info} </span>)},
    },
    {
      title: 'Submitted at',
      dataIndex: 'enqueue_time',
      key: 'enqueue_time',
      render: text => <span>{text}</span>,
    },
    {
        title: 'Wait Time',
        dataIndex: 'start_time',
        key: 'start_time',
        className: this.state.jobStartTime,
        render: text => <span>{text}</span>,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      filters: this.state.filter_priority_types,
      onFilter: (value, record) => PRIORITY_LABELS[record.priority] === value,
      className: this.state.showPriority,
      render: (text, record) => (
        <Dropdown overlay={this.getMenu(record.request_id)} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {PRIORITY_LABELS[text]} <DownOutlined />
          </a>
        </Dropdown>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer_username',
      key: 'customer_username',
      filters: this.state.filter_customer_types,
      onFilter: (value, record) => record.customer_username === value,
      render: text => <span>{text}</span>,
    },
    {
        title: 'Time and Cost',
        dataIndex: 'time_taken',
        key: 'time_taken',
        className: this.state.jobCompletionTime,
        render: text => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'cancal_waiting_job',
      dataIndex: 'cancal_waiting_job',
      className: this.state.awaitingJonCacnelAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.cancelAwaitingRequestComfirm(record.request_id)}>Abort</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'cancal_inprogress_job',
      dataIndex: 'cancal_inprogress_job',
      className: this.state.inprogressJonCacnelAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.cancelInprogressRequestComfirm(record.request_id)}>Abort</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      className: this.state.regenerateAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.regenerateRequestComfirm(record.request_id, record.entity_id.split('/').slice(-1)[0] , record.entity_type, record.request_type)}>Regenerate</a>
        </span>
      ),
    }
  ];

  // Search in table functionality
  getColumnSearchProps = (dataIndex,placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? 'Here is title' : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? 'Here is footer' : undefined });
  };

  handleScrollChange = enable => {
    this.setState({ scroll: enable ? { y: 240 } : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };
  getRenderType= (type) => {
    return COST_TYPE_LABELS[type];
  }

  getDataSource= (costData) => {
    let dataSource = [];
    costData.map((value,index)=> {
      let dataSourceObject = {
        "key": index+1,
        "step":index+1,
        "type" :this.getRenderType(value.unit_cost_type),
        "time" : (Math.floor(value.time_seconds/60) + ( Math.floor(value.time_seconds/60)==1 ?" min " : " mins ") + value.time_seconds%60 + ( value.time_seconds%60 ==1 ? " sec." : " secs.") ),
        "render_width": value.render_width > 0 ? value.render_width : "-",
        "render_height": value.render_height > 0 ? value.render_height : "-" 
      }
      dataSource.push(dataSourceObject)
    })
    return dataSource
  }

  getCostHTML = (costData) => {
    let detailedCostData= null;
    let dataSource = this.getDataSource(costData.data)
    if( costData.data && costData.data.length > 0 ){
      detailedCostData = (
        <div style={{marginTop:3, textAlign:"center"}}>
          <span className='note-bg-artist' style={{fontWeight : "Bold"}} >Cost Breakdown</span>
          <Table style={{marginTop:15}} dataSource={dataSource} columns={COLUMNS}/>
        </div>
      )
    }
    return detailedCostData
  }

  getCostIndividual = (requestID) => {
    let payload = {"request_id" : String(requestID)}
    let costData = null
    this.setState({
      showCostModal : true,
      loader: true
    })
    axios.post(ENVIRONMENT.GET_COST_AUTOMATION_REQUEST,payload)
      .then(res => {
        costData = this.getCostHTML(res);
        if(costData == null){
          costData = <div><span>No further cost breakdown for this request.</span></div>
        }
        this.setState({
          costRequestData : costData,
          loader : false
        })
      })
  }

  getMenu = (request_id) => {
    return (
      <Menu>
        <Menu.Item key="3" onClick={() => this.confirmPrioritySet(request_id, "3")}>Low</Menu.Item>
        <Menu.Item key="2" onClick={() => this.confirmPrioritySet(request_id, "2")}>medium</Menu.Item>
        <Menu.Item key="1" onClick={() => this.confirmPrioritySet(request_id, "1")}>High</Menu.Item>
      </Menu>
    );
  }

  confirmPrioritySet = (request_id, priority) => {
    Modal.confirm({
      title: 'Are you sure you change priority of this job?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.setJobPriority(request_id, priority);
      },
      onCancel() {
      },
    });
  }

  setJobPriority = (request_id, priority) => {
    let payload = {
      automation_request_id: request_id,
      priority: priority
    }

    axios.post(ENVIRONMENT.AUTOMATION_REQUEST_UPDATE, payload)
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Priority Updated Successfully.');
        // window.location.href = '/automation_requests';
      })
  
  }

  cancelAwaitingRequestComfirm(request_id) {
    Modal.confirm({
      title: 'Are you sure you want to cancel this job?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.cancelAwaitingRequest(request_id);
      },
      onCancel() {
      },
    });
  }

  cancelAwaitingRequest = (request_id) => {
    let payload = {
      automation_request_id: request_id,
      status: 'aborted',
      username: localStorage.getItem('username'),
    }

    console.log(payload);
    
    axios.post(ENVIRONMENT.AUTOMATION_REQUEST_UPDATE, payload)
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Request is aborted successfully.');
        window.location.href = '/automation_requests';
      })
  }

  cancelInprogressRequestComfirm(request_id) {
    Modal.confirm({
      title: 'Are you sure you want to cancel this job?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.cancelInprogressRequest(request_id);
      },
      onCancel() {
      },
    });
  }

  cancelInprogressRequest = (request_id) => {
    let payload = {
      automation_request_id: request_id,
      status: 'aborted',
      username: localStorage.getItem('username'),
      inprogress_job: 1   //This flag is passed to backend so proper actions can be taken if already running job is being aborted
    }

    console.log(payload);
    
    axios.post(ENVIRONMENT.AUTOMATION_REQUEST_UPDATE, payload)
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Request is aborted successfully.');
        window.location.href = '/automation_requests';
      })
  }
  
  regenerateRequestComfirm(request_id, entity_id, entity_type, request_type) {
    Modal.confirm({
      title: 'Are you sure you want to regenerate this product?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        this.regenerateRequest(request_id, entity_id, entity_type, request_type);
      },
      onCancel() {
      },
    });
  }

  updateAutomationRequest = (request_id) =>{
    let payload = {
      request_id: request_id
    }
    
    axios.post(ENVIRONMENT.AUTOMATION_REQUEST_REGEN, payload)
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Request regenerate has been submitted successfully.');
        window.location.href = '/automation_requests';
      })
  }

  regenerateRequest = (request_id, entity_id, entity_type, request_type) => {

    if (UPDATE_STATUS_REQUEST_TABLE.includes(request_type)) {
      let url = ''
      let payload = { model_status: 6 }
      if (['product', 'room'].includes(entity_type)) {
        const isProduct = entity_type === 'product';
        url = isProduct ? ENVIRONMENT.UPDATE_PRODUCT: ENVIRONMENT.UPDATE_ROOM
        payload = {
          model_status: 6,
          username: localStorage.getItem("username"),
          [isProduct ? 'product_id' : 'room_id']: entity_id,
        }

      }else if (entity_type == 'material') {
        url = ENVIRONMENT.UPDATE_MATERIAL
        payload = {
          status: 6,
          username: localStorage.getItem("username"),
          material_id: entity_id
        }

      }

      axios.post(url, payload)
      .then(res => {
        this.updateAutomationRequest(request_id);
      })

    } else {
      this.updateAutomationRequest(request_id)
    }
  }

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    if (this.props.state === 'waiting') {
      this.handleRequestLoadData('enqueued');
    }
    if (this.props.state === 'being_processed') {
        this.handleRequestLoadData('inprogress');
    }
    if (this.props.state === 'completed') {
        this.handleRequestLoadData('completed_successfully');
    }
    if (this.props.state === 'failed') {
        this.handleRequestLoadData('failed');
    }
    if (this.props.state === 'aborted') {
        this.handleRequestLoadData('aborted');
    }

  }

  timestampToTimeSince = (timestamp) => {
    let timeSince = ""
    let currentTimestamp = new Date()
    
    var difference = currentTimestamp - (new Date(timestamp));
    difference += (currentTimestamp.getTimezoneOffset()*1000*60);
    console.log("Difference",difference);

    var daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    var hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60

    var minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60

    if (minutesDifference  <= 1) {
        timeSince = "a minute ago"
    } else {
        timeSince = minutesDifference + " minutes ago"
    }

    if (hoursDifference != 0) {
        if (hoursDifference  == 1) {
            timeSince = "an hour ago"
        } else {
            timeSince = hoursDifference + " hours ago"
        }
    }

    if (daysDifference != 0) {
        if (daysDifference  == 1) {
            timeSince = "a day ago"
        } else {
            timeSince = daysDifference + " days ago"
        }
    }

    
    return timeSince; 
}

checkJobCostWorstCase = (timestamp1, timestamp2, instance_type) => {
    var difference = (new Date(timestamp2)) - (new Date(timestamp1));

    var daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    var hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60
    hoursDifference = hoursDifference + 1;
    let cost = hoursDifference * INSTANCE_TYPE_PRICES[instance_type];

    return cost;
}

checkJobCostBestCase = (timestamp1, timestamp2, instance_type) => {
  var difference = (new Date(timestamp2)) - (new Date(timestamp1));

  var daysDifference = Math.floor(difference/1000/60/60/24);
  difference -= daysDifference*1000*60*60*24

  var hoursDifference = Math.floor(difference/1000/60/60);
  difference -= hoursDifference*1000*60*60

  var minutesDifference = Math.floor(difference/1000/60);
  difference -= minutesDifference*1000*60

  minutesDifference = minutesDifference +  (hoursDifference * 60)
  let cost = (minutesDifference/60) * INSTANCE_TYPE_PRICES[instance_type];

  return cost;
}

timestampToTimeTaken = (timestamp1, timestamp2) => {
    let timeTaken = ""
    
    var difference = (new Date(timestamp2)) - (new Date(timestamp1));

    var daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24

    var hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60

    var minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60

    if (minutesDifference  <= 1) {
        timeTaken = "a minute"
    } else {
        timeTaken = "around " + minutesDifference + " minutes"
    }

    if (hoursDifference != 0) {
        if (hoursDifference  == 1) {
            timeTaken = "an hour"
        } else {
            timeTaken = "around " + hoursDifference + " hours"
        }
    }

    if (daysDifference != 0) {
        if (daysDifference  == 1) {
            timeTaken = "a day"
        } else {
            timeTaken = "around " + daysDifference + " days ago"
        }
    }

    
    return timeTaken; 
  }

  getTimeAndCostString = (start_time, end_time, instance_id) => {
      let combined_str = this.timestampToTimeTaken(start_time, end_time);
      combined_str = combined_str + " ($" +  this.checkJobCostBestCase(start_time, end_time, instance_id).toFixed(2);
      combined_str = combined_str + " to $" + this.checkJobCostWorstCase(start_time, end_time, instance_id).toFixed(2) + ")";
      return combined_str;
  }
  handleFilteringOptions = (filter_entity_values,filter_request_values,filter_customer_values,filter_priority_values) => {

    let filter_entity_objects = [];
    filter_entity_values.forEach(filter_entity_value => {
      filter_entity_objects.push({
        text: filter_entity_value,
        value: filter_entity_value,
      })
    });

    this.setState({
      filter_entity_types: filter_entity_objects,
    });

  let filter_request_objects = [];
  let dummySceneRenderAdded = false;

  filter_request_values.forEach(filter_request_value => {
    if (filter_request_value.startsWith('dummy_scene_render+')) {
      if (!dummySceneRenderAdded) {
        filter_request_objects.push({
          text: 'Space Image',
          value: 'dummy_scene_render',
        });
        dummySceneRenderAdded = true;
      }
    } else {
      filter_request_objects.push({
        text: FileConstants.REQUEST_TYPE_LABELS[filter_request_value] || filter_request_value,
        value: filter_request_value,
      });
    }
  });

  this.setState({
    filter_request_types: filter_request_objects,
  });

  

    let filter_customer_objects = [];
    filter_customer_values.forEach(filter_customer_value => {
      filter_customer_objects.push({
        text: filter_customer_value,
        value: filter_customer_value,
      })
    });

    this.setState({
      filter_customer_types: filter_customer_objects,
    });

    let filter_priority_objects = filter_priority_values.map(value => ({
      text: PRIORITY_LABELS[value],
      value: PRIORITY_LABELS[value],
    }));

    this.setState({
     // filter_priority_types: filter_priority_objects,
    });
  }
  handleRequestLoadData = (status) => {
    let filter_entity_values = [];
    let filter_request_values=[];
    let filter_customer_values=[];
    let filter_priority_values=[];
    axios.post(ENVIRONMENT.AUTOMATION_REQUEST_GET_BATCH, {
        request_status: status
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        let entity_value=null;
        let request_value=null;
        let customer_value=null;
        let priority_value=null;
        var data = [];
        for (let i = 1; i <= (res.data).length; i++) {
          
          let entity_id_link = '';
          let entity_type = res.data[i - 1]['entity_type'];
          let request_type=res.data[i - 1]['type'];
          let customer_type=res.data[i - 1]['customer_username'];
          let priority_type=res.data[i-1]['priority'];
         
          if (entity_type == 'product') {
            entity_id_link = '/admin_products/' + res.data[i - 1]['entity_id'];
          }
          else if (entity_type == 'room') {
            entity_id_link = '/admin_rooms/' + res.data[i - 1]['entity_id'];
          }
          else if (entity_type == 'scene') {
            entity_id_link = '/admin_scene_renders/' + res.data[i - 1]['entity_id'];
            //Below is the special case where render is against the scene in automation request
            //but is actually related to space and should be shown on front-end accordingly 
            if (request_type.includes('dummy_scene_render')) {
              const tokens = request_type.split('+')
              if (tokens.length > 1) {
                const room_id = tokens[1]
                entity_id_link = '/admin_rooms/' + room_id;
                request_type = 'Space Render'
              }
            }
          }
          else if (entity_type == 'material') {
            console.log('material', res.data[i - 1]);
            entity_id_link = '/admin_materials/' + res.data[i - 1]['entity_id'];
          }
          else if (entity_type == 'test_product') {
            entity_id_link = '/admin_products/' + res.data[i - 1]['entity_id'];
          }
          else {
            entity_id_link = '#'
          }

          data.push({
            key: i,
            request_id: res.data[i - 1]['request_id'],
            entity_type: res.data[i - 1]['entity_type'],
            entity_id: entity_id_link,
            request_type: request_type,
            enqueue_time: this.timestampToTimeSince(res.data[i - 1]['enqueue_time']),
            start_time: this.timestampToTimeTaken(res.data[i - 1]['enqueue_time'], res.data[i - 1]['start_time']),
            customer_username: (res.data[i - 1]['job_username'] == "") ? res.data[i - 1]['customer_username'] : res.data[i - 1]['job_username'],
            time_taken: this.getTimeAndCostString(res.data[i - 1]['start_time'], res.data[i - 1]['end_time'], res.data[i - 1]['instance_type']),
            priority: res.data[i - 1]['priority'],  // Use the raw priority value ('1', '2', '3')
            additional_info: res.data[i - 1]['additional_info']
          });          
          entity_value = res.data[i - 1]['entity_type'];
          request_value=res.data[i - 1]['type'];
          customer_value=res.data[i-1]['customer_username'];
          priority_value=res.data[i - 1]['priority'];
          if (entity_value && !filter_entity_values.includes(entity_value)) {
            filter_entity_values.push(entity_value);
          }
          if (request_value && !filter_request_values.includes(request_value)) {
            filter_request_values.push(request_value);
          }
          if (customer_value && !filter_customer_values.includes(customer_value)) {
            filter_customer_values.push(customer_value);
          }
          if (priority_value && !filter_priority_values.includes(priority_value)) {
            filter_priority_values.push(priority_value);
          }
        this.handleFilteringOptions(filter_entity_values,filter_request_values,filter_customer_values,filter_priority_values);


        }

        let jobStartTime = 'hide';
        let jobEndTime = 'hide';
        let jobCompletionTime = 'hide';
        let regenerateAction = 'hide';
        let inprogressJonCacnelAction = 'hide';
        if (status == 'completed_successfully') {
            jobCompletionTime = 'show';
        }
        if (status == 'aborted' || status == 'failed') {
          regenerateAction = 'show';
        }
        if (status == 'inprogress') {
          inprogressJonCacnelAction = 'show';
        }
        if (status != 'enqueued') {
            jobStartTime = 'show';
            if (status != 'inprogress'){
                jobEndTime = 'show';
            }
            this.setState(
                {
                  jobStartTime: jobStartTime,
                  jobEndTime: jobEndTime,
                  jobCompletionTime: jobCompletionTime,
                  regenerateAction: regenerateAction,
                  inprogressJonCacnelAction: inprogressJonCacnelAction,
                }
            );
        } else {
            this.setState(
            {
              awaitingJonCacnelAction: 'show',
              showPriority: 'show'
            }
        );
        }
        

        this.setState({
          dataSource: data
        })
      })
  }

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {dataSource == undefined ?<DottedLoader/>:""}
        {
          dataSource &&
          <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
        }
        <Modal     
          closeIcon={
              <img src={"/img/closeIcon.png"}  alt="Close Modal Icon" 
              style={{marginTop: 0, cursor:"pointer",borderRadius:"4px"}} 
              />
          }
          
          visible={this.state.showCostModal} width={"60%"} onCancel={() => this.setState({showCostModal:false})} footer={false}>
          {this.state.loader ? <Spin style={{marginLeft:"50%"}}/> : this.state.costRequestData}
        </Modal>
      </div>
    );
  }
}

export default AutomationRequestsTable;
