import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import FileConstants from '../../../../FileConstants';
import '@ant-design/compatible/assets/index.css';
import _ from 'lodash'; // Import the entire lodash library
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Button, Col, Tag, Row, Radio } from 'antd';
import RoomsListVirtualized from './RoomsListVirtualized';
import Filters from './Filters';
import DottedLoader from '../../DottedLoader';
import * as Utilities from '../../Utilities';
import { CheckCircleFilled, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const MANAGED_SHARED_ENTITES = localStorage.getItem("managed_shared_entites");
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const SelectSpace = (props) => {
  const [ownedSpaceData, setOwnedSpaceData] = useState([]);
  const [sharedSpaceData, setSharedSpaceData] = useState([]);
  const [storeSpaceData, setStoreSpaceData] = useState([]);
  const [mspUserData, setMspUserData] = useState([]);
  const [mspSharedData, setMspSharedData] = useState([]);
  const [tabActiveKey, setTabActiveKey] = useState('owned');
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isMSProvider, setIsMSProvider] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const { selectedRoom, setSelectedRoom, setStep, setLoading, multiple_select, setIsStore, isStore,loading, empty_scene, creationType, requestStatusRoom, selectedScenes, setSelectedScenes, customFooter, handleNext, heading, preselectedSpaces, isEdit} = props;
  const [mspFilteredData, setMspFilteredData] = useState([]);
  const [mspSharedFilteredData, setMspSharedFilteredData] = useState([]);
  const [mspSharedTabType, setMspSharedTabType] = useState('all');
  const [ownedFilteredData, setOwnedFilteredData] = useState([]);
  const [sharedFilteredData, setSharedFilteredData] = useState([]);
  const [storeFilteredData, setStoreFilteredData] = useState([]);
  const [storeSpacesLoader, setStoreSpacesLoader] = useState(true);
  const [initialLoadSpaces, setInitialLoadSpaces] = useState(false);
  const [initialLoadShared, setInitialLoadShared] = useState(true);
  const [initialLoadOwned, setInitialLoadOwned] = useState(true);
  const [sharedSpaceLoader, setSharedSpaceLoader] = useState(true);
  const [ownedSpaceLoader, setOwnedSpaceLoader] = useState(true);
  const [tabCount, setTabCount] = useState({owned: -1, other: -1, store: -1, msp: -1, other_msp: -1, project_rooms: -1});
  const [sharedTabType, setSharedTabType] = useState('all');
  const [tabValueSet, setTabValueSet] = useState(false);
  const [projectRooms, setProjectRooms] = useState([]);
  const [projectFilteredData, setProjectFilteredData] = useState([]);
  const [projectDataLoader, setProjectDataLoader] = useState(false);
  const [tabChangeThroughClick, setTabChangeThroughClick] = useState(false);

    const getSpaces = (type) => {
    if (type == "msp") {
      return mspUserData;
    } else if (type == 'other_msp') {
      if (mspSharedTabType == 'all') {
        return mspSharedData;
      } else if (mspSharedTabType == 'shared') {
        const spaces = mspSharedData.filter((space) => (space.shared_room_type == 'shared_by_customer'));
        return spaces;
      } else {
        const spaces = mspSharedData.filter((space) => (space.shared_room_type == 'added_from_store'));
        return spaces;
      }
    } else if (type == "project_rooms") {
      return projectRooms;
    } else if (type == "owned") {
      return ownedSpaceData;
    } else if (type == "store") {
      return storeSpaceData;
    } else {
      if (sharedTabType == 'all') {
        return sharedSpaceData;
      } else if (sharedTabType == 'shared') {
        const spaces = sharedSpaceData.filter((space) => (space.shared_room_type == 'shared_by_customer'));
        return spaces;
      } else {
        const spaces = sharedSpaceData.filter((space) => (space.shared_room_type == 'added_from_store'));
        return spaces;
      }
    }
  };

  const getSpacesLength = (type, tab_options = 'not_all') => {
    if (type == "msp") {
      return mspUserData.length;
    } else if (type == 'other_msp') {
      if (mspSharedTabType == 'all' || tab_options == 'all') {
        return mspSharedData.length;
      }
      else if (mspSharedTabType == 'shared') {
        const spaces = mspSharedData.filter((space) => (space.shared_room_type == 'shared_by_customer'));
        return spaces.length;
      } else {
        const spaces = mspSharedData.filter((space) => (space.shared_room_type == 'added_from_store'));
        return spaces.length;
      }
    } else if (type == "project_rooms") {
      return projectRooms.length;
    } else if (type == "owned") {
      return ownedSpaceData.length;
    } else if (type == "store") {
      return storeSpaceData.length;
    } else {
      if (sharedTabType == 'all' || tab_options == 'all') {
        return sharedSpaceData.length;
      }
      else if (sharedTabType == 'shared') {
        const spaces = sharedSpaceData.filter((space) => (space.shared_room_type == 'shared_by_customer'));
        return spaces.length;
      } else {
        const spaces = sharedSpaceData.filter((space) => (space.shared_room_type == 'added_from_store'));
        return spaces.length;
      }
    }
  };

  const onChangeSharedTabType = (e, type) => {
    if (type == 'other') {
      setSharedTabType(e.target.value);
    } else if ('other_msp') {
      setMspSharedTabType(e.target.value);
    }
    setTabChangeThroughClick(true);
  }
  
  const getRoomTabCount = (type) => {
    return tabCount[type] !== -1 ? tabCount[type] : getSpacesLength(type);
  };

  const updateTabCount = (count, tabName) => {
    setTabCount(prevTabCount => ({
      ...prevTabCount,
      [tabName]: count
    }));
  };

  const getProjectEntitiesPayload = (project_id) => {
    let payload = {
      order_by: "last_modified_stamp desc",
      required_fields: ['room_id', 'room_name', 'brand_id', 'category', 'dimensions',
        'customer_username', 'model_status', 'thumbnail', 'last_modified_stamp', 'variant_of',
        'is_store_item', 'company_id', 'style', 'platform', 'tags', 'price', 'space_areas'],
      filter_string: `(project_id__exact='${project_id}'&&model_status__in=[5, 4])`,
    };

    return payload;
  };

  const getProjectRoomsList = async (project_id) => {
    setProjectDataLoader(true);
    let payload = getProjectEntitiesPayload(project_id);
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then((response) => {
        if (response.data) {
          let spaces_with_keywords = getRoomsDataWithKeywords(response.data);
          setProjectRooms(spaces_with_keywords);
          setProjectDataLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getRoomPayload = (initial, type, sequence_id,  current_customer = CUSTOMER_USERNAME, current_company_id = COMPANY_ID, current_shared_entities = SHARED_ENTITIES, is_customer_data = false) => {
    const payload = {
      'required_fields': ['room_id', 'room_name', 'brand_id', 'category', 'dimensions',
        'customer_username', 'model_status', 'thumbnail', 'last_modified_stamp', 'variant_of',
        'is_store_item', 'company_id', 'style', 'platform', 'tags', 'price', 'space_areas'],
    };
    if (IS_MS_PROVIDER || sequence_id == -1) {
      payload['order_by'] = 'last_modified_stamp desc';
    } else {
      payload['required_fields'].push('folder_id', 'folder_name', 'parent_folder_id',
        'library_order', 'entity_order');
      payload['order_by'] = 'library_order asc,entity_order asc';
    }
    let filter_string = '';
    let shared_username = '';
    let customer_username = "customer_username__exact='" + current_customer + "'"
    let customer_username_notexact = "customer_username__notexact='" + current_customer + "'"
    let company_id_notexact = '';

    let company_id = '';
    const model_status = 'model_status__in=[5]';
    const is_hidden = 'is_hidden__not=true';
    const shared_hidden = 'shared_hidden__not=true';
    const sequence_id_value = 'sequence_id__exact='+sequence_id;
    if (type == 'shared') {
      payload['required_fields'].push('shared_username');
      payload['required_fields'].push('shared_room_type');
      shared_username = "shared_username__exact='" + current_customer + "'"
      filter_string = '('+shared_username + '&&' + shared_hidden + ')';

      if (current_shared_entities != undefined && current_company_id && current_shared_entities.split('_').includes('room')) {
        company_id_notexact = 'company_id__notexact=' + current_company_id + '||company_id__isnull=true';
        filter_string = filter_string + '&&(' + company_id_notexact + ')&&('+customer_username_notexact+')';
      } else {
        filter_string = filter_string + '&&('+customer_username_notexact+')';
      }
    } else if (current_shared_entities != undefined && current_company_id && current_shared_entities.split('_').includes('room')) {
      company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
      filter_string = '('+company_id+'||'+customer_username+')&&(' + is_hidden + ')';
    } else {
      if (IS_MS_PROVIDER && !is_customer_data) {
        company_id = 'company_id__isnull=true';
        const requested_for = 'requested_for__isnull=true';
        filter_string = '('+company_id+'&&'+customer_username+')&&(' + is_hidden + ')&&(' + requested_for + ')';
      } else {
        filter_string = '('+customer_username+ '&&' + is_hidden +')';
      }
    }
    filter_string = filter_string + '&&(' + model_status + ')';
    if (sequence_id != -1) {
      filter_string = filter_string + '&&(' + sequence_id_value + '||sequence_id__isnull=true)';
    }
    filter_string = filter_string + "&&(has_active_project__is=false)"

    payload['filter_string'] = filter_string;
    if (initial) {
      payload['pagination_filters'] = {
        'limit': 100,
        'offset': 0,
      };
    }
    return payload;
  };

  const fetchRoomData = async (type, sequenceId, initial = false) => {
    if (!initial) {
        setDataLoading(true);
      } else {
        if (type == 'shared') {
          setInitialLoadShared(true);
        } else {
          setInitialLoadOwned(true);
        }
    }
    const payload = getRoomPayload(initial, type, sequenceId);
    const data = [];
    await axios.post(ENVIRONMENT.LIST_ROOMS, payload)
        .then((res) => {
          if (res.data) {
            const dataWithKeywords = getRoomsDataWithKeywords(res.data);
            type === 'owned' && setOwnedSpaceData(dataWithKeywords);
            type === 'shared' && setSharedSpaceData(dataWithKeywords);
          }

          if (initial) {
            if (type == 'shared') {
              setInitialLoadShared(false);
            } else {
              setInitialLoadOwned(false);
            }
          } else {
            if (type == 'shared') {
              setSharedSpaceLoader(false);
            } else {
              setOwnedSpaceLoader(false);
            }
          }
        });
    return data;
  };

  const fetchSpaceCategories = () => {
    const payload = {
      'request_type': 'room_model',
    };
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
        .then((res) => {
          let categories = [];
          if (res.data) {
            res.data.map((request) => {
              if (!categories.includes(request.category)) {
                categories.push(request.category);
              }
            });
            categories = categories.filter((item) => item !== '');
            setCategories(categories);
          }
        });
  };

  const fetchStoreRoomData = async (initial = false) => {
    const data = [];
    setStoreSpacesLoader(true);
    let payload = {
      item_type: 'room',
      username: localStorage.getItem('username')
    }
    if (initial) {
      payload.limit = '';
      setInitialLoadSpaces(true);
    }
    axios.post(ENVIRONMENT.STORE_LIST, payload)
        .then((res) => {
          if (!initial) {
            setStoreSpacesLoader(false);
          }
          const dataWithKeywords = getRoomsDataWithKeywords(res.data);
          setStoreSpaceData(dataWithKeywords);
        })
        .catch((error) => {
        });
    return data;
  };

  const getRoomsDataWithKeywords = (rooms) => {
    const result = rooms.map((obj) => {
      let keywords = [];

      for (const keyword of obj.room_name.replace(/[^a-zA-Z0-9 ]/g, ' ').split(' ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase());
          }
        }
      }

      for (const keyword of obj.category.replace(/[^a-zA-Z0-9 ]/g, ' ').split(' ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase());
          }
        }
      }

      if (obj?.style && obj.style != '') {
        keywords.push(obj.style.toLowerCase());
      }

      for (const keyword of obj.tags.split(', ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords = keywords.concat(keyword.toLowerCase().split(' '));
          }
        }
      }

      if (obj.folder_name) {
        keywords = keywords.concat(obj.folder_name.toLowerCase().split(' '));
      }

      return { ...obj, 'keywords': keywords };
    });
    return result;
  };

  const getCustomerDataForMsprovider = async (type) => {
    const payload = getRoomPayload(false, type, -1, MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true);
    await axios.post(ENVIRONMENT.LIST_ROOMS, payload)
        .then((res) => {
          if (res.data) {
            const data = getRoomsDataWithKeywords(res.data);
            type === 'owned' && setMspUserData(data);
            type === 'shared' && setMspSharedData(data);
          }
        });
  };

  const handleTabChange = (key, event_type = 'invoked') => {
    setTabActiveKey(key);
    if (key == 'store') {
      setIsStore(true);
    } else setIsStore(false);

    if (event_type == 'click') {
      setTabChangeThroughClick(true);
    }
  };

  const handleContinue = () => {
    if (empty_scene) {
      setStep(4); // product selection
    } else {
      setStep(3);
    }
    
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchSpaceCategories();
      if (props.project_id) {
        await getProjectRoomsList(props.project_id);
        await fetchStoreRoomData(true);
        setTabActiveKey('project_rooms');
      } else {
        fetchRoomData('owned', -1, true);
        fetchRoomData('shared', -1, true);
        await fetchStoreRoomData(true);
        if (IS_MS_PROVIDER &&
          (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
          await getCustomerDataForMsprovider('owned');
          await getCustomerDataForMsprovider('shared');
          setIsMSProvider(true);
        }
      }
      setDataLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (initialLoadSpaces) {
      fetchStoreRoomData();
    }
  }, [initialLoadSpaces]);

  useEffect(() => {
    if (!initialLoadOwned && ownedSpaceLoader) {
        fetchRoomData('owned', -1);
    }
  }, [initialLoadOwned]);

  useEffect(() => {
    if (!initialLoadShared && sharedSpaceLoader) {
        fetchRoomData('shared', -1);
    }
  }, [initialLoadShared]);

  useEffect(() => {
    setProjectFilteredData(projectRooms);
    setMspFilteredData(mspUserData);
    setMspSharedFilteredData(mspSharedData);
    setOwnedFilteredData(ownedSpaceData);
    setSharedFilteredData(sharedSpaceData);
    setStoreFilteredData(storeSpaceData);
    setIsStore(false)
  }, [mspUserData, ownedSpaceData, sharedSpaceData, storeSpaceData, mspSharedData, projectRooms]);

  const goBack = () => {
    window.location.href = '/my_scenes'
  }

  const tabs = [
    {
      type: 'msp',
      title: `${MANAGED_CUSTOMER_USERNAME}'s Spaces (${getRoomTabCount('msp')}${searchValue != '' || selectedCategories.length > 0 ? ` of ${getSpacesLength('msp')} spaces` : ``})`,
      data: getSpaces('msp'),
      setData: setMspFilteredData,
    },
    {
      type: 'other_msp',
      title: `${MANAGED_CUSTOMER_USERNAME}'s Other Spaces (${getRoomTabCount('other_msp')}${searchValue != '' || selectedCategories.length > 0 || mspSharedTabType != 'all' ? ` of ${getSpacesLength('other_msp', 'all')} spaces` : ``})`,
      data: getSpaces('other_msp'),
      setData: setMspSharedFilteredData,
    },
    {
      type: 'project_rooms',
      title: `Project Spaces  (${getRoomTabCount('project_rooms')}${searchValue != '' || selectedCategories.length > 0  ? ` of ${getSpacesLength('project_rooms')} spaces` : ``})`,
      data: getSpaces('project_rooms'),
      setData: setProjectFilteredData,
    },
    {
      type: 'owned',
      title: `Your Spaces ${ownedSpaceLoader ? '' : `(${getRoomTabCount('owned')}${searchValue != '' || selectedCategories.length > 0 ? ` of ${getSpacesLength('owned')} spaces` : ``})`}`,
      data:  getSpaces('owned'),
      setData: setOwnedFilteredData
    },
    {
      type: 'other',
      title: `Other Spaces ${sharedSpaceLoader ? '' : `(${getRoomTabCount('other')}${searchValue != '' || selectedCategories.length > 0 || sharedTabType != 'all' ? ` of ${getSpacesLength('other', 'all')} spaces` : ``})`}`,
      data:  getSpaces('other'),
      setData: setSharedFilteredData
    },
    {
      type: 'store',
      title: `Store Spaces ${storeSpacesLoader ? `` : `(${getRoomTabCount('store')}${searchValue != '' || selectedCategories.length > 0 ? ` of ${getSpacesLength('store')} spaces` : ``})`}`,
      data:  getSpaces('store'),
      setData: setStoreFilteredData
    },
  ];

  const closeTags = (entity) => {
    const arr = selectedCategories.filter((category) => {
      return entity != category;
    });
    setSelectedCategories(arr);
  };

  const checkFilteredResults = (roomsData) => {
    let data = [];
    if (searchValue != "") {
      data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, roomsData);
    } else if (selectedCategories?.length > 0) {
      data = Utilities.getRelevanceBasedOrderedSearchResult(selectedCategories.join(' '), roomsData);
    }
    return data;
  }

  const switchTabIfEmpty = () => {
    // if current selected tab is empty only then switch to other tab
    if (tabChangeThroughClick == false) {
      if (checkFilteredResults(getSpaces(tabActiveKey))?.length == 0) {
        if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getSpaces("msp"))?.length > 0) {
          setTabActiveKey("msp");
        } else if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getSpaces("other_msp"))?.length > 0) {
          setTabActiveKey("other_msp");
        }  else if (checkFilteredResults(getSpaces("project_rooms"))?.length > 0) {
          setTabActiveKey('project_rooms');
        } else if (checkFilteredResults(getSpaces("owned"))?.length > 0) {
          setTabActiveKey('owned');
        } else if (checkFilteredResults(getSpaces("other"))?.length > 0) {
          setTabActiveKey('other');
        } else if (checkFilteredResults(getSpaces("store"))?.length > 0) {
          setTabActiveKey('store');
        }
      }
    }
  }

  const getFilteredTags = () => {
    let filteredTags = <>{selectedCategories.length > 0 &&
      <Col span={24} style={{ marginLeft: 10 }}>
        <h4 className="manrope f-14 black-55 italic">Showing results for</h4>
        <div style={{ marginBottom: 20 }}>
          {selectedCategories?.map((category) => {
              return (
                <Tag
                  key={category}
                  onClose={() => closeTags(category, 'space_category')}
                  closable
                  visible={true}
                  className="manrope f-12 black-55 tag-filter"
                >
                  Category:{' '}
                  <span className="manrope f-12 grey-77">{category}</span>
                </Tag>
              );
            })}
        </div>
      </Col>}
    </>
    return filteredTags;
  }

  return (
    <div className="spaces-list">
      <Filters
        handleValueChange = { setSelectedCategories }
        selectedValue = {selectedCategories}
        filters = { categories }
        searchValue = { searchValue }
        setSearchValue = { setSearchValue }
        title = {heading ? heading : 'Select a space for your scene'}
        searchPlaceholder = 'Search all spaces'
      />
      {getFilteredTags()}
      <Tabs
        onChange={(key) => handleTabChange(key, 'click')}
        className="product-tab library"
        tabBarGutter={16}
        size="large"
        activeKey={tabActiveKey}
      >
        {(!props.project_id && (initialLoadOwned || initialLoadShared)) || (props.project_id && projectDataLoader) ? (
          <DottedLoader />
        ) : (
        tabs?.map((tab, index)=>{
          return (
            ((index === 0 || index === 1) && !isMSProvider) || (index == 2 && !props.project_id) || (props.project_id && ![2, 5].includes(index)) ? null :
            <TabPane
              forceRender={true}
              tab= {tab.title}
              key={tab.type}>
                {(tab.type == "other" || tab.type == "other_msp") && <Row>
                  <div className="shared-models-tab manrope f-14 black-55">
                    <span className="shared-models-tab-span">
                      Show Items:
                    </span>
                    <Radio.Group onChange={(e) => onChangeSharedTabType(e, tab.type)} value={tab.type == "other" ? sharedTabType : mspSharedTabType}>
                      <Radio value='all'>
                        All
                      </Radio>
                      <Radio value='store'>
                        Added from Store
                      </Radio>
                      <Radio value='shared'>
                        Shared
                      </Radio>
                    </Radio.Group>
                  </div>
                </Row>}
              <RoomsListVirtualized
                tabChangeThroughClick={tabChangeThroughClick}
                setTabChangeThroughClick={setTabChangeThroughClick}
                switchTabIfEmpty={switchTabIfEmpty}
                checkFilteredResults={checkFilteredResults}
                type = 'space'
                roomsData={tab.data}
                selectedRoom = { selectedRoom }
                setSelectedRoom = { setSelectedRoom }
                selectedScenes={selectedScenes}
                setSelectedScenes={setSelectedScenes}
                searchValue = { searchValue }
                selectedCategories = { selectedCategories }
                setSelectedCategories = { setSelectedCategories }
                setData = { tab.setData }
                creationType = {creationType}
                isStore = {isStore}
                requestStatusRoom = {requestStatusRoom} 
                multiple_select={multiple_select}
                isEdit={isEdit}
                preselectedSpaces={preselectedSpaces}
                tabKey={tab.type}
                updateTabCount={updateTabCount}
              />
            </TabPane>
          );
        }))}
      </Tabs>
      <div className={`bottom-menu ${((!initialLoadOwned && ownedSpaceLoader && tabActiveKey == "owned") || (sharedSpaceLoader && tabActiveKey == "other") || (storeSpacesLoader && tabActiveKey == "store")) ?  'justify-space-between-important' : ``} `}>
          {((!initialLoadOwned && ownedSpaceLoader && tabActiveKey == "owned") || (sharedSpaceLoader && tabActiveKey == "other") || (storeSpacesLoader && tabActiveKey == "store")) &&
            <div className='manrope f-16 justify-in-start ml-100' style={{color:'#276DD7', padding:10}}>
                <LoadingOutlined className='manrope f-24 mr-16' />
                Loading remaining {tabActiveKey} spaces
            </div>}
            {customFooter ? <div className='mr-20 justify-in-end '>
              {(selectedScenes && selectedScenes.length > 0) && <div className='manrope black-d9 f-16 mr-16'><CheckCircleFilled className='mr-2' /> {selectedScenes?.length} selected</div>}
              <Button
                  onClick={props.handleCancel}
                  className='product-btn br-4 f-16 light-blue pd-16'>
                      Back
                </Button>
                {selectedScenes?.length>0 ? <Button
                  onClick={handleNext ? handleNext : handleContinue}
                  disabled={selectedScenes?.length <= 0}
                  className = {selectedScenes?.length <= 0 ? 'product-btn br-4 f-16 disabled-grey ml-16 pd-16' : 'product-btn dark-blue f-16 ml-16 br-4 pd-16'}>
                      Continue {loading ? <LoadingOutlined /> : ''}
                </Button>
                :
                <Button
                  onClick={handleContinue}
                  className = {selectedScenes?.length > 0 ? 'product-btn br-4 f-16 disabled-grey ml-16 pd-16' : 'product-btn dark-blue f-16 ml-16 br-4 pd-16'}>
                      Skip Spaces {loading ? <LoadingOutlined /> : ''}
                </Button>}
              </div> : 
              <div className='mr-20 justify-in-end'>
                <Button
                    onClick={props.handleCancel}
                    className='product-btn br-4 f-16 light-blue pd-16'>
                        Back
                  </Button>
                  <Button
                    onClick={handleContinue}
                    disabled={selectedRoom == null}
                    className = {selectedRoom == null ? 'product-btn br-4 f-16 disabled-grey ml-16 pd-16' : 'product-btn dark-blue f-16 ml-16 br-4 pd-16'}>
                        Continue {loading ? <LoadingOutlined /> : ''}
                  </Button>
              </div>}
          </div>
    </div>
  );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(
    mapStateToProps,
)(SelectSpace),
);
