import React from "react";
import { Row, Col } from "antd";

function TestHeading() {
  return (
    <Row
      style={{
        marginTop: "15px",
        marginBottom: 20,
        alignItems: "center",
      }}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p className="artist-product-heading">Test Artist Assessment</p>
          </div>
        </span>
      </Col>
    </Row>
  );
}

export default TestHeading;
