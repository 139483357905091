import React, { useState, useEffect, useCallback } from 'react';
import { getProductRender, listProducts} from "../../../redux/actions";
import { withRouter } from "react-router-dom";
import DottedLoader from '../DottedLoader';
import ImageMagnify from '../CustomerComponents/ImageMagnify/ImageMagnify';
import { connect } from "react-redux";
import {
    DownloadOutlined,
    DownOutlined,
    RightOutlined,
    LoadingOutlined,
    CheckOutlined,
    LeftOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import {
    Row,
    Col,
    Card,
    Button,
    Dropdown,
    Menu,
    message,
    Tooltip
} from 'antd';
import { GET_VARIATIONS, QA_IMG_URI, getBaseURL, VARIATION_RENDERS_URI } from '../../../environments/env';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';


function saveToZip (filename, urls) {
    message.info('Generating Images Archive, your download will start shortly.', 6);
    const zip = new JSZip()
    urls.forEach((url)=> {
        const blobPromise = fetch(url, {cache: 'no-store'}).then(r => {
            if (r.status === 200) return r.blob()
            return Promise.reject(new Error(r.statusText))
        })
        const name = url.split('/').slice(-1)[0] 
        zip.file(name, blobPromise)
    })

    zip.generateAsync({type:"blob"})
        .then((blob) => {
            saveAs(blob, filename);
        })
        .catch((e) => {
            console.log(e);
        });
}

const ConfigurationViewer = (props) => {

    const { match: { params } } = props;
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [componentData, setComponentData] = useState([]);
    const [selectedVariation, setSelectedVariation] = useState('');
    const [allVariations, setAllVariations] = useState([]);
    const [toggleConfigurator, setToggleConfigurator] = useState(true);
    const [loader, setLoader] = useState(true);
    const [materialsInProgress,setMaterialsInProgress] = useState('');

    useEffect(() => {
        props.getProduct({ product_id: params.id });

        // axios.post(GET_VARIATIONS, {product_id: params.id})
        // .then(res => {
        //     let temp_arr = []
           
        //     let variation_data = res.data;
        //     console.log('All Variations', variation_data);
        //     setAllVariations(variation_data);
        //     let in_progress_mats = [];

        //     variation_data.map((data,index) => {

        //         if (index == 0) {
        //             setSelectedVariation(data);
        //         }

        //         let mapping = data.mapping;
        //         let value_exists = [];
        //         mapping.map(arr => {

        //             if (temp_arr.length > 0) {
        //                 value_exists = temp_arr.filter((x) => { 
        //                     return ((x.material_id == arr.material_id) && (x.component_id == arr.component_id));
        //                 });
        //             }

        //             if (value_exists.length == 0) {
        //                 temp_arr.push(arr)
        //             }
        //             if (data.renders && !data.renders.thumbnail) {
        //                 in_progress_mats.push(arr);
        //             }
        //         });

               
        //     });

        //     setMaterialsInProgress(in_progress_mats);
        //     // markInProgressMaterials(in_progress_mats);
        //     console.log(in_progress_mats);
        //     groupData(temp_arr);
        //     setLoader(false);

        // }).catch(err => {
        //     console.log(err, ' Error in getting variation renders!');
        //     message.error('Error in getting variation renders!')
        // });
        setLoader(false);
    }, [params]);

    useEffect(() => {
        if (allVariations.length > 0 && materialsInProgress.length > 0) {
            markInProgressMaterials(materialsInProgress);
        }

    },[allVariations,materialsInProgress])

    const markInProgressMaterials = (in_progress_materials) => {
        console.log(in_progress_materials)
        let not_in_progress = [];
        let in_progress_comp = [];
        
        allVariations.map((data,i_index) => {
            data.mapping.map((element,j_index) => {
                in_progress_materials.map((mat, k_index) => {
                    if ((element.material_id == mat.material_id) && (element.component_id == mat.component_id)) {
                        if ((data.renders && !data.renders.thumbnail) && !not_in_progress.includes(element.material_id)) {
                            
                           
                            in_progress_comp.push(element.material_id);
                        } 
                    }
                })
                if ((data.renders && data.renders.thumbnail) && !not_in_progress.includes(element.material_id)) {
                    not_in_progress.push(element.material_id);
                //    in_progress_comp = in_progress_comp.filter(ele => {return (element.material_id != ele.material_id) && (element.component_id != ele.component_id)})
                } 
            });
            
        });

        console.log(not_in_progress);
    }

    const groupData = (arr) => {
        let approvedArray = arr;
        let groups = approvedArray.reduce((groups, data) => {
            let component_name = data.component_name;

            if (!groups[component_name]) {
              groups[component_name] = [];
            }

            groups[component_name].push(data);

            return groups;
          }, {});
          

          // To add it in the array format instead
          let groupArrays = Object.keys(groups).map((component_name) => {

            return {
                component_name,
                data: groups[component_name]
            };

          });

        setComponentData(groupArrays);
    }
    
    const selectCombination = (c_index,selected) => {
        let selected_mat_match = false;
        let unselected_mat_match = false;
        let no_match_check = false;
        allVariations.map((data,i_index) => {
            data.mapping.map((element,j_index) => {
            
                if ((c_index == j_index) && !no_match_check && (element.material_id == selected.material_id) && (element.component_id == selected.component_id)) {
                    selected_mat_match = true;//if same material is selected in the same component as the selected mapping
                }
                else if ((c_index != j_index) && !no_match_check && (element.material_id == selectedVariation.mapping[j_index].material_id) && (element.component_id == selectedVariation.mapping[j_index].component_id)) {
                    unselected_mat_match = true;//if same materials are selected for other components of the selected mapping
                } else {
                    selected_mat_match = false;//if materials/components are not the same
                    unselected_mat_match = false;
                    no_match_check = true;
                }
            });
            
            if (selected_mat_match && unselected_mat_match && !no_match_check) {
                console.log('Selected Variation',data);
                no_match_check = true;
                setSelectedVariation(data);
                forceUpdate();
            } else if (no_match_check) {
                no_match_check = false;
            }
        });
    }


    const selectedVariationStatus = (c_index, mat) => {
        return ((selectedVariation.mapping[c_index]['material_id'] == mat.material_id) && (selectedVariation.mapping[c_index]['component_id'] == mat.component_id));
    }

    const downloadImagesArchive = () => {
        let downloadData = [];
        allVariations.map((data,index) => {
            downloadData.push(getBaseURL(props.productReducer.product.platform) + QA_IMG_URI + data.renders.thumbnail);
        })
        saveToZip (params.id+'_Variation Renders.zip', downloadData);
    }

    const toggleConfiguratorFunc = () => {
        setToggleConfigurator(!toggleConfigurator);
    }

    const menu = (
        <Menu style={{ width: "186px",fontFamily:"Avenir" }}>
            <Menu.Item key={0}>
                <a target="_blank" onClick={() => downloadImagesArchive()}> Download Images <DownloadOutlined style={{ float: "right" }} /></a>
            </Menu.Item>
            {(props.productReducer.product.download_links || []).filter(ele => {return ele.display_name != "GLB"}).map((ele, index) => {
                return (
                    <Menu.Item key={index}>
                        <a target="_blank" href={ele.model_url} style={{fontFamily:"Avenir"}}>{ele.display_name} File <DownloadOutlined style={{ float: "right" }} /></a>
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    return(
   loader ? <DottedLoader/> : <Row className="justify-align-start">
       <Col span={toggleConfigurator ? 18 : 24}>
        <Card className="card-bg-config justify-in-center">
            {selectedVariation && selectedVariation.renders && selectedVariation.renders.thumbnail ?
            <ImageMagnify style={{cursor:"zoom-in"}} image={getBaseURL(props.productReducer.product.platform) + VARIATION_RENDERS_URI + params.id + '/' + selectedVariation.id + '/perspective_renders/' + selectedVariation.renders.thumbnail} className="image-zoom" />: 
            allVariations && allVariations.length > 0? 
            <div className="image-zoom">
                <div className="reject-admin-bg manrope f-16 white">
                The configuration for this material is in progress currently. You will be notified via email when it is ready and the renders are available for review.
                </div>
            </div> :
            <div className="image-zoom">
                {props.productReducer.product.model_status != 4 ?
                <div className="reject-admin-bg manrope f-16 white">   
                Your product model is in progress. You will be notified via email when it is ready.
                </div> : 
                props.productReducer.product.model_status == 4 ?
                <div className="reject-admin-bg manrope f-16 white">   
                You have not added configurations for this model. You can configure your model <a href={"/add-configurations/"+ params.id}>here.</a>
                </div> : '' }
            </div> 
            }
            <div style={{ position: "absolute", zIndex: 2, top: "20px", right: props.productReducer.product.model_status == 4 ? "" :"20px",left:  props.productReducer.product.model_status == 4 ? 20 : ""} } id="components-dropdown-demo-dropdown-button">
                <Dropdown overlay={menu} trigger={['click']} placement={"bottomRight"}>
                    <Button className="modal-okay square font-16 w-900">
                        Download <DownOutlined />
                    </Button> 
                </Dropdown>
            </div>
            {/* <div style={{ position: "absolute", zIndex: 2, top: "20px", right:"185px" }}>
                <Tooltip placement="left" title={`Something does not look good? Report it to the admin.`}>
                    <img className="flag-image-silo" src="/img/image_flag.png"/>
                </Tooltip>
            </div> */}
            <Button className="modal-okay round collapse-button" onClick={toggleConfiguratorFunc}>
                {toggleConfigurator ?
                <RightOutlined className="manrope f-14 white"/> : 
                <LeftOutlined className="manrope f-14 white"/>}
            </Button>
        </Card>
       </Col>
       <Col span={toggleConfigurator ? 6 : 0} style={{paddingLeft: 24,paddingRight: 16}} className="vertical-scroll-config">
           <div className="manrope f-24 black-00" style={{marginBottom: 8}}>
               Configurator
           </div>
           <div className="manrope f-14 grey-77" style={{marginBottom: 16}}>
               {allVariations.length} configurations
           </div>
           {componentData.map((element, c_index) => (
               <div style={{borderBottom: "1px solid #eeeeee"}}>
                   <div className="manrope f-16 black-33 capitalize" style={{marginBottom: 16, marginTop: 16}}>
                       {element.component_name} Materials
                   </div>
                   {/* <div className="manrope f-14 grey-77" style={{marginBottom: 12}}>
                       Plain Materials
                   </div> */}
                   <div className="justify-in-start">
                    {element.data.map((mat,map_index) => (
                        // mat.material_type == "plain" ?
                            <div style={{textAlign:'center',paddingRight: 8, paddingBottom: 16}}>
                                <Tooltip title={<span className="manrope f-12 white">{mat.material_name}</span>}>
                                    <div>
                                        {selectedVariationStatus(c_index,mat) ?
                                        <div>
                                            <div className={"mat-overlay"} style={{backgroundImage: mat.thumbnail}}>
                                                <img src={mat.thumbnail} className="mats" style={{cursor:"pointer"}} onClick={() => selectCombination(c_index,mat)}/>
                                                <CheckOutlined className="white-check"/>
                                                {/* {materialsInProgress && (materialsInProgress.material_id == mat.material_id) && (materialsInProgress.component_id == mat.component_id) ?
                                                <ClockCircleOutlined className="white-check"/>
                                                :''} */}
                                            </div>
                                        </div> : 
                                        <img src={mat.thumbnail} className="mats" style={{cursor:"pointer"}} onClick={() => selectCombination(c_index,mat)}/>
                                        }


                                        <div className="manrope f-12 grey-73 clip-text" style={{paddingTop: 8}}>
                                            {mat.material_name}
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                             
                    ))}
                   </div>
                   {/* <div className="manrope f-14 grey-77" style={{marginBottom: 12}}>
                       Patterned Materials
                   </div>
                   <div className="justify-in-start">
                    {element.data.map((mat,index) => (
                        mat.material_type == "pattern" ?
                            <div style={{textAlign:'center',paddingRight: 16, paddingBottom: 16}}>
                                <img src={mat.thumbnail} className="mats"/>
                                <div className="manrope f-12 grey-73" style={{paddingTop: 8}}>
                                    {mat.material_name}
                                </div>
                            </div> :
                            ""
                    ))}
                   </div> */}
                   
               </div>
           ))}
       </Col>
       
   </Row>
    );

}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    listProducts: () => {
        dispatch(listProducts());
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigurationViewer))