export default {
  "&multiLine": {
    control: {
    },
    highlighter: {
      padding: 9,
    },
    input: {
      padding: 9,
    },

  },

  "&singleLine": {
    display: "inline-block",

    highlighter: {
      padding: 1,
    },
    input: {
      padding: 1,
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
      maxHeight: 160, // Set a maximum height for scrollability
      overflowY: "auto", // Enable vertical scrolling
    },
    item: {
      "&focused": {
        backgroundColor: "#D3D3D3",
      },
    },
  },
};
