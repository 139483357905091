import React, { useEffect, useState } from "react";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import { Row, Modal, Button, Tabs, Col, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import * as Utilities from "../../Utilities";
import DottedLoader from "../../DottedLoader/DottedLoader";
import ProductsList from "./ProductsList";
import FileConstants from "../../../../FileConstants";

const COMPANY_ID = FileConstants.COMPANY_ID;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const IS_MSPROVIDER = FileConstants.isMSProvider;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;

const { TabPane } = Tabs;

const SwapProduct = (props) => {
    const { swap_space_modal, toggleSwapProductModal, setLoading, product_id, swapProduct, collection_id, space_area, project_ids } =
        props;
    const [variantsData, setVariantsData] = useState([]);
    const [tabActiveKey, setTabActiveKey] = useState("variants");
    const [selected, setSelected] = useState([]);
    const [selectedElementDetials, setSelectedElementDetials] = useState([]);
    const [userData, setUserData] = useState([]);
    const [sharedData, setSharedData] = useState([]);
    const [storeData, setStoreData] = useState([]);
    const [mspData, setMSPData] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [filteredUserData, setFilteredUserData] = useState([]);
    const [filteredSharedData, setFilteredSharedData] = useState([]);
    const [filteredStoreData, setFilteredStoreData] = useState([]);
    const [filteredVariantsData, setFilteredVariantsData] = useState([]);
    const [filteredMSPData, setFilteredMSPData] = useState([]);
    const [filteredProjectData, setFilteredProjecteData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [initialLoadProducts, setInitialLoadProducts] = useState(false);
    const [storeProductsLoader, setStoreProductsLoader] = useState(false);
    const [requestStatusProduct, setRequestStatusProduct] = useState(false);
    const [initialLoadShared, setInitialLoadShared] = useState(true);
    const [initialLoadOwned, setInitialLoadOwned] = useState(true);
    const [sharedProductLoader, setSharedProductLoader] = useState(true);
    const [ownedProductLoader, setOwnedProductLoader] = useState(true);  

    const tabs = [
        {
            type: 'project',
            title: "Project Products",
            data: filteredProjectData,
            selectable: true,
        },
        {
            key: "msp",
            title: `${MANAGED_CUSTOMER_USERNAME}'s Products`,
            data: filteredMSPData,
            selectable: true,
            store: false,
        },
        {
            key: "variants",
            title: "Your Variants",
            data: filteredVariantsData,
            selectable: true,
            store: false,
        },
        {
            key: "your",
            title: "Your Products",
            data: filteredUserData,
            selectable: true,
            store: false,
        },
        {
            key: "shared",
            title: "Other Products",
            data: filteredSharedData,
            selectable: true,
            store: false,
        },
        {
            key: "store",
            title: "Store",
            data: filteredStoreData,
            selectable: true,
            store: true,
            loader: storeProductsLoader,
        },
    ];

    const customSort = (a, b) => {
        // Parse prices as numbers or set them to Infinity if empty or 0
        const priceA = a.price === "" ? Infinity : parseFloat(a.price || 0.0);
        const priceB = b.price === "" ? Infinity : parseFloat(b.price || 0.0);

        // Compare prices
        if (priceA < priceB) {
            return 1;
        } else if (priceA > priceB) {
            return -1;
        } else {
            // If prices are equal, maintain the original order
            return b.id - a.id;
        }
    }

    const getProductPayload = (type, initial) => {
        let payload = {
            required_fields: [
                "id",
                "name",
                "brand_id",
                "category",
                "height",
                "customer_username",
                "width",
                "depth",
                "model_status",
                "thumbnail",
                "last_modified_stamp",
                "variant_of",
                "is_store_item",
                "group_id",
                "dimensions",
                "company_id",
                "color_name",
                "materials",
                "platform",
                "style_category",
                "gtin",
                "tags",
                "product_model_type",
                "user_render_count",
                "placement_type",
                "material_type"
            ],
        };
        if (IS_MSPROVIDER) {
            payload["order_by"] = "last_modified_stamp desc";
        } else {
            payload["order_by"] = "last_modified_stamp desc";
        }

        let filter_string = "";
        let shared_username = "";
        let asset_username =
            "asset_username__exact='" +
            CUSTOMER_USERNAME +
            "'||asset_username__isnull=true";
        let asset_company = "";
        let customer_username =
            "customer_username__exact='" + CUSTOMER_USERNAME + "'";
        let customer_username_notexact =
            "customer_username__notexact='" + CUSTOMER_USERNAME + "'";
        let company_id_notexact = "";

        let company_id = "";
        let model_status = "model_status__in=[5]";
        let is_hidden = "is_hidden__not=true";
        let shared_hidden = "shared_hidden__not=true";
        if (COMPANY_ID) {
            payload["required_fields"].push("company_render_count");
            asset_company =
                "&&(asset_company__exact=" +
                COMPANY_ID +
                "||asset_company__isnull=true)";
        }

        if (type == "shared") {
            payload["required_fields"].push("shared_username");
            payload["required_fields"].push("shared_product_type");
            shared_username = "shared_username__exact='" + CUSTOMER_USERNAME + "'";
            filter_string = "(" + shared_username + "&&" + shared_hidden + ")";

            if (
                SHARED_ENTITIES != undefined &&
                COMPANY_ID &&
                SHARED_ENTITIES.split("_").includes("product")
            ) {
                company_id_notexact =
                    "company_id__notexact=" + COMPANY_ID + "||company_id__isnull=true";
                filter_string =
                    filter_string +
                    "&&(" +
                    company_id_notexact +
                    ")&&(" +
                    customer_username_notexact +
                    ")&&(" +
                    asset_username +
                    ")" +
                    asset_company;
            } else {
                filter_string =
                    filter_string +
                    "&&(" +
                    customer_username_notexact +
                    ")&&(" +
                    asset_username +
                    ")" +
                    asset_company;
            }
        } else if (
            SHARED_ENTITIES != undefined &&
            COMPANY_ID &&
            SHARED_ENTITIES.split("_").includes("product")
        ) {
            company_id =
                "company_id__exact=" +
                COMPANY_ID +
                "||additional_company_ids__contains='" +
                COMPANY_ID +
                "'";
            filter_string =
                "(" +
                company_id +
                "||" +
                customer_username +
                ")&&(" +
                is_hidden +
                ")&&(" +
                asset_username +
                ")" +
                asset_company;
        } else {
            if (IS_MSPROVIDER) {
                company_id = "company_id__isnull=true";
                let requested_for = "requested_for__isnull=true";
                filter_string =
                    "(" +
                    company_id +
                    "&&" +
                    customer_username +
                    ")&&(" +
                    is_hidden +
                    ")&&(" +
                    requested_for +
                    ")&&(" +
                    asset_username +
                    ")";
            } else {
                filter_string =
                    "(" +
                    customer_username +
                    "&&" +
                    is_hidden +
                    ")&&(" +
                    asset_username +
                    ")" +
                    asset_company;
            }
        }
        filter_string = filter_string + "&&(" + model_status + ")";
        payload["filter_string"] = filter_string;
        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }
        return payload;
    };

    const getMSPProductPayload = () => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "is_shared", "color_name", "materials",
                "platform", "style_category", "gtin", "tags", "product_model_type", "user_render_count"],
        }
        payload["order_by"] = "last_modified_stamp desc, is_shared desc"
        let filter_string = "";
        let shared_username = "shared_username__exact='" + MANAGED_CUSTOMER_USERNAME + "'"
        let customer_username = "customer_username__exact='" + MANAGED_CUSTOMER_USERNAME + "'"
        let company_id = "";
        let model_status = "model_status__in=[5]"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + MANAGED_CUSTOMER_USERNAME + "\"%'"
        let asset_username = "asset_username__exact='" + MANAGED_CUSTOMER_USERNAME + "'||asset_username__isnull=true";
        let asset_company = "";
        if (MANAGED_COMPANY_ID) {
            payload['required_fields'].push('company_render_count');
            company_id = "company_id__exact=" + parseInt(MANAGED_COMPANY_ID) + "||additional_company_ids__contains='" + parseInt(MANAGED_COMPANY_ID) + "'"
            asset_company = "asset_company__exact=" + parseInt(MANAGED_COMPANY_ID) + "||asset_company__isnull=true";
            filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")&&(" + asset_username + ")&&(" + asset_company + ")"
        } else {
            filter_string = "(" + customer_username + "&&" + is_hidden + ")&&(" + asset_username + ")"
        }
        filter_string = filter_string + "&&(" + model_status + ")" + "||(" + has_access_to + ")"

        payload['filter_string'] = filter_string

        return payload;
    }

    const fetchProductData = async (type, initial = false) => {
        if (!initial) {
            setDataLoading(true);
          } else {
            if (type == 'shared') {
              setInitialLoadShared(true);
            } else {
              setInitialLoadOwned(true);
            }
        }
        let payload = getProductPayload(type, initial);
        await axios.post(ENVIRONMENT.LIST_PRODUCT, payload).then((res) => {
            if (res.data) {
                // setting data
                const dataWithKeywords = Utilities.getProductsDataWithKeywords(
                    res.data
                );
                if (type == "shared") {
                    let products =
                        Utilities.getProductsDataWithKeywords(dataWithKeywords);
                    setSharedData(products);
                    setFilteredSharedData(products);
                } else {
                    let products =
                        Utilities.getProductsDataWithKeywords(dataWithKeywords);
                    setUserData(products);
                    setFilteredUserData(products);
                }
            }
            if (initial) {
                if (type == 'shared') {
                  setInitialLoadShared(false);
                } else {
                  setInitialLoadOwned(false);
                }
              } else {
                if (type == 'shared') {
                  setSharedProductLoader(false);
                } else {
                  setOwnedProductLoader(false);
                }
                setDataLoading(false)
              }
        });
    };

    const fetchVariantsData = async () => {
        let payload = {
            required_fields: [
                "id",
                "name",
                "category",
                "height",
                "customer_username",
                "width",
                "depth",
                "thumbnail",
                "last_modified_stamp",
                "variant_of",
                "dimensions",
                "platform",
                "gtin",
                "tags",
                "product_model_type",
                "placement_type",
                "material_type"
            ],
        };

        payload["filter_string"] = `(variant_of__exact='${product_id}'&&model_status__in=[5])`;
        payload["order_by"] = "last_modified_stamp desc";

        await axios.post(ENVIRONMENT.LIST_PRODUCT, payload).then((res) => {
            if (res.data) {
                const dataWithKeywords = Utilities.getProductsDataWithKeywords(
                    res.data
                );
                setVariantsData(dataWithKeywords);
                setFilteredVariantsData(dataWithKeywords);
            }
        });
    };

    const fetchStoreProductData = async (initial = false) => {
        const data = [];
        setStoreProductsLoader(true);
        let payload = {
            item_type: 'product',
            username: CUSTOMER_USERNAME
        }
        if (initial) {
            payload.limit = '';
            setInitialLoadProducts(true);
        }
        axios.post(ENVIRONMENT.STORE_LIST, payload)
            .then((res) => {
                if (!initial) {
                    setStoreProductsLoader(false);
                }
                let dataWithKeywords = Utilities.getProductsDataWithKeywords(res.data, "store");
                dataWithKeywords = dataWithKeywords.sort(customSort);
                setStoreData(dataWithKeywords);
                setFilteredStoreData(dataWithKeywords);
            })
            .catch((error) => {
            });
        return data;
    };

    const fetchMSPCustomerData = async () => {
        if (IS_MSPROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
            const payload = getMSPProductPayload();
            await axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
                .then(res => {
                    if (res.data) {
                        const products = Utilities.getProductsDataWithKeywords(res.data);
                        setMSPData(products);
                        setFilteredMSPData(products);
                    }
                });
        }
    }

    let getProjectProductsPayload = (project_ids) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "is_shared", "platform", "placement_type", "material_type"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        payload["filter_string"] =  `(project_ids__in=[${project_ids.join(",")}]) && (model_status__in=[4, 5])`

        return payload;
    }

    const getProjectProductData = () => {
        let payload = getProjectProductsPayload(project_ids);
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then((response) => {
                if (response.data) {
                    let owned_products = Utilities.getProductsDataWithKeywords(response.data);;
                    setProjectData(owned_products);
                    setFilteredProjecteData(owned_products);
                }
            })
            .catch((error) => {
            });
    }

    const handleSubmit = async () => {
        setLoading(true);
        handleCancelSwapProductModal();

        const product_id = selected[0];
        const payload = {
            products_list: [{product_id, area_name:space_area}],
            space_allocation: true,
            collection_id: collection_id,
            username: CUSTOMER_USERNAME,
            update_collection_metadata: true,
        };

        axios.post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload)
        .then((response) => {
        
        })
        .catch(() => {
        });

        swapProduct(selectedElementDetials, () => {
            message.success("Product Swapped Successfully");
        })
    }

    const handleCancelSwapProductModal = () => {
        toggleSwapProductModal(false);
        setSelected([]);
        setSearchValue("");
    };

    const handleTabChange = (key) => {
        setTabActiveKey(key);
    };

    const getTabTitle = (tab) => {
      if (tab.key == "store" && storeProductsLoader)
        return tab.title;
      else return tab.title + ` (${tab.data.length})`;
    };

    useEffect(() => {
        const fetchData = async () => {
            if (product_id) {
                setDataLoading(true);
                await fetchVariantsData();
                setDataLoading(false);
            }
        };
        fetchData();
    }, [product_id]);

    useEffect(()=>{
        if (project_ids.length > 0) {
            getProjectProductData()
        }
    },[project_ids])

    useEffect(() => {
        const fetchData = async () => {
            setDataLoading(true);
            fetchProductData("owned", true);
            fetchProductData("shared", true);
            await fetchMSPCustomerData();
            await loadUserRequestStatusProducts();
            await fetchStoreProductData(true);
            setDataLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (searchValue.length) {
            const filteredVariantsData =
                Utilities.getRelevanceBasedOrderedSearchResult(
                    searchValue,
                    variantsData
                );
            const filteredUserData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                userData
            );
            const filteredSharedData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                sharedData
            );
            const filteredStoreData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                storeData
            );
            const filteredMSPData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                mspData
            );
            const filteredProjectData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                projectData
            );
            setFilteredMSPData(filteredMSPData);
            setFilteredSharedData(filteredSharedData);
            setFilteredUserData(filteredUserData);
            setFilteredVariantsData(filteredVariantsData);
            setFilteredStoreData(filteredStoreData);
            setFilteredProjecteData(filteredProjectData);
        }
        else {
            setFilteredSharedData(sharedData);
            setFilteredUserData(userData);
            setFilteredVariantsData(variantsData);
            setFilteredStoreData(storeData);
            setFilteredProjecteData(projectData);
        }
    }, [searchValue]);

    useEffect(() => {
        if (initialLoadProducts) {
            fetchStoreProductData();
        }
    }, [initialLoadProducts]);

    useEffect(() => {
        if (!initialLoadOwned && ownedProductLoader) {
            fetchProductData('owned');
        }
      }, [initialLoadOwned]);
    
      useEffect(() => {
        if (!initialLoadShared && sharedProductLoader) {
            fetchProductData('shared');
        }
      }, [initialLoadShared]);

    useEffect(() => {
        if (!dataLoading && swap_space_modal) {
            const nextNonEmptyTab = tabs.find(tab => tab.data.length > 0);

            if (nextNonEmptyTab && tabActiveKey !== nextNonEmptyTab.key) {
                setTabActiveKey(nextNonEmptyTab.key);
            }
        }
    }, [swap_space_modal, dataLoading]);

    const loadUserRequestStatusProducts = async () => {
        const check_payload = {
            username: localStorage.getItem("username"),
            action: 'check',
            request_type: 'product_prop',
        };
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
            setRequestStatusProduct(res.data['request_allowed']);
        });
    };

    return (
        <Modal
            destroyOnClose={true}
            closable={true}
            onCancel={handleCancelSwapProductModal}
            className="swap-modal"
            bodyStyle={{ padding: 0 }}
            footer={null}
            visible={swap_space_modal}
        >
            <Row className="header-container">
                <div className="swap-heading-tag">
                    <span className="manrope f-14">Swap Product</span>
                </div>
            </Row>
            <Row className="justify-space-between swap-container">
                <Col span={16} className="justify-in-start">
                    <span className="manrope f-20 w-600">Choose a product below to replace the selected product</span>
                </Col>
                <Col span={8} className="justify-in-end">
                    <Input
                        className="manrope f-14 grey-99 pd-8"
                        prefix={
                            <SearchOutlined
                                style={{
                                    marginleft: "4px",
                                    marginRight: "8px",
                                    fontSize: "16px",
                                }}
                            />
                        }
                        placeholder="Search Products"
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                    />
                </Col>
            </Row>
            <div className="swap-container">
                <Tabs
                    onChange={(key) => handleTabChange(key)}
                    className="product-tab library"
                    tabBarGutter={16}
                    size="large"
                    activeKey={tabActiveKey}
                >
                    {tabs?.map((tab, index) => {
                        return (index === 1 && !IS_MSPROVIDER) || (index === 0 && project_ids.length == 0 ) ? null : (
                            <TabPane
                                tab={getTabTitle(tab)}
                                key={tab.key}
                                style={{ padding: "0px 10px" }}
                            >
                                <div className="product-tab library">
                                    {initialLoadOwned || initialLoadShared  ? (
                                        <div className="modal-tab">
                                            <DottedLoader />
                                        </div>
                                    ) : (
                                        <ProductsList
                                            data={tab.data}
                                            selectable={tab.selectable}
                                            store={tab.store}
                                            selected={selected}
                                            setSelected={setSelected}
                                            setSelectedElementDetials={setSelectedElementDetials}
                                            product_id={product_id}
                                            loader={tab.loader}
                                            requestStatusProduct = {requestStatusProduct}
                                        />
                                    )}
                                </div>
                            </TabPane>
                        );
                    })}
                </Tabs>
            </div>
            <Row className="swap-modal-footer justify-in-end" onClick={() => { }}>
                <Button
                    style={{ marginRight: 10 }}
                    className="outline-red-btn square font-12 manrope"
                    onClick={handleCancelSwapProductModal}
                >
                    Cancel
                </Button>
                <Button
                    className="modal-okay square font-14"
                    disabled={selected.length === 0}
                    onClick={handleSubmit}
                >
                    Continue
                </Button>
            </Row>
        </Modal>
    );
};

export default SwapProduct;
