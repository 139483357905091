import React, { useEffect, useState, useCallback } from 'react';
import '@ant-design/compatible/assets/index.css';
import { LoadingOutlined, DownloadOutlined,DownOutlined } from '@ant-design/icons';
import { Modal, Button, Form, Input, Row, Col, Tooltip,Card,TreeSelect, Dropdown,Menu ,Select} from 'antd';
import 'rc-color-picker/assets/index.css';
import ImageZoomAdmin from '../ImageZoomAdmin';
import FileConstants from '../../../../FileConstants';
import ENVIRONMENT from '../../../../environments';


const MaterialDetailModal = (props) => {
    const [renders, setRenders] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const { Option } = Select;

    useEffect(() => {
        console.log(props.selectedMaterial)
        if (props.selectedMaterial) {
            let renders = [];
            if (props.selectedMaterial.renders && props.selectedMaterial.renders.data) {
                setSelectedImage(props.selectedMaterial.renders.data && props.selectedMaterial.renders.data.thumbnail)
                if (props.selectedMaterial.renders.data.artist_renders) {
                    renders = props.selectedMaterial.renders.data.artist_renders;

                } else {
                    if (props.selectedMaterial.renders.data.QA_renders) {
                        props.selectedMaterial.renders.data.QA_renders.map((render) => {
                            renders.push(render+'.jpg');
                        })
                    }
                }
                renders.unshift(props.selectedMaterial.renders.data.thumbnail);
                setRenders(renders);
            }
            console.log('Renders',renders)

        }
    },[props.selectedMaterial])


  const menu = (
    <Menu style={{ width: "200px",fontFamily:"Avenir" }}>
        <Menu.Item key={0}>
          <a download href={props.selectedMaterial.input_bundle ? props.selectedMaterial.input_bundle.replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(props.selectedMaterial.platform)) : ''}>Material Bundle<span style={{ float: "right", color:"#1681E5" }}><DownloadOutlined/></span></a>
        </Menu.Item>
        {props.selectedMaterial && props.selectedMaterial.status == 5 && props.selectedMaterial.renders.data && props.selectedMaterial.renders.data.thumbnail ?
            <Menu.Item key={1}>
                <a download href={ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.selectedMaterial.id + '/' +  encodeURIComponent(props.selectedMaterial.renders.data.thumbnail)}>Material Swatch<span style={{ float: "right" , color:"#1681E5" }}><DownloadOutlined/></span></a>
            </Menu.Item>
            : " "}
    </Menu>
  );

    return (
      <Modal
      visible={props.materialModal}
      onCancel={props.closeMaterialModal}
      closable={true}
      maskClosable={false}
      className="aspect-ratio-modal-closable"
      footer={[
      ]}>
      <div style={{padding:"40px 40px 0 40px"}}>
          {props.selectedMaterial ?
            <Row>
              <Col span={24} className="material-img-bg justify-in-center">
                {props.selectedMaterial && props.selectedMaterial.status == 5 &&
                <div className="material-download-option customer">
                    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                        <Button className="modal-okay square font-14">Download Links <DownOutlined /></Button>
                    </Dropdown>
                </div>}
              {props.selectedMaterial && props.selectedMaterial.status == 5 && props.selectedMaterial.renders.data && props.selectedMaterial.renders.data.thumbnail ?
              <div  style={{textAlign:'center',display:'flex',justifyContent:'center'}} >
                <ImageZoomAdmin material_modal={true} image={ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.selectedMaterial.id + '/' +  encodeURIComponent(selectedImage)} className="selected-mat approved-material"/>
              </div>
              :
                props.selectedMaterial && Object.keys(props.selectedMaterial.material_files).length != 0 &&
                props.selectedMaterial.material_files[0] && !props.selectedMaterial.is_scanned ?
                    <img style={{filter: props.selectedMaterial.status == 5 ? "" :"blur(4px)"}} src={ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(props.selectedMaterial.material_files[0].uid + '/' + props.selectedMaterial.material_files[0].name)} className="selected-mat modal-img"/>
                    :
                    <img src={'/img/placeholder.png'} className="selected-mat modal-img"/>
                }

                <div className="justify-space-between" style={{padding: "0 3% 0 0",display: "flex"}}>

            <div className="material-position">
                {(props.selectedMaterial && props.selectedMaterial.material_output.includes('vray') && (props.selectedMaterial.status == "3" || props.selectedMaterial.status == "5"   )) ?
                <div className="justify-in-start">
                    {renders.map((image,index) => (
                        <Card style={{marginRight: 16,padding: 4, cursor:"pointer",border: image == selectedImage ? "1px solid #141414" : "1px solid #e4e4e4"}} onClick={() => setSelectedImage (image)}>
                            {!image.includes(FileConstants.THUMBNAIL_FILENAME ) ? <div className="tag-bg"><span className="tag-txt">{FileConstants.MATERIAL_TYPE_LABELS[FileConstants.materialRenderTypeResolution(image)]}</span></div> : '' }
                            <img src={image.includes('.jpg') || image.includes('.png') || image.includes('.jpeg') ? ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.selectedMaterial.id + '/' + image : ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.MATERIAL_FILE_URI + props.selectedMaterial.id + '/' + image + '.jpg'} className="select-mat"/>
                        </Card>
                    ))}
                    </div>: ''}
                </div>
              </div>

                {props.selectedMaterial && props.selectedMaterial.status != 5 ?
                  <div className="reject-admin-bg in-progress manrope f-16 white">In Progress Request</div> : ''}
              </Col>
          </Row>:
          <div  className="selected-mat modal-img justify-in-center" style={{margin:"auto"}}>
            <LoadingOutlined style={{margin:"auto",fontSize:32}}/>
          </div>
          }
          {props.selectedMaterial ?
          <Row style={{marginTop: 10}} className="justify-space-between" gutter={[16]}>
              <Col className="justify-in-start" span={8} style={{flexWrap:'wrap'}}>
                  <Tooltip title={<span className="manrope f-12 white">{props.selectedMaterial.name}</span>}>
                      <div className="manrope f-20 black-33 clamp-text w-50 capitalize" style={{marginRight: "20px"}}>
                          <span style={{marginRight:5}}>{props.selectedMaterial.name}</span>
                      </div>
                  </Tooltip>

                  {props.selectedMaterial.status == 5 && !props.editable ?
                  <Tooltip title={<span className="manrope f-12 white">Edit Material Name and Category</span>}>
                      <img id="edit-icon" style={{marginLeft: "0px",width: 30, height: 30}} src ="/img/edit-w-hover.png" className="edit-icon-data" onClick={props.makeEditable} onMouseOut={props.onMouseOut} onMouseOver={props.onMouseOver}/>
                  </Tooltip>: ''}
              </Col>
              {props.editable &&
              <Col className="justify-in-end" span={16} style={{flexWrap:'wrap'}}>
                  <Form name="material-form"  className="material-edit-form" onFinish={props.onFinishForm}
                  style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end',flexWrap:'wrap',flex: "0.6 1 auto"}}
                  initialValues={{
                      material_name: props.selectedMaterial.name,
                      material_category: props.selectedMaterial.category,
                      tags: props.selectedMaterial.tags
                  }} layout="vertical">
                      <Col span={24} className="j-end-a-end" style={{display: 'block'}}>
                        <div style={{display: 'flex'}}>

                          <Form.Item style={{marginLeft:10}} className="material-edit-item j-start-a-start" name="material_name" colon={false} label={<span className="manrope f-12 black-55">Name</span>} rules={[{ required: false, message: 'Please enter product name.' }]}>
                              <Input disabled={props.saveButtonLoader} placeholder={'Enter Material Name'} className="manrope f-12 black-55 library-search"/>
                          </Form.Item>

                          <Form.Item style={{marginLeft:10}} className="material-edit-item j-start-a-start" name="material_category"  label={<span className="manrope f-12 black-55">Category</span>} colon={false} rules={[{ required: false, message: 'Please select a category!' }]}>
                              <TreeSelect
                                  disabled={props.saveButtonLoader}
                                  className="tree-select-material f-14 material-edit-treeselect"
                                  showSearch
                                  style={{ width: '100%', color:"#333333" }}
                                  dropdownStyle={props.categoryStyle}
                                  placeholder={'Select or type in to search'}
                                  treeData={props.materialsData}
                                  value={props.materialValue}
                                  onChange={(value) => props.setMaterialValue(value)}
                                  onSearch={(e) => {props.setMaterialSearchValue(e)}}
                                  onInputKeyDown={(e) => { props.addMaterialOption(e)} }
                                  notFoundContent={<span className='tree-select-material'>No such material found. Press enter to add your material.</span>}>
                              </TreeSelect>
                          </Form.Item>

                            <Form.Item colon={false} style={{marginLeft: 10, minWidth: 220}} label={<span className="manrope f-12 black-55">Tags</span>} name="tags" rules={[{ required: false, type: 'array', message: 'Please add tag(s).' }]}>
                                    <Select disabled={props.saveButtonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter tag(s)">
                                        <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                    </Select>
                            </Form.Item>
                        </div>

                          <div className="material-edit-item" style={{display: 'flex', flexDirection: 'row-reverse'}}>
                              <Form.Item style={{marginLeft:10, marginRight: 10}}>
                                  <Button disabled={props.saveButtonLoader} className="modal-okay square font-14" type="primary" htmlType="submit">
                                      Save&nbsp;{props.saveButtonLoader ? <LoadingOutlined/> : ''}
                                  </Button>
                              </Form.Item>
                              <Button disabled={props.saveButtonLoader} style={{ marginBottom: 10}} className="modal-okay-gray square font-14" onClick={props.makeEditable}>
                                  Cancel
                              </Button>

                          </div>
                      </Col>
                  </Form>

              </Col>}
          </Row>: ''}
          {props.errorMessage == "" ? '' :
          <Row>
              <Col span={24} className="justify-in-end" style={{marginTop: 20}}>
                  <div className="justify-space-between err-bg manrope f-12 red-error"><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{props.errorMessage}</span></div>
              </Col>
          </Row>}
        {!props.editable && <Row type="flex" style={{ justifyContent: "space-between", marginTop: "10px",alignItems:'center'}}>
            <Col span={18}>
                <div className="justify-in-start" style={{flexWrap: 'wrap',alignItems: 'flex-start'}}>

                <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Status</div>
                    <div className="manrope f-12 black-33 w-600">
                        {props.selectedMaterial.status == 5 ? "Complete" : "In Progress"}
                    </div>
                </Col>
                <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Customer Name</div>
                    <div className="manrope f-12 black-33 w-600">
                        {props.selectedMaterial.username}
                    </div>
                </Col>
                <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Category</div>
                    <div className="manrope f-12 black-33 w-600">
                        {props.selectedMaterial.category}
                    </div>
                </Col>
                <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Type</div>
                    <div className="manrope f-12 black-33 w-600">
                        {props.selectedMaterial.type}
                    </div>
                </Col>
                <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Requirement</div>
                    <div className="manrope f-12 black-33 w-600">
                        {FileConstants.MATERIAL_TYPE_LABELS[props.selectedMaterial.material_output]}
                    </div>
                </Col>
                {props.selectedMaterial.width && props.selectedMaterial.length && (props.selectedMaterial.width != 0 && props.selectedMaterial.length != 0) ?
                <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Dimensions</div>
                    <div className="manrope f-12 black-33 w-600">
                    W {props.selectedMaterial.width}" H {props.selectedMaterial.length}"
                    </div>
                </Col>: ''}
                <Col span={6} style={{marginBottom:"10px"}}>
                    <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>ID</div>
                    <div className="manrope f-12 black-33 w-600">
                        {props.selectedMaterial.id}
                    </div>
                </Col>
                {props.selectedMaterial.guidelines && props.selectedMaterial.guidelines!= "" ?
                    <Col span={6} style={{marginBottom:"10px"}}>
                        <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Guidelines/ Comments</div>
                        {props.selectedMaterial.guidelines.split('\n').map((item, i) => {
                            if (item === "") {
                            }
                            else {
                            return <div className="manrope f-12 black-33 w-600" style={{textAlign:"left",marginBottom:4}} key={i}>{item}</div>}
                        })}
                    </Col>: ''}
                {props.selectedMaterial.tags && props.selectedMaterial.tags.length > 0 ?
                    <Col span={6} style={{marginBottom:"10px"}}>
                        <div className="manrope f-12 grey-99" style={{marginBottom:"4px",textAlign:"left"}}>Tags</div>
                        <div className="manrope f-12 black-33 w-600">
                            {props.selectedMaterial.tags.map((tag, index) => (
                                <span className="manrope f-12 black-33 w-600">
                                    {index > 0 ? `, ${tag}` : `${tag}`}
                                </span>
                            ))
                            }
                        </div>
                    </Col>
                : ''}

                </div>
            </Col>
        </Row>}


        <hr/>

        {!props.editable && <Row type="flex" style={{ justifyContent: "space-between", marginTop: "0px",alignItems:'center'}}>
            <Col span={16}>
                <div className="justify-in-start" style={{alignItems: 'flex-start'}}>
                    {!props.editable && props.selectedMaterial && Object.keys(props.selectedMaterial.material_files).length != 0 && props.selectedMaterial.material_files.length > 0 ?
                        <span style={{marginRight: 8}}>
                            <div className="manrope f-12 grey-77" style={{marginTop: 10,marginBottom: 4}}>Material Swatch</div>
                            <Row>
                                <Col className="justify-in-start">

                                {!props.selectedMaterial.is_scanned ?
                                    props.selectedMaterial.material_files.map((file,index) => (

                                        <Col style={{paddingRight: 8}}>
                                            <Card bordered>

                                                <img
                                                className="rejected-mat" src={ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)}
                                                />
                                            </Card>
                                        </Col>
                                        ))
                                    :
                                    props.selectedMaterial.material_files.map((file,index) => (
                                        <Col style={{paddingRight: 8}}>
                                            <Card bordered>
                                                <a href={ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} target="_blank">
                                                    <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                                        <img
                                                        className="rejected-mat" src={'/img/file-icon.png'}
                                                        />
                                                    </Tooltip>
                                                </a>
                                            </Card>
                                        </Col>
                                        )) }
                                </Col>
                            </Row>
                        </span>: ''}
                        {!props.editable && props.selectedMaterial && Object.keys(props.selectedMaterial.reference_files).length != 0 && props.selectedMaterial.reference_files.length > 0 ?
                        <span>
                            <div className="manrope f-12 grey-77" style={{marginTop: 10,marginBottom: 4}}>Reference Images</div>
                            <Row>
                                <Col className="justify-in-start">
                                    {props.selectedMaterial && Object.keys(props.selectedMaterial.reference_files).length != 0 && props.selectedMaterial.reference_files.length > 0 ?
                                    props.selectedMaterial.reference_files.map((file,index) => (
                                        <Col style={{paddingRight: 8}}>
                                            <Card bordered>

                                            {file.name.includes('.zip') || file.name.includes('.mat') ?
                                            <a href={ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} target="_blank">
                                                <Tooltip title={<span className="manrope f-14 white">{file.name}</span>}>
                                                    <img  className="rejected-mat"  src="/img/file-icon.png"/>
                                                </Tooltip>
                                            </a> :
                                            <img
                                            className="rejected-mat" src={ENVIRONMENT.getBaseURL(props.selectedMaterial.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)}
                                            />}
                                            </Card>
                                        </Col>
                                        )) : ''}
                                </Col>

                            </Row>
                        </span>: ''}
                </div>
            </Col>
        </Row>}

      </div>
  </Modal>
    );

}

export default MaterialDetailModal;