import React, { useState } from "react";
import { Card, Row, } from "antd";
import "./ArtistGuidelines.scss";
import ArtistMainLayout from "../ArtistMainLayout";
import { useEffect } from "react";
import FileConstants from "../../../../FileConstants";

const { Meta } = Card;

function ArtistGuideline(props) {

  return (
    <ArtistMainLayout>
      <React.Fragment>
        <div className="content-container">
          <div className="guidelines-heading">
            <h3>Artist Guidelines</h3>
          </div>
          {FileConstants.artistGuidelinesSections.map((section) => {
            return (
              <div>
                <h4>{section["section_heading"]}</h4>
                <Row style={{ marginBottom: 20 }}>
                  {section["items"].map((item) => {
                    return (
                      <a href={item["link"]} target="_blank">
                        <Card
                          hoverable={true}
                          style={{ width: "fit-content", marginRight: 15 }}
                          cover={<img src={item["image"]} />}
                        >
                          <Meta
                            style={{ padding: 15 }}
                            title={
                              <span style={{ fontSize: 13, fontWeight: 400 }}>
                                {item["name"]}
                              </span>
                            }
                          />
                        </Card>
                      </a>
                    );
                  })}
                </Row>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    </ArtistMainLayout>
  );
}

export default ArtistGuideline;
