import React from 'react';
import { Slider, Row,Col,InputNumber,Input, Radio} from 'antd';
import './styles.css'

class CameraHeightWidget extends React.Component {

    state={
        sliderPosition: 200,
    }

    componentDidMount(){
        let handleDiv = document.createElement('div');
        handleDiv.id = "camera";
        handleDiv.innerHTML = '<img style="position: absolute;top: 1px;width:32px;height:32px;right: -12px;-moz-user-select: none;-webkit-user-select: none;user-select: none;" src="/img/camera_widget.svg"/>';
        let slider_handle = document.getElementsByClassName("ant-slider-handle")[0];
        slider_handle.style.border = "none";
        slider_handle.style.zIndex = 1000;
        slider_handle.appendChild(handleDiv);
        window.$("#slider-silo").roundSlider({
                radius: 130,    
                circleShape: "half-right",
                sliderType: "min-range",
                showTooltip: false,
                min: -90,
                startValue: 0,
                max: 90,
                value: this.props.getCameraAngle(),
                drag: (args) =>  {
                    this.props.setPolarAngle(args.value + 90);
                    this.props.setCameraAngle(args.value)
                    slider_handle.style.transform = "rotate("+args.value+"deg)";
                } 
            });
    }

    onChange = (value) => {
        this.props.setCameraHeight(value/900);
        this.setState({
            sliderPosition: value
            }
        );
    }

    render(){

        const marks = {
            0: '',
            40: '',
            80: '',
            120: '',
            160: '',
            200: ''
          };
        
        return <div id="camera_controls_container" >
                    <div style={{height:20}}></div>
                    <div className="camera_silo_container">
                        <div style={{height:20}}>
                        </div>
                        <div id="camera_silo_slider">
                            <Slider marks={marks} id="vs" vertical autoFocus tooltipVisible={false} value={200 - this.props.sliderPos} onChange={this.props.setSliderPosition} defaultValue={this.props.sliderPos} min={0} max={200} /> 
                            <div id="slider-silo" style={{top: this.state.sliderPosition}}></div>
                        </div>
                        <div id="slider-floor-container">
                            <div id="slider-floor-position">
                                <hr id="slider-floor"></hr>
                            </div>
                        </div>
                    </div>
                </div>
        
    }
}

export default CameraHeightWidget;