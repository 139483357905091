import React, { useState, useEffect,useLayoutEffect }  from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, List } from 'antd';
import {connect} from "react-redux";
import { withRouter} from 'react-router-dom';
import { updateRoom, fetchRoom} from "../../../../redux/actions";
import ENVIRONMENT from '../../../../environments'
import '../../../../styles/helper.scss';
// import { pdfjsLib } from "pdfjs-dist/build/pdf";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { LoadingOutlined } from '@ant-design/icons';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

var canvas = null;
var canvasObj = null;

const AnnotationForm = (props) => {

    // const [canvas, setCanvas] = useState(null);
    const [textField, setTextField] = useState('');
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    
    useEffect(() => {
        if (canvas == null) {
            if( props.roomReducer.room.floorplan != undefined ) {
                if( props.roomReducer.room.floorplan[0] != undefined ){
                    canvasObj = new window.fabric.Canvas('c');
                    const cDim = document.getElementById("canvas-dimension");
                    let floorplanName = props.roomReducer.room.floorplan[0].name;
                    let floorplanURL = ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.BASE_URI + props.roomReducer.room.floorplan[0].uid + "/" +  props.roomReducer.room.floorplan[0].name;
                    if(floorplanName.includes('.pdf')) {
                        handlePdfFloorplan(floorplanURL, cDim);
                    }
                    else 
                    {
                        setCanvasBackground(floorplanURL, cDim);   
                    }  
                }
            }
        }
    }, [props.roomReducer.room]);

    const handlePdfFloorplan = (floorplanURL, cDim) => {
        var loadingTask = pdfjsLib.getDocument({ url: floorplanURL });
        loadingTask.promise.then(function (pdf) {
            pdf.getPage(1).then((page) => {
                let scale = 1;
                let viewport = page.getViewport({scale});
                //
                // Prepare canvas using PDF page dimensions
                //
                let canvas = document.getElementById('pdf-canvas');
                let context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
    
                //
                // Render PDF page into canvas context
                //
                let task = page.render({canvasContext: context, viewport: viewport})
                task.promise.then(function(){
                    let imageDataFromPDF = canvas.toDataURL('image/jpeg');
                    setCanvasBackground(imageDataFromPDF, cDim);
                    props.setLoader(false)
                });
                });
        });
    }

    const setCanvasBackground = (floorplanURL, cDim) => {
        if (cDim) {
            window.fabric.Image.fromURL(floorplanURL, (oImg) => {
                let scaleW = cDim.clientWidth / oImg.width;
                let scaleH = cDim.clientHeight / oImg.height;
    
                if (oImg.width < oImg.height) {
                    if (scaleH > scaleW) {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleW,
                            scaleY: scaleW,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                    else {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleH,
                            scaleY: scaleH,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                }
                else if (oImg.width > oImg.height) {
                    if (scaleH > scaleW) {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleW,
                            scaleY: scaleW,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                    else {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleH,
                            scaleY: scaleH,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                }
                else if (oImg.width > 949) {
                    if (scaleH > scaleW) {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleW,
                            scaleY: scaleW,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                    else {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleH,
                            scaleY: scaleH,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                }
                else if(oImg.width < 949 ){
                    if (scaleH > scaleW) {
                        oImg.set({
                            opacity: 1,
                            scaleY: scaleH,
                            scaleX: scaleH,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                    else {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleW,
                            scaleY:scaleW,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                }
                    
                canvasObj.setBackgroundImage(oImg);
                canvasObj.renderAll();
                props.setLoader(false)
    
            },{ crossOrigin: 'anonymous'});
            canvas = canvasObj;
            props.canvas(canvasObj);
        }
    }

    useEffect(() => {
        if (canvas != null && canvas.width != '949') {
            forceUpdate();
        }
        else if (canvas!= null && canvas.width == '949'){
            forceUpdate();
        }
    },[canvas]);

    const setText = text => {
        let t = new window.fabric.Text(text);
        canvas.add(t);
        forceUpdate();

    }

    const changeWindowSize = () => {
        if( props.roomReducer.room.floorplan != undefined ) {
            if( props.roomReducer.room.floorplan[0] != undefined ){
                const cDim = document.getElementById("canvas-dimension");
                let floorplanName = props.roomReducer.room.floorplan[0].name;
                let floorplanURL = ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.BASE_URI + props.roomReducer.room.floorplan[0].uid + "/" +  props.roomReducer.room.floorplan[0].name;
                if(floorplanName.includes('.pdf')) {
                    handlePdfFloorplan(floorplanURL, cDim);
                }
                else 
                {
                    setCanvasBackground(floorplanURL, cDim);   
                }  
            }
        }
    }

    useEffect(() => {
        changeWindowSize();
        forceUpdate();
       
    },[window.devicePixelRatio]);
    
    window.addEventListener('resize', changeWindowSize, false);


    let elementsMenu  = '';
    if (props.roomReducer.room.elements && props.roomReducer.room.elements.length > 0)
    {
        elementsMenu = props.roomReducer.room.elements.map((ele, index) => {
            return ele ?<List.Item>
                <a onClick={(e) => setText(e.target.innerText)}>
                    {ele.elementName}
                </a>
            </List.Item>:''
        });
    }

    //------------- Might be used later ------------------
    // var inputAddText = document.getElementById("add-text");
    // var addTextButton = document.getElementById("add-text-btn");
    // if (inputAddText != null && addTextButton != null) {
    //     inputAddText.addEventListener("keyup", (event) => {
    //         event.stopImmediatePropagation();
    //         if (event.key === 'Enter') {
    //             addTextButton.click();
    //         }
    //     });
    // }

    const deleteText = () => 
    {
        var activeGroup = canvas.getActiveGroup();
        if (activeGroup) {
            var activeObjects = activeGroup.getObjects();
            for (let i in activeObjects) {
                canvas.remove(activeObjects[i]);
            }
            canvas.discardActiveGroup();
            canvas.renderAll();
        } else 
        {
            if (canvas.getActiveObject() != undefined)
            {
                canvas.getActiveObject().remove();
            }
        }
    }
    
   
    return <Row style={{border: '2px solid'}}>
        <Col span={6} style={{height: 500, "padding-top": 24, "background-color": "#d3d3d3"}}>
            <div style={{textAlign: 'center', padding: 15}}>
            <List
                header={<div className="manrope f-14 black-55">Elements (click to place)</div>}
                bordered
                style = {{"border-color": "cornsilk"}}
            >
                <div id= "scroll-style">
                   <div className={elementsMenu.length>5 ? "ant-spin-nested-loading manrope f-12 blue hover-change":"manrope f-12 blue hover-change"}>{elementsMenu}</div>
                </div>
            </List>
            <br/>
                <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Input style={{marginBottom: 10}}  className="manrope f-12 black-55" placeholder="Enter Text" id="add-text" onChange={ (e) => setTextField(e.target.value) } />
                    <Button  style={{marginBottom: 10}} onClick={() => setText(textField)} id="add-text-btn" className='modal-okay square font-12' type="button">Add Text</Button>
                    <Button onClick={deleteText} id="delete-text" className='outline-red-btn square font-12'>Delete Selected Text</Button>
                </div>
            </div>
        </Col>
        
        <Col span={18}>
            <div id="canvas-dimension">
                <canvas height="500" width='949' style={{objectFit:"contain"}} id="c"></canvas>
            </div>
        </Col>
        <canvas id="pdf-canvas" style={{display: "none"}}></canvas>
    </Row>
}

const Annotation = Form.create({ name: 'room_reference' })(AnnotationForm);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({  
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
    getRoom: (room) => {
        dispatch(fetchRoom(room));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(Annotation)
)
