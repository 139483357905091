import React, { useState, useEffect }  from 'react';
import { PlusOutlined, LoadingOutlined, CheckOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Row, Col, Menu, Dropdown, notification, Form, Input, Select, Button, Card, Drawer, message, TreeSelect, Checkbox, Tag, Tooltip, Slider, Tabs, Radio, Breadcrumb, Empty, Pagination } from 'antd';
import ENVIRONMENT from '../../../../environments'
import FileConstants from '../../../../FileConstants';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const StoreContent = (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        forceUpdate();
    },[props.itemType])

  return (<Col span={6} style={{padding : "6px"}} key={props.index}>
  <Card className="card-shadow-product" bordered={false}>
  {props.itemType == 'scenes' || CUSTOMER_USERNAME && props.item_type == 'not_shared' &&
  (CUSTOMER_USERNAME != props.customer_username) && ((props.itemType=='products' && !props.productsAddedToLibrary.includes(props.id)) || (props.itemType=='spaces' && !props.spacesAddedToLibrary.includes(props.id)))?

  <Dropdown overlay={props.get_add_options(props.onAddSceneClick, props.addToLibrary, props.id, props.name, props.price, props.company_display_name, props.itemType)} trigger={['click']}>
      <PlusOutlined
          className="group-settings plus-icon"
          />
    </Dropdown> : (CUSTOMER_USERNAME) && 
    <Tooltip title={<span className='manrope f-12 white'>{ props.getRequestPlan(props.itemType) == 'enterprise' ? CUSTOMER_USERNAME == props.customer_username ? "" :  "You have already added this item into your library!" : CUSTOMER_USERNAME == props.customer_username ? "" : "You have already purchased this item."}</span>}>
    <CheckOutlined className="group-settings check-icon"/></Tooltip>}
    <a 
    href={props.itemType == 'products' && CUSTOMER_USERNAME ? `/product-assets/${props.id}` : 
    props.itemType == 'products' && !CUSTOMER_USERNAME ? `https://all3d.ai/product-assets/${props.id}`: 
    props.itemType == 'spaces' && CUSTOMER_USERNAME ? `/room-assets/${props.id}` : 
    props.itemType == 'spaces' && !CUSTOMER_USERNAME ?`https://all3d.ai/room-assets/${props.id}` : 
    props.itemType == 'scenes' ? `/template/${props.id}`: ``} 
    className="suggested-product" 
    target="_blank" 
    style={{zIndex:"1"}}>
      <div className="box">    
        <img key={props.id} className={`${props.itemType == 'spaces' ? `product-image space-card-img` : `product-image product-card-img`}`} src={props.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.platform)) || require("../../../../assets/images/chair.png")} />
      </div>
      <Tooltip title={<span className='manrope f-12 white'> {props.name}</span>}>
        {props.itemType == 'products' ? <hr style={{marginTop: 0, marginBottom: 0}}/>: ''}
        <div className="manrope f-14 w-500 black-00 j-end clamp-text w-90" style= {{margin:"12px 12px 8px 12px"}}>
            {props.name}
        </div>
      </Tooltip>
      {props.category ? <>
        <div className="justify-in-start manrope f-12 grey-77 capitalize" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"4px",textAlign:"left"}}> 
        <div style={{marginRight: 4}}>Category:</div>
        <div style={{"color":"#333333"}}>{props.category}</div>
      </div>
      </>:""}
      {props.company_display_name? 
      <>
      <div className="justify-in-start manrope f-12 grey-77 capitalize"  style= {{marginBottom:"4px", marginLeft:"12px",marginRight:"12px",textAlign:"left"}}> 
        <div style={{marginRight: 4}}>Brand:</div> 
        <div style={{"color":"#333333"}}>{props.company_display_name}</div>
      </div>
      </>:""}

      <div className="justify-in-start manrope f-12 grey-77 capitalize" style= {{marginBottom:"12px", marginLeft:"12px",marginRight:"12px",textAlign:"left"}}> 
          {props.itemType !='scenes' && props.item_type == 'shared' && props.subscriptionPlan != 'enterprise' && !FileConstants.ALL3D_COMPANY_IDS().includes(props.company_id.toString())
          ? <div style={{marginRight: 4}}>Already Purchased</div> :
          props.itemType !='scenes' && props.item_type == 'shared' && props.subscriptionPlan == 'enterprise' && !FileConstants.ALL3D_COMPANY_IDS().includes(props.company_id.toString())
          ? <div style={{marginRight: 4}}>Added to Library</div> :
          <>
          {props.price == 0 ?
            <div style={{"color":"#333333"}}>Free</div> :
            props.company_id && FileConstants.ALL3D_COMPANY_IDS().includes(props.company_id.toString()) && props.price && props.price !=0 ?
            <div style={{"color":"#333333"}}>Included in platform fee</div> :
            props.price ?
            <>
              <div >Price:</div> &nbsp;
              <div style={{"color":"#333333"}}>${props.price}</div>
            </>: ''}
          </>}
      </div>
    </a>
  </Card>
</Col>);
};



export default StoreContent;