import React, { useEffect, useState, useRef, createRef } from "react";
import { Form, Input, Table, Modal, Select, Row, Col, Button, Divider, message } from "antd";
import axios from "axios";
import ENVIRONMENT from '../../../../environments'
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import useTableFilter from "../../UseTableFilter/useTableFilter";
import * as Utilities from "../../Utilities";
import './GroupManagement.scss'

const { Option } = Select;

export default function GroupManagement() {

    const [customGroups, setCustomGroups] = useState([])
    const [companyUsers, setCompanyUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [manageGroupModalVisible, setManageGroupModalVisible] = useState(false)
    const [formSubmitLoader, setFormSubmitLoader] = useState(false)

    const [getColumnSearchProps] = useTableFilter();
    const formRef = createRef()

    useEffect(() => {
        fetchCustomGroups()
        fetchCompanyUsers()
    }, [])

    function fetchCompanyUsers() {
        if (localStorage.getItem('company_id')) {
            let payload = {
                'filter_string': '(company_id__exact=' + localStorage.getItem('company_id') + ')',
                'required_fields': ["username", "email"],
                'order_by': "username desc"
            }
            axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
                .then(res => {
                    if (res.data) {
                        setCompanyUsers(res.data)
                    }
                })
        }
    }

    function fetchCustomGroups() {
        setIsLoading(true)
        let payload = {
            'action': 'list_groups',
        }
        if(localStorage.getItem('company_id'))
            payload['company_id'] = localStorage.getItem('company_id')
        else
            payload['created_by'] = localStorage.getItem('username')

        axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload)
            .then(res => {
                setIsLoading(false)
                if (res && res['data']) {
                    setCustomGroups(res['data'])
                }
            })
}

    function getUsersNotInSelectedGroup() {
        // This function returns the company users which are not in the group currently selected

        let filtered_users = []
        if(selectedGroup && selectedGroup.users.length > 0){
            filtered_users = companyUsers.filter((comp_user) => {
                return !selectedGroup.users.some((group_user) => comp_user.email == group_user.email && comp_user.username == group_user.username)
            })
        }
        else{
            filtered_users = companyUsers
        }
        return filtered_users
    }

    function addUsersToGroup(values) {
        let payload = {
            'action': 'add_users',
            'added_by': localStorage.getItem('username'),
            'emails': values['users'],
            'group_id': selectedGroup.group_id
        }

        setFormSubmitLoader(true)

        axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload)
        .then(res => {
            if(res.data && res.data.statusCode == 200){
                message.info('User(s) added to group successfully')
                setManageGroupModalVisible(false)
                setFormSubmitLoader(false)
                fetchCustomGroups()
            }
            else{
                message.error('Something went wrong. Please try again')
            }
        })
    }

    function removeUserFromGroup(group_id, username) {
        let payload = {
            'action': 'remove_user',
            'group_id': group_id,
            'username': username
        }
        
        axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload)
        .then(res => {
            if(res.data && res.data.statusCode == 200){
                message.info('User removed from group')
                let updatedUsers = selectedGroup.users.filter((user) => {
                    return user.username != username
                })

                let updatedSelectedGroup = selectedGroup
                updatedSelectedGroup.users = updatedUsers
                // Update users in the selected group, it will update the UI of modal
                setSelectedGroup(updatedSelectedGroup);

                // update the selectedGroup in the customGroup list, this will render the table with updated user count in group
                const updatedCustomGroups = customGroups.map(group => 
                    group.group_id == updatedSelectedGroup.group_id ? updatedSelectedGroup : group
                )
                setCustomGroups(updatedCustomGroups)

            }
            else{
                message.error('Something went wrong. Please try again')
            }
        })

    }


    function createCustomGroup(values) {
        let payload = {
            'action': 'create_group',
            'created_by': localStorage.getItem('username'),
            'company_id': localStorage.getItem('company_id'),
            'emails': values['users'],
            'name': values['name']
        }

        setFormSubmitLoader(true)

        axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload)
        .then(res => {
            if(res.data && res.data.statusCode == 200){
                message.info('Group Successfully Created')
                setManageGroupModalVisible(false)
                setFormSubmitLoader(false)
                fetchCustomGroups()
            }
            else{
                message.error('Something went wrong. Please try again')
            }
        })
    }

    const customGroupColumns = [
        {
            title: "Group Name",
            dataIndex: "group_name",
            key: "group_name",
            ...getColumnSearchProps("Group Name"),
        },
        {
            title: "Total Users",
            dataIndex: "users_count",
            key: "users",
            render: (text, record) => (
                <span>
                    {record.users.length}
                </span>
            )
        },
        {
            title: "Action",
            render: (text, record) => (
                <a onClick={() => { setSelectedGroup(record); setManageGroupModalVisible(true) }}>Manage Group</a>
            )
        }
    ]

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button type="primary" shape="square" style={{ marginBottom: 10, backgroundColor: '#276DD7' }}
                    onClick={() => { setManageGroupModalVisible(true) }} icon={<PlusOutlined />} size={"medium"}>
                    Create Group
                </Button>
            </div>

            <Table
                dataSource={customGroups}
                loading={{ indicator: <LoadingOutlined />, spinning: isLoading }}
                columns={customGroupColumns}
            />


            <Modal
                visible={manageGroupModalVisible}
                destroyOnClose={true}
                onCancel={() => {
                    setManageGroupModalVisible(false)
                    setSelectedGroup(null)
                }}
                title={
                    selectedGroup ? "Manage Group" : "Create Group"
                }
                footer={null}
            >
                <>
                {selectedGroup === null ? '' : <p style={{color: 'red', marginBottom: 10, marginTop: -10}}>Note: Adding users will grant access to already shared items and removing users from group will remove their access to group wide shared item.</p>}
                    {selectedGroup && selectedGroup.users.length > 0 ?
                        <div>
                            <p className="mb-10">Users in this group</p>
                            {
                                selectedGroup.users.map((item, index) => {
                                    return (
                                        <div key={item.index} className="group-users-list">
                                            <div>
                                                <span className="manrope f-14 w-700" style={{ display: 'block' }}>{item.username}</span>
                                                <span className="manrope f-12 w-400" style={{ display: 'block' }}>{item.email}</span>
                                            </div>
                                            <DeleteOutlined style={{ color: 'red' }}  onClick={
                                                () => removeUserFromGroup(selectedGroup.group_id, item.username)
                                                }/>

                                        </div>
                                    )
                                })
                            }
                            <Divider />
                        </div>

                        : ''
                    }

                    {/* Form to create group and add users in the group */}
                    <Form
                        ref={formRef}
                        onFinish={(values) => {
                            selectedGroup ? addUsersToGroup(values) : createCustomGroup(values)
                        }}>
                        {selectedGroup == null ?
                            <Form.Item
                                style={{ display: "block" }}
                                label={<span className="manrope f-12 black-55 w-600"> Group Name</span>}
                                name="name"
                                rules={[{ required: true, message: 'Please enter group name' },
                                {
                                    validator:  (_, value) => {
                                        // if scene with same name exists
                                        if (value) {
                                            let customGroupNames = customGroups.map(function(item) {
                                                return item.group_name;
                                            });
                                            if ((customGroupNames.includes(value))) {
                                                return Promise.reject('Group name must be unique');
                                            }
                                        }
                                        return Promise.resolve()
                                    }
                                }
                            ]}

                            >
                                <Input className="manrope f-12 black-55 library-search" placeholder="Type Group Name" />
                            </Form.Item> : ''
                        }
                        {selectedGroup &&
                            <p className="pb-10">Add new user(s) to this group</p>
                        }
                        <Form.Item
                            label={<span className="manrope f-12 black-55 w-600"> Choose User(s)</span>}
                            className="material-category-form"
                            name="users"
                            style={{ display: "block" }}
                            rules={[{ required: true, message: 'Please select one or more user' },
                        {
                            validator: Utilities.validateEmailsList
                        }]}
                        >

                            <Select
                                className="manrope f-12 black-55 select-tags selection-popup"
                                placeholder="Choose users to add in the group"
                                mode="tags"
                            >
                                {getUsersNotInSelectedGroup().map((obj, index) => {
                                    return (<Option key={index} value={obj.email}> 
                                                {obj.email}
                                            </Option>
                                        )
                                })}

                            </Select>
                        </Form.Item>

                        <Form.Item
                        >
                            <Button className="modal-okay square font-14" disabled={formSubmitLoader} style={{display: 'flex', float: 'right'}} htmlType="submit">
                                Save {formSubmitLoader && <LoadingOutlined />}
                            </Button>
                        </Form.Item>

                    </Form>
                </>
            </Modal>
        </>
    );
};
