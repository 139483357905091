import React, { useState, useEffect } from 'react';
import {
    Col,
    Card,
    Tooltip,
    Dropdown,
    Menu
} from 'antd';
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'
import {Link} from 'react-router-dom'
import '../../../../styles/helper.scss'
import * as Utilities from '../../Utilities'
import * as Styles from '../../../../Styles';
import { Grid, AutoSizer } from 'react-virtualized';
import AddNewEntity from '../AddNewEntity/AddNewEntity';
import LibraryGrid from '../LibraryGrid/LibraryGrid';

const layout_maping = Styles.layout_maping;
const collageSettings = Styles.collageSettings;
const height_maping = Styles.height_maping;

let get_options = (collection_id, openDeleteModal, collectionUsername, collectionCompanyId) => {
    return <Menu>
    <Menu.Item>
      <a className="manrope f-14" onClick={() => openDeleteModal(collection_id, collectionUsername, collectionCompanyId) }>Delete Collection</a>  
    </Menu.Item>    
  </Menu>
};

const CollectionsList = (props) => {
    const [columnCount, setColumnCount] = useState(4);
    const [rowCount, setRowCount] = useState(1);
    const [data, setData] = useState([]);
    const maxColumnWidth = 280;
    const rowSize = 280;

    // column widths are stored in a state by index
    const [columnWidths, setColumnWidths] = useState(() => {
        const containerWidth = window.innerWidth - 80; // Get the width of the container element
        const columnWidthPercentage = 25; // Set the desired column width percentage

        const columnWidth = ((containerWidth * columnWidthPercentage) / 100) - 38;
        const initialWidths = new Array(columnCount).fill(columnWidth); // Set initial column widths here
        return initialWidths;
    });

    // function to handle column width changes
    const handleColumnWidthChange = ({ index, width }) => {
        setColumnWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[index] = width;
            return newWidths;
        });
    };

    const getColumnWidth = ({ index }) => columnWidths[index];

    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        if(rowIndex === 0 && columnIndex === 0 && props.searchValue == '' 
            && (props.collection_type == 'your_models' || props.collection_type == 'selected_customer'))
        {
            return(
            <div key={key} style={style}>
            <Col lg={24} sm={24} md={8} offset={0}>
            <Card data_tut="reactour__collection" onClick={props.addCollectionRedirect} className="card-shadow-product" bordered={false} style={{ marginRight: "10px", marginBottom: "10px",height:"270px",backgroundColor:"#FAFAFA",cursor:"pointer" }}>
                <div>
                    <img className="create-button-box" src={require("../../../../assets/images/create_collection.svg")} />
                    <div style={{top:"223px"}} className="create-button-text  manrope f-14 black-00">Create New Collection</div>
                </div>
                
            </Card>
            </Col>
            </div>)
        }

        const dataColumnIndex = (props.searchValue || (props.collection_type != 'your_models' && props.collection_type != 'selected_customer')) ? columnIndex : columnIndex - 1;

        const itemIndex = (rowIndex * columnCount) + dataColumnIndex;
        const ele = data[itemIndex] && data[itemIndex];
        if (!ele) {
            return null;
        }
        
        let collageObject =  Object.assign(collageSettings);
            collageObject.layout = layout_maping[2];
            collageObject.height = height_maping[2];
            collageObject.photo = ele.thumbnail_link;

            if(ele.product_thumbnails.length === 0 || ele.thumbnail_link == "")
            {
                collageObject.photo = "/img/empty-collection.png"
                collageObject.layout = layout_maping[1];
                collageObject.height = height_maping[1];
            }
            else if(ele.product_thumbnails.length < 6 && ele.product_thumbnails.length > 0 )
            {
                collageObject.layout = layout_maping[ele.product_thumbnails.length];
                collageObject.height = height_maping[ele.product_thumbnails.length];
            }
            else{
                collageObject.layout = layout_maping[6];
                collageObject.height = height_maping[6];
            }
        
        let CollectionEntry = props.libraryCollection.length > 0 ? <Col lg={24} sm={24} md={8} style={{cursor: "pointer"}} onClick={() => {
            if (props.select) {
                props.setSelectedCollection(ele.id);
            }
        }}>
                <Card  bodyStyle={(props.select && (ele.id==props.selectedCollection))?{padding:0,pointerEvents: "none", border:"1px solid #25d6a4"}:{padding:0,pointerEvents: 'auto'}} className="card-shadow-product" bordered={false} style={{marginRight: "10px", marginBottom: "10px", cursor: "pointer", height:"270px" }} loading={false}>
                    <Link key={key} to={ props.select ? `#` : `/collection/${ele.id}/products?name=${encodeURIComponent(ele.name)}`} className='suggested-product' style={{zIndex:"1"}}>
                        <div>
                            <div className="box" style={{height: "200px"}}>
                            <img src={collageObject.photo} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain", borderRadius: "4px", zIndex: "1", border: "none" }} />
                                <div className="text manrope f-14 white">
                                    {ele.spaces_count == '' ? 0 : ele.spaces_count} Space and {ele.products_count == '' ? 0 : ele.products_count} Product Models
                                </div>
                            </div>
                            <div className='justify-in-start' style= {{margin:"12px 12px 8px 12px"}}>
                                <Tooltip title={<span className='manrope f-12 white'>{ele.name}</span>}>
                                    <div className="manrope f-14 w-500 black-00 clamp-text w-90" style= {{textAlign:"left"}}>
                                        {ele.name}
                                    </div>
                                </Tooltip>    
                               {ele.last_modified && <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(ele.last_modified, 'tooltip')}</span>}>                                                  
                                    <span className="manrope f-12 grey-77 w-500 time-clock-bg"  style={{marginLeft: 4}}>
                                        <img src='/img/carbon_time.svg'  style={{marginRight: 2}}/>{Utilities.timestampToTimeSince(ele.last_modified)}
                                    </span>
                                </Tooltip>    }
                            </div>
                    
                            <div className="justify-in-start" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"12px",textAlign:"left"}}> 
                                <span className="manrope f-12 grey-77 capitalize" style={{marginRight: 4}}>Collection ID</span>
                                <span className="manrope f-12 black-33 capitalize">{`${ele.id}` || 'N/A'}</span>
                            </div>
                        </div>
                    </Link>
                    {!props.scene_flow && (
                        <Dropdown className="group-settings more-icon" overlay={get_options(ele.id, props.openDeleteModal, ele.customer_username, ele.company_id)} trigger={['click']}>
                            <MoreIcon/>
                        </Dropdown>
                    )}
                </Card>
            </Col>:''

        return (
        <div key={key} style={style}>
            {props.searchValue === '' || Utilities.isMatching(props.searchValue.toLowerCase(), ele.name.toLowerCase()) ? CollectionEntry : ""}
        </div>
        )
    }
    
    useEffect(()=>{
        let collectionData = [];

        if (props.searchValue == '' && props?.libraryCollection.length) {
            collectionData = [...props.libraryCollection];
            if (collectionData.length !== data.length) {
                props.updateTabCount(-1, props.collection_type)
            }
        }
        else if (props?.libraryCollection.length && props.searchValue)
        {
            props.libraryCollection.map((ele)=>
            {
                if (Utilities.isMatching(props.searchValue.toLowerCase(), ele.name.toLowerCase())) {
                    collectionData.push(ele);
                }
            });
            if (collectionData.length !== data.length) {
                props.updateTabCount(collectionData.length, props.collection_type)
            }
        }
        setData(collectionData);
        setRowCount(Math.ceil(collectionData.length / columnCount));
    }, [props.searchValue,props.libraryCollection]);

    if(!props.loader && props.libraryCollection.length > 0)
    {
        return (
            <>
            <LibraryGrid
                setColumnCount = {setColumnCount}
                setRowCount = {setRowCount}
                maxColumnWidth = {maxColumnWidth}
                data = {data}
                cellRenderer={cellRenderer}
                columnCount={columnCount}
                getColumnWidth={getColumnWidth}
                rowCount={rowCount}
                getEstimatedRowSize={rowSize}
                handleColumnWidthChange={handleColumnWidthChange}
            />
            </>
          );
    }
    else
        return "";
}

export default (CollectionsList)
