import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import CustomerMainLayout from '../CustomerMainLayout';
import { Button } from 'antd';

class CustomerHelp extends React.Component {
    render () {
        return (
            <CustomerMainLayout>
                <div style={{textAlign:"center" ,marginTop: 24, padding: 24}}>
                    <h5>
                    We will have our help section populated with explainer videos very soon. For now, please check out the onboarding if you haven't already.
                    </h5>
                    <Button style={{textAlign:"center" ,marginTop: 24}} type="danger" onClick={() => this.props.history.push("/home?step_no=1")}>Start Onboarding Session</Button>
                </div>
            </CustomerMainLayout>
        );
    }
}

export default withRouter(CustomerHelp);