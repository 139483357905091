import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Row, Col, Modal, Tabs } from "antd";
import SpaceImageCarousel from "./SpaceImageCarousel";
import SpaceThreeSixtyViews from "./SpaceThreeSixtyViews";

const { TabPane } = Tabs;

const SpaceAssetViewer = ({
  renders,
  top_view,
  setDisplay360,
  selectedImage,
  changeSelectedImage,
  three_sixties,
  setFullScreen,
  isFullScreen,
  platform,
  selected360,
  displayDetailType,
  setDisplayDetailType,
  selectedImageUrl,
  storePage,
  id,
  roomDetails,
  downloadSceneImagesArchive
}) => {
  const [selectedAsset, setSelectedAsset] = useState(null);

  const changeAssetTab = (key) => {
    setSelectedAsset(key);
  };

  const [productAssetViewer, setProductAssetViewer] = useState(true);

  const closeSpaceAssetViewer = () => {
    setSelectedAsset(null);
    setDisplayDetailType(null);
  };

  useEffect(() => {
    if (displayDetailType) setSelectedAsset(displayDetailType);
  }, [displayDetailType]);

  return (
    <Modal
      className="space-asset-viewer-modal"
      visible={!!selectedAsset}
      footer={null}
      closable={true}
      onCancel={closeSpaceAssetViewer}
    >
      <Row className="h-full">
        <Col span={24} className="h-full children-full">
          <Tabs
            className="items-table h-full space-tabs-override"
            onChange={(key) => changeAssetTab(key)}
            tabBarGutter={32}
            activeKey={selectedAsset}
            size="large"
          >
            <TabPane
              tab={
                <span
                  className={`manrope f-14 asset-tab-heading ${
                    selectedAsset == "3d_preview" ? `selected` : ``
                  }`}
                >
                  360 Spin
                </span>
              }
              key={"3d_preview"}
              className="pd-10 py-none modal-height"
            >
              <div className="h-full">
                <SpaceThreeSixtyViews
                setDisplay360={setDisplay360}
                selectedImage={selectedImage}
                three_sixties={three_sixties}
                setFullScreen={setFullScreen}
                isFullScreen={isFullScreen}
                platform={platform}
                selected360={selected360}
                storePage={storePage}
                downloadSceneImagesArchive={downloadSceneImagesArchive}
              />
              </div>
              
            </TabPane>
            {renders?.length <= 0 && !top_view ? (
              <></>
            ) : (
              <TabPane
                tab={
                  <span
                    className={`manrope f-14 asset-tab-heading ${
                      selectedAsset == "images" ? `selected` : ``
                    }`}
                  >
                    Images
                  </span>
                }
                key={"images"}
                className="pd-10 py-none modal-height"
                disabled={renders?.length <= 0 && !top_view}
              >
                <div className="h-full">
                <SpaceImageCarousel
                  renders={renders}
                  top_view={top_view}
                  platform={platform}
                  selectedImageUrl={selectedImageUrl}
                  storePage={storePage}
                  id={id}
                  roomDetails={roomDetails}
                />
                </div>
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </Modal>
  );
};

export default SpaceAssetViewer;
