import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

class PanoramaThumbnailViewer extends React.Component {

    constructor(props){
        super(props);
        this.viewer = null;
        this.autorotate = null;
    }

    componentDidMount() {
        this.viewer = new window.Marzipano.Viewer(this.el, );
        this.autorotate = window.Marzipano.autorotate({
            yawSpeed: 0.3,         // Yaw rotation speed
            targetPitch: 0,        // Pitch value to converge to
            targetFov: Math.PI/2   // Fov value to converge to
        });
        
        // Create source.
        var source = window.Marzipano.ImageUrlSource.fromString(
            this.props.threeSixtyUrl
        );

        // Create geometry.
        var geometry = new window.Marzipano.EquirectGeometry([{ tileSize: 512, size: 512 }]);

        // Create view.
        var limiter = window.Marzipano.RectilinearView.limit.traditional(512, 100*Math.PI/180);
        var view = new window.Marzipano.RectilinearView(null, limiter);

        // Create scene.
        var scene = this.viewer.createScene({
        source: source,
        geometry: geometry,
        view: view,
        pinFirstLevel: true
        });

        // Display scene.
        scene.switchTo();
        this.viewer.controls().disable();

        //Following is a temporary hack
        var parnoramaDivs = document.getElementsByClassName("panorama-thumbnail-styles");
        
        for (var i=0; i< parnoramaDivs.length;i++){
            //console.log(parnoramaDivs[i]);
            parnoramaDivs[i].style.cursor = "pointer";
        }

        if(this.props.customClass){
            parnoramaDivs = document.getElementsByClassName(this.props.customClass);
            
            for (var i=0; i< parnoramaDivs.length;i++){
               // console.log(parnoramaDivs[i]);
                parnoramaDivs[i].style.cursor = "pointer";
            }
        }



    }

    handleMouseOverViewer = () => {
        this.viewer.startMovement(this.autorotate);
    }

    handleMouseLeaveViewer = () => {
        this.viewer.stopMovement();
    }



    render () {
        return (
            <div>
            <div
                className={this.props.customClass ? this.props.customClass : 'panorama-thumbnail-styles'}
                ref={ref => (this.el = ref)}
                onMouseEnter={this.handleMouseOverViewer}
                onMouseLeave={this.handleMouseLeaveViewer}
                style = {{cursor: "pointer !important"}}
            /> 
            {/* {console.log(this.el)} */}
             {/* {this.image.height == 0 ? "" :<LoadingOutlined size="large" spin  style={{textAlign:"center",position:"absolute",left:"50%", top:"50%",fontSize:"30px",zIndex:"1",color:"#333333"}}/> } */}
            </div>
        );
    }
}

export default PanoramaThumbnailViewer;