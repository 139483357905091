import React from "react";
import axios from 'axios';
import ENVIRONMENTS from '../../../../environments'
import {calcTotalModelsWorked, getNeedToModelString} from '../../Utilities'
import DottedLoader from "../../DottedLoader";
import { Button, Row, Col, Modal, Select, DatePicker, Table, Tooltip } from "antd";
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import AdminMainLayout from '../AdminMainLayout';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
const DATE_FORMAT = 'MM/DD/YYYY';

class ArtistTimeLog extends React.Component {
  csvLink = React.createRef();

  state = {
    artist_username: '',
    profile_name: '',
    email: '',
    billing_type: '',
    is_active: false,
    is_superartist: false,
    hourly_rate: 0,
    rating: 0,
    start_date: moment().startOf('month').format(DATE_FORMAT),
    end_date: moment().endOf('month').format(DATE_FORMAT),
    invoice_data: [],
    invoice_summary: {},
    filtered_invoice_date: [],
    loading_invoice_date: false,
    model_type_filter: '',
    customer_username_filter: '',
    invoice_filename: '',
    csv_data_source: [],
    export_invoice_modal: false,
    // invoice summary variables
    total_hours_worked: 0,
    total_models_worked: 0,
    total_amount_invoiced: 0,
    required_invoicing_data: 'all'
  };

  componentDidMount() {
    let artist_id = this.props.match.params.id;
    axios.post(ENVIRONMENTS.GET_ARTIST_PROFILE, {
      artist_id: artist_id,
    })
      .then(res => {
        console.log('artist = ', res.data);
        this.setState({
          artist_username: res.data['artist_username'],
          profile_name: res.data['profile_name'],
          email: res.data['email'],
          billing_type: res.data['billing_type'],
          hourly_rate: parseFloat(res.data['hourly_rate']),
          is_active: res.data['is_active'],
          rating: res.data['rating']
        }, this.fetchArtictInvoice(res.data['artist_username']));
      })
  }

  prepareCSVData = () => {    
    let data = [];
    let dataSource = Array.from(this.state.invoice_data);
    for (let i = 0; i < dataSource.length; i++) {
        data.push({
            'Model ID': dataSource[i]['model_id'],
            'Model Name': dataSource[i]['model_name'].replace('"', '""'),
            'Model Type': dataSource[i]['model_type'],
            'Task Type': dataSource[i]['request_type'],
            'Customer': dataSource[i]['customer_username'],
            'Hours Worked': parseFloat(dataSource[i]['hours'] || 0)
        });
        
    }
    let filename = this.state.artist_username + "_ from_" + this.state.start_date + "_to_" + this.state.end_date + "_.csv";

    this.setState({
        csv_data_source: data,
        invoice_filename: filename,
        export_invoice_modal: false
    }, () => {
        this.csvLink.current.link.click()
      })
  }

  onCalenderChange = (date_values) => {
    if (date_values && date_values[0] && date_values[1]) {
        this.setState({
          start_date: date_values[0].format(DATE_FORMAT),
          end_date: date_values[1].format(DATE_FORMAT)
        }, () => {this.fetchArtictInvoice(this.state.artist_username)})
    }
}

  fetchArtictInvoice = (artist_username) => {
    let payload = {
      'start_date': moment(this.state.start_date).format('YYYY-MM-DD'),
      'end_date': moment(this.state.end_date).format('YYYY-MM-DD'),
      'artist_usernames': [artist_username],
    }
    console.log('payload = ', payload)
    this.setState({
      loading_invoice_date: true
    })
    axios.post(ENVIRONMENTS.GET_ARTIST_INVOICE, payload)
    .then(res => {
      console.log('invoicing data = ', res);
      this.setState({
        invoice_data: res.data['invoice_data'],
        filtered_invoice_date: res.data['invoice_data'],
        invoice_summary: res.data['summary'],
        loading_invoice_date: false
      }, ()=> {this.filterInvoiceData()})
    })

  }

  getCustomerUsernames = () => {
    let usernames = [];
    for(let i=0; i < this.state.invoice_data.length; i++){
      if(!usernames.includes(this.state.invoice_data[i]['customer_username'])){
        usernames.push(this.state.invoice_data[i]['customer_username']);
      }
    }

    console.log('usernames = ', usernames);
    return usernames
  }

  getTotalModelsWorked = () => {
    let total_models = 0;
    total_models = this.state.invoice_summary['spaces'] + this.state.invoice_summary['space_variants'] + this.state.invoice_summary['product_variants'] + this.state.invoice_summary['products'] + this.state.invoice_summary['materials'] + this.state.invoice_summary['complaints']
    if(!total_models)
      total_models = 0;
    return total_models;
  }

  getBillingRateString = () => {
    let billing_rate = "N/A";
    if (this.state.hourly_rate && (this.state.hourly_rate == 0 || this.state.hourly_rate == "")){
      billing_rate = "N/A";
    }
    else if(this.state.hourly_rate){
      billing_rate = "$" + this.state.hourly_rate;
      if (this.state.billing_type == "hourly")
        billing_rate += " / hour";
      else if(this.state.billing_type == "per_model")
        billing_rate += " / model";
    }
    return billing_rate;
  }


  getColumns = () => [
    {
      title: <span className="manrope f-16 black-14">Model ID</span>,
      dataIndex: 'model_id',
      key: 'model_id',
      render: text => <span  className="manrope f-14 black-14">{text}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">Model Name</span>,
      dataIndex: 'model_name',
      key: 'model_name',
      render: text => <span  className="manrope f-14 black-14">{text}</span>,
    
    },
    {
      title: <span className="manrope f-16 black-14">Model Type</span>,
      dataIndex: 'model_type',
      key: 'model_type',
      render: (text, index) => <span className="manrope f-14 black-14">{text} {index['variant_of'] != null ? ' Variation' : ''}  {getNeedToModelString(index)}</span>,
    },
    {
        title: <span className="manrope f-16 black-14">Task Type</span>,
        dataIndex: 'request_type',
        key: 'request_type',
        render: text => <span  className="manrope f-14 black-14">{text}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">Customer</span>,
      dataIndex: 'customer_username',
      key: 'customer_username',
      render: text => <span  className="manrope f-14 black-14">{text || 'N/A'}</span>,
    },
    {
        title: <span className="manrope f-16 black-14">Hours Worked</span>,
        dataIndex: 'hours',
        key: 'hours',
        render: text => <span className="manrope f-14 black-14">{parseFloat(text || 0) + ' Hours'}</span>,
    },
];


filterInvoiceData = () => {
  let filtered_data = [];
  let filters = {};
  if(this.state.customer_username_filter != ''){
    filters['customer_username'] = this.state.customer_username_filter;
  }

  if(this.state.model_type_filter != ''){
    filters['model_type'] = this.state.model_type_filter;
  }
  console.log('filters = ', filters);

  filtered_data = this.state.invoice_data.filter(function(item) {
    for (var key in filters) {
      if (item[key] != filters[key])
        return false;
    }
    return true;
  });

  this.setState({filtered_invoice_date: filtered_data})
}

  render() {

    return (
      <AdminMainLayout selected={"8"}>
        <Row>
          <Col span={17}>
            <h3 style={{marginTop: 25, fontSize: 32, fontWeight: 800}}>Artist Time Log</h3>
          </Col>

          <Col span={7} style={{alignSelf: 'center', display: 'flex', justifyContent: 'flex-end'}}>
              <Button className="save-button okay font-16" style={{marginLeft: 5}} onClick={() => this.setState({export_invoice_modal: true})}>
                Export Invoice
              </Button>
              <CSVLink data={this.state.csv_data_source}
                filename={this.state.invoice_filename}
                style={{display: 'none'}}
                ref={this.csvLink}
              >
              </CSVLink>
            </Col>
        </Row>

        <Row className="justify-in-start">
          
          <Col span={5}>
            <RangePicker size={"large"} defaultValue={[moment(this.state.start_date, DATE_FORMAT), moment(this.state.end_date, DATE_FORMAT)]}
                    format={DATE_FORMAT} className="date-picker" popupStyle={{fontFamily: 'Manrope', fontSize: 14,color:"#40a9ff",background:"#e6efff" }} placeholder={['Date (From)', 'Date (To)']} 
                    value={[moment(this.state.start_date, DATE_FORMAT), moment(this.state.end_date, DATE_FORMAT)]}
                    onCalendarChange={val => this.onCalenderChange(val)}/>
          </Col>

          <Col span={4} offset={1}>
            <Select placeholder="Model Type: All" size={"large"} style={{width: '90%'}} onChange={val => {this.setState({model_type_filter: val}, ()=>this.filterInvoiceData())}}>
            <Option className="manrope f-14 black-55 select-view" value="">Model Type: All</Option>
              <Option className="manrope f-14 black-55 select-view" value="Space">Space</Option>
              <Option className="manrope f-14 black-55 select-view" value="Product">Product</Option>
              <Option className="manrope f-14 black-55 select-view" value="Material">Material</Option>
              <Option className="manrope f-14 black-55 select-view" value="Complaint">Complaint</Option>
            </Select>
          </Col>

          <Col span={4}>
            <Select placeholder="Customer: All" size={"large"} style={{width: '90%'}} onChange={val => {this.setState({customer_username_filter: val}, ()=>this.filterInvoiceData())}}>
              <Option className="manrope f-14 black-55 select-view" value="">Customer: All</Option>
              {this.getCustomerUsernames().map((ele) => {
                return <Option className="manrope f-14 black-55 select-view" value={ele}>{ele}</Option>
              })}
            </Select>
          </Col>
        </Row>

        {this.state.loading_invoice_date ? <DottedLoader/> :
        <React.Fragment>
           <Row style={{marginTop: 20, minHeight: '50vh'}}>
              <Col span={24}>
                  <Table columns={this.getColumns()} dataSource={this.state.filtered_invoice_date} pagination={{ pageSize: 7}}/>
              </Col>
            </Row>

            <span style={{marginTop: 25, fontSize: 20, fontWeight: 600, marginTop: 30}}>Work Summary</span>
            <Row className="artist-details-info" style={{marginBottom: 20}}>
                <Col span={6}>
                  <div>
                    <p className="artist-details-heading">Total Hours Worked</p>
                    <p className="artist-details-value">{this.state.invoice_summary['hours'] ? this.state.invoice_summary['hours'] + ' Hours' : '0 Hours'}</p>
                  </div>
                </Col>

                <Col span={6}>
                  <div>
                    <p className="artist-details-heading">Total Models Made
                    {this.getTotalModelsWorked() > 0 ?
                    <Tooltip title={<span className='manrope f-12 white'>
                      {this.state.invoice_summary['spaces'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Spaces: {this.state.invoice_summary['spaces']}</span> : ''
                      }
                      {this.state.invoice_summary['space_variants'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Space Variants: {this.state.invoice_summary['space_variants']}</span> : ''
                      }
                      {this.state.invoice_summary['products'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Products: {this.state.invoice_summary['products']}</span> : ''
                      }
                      {this.state.invoice_summary['product_variants'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Product Variants: {this.state.invoice_summary['product_variants']}</span> : ''
                      }
                      {this.state.invoice_summary['complaints'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Complaints: {this.state.invoice_summary['complaints']}</span> : ''
                      }
                      {this.state.invoice_summary['materials'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Materials: {this.state.invoice_summary['materials']}</span> : ''
                      }

                    </span>}>
                      <InfoCircleOutlined style={{marginLeft: 3}}/>
                    </Tooltip> : ''}
                    </p>
                    <p className="artist-details-value">{this.getTotalModelsWorked()}</p>
                  </div>
                </Col>

                <Col span={6}>
                  <div>
                    <p className="artist-details-heading">Billing Rate</p>
                    <p className="artist-details-value">{this.getBillingRateString()}</p>
                  </div>
                </Col>

                <Col span={6}>
                  <div>
                    <p className="artist-details-heading">Total Amount Invoiced</p>
                    <p className="artist-details-value">{this.state.invoice_summary['amount'] || '$0.0'}</p>
                  </div>
                </Col>
            </Row>
          

        </React.Fragment>
        }

        <Modal  
          visible={this.state.export_invoice_modal}
          width={500}
          closable={false}
          footer={[
            <div className="justify-in-end">
                <Button className="modal-okay-gray square font-14"  htmlType="submit" onClick={() => this.setState({export_invoice_modal: false})}>
                    Cancel
                </Button>
                <Button disabled={this.state.buttonLoader} className="modal-okay square font-14" htmlType="submit" onClick={() => this.prepareCSVData()}>
                    Export&nbsp;{this.state.buttonLoader ? <LoadingOutlined/>: ''}
                </Button>
                </div>
            ]}>
          <div style={{padding:"32px 32px 24px 32px"}}>
          <div className="manrope f-20 black-14" style={{marginBottom: 16,textTransform:"capitalize"}}>
            Export Invoice
          </div>
          <div className="manrope f-16 black-14" style={{marginBottom: 5,textTransform:"capitalize"}}>
            Select Date Range
          </div>
          <Row className="justify-in-start">
            <Col>
              <RangePicker size={"large"} defaultValue={[moment(this.state.start_date, DATE_FORMAT), moment(this.state.end_date, DATE_FORMAT)]}
                format={DATE_FORMAT} className="date-picker" popupStyle={{fontFamily: 'Manrope', fontSize: 14,color:"#40a9ff",background:"#e6efff" }} placeholder={['Date (From)', 'Date (To)']} 
                value={[moment(this.state.start_date, DATE_FORMAT), moment(this.state.end_date, DATE_FORMAT)]}
                onCalendarChange={val => this.onCalenderChange(val)}/>
            </Col>
          </Row>
          </div> 
        </Modal>


      </AdminMainLayout>
    );
  }
}

export default ArtistTimeLog;