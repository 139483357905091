import React from 'react';
import { Modal} from 'antd';
 
const AlertModal = (props) => {

    return (
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={696}
        onCancel={props.onCancel}
        visible={props.visible}
        bodyStyle={{padding: 0}}
        centered={true}
        footer={props.footer}>
        <div style={{padding:"40px"}}>
            <div style={{textAlign: "center"}}>
                <img style={{width: 85,height:85}} src={require("../../../assets/images/alert_icon.png")}></img>
            </div>
            <div style={{textAlign: "center",margin: "20px auto"}}>
                <h2  className="manrope f-24 yellow w-700 justify-in-center">{props.heading}</h2>
                <p className="manrope f-16 grey-77">{props.text}</p>
            </div>     
        </div>       
    </Modal>
      );
}

export default AlertModal;