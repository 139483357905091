import * as THREE from 'three'
import Constants from "../Constants.js"
import {getNormalForIntersect} from "../HelperFunctions"
export default class MeasurementTool {

    startPoint = null;
    endPoint = null;
    currentPoint = null;
    startRing = null;
    endRing = null;
    currentRing = null;
    spaceManager = null;
    raycastManager = null;
    mouse = null;
    enabled = false;
    active = false;
    measurementLine = null;
    measurementLabel = null;
    helperAxis = {};
    helperDirections = {};
    materials = {};
    inchesFactor = 39.3701;

    /**
     * Struct that contains references to all the event callbacks for this module.
     */
    events = {
        scope : null,

        onMouseDown () {
            this.scope.#onMouseDown();
        },
        onMouseMove () {
            this.scope.#onMouseMove();
        },
        onMouseUp () {
            this.scope.#onMouseUp();
        }
    }

    /**
     * Measurement Tool is used to measure between two points
     * @param {SceneCreator} sceneCreator - The main scene creator instance.
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     * @param {THREE.Vector2} mouse - Reference to vector2 containing mouse position in NDC (normalized device coordinates). 
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     * @param {boolean} fixNormal - Check if normal needs to be fixed.
     */
    constructor(sceneCreator, scene, mouse, managersDict, fixNormal) {
        this.sceneCreator = sceneCreator;
        this.mouse = mouse;
        this.scene = scene;
        this.fixNormal = fixNormal;
        this.events.scope = this;
        this.setupRequiredManagers(managersDict);
        this.measurementLabel = this.createMeasurementLabel();
    }

    /**
     * Setup all helper managers required in this module
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    setupRequiredManagers(managersDict) {
        this.spaceManager = managersDict[Constants.Manager.SpaceManager];
        this.raycastManager = managersDict[Constants.Manager.RaycastManager];
    }

    enableMeasurementTool (state) {
        this.enabled = state;
        if(!state) {
            this.disposeMeasurementTool();
        }
    }

    createMeasurementLabel() {
        var label = document.createElement('div');
        label.setAttribute("id", "MeasurementLabel");
        label.style.position = 'absolute';
        label.style.height = 'auto'; // Allow height to adjust automatically based on content
        label.style.width = 'auto';  // Allow width to adjust automatically based on content
        label.style.fontSize = "35px";
        label.style.fontWeight = "bold";
        label.style.color = "#276DD7";  // Blue color for text contrast
        label.style.display = "none";
        label.style.padding = "10px";  // Adds padding around the text
        label.style.backgroundColor = "#ffffff";  // White background color for contrast
        label.style.borderRadius = "5px";  // Rounded corners
        label.style.textAlign = "center";  // Centers text horizontally
        label.style.lineHeight = "1.2";  // Adjust line height for better vertical alignment
        label.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.1)"; // Optional: Add a slight shadow for better visibility
        label.style.pointerEvents = "none"; // Optional: Makes sure the label does not capture mouse events
        document.body.appendChild(label);
        return label;
    }

    updateMeasurementLabel(distance = null) {
		if (this.measurementLabel) {
            if (distance != null) {
                this.measurementLabel.innerHTML = distance.toFixed(2).toString() + '"';
            }
            let sceneCreatorDiv = document.getElementById("scene-creator");
            if (sceneCreatorDiv) {
                this.measurementLabel.style.left = (document.getElementById("scene-creator").clientWidth - this.measurementLabel.clientWidth - 20)+ "px";
            }
            this.measurementLabel.style.top =  '90px';
		}
	}

    createMaterial(color) {
        const material = new THREE.LineDashedMaterial({
            // for dashed lines
            color: color,
            linewidth: 1,scale: 1,
            dashSize: 0.01,
            gapSize: 0.01,
            depthTest: false
        })
        return material;
    }

    createMeasurementLine(points) {
        if (!this.materials["line"]) {
            this.materials["line"] = this.createMaterial(0xffffff);
        }
        let lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
        this.measurementLine = new THREE.Line(lineGeometry, this.materials["line"]);
        this.measurementLine.computeLineDistances();
    }

    updateMeasurementLine(startPoint, endPoint) {
        this.disposeComponent(this.measurementLine);
        const points = [startPoint, endPoint];
        this.createMeasurementLine(points);
        const distance = startPoint.distanceTo(endPoint) * this.inchesFactor;
        this.updateMeasurementLabel(distance);
        this.measurementLabel.style.display = "flex";
        this.scene.add(this.measurementLine);
    }

    createRing(intersect) {
        const ringGeometry = new THREE.TorusGeometry(0.01, 0.002, 16, 100);
        const ringMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, transparent: true, depthTest: false});
        let ring = new THREE.Mesh(ringGeometry, ringMaterial);
        ring.position.copy(intersect.point);
        if (!this.isHelperIntersect(intersect)) {
            let normal = intersect.face ? intersect.face.normal : new THREE.Vector3();
            if (this.spaceManager.walls.includes( intersect.object )) {
                normal = getNormalForIntersect(intersect, null, this.fixNormal);
            }
            ring.lookAt( intersect.point.clone().add(normal) );
        }
        ring.renderOrder = 10;
        const cameraPosition = this.sceneCreator.activeCamera.position.clone();
        const scale = cameraPosition.distanceTo(ring.position);
        ring.scale.set(scale, scale, scale);
        ring.userData.sourcePosition = cameraPosition;
        return ring;
    }

    updateRing(ring, intersect) {
        ring.position.copy(intersect.point);
        if (!this.isHelperIntersect(intersect)) {
            let normal = intersect.face ? intersect.face.normal : new THREE.Vector3();
            if (this.spaceManager.walls.includes( intersect.object )) {
                normal = getNormalForIntersect(intersect, null, this.fixNormal);
            }
            ring.lookAt( intersect.point.clone().add(normal) );
        }
        const cameraPosition = this.sceneCreator.activeCamera.position.clone();
        const scale = cameraPosition.distanceTo(ring.position);
        ring.scale.set(scale, scale, scale);
        ring.userData.sourcePosition = cameraPosition;
    }

    updateRingSize(ring) {
        const activeCameraPos = this.sceneCreator.activeCamera.position.clone();
        if (!activeCameraPos.equals(ring.userData.sourcePosition)) {
            const scale = activeCameraPos.distanceTo(ring.position);
            ring.scale.set(scale, scale, scale);
            ring.userData.sourcePosition = activeCameraPos.clone();
        }
    }

    createHelper(startPoint, endPoint, radius = 0.05, helperName) {
        // Compute the direction between the two points
        let direction = new THREE.Vector3().subVectors(endPoint, startPoint);
        let height = direction.length();
        let material = this.materials[helperName];
        // Create a helper geometry (height is the distance between the points)
        let helperGeometry = new THREE.CylinderGeometry(radius, radius, height, 32);
        let helper = new THREE.Mesh(helperGeometry, material);
        // Align the helper with the direction vector
        let quaternion = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 1, 0), direction.clone().normalize());
        helper.applyQuaternion(quaternion);
        // Position the helper at the midpoint between the two points
        let midpoint = new THREE.Vector3().addVectors(startPoint, endPoint).multiplyScalar(0.5);
        helper.position.copy(midpoint);
        const cameraPosition = this.sceneCreator.activeCamera.position.clone();
        let scale = cameraPosition.distanceTo(startPoint) * 0.003;
        helper.scale.set(scale,1,scale);
        helper.userData.sourcePosition = cameraPosition;
        helper.renderOrder = 20;
        helper.name = helperName;
        return helper;
    }

    updateHelperSize(helper) {
        const activeCameraPos = this.sceneCreator.activeCamera.position.clone();
        if (!activeCameraPos.equals(helper.userData.sourcePosition) && this.startPoint) {
            const scale = activeCameraPos.distanceTo(this.startPoint.point) * 0.003;
            helper.scale.set(scale, 1, scale);
            helper.userData.sourcePosition = activeCameraPos.clone();
        }
    }

    createHelperMaterials() {
        if (!this.materials[Constants.HelperAxis.X]) {
            this.materials[Constants.HelperAxis.X]= this.createMaterial(Constants.HelperAxisColor.X);
        }
        if (!this.materials[Constants.HelperAxis.Y]) {
            this.materials[Constants.HelperAxis.Y]= this.createMaterial(Constants.HelperAxisColor.Y);
        }
        if (!this.materials[Constants.HelperAxis.Z]) {
            this.materials[Constants.HelperAxis.Z]= this.createMaterial(Constants.HelperAxisColor.Z);
        }
    }

    updateHelper(startPoint, direction, axis) {
        const helperLength = 100;
        let targetPoint = startPoint.clone().add(direction.clone().multiplyScalar(helperLength));
        const helper = this.createHelper(startPoint, targetPoint, 1, axis); 
        this.scene.add(helper);
        return helper;
    }

    updateHelpers(startPoint, endPoint) {
        this.createHelperMaterials();
        let XHelperDir = Constants._unit.X;
        let YHelperDir = Constants._unit.Y;
        let ZHelperDir = Constants._unit.Z;
        let direction = new THREE.Vector3().subVectors(endPoint, startPoint).normalize();
        let absDirection = new THREE.Vector3(Math.abs(direction.x), Math.abs(direction.y), Math.abs(direction.z));
        let threshold = 0.5;
        // Check if the direction is close to the reverse axes
        if (absDirection.x > absDirection.y && absDirection.x > absDirection.z && direction.x < -threshold) {
            XHelperDir = Constants._unit.aX;  // Reverse X if close to negative X
        }
        if (absDirection.y > absDirection.x && absDirection.y > absDirection.z && direction.y < -threshold) {
            YHelperDir = Constants._unit.aY;  // Reverse Y if close to negative Y
        }
        if (absDirection.z > absDirection.x && absDirection.z > absDirection.y && direction.z < -threshold) {
            ZHelperDir = Constants._unit.aZ;  // Reverse Z if close to negative Z
        }
        if (!this.helperDirections[Constants.HelperAxis.X] || 
            !this.helperDirections[Constants.HelperAxis.X].equals(XHelperDir)) {
            this.disposeComponent(this.helperAxis[Constants.HelperAxis.X]);
            this.helperDirections[Constants.HelperAxis.X] = XHelperDir;
            this.helperAxis[Constants.HelperAxis.X] = this.updateHelper(startPoint, XHelperDir, Constants.HelperAxis.X);
        }
        if (!this.helperDirections[Constants.HelperAxis.Y] || 
            !this.helperDirections[Constants.HelperAxis.Y].equals(YHelperDir)) {
            this.disposeComponent(this.helperAxis[Constants.HelperAxis.Y]);
            this.helperDirections[Constants.HelperAxis.Y] = YHelperDir;
            this.helperAxis[Constants.HelperAxis.Y] = this.updateHelper(startPoint, YHelperDir, Constants.HelperAxis.Y);
        }
        if (!this.helperDirections[Constants.HelperAxis.Z] || 
            !this.helperDirections[Constants.HelperAxis.Z].equals(ZHelperDir)) {
            this.disposeComponent(this.helperAxis[Constants.HelperAxis.Z]);
            this.helperDirections[Constants.HelperAxis.Z] = ZHelperDir;
            this.helperAxis[Constants.HelperAxis.Z] = this.updateHelper(startPoint, ZHelperDir, Constants.HelperAxis.Z);
        }
    }

    snapToHelper(startPoint, endPoint) {
        this.updateHelpers(startPoint.point, endPoint.point);
        let targetHelper = endPoint.object.name;
        if (!this.isHelperIntersect(endPoint)) {
            targetHelper = this.snapToClosestHelper(startPoint.point, endPoint.point);
        }
        if (targetHelper) {
            if (targetHelper == Constants.HelperAxis.X) {
                this.highlightHelper(this.helperAxis[Constants.HelperAxis.X]);
                this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.Y], Constants.HelperAxisColor.Y);
                this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.Z], Constants.HelperAxisColor.Z);
            }
            else if (targetHelper == Constants.HelperAxis.Y) {
                this.highlightHelper(this.helperAxis[Constants.HelperAxis.Y]);
                this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.X], Constants.HelperAxisColor.X);
                this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.Z], Constants.HelperAxisColor.Z);
            }
            else if (targetHelper == Constants.HelperAxis.Z) {
                this.highlightHelper(this.helperAxis[Constants.HelperAxis.Z]);
                this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.X], Constants.HelperAxisColor.X);
                this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.Y], Constants.HelperAxisColor.Y);
            }
        }
        else {
            this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.X], Constants.HelperAxisColor.X);
            this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.Y], Constants.HelperAxisColor.Y);
            this.dehighlightHelper(this.helperAxis[Constants.HelperAxis.Z], Constants.HelperAxisColor.Z);
        }
    }

    getAngleBetweenVectors(vector1, vector2) {
        // Calculate the dot product of the two direction vectors
        const dotProduct = vector1.clone().dot(vector2);
        // Calculate the angle between the two direction vectors
        const angleRadians = Math.acos(dotProduct); // in radians
        const angleDegrees = THREE.MathUtils.radToDeg(angleRadians); // convert to degrees
        return angleDegrees;
    }

    snapToClosestHelper(startPoint, endPoint) {
        let targetHelper = null;
        let distance = startPoint.distanceTo(endPoint);
        let direction = new THREE.Vector3().subVectors(endPoint, startPoint).normalize();
        let angleX = this.getAngleBetweenVectors(direction, this.helperDirections[Constants.HelperAxis.X].clone());
        let angleY = this.getAngleBetweenVectors(direction, this.helperDirections[Constants.HelperAxis.Y].clone());
        let angleZ = this.getAngleBetweenVectors(direction, this.helperDirections[Constants.HelperAxis.Z].clone());
        let threshold = 5;
        if (angleX < threshold) {
            targetHelper = Constants.HelperAxis.X;
        }
        else if (angleY < threshold) {
            targetHelper = Constants.HelperAxis.Y;
        }
        else if (angleZ < threshold){
            targetHelper = Constants.HelperAxis.Z;
        }
        if (targetHelper) {
            let targetPoint = startPoint.clone().add(this.helperDirections[targetHelper].clone().multiplyScalar(distance))
            this.updateMeasurementLine(startPoint, targetPoint);
            if(this.currentRing) {
                this.currentRing.position.copy(targetPoint);
                if(this.startRing) {
                    this.currentRing.quaternion.copy(this.startRing.quaternion);
                }
            }
        }
        return targetHelper;
    }

    highlightHelper(helper) {
        let highlightColor = 0xffff00;
        helper.material.color.set(highlightColor);
    }

    dehighlightHelper(helper, originalColor) {
        helper.material.color.set(originalColor);
    }

    disposeComponent(component) {
        if (component) {
            this.scene.remove(component);
            this.sceneCreator.disposeScene(component);
        }
    }

    disposeHelpers() {
        this.disposeComponent(this.helperAxis[Constants.HelperAxis.X]);
        this.disposeComponent(this.helperAxis[Constants.HelperAxis.Y]);
        this.disposeComponent(this.helperAxis[Constants.HelperAxis.Z]);
        this.helperAxis = {};
        this.helperDirections = {};
    }

    disposeMeasurementTool() {
        this.disposeComponent(this.measurementLine);
        this.disposeComponent(this.startRing);
        this.disposeComponent(this.endRing);
        this.disposeComponent(this.currentRing);
        this.disposeHelpers();
        this.measurementLine = null;
        this.startRing = null;
        this.endRing = null;
        this.currentRing = null;
        this.measurementLabel.style.display = "none";
    }

    isHelperIntersect(intersect) {
        return intersect.object.name === Constants.HelperAxis.X || 
        intersect.object.name == Constants.HelperAxis.Y 
        || intersect.object.name == Constants.HelperAxis.Z;
    }

    getIntersectionPoint() {
        let raycastPoint = new THREE.Vector2();
        raycastPoint.copy(this.mouse);
        let raycastResult = null;
        let raycastIntersections = this.raycastManager.setFromCameraAndIntersect(raycastPoint, this.sceneCreator.activeCamera, 
            this.scene.children, true);
        if (raycastIntersections && raycastIntersections.length > 0) {
            raycastResult = raycastIntersections.find((item) => {
                return this.isHelperIntersect(item)
            });
            if (!raycastResult) {
                raycastIntersections = raycastIntersections.filter( ( item ) => {
                    return ((
                        item.object != this.measurementLine && (this.sceneCreator.isRenderCamActive()
                        || !this.spaceManager.ceilings.includes( item.object ))
                    )) ;
                } );
                if (raycastIntersections && raycastIntersections.length > 0) {
                    raycastResult = raycastIntersections[0];
                }
            }
        }
        return raycastResult;
    }

    update() {
        if (this.startRing) {
            this.updateRingSize(this.startRing);
        }
        if (this.endRing) {
            this.updateRingSize(this.endRing);
        }
        if (this.currentRing) {
            this.updateRingSize(this.currentRing);
        }
        if (this.helperAxis[Constants.HelperAxis.X]) {
            this.updateHelperSize(this.helperAxis[Constants.HelperAxis.X]);
        }
        if (this.helperAxis[Constants.HelperAxis.Y]) {
            this.updateHelperSize(this.helperAxis[Constants.HelperAxis.Y]);
        }
        if (this.helperAxis[Constants.HelperAxis.Z]) {
            this.updateHelperSize(this.helperAxis[Constants.HelperAxis.Z]);
        }
    }

    reset = () => {
        this.startPoint = false;
        this.endPoint = false;
        this.active = false;
    }

    #onMouseDown = () => {
        this.active = true;
    }

    #onMouseMove = () => {
        let intersectionPoint = this.getIntersectionPoint();
        if (intersectionPoint) {
            this.currentPoint = intersectionPoint;
            if (!this.currentRing) {
                this.currentRing = this.createRing(this.currentPoint);
                this.scene.add(this.currentRing);
            }
            else {
                this.updateRing(this.currentRing, this.currentPoint);
            }
            if (this.startPoint) {
                this.endPoint = this.currentPoint;
                this.endRing = this.currentRing;
                if (this.startPoint && this.endPoint) {
                    this.updateMeasurementLine(this.startPoint.point, this.endPoint.point);            
                    this.snapToHelper(this.startPoint, this.endPoint);
                }
            }
        }
    }

    #onMouseUp = () => { 
        if (this.active) {
            if (!this.startPoint) {
                this.disposeComponent(this.startRing);
                this.disposeComponent(this.endRing);
                this.startPoint = this.currentPoint;
                this.startRing = this.currentRing;
                this.currentPoint = null;
                this.currentRing = null;
                this.endRing = null;
            }
            else {
                this.endPoint = this.currentPoint;
                this.endRing = this.currentRing;
                this.currentPoint = null;
                this.currentRing = null;
                if (this.startPoint && this.endPoint) {
                    this.updateMeasurementLine(this.startPoint.point, this.endPoint.point);
                    this.snapToHelper(this.startPoint, this.endPoint);
                }
                this.reset();
                this.disposeHelpers();
            }   
        }
        this.active = false;
    }
}