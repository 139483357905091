import React, { useContext } from 'react';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import { LoadingOutlined, LockOutlined, WarningOutlined } from '@ant-design/icons';

const LoadingContainer = (props) => {
    return (<div className="load-container">
        {props.icon}
        <h3 className="load-message large">{props.heading}</h3>
        <h3 className="load-message">{props.sub_text}</h3>
    </div>)
}

const CanvasLoader = () => {
    const { initialLoader, canvasLoadMessage, access_level } = useContext(CollaborateContext);

    return (
        initialLoader && canvasLoadMessage ?
            <LoadingContainer
                icon={<WarningOutlined className='on-start-load warning-icon' size="large" />}
                heading={"Oops! An error occured."}
                sub_text={"An error occured while trying to load this collaboration. Please contact admin."} />
            :
            initialLoader && !canvasLoadMessage ?
                <LoadingContainer
                    icon={<LoadingOutlined className="on-start-load loading-icon" size="large" spin />}
                    heading={"Setting up your Canvas"}
                    sub_text={"Please wait a few moments while we set up your canvas playground."} />
                : access_level == "restricted" ?
                    <LoadingContainer
                        icon={<LockOutlined className='on-start-load loading-icon' size="large" />}
                        heading={"Oops! You don't have access!"}
                        sub_text={"Please request the owner of this collaboration to grant you access."} /> : ""
    );
}


export default CanvasLoader;
