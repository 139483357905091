import React, { useContext, useState, useEffect } from 'react';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import { Drawer, Empty, Image, Space, Tooltip, Input } from 'antd';
import * as Utilities from '../../Utilities';
import ENVIRONMENT from '../../../../environments';
import { LoadingOutlined, CheckOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import './CollaborateTool.scss';
import axios from 'axios';

const CollaborationVersionHistory = (props) => {
    const [isEditName, setIsEditName] = useState(null);
    const [nameLoader, setNameLoader] = useState(false);
    const [name, setName] = useState('');
    const { historyOfRenders, versionHistoryVisible, closeVersionHistoryDrawer, scene_id, platform, collaboration_id, updtateVersionNameInHistory } = useContext(CollaborateContext);

    const openCollaborationVersion = (version) => {
        const URL = `${window.location.origin}${window.location.pathname}?version=${version}`;
        window.open(URL, '_blank');
    }

    const handleNameSubmit = (version) => {
        let payload = {
            'collaboration_id': collaboration_id,
            'version_number': version,
            'display_name': name,
            'action': 'update_display_name'
        }
        setNameLoader(true)
        axios.post(ENVIRONMENT.COLLABORATION_RENDER_HISTORY_UPDATE, payload)
            .then(res => {
                setNameLoader(false);
                setIsEditName(null);
                updtateVersionNameInHistory(name, version)
            })
    }

    const handleNameChange = (e) => {
        setName(e.target.value)
    }
    
    const handleEditCancel = () => {
        setIsEditName(null)
        setName('')
    }

    return (
        <Drawer
            className='version-history-drawer'
            closable={true}
            title={<h3 className='manrope f-18 black-33 mb-0'>Version History</h3>}
            placement="right"
            onClose={closeVersionHistoryDrawer}
            visible={versionHistoryVisible}>
            <div className="pd-16">
                {historyOfRenders.length == 0 ?
                    <Empty
                        description={"No previous versions"}
                        className="manrope f-14 display-flex j-c a-c dir-col" />
                    :
                    historyOfRenders.map((version, index) => (
                        <div className='version-card' key={index}>
                            <div className="justify-space-between">
                                <div className = "w-50">
                                {(isEditName == index) ?
                                <div id="name_form" className='manrope f-16 mb-4 nameEditField'>
                                    <Input className='manrope f-14' style={{width:"90%"}} value={name} onChange={handleNameChange} bordered={false} />
                                    <div className='nameEditIcons'>
                                        {nameLoader ?
                                        <LoadingOutlined className='ml-16' /> :
                                        <>
                                            <CloseOutlined className='nameIcon' onClick={handleEditCancel} />
                                            <CheckOutlined className='nameIcon' onClick={() => {
                                                handleNameSubmit(version.version_number)} } />
                                        </>}
                                    </div>
                                </div> :
                                <div className = "display-flex j-s a-e">
                                    <Tooltip title={<span className='manrope f-12 white'>{version.old_name || `version-${version.version_number}`}</span>} placement='bottom'>
                                        <p className="manrope f-14 w-700 black-00 mb-8 clamp-text w-100">
                                            {version.old_name || `version-${version.version_number}`}
                                        </p>
                                    </Tooltip>
                                    <p className="manrope f-14 w-700 black-00 mb-8">
                                        <EditOutlined className='ml-10' onClick={()=> {
                                            setIsEditName(index) 
                                            setName(version.old_name)
                                            }} />
                                    </p>
                                </div>
                                }
                                </div>
                                <p className="manrope f-14 black-33 mb-8">{Utilities.convertToCustomFormat(version.old_last_modified)}</p>
                            </div>
                            <Image
                                onClick={ (e) => {
                                    e.stopPropagation();
                                    openCollaborationVersion(version.version_number);
                                }} 
                                preview={{
                                    mask: (
                                        <Space>
                                            <div className="version-tag">
                                                <Tooltip
                                                    title={
                                                    <span className="manrope f-12 white">Open version in new tab</span>
                                                    }
                                                >
                                                    <span>{`version-${version.version_number}`}</span>
                                                    <img className="open-icon" src="/img/redirect-icon.svg" />
                                                </Tooltip>
                                            </div>
                                        </Space>
                                    ),
                                    visible: false,
                                }}
                                className='history-img mb-16' src={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + scene_id + '/' + version.old_camera_name.replace("tiff", "jpg")} 
                            />
                        </div>
                    ))}
            </div>
        </Drawer>
    );
}


export default CollaborationVersionHistory;
