import React from 'react';
import { Form } from '@ant-design/compatible';
import { message, Row, Col, Input, Upload, Button } from 'antd';
import { Link } from "react-router-dom";
import axios from 'axios';
import AdminMainLayout from '../AdminMainLayout';
import Scene360Viewer from '../../CustomerComponents/Scene360Viewer';
import * as Constants from "../../CustomerComponents/Constants";
import CONSTANTS  from '../../../../environments'

const TextArea = Input;
const { GET_SCENE_DETAIL, UPDATE_SCENE, getBaseURL, SCENE_BASE_URI } = CONSTANTS

class SceneDetailsContainer extends React.Component {
  state = {
    scene_name: '',
    customer_name: '',
    unhappy_details: '',
    unhappy_images: [],
    platform: ''
  }

  componentDidMount() {
    axios.post(GET_SCENE_DETAIL, { scene_id: this.props.match.params.id })
      .then(res => {
        console.log(res);
        console.log(res.data);
        let scene_name = res.data['name'];
        let customer_name = res.data['customer_username'];
        let unhappy_content = JSON.parse(res.data['unhappy_details'])["unhappy_details"];
        let scene_platform = res.data['platform']
        console.log(unhappy_content);
        let unhappy_details = unhappy_content["details"];
        let unhappy_images = [];
        if (unhappy_content['photos'] != undefined) {
          unhappy_images = unhappy_content['photos'];
        }
        this.setState({
          scene_name: scene_name,
          customer_name: customer_name,
          unhappy_details: unhappy_details,
          unhappy_images: unhappy_images,
          platform: scene_platform
        });

      });

  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let unhappy_details = {};
        unhappy_details['details'] = values['details'];
        unhappy_details['updated_bundle'] = values['updated_bundle'];
        let body = {
          "scene_id": this.props.match.params.id,
          'manual_fix_details': unhappy_details

        };
        console.log(JSON.stringify(body));

        axios.post(UPDATE_SCENE, body)
          .then(res => {
            console.log(res.data);
            message.info('Scene Bundle is updated.')
          })
          .catch((error) => {
            message.error('Error in submitting ');
          });
      }
    });
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFieldsValue, resetFields } = this.props.form;
    return (
      <AdminMainLayout selected={"4"}>
        <Row>
          <Col span={24}>
          <h3 style={{fontFamily:"Avenir", fontSize:"16px",color: "#737373",marginBottom:0,alignSelf:"center",marginTop: 60,marginBottom: 30}}>
              <Link className="store-bd " to={`/admin`} style={{color:"#999999"}}>Home</Link> / <Link className="store-bd " to={`/admin_scenes`} style={{color:"#999999"}}>Scene Library</Link> / Flagged / <span className="active-route-link-color">{this.state.scene_name}</span>
          </h3>
          <Scene360Viewer id={this.props.match.params.id} platform={this.state.platform}/>
            {/* <h5><b>Scene Name: </b>{this.state.scene_name}</h5>
            <h5><b>Customer Name: </b>{this.state.customer_name}</h5>
            <h5><b>Concers: </b>{this.state.unhappy_details}</h5>
            <h5><b>Max Scene: </b><a href={SCENE_BASE_URL + this.props.match.params.id + '/maxScene.zip'}>{SCENE_BASE_URL + this.props.match.params.id + '/maxScene.zip'}</a></h5>
            <h5><b>Images: </b></h5>

            {this.state.unhappy_images.map(x => (
              <img src={BASE_URL + x['uid'] + '/' + x['name']}></img>
            ))} */}
            <Row type="flex" style={{ justifyContent: "space-between", marginTop: "30px" }}>
              <Col>
                <h3 className="product-name-h3 black" style={{marginBottom:"48px"}}>{this.state.scene_name}</h3>
              </Col>
              <Col>
                <Button className="upload-bundle-btn">
                    <div className="upload-bundle-btn-text">UPLOAD BUNDLE</div>
                </Button>
                <Button className="continue-cta-green" style={{ marginLeft: 10 }}>
                    <div className="assign-artist-btn-txt">ASSIGN ARTIST</div>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h5 className="product-category-h3 grey" style={{marginBottom:"48px",display:"flex",justifyContent:"flex-start"}}>
                    <span className="product-category-h3 grey" > ID: <span className="product-category-h3 black">{this.props.match.params.id}</span></span>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h5 className="product-category-h3 grey" style={{marginBottom:"48px",display:"flex",alignItems:"flex-start",flexDirection:"column"}}>
                    <div className="product-category-h3 black"> Customer: </div>
                    <div className="flagged-bg"><span className="flagged-txt">{this.state.customer_name}</span></div>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="flagged-bg border">
                  <div className="flagged-txt bold" style={{marginBottom: 30}}>Customer Flagged because:</div>
                  <div className="flagged-txt gray">
                    Comment
                  </div>
                  <div className="flagged-txt black">
                  {this.state.unhappy_details}
                  </div>
                  <div className="flagged-txt gray" style={{marginTop: 30}}>
                   Uploads
                  </div>
                  <div className="flagged-txt black">
                  {this.state.unhappy_details}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={12}>
                <h5 className="product-category-h3 grey" style={{marginBottom:"48px",marginTop: 30,display:"flex",alignItems:"flex-start",flexDirection:"column"}}>
                    <div className="product-category-h3 black"> Download Link: </div>
                    <div className="flagged-bg"><span className="flagged-txt" style={{color:"#276DD7",textTransform:"none"}}>{getBaseURL(this.state.platform) + SCENE_BASE_URI + this.props.match.params.id + '/maxScene.zip'}</span></div>
                </h5>
              </Col>
            </Row>
            
          </Col>
          <Col style={{ padding: 80 }} span={16}>
            
            <Form className="input-form" onSubmit={this.handleSubmit}>
              <Form.Item name="details" label="Details" colon={false}>
                {getFieldDecorator('details', {
                  rules: [{ required: true, message: "Details are required!" }]
                })(
                  <TextArea
                    placeholder="Enter Message"
                    rows={5}
                    style={{height: "auto !important"}}
                  />
                )}
              </Form.Item>
              <Form.Item name={'updated_bundle'} label="Updated Bundle" colon={false}>
                {getFieldDecorator('updated_bundle', {
                  valuePropName: 'updated_bundle',
                  getValueFromEvent: e => e && e.fileList,
                  rules: [{ required: true, message: "Bundle is required!" }]
                })(
                  <Upload {...Constants.upload_props} multiple="true" listType="text" className="upload-lg-btn ant-upload-picture-card-wrapper">
                    <div className="d-flex">
                      <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                      <div className="ant-upload-text">
                        <div className="up-info">Drop your Bundle</div>
                        <div>Support: ZIP, RAR</div>
                      </div>
                    </div>
                  </Upload>
                )}
              </Form.Item>
              <Button shape="round" type="danger" htmlType="submit" style={{ marginLeft: 2 }}>Update Scene</Button>
            </Form>
          </Col>
        </Row>
      </AdminMainLayout>
    );
  }
}

const SceneDetails = Form.create({ name: 'update_scene_bundle' })(SceneDetailsContainer);

export default SceneDetails;