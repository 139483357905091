import React from "react";
import AdminMainLayout from "../AdminMainLayout";
import { Row, Col, Card } from "antd";
import { FileDoneOutlined, MailOutlined, MobileOutlined, ShoppingCartOutlined } from '@ant-design/icons';

const SystemManagement = (props) => {
    const iconSize = 50;
    const iconColor = 'black';
    const iconStyle = {fontSize: iconSize, color: iconColor}
    const sysMngOptions = [
        {
            "name" : "Test Renders",
            "icon" : <FileDoneOutlined style={iconStyle} />,
            "uri"  : "/render_service",
        },
        {
            "name" : "Reconstruction Server",
            "icon" : <MobileOutlined style={iconStyle} />,
            "uri"  : "/scans",
        },
        {
            "name" : "Notification Management",
            "icon" : <MailOutlined style={iconStyle} />,
            "uri"  : "/notification_management",
        },
        {
            "name" : "Store",
            "icon" : <ShoppingCartOutlined style={iconStyle} />,
            "uri"  : "/admin_store",
        },
    ]
    return (
        <>
        <AdminMainLayout selected={"19"} color={true}>
            <div>
                <div style={{marginTop: 30}}>
                    <Row gutter={[10,10]}>
                        {
                            sysMngOptions.map ( option =>
                                <Col span={6}>
                                    <Card 
                                    style={{padding:50}} 
                                    bodyStyle={{textAlign:"center"}}
                                    hoverable={true}
                                    onClick={ () => {
                                        props.history.push(option.uri);
                                    }}>
                                        {option.icon}
                                        <span style={{marginTop: 20}}>
                                            <h2 style={{marginTop: 20}} className="manrope f-16 black-14 w-600 justify-in-center">{option.name}</h2>
                                        </span>
                                    </Card>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            </div>
        </AdminMainLayout>
        </>
    );
};

export default SystemManagement;