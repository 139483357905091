import React from 'react';
import SiloPreviewShots from './SiloPreviewShots';

export default function SiloSetAngles(props) {
     
  return (
    <div style={{height:"100%"}}>
        <SiloPreviewShots setResolutionBar={true} {...props}/>        
    </div>
  );
}
