import * as THREE from 'three'
import Constants from "../../Constants.js"
import {SnapGuide} from './SnapGuide.js'
import {rounded} from '../../HelperFunctions.js'

export default class ObjectSnappingManager {

    selection = null;

    snapGuide = null;

    spaceManager = null;
    raycastManager = null;

    /**
     * Object snapping manager is used for auto snapping(translation and rotation) of objects to walls and identical objects.
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     * @param {Object} selection - Reference to struct that contains all the required information for the selected object. (Struct defined in Object Placement Manager)
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    constructor(scene, selection, managersDict) {
        this.selection = selection;

        // Build snap guide instance and add to scene
        this.snapGuide = this.buildSnapGuide();
        scene.add(this.snapGuide);

        this.setupRequiredManagers(managersDict);
    }

    /**
     * Build an instance of SnapGuide, used for showing guided lines when snapping objects.
     */
    buildSnapGuide() {
        const guide = new SnapGuide();
        guide.name = "snapGuide";
        return guide;
    }

    /**
     * Setup the managers required by this module to work.
     */
    setupRequiredManagers(managersDict) {
        this.spaceManager = managersDict[Constants.Manager.SpaceManager];
        this.raycastManager = managersDict[Constants.Manager.RaycastManager];
    }

    /**
     * Try to snap the currently selected asset to a near by wall (if the distance to wall is less than snapping threshold).
     */
    snapToWall () {

        let minSnapThreshold = 0.05;
        let maxSnapThreshold = 0.2;
        let snapThreshold = 0.1;
        let faceMatchThreshold = 0.3;
        let snapDistance = 0.05;

        let didSnap = false;

        let data = this.selection.object.userData.pvb.getDataForSnapTest();

        let frontIntersects = this.raycastManager.setAndIntersect(data.frontOrigin, data.frontDir, this.spaceManager.walls);
        let backIntersects = this.raycastManager.setAndIntersect(data.backOrigin, data.backDir, this.spaceManager.walls);
        let leftIntersects = this.raycastManager.setAndIntersect(data.leftOrigin, data.leftDir, this.spaceManager.walls);
        let rightIntersects = this.raycastManager.setAndIntersect(data.rightOrigin, data.rightDir, this.spaceManager.walls);

        if ( backIntersects ) {
            let translatedNormal = new THREE.Vector3( backIntersects.face.normal.x, backIntersects.face.normal.z, -backIntersects.face.normal.y );
            let dotProduct = translatedNormal.clone().dot( this.selection.worldDirection );

            if ( data.backOrigin.distanceTo( backIntersects.point ) <= faceMatchThreshold && dotProduct >= 0.9 ) {
                this.selection.object.lookAt( this.selection.object.getWorldPosition( new THREE.Vector3() ).clone().add( translatedNormal ) );
            }

            if ( data.backOrigin.distanceTo( backIntersects.point ) <= maxSnapThreshold && data.backOrigin.distanceTo( backIntersects.point ) >= minSnapThreshold && dotProduct >= 0.99 ) {
                this.selection.object.translateOnAxis( Constants._unit.aZ, data.backOrigin.distanceTo( backIntersects.point ) - snapDistance);
                this.selection.object.userData.snapped = true;
                this.selection.object.userData.snapType = "wall";
                this.selection.object.userData.snapDir = "back";

                didSnap = true;
            }
        }

        if ( frontIntersects ) {
            let translatedNormal = new THREE.Vector3( frontIntersects.face.normal.x, frontIntersects.face.normal.z, -frontIntersects.face.normal.y ).negate();
            let dotProduct = translatedNormal.clone().dot( this.selection.worldDirection );

            if ( data.frontOrigin.distanceTo( frontIntersects.point ) <= faceMatchThreshold && dotProduct >= 0.9 ) {
                this.selection.object.lookAt( this.selection.object.getWorldPosition( new THREE.Vector3() ).clone().add( translatedNormal ) );
            }

            if ( data.frontOrigin.distanceTo( frontIntersects.point ) <= maxSnapThreshold && data.frontOrigin.distanceTo( frontIntersects.point ) >= minSnapThreshold  && dotProduct >= 0.99 ) {
                this.selection.object.translateOnAxis( Constants._unit.Z, data.frontOrigin.distanceTo( frontIntersects.point ) - snapDistance);
                this.selection.object.userData.snapped = true;
                this.selection.object.userData.snapType = "wall";
                this.selection.object.userData.snapDir = "front";

                didSnap = true;
            }
        }

        if ( leftIntersects ) {
            let translatedNormal = new THREE.Vector3( leftIntersects.face.normal.x, leftIntersects.face.normal.z, -leftIntersects.face.normal.y );
            let dotProduct = Math.abs( translatedNormal.clone().dot( this.selection.worldDirection ) );
            dotProduct = rounded( dotProduct );

            if ( data.leftOrigin.distanceTo( leftIntersects.point ) <= faceMatchThreshold && ( dotProduct > 0 && dotProduct < 0.45 ) ) {
                let rotatedNormal = translatedNormal.clone().applyAxisAngle( Constants._unit.Y, - Math.PI / 2.0 );
                this.selection.object.lookAt( this.selection.object.getWorldPosition( new THREE.Vector3() ).clone().add( rotatedNormal ) );
            }

            if ( data.leftOrigin.distanceTo( leftIntersects.point ) <= maxSnapThreshold && data.leftOrigin.distanceTo( leftIntersects.point ) >= minSnapThreshold && dotProduct == 0 ) {
                this.selection.object.translateOnAxis( Constants._unit.aX, data.leftOrigin.distanceTo( leftIntersects.point ) - snapDistance);

                if( !this.selection.object.userData.snapped ) {
                    this.selection.object.userData.snapped = true;
                    this.selection.object.userData.snapType = "wall";
                    this.selection.object.userData.snapDir = "left";

                    didSnap = true;
                }
            }
        }

        if ( rightIntersects ) {
            let translatedNormal = new THREE.Vector3( rightIntersects.face.normal.x, rightIntersects.face.normal.z, -rightIntersects.face.normal.y );
            let dotProduct = Math.abs( translatedNormal.clone().dot( this.selection.worldDirection ) );
            dotProduct = rounded( dotProduct );

            if ( data.rightOrigin.distanceTo( rightIntersects.point ) <= faceMatchThreshold && ( dotProduct > 0 && dotProduct < 0.45 ) ) {
                let rotatedNormal = translatedNormal.clone().applyAxisAngle( Constants._unit.Y, Math.PI / 2.0 );
                this.selection.object.lookAt( this.selection.object.getWorldPosition( new THREE.Vector3() ).clone().add( rotatedNormal ) );
            }

            if ( data.rightOrigin.distanceTo( rightIntersects.point ) <= maxSnapThreshold && data.rightOrigin.distanceTo( rightIntersects.point ) >= minSnapThreshold && dotProduct == 0 ) {
                this.selection.object.translateOnAxis( Constants._unit.X, data.rightOrigin.distanceTo( rightIntersects.point ) - snapDistance);

                if( !this.selection.object.userData.snapped ) {
                    this.selection.object.userData.snapped = true;
                    this.selection.object.userData.snapType = "wall";
                    this.selection.object.userData.snapDir = "right";

                    didSnap = true;
                }   
            }
        }

        return didSnap;
    }

    /**
     * Try to snap the currently selected asset to a near by same asset (if the distance to near by asset is less than snapping threshold).
     * @param {Array} sceneAssets - An array that contains references to all the assets currently in the scene.
     */
    snapToIdenticalItem(sceneAssets) {
        let selectedObj = this.selection.object;
        let snapThreshold = 0.075;
        let width = selectedObj.userData.size.x;
        let depth = selectedObj.userData.size.z;
        let max = Math.max( width, depth );
        let asset1PVBData = selectedObj.userData.pvb.getDataForSnapTest();

        let didSnap = false;
        
        let dirMap = {
            left: { unit: Constants._unit.X, offset: width },
            right: { unit: Constants._unit.aX, offset: width },
            front: { unit: Constants._unit.aZ, offset: depth },
            back: { unit: Constants._unit.Z, offset: depth },

        }

        for ( let asset of sceneAssets ) {
            if ( asset.id != selectedObj.id &&
                asset.name == selectedObj.name &&
                asset.parent == selectedObj.parent &&
                asset.position.distanceTo( selectedObj.position ) <= max ) {

                let asset2PVBData = asset.userData.pvb.getDataForSnapTest();
                let dotProduct = selectedObj.getWorldDirection( new THREE.Vector3() ).dot( asset.getWorldDirection( new THREE.Vector3() ) );


                for ( let key1 in dirMap ) {
                    for ( let key2 in dirMap ) {
                        let snapPoint1 = asset1PVBData[ key1 + "Origin" ]; // Snap point of selected object
                        let snapPoint2 = asset2PVBData[ key2 + "Origin" ]; // Snap point of identical asset

                        if ( snapPoint1.distanceToSquared( snapPoint2 ) <= 0.01 ) {
                            if( key1 == key2 && dotProduct > 0 ) {
                                continue;
                            }

                            selectedObj.position.copy( asset.position );

                            let absDotProduct = Math.abs( dotProduct );
                            if ( absDotProduct >= 0.75 ) {
                                selectedObj.rotation.copy( asset.rotation );

                                if ( dotProduct < 0 ) {
                                    selectedObj.rotateOnAxis( Constants._unit.Y, Math.PI );
                                }
                            }

                            else if ( absDotProduct >= 0 && absDotProduct <= 0.35 ) {
                                // Postive value == Clockwise , Negative Value == Anti-Clockwise
                                let rotationDir = selectedObj.getWorldDirection( new THREE.Vector3() ).cross( asset.getWorldDirection( new THREE.Vector3() ) ).y;

                                selectedObj.rotation.copy( asset.rotation );
                                
                                rotationDir > 0 ? selectedObj.rotateOnAxis( Constants._unit.Y, -( Math.PI / 2.0 ) ) : selectedObj.rotateOnAxis( Constants._unit.Y,  Math.PI / 2.0 );
                            }

                            else {
                                selectedObj.rotation.copy( asset.rotation );
                                selectedObj.rotateOnAxis( Constants._unit.Y, Math.PI );
                            }
                            

                           
                            if( dirMap[ key1 ].offset != dirMap[ key2 ].offset ) {
                                selectedObj.translateOnAxis( dirMap[ key1 ].unit, ( ( dirMap[ key1 ].offset / 2 ) + ( dirMap[ key2 ].offset / 2.0 ) ) );    
                            }
                            else {
                                selectedObj.translateOnAxis( dirMap[ key1 ].unit, dirMap[ key1 ].offset );
                            }
                            
                            selectedObj.userData.snapDir = key1;
                            selectedObj.userData.snapped = true;
                            selectedObj.userData.snapType = "item";

                            didSnap = true;
                            return didSnap;
                        }
                    }
                }
            }
        }

        return didSnap;
    }
}