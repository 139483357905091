import React from 'react'

import {  Row, Col, Radio, InputNumber,Input, Slider, Button } from 'antd';

const radioStyleInline = {
    display: 'inline',
    height: '20px',
    lineHeight: '120%'
};

const angleMarks = {
    5: '5°',
    20: '20°',
    30: '',
    40: '',
    50: '',
    60: '',
    70: '',
    80: '80°',
};

const mmMarks = {
    10: '10mm',
    20: '',
    30: '',
    40: '',
    50: '',
    60: '',
    70: '',
    80: '',
    90: '',
    100: '100mm',
};

export default function SiloSettings(props) {
  return (
    <div style={props.border?{borderLeft:"1px solid rgba(217,217,217,1)"}:{height:"50vh", marginTop:"5%", display:"flex", flexDirection:"column", justifyContent:"space-evenly", marginLeft:"3%"}} className='silo-snapshot-settings'>
        {
        props.cameraSettingsChange ==undefined
            ?
                <h1 className='manrope f-16 image-setting-header'>
                    Image Settings
                </h1>
            :
            ''
        }
        
        
        {props.cameraSettingsChange ==undefined ?
        <>
        {/* <Divider/> */}
        <h2 className="manrope f-14 black-33 w-600 image-setting-header">Select Orientation</h2>
        <Row className='silo-setting-individual '>
            <Col span={24} style={{display:"flex"}}>
                <Radio.Group style={{marginBottom:"6px"}} onChange={props.setOrientationRadio} value={props.orientation}>
                    <Radio value={"Landscape"}><span className='manrope f-14'>Landscape</span></Radio>
                    <Radio value={"Portrait"}><span className='manrope f-14'>Portrait</span></Radio>
                    <Radio value={"Square"}><span className='manrope f-14'>Square</span></Radio>
                    <Radio value={"Custom"}><span className='manrope f-14'>Custom</span></Radio>
                </Radio.Group>
            </Col>
        </Row>
        
        <h2 className="manrope f-14 black-33 w-600 image-setting-header" style={{display:((props.orientation == 'Landscape' || props.orientation =='Portrait') && props.orientationClicked) ? "flex" : "none"}} >Select Aspect Ratio</h2>
        <Row className='silo-setting-individual'>
            <Col style={{display:(props.orientation == 'Landscape') ? "flex" : "none"}} span={24} >
                <Radio.Group style={{marginBottom:"6px"}} onChange={(e)=>{props.selectAspectRatio(e.target.value,'Landscape')}} defaultValue='16:9'>
                    <Radio value={"16:9"}><span className='manrope f-14'>16:9</span></Radio>
                    <Radio value={"16:10"}><span className='manrope f-14'>16:10</span></Radio>
                    <Radio value={"1.91:1"}><span className='manrope f-14'>1.91:1</span></Radio>
                </Radio.Group>
            </Col>
            <Col style={{display:(props.orientation == 'Portrait') ? "flex" : "none"}} span={24} >
                <Radio.Group style={{marginBottom:"6px"}} onChange={(e)=>{props.selectAspectRatio(e.target.value,'Portrait'); }} defaultValue='9:16'>
                    <Radio value={"9:16"}><span className='manrope f-14'>9:16</span></Radio>
                    <Radio value={"10:16"}><span className='manrope f-14'>10:16</span></Radio>
                    <Radio value={"1:1.91"}><span className='manrope f-14'>1:1.91</span></Radio>
                </Radio.Group>
            </Col>
        </Row>
        </>
        :
        ''
        }
        {/* <Divider/> */}
        <div>
        <span  className={props.cameraSettingsChange ==undefined?"manrope f-14 black-33 w-600 image-setting-header":"manrope f-14 black silo-heading-main"} style={{display: props.orientation !='Custom' && props.aspectClicked|| props.orientation =="Square" && props.orientationClicked || props.cameraSettingsChange? "block" : "none"}}>Select Resolution</span>
        <Row className='silo-setting-individual' style={{display: props.orientation != 'Custom' && props.aspectClicked|| props.orientation =="Square" && props.orientationClicked || props.cameraSettingsChange ? "flex" : "none"}}>
            <Col>
                <Radio.Group style={{marginBottom:"6px", display:"contents"}} onChange={(e) => {
                        if (props.cameraSettingsChange)
                            props.onResolutionChangeCamera(e.target.value)
                        else 
                            props.onResolutionChange(e)
                    }} value={props.resolutionStatus}>
                    {
                        !props.cameraSettingsChange || props.defaultResolutions.includes(props.resolution) ?
                        <>
                        <Radio className="dpi-settings manrope f-14 black" value={0}><span style={{color: props.resolutionStatus == 0 ? "#333333" : "#999999"}}>1K ({props.imageResArr[0]})</span></Radio>
                        <Radio className="dpi-settings manrope f-14 black" value={1}><span style={{color: props.resolutionStatus == 1 ? "#333333" : "#999999"}}>2K ({props.imageResArr[1]})</span></Radio>
                        <Radio className="dpi-settings manrope f-14 black" value={2}><span style={{color: props.resolutionStatus == 2 ? "#333333" : "#999999"}}>3K ({props.imageResArr[2]})</span></Radio>
                        <Radio className="dpi-settings manrope f-14 black" value={3}><span style={{color: props.resolutionStatus == 3 ? "#333333" : "#999999"}}>4K ({props.imageResArr[3]})</span></Radio>
                        
                        </>
                        :
                        <Radio className="dpi-settings manrope f-14 black" value={0} defaultChecked={true} ><span style={{color: "#999999"}}>({props.resolution})</span></Radio>
                    }
                    {
                        props.cameraSettingsChange ?
                            ''
                        :
                        <Radio style={{display: props.orientation == 'Custom' && props.orientationClicked ? "flex" : "none"}} className="dpi-settings manrope f-14 black" value={4}>
                            <span className={`radio-btn-change f-14 ${props.resolutionStatus == 4 ? `custom-res-message` : ``}`} style={{color: props.resolutionStatus == 4 ? "#333333" : "#999999"}} >Add Custom Resolution
                            {/* {props.resolutionStatus == 4 ? */}
                                <span style={{display:"flex",marginTop: 9}}>
                                    <div className="custom-input-box f-14" style={{marginRight:8}}>
                                        <span style={{marginRight:5}}>Width</span>
                                        <div className="custom-input-contain">
                                            <InputNumber onChange={props.onImageWidthRes}
                                            value={props.customWRes}
                                            min={0.1} max={8096} />
                                        </div>
                                    </div>

                                    <div className="custom-input-box f-14">
                                        <span style={{marginRight:5}}>Height</span>
                                        <div className="custom-input-contain">
                                            <InputNumber onChange={props.onImageHeightRes}
                                            value={props.customHRes} min={0.1} max={8096} />
                                        </div>
                                    </div>
                                </span> 
                            {/* } */}
                            </span>
                        </Radio>
                    }
                </Radio.Group>
            </Col>
        </Row>
        </div>
        {props.cameraSettingsChange == undefined ?
        <>
        <div >
        <h2 className="manrope f-14 black-33 w-600" style={{display: props.orientation == 'Custom' && props.orientationClicked ? "block" : "none", marginLeft:10}}>Enter Custom Resolution</h2>
        <Row className='silo-setting-individual' style={{display: props.orientation == 'Custom' && props.orientationClicked ? "block" : "none"}} >
            <Col className="custom-res-row">
                <div className="custom-aspects" style={{marginRight:20}}>
                    <span className="width-height-label f-14">Width</span>
                    <InputNumber className="width-height-label f-14" style={{width: 80,marginLeft:10}} autoFocus={false}  value={props.imageWidth}  min={1} max={8096} onChange={props.onChangeWidth}/>
                </div>
                <div className="custom-aspects" style={{marginRight:20}}>
                    <span className="width-height-label f-14">Height</span>
                    <InputNumber className="width-height-label f-14" style={{width: 80,marginLeft:10}} autoFocus={false}  value={props.imageHeight}  min={1} max={8096} onChange={props.onChangeHeight}/>
                </div>
                <div className="custom-aspects">
                    <span className="width-height-label f-14">Unit</span>
                    <Input className="width-height-label f-14" style={{width: 68,marginLeft:10}} value={'Pixels'} readOnly autoFocus={false} />
                </div>
            </Col>
        </Row>
        <Row className='silo-setting-individual' style={{display:props.orientation == 'Custom' && props.orientationClicked ? "block" : "none"}} >
            <Col className="custom-res-row">
                    <div className="note-bg">
                        <span className="width-height-label f-14" style={{color: "#262626"}}>Note:&nbsp;</span><span className="width-height-label f-14" style={{color: "#595959"}}>The width and height you add here will be the resolution in pixels for your image.</span>
                    </div>
            </Col>
        </Row>
        </div>
        </>
        :
        <div style={{width: "15vw!important", marginLeft:"2%"}}>
        <Button onClick={() => {
            props.setEditCamera(true);
            props.setTakeSiloShotModal(true);
        }} className='modal-okay-black square invert'>
            <span className='modal-okay-text manrope f-14 white'>
                Select Custom Resolution
            </span>
        </Button>
        </div>
        }
        {/* <Divider/> */}
        <div className=' silo-setting-individual'>
            <span className={props.cameraSettingsChange ==undefined?"manrope f-14 black-33 w-600 image-setting-header":"manrope f-14 black silo-heading-main"} >Select DPI</span>
            <Radio.Group
                defaultValue={'300'}
                value={props.dpi}
                style={{display:"flex"}}
                onChange={(e) => {
                    if (props.cameraSettingsChange){
                        props.onChangeDPICamera(e);
                    }
                    else {
                        props.onDPIChange(e);
                    }
                }}
                >
                <Radio value={'300'} className="dpi-settings manrope f-14 black">300 DPI</Radio>
                <Radio value={'400'} className="dpi-settings manrope f-14 black">400 DPI</Radio>
                <Radio value={'600'} className="dpi-settings manrope f-14 black">600 DPI</Radio>
            </Radio.Group>
        </div>
        <div className='silo-setting-individual'>
            <span className={props.cameraSettingsChange ==undefined?"manrope f-14 black-33 w-600 image-setting-header":"manrope f-14 black silo-heading-main"} >Image Format</span>
                <Radio.Group value={props.imageFormat} style={{display:"flex"}} defaultValue={'jpg'}
                onChange={(e) => {
                    if(props.cameraSettingsChange){
                        props.onImageFormatChangeCamera(e)
                    } else {
                        props.onImageFormatChange(e)
                    }
                    }}>
                    <Radio className="dpi-settings" value={'jpg'}><span className='manrope f-14  black'>JPG</span></Radio>
                    <Radio className="dpi-settings" value={'png'}><span className='manrope f-14 black'>PNG</span></Radio>
                    <Radio className="dpi-settings" value={'tiff'}><span className='manrope f-14  blackF'>TIFF</span></Radio>
                </Radio.Group>
        </div>
        {
            props.cameraSettingsChange == undefined ? 
            
            <>
            {/* <Divider/> */}
            <h2 style={{marginTop:10}} className="manrope f-14 black-33 w-600 silo-setting-individual">Field of View / Focal Length</h2>
            <Row>
                <Radio.Group
                    defaultValue={'fov'}
                    value={props.fovSwitch}
                    onChange={props.onFovSwitchChange}
                    buttonStyle="solid"
                    className="dpi-radio">
                    <Radio.Button value = {'fov'} className="dpi-settings silo-setting-individual">Field of View</Radio.Button>
                    <Radio.Button value = {'fl'} className="dpi-settings silo-setting-individual">Focal Length</Radio.Button>
                </Radio.Group>
            </Row>
            {props.fovSwitch == 'fov' ? 
            <Row className='silo-setting-individual'>
                <Col span={12} >
                    <Slider
                        marks={angleMarks}
                        className="black-slider"
                        min={5}
                        max={80}
                        onChange={props.onChangeFov}
                        value={props.fovValue}
                    />
                </Col>
                <Col span={4} className="custom-input-contain" style={{marginLeft: 16}}>
                    <InputNumber
                        className="input-fov"
                        min={5}
                        max={80}
                        value={props.fovValue}
                        onChange={props.onChangeFov}
                        formatter={value => `${value}°`}
                        parser={value => value.replace('°', '')}
                    />
                </Col>
            </Row>
            : 
            <Row className='silo-setting-individual'>
                <Col span={12} >
                    <Slider
                        marks={mmMarks}
                        className="black-slider"
                        min={props.focalLengthRange.min}
                        max={props.focalLengthRange.max}
                        onChange={props.onChangeFocalLength}
                        value={props.focalLengthValue}
                    />
                </Col>
                <Col span={4} className="custom-input-contain" style={{marginLeft: 16}}>
                    <InputNumber
                        className="input-fov"
                        min={props.focalLengthRange.min}
                        max={props.focalLengthRange.max}
                        value={props.focalLengthValue}
                        onChange={props.onChangeFocalLength}
                        formatter={value => `${value}mm`}
                        parser={value => value.replace('mm', '')}
                    />
                </Col>
            </Row>
            }
            </>
            :
            ''
        }
    </div>
  )
}
