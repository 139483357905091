import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button, Card, Menu, Dropdown } from 'antd';
import { ArrowRightOutlined, CameraOutlined, DownOutlined, EditOutlined } from '@ant-design/icons';
import ActionButtons from './ActionButtons';
import StoreActionButtons from './StoreActionButtons'
import BasicDetail from './BasicDetail';
import ProductInfoCollapse from './ProductInfoCollapse';
import FileConstants from '../../../../FileConstants';

const ProductTextualInfo = () => {
    const { productData, restrictedItem, openProductEditModal, dimensionalImageExists, isStorePage,
        productAssetData, statusOf360Spin, statusOfMp4Video, userAccessLevel } = useContext(CustomerProductContext);

    return (
        <Col span={12} className='detail-pd'>

            {!isStorePage && productAssetData['silo_data'] && productAssetData['silo_data'].length == 0
                && dimensionalImageExists == false && statusOf360Spin == "" && statusOfMp4Video == ""
                &&
                <div className='no-assets-message'>
                    <div className='manrope f-14 lh-14 orange'>
                        <CameraOutlined className='camera-no-assets' />
                        &nbsp;&nbsp;There are no images or 3D assets of this product yet!
                    </div>
                </div>}

            <div className={`display-flex j-s a-baseline ${productAssetData['silo_data'] && productAssetData['silo_data'].length == 0
                && dimensionalImageExists == false && statusOf360Spin == "" && statusOfMp4Video == "" ? '' : 'mt-40'}`}>
                <Tooltip title={<span className='manrope f-12 white'>
                    {productData['product_name']}
                </span>}>
                    <h6 className='manrope f-30 black-55 limit-lines limit-2'>
                        {productData['product_name']}
                    </h6>
                </Tooltip>
                {!isStorePage && userAccessLevel == 'edit' ?
                    <Tooltip title={<span className='manrope f-12 white'>
                        Edit Information
                    </span>}>
                        <EditOutlined onClick={openProductEditModal} className='edit-icon-property ml-4' />
                    </Tooltip> : ''}
            </div>
            <BasicDetail />
            {!isStorePage &&
                <ActionButtons />}
            {/* {isStorePage && 
                <StoreActionButtons/>} */}
            <ProductInfoCollapse />
        </Col>
    )
}

export default ProductTextualInfo;