import React, { useState, useEffect } from "react";
import { Card, Row, Col, Select, Modal, Calendar, Button, Empty, Alert, Space } from "antd";
import moment from "moment";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { LoadingOutlined, DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Line } from "@ant-design/charts";

const Actions = {
    get_monthly_breakdown: "get_monthly_breakdown",
};

const DATE_FORMAT = "YYYY-MM-DD";
const ALL_TIME_START = "2022-01-01";
const CALENDAR_LEFT = "left";
const CALENDAR_RIGHT = "right";

const ORIGINAL_LEGEND_SELECTED = {}

const CostMonthlyBreakdown = () => {
    // Variables
    const [isMonthlyBreakdownCostFetched, setIsMonthlyBreakdownCostFetched] = useState(false);
    const [monthlyCosts, setMonthlyCosts] = useState([]);
    const [startDate, setStartDate] = useState(
        moment().subtract(6, 'months').startOf("month").format(DATE_FORMAT)
    );
    const [endDate, setEndDate] = useState(
        moment().subtract(1, 'months').endOf("month").format(DATE_FORMAT)
    );
    const [isCustomDateModalVisible, setIsCustomDateModalVisible] = useState(false);
    const [customStartDate, setCustomStartDate] = useState(
        moment().subtract(1,"months").startOf('month')
    );
    const [customEndDate, setCustomEndDate] = useState(
        moment()
    );
    const [selectedValue, setSelectedValue] = useState("six_months");
    const [previousSelectedValue, setPreviousSelectedValue] = useState("six_months");
    const forecastMessage = `Data for the month of ${moment().format("MMMM YYYY")} is forecasted`;
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [modelTypeOptions, setModelTypeOptions] = useState([]);
    const [selectedModelTypes, setSelectedModelTypes] = useState({});

    const CONFIG = (dataSource, xField, yField, seriesField) => {
        let config = {
            data: dataSource,
            xField: xField,
            yField: yField,
            yAxis: {
                label: {
                formatter: (v) => `$${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                },
            },
            smooth: true,
            seriesField: seriesField,
            legend: {
                position: "top",
                layout: "horizontal",
                selected: selectedModelTypes,
            },
            animation: {
                appear: {
                animation: "path-in",
                duration: 500,
                },
            },
            tooltip: {
                fields: ['model_type', 'avg_modeling_cost', 'avg_automation_cost', 'avg_cost', 'frequency'],
                customContent: (title, items) => {
                    const formatterInfo = {
                        avg_modeling_cost: "Average Modeling Cost: ",
                        avg_automation_cost: "Average Automation Cost: ", 
                        avg_cost: "Average Cost: ",
                        frequency: "Frequency: ",
                        model_type: "Model Type: "
                    };
                    const month = items?.[0]?.title;
            
                    let htmlStr = `<div 
                                    style="margin:20px 20px;
                                    overflow: auto;
                                    max-height: 400px;" 
                                    class="g2-tooltip-items">
                                    <div 
                                    class="g2-tooltip-item" 
                                    style="margin-bottom:10px;
                                    display:flex;
                                    justify-content:space-between;">
                                    <span 
                                    class="g2-tooltip-item-value" 
                                    style="font-weight:bold;
                                    color:black;
                                    font-size: 14px;
                                    display: inline-block; 
                                    text-align: center;
                                    width: 100%;
                                    ">
                                    ${month}
                                    </span>
                                    </div>`;
            
                    items.forEach((item) => {
                        if (item.name === "model_type") {
                            htmlStr += `<div 
                                        class="g2-tooltip-item" 
                                        style="margin-bottom:10px;
                                        display:flex;
                                        justify-content:space-between;">
                                        <span 
                                        class="g2-tooltip-item-value" 
                                        style="font-weight:bold;
                                        color:black;
                                        text-decoration: underline;">
                                        ${item.value}
                                        </span>
                                        </div>`;
                        } 
                        else if (item.name === "frequency") {
                            htmlStr += `<div 
                                        class="g2-tooltip-item" 
                                        style="margin-bottom:15px;
                                        display:flex;
                                        justify-content:space-between;">
                                        <span 
                                        class="g2-tooltip-item-value" 
                                        style="font-weight:bold;">
                                        ${formatterInfo[item.name]}
                                        </span>
                                        <span 
                                        class="g2-tooltip-item-value">
                                        ${item.value}
                                        </span>
                                        </div>`;
                        }
                        else {
                            htmlStr += `<div 
                                        class="g2-tooltip-item" 
                                        style="margin-bottom:8px;
                                        display:flex;
                                        justify-content:space-between;">
                                        <span 
                                        class="g2-tooltip-item-value" 
                                        style="font-weight:bold;">
                                        ${formatterInfo[item.name]}
                                        </span>
                                        <span 
                                        class="g2-tooltip-item-value">
                                        $${item.value}
                                        </span>
                                        </div>`;
                        }
                    });
                    htmlStr += '</div>';
                    return htmlStr;
                },
                enterable: true
            },            
        };
        return config;
    };

    const isDateInRange = () => {
        const currentDate = new Date();
        const start = new Date(startDate);
        const end = new Date(endDate);
    
        return (
          (start.getMonth() === currentDate.getMonth() && start.getFullYear() === currentDate.getFullYear())  ||
          (end.getMonth() === currentDate.getMonth() && end.getFullYear() === currentDate.getFullYear()) ||
          (start <= currentDate && currentDate <= end)
        );
    };

    const getMonthlyBreakdown = () => {
        /* Function to get monthly average cost breakdown between given dates */
        
        setIsAlertVisible(isDateInRange());
        let payload = {
            "action" : Actions["get_monthly_breakdown"],
            "start_date" : startDate,
            "end_date" : endDate
        };

        console.log("Payload", payload);
        
        setIsMonthlyBreakdownCostFetched(true);
        axios.post(ENVIRONMENT.GET_MODELING_COST_REPORT, payload).then((response) => {
            const responseData = response.data;
            let selectOpts = []
            responseData.map ((type) => {
                if (!(type.model_type in ORIGINAL_LEGEND_SELECTED)) {
                    ORIGINAL_LEGEND_SELECTED[type.model_type] = true;
                    selectOpts.push({label: type.model_type, value: type.model_type});
                }
            })
            setSelectedModelTypes(ORIGINAL_LEGEND_SELECTED);
            setModelTypeOptions(selectOpts);
            setMonthlyCosts(responseData);
            setIsMonthlyBreakdownCostFetched(false);
        });
    }

    useEffect(() => {
        getMonthlyBreakdown();
    }, [startDate, endDate]);

    // Select (Date Dropdown) functions
    const dateOptions = [
        {
            label: "Last 6 Months",
            value: "six_months"
        },
        {
            label: "Last 1 Year",
            value: "one_year"
        },
        {
            label: "All Time",
            value: "all_time"
        },
        {
            label: "Custom",
            value: "custom"
        }
    ]

    const handleDateChange = (value) => {
        console.log("Date Value: ", value);
        if (value === "six_months") {
            setSelectedValue(value);
            setStartDate(moment().subtract(6, 'months').startOf("month").format(DATE_FORMAT));
            setEndDate(moment().subtract(1, 'months').endOf("month").format(DATE_FORMAT));
        }
        else if (value === "one_year") {
            setSelectedValue(value);
            setStartDate(moment().subtract(12, 'months').startOf("month").format(DATE_FORMAT));
            setEndDate(moment().subtract(1, 'months').endOf("month").format(DATE_FORMAT));
        }
        else if (value === "all_time") {
            setSelectedValue(value);
            setStartDate(ALL_TIME_START);
            setEndDate(moment().format(DATE_FORMAT));
        } 
    };

    const handleDateSelect = (value) => {
        console.log("Date Value: ", value);
        if (value === "custom") {
            setPreviousSelectedValue(selectedValue);
            setSelectedValue(value);
            setIsCustomDateModalVisible(true);
        }
    };

    // Custom Date Modal (Dropdown) functions
    const handleModalClose = () => {
        setIsCustomDateModalVisible(false);
        setSelectedValue(previousSelectedValue);
    };

    const handleModalOk = () => {
        const start = customStartDate.format(DATE_FORMAT);
        const end = customEndDate.format(DATE_FORMAT);
        if (end < start) {
            showAlert('Start date cannot be greater than end date!');
            return;
        }
        setStartDate(start);
        setEndDate(end);
        setIsCustomDateModalVisible(false);
        setSelectedValue(customStartDate.format("DD/MM/YYYY") + " → " + customEndDate.format("DD/MM/YYYY"));
    }

    const showAlert = (message) => {
        alert(message);
    };

    // Calendar Functions
    const handleDisabledDates = (date) => {
        if (date.format(DATE_FORMAT) < ALL_TIME_START || date.format(DATE_FORMAT) > moment().format(DATE_FORMAT)) {
            return true;
       }
       return false;
    };

    const CalendarHeader = ({calendarType, onChange}) => {
        const calendarDate = calendarType === CALENDAR_LEFT ? customStartDate : customEndDate;
        return (
          <Row>
            <Col span={3} className="justify-in-start">
              <Button 
                onClick={() => {
                    handlePreviousChange(calendarType, "years", onChange);
                }} 
                icon={<DoubleLeftOutlined />} />
            </Col>
            <Col span={3} className="justify-in-start">
              <Button 
                onClick={() => {
                    handlePreviousChange(calendarType, "months", onChange);
                }}
                icon={<LeftOutlined />} />
            </Col>
            <Col span={12} className="justify-in-center">
              <span style={{color: "black", fontWeight: "bold"}}>{calendarDate.format("MMMM YYYY")}</span>
            </Col>
            <Col span={3} className="justify-in-end">
              <Button 
                onClick={() => {
                    handleNextChange(calendarType, "months", onChange);
                }}
                icon={<RightOutlined />} />
            </Col>
            <Col span={3} className="justify-in-end">
              <Button 
                onClick={() => {
                    handleNextChange(calendarType, "years", onChange);
                }}  
                icon={<DoubleRightOutlined />} />
            </Col>
          </Row>
        );
    }; 

    const handlePreviousChange = (type, locale, onChange) => {
        if (type == CALENDAR_LEFT) {
            const updatedCustomStartDate = moment(customStartDate).subtract(1, locale);
            if (updatedCustomStartDate.format(DATE_FORMAT) >= ALL_TIME_START) {
                setCustomStartDate(updatedCustomStartDate);
                onChange(updatedCustomStartDate);
            }
        }
        if (type == CALENDAR_RIGHT) {
            const updatedCustomEndDate = moment(customEndDate).subtract(1, locale);
            if (updatedCustomEndDate.format(DATE_FORMAT) >= ALL_TIME_START) {
                setCustomEndDate(updatedCustomEndDate);
                onChange(updatedCustomEndDate);
            }
        }
    };

    const handleNextChange = (type, locale, onChange) => {
        const currentDate = moment().format(DATE_FORMAT)
        if (type == CALENDAR_LEFT) {
            const updatedCustomStartDate = moment(customStartDate).add(1, locale);
            if (updatedCustomStartDate.format(DATE_FORMAT) <= currentDate) {
                setCustomStartDate(updatedCustomStartDate);
                onChange(updatedCustomStartDate);
            }
        }
        if (type == CALENDAR_RIGHT) {
            const updatedCustomEndDate = moment(customEndDate).add(1, locale);
            if (updatedCustomEndDate.format(DATE_FORMAT) <= currentDate) {
                setCustomEndDate(updatedCustomEndDate);
                onChange(updatedCustomEndDate);
            }
        }
    };

    const handleOnSelect = (newValue, calendarType) => {
        if (calendarType == CALENDAR_LEFT) {
            setCustomStartDate(newValue);
        }
        if (calendarType == CALENDAR_RIGHT) {
            setCustomEndDate(newValue);
        }
    };

    const handleOptionChange = (value) => {
        const allValues = Object.keys(value).map(key => value[key]);
        let selectedValue = allValues[allValues.length - 1];
        console.log(`selected ${selectedValue}`);
        let newValue = {};
        Object.entries(ORIGINAL_LEGEND_SELECTED).forEach(([key, status]) => {
            newValue[key] = allValues.length === 0 ? true : allValues.includes(key);
        });
        setSelectedModelTypes(newValue);
    };

    return (
        <>
            <Card
            className="no-hover section-container mt-10"
            size="small"
            title={
                <Row className="mt-10 justify-in-start" gutter={12}>
                    <Col span={14}>
                        <span className="sub-heading">Monthly Breakdown</span>
                    </Col>
                    <Col span={6} className="justify-in-end">
                        <Select
                        placeholder="Select Type"
                        mode="tags"
                        style={{width: "100%"}}
                        options={modelTypeOptions}
                        onChange={handleOptionChange}
                        tokenSeparators={[',']}
                        >
                        </Select>
                    </Col>
                    <Col span={4} className="justify-in-end">
                        <Select
                        placeholder="Select Date"
                        style={{width: "100%"}}
                        options={dateOptions}
                        defaultValue={dateOptions[0]['value']}
                        onChange={handleDateChange}
                        onSelect={handleDateSelect}
                        value={selectedValue}
                        >
                        </Select>
                    </Col>
                </Row>
            }>
                <Row>
                    <Space
                        direction="vertical"
                        style={{
                        width: '100%',
                        }}>
                        {isAlertVisible && (
                        <Alert
                            message={forecastMessage}
                            type="info"
                            closable
                            showIcon
                            onClose={() => setIsAlertVisible(false)}
                        />
                        )}
                    </Space>
                </Row>
                <Row>
                    <div className="chart-container">
                        {
                        isMonthlyBreakdownCostFetched ? (<LoadingOutlined className="loading-center top-20"/>) : 
                        monthlyCosts.length > 0 ? 
                        <Line
                        {...CONFIG(
                            monthlyCosts,
                            "month",
                            "avg_cost",
                            "model_type",
                        )}
                        className="line-plot"/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </div>
                </Row>
            </Card>
            <Modal
            title="Custom Date"
            visible={isCustomDateModalVisible}
            onCancel={handleModalClose}
            onOk={handleModalOk}
            width={"50%"}
            bodyStyle={{ maxWidth: '100%', overflowX: 'auto' }}
            okText="Confirm">
                <Row gutter={20}>
                    <Col span={12}>
                        <span className="ant-picker-cell-inner" style={{color: "black"}}>
                            Select Start Date:
                        </span>
                    </Col>
                    <Col span={12}>
                        <span className="ant-picker-cell-inner" style={{color: "black"}}>
                            Select End Date:
                        </span>
                    </Col>
                </Row>
                <Row gutter={20} className="justify-in-center">
                    <Col span={12}>
                        <div className="wrapper-style" style={{ maxWidth: '100%', overflowX: 'auto' }}>
                            <Calendar 
                            fullscreen={false} 
                            disabledDate={handleDisabledDates}
                            headerRender={({ value, type, onChange, onTypeChange }) => {
                                return (
                                    <CalendarHeader calendarType={CALENDAR_LEFT} onChange={onChange}/>
                                );
                            }}
                            onSelect={(date) => {
                                handleOnSelect(date, CALENDAR_LEFT);
                            }}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                    <div className="wrapper-style" style={{ maxWidth: '100%', overflowX: 'auto' }}>
                            <Calendar 
                            fullscreen={false} 
                            disabledDate={handleDisabledDates}
                            headerRender={({ value, type, onChange, onTypeChange }) => {
                                return (
                                    <CalendarHeader calendarType={CALENDAR_RIGHT} onChange={onChange}/>
                                );
                            }}
                            onSelect={(date) => {
                                handleOnSelect(date, CALENDAR_RIGHT);
                            }}
                            />
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default CostMonthlyBreakdown;
