import React from 'react';
import { Modal, Button, Row, Col, Card, Carousel } from 'antd';
import { withRouter } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import ENVIRONMENT from '../../../../environments';
import AWS from 'aws-sdk';
import axios from 'axios';
import FileConstants from '../../../../FileConstants';
import { LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import $ from 'jquery';
class Onboarding extends React.Component {
    constructor(props){
        super(props);
        this.carousel = React.createRef();

        let new_step = 1;
        let step_num = new URLSearchParams(window.location.search).get("step_no");
        let account_dropdown = new URLSearchParams(window.location.search).get("account_dropdown");


        if (step_num) {
            new_step = parseInt(step_num);
        }
      
       
        this.state = {
            selected_feature: '',
            carousel_data: [],
            new_step: new_step,
            account_details_tour: false,
            carousel_start: 1,
            total_slides: 0,
            congratsModal: false,
            account_dropdown: account_dropdown,
            trigger_next: false,
            terms_accepted: false,
            user_plan: 'enterprise'
        }

    }

    componentDidMount = () => {
        this.loadUserRequestStatus();
        FileConstants.exploreTourOptions.map((option) => {
            console.log(option)
            this.iterateSteps(option);
        });       
       
    }

    loadUserRequestStatus = () => {
        let check_payload = {
          "username": localStorage.getItem('username'),
          "action": "check",
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload)
        .then(res => {
            this.setState({
                user_plan: res.data['subscription_plan']
            });
        });
    }

    onCancelCongrats = () => {
        this.setState({
            congratsModal: false,
            selected_feature: ''
        })
    }

    next = () => {
        this.carousel.next();

        //this detects that the user moved the carousel through next/prev button
        this.setState({
            trigger_next: true
        });
    }

    prev = () => {
        //if user moves back from step from carousel then close the carousel
        if (this.state.carousel_start == 1) {
            this.carousel.goTo(0, true);
            this.setState({
                new_step: 5,
                selected_feature: '',
                congratsModal: false,
                trigger_next: false
            });
        } else {
            this.carousel.prev();

            //this detects that the user moved the carousel through next/prev button
            this.setState({
                trigger_next: true
            });
        }
        
    }

    closeCarouselSlides = () => {
        this.setState({
            selected_feature: '',
        });
    }


    moveToNextStep = () => {
        this.setState({
            new_step: this.state.new_step + 1
        });
    }

    moveBackToPrevStep = () => {
        this.setState({
            new_step: this.state.new_step - 1
        });
    }
   

    setCurrentStep = (path) => {
        if (path == 'account_details_dropdown') {
            window.location.href = '/home?step_no=3&account_dropdown=true'
            // this.props.history.push({
            //     pathname: '/home',
            //     search: `?step_no=2&account_dropdown=true`
            // });  
        }
        else if (path == 'account_page') {
            if (this.state.user_plan == 'enterprise') {
                window.location.href = '/home?step_no=5'
            } else {
                this.props.history.push({
                    pathname: '/account-details',
                    search: `?step_no=4`
                }); 
            } 
        } else if (path == 'explore_popup') {
            this.moveToNextStep();
            this.props.history.push({
                pathname: '/home',
                search: `?step_no=5`
            }); 
        }
        else if (path == 'close') {
            this.closeTour();
            this.setState({
                new_step: -1
            }); 
        }

        // this.setState({
        //     new_step: this.state.new_step + 1
        // });
    }

    //get files in selected feature's folder from S3
    iterateSteps = (feature) => {
        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: { 
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });

        cognito_credentials.refresh(() => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });
            var s3 = new AWS.S3();
            AWS.config.update({region: 'us-west-2'});

            var params = {
                Bucket: 'all3d',
                Delimiter: '/',
                Prefix: `static_files/tour/${feature}/`,
            }; 
            
            s3.listObjects(params,  (err, data) => {
                if(err) {
                    throw err;
                } else {
                    //create and populate images for each feature
                    let data_arr = this.state.carousel_data;
                    data_arr[feature] = data.Contents;
                    this.setState({
                        carousel_data: data_arr
                    });
                    
                }
            });
        });
    }

    disableBody = target => disableBodyScroll(target);
    enableBody = target => enableBodyScroll(target);

    closeTour = () => {
        this.setState({ isTourOpen: false });
    };

    onChangeCarousel = (before, to) => {
        this.setState({
            carousel_start: to + 1,
        });
        if (this.state.selected_feature != '') {
            //if user completes the tour i.e if the next step is equal to the number of images on s3, then show congrats modal
            if ((before == (this.state.carousel_data[this.state.selected_feature].length - 2) && to == 0) && this.state.trigger_next) {
                this.carousel.goTo(0,true);
                this.setState({
                    congratsModal: true,
                    trigger_next: false
                })
            } else if ((before == 0 && to == (this.state.carousel_data[this.state.selected_feature].length - 2)) && this.state.trigger_next) {
                //if the user goes back from the first step, then close carousel and move to previous step
                this.setState({
                    new_step: 5,
                    selected_feature: '',
                    congratsModal: false,
                    trigger_next: false
                });
            }
        }  
    }

    learnMore = () => {
        this.setState({
            new_step: 5,
            selected_feature: '',
            congratsModal: false
        });
    }


    openFeatureSteps = (feature) => {
        this.setState({
            selected_feature: feature,
            new_step: this.state.new_step + 1
        });
    }

    render() {
        const accentColor = "#D93025";

        const accountOption = [
            {
            selector: '[data_tut="reactour__accountOption"]',
            content: () => (
                <div>
                    <p className='manrope f-14 black-14'>You can see your subscription plan and usage in the Account Details section.</p>
                </div>
                ),
            style: {minWidth: 400}
            }
        ];

        const accountDetails = [
            {
            selector: '[data_tut="reactour__accountDetails"]',
            content: () => (
                <div>
                    <h3 className="manrope f-16 black-33 w-700">ALL3D Free Trial</h3>

                    <p className='manrope f-14 black-14'>
                    Free trial users will be able to use 20 product models and 2 space models from the ALL3D Store at no cost. They will also be able to upload 10 3D Models and render 5 1k lifestyle images for free.
                        <br/>
                        <br/>
                        Usage limits can be seen in the ‘Total Usage’ Section under ‘Account Settings’.</p>
                </div>
                ),
            style: {minWidth: 500}
            }
        ];

        return (
            <>
                <Modal
                    title={null}
                    closable={false}
                    footer={null}
                    visible={this.state.new_step == 1}
                    bodyStyle={{padding: 0}}
                    width={800}
                    centered={true}
                    >
                    <div style={{padding: 40}}>
                        <img style={{width: '100%'}} src={require("../../../../assets/images/welcome_to_all3d.jpg")}></img>
                        <div style={{width: 620, textAlign: "center", margin: 'auto'}}>
                            <h3 style={{marginTop: 12}}>Welcome to ALL3D!</h3>
                            <div className="justify-in-center" style={{marginTop: 32}}>
                                <Button className="modal-okay-light-gray square font-16" style={{marginRight: 30}} onClick={() =>this.setCurrentStep('close')}>Skip Tour</Button>
                                <Button className="modal-okay square font-16" onClick={() =>this.moveToNextStep()} >Let's Get Started</Button>
                            </div>
                        </div>       
                    </div>     
                </Modal>

                <Modal
                    title={null}
                    closable={false}
                    footer={null}
                    visible={this.state.new_step == 2}
                    bodyStyle={{padding: 0}}
                    width={800}
                    centered={true}
                    >
                    <div style={{padding: 40}}>
                        <div className='justify-space-between'>
                            <img className='image-subscription' src={"/img/coins.png"}/>
                            <div>
                                <h3 className='manrope f-24 black-14 w-600' style={{marginTop: 12, marginLeft: 20}}>ALL3D’s Subscription Plans</h3>
                                <p style={{marginLeft:20}} className='manrope f-14 black-55 w-400'>
                                    ALL3D offers monthly subscription plans suited for all types of users, ranging from a free trial all the way to an Enterprise plan.
                                    <br/>
                                    <br/>
                                    All users get access to a free trial package that provides limited use of ALL3D’s awesome features. You can upgrade to a better plan at any time
                                </p>
                                <p className='manrope f-14 black-55 w-400 italic' style={{marginLeft:20}}>
                                    * Please note that some purchases within the system require in-app payments as well. 
                                </p>
                            
                            </div>  
                        </div>    
                        <div className="justify-in-end" style={{marginTop: 32}}>
                            <Button className="modal-okay-light-gray square font-14" style={{marginRight: 30}} onClick={() => this.setCurrentStep('close')}>Skip Tour</Button>
                            <Button className="modal-okay square font-14" onClick={() =>this.setCurrentStep('account_details_dropdown')} >Next</Button>
                        </div> 
                    </div>     
                </Modal>

                <Modal
                    title={null}
                    closable={false}
                    footer={null}
                    visible={this.state.new_step == 5 && !this.state.congratsModal}
                    bodyStyle={{padding: 0}}
                    className='tour-explore-modal'
                    centered={true}
                    >
                    <div style={{padding: "28px"}}>
                        <div className='manrope f-24 black-14 w-600 justify-in-center' style={{textAlign: 'center', margin: 'auto'}}>
                            Let’s explore what you can do
                        </div> 
                        <p className='manrope f-14 black-55' style={{marginBottom: 0, marginTop:10}}>Click an option to find out more.</p> 
                        <div className='justify-in-center' style={{flexWrap: 'wrap'}}>
                            {FileConstants.TOUR_FEATURES.map((ele,index) => ((
                                <Card  style={{padding: 12, marginBottom: 12, marginTop: 12, marginRight: 12}} onClick={() => this.openFeatureSteps(ele.value)}>
                                    <img className='explore-img' src={ele.image}/>
                                    <div className='manrope f-14 black-14 w-500' style={{textAlign: 'center', marginTop: 12}}>{ele.text}</div>
                                </Card>
                            )))}
                        </div>  
                        <div className="justify-in-end" style={{marginTop: 28}}>
                            <Button className="modal-okay-light-gray square font-14" style={{marginRight: 30}} onClick={() =>this.setCurrentStep('close')}>Skip Tour</Button>
                        </div> 
                    </div>     
                </Modal>

                <Modal
                    title={null}
                    closable={false}
                    footer={null}
                    visible={this.state.selected_feature != '' && !this.state.congratsModal}
                    bodyStyle={{padding: 0}}
                    className='tour-modal'
                    centered={true}
                    >
                    <div style={{padding: "20px 28px"}}>
                        {this.state.carousel_data[this.state.selected_feature] && this.state.carousel_data[this.state.selected_feature].length > 1 ?
                        <>
                            <div className='manrope f-24 black-14 w-600 justify-in-center' style={{textAlign: 'center', margin: 'auto'}}>
                                {FileConstants.getTourHeading(this.state.selected_feature)}
                            </div> 

                            <div style={{width:'100%', textAlign: "center"}}>
                                <Carousel autoplay={false} ref={node => (this.carousel = node)} style={{width:"100%"}} beforeChange={this.onChangeCarousel}>
                                    {this.state.carousel_data[this.state.selected_feature].map((img,index) => (
                                        index > 0 &&
                                        <div>
                                            <img style={{marginTop: 21}} className='carousel-help' src={ENVIRONMENT.getBaseURL() +img.Key}/>
                                        </div>
                                    ))}            
                                </Carousel>
                            </div>  
                        
                            <div className="justify-space-between" style={{marginTop: 28}}>
                                <Button className="modal-okay-light-gray square font-14" onClick={this.closeCarouselSlides}>Skip Tour</Button>
                                <div className='justify-in-end'>
                                    <Button className="modal-okay-light-gray square font-14" style={{marginRight: 30}} onClick={() =>this.prev()}><LeftOutlined/></Button>
                                    <p style={{marginBottom: 0, marginRight: 30}} className='manrope f-14 black-14'>{this.state.carousel_start} / {this.state.carousel_data[this.state.selected_feature].length - 1}</p>
                                    <Button className="modal-okay square font-14" onClick={() => this.next()} ><RightOutlined/></Button>
                                </div>
                            </div> 
                        </> : 
                        <div style={{height: '500px'}} className='justify-in-center'> 
                            <LoadingOutlined style={{fontSize: 40, textAlign: 'center'}}/> 
                        </div>}
                    </div>     
                </Modal>
               
                <Modal
                 className="silo-success-modal"
                 centered={true}
                 closable={false}
                 maskClosable={false}
                 width={600}
                 onCancel={this.onCancelCongrats}
                 visible={this.state.congratsModal}
                 bodyStyle={{padding: 0}}
                 footer={[
                    <div className="justify-in-center">
                        <Button className="modal-okay-light-gray square font-16" onClick={this.onCancelCongrats}>Skip Tour</Button>
                        <Button className="modal-okay square font-16" htmlType="submit" onClick={this.learnMore}>
                            Learn More
                        </Button>
                    </div>
                ]}>
                    <div style={{padding:"40px"}}>
                        <div style={{textAlign: "center"}}>
                            <img style={{width: 85,height:85}} src={"/img/smiley.png"}></img>
                        </div>
                        <div style={{textAlign: "center",margin: "20px auto"}}>
                            <h2  className=" manrope f-20 green-75 w-600 justify-in-center">Congratulations!</h2>
                            <p className="manrope f-16 black-55">{FileConstants.congratsMessage(this.state.selected_feature)}</p>
                        </div>     
                    </div>  
                </Modal>

                <Tour
                    steps={accountOption}
                    isOpen={this.state.new_step == 3 && this.state.account_dropdown}
                    className="helper"
                    rounded={5}
                    accentColor={accentColor}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                    maskClassName="custom-mask"
                    disableDotsNavigation={true}
                    closeWithMask={false}
                    lastStepNextButton={<Button type="primary" className="modal-okay square font-14" onClick={() => this.setCurrentStep('account_page')} style={{position:"absolute", right: 20, bottom: 20}}>Next</Button>}
                    nextButton={<span></span>}
                    prevButton={<span></span>}
                    showCloseButton={false}
                    showNavigation={false}
                    showNavigationNumber={false}
                    showNumber={false}
                    onRequestClose={() => this.setCurrentStep('account_page')}
                />

                <Tour
                    steps={accountDetails}
                    isOpen={this.state.new_step == 4}
                    className="helper"
                    rounded={5}
                    accentColor={accentColor}
                    onAfterOpen={this.disableBody}
                    onBeforeClose={this.enableBody}
                    maskClassName="custom-mask"
                    disableDotsNavigation={true}
                    closeWithMask={false}
                    lastStepNextButton={<Button type="primary" className="modal-okay square font-14" onClick={() => this.setCurrentStep('explore_popup')} style={{position:"absolute", right: 20, bottom: 20}}>Next</Button>}
                    nextButton={<span></span>}
                    prevButton={<span></span>}
                    showCloseButton={false}
                    showNavigation={false}
                    showNavigationNumber={false}
                    showNumber={false}
                    onRequestClose={() => this.setCurrentStep('explore_popup')}
                />
                
            </>
        );
    }
}

export default withRouter(Onboarding);