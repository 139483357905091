import React, {useEffect, useState} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Row, message, Modal } from "antd";
import {listCollections} from "../../../../redux/actions";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import CollectionsList from '../CustomerCollections/CollectionList';
import DottedLoader from '../../DottedLoader';

const CustomerCollections = (props) => {

    const [modalVisible,setModalVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [selectedCollection, setSelectedCollection] = useState(-1);
    const [isLoading,setIsLoading] = useState(false);
    const [libraryCollection, setLibraryCollection] = useState([]);
    const [selectedCustomerCollections, setSelectedCustomerCollections] = useState([]);

    const getMSPCollectionPayload = () =>
    {
        let payload = {
            "required_fields": ["id", "name", "customer_username", 
            "created_on", "spaces_count", "products_count", 
            "product_thumbnails", "thumbnail_link","space_areas"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + localStorage.getItem('username') + "'"
        let company_id = "";
        let designed_for = "designed_for__exact='" + localStorage.getItem('managed_customer_username') + "'"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + localStorage.getItem('managed_customer_username') + "\"%'"

        if (localStorage.getItem('managed_company_id')) {
            company_id = "company_id__exact=" + parseInt(localStorage.getItem('managed_company_id'))
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden + ")&&(" + designed_for + ")"   
          } else {
            filter_string = "("+customer_username+ "&&" + is_hidden + ")&&(" + designed_for + ")" 
          }
          filter_string = filter_string + "||(" + has_access_to + ")"

          payload['filter_string'] = filter_string
      
          return payload;
    }

    let getCollectionPayload = () => {
        let payload = {
            "required_fields": ["id", "name", 
            "created_on", "spaces_count", "products_count", "template_collection_id", 
            "product_thumbnails", "thumbnail_link", "customer_username", "designed_for", "space_areas"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + localStorage.getItem('username') + "'"
        let company_id = "";
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + localStorage.getItem('username') + "\"%'"
    
        if (localStorage.getItem('shared_entities') != undefined && localStorage.getItem('company_id') && localStorage.getItem('shared_entities').split("_").includes("collection")) {
            company_id = "company_id__exact=" + localStorage.getItem('company_id')
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")&&(template_collection_id__is=null)"
        } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")&&(template_collection_id__is=null)"
        }
        filter_string = filter_string + "||(" + has_access_to + ")||(template_collection_id__not=NULL&&" + customer_username+ "&&"+ is_hidden +")"
       
        payload['filter_string'] = filter_string
    
        return payload;
      }

      const getMSPCustomerData = () => {
        if (localStorage.getItem("is_msprovider") === 'true' && (localStorage.getItem('managed_customer_username') != null || localStorage.getItem('managed_company_id') != null)) {
          let payload = getMSPCollectionPayload();
          axios.post(ENVIRONMENT.LIST_COLLECTION, payload)
          .then(res => {
            if (res.data) {
              let owned_data = res.data;
              setSelectedCustomerCollections(owned_data)
            }
            setIsLoading(false)
          });
        }
      }

      const fetchCollectionData = () =>
      {
        let payload = getCollectionPayload();
        axios.post(ENVIRONMENT.LIST_COLLECTION, payload)
          .then(res => {
            if (res.data) {
              let owned_data = res.data;
              setLibraryCollection(owned_data)
            }
            setIsLoading(false)
          });
      }

    useEffect(() => {
        if (props.redirection_path == '/projects') {
            props.setNewCollectionView();
        }
    }, []);

    useEffect(() => {
        if(localStorage.getItem("is_msprovider") === 'true')
        {
            getMSPCustomerData();
        }
        fetchCollectionData()
    }, []);

    const createScene = (payload) => {
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then((res) => {
                message.info('Scene created successfully');
                var scene_id = res.data["scene_id"];
                window.location.href = '/scene_builder/' + scene_id + '?origin=/home';
            })
            .catch((error) => {
                message.error('Error in creating scene');
            });
    }

    const getCollectionDetails = (id) => {
        
        let collection_details = {
            products_count: 0,
            spaces_count: 0
        };
        let collection_list = getCollections()
        for (var i = 0; i < collection_list.length; i++) {
            if (collection_list[i].id == id) {
                collection_details.products_count = collection_list[i].products_count;
                collection_details.spaces_count = collection_list[i].spaces_count;
                collection_details.space_areas = collection_list[i].space_areas;
            }
        }
        
        return collection_details;
    }

    const getRoomAndCreateScene = (payload, id) => {
        console.log(payload,id)
        axios.post(ENVIRONMENT.COLLECTION_GET_ROOMS, {
            collection_id: id.toString()
        })
            .then((response) => {
                payload.room_id = response.data[0].room_id;
                createScene(payload);
            })
            .catch((error) => {
            });
    }

    const handleContinue = () => {
        if (selectedCollection == -1)
        {
            setModalVisible(true);
        } 
        else
        {
            let values = {"scene_name": props.getSceneName(), "collection_id": selectedCollection}
            props.setCollectionId(values.collection_id);
            message.info('Collection Selected');
            let collectionDetails = getCollectionDetails(values.collection_id);
            
            if (collectionDetails.spaces_count == 0) {
                setIsLoading(true);
                props.setModelSelectionStage(1);
            }
            else if(collectionDetails.spaces_count > 0 && collectionDetails.products_count == 0){
                setIsLoading(true);
                props.setSpaceAreas(collectionDetails.space_areas);
                props.setModelSelectionStage(2);
            }
            else {
                setIsLoading(true);
                
                let payload = {
                    "scene_name": values.scene_name,
                    "collection_id": values.collection_id,
                    "room_id": "",
                    "username": localStorage.getItem('username')
                };
                let company_id = localStorage.getItem('company_id');
                if (company_id != undefined) {
                    payload.company_id = company_id;
                }
                if (localStorage.getItem("is_msprovider") === 'true' && (localStorage.getItem('managed_customer_username') != null || localStorage.getItem('managed_company_id') != null)) {
                    payload.designed_for = localStorage.getItem('managed_customer_username');
                }
                console.log(payload, values, 'values')
                getRoomAndCreateScene(payload, values.collection_id);
            }
        }
    }

    const handleCancellation = () => {
        if(props.redirection_path){
            props.history.push({
                pathname: props.redirection_path
            }); 
        } else {
            props.history.push({
                pathname: '/home'
            });
        }
    }    

    const checkIfCompanyExisits = (company_id) => {
        if (company_id != "" && company_id != null) {
            return true;
          } 
        return false;
      }

    const getCollections = () => {

        if (localStorage.getItem("is_msprovider") === 'true' && localStorage.getItem('managed_customer_username') != null) {
            return selectedCustomerCollections;  
        }
        else {
            if (localStorage.getItem("is_msprovider") === 'true') {
                return libraryCollection.filter((collection) => (collection.customer_username == localStorage.getItem('username')) && collection.designed_for == "");
            } else {
                return libraryCollection;
            }
        }
    }

    return (
        <div>
            { props.redirection_path != "/projects" && 
            <>
            <div style={{ display: "flex", justifyContent: "space-between",marginBottom: 20 }}>
                <div>
                    <h2 className='manrope f-24 black-00 w-700' style={{marginTop: "6px"}}>My Collections</h2>
                    <h5 className='manrope f-14 black-55 w-600' > Choose From Existing Collections </h5>
                </div>
                <div>
                    <Button className="modal-okay-orange square font-14" onClick={() => props.setNewCollectionView()} type="danger d-inline" shape="round" style={{ float: "right", marginTop: 24 }}>
                       Create New Collection
                    </Button>
                </div>
            </div>
           
            {props.collectionReducer.isLoading || (getCollections() && getCollections() <= 0) ?  <DottedLoader/> :
            <Row gutter={12}>
                <CollectionsList
                scene_flow={props.scene_flow}
                selectedCollection={selectedCollection}
                setSelectedCollection={setSelectedCollection}
                select={true}
                loader={props.collectionReducer.isLoading}
                libraryCollection={getCollections()}
                searchValue={searchValue}
                />
            </Row>}
            <div style={{ position: "fixed", width: "100%",padding:"0px", backgroundColor: "white",zIndex: 1, bottom: 0, boxShadow: "0px -8px 25px rgba(0, 0, 0, 0.04)"}}>
                <div style={{ display: "flex", justifyContent: "flex-end", margin:20,marginRight:168 }}>
                    <Button className="outline-red-btn square font-14" onClick={handleCancellation}>
                        Cancel
                    </Button>
                    <Button className="modal-okay-green square font-14" style={{ marginLeft: 10 }} onClick={handleContinue}>
                        Continue {isLoading ? <LoadingOutlined/> : ""}
                    </Button>
                </div>
            </div>
            <Modal
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    width={740}
                    className="model-space-alert"
                    bodyStyle={{padding: 0}}
                    footer={[
                        <div className='justify-in-end'>
                            <Button className="modal-okay square font-14" key="ok" onClick={() => setModalVisible(false)}>
                                Okay
                            </Button>
                        </div>
                    ]}>
                    <div style={{padding:"30px",display:"flex"}}>
                        <img src='/img/alert-triangle.svg' style={{width:"64px",height:"64px",marginRight:"20px"}} alt="Alert"/>
                        <div>
                            <h2 className="modal-heading manrope f-16 black-00 w-600">No Collection Selected</h2>
                            <p className="modal-text manrope f-14 black-55">You have not selected any collection. Please click any one collection before pressing continue.</p>
                        </div>
                    </div>
                </Modal>
                </>
            }
        </div>
    );
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    listCollections: (payload) => {
        dispatch(listCollections(payload));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(CustomerCollections)
)
