import React from 'react';

const CollaborateConstants = new Object();

CollaborateConstants.COLLABORATE_ACTIONS = [
    {
        "main_action": "select",
        "tooltip": "Select",
        "icon_type": "img",
        "icon": "/img/collaborate_tool/select.svg",
        "extra_actions": []
    },
    {
        "main_action": "annotate",
        "tooltip": "Annotate",
        "icon_type": "img",
        "icon": "/img/collaborate_tool/comment.svg",
        "extra_actions": []
    },
    {
        "main_action": "color",
        "tooltip": "Change Color",
        "icon_type": "color",
        "icon": "",
        "extra_actions": []
    },
    {
        "main_action": "text",
        "tooltip": "Add Text",
        "icon_type": "img",
        "icon": "/img/collaborate_tool/text_t.svg",
        "extra_actions": []
    },
    {
        "main_action": "shape",
        "tooltip": "Add Shapes",
        "icon_type": "img",
        "icon": "/img/collaborate_tool/triangle.svg",
        "extra_actions": [
            {
                "main_action": "rectangle",
                "tooltip": "Square",
                "icon_type": "img",
                "icon": "/img/collaborate_tool/square.svg",
                "extra_actions": []
            },
            {
                "main_action": "circle",
                "tooltip": "Circle",
                "icon_type": "img",
                "icon": "/img/collaborate_tool/circle.svg",
                "extra_actions": []
            },
            {
                "main_action": "triangle",
                "tooltip": "Triangle",
                "icon_type": "img",
                "icon": "/img/collaborate_tool/triangle.svg",
                "extra_actions": []
            }
        ]
    },
    {
        "main_action": "draw",
        "tooltip": "Draw",
        "icon_type": "img",
        "icon": "/img/collaborate_tool/pencil.svg",
        "extra_actions": [
            {
                "main_action": "pen_thickness",
                "tooltip": "Pen Thickness",
                "icon_type": "img",
                "icon": "/img/collaborate_tool/thickness.svg",
                "extra_actions": [{
                    "main_action": "thin",
                    "tooltip": "Thin",
                    "icon_type": "text",
                    "icon": "",
                    "extra_actions": []
                }, {
                    "main_action": "medium",
                    "tooltip": "Medium",
                    "icon_type": "text",
                    "icon": "",
                    "extra_actions": []
                }, {
                    "main_action": "thick",
                    "tooltip": "Thick",
                    "icon_type": "text",
                    "icon": "",
                    "extra_actions": []
                }]
            }
        ]
    }, {
        "main_action": "erase",
        "tooltip": "Remove/ Erase ",
        "icon_type": "img",
        "icon": "/img/collaborate_tool/eraser.svg",
        "extra_actions": []
    },
]

CollaborateConstants.CANVAS_ACTIONS = [
    {
        "main_action": "select",
        "tooltip": "Select",
        "icon": "/img/collaborate_tool/select.svg",
    },
    {
        "main_action": "pan",
        "tooltip": "Pan",
        "icon": "/img/collaborate_tool/hand.svg"
    },
    {
        "main_action": "zoom_out",
        "tooltip": "Zoom Out",
        "icon": "/img/collaborate_tool/zoom_out.svg"
    },
    {
        "main_action": "zoom_in",
        "tooltip": "Zoom In",
        "icon": "/img/collaborate_tool/zoom_in.svg"
    },
    {
        "main_action": "send_email",
        "tooltip": "Send Email Notification",
        "icon": "/img/collaborate_tool/email.svg"
    },
]


CollaborateConstants.edit_collaboration_access_values = [
    {
        'label': 'can edit',
        'value': 'edit'
    },
    {
        'label': 'can view',
        'value': 'view'
    },
    {
        'label': 'not shared',
        'value': 'restricted'
    }
]

CollaborateConstants.new_collaboration_access_values = [
    {
        'label': 'Editor',
        'value': 'edit'
    },
    {
        'label': 'Co-Owner',
        'value': 'co-owner'
    }
]

CollaborateConstants.comment_history_options = [
    {
        'label': 'All comments',
        'value': 'all_comments',
        'key': 'all_comments'
    },
    {
        'label': 'Comment by me',
        'value': 'my_comments',
        'key': 'my_comments'

    },
    {
        'label': 'My mentions',
        'value': 'mentions',
        'key': 'mentions'

    }
]

CollaborateConstants.access_levels = {
    'edit': 'Editor',
    'owner': 'Owner',
    'co-owner': 'Co-Owner'
}

CollaborateConstants.edit_access_levels = ['edit', 'owner', 'co-owner']

CollaborateConstants.ASPECT_RATIO = 1.78;
CollaborateConstants.ASPECT_RATIO_MULTIPLIER = 0.5625;

CollaborateConstants.ANNOTATE_WIDTH_PERC = 0.28;
CollaborateConstants.ANNOTATE_HEIGHT_PERC = 0.28;

CollaborateConstants.ANNOTATE_W_COMMENTS_HEIGHT_PERC = 0.48;
CollaborateConstants.ANNOTATE_RESOLVED_HEIGHT_PERC = 0.36;


CollaborateConstants.ANNOTATE_TOP_OFFSET = 30;
CollaborateConstants.ANNOTATE_LEFT_OFFSET = 25;
CollaborateConstants.SAFE_ZONE_HEIGHT_OFFSET = 150;

CollaborateConstants.COMMENT_SAFE_ZONE_WIDTH_OFFSET = 430;
CollaborateConstants.HEIGHT_OFFSET = 140;

CollaborateConstants.WIDTH_PERC_PRODUCT_QA = 0.38;
CollaborateConstants.HEIGHT_OFFSET_PRODUCT_QA = 260;
CollaborateConstants.SAFE_ZONE_HEIGHT_OFFSET_PRODUCT_QA = 270;
CollaborateConstants.HEIGHT_OFFSET_PRODUCT_QA_EDITOR = 195;
CollaborateConstants.SAFE_ZONE_HEIGHT_OFFSET_PRODUCT_QA_EDITOR = 215;


CollaborateConstants.THIN = 5;
CollaborateConstants.MEDIUM = 10;
CollaborateConstants.THICK = 20;
CollaborateConstants.MIN_ZOOM = 1;
CollaborateConstants.MAX_ZOOM = 5;
CollaborateConstants.ZOOM_FACTOR = 1.1;
CollaborateConstants.HOTSPOT_COLOR_RED = '#D93025';
CollaborateConstants.HOTSPOT_COLOR_GREEN = '#52C41A';
CollaborateConstants.HOTSPOT_COLOR_BLUE = '#008BFF';
CollaborateConstants.HOTSPOT_COLOR_WHITE = '#FFFFFF';


CollaborateConstants.HOTSPOT_COLOR = {
    "basic_resolved":
    {
        "fill": CollaborateConstants.HOTSPOT_COLOR_GREEN,
        "stroke": CollaborateConstants.HOTSPOT_COLOR_GREEN,
        "text": {
            "fill": CollaborateConstants.HOTSPOT_COLOR_WHITE
        }
    },
    "basic_unresolved": {
        "fill": CollaborateConstants.HOTSPOT_COLOR_WHITE,
        "stroke": CollaborateConstants.HOTSPOT_COLOR_RED,
        "text": {
            "fill": CollaborateConstants.HOTSPOT_COLOR_RED
        }
    },
    "blue_resolved": {
        "fill": CollaborateConstants.HOTSPOT_COLOR_BLUE,
        "stroke": CollaborateConstants.HOTSPOT_COLOR_BLUE,
        "text": {
            "fill": CollaborateConstants.HOTSPOT_COLOR_WHITE
        }
    },
    "blue_unresolved": {
        "fill": CollaborateConstants.HOTSPOT_COLOR_WHITE,
        "stroke": CollaborateConstants.HOTSPOT_COLOR_BLUE,
        "text": {
            "fill": CollaborateConstants.HOTSPOT_COLOR_BLUE
        }
    }
}


CollaborateConstants.IMAGE_HEIGHT = {
    "product-carousel-image-": {
        "height" : "calc(100vh - 140px)",
        "width" : "calc(100vh - 140px)"
    },
    "zoomed-image": {
        "height": "calc(100vh - 202px)",
        "width": "calc(100vh - 202px)"
    },
    "product-qa-image": {
        "height": "calc(100vh - 280px)",
        "width": "calc(100vh - 280px)"
    }
    
}



export default CollaborateConstants;