import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode'

import {
    CognitoState,
} from 'react-cognito';

function GetMainViewContentFromGroup(user, state, removeLoader, attributes) {
    var groups = ['Customer'];
    const token = localStorage.getItem('modified_super_admin_token')
    if (state === CognitoState.LOGGED_IN || state === CognitoState.LOGGING_IN) {
        if(token){
            const decoded = jwtDecode(token);
            if(decoded['cognito:groups'])
              groups = decoded['cognito:groups']
          }
          else{
            if ('cognito:groups' in user['signInUserSession']['idToken']['payload']) {
                groups = user['signInUserSession']['idToken']['payload']['cognito:groups'];
            }
          }
    }
    removeLoader();
    if (groups.indexOf('Admin') !== -1 || groups.indexOf('SuperAdmin') !== -1) {
        localStorage.setItem("is_msprovider",false)
        let redirectURL = "/admin";
        let redirectURLParam = new URLSearchParams(window.location.search).get("redirectURL");
        if(redirectURLParam != undefined){
            redirectURL = redirectURLParam;
        }

        if (attributes['custom:company_id'] != undefined) {
            if (attributes['custom:company_id'] != '0') {
                redirectURL = "/home";
            }
        }
        return (
            <Redirect to={redirectURL} />
        );
    }
    if (groups.indexOf('Artist') !== -1 || groups.indexOf('SuperArtist') !== -1 || groups.indexOf('AgencyAdmin') !== -1) {
        localStorage.setItem("is_msprovider",false)
        let redirectURL = "/artist_products";
        let redirectURLParam = new URLSearchParams(window.location.search).get("redirectURL");
        if(redirectURLParam != undefined){
            redirectURL = redirectURLParam;
        }
        
        return (
            <Redirect to={redirectURL} />
        );
    }

        if (groups.indexOf("TestArtist") !== -1) {
          localStorage.setItem("is_msprovider", false);
          let redirectURL = "/artist_test";
          let redirectURLParam = new URLSearchParams(
            window.location.search
          ).get("redirectURL");
          if (redirectURLParam != undefined) {
            redirectURL = redirectURLParam;
          }

          return <Redirect to={redirectURL} />;
        }

    if (groups.indexOf('Customer') !== -1 ||  groups.indexOf('MSProvider') !== -1) {
        
        if (groups.indexOf('MSProvider') !== -1) {
            localStorage.setItem("is_msprovider",true)
        } else {
            localStorage.setItem("is_msprovider",false)
        }
        let redirectURL = "/home";
        let redirectURLParam = new URLSearchParams(window.location.search).get("redirectURL");
        if(redirectURLParam != undefined){
            redirectURL = redirectURLParam;
        }
        return (
            <Redirect to={redirectURL} />
        );
    }


    return null;
}


function UserContent(props) {
    return (
        <h3>
            {GetMainViewContentFromGroup(props.user, props.state, props.removeLoader, props.attributes)}
        </h3>

    );
}

UserContent.propTypes = {
    user: PropTypes.object,
    attributes: PropTypes.object,
    state: PropTypes.string,
};

const mapStateToProps = state => ({
    state: state.cognito.state,
    user: state.cognito.user,
    attributes: state.cognito.attributes,
});


const UserMenuOptions = connect(mapStateToProps, null)(UserContent);

export default UserMenuOptions;