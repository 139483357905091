import React, {
  useState,
  useEffect,
  useMemo,
} from "react";
import { withRouter } from "react-router-dom";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { useQuery } from "react-query";
import {
  Row,
  Col,
  Tooltip,
} from "antd";
import { snakeToCamelWithSpaces } from "./utils";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { getBaseURL } from "../../../../environments/env";
import { platform } from "chart.js";

//style, tags, dimensions, style,  licensing_options, get dimensions from validation_report (check previous implemention), model type (only show if its a variation - will be a variation if variant_of key is not null)

const TextContainer = (props) => {
  return (

      <Col  span={11}>
        <div className="manrope f-14 lh-14 grey-8c text-left">
          {props.name}
        </div>
        <Tooltip title={<span className="manrope f-12 white">{props.value}</span>}>
          {props.link?<a className="manrope f-16 lh-16 clamp-w-width blue hover-change" href={props.link} >{props.value}</a> : <div className="manrope f-16 lh-16 black-26 clamp-w-width">
              {props.value}
            </div>}
        </Tooltip>
      </Col>

  );
};

const SpaceDetails = (props) => {
  const [countRoomDimensions, setCountRoomDimensions] = useState(-1);

  const roomDetailsFetcher = async (id) => {
    const data = await axios.post(ENVIRONMENT.FETCH_ROOM, { room_id: id });

    return { ...data?.data };
  };

  const platformFetcher = async (immediate_variant_id, variant_of_id ) => {

    const promises= [axios.post(ENVIRONMENT.FETCH_ROOM, {room_id: immediate_variant_id}),axios.post(ENVIRONMENT.FETCH_ROOM, {room_id: variant_of_id})]


    const results=await Promise.all(promises);

    const platforms= results.map((obj)=>obj?.data?.platform)

    return { immediate_parent_variant: platforms[0], variant_of: platforms[1] };
  };

  const {
    data: spaceDetailsData,
    error,
    isLoading: spaceDetailsLoading,
  } = useQuery(
    [
      `${ENVIRONMENT.FETCH_ROOM}/${props?.match?.params?.id}`,
      props?.match?.params?.id,
    ],
    () => roomDetailsFetcher(props?.match?.params?.id),
    {
      enabled: !!props?.match?.params?.id,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: platforms,
    error: platformsError,
    isLoading: platformsLoading,
  } = useQuery(
    [
      `${ENVIRONMENT.FETCH_ROOM}/${spaceDetailsData?.immediate_parent_variant}/${spaceDetailsData?.variant_of}`,
      props?.match?.params?.id,
    ],
    () => platformFetcher(spaceDetailsData?.immediate_parent_variant,spaceDetailsData?.variant_of),
    {
      enabled: !!spaceDetailsData,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(()=>{
    console.log('PLATFORMS: ',platforms);
  },[platforms])

  const spaceDetails = useMemo(() => {
    const spaceDetailsObj = {};
    spaceDetailsObj["style"] = spaceDetailsData?.style;
    spaceDetailsObj["tags"] =
      spaceDetailsData?.tags.length > 0
        ? spaceDetailsData?.tags?.join(", ")
        : null;
    spaceDetailsObj["license"] = spaceDetailsData?.license;

    if (spaceDetailsData?.validation_report?.High?.dimensions)
      spaceDetailsObj["total_dimensions"] = `${parseInt(
        spaceDetailsData?.validation_report?.High?.dimensions?.width
      )}"W x ${parseInt(
        spaceDetailsData?.validation_report?.High?.dimensions?.height
      )}"H x ${parseInt(
        spaceDetailsData?.validation_report?.High?.dimensions?.depth
      )}"D`;

    if (
      countRoomDimensions &&
      countRoomDimensions > 1 &&
      spaceDetailsData?.validation_report?.High?.room_dimensions
    )
      Object.keys(
        spaceDetailsData?.validation_report.High.room_dimensions
      ).forEach((ele, index) => {
        spaceDetailsObj[ele] = `${parseInt(
          spaceDetailsData?.validation_report.High.room_dimensions[ele].width
        )}"W x ${parseInt(
          spaceDetailsData?.validation_report.High.room_dimensions[ele].height
        )}"H x ${parseInt(
          spaceDetailsData?.validation_report.High.room_dimensions[ele].length
        )}"D`;
      });

    if (spaceDetailsData?.variant_of) {
      spaceDetailsObj["model_type"] = "Variation";
    }

   

    return spaceDetailsObj;
  }, [spaceDetailsData, countRoomDimensions]);

  const spaceLinks = useMemo(()=>{
    const spaceLinksObj={};

    if(spaceDetailsData?.immediate_parent_variant){
      spaceLinksObj["immediate_parent_variant"] = {title: spaceDetailsData?.immediate_parent_variant, link:  `${ENVIRONMENT.getBaseURL(platforms?.immediate_parent_variant)}${ENVIRONMENT.SPACE_LOW_POLY_URI}${spaceDetailsData?.immediate_parent_variant}.zip` }
    }

    if(spaceDetailsData?.variant_of){
      spaceLinksObj["base_variant"] = {title: spaceDetailsData?.variant_of, link: `${ENVIRONMENT.getBaseURL(platforms?.variant_of)}${ENVIRONMENT.SPACE_LOW_POLY_URI}${spaceDetailsData?.variant_of}.zip`}
    }

    return spaceLinksObj;

  },[spaceDetailsData,platforms])


  useEffect(() => {
    if (
      spaceDetailsData?.validation_report &&
      spaceDetailsData?.validation_report?.High &&
      spaceDetailsData?.validation_report.High?.room_dimensions &&
      Object.keys(spaceDetailsData?.validation_report?.High?.room_dimensions)
        .length > 0
    ) {
      setCountRoomDimensions(
        Object.keys(spaceDetailsData?.validation_report?.High?.room_dimensions)
          .length
      );
    }
  }, [props]);

  const isPublic = useMemo(
    () => spaceDetailsData?.is_store_item == 1,
    [spaceDetailsData?.is_store_item]
  );

  return (
    <div className="w-full">
      <Row className="basic-detail-container j-s-b">
        {spaceDetails &&
          Object.entries(spaceDetails).map(([key, value]) =>
            value ? (
              <TextContainer name={snakeToCamelWithSpaces(key)} value={value} />
            ) : (
              <></>
            )
          )}
        {spaceLinks &&
          Object.entries(spaceLinks).map(([key, value]) =>
            value ? (
              <TextContainer name={snakeToCamelWithSpaces(key)} value={value?.title} link={value?.link}/>
            ) : (
              <></>
            )
          )}
      </Row>
      <Row
        className="flex-row manrope f-14"
        style={{
          marginTop: "1.5rem",
          alignItems: "center",
          color: "#000000",
          background: "#F4F4F4",
          padding: "1rem",
          width: "fit-content",
        }}
      >
        {isPublic ? (
          <EyeOutlined
            style={{ height: "2rem", width: "2rem", display: "flex" }}
            className="flex-center"
          />
        ) : (
          <EyeInvisibleOutlined
            style={{ height: "2rem", width: "2rem", display: "flex" }}
            className="flex-center"
          />
        )}
        <span
          style={{ marginLeft: "0.5rem" }}
          className="manrope f-14 lh-14 black-33"
        >{`This model’s license is set to ${
          !isPublic ? "Private" : "Public"
        }.`}</span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => props.performStoreAction()}
          className="manrope f-14 lh-14 blue hover-change ml-4"
        >
          {`Change to ${isPublic ? "Private" : "Public"}`}
        </span>
      </Row>
    </div>
  );
};

export default withRouter(SpaceDetails);
