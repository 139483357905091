import React, { useState, useEffect } from 'react';
import ENVIRONMENT from '../../../environments'
import { getProductRender, listProducts, updateProduct, rejectProduct } from "../../../redux/actions";
import {
    ArrowRightOutlined,
    LineOutlined,
    LoadingOutlined, RightOutlined
} from '@ant-design/icons';
import {
    Row,
    Button,
    Popover,
    Layout,
    Steps,
    message,
    Tooltip
} from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import AWS from "aws-sdk";
import Inspector from '../../../Inspector';
import CameraHeightWidget from '../CustomerComponents/CameraHeightWidget';
import _ from "lodash" // Import the entire lodash library
import * as Utilities from '../Utilities';
import SiloPhotographyModals from './SiloPhotographyModals';
import PaymentFailureModal from '../PaymentFailureModal/PaymentFailureModal';
import { WHITE_BG_DROPSHADOW_ON, BLIND_MOUNT_RIG, BUCKET_NAME, SILO_BASE_URL, SILO_CONFIG_JSON } from '../../../environments/env';
import CustomHeader from '../CustomHeader/CustomHeader';
import SiloSetAngles from './SiloSetAngles';
import SiloSubmitRequest from './SiloSubmitRequest';
import SiloLighting from './SiloLighting';
import FileConstants from '../../../FileConstants';

const { Footer } = Layout;
const { Header } = Layout;
const { Step } = Steps;

const ratioFromAspect = {
    1.91 : '1.91:1',
    1.6 : '16:10',
    1.7777777777777777 : '16:9',
    0.5235602094240838 : '1:1.91',
    0.5625 : '9:16',
    0.625 : '10:16',
    1 : '10:10'
}

const defaultResolutions = [
    '1024x576','2048x1152','3072x1728','4096x2304',
    '1024x640','2048x1280','3072x1920','4096x2560',
    '1024x5736','2048x1072','3072x1608','4096x2145',
    '576x1024','1152x2048','1728x3072','2304x4096',
    '640x1024','1280x2048','1920x3072','2560x4096',
    '5736x1024','1072x2048','1608x3072','2145x4096',
    '1024x1024','2048x2048','3072x3072','4096x4096'
]

const defaultCameraNames = [
    'Front',
    'Front 3/4',
    'Side',
    'Back',
    'Back 3/4',
    'Top'
]

var inspector = null;

const SiloTool = (props) => {
    const { match: { params } } = props;
    const { history } = props;
    const { bulkFlowData } = props?.location?.state || {};
    const [step, setStep] = useState(0)
    const [productID, setProductID] = useState('');
    const [productInfo, setProductInfo] = useState({});
    const [modelLoaded, setModelLoaded] = useState(false);
    const [selectedAspectRatio, setSelectedAspectRatio] = useState('10:10');
    const [snapshotModal, showSnapshotModal] = useState(false);
    const [modalShot, setModalShot] = useState('');
    const [imageHeight, setImageHeight] = useState(1);
    const [imageWidth, setImageWidth] = useState(1);
    const [imageResArr, setImageResArr] = useState([]);
    const [perspective, setPerspective] = useState(0);
    const [successModal, setSuccessModal] = useState(false);
    const [customWRes, setCustomWRes] = useState(1);
    const [customHRes, setCustomHRes] = useState(1);
    const [camera, setCamera] = useState([]);
    const [cameraIndex,setCameraIndex] = useState(1);
    const [imageFormat,setImageFormat] = useState('jpg');
    const [dpi,setDpi] = useState('300');
    const [fovSwitch, setFovSwitch] = useState('fov');
    const [resolution, setResolution] = useState('1024x640');
    const [resolutionStatus,setResolutionStatus] = useState(3);
    const [editResFlag, setEditResFlag] = useState(false);
    const [orientation, setOrientation] = useState('Landscape');
    const [perspectiveLabels, setPerspectiveLabels] = useState([]);
    const [perspectiveImages, setPerspectiveImages] = useState([]);
    const [loadingProgress,setLoadingProgress] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [panToggle, setPanToggle] = useState(false);
    const [cameraSelected, setCameraSelected] = useState(false);
    const [cameraAngle, setCameraAngles] = useState(false);
    const [siloRequestLoader, setSiloRequestLoader] = useState(false);
    const [sliderPos,setSliderPos] = useState(300);
    const [orientationClicked, setOrientationClicked] = useState(true);
    const [aspectClicked,setAspectClicked] = useState(false);
    const [settingDone,setSettingDone] = useState(false);
    const [resetConfiguration, setResetConfiguration] = useState({});
    const [aspectRatioModal, setAspectRatioModal] = useState(false);
    const [selectedCameraName, setSelectedCameraName] = useState('');
    const [background, setBackground] = useState(1);
    const [shadows, setShadows] = useState(false);
    const [lightRig, setLightRig] = useState(FileConstants.lightRigUrl['white-bg-shadow-off']);
    const [defaultChecked, setDefaultChecked] = useState(true);
    const [defaultLightRigCase, setDefaultLightRigCase] = useState('');
    const [popoverDefault, setPopoverDefault] = useState(false);
    const [goBackConfirmModal, setGoBackConfirmModal] = useState(false);
    const [fovValue, setFovValue] = useState(20);
    const [fovRange, setFovRange] = useState({'min': 5, 'max': 80})
    const [focalLengthRange, setFocalLengthRange] = useState({'min': 10, 'max': 120})
    const [focalLengthValue, setFocalLengthValue] = useState(100);
    const [gridState, setGridState] = useState(false);
    const [perspectiveCount, setPerspectiveCount] = useState(0);
    const [defaultSettingsState, setDefaultSettingsState] = useState(false);
    const [defaultSilosModal, showDefaultSilosModal] = useState(false);
    const [configurationData, setConfigurationData] = useState(null);
    const [modelType, setModelType] = useState(null);
    const [confirmRequestState, setConfirmRequestState] = useState(false);
    const [swatchRenderExist, setSwatchRenderExist] = useState(false);
    const [blindImages, setBlindImages] = useState([])
    const [blindPerspectives, setBlindPerspectives] = useState([0])
    const insideMount = "Inside Mount";
    const outsideMount = "Outside Mount";
    const blindMount = "Mount";
    const [requestBlindRenders, setRequestBlindRenders] = useState(false);
    const [blindRendersState, setBlindRendersState] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [overlaySettingsModal, setOverlaySettingsModal] = useState(false);
    const [tempSnapshotOverlay, setTempSnapshotOverlay] = useState('');
    const [overlaySwitch, setOverlaySwitch] = useState(true);
    const [overlayOpacity, setOverlayOpacity] = useState(50);
    const [overlayColor, setOverlayColor] = useState('#500000');
    const [overlayHeight, setOverlayHeight] = useState(100);
    const [overlayWidth, setOverlayWidth] = useState(100);
    const [overlaySizeUnit, setOverlaySizeUnit] = useState('pixels');
    const [overlayLimit, setOverlayLimit] = useState(200);
    const [defaultOverlaySettings, setDefaultOverlaySettings] = useState(true);
    const [dropShadows, setDropShadows] = useState(false);
    const [enableDefaultShadows, setEnableDefaultShadows] = useState(false);
    const [dropShadowShot, setDropShadowsShot] = useState('')
    const [regularShadowShot, setRegularShadowsShot] = useState('')
    const [isFloorItem, setIsFloorItem] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [cardPaymentFailure, setCardPaymentFailure] = useState(false);
    const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
    const [siloPayload, setSiloPayload] = useState(null);
    const [paymentRequestID, setPaymentRequestID] = useState(-1);
    const [requestType, setRequestType] = useState('product_silo');
    const [prices, setPrices] = useState([]);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [subscriptionLoader, setSubscriptionLoader] = useState(false);
    const [currentPackage, setCurrentPackage] = useState('');
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [confirmRequestSilo, setConfirmRequestSilo] = useState(false);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [defaultLoader, setDefaultLoader] = useState(false)
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [takeSiloShotModal, setTakeSiloShotModal] = useState(false);
    const [placementType, setPlacementType] = useState('');
    const [savedSiloDefaults, setSavedSiloDefaults] = useState(undefined);
    const [siloToolBuilt, setSiloToolBuilt] = useState (false);
    const [selectedLight, setSelectedLight] = useState ("default");
    const [loadingProgressSnapshot, setLoadingProgressSnapshot] = useState(false);
    const [lightingData, setLightingData] = useState({});
    const [presetName, setPresetName] = useState("");
    const [hexColor, setHexColor] = useState("#FFFFFF");
    const [backgroundColor, setBackgroundColor] = useState(0);
    const [allBackgroundColors, setAllBackgroundColors] = useState(['#FFFFFF', '#AAAAAA']);
    const [productData, setProductData] = useState({});
    const [cameraDisplayName, setCameraDisplayName]= useState('');
    const [selectedCameras, setSelectedCameras] = useState([]);
    const [presetModal, setPresetModal] = useState(false);
    const [cameraStartingIndex, setCameraStartingIndex] = useState(0);
    const [selectedCamera, setSelectedCamera] = useState(0);
    const [presetsFlow, setPresetsFlow] = useState(false);
    const [allPresets, setAllPresets] = useState([]);
    const [selectedPreset, setSelectedPreset] = useState(-1);
    const [openSiloPresetModal, setOpenSiloPresetModal] = useState(false);
    const [loadedProgress, setLoadedProgress] = useState(0);
    const [presetID, setPresetID] = useState(-1);
    const [lightTemperature, setLightTemperature] = useState(5000);
    const [lightTemperatureUpdate, setLightTemperatureUpdate] = useState(false);
    const [platform, setPlatform] = useState('aws');
    const [thumbnailSignedUrl, setThumbnailSignedUrl] = useState('');
    const [editCamera, setEditCamera] = useState(false);
    const [greyMat, setGreyMat] = useState(false);
    const [defaultShotsTaken, setDefaultShotsTaken] = useState(false);
    const [camerasLightingSelected, setCamerasLightingSelected] = useState([]);
    const [lightingModal, setLightingModal] = useState(false)
    const [savePresetLoader, setSavePresetLoader] = useState(false);
    const [customLightingModal, setCustomLightingModal] = useState(false);
    const [customLightingRigName, setCustomLightingRigName] = useState('');
    const [customLightingRigComment, setCustomLightingRigComment] = useState('');
    const [customLightingModalStep, setCustomLightingModalStep] = useState(0);
    const [customLightingImages, setCustomLightingImages] = useState([]);
    const [allCustomLightingRigs, setAllCustomLightingRigs] = useState([]);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [shadowsRig, setShadowsRig] = useState(false);
    const [defaultRigLightPositions, setDefaultRigLightPositions] = useState(undefined);
    const [defaultShadowRigLightPositions, setDefaultShadowRigLightPositions] = useState(undefined);


    useEffect(()=> {
        if (inspector != null) {
            for (let i = 0; i < perspectiveCount ; i++) {
                setPerspective(i);
                inspector.changePerspectives(i,setPerspectiveButton);
            }
            inspector.enableAxes();
            if (getCookie("silo-help")) {
                setIsTourOpen(false);
            }
            else {
                // to be set true when the tour is implemented
                // setIsTourOpen(true);
            }
        }
        window.addEventListener("resize", SetOverlayLines);
    },[perspectiveCount]);


    useEffect(() => {
        if (perspectiveImages.length !== 0){
            captureDefaultSilos();
            setDefaultShotsTaken(true);
        }
    },[perspectiveImages])

    useEffect(()=>{
        if(overlayHeight > overlayLimit){
            setOverlayHeight(overlayLimit)
        }
        if(overlayWidth > overlayLimit){
            setOverlayWidth(overlayLimit)
        }
        SetOverlayLines();
    })
    
    useEffect(() => {
        setCameraDisplayName("camera_"+cameraIndex)
    },[cameraIndex])

    const identifyOrientation = (aspectRatio) => {
        if (aspectRatio == "16:10" || aspectRatio == "16:9" || aspectRatio == "1.91:1") {
            return "Landscape";
        }
        else if (aspectRatio == "9:16" || aspectRatio == "10:16" || aspectRatio == "1:1.91") {
            return "Portrait";
        }
        else if (aspectRatio == "10:10") {
            return "Square";
        }
        return "Custom";

    }
    
    async function loadLightingData () {
        const util = require('util')
        const request = require("request");
        const requestPromise = util.promisify(request);
        const response = await requestPromise(FileConstants.SILO_LIGHTING_CONFIG_JSON);
        setLightingData(JSON.parse(response.body));
    }
    
    async function loadLightingRigsData() {
        let payload = {
            'customer_username' : localStorage.getItem('username'),
            'action' : 'get_lighting_rigs'
        }
        if (localStorage.getItem('company_id')) {
            payload['company_id'] = localStorage.getItem('company_id')
        }
        axios.post(ENVIRONMENT.CUSTOMER_LIGHT_RIG_CONTROLLER, payload)
        .then((res) => {
            setAllCustomLightingRigs(res.data)
        })
    }

    const showTempOverlayLines = () => {
        if(overlaySwitch){
            var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
            for (var i = 0 ; i < overlay_lines.length ; i++){
                overlay_lines[i].style.display = "inherit";
            }
        }
    }

    const setOverlayLimitFunc = (value = 'default') => {
        if(value=='default'){
            if(overlaySizeUnit == 'pixels'){
                var image_width =  (resolution.split('x')[0]);
                var image_height = (resolution.substring(resolution.indexOf('x') + 1));
                setOverlayLimit(Math.min(image_width/2,image_height/2))
            }
            else{
                setOverlayLimit(100)
            }
        }
        else{
            if(value == 'pixels'){
                var image_width =  (resolution.split('x')[0]);
                var image_height = (resolution.substring(resolution.indexOf('x') + 1));
                setOverlayLimit(Math.min(image_width/2,image_height/2))
            }
            else{
                setOverlayLimit(100)
            }
        }
    }

    const SetOverlayLines = () => {
        try{
            var canvas_image = document.getElementById('silo-inspector').getElementsByTagName('canvas')[0]
            if(overlayHeight > overlayLimit){
                setOverlayHeight(overlayLimit)
            }
            if(overlayWidth > overlayLimit){
                setOverlayWidth(overlayLimit)
            }

            if(canvas_image && overlaySwitch){

                var image_width =  (resolution.split('x')[0]);
                var image_height = (resolution.substring(resolution.indexOf('x') + 1));
                //Setting top and bottom of top bottom overlay
                document.getElementById('overlay-blur-top').style.top = canvas_image.offsetTop +'px'
                document.getElementById('overlay-blur-top').style.left = canvas_image.offsetLeft +'px'
                document.getElementById('overlay-blur-bottom').style.left = canvas_image.offsetLeft +'px'

                //setting width of top and bottom
                document.getElementById('overlay-blur-top').style.width = canvas_image.getBoundingClientRect().width + "px"
                document.getElementById('overlay-blur-bottom').style.width = canvas_image.getBoundingClientRect().width  + "px"

                //Setting left and right of left right overlay
                document.getElementById('overlay-blur-left').style.left = canvas_image.offsetLeft +'px'
                document.getElementById('overlay-blur-right').style.right = canvas_image.offsetLeft +'px'


                //handling case for in case of pixels
                if (overlaySizeUnit == 'pixels'){
                    //for lines on top and bottom we just set the height
                    document.getElementById("overlay-blur-top").style.height = (canvas_image.getBoundingClientRect().height/image_height*  overlayHeight) + "px";
                    document.getElementById("overlay-blur-bottom").style.height = (canvas_image.getBoundingClientRect().height/image_height* overlayHeight) + "px";

                    var height_of_top = 2* document.getElementById("overlay-blur-top").getBoundingClientRect().height;

                    //for lines on left and right, first we adjust their position and height, according to the lines on top and bottom
                    document.getElementById("overlay-blur-left").style.height = (canvas_image.getBoundingClientRect().height - height_of_top) + "px"
                    document.getElementById("overlay-blur-right").style.height = (canvas_image.getBoundingClientRect().height - height_of_top) + "px"
                    document.getElementById("overlay-blur-left").style.top = (canvas_image.getBoundingClientRect().height/image_height*  overlayHeight) + "px";
                    document.getElementById("overlay-blur-right").style.top = (canvas_image.getBoundingClientRect().height/image_height*  overlayHeight) + "px";

                    //then we adjust their width
                    document.getElementById("overlay-blur-left").style.width = (canvas_image.getBoundingClientRect().width/image_width* overlayWidth) + "px"
                    document.getElementById("overlay-blur-right").style.width = (canvas_image.getBoundingClientRect().width/image_width* overlayWidth) + "px"
                }
                else{
                    //for lines on top and bottom we just set the height
                    document.getElementById("overlay-blur-top").style.height = canvas_image.getBoundingClientRect().height * overlayHeight /200 + "px";
                    document.getElementById("overlay-blur-bottom").style.height = canvas_image.getBoundingClientRect().height * overlayHeight /200 + "px";

                    var height_factor = canvas_image.getBoundingClientRect().height * overlayHeight /200
                    //for lines on left and right, first we adjust their position and height, according to the lines on top and bottom
                    document.getElementById("overlay-blur-left").style.height = canvas_image.getBoundingClientRect().height-(2*height_factor) +"px"
                    document.getElementById("overlay-blur-left").style.top = document.getElementById('overlay-blur-top').getBoundingClientRect().height + "px"
                    document.getElementById("overlay-blur-right").style.height = canvas_image.getBoundingClientRect().height-(2*height_factor) +"px"
                    document.getElementById("overlay-blur-right").style.top = document.getElementById('overlay-blur-top').getBoundingClientRect().height + "px"

                    //then we adjust their width
                    document.getElementById("overlay-blur-left").style.width =  canvas_image.getBoundingClientRect().width * overlayWidth /200 + "px";
                    document.getElementById("overlay-blur-right").style.width =  canvas_image.getBoundingClientRect().width * overlayWidth /200 + "px";
                }
                document.getElementById('overlay-blur-bottom').style.top = canvas_image.offsetTop + canvas_image.getBoundingClientRect().height - document.getElementById("overlay-blur-top").getBoundingClientRect().height +'px'
            }

        }
        catch{
            console.log("Window resize error")
        }
    }

    const setTempOverlayLines = () => {
        if (defaultOverlaySettings) {
            applyOverlaySwitch(true)
            applyOverlayWidth(overlayWidth);
            applyOverlayHeight(overlayHeight);
            applyOverlayColor(overlayColor)
            setDefaultOverlaySettings(false)
        }
        if(document.getElementById('overlay-temp-top')){
            //DEFINING SOME VARIABLES TO BE USED
            var height_of_top_bottom = document.getElementById('overlay-temp-top').getBoundingClientRect().height;
            var bottom_offset =  document.getElementsByClassName('img-container')[0].getBoundingClientRect().height - document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().height
            var right_offset = document.getElementsByClassName('img-container')[0].getBoundingClientRect().width - document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().width

            //setting width
            document.getElementById('overlay-temp-top').style.width = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width + "px";
            document.getElementById('overlay-temp-bottom').style.width = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width + "px";

            //setting height
            document.getElementById('overlay-temp-right').style.height = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height - (2*height_of_top_bottom) + "px"
            document.getElementById('overlay-temp-left').style.height = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height - (2*height_of_top_bottom) + "px"

            //setting top
            document.getElementById('overlay-temp-right').style.top = height_of_top_bottom + "px"
            document.getElementById('overlay-temp-left').style.top = height_of_top_bottom + "px"

            //setting bottom and right
            document.getElementById('overlay-temp-bottom').style.bottom = bottom_offset +"px"
            document.getElementById('overlay-temp-right').style.right = right_offset +"px"
        }
    }

    const OpenOverlaySettingsModal = () => {
        var img_data = inspector.getSceneRender().domElement.toDataURL();
        setOverlaySettingsModal(true);
        setTempSnapshotOverlay(img_data);
        showTempOverlayLines();
        setOverlayLimitFunc();
    }

    const handleApplyOverlaySettings = (e) => {
        SetOverlayLines();

        var overlay_lines = document.getElementsByClassName("overlay-blur");
        if(overlaySwitch == true){
            for (var i = 0 ; i < overlay_lines.length ; i++) {
                overlay_lines[i].style.display = 'inherit';
                overlay_lines[i].style.opacity = 1 - overlayOpacity/100;
                overlay_lines[i].style.background = overlayColor;
            }

        }
        else{
            for (var i = 0 ; i < overlay_lines.length ; i++) {
                overlay_lines[i].style.display = 'none'
            }
        }
        setOverlaySettingsModal(false)
    }

    const onChangeOverlayOpacity = (value) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            overlay_lines[i].style.opacity = 1 - value/100;
        }
        setOverlayOpacity(value)

    }

    const applyOverlayColor = (value) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            overlay_lines[i].style.background = value;
        }
    }

    const onChangeOverlayColor = (value) => {
        applyOverlayColor(value);
        setOverlayColor(value)
    }

    const applyOverlayHeight = (value) => {
        if(overlaySizeUnit == 'pixels'){
            var canvas_image = document.getElementById('silo-inspector').getElementsByTagName('canvas')[0]
            var image_height = (resolution.substring(resolution.indexOf('x') + 1));
            var factor_height_pixels = document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().height / document.getElementById('silo-inspector').getBoundingClientRect().height ;

            //changing the height of lines on top and bottom
            //changing the height of lines on top and bottom
            document.getElementById("overlay-temp-top").style.height = factor_height_pixels*canvas_image.getBoundingClientRect().height/image_height*value +"px";
            document.getElementById("overlay-temp-bottom").style.height = factor_height_pixels*canvas_image.getBoundingClientRect().height/image_height*value +"px";
        }
        else{
            //calculating the height of top,bottom lines
            var height_of_snapshot = parseInt(document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height*value/100);

            //for lines on top and bottom we set the height
            document.getElementById("overlay-temp-top").style.height = height_of_snapshot/2 +"px";
            document.getElementById("overlay-temp-bottom").style.height = height_of_snapshot/2 +"px";
        }
    }

    const onChangeOverlayHeight = (value) => {
        if(value<overlayLimit){
            applyOverlayHeight(value);
            setTempOverlayLines();
            setOverlayHeight(value)
        }
    }

    const onChangeOverlayUnit = (e) => {
        setOverlaySizeUnit (e.target.value)
        setOverlayLimitFunc(e.target.value)
        if(overlayWidth>100)
            setOverlayWidth(100)
        if(overlayHeight>100)
            setOverlayHeight(100)
    }

    const applyOverlayWidth = (value) => {
        if(overlaySizeUnit == 'pixels'){
            var canvas_image = document.getElementById('silo-inspector').getElementsByTagName('canvas')[0]
            var image_width =  (resolution.split('x')[0]);
            var factor_width_pixels =  document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().width / document.getElementById('silo-inspector').getBoundingClientRect().width ;

            //changing the height of lines on top and bottom
            document.getElementById("overlay-temp-right").style.width = factor_width_pixels*canvas_image.getBoundingClientRect().width/image_width*value +"px";
            document.getElementById("overlay-temp-left").style.width = factor_width_pixels*canvas_image.getBoundingClientRect().width/image_width*value +"px";
        }
        else{
            //calculating the height of left,right lines
            var width_of_snapshot = parseInt(document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width*value/100);
            //for lines on left and right we just set the width
            document.getElementById("overlay-temp-right").style.width = width_of_snapshot/2 +"px";
            document.getElementById("overlay-temp-left").style.width = width_of_snapshot/2 +"px";
        }
    }

    const onChangeOverlayWidth = (value) => {
        if(value<overlayLimit){
            applyOverlayWidth(value)
            setTempOverlayLines();
            setOverlayWidth(value)
        }
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'save_card') {
              message.success('Payment Method Updated Successfully.');
              snapshotTaken();
            }
        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
        if(child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);
        }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    const defaultRequested = () => {
        let silo_cameras = inspector.getSilosInformation()
        silo_cameras = silo_cameras.silo_data
        let categories = []
        silo_cameras.map((cam)=> {
            if (cam.image_status == 'pending') {
                categories.push( returnPrice((cam.image_data.img_resolution),'category' ))
            }
        })
        setSelectedCategories(categories);
        loadUserRequestStatus(categories,null,true)
    }

    const applyOverlaySwitch = (checked) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            if(checked)
                overlay_lines[i].style.display = "inherit";
            else
            overlay_lines[i].style.display = "none";
        }
    }

    const setShowOverlaySwitch = (checked) => {
        applyOverlaySwitch(checked)
        setOverlaySwitch(checked);
        setTempOverlayLines()
    }

    const handleDiscardOverlaySettings = () =>{
        setOverlaySettingsModal(false)
    }
    
    const onAfterChangeTemperature = (value) => {
        inspector.setLightTemperature(value)
        setLightTemperature(value)
        setLightTemperatureUpdate(!lightTemperatureUpdate)
    }
    
    const readCameraConfigurationData = () => {
        if (configurationData == null) {
            return;
        }
        let defaultOrientation = null;
        if (configurationData[modelType]["aspect_ratio"]) {
            let aspectRatio = selectedAspectRatio;
            if (typeof configurationData[modelType]["aspect_ratio"] === 'string' || configurationData[modelType]["aspect_ratio"] instanceof String) {
                aspectRatio = parseFloat(configurationData[modelType]["aspect_ratio"].split(':')[0]) / parseFloat(configurationData[modelType]["aspect_ratio"].substring(configurationData[modelType]["aspect_ratio"].indexOf(':') + 1));
            }
            defaultOrientation = identifyOrientation(configurationData[modelType]["aspect_ratio"]);
            selectOrientation(defaultOrientation);
            selectAspectRatio(configurationData[modelType]["aspect_ratio"],defaultOrientation);
            inspector.changeOrientation(configurationData[modelType]["aspect_ratio"], "Custom");
            setSettingDone(true);
        }
        if (configurationData[modelType]["resolution"]) {
            let width = 0;
            let height = 0;
            if (typeof configurationData[modelType]["resolution"] === 'string' || configurationData[modelType]["resolution"] instanceof String) {
                width = configurationData[modelType]["resolution"].split('x')[0];
                height = configurationData[modelType]["resolution"].substring(configurationData[modelType]["resolution"].indexOf('x') + 1);
            }
            setCustomWRes(parseFloat(width));
            setCustomHRes(parseFloat(height));
            setImageWidth(parseFloat(width));
            setImageHeight(parseFloat(height));
            setResolutionStatus(returnResolutionStatus(configurationData[modelType]["resolution"]));
            setResolution(configurationData[modelType]["resolution"]);
        }
        if (productData.rig_file != undefined && productData.rig_file != ''){
            setLightRig(productData.rig_file);
        }
        else if (configurationData[modelType]["default_rig"]) {
            setLightRig(FileConstants.lightRigUrl[defaultLightRigCase]);
            setDefaultChecked(true);
        }
        if (configurationData[modelType]["dropShadows"]!=undefined) {
            setDropShadows(configurationData[modelType]["dropShadows"]);
            if ( configurationData[modelType]["dropShadows"] ) {
                setLightRig(FileConstants.lightRigUrl["white-bg-dropShadow-on"]);        
            }
            setDefaultChecked(false);
        }
        else if (configurationData[modelType]["shadows"]!=undefined) {
            setShadows(configurationData[modelType]["shadows"]);
            setDefaultChecked(false);
        }
        if (configurationData[modelType]["background"]) {
            setDefaultBackground(configurationData[modelType]["background"]);
            setDefaultChecked(false);
        }
        if (configurationData[modelType]["horizontal_fov"]!=undefined) {
            setFovValue(configurationData[modelType]["horizontal_fov"]);
            inspector.setHorizontalFOV(configurationData[modelType]["horizontal_fov"]);
            inspector.setDefaultHorizontalFOV(configurationData[modelType]["horizontal_fov"]);
            setFocalLengthValue(inspector.getCameraFocalLength())
        }
        if (configurationData[modelType]["focal_length"]) {
            setFocalLengthValue(configurationData[modelType]["focal_length"])
            inspector.setFocalLength(configurationData[modelType]["focal_length"])
            let horizontalFOV = inspector.getHorizontalFOV()
            setFovValue(horizontalFOV);
            inspector.setDefaultHorizontalFOV(horizontalFOV);
        }
        if (configurationData[modelType]["dpi"]) {
            setDpi(configurationData[modelType]["dpi"]);
        }

    }

    const setDefaultBackground = (value) => {
        if (value == "white" ) {
            setBackground(1);
            return 1;
        }
        else if (value == "grey" ) {
            setBackground(2);
            return 2;
        }
        else {
            setDefaultChecked(true);
            return 0;
        }
    }

    const resetPaymentsOnCancel = () => {
        setSelectedCategories([]);
    }
    
    const returnResolutionStatus = (res) => {
        let width = parseInt(res.split('x')[0]);
        let height = parseInt(res.split('x')[1]);
            if (Utilities.nearestThousand(height) == 4000 || Utilities.nearestThousand(width) == 4000 || height >= 4000 || width >= 4000) {
                return 3
            } else if(Utilities.nearestThousand(height) == 3000 || Utilities.nearestThousand(width) == 3000) {
                return 2
            } else if (Utilities.nearestThousand(height) == 2000 || Utilities.nearestThousand(width) == 2000) {
                return 1
            } else {
                return 0
            }
    }
    
    const inspectorChangePerspective = (name, index) => {
        if (inspector == undefined)
            return
        setPerspective(index)
        let silo_data = inspector.getSilosInformation();
        let value = 0;
        for (let i in silo_data['silo_data']){
            if (silo_data['silo_data'][i]['camera_name'] == name){
                value = i;
            }
        }
        setSelectedCamera(value);
        inspector.SetupLighting(silo_data['silo_data'][value]['image_data']['lighting'], lightingData)
        if (silo_data['silo_data'][value]['image_data']['img_dpi'] != undefined){
            setDpi(String(silo_data['silo_data'][value]['image_data']['img_dpi']));            
        }
        else {
            setDpi('300')
        }
        let aspect_rat = silo_data['silo_data'][value]['image_data']['aspect_ratio'];
        let ratio = '';
        let res = silo_data['silo_data'][value]['image_data']['img_resolution'];
        if( aspect_rat != undefined ) {
            ratio = getRatioFromAspect(aspect_rat)
        }
        else {
            let imageWidthTemp = parseInt(res.split('x')[0]);
            let imageHeightTemp = parseInt(res.split('x')[1]);
            ratio = getRatioFromAspect(imageWidthTemp/imageHeightTemp)
        }
        let orien = identifyOrientation(ratio);
        setOrientation(orien);
        changeAspectRatio(ratio, orien);
        inspector.changeOrientation(ratio,"Custom");
        let status_resolution = returnResolutionStatus(res);
        if ( defaultResolutions.includes(res) ) {
            setResolutionStatus(status_resolution);    
        }
        else {
            setResolutionStatus(0)
        }
        setImageFormat(silo_data['silo_data'][value]['image_data']['img_format']);
        inspector.changePerspective(name)
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        setResolution(res);
        inspector.enableAxes();
    }

    const captureDefaultSilos = () => {
        inspector.setImgDPI(dpi);
        inspector.setImgFormat(imageFormat);
        let categories = [];
        let cam_selected = [];
        if ( configurationData[modelType]["dropShadows"] ) {
            showShadows();
        }
        for (let i = 0; i < perspectiveCount; i++) {
            let thisImage = configurationData[modelType]['images'][i];
            let res =  thisImage['img_resolution'];
            inspector.setResolution(res);
            let imageWidthTemp = parseInt(res.split('x')[0]);
            let imageHeightTemp = parseInt(res.split('x')[1]);
            if (thisImage['img_dpi'] != undefined){
                inspector.setImgDPI(thisImage['img_dpi']);
            }
            if (thisImage['img_format'] != undefined){
                inspector.setImgFormat(thisImage['img_format']);
            }
            if (thisImage['horizontal_fov'] != undefined){
                inspector.setHorizontalFOV(thisImage['horizontal_fov']);
            }
            else {
                inspector.setHorizontalFOV(20);
            }
            if( thisImage['aspect_ratio'] != undefined ) {
                inspector.changeOrientation(parseInt(thisImage['aspect_ratio']),"Custom");
                inspector.configureCameraAndControls();
            }
            else {
                inspector.changeOrientation(getRatioFromAspect(imageWidthTemp/imageHeightTemp), "Custom");
                inspector.configureCameraAndControls();
                
            }
            // changePerspective(i);
            inspector.changePerspectives(i);
            let camera_name = takeSnapshotsForDefaults(i);
            categories.push(returnPrice(thisImage['img_resolution'], 'category'));
            if ( presetsFlow ) {
                cam_selected.push(camera_name)
            }
        }
        inspector.changePerspectives(0);
        if ( presetsFlow ) {
            setSelectedCameras(cam_selected)
        }
        // changePerspective(0);
        setSelectedCategories(categories);
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        setSavedSiloDefaults(inspector.getSilosInformation().silo_data);
        if ( presetsFlow && !bulkFlowData) {   
            setStep(2);
        }
        inspector.enableAxes();
        setLoadingProgress(false);
    }

    const takeSnapshotsForDefaults = (camera_index) => {
        if ( configurationData[modelType]['images'][camera_index]['category'] == "blind") {
            blindSnapshotTaken(configurationData[modelType]['images'][camera_index]['display_name'], true);
            return;
        }
        let index_of_camera = camera_index + cameraIndex;
        let resolution_value = configurationData[modelType]['images'][camera_index]['img_resolution'];
        inspector.disableAxes()
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        inspector.enableAxes()
        var camera_name = "camera_" + (index_of_camera);
        setCameraIndex(index_of_camera + 1);
        let camera_display_name = camera_name;
        if (modelType == 'system'){
            camera_display_name =  defaultCameraNames[camera_index];
        }
        else if (configurationData[modelType]['images'][camera_index]['camera_display_name'] != undefined){
            camera_display_name =  configurationData[modelType]['images'][camera_index]['camera_display_name'];
        }
        let image_DPI = inspector.getImageDPI();
        let image_Format = inspector.getImgFormat();
        let aspect = inspector.getAspect();
        if (configurationData[modelType]['images'][camera_index]['image_format'] != undefined){
            image_Format =  configurationData[modelType]['images'][camera_index]['image_format'];
        }
        if (configurationData[modelType]['images'][camera_index]['img_dpi'] != undefined){
            image_DPI =  configurationData[modelType]['images'][camera_index]['img_dpi'];
        }
        if (configurationData[modelType]['images'][camera_index]['aspect_ratio'] != undefined){
            aspect =  parseInt(configurationData[modelType]['images'][camera_index]['aspect_ratio']);
        }
        addCamera(camera_name,img_data,'',resolution_value, camera_display_name, image_DPI, image_Format);
        
        
                
        inspector.get3DMaxValues(resolution_value, camera_name, 'pending',lightRig,camera_display_name, aspect);
        if ( camera_index == 0 ) {
            setResolution(resolution_value);
            let status_resolution = returnResolutionStatus(resolution_value);
            if ( defaultResolutions.includes(resolution_value) ) {
                setResolutionStatus(status_resolution);    
                setImageResArr(inspector.setResolutions(resolution_value.split('x')[0], resolution_value.split('x')[1]))
            }
            else {
                setResolutionStatus(0)
            }
        }

        return camera_name
    }

    const leftPerspectiveScroll = () => {
        var elem = document.getElementById('default-silo-scrollable');
        var snapshotLength = document.getElementById('default-silo-snapshot');
        if (snapshotLength) {
            elem.scrollLeft -= snapshotLength.offsetWidth ;
        }

    }

    const rightPerspectiveScroll = () => {
        var elem = document.getElementById('default-silo-scrollable');
        var snapshotLength = document.getElementById('default-silo-snapshot');
        if (snapshotLength) {
            elem.scrollLeft += snapshotLength.offsetWidth ;
        }
    }

    const showShadows = () => {
        if (inspector != null) {
            inspector.showShadows();
            inspector.disableAxes();
            let img_data = inspector.getSceneRender().domElement.toDataURL();
            setModalShot(img_data);
            inspector.enableAxes();
        }
    }

    const hideShadows = () => {
        if (inspector != null ) {
            inspector.hideShadows();
            inspector.disableAxes();
            let img_data = inspector.getSceneRender().domElement.toDataURL();
            setModalShot(img_data);
            inspector.enableAxes();
        }
    }

    useEffect(() => {
        if (productData.category != undefined) {
          if (bulkFlowData) {
            setPresetName(bulkFlowData?.productName);
          }
          let presetIndex = props.location.search.split("preset=")[1];
          let payload = {'action':'get_presets'}
          if (presetIndex && bulkFlowData) {
            payload['preset_id'] = presetIndex
          }
          else {
            payload['username'] = localStorage.getItem('username')
          }

        axios.post(ENVIRONMENT.CUSTOMER_SILO_CONFIG, payload)
            .then(res => {
                let loadDefaultSettings = false;
                let presetsData = {}
                for (let i = 0; i< res.data.length; i++){
                  presetsData[res.data[i].id] = res.data[i]
                }
                let presets = [];
                for (let i = 0; i< res.data.length; i++){
                    if (res.data[i].settings[productData.category] != undefined){
                        presets.push(res.data[i]);
                    }
                }
                setAllPresets(presets);
                if ( Object.keys(presetsData).length > 0 && !isNaN(presetIndex) && presetsData[presetIndex]?.settings) {
                    setPresetID(presetIndex)
                    setPresetName(presetsData[presetIndex]['preset_name'])
                    console.log("presets info",presetsData[presetIndex])

                    let configData = presetsData[presetIndex].settings;
                    let modelType = null;
                    if (configData[productData.category] != undefined) {
                        modelType = productData.category
                        setModelType(productData.category);
                        setConfigurationData(configData);
                        if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                            setBlindPerspectives(2);
                            setRequestBlindRenders(true);
                        }
                    }
                    else if (configData["default"] != undefined) {
                        modelType = "default"
                        setModelType("default");
                        setConfigurationData(configData);
                        if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                            setBlindPerspectives(2);
                            setRequestBlindRenders(true);
                        }
                    }
                    else if (productData.category.toLowerCase().includes("shade") && configData["Shades"] != undefined) {
                        modelType = "Shades"
                        setModelType(modelType);
                        setConfigurationData(configData);
                        if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                            setBlindPerspectives(2);
                            setRequestBlindRenders(true);
                        }
                    }
                }
                else {
                    payload = {}
                    payload['customer_username'] = localStorage.getItem('username')
                    payload['action'] = 'get_default_settings'
                    axios.post(ENVIRONMENT.CUSTOMER_SILO_CONFIG, payload)
                    .then(res => {
                        if (Object.keys(res.data).length !== 0 ) {
                            let configData = res.data.data;
                            let modelType = null;
                            if (configData[productData.category] != undefined) {
                                modelType = productData.category
                                setModelType(productData.category);
                                setConfigurationData(configData);
                                if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                                    setBlindPerspectives(2);
                                    setRequestBlindRenders(true);
                                }
                            }
                            else if (configData["default"] != undefined) {
                                modelType = "default"
                                setModelType("default");
                                setConfigurationData(configData);
                                if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                                    setBlindPerspectives(2);
                                    setRequestBlindRenders(true);
                                }
                            }
                            else if (productData.category.toLowerCase().includes("shade") && configData["Shades"] != undefined) {
                                modelType = "Shades"
                                setModelType(modelType);
                                setConfigurationData(configData);
                                if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                                    setBlindPerspectives(2);
                                    setRequestBlindRenders(true);
                                }
                            }  
                        } 
                        else {
                            async function loadDataFromConfigFile(){
                                const util = require('util')
                                const request = require("request");
                                const requestPromise = util.promisify(request);
                                const response = await requestPromise(SILO_CONFIG_JSON);
                                let configData = JSON.parse(response.body);
                                let polarAngleLimits = configData["polar_angle_limits"];
                                delete configData["polar_angle_limits"];
                                let data = {};
                                data["system"] = configData;
                                data["system"]["resolution"] = resolution;
                                data["system"]["aspect_ratio"] = "10:10";
                                data["system"]["default_rig"] = true;
                                data["polar_angle_limits"] = polarAngleLimits;
                                setConfigurationData(data);
                                setModelType("system");
                            }
                            loadDataFromConfigFile();
                        }
                    })
                }
            })
        }

    },[productData.category, productData.customer_username]);

    useEffect(() => {
        if (configurationData != null && inspector != null && modelType != null && modelLoaded != false) {
            readCameraConfigurationData();
            inspector.addPerspectives(configurationData, modelType);
            changePerspective(0);
            setIsFloorItem(inspector.checkIsFloorItem());
            inspector.showShadows();
            inspector.disableAxes();
            setDropShadowsShot(inspector.getSceneRender().domElement.toDataURL());
            inspector.enableAxes();
            inspector.hideShadows();
            inspector.disableAxes();
            setRegularShadowsShot(inspector.getSceneRender().domElement.toDataURL());
            inspector.enableAxes();

            if (enableDefaultShadows) {
                inspector.showShadows();
            }

        }
    },[configurationData, modelType, modelLoaded]);


    useEffect(() => {
        if (!defaultSettingsState) {
            if (cameraIndex >= perspectiveCount - blindPerspectives) {
                setDefaultSettingsState(true)
            }
        }
    },[defaultSettingsState])

    useEffect(() => {

        if (successModal) {
            setCamera([]);
            setBlindImages([]);
            setConfirmRequestState(false);
            setBlindImages([]);
        }

    }, [successModal]);

    // ******************************** silo Presets ********************************

    useEffect(()=> {
        setProductID(params.id);
        loadUserRequestStatus(null,null);
        loadLightingData();
        loadLightingRigsData();
        let presetName = props.location.search.split("preset=")[1];
        if (presetName!=undefined && !bulkFlowData){
            setPresetsFlow(true);
        }
    },[]); 
    const loadUserRequestStatus = (request_category = null, confirm_request = null, default_silos = false) => {
        let payload = {
        "username": localStorage.getItem('username'),
        "action": "check",
        'request_type': 'product_silo',
        'categories': ['1k']
        }

        if (request_category != null) {
            payload.categories = request_category;
        }

        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then(res => {
            setRequestStatus([res.data['request_allowed']]);
            setCurrentPackage(res.data['subscription_plan']);
            setIsSubscriptionActive(res.data['is_subscription_active'])
            setCardStatus(res.data['card_status']);
            loadPayments(res.data['payment_type']);
            if (confirmRequestSilo != null) {
                setConfirmRequestSilo(true);
            }
            if (default_silos == true){
                setDefaultLoader(false)
                showDefaultSilosModal(false)
            }
        });
    }

    const loadPayments = (payment_type) => {
        let payload = {
            "request_type": 'product_silo',
            "payment_type": payment_type,
        }

        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
        .then(res => {
            if (res.data) {
                setPrices(res.data);
            }
        })
    }


    const setPerspectiveSrc = (imagesCount) => {
        setPerspectiveCount(imagesCount);
    }

    const getLightRig = (shadows, background, dropShadow = false) => {
        if (shadowsRig == true) {
            hideShadows();
            setLightRig(FileConstants.lightRigUrl["white-bg-shadow-on"]);
            return FileConstants.lightRigUrl["white-bg-shadow-on"];
        }
        if (dropShadow == true && background == 1) {
            showShadows();
            setLightRig(FileConstants.lightRigUrl["white-bg-dropShadow-on"])
            return FileConstants.lightRigUrl["white-bg-dropShadow-on"]
        }
        else if (productData.rig_file != undefined && productData.rig_file != '') {
            setLightRig(productData.rig_file) 
            return productData.rig_file               
        }
        else if (shadows == false && background == 1) {
            hideShadows();
            setLightRig(FileConstants.lightRigUrl["white-bg-shadow-off"]);
            return (FileConstants.lightRigUrl["white-bg-shadow-off"]);
        }
        else if (shadows == false && background == 2) {
            hideShadows();
            setLightRig(FileConstants.lightRigUrl["grey-bg-shadow-off"]);
            return FileConstants.lightRigUrl["grey-bg-shadow-off"]
        }
        else if (shadows == true && background == 1) {
            hideShadows();
            setLightRig(FileConstants.lightRigUrl["white-bg-shadow-on"]);
            return FileConstants.lightRigUrl["white-bg-shadow-on"];
        }
        else if (shadows == true && background == 2) {
            hideShadows();
            setLightRig(FileConstants.lightRigUrl["grey-bg-shadow-on"]);
            return FileConstants.lightRigUrl["grey-bg-shadow-on"]
        }
    }

    const changeLightRig = (value) => {
        if (value == "white-bg-shadow-off") {
            setShadows(false);
            setDropShadows(false);
            setBackground(1);
        }
        else if (value == "grey-bg-shadow-off") {
            setShadows(false);
            setDropShadows(false);
            setBackground(2);
        }
        else if (value == "white-bg-shadow-on") {
            setShadows(true);
            setDropShadows(false);
            setBackground(1);
        }
        else if (value == "grey-bg-shadow-on") {
            setShadows(true);
            setDropShadows(false);
            setBackground(2);
        }
        else if (value == "white-bg-dropShadow-on") {
            setDropShadows(true);
            setShadows(false);
            setBackground(1);
        }
        else {
            setDefaultChecked(true);
        }
    }

    const getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    const setCookie =(cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


    const setPerspectiveButton = (index) => {
        inspector.disableAxes();
        let  imgElement= inspector.sceneRenderer.domElement.toDataURL();
        setPerspectiveImages(oldArray => [...oldArray, imgElement]);
        setPerspectiveLabels(inspector.getViewNames());
        if (index == (perspectiveCount-1)) {
            changePerspective(0);
        }
    }

    useEffect(() => {
        var product_id = params.id;
        var placementType = null;
        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                let company_id = null;
                if(res.data['company_id'] != undefined) {
                    let current_company = localStorage.getItem('company_id');
                    if (current_company === res.data['company_id'].toString() || FileConstants.isMSProvider){
                        company_id = res.data['company_id'].toString();
                        setCompanyId(company_id)
                    }
                }
                if (res.data['placement_type'] != undefined) {
                    placementType = res.data['placement_type'];
                    setPlacementType(placementType)
                }
                loadProductAssets(company_id, placementType);
            });
    },[]);

    const loadProductAssets = (company_id, placementType) => {
        var product_id = params.id;

        let payload = { product_id: product_id, username: localStorage.getItem("username")};
        if (company_id != null) {
            payload.company_id = company_id;
        }
        if(document.getElementById('silo-inspector')!= undefined){
            buildSiloInspector(payload, placementType, true);
            axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
                .then(res => {
                    setProductData(res.data)
                })
        }
    };

    useEffect(() => {
        let defaultRigID = ENVIRONMENT.DEFAULT_RIG_ID;
        if (productData.rig_file == undefined || productData.rig_file == '') {
            setDefaultLightRigCase('white-bg-shadow-off');
            setLightRig(FileConstants.lightRigUrl['white-bg-shadow-off']);
        } else {
            setLightRig(productData.rig_file);
            defaultRigID = productData.rig_id;
        }
        axios.post(ENVIRONMENT.GET_RIG_LIGHT_POSITIONS, {"rig_id":defaultRigID})
            .then(res => {
                setDefaultRigLightPositions(res?.data?.lighting_setup);                
            })
        axios.post(ENVIRONMENT.GET_RIG_LIGHT_POSITIONS, {"rig_id":ENVIRONMENT.DEFAULT_SHADOW_RIG_ID})
            .then(res => {
                setDefaultShadowRigLightPositions(res?.data?.lighting_setup);                
            })
    },[productData]);

    const buildSiloInspector = (payload, placementType, setDefaultSilos = false) => {
        axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
            .then(res => {
                setProductInfo(res.data);
                console.log('&',res.data);
                let assetBaseURL = ENVIRONMENT.getBaseURL(res.data.platform) + ENVIRONMENT.ASSET_GLB_URI;
                setPlatform(res.data.platform)
                let initial_silo_data = res.data.silo_data;
                if (!setDefaultSilos){
                    initial_silo_data = savedSiloDefaults;
                }
                inspector = new Inspector(params.id,'silo-inspector', 0, initial_silo_data, placementType, setLoadedProgress, setPerspectiveSrc, setModelLoaded, setSliderPosition, assetBaseURL);
                if (inspector != null) {
                    changeOrientation('Landscape');
                    changeAspectRatio('10:10','Landscape');
                    inspector.setDefaultHorizontalFOV(fovValue);
                    inspector.changeOrientation('10:10','Custom');
                    setCameraStartIndex(initial_silo_data);
                    setLoadingProgressSnapshot(false);
                }
            });
    }

    const setSliderPosition = (value, flag = 1) => {
        if (inspector != null) {
            let sliderLimit = 200;
            setSliderPos(value);
            let arrowHandler = document.querySelector("#slider-silo");
            if (arrowHandler != null) {
                arrowHandler.style.transform = "translateY("+((value - sliderLimit))+"px)";
                if (flag == 1){
                    setSliderPos(sliderLimit - value);
                    inspector.setCameraHeight((sliderLimit - value)/900);
                    let arrowHandler = document.querySelector("#slider-silo");
                    arrowHandler.style.transform = "translateY("+((0 - value))+"px)";
                }
            }

        }
    }

    const handleVisibleChange = visible => {
        setPopoverDefault(visible);
    };

    const setCameraStartIndex = (initial_silo_data) => {
        let camera_index = 0;
        if(initial_silo_data != undefined) {
            (initial_silo_data).forEach( (silo_image_datas) => {
                if (silo_image_datas.camera_name.includes("swatch")) {
                    setSwatchRenderExist(true);
                }
                else if (!silo_image_datas.camera_name.includes("Mount")){
                    let cameraToks = silo_image_datas.camera_name.split("_");
                    if(cameraToks[1] != undefined){
                        camera_index = parseInt(cameraToks[1])
                    }
                }
            } );
        }
        setCameraStartingIndex(camera_index+1)
        setCameraIndex(camera_index+1)
    }


    useEffect(()=> {
        if (inspector != null) {
            changeOrientation('Landscape');
            changeAspectRatio('10:10','Landscape')
            inspector.changeOrientation('10:10','Custom');
        }
    },[inspector]);

    useEffect(()=> {
        if (inspector != null){
            inspector.resetCameraPreserveSetting();
            orientationSettings()
        }
    },[selectedAspectRatio, imageWidth, imageHeight]);


    const onImageFormatChange = (e) => {
        setImageFormat(e.target.value);
    }

    const onDPIChange = (e) => {
        setDpi(e.target.value);
    }
    
    const getInspectorCamera = (name) => {
        let silo_data = inspector.getSilosInformation()['silo_data'];
        let index = 0;
        for (let i in silo_data){
            if (silo_data[i]['camera_name'] == name){
                index = i;
                break;
            }
        }
        return index;
    }

    const onImageFormatChangeCamera = (val,name,index) => {
        let camera_index = getInspectorCamera(name);
        let silo_data = inspector.getSilosInformation();
        silo_data['silo_data'][camera_index]['image_data']['img_format'] = val;
        inspector.setSilosInformation(silo_data);
        let allCameras = camera;
        allCameras[index]['image_format'] = val;
        setCamera(allCameras)
        setImageFormat(val)
    }

    const onChangeDPICamera = (val,name,index) => {
        let camera_index = getInspectorCamera(name);
        let silo_data = inspector.getSilosInformation();
        silo_data['silo_data'][camera_index]['image_data']['img_dpi'] = val;
        inspector.setSilosInformation(silo_data);
        let allCameras = camera;
        allCameras[index]['dpi'] = val;
        setCamera(allCameras)
        setDpi(val);
    }
    
    const onChangeResolutionCamera = (val,name,index) => {
        let camera_index = getInspectorCamera(name);
        let silo_data = inspector.getSilosInformation();
        silo_data['silo_data'][camera_index]['image_data']['img_resolution'] = val;
        silo_data['silo_data'][camera_index]['image_data']['width'] = val.split('x')[0];
        silo_data['silo_data'][camera_index]['image_data']['height'] = val.split('x')[1];
        inspector.setSilosInformation(silo_data);
        let allCameras = camera;
        allCameras[index]['resolution'] = val;
        setCamera(allCameras)
        setDpi(val);
    }

    const onFovSwitchChange = (e) => {
        setFovSwitch(e.target.value)
    }

    const onBackgroundChange = (e) => {
        setBackground(e.target.value);
    }

    const onChangeShadows = (value) => {
        setShadows(value);
        setDropShadows(false)
    }

    const onChangeDropShadows = (value) => {
        setDropShadows(value);
    }

    const discardSnapshot = () => {
        setEditResFlag(false);
        showSnapshotModal(false);
        restoreInformation(true);
        inspector.resetCameraState();
    }
    
    
    const hexToRGB = (hex) => {
        let alpha = false,
            h = hex.slice(hex.startsWith('#') ? 1 : 0);
        if (h.length === 3) h = [...h].map(x => x + x).join('');
        else if (h.length === 8) alpha = true;
        h = parseInt(h, 16);
        return (
            [
            (h >>> (16)) 
            ,
            ((h & (0x00ff00)) >>> (8))
            ,
            ((h & (0x0000ff)) >>> (0))
            ]
        );
    };
      
    const changeHexColor = (e) => {
        setHexColor(e.target.value);
        setBackgroundColor(allBackgroundColors.length);
    }
    
    useEffect(() => {       
         
        if (inspector == undefined || camera.length == 0)
            return
        
        inspector.toggleGreyMat(greyMat);

        let lightingRig = getLightRig(shadows, background, dropShadows);
        let silo_information = inspector.getSilosInformation();
        for (let index in silo_information['silo_data']){
            silo_information['silo_data'][index]['rig_url'] = lightingRig;
        }
        inspector.setSilosInformation(silo_information);
            
        let configData = camera;        

        inspector.changeBackGroundColor(hexColor);

        if (dropShadows == true) {
            showShadows()            
        }
        else {
            hideShadows()
        }
        
        inspector.disableAxes();
        for (let index in configData) {
            inspectorChangePerspective(configData[index]['name'], index);
            inspector.disableAxes();
            configData[index]['thumbnail'] = inspector.getSceneRender().domElement.toDataURL();
            inspector.enableAxes();
        }
        
        inspectorChangePerspective(configData[perspective]['name'], perspective)
        setCamera(configData)
        inspector.enableAxes();
        
    },[hexColor, selectedLight, dropShadows, greyMat, lightTemperatureUpdate, shadowsRig])
    
    const changeBackgroundColor = (e) => {
        setBackgroundColor(e.target.value);
    }
    
    const snapshotUpdate = () => {
        
        let camera_name = camera[perspective]['name'];
        let silo_data = inspector.getSilosInformation();
        let index = 0;
        
        for (let i in silo_data['silo_data']){
            if (silo_data['silo_data'][i]['camera_name'] == camera_name){
                index = i;
                break;
            }
        }
                
        let data = configurationData;
        data[modelType]['images'][perspective]['img_resolution'] = resolution;
        data[modelType]['images'][perspective]['polar_angle'] =  inspector.getPolarAngle();
        data[modelType]['images'][perspective]['azimuthal_angle'] = inspector.getAzimuthalAngle();
        setConfigurationData(data);
        inspector.setConfigurationData(data);
                
        silo_data['silo_data'][index]['image_data']['width'] = resolution.split('x')[0];
        silo_data['silo_data'][index]['image_data']['height'] = resolution.split('x')[1];
        silo_data['silo_data'][index]['image_data']['img_resolution'] = resolution;
        silo_data['silo_data'][index]['image_data']['aspect_ratio'] = inspector.aspect;
        silo_data['silo_data'][index]['image_data']['img_format'] = imageFormat;
        silo_data['silo_data'][index]['image_data']['img_dpi'] = dpi;
        silo_data['silo_data'][index]['image_data']['fov'] = inspector.getVerticalFov();
        silo_data['silo_data'][index]['image_data']['horizontal_fov'] = inspector.getHorizontalFOV();
        silo_data['silo_data'][index]['image_data']['polar_angle'] = inspector.controls.getPolarAngle();
        silo_data['silo_data'][index]['image_data']['azimuthal_angle'] = inspector.controls.getAzimuthalAngle();
        silo_data['silo_data'][index]['image_data']['world_position'] = inspector.getCameraWorldPosition();
        silo_data['silo_data'][index]['image_data']['lookAt'] = inspector.controls.target.clone();
        inspector.setSilosInformation(silo_data);

        inspector.disableAxes();
        
        let camerasTemp = camera;
        camerasTemp[perspective]['dpi'] = dpi;
        camerasTemp[perspective]['image_format'] = imageFormat;
        camerasTemp[perspective]['thumbnail'] = inspector.getSceneRender().domElement.toDataURL();
        camerasTemp[perspective]['resolution'] = resolution;
        setCamera(camerasTemp);
        
        inspector.enableAxes();
        setEditCamera(false);
        setTakeSiloShotModal(false);
    }
    
    const resetZoom = () => {
        if (inspector.zoomedInState)
            inspector.callZoomedOutState()
    }

    const snapshotTaken = () => {
        let imageWidthTemp = 0;
        let imageHeightTemp = 0;
        let newRes = '';
        let resolution_value = '';
        if (resolutionStatus == 4 && orientation != 'Custom'){
            imageWidthTemp = customWRes;
            imageHeightTemp = customHRes;
            newRes= customWRes + 'x' + customHRes;
            inspector.setResolution(newRes);
            resolution_value = newRes;
        }
        else if (orientation == 'Custom') {
            imageWidthTemp = imageWidth;
            imageHeightTemp = imageHeight;
            newRes= imageWidth + 'x' + imageHeight;
            inspector.setResolution(newRes);
            resolution_value = newRes;
        }
        else {
            imageWidthTemp = resolution.split('x')[0];
		    imageHeightTemp = resolution.substring(resolution.indexOf('x') + 1);
            inspector.setResolution(resolution);
            resolution_value = resolution;
        }
        
        imageWidthTemp = parseFloat(imageWidthTemp);
        imageHeightTemp = parseFloat(imageHeightTemp);
        inspector.setHorizontalFOV(fovValue);
        inspector.setImgDPI(dpi);
        inspector.setImgFormat(imageFormat);
        inspector.disableAxes();
        var img_data = inspector.getSceneRender().domElement.toDataURL();
        inspector.enableAxes();
        let aspect = inspector.getAspect()
        var camera_index = cameraIndex;
        setCameraIndex(camera_index + 1);
        var camera_name = "camera_" + camera_index;
        addCamera(camera_name,img_data,'',resolution_value, cameraDisplayName, dpi, imageFormat);
        // uploadSiloPreview(params.id, camera_name + '_preview', img_data);

        inspector.get3DMaxValues(resolution_value, camera_name, 'pending', lightRig, cameraDisplayName ,aspect);
        let azi_angle = inspector.getAzimuthalAngle();
        let pol_angle = inspector.getPolarAngle();
        let config_data = configurationData;
        let cameraObj = {
            'system_name' : camera_name,
            'display_name' : cameraDisplayName,
            'polar_angle' : pol_angle,
            'azimuthal_angle' : azi_angle
        }
        config_data[modelType]['images'].push(cameraObj);
        setConfigurationData(config_data)
        setTakeSiloShotModal(false);
        setOverlayLimitFunc();
        let camera_categories = selectedCategories;
        let category_value = returnPrice(resolution_value,'category');
        camera_categories.push(category_value);
        setSelectedCategories(camera_categories);
        loadUserRequestStatus(camera_categories, null);
    }

    const blindSnapshotTaken = ( blindName, defaultSilo = false ) => {
        if (blindImages.includes(blindName)) {
            return;
        }
        inspector.disableAxes();
        var img_data = null;
        if (blindName.includes(insideMount)) {
            img_data = perspectiveImages[perspectiveCount - 2];
        }
        else {
            img_data = perspectiveImages[perspectiveCount - 1];
        }
        var camera_index = null;
        var camera_name = blindName;
        addCamera(camera_name,img_data,'', "2100x1800");
        // uploadSiloPreview(params.id, camera_name + '_preview', img_data);
        if (!blindImages.includes(camera_name)) {
            setBlindImages(oldArray => [...oldArray, camera_name]);
        }
        showSnapshotModal(false);
    }
    const deleteSnapshot = (name,index) => {
        let newCategories = [];
        let newArray = [];
        let camera_deleted = false;
        
        camera_deleted = false;
        newArray = [];
        for (var i=0; i < camera.length; i++){
            if (camera[i]['name'] == name && !camera_deleted)
            {
				camera_deleted = true;
			}
			else {
                if (camera_deleted) {
                    let cameraToks = camera[i]['name'].split("_")[1]
                    if (cameraToks != undefined){
                        camera[i]['name'] = 'camera_' + (parseInt(cameraToks)-1)
                    }    
                }
                newArray.push(camera[i]);
                newCategories.push( returnPrice((camera[i].resolution),'category' ));
            }
        }
        setCamera(newArray)
        
        if (name.includes(blindMount)) {
            let newBlindArray = []
            for (var i=0; i<blindImages.length; i++){
                if (blindImages[i] != name)
                {
                    newBlindArray.push(blindImages[i]);
                }
            }
            setBlindImages(newBlindArray)
        }
        inspector.removeByCameraName(name);
        setSavedSiloDefaults(inspector.getSilosInformation().silo_data)
        setSelectedCategories(newCategories);
        loadUserRequestStatus(newCategories, null);
        addSelectedCamera(index, name)
    }

    const addCamera = (camera_name, img_data, camera_render = '',resolution, display_name='', image_DPI = undefined, image_format = undefined) => {
        let camera_obj = {
            name: camera_name,
            thumbnail: img_data,
            camera_render: camera_render,
            resolution: resolution,
            camera_display_name : display_name,
            dpi: image_DPI,
            image_format : image_format,
            lighting: "default",
            rig_name: "default"
        };
        setCamera(oldArray => [...oldArray,camera_obj]);
    }

    const setFullscreen = () => {
        if(isFullscreen == false)
        {
            document.body.requestFullscreen();
        }
        if(isFullscreen == true)
        {
            document.exitFullscreen();
        }
        setIsFullscreen(!isFullscreen);
        SetOverlayLines();
    }

    document.addEventListener('fullscreenchange', (event) => {
        let elem = event.target;
        let isFullscreenState = document.fullscreenElement === elem;
        setIsFullscreen(isFullscreenState);
        SetOverlayLines();
    });

    const setCameraHeight = (value) => {
        if (inspector != null) {
            inspector.setCameraHeight(value);
        }
    }

    const getCameraHeight = (value) => {
        if (inspector != null) {
            return inspector.getCameraHeight();
        }
        return null;
    }

    const setPolarAngle = (angle) => {
        if (inspector != null) {
            inspector.setCameraPolarAngle(180 - angle)
        }
    }

    const getCameraAngle = () => {
        return cameraAngle;
    }

    const setCameraAngle = (value) => {
        setCameraAngles(value)
    }

    const isSignedURLvalid = (url) => {
        if (url == '')
            return false;
        const urlSearchParams = new URLSearchParams(new URL(url).search);
        const expirationTimeString = urlSearchParams.get('X-Goog-Expires');
        if (!expirationTimeString) {
          console.error('Invalid signed URL: No expiration time found.');
          return false;
        }
        const expirationTime = new Date(parseInt(expirationTimeString) * 1000);
        const currentTime = new Date();
        return currentTime > expirationTime;
      }

      const uploadSceneThumbnailtoGoogle = (product_id, filename, blobData, callback = undefined) => {
        let buf = Buffer.from(blobData.replace(/^data:image\/\w+;base64,/, ""),'base64')
        let thumbnail_path = "product_assets/perspective_renders/" + product_id + "/" + localStorage.getItem("username") + "/" + filename + ".jpg";
        if (companyId != null){
            thumbnail_path = "product_assets/perspective_renders/" + product_id + "/company/" + companyId + "/" + filename + ".jpg";
        }
        console.log(isSignedURLvalid(thumbnailSignedUrl))
        if (isSignedURLvalid(thumbnailSignedUrl)) {
            let signedUrl = thumbnailSignedUrl;
            const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true);

              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response);
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': thumbnail_path
                    })
                    if (callback != undefined){
                        callback(true);
                    }
                  } else {
                    if (callback != undefined){
                        callback(false);
                    }
                  }
              };

              xhr.send(buf);
        }
        else
        {
            axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                'action': 'get_asigned_urls',
                'file_uri': thumbnail_path
            })
              .then(res => {
                  let signedUrl = res.data;
                  setThumbnailSignedUrl(signedUrl)
                  const xhr = new XMLHttpRequest();
                  xhr.open('PUT', signedUrl, true);

                  xhr.onload = function() {
                      console.log(xhr)
                      if (xhr.status === 200) {
                        console.log(xhr);
                        console.log("SEND FINISHED", xhr.response);
                        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                          'action': 'make_file_public',
                          'file_uri': thumbnail_path
                        })
                        if (callback != undefined){
                            callback(true);
                        }
                      } else {
                        if (callback != undefined){
                            callback(false);
                        }
                      }
                  };

                  xhr.send(buf);
              });
        }
    }
    
    const uploadSceneThumbnailtoAWS = (product_id, filename, blobData, callback = undefined) => {

        let buf = Buffer.from(blobData.replace(/^data:image\/\w+;base64,/, ""),'base64')

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });
            const S3 = new AWS.S3();
            let key = "product_assets/perspective_renders/" + product_id + "/" + localStorage.getItem("username") + "/" + filename + ".jpg";
            if (companyId != null){
                key = "product_assets/perspective_renders/" + product_id + "/company/" + companyId + "/" + filename + ".jpg";
            }
            const objParams = {
                Bucket: BUCKET_NAME,
                Key: key,
                ACL: 'public-read',
                Body: buf,
                ContentEncoding: 'base64',
                ContentType: "image/jpeg",
                CacheControl: 'no-cache'
            };


            S3.upload(objParams, (err, data) => {

                if (callback != undefined){
                    if(!err) {
                        callback(true);
                    }
                    else{
                        callback(false);
                    }
                }
            });
        });
    }
    
    const uploadSiloPreview = (product_id, filename, blobData, callback = undefined) => {

        if (platform == 'google') {
            uploadSceneThumbnailtoGoogle(product_id, filename, blobData, callback = callback)
        } else {
            uploadSceneThumbnailtoAWS(product_id, filename, blobData, callback = callback)
        }
    }

  const updateCameraDisplayName = (camera_name, camera_display_name) => {
    inspector.updateDisplayName(camera_name, camera_display_name)
  }  

  const savePresetForBulkSiloConfigFlow = () => {
        setSavePresetLoader(true);
        let silo_data = inspector.getSilosInformation().silo_data;
        let preset_settings = {}
        let images = []
        let camera_names = []
        for (let index in camera){
            camera_names.push(camera[index].name)
        }
        for (let index in silo_data){
            if( selectedCameras.includes(silo_data[index]['camera_name']) ){
                let image_data = silo_data[index]['image_data']
                image_data['system_name'] = silo_data[index]['camera_name']
                image_data['camera_display_name'] = silo_data[index]['camera_display_name']
                image_data['polar_angle'] = image_data['polar_angle'] * (180/ Math.PI)
                image_data['azimuthal_angle'] = image_data['azimuthal_angle'] * (180/ Math.PI)
                images.push(image_data)    
            }
        }
        preset_settings['images'] = images;
        preset_settings['shadows'] = shadows;
        let settings = {};
        settings["default"] = preset_settings;

        if (bulkFlowData.action_type == 'create') {
            let payload = {
                'username': localStorage.getItem('username'),
                'preset_name': presetName,
                'settings': settings,
                'customers': bulkFlowData.customerNames,
                'product_id': bulkFlowData.productID,
                'action': 'insert_presets'
            }
            axios.post(ENVIRONMENT.PRODUCT_BULK_PRESET_CONTROLLER, payload)
            .then((res) => {
            if (res && res['data']['statusCode'] == 200) {   
                setPresetModal(false);
                setSavePresetLoader(false);
                message.success('Presets created sucessfully!')
                history.push({
                    pathname: '/bulk-configurations',
                    state: {
                        tabData: {
                            'tabKey': '4',
                            'customerName': bulkFlowData.customerNames[0],
                        },
                    },
                });
            }
            else
            {
                message.error('Error while creating saving preset.')
            }
            })
            return;
        }
        
        else if (bulkFlowData.action_type == 'update') {
            let payload = {
                'username' : localStorage.getItem('username'),
                'settings' : settings,
                'preset_name' : presetName,
                'action' : 'update_preset',
                'preset_id': bulkFlowData.presetID   
            }

            axios.post(ENVIRONMENT.CUSTOMER_SILO_CONFIG, payload)
            .then((res) => {
            if (res && res['data'] && res['data']['ResponseMetadata']['HTTPStatusCode'] == 200) {   
                setPresetModal(false);
                setSavePresetLoader(false);
                message.success('Presets updated sucessfully!')
                history.push({
                    pathname: '/bulk-configurations',
                    state: {
                        tabData: {
                            'tabKey': '4',
                            'customerName': bulkFlowData.customerNames[0],
                        },
                    },
                });
            }
            else
            {
                message.error('Error while creating saving preset.')
            }
            })
            return;
        }     
  }

    const confirmRequest = (savePresets = false, updatePresets = false) =>  {
        if (bulkFlowData) {
            savePresetForBulkSiloConfigFlow()
        }
        else {
            let indexStart = cameraStartingIndex
            for (let cam in camera) {
                if( selectedCameras.includes(camera[cam].name)) {
                    let cam_name = 'camera_' + indexStart;
                    indexStart ++;
                    uploadSiloPreview(params.id, cam_name +"_preview", camera[cam].thumbnail)
                }
            }
            
            let swatchRenderRequest = false;
            if(configurationData != null && configurationData[modelType]["swatch_renders"] != undefined ) {
                swatchRenderRequest = configurationData[modelType]["swatch_renders"];
            }
            
            setSiloRequestLoader(true);
            let payload = inspector.getSilosInformation();
            payload.silo_job_status = 'generate';
            payload.username = localStorage.getItem("username");
            payload.swatch_renders = swatchRenderRequest;
            payload.lighting = selectedLight;
            payload.color_temperature = hexToRGB(FileConstants.temperatureColors[lightTemperature]);
            payload.swatch_generated = swatchRenderExist;
            if( hexColor != "default" ) {
                payload.background_color = hexToRGB(hexColor);
            }
            if (companyId != null) {
                payload.company_id = companyId;
            }
            if (requestBlindRenders == true) {
                let insideMountRequested = false;
                let outsideMountRequested = false;
                let requestInsideMount = blindImages.includes(insideMount);
                let requestOutsideMount = blindImages.includes(outsideMount);
                var requested_render_categories = [];
                for (let imageData of payload.silo_data) {
                    let cam_height = imageData['image_height']
                    let cam_width = imageData['image_width']
                    let camera_res = cam_width.toString() + 'x' + cam_height.toString();
                    imageData['price_category'] = returnPrice(camera_res,'category');
                    requested_render_categories.push(imageData['price_category'])

                    if (imageData.camera_name == productID + "_InsideMount" && requestInsideMount) {
                        imageData.image_status = 'pending';
                        imageData.captured_by = localStorage.getItem("username");
                        imageData.rig_name = BLIND_MOUNT_RIG;
                        imageData.rig_url = BLIND_MOUNT_RIG;
                        insideMountRequested = true;
                    }
                    if (imageData.camera_name == productID + "_OutsideMount" && requestOutsideMount) {
                        imageData.image_status = 'pending';
                        imageData.rig_name = BLIND_MOUNT_RIG;
                        imageData.captured_by = localStorage.getItem("username");
                        imageData.rig_url = BLIND_MOUNT_RIG;
                        outsideMountRequested = true;
                    }
                }
                if (!insideMountRequested && requestInsideMount) {
                    let imageInfo = {}
                    imageInfo.camera_name = productID + "_InsideMount";
                    imageInfo.rig_name = BLIND_MOUNT_RIG;
                    imageInfo.rig_url = BLIND_MOUNT_RIG;
                    imageInfo.captured_by = localStorage.getItem("username");
                    imageInfo.image_status = "pending";
                    imageInfo.image_type = "blind";
                    imageInfo.image_data = {
                        "width" : 2100,
                        "height": 1800,
                        "img_format": "jpg"
                    };
                    payload.silo_data.push(imageInfo);
                }
                if (!outsideMountRequested && requestOutsideMount) {
                    let imageInfo = {}
                    imageInfo.camera_name = productID + "_OutsideMount";
                    imageInfo.rig_name = BLIND_MOUNT_RIG;
                    imageInfo.captured_by = localStorage.getItem("username");
                    imageInfo.rig_url = BLIND_MOUNT_RIG;
                    imageInfo.image_status = "pending";
                    imageInfo.image_type = "blind";
                    imageInfo.image_data = {
                        "width" : 2100,
                        "height": 1800,
                        "img_format": "jpg"
                    };
                    payload.silo_data.push(imageInfo);
                }
            }
            let pending_silo_requests = []
            let initial_silo_cameras = [];
            let index = cameraStartingIndex
            payload.silo_data.map((camera) => {
                if (camera.image_status == 'pending' && !initial_silo_cameras.includes(camera.camera_name) && selectedCameras.includes(camera.camera_name)) {
                    camera.camera_name = 'camera_' + index;
                    index ++;
                    pending_silo_requests.push(camera);
                }
            })
            console.log('Payload Silo', pending_silo_requests, requestStatus)
            payload.silo_data = pending_silo_requests;
            let requestPayload = payload;
            requestPayload['username'] = localStorage.getItem('username');
            let gateway_payload = {
                "username": localStorage.getItem('username'),
                "request_type": requestType,
                "action": "perform",
                "request_payload": requestPayload,
                "categories": selectedCategories,
                "request_id": Date.now().toString()
            }

            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed' || requestStatus == 'update_subscription') {
                siloSubmissionRequest(gateway_payload, savePresets , updatePresets);
            }
            if (requestStatus == 'payment_required') {

                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'product_silo') {
                        siloSubmissionRequest(gateway_payload, savePresets, updatePresets);
                    }

                }, false);

                if(cardStatus ==1){
                    siloSubmissionRequest(gateway_payload, savePresets, updatePresets);
                }
                if(cardStatus == 0){
                    let child_window = window.open('/save-card?action=product_silo', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                    if(child_window.closed) {
                        setCardSaveFlowTriggered(false);
                        setSiloRequestLoader(false)
                        clearInterval(refreshInterval);
                    }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }
            loadUserRequestStatus(null,null);
        }
    }

    const cancelSubscriptionModal = () => {
        setSubscriptionModal(false);
    }

    useEffect(() => {
        setSiloRequestLoader(false);
    }, [inAppLimitExceededModal])

    const upgradeSubscription = () => {
        let payload ={
            username: localStorage.getItem('username'),
            subscription_type: subscriptionPlan
        }
          console.log('Subscription payload',payload);
          setSubscriptionLoader(true);
          axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
          .then( (response) => {
            console.log(response.data);
            setSubscriptionModal(false);
            setSubscriptionLoader(false);
            loadUserRequestStatus(null,'confirm');
            message.success('Your subscription plan has been successfully upgraded.');
        })
          .catch((error) => {
          });
    }


    const siloSubmissionRequest = (payload, savePresets = false, updatePresets = false) => {
        console.log(JSON.stringify(payload))
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then(res => {
            if (res.data.status!=undefined && res.data.status == 1) {
                    setConfirmRequestState(true);
                    setSiloRequestLoader(false);
                    showDefaultSilosModal(false);

                    let payload = { product_id: params.id, username: localStorage.getItem("username")};
                    let company_id = localStorage.getItem("company_id")
                    if (company_id != null) {
                        payload.company_id = company_id
                    }
                    if (FileConstants.isMSProvider && companyId != null) {
                        payload.company_id = companyId
                    }

                    axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
                    .then(res => {
                        console.log('Updated Silo Data', res.data.silo_data)
                        let silo_data = res.data.silo_data;
                        if (inspector != null) {
                            inspector.receiveUpdatedData(silo_data);
                        }
                    });
                    
                    if (savePresets || updatePresets ){
                        let silo_data = inspector.getSilosInformation().silo_data;
                        let preset_settings = {}
                        let images = []
                        let camera_names = []
                        for (let index in camera){
                            camera_names.push(camera[index].name)
                        }
                        for (let index in silo_data){
                            if( camera_names.includes(silo_data[index]['camera_name']) ){
                                let image_data = silo_data[index]['image_data']
                                image_data['system_name'] = silo_data[index]['camera_name']
                                image_data['camera_display_name'] = silo_data[index]['camera_display_name']
                                image_data['polar_angle'] = image_data['polar_angle'] * (180/ Math.PI)
                                image_data['azimuthal_angle'] = image_data['azimuthal_angle'] * (180/ Math.PI)
                                images.push(image_data)    
                            }
                        }
                        preset_settings['images'] = images;
                        preset_settings['dropShadows'] = dropShadows;
                        let settings = {};
                        settings[productData.category] = preset_settings;
                        if ( updatePresets ) {
                            axios.post(ENVIRONMENT.CUSTOMER_SILO_CONFIG, {"username" : localStorage.getItem('username'), 'action':'update_preset', "preset_id":presetID, "preset_name":presetName, "settings": settings})
                            .then(res => {
                                setSuccessModal(true);
                            })  
                        }
                        
                        if( savePresets ) {
                            axios.post(ENVIRONMENT.CUSTOMER_SILO_CONFIG, {"username" : localStorage.getItem('username'), 'action':'insert_presets', "preset_name":presetName, "settings": settings})
                            .then(res => {
                                setSuccessModal(true);
                            })    
                        }
                    }
                    else {
                        setSuccessModal(true);
                    }
            }
            else if (res.data.request_allowed!=undefined && (res.data.request_allowed == 'not_allowed' || res.data.request_allowed == 'update_subscription')) {
                setInAppLimitExceededModal(true);
            }
            else {
                setPaymentFailureMessage(res.data.error_message);
                setPaymentFailureModal(true);
                setSiloRequestLoader(false);
                showDefaultSilosModal(false);
            }
        })
    }


    const saveRequestIntermedietely = (callback = null) => {
        setSiloRequestLoader(true);
        console.log(JSON.stringify(siloPayload))
        let payload = {
            'request_type': requestType,
            'request_body': siloPayload,
            'processed': false
        }
        if (paymentRequestID == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
            .then(res => {
                console.log(res);
                setSiloRequestLoader(false);
                if (callback!= null)
                {
                   callback(res.data[0].request_id,requestType);
                }
            });
        } else {
            setSiloRequestLoader(false);
            if (callback!= null)
            {
                callback(paymentRequestID,requestType);
            }
        }
    }
    
    const getImageResolutionArray = (res) => {
        let img_width = parseInt(res.split('x')[0])
        let img_height = parseInt(res.split('x')[1])
        let imgResolutions = [];
		if (img_width / img_height > 1) {
			imgResolutions.push(1024 + 'x' + Math.round(img_height / (img_width / 1024))); // low
			imgResolutions.push(1024*2 + 'x' + Math.round(img_height*2 / (img_width / 1024))); // low
			imgResolutions.push(1024*3 + 'x' + Math.round(img_height*3 / (img_width / 1024))); // low
			imgResolutions.push(1024*4 + 'x' + Math.round(img_height*4 / (img_width / 1024))); // low	
		}
		else {
			imgResolutions.push(Math.round(img_width / (img_height / 1024)) + 'x' + 1024); // low
			imgResolutions.push(Math.round(img_width*2 / (img_height / 1024)) + 'x' + 1024*2); // low
			imgResolutions.push(Math.round(img_width*3 / (img_height / 1024)) + 'x' + 1024*3); // low
			imgResolutions.push(Math.round(img_width*4 / (img_height / 1024)) + 'x' + 1024*4); // low
		}
        return imgResolutions
    }

    const changeAspectRatio = (ratio,type) => {
        let widthR = 0;
        let heightR = 0;
        let newAspect = 0;
        if (typeof ratio === 'string' || ratio instanceof String) {
			widthR = parseFloat(ratio.split(':')[0]);
            heightR = parseFloat(ratio.split(':')[1]);
            widthR = widthR*100;
            heightR = heightR*100;
			newAspect = widthR/heightR;
            setSelectedAspectRatio(ratio);
            let resArr = inspector.setResolutions(widthR,heightR)
            setImageResArr(resArr);
            setImageWidth(resArr[resolutionStatus].split('x')[0]);
            setImageHeight(resArr[resolutionStatus].split('x')[1]);
            setCustomWRes(resArr[resolutionStatus].split('x')[0]);
            setCustomHRes(resArr[resolutionStatus].split('x')[1]);
            if (defaultShotsTaken)
                setResolution(resArr[resolutionStatus])
		}
    }

    const changeOrientation = (value) => {
        if(value == 'Landscape')
        {
            setOrientation('Landscape');
            changeAspectRatio("16:10","Landscape");
        }
        else if(value == 'Portrait')
        {
            setOrientation('Portrait');
            changeAspectRatio("9:16","Portrait");
        }
        else if(value == 'Square')
        {
            setOrientation('Square');
            changeAspectRatio("10:10","Square");
        }
        else if (value == 'Custom') {
            setOrientation('Custom');
        }
    }

    const setAspects = (value,ratio = null) => {
        let aspect_ratio = selectedAspectRatio;
        if (ratio != null) {
            aspect_ratio = ratio;
        }
        if (inspector != null) {
            if (value != 'Custom') {
                inspector.changeOrientation(aspect_ratio,'Custom');
            }
            inspector.setHorizontalFOV(fovValue);
            inspector.disableAxes();
            let img_data = inspector.getSceneRender().domElement.toDataURL();
            setModalShot(img_data);
            inspector.enableAxes();
        }
    }

    const orientationSettings = () => {
        if (orientation == 'Landscape') {
            inspector.changeOrientation(selectedAspectRatio,'Custom');
        }
        else if(orientation == 'Portrait')
        {
            inspector.changeOrientation(selectedAspectRatio,'Custom');
        }
        else if(orientation == 'Square')
        {
            inspector.changeOrientation(selectedAspectRatio,'Custom');
        }
        else if (orientation == 'Custom') {
            let newAspect = imageWidth/imageHeight;
            setSelectedAspectRatio(ratioFromAspect[newAspect]);
            inspector.changeOrientation(newAspect,'Custom');
        }
        setAspectRatioModal(false);
        setSettingDone(true);
        inspector.setHorizontalFOV(fovValue);
        if(resolution != undefined){
            setOverlayLimitFunc();
            SetOverlayLines();
        }
    }

    const closeAspectRatioModal = () => {
        if (settingDone == false) {
            setAspectClicked(false);
            setOrientationClicked(false);
        }
        setAspectRatioModal(false);
        restoreInformation(false);

    }

    const resetCamera = () => {
        if (inspector != null){
            inspector.resetCameraSetting();
        }
    }

    const alignCameraForSilos = () => {
        if (inspector != null){
            inspector.alignCameraForSilos();
        }
    }

    const showGridForSilos = () => {
        if (inspector != null){
            changeGridState();
            inspector.showGridForSilos();
        }

    }

    const changeCameraState = () => {
        setCameraSelected(!cameraSelected);
    }

    const changeGridState = () => {
        if (gridState == true) {
            setGridState(false);
        }
        else {
            setGridState(true);
        }

    }

    const onChangeWidth = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            setImageWidth(1);
            new_aspect = 1/imageHeight;
            res = '1x' + imageHeight;
        } else {
            setImageWidth(value);
            new_aspect = value/imageHeight;
            res = value + 'x' +imageHeight;
        }
        setResolution(res);

    }

    const onChangeHeight = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            setImageHeight(1);
            new_aspect = imageWidth/1;
            res = imageWidth + 'x1';
        } else {
            setImageHeight(value);
            new_aspect = imageWidth/value;
            res = imageWidth + 'x' + value;
        }
        setResolution(res);
    }

    const onChangeWidthDynamically = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            setImageWidth(1);
            new_aspect = 1/imageHeight;
            res = '1x' + imageHeight;
        } else {
            setImageWidth(value);
            new_aspect = value/imageHeight;
            res = value + 'x' +imageHeight;
        }
        setResolution(res);
        setSelectedAspectRatio(new_aspect);
        inspector.changeOrientation(new_aspect,'Custom');
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }

    const onChangeHeightDynamically = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            setImageHeight(1);
            new_aspect = imageWidth/1;
            res = imageWidth + 'x1';
        } else {
            setImageHeight(value);
            new_aspect = imageWidth/value;
            res = imageWidth + 'x' + value;
        }
        setResolution(res);
        setSelectedAspectRatio(new_aspect);
        inspector.changeOrientation(new_aspect,'Custom');
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }
    

    const onResolutionChangeCamera = (value, pers = perspective) => {
        
        let camera_name = camera[pers].name
        let silo_data = inspector.getSilosInformation();
        
        let index = 0;
        for (let i in silo_data['silo_data']){
            if (silo_data['silo_data'][i]['camera_name'] == camera_name){
                index = i
            }
        } 
        let initial_width = (silo_data['silo_data'][index]['image_data']['width'])
        let initial_height = (silo_data['silo_data'][index]['image_data']['height'])
        let type = identifyOrientation(ratioFromAspect[silo_data['silo_data'][index]['image_data']['aspect_ratio']])
        let imageResolutionArray = inspector.setResolutions(initial_width, initial_height)
        let res = imageResolutionArray[value];
        let width = res.split('x')[0];
        let height = res.split('x')[1];
        silo_data['silo_data'][index]['image_data']['img_resolution'] = imageResolutionArray[value];
        silo_data['silo_data'][index]['image_data']['width'] = width;
        silo_data['silo_data'][index]['image_data']['height'] = height;
        inspector.setSilosInformation(silo_data);
        setResolutionStatus(value);
        setResolution(res);
        let camerasTemporary = camera;
        camerasTemporary[pers]['resolution'] = imageResolutionArray[value];
        setCamera(camerasTemporary);
    
    }

    const getResolutions = (res) => {
        let width = res.split('x')[0];
        let height = res.split('x')[1];
        let type = identifyOrientation(getRatioFromAspect(parseInt(width)/parseInt(height)));
        let imageResolutionArray = inspector.setResolutions(width, height);
        return imageResolutionArray
    }
    
    const onResolutionChange = (e) => {
        setResolutionStatus(e.target.value);
        setResolution(imageResArr[e.target.value]);
    }

    useEffect(() => {
        if (defaultResolutions.includes(resolution)){
            setResolution(imageResArr[resolutionStatus])     
        }
    },[imageResArr]);

    const onChangeToggle = (e) => {
        if (e.target.value == "pan") {
            setPanToggle(true);
            if (inspector != null) {
                inspector.togglePan(true);
            }
        }
        else {
            setPanToggle(false);
            if (inspector != null) {
                inspector.togglePan(false);
            }
        }
    }

    const zoomIn = () => {
        inspector.zoomInCamera();
    }

    const zoomOut = () => {
        inspector.zoomOutCamera();
    }

    const openSnapshotModal = () => {

        if ( configurationData[modelType]['images'][perspective]['category'] == "blind") {
            var imageName = configurationData[modelType]['images'][perspective]['display_name']
            blindSnapshotTaken(imageName);
            return;
        }
        inspector.saveCameraState();
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        setResetConfiguration(prevState => ({...prevState,
            img: inspector.getSceneRender().domElement.toDataURL(),
            dpi: dpi,
            image_format: imageFormat,
            resolution_status: resolutionStatus,
            orientation: orientation,
            resolution: resolution,
            aspect_ratio: selectedAspectRatio,
            image_width: imageWidth,
            image_height: imageHeight,
            custom_width: customWRes,
            custom_height: customHRes,
            fov: fovValue,
            rig_name: lightRig,
            default_rig: defaultChecked
        }));
        inspector.setHorizontalFOV(fovValue);
        showSnapshotModal(true);
        setEditResFlag(false);
        inspector.enableAxes();
    }

    const openOrientationModal = () => {
        setResetConfiguration(prevState => ({...prevState,
            img: inspector.getSceneRender().domElement.toDataURL(),
            dpi: dpi,
            image_format: imageFormat,
            resolution_status: resolutionStatus,
            orientation: orientation,
            resolution: resolution,
            aspect_ratio: selectedAspectRatio,
            image_width: imageWidth,
            image_height: imageHeight,
            custom_width: customWRes,
            custom_height: customHRes,
            fov: fovValue,
            rig_name: lightRig,
            default_rig: defaultChecked
        }));
        inspector.setHorizontalFOV(fovValue);
        setAspectRatioModal(true);
    }

    const onChangeFov = (value) => {
        if (isNaN(value)) {
            setFovValue(fovRange.min);
            inspector.updateHorizontalFOV(fovRange.min);
            setFocalLengthValue(inspector.getCameraFocalLength())
        }
        else {
            if (value < fovRange.min) {
                setFovValue(fovRange.min);
                inspector.updateHorizontalFOV(fovRange.min);
                setFocalLengthValue(inspector.getCameraFocalLength())
            } else if (value > fovRange.max) {
                setFovValue(fovRange.max);
                inspector.updateHorizontalFOV(fovRange.max);
                setFocalLengthValue(inspector.getCameraFocalLength())
            } else {
                setFovValue(value);
                inspector.updateHorizontalFOV(value);
                setFocalLengthValue(inspector.getCameraFocalLength())
            }
        }
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }

    const onChangeFocalLength = (value) => {
        if (isNaN(value)) {
            setFocalLengthValue(focalLengthRange.min)
            inspector.setFocalLength(focalLengthRange.min)
            setFovValue(inspector.getHorizontalFOV());
        } else {
            if (value < focalLengthRange.min) {
                setFocalLengthValue(focalLengthRange.min)
                inspector.setFocalLength(focalLengthRange.min)
                setFovValue(inspector.getHorizontalFOV());
            } else if (value > focalLengthRange.max) {
                setFocalLengthValue(focalLengthRange.max)
                inspector.setFocalLength(focalLengthRange.max)
                setFovValue(inspector.getHorizontalFOV());
            } else {
                setFocalLengthValue(value)
                inspector.setFocalLength(value)
                setFovValue(inspector.getHorizontalFOV());
            }
        }
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }


    const restoreInformation = (check) => {
        setModalShot(resetConfiguration.img);
        if (typeof(resetConfiguration.dpi) == "number") {
            setDpi(resetConfiguration.dpi.toString());
        }
        else {
            setDpi(resetConfiguration.dpi);
        }
        setImageFormat(resetConfiguration.image_format);
        setResolutionStatus(resetConfiguration.resolution_status);
        setResolution(resetConfiguration.resolution);
        setOrientation(resetConfiguration.orientation);
        setCustomHRes(resetConfiguration.custom_height);
        setCustomWRes(resetConfiguration.custom_width);
        setImageWidth(resetConfiguration.image_width);
        setImageHeight(resetConfiguration.image_height);
        setLightRig(FileConstants.lightRigUrl[resetConfiguration.rig_name]);
        setDefaultChecked(resetConfiguration.default_rig);
        changeLightRig(resetConfiguration.rig_name);
        if (resetConfiguration.orientation == 'Custom') {
            let newAspect = resetConfiguration.image_width/resetConfiguration.image_height;
            setSelectedAspectRatio(newAspect);
            if (check) {
                inspector.changeOrientation(newAspect,'Custom');
            }
        }
        else {
            if (resetConfiguration.resolution_status == 4){
                setSelectedAspectRatio(resetConfiguration.aspect_ratio);
            }
            else {
                changeAspectRatio(resetConfiguration.aspect_ratio,resetConfiguration.orientation);
            }
            if (check) {
                inspector.changeOrientation(resetConfiguration.aspect_ratio,'Custom');
            }
        }
        setFovValue(resetConfiguration.fov);
        inspector.setHorizontalFOV(resetConfiguration.fov);
    }

    const getRatioFromAspect = (aspectValue) => {
        if ( ratioFromAspect[aspectValue] ) {
            return ratioFromAspect[aspectValue]
        }
        else
        {
            return aspectValue
        }
    }

    const addSelectedCamera = (value, name) => {
        if ( selectedCameras.includes(name) ) {
            let arr = selectedCameras.filter(function(item) {
                return item !== name
            })
            setSelectedCameras(arr)
        }
        else {
            setSelectedCameras([name,...selectedCameras])
            inspectorChangePerspective(name, value)    
        }
    }
    
    const changePerspective = (value, name='') => {
        setPerspective(value);
        setSelectedCameraName(name);
        try{
            let silo_data = inspector.getSilosInformation();
            setDpi(String(silo_data['silo_data'][value]['image_data']['img_dpi']));
            let aspect_rat = silo_data['silo_data'][value]['image_data']['aspect_ratio'];
            let ratio = getRatioFromAspect(aspect_rat)
            aspect_rat = String(aspect_rat)
            let orien = identifyOrientation(ratio);
            setOrientation(orien);
            changeAspectRatio(ratio, orien);;
            let status_resolution = returnResolutionStatus(silo_data['silo_data'][value]['image_data']['img_resolution']);
            if (defaultShotsTaken)
                setResolution(silo_data['silo_data'][value]['image_data']['img_resolution']);
            setResolutionStatus(status_resolution);
            setImageFormat(silo_data['silo_data'][value]['image_data']['img_format'])
        }
        catch {
            console.log("Error setting data")
        }

        inspector.changePerspectives(value);
        setFovValue(inspector.getHorizontalFOV());
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }

    const setOrientationRadio = (e) => {
        selectOrientation(e.target.value)
    }

    const selectOrientation = (orientation) => {
        changeOrientation(orientation);
        setOrientationClicked(true);
    }

    const selectAspectRatio = (aspect_ratio, type) => {
        changeAspectRatio(aspect_ratio, type);
        setAspectClicked(true);
    }

    const onImageWidthRes = (value) => {
        if (typeof value != 'number') {
            value = 1;
        }
        setCustomHRes(inspector.setCustomResolutionHeight(value));
        setCustomWRes(value);

    }

    const onImageHeightRes = (value) => {
        if (typeof value != 'number') {
            value = 1;
        }
        setCustomWRes(inspector.setCustomResolutionWidth(value));
        setCustomHRes(value);
    }

    const onChangeDefault = (e) => {
        setDefaultChecked(e.target.value);
    }

    const leftScroll = () => {
        var elem = document.getElementById('silo-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                elem.scrollLeft -= 273;
            }
            else if (window.innerWidth <= 1110) {
                elem.scrollLeft -= 224;
            }
            var left = document.getElementById('left-button-scr');
            var right = document.getElementById('right-button-scr');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }
            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                if (right != null && elem.scrollLeft != 352) {
                    right.style.display = "inline-block";
                }
            } else if (window.innerWidth <= 1110){
                if (right != null && elem.scrollLeft != 582) {
                    right.style.display = "inline-block";
                }
            }
        }
    }

    const rightScroll = () => {
        var elem = document.getElementById('silo-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                elem.scrollLeft += 273;
            }
            else if (window.innerWidth <= 1110) {
                elem.scrollLeft += 224;
            }
            var left = document.getElementById('left-button-scr');
            var right = document.getElementById('right-button-scr');
            if (left != null) {
                left.style.display = "inline-block";
            }
            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                if (right != null && elem.scrollLeft == 352) {
                    right.style.display = "none";
                }
            } else if (window.innerWidth <= 1110){
                if (right != null && elem.scrollLeft == 582) {
                    right.style.display = "none";
                }
            }
        }
    }


    var el= document.getElementById('silo-scrollable');
    if (el != null) {
        el.style.scrollBehavior = "unset";

        var y1 = el.scrollTop;
        el.scrollTop += 1;
        var y2 = el.scrollTop;
        el.scrollTop -= 1;
        var y3 = el.scrollTop;
        el.scrollTop = y1;

        var x1 = el.scrollLeft;
        el.scrollLeft += 1;
        var x2 = el.scrollLeft;
        el.scrollLeft -= 1;
        var x3 = el.scrollLeft;
        el.scrollLeft = x1;
        var right = document.getElementById('right-button-scr');
        var left = document.getElementById('left-button-scr');
        if (right != null && (x1 !== x2 || x2 !== x3)) {
            right.style.display = "inline-block";
        }
        else if (right != null && !(x1 !== x2 || x2 !== x3) && left != null) {
            right.style.display = "none";
            left.style.display = "none";
        }
        if (left != null && el.scrollLeft == 0) {
            left.style.display = "none";
        }

        if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
            if (right != null && el.scrollLeft == 352) {
                right.style.display = "none";
            }
        } else if (window.innerWidth <= 1110){
            if (right != null && el.scrollLeft == 582) {
                right.style.display = "none";
            }
        }
    }

    const returnPrice = (resolution, type='price') => {
        let width = resolution.split('x')[0]
        let height = resolution.split('x')[1]
        let silo_prices = [];
        if (Utilities.nearestThousand(height) == 4000 || Utilities.nearestThousand(width) == 4000 || height >= 4000 || width >= 4000) {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "4k")
        } else if(Utilities.nearestThousand(height) == 3000 || Utilities.nearestThousand(width) == 3000) {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "4k")
        } else if (Utilities.nearestThousand(height) == 2000 || Utilities.nearestThousand(width) == 2000) {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "2k")
        } else if ((Utilities.nearestThousand(height) == 1000 || Utilities.nearestThousand(width) == 1000) && (height >= 1000 || width >= 1000)) {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "1k")
        } else {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "1k")
        }
        if (silo_prices && silo_prices[0]) {
            if (type == 'price') {
                return parseInt(silo_prices[0].price);
            } else {
                return silo_prices[0].category;
            }

        }
    }
    
    const setDefaultLighting = () => {
        inspector.SetupLighting('default', lightingData)
    }

    const totalPrice = () => {
        let totalPrice = 0;
        camera.map((element,index) => {
            totalPrice = totalPrice + returnPrice(element.resolution);
        });
        return totalPrice;
    }
    
    const setLight = (light) =>{
        let tempCamera = camera;
        let camera_name = camera[perspective]['name'];
        
        if (!camerasLightingSelected.includes(camera[perspective]['name'])) {
            setCamerasLightingSelected([camera[perspective]['name'], ...camerasLightingSelected]);            
        }
        inspector.SetupLighting(light, lightingData);
        
        inspector.disableAxes()
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        tempCamera[perspective]['thumbnail'] = img_data
        setModalShot(img_data)
        inspector.enableAxes()
        tempCamera[perspective]['lighting'] = light;
        tempCamera[perspective]['rig_name'] = 'default';
        setCamera(tempCamera);
        
        let silo_data = inspector.getSilosInformation();
        for (let i in silo_data['silo_data']){
            if (silo_data['silo_data'][i]['camera_name'] == camera_name){
                silo_data['silo_data'][i]['image_data']['lighting'] = light;
                silo_data['silo_data'][i]['image_data']['rig_url'] = lightRig;
                break;
            }
        }
        inspector.setSilosInformation(silo_data);
        inspector.SetupLighting('default', lightingData);
        setSelectedLight(light)
    }
    
    const setRig = (id, name) => {
        let tempCamera = camera;
        let camera_name = camera[perspective]['name'];
        
        if (!camerasLightingSelected.includes(camera[perspective]['name'])) {
            setCamerasLightingSelected([camera[perspective]['name'], ...camerasLightingSelected]);            
        }
        inspector.SetupLighting('default', lightingData);
        
        inspector.disableAxes()
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        tempCamera[perspective]['thumbnail'] = img_data
        setModalShot(img_data)
        inspector.enableAxes()
        tempCamera[perspective]['lighting'] = 'default';
        tempCamera[perspective]['rig_name'] = name;
        setCamera(tempCamera);
        
        let silo_data = inspector.getSilosInformation();
        for (let i in silo_data['silo_data']){
            if (silo_data['silo_data'][i]['camera_name'] == camera_name){
                silo_data['silo_data'][i]['image_data']['lighting'] = 'default';
                silo_data['silo_data'][i]['rig_url'] = ENVIRONMENT.CUSTOMER_RIG_BASE_URL + id + '.zip'
                silo_data['silo_data'][i]['rig_name'] = name
                break;
            }
        }
        inspector.setSilosInformation(silo_data);
        inspector.SetupLighting('default', lightingData);
        setSelectedLight('default');
    }

    const checkScroll = (e) => {
        var el= document.getElementById('silo-scrollable');
        if (el != null) {
            el.style.scrollBehavior = "unset";

            var y1 = el.scrollTop;
            el.scrollTop += 1;
            var y2 = el.scrollTop;
            el.scrollTop -= 1;
            var y3 = el.scrollTop;
            el.scrollTop = y1;

            var x1 = el.scrollLeft;
            el.scrollLeft += 1;
            var x2 = el.scrollLeft;
            el.scrollLeft -= 1;
            var x3 = el.scrollLeft;
            el.scrollLeft = x1;
            var right = document.getElementById('right-button-scr');
            var left = document.getElementById('left-button-scr');

            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
            }
            else if (right != null && !(x1 !== x2 || x2 !== x3) && left != null) {
                right.style.display = "none";
                left.style.display = "none";
            }
            if (left != null && el.scrollLeft == 0) {
                left.style.display = "none";
            }

            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                if (right != null && el.scrollLeft == 352) {
                    right.style.display = "none";
                }
            } else if (window.innerWidth <= 1110){
                if (right != null && el.scrollLeft == 582) {
                    right.style.display = "none";
                }
            }

            if (e.axis == e.HORIZONTAL_AXIS) {
                e.stopPropagation();
                e.preventDefault();
                e.cancelBubble = false;
            }
            return false;
        }
    }

    window.addEventListener("resize", checkScroll);
    window.addEventListener('scroll', checkScroll);

    return (
        <div id ='silo-inspector-main'>
            <Row className='background-fafafa' type="flex" justify="center">
              <div >
                <Header className="header">
                  <CustomHeader ></CustomHeader>
                </Header>
                <div className='silo-tool-step-container'>
                    <div >
                        <span className='manrope f-16 black'>
                            Generate Silo Images
                        </span>
                    </div>
                    <div style={{width:"40vw"}}>
                        <Steps className='step-class' size="small" current={ step }>
                            <Step className={'manrope f-12'} title="Create Shot List" />
                            { !bulkFlowData &&
                            <Step className={'manrope f-12'} title="Background & Lighting" />}
                            <Step className={'manrope f-12  '} title="Resolution & Review" />
                        </Steps> 
                    </div>
                    
                        {
                            presetsFlow || bulkFlowData?
                                <div style={{padding:"1% 4%", border:"1px solid #D9D9D9", borderRadius:"4px"}}>
                                    <span className='manrope f-16 black'>
                                        {presetName}
                                    </span>
                                </div>
                                
                            :
                            <div >
                                <Button disabled={allPresets.length == 0} onClick={() => {setOpenSiloPresetModal(true)}} style={{alignItems:"center"}} className='modal-okay-black square invert'>
                                    <span className={allPresets.length == 0 ? 'modal-okay-text manrope f-14 black' : 'modal-okay-text white manrope f-14'}>
                                        Switch to a Saved Preset 
                                        <ArrowRightOutlined style={{marginLeft:"10px"}}/>                      
                                    </span>
                                </Button>
                            </div>
                        }
                        
                  
                </div>
                <div className= 'main-silo-container background-fafafa'>
                  {
                    step == 0?
                    <SiloSetAngles
                        siloToolBuilt={siloToolBuilt}
                        getResolutions={getResolutions}
                        setSiloToolBuilt={setSiloToolBuilt}
                        loadingProgress={loadingProgress}
                        perspectiveImages={perspectiveImages}
                        perspective={perspective}
                        selectedCameraName={selectedCameraName}
                        perspectiveLabels={perspectiveLabels}
                        imageResArr={imageResArr}
                        resolution={resolution}
                        panToggle={panToggle}
                        blindRendersState={blindRendersState}
                        changePerspective ={inspectorChangePerspective}
                        setTakeSiloShotModal= {setTakeSiloShotModal}
                        companyId = {companyId}
                        placementType = {placementType}
                        product_id = {params.product_id}
                        setLoadingProgress={setLoadingProgress}
                        camera={camera}
                        deleteSnapshot={deleteSnapshot}
                        setModelLoaded={setModelLoaded}
                        setPerspective={setPerspective }
                        onResolutionChangeCamera={onResolutionChangeCamera}
                        setLoadingProgressSnapshot={setLoadingProgressSnapshot}
                        presetName={presetName}
                        setPresetName={setPresetName}
                        hexColor={hexColor}
                        addSelectedCamera={addSelectedCamera}
                        selectedCameras={selectedCameras}
                        loadedProgress = {loadedProgress}
                        setEditCamera={setEditCamera}
                        setDefaultLighting={setDefaultLighting}
                        setImageHeight={setImageHeight}
                        setImageWidth={setImageWidth}
                        selectOrientation={selectOrientation}
                        onImageWidthRes={onImageWidthRes}
                        onImageHeightRes={onImageHeightRes}

                    />
                    :
                    step == 1?
                    <SiloLighting
                        modalShot= {modalShot}
                        onChangeDropShadows={onChangeDropShadows}
                        dropShadows={dropShadows}
                        defaultSettingsState={defaultSettingsState}
                        defaultChecked={defaultChecked}
                        background={background}
                        isFloorItem={isFloorItem}
                        selectedLight={selectedLight}
                        setLight={setLight}
                        setRig={setRig}
                        lightingData={lightingData}
                        changeHexColor={changeHexColor}
                        changeBackgroundColor={changeBackgroundColor}
                        setHexColor={setHexColor}
                        backgroundColor={backgroundColor}
                        hexColor={hexColor}
                        allBackgroundColors={allBackgroundColors}
                        setAllBackgroundColors={setAllBackgroundColors}
                        greyMat={greyMat}
                        setGreyMat={setGreyMat}
                        camera={camera}
                        selectedCameras={selectedCameras}
                        perspective={perspective}
                        changePerspective={inspectorChangePerspective}
                        setCustomLightingModal={setCustomLightingModal}
                        allCustomLightingRigs={allCustomLightingRigs}
                        onChangeTemperature={onAfterChangeTemperature}
                        setLightTemperature={setLightTemperature}
                        lightTemperature={lightTemperature}
                        shadowsRig={shadowsRig}
                        setShadowsRig={setShadowsRig}
                        setDropShadows={setDropShadows}
                        defaultRigLightPositions={defaultRigLightPositions}
                        defaultShadowRigLightPositions={defaultShadowRigLightPositions}
                    />
                    :
                    step ==2 ?
                    <SiloSubmitRequest
                        loadingProgress={loadingProgress}
                        perspectiveImages={perspectiveImages}
                        perspective={perspective}
                        selectedCameraName={selectedCameraName}
                        perspectiveLabels={perspectiveLabels}
                        imageResArr={imageResArr}
                        resolution={resolution}
                        panToggle={panToggle}
                        blindRendersState={blindRendersState}
                        changePerspective ={inspectorChangePerspective}
                        setTakeSiloShotModal= {setTakeSiloShotModal}
                        companyId = {companyId}
                        placementType = {placementType}
                        product_id = {params.product_id}
                        setLoadingProgress={setLoadingProgress}
                        camera={camera}
                        deleteSnapshot={deleteSnapshot}
                        orientation={orientation}
                        orientationClicked={orientationClicked}
                        selectAspectRatio={selectAspectRatio}
                        onResolutionChange={onResolutionChange}
                        onChangeDPICamera={onChangeDPICamera}
                        onChangeResolutionCamera={onChangeResolutionCamera}
                        cameraSettingsChange = {true}
                        dpi={dpi}
                        resolutionStatus={resolutionStatus}
                        onImageFormatChange={onImageFormatChange}
                        onResolutionChangeCamera={onResolutionChangeCamera}
                        onImageFormatChangeCamera={onImageFormatChangeCamera}
                        imageFormat={imageFormat}
                        fovSwitch = {fovSwitch}
                        onFovSwitchChange={onFovSwitchChange}
                        focalLengthValue={focalLengthValue}
                        fovValue={fovValue}
                        onChangeFov={onChangeFov}
                        focalLengthRange={focalLengthRange}
                        onChangeFocalLength={onChangeFocalLength}
                        selectedCameras={selectedCameras}
                        hexColor={hexColor}
                        setCamera={setCamera}
                        onImageWidthRes={onImageWidthRes}
                        customWRes={customWRes}
                        onImageHeightRes={onImageHeightRes}
                        customHRes={customHRes}
                        defaultResolutions={defaultResolutions}
                        setEditCamera={setEditCamera}
                        getImageResolutionArray={getImageResolutionArray}
                        updateCameraDisplayName={updateCameraDisplayName}
                    />
                    :
                    ""
                  }
                    <div id='camera-controls-silo' data_tut="reactour__cameraheight--observe" style={{position: 'absolute', top: "5", left: "5%", zIndex:1500}}>
                        <div style={{display: (cameraSelected && !blindRendersState) ? "block" : "none"}} className="camera_controls_fov">
                            <div style={{position: 'relative', top: "-10px", left: 20}}>
                                <CameraHeightWidget fovSwitch={fovSwitch} onFovSwitchChange={onFovSwitchChange}
                                onChangeFocalLength={onChangeFocalLength} focalLengthRange={focalLengthRange} focalLengthValue={focalLengthValue}
                                onChangeFov={onChangeFov} fovValue={fovValue} setSliderPosition={setSliderPosition}
                                sliderPos={sliderPos}  inspectorVal={inspector} setPolarAngle={setPolarAngle}
                                setCameraHeight={setCameraHeight} getCameraAngle={getCameraAngle}
                                setCameraAngle={setCameraAngle} getCameraHeight={getCameraHeight}
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer style={{display: "flex", padding: 12}} className="silo-footer">
                    { step== 2 && (requestStatus == "payment_required" || requestStatus == "not_allowed" || requestStatus == "update_subscription") ?
                            <div className="manrope f-16 black-55 justify-in-start">
                        Total Price: <span className="manrope f-20 w-700" style={{color: "#25D6A4"}}>${totalPrice()}</span>
                    </div>: ''}
                        <span style={{display: "flex", width: "100%"}} className='justify-in-end'>
                            <div style={{display:"flex"}}>
                                <Button disabled={presetsFlow} className="modal-okay-gray square font-14" style={{marginRight:"12px"}} onClick={() => {
                                    if (camera.length == 0) {
                                        window.history.back();
                                    } else {
                                        if(step == 0) {
                                            if (bulkFlowData)
                                                history.push({
                                                    pathname: '/bulk-configurations',
                                                    state: {
                                                    tabData: {
                                                        'tabKey': '4',
                                                        'customerName': bulkFlowData.customerNames[0],
                                                    },
                                                    },
                                                });
                                            else 
                                                setGoBackConfirmModal(true);
                                        }
                                        if (step == 1)
                                            setStep(0)
                                        if (step == 2)
                                        {
                                            if (bulkFlowData){
                                                setStep(0)
                                            }
                                            else {
                                                setStep(1)
                                            }
                                        }
                                    }
                                    }}>
                                    {step == 0 ? 'Cancel' : 'Back'}
                                </Button>
                                {
                                    step == 2 && !presetsFlow?
                                        <Button disabled={(siloRequestLoader || cardSaveFlowTriggered || loadingProgress)} className="modal-okay-blue invert square font-14" style={{marginRight:"12px"}} onClick={() => {
                                            setPresetModal(true);
                                        }}>
                                            { bulkFlowData ? "Save Preset" : (requestStatus == "allowed" || requestStatus == "always_allowed") ? "Save Preset & Confirm" :
                                            cardStatus == 0 && (requestStatus == "payment_required" || requestStatus == "not_allowed" || requestStatus == "update_subscription") ?"Enter Payment Details - Save Preset & Confirm" : "Save Preset & Submit"}  {siloRequestLoader ? <LoadingOutlined spin />:""}
                                        </Button>
                                        :
                                        step == 2?
                                        <Button disabled={(siloRequestLoader || cardSaveFlowTriggered || loadingProgress)} style={{marginRight:"12px"}} className="modal-okay-blue invert square font-14" onClick={ ()=> {
                                            confirmRequest(false, true);
                                            
                                        }}>
                                            Confirm Silos & Update Preset
                                            {siloRequestLoader ? <LoadingOutlined spin />:""}
                                        </Button>
                                        :
                                        ''
                                }
                                
                                {bulkFlowData && step == 2 ? '' : 
                                <Tooltip title={selectedCameras.length==0?'Please select cameras you would like to render':''}>
                                    <span>
                                        <Button  disabled={(siloRequestLoader || cardSaveFlowTriggered || loadingProgress || selectedCameras.length == 0)} className="modal-okay square font-14" onClick={() => {
                                                if(step == 0) {
                                                    let camera_index = 0;
                                                    let camera_name = ''
                                                    for (let index in camera) {
                                                        if (selectedCameras.includes(camera[index]['name'])){
                                                            camera_index = index;
                                                            camera_name = camera[index]['name'];
                                                            break;
                                                        }
                                                    }
                                                    inspectorChangePerspective(camera_name, camera_index)
                                                    bulkFlowData ? setStep(2) : setStep(1);
                                                }
                                                if (step == 1){
                                                    if (camerasLightingSelected.length < selectedCameras.length) {
                                                        setLightingModal(true)
                                                    }
                                                    else {
                                                        setStep(2)
                                                    }
                                                }
                                                if (step == 2 && camera.length>0)
                                                    confirmRequest()
                                            }}>
                                            {
                                            (
                                                step != 2
                                                ?
                                                "Confirm"
                                                :
                                                requestStatus == "allowed" || requestStatus == "always_allowed") ? "Confirm" :
                                                cardStatus == 0 && (requestStatus == "payment_required" || requestStatus == "not_allowed" || requestStatus == "update_subscription") ?"Confirm & Enter Payment Details" : "Submit"}  {siloRequestLoader ? <LoadingOutlined spin />:""}
                                        </Button>
                                    </span>
                                </Tooltip>}
                            </div>
                        </span>
                    </Footer>
              </div>
            </Row>

            <PaymentFailureModal
            paymentFailureMessage={paymentFailureMessage}
            setPaymentFailureModal={setPaymentFailureModal}
            updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
            paymentFailureModal={paymentFailureModal}
            />

            <SiloPhotographyModals
            {...props}
            closeAspectRatioModal={closeAspectRatioModal}
            aspectRatioModal={aspectRatioModal}
            aspectClicked={aspectClicked}
            orientation={orientation}
            orientationClicked={orientationClicked}
            orientationSettings={orientationSettings}
            selectOrientation={selectOrientation}
            setOrientationRadio={setOrientationRadio}
            selectAspectRatio={selectAspectRatio}
            selectedAspectRatio={selectedAspectRatio}
            onResolutionChange={onResolutionChange}
            resolutionStatus={resolutionStatus}
            imageResArr={imageResArr}
            onImageWidthRes={onImageWidthRes}
            customWRes={customWRes}
            onImageHeightRes={onImageHeightRes}
            customHRes={customHRes}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            onChangeWidth={onChangeWidth}
            onChangeHeight={onChangeHeight}
            snapshotModal={snapshotModal}
            showSnapshotModal={showSnapshotModal}
            setEditResFlag={setEditResFlag}
            restoreInformation={restoreInformation}
            editResFlag={editResFlag}
            snapshotTaken={snapshotTaken}
            modalShot={modalShot}
            changeOrientation={changeOrientation}
            setAspects={setAspects}
            changeAspectRatio={changeAspectRatio}
            onChangeWidthDynamically={onChangeWidthDynamically}
            onChangeHeightDynamically={onChangeHeightDynamically}
            dpi={dpi}
            onDPIChange={onDPIChange}
            fovSwitch = {fovSwitch}
            onFovSwitchChange={onFovSwitchChange}
            focalLengthValue={focalLengthValue}
            onImageFormatChange={onImageFormatChange}
            imageFormat={imageFormat}
            background={background}
            onBackgroundChange={onBackgroundChange}
            shadows={shadows}
            onChangeShadows={onChangeShadows}
            siloRequestLoader={siloRequestLoader}
            confirmRequest={confirmRequest}
            camera={camera}
            deleteSnapshot={deleteSnapshot}
            successModal={successModal}
            setSuccessModal={setSuccessModal}
            params={params}
            isTourOpen={isTourOpen}
            getCookie={getCookie}
            setCookie={setCookie}
            setIsTourOpen={setIsTourOpen}
            onChangeDefault={onChangeDefault}
            defaultChecked={defaultChecked}
            defaultLightRigCase={defaultLightRigCase}
            handleVisibleChange={handleVisibleChange}
            popoverDefault={popoverDefault}
            setPopoverDefault={setPopoverDefault}
            setGoBackConfirmModal={setGoBackConfirmModal}
            goBackConfirmModal={goBackConfirmModal}
            onChangeFov={onChangeFov}
            setFocalLengthRange={setFocalLengthRange}
            onChangeFocalLength={onChangeFocalLength}
            fovValue={fovValue}
            focalLengthRange = {focalLengthRange}
            discardSnapshot={discardSnapshot}
            defaultSilosModal={defaultSilosModal}
            showDefaultSilosModal={showDefaultSilosModal}
            setDefaultLoader={setDefaultLoader}
            defaultLoader={defaultLoader}
            leftPerspectiveScroll = {leftPerspectiveScroll}
            rightPerspectiveScroll = {rightPerspectiveScroll}
            perspectiveImages = {perspectiveImages}
            perspective = {perspective}
            perspectiveLabels = {perspectiveLabels}
            setDefaultSettingsState = {setDefaultSettingsState}
            defaultSettingsState = {defaultSettingsState}
            overlaySettingsModal={overlaySettingsModal}
            tempSnapshotOverlay={tempSnapshotOverlay}
            handleDiscardOverlaySettings={handleDiscardOverlaySettings}
            setShowOverlaySwitch={setShowOverlaySwitch}
            overlaySwitch={overlaySwitch}
            onChangeOverlayOpacity={onChangeOverlayOpacity}
            overlayOpacity={overlayOpacity}
            onChangeOverlayColor={onChangeOverlayColor}
            overlayColor={overlayColor}
            onChangeOverlayHeight={onChangeOverlayHeight}
            overlayHeight={overlayHeight}
            onChangeOverlayWidth={onChangeOverlayWidth}
            overlayWidth={overlayWidth}
            onChangeOverlayUnit={onChangeOverlayUnit}
            overlaySizeUnit={overlaySizeUnit}
            handleApplyOverlaySettings={handleApplyOverlaySettings}
            dropShadows = {dropShadows}
            onChangeDropShadows={onChangeDropShadows}
            dropShadowShot = {dropShadowShot}
            regularShadowShot = {regularShadowShot}
            isFloorItem = {isFloorItem}
            overlayLimit={overlayLimit}
            setTempOverlayLines={setTempOverlayLines}
            cardStatus={cardStatus}
            requestStatus={requestStatus}
            returnPrice={returnPrice}
            setSiloRequestLoader={setSiloRequestLoader}
            totalPrice={totalPrice}
            cardSaveDrawer={cardSaveDrawer}
            setCardSaveDrawer={setCardSaveDrawer}
            saveRequestIntermedietely={saveRequestIntermedietely}
            setCardSaveFailure={setCardSaveFailure}
            subscriptionModal={subscriptionModal}
            subscriptionLoader={subscriptionLoader}
            upgradeSubscription={upgradeSubscription}
            cancelSubscriptionModal={cancelSubscriptionModal}
            currentPackage={currentPackage}
            isSubscriptionActive={isSubscriptionActive}
            subscriptionPlan={subscriptionPlan}
            defaultRequested={defaultRequested}
            setSubscriptionPlan={setSubscriptionPlan}
            cardSaveFlowTriggered={cardSaveFlowTriggered}
            inAppLimitExceededModal={inAppLimitExceededModal}
            setInAppLimitExceededModal={setInAppLimitExceededModal}
            resetPaymentsOnCancel={resetPaymentsOnCancel}
            setTakeSiloShotModal={setTakeSiloShotModal}
            takeSiloShotModal={takeSiloShotModal}
            productInfo={productInfo}
            loadingProgress={loadingProgress}
            panToggle={panToggle}
            onChangeToggle={onChangeToggle}
            openOrientationModal={openOrientationModal}
            OpenOverlaySettingsModal={OpenOverlaySettingsModal}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            setFullscreen={setFullscreen}
            isFullscreen={isFullscreen}
            blindRendersState= {blindRendersState}
            resetCamera = {resetCamera}
            rightScroll = {rightScroll}
            showGridForSilos={showGridForSilos}
            gridState={gridState}
            alignCameraForSilos={alignCameraForSilos}
            openSnapshotModal={openSnapshotModal}
            cameraSelected={cameraSelected}
            setPolarAngle={setPolarAngle}
            setCameraHeight={setCameraHeight}
            getCameraAngle={getCameraAngle}
            setCameraAngle={setCameraAngle}
            getCameraHeight={getCameraHeight}
            inspector={inspector}
            changePerspective={changePerspective}
            loadingProgressSnapshot={loadingProgressSnapshot}
            setLoadingProgressSnapshot={setLoadingProgressSnapshot}
            cameraDisplayName={cameraDisplayName}
            setCameraDisplayName={setCameraDisplayName}
            setPresetModal={setPresetModal}
            presetModal={presetModal}
            presetName={presetName}
            setPresetName={setPresetName}
            defaultResolutions={defaultResolutions}
            setOpenSiloPresetModal={setOpenSiloPresetModal}
            openSiloPresetModal={openSiloPresetModal}
            allPresets={allPresets}
            setSelectedPreset={setSelectedPreset}
            selectedPreset={selectedPreset}
            editCamera={editCamera}
            snapshotUpdate={snapshotUpdate}
            changeCameraState={changeCameraState}
            setCameraSelected={setCameraSelected}
            lightingModal={lightingModal}
            setLightingModal={setLightingModal}
            setStep={setStep}
            resetZoom={resetZoom}
            savePresetLoader = {savePresetLoader}
            customLightingModal={customLightingModal}
            setCustomLightingModal={setCustomLightingModal}
            customLightingRigName={customLightingRigName}
            setCustomLightingRigName={setCustomLightingRigName}
            setCustomLightingRigComment={setCustomLightingRigComment}
            customLightingRigComment={customLightingRigComment}
            customLightingModalStep={customLightingModalStep}
            setCustomLightingModalStep={setCustomLightingModalStep}
            customLightingImages={customLightingImages}
            setCustomLightingImages={setCustomLightingImages}
            platform={platform}
            setFileUploaded={setFileUploaded}
            fileUploaded={fileUploaded}
            />
            <div id="silo-inspector" style={{cursor: props.panToggle ? "move" : "default", position: "fixed", left:"1",top:"1", zIndex:"-10"}}/>
        </div>
        
    )
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    updateProduct: (product) => {
        dispatch(updateProduct(product));
    },
    listProducts: () => {
        dispatch(listProducts());
    },
    rejectProduct: (product) => {
        dispatch(rejectProduct(product))
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SiloTool))