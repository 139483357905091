export const FETCH_DASHBOARD = 'FETCH_WHISKYS';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_WHISKYS_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_WHISKYS_FAILURE';

export const fetchDashboard = (payload) => ({
  type: FETCH_DASHBOARD,
  payload: payload,
});

export const fetchDashboardSuccess = (payload) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: payload,
});

export const fetchDashboardFailure = (message) => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: message,
});
