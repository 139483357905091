import React from 'react'
import { Col, Row, Switch } from 'antd'
import LightingSetting from './LightingSetting'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export default function SiloLighting(props) {
  return (    
        <>
            <Row>
                <Col style={{margin:"1%", background:''+props.hexColor+'', borderRadius:"4px", textAlignLast:'center', height:"94%"}} span={16}>
                    <div style={{height:"20%"}}>
                      {
                        props.camera[props.perspective]['lighting']!="default" && props.lightingData[props.camera[props.perspective]['lighting']]?.map((light) => {
                          if(light.direction == "top") {
                            return (
                              <img src='/img/light_top.png' style={{position:"absolute", height:`calc(${light.factor}*18vh)`, left:"48%", top:"5%"}} />
                            )
                          }
                          if(light.direction.includes("north")){
                            return (
                              <img src='/img/light_back.png' style={{position:"absolute", height:`calc(${light.factor}*7vh)`, left:"48%", top:"10%"}} />
                            )
                          }
                        })
                      }
                      {
                        !props.shadowsRig && props.camera[props.perspective]['lighting']=="default" && props.defaultRigLightPositions?.map((light) => {
                          if(light.direction == "top") {
                            return (
                              <img src='/img/light_top.png' style={{position:"absolute", height:`calc(${light.factor}*18vh)`, left:"48%", top:"5%"}} />
                            )
                          }
                          if(light.direction.includes("north")){
                            return (
                              <img src='/img/light_back.png' style={{position:"absolute", height:`calc(${light.factor}*7vh)`, left:"48%", top:"10%"}} />
                            )
                          }
                        })
                      }
                      {
                        props.shadowsRig && props.camera[props.perspective]['lighting']=="default" && props.defaultShadowRigLightPositions?.map((light) => {
                          if(light.direction == "top") {
                            return (
                              <img src='/img/light_top.png' style={{position:"absolute", height:`calc(${light.factor}*18vh)`, left:"48%", top:"5%"}} />
                            )
                          }
                          if(light.direction.includes("north")){
                            return (
                              <img src='/img/light_back.png' style={{position:"absolute", height:`calc(${light.factor}*7vh)`, left:"48%", top:"10%"}} />
                            )
                          }
                        })
                      }
                    </div>
                    <div style={{width:"100%", height:"60%", display:"flex"}}>
                      <div style={{width:"20%", height:"100%"}}> 
                      {
                        props.camera[props.perspective]['lighting']!="default" && props.lightingData[props.camera[props.perspective]['lighting']]?.map((light) => {
                          if(light.direction.includes("west")) {
                            return (
                              <img src='/img/light_left.png' style={{position:"absolute", width:`calc(${light.factor}*8vw)`, top:"30%", left:"1%"}} />
                            )
                          }
                        })
                      }
                      {
                        !props.shadowsRig && props.camera[props.perspective]['lighting']=="default" && props.defaultRigLightPositions?.map((light) => {
                          if(light.direction.includes("west")) {
                            return (
                              <img src='/img/light_left.png' style={{position:"absolute", width:`calc(${light.factor}*8vw)`, top:"30%", left:"1%"}} />
                            )
                          }
                        })
                      }
                      {
                        props.shadowsRig && props.camera[props.perspective]['lighting']=="default" && props.defaultShadowRigLightPositions?.map((light) => {
                          if(light.direction.includes("west")) {
                            return (
                              <img src='/img/light_left.png' style={{position:"absolute", width:`calc(${light.factor}*8vw)`, top:"30%", left:"1%"}} />
                            )
                          }
                        })
                      }
                      </div>
                      <img src={props.modalShot} style={{width:"60%", height:"100%", objectFit:"contain"}} id="snapshot-default-img" />
                      <div style={{width:"20%", height:"100%"}}>
                      {
                        props.camera[props.perspective]['lighting']!="default" && props.lightingData[props.camera[props.perspective]['lighting']]?.map((light) => {
                          if(light.direction.includes("east")) {
                            return (
                              <img src='/img/light_right.png' style={{position:"absolute", width:`calc(${light.factor}*8vw)`, top:"30%", right:"1%"}} />
                            )
                          }
                        })
                      }
                      {
                        !props.shadowsRig && props.camera[props.perspective]['lighting']=="default" && props.defaultRigLightPositions?.map((light) => {
                          if(light.direction.includes("east")) {
                            return (
                              <img src='/img/light_right.png' style={{position:"absolute", width:`calc(${light.factor}*8vw)`, top:"30%", right:"1%"}} />
                            )
                          }
                        })
                      }
                      {
                        props.shadowsRig && props.camera[props.perspective]['lighting']=="default" && props.defaultShadowRigLightPositions?.map((light) => {
                          if(light.direction.includes("east")) {
                            return (
                              <img src='/img/light_right.png' style={{position:"absolute", width:`calc(${light.factor}*8vw)`, top:"30%", right:"1%"}} />
                            )
                          }
                        })
                      }
                      </div>                      
                    </div>
                    {
                      props.selectedCameras.length > 3 ?
                        <Carousel infinite={false} autoPlay={false} autoPlaySpeed={100000000} itemClass='item-carousel-silo' containerClass='carousel-container-lighting' responsive={responsive}>
                          {
                              props.camera.filter( (cam) => props.selectedCameras.includes(cam.name))?.map( (view,index) => (
                                <img onClick={() => {
                                    let cam_index = 0
                                    for ( let ind in props.camera ) {
                                      if (props.camera[ind]['name'] == view.name) {
                                        cam_index = ind;
                                        break
                                      }
                                    }
                                    props.changePerspective(view.name,cam_index)
                                }} className={props.camera[props.perspective]['name'] == view.name ? 'img-carousel-silo-selected' :'img-carousel-silo'} src={view.thumbnail} />
                              ))
                          }
                      </Carousel>
                      :
                      <div className='carousel-container-lighting' style={{placeContent:"center"}}>
                        {
                          props.camera.filter( (cam) => props.selectedCameras.includes(cam.name))?.map( (view,index) => (
                            <div className='item-carousel-silo'>
                              <img onClick={() => {
                                  let cam_index = 0
                                  for ( let ind in props.camera ) {
                                    if (props.camera[ind]['name'] == view.name) {
                                      cam_index = ind;
                                      break
                                    }
                                  }
                                  props.changePerspective(view.name,cam_index)
                              }} className={props.camera[props.perspective]['name'] == view.name ? 'img-carousel-silo-selected' :'img-carousel-silo'} src={view.thumbnail} />
                            </div>
                          ))
                        }
                      </div>
                    }
                    
                    <div style={{position:"absolute", right:"2%", top:"1%"}} className='switch-flex'>
                        <span className='manrope f-14 black'>
                            Contact Shadows
                        </span>
                        <Switch  style={{marginLeft: 8}} checked={props.dropShadows} disabled={ props.shadowsRig|| !props.isFloorItem}  onChange={props.onChangeDropShadows} />
                    </div>
                    
                </Col>
                <Col span={7} style={{display:"flex", flexDirection:"column", justifyContent:"space-evenly", margin:"10px 0px"}}>
                    <LightingSetting  {...props} />
                </Col>
            </Row>
                
        </>
  )
}
