import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Row, Col } from 'antd';
import AssetButtons from './AssetButtons';


const ProductActionBar = () => {
    const { showBottomBar } = useContext(CustomerProductContext);

    return (showBottomBar && <Row className={`product-action-bottom-bar`}>
        <Col className="product-col">
            <div className="button-container">
                <AssetButtons up={true} />
            </div>
        </Col>
    </Row>
    );
};

export default ProductActionBar;