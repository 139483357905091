export default {
  chart: {
    width: '400',
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
  },
  title: {
    text: 'Products<br>total<br>200',
    align: 'center',
    verticalAlign: 'middle',
    y: 30,
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
  },
  accessibility: {
    point: {
      valueSuffix: '%',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        distance: 0,
        style: {
        },
      },
      startAngle: 0,
      endAngle: 0,
      // center: ['50%', '75%'],
      size: '60%',
    },
  },
  series: [{
    type: 'pie',
    name: 'Products',
    innerSize: '80%',
    data: [
      {
        name: 'In progress',
        y: 58.9,
        color: '#DA3926',
      },
      {
        name: 'QA pending',
        y: 14.9,
        color: '#DA3926',
      },
      {
        name: 'Approved',
        y: 12.9,
        color: '#DA3926',
      },
      {
        name: 'Rejected',
        y: 7.61,
        color: '#DA3926',
      },
    ],
  }],
};
