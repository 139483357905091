import React from 'react';
import CustomerMainLayout from '../CustomerMainLayout'
import CustomerAccountUsage from '../CustomerAccountUsage/CustomerAccountUsage';

const CustomerSystemUsage = (props) => {
    
    return (
        <CustomerMainLayout selected='17'>
            <CustomerAccountUsage/>
        </CustomerMainLayout>
      );

}

export default CustomerSystemUsage;
  