import * as THREE from "three";

var RotationControls = function ( camera, domElement ) {
    if ( domElement === undefined ) {
        domElement = document;
    }

    THREE.Object3D.call( this );

    this.visible = false;
    this.domElement = domElement;

    var _yGizmo = new RotationGizmo( "Y" );
    this.add( _yGizmo );

    var _xGizmo = new RotationGizmo( "X" );
    this.add( _xGizmo );

    var _plane = new RotationHelperPlane();
    this.add( _plane );

    var scope = this;

    defineProperty( "camera", camera );
    defineProperty( "object", undefined );
    defineProperty( "enabled", true );
    defineProperty( "active", false );
    defineProperty( "currentAxis", "Y" );
    defineProperty( "rotationSnap", 0.0872665 ); // 5 Degrees
    defineProperty( "dragging", false );
    defineProperty( "size", ( new THREE.Vector3( 1, 1, 1 ) ) );

    var changeEvent = { type: "change" };
    var mouseDownEvent = { type: "mouseDown" };
    var mouseUpEvent = { type: "mouseUp" };
    var objectChangeEvent = { type: "objectChange" };

    var ray = new THREE.Raycaster();

    var _unit = {
        X: new THREE.Vector3( 1, 0, 0 ),
        Y: new THREE.Vector3( 0, 1, 0 ),
        Z: new THREE.Vector3( 0, 0, 1 )
    };

    var pointStart = new THREE.Vector3();
    var pointEnd = new THREE.Vector3();

    var cameraPosition = new THREE.Vector3();
    var cameraQuaternion = new THREE.Quaternion();
    var cameraScale = new THREE.Vector3();

    var worldPositionStart = new THREE.Vector3();
    var worldQuaternionStart = new THREE.Quaternion();
    var worldScaleStart = new THREE.Vector3();

    var worldPosition = new THREE.Vector3();
    var worldQuaternion = new THREE.Quaternion();
    var worldScale = new THREE.Vector3();
    var worldDirection = new THREE.Vector3();

    var eye = new THREE.Vector3();

    // TODO: remove properties unused in plane and gizmo

    defineProperty( "worldPosition", worldPosition );
    defineProperty( "worldPositionStart", worldPositionStart );
    defineProperty( "worldQuaternion", worldQuaternion );
    defineProperty( "worldQuaternionStart", worldQuaternionStart );
    defineProperty( "cameraPosition", cameraPosition );
    defineProperty( "cameraQuaternion", cameraQuaternion );
    defineProperty( "pointStart", pointStart );
    defineProperty( "pointEnd", pointEnd );
    defineProperty( "eye", eye );

    {

        domElement.addEventListener( "mousedown", onPointerDown, false );
        domElement.addEventListener( "touchstart", onPointerDown, false );
        domElement.addEventListener( "mousemove", onPointerHover, false );
        domElement.addEventListener( "touchmove", onPointerHover, false );
        domElement.addEventListener( "touchmove", onPointerMove, false );
        domElement.addEventListener( "mouseup", onPointerUp, false );
        domElement.addEventListener( "touchend", onPointerUp, false );
        domElement.addEventListener( "touchcancel", onPointerUp, false );
        domElement.addEventListener( "touchleave", onPointerUp, false );

    }

    this.dispose = function () {

        domElement.removeEventListener( "mousedown", onPointerDown );
        domElement.removeEventListener( "touchstart", onPointerDown );
        domElement.removeEventListener( "mousemove", onPointerHover );
        document.removeEventListener( "mousemove", onPointerMove );
        domElement.removeEventListener( "touchmove", onPointerHover );
        domElement.removeEventListener( "touchmove", onPointerMove );
        domElement.removeEventListener( "mouseup", onPointerUp );
        domElement.removeEventListener( "touchend", onPointerUp );
        domElement.removeEventListener( "touchcancel", onPointerUp );
        domElement.removeEventListener( "touchleave", onPointerUp );

        this.traverse( function ( child ) {

            if ( child.geometry ) child.geometry.dispose();
            if ( child.material ) child.material.dispose();

        } );

    };

    // Set current object
    this.attach = function ( object ) {

        this.object = object;
        this.visible = true;

        this.size = object.userData.size.clone();

        this.size.z = Math.max(this.size.x, this.size.z);

        let minSize = 0.1;

        if ( this.size.y < minSize ) {

            this.size.y = minSize;
        }

        if ( this.size.z < minSize ) {

            this.size.z = minSize;
        }
        
        _yGizmo.updateGeometry();
        _xGizmo.updateGeometry();

        this.updateMatrixWorld();

        return this;

    };

    // Detatch from object
    this.detach = function () {

        this.object = undefined;
        this.visible = false;
        this.active = false;

        return this;

    };

    // Defined getter, setter and store for a property
    function defineProperty( propName, defaultValue ) {

        var propValue = defaultValue;

        Object.defineProperty( scope, propName, {

            get: function () {

                return propValue !== undefined ? propValue : defaultValue;

            },

            set: function ( value ) {

                if ( propValue !== value ) {

                    propValue = value;
                    _plane[ propName ] = value;
                    _yGizmo[ propName ] = value;
                    _xGizmo[ propName ] = value;

                    scope.dispatchEvent( { type: propName + "-changed", value: value } );
                    scope.dispatchEvent( changeEvent );

                }

            }

        } );

        scope[ propName ] = defaultValue;
        _plane[ propName ] = defaultValue;
        _yGizmo[ propName ] = defaultValue;
        _xGizmo[ propName ] = defaultValue;

    }

    // updateMatrixWorld  updates key transformation variables
    this.updateMatrixWorld = function () {

        if ( this.object !== undefined ) {

            this.object.updateMatrixWorld();
            this.object.matrixWorld.decompose( worldPosition, worldQuaternion, worldScale );
            this.position.copy(worldPosition);
            _xGizmo.quaternion.copy(worldQuaternion);
            var yRotation = Math.atan2(2 * (worldQuaternion.y * worldQuaternion.w - worldQuaternion.x * worldQuaternion.z), 1 - 2 * (worldQuaternion.y ** 2 + worldQuaternion.z ** 2));
            _yGizmo.rotation.set(0, yRotation, 0);
        }

        this.camera.updateMatrixWorld();
        this.camera.matrixWorld.decompose( cameraPosition, cameraQuaternion, cameraScale );

        eye.copy( cameraPosition ).sub( worldPosition ).normalize();

        THREE.Object3D.prototype.updateMatrixWorld.call( this );

    };

    this.pointerHover = function ( pointer ) {

        if ( this.object === undefined || this.dragging === true || ( pointer.button !== undefined && pointer.button !== 0 ) ) return;

        ray.setFromCamera( pointer, this.camera );

        let intersectTargets = [];

        if ( _xGizmo.enabled ) {

            intersectTargets.push( _xGizmo.picker );

        }

        if ( _yGizmo.enabled ) {

            intersectTargets.push( _yGizmo.picker );

        }

        if ( intersectTargets.length == 0 ) {

            this.active = false;
            return;
            
        }

        var intersect = ray.intersectObjects( intersectTargets, true )[ 0 ] || false;

        if ( intersect ) {
            this.active = true;

            if ( intersect.object == _xGizmo.picker ) {
                this.currentAxis = "X";
            }

            else if ( intersect.object == _yGizmo.picker ) {
                this.currentAxis = "Y";
            }

        } else {
            this.active = false;
        }

    };

    this.pointerDown = function ( pointer ) {

        if ( this.object === undefined || this.dragging === true || ( pointer.button !== undefined && pointer.button !== 0 ) ) return;

        if ( ( pointer.button === 0 || pointer.button === undefined ) && this.active ) {

            ray.setFromCamera( pointer, this.camera );

            var planeIntersect = ray.intersectObjects( [ _plane ], true )[ 0 ] || false;

            if ( planeIntersect ) {
                this.object.updateMatrixWorld();
                this.object.parent.updateMatrixWorld();

                this.object.matrixWorld.decompose( worldPositionStart, worldQuaternionStart, worldScaleStart );

                pointStart.copy( planeIntersect.point ).sub( worldPositionStart );
                pointStart.normalize();

            }

            this.dragging = true;
            this.dispatchEvent( mouseDownEvent );

        }

    };

    this.pointerMove = function ( pointer ) {

        var active = this.active;
        var object = this.object;

        if ( object === undefined || active == false || this.dragging === false || ( pointer.button !== undefined && pointer.button !== 0 ) ) return;

        ray.setFromCamera( pointer, this.camera );

        var planeIntersect = ray.intersectObjects( [ _plane ], true )[ 0 ] || false;

        if ( planeIntersect === false ) return;

        if ( this.currentAxis == 'X' ) {

            this.object.matrixWorld.decompose( worldPositionStart, worldQuaternionStart, worldScaleStart );
            pointEnd.copy( planeIntersect.point ).sub( worldPositionStart );
            pointEnd.normalize();

            let dotProduct = THREE.MathUtils.clamp( ( pointEnd.clone().dot( pointStart ) ), -1.0, 1.0 );
            let rotationDirection = pointEnd.clone().cross( pointStart );
            rotationDirection.applyQuaternion( object.quaternion.clone().inverse() );
            let angle = Math.acos( dotProduct );

            rotationDirection.x > 0 ? object.rotateOnAxis( _unit.X, -angle ) : object.rotateOnAxis( _unit.X, angle );
            
            pointStart.copy ( pointEnd );

        }

        if ( this.currentAxis == 'Y' ) {

            object.matrixWorld.decompose( worldPositionStart, worldQuaternionStart, worldScaleStart );
            pointEnd.copy( planeIntersect.point ).sub( worldPositionStart );
            pointEnd.normalize();

            let dotProduct = THREE.MathUtils.clamp( ( pointEnd.clone().dot( pointStart ) ), -1.0, 1.0 );

            if( dotProduct < 0.975 ) {
                this.snapped = false;
            }

            let rotationDirection = pointEnd.clone().cross( pointStart ).y;
            let angle = Math.acos( dotProduct );

            const parent = object.parent;
            this.parent.attach(object);
            rotationDirection > 0 ? object.rotateOnWorldAxis( _unit.Y, -angle ) : object.rotateOnWorldAxis( _unit.Y, angle );
            parent.attach(object);
            object.getWorldDirection( worldDirection );

            // if ( !_xGizmo.enabled ) {

            //     if ( worldDirection.x > 0.975 || worldDirection.x < -0.975 ) {

            //         let dir = new THREE.Vector3();
                    
            //         dir.x = worldDirection.x > 0 ? 1 : -1;
            //         dir.y = 0;
            //         dir.z = 0;
            //         object.lookAt( worldPositionStart.clone().add( dir ) );
            //         this.snapped = true;
            //     }

            //     else if ( worldDirection.z > 0.975 || worldDirection.z < -0.975 ) {

            //         let dir = new THREE.Vector3();

            //         dir.x = 0;
            //         dir.y = 0;
            //         dir.z = worldDirection.z > 0 ? 1 : -1;
            //         object.lookAt( worldPositionStart.clone().add( dir ) );
            //         this.snapped = true;
            //     }

            // }

            if( !this.snapped ) {
                pointStart.copy ( pointEnd );    
            }
            else {
                pointStart.copy ( worldDirection );
            }

        }

        

        this.dispatchEvent( changeEvent );
        this.dispatchEvent( objectChangeEvent );
    };

    this.pointerUp = function ( pointer ) {

        if ( pointer.button !== undefined && pointer.button !== 0 ) return;

        if ( this.dragging && this.active ) {

            this.dispatchEvent( mouseUpEvent );

        }

        this.dragging = false;

        if ( pointer.button === undefined ) this.active = false;

    };

    // normalize mouse / touch pointer and remap {x,y} to view space.

    function getPointer( event ) {

        if ( document.pointerLockElement ) {

            return {
                x: 0,
                y: 0,
                button: event.button
            };

        } else {

            var pointer = event.changedTouches ? event.changedTouches[ 0 ] : event;

            var rect = domElement.getBoundingClientRect();

            return {
                x: ( pointer.clientX - rect.left ) / rect.width * 2 - 1,
                y: - ( pointer.clientY - rect.top ) / rect.height * 2 + 1,
                button: event.button
            };

        }

    }

    // mouse / touch event handlers

    function onPointerHover( event ) {

        if ( ! scope.enabled ) return;

        scope.pointerHover( getPointer( event ) );

    }

    function onPointerDown( event ) {

        if ( ! scope.enabled ) return;

        document.addEventListener( "mousemove", onPointerMove, false );

        scope.pointerHover( getPointer( event ) );
        scope.pointerDown( getPointer( event ) );

    }

    function onPointerMove( event ) {

        if ( ! scope.enabled ) return;

        scope.pointerMove( getPointer( event ) );

    }

    function onPointerUp( event ) {

        if ( ! scope.enabled ) return;

        document.removeEventListener( "mousemove", onPointerMove, false );

        scope.pointerUp( getPointer( event ) );

    }

    this.setRotationSnap = function ( rotationSnap ) {

        scope.rotationSnap = THREE.MathUtils.degToRad( rotationSnap );

    };

    this.showAxis = function ( axis, visible ) {

        if ( axis == "X" ) {

            _xGizmo.enabled = visible;

        }

        else if ( axis == "Y" ) {

            _yGizmo.enabled = visible;

        }
    }


};

RotationControls.prototype = Object.assign( Object.create( THREE.Object3D.prototype ), {
    constructor: RotationControls,
} );

var RotationGizmo = function ( axis ) {
    'use strict';

    THREE.Object3D.call( this );

    this.type = 'RotationControlsGizmo';
    this.axis = axis;

    var scope = this;

    var gizmoMaterial = new THREE.MeshBasicMaterial( {
        depthTest: false,
        depthWrite: false,
        transparent: true,
        side: THREE.DoubleSide,
        fog: false
    } );

    var matInvisible = gizmoMaterial.clone();
    matInvisible.opacity = 0.0;

    var helperMaterial_X = gizmoMaterial.clone();
    helperMaterial_X.color.set(0xff0000);

    var helperMaterial_Y = gizmoMaterial.clone();
    helperMaterial_Y.color.set(0xffff00);

    var getProcessedMeshData = function ( geometryMap ) {
        let dummyObject = new THREE.Object3D();

        let objMaterial = geometryMap[1];
        let objPosition = geometryMap[2];
        let objRotation = geometryMap[3];

        if ( objPosition ) {
            dummyObject.position.set( objPosition[0], objPosition[1], objPosition[2] );
        }

        if ( objRotation ) {
            dummyObject.rotation.set( objRotation[0], objRotation[1], objRotation[2] );
        }

        dummyObject.updateMatrix();

        let tempGeometry = geometryMap[ 0 ];
        tempGeometry.applyMatrix4( dummyObject.matrix );

        let meshObj = new THREE.Mesh( tempGeometry, objMaterial );
        meshObj.renderOrder = Infinity;
        meshObj.name = "rotationControl";
        return meshObj;
    };

    var cleanUp = function () {

        scope.remove( scope.gizmoTorus );
        scope.remove( scope.gizmoArrow1 );
        scope.remove( scope.gizmoArrow2 );
        scope.remove( scope.gizmoSphere );
        scope.remove( scope.picker );

        if ( scope.gizmoTorus ) {
            scope.gizmoTorus.geometry.dispose();
            scope.gizmoTorus.material.dispose();
        }

        if ( scope.gizmoArrow1 ) {
            scope.gizmoArrow1.geometry.dispose();
            scope.gizmoArrow1.material.dispose();
        }

        if ( scope.gizmoArrow2 ) {
            scope.gizmoArrow2.geometry.dispose();
            scope.gizmoArrow2.material.dispose();
        }

        if ( scope.gizmoSphere ) {
            scope.gizmoSphere.geometry.dispose();
            scope.gizmoSphere.material.dispose();
        }

        if ( scope.picker ) {
            scope.picker.geometry.dispose();
            scope.picker.material.dispose();
        }
    };

    var setupGeometry = function () {

        cleanUp();

        let height;
        let depth;

        let torusMap; 
        let arrow1Map; 
        let arrow2Map; 
        let sphereMap; 
        let pickerMap;

        if ( scope.size == undefined ) {

            height = 1;
            depth = 1;

        }

        else {

            height = scope.size.y;
            depth = scope.size.z;

        }

        if ( scope.axis == "X" ) {

            torusMap = [ new THREE.TorusBufferGeometry(1 * height, 0.02 * height, 8, 20, Math.PI / 2), helperMaterial_X, null, [ Math.PI / 4, Math.PI / 2, 0 ] ] ; 
            arrow1Map = [ new THREE.ConeBufferGeometry(0.065 * height, 0.2 * height, 10), helperMaterial_X , [ 0, 0.65 * height, 0.76 * height], [ 0, Math.PI / 2, Math.PI / 1.3334 ] ]; 
            arrow2Map = [ new THREE.ConeBufferGeometry(0.065 * height, 0.2 * height, 10), helperMaterial_X , [ 0, 0.65 * height, -0.76 * height], [ 0, Math.PI / 2, -Math.PI / 1.3334 ] ]; 
            sphereMap = [ new THREE.SphereBufferGeometry(0.07 * depth, 10, 10), helperMaterial_X , [0, 1.0 * height, 0], null ]; 
            pickerMap = [ new THREE.TorusBufferGeometry(1 * height, 0.07 * height, 8, 20, Math.PI / 1.5), matInvisible, null, [ Math.PI / 6, Math.PI / 2, 0 ] ] ; 

        }

        if ( scope.axis == "Y" ) {

            torusMap = [ new THREE.TorusBufferGeometry(1 * depth, 0.02 * depth, 8, 20, Math.PI / 2), helperMaterial_Y, null, [ Math.PI / 2 , 0, Math.PI / 4 ] ] ;
            arrow1Map = [ new THREE.ConeBufferGeometry(0.065 * depth, 0.2 * depth, 10), helperMaterial_Y , [-0.76 * depth, 0, 0.65 * depth], [ Math.PI / 2, 0, Math.PI / 1.3334 ] ];
            arrow2Map = [ new THREE.ConeBufferGeometry(0.065 * depth, 0.2 * depth, 10), helperMaterial_Y , [0.76 * depth, 0, 0.65 * depth], [ Math.PI / 2, 0, -Math.PI / 1.3334 ] ];
            sphereMap = [ new THREE.SphereBufferGeometry(0.07 * depth, 10, 10), helperMaterial_Y , [0, 0, 1.0 * depth], null ];
            pickerMap = [ new THREE.TorusBufferGeometry(1 * depth, 0.07 * depth, 8, 20, Math.PI / 1.5), matInvisible, null, [ Math.PI / 2 , 0, Math.PI / 6 ] ] ;

        }

        scope.gizmoTorus = getProcessedMeshData( torusMap );
        scope.gizmoArrow1 = getProcessedMeshData( arrow1Map );
        scope.gizmoArrow2 = getProcessedMeshData( arrow2Map );
        scope.gizmoSphere = getProcessedMeshData( sphereMap );
        scope.picker = getProcessedMeshData( pickerMap );

        if ( scope.axis == "X" ) { scope.picker.name = "X_Picker"; }
        else if ( scope.axis == "Y" ) { scope.picker.name = "Y_Picker"; }

        scope.add( scope.gizmoTorus );
        scope.add( scope.gizmoArrow1 );
        scope.add( scope.gizmoArrow2 );
        scope.add( scope.gizmoSphere );
        scope.add( scope.picker );
    }

    // Gizmo creation
    setupGeometry( );

    // updateMatrixWorld will update transformations and appearance of individual handles

    this.updateMatrixWorld = function () {

        if ( this.enabled ) {

            this.visible = true;

            var handles = [];
            handles = handles.concat( this.gizmoTorus, this.gizmoArrow1, this.gizmoArrow2, this.gizmoSphere, this.picker );
            
            for ( var i = 0; i < handles.length; i ++ ) {

                var handle = handles[ i ];

                if( this.object != undefined ) {
                    var eyeDistance = this.worldPosition.distanceTo( this.cameraPosition );
                    let length = this.object.userData.size.length();
                    let scalarValue = 1.0;

                    if ( eyeDistance < ( length * 3 ) ) {
                        scalarValue = eyeDistance / ( length * 3 );
                    }
                    
                    handle.scale.set( 1, 1, 1 ).multiplyScalar( scalarValue );
                }
                else {
                    handle.scale.set( 1, 1, 1 );
                }

                // highlight active controls

                if ( handle != this.picker ) {

                    handle.visible = true;
                    
                    if ( this.active && this.axis == this.currentAxis ) { 
                        handle.material.color.lerp( new THREE.Color( 1, 1, 1 ), 0.5 ); 
                    } 
                    else { 
                        if ( this.axis == "X" ) { 
                            handle.material.color.lerp( new THREE.Color( 1, 0, 0 ), 0.5 ); 
                        } 
                        else if ( this.axis == "Y" ) { 
                            handle.material.color.lerp( new THREE.Color( 1, 1, 0 ), 0.5 ); 
                        } 
                    } 
                }
            }

        }

        else {

            this.visible = false;

        }

        THREE.Object3D.prototype.updateMatrixWorld.call( this );

    };

    this.updateGeometry = function () {
        setupGeometry();
    };
};

RotationGizmo.prototype = Object.assign( Object.create( THREE.Object3D.prototype ), {
    constructor: RotationGizmo,
    isRotationGizmo: true
} );

var RotationHelperPlane = function() {
    'use strict';

    THREE.Mesh.call( this,
        new THREE.PlaneBufferGeometry( 100000, 100000, 2, 2 ),
        new THREE.MeshBasicMaterial( { visible: false, wireframe: true, side: THREE.DoubleSide, transparent: true, opacity: 0.1 } )
    );

    this.type = 'RotationHelperPlane';

    var unitX = new THREE.Vector3( 1, 0, 0 );
    var unitY = new THREE.Vector3( 0, 1, 0 );
    var unitZ = new THREE.Vector3( 0, 0, 1 );

    var tempVector = new THREE.Vector3();
    var dirVector = new THREE.Vector3();
    var alignVector = new THREE.Vector3();
    var tempMatrix = new THREE.Matrix4();

    this.updateMatrixWorld = function () {

        if ( !this.enabled ) {
            return;
        }

        if ( this.currentAxis == "X" ) {

            this.quaternion.copy( this.object.quaternion );
            this.rotateOnAxis( unitY, Math.PI / 2.0 );

        }

        else if ( this.currentAxis == "Y" ) {

            alignVector.copy( unitZ );
            dirVector.copy( unitY );

            tempMatrix.lookAt( tempVector.set( 0, 0, 0 ), dirVector, alignVector );
            this.quaternion.setFromRotationMatrix( tempMatrix );

        }

        THREE.Object3D.prototype.updateMatrixWorld.call( this );

    };
};

RotationHelperPlane.prototype = Object.assign( Object.create( THREE.Mesh.prototype ), {
    constructor: RotationHelperPlane,
    isRotationHelperPlane: true
} );

export { RotationControls };
