import React, { useState, useEffect } from "react";
import { Table, Button, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import useTableFilter from "../../UseTableFilter/useTableFilter";
import DottedLoader from "../../DottedLoader";

const subscriptionPackages = ["Basic", "Pro", "Premium"];

const SelfSubscribedUsers = ({ setSelfSubscribedSizeCount, selectedManagers, selectedCompanies }) => {
  const [dataSource, setDataSource] = useState([]);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getColumnSearchProps] = useTableFilter();

  const getSelfSubscribedTableColumns = () => {
    return [
      {
        title: "Username",
        dataIndex: "customer_username",
        key: "customer_username",
        ...getColumnSearchProps("customer_username"),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Company",
        dataIndex: "company_name",
        key: "company_name",
        ...getColumnSearchProps("company_name"),
        render: (text, record) => (
          <a
            target="_blank"
            onClick={() => window.location.href = '/admin-company-usage/' + record.company_id}
          >
            {text}
          </a>
        ),
      },      
      {
        title: "Subscription Package",
        dataIndex: "subscription_package",
        key: "subscription_package",
      },
      {
        title: "Account Manager",
        dataIndex: "account_manager_username",
        key: "account_manager_username",
        ...getColumnSearchProps("account_manager_username"),
      },
      {
        title: "Action",
        key: "action",
        render: (_, record) => (
          <Button type="link" onClick={() => handleAssignAccountManager(record)}>
            Make me Account Manager
          </Button>
        ),
      },
    ];
  };

  const handleAssignAccountManager = async (record) => {
    const payload = record.company_name && record.company_name.trim() !== ""
    ? {
        company_id: parseInt(record.company_id, 10),
        account_manager: localStorage.getItem('username'),
        action: "assign_account_manager",
      }
    : record.customer_username && record.customer_username.trim() !== ""
    ? {
        customer_username: record.customer_username,
        account_manager: localStorage.getItem('username'),
        action: "assign_account_manager",
      }
    : null;
    try {
      await axios.post(ENVIRONMENT.ACCOUNT_MANAGER_CONTROLLER, payload);
      setIsModalVisible(true); // Show success modal
    } catch (error) {
      console.error("Error assigning account manager:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let aggregatedData = [];

      try {
        for (const subscription_package of subscriptionPackages) {
          const payload = { subscription_package };
          const response = await axios.post(ENVIRONMENT.FUNNEL_TABLES_DATA, payload);
          aggregatedData = [...aggregatedData, ...response.data];
        }
        setDataSource(aggregatedData);
        setFilteredDataSource(aggregatedData);
        setSelfSubscribedSizeCount(aggregatedData.length);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [setSelfSubscribedSizeCount]);

  useEffect(() => {
    let filteredData = dataSource;

    if (selectedManagers.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedManagers.includes(item.account_manager_username)
      );
    }

    if (selectedCompanies.length > 0) {
      filteredData = filteredData.filter((item) =>
        selectedCompanies.includes(item.company_name)
      );
    }

    setFilteredDataSource(filteredData);
    setSelfSubscribedSizeCount(filteredData.length);
  }, [selectedManagers, selectedCompanies, dataSource, setSelfSubscribedSizeCount]);

  return (
    <>
      {isLoading ? (
        <DottedLoader />
      ) : (
        <Table
          dataSource={filteredDataSource}
          columns={getSelfSubscribedTableColumns()}
          scroll={{ x: true }}
          style={{ overflowX: "scroll" }}
        />
      )}

      <Modal
        title="Success"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <p>Account Manager assigned successfully!</p>
      </Modal>
    </>
  );
};

export default SelfSubscribedUsers;
