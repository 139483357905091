import React from "react";
import { Row, Col, Tabs, Select, Button, InputNumber } from "antd";
import AdminMainLayout from '../AdminMainLayout';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';

import ItemsTable from '../../ItemsTable'

const { TabPane } = Tabs;
const { Option } = Select;

class  AdminProductsData extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      customer_username_filter: "",
      admin_qa_count: null,
      admin_view_count: null,
      failed_aborted_count: null,
      being_fixed_count: null,
      material_review_count: null,
      enqueued_count: null,
      artist_review_count: null,
      customers_names: [],
      filter_product_id: "",
    };  
  }

  componentDidMount(){
    this.populateCustomersList();  
  }

  populateCustomersList = () => {
    axios.post(ENVIRONMENT.CUSTOMER_GET_BATCH, {
      })
        .then(res => {
          var customers_names = [];
          for (let i = 1; i <= (res.data).length; i++) {
            customers_names.push(res.data[i - 1]['customer_username']);

          }
          
          this.setState({
            customers_names: customers_names
          })
        })
    
  }

  setAdminQACount = (value) => {
    this.setState({
      admin_qa_count: value
    });
  }

  setAdminViewCount = (value) => {
    this.setState({
      admin_view_count: value
    });
  }

  setMaterialReviewCount = (value) => {
    this.setState({
      material_review_count: value
    });
  }

  setFailedAbortedCount = (value) => {
    this.setState({
      failed_aborted_count: value
    });
  }

  setBeingFixedCount = (value) => {
    this.setState({
      being_fixed_count: value
    });
  }

  setEnqueuedCount = (value) => {
    this.setState({
      enqueued_count: value
    });
  }

  setArtistReviewCount = (value) => {
    this.setState({
      artist_review_count: value
    });
  }

  onChange = value => {
    this.setState({
      customer_username_filter: value
    });
  };

  onFilterIdChange = value => {
    this.setState({
      filter_product_id: value
    });
  }


  applyIdFilter = () => {
    window.location.href = "/admin_products/" + this.state.filter_product_id
  }

  render() {
    const { customer_username_filter } = this.state;
    return (
      <AdminMainLayout selected={"2"}>
        <Row style={{marginTop: 24}} gutter={24}>
          <Col span={2}>
            <p style={{margin: 3, fontSize: 16}}>
              <b>Go to ID:</b>
            </p>
          </Col>
          <Col span={4}>
            <InputNumber style={{ width: "100%" }} onChange={this.onFilterIdChange} />
          </Col>
          <Col span={4}>
                <Button type="primary" onClick={() => this.applyIdFilter()} disabled={(this.state.filter_product_id == "")}>Submit</Button>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1" tabBarStyle={{textAlign: 'center'}} animated={false}>
          <TabPane tab={(this.state.admin_qa_count==null)?"QA Pending":"QA Pending (" + this.state.admin_qa_count + ")"} key="1">
            <ItemsTable
              customer_username_filter={customer_username_filter}
              state="admin_qa"
              setCount={this.setAdminQACount}
            />
          </TabPane>
          <TabPane tab={(this.state.admin_view_count==null)?"Customer QA Pending":"Customer QA Pending (" + this.state.admin_view_count + ")"} key="2">          
            <ItemsTable
              customer_username_filter={customer_username_filter}
              state="admin_view"
              setCount={this.setAdminViewCount}
            />
          </TabPane>
          {/* <TabPane tab={(this.state.material_review_count==null)?"Material In Review":"Materials In Review (" + this.state.material_review_count + ")"} key="7">
            <ItemsTable
              customer_username_filter={customer_username_filter}
              state="material_review"
              setCount={this.setMaterialReviewCount}
            />
          </TabPane> */}
          <TabPane tab={(this.state.being_fixed_count==null)?"Being Fixed":"Being Fixed (" + this.state.being_fixed_count + ")"} key="4">
            <ItemsTable
              customer_username_filter={customer_username_filter}
              state="being_fixed"
              setCount={this.setBeingFixedCount}
            />
          </TabPane>
          <TabPane tab={(this.state.artist_review_count==null)?"Artist Review":"Artist Review (" + this.state.artist_review_count + ")"} key="5">
            <ItemsTable
              customer_username_filter={customer_username_filter}
              state="artist_review_for_admin"
              setCount={this.setArtistReviewCount}
            />
          </TabPane>
          <TabPane tab={(this.state.enqueued_count==null)?"Enqueued":"Enqueued (" + this.state.enqueued_count + ")"} key="6">
            <ItemsTable
              customer_username_filter={customer_username_filter}
              state="enqueued"
              setCount={this.setEnqueuedCount}
            />
          </TabPane>
          <TabPane tab={(this.state.failed_aborted_count==null)?"Failed & Aborted":"Failed & Aborted (" + this.state.failed_aborted_count + ")"} key="7">
            <ItemsTable
              customer_username_filter={customer_username_filter}
              state="failed_aborted"
              setCount={this.setFailedAbortedCount}
            />
          </TabPane>
        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminProductsData;