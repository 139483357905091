import React from 'react';
import { Modal} from 'antd';
 
const UpdateSubscriptionPackageModal = (props) => {

    return (
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={696}
        onCancel={props.onCancel}
        visible={props.visible}
        bodyStyle={{padding: 0}}
        centered={true}
        footer={props.footer}>
        <div style={{padding:"40px"}}>
            {/* <div style={{textAlign: "center"}}>
                <img style={{width: 85,height:85}} src={require("../../../assets/images/exclamation_icon.png")}></img>
            </div>
         */}
            <div style={{textAlign: "center",margin: "20px auto 12px auto"}}>
                <h2  className="manrope f-24 blue w-700 justify-in-center">{props.heading}</h2>
                <p className="manrope f-18 grey-77">{props.text}</p>
            </div> 
              
            {/* <div className="payment-modal-border justify-space-between">
                <span className="manrope f-16 black-55">
                    Name
                </span>
                <span className="manrope f-16 black-55">
                    {props.name}
                </span>
            </div>   
            <div style={{marginTop: 12}} className="payment-modal-border full justify-space-between">
                <span className="manrope f-16 black-55">
                    Total Price
                </span>
                <span className="manrope f-16 green w-700">
                    {props.amount}
                </span>
            </div>    */}
        </div>       
    </Modal>
      );
}

export default UpdateSubscriptionPackageModal;