import React from "react";
import ArtistMainLayout from '../ArtistMainLayout';
import MonthlyHoursDetails from '../MonthlyHoursDetails';

class ArtistMonthDetails extends React.Component {
  render() {
    return (
      <ArtistMainLayout selected={"4"}>
          <MonthlyHoursDetails />
      </ArtistMainLayout>
    );
  }
}

export default ArtistMonthDetails;