import React from 'react';
import { Table } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'

const expandable = { expandedRowRender: record => <p>{record.description}</p> };
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class RenderServiceTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    expandable,
    title: undefined,
    showHeader,
    footer: false,
    rowSelection: {},
    scroll: undefined,
    tableLayout: 'fixed',
    hasData: true,
    dataSource: null,
    reviewAction: 'hide',
    input_bundle: 'hide',
    output_bundle: 'hide'
  };

  getColumns = () => [
    {
      title: 'Test Render ID',
      dataIndex: 'render_id',
      key: 'render_id',
      sorter: (a, b) => a.render_id - b.render_id,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Render Name',
      dataIndex: 'render_name',
      key: 'render_name',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      onFilter: (value, record) => record.username === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => <span>{text == 2 ? "In Progress" : text == 1 ? "Enqueue" : text == 3 ? "Complete" : text == 4 ? "Rejected" : ''}</span>,
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Input Archive',
      dataIndex: 'input_archive_url',
      key: 'action',
      className: this.state.input_bundle,
      render: (text) => (
          <a href={text} target="_blank">Download Archive</a>
      ),
    },
    {
      title: 'Output Archive',
      dataIndex: 'output_archive_url',
      key: 'action',
      className: this.state.output_bundle,
      render: (text) => (
        <a href={text} target="_blank">Download Archive</a>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.reviewAction,
      render: (text, record) => (
        <span>
          <a onClick={() => window.location.href = ('/render_service/' + record.render_id + '/' + record.username)}>Upload</a>
        </span>
      ),
    },
  ];

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    if (this.props.state === "enqueue") {
      this.handleAssignedData("1");
    } else if (this.props.state === 'in_progress') {
      this.handleAssignedData("2");
    } else if (this.props.state === "complete") {
      this.handleAssignedData("3");
    } else if (this.props.state === "rejected") {
      this.handleAssignedData("4");
    }
  }

  handleAssignedData = (status_value) => {
    axios.post(ENVIRONMENT.GET_BATCH_TEST_RENDERS, {
      status: status_value,
      username : localStorage.getItem('username')
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        var data = [];
        for (let i = 1; i <= (res.data).length; i++) {
          console.log(res.data);
          data.push({
            key: i,
            render_id: res.data[i - 1]['render_id'],
            username: res.data[i - 1]['username'],
            render_name: res.data[i - 1]["render_name"],
            last_modified:  res.data[i - 1]['last_modified'],
            status: res.data[i - 1]['status'],
            output_archive_url: res.data[i - 1]['output_archive_url'],
            input_archive_url: res.data[i - 1]['input_archive_url']
          });
          if (status_value == "1" || status_value == "4") {
            this.setState({
              // reviewAction: "show",
              input_bundle: "show"
            });
          } else if (status_value == "2") {
            this.setState({
              input_bundle: "show"
            });
          } else if (status_value == "3") {
            this.setState({
              output_bundle: "show"
            });
          }
        }

        this.setState({
          dataSource: data
        })
      })
  }

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandable: enable ? expandable : undefined });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource &&
          <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
        }
      </div>
    );
  }
}

export default RenderServiceTable;
