import React, { useContext } from 'react';
import _ from "lodash";
import { Button } from 'antd';
import ModelVariationRequestModal from '../ModelVariationRequestModal/ModelVariationRequestModal';
import SuccessModal from '../../SuccessModal/SuccessModal';

const OpenVariationModal = (props) => {
    const { product_id, folderParentID, productData,
        modelDepth, modelWidth, modelHeight, requestStatus, cardStatus, requestSaved, setRequestSaved,
        variationModal, closeVariationModal, variationStep, variationType, setRequestSubmitted,
        setVariationStep, onChangeVariationType, variationSaved, variationCreated,
        onChangeARVariationType, arVariationType, savedID, variationProductDetails, requestSubmitted, statusLoader } = props;


    return (<>

        <ModelVariationRequestModal
            requestType={"variation_product_model"}
            requestStatus={requestStatus}
            cardStatus={cardStatus}
            variationLoader={statusLoader}
            variationModal={variationModal}
            closeVariationModal={closeVariationModal}
            savedID={savedID}
            variationStep={variationStep}
            variationType={variationType}
            onChangeVariationType={onChangeVariationType}
            onChangeARVariationType={onChangeARVariationType}
            setVariationStep={setVariationStep}
            arVariationType={arVariationType}
            model_depth={modelDepth}
            model_width={modelWidth}
            model_height={modelHeight}
            variationSaved={variationSaved}
            variationCreated={variationCreated}
            product_id={product_id}
            product_details={savedID == -1 ? productData : variationProductDetails}
        />
        <SuccessModal
            visible={requestSubmitted}
            onCancel={() => setRequestSubmitted(false)}
            heading={"Model Variation Requested Successfully!"}
            text={"Your Product Model's Variation request has been successfully submitted. You will be notified via email once your Product Model is ready to be reviewed."}
            footer={[
                <div className="justify-in-center">
                    <Button className="modal-okay square font-12" onClick={() => window.location.href = "/products/" + product_id + `?folder_id=${folderParentID}`}>
                        Okay
                    </Button>
                </div>
            ]}
        />
        <SuccessModal
            visible={requestSaved}
            onCancel={() => setRequestSaved(false)}
            heading={"Information Saved Successfully!"}
            text={"You can come back any time by reopening this form or from the incomplete section to fill out the remaining information."}
            footer={[
                <div className="justify-in-center">
                    <Button className="modal-okay square font-14" onClick={() => window.location.href = "/products/" + product_id + `?folder_id=${folderParentID}`}>
                        Okay
                    </Button>
                </div>
            ]}
        />
    </>)
}

export default OpenVariationModal;