import * as THREE from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js'
import { ASSET_GLB_URL } from "./environments/env";

const textureLoader = new THREE.TextureLoader();


export default class Configurator {

	constructor (productID,sceneContainerID, screenHeightDifference, screenWidthDifference, responsive, toggleLoader) {
		this.sceneContainer = document.getElementById(sceneContainerID);
		this.clay_image = '';
		this.checkerboard_image = '';
		this.imageData = '';
		this.selectedImage = '';
		this.components = [];
		this.responsive = responsive;
		this.selected_component = '';
		this.moveHorizontal =  document.querySelector('.horizontal');
		this.moveVertical = document.querySelector('.vertical');
		this.repeatPttrn = document.querySelector('.repeat');
		this.txtRotateBtn = document.querySelector('#rotateTexture');
		this.txtRotateBtnAnti = document.querySelector('#rotateTextureAnti');
		this.screenshotBtn = document.querySelector('#takeScreenshot');
		this.clockwiseValue = 0;
		this.antiClockwiseValue = 0;
		this.screenHeightDifference = screenHeightDifference;
		this.screenWidthDifference = screenWidthDifference;
		this.aspectApplied = false;
		this.aspect_type = 'Horizontal';
		this.aspect_ratio = '16:9';
		this.modelLoaded = false;
		this.toggleLoader = toggleLoader;
		
		this.sceneObjs = [];
		this.appliedMaterial = [];

		this.currentHighlightedObject = null;
		this.currentSelectedObject = null;
		this.focusedHighlightColor = new THREE.Color().setRGB( 250, 250, 250 );
		this.defaultHighLightColor = new THREE.Color().setRGB( 0, 0.5, 1 );

		this.updateScreenProps();
		
		this.loader = this.buildGLTFLoader();
		
		this.scene = this.buildScene();
		this.sceneRenderer = this.buildSceneRenderer( this.sceneWidth, this.sceneHeight );
		this.sceneContainer.appendChild( this.sceneRenderer.domElement );
		this.buildSceneLights();

		this.camera = new THREE.PerspectiveCamera( 37.8, this.sceneWidth / this.sceneHeight, 1, 1000 ); // 37.8 vertical fov == 35mm lens focal length
		this.controls = new OrbitControls( this.camera, this.sceneRenderer.domElement );
		this.controls.enablePan = false;

		this.baseURL = ASSET_GLB_URL;

		this.productID = productID;

		if ( this.productID != undefined ) {
			this.loadModel(this.productID);
			
		}

		this.pointer = new THREE.Vector2();
		this.raycaster = new THREE.Raycaster();

		this.enableHovering = false;

		this.buildGUI();
		this.setupEventListeners();
		

		this.animate();
		this.onWindowResize();
	}

	getParams ( url ) {
		let params = {};
		let parser = document.createElement('a');
		parser.href = url;
		let query = parser.search.substring(1);
		let vars = query.split('&');
		for (let i = 0; i < vars.length; i++) {
			let pair = vars[i].split('=');
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
		return params;
	}

	buildScene () {
        const scene = new THREE.Scene();
        scene.background = new THREE.Color("#fafafa");
        scene.environment = this.loadEnvironment();
        return scene;
    }

    buildSceneRenderer ( width, height ) {
        const renderer = new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.gammaFactor = 2.2;
        renderer.setClearColor( 0x000000 );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        return renderer;
    }

	buildGLTFLoader () {
		const gltfLoader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath( '/js/libs/draco/gltf/' );
		gltfLoader.setDRACOLoader( dracoLoader );
        return gltfLoader;
	}

	buildSceneLights () {

		RectAreaLightUniformsLib.init();
		
		this.keyLight = new THREE.RectAreaLight( 0xffffff, 8, 5, 5 );
		this.fillLight = new THREE.RectAreaLight( 0xffffff, 3, 5, 5 );
		this.rimLight = new THREE.RectAreaLight( 0xffffff, 3, 5, 5 );

		this.keyLight.position.set( -3.75, 2.5, 5.0 );
		this.fillLight.position.set( 5.0, 0.0, 1.25 );
		this.rimLight.position.set( 0.0, 5.0, -1.25 );

		let origin = new THREE.Vector3( 0, 0, 0 );
		this.keyLight.lookAt(origin);
		this.fillLight.lookAt(origin);
		this.rimLight.lookAt(origin);

		this.scene.add( this.keyLight );
		this.scene.add( this.fillLight );
		this.scene.add( this.rimLight );
	}

	buildGUI () {

		this.uOffset = 0.0;
		this.vOffset = 0.0;
		this.textureRepeat = 1.0;

		let scope = this;
		this.textureRotation = () => {
			scope.selectedMaterial.map.rotation += ( Math.PI / 2 );
			this.clockwiseValue = scope.selectedMaterial.map.rotation;
			this.antiClockwiseValue = scope.selectedMaterial.map.rotation;
		}

		this.screenshot = () => {
			scope.saveScreenshot();
		}

		this.textureRotationAnti = () => {
			scope.selectedMaterial.map.rotation -= ( Math.PI / 2 );
			this.antiClockwiseValue = scope.selectedMaterial.map.rotation;
			this.clockwiseValue = scope.selectedMaterial.map.rotation;
		}

		this.gui = new GUI( { width: 350,autoPlace: false,visibility:"hidden" });
		// var GUIContainer = document.getElementById('gui-controls');
		// GUIContainer.appendChild(this.gui.domElement);

		this.uCtrl = this.gui.add( this, 'uOffset', 0.0, 1.0, 0.01 ).name( 'Move Horizontal' );
		this.vCtrl = this.gui.add( this, 'vOffset', 0.0, 1.0, 0.01 ).name( 'Move Vertical' );
		this.repeatCtrl = this.gui.add( this, 'textureRepeat', 1.0, 10.0, 0.5 ).name( 'Repeat' );
		this.gui.add( this, 'textureRotation' ).name( 'Rotate 90°' );
		this.gui.add( this, 'textureRotationAnti').name( 'Rotate 90 Anti ');
		this.gui.add( this, 'screenshot' ).name( 'Take Screenshot' );

		this.uCtrl.onChange( ()=> {
			scope.selectedMaterial.map.offset.x = this.uOffset;
		} );
		this.xAxis = (value) => {
			scope.selectedMaterial.map.offset.x = value;
		}

		this.vCtrl.onChange( ()=> {
			this.selectedMaterial.map.offset.y = this.vOffset;
		} );
		this.yAxis = (value) => {
			scope.selectedMaterial.map.offset.y = value;
		}

		this.repeatCtrl.onChange( ()=> {
			this.selectedMaterial.map.repeat.set( this.textureRepeat, this.textureRepeat );
		} );

		this.repeatPattern = (value) => {
			scope.selectedMaterial.map.repeat.set( value, value );
		}

		this.gui.hide();
		this.gui.close();
		if (this.moveHorizontal != null && this.moveVertical != null && this.repeatPttrn != null && this.txtRotateBtn != null && this.txtRotateBtnAnti != null && this.screenshotBtn != null){
			this.moveHorizontal.addEventListener('mousedown', this.xAxis, false);
			this.moveVertical.addEventListener('mousedown', this.yAxis, false);
			this.repeatPttrn.addEventListener('mousedown', this.repeatPattern, false);
			this.txtRotateBtn.addEventListener('click', this.textureRotation, false);
			this.txtRotateBtnAnti.addEventListener('click',this.textureRotationAnti, false);
			this.screenshotBtn.addEventListener('click',this.screenshot, false);
		}
	}

	getClockwiseValue () {
		return this.clockwiseValue;
	}

	getAntiClockwiseValue () {
		return this.antiClockwiseValue;
	}

	updateAndShowGUI () {
		this.uOffset = this.selectedMaterial.map.offset.x;
		this.vOffset = this.selectedMaterial.map.offset.y;
		this.textureRepeat = this.selectedMaterial.map.repeat.x;
		this.uCtrl.updateDisplay();
		this.vCtrl.updateDisplay();
		this.repeatCtrl.updateDisplay();
		this.gui.show();
	}

	hideGUI () {
		this.gui.hide();
	}

	setupEventListeners () {

		window.addEventListener( 'resize', () => { this.onWindowResize(); }, false );
		// this.sceneRenderer.domElement.addEventListener( 'mousedown', this.onPointerDown, false );
		this.sceneRenderer.domElement.addEventListener( 'mousemove', this.onPointerMove, false );
		this.sceneContainer.addEventListener( 'dragover', this.onDragOver, false );
		this.sceneContainer.addEventListener( 'dragenter', this.onDragEnter, false );
		this.sceneContainer.addEventListener( 'dragleave', this.onDragLeave, false );
		this.sceneContainer.addEventListener( 'drop', this.onDrop, false );
		// this.sceneContainer.addEventListener( 'mousedown', this.onClickModel, false );
	}

	loadEnvironment () {
		const url = './resources/env/'
        const format 	= '.jpg';
        const env 		= new THREE.CubeTextureLoader().load( [
            url + 'px' + format, url + 'nx' + format,
            url + 'py' + format, url + 'ny' + format,
            url + 'pz' + format, url + 'nz' + format
        ] );
        return env;
    }

	loadModel ( id ) {

		this.loader.load( this.baseURL + id + ".glb",
			( data ) => this.onModelLoaded( id, data ),
			( xhr ) => this.onLoadingProgress( xhr ),
			( error ) => this.onLoadingFailed( error ) );

	}

	configureCameraAndControls () {

		let length = this.model.size.length();
		this.model.bBox.getCenter( this.controls.target );
		
		this.controls.minDistance = length * 0.5;
		this.controls.maxDistance = length * 5.0;
		this.camera.near = length / 100.0;
		this.camera.far = length * 100.0;
		this.camera.updateProjectionMatrix();

		let vFov = this.camera.fov * ( Math.PI / 180 );
		let hFov = 2 * Math.atan ( ( this.sceneWidth / this.sceneHeight ) * Math.tan( vFov / 2 ) );
		let hFactor = 1.0 + ( hFov / 100.0 );
		let vFactor = 1.2 + ( vFov / 100.0 );
		let hFovLen = Math.abs ( this.model.size.x * hFactor );
		let vFovLen = Math.abs ( this.model.size.y * vFactor );
		let halfDepth = Math.abs( this.model.size.z / 2.0) ;
		
		let hDolly = hFovLen / Math.tan( hFov );
		hDolly *= ( hDolly / ( hDolly - halfDepth ) );
		let vDolly = vFovLen / Math.tan( vFov );
		vDolly *= ( vDolly / ( vDolly - halfDepth ) );

		if ( hDolly > vDolly )
		{
			this.camera.position.set( 0, this.model.size.y * 0.8, hDolly );
		}
		else
		{
			this.camera.position.set( 0, this.model.size.y * 0.8, vDolly );
		}

	}

	configureLights () {
		// Ideally lights should be configured here and not in the buildSceneLights method
	}

	onModelLoaded ( id, data ) {

		this.model = data.scene;
		this.model.name = id;

		this.model.bBox = new THREE.Box3().setFromObject( this.model );
		this.model.size = new THREE.Vector3();
		this.model.bBox.getSize( this.model.size );

		this.configureCameraAndControls();
		this.configureLights();
		this.scene.add( this.model );
		this.components = this.model.children[0].children[0].children;
		this.components.map((component,index) => {
			this.applyMaterial(component, '');
		});
		this.highLightSelectedComponent();
		this.modelLoaded = true;
		this.toggleLoader();

	}

	getThumbnails () {
		//this.clay_image = this.sceneRenderer.domElement.toDataURL();
		
		// this.applyCheckerboardMaterial();
		// this.checkerboard_image = this.sceneRenderer.domElement.toDataURL();
		// this.removeMaterial();
	}

	getClayImage () {
		return this.clay_image;
	}

	getCheckerboardImage () {
		return this.checkerboard_image;
	}

	selectedComponent = (component) => {
		this.selected_component = component;
	}

	highLightSelectedComponent () {
		let selected_component = this.components.filter((x) => { return !x.name.includes(this.selected_component)})
		selected_component.map((component,index) => {
			this.selectObject(component, this.focusedHighlightColor);
		})
	}

	applyCheckerboardMaterial = () => {
		let image = "/img/UVChecker.png";
		this.components = this.model.children[0].children[0].children;
		this.components.map((component,index) => {
			this.applyMaterial(component, image);
		});
	}

	removeMaterial = () => {
		this.components = this.model.children[0].children[0].children;
		this.components.map((component,index) => {
			this.applyMaterial(component, '');
		});
	}

	applyMaterial = (component, uv_material) => {

		let scope = this;
		var reader = new FileReader();
		scope.raycaster.setFromCamera( scope.pointer, scope.camera);
		if ( component ) {
			var image = document.createElement('img');
			image.src = uv_material;
			if (uv_material != '') {
				textureLoader.load(image.src,

					// onLoad callback
					function ( newTex) {				
						newTex.wrapS = THREE.RepeatWrapping;
						newTex.wrapT = THREE.RepeatWrapping;
						newTex.needsUpdate = true;
						if (component) {
							scope.selectedMaterial = component.material;
						}
							
						scope.selectedMaterial.map = newTex;
						scope.selectedMaterial.roughnessMap = null;
						scope.selectedMaterial.metalnessMap = null;
						scope.selectedMaterial.normalMap = null;
						scope.selectedMaterial.needsUpdate = true;
						scope.updateAndShowGUI();
					},

					// onProgress callback currently not supported
					undefined,

					// onError callback
					function ( err ) {
						console.error( 'An error happened.' );
					}
				);
			}
			else {
				var newTex = new THREE.Texture(image);
				newTex.wrapS = THREE.RepeatWrapping;
				newTex.wrapT = THREE.RepeatWrapping;
		        newTex.needsUpdate = true;

		        scope.selectedMaterial = component.material;
				scope.selectedMaterial.map = null;
				scope.selectedMaterial.roughnessMap = null;
				scope.selectedMaterial.metalnessMap = null;
				scope.selectedMaterial.normalMap = null;
				scope.selectedMaterial.needsUpdate = true;
			}
		}
		else {
			scope.hideGUI();
		}

		
	}

	onLoadingProgress ( xhr ) {

	}

	onLoadingFailed ( error ) {

	}

	onPointerDown = ( event ) => {

		event.preventDefault();

		this.raycaster.setFromCamera( this.pointer, this.camera);
		let intersect = this.raycaster.intersectObjects( this.scene.children, true )[0] || false;

		if ( intersect ) {
			this.selectedMaterial = intersect.object.material;
			this.updateAndShowGUI();
		}
		else {
			this.hideGUI();
		}

	}

	highlightObject(targetObject, targetColor) {
		// check if the same object is not already highlighted/ hovered on or selected
		if (this.currentSelectedObject!==null && this.currentSelectedObject.userData.name === targetObject.userData.name ) {
			this.removeHighlightObject();
			return;
		}
		else if (this.currentHighlightedObject !== null && this.currentHighlightedObject.userData.name !== targetObject.userData.name ) {
				this.removeHighlightObject();
		}
		if(this.currentHighlightedObject == null ) {
			this.currentHighlightedObject = targetObject;
			targetObject.material.emissive.copy( targetColor);
			targetObject.material.emissiveIntensity = 0.5;
		}
		
		
	}

	// remove the highlighted segment when hover is moved away from it
	removeHighlightObject() {
		// remove highlight when the pointer is moved away from the highlighted object
		// check if any object is highlighted and do not change color if the object is selected
		if(this.currentHighlightedObject != null) {
			this.currentHighlightedObject.material.emissive.setRGB( 0.0, 0.0, 0.0 );
			this.currentHighlightedObject.material.emissiveIntensity = 0.0;
			this.currentHighlightedObject = null;
		}
	}

	// select highlight the clicked segmented part of the model
	selectObject(targetObject, targetColor) { 
		// return directly if the target object is already selected
		if (this.currentSelectedObject !=null && this.currentSelectedObject.userData.name === targetObject.userData.name) {
			return;
		}
		else if(this.currentSelectedObject!=null) {
			// unselect the selected object before selecting the new one
			this.removeSelectedObject();
		}
		this.currentSelectedObject = targetObject;
		this.removeHighlightObject();
		targetObject.material.emissive.copy( targetColor );
		targetObject.material.emissiveIntensity = 1.0;
	}

	// remove the current selected segmented part of the model
	removeSelectedObject() {
		if(this.currentSelectedObject!=null){
			this.currentSelectedObject.material.emissive.setRGB( 0.0, 0.0, 0.0 );
			this.currentSelectedObject.material.emissiveIntensity = 0.0;
			this.currentSelectedObject = null;
			}
	}

	onPointerMove = ( event ) => {

		event.preventDefault();

		this.updatePointerPosition( event.layerX, event.layerY );
		let scope = this;
		scope.raycaster.setFromCamera( scope.pointer, scope.camera);
		let intersectedObjects = scope.raycaster.intersectObjects( [ scope.model ], true );
		if(intersectedObjects[0]) {
			this.enableHovering && this.highlightObject(intersectedObjects[0].object, this.defaultHighLightColor);
		}
		else {
			this.enableHovering && this.removeHighlightObject();
		}

	}

	onDragOver = ( event ) => {

    	event.preventDefault();
    	this.updatePointerPosition( event.layerX, event.layerY );
		event.dataTransfer.dropEffect = 'copy';

    }

    onDragEnter = ( event ) => {

    }

    onDragLeave = ( event ) => {

	}
	
	selectedMaterialImage = (id) => {
		this.selectedImage = id;
		// this.removeHighlightObject();
		// this.removeSelectedObject();
		let scope = this;
		var reader = new FileReader();
		// reader.addEventListener( 'load', function ( event ) {
		scope.raycaster.setFromCamera( scope.pointer, scope.camera);
		let intersectedObjects = scope.raycaster.intersectObjects( [ scope.model ], true );
		let selected_component = this.components.filter((x) => { 
			return x.name.includes(this.selected_component);
		});
		
		if ( selected_component[0] ) {
			var image = document.getElementById(scope.selectedImage);
			let textureLoaderr = new THREE.TextureLoader();

			textureLoaderr.load(image.src,

				// onLoad callback
				function ( newTex) {	
					newTex.wrapS = THREE.RepeatWrapping;
					newTex.wrapT = THREE.RepeatWrapping;
					newTex.flipY = false;
					newTex.center.set(0.5, 0.5);
					newTex.needsUpdate = true;
					scope.selectedMaterial = selected_component[0].material;
					scope.selectedMaterial.map = newTex;
					scope.selectedMaterial.roughnessMap = null;
					scope.selectedMaterial.metalnessMap = null;
					scope.selectedMaterial.normalMap = null;
					scope.selectedMaterial.needsUpdate = true;
					scope.updateAndShowGUI();
				},

				// onProgress callback currently not supported
				undefined,

				// onError callback
				function ( err ) {
					console.error( err, 'An error happened.' );
				}
			);
		}
		else {
			scope.hideGUI();
		}

		
	}

	onClickModel = (event) => {
		event.preventDefault();
		let scope = this;
		scope.raycaster.setFromCamera( scope.pointer, scope.camera);
		let intersectedObjects = scope.raycaster.intersectObjects( [ scope.model ], true );
		if ( intersectedObjects[0] ) {
		}
		else {
			this.enableHovering && this.removeHighlightObject();
			this.enableHovering && this.removeSelectedObject();
		}
	
	}

    onDrop = ( event ) => {

    	event.preventDefault();
		// this.removeHighlightObject();
		// this.removeSelectedObject();
		let scope = this;
		var reader = new FileReader();
		// reader.addEventListener( 'load', function ( event ) {
		scope.raycaster.setFromCamera( scope.pointer, scope.camera);
		let intersectedObjects = scope.raycaster.intersectObjects( [ scope.model ], true );

		if ( intersectedObjects[0] ) {
			var image = document.getElementById(scope.selectedImage);
			textureLoader.load(image.src,
				// onLoad callback
				function ( newTex) {				
					newTex.wrapS = THREE.RepeatWrapping;
					newTex.wrapT = THREE.RepeatWrapping;
					newTex.needsUpdate = true;
					scope.selectedMaterial = intersectedObjects[0].object.material;
					scope.selectedMaterial.map = newTex;
					scope.selectedMaterial.roughnessMap = null;
					scope.selectedMaterial.metalnessMap = null;
					scope.selectedMaterial.normalMap = null;
					scope.selectedMaterial.needsUpdate = true;
					scope.updateAndShowGUI();
				},
				// onProgress callback currently not supported
				undefined,
				// onError callback
				function ( err ) {
					console.error( 'An error happened.' );
				}
			);
		}
		else {
			scope.hideGUI();
		}

		// }, false );

		// if ( event.dataTransfer.files[ 0 ].type.includes("image") ) {
			// reader.readAsDataURL( document.getElementById(scope.selectedImage).src );	
		// }

    }

    updatePointerPosition ( offsetX, offsetY ) {

    	// calculate pointer position in normalized device coordinates
        // (-1 to +1) for both components
        this.pointer.x = ( offsetX / this.sceneWidth ) * 2 - 1;
        this.pointer.y = - ( offsetY / this.sceneHeight ) * 2 + 1;

    }

	updateScreenProps () {
		
		if (this.responsive) {
			this.defaultRenderer();
		} else {
			this.sceneWidth = this.screenWidthDifference;
			this.sceneHeight = this.sceneWidth * 0.5625;
			this.aspect = this.sceneWidth / this.sceneHeight;
			
		}
	}

	defaultRenderer(){
		this.aspectApplied = true;
			let height = window.innerHeight - this.screenHeightDifference;
			let width = this.sceneContainer.clientWidth - this.screenWidthDifference;
			if(width/height >= 1.78) {
				this.sceneHeight = window.innerHeight - this.screenHeightDifference;
				this.sceneWidth  = (this.sceneHeight * 1.78);
			}
			else {
				this.sceneWidth 		= this.sceneContainer.clientWidth - this.screenWidthDifference;
				this.sceneHeight 	    = this.sceneWidth*0.5625;
			}
			this.aspect = this.sceneWidth/this.sceneHeight;
			if (this.modelLoaded) {
				this.render();
			}
			

	}

	changeOrientation(ratio = "16:9", type = "Custom"){
		this.defaultRenderer();
		this.aspect_type = type;
		this.aspect_ratio = ratio;
		var widthR = 0;
		var heightR = 0;
		var newAspect = 0;
		if (typeof ratio === 'string' || ratio instanceof String) {
			widthR = ratio.split(':')[0];
			heightR = ratio.substring(ratio.indexOf(':') + 1);
			newAspect = widthR/heightR; 
		}
		else {
			newAspect = ratio;
		}
		if(type == "Horizontal") {
			this.aspectApplied = true;
			let height = window.innerHeight - 320;
			let width = this.sceneContainer.clientWidth;
			if(width/height >= 1.78) {
				this.sceneHeight = window.innerHeight - 320;
				this.sceneWidth  = this.sceneHeight * 1.78;
			}
			else {
				this.sceneWidth 		= this.sceneContainer.clientWidth;
				this.sceneHeight 	    = this.sceneWidth*0.5625;
			}
		}
		else if (type == "Vertical") {
			this.aspectApplied = true;
			this.sceneHeight = this.sceneContainer.clientHeight;
			this.sceneWidth = this.sceneHeight*0.5625;
		}
		else if (type == "Square") {
			this.aspectApplied = true;
			if (this.sceneContainer.clientWidth < this.sceneContainer.clientHeight){
				this.sceneWidth = this.sceneContainer.clientWidth;
				this.sceneHeight = this.sceneContainer.clientWidth;
			}
			else {
				this.sceneWidth = this.sceneContainer.clientHeight;
				this.sceneHeight = this.sceneContainer.clientHeight;
			}
		}
		else if (type == "Custom") {
			this.aspectApplied = true;
			if(newAspect > 1) {
				let height = window.innerHeight - 320;
				let width = this.sceneContainer.clientWidth;
				if(width/height >= newAspect){
					this.sceneHeight = window.innerHeight - 320;
					this.sceneWidth  = this.sceneHeight * newAspect;
				}
				else {
					this.sceneWidth 		= this.sceneContainer.clientWidth;
					this.sceneHeight 	    = this.sceneWidth*(1/newAspect);
				}
			}
			else {
				this.sceneHeight = this.sceneContainer.clientHeight;
				this.sceneWidth = this.sceneHeight* newAspect;
			}
		}
		this.aspect = this.sceneWidth/this.sceneHeight;
		// this.render();
		
	}
	
	getVarSnapshot() {
		return this.imageData;
	}

    saveScreenshot() {

        //let imgData, imgNode;

        try {
            let strMime = "image/jpeg";
            let strDownloadMime = "image/octet-stream";
			//imgData = this.sceneRenderer.domElement.toDataURL( strMime );
			this.imageData = this.sceneRenderer.domElement.toDataURL( strMime );
			// this.saveFile( imgData.replace( strMime, strDownloadMime ), this.model.name + "_textureRef.jpg" );

        } catch (e) {
            console.log(e);
            return;
        }

    }

    saveFile (strData, filename) {

        let link = document.createElement( 'a' );
        
        if ( typeof link.download === 'string' ) {
            document.body.appendChild( link );
            link.download = filename;
            link.href = strData;
            link.click();
            document.body.removeChild( link );
        } 

        else {
            //location.replace( uri );
        }

    }

    onWindowResize () {

    	this.updateScreenProps();

        this.sceneRenderer.domElement.style.margin = "0 auto"
        this.sceneRenderer.domElement.style.display = "flex";
        this.sceneRenderer.domElement.style.flexDirection = "row";
        this.sceneRenderer.domElement.style.justifyContent = "center";
        this.sceneRenderer.domElement.style.alignItems = "center";
		this.sceneRenderer.setSize( this.sceneWidth, this.sceneHeight );
		// this.selectedComponent('');
	}

	render () {

		this.sceneRenderer.clear();
        this.sceneRenderer.render( this.scene, this.camera );

	}

	update () {

		this.controls.update();

	}

	animate () {

		requestAnimationFrame( ()=>{ this.animate(); } );
        this.update();
        this.render();

	}
}

export { Configurator }