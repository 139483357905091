import 'rxjs-compat/add/operator/switchMap';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/catch';
import { ajax } from 'rxjs-compat/observable/dom/ajax';
import {
  CREATE_COLLECTION,
  LIST_COLLECTION,
  UPDATE_COLLECTION,
  collectionFailureAction,
  collectionSuccessAction,
  FETCH_COLLECTION_PRODUCTS,
  FETCH_COLLECTION_ROOMS,
  DELETE_COLLECTION_PRODUCT,
} from '../actions';
import ENVIRONMENT from '../../environments'

export const listCollections = (action$) => {
  return action$
      .ofType(LIST_COLLECTION)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.LIST_COLLECTION,
            { ...payload }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => collectionSuccessAction({ collections: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(collectionFailureAction({ message: 'Unable to create collection. Fill missing fields.' })));
      });
};


export const createCollection = (action$) => {
  return action$
      .ofType(CREATE_COLLECTION)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.CREATE_COLLECTION,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => collectionSuccessAction({ ...data.response, isCreated: true, isUpdated: false }))
            .catch((error) => Promise.resolve(collectionFailureAction({ message: 'Unable to create collection. Fill missing fields.' })));
      });
};


export const updateCollection = (action$) => {
  return action$
      .ofType(UPDATE_COLLECTION)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.UPDATE_COLLECTION,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => collectionSuccessAction({ collections: data.response.collections, isCreated: false, isUpdated: true }))
            .catch((error) => Promise.resolve(collectionFailureAction({ message: 'Unable to update collection. Fill missing fields.' })));
      });
};

export const getCollectionProducts = (action$) => {
  return action$
      .ofType(FETCH_COLLECTION_PRODUCTS)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.FETCH_COLLECTION_PRODUCTS,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => collectionSuccessAction({ products: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(collectionFailureAction({ message: 'Unable to update collection. Fill missing fields.' })));
      });
};

export const getCollectionRooms = (action$) => {
  return action$
      .ofType(FETCH_COLLECTION_ROOMS)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.COLLECTION_GET_ROOMS,
            { ...payload }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => collectionSuccessAction({ rooms: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(collectionFailureAction({ message: 'Unable to update collection. Fill missing fields.' })));
      });
};

export const collectionDeleteProduct = (action$) => {
  return action$
      .ofType(DELETE_COLLECTION_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.DELETE_COLLECTION_PRODUCT,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => collectionSuccessAction({ products: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(collectionFailureAction({ message: 'Unable to update collection. Fill missing fields.' })));
      });
};

