import React, { useEffect } from 'react';

const PanoramaViewer = (props) => {

    useEffect(() => {
        let viewerOpts = {
            cursors: {
                drag:{
                    active: 'grabbing',
                    inactive: 'grab',
                    disabled: 'default',
                }
            }
          };

        let pano = document.getElementById(props.id);
        let viewer = new window.Marzipano.Viewer(pano, viewerOpts);
        var autorotate = window.Marzipano.autorotate({
            yawSpeed: 0.1,         // Yaw rotation speed
            targetPitch: 0,        // Pitch value to converge to
            targetFov: Math.PI/2   // Fov value to converge to
        });
        
        // Create source.
        var source = window.Marzipano.ImageUrlSource.fromString(
            props.threeSixtyUrl
        );

        console.log(props.threeSixtyUrl);

        // Create geometry.
        var geometry = new window.Marzipano.EquirectGeometry([{ tileSize: 512, size: 512 }]);

        // Create view.
        var limiter = new window.Marzipano.RectilinearView.limit.traditional( 2560,(100 * Math.PI) / 180);
        var view = new window.Marzipano.RectilinearView(null, limiter);

        // Create scene.
        var scene = viewer.createScene({
        source: source,
        geometry: geometry,
        view: view,
        pinFirstLevel: true
        });

        // Display scene.
        scene.switchTo();
        viewer.startMovement(autorotate);
    
    },[props.id]);
   
        return (
            <div id={props.id} className={props.customClass ? props.customClass : 'panorama-default-styles'} />
        );
}

export default PanoramaViewer;