import React from 'react';
import CustomerMainLayout from '../CustomerComponents/CustomerMainLayout';
const ComingSoon = () => {
    return <CustomerMainLayout selected={new URLSearchParams(window.location.search).get("selected")}>
        <div style={{margin: "30px", textAlign: "center"}}>
            <img src={require("../../../assets/images/comingsoon.jpeg")} alt="Coming Soon" />
        </div>
    </CustomerMainLayout>
};
export default ComingSoon;

