import React, { useEffect, useState } from 'react';
import { Modal, Radio,Col,Card ,Row, Button} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons'
import axios from 'axios';
import ENVIRONMENT from '../../../environments';
import './SubscriptionPackageUpdate.scss';
import FileConstants from '../../../FileConstants';

const SUBSCRIPION_PLANS_ORDER = [
    'freemium',
    'basic',
    'pro',
    'premium'
]

const HIGHEST_SUBSCRIPTION_PLAN = 'premium';

const SubscriptionPackageUpdate = (props) => {

    const [packages, setPackages] = useState('');
    const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false);
    const [updateToEnterpriseModalVisible, setUpdateToEnterpriseModalVisible] = useState(false);

    useEffect(() => {
        if (props.currentSubscriptionPlan == HIGHEST_SUBSCRIPTION_PLAN && props.isSubscriptionActive) {
            setUpdateToEnterpriseModalVisible(props.visible)
        } else {
            setSubscriptionModalVisible(props.visible)
        }
    }, [props.visible])

    const mapRequestType = {
        "existing_product_model" : "Upload 3D models: ",
        "room_prop" : "ALL3D Store Space models: ",
        "product_prop" : "ALL3D Store Product models: ",
        // "lifestyle_tokens" : "Lifestyle images/renders: " 
    }
    const mapRequestTypeTokens = {
        "lifestyle_tokens" : {
            "Basic": 1,
            "Pro": 2,
            "Premium": 4
        } 
    }
    useEffect(() => {
        let payload = {
            "data" : "subscription-update"
        }
        axios.post(ENVIRONMENT.GET_UPDATE_SUBSCRIPTION_DATA, payload)
        .then(res => {
            setPackages(res.data);
        });
       
    },[]);

    const changeSubsriptionPlan = (id) => {
        props.setSubscriptionPlan(id);
        if(document.getElementsByClassName("blue-border")[0] != undefined){
            document.getElementsByClassName("blue-border")[0].setAttribute("class","no-border")
        }
        document.getElementById(id).setAttribute("class", "blue-border")
    }
   

    const getCardData = () => {
        return (
        <Radio.Group value={props.subscriptionPlan} defaultValue={null}  >
            <Row className="product-tab-content-scrollable" type="flex" style={{ marginTop: "10px", justifyContent: "start"}}>
            
            {
                Object.keys(packages).map((ele, index) => (
                    <>
                    {(!props.isSubscriptionActive ||
                    ((SUBSCRIPION_PLANS_ORDER.indexOf(packages[ele][5]['name']) > SUBSCRIPION_PLANS_ORDER.indexOf(props.currentSubscriptionPlan)) && props.isSubscriptionActive)) ?
                    <Col style={{margin:"0px 15px"}} span={11}>
                        
                        <Card onClick={()=>changeSubsriptionPlan(packages[ele][5]['name'])} className={(packages[ele][5]['name']) == props.subscriptionPlan ? 'blue-border' : 'no-border'} id ={packages[ele][5]['name']}>
                        
                            <div style={{background:"#F7F7F8",padding:"10px"}}>
                            <Radio value={packages[ele][5]['name']} style={{display:"contents"}}>
                                <span className='manrope f-16'>
                                    {ele}
                                </span>
                                <div className='float-right manrope f-12'>
                                    <span className='manrope f-18 dollar'>${packages[ele][4]['price']}</span><span> / Month</span>
                                </div>
                            </Radio>
                            </div>
                            <div style={{marginBottom:"20px"}}>
                                {
                                    // let elem = packages[ele],
                                    (packages[ele]).map( (type, inedx) => (
                                        <p style={{margin:"16px 0px 0px 16px"}}>
                                            {mapRequestType[type["request_type"]] ?
                                            <span>
                                                <span  className='manrope f-14 w-400'>
                                                {mapRequestType[type["request_type"]]}
                                                </span>
                                                <span className='manrope f-14 w-600'>
                                                    {type["quantity"] + ' Included'}
                                                </span>
                                            </span>:''
                                            }
                                            {mapRequestTypeTokens[type["request_type"]] ?
                                            <span>
                                                <span  className='manrope f-14 w-400'>
                                                Lifestyle images/renders: 
                                                </span>
                                                <span className='manrope f-14 w-600'>
                                                    {(type["quantity"] / mapRequestTypeTokens[type["request_type"]][ele]) + " " + mapRequestTypeTokens[type["request_type"]][ele].toString() + "Ks"}
                                                </span>
                                                <br></br>
                                                <span className='manrope f-14 w-400'>
                                                    OR
                                                </span>
                                                <span className='manrope f-14 w-600'>
                                                    {" " + Math.floor(type["quantity"]/FileConstants.modelRequestTokens) + " "}
                                                </span>
                                                <span  className='manrope f-14 w-400'>
                                                Product Models 
                                                </span>
                                            </span>:''
                                            }
                                            
                                        </p>
                                    ))
                                }
                                <span style={{margin:"0px 15px"}}>
                                    <span  className='manrope f-14 w-400'>
                                    {'Product Images / Renders: '}
                                    </span>
                                    <span className='manrope f-14 w-600'>
                                        {'Upto 4 2K per model'}
                                    </span>
                                </span>
                            </div>
                        
                        
                        </Card>
                    </Col>:null}
                    </>
                ))
            }
            </Row>
        </Radio.Group>
        )
    }

    return (
        <>
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={1000}
        height={1200}
        onCancel={props.onCancel}
        visible={subscriptionModalVisible}
        bodyStyle={{padding: 0}}
        footer={props.footer}>
        <div className='subscription-package-wrapper' style={{padding:"10px"}}>
            <div style={{margin: "24px 28px"}}>
                <span style={{display:"unset"}}  className="manrope f-24 w-700">Upgrade Plan</span>
                <span className="manrope f-14" style={{float:'right'}}><a href='https://all3d.ai/pricing' target="_blank">See Detailed <ArrowRightOutlined style={{marginLeft:"10px"}}/></a></span>
            </div> 
            {getCardData()}
            <div style={{margin: "24px 28px"}}>
                <span style={{display:"unset"}}  className="manrope f-12 w-500">*Subscription will automatically be renewed</span>
            </div>
        </div>       
    </Modal>
    <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={696}
        onCancel={props.onCancel}
        visible={updateToEnterpriseModalVisible}
        // visible={true}
        bodyStyle={{padding: 0}}
        footer={[
            <span style={{display: 'flex', justifyContent:'center'}}>
                <Button className="modal-okay-gray font-14 square" onClick={() => {
                    props.onCancel();
                }}>
                    Cancel
                </Button>
                <Button className="modal-okay font-14 square" onClick={() => {
                    window.open('https://all3d.ai/contact-us?origin=portal', '_blank');
                    props.onCancel();
                    }}>
                    Contact Us
                </Button>
            </span>
        ]}>
        <div style={{padding:"40px"}}>
            <div style={{textAlign: "center"}}>
                <img style={{width: 85,height:85}} src={require("../../../assets/images/exclamation_icon.png")}></img>
            </div>

            <div style={{textAlign: "center",margin: "20px auto 12px auto"}}>
                <h2  className="manrope f-24 blue w-700 justify-in-center">
                    Subscription Usage Exceeded
                </h2>
                <p className="manrope f-18 grey-77">You have exceeded your allocated subscription usage. Please contact us to explore our enterprise plans.</p>
            </div> 
        </div>
    </Modal>
    </>
      );
}

export default SubscriptionPackageUpdate;