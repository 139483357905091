import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Table, Button, Space } from 'antd';
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import axios from 'axios';
import ENVIRONMENT from '../../../environments';
import FileConstants from '../../../FileConstants';
import DottedLoader from '../DottedLoader/DottedLoader';
import { getBaseURL, getUploadPlatform, SCENE_THUMBNAIL_URI } from '../../../environments/env';

let searchInput = ''

const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const IS_MS_PROVIDER = FileConstants.isMSProvider;
const ManagedServices = (props) => {
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(null);
    const [managedServiceScenes, setManagedServiceScenes] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [platform, setPlatform] = useState('');


    useEffect(() => {
        setLoading(true);
        getManagedServiceUsage();
    }, [props]);


    const getAppliedFilterValue = (filter) => {
        // return already applied filter value or null
        const url = new URL(window.location);
        let value = null;
        value = url.searchParams.get(filter);
        if (value)
            value = value.split(',');
        return value;
    }

    //Set query params value with filter
    const setFilterQueryParams = (filters) => {
        setSelectedFilters(filters);
        const url = new URL(window.location);

        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                url.searchParams.set(key, value);
            }
            else if (url.searchParams.has(key)) {
                url.searchParams.delete(key);
            }
        }
        window.history.replaceState({}, '', url)
    }

    // Search in table functionality
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    defaultValue={getDefaultSearchValue(dataIndex, setSelectedKeys, selectedKeys)}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        defaultFilteredValue: getAppliedFilterValue(dataIndex),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        }
    });

    //Search value according to filter
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    //Reset filter value
    const handleReset = (clearFilters) => {
        console.log(clearFilters)
        clearFilters();
        setSearchText('');
    };

    // Clear all filters when switching tabs
    const clearAllFilters = () => {
        searchInput = '';
        setSearchText('');
        setSearchedColumn('');
        if (selectedFilters) {
            const url = new URL(window.location);
            for (const [key, value] of Object.entries(selectedFilters)) {
                if (url.searchParams.has(key)) {
                    url.searchParams.delete(key);
                }
            }
            window.history.replaceState({}, '', url)
        }
        setSelectedFilters(null);
    };


    const getColumns = () => {
        return [
            {
                title: <span className="manrope f-14 black-14">Model ID</span>,
                dataIndex: 'product_id',
                key: 'product_id',
                ...getColumnSearchProps('product_id'),
                render: (text) => (
                    <a className="manrope f-12" href={"/msp_products/" + text} target='_blank'>{text}</a>),
            },
            {
                title: <span className="manrope f-14 black-14">Model Name</span>,
                dataIndex: 'product_name',
                key: 'product_name',
                ...getColumnSearchProps('product_name'),
                render: (text) => (
                    <span className="manrope f-12 black-14">{text}</span>),
            },
            {
                title: <span className="manrope f-14 black-14">Scene ID</span>,
                dataIndex: 'scene_id',
                key: 'scene_id',
                ...getColumnSearchProps('scene_id'),
                render: (id, record) => id.length == 0 ? <span className="manrope f-12 black-14">N/A</span> : id.map((id_value, index) =>
                    <a className="manrope f-12" target='_blank' href={"/scene_renders/" + id_value}>{id_value} {id.length - 1 == index ? "" : ", "}</a>),
            },
            {
                title: <span className="manrope f-14 black-14">Scene Name</span>,
                dataIndex: 'scene_name',
                key: 'scene_name',
                ...getColumnSearchProps('scene_name'),
                render: (text) => (
                    <span className="manrope f-12 black-14">{text}</span>
                ),
            },
            {
                title: <span className="manrope f-14 black-14">1K Render</span>,
                dataIndex: 'render_1k',
                key: 'render_1k',
                render: (renders, record) => renders.length == 0 ? <span className="manrope f-12 black-14">N/A</span> : renders.map((render_name, index) =>
                    <a className="manrope f-12" target='_blank' href={getBaseURL(platform) + SCENE_THUMBNAIL_URI + render_name}>{render_name.split("/")[1]} {renders.length - 1 == index ? "" : ", "}</a>),
            },
            {
                title: <span className="manrope f-14 black-14">1K Status</span>,
                dataIndex: 'status_1k',
                key: 'status_1k',
                filters: filters && filters.filter_status_1k,
                defaultFilteredValue: getAppliedFilterValue('status_1k'),
                onFilter: (value, record) => record.status_1k == value,
                render: text => <span className="manrope f-12 black-14">{FileConstants.MSP_RENDER_STATUS[text] || "N/A"}</span>,
            },
            {
                title: <span className="manrope f-14 black-14">4K Render</span>,
                dataIndex: 'render_4k',
                key: 'render_4k',
                render: (renders, record) => renders.length == 0 ? <span className="manrope f-12 black-14">N/A</span> : renders.map((render_name, index) =>
                    <a className="manrope f-12" target='_blank' href={getBaseURL(platform) + SCENE_THUMBNAIL_URI + render_name}>{render_name.split("/")[1]} {renders.length - 1 == index ? "" : ", "}</a>
                ),
            },
            {
                title: <span className="manrope f-14 black-14">4K Status</span>,
                dataIndex: 'status_4k',
                key: 'status_4k',
                filters: filters && filters.filter_status_4k,
                defaultFilteredValue: getAppliedFilterValue('status_4k'),
                onFilter: (value, record) => record.status_4k == value,
                render: text => <span className="manrope f-12 black-14">{FileConstants.MSP_RENDER_STATUS[text] || "N/A"}</span>,
            },
            {
                title: <span className="manrope f-14 black-14">Last Modified</span>,
                dataIndex: 'last_modified',
                key: 'last_modified',
                render: text => <span className="manrope f-12 black-14">{text}</span>,
                sorter: (a, b) => new Date(a.last_modified) - new Date(b.last_modified),
            },
            {
                title: <span className="manrope f-14 black-14">Designed For</span>,
                dataIndex: 'designed_for',
                key: 'designed_for',
                render: text => <span className="manrope f-12 black-14">{text}</span>,
                hidden: (IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME) || !IS_MS_PROVIDER
            }

        ].filter(col => !col.hidden);
    }

    /*Get managed service usage*/
    const getManagedServiceUsage = () => {
        let payload = {
            "required_fields": ["product_id", "product_name", "scene_id", "scene_name", "render_1k", "render_4k", "status_1k"
                , "flag_1k", "status_4k", "flag_4k", "designed_for", "last_modified", "designed_for", "platform"],
            "order_by": "last_modified_stamp desc"
        }
        let filter_string = "";
        if (IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME) {
            filter_string = "(designed_for__exact='" + MANAGED_CUSTOMER_USERNAME + "'&&customer_username__exact='" + CUSTOMER_USERNAME + "')"
        } else if (IS_MS_PROVIDER) {
            filter_string = "(designed_for__notexact=''&&customer_username__exact='" + CUSTOMER_USERNAME + "')"
        } else {
            if (props.account_type == "admin") {
                if (props.usage_type == 'customer') {
                    filter_string = "(shared_customer_username__exact='" + props.data_value + "')"
                }
                if (props.usage_type == 'company') {
                    filter_string = "(shared_company_id__exact=" + props.data_value + ")"
                }
            } else {
                filter_string = "(shared_customer_username__exact='" + CUSTOMER_USERNAME + "')"
            }
        }
        filter_string = filter_string + "&&(last_modified_stamp__lessthanrequals='" + props.end_date + "'&&last_modified_stamp__greaterthanrequals='" + props.start_date + "')"
        payload['filter_string'] = filter_string;

        console.log('Payload', payload);

        axios.post(ENVIRONMENT.GET_PRODUCT_MANAGED_LIFESTYLES, payload)
            .then(res => {
                console.log('Response', res.data)
                if (res.data) {
                    setPlatform(res.data["platform"])
                    populateData(res.data)
                    setLoading(false);
                }
            }).catch(err => {
                console.log(err)
            });
    }


    /* Prepare filters and get data*/
    const populateData = (response_data) => {
        let returned_values = {}
        let filter_values = {
            filter_status_1k_values: [],
            filter_status_4k_values: [],

        }
        let values = {
            status_1k_values: [],
            status_4k_values: [],
        };
        if (response_data.length > 0) {
            console.log(response_data)
            let modified_data = groupDataByAttribute(response_data, 'product_id')
            setManagedServiceScenes(modified_data);
            modified_data.map(ele => {
                returned_values = createFilters(ele,
                    filter_values,
                    values);
                values = returned_values.all_values
                filter_values = returned_values.filters
            })
            handleFilteringOptions(
                filter_values,
                values);
        } else {
            setManagedServiceScenes([]);
        }

        console.log('All', values)

    }

    const groupDataByAttribute = (data, attribute) => {
        let groups = data.reduce((groups, data_row) => {
            let distinct_attr = data_row[attribute]
            if (!groups[distinct_attr]) {
                groups[distinct_attr] = [];
            }
            groups[distinct_attr].push(data_row);
            return groups;
        }, {});

        // To add it in the array format instead
        let groupArrays = Object.keys(groups).map((distinct_attr) => {
            return {
                distinct_attr,
                info: groups[distinct_attr]
            };
        });

        let table_info = [];
        let row_info = {};
        let render_1k = [];
        let render_4k = [];
        let scene_id = [];
        let scene_name = "";
        groupArrays.map((data) => {
            render_1k = [];
            render_4k = [];
            scene_id = [];
            scene_name = "";
            data['info'].map((info, index) => {
                scene_id.push(info['scene_id']);
                scene_name = scene_name + (info['scene_name'] + (index == data['info'].length - 1 ? "" : ","))
                if (info['render_1k'].length > 0) {
                    info['render_1k'].map((render_name) => {
                        render_1k.push(info['scene_id'] + "/" + render_name)
                    })
                }
                if (info['render_4k'].length > 0) {
                    info['render_4k'].map((render_name) => {
                        render_4k.push(info['scene_id'] + "/" + render_name)
                    })
                }
                row_info = info
            })
            row_info['scene_id'] = scene_id;
            row_info['scene_name'] = scene_name;
            row_info['render_1k'] = render_1k;
            row_info['render_4k'] = render_4k;
            table_info.push(row_info);
        })

        console.log("Table Info", table_info)

        return table_info;

    }

    /* Prepare filter data according to attributes */
    const createFilters = (data, filter_values, values) => {

        if (filter_values) {
            let status_1k_value = data['status_1k'];
            if (status_1k_value != "" && filter_values.filter_status_1k_values && !filter_values.filter_status_1k_values.includes(status_1k_value)) {
                filter_values.filter_status_1k_values.push(status_1k_value);
            }
            if (status_1k_value != '') {
                values.status_1k_values.push(status_1k_value)
            }
            let status_4k_value = data['status_4k'];
            if (status_4k_value != "" && filter_values.filter_status_4k_values && !filter_values.filter_status_4k_values.includes(status_4k_value)) {
                filter_values.filter_status_4k_values.push(status_4k_value);
            }
            if (status_4k_value != '') {
                values.status_4k_values.push(status_4k_value)
            }

            let filters = {
                filter_status_1k_values: filter_values.filter_status_1k_values,
                filter_status_4k_values: filter_values.filter_status_4k_values
            }
            let all_values = {
                status_1k_values: values.status_1k_values,
                status_4k_values: values.status_4k_values
            }
            return {
                filters,
                all_values
            }
        }

    }

    //Get counts of filters and populate their data in filters
    const handleFilteringOptions = (filter_values, values) => {
        let filter_status_1k_values = filter_values.filter_status_1k_values;
        let filter_status_4k_values = filter_values.filter_status_4k_values;

        let filter_status_1k_objects = [];
        let filter_status_4k_objects = [];


        filter_status_1k_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });
        filter_status_4k_values.sort(function (a, b) {
            return (a.toLowerCase() > b.toLowerCase()) ? 1 : -1
        });

        let counts = {
            status_1k_count: getCounts(values.status_1k_values),
            status_4k_count: getCounts(values.status_4k_values)
        }

        filter_status_1k_values.forEach(filter_status_1k_value => {
            filter_status_1k_objects.push({
                text: `${filter_status_1k_value} (${counts['status_1k_count'][filter_status_1k_value]})`,
                value: filter_status_1k_value,
            })
        });

        filter_status_4k_values.forEach(filter_status_4k_value => {
            filter_status_4k_objects.push({
                text: `${filter_status_4k_value} (${counts['status_4k_count'][filter_status_4k_value]})`,
                value: filter_status_4k_value,
            })
        });


        let filters = {
            filter_status_1k: filter_status_1k_objects,
            filter_status_4k: filter_status_4k_objects,
        };

        console.log('Filters: ', filters)
        setFilters(filters);
    }

    //Return default filter value
    const getDefaultSearchValue = (dataIndex, setSelectedKeys, selectedKeys) => {
        let filteredValue = getAppliedFilterValue(dataIndex);
        if (!selectedKeys)
            setSelectedKeys(filteredValue);
        return filteredValue;
    }

    // Return count of requests
    const getCounts = (data) => {
        const counts = {};

        for (const num of data) {
            counts[num] = counts[num] ? counts[num] + 1 : 1;
        }

        return counts;
    }


    return (
        <>
            {loading ? <div className="justify-in-center" style={{ width: '100%' }}>
                <LoadingOutlined style={{ fontSize: 32, marginTop: 20, marginBottom: 20 }} />
            </div> : <Row>
                <Col span={24}>
                    <Table
                        tableLayout='fixed'
                        onChange={
                            (pagination, filters, sorter, extra) => {
                                setFilterQueryParams(filters)
                            }
                        }
                        columns={getColumns()}
                        dataSource={managedServiceScenes} />
                </Col>
            </Row>}
        </>
    );
}

export default ManagedServices;