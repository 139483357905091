/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { Card, Tooltip, Dropdown, Space, Button } from 'antd';
import * as Utilities from '../../Utilities';
import FileConstants from '../../../../FileConstants';
import _ from "lodash" 

const SpaceAreaSelection = (props) => {
    const toggleAreaSelection = (area) => {
        let scenes = _.cloneDeep(props.selectedScenes);

        const sceneIndex = scenes.findIndex(scene => scene.id === props.id && (scene.area_name && scene.area_name == area))
        if(sceneIndex !== -1){
            scenes.splice(sceneIndex, 1)
        } else {
            scenes.push({id: props.scene.id ,collection_id: props.scene.collection_id, area_name: area })
        }
        console.log(scenes)
        props.setSelectedScenes(scenes);
    }

    const checkIfAreaSelected = (area) => {
        const sceneIndex = props.selectedScenes.findIndex(scene => scene.id == props.id && scene.area_name && scene.area_name === area);

        if (sceneIndex !== -1) {
            return true;
        }
        return false;
    }

    const checkIfItemSelected = (ele) => {
        return (props.selectedScenes && props.selectedScenes.some(item => item.id === props.id))
    }
    return (<Dropdown overlay={<div className='comment-option-dropdown'>
        {props.space_areas.map((area, index) => (
            <Space key={index}
                className='area-pd-space justify-space-between'>
                <p className='manrope f-14 lh-28 mb-0'>{area}</p>
                <Button ghost className={`item-add-btn justify-in-center manrope f-14 lh-28 ${checkIfAreaSelected(area) ? `red` : `blue`}`} onClick={() => toggleAreaSelection(area)}>
                    {checkIfAreaSelected(area) ? 'Remove' : 'Add'}
                </Button>
            </Space>
        ))}
    </div>}
        trigger={['click']}>
        {checkIfItemSelected(props.scene) ? 
            <CheckOutlined title="Added To Scene"
            className="item-icons plus button-checked"
          />:
          props.preselectedScenes?.includes(props.id) ?
          <CheckOutlined title="Added To Scene"
            className="item-icons plus button-checked-disabled"
            disabled
          /> 
        :<PlusOutlined
            title="Add To Scene"
            className="item-icons plus"
        />}
    </Dropdown>);
}


const SceneCard = (props) => {
  const {
    id,
    itemType,
    customer_username,
    item_type,
    requestPlan,
    thumbnail,
    name,
    category,
    company_display_name,
    subscriptionPlan,
    company_id,
    price,
    handleAddClick,
    ele,
    selectedId,
    creationType,
    selectedScenes,
    setSelectedScenes,
    isStore,
    requestStatusRoom,
    preselectedSpaces,
    preselectedScenes,
    last_modified_stamp
  } = props;
  
  const CUSTOMER_USERNAME = localStorage.getItem('username');

  const checkIfItemAddedToLibrary = (room) => {
    return isStore && (item_type && item_type == 'not_shared') && (room.price != 0 || room.price != '');
  }

  return (
    <Card className={`card-shadow-product room-card ${(!(requestStatusRoom == 'always_allowed' ||requestStatusRoom == 'allowed') && checkIfItemAddedToLibrary(ele)) ? `product-hover` : ``}`} >
      {
        (!(requestStatusRoom == 'always_allowed' ||requestStatusRoom == 'allowed') && checkIfItemAddedToLibrary(ele)) ? 
        <a href='/store?item_type=spaces' target='_blank'>
            <Tooltip title={<span className='manrope f-12 white'>Please go to store to purchase this item</span>}>
                <img src='/img/redirect-icon.svg' className="item-icons plus" />
            </Tooltip>
        </a> :
        (creationType && itemType == 'scene' && ele && ele.space_areas.length != 0) ? 
        <SpaceAreaSelection
            selectedScenes={selectedScenes}
            preselectedScenes={preselectedScenes}
            setSelectedScenes={setSelectedScenes}
            scene={ele}
            space_areas={ele.space_areas}
            id={id} />
        :
        preselectedSpaces?.includes(id) || preselectedScenes?.includes(id) ?
        <CheckOutlined title="Added To Scene"
            className="item-icons plus button-checked-disabled"
            disabled
          /> :
        (selectedId.includes(id)) ?
          <CheckOutlined title="Added To Scene"
            className="item-icons plus button-checked"
            onClick={() => handleAddClick(ele)}
          /> :
          (creationType != 'add-product-to-existing-scene') || (ele && ele.space_areas.length == 0) ?
            <PlusOutlined
                title="Add To Scene"
                className="item-icons plus"
                onClick={() => handleAddClick(ele)}
            /> :
            <SpaceAreaSelection
                selectedScenes={selectedScenes}
                setSelectedScenes={setSelectedScenes}
                scene={ele}
                space_areas={ele.space_areas}
                preselectedScenes={preselectedScenes}
                id={id} />
      }
      <div
        className="suggested-product"
        target="_blank"
        style={{ zIndex: '1' }}
      >
        <div className="box">
          <img
            key={id}
            className="product-image space-card-img"
            src={thumbnail || require('../../../../assets/images/chair.png')}
          />
        </div>
        <div className='justify-in-start' style={{ margin: '12px 12px 8px 12px' }}>
          <Tooltip title={<span className="manrope f-12 white"> {name}</span>}>
            <div
              className="manrope f-14 w-500 black-00 j-end clamp-text w-100"
            >
              {name}
            </div>
          </Tooltip>
          {last_modified_stamp ? 
          <Tooltip title={ <span className="manrope f-12 white"> { Utilities.timestampToTimeSince( ele.last_modified_stamp, "tooltip")} </span>}>
            <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                <img src="/img/carbon_time.svg" style={{ marginRight: 2 }} /> {Utilities.timestampToTimeSince(ele.last_modified_stamp)}
            </span>
          </Tooltip> : ""}
        </div>
        {category ? (
          <>
            <div
              className="justify-in-start manrope f-12 grey-77 capitalize"
              style={{
                marginLeft: '12px',
                marginRight: '12px',
                marginBottom: '4px',
                textAlign: 'left',
              }}
            >
              <div style={{ marginRight: 4 }}>Category:</div>
              <div style={{ color: '#333333' }}>{category}</div>
            </div>
          </>
        ) : (
          ''
        )}
        {company_display_name ? (
          <>
            <div
              className="justify-in-start manrope f-12 grey-77 capitalize"
              style={{
                marginBottom: '4px',
                marginLeft: '12px',
                marginRight: '12px',
                textAlign: 'left',
              }}
            >
              <div style={{ marginRight: 4 }}>Brand:</div>
              <div style={{ color: '#333333' }}>
                {company_display_name}
              </div>
            </div>
          </>
        ) : (
          ''
        )}

        <div
          className="justify-in-start manrope f-12 grey-77 capitalize"
          style={{
            marginBottom: '12px',
            marginLeft: '12px',
            marginRight: '12px',
            textAlign: 'left',
          }}
        >
          {itemType != 'scene' &&
          item_type == 'shared' &&
          subscriptionPlan != 'enterprise' &&
          !FileConstants.ALL3D_COMPANY_IDS().includes(
              company_id.toString(),
          ) ? (
            <div style={{ marginRight: 4 }}>Already Purchased</div>
          ) : itemType != 'scene' &&
            item_type == 'shared' &&
            subscriptionPlan == 'enterprise' &&
            !FileConstants.ALL3D_COMPANY_IDS().includes(
                company_id.toString(),
            ) ? (
            <div style={{ marginRight: 4 }}>Added to Library</div>
          ) : (
            <>
              {price == 0 ? (
                <div style={{ color: '#333333' }}>Free</div>
              ) : company_id &&
                FileConstants.ALL3D_COMPANY_IDS().includes(
                    company_id.toString(),
                ) &&
                price &&
                price != 0 ? (
                <div style={{ color: '#333333' }}>Included in platform fee</div>
              ) : price ? (
                <>
                  <div>Price:</div> &nbsp;
                  <div style={{ color: '#333333' }}>${price}</div>
                </>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default SceneCard;
