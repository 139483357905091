import React from 'react';
import { InputNumber, Slider} from 'antd';
import './styles.css'
class CameraControls extends React.Component {


    state={
        waistValue : parseInt( (0.92/ this.props.room_height *150)),
        eyeValue: parseInt( ( 1.68 / this.props.room_height * 150)),
        marks : {[parseInt( (0.94/ this.props.room_height *150))]:{style:{color:"#276dd7", fontWeight:"bold", fontSize:10}, label:"Waist"}, [parseInt( ( 1.7 / this.props.room_height * 150))]:{style:{color:"#276dd7", fontWeight:"bold", fontSize:10}, label:"Eye"}},
        room_height: this.props.room_height,
        sliderFactor: 0
    }

    componentDidMount(){
        let handleDiv = document.createElement('div');
        handleDiv.id = "camera";
        handleDiv.innerHTML = '<img id="camera_handle" style="width:32px; height:32px; position: absolute;top: -8px;right: -13px;-moz-user-select: none;-webkit-user-select: none;user-select: none;" src="/img/Camera_Handle.png"></img>';
        let slider_handle = document.querySelector(".camera-height-slider .ant-slider-handle");
        slider_handle.style.border = "none";
        slider_handle.style.zIndex = 1000;
        slider_handle.appendChild(handleDiv);
        let value=this.props.getCameraAngle();
        slider_handle.style.transform = "rotate(" + (this.props.getCameraAngle()) + "deg)";
        

        window.$("#slider").roundSlider({
                radius: 130,
                circleShape: "half-right",
                sliderType: "min-range",
                showTooltip: false,
                min: -90,
                startValue: 0,
                max: 90,
                value: this.props.getCameraAngle(),
                drag: (args) =>  {
                    this.props.setPolarAngle(args.value + 90);
                    this.props.setCameraAngle(args.value)
                    slider_handle.style.transform = "rotate("+args.value+"deg)";
                }

            });
            this.setState({
                sliderFactor: (document.getElementById("camera_controls_slider").clientHeight-9) / 150
            })

            this.props.setCameraHeight(this.props.sliderPosition3D/450)
            this.props.setCameraStateHeight( Math.round(( ( (this.props.sliderPosition3D/150) *(this.props.room_height*39.37)))) )
    }

    componentDidUpdate(){
        window.$('#slider').data("roundSlider").option("readOnly", this.props.disableCameraAngle);
        if (this.props.showRotationSlider) {
            window.$("#slider").show();
        }
        else {
            window.$("#slider").hide();
        }
        
        if(this.props.room_height!=this.state.room_height){
            this.setState({
                room_height : this.props.room_height,
                waistValue : parseInt( (0.92/ this.props.room_height *150)),
                eyeValue: parseInt( ( 1.68 / this.props.room_height * 150)),
                marks : {[parseInt( (0.94/ this.props.room_height *150))]:{style:{color:"#276dd7", fontWeight:"bold", fontSize:10}, label:"Waist"}, [parseInt( ( 1.7 / this.props.room_height * 150))]:{style:{color:"#276dd7", fontWeight:"bold", fontSize:10}, label:"Eye"}},
                sliderFactor: (document.getElementById("camera_controls_slider").clientHeight-9) / 150,
            })
            if(this.props.sliderPosition3D == 35){
                this.props.setCameraHeight(this.props.sliderPosition3D/450)
                this.props.setCameraStateHeight( Math.round(( ( (this.props.sliderPosition3D/150) *(this.props.room_height*39.37)))) )
            }


        }
        if(this.props.camera_height && this.props.room_height){
            if((this.props.camera_height/39.37 - this.props.room_height)>0.5){
                this.onChange(this.props.sliderPosition3D)
            }
        }
    }


    onChange = (value) => {
            let val = value
            // checking if value is near waist, snapping to exact waist value
            if((this.state.room_height/150 * val > 0.77)  && (this.state.room_height/150 *val < 1.03)){
                val = this.state.waistValue;
            }
            if((this.state.room_height/150 * val > 1.55)  && (this.state.room_height/150 *val < 1.81)){
                val = this.state.eyeValue;
            }
            this.props.setCameraHeight(val/450);
            this.props.setCameraStateHeight( Math.round(( ( (val/150) *(this.props.room_height*39.37)))) )
    }



    onChangeInput = (val) =>{
        let value = val/39.37;
        if(value>=0 && value<this.props.room_height){
            this.props.setCameraStateHeight(Math.round( (value*39.37)))
            if(value >=0 && value < this.state.room_height ){
            this.props.setCameraHeight((value/3)/(this.state.room_height));
            }
        }
    }


    render(){
     return <div id="camera_controls_container" className="camera_controls_container-3d" style={{height:"100%", width:"50%", marginTop:"2%"}}>
            <div id="main-camera_controls_slider" style={{backgroundColor: "rgba(210,210,210,0.5)", marginLeft:'2%',borderRadius: 4, width:"100%", height:"97%"}}>
            <div id="camera_controls_slider" style={{height: "85%", marginLeft:"15", paddingTop:"8%", width:"100%"}}>
                <Slider className="camera-height-slider" included={true} marks={this.props.showPositionSliderMarks ? this.state.marks: ''} value={this.props.sliderPosition3D} trackStyle={{backgroundColor:"#276dd7"}} railStyle={{backgroundColor:"#cccccc"}} vertical style={{marginLeft:18}} tooltipVisible={false} onChange={this.onChange} defaultValue={150-this.props.sliderPosition3D} min={0} max={150} />
                <div id="slider" style={{position:'absolute', left:'10%', width:"50%" ,top:(150 -this.props.sliderPosition3D)*this.state.sliderFactor-(114)}}></div>
            </div>
                <div style={{ display: "flex", width: "40%", "justify-content": "space-between"}}>
                    <div style={{position:"absolute", top:"60%", left:"3%", width: "15%"}}>
                        <hr style={{border: "0.1vh solid #666666"}}></hr>
                    </div>
                    <span style={{position:"absolute", top:"63%", left:"21%", width: "10%", fontSize:"0.8em"}}>
                        Floor
                    </span>
                    <div style={{position:"absolute", left:"33%", top:"60%", width: "15%"}}>
                        <hr style={{border: "0.1vh solid #666666"}}></hr>
                    </div>
                </div>

            </div>
            <span style={{color:"#111111", position:"absolute", left:"60%", top:"0%", fontSize:'1.4vh', fontWeight:"bold", whiteSpace:"nowrap"}}>
                Camera Height
            </span>

            <span style={{position:"absolute", left:"58%", top:"5%"}}>
                <InputNumber
                formatter={(value) => `${value} inches`}
                parser={(value) => value.replace('inches', '')}
                name="Camera Height" min={0} max={(this.props.room_height*39.37)+1} onChange={this.onChangeInput} style={{paddingLeft:2, fontSize:"1.5vh", width:"80%" , height:"4vh",  borderRadius:"4px", borderColor:"#276dd7"}} value={(this.props.camera_height)} defaultValue={(this.props.camera_height)} />
            </span>
            <span style={{color:"#111111", position:"absolute", left:"60%", top:"14%", fontSize:'1.4vh', fontWeight:"bold", whiteSpace:"nowrap"}}>
                Camera Angle
            </span>

            <span style={{position:"absolute", left:"58%", top:"19%"}}>
                <InputNumber
                disabled={this.props.disableCameraAngle}
                formatter={(value) => `${value}°`}
                parser={(value) => value.replace('°', '')}
                name="Camera Angle" min={0} max={180} value={90-Math.round(this.props.camera_angle)} onChange={this.props.onChangeCameraAngle} style={{paddingLeft:2, fontSize:"1.5vh", width:"80%" , height:"4vh",  borderRadius:"4px", borderColor:"#276dd7"}}  />
            </span>
        </div>
    }
}

export default CameraControls;

















