import React, { useEffect, useState, useRef } from 'react';
import 'antd/dist/antd.css';
import AddCollection from "./AddCollection";
import CustomerMainLayout from '../CustomerMainLayout'
import { LoadingOutlined, ShareAltOutlined, PlusOutlined, FilterOutlined, PlusCircleFilled, FolderOpenOutlined, AppstoreOutlined } from '@ant-design/icons';
import DottedLoader from "../../DottedLoader";
import RoomsList from './RoomsList';
import '@ant-design/compatible/assets/index.css';
import _ from "lodash"; // Import the entire lodash library
import axios from 'axios';
import {
  Input,
  Col,
  Row,
  Card,
  Select,
  Radio,
  Button,
  Form,
  Modal,
  Tooltip,
  Tabs,
  Empty,
  message,
  Drawer,
  Tag
} from 'antd';
import ENVIRONMENT from '../../../../environments';
import {
  listRooms,
  getScannedRooms,
  getArrangements,
  reorderData,
  createFolder,
  moveToFolder,
  renameFolder,
} from "../../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import SuccessModal from '../../SuccessModal/SuccessModal';
import AddItemToStore from '../../AddItemToStore';
import * as Utilities from '../../Utilities';
import ReorderIcon from '../../../../icons/ReorderIcon';
import BatchSharingModal from '../BatchSharinModal/BatchSharingModal';


const { TabPane } = Tabs;
const formRef = { useRef };
let SEQUENCE_ID_OTHER = -1;
let SEQUENCE_ID_OWNED = -1;

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const MANAGED_SHARED_ENTITES = localStorage.getItem("managed_shared_entites");
const SEQUENCE_IDS = localStorage.getItem('sequence_ids');
const { Option } = Select;
const FILTER_KEYS_FOR_QUERY_PARAMS = ['category']


const CustomerRooms = (props) => {
  const [selectedRoomIdForCollection, setSelectedRoomIdForCollection] = useState(null);
  const [collectionModalVisible, setCollectionModalVisible] = useState(false);
  const [sharingModal, setSharingModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedRoomIds, setSelectedRoomIds] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState('download_and_embed');
  const [sharingLoader, setSharingLoader] = useState(false);
  const [selectable, setSelectable] = useState(false);
  const [defaultRoomIds, setDefaultRoomIds] = useState([]);
  const [libraryRooms, setLibraryRooms] = useState([]);
  const [companyID, setCompanyID] = useState(-1);
  const [currentId, setCurrentId] = useState(-1);
  const [currentCustomer, setCurrentCustomer] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [folderState, setFolderState] = useState(false);
  const [selectedParentRoom, setSelectedParentRoom] = useState(null);
  const [parentFolder, setParentFolder] = useState([]);
  const [roomLibrary, setRoomLibrary] = useState([]);
  const [savedID, setSavedID] = useState(-1);
  const [groupedRooms, setGroupedRooms] = useState([]);
  const [folderLoader, setFolderLoader] = useState(false);
  const [roomTab, setRoomTab] = useState("customer_owned");
  const [tabActiveKey, setTabActiveKey] = useState("");
  const [folderSpaces, setFolderSpaces] = useState([]);
  const [, updateState] = React.useState();
  const [sharedTabType, setSharedTabType] = useState('all');
  const [mspCustomerSharedTabType, setMspCustomerSharedTabType] = useState('all');
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [sellVisible, setSellVisible] = useState(false);
  const [showRoomDetails, setShowRoomDetails] = useState(false);
  const [sellLoader, setSellLoader] = useState(false);
  const [price, setPrice] = useState(0);
  const [removeFromStore, setRemoveFromStore] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [showBottomNavBar, setShowBottomNavBar] = useState(false);
  const [reorderItems, setReorderItems] = useState(false);
  const [selectedFolderRooms, setSelectedFolderRooms] = useState([]);
  const [newFolder, setNewFolder] = useState(false);
  const [existingFolder, setExistingFolder] = useState(false);
  const [viewRenameFolderModal, setViewRenameFolderModal] = useState(false);
  const [viewUnGroupFolderModal, setViewUnGroupFolderModal] = useState(false);
  const [entityArrangement, setEntityArrangement] = useState([]);
  const [ownedRoomsEntityArrangement, setOwnedRoomsEntityArrangement] = useState([]);
  const [otherRoomsEntityArrangement, setOtherRoomsEntityArrangement] = useState([]);
  const [mspOwnedRoomsEntityArrangement, setMspOwnedRoomsEntityArrangement] = useState([]);
  const [mspOtherRoomsEntityArrangement, setMspOtherRoomsEntityArrangement] = useState([]);
  const [folderArrangement, setFolderArrangement] = useState([]);
  const [ownedRoomsFolderArrangement, setOwnedRoomsFolderArrangement] = useState([]);
  const [otherRoomsFolderArrangement, setOtherRoomsFolderArrangement] = useState([]);
  const [mspOwnedRoomsFolderArrangement, setMspOwnedRoomsFolderArrangement] = useState([]);
  const [mspOtherRoomsFolderArrangement, setMspOtherRoomsFolderArrangement] = useState([]);
  const [saveOrderedItems, setSaveOrderedItems] = useState(false);
  const [orderSaved, setOrderSaved] = useState(false);
  const [sortedItems, setSortedItems] = useState([]);
  const [newLoader, setNewLoader] = useState(true);
  const [newFolderName, setNewFolderName] = useState(null);
  const [hashUpdated, setHashUpdated] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFolderParentId, setSelectedFolderParentId] = useState(null);
  const [folderNewName, setFolderNewName] = useState(null);
  const [reorderByModal, setReorderByModal] = useState(false);
  const [reorderByCompany, setReorderByCompany] = useState(false);
  const [mspCustomerReorderByCompany, setMspCustomerReorderByCompany] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [ownedCompanyArrangement, setOwnedCompanyArrangement] = useState(false);
  const [otherCompanyArrangement, setOtherCompanyArrangement] = useState(false);
  const [reorderingLoader, setReorderingLoader] = useState(false);
  const [companyArrangement, setCompanyArrangement] = useState(false);
  const [customerCompanyArrangement, setCustomerCompanyArrangement] = useState(false);
  const [foldersListExceptCurrentFolder, setFoldersListExceptCurrentFolder] = useState([]);
  const [saveByCompany, setSaveByCompany] = useState(false);
  const [mspCustomerSaveByCompany, setMspCustomerSaveByCompany] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [renameError, setRenameError] = useState(null);
  const [createVariantList, setCreateVariantList] = useState(false);
  const [triggerGroupRoomUpdate, setTriggerGroupRoomUpdate] = useState(false);
  const [orderUpdated, setOrderUpdated] = useState(false);
  const [initialLoadShared, setInitialLoadShared] = useState(true);
  const [initialLoadOwned, setInitialLoadOwned] = useState(true);
  const [sharedRoomLoader, setSharedRoomLoader] = useState(true);
  const [ownedRoomLoader, setOwnedRoomLoader] = useState(true);
  const [ownedOriginalData, setOwnedOriginalData] = useState([]);
  const [sharedOriginalData, setSharedOriginalData] = useState([]);
  const [mspCustomerOwnedOrgRooms, setMspCustomerOwnedOrgRooms] = useState([])
  const [mspCustomerOtherOrgRooms, setMspCustomerOtherOrgRooms] = useState([])
  const [folderInfo, setFolderInfo] = useState([]);
  const [ownedRoomData, setOwnedRoomData] = useState([]);
  const [sharedRoomData, setSharedRoomData] = useState([]);
  const [mspCustomerOwnedRooms, setMspCustomerOwnedRooms] = useState([]);
  const [mspCustomerOtherRooms, setMspCustomerOtherRooms] = useState([])
  const [ownedFolderData, setOwnedFolderData] = useState([]);
  const [otherFolderData, setOtherFolderData] = useState([]);
  const [mspCustomerOwnedFolderData, setMspCustomerOwnedFolderData] = useState([]);
  const [mspCustomerOtherFolderData, setMspCustomerOtherFolderData] = useState([]);
  const [checkIfItemsHaveReorderedOnce, setCheckIfItemsHaveReorderedOnce] = useState([]);
  const [folderID, setFolderID] = useState(null);
  const [actionLoader, setActionLoader] = useState(false);
  const [checkIfTabChanged, setCheckIfTabChanged] = useState(false);
  const [lastFolderAccessed, setLastFolderAccessed] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [spaceCategoryValue, setSpaceCategoryValue] = useState([]);
  const [filtersDrawer, setFiltersDrawer] = useState(false);
  const [spaceCategories, setSpaceCategories] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [updatingAccessEmails, setUpdatingAccessEmails] = useState({})
  const [accessUpdateLoader, setAccessUpdateLoader] = useState(false)
  const [inviteLoader, setInviteLoader] = useState(false)
  const [customerSequences, setCustomerSequences] = useState({});
  const [currentView, setCurrentView] = useState("folder_view");
  const [filtersAppliedFlag, setFiltersAppliedFlag] = useState(false);
  const [filteredViewInitialLoader, setFilteredViewInitialLoader] = useState(false);
  const [tabCount, setTabCount] = useState({owned: -1, shared: -1, customer_owned: -1, customer_shared: -1});
  const [projects, setProjects] = useState([]);
  const [tempOwnedCount, setTempOwnedCount] = useState(0);
  const [tabChangeThroughClick, setTabChangeThroughClick] = useState(false);

  useEffect(() => {
    applyFiltersFromQueryStrings();
    applySearchFromQueryString();
  }, [spaceCategories]);

  useEffect(() => {
    updateFiltersInQueryString()
  }, [filtersAppliedFlag])

  const updateTabCount = (count, tabName) => {
    setTabCount(prevTabCount => ({
      ...prevTabCount,
      [tabName]: count
    }));
  };

  useEffect(() => {
    let flag = false;
    if (ownedRoomLoader || sharedRoomLoader) {
      const url = new URL(window.location.href)
      const searchValue = url.searchParams.get("search");
      if (searchValue !== null) {
        let cleanedSearchValue = decodeURIComponent(searchValue);
        if (cleanedSearchValue) {
          flag = true;
        }
      }
      const searchParams = new URLSearchParams(url.search);
      for (const [key, value] of searchParams.entries()) {
        if (FILTER_KEYS_FOR_QUERY_PARAMS.includes(key)) {
          flag = true;
        }
      }
    };
    setFilteredViewInitialLoader(flag);
  }, [ownedRoomLoader, sharedRoomLoader]);

  const getUserProjects = () => {
    let payload = {
      order_by: "last_modified_stamp",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "last_modified_stamp"
      ],
      filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
    };

    axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => 
    {
        let projects = []
        if(MANAGED_CUSTOMER_USERNAME){
            projects = res.data.map(item => ({
                ...item,
                name: `[${item.customer_username}] ${item.name} `
            }));
            setProjects(projects)
        }
        else{
            setProjects(res.data)
        }
    });
  }

  const applySearchFromQueryString = () => {
    const url = new URL(window.location.href);
    const searchValue = url.searchParams.get('search');
    if (searchValue !== null) {
      let cleanedSearchValue = decodeURIComponent(searchValue);
      if (cleanedSearchValue) {
        setSearchValue(cleanedSearchValue);
      }
    }
  };

  const removeSearchParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('search');
    props.history.replace(url.pathname + url.search);
  };

  const updateFiltersInQueryString = () => {
    let filters = {
      'category': spaceCategoryValue
    }

    const url = new URL(window.location.href);
  
    if(!initialLoadShared && !initialLoadOwned){
      // Iterate over each filter variable and add its values to the URL query parameters
      for (const filter in filters) {
  
            const values = filters[filter];
            if (values && values.length > 0) {
                if (Array.isArray(values)) {
                    // If multiple values are present for a filter, add them together under the same key
                    const combinedValues = values.join(',');
                    url.searchParams.set(filter, encodeURIComponent(combinedValues));
                } else {
                    // If a single value is present for a filter, set it, overwriting any existing value
                    if (values !== null && values !== undefined && values !== "") {
                        url.searchParams.set(filter, encodeURIComponent(values));
                    }
                }
            } else {
                // If no values are provided for a filter, remove it from the URL
                url.searchParams.delete(filter);
            }
  
      }
      window.history.replaceState({}, "", url);
    }
  }

  const applyFiltersFromQueryStrings = () => {
    const filters = {};
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    
    for (const [key, value] of searchParams.entries()) {
      if(FILTER_KEYS_FOR_QUERY_PARAMS.includes(key)){
        if (value.includes(',')) {
          filters[key] = value.split(',').map(decodeURIComponent);
        } else {
            filters[key] = [decodeURIComponent(value)];
        }
      }
    }

    if(Object.keys(filters).length > 0 ){
      // Get the applied filters and set their values
      setSpaceCategoryValue(filters['category']);

      setFiltersApplied(true);
      updateFiltersFlag();
      forceUpdate();
    }

  }

  const onDelete = () => {
    setDeleteLoader(true);
    let api_end_point = ENVIRONMENT.UPDATE_ROOM;
    let payload = {
      "room_id": currentId,
      "is_hidden": true,
      "username": CUSTOMER_USERNAME,
    };
    payload['sequence_id'] = roomTab == "owned" ? SEQUENCE_ID_OWNED : SEQUENCE_ID_OTHER

    let company_id = COMPANY_ID
    if (CUSTOMER_USERNAME != currentCustomer && company_id != companyID) {
      //case of customer deleting the room added from store or shared by some other customer
      api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
      payload = {
        "room_id": currentId,
        "username": CUSTOMER_USERNAME,
      };
    }

    axios.post(api_end_point, payload)
      .then((response) => {
        // Retrieve the counts array from localStorage
        const counts = JSON.parse(localStorage.getItem("entity_counts"));

        if (counts) {
        const spaceEntry = counts.find(entity => entity.type === "space");

        if (spaceEntry && spaceEntry.count > 0) {
            spaceEntry.count -= 1;
        }

        // Store the updated counts array back in localStorage
        localStorage.setItem("entity_counts", JSON.stringify(counts));
        } else {
        console.error("Counts not found in localStorage.");
        }

        message.info('Space Deleted.');
        setDeleteLoader(false);
        setDeleteModal(false);
        window.location.href = '/rooms';
      })
      .catch((error) => {
        console.log(error);
        message.error('Error in deleting space.');
        setDeleteLoader(false);
        setDeleteModal(false);

      });
  };

  const changeOrderBySelection = (e) => {
    setReorderByCompany(e.target.value);
  };

  const changeMSPCustomerOrderBySelection = (e) => {
    setMspCustomerReorderByCompany(e.target.value);
  }

  //creates entity arrangement when the rooms are first loaded, First time arrangements
  const createFirstArrangementOrder = (rooms, key) => {
    let items = checkIfItemsHaveReorderedOnce;
    items.push(key == "owned" ? "owned_space" : "other_space");
    setCheckIfItemsHaveReorderedOnce(items);
    const entityArrangementFirst = [];
    const folderArrangementFirst = [];
    let grouped_spaces = getGroupedSpaceData(rooms);
    // if room is not a variant or if room has is a variant but its parent does not exist in the library
    rooms.map((item, index) => {
        if(item.variant_of == "" || (item.variant_of != "" && !checkIfExistsInLibrary(rooms,item.variant_of)))
        {
        let obj = {};
        if (!checkIfVariantsExistOfParent(rooms, item.room_id)) {
          // if variants do not exist for this room
            obj = {
            entity_id: parseInt(item.room_id),
            ordering_number: index,
            parent_folder_id: "",
          };
          entityArrangementFirst.push(obj);
        } else {
          // if variant for this room exists in the library, 
          // then add it in folder arrangement
          let folderEntities = [parseInt(item.room_id)];
          let foldername = rooms.filter(
            (room) => {return parseInt(room.room_id) == parseInt(item.room_id)}
          );
          let folderData = grouped_spaces.filter(
            (room) => {return parseInt(room.parent_room_id) == parseInt(item.room_id)}
          );
          folderData[0].rooms.map((room) =>
            folderEntities.push(parseInt(room.room_id))
          );
          obj = {
            ordering_number: folderData[0]['index'],
            folder_name: "Variations of " + foldername[0].room_name,
            folder_entities: folderEntities,
          };
          folderArrangementFirst.push(obj);
        }
        }
    });

    // variants that are variants
    // ***also need to check if only child item is present but parent is not present
    const nonVariantRooms = rooms.filter(
      (room) => room.variant_of != "" && checkIfExistsInLibrary(rooms,room.variant_of)
    );
    const folderCount = {};
    nonVariantRooms.map((item) => {
      if (folderCount[parseInt(item.variant_of)] === undefined) {
        // adding parent item at the first index
        const obj = {
          entity_id: parseInt(item.variant_of),
          ordering_number: 0,
          parent_folder_id: "",
        };
        entityArrangementFirst.push(obj);
        folderCount[parseInt(item.variant_of)] = 1;
      } else {
       // updating ordering number of the rest of the items in the folder
        folderCount[parseInt(item.variant_of)]++;

      }
      let data = entityArrangementFirst && entityArrangementFirst.filter(
        (arrangement) => {
          return parseInt(item.room_id) == parseInt(arrangement.entity_id)
        });
      if (data && data.length == 0) {
        /* add arrangement info & ordering number of variation room if its not
        already present in the arrangement */
        const obj = {
          entity_id: parseInt(item.room_id),
          ordering_number: folderCount[parseInt(item.variant_of)],
          parent_folder_id: "",
        };
        entityArrangementFirst.push(obj);
      }
    });
    let payload = {
      action: "reorder_first_time",
      entity_type: getEntityType(key),
      username: getCurrentUserUsername(key),
      entity_arrangement: entityArrangementFirst,
      folder_arrangement: folderArrangementFirst,
      save_by_company: false,
    };
    if (SHARED_ENTITIES != undefined && COMPANY_ID && key == "owned") {
      if (SHARED_ENTITIES.split("_").includes("room")) {
        payload.company_id = COMPANY_ID;
        payload.save_by_company = true;
      }
    } else if (MANAGED_SHARED_ENTITES != undefined && MANAGED_COMPANY_ID && key == "customer_owned") {
      if (MANAGED_SHARED_ENTITES.split("_").includes("room")) {
        payload.company_id = MANAGED_COMPANY_ID;
        payload.save_by_company = true;
      }
    }
    if (rooms && rooms.length > 0 && entityArrangementFirst && entityArrangementFirst.length > 0) {
      organizeData(payload, getEntityType(key), false)
    }
  };

  useEffect(() => {
    if (SHARED_ENTITIES != undefined && COMPANY_ID) {
      const shared = SHARED_ENTITIES.split("_").includes("room");
      if (shared) {
        setSaveByCompany(true);
        setReorderByCompany(true);
      }
    }

    if (MANAGED_SHARED_ENTITES != undefined && MANAGED_COMPANY_ID) {
      const shared = MANAGED_SHARED_ENTITES.split("_").includes("room");
      if (shared) {
        setMspCustomerSaveByCompany(true);
        setMspCustomerReorderByCompany(true);
        }
      }
  }, []);

  const checkIfVariantsExistOfParent = (rooms, id) => {
    //check if variations exists for the room against this particular id
    if (rooms && rooms.length > 0) {
      let room = rooms.filter(
        (room) => room.variant_of == id.toString()
      );
      if (room.length > 0) return true;
    }
    return false;
  };

  const checkIfExistsInLibrary = (rooms, id) => {
    if (rooms && rooms.length > 0) {
      let room = rooms.filter(
        (room) => room.room_id == id.toString()
      );
      if (room.length > 0) return true;
    }
    return false;
  }

  useEffect(() => {
    if ((ownedRoomData.length > 0 || sharedRoomData.length > 0) && (ownedRoomsEntityArrangement.length > 0 || otherRoomsEntityArrangement.length > 0)) {
      changeRoomTab(roomTab, 'trigger');
    }
  }, [ownedRoomsEntityArrangement, ownedRoomsFolderArrangement, otherRoomsFolderArrangement, otherRoomsEntityArrangement, ownedRoomData, sharedRoomData, roomTab])

  useEffect(() => {
    setDefaultIdsForSharing();
  },[ownedOriginalData, sharedOriginalData, roomTab]);

  const setDefaultIdsForSharing = () => {
    let data = roomTab == "owned" ? ownedOriginalData : sharedOriginalData;
    if (selectable === false) {
      let ids = data.map((ele) => {
        return parseInt(ele.room_id);
      });
      if(ids.length != 0){
        setDefaultRoomIds(ids);
      }
    }
  }


  useEffect(()=>{
    // set to initial product data that is being returned
    if ((ownedOriginalData.length > 0 || sharedOriginalData.length > 0)) {
      let owned_spaces = ownedOriginalData;
      if (owned_spaces.length > 0 && SEQUENCE_ID_OWNED == -1 && !checkIfItemsHaveReorderedOnce.includes('owned_space') && !ownedRoomLoader) {
        createFirstArrangementOrder(owned_spaces, "owned");
      }

      let other_spaces = sharedOriginalData;
      if (other_spaces.length > 0 && SEQUENCE_ID_OTHER == -1 && !checkIfItemsHaveReorderedOnce.includes('other_space') && !sharedRoomLoader) {
        createFirstArrangementOrder(other_spaces, "shared");
      }
    }
  }, [ownedOriginalData, sharedOriginalData, sharedRoomLoader, ownedRoomLoader])

  const getSpacesLength = (type, tab_options = 'not_all') => {
    if (type == "customer_owned") {
      return mspCustomerOwnedOrgRooms.length;
    } else if (type == "owned") {
        return ownedOriginalData.length;
    } 
    else if (type == "shared") {
      let rooms_temp = sharedOriginalData
      if (sharedTabType == "all" || tab_options == 'all') {
        return rooms_temp.length;
      } else if (sharedTabType == "shared") {
        const rooms = rooms_temp.filter(
          (room) => room.shared_room_type == "shared_by_customer"
        );
        return rooms.length;
      } else {
        const rooms = rooms_temp.filter(
          (room) => room.shared_room_type == "added_from_store"
        );
        return rooms.length;
      }
    } else {
      if (mspCustomerSharedTabType == "all" || tab_options == 'all') {
        return mspCustomerOtherOrgRooms.length;
      } else if (mspCustomerSharedTabType == "shared") {
        const rooms = mspCustomerOtherOrgRooms.filter((product) => (product.shared_room_type == 'shared_by_customer'))
        return rooms.length;
      } else {
        const rooms = mspCustomerOtherOrgRooms.filter((product) => (product.shared_room_type == 'added_from_store'))
        return rooms.length;
      }
    }
  };

  const switchView = () => {
    if (currentView === 'folder_view') {
      setCurrentView("flat_view");
    } else {
      setCurrentView("folder_view");
    }
  };

  const getSpaces = (type) => {
    if (type == "customer_owned") {
      if (currentView === 'folder_view') {
        return mspCustomerOwnedRooms;
      } else {
        return mspCustomerOwnedOrgRooms;
      }
    } else if (type == "owned") {
      return ownedRoomData;
    } else if (type == "shared") {
      if (sharedTabType == "all") {
        return sharedRoomData;
      } else if (sharedTabType == "shared") {
        const rooms = sharedOriginalData.filter(
          (room) => room.shared_room_type == "shared_by_customer"
        );
        return rooms;
      } else {
        const rooms = sharedOriginalData.filter(
          (room) => room.shared_room_type == "added_from_store"
        );
        return rooms;
      }
    } else {
      if (mspCustomerSharedTabType == "all") {
        if (currentView === 'folder_view') {
          return mspCustomerOtherRooms;
        } else {
          return mspCustomerOtherOrgRooms;
        }
      } else if (mspCustomerSharedTabType == "shared") {
        const rooms = mspCustomerOtherOrgRooms.filter((product) => (product.shared_room_type == 'shared_by_customer'))
        return rooms;
      } else {
        const rooms = mspCustomerOtherOrgRooms.filter((product) => (product.shared_room_type == 'added_from_store'))
        return rooms;
      }
    }
  };

  const getSpaceCount = (type) => {
    if (
      IS_MS_PROVIDER &&
      (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)
    ) {
      return ownedOriginalData.length + sharedOriginalData.length + mspCustomerOwnedOrgRooms.length + mspCustomerOtherOrgRooms.length;
    }

    return ownedOriginalData.length + sharedOriginalData.length;
  };

  const getTabTotalCount = () => {
    if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
      return getRoomTabCount('customer_shared') + getRoomTabCount('customer_owned')  + getRoomTabCount('owned')  + getRoomTabCount('shared'); 
    }
    return getRoomTabCount('owned')  + getRoomTabCount('shared'); 
  }

  const getFolderSpaceCount = () => {
    return folderSpaces.length;
  };

  const changeSearchValue = (value) => {
    setSearchValue(value);
    const url = new URL(window.location.href);
    if (value) {
      url.searchParams.set('search', encodeURIComponent(value));
    }
    else {
      url.searchParams.delete('search');
    }
    window.history.replaceState({}, "", url);
  };

  const checkIfParentRoomExistsInData = (rooms, parent_room_id) => {
    let parent_room_exists = false;
    rooms.map((room) => {
      if (parseInt(room.room_id) == parseInt(parent_room_id)) {
        parent_room_exists = true
      }
    })
    return parent_room_exists;
  }

  const getGroupedSpaceData = (spaces) => {
    let grouped_spaces = [];
    let folder_indexes = {}
    if (spaces && spaces.length > 0) {
      // this will return the data for all folders and their parent models
      // compile data for when no arrangement data exists
      let groups = spaces.reduce((groups, space, index) => {
        let parent_room_id = space.variant_of;
        if (!groups[parent_room_id] && checkIfParentRoomExistsInData(spaces, parent_room_id)) {
          groups[parent_room_id] = [];
          folder_indexes[parent_room_id] = spaces.length + 1
        }
        if (checkIfParentRoomExistsInData(spaces, parent_room_id)){
          groups[parent_room_id].push(space);
          if (index < folder_indexes[parent_room_id]){
            folder_indexes[parent_room_id] = index
          }
        }
        return groups;
      }, {});

      // To add it in the array format instead
      let groupArrays = Object.keys(groups).map((parent_room_id) => {
        return {
          parent_room_id,
          rooms: groups[parent_room_id],
          index: folder_indexes[parent_room_id]
        };
      });
      console.log('Grouped Space Array', groupArrays);
      setGroupedRooms(groupArrays);
      grouped_spaces = groupArrays;
    }
    return grouped_spaces;
  }

  const setSequenceIds = (sequence_ids = SEQUENCE_IDS) => {
    if (sequence_ids) {
      sequence_ids = JSON.parse(sequence_ids)
      sequence_ids.map((sequence_data) => {
        if (sequence_data['entity_type'] == 'owned_space') {
          SEQUENCE_ID_OWNED = sequence_data['sequence_id']
          setCompanyArrangement(sequence_data['saved_by_company']);
        } else if (sequence_data['entity_type'] == 'other_space') {
          SEQUENCE_ID_OTHER = sequence_data['sequence_id']
        }
      })
    }
  }

  const loadSequenceIDs = (res, trigger_api, reload_page = false) => {
    localStorage.setItem('sequence_ids', JSON.stringify(res.data));
    setSequenceIds(JSON.stringify(res.data));
    if (trigger_api) {
      triggerRoomFetchAPI();
    }
    if (reload_page) {
      window.location.reload();
    }
  }


  const getRoomPayload = (initial, type, sequence_id, current_username, current_company_id, shared_entities, is_customer_data = false) => {
    let payload = {
      "required_fields": ["room_id","room_name", "brand_id", "category", "dimensions", 
      "customer_username", "model_status","thumbnail" ,"last_modified_stamp", "variant_of",
      "is_store_item", "company_id", "style", "platform", "tags"],
    } 
    if (sequence_id != -1) {
      payload['required_fields'].push('folder_id', 'folder_name','parent_folder_id',
      'library_order', 'entity_order')
      payload["order_by"] = "library_order asc,entity_order asc"
    } else {
      payload["order_by"] = "last_modified_stamp desc"
    }
    let filter_string = "";
    let shared_username = "";
    let customer_username = "customer_username__exact='" + current_username + "'"
    let customer_username_notexact = "customer_username__notexact='" + current_username + "'"
    let company_id_notexact = ""

    let company_id = "";
    let model_status = "model_status__in=[5]"
    let is_hidden = "is_hidden__not=true"
    let shared_hidden = "shared_hidden__not=true"
    let sequence_id_value = "sequence_id__exact="+sequence_id
    if (type == 'shared') {
      payload['required_fields'].push('shared_username')
      payload['required_fields'].push('shared_room_type')
      shared_username = "shared_username__exact='" + current_username + "'"
      filter_string = "("+shared_username + "&&" + shared_hidden + ")"

      if (shared_entities != undefined && current_company_id && shared_entities.split("_").includes("room")) {
        company_id_notexact = "company_id__notexact=" + current_company_id + "||company_id__isnull=true"
        filter_string = filter_string + "&&(" + company_id_notexact + ")&&("+customer_username_notexact+")"
      } else {
        filter_string = filter_string + "&&("+customer_username_notexact+")"
      }

    } else if (shared_entities != undefined && current_company_id && shared_entities.split("_").includes("room")) {
      company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
      filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")"
    } else {
      if (IS_MS_PROVIDER && !is_customer_data) {
        company_id = "company_id__isnull=true"
        let requested_for = "requested_for__isnull=true"
        filter_string = "("+company_id+"&&"+customer_username+")&&(" + is_hidden + ")&&(" + requested_for + ")"
      } else {
        filter_string = "("+customer_username+ "&&" + is_hidden +")"
      }
    }
    filter_string = filter_string + "&&(" + model_status + ")"
    if (sequence_id != -1) {
      filter_string = filter_string + "&&(" + sequence_id_value + "||sequence_id__isnull=true)"
    }
      filter_string = filter_string + "&&(has_active_project__is=false)"

    payload['filter_string'] = filter_string
    if (initial) {
      payload['pagination_filters'] = {
        'limit': 100,
        'offset': 0
      }
    }
    return payload;
  }

  const getCustomerData = () => {
    getSequenceIds(MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true, false, false);
  }

  const getSequenceIds = (username, company_id, shared_entities, managed_customer, trigger_api, reload_page = false) => {
    let payload = {
      'username': username,
      'action': 'get_sequence_ids'
    };
    if (company_id) {
      payload['company_id'] = company_id;
      payload['shared_entities'] = shared_entities;
    }

    axios.post(ENVIRONMENT.ENTITY_ORGANIZER, payload).then(res => {
      if (res.data && res.data.length > 0) {
        if (managed_customer == true) {
          let sequences = (res.data);
          let customer_seq = {};
          sequences.map ((sequence_data) => {
            if (sequence_data['entity_type'] == 'owned_space') {
              customer_seq["owned"] = sequence_data['sequence_id']
              setCustomerCompanyArrangement(sequence_data['saved_by_company']);
            } else if (sequence_data['entity_type'] == 'other_space') {
              customer_seq["other"] = sequence_data['sequence_id']
            }
          });
          setCustomerSequences(customer_seq);
          if (reload_page) {
            window.location.reload();
          }
        } else {
          loadSequenceIDs(res, trigger_api, reload_page);
        }
      }
    })
  }

  useEffect (() => {
    if (Object.keys(customerSequences).length > 0) {
      getMSPCustomerData("owned");
      getMSPCustomerData("shared");
    }
  }, [customerSequences])

  const getMSPCustomerData = (type) => {
    let payload = getRoomPayload(false, type, type == "owned" ? customerSequences["owned"] : customerSequences["other"], MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true);
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
    .then(res => {
      if (res.data) {
        let rooms = Utilities.getRoomsDataWithKeywords(res.data);
        if (type == "owned") {
          if (rooms.length > 0 && customerSequences["owned"] == -1) {
            createFirstArrangementOrder(rooms, "customer_owned");
          }
          setMspCustomerOwnedRooms(rooms);
          setMspCustomerOwnedOrgRooms(rooms);
        } else {
          if (rooms.length > 0 && customerSequences["other"] == -1) {
            createFirstArrangementOrder(rooms, "customer_shared");
          }
          setMspCustomerOtherRooms(rooms);
          setMspCustomerOtherOrgRooms(rooms);
        }
      }
    });
  }

  const fetchRoomData = (type, sequence_id, initial = false) => {
    if (!initial) {
        setLoader(true);
      } else {
        if (type == 'shared') {
          setInitialLoadShared(true);
        } else {
          setInitialLoadOwned(true);
        }
      }
      let payload = getRoomPayload(initial, type, sequence_id, CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES, false);
      axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        if (res.data) {
            let rooms_data = Utilities.getRoomsDataWithKeywords(res.data) 
            if (type == 'shared') {
              setSharedOriginalData(rooms_data);
            } else {
              setOwnedOriginalData(rooms_data);
            }
    
            // setting loaders
            if (initial) {
              if (type == 'shared') {
                setInitialLoadShared(false);
              } else {
                setInitialLoadOwned(false);
              }
            } else {
              if (type == 'shared') {
                setSharedRoomLoader(false);
              } else {
                setOwnedRoomLoader(false);
              }
            }
            setLoader(false);
          }
      });
  }

  const triggerRoomFetchAPI = () => {
    fetchRoomData('owned', SEQUENCE_ID_OWNED, true);  
    fetchRoomData('shared', SEQUENCE_ID_OTHER, true);
    getUserProjects();
  }

  const getOwnedRoomCount = () => {
    const counts = localStorage.getItem("entity_counts");
    if (counts) {
        let entityCounts = JSON.parse(counts);
        let owned_product_count = entityCounts.find(entity => entity.type === "space").count
        setTempOwnedCount(owned_product_count);
    }
    else {
        let payload = {
            username: CUSTOMER_USERNAME,
            project_homepage: true
        };
        if (COMPANY_ID != undefined) {
            payload.company_id = COMPANY_ID;
        };
        
        if(IS_MS_PROVIDER){
            if(MANAGED_CUSTOMER_USERNAME){
                payload['username'] = MANAGED_CUSTOMER_USERNAME
            }
            if(MANAGED_COMPANY_ID){
                payload['company_id'] = MANAGED_COMPANY_ID
            }
        }

        axios.post(ENVIRONMENT.HOME_SCREEN_STATS, payload).then((res) => {
            let approved_project = {
                "type": "approved",
                "count": (res.data.library_data.find(ele => ele.type === "project"))?.count
            }
            res.data.library_data = res.data.library_data.filter(ele => ele.type !== "project")
            let active_project = {
                "type": "active",
                "count": (res.data.review_data.find(ele => ele.type === "project"))?.count
            }
            let requested_project = {
                "type": "requested",
                "count": (res.data.in_progress_data.find(ele => ele.type === "project"))?.count
            }
            //Setting Entity Counts in localStorage
            let entity_counts = _.cloneDeep(res.data.library_data);
            
            entity_counts.push(approved_project)
            entity_counts.push(active_project)
            entity_counts.push(requested_project)
            
            localStorage.setItem('entity_counts', JSON.stringify(entity_counts));
            setTempOwnedCount(entity_counts.find(entity => entity.type === "space").count);
        })
    }
  }
  useEffect(() => {
    getOwnedRoomCount();

    if (IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
        getCustomerData();
    }
    if (SEQUENCE_IDS) {
        setSequenceIds();
        triggerRoomFetchAPI();
    } else {
      getSequenceIds(CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES, false, true)
    }
  }, []);

  const scrollToItem = (type, id) => {
    let element_id = `${type}-${id}`;
    
    let element = document.getElementById(element_id)
    console.log('element id', element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
     
    }
  }

  useEffect(() => {
    if (!initialLoadOwned && ownedRoomLoader) {
      fetchRoomData('owned', SEQUENCE_ID_OWNED);
    }
  }, [initialLoadOwned]);

  useEffect(() => {
    if (!initialLoadShared && sharedRoomLoader) {
      fetchRoomData('shared', SEQUENCE_ID_OTHER);
    }
  }, [initialLoadShared]);

  const convertToLibraryFormat = (data) => {
    // data will be converted in this format: let's say 4 products (p) at root and 2 folders (f) at root
    // [p, p, p, f -> [p,p], p, f -> [p, p]]
    let root_items = [];
    let folder_info = [];
    let index = 0;
    if (data && data.length > 0) {
      let folder_indexes = new Map();
      data.map((room) => {
        // if item is in folder, then create an array at the folder index (which will be 
        // the same as folder ordering #)
        // push the item in that array
        // otherwise push the product normally
        if (room.folder_id) {
          // check if array index has been created for the particular folder id
          // if it's not, then initialize it and create entry for folder_data
          // otherwise push the product in the appropriate array index
          if (folder_indexes.get(room.folder_id) == undefined) {
            folder_indexes.set(room.folder_id, index);
            root_items[folder_indexes.get(room.folder_id)] = [];
            let folder_data = {
              'folder_id': room.folder_id,
              'folder_name': room.folder_name,
              'ordering_number': room.library_order,
              'parent_item': room
            }
            folder_info.push(folder_data);
            index++;
          }
          root_items[folder_indexes.get(room.folder_id)].push(room);
        } else {
          root_items.push(room);
          index++;
        }
      });
    }

    
    return {
      root_items, 
      folder_info
    };
  }

  useEffect(() => {
    let owned_rooms = ownedOriginalData;
    let formatted_data = convertToLibraryFormat(owned_rooms);
    setOwnedRoomData(formatted_data.root_items);
    setOwnedFolderData(formatted_data.folder_info);
    convertToArrangement(owned_rooms, formatted_data.folder_info, 'owned');

    let other_rooms = sharedOriginalData;
    formatted_data = convertToLibraryFormat(other_rooms);
    setSharedRoomData(formatted_data.root_items);
    setOtherFolderData(formatted_data.folder_info);
    convertToArrangement(other_rooms, formatted_data.folder_info, 'shared');
    
  }, [ownedOriginalData, sharedOriginalData]);

  useEffect(() => {
    let owned_rooms = mspCustomerOwnedOrgRooms;
    let formatted_data = convertToLibraryFormat(owned_rooms);
    setMspCustomerOwnedRooms(formatted_data.root_items);
    setMspCustomerOwnedFolderData(formatted_data.folder_info);
    convertToArrangement(owned_rooms, formatted_data.folder_info, 'customer_owned');

    let other_rooms = mspCustomerOtherOrgRooms;
    formatted_data = convertToLibraryFormat(other_rooms);
    setMspCustomerOtherRooms(formatted_data.root_items);
    setMspCustomerOtherFolderData(formatted_data.folder_info);
    convertToArrangement(other_rooms, formatted_data.folder_info, 'customer_shared');
    
  }, [mspCustomerOwnedOrgRooms, mspCustomerOtherOrgRooms]);

  // adjust library view (move to the room or folder that was last clicked by the user in the library)
  const adjustLibraryView = (data, tab_key) => {
    let room_id = new URLSearchParams(window.location.search).get("entity_id");
    let folder_id = new URLSearchParams(window.location.search).get("folder_id");
    let folder_room_id = new URLSearchParams(window.location.search).get("folder_entity_id");

    if (room_id && data.length > 0) {
      let room_data = data.filter(ele=> ele.room_id == room_id);
      if (room_data.length > 0) {
        let folder_id = null;
        // if room exists inside a folder, then open that folder and scroll to that room
        // in the folder
        if (room_data[0].folder_id) {
          folder_id = room_data[0].folder_id;
          let folder_items = [];
          if (tab_key == "owned") { folder_items = ownedOriginalData }
          else if (tab_key == "shared") { folder_items = sharedOriginalData }
          else if (tab_key == "customer_owned") { folder_items = mspCustomerOwnedOrgRooms }
          else { folder_items = mspCustomerOtherOrgRooms }
          folder_items = folder_items.filter(item => item.folder_id == folder_id);
          if (folder_items && folder_items.length > 0) {
            let parent_entity = folder_items[0].parent_item
            setSelectedParentRoom(parent_entity);
            if (parent_entity) {
              getFoldersListExceptCurrentFolder(folder_id);
            }
            setFolderState(true);
            enterFolderState(folder_items, false);
            setRoomTab(tab_key);
            setTabActiveKey(tab_key);
          }
        } 
        // otherwise move to that room
        else {
          setRoomTab(tab_key);
          setTabActiveKey(tab_key);
        }
        
        setCheckIfTabChanged(!checkIfTabChanged)
      }
    } 
    // if folder id is present, then just open that folder
    // if folder_room_id is present, then just scroll the library to that folder
    // without opening it - used when folder is closed or when new folder is created
    else if ((folder_id || folder_room_id) && data.length > 0) {
      let folder_items = [];
      if (tab_key == "owned") { folder_items = ownedOriginalData }
      else if (tab_key == "shared") { folder_items = sharedOriginalData }
      else if (tab_key == "customer_owned") { folder_items = mspCustomerOwnedOrgRooms }
      else { folder_items = mspCustomerOtherOrgRooms }
      if (folder_id) {
        folder_items = folder_items.filter(item => item.folder_id == folder_id);
      } else if (folder_room_id) {
        folder_items = folder_items.filter(item => item.id == folder_room_id);
      }
      if (folder_items && folder_items.length > 0) {
        setRoomTab(tab_key);
        setTabActiveKey(tab_key);
        setCheckIfTabChanged(!checkIfTabChanged)
      }
    }
  }


  useEffect(() => {
    // if tab is changed then check if these are present in URL params
    // if yes, then scroll to item or folder
    let room_id = new URLSearchParams(window.location.search).get("entity_id");
    let folder_id = new URLSearchParams(window.location.search).get("folder_id");

    if (room_id) {
      if (!lastFolderAccessed) {
        scrollToItem('element', room_id);
      } else {
            setTimeout(() => {scrollToItem('folder', lastFolderAccessed);
            setLastFolderAccessed(null);
        }, 1000)
      }
    } else if (lastFolderAccessed || folder_id) {
      let new_folder_id = lastFolderAccessed ? lastFolderAccessed : folder_id;
      setTimeout(() => {scrollToItem('folder', new_folder_id);
            setLastFolderAccessed(null);
        }, 1000)
    }
  }, [checkIfTabChanged])

  const convertToArrangement = (rooms, folder_info, type) => {
    let entity_arrangement = [];
    let arrangement_info = {};
    let folder_arrangement = [];
    let folder_arrangement_info = {};
    if (rooms && rooms.length > 0) {
      rooms.map((data) => {
        arrangement_info = {
          entity_id: data.room_id,
          ordering_number: parseInt(data.library_order),
          parent_folder_id: data.folder_id == "" ? "" : parseInt(data.folder_id),
          sequence_id: getTabSequenceID(type)
        }
        entity_arrangement.push(arrangement_info);
      });

      folder_info.map((data) => {
        folder_arrangement_info = {
          folder_id: data.folder_id,
          ordering_number: data.library_order == "" ? "" : parseInt(data.library_order),
          folder_name: data.folder_name,
          sequence_id: getTabSequenceID(type)
        }
        folder_arrangement.push(folder_arrangement_info);
      });
    }

    if (type == 'owned') {
      setOwnedRoomsEntityArrangement(entity_arrangement);
      setOwnedRoomsFolderArrangement(folder_arrangement);
    } else if (type == 'shared') {
      setOtherRoomsEntityArrangement(entity_arrangement);
      setOtherRoomsFolderArrangement(folder_arrangement);
    } else if (type == 'customer_owned') {
      setMspOwnedRoomsEntityArrangement(entity_arrangement);
      setMspOwnedRoomsFolderArrangement(folder_arrangement);
    } else {
      setMspOtherRoomsEntityArrangement(entity_arrangement);
      setMspOtherRoomsFolderArrangement(folder_arrangement);
    }
  }

  const getTabSequenceID = (tabType) => {
    let sequence_id = -1;
    if (tabType == "owned") {
      sequence_id = SEQUENCE_ID_OWNED;
    } else if (tabType == "shared") {
      sequence_id = SEQUENCE_ID_OTHER;
    } else if (tabType == "customer_owned") {
      sequence_id = customerSequences["owned"];
    } else {
      sequence_id = customerSequences["other"];
    }
    return sequence_id;
  };

  useEffect(() => {
    // if MS provider then select first tab by default
    if (IS_MS_PROVIDER) {
      setRoomTab("owned");
      setTabActiveKey("owned");
    }
    //if managed customer tab is selected
    console.log('selected',mspCustomerOwnedRooms)
    if (mspCustomerOwnedRooms && mspCustomerOwnedRooms.length > 0) {
      setRoomTab("customer_owned");
      setTabActiveKey("customer_owned");
      adjustLibraryView(mspCustomerOwnedRooms, "customer_owned");

    } else {
      //if any other tab is selected
      console.log(ownedRoomData, sharedRoomData)
      if((ownedRoomData && ownedRoomData.length > 0) || (sharedRoomData && sharedRoomData.length > 0))
      {
        if(getSpacesLength("owned") > 0){
            setRoomTab("owned");
            setTabActiveKey("owned");
          }
          else if(getSpacesLength("shared") > 0){
            setRoomTab("shared");
            setTabActiveKey("shared");
          }
          adjustLibraryView(ownedOriginalData, "owned");
          adjustLibraryView(sharedOriginalData, "shared");
      }else {
        setRoomTab("owned");
        setTabActiveKey("owned");
      }
    }
  }, [ownedRoomData, sharedRoomData, mspCustomerOwnedRooms])


  const getSelectedRoomIds = () => {
    let room_ids = []
    
    if (selectable === false || selectedRoomIds.length === 0) {
      room_ids = defaultRoomIds;
    }
    else {
      let selected_room_ids = selectedRoomIds;
      room_ids = selectedRoomIds;
      if (room_ids.length === 0) {
        room_ids = defaultRoomIds;
      } else {
        //if the user has not opened a stack and the shared entity is a folder stack then share all its items inside the folder too
        if (!folderState) {
          groupedRooms.map((room) => {
            if (selected_room_ids.includes(room.variant_of)) {
              room.rooms.map((element) => {
                if (!selected_room_ids.includes(room.room_id)) {
                  selected_room_ids.push(element.room_id);
                }
              })
            }
          });
        }
        room_ids = selected_room_ids;
      }
    }

    return room_ids
  }

  const onAddCollectionClick = (id) => {
    setSelectedRoomIdForCollection(id);
    setCollectionModalVisible(true);
  }

  const sellOnStore = (data) => {
    setShowRoomDetails(data);
    setSellVisible(true);
    let room_id = data.room_id;
    axios.post(ENVIRONMENT.FETCH_ROOM, { room_id })
      .then(res => {
        console.log(res.data);
        setShowRoomDetails(res.data);
      });
  }

  const cancelSellModal = () => {
    setSellVisible(false);
  }

  const onChangePrice = (e) => {
    setPrice(e.target.value);
  }
  const removeItemFromStore = (room_data) => {
    setRemoveFromStore(true);
    setShowRoomDetails(room_data);
    let room_id = room_data.room_id;
    axios.post(ENVIRONMENT.FETCH_ROOM, { room_id })
      .then(res => {
        console.log(res.data);
        setShowRoomDetails(res.data);
      });
  }

  const setStoreRequests = () => {
    let payload = {
      room_id: showRoomDetails.room_id,
    }
    if (showRoomDetails.is_store_item == -2) {
      payload.is_store_item = -2;
    } else if (showRoomDetails.is_store_item == 1) {
      payload.is_store_item = 0;
    } else {
      payload.is_store_item = -2;
    }

    let price_payload = {
      entity_id: showRoomDetails.room_id,
      customer_username: CUSTOMER_USERNAME,
      entity_type: 'room',
      proposed_price: price,
    }
    setRemoveLoader(true);
    setSellLoader(true);
    axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
      .then(res => {
        if (showRoomDetails.is_store_item == -2) {
          price_payload.action = 'update';
        } else if (showRoomDetails.is_store_item == 1) {
          price_payload.action = 'remove';
        } else {
          price_payload.action = 'insert';
        }

        updateStore(price_payload);
      })
      .catch((error) => {
        setRemoveLoader(false);
        console.log(error);
        message.error('Error in removing from store.');
      });

  }

  const updateStore = (price_payload) => {
    axios.post(ENVIRONMENT.SET_STORE_PRICE, price_payload)
      .then((response) => {
        setSellLoader(false);
        if (showRoomDetails.is_store_item == -2) {
          message.info('Price updated successfully!');
        } else if (showRoomDetails.is_store_item == 1) {
          message.info('Item removed from store successfully!');
        } else {
          message.info('Price submitted successfully!');
        }
        window.location.href = '/rooms';

      })
      .catch((error) => {
        setSellLoader(false);
        console.log(error);
        message.error('Error.');

      });
  }

  const enterFolderState = (element, removeSearchValue=true) => {
    setFolderState(true);
    let folder_items = element
    console.log('items', folder_items)
    if (folder_items.length > 0) {
      setSelectedParentRoom(folder_items[0]);
      if (folder_items[0]) {
        let currentFolderId = getFolderId(folder_items[0]);
        setFolderID(currentFolderId);
        setSelectedFolderParentId(folder_items[0].room_id);
        getFoldersListExceptCurrentFolder(currentFolderId);
      }
      let folder_rooms = _.cloneDeep(folder_items);// deep copy
      setFolderSpaces(folder_rooms);
      if (removeSearchValue) {
        removeSearchParams();
        setSearchValue('');
      }
    }
  }

  const moveOutOfReorderState = () => {
    if (reorderItems) {
      removeSearchParams();
      setSearchValue('');
    }
    setShowBottomNavBar(false);
    setReorderItems(false);
    setSelectable(false);
    setSelectedFolderRooms([]);
    setSelectedRoomIds([]);
    changeRoomTab(roomTab, 'trigger');
    setOrderUpdated(false);
  }

  const onChangeSharedTabType = (e) => {
    setSharedTabType(e.target.value);
    setTabChangeThroughClick(true);
  }

  const onMSPChangeSharedTabType = (e) => {
    setMspCustomerSharedTabType(e.target.value);
    setTabChangeThroughClick(true);
  }

  const leaveFolderState = () => {
    setLastFolderAccessed(folderID)
    setFolderID(null);
    setFolderState(false);
    setFolderID(null);
    setFolderSpaces([]);
    setSearchValue('');
    removeSearchParams();
    setSaveOrderedItems(false);
    changeRoomTab(roomTab, 'trigger');
    setCheckIfTabChanged(!checkIfTabChanged);
    resetFilters();
  }

  useEffect(() => {
    if (saveOrderedItems) {
      reorderArrangementData();
    }
  }, [saveOrderedItems])

  const reorderArrangementData = (entitiesArrangement,folderArrangement) => {
    console.log(entitiesArrangement, folderArrangement)
    if (saveOrderedItems && entitiesArrangement && folderArrangement) {
      setSaveOrderedItems(false);
      let payload = {};
      let company_arrangement = false;
      if (roomTab == "owned") {
        company_arrangement = companyArrangement
      } else if (roomTab == "customer_owned") {
        company_arrangement = customerCompanyArrangement
      }
      if (
        (reorderByCompany == company_arrangement && roomTab == "owned") ||
        (mspCustomerReorderByCompany == company_arrangement && roomTab == "customer_owned") ||
        (roomTab == "shared") ||
        (roomTab == "customer_shared")
        ) {
        payload = {
          action: "reorder",
          entity_arrangement: entitiesArrangement,
          folder_arrangement: folderArrangement,
          sequence_id: getTabSequenceID(roomTab)
        };
        console.log("Re Order Payload ", roomTab, payload);
      } else {
        let entity_arrangement = []
        let folder_arrangement = [];
        if (folderState) {
          let arrangement_values = getAllArrangementInfo(entitiesArrangement);
          console.log(arrangement_values)
          entity_arrangement = arrangement_values['new_entity_arrangement'];
          folder_arrangement = arrangement_values['new_folder_arrangement'];
        } else {
          entity_arrangement = entitiesArrangement;
          folder_arrangement = folderArrangement;
        }

        let folder_arr = [];
        let folder_dict = {};
        folder_arrangement.map((folder) => {
          let folder_entities = [];
          folder_dict = {
            ordering_number: folder["ordering_number"],
            folder_name: folder["folder_name"],
          };
          let entities = []
          entity_arrangement.map((entity) => {
            if (entity["parent_folder_id"] == folder["folder_id"]) {
              entities.push(entity);
            }
          });

          if (entities.length > 0) {
            entities = entities.sort((a, b) => (a.ordering_number - b.ordering_number))
            entities.map((entity) => {
              folder_entities.push(entity["entity_id"]);
            })
          }

          folder_dict["folder_entities"] = folder_entities;
          folder_arr.push(folder_dict);
        });
        let new_entity_arr = [];
        let entity_dict = {};
        entity_arrangement.map((entity) => {
          entity_dict = entity;
          entity_dict["parent_folder_id"] = "";
          new_entity_arr.push(entity_dict);
        });
        payload = {
          action: "reorder_first_time",
          entity_type: getEntityType(roomTab),
          username: getCurrentUserUsername(roomTab),
          entity_arrangement: new_entity_arr,
          folder_arrangement: folder_arr,
          company_id: getCurrentUserCompany(roomTab),
          old_sequence_id: getTabSequenceID(roomTab),
          save_by_company: getReorderByCompany(roomTab),
        };
      }
      console.log('Payload',payload)
      organizeData(payload, getEntityType(roomTab));
    }
  }

  const getEntityType = (tabType) => {
    if (tabType == "owned" || tabType == "customer_owned") {
      return "owned_space";
    } else {
      return "other_space";
    }
  };

  const getCurrentUserUsername = (tabType) => {
    if (tabType == "owned" || tabType == "shared") {
      return CUSTOMER_USERNAME;
    } else {
      return MANAGED_CUSTOMER_USERNAME;
    }
  };

  const getCurrentUserCompany = (tabType) => {
    if (tabType == "owned" || tabType == "shared") {
      return COMPANY_ID;
    } else {
      return MANAGED_COMPANY_ID;
    } 
  };

  const getReorderByCompany = (tabType) => {
    if (tabType == "owned") {
      return reorderByCompany;
    } else if (tabType == "customer_owned") {
      return mspCustomerReorderByCompany;
    } else {
      return false;
    }
  };

  const getAllArrangementInfo = (entity_arr) => {
    let new_entity_arrangement = [];
    let new_folder_arrangement = [];
    let arrangement_info = {};
    let folder_arrangement_info = {};
    let items = getSpaces(roomTab);
    items.map((item, index) => {
      if (Array.isArray(item)) {
        folder_arrangement_info = {
          folder_id: parseInt(item[0].folder_id),
          ordering_number: index,
          folder_name: item[0].folder_name,
          sequence_id: getTabSequenceID(roomTab)
        }
        new_folder_arrangement.push(folder_arrangement_info);
        
        item.map((folder_item, folder_index) => {
          let folder_data = []
          let folder_arr_info = entity_arr.filter((arrangement) => parseInt(arrangement.entity_id) == folder_item.room_id);
          if (folder_arr_info.length > 0) {
            folder_data = folder_arr_info[0];
            arrangement_info = {
              entity_id: parseInt(folder_data.entity_id),
              ordering_number: folder_data.ordering_number,
              parent_folder_id: parseInt(folder_item.folder_id),
              sequence_id: getTabSequenceID(roomTab)
            }
          } else {
            folder_data = item;
            arrangement_info = {
              entity_id: parseInt(folder_item.room_id),
              ordering_number: folder_index,
              parent_folder_id: parseInt(folder_item.folder_id),
              sequence_id: getTabSequenceID(roomTab)
            }
          }
          new_entity_arrangement.push(arrangement_info);
        })
      } else {
        arrangement_info = {
          entity_id: parseInt(item.room_id),
          ordering_number: index,
          parent_folder_id: item.folder_id,
          sequence_id: getTabSequenceID(roomTab)
        }
        new_entity_arrangement.push(arrangement_info);
      }
    })
    console.log(new_entity_arrangement, new_folder_arrangement)
    return {
      new_entity_arrangement, new_folder_arrangement
    }
  }

  const changeRoomTab = (key, event_type) => {
    // key is the tab key, and event type tells us
    // whether tab change was triggered by click or through
    // any other action
    setRoomTab(key);
    setTabActiveKey(key);
    setCurrentTabArrangements(key);
    setSelectedFolderRooms([]);
    setSelectedRoomIds([]);
    if (event_type == 'click') {
      setTabChangeThroughClick(true);
      let url = new URL(window.location);
      url.searchParams.delete("folder_id");
      url.searchParams.delete("library_folder_id");
      url.searchParams.delete("entity_id");
    }
  }

  const setCurrentTabArrangements = (key) => {
    if (key == "owned") {
      setFolderArrangement(ownedRoomsFolderArrangement);
      setEntityArrangement(ownedRoomsEntityArrangement);
    } else if (key == "shared") {
        setFolderArrangement(otherRoomsFolderArrangement);
        setEntityArrangement(otherRoomsEntityArrangement);
    } else if (key == "customer_owned") {
      setFolderArrangement(mspOwnedRoomsFolderArrangement);
      setEntityArrangement(mspOwnedRoomsEntityArrangement);
    } else {
      setFolderArrangement(mspOtherRoomsFolderArrangement);
      setEntityArrangement(mspOtherRoomsEntityArrangement);
    }
  }

  const enterReorderingState = () => {
    setReorderItems(true);
    setCurrentView("folder_view");
    setShowBottomNavBar(true);
    setSelectedRoomIds([]);
    setSaveOrderedItems(false);
    setSharedTabType('all');
    setMspCustomerSharedTabType('all');
    setSearchValue('');
    removeSearchParams();
    setCurrentTabArrangements(roomTab);
  }

  const onClickShareIcon = () => {
    setSelectable(true);
    setSelectedRoomIds([]);
    setTriggerUpdate(!triggerUpdate);
    setReorderItems(false);
    setShowBottomNavBar(true);
    forceUpdate();
  };

  const createNewFolder = () => {
    setNewFolder(true);
  };

  const checkIfFolderNameAlreadyExists = (folder_name) => {
    let folder_info = getFoldersData(roomTab);
    const folder_exists = folder_info.filter(info => info.folder_name == folder_name);
    if(folder_exists.length > 0 ) {
        return true;
      }
      return false;
  }

  const getFoldersData = (tab_key) => {
    if (tab_key == "owned") {
      return ownedFolderData;
    } else if (tab_key == "shared"){
      return otherFolderData;
    } else if (tab_key == "customer_owned") {
      return mspCustomerOwnedFolderData;
    } else { 
      return mspCustomerOtherFolderData;
    }
  }

  const createNewFolderConfirm = () => {

    if (newFolderName) {
    const folderExists = checkIfFolderNameAlreadyExists(newFolderName);
    if(folderExists){
        setNewFolder(true);
        setErrorMessage("Folder already exists no duplicate folders are allowed.")
    } else {
        setErrorMessage("");
        let folder_entities = selectedFolderRooms.map((folder_entity) => {
            return parseInt(folder_entity)
          })
        const newFolder = {
            folder_name: newFolderName,
            folder_entities: folder_entities
        }

        let payload = {
            action: "create_new_folder",
            folder_arrangement: newFolder,
            sequence_id: getTabSequenceID(roomTab)
        };

        console.log("New folder payload: ", payload);
        let url = new URL(window.location);
        url.searchParams.delete("folder_id");
        url.searchParams.delete("library_folder_id");
        url.searchParams.set("entity_id", selectedFolderRooms[0]);
        window.history.replaceState({}, "", url);
        setSelectedFolderRooms([])
        setReorderItems(false)
        setShowBottomNavBar(false)
        setNewFolderName(null)
        organizeData(payload, getEntityType(roomTab))

      }
    }
    else {
      setErrorMessage('Please enter a folder name.')
    }

  };

  const getFoldersListExceptCurrentFolder = (id) => {
    let folder_info = roomTab == "owned" ? ownedFolderData : otherFolderData;
    const folder_list = folder_info.filter(info => info.folder_id != id);
    setFoldersListExceptCurrentFolder(folder_list);
  };

  const moveToFolderOnClick = () => {
    setExistingFolder(true);
    if (selectedParentRoom) {
      let currentFolderId = getFolderId(selectedParentRoom);
      getFoldersListExceptCurrentFolder(currentFolderId);
    }
    let folder_info = getFoldersData(roomTab);
    setFolderInfo(folder_info);
  };

  const moveToFolderConfirm = () => {
    if (selectedFolder) {
        console.log("check", folderInfo)
        console.log(selectedFolder)
      const folderId = folderInfo.filter(folderInfo => folderInfo.folder_id == selectedFolder)
      Utilities.updateFolderIDInUrl(selectedFolder);
      let folder_entities = selectedFolderRooms.map((folder_entity) =>{
        return parseInt(folder_entity)
      })
      let payload = {
        action: 'move_to_folder',
        sequence_id : getTabSequenceID(roomTab),
        folder_id: folderId[0].folder_id,
        moved_entities: folder_entities,
      }
      console.log(payload, "move_to_folder")
      organizeData(payload, getEntityType(roomTab));
      setSelectedFolderRooms([]);
      setShowBottomNavBar(false);
      setReorderItems(false)
      setSelectedFolder(null)
    } else {
      alert("Please select a folder first")
    }
  };

  const organizeData = (payload, entity_type = 'owned_space', reload = true) => {
    console.log('Arrangement Payload', payload)
    setActionLoader(true);
    axios.post(ENVIRONMENT.ENTITY_ORGANIZER, payload)
    .then(res => {
      console.log(res.data);
      console.log(
        "Arrangements Received: ",
        res.data.entity_arrangement ,
        res.data.folder_arrangement
      );
      if (payload.action == 'reorder_first_time') {
        if (roomTab == "owned" || roomTab == "shared") {
          getSequenceIds(CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES, false, false, reload);
        } else {
          getSequenceIds(MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true, false, reload);
        }
      } else {
        if (folderID) {
            window.location.reload();
        } else {
          window.location.reload();
        }
      }
      setExistingFolder(false);
      setViewUnGroupFolderModal(false);
      setViewRenameFolderModal(false);
      setNewFolder(false);
      setActionLoader(false)
      
    }).catch(err => {
      console.log(err);
    });
  }

  const renameFolderOnclick = (element) => {
    let folder_item = element[0];
    Utilities.updateFolderIDInUrl(folder_item.folder_id);
    setSelectedFolderParentId(folder_item.room_id);
    setSelectedParentRoom(folder_item)
    const folderName = getFolderName(folder_item);
    setFolderNewName(folderName);
    setViewRenameFolderModal(true);
  };

  const getFolderName = (element) => {
    if (element.folder_id != "") {
        return element.folder_name;
      }
      return '';
  };

  const renameFolderConfirm = () => {
    if (folderNewName) {
        const folderExists = checkIfFolderNameAlreadyExists(folderNewName);
        if(folderExists){
            setViewRenameFolderModal(true);
            setRenameError("Folder already exists please enter a unique name.");
        } else {
            setRenameError("");
            const selectedFolderId = getFolderId(selectedParentRoom)
            let payload= {
                action: 'rename_folder',
                folder_id: parseInt(selectedFolderId),
                folder_name: folderNewName,
                sequence_id: getTabSequenceID(roomTab)
              }

            organizeData(payload, getEntityType(roomTab));
            setSelectedFolderParentId(null);
            setSelectedParentRoom(null);
            }
    } else {
        setRenameError('Please enter a folder name.')
    }
  }

  const unGroupFolderItemsOnclick = (element) => {
    let folder_item = element[0];
    Utilities.updateEntityInUrl(folder_item.room_id);
    setSelectedParentRoom(folder_item);
    setSelectedFolderParentId(folder_item.room_id);
    setViewUnGroupFolderModal(true);
  }

  const unGroupFolderItemsConfirm = () => {
    const selectedFolderId = getFolderId(selectedParentRoom);

    let payload = {
      'action': 'delete_folder',
      sequence_id: getTabSequenceID(roomTab),
      deleted_folder_id: parseInt(selectedFolderId),
    }
    if (roomTab == "owned") {
      if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined) {
        payload.company_id = COMPANY_ID;
      }
    } else if (roomTab == "customer_owned") {
      if (MANAGED_SHARED_ENTITES != undefined && MANAGED_COMPANY_ID != undefined) {
        payload.company_id = MANAGED_COMPANY_ID;
      }
    }
    organizeData(payload, getEntityType(roomTab));
  }

  const saveArrangements = () => {
    setSaveOrderedItems(true);
    setShowBottomNavBar(false);
    setReorderItems(false);
    setSelectable(false);
    setReorderByModal(false);
    setSelectedFolderRooms([]);
    setSelectedRoomIds([]);
  }

  const onChangeFolderName = (value) => {
    setNewFolderName(value);
  }

  const onChangeNewFolderName = (value) => {
    setFolderNewName(value);
  }

  const closeReorderByModal = () => {
    setReorderByModal(false);
  }

  //get folder id of parent id
  const getFolderId = (element) => {
    if (element.folder_id != "") {
        return element.folder_id;
      }
      return -1;
  }

  const setSpaceCategoriesData = () => {
    let payload = {
      'request_type': 'room_model'
    }
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
    .then(res => {
      let categories = []
      if (res.data) {
        res.data.map((request) => {
          if (!categories.includes(request.category)) {
            categories.push(request.category);
          }
        })
        categories = categories.filter(item => item !== '')
        setSpaceCategories(categories);
      }
    })
  }

  useEffect(() => {
    setSpaceCategoriesData();
    getCompanyUsersEmails();
  }, []);

  useEffect(() => {
    //display tags when this is clicked
    if (spaceCategoryValue.length > 0) {
      setFiltersApplied(true);
      updateFiltersFlag();
      forceUpdate();
    } else {
      setFiltersApplied(false);
      updateFiltersFlag();
    }
  },[spaceCategoryValue])

  const getCompanyUsersEmails = () => {
    let emails = []
    if(MANAGED_CUSTOMER_USERNAME){
      emails.push({
          'email': MANAGED_CUSTOMER_EMAIL,
          'username': MANAGED_CUSTOMER_USERNAME
      })
      setCompanyUsers(emails)
    }
    if(COMPANY_ID){
        let payload = {
            "required_fields": ["email", "username"],
            "filter_string": `(company_id__exact=${COMPANY_ID})`,
            "order_by": "email asc"
        }
        axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
        .then(res => {
            if(res && res.data){
                console.log('Company User Emails', res.data);
                emails = res.data.filter(item => item.username != CUSTOMER_USERNAME)
                setCompanyUsers(emails)
              }
        })
    }
  }

  const openFilters = () => {
    setFiltersDrawer(true);
  };

  const closeFiltersDrawer = () => {
    setFiltersDrawer(false);
  }

  const changeSpaceCategory = (value) => {
    setSpaceCategoryValue(value);
  }

  const returnSearchValue = (entity) => {
    //return value according to the filters applied
      if (getSpaceSearchValue(entity)){
        return true;
      } else {
        return false;
      }
  }

  const getSpaceSearchValue = (entity) => {  
    let category = entity.category;
    let space_category = false;
    if ((spaceCategoryValue.length > 0 && spaceCategoryValue.includes(category)) || spaceCategoryValue.length == 0) {
      space_category = true;
    }
    if (space_category) {
      return true;
    }
  }

  const getFilteredTags =()=> {
    let filteredTags = <>
      {filtersApplied && !reorderItems && <Col span={24} style={{marginLeft: 10, marginTop: 20, textAlign: "left"}}>
        <h4 className='manrope f-14 black-55 italic'>Showing results for</h4>
        <div style={{marginBottom: 20}}>
          {spaceCategoryValue.length > 0 && spaceCategoryValue.map((category) => {
              return <Tag onClose={() => closeTags(category,'space_category')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
            }) 
          }
        </div>
      </Col>}
    </>

    return filteredTags;
  }

  const resetFilters = () => {
    spaceCategoryValue.length > 0 && spaceCategoryValue.map((category) => {
      closeTags(category,'space_category');
    });
  };

  const closeTags = (entity,type) => {
    //remove the tag when this is clicked from showing result section
    if (type == 'space_category') {
      let arr = spaceCategoryValue.filter(category => {return entity != category});
      setSpaceCategoryValue(arr);
    }
  }

  const ReorderYourModelsData = (props) => {
    let message = `Do you want to reorder ${props.roomTab == "owned" ? "your" : MANAGED_CUSTOMER_USERNAME}'s data by company or by user?`
    return (
    <div style={{ padding: "16px 16px 0 16px" }}>
      <h2 className="manrope f-18 black-00 w-600">{message}</h2>
      {!props.folderState && <p className="blue-note-bg">

          <span className="scene-note flag">
              Note: <span style={{ color: "#333333" }}>Items in {props.productTab == "owned" ? "'Your Spaces'" : `'${MANAGED_CUSTOMER_USERNAME} Spaces'`} tab will be reordered since that tab is selected.</span>
          </span>
      </p>}
      <Radio.Group defaultValue={props.reorderByCompany} onChange={props.changeOrderBySelection} className="modal-radio-text">
          <Radio className="manrope f-12 black-55" value={true} style={{ marginBottom: "8px" }}>By Company</Radio>
          <br />
          <Radio className="manrope f-12 black-55" value={false} style={{ marginBottom: "8px" }}>By User</Radio>
      </Radio.Group>
    </div>
    );
  }

  const ReorderOtherModelsData = (props) => {
    let tabName = ""
    if (props.roomTab == "owned") { tabName = "'Your Spaces'" }
    else if (props.roomTab == "shared") { tabName = "'Other Spaces'" }
    else if (props.roomTab == "customer_owned") { tabName =`'${MANAGED_CUSTOMER_USERNAME} Spaces'` }
    else { tabName =`'${MANAGED_CUSTOMER_USERNAME} Other Spaces'` }
    return (
      <div style={{ padding: "16px 16px 0 16px" }}>
          <h2 className="manrope f-18 black-00 w-600">
              Do you want to reorder your data?
          </h2>
          {!props.folderState && <p className="blue-note-bg">
              <span className="scene-note flag">
                  Note: <span style={{ color: "#333333" }}>Items in {tabName} tab will be reordered since that tab is selected.</span>
              </span>
          </p>}
      </div>)
  }

  const getRoomTabCount = (type) => {
    return tabCount[type] !== -1 ? tabCount[type] : getSpacesLength(type);
  };

  const updateFiltersFlag = () => {
    setFiltersAppliedFlag((prevFlag) => !prevFlag);
  };

  const checkFilteredResults = (rooms) => {
    let roomsData = [];
    if (searchValue != '' && !filtersApplied) {
      const allSpacesData = Utilities.getDataWithoutFolders(rooms);
      roomsData = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, allSpacesData);
    } else if (searchValue == '' && filtersApplied) {
      const allEntities = Utilities.getDataWithoutFolders(rooms);
      roomsData = allEntities.filter(returnSearchValue);
    } else if (searchValue != '' && filtersApplied) {
      const allEntities = Utilities.getDataWithoutFolders(rooms);
      let filter_data = allEntities.filter(returnSearchValue);
      roomsData = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, filter_data);
    }
    return roomsData;
  }

  const switchTabIfEmpty = () => {
    // if current selected tab is empty only then switch to other tab
    if (tabChangeThroughClick == false) {
      if (checkFilteredResults(getSpaces(roomTab))?.length == 0) {
        if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getSpaces("customer_owned"))?.length > 0) {
          setRoomTab("customer_owned");
        } else if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getSpaces("customer_shared"))?.length > 0) {
          setRoomTab('customer_shared');
        } else if (checkFilteredResults(getSpaces("owned"))?.length > 0) {
          setRoomTab('owned');
        } else if (checkFilteredResults(getSpaces("shared"))?.length > 0) {
          setRoomTab('shared');
        }
      }
    }
  }

  return (
    <div className="my-products">
      <CustomerMainLayout selected="5">
        <Row
          className="justify-space-between"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          {loader ? (
            ''
          ) : (
            <>
              <Col lg={10} xl={10}>
                <div
                  className="manrope f-24 w-700"
                  style={{
                    display: "flex",
                    alignItems: folderState ? "center" : "flex-end",
                  }}
                >
                  {folderState ? (
                    <img
                      src="/img/back_arrow.png"
                      style={{ marginRight: 20, cursor: "pointer" }}
                      onClick={leaveFolderState}
                    />
                  ) : (
                    ""
                  )}
                  {folderState ? (
                    <Tooltip
                      title={
                        <span className="manrope f-12 white">
                          {
                            selectedParentRoom ? ( getFolderId(selectedParentRoom.room_id) != -1 ? selectedParentRoom.folder_name : "" ) : ""
                          }
                        </span>
                      }
                    >
                      <span className="manrope f-24 w-700 clamp-text w-60">
                        {selectedParentRoom ? ( getFolderId(selectedParentRoom.room_id) != -1 ? selectedParentRoom.folder_name : "" ) : ""}
                      </span>
                    </Tooltip>
                  ) : (
                    "Space Library"
                  )}
                
                {!folderState && !sharedRoomLoader && !ownedRoomLoader  ? 
                <span style={{ marginLeft: 4, paddingBottom: 2 }} className="manrope f-20">({(searchValue != '' || filtersApplied)&&<span className="manrope grey-6b">{getTabTotalCount()} of&nbsp;</span>}{getSpaceCount()} spaces)</span>
                : folderState && folderSpaces && !loader ?  <span style={{marginLeft: 4}}  className="manrope f-20">({getFolderSpaceCount()} spaces)</span> : ''}
                </div>
              </Col>
              <Col lg={14} xl={14} className='justify-in-end'>
                {!reorderItems && (
                <div className='search-bar-width mr-8'>
                  <Form>
                    <Input.Group compact size="large" className="d-inline">
                      <Input
                        placeholder="Search All Spaces"
                        value={searchValue}
                        onChange={(e) => {
                          changeSearchValue(e.target.value);
                        }}
                        className="manrope f-14 mat-search-bar"
                      ></Input>
                    </Input.Group>
                  </Form>
                </div>
                )}

                {!reorderItems && IS_MS_PROVIDER && MANAGED_CUSTOMER_USERNAME !== null && !folderState ?
                  <div>
                      <Tooltip title={<span className="manrope f-12 white">{currentView == 'folder_view' ? 'Switch to All Spaces View' : 'Switch to Folder View'}</span>}>
                        <span className={['customer_owned', 'customer_shared'].includes(roomTab) ? "" : "not-allowed-setting"}>
                            <div className={['customer_owned', 'customer_shared'].includes(roomTab) ? "icon-button-container mr-8" : "pointer-none-setting icon-button-container mr-8"} onClick={switchView}>
                              {
                                currentView === 'flat_view' ? <FolderOpenOutlined/> : <AppstoreOutlined/>
                              }
                            </div>
                          </span>
                      </Tooltip>
                  </div> : ''}
                
                {!reorderItems && !sharedRoomLoader && !ownedRoomLoader ?
                <div>
                  <Tooltip title={<span className="manrope f-12 white">Apply Filters</span>}>
                    <div className="icon-button-container mr-8" onClick={openFilters}>
                      <FilterOutlined />
                    </div>
                  </Tooltip>
                </div>: ''}

                {!reorderItems && !sharedRoomLoader && !ownedRoomLoader &&
                  <div>
                    <Tooltip title={<span className="manrope f-12 white">Reorder Items</span>}>
                      <div className="icon-button-container mr-8" onClick={enterReorderingState}>
                        <ReorderIcon className='reorder-icon'/>
                      </div>
                    </Tooltip>
                  </div>
                }
                {!reorderItems && !sharedRoomLoader && !ownedRoomLoader && 
                  <div>
                    <Tooltip title={<span className="manrope f-12 white">Share Items</span>}>
                      <div className="icon-button-container mr-8" onClick={onClickShareIcon} >
                        <ShareAltOutlined />
                      </div>
                    </Tooltip>
                  </div>
                }
                {
                    !reorderItems && !sharedRoomLoader && !ownedRoomLoader &&
                    <div>
                        <Tooltip title={<span className="manrope f-12 white">Create New Space</span>}>
                            <Button className='new-product-btn red f-14' onClick={() => props.history.push('/add-new-room?origin=/rooms')}>
                                <PlusCircleFilled />New Space
                            </Button>
                        </Tooltip>
                    </div>
                }
              </Col>
            </>
          )}
        </Row>

        <div>
          <div className="text-center">
            {" "}
            {initialLoadOwned || initialLoadShared || filteredViewInitialLoader ? (
              <DottedLoader />
            ) : (
              <span>
                {reorderItems ? (
                  <Row
                    type="flex"
                    style={{
                      justifyContent: "flex-start",
                      height: "40px",
                      marginBottom: "20px",
                    }}
                  >
                    <p className="manrope f-20 ">Reorder Items</p>
                  </Row>
                ) : 
                <>
                {getFilteredTags()}
                </>}
                {folderState == false ? (
                  <Col span={24}>
                    <Tabs
                      onChange={(key) => changeRoomTab(key, 'click')}
                      className="product-tab library"
                      tabBarGutter={16}
                      size="large"
                      activeKey={tabActiveKey}
                    >
                      {IS_MS_PROVIDER &&
                        (MANAGED_CUSTOMER_USERNAME != null ||
                          MANAGED_COMPANY_ID != null) ? (
                            <>
                        <TabPane
                          forceRender={true}
                          tab={MANAGED_CUSTOMER_USERNAME + `'s Spaces (${getRoomTabCount("customer_owned")}${searchValue != '' || filtersApplied ? ` of ${getSpacesLength('customer_owned')} spaces` : ``})`}
                          key={"customer_owned"}
                          style={{ padding: 10 }}
                        >
                        <>
                            <RoomsList
                              tabChangeThroughClick={tabChangeThroughClick}
                              setTabChangeThroughClick={setTabChangeThroughClick}
                              checkFilteredResults={checkFilteredResults}
                              switchTabIfEmpty={switchTabIfEmpty}
                              SEQUENCE_ID_OTHER={customerSequences["owned"]}
                              SEQUENCE_ID_OWNED={customerSequences["other"]}
                              setOrderUpdated={setOrderUpdated}
                              removeItemFromStore={removeItemFromStore}
                              space_type={"managed_service"}
                              setCompanyID={setCompanyID}
                              folderState={folderState}
                              enterFolderState={enterFolderState}
                              groupedRooms={groupedRooms}
                              selectable={selectable}
                              selectedRoomIds={selectedRoomIds}
                              managed_customer={true}
                              setSelectedRoomIds={setSelectedRoomIds}
                              rooms={getSpaces("customer_owned")}
                              onAddCollectionClick={onAddCollectionClick}
                              sellOnStore={sellOnStore}
                              link={`/rooms/`}
                              searchValue={searchValue}
                              returnSearchValue={returnSearchValue}
                              filtersApplied = {filtersApplied}
                              setCurrentId={setCurrentId}
                              setCurrentCustomer={setCurrentCustomer}
                              setDeleteModal={setDeleteModal}
                              reorderItems={reorderItems}
                              selectedFolderRooms={selectedFolderRooms}
                              setSelectedFolderRooms={setSelectedFolderRooms}
                              saveOrderedItems={saveOrderedItems}
                              setSaveOrderedItems={setSaveOrderedItems}
                              roomTab={roomTab}
                              hashUpdated={hashUpdated}
                              entityArrangement={entityArrangement}
                              folderArrangement={folderArrangement}
                              setHashUpdated={setHashUpdated}
                              renameFolderOnclick={renameFolderOnclick}
                              unGroupFolderItemsOnclick={
                                unGroupFolderItemsOnclick
                              }
                              setNewLoader={setNewLoader}
                              setSortedItems={setSortedItems}
                              setOrderSaved={setOrderSaved}
                              reorderByCompany={mspCustomerReorderByCompany}
                              companyArrangement={customerCompanyArrangement}
                              organizeData={organizeData}
                              reorderArrangementData={reorderArrangementData}
                              filtersAppliedFlag={filtersAppliedFlag}
                              updateTabCount={updateTabCount}
                              tabKey={"customer_owned"}
                              tabActiveKey={tabActiveKey}
                              projects={projects}
                            />
                          </>
                        </TabPane>
                        <TabPane
                        forceRender={true}
                        tab={MANAGED_CUSTOMER_USERNAME + `'s Other Spaces (${getRoomTabCount("customer_shared")}${searchValue != '' || filtersApplied || mspCustomerSharedTabType != 'all' ? ` of ${getSpacesLength('customer_shared', 'all')} spaces` : ``})`}
                        key={"customer_shared"}
                        style={{ padding: "0px 10px 10px 10px" }}
                      >
                        {!reorderItems && <Row>
                          <div className="shared-models-tab manrope f-14 black-55">
                            <span className="shared-models-tab-span">
                              Show Items:
                            </span>
                            <Radio.Group
                              onChange={onMSPChangeSharedTabType}
                              value={mspCustomerSharedTabType}
                            >
                              <Radio value="all">All</Radio>
                              <Radio value="store">Added from Store</Radio>
                              <Radio value="shared">Shared</Radio>
                            </Radio.Group>
                          </div>
                        </Row>}
                          <RoomsList
                            tabChangeThroughClick={tabChangeThroughClick}
                            setTabChangeThroughClick={setTabChangeThroughClick}
                            checkFilteredResults={checkFilteredResults}
                            switchTabIfEmpty={switchTabIfEmpty}
                            SEQUENCE_ID_OTHER={customerSequences["owned"]}
                            SEQUENCE_ID_OWNED={customerSequences["other"]}
                            setOrderUpdated={setOrderUpdated}
                            removeItemFromStore={removeItemFromStore}
                            space_type={"shared_spaces"}
                            setCompanyID={setCompanyID}
                            folderState={folderState}
                            sharedRoomLoader={sharedRoomLoader}
                            enterFolderState={enterFolderState}
                            groupedRooms={groupedRooms}
                            selectable={selectable}
                            managed_customer={true}
                            selectedRoomIds={selectedRoomIds}
                            setSelectedRoomIds={setSelectedRoomIds}
                            rooms={getSpaces("customer_shared")}
                            onAddCollectionClick={onAddCollectionClick}
                            sellOnStore={sellOnStore}
                            link={`/rooms/`}
                            searchValue={searchValue}
                            returnSearchValue={returnSearchValue}
                            filtersApplied = {filtersApplied}
                            setCurrentId={setCurrentId}
                            setCurrentCustomer={setCurrentCustomer}
                            setDeleteModal={setDeleteModal}
                            reorderItems={reorderItems}
                            selectedFolderRooms={selectedFolderRooms}
                            setSelectedFolderRooms={setSelectedFolderRooms}
                            saveOrderedItems={saveOrderedItems}
                            setSaveOrderedItems={setSaveOrderedItems}
                            roomTab={roomTab}
                            hashUpdated={hashUpdated}
                            entityArrangement={entityArrangement}
                            folderArrangement={folderArrangement}
                            setHashUpdated={setHashUpdated}
                            renameFolderOnclick={renameFolderOnclick}
                            unGroupFolderItemsOnclick={
                              unGroupFolderItemsOnclick
                            }
                            setNewLoader={setNewLoader}
                            setSortedItems={setSortedItems}
                            setOrderSaved={setOrderSaved}
                            reorderByCompany={mspCustomerReorderByCompany}
                            companyArrangement={customerCompanyArrangement}
                            organizeData={organizeData}
                            reorderArrangementData={reorderArrangementData}
                            filtersAppliedFlag={filtersAppliedFlag}
                            updateTabCount={updateTabCount}
                            tabKey={"customer_shared"}
                            tabActiveKey={tabActiveKey}
                            projects={projects}
                          />
                          {sharedRoomLoader && searchValue == "" &&<div className="justify-in-center" style={{width: '100%'}}>
                                <p className="empty-section-text manrope f-16 black-55">
                                  Please wait while we load all your spaces.
                                  <LoadingOutlined style={{fontSize: 24, marginLeft: 14}}/>
                                </p>
                              </div>}
                          {mspCustomerOtherOrgRooms?.length == 0 ? (
                            <div className="no-models-div">
                              <Empty
                                description={"No Spaces To Show Yet"}
                                className="manrope f-14 no-models-span"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                      </TabPane>
                      </>
                      ) : (
                        ""
                      )}
                      <TabPane
                        forceRender={true}
                        tab={"Your Spaces " + (!ownedRoomLoader ? `(${getRoomTabCount("owned")}${searchValue != '' || filtersApplied ? ` of ${getSpacesLength('owned')} spaces` : ``})` : `(${tempOwnedCount})`)}
                        key={"owned"}
                        style={{ padding: "10px" }}
                      >
                        {getSpacesLength("owned") != 0 ? (
                          <>
                            <RoomsList
                              tabChangeThroughClick={tabChangeThroughClick}
                              setTabChangeThroughClick={setTabChangeThroughClick}
                              checkFilteredResults={checkFilteredResults}
                              switchTabIfEmpty={switchTabIfEmpty}
                              SEQUENCE_ID_OTHER={SEQUENCE_ID_OTHER}
                              SEQUENCE_ID_OWNED={SEQUENCE_ID_OWNED}
                              setOrderUpdated={setOrderUpdated}
                              removeItemFromStore={removeItemFromStore}
                              space_type={"your_spaces"}
                              setCompanyID={setCompanyID}
                              folderState={folderState}
                              ownedRoomLoader = {ownedRoomLoader}
                              enterFolderState={enterFolderState}
                              groupedRooms={groupedRooms}
                              selectable={selectable}
                              selectedRoomIds={selectedRoomIds}
                              setSelectedRoomIds={setSelectedRoomIds}
                              rooms={getSpaces("owned")}
                              managed_customer={false}
                              onAddCollectionClick={onAddCollectionClick}
                              link={`/rooms/`}
                              sellOnStore={sellOnStore}
                              searchValue={searchValue}
                              returnSearchValue={returnSearchValue}
                              filtersApplied = {filtersApplied}
                              setCurrentId={setCurrentId}
                              setCurrentCustomer={setCurrentCustomer}
                              setDeleteModal={setDeleteModal}
                              reorderItems={reorderItems}
                              selectedFolderRooms={selectedFolderRooms}
                              setSelectedFolderRooms={setSelectedFolderRooms}
                              saveOrderedItems={saveOrderedItems}
                              setSaveOrderedItems={setSaveOrderedItems}
                              roomTab={roomTab}
                              hashUpdated={hashUpdated}
                              entityArrangement={entityArrangement}
                              folderArrangement={folderArrangement}
                              setHashUpdated={setHashUpdated}
                              renameFolderOnclick={renameFolderOnclick}
                              unGroupFolderItemsOnclick={
                                unGroupFolderItemsOnclick
                              }
                              setNewLoader={setNewLoader}
                              setSortedItems={setSortedItems}
                              setOrderSaved={setOrderSaved}
                              reorderByCompany={reorderByCompany}
                              companyArrangement={companyArrangement}
                              organizeData={organizeData}
                              reorderArrangementData={reorderArrangementData}
                              filtersAppliedFlag={filtersAppliedFlag}
                              updateTabCount={updateTabCount}
                              tabKey={"owned"}
                              tabActiveKey={tabActiveKey}
                              projects={projects}
                            />
                            {ownedRoomLoader && searchValue == "" && <div className="justify-in-center" style={{width: '100%', position:'absolute'}}>
                                <p className="empty-section-text manrope f-16 black-55">
                                  Please wait while we load all your spaces.
                                  <LoadingOutlined style={{fontSize: 24, marginLeft: 14}}/>
                                </p>
                              </div>}
                          </>
                        ) : (
                          <div
                            className="empty-section"
                            style={{
                              marginTop: "10%",
                              position: "relative",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <img
                              id="empty-section-image"
                              style={{ width: "10%" }}
                              src="../../../../img/Spaces.svg"
                            />

                            <p className="empty-section-text manrope f-14 black-55">
                              Nothing to show
                            </p>
                            <p
                              style={{ marginTop: "-8px" }}
                              className="empty-section-text manrope f-12 grey-6b"
                            >
                              There are no spaces of your own. To get started
                              create a new space.
                            </p>
                            <Button
                              onClick={() =>
                                props.history.push("/add-new-room?origin=/rooms")
                              }
                              className="outline-red-btn square font-14 pd-10-18"
                            >
                              Create New <PlusOutlined />
                            </Button>
                          </div>
                        )}
                      </TabPane>
                      <TabPane
                        forceRender={true}
                        tab={"Other Spaces " + (!sharedRoomLoader ? `(${getRoomTabCount("shared")}${searchValue != '' || filtersApplied || sharedTabType != 'all' ? ` of ${getSpacesLength('shared', 'all')} spaces` : ``})` : '')}
                        key={"shared"}
                        style={{ padding: "0px 10px 10px 10px" }}
                      >
                        {!reorderItems && <Row>
                          <div className="shared-models-tab manrope f-14 black-55">
                            <span className="shared-models-tab-span">
                              Show Items:
                            </span>
                            <Radio.Group
                              onChange={onChangeSharedTabType}
                              value={sharedTabType}
                            >
                              <Radio value="all">All</Radio>
                              <Radio value="store">Added from Store</Radio>
                              <Radio value="shared">Shared</Radio>
                            </Radio.Group>
                          </div>
                        </Row>}
                          <RoomsList
                            tabChangeThroughClick={tabChangeThroughClick}
                            setTabChangeThroughClick={setTabChangeThroughClick}
                            checkFilteredResults={checkFilteredResults}
                            switchTabIfEmpty={switchTabIfEmpty}
                            SEQUENCE_ID_OTHER={SEQUENCE_ID_OTHER}
                            SEQUENCE_ID_OWNED={SEQUENCE_ID_OWNED}
                            setOrderUpdated={setOrderUpdated}
                            removeItemFromStore={removeItemFromStore}
                            space_type={"shared_spaces"}
                            setCompanyID={setCompanyID}
                            folderState={folderState}
                            sharedRoomLoader={sharedRoomLoader}
                            enterFolderState={enterFolderState}
                            groupedRooms={groupedRooms}
                            selectable={selectable}
                            managed_customer={false}
                            selectedRoomIds={selectedRoomIds}
                            setSelectedRoomIds={setSelectedRoomIds}
                            rooms={getSpaces("shared")}
                            onAddCollectionClick={onAddCollectionClick}
                            sellOnStore={sellOnStore}
                            link={`/rooms/`}
                            searchValue={searchValue}
                            returnSearchValue={returnSearchValue}
                            filtersApplied = {filtersApplied}
                            setCurrentId={setCurrentId}
                            setCurrentCustomer={setCurrentCustomer}
                            setDeleteModal={setDeleteModal}
                            reorderItems={reorderItems}
                            selectedFolderRooms={selectedFolderRooms}
                            setSelectedFolderRooms={setSelectedFolderRooms}
                            saveOrderedItems={saveOrderedItems}
                            setSaveOrderedItems={setSaveOrderedItems}
                            roomTab={roomTab}
                            hashUpdated={hashUpdated}
                            entityArrangement={entityArrangement}
                            folderArrangement={folderArrangement}
                            setHashUpdated={setHashUpdated}
                            renameFolderOnclick={renameFolderOnclick}
                            unGroupFolderItemsOnclick={
                              unGroupFolderItemsOnclick
                            }
                            setNewLoader={setNewLoader}
                            setSortedItems={setSortedItems}
                            setOrderSaved={setOrderSaved}
                            reorderByCompany={reorderByCompany}
                            companyArrangement={companyArrangement}
                            organizeData={organizeData}
                            reorderArrangementData={reorderArrangementData}
                            filtersAppliedFlag={filtersAppliedFlag}
                            updateTabCount={updateTabCount}
                            tabKey={"shared"}
                            tabActiveKey={tabActiveKey}
                            projects={projects}
                          />
                          {sharedRoomLoader && searchValue == "" && <div className="justify-in-center" style={{width: '100%'}}>
                                <p className="empty-section-text manrope f-16 black-55">
                                  Please wait while we load all your spaces.
                                  <LoadingOutlined style={{fontSize: 24, marginLeft: 14}}/>
                                </p>
                              </div>}
                          {sharedOriginalData?.length == 0 ? (
                            <div className="no-models-div">
                              <Empty
                                description={"No Spaces To Show Yet"}
                                className="manrope f-14 no-models-span"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                      </TabPane>
                    </Tabs>
                  </Col>
                ) : (
                  <Col span={24}>
                    <div className="product-tab library">
                      <Row
                        className="product-tab-content-scrollable"
                        type="flex"
                        style={{
                          paddingTop: "20px",
                          justifyContent: "start",
                        }}
                      >
                        <RoomsList
                          tabChangeThroughClick={tabChangeThroughClick}
                          setTabChangeThroughClick={setTabChangeThroughClick}
                          checkFilteredResults={checkFilteredResults}
                          switchTabIfEmpty={switchTabIfEmpty}
                          SEQUENCE_ID_OTHER={SEQUENCE_ID_OTHER}
                          SEQUENCE_ID_OWNED={SEQUENCE_ID_OWNED}
                          setOrderUpdated={setOrderUpdated}
                          removeItemFromStore={removeItemFromStore}
                          space_type={"folder"}
                          setCompanyID={setCompanyID}
                          folderState={folderState}
                          rooms={folderSpaces}
                          enterFolderState={enterFolderState}
                          groupedRooms={groupedRooms}
                          selectable={selectable}
                          selectedRoomIds={selectedRoomIds}
                          setSelectedRoomIds={setSelectedRoomIds}
                          managed_customer={false}
                          onAddCollectionClick={onAddCollectionClick}
                          sellOnStore={sellOnStore}
                          link={`/rooms/`}
                          searchValue={searchValue}
                          returnSearchValue={returnSearchValue}
                          filtersApplied = {filtersApplied}
                          setCurrentId={setCurrentId}
                          setCurrentCustomer={setCurrentCustomer}
                          setDeleteModal={setDeleteModal}
                          reorderItems={reorderItems}
                          selectedFolderRooms={selectedFolderRooms}
                          setSelectedFolderRooms={setSelectedFolderRooms}
                          saveOrderedItems={saveOrderedItems}
                          setSaveOrderedItems={setSaveOrderedItems}
                          roomTab={roomTab}
                          hashUpdated={hashUpdated}
                          entityArrangement={entityArrangement}
                          folderArrangement={folderArrangement}
                          setHashUpdated={setHashUpdated}
                          renameFolderOnclick={renameFolderOnclick}
                          unGroupFolderItemsOnclick={
                            unGroupFolderItemsOnclick
                          }
                          setNewLoader={setNewLoader}
                          setSortedItems={setSortedItems}
                          setOrderSaved={setOrderSaved}
                          reorderByCompany={reorderByCompany}
                          organizeData={organizeData}
                          companyArrangement={companyArrangement}
                          reorderArrangementData={reorderArrangementData}
                          filtersAppliedFlag={filtersAppliedFlag}
                          projects={projects}
                        />
                      </Row>
                    </div>
                  </Col>
                )}
                {showBottomNavBar && (
                  <div className="fixed-row-2">
                    <Row
                      style={{
                        marginBottom: "100px",
                        marginTop: 15,
                        width: "95%",
                        marginRight: "3%",
                      }}
                    >
                      <Col span={6} className="justify-in-start">
                        <Button
                          onClick={moveOutOfReorderState}
                          style={{ marginBottom: "10px" }}
                          className="modal-okay-gray square font-14"
                        >
                          Cancel
                        </Button>
                      </Col>
                      {reorderItems ? (
                        <Col span={18} className="justify-in-end">
                            {folderState ? '' :
                          <Button
                            disabled={
                              selectedFolderRooms.length === 0}
                            style={{ marginLeft: "5px", marginBottom: 20 }}
                            onClick={createNewFolder}
                            className="modal-okay-invert square-disabled font-14"
                          >
                            Create New Folder
                          </Button>}

                          <Button
                            disabled={
                              selectedFolderRooms.length === 0 || folderArrangement.length == 0
                              || folderState && foldersListExceptCurrentFolder.length == 0
                            }
                            style={{ marginLeft: "5px", marginBottom: 20 }}
                            onClick={moveToFolderOnClick}
                            className="modal-okay-invert square-disabled font-14"
                          >
                            Move To Folder
                          </Button>
                          {searchValue? "" : 
                          <Button
                            disabled={!orderUpdated}
                            style={{ marginLeft: "5px", marginBottom: 20 }}
                            onClick={() => setReorderByModal(true)}
                            className="modal-okay square font-14"
                          >
                            Reorder
                          </Button>}
                        </Col>
                      ) : (
                        <Col span={18} className="justify-in-end">
                          <Tooltip
                            title={
                              selectable && selectedRoomIds.length == 0 ? (
                                <span className="manrope f-12 white">
                                  Please select at least one space to enable
                                  this button.
                                </span>
                              ) : (
                                ""
                              )
                            }
                          >
                            <span>
                              <Button
                                style={{
                                  marginLeft: "5px",
                                  marginBottom: 20,
                                }}
                                onClick={() => setSharingModal(true)}
                                className="modal-okay-gray square font-14 "
                              >
                                Share{" "}
                                {selectable && selectedRoomIds.length !== 0
                                  ? `(${selectedRoomIds.length}) Space Models`
                                  : `Space Library`}
                              </Button>
                            </span>
                          </Tooltip>
                        </Col>
                      )}
                    </Row>
                  </div>
                )}
              </span>
            )}
          </div>
        </div>
        <AddCollection
          roomId={selectedRoomIdForCollection}
          visible={collectionModalVisible}
          addedToLibrary={true}
          setCollectionModalVisible={setCollectionModalVisible}
        ></AddCollection>
      </CustomerMainLayout>

      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        visible={viewUnGroupFolderModal}
        closable={false}
        footer={[
          <span className="justify-in-center">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay-gray square font-12"
              onClick={() => setViewUnGroupFolderModal(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={actionLoader}
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-12"
              onClick={() => unGroupFolderItemsConfirm()}
            >
              Confirm {actionLoader && <LoadingOutlined/>}
            </Button>
          </span>,
        ]}
      >
        <div
          style={{
            margin: "30px auto 0px auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="manrope f-16 black-00 w-600">
            Are you sure you want to delete this folder?
          </h2>
          <p className="manrope f-14 black-55">
            This will unstack all your folder items to the root folder
          </p>
        </div>
      </Modal>

      <Modal
        visible={viewRenameFolderModal}
        onCancel={() => setViewRenameFolderModal(false)}
        className="modal-share"
        footer={[
          <div className="justify-in-end">
            <Button
              className="modal-okay-gray square font-12"
              onClick={() => setViewRenameFolderModal(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={actionLoader}
              className="modal-okay square font-12"
              style={{ marginRight:8 }}
              onClick={renameFolderConfirm}
            >
              Confirm {actionLoader && <LoadingOutlined/>}
            </Button>
          </div>,
        ]}
      >
        <div style={{ padding: "16px 16px 0 16px" }}>
          <h2 className="manrope f-18 black-00 w-600">Rename Folder</h2>
          <p
            className="manrope f-14 grey-6b"
            style={{ marginBottom: 5, marginTop: 25 }}
          >
            Enter New Folder Name{" "}
          </p>
          <Input
            value={folderNewName}
            onChange={(e) => {
              onChangeNewFolderName(e.target.value);
            }}
            className="manrope f-12 grey-6b folder-name-input"
            type="text"
            placeholder="Type here"
          />
          {renameError?          
          <div className="manrope w-500 f-12" style={{ marginTop: 20 }}>
            <p style={{ color: "#E54547", marginBottom:0 }}>{renameError}</p>
          </div> :""}
        </div>
      </Modal>

      <Modal
        visible={newFolder}
        onCancel={() => setNewFolder(false)}
        className="modal-share"
        footer={[
          <div className="justify-in-end">
            <Button
              key="back"
              className="modal-okay-gray square font-12"
              onClick={() => setNewFolder(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={actionLoader}
              key="back"
              className="modal-okay square font-12"
              style={{ marginRight:8 }}
              onClick={createNewFolderConfirm}
            >
              Confirm {actionLoader && <LoadingOutlined/>}
            </Button>
          </div>,
        ]}
      >
        <div style={{ padding: "16px 16px 0px 16px" }}>
          <h2 className="manrope f-18 black-00 w-600">Create New Folder</h2>
          <p
            className="manrope f-14 grey-6b"
            style={{ marginBottom: 5, marginTop: 25 }}
          >
            Folder name{" "}
          </p>
          <Input
            onChange={(e) => {
              onChangeFolderName(e.target.value);
            }}
            className="manrope f-12 grey-6b folder-name-input"
            type="text"
            placeholder="Type here"
          />
          {errorMessage?          
          <div className="manrope w-500 f-12" style={{ marginTop: 20 }}>
            <p style={{ color: "#E54547", marginBottom:0 }}>{errorMessage}</p>
          </div> :""}
        </div>
      </Modal>

      <Modal
        visible={existingFolder}
        onCancel={() => setExistingFolder(false)}
        className="modal-share"
        footer={[
          <div className="justify-in-end">
            <Button
              className="modal-okay-gray square font-12"
              onClick={() => setExistingFolder(false)}
            >
              Cancel
            </Button>
            <Button
            disabled={actionLoader}
              className="modal-okay square font-12"
              style={{ marginRight:8 }}
              onClick={moveToFolderConfirm}
            >
              Confirm {actionLoader && <LoadingOutlined/>}
            </Button>
          </div>,
        ]}
      >
        <div style={{ padding: "16px 16px 0 16px" }}>
          <h2 className="manrope f-18 black-00 w-600">
            Move Space(s) To Folder
          </h2>
          <Form className="input-form-2" onSubmit={() => { }}>
            <Form.Item name="collection_id" colon={false}>
              <Select
                showSearch
                onChange={(value) => {
                  setSelectedFolder(value);
                }}
                placeholder="Select Folder"
              >
                {folderState ? 
                foldersListExceptCurrentFolder.map((folderInfo, index) => (
                  <Option key={index} value={folderInfo.folder_id}>
                    {folderInfo.folder_name}
                  </Option>
                )):folderArrangement.map((folderInfo, index) => (
                  <Option key={index} value={folderInfo.folder_id}>
                    {folderInfo.folder_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        onCancel={() => setRemoveFromStore(false)}
        visible={removeFromStore}
        closable={false}
        footer={[
          <span className="justify-in-center">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay-gray square font-12"
              onClick={() => setRemoveFromStore(false)}
            >
              Cancel
            </Button>
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-12"
              onClick={setStoreRequests}
            >
              {removeLoader ? (
                <span>
                  {" "}
                  Removing <LoadingOutlined spin />
                </span>
              ) : (
                <span> Okay </span>
              )}
            </Button>
          </span>,
        ]}
      >
        <div
          style={{
            margin: "30px auto 0px auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="manrope f-16 black-00 w-600">
            You're about to remove this space from store. Are you sure you
            want to remove?
          </h2>
        </div>
      </Modal>

      <AddItemToStore
        type={"customer"}
        entity_type={"room"}
        addToStore={setStoreRequests}
        visible={sellVisible}
        add_store_item_loader={sellLoader}
        onCancel={cancelSellModal}
        selected_item={showRoomDetails}
        onChangePrice={onChangePrice}
        price={price}
      />
      
      <BatchSharingModal
          entityType='room'
          modalVisible={sharingModal}
          setSharingModal={setSharingModal}
          getEntityIds={getSelectedRoomIds}
        />
      <SuccessModal
        visible={successModal}
        onCancel={() => setSuccessModal(false)}
        footer={[
          <div className="justify-in-center">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-14"
              onClick={() => setSuccessModal(false)}
            >
              Okay
            </Button>
          </div>,
        ]}
        heading={"Space(s) shared successfully!"}
      />

      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        visible={deleteModal}
        closable={false}
        footer={[
          <span className="justify-in-center">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay-gray square font-12"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-12"
              onClick={() => onDelete()}
            >
              {deleteLoader ? (
                <span>
                  {" "}
                  Deleting <LoadingOutlined spin />
                </span>
              ) : (
                <span> Okay </span>
              )}
            </Button>
          </span>,
        ]}
      >
        <div
          style={{
            margin: "30px auto 0px auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="manrope f-16 black-00 w-600">
            You're about to delete a space
          </h2>
          {companyID == COMPANY_ID ? (
            <p className="manrope f-14 black-55">
              This space and its assets will be deleted for the users of your
              company. Are you sure you want to delete?
            </p>
          ) : (
            <p className="manrope f-14 black-55">
              Deleting this space would delete all assets generated with it.
              Are you sure you want to delete?
            </p>
          )}
        </div>
      </Modal>
      <Modal
        visible={reorderByModal}
        onCancel={closeReorderByModal}
        className="modal-share"
        footer={[
          <div className="justify-in-end">
            <Button
              className="modal-okay-gray square font-12"
              htmlType="submit"
              onClick={closeReorderByModal}
            >
              Cancel
            </Button>
            <Button
              disabled={saveOrderedItems}
              className="modal-okay square font-12"
              style={{ marginRight:8 }}
              onClick={saveArrangements}
            >
              Reorder
            </Button>
          </div>,
        ]}
      >
        {saveByCompany && roomTab == "owned" ? (
          <ReorderYourModelsData
          folderState={folderState}
          roomTab={roomTab}
          reorderByCompany={reorderByCompany}
          changeOrderBySelection={changeOrderBySelection}/>
          ) : 
        mspCustomerSaveByCompany && roomTab == "customer_owned" ? (
          <ReorderYourModelsData
          folderState={folderState}
          roomTab={roomTab}
          reorderByCompany={mspCustomerReorderByCompany}
          changeOrderBySelection={changeMSPCustomerOrderBySelection}/>
        ) :
        <ReorderOtherModelsData 
          folderState={folderState}
          roomTab={roomTab}/>
        }
      </Modal>
      <Drawer
      width={360}
      visible={filtersDrawer}
      onClose={closeFiltersDrawer}
      placement="right">
        <div style={{padding: 20}}>
          <h2 className="manrope f-18 black-14 w-700">Filters</h2>
          <div>

              <h5 className='manrope f-16 black-14' style={{marginTop: 20}}>
                Space Category
              </h5>
              <Select
              className={`tree-select-material f-14 filter bg-light`}
              showSearch={true}
              value={spaceCategoryValue}
              onChange={changeSpaceCategory}
              placeholder="Search and select category" mode="multiple">
                {spaceCategories && spaceCategories.map((category,index) => {
                  return <Option className="manrope f-12 select-view" value={category}>{category}</Option>
                })}
              </Select>
          </div>
        </div>
      </Drawer>
    </div>
  );
}


const mapStateToProps = state => state;
const mapDispatchToProps = (dispatch) => ({
  listRooms: (payload) => {
    dispatch(listRooms(payload));
  },
  getScannedRooms: () => {
    dispatch(getScannedRooms());
  },
  getArrangements: (payload) => {
    dispatch(getArrangements(payload));
  },
  reorderData: (payload) => {
    dispatch(reorderData(payload));
  },
  createFolder: (payload) => {
    dispatch(createFolder(payload));
  },
  moveToFolder: (payload) => {
    dispatch(moveToFolder(payload));
  },
  renameFolder: (payload) => {
    dispatch(renameFolder(payload));
  },
});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerRooms)
)
