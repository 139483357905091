import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { Row, Col, Card, Image } from 'antd';
import ENVIRONMENT from '../../../../environments';
import { LoadingOutlined } from '@ant-design/icons';
import DottedLoader from '../../DottedLoader';
import * as Utilities from '../../Utilities';

const { Meta } = Card;


const TemplateDetails = (props) => {
    const [lifestyleRenders, setLifestyleRenders] = useState([]);
    const [imageVisible, setImageVisible] = useState([]);
    const [expand_image, setExpandImage] = useState(false)
    useEffect(() => {
        let lifestyle_renders = props.templateDetails.lifestyle_renders;

        lifestyle_renders.sort(function (a, b) {
            return new Date(a.time_updated) - new Date(b.time_updated)
        });
        let groups = lifestyle_renders.filter((ele) => ele.status != 'hidden').reduce((groups, render) => {
            let date = new Date(render.time_updated).toDateString();
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(render);
            return groups;
        }, {});

        console.log(groups);

        // To add it in the array format instead
        let groupArrays = Object.keys(groups).map((date) => {
            return {
                date,
                render: groups[date]
            };
        });

        console.log(groupArrays)
        setLifestyleRenders(groupArrays);

    }, []);

    const expandImage = (name) => {
        let temp_arr = imageVisible;
        props.templateDetails.lifestyle_renders.map(x => {
            if (x.filename == name) {
                temp_arr[x.filename.split(".")[0]] = true;
            } else {
                temp_arr[x.filename.split(".")[0]] = false;
            }
        });

        setImageVisible(temp_arr);
        setExpandImage(true);
    }

    const changeVisibility = (visible, prevVisible) => {
        let temp_arr = [];
        if (expand_image) {
            props.templateDetails.lifestyle_renders.map(x => {
                if (imageVisible[x.filename.split(".")[0]] && !visible) {
                    temp_arr[x.filename.split(".")[0]] = false;
                }
                else if (imageVisible[x.filename.split(".")[0]] && visible) {
                    temp_arr[x.filename.split(".")[0]] = true;
                } else {
                    temp_arr[x.filename.split(".")[0]] = false;
                }
            });
            setImageVisible(temp_arr);
            setExpandImage(false);
        }

    }

    return (<Col span={24} style={{ marginTop: 20 }}>
        <h1 className='manrope f-24 black-33'>{props.templateDetails.name}</h1>
        {lifestyleRenders && lifestyleRenders.slice(0).reverse().map(camera => (
            <Col span={24}>
                <Row>
                    <div className="manrope f-16 black-55" style={{ margin: "10px 0" }}>Date Created: {camera.date}</div>
                </Row>
                <Row gutter={[16, 16]}>
                    {camera.render && camera.render.slice(0).reverse().map(x => (
                        (x.status == 'rendered' && x.filename != '360Camera.jpg') ?
                            <Col span={12}>
                                <Card className="lifestyle-image-card">
                                    <div className="position-flag-dim">
                                        <div className="resolution-btn">
                                            <span className="category-artist white">{Math.round(x.image_width) + ' x ' + Math.round(x.image_height)}</span>
                                        </div>
                                    </div>
                                    <div className="img-format-tag">
                                        <div className="resolution-btn">
                                            <span className="category-artist white">{Utilities.imageFormat(x.filename)}</span>
                                        </div>
                                    </div>

                                    <Image preview={{
                                        visible: imageVisible[x.filename.split(".")[0]],
                                        maskClassName: 'customize-mask',
                                        mask: (
                                            <span>
                                                <img className="image-thumbnail" src="/img/expand_thumbnail.png" onClick={() => expandImage(x.filename)} />
                                            </span>
                                        ),
                                        onVisibleChange: changeVisibility,
                                    }}
                                        className="lifestyle-image-settings" src={ENVIRONMENT.getBaseURL(props.templateDetails.platform) + ENVIRONMENT.TEMPLATE_BASE_URI + props.templateDetails.id + '/' + x.filename.replace('tiff', 'jpg')}
                                    />
                                </Card>
                            </Col>
                            : ""
                    ))}
                </Row>
            </Col>))}
    </Col>
    );
}

export default TemplateDetails;