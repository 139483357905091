import React, { useEffect, useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Button, Input, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { listCollections, updateCollection } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import FileConstants from '../../../../FileConstants';
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES
const COMPANY_ID = FileConstants.COMPANY_ID

const SceneNameInput = (props) => {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sceneNames, setSceneNames] = useState([])
    const [collectionNames, setCollectionNames] = useState([]);
    const formRef = React.createRef();

    useEffect(() => {
        getSceneNames();
        getCollectionNames();

    }, [])

    const generatePayload = () => {
        let payload = {
            'username': CUSTOMER_USERNAME
        }
        if (FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null)) {
            payload.designed_for = MANAGED_CUSTOMER_USERNAME;
        }
        if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined) {
            if (SHARED_ENTITIES.includes('collection')) {
                payload.company_id = COMPANY_ID
            }
        }
        return payload
    }

    const getSceneNames = () => {
        let payload = generatePayload();
        axios.post(ENVIRONMENT.GET_SCENE_NAMES, payload)
            .then(res => {
                setSceneNames(res.data.body)
            })
    }

    const getCollectionNames = () => {
        let payload = generatePayload();
        axios.post(ENVIRONMENT.GET_COLLECTION_NAMES, payload)
            .then((response) => {
                let names = []
                response.data && response.data.map((coll => {
                    names.push(coll.name);
                }))
                setCollectionNames(names);
            });
    }

    const handleCancel = () => {
        setVisible(false);
        if (formRef && formRef.current) {
            formRef.current.resetFields();
        }

        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        let scene_state = new URLSearchParams(window.location.search).get("scene_state");
        let room_state = new URLSearchParams(window.location.search).get("room_state");
        let product_state = new URLSearchParams(window.location.search).get("product_state");

        if (onboarding != undefined) {
            if (typeof (onboarding == 'string') || onboarding instanceof String) {
                onboarding = (onboarding.toLowerCase() === 'true');
            }
            if (onboarding) {
                if (scene_state != undefined && room_state != undefined && product_state != undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=15`
                    });
                } else if (scene_state != undefined && room_state == undefined && product_state == undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added`
                    });
                }
                else if (scene_state != undefined && room_state != undefined && product_state == undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added&room_state=added`
                    });
                } else if (scene_state != undefined && room_state == undefined && product_state != undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added&product_state=added`
                    });
                }
                else {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=15`
                    });
                }
            } else {
                if(props.redirection_path){
                    props.history.push({
                        pathname: props.redirection_path
                    }); 
                } else {
                    props.history.push({
                        pathname: '/home'
                    });
                }
            }
        }
        else{
            if(props.redirection_path){
                props.history.push({
                    pathname: props.redirection_path
                }); 
            } else {
                props.history.push({
                    pathname: '/home'
                });
            }  
        }

    }

    useEffect(() => {
        setVisible(props.visible);
        if (props.visible === false) {
            if (formRef && formRef.current) {
                formRef.current.resetFields();
            }
        }
    }, [props.visible])

    const getCollectionPayload = () => {
        let payload = {
            "required_fields": ["id", "name",
                "created_on", "spaces_count", "products_count", "template_collection_id",
                "product_thumbnails", "thumbnail_link"],
            "order_by": "id desc"
        }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"

        filter_string = "(" + customer_username + "&&" + is_hidden + ")&&(template_collection_id__is=null)"
        filter_string = filter_string + "||(" + has_access_to + ")||(template_collection_id__not=NULL&&" + customer_username + "&&" + is_hidden + ")"

        payload['filter_string'] = filter_string

        return payload;
    }

    useEffect(() => {

        props.listCollections(getCollectionPayload());
    }, []);

    const handleNextScreen = () => {
        let collections_count = props.rootReducer.dashboardData.counts.collections;
        if (collections_count == -1) {
            axios.post(ENVIRONMENT.DASHBOARD_ANALYTICS, { username: localStorage.getItem('username') })
                .then((res) => {
                    if (res.data.counts != undefined) {
                        if (res.data.counts.collections == 0) {
                            props.setFirstCollectionView();
                        }
                        else {
                            props.setCollectionSelectionView();
                        }
                    }
                    else {
                        props.setCollectionSelectionView();
                    }
                })
                .catch((error) => {
                    props.setCollectionSelectionView();
                });
        }
        else {
            if (collections_count == 0) {
                props.setFirstCollectionView();
            } else {
                props.setCollectionSelectionView();
            }

        }

    }
    const handleSubmit = (values) => {
        console.log(values)
        setIsLoading(true);

        if (props.scene_flow) {
            setIsLoading(true);
            props.setSceneName(values.scene_name);
            handleNextScreen();
        } else {
            props.setSceneName(values.scene_name);
            props.setCollectionSelectionView();
        }

    }

    return (
        <div>
            <div>
                <Form name="scene_name_input"
                    ref={formRef} layout="vertical"
                    className="input-form" onFinish={handleSubmit}>
                    <Form.Item name="scene_name" label={<span className='manrope f-14 black-00 w-600'>Scene Name</span>}
                        rules={[{ required: true, message: 'Please enter scene name.' },
                        {
                            validator: async (_, value) => {
                                // if scene with same name exists
                                if (value) {
                                    if ((sceneNames && sceneNames.includes(value)) || (collectionNames && collectionNames.includes(value))) {
                                        throw new Error('Name must be unique. Please use a different name.');
                                    }
                                }
                            }
                        }]}
                        colon={false}>
                        <Input className="manrope f-10 black-55"
                            placeholder="Enter your scene name" />
                    </Form.Item>
                    <Row type="flex" style={{ marginTop: 20, justifyContent: "flex-end" }}>
                        <Button style={{ marginBottom: 10, marginRight: 10 }} className="outline-red-btn square font-12" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Form.Item>
                            <Button className="modal-okay-green square font-12"
                                type="primary" htmlType='submit'>
                                Continue  {isLoading ? <LoadingOutlined /> : ""}
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>

            </div>
        </div>
    );
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    listCollections: (payload) => {
        dispatch(listCollections(payload));
    },
    updateCollection: (payload) => {
        dispatch(updateCollection(payload));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SceneNameInput))
