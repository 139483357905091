import React from "react";
import { Row, Col, Tabs, Select, Button, InputNumber,Modal,Form,Upload,Input,message } from "antd";
import AdminMainLayout from '../AdminMainLayout';
import ENVIRONMENT from '../../../../environments';
import RenderServiceTable from '../../AdminComponents/RenderServiceTable'
import AWS from 'aws-sdk';
import axios from 'axios';
import { Link } from "react-router-dom";
import * as Constants from '../../CustomerComponents/Constants';
import { LoadingOutlined } from '@ant-design/icons';

import { BASE_URL, BUCKET_NAME } from "../../../../environments/env";
const { Option } = Select;
const { Dragger } = Upload;
const { TabPane } = Tabs;

class AdminRenderServices extends React.Component {
  state = { size: "small",
  modelFileList: [],
  upload_error: '',
  request_submitted: false,
  open_render_modal: false,
  upload_error: '',
  success_modal: false,
  uploading: false,
  hasError: false,
  errorMessage: ''
 };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  componentDidMount() {
    this.setState({
      username: localStorage.getItem('username'),
    });
  }

  validateFileUploads = (files) => {
    let status_done = false;
    if (files != undefined) {
        for(var file of files) {
            if (file['status'] == 'uploading') {
                return 'uploading';
            }
            else if (file['status'] == 'error') {
                return 'error';
            }
            else if (file['status'] == 'done') {
                status_done = true;
            }
        }
    }
    if (status_done) {
        return 'done'
    }
    return 'not_started';
}

  handleChange = info => {
    let fileList = info.fileList;
    if (fileList != undefined) {
      fileList = fileList.slice(-1);
      this.setState({modelFileList: fileList,
      uploading: true});
      console.log(info,info.file);
      if (info.file.status === "done") {
          message.success(`${info.file.name} file uploaded successfully`);
          this.setState({
            hasError: false,
            errorMessage: '',
            uploading: false
          });
        
      } else if (info.file.status === "error") {
          console.log(info.file.error.message,info.file);
          this.setState({
            hasError: true,
            uploading: false,
            errorMessage: info.file.error.message.concat('   ' + info.file.error.message)
        });
        if (info.file.error.code.includes('Credentials')) {
            this.setState({
                hasError: true,
                uploading: false,
                errorMessage: info.file.error.message.concat('. Your session has expired. Please reload the page.')
            });
        }  else if (info.file.error.code.includes('Network')) {
            this.setState({
                hasError: true,
                uploading: false,
                errorMessage: info.file.error.message.concat('. We are unable to upload due to an issue with your internet connection.')
            });
        }
      }
    }
  };

  handleSubmit = (values) => {
    console.log('values',values);
    let upload_status = this.validateFileUploads(this.state.modelFileList);
    if (upload_status == "uploading") {
      this.setState({
        upload_error: "You are not allowed to submit while upload is in progress."
      });
    } else if (upload_status == "error") {
      this.setState({
        upload_error: "An error occured while uploading your file. Please reupload your file."
      });
    } else if (upload_status == "done") {
      this.setState({
        request_submitted: true
      });
      let body = {};
      body.username = values.username;
      body.render_name = this.state.render_name;
      body.input_archive_url = BASE_URL + this.state.modelFileList.uid + "/" + this.state.modelFileList.name;
      console.log(this.state.modelFileList);
      axios.post(ENVIRONMENT.CREATE_RENDERS, { 
        "username": this.state.username,
        "render_name": values.render_name,
        "input_archive_url": BASE_URL + this.state.modelFileList[0].uid + "/" + this.state.modelFileList[0].name })
        .then(res => {
            this.setState({
              request_submitted: false,
              success_modal: true,
              open_render_modal: false
            });
        });
    } else {
      this.setState({
        upload_error: "Please upload an archive file."
      });
    }
  }

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"19"} style={{marginTop: 24}}>
        <Row type="flex" justify="space-around" style={{marginTop: 40, marginBottom: 20}}>
          <Col span={24} style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
              <h2 className="product-h2">
                <span style={{ color: "#999999"}}>Admin Renders</span>
              </h2>
              <Button className="modal-okay square font-16" onClick={() => {
                this.setState({
                  open_render_modal: true
                })
              }}>
                Upload Test Render Archive
              </Button>
          </Col>
        </Row>
        <Modal
          visible={this.state.open_render_modal}
          className="model-time-modal"
          maskClosable={false}
          onCancel={() => {
            this.setState({
              open_render_modal: false
              })}}>
                   <Form style={{padding: "32px 32px 2px 32px"}} className="artist-form" onFinish={this.handleSubmit}>
                    <div className="model-time-h" style={{marginBottom: 30}}>Upload Test Render Archive</div>
                    <Form.Item rules={[{ required: true, message: 'Please input render name' }]} name="render_name" className="artist-upload-txt align-text large" label={"Test Render's Name"} colon={false}>
                      <Input
                      style={{padding: 10}}
                      placeholder="Please enter the test render's name"/>
                    </Form.Item>

                    <div className="artist-upload-txt align-text upload-text"><span style={{color:"#ff4d4f"}}>*</span> Max Archive</div>
                    <div name="render_form" className="artist-upload-txt align-text" colon={false}>
                      <div style={{marginBottom: 8 ,display:"block"}}>
                        <div className="dragger-upload-div" style={{padding:"20px"}}>
                          <Dragger style={{display: 'inline-block', width: "100%"}}
                          className="upload-dragger-artist"
                          multiple={false}
                          progress= {{
                            strokeColor: {
                            '0%': '#108ee9',
                            '100%': '#87d068',
                            },
                            strokeWidth: 3,
                            format: percent => `${parseFloat(percent.toFixed(2))}%`,
                            }}
                          disabled={false}
                          beforeUpload={file => {
                            
                          }}
                          {...Constants.upload_props}
                          onChange = {this.handleChange}
                          listType="text"
                          openFileDialogOnClick={!(this.validateFileUploads(this.state.modelFileList) == 'error')}
                          onRemove={file => {
                              message.success(`${file.name} removed successfully!`)
                              const index = this.state.modelFileList.indexOf(file);
                              const newFileList = this.state.modelFileList.slice();
                              newFileList.splice(index, 1);
                              this.setState({
                                modelFileList: newFileList
                              });
                              console.log(newFileList)
                              newFileList.forEach((file) => {
                                  if (file.status !== "error"){
                                      this.setState({
                                        hasError: false
                                      });
                                  }
                                  else {
                                    this.setState({
                                      hasError: true
                                    });
                                  }
                              })
                              return true;
                          }}
                          fileList = {this.state.modelFileList}>
                            {this.validateFileUploads(this.state.modelFileList) == "uploading"  ? 
                              <span>
                                <p className="artist-upload-txt large">Upload in Progress... </p>
                              </span> :
                              this.validateFileUploads(this.state.modelFileList) == "done" ? 
                              <span>
                                <div className="justify-in-center">
                                  <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                                  <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                                </div>                        
                              </span> :
                              this.validateFileUploads(this.state.modelFileList) == "error" ? 
                              <span>
                                <div className="justify-in-center">
                                  <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                  <p className="artist-upload-txt large" style={{color:"#D93025"}}>Upload Error</p>
                                </div>                        
                              </span> :
                              <span>
                              <p className="ant-upload-drag-icon">
                              <img src="/img/exclude.png"></img>
                              </p>
                              <p className="artist-upload-txt">Drop your Max <span style={{color:"#333333"}}>Archive</span> folder here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                              <p className="artist-upload-txt small">
                                Only .zip files are supported     
                              </p>
                              </span>
                                }
                        </Dragger>
                      </div>
                    </div>
                  </div>
                  {this.state.upload_error == "" ? "" :
                  <div style={{marginBottom: 8}}>
                    <span className="category-artist red">{this.state.upload_error}</span>
                  </div>}
                                
                  {(this.validateFileUploads(this.state.modelFileList) == 'error') ? 
                      <div style={{marginBottom: 8}}>
                        <span className="category-artist red">{this.state.errorMessage}</span>
                      </div>: ''}

                  <div className="blue-note-bg" style={{marginBottom: 18}}>
                      <span className="scene-note flag">
                          Note: <div style={{color:"#333333",marginTop: 8}}>In order to specify parameters like image resolution and render type (Still, 360) per camera, please use the ALL3D Camera Parameters plugin which you can download from this <a href="https://all3d.s3.us-west-2.amazonaws.com/static_files/AddAll3DCamCA.mzp" target="_blank">link</a>.</div>
                      </span>
                  </div>

                  <Form.Item className="artist-form-button justify-buttons end">
                      <Button disabled={(this.state.request_submitted === true)} className="modal-okay square font-14" htmlType="submit">
                        Submit {this.state.request_submitted === true?<LoadingOutlined spin/>:""}
                      </Button>
                    </Form.Item>
                  </Form>
              </Modal>
              <Modal
              className="modal-lifestyle-request"
              centered={true}
              visible={this.state.success_modal}
              bodyStyle={{padding: 0}}
              centered={true}
              footer={[
                <div className="justify-in-center">
                  <Button className="modal-okay font-14" key="submit" onClick={() => {
                  this.setState({
                    success_modal: false
                  }, () => {
                    window.location.reload();
                  });
                  } }>
                      Okay
                  </Button>
                  </div>
              ]}>
                  <div style={{padding:"40px"}}>
                      <div style={{textAlign: "center"}}>
                          <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                      </div>
                      <div style={{textAlign: "center",margin: "20px 0"}}>
                          <h2 className="modal-heading">Render Submitted Successfully!</h2>
                          <p className="modal-text">Your test render has been submitted successfully.</p>
                      </div>     
                  </div>       
          </Modal>
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false}>
          
          <TabPane tab={"Enqueue"} key="1">          
            <RenderServiceTable
              state="enqueue"
            />
          </TabPane>
          <TabPane tab={"In Progress"} key="2">
            <RenderServiceTable
              state="in_progress"
            />
          </TabPane>
          <TabPane tab={"Complete"} key="3">
            <RenderServiceTable
              state="complete"
            />
          </TabPane>
          <TabPane tab={"Failed"} key="4">
            <RenderServiceTable
              state="rejected"
            />
          </TabPane>
          
        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminRenderServices;