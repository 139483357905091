import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash";
import axios from 'axios';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Button, Row, Col, Form, Select, InputNumber, Modal, Radio, message } from 'antd';
import ENVIRONMENT from '../../../../environments';
import SuccessModal from '../../SuccessModal/SuccessModal';
import { LoadingOutlined } from '@ant-design/icons';
import * as Styles from '../../../../Styles';

const { Option } = Select;

const radioStyle = Styles.radioStyle;

const Product360Spin = () => {
    const { product_id, threesixtyDetailsModal, setThreesixtyDetailsModal, requestStatus, cardStatus, productData,
        selectedPriceCategory, setInAppLimitExceededModal, setPaymentFailureModal, setCardSaveFlowTriggered, setPaymentFailureMessage,
        cardSaveFlowTriggered, openDimensionalModal, openVideoModal, productAssetData,
        statusOf360Spin, setStatusOf360Spin, threeSixtyPrice, statusLoader,
        currentId, variantSelected, setStatusLoader, variationProductData,
        setVariantSelected, setVariationProductData, setVariationAssetData, variationAssetData, setSavedVariationProductDetails
    } = useContext(CustomerProductContext);

    const [requestType, setRequestType] = useState('product_dimensional');
    const [frame360, setFrame360] = useState('36');
    const [res360, setRes360] = useState('2.0');
    const [replace360Confirm, setReplace360Confirm] = useState(false);
    const [threesixtyGenerationLoader, setThreesixtyGenerationLoader] = useState(false);
    const [payload360, setPayload360] = useState('');
    const [threesixtySuccessModal, setThreesixtySuccessModal] = useState(false);
    const [disable360, setDisable360] = useState(false);


    useEffect(() => {
        let product_asset_data = productAssetData;
        if (variantSelected && variationAssetData) {
            product_asset_data = variationAssetData;
        }
        if (product_asset_data && product_asset_data.threesixty_spin_details != undefined) {
            if (Object.entries(product_asset_data.threesixty_spin_details).length !== 0) {
                setStatusOf360Spin(true);
                if (frame360 == product_asset_data.threesixty_spin_details.frame_count && res360 == product_asset_data.threesixty_spin_details.frame_resolution) {
                    setDisable360(true);
                } else {
                    setDisable360(false);
                }
            }
        }
    }, [res360, frame360, variantSelected, variationAssetData]);


    const handleThreesixtySubmit = values => {
        let payload = {};
        payload.product_id = variantSelected ? currentId : product_id;
        payload.username = localStorage.getItem("username");
        payload.threesixty_spin_details = values;
        payload.threesixty_spin_details.captured_by = localStorage.getItem("username");
        payload.threesixty_spin_details.asset_status = 'pending';
        payload.threesixty_job_status = 'generate';
        payload.generate_asset = 1;
        let company_id = localStorage.getItem('company_id');
        let product_data = variantSelected ? variationProductData : productData;

        if (company_id != undefined && product_data.company_id != undefined) {
            if (company_id == product_data.company_id) {
                payload.company_id = company_id;
            }
        }
        setPayload360(payload);
        setRequestType('product_360_spin');
        let requestPayload = payload;
        requestPayload['username'] = localStorage.getItem('username');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_360_spin",
            "action": "perform",
            "request_payload": requestPayload,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if (statusOf360Spin == '') {
            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
                setThreesixtyGenerationLoader(true);
                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status != undefined && res.data.status == 1) {
                            setThreesixtySuccessModal(true);
                            close360Spin();
                            setThreesixtyGenerationLoader(false);
                            setStatusOf360Spin("pending");
                        }
                        else {
                            if (res.data.request_allowed == 'not_allowed') {
                                close360Spin();
                                setThreesixtyGenerationLoader(false);
                                setInAppLimitExceededModal(true);
                            } else {
                                message.error('something went wrong');
                            }
                        }
                    })
            }
            if (requestStatus == 'payment_required') {
                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'product_360_spin') {
                        threesixtyAPICall(gateway_payload);
                    }

                }, false);

                if (cardStatus == 1) {
                    threesixtyAPICall(gateway_payload);
                }
                if (cardStatus == 0) {
                    let child_window = window.open('/save-card?action=product_360_spin', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }


        } else {
            setReplace360Confirm(true);
            setThreesixtySuccessModal(false);
            close360Spin();
            setThreesixtyGenerationLoader(false);
        }
    }

    const threesixtyAPICall = (gateway_payload) => {
        setThreesixtyGenerationLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    setThreesixtySuccessModal(true);
                    close360Spin();
                    setThreesixtyGenerationLoader(false);
                    setStatusOf360Spin("pending");
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        close360Spin();
                        setThreesixtyGenerationLoader(false);
                        setInAppLimitExceededModal(true);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setThreesixtyGenerationLoader(false);
                    }
                }
            })
    }

    const replace360 = () => {
        setRequestType('product_360_spin');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_360_spin",
            "action": "perform",
            "request_payload": payload360,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if (statusOf360Spin != '') {
            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
                setThreesixtyGenerationLoader(true);
                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status != undefined && res.data.status == 1) {
                            setReplace360Confirm(false);
                            setThreesixtySuccessModal(true);
                            close360Spin();
                            setThreesixtyGenerationLoader(false);
                            setStatusOf360Spin("pending");
                        }
                        else {
                            if (res.data.request_allowed == 'not_allowed') {
                                close360Spin();
                                setThreesixtyGenerationLoader(false);
                                setInAppLimitExceededModal(true);
                            } else {
                                message.error('something went wrong');
                            }
                        }
                    });
            }
            if (requestStatus == 'payment_required') {
                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'product_360_spin') {
                        threesixtyReplaceAPICall(gateway_payload);
                    }

                }, false);

                if (cardStatus == 1) {
                    threesixtyReplaceAPICall(gateway_payload);
                }
                if (cardStatus == 0) {
                    let child_window = window.open('/save-card?action=product_360_spin', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }

        }
    }

    const threesixtyReplaceAPICall = (gateway_payload) => {
        setThreesixtyGenerationLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    setReplace360Confirm(false);
                    setThreesixtySuccessModal(true);
                    close360Spin();
                    setThreesixtyGenerationLoader(false);
                    setStatusOf360Spin("pending");
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        close360Spin();
                        setThreesixtyGenerationLoader(false);
                        setInAppLimitExceededModal(true);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setThreesixtyGenerationLoader(false);
                    }
                }
            });
    }


    const handleFrameChange = (value) => {
        setFrame360(value);
    }

    const handleResChange = (value) => {
        setRes360(value);
    }

    const close360Spin = () => {
        setThreesixtyDetailsModal(false);
        setVariationProductData(null);
        setVariationAssetData(null);
        setVariantSelected(false);
        setSavedVariationProductDetails(null);
    }

    return (<>
        <Modal
            visible={threesixtyDetailsModal}
            className="modal-share"
            width={788}
            onCancel={close360Spin}
            footer={statusLoader ? '' : [
                <div className={(requestStatus == "payment_required" || requestStatus == "not_allowed") ? "justify-space-between" : "justify-in-end"}>
                    {(requestStatus == "payment_required" || requestStatus == "not_allowed") ?
                        <div className="manrope f-14 black-55">
                            Total Price: <span className="manrope f-14 w-700" style={{ color: "#25D6A4" }}>{"$" + threeSixtyPrice}</span>
                        </div> : ''}
                    <Button disabled={disable360 || cardSaveFlowTriggered || threesixtyGenerationLoader} style={{ textAlign: "center" }} className="modal-okay square font-14"
                        onClick={() => document.getElementById("generate-threesixty-button").click()}>
                        {threesixtyGenerationLoader ? <>Submitting Request<LoadingOutlined style={{ marginLeft: 6 }} spin /></> :
                            <>
                                {cardStatus == 1 && (requestStatus == "payment_required" || requestStatus == "not_allowed") ? "Confirm & Process Payment" :
                                    cardStatus == 0 && (requestStatus == "payment_required" || requestStatus == "not_allowed") ? "Confirm & Enter Payment Details" : "Confirm"}
                            </>}
                    </Button>
                </div>
            ]}>
            {statusLoader ? (
                <LoadingOutlined className="loading-center mt-10 mb-20" />
            ) :
                <div style={{ padding: "16px 16px 0 16px" }}>
                    <h2 className="manrope f-18 black-14 w-700" style={{ marginBottom: 20 }}>360 Spin Configuration</h2>
                    {
                        statusOf360Spin != '' && disable360 ?
                            <div style={{ padding: "10px 16px", marginTop: 0, marginBottom: 30 }} className="note-flag">
                                <span className="manrope f-12 grey-73">
                                    <span className="manrope f-12 grey-73 w-700">Note:</span>&nbsp;Please select a configuration different than before to request another 360 Spin.
                                </span>
                            </div> : ''
                    }

                    <Form onFinish={handleThreesixtySubmit} layout="vertical">
                        <Col span={24}>
                            <Form.Item name="frame_count" className="material-category-form" label={<span className="manrope f-14 black-55 w-900">Frames Count</span>} initialValue="36" colon={false}>
                                <Select
                                    value={frame360}
                                    onChange={handleFrameChange}
                                    className="manrope black-55 tree-select-material f-12"
                                    defaultValue={'36'}>
                                    <Option value="36" className="manrope f-12 select-view">36 Frames</Option>
                                    <Option value="72" className="manrope f-12 select-view">72 Frames</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginTop: 24 }}>
                            <Form.Item name="frame_resolution" className="material-category-form" label={<span className="manrope f-14 black-55 w-900">Frame Resolution</span>} initialValue="2.0" colon={false}>
                                <Select
                                    value={res360}
                                    className="manrope black-55 tree-select-material f-12"
                                    defaultValue={'36'}
                                    onChange={handleResChange}>
                                    <Option value="2.0" className="manrope f-12 select-view">2K</Option>
                                    <Option value="4.0" className="manrope f-12 select-view">4K</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Button id="generate-threesixty-button" type="primary" htmlType="submit" style={{ "display": "none" }}>
                            Submit
                        </Button>
                    </Form>
                </div>}
        </Modal>
        <Modal
            closable={false}
            visible={replace360Confirm}
            className="modal-share-success"
            width={600}
            onCancel={() => setReplace360Confirm(false)}
            footer={[
                <div className='justify-in-end'>
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay-black square font-12" onClick={() => setReplace360Confirm(false)}>
                        Cancel 360 Spin
                    </Button>
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => replace360()}>
                        Replace Older 360 Spin {threesixtyGenerationLoader ? <LoadingOutlined spin /> : ""}
                    </Button>
                </div>
            ]}>
            <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <h2 className="manrope f-16 black-00 w-600">Your Older 360 Spin Will Be Replaced</h2>
                <div className="manrope f-14 black-55">Your previously generated 360 Spin will be replaced with the new one. Are you sure you want continue with your request?</div>
            </div>
        </Modal>

        <SuccessModal
            onCancel={() => setThreesixtySuccessModal(false)}
            visible={threesixtySuccessModal}
            heading={"360 Spin Requested Successfully!"}
            closable={true}
            text={"You have successfully requested your new 360 spin. The current 360 spin will be replaced as soon as the new one is available. We will notify you once it is ready."}
            footer={[
                <div className='justify-in-center'>
                    <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                        window.location.href = "/silo-tool/" + product_id;
                    }}>
                        Create Silo Images
                    </Button>
                    <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                        setThreesixtySuccessModal(false);
                        openDimensionalModal();
                    }}>
                        Create Dimensional Image
                    </Button>
                    <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                        setThreesixtySuccessModal(false);
                        openVideoModal();
                    }}>
                        Request MP4 Video
                    </Button>
                </div>
            ]}
        />

    </>)
}

export default Product360Spin;