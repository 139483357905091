import * as THREE from 'three'

export default class SwapManager {

    scene = null;
    space = null;
    sceneAssets = null;
    oldSpaceObject = null;
    sceneLoader = null;
    isBabylonExported = false;

    /**
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     * @param {THREE.Scene} space - The space object cobtaining data about space.
     * @param {THREE.Scene} sceneAssets - List of assets in the scene.
     * @param {THREE.Scene} sceneLoader - Sceneloader object.
     * @param {THREE.Scene} isBabylonExported - Boolean indicating is space is babylon exported.
     */
    constructor(scene, space, sceneAssets, sceneLoader, isBabylonExported ) {
        this.scene = scene;
        this.space = space;
        this.sceneAssets = sceneAssets;
        this.sceneLoader = sceneLoader;
        this.isBabylonExported = isBabylonExported
    }

    setSpace(space) {
        this.space = space;
    }

    setOldSpaceObject(oldSpaceObject) {
        this.oldSpaceObject = oldSpaceObject;
    }

    checkAutoPlaceAssetsOnSpaceSwap () {
        const oldSpaceBB = new THREE.Box3().setFromObject(this.oldSpaceObject);
        const currentSpaceBB = new THREE.Box3().setFromObject(this.space.scene);

        const distanceX = Math.abs(oldSpaceBB.max.x - currentSpaceBB.max.x) + Math.abs(oldSpaceBB.min.x - currentSpaceBB.min.x);
        const distanceZ = Math.abs(oldSpaceBB.max.z - currentSpaceBB.max.z) + Math.abs(oldSpaceBB.min.z - currentSpaceBB.min.z);

        // tolerance level for space
        const tolerance = 1;

        // Check if all distances are within the tolerance level
        const isBoundingBoxClose = distanceX < tolerance && distanceZ < tolerance;

        if (!isBoundingBoxClose) {
            this.autoPlaceAssetsOnSpaceSwap();
        }
    }

    autoPlaceAssetsOnSpaceSwap() {
        var scope = this;

        scope.autoplaceWallItems();

        // group objects with distance threshold 3
        const groupedAssets = this.groupObjects(3);

        for (let groupObject of groupedAssets) {
            scope.scene.add(groupObject)
        }

        scope.sceneLoader.autoPlaceGroupedAssets(groupedAssets, this.isBabylonExported);
        this.unGroupObjects(groupedAssets);
    }

    calculateDistanceToPoint(obj, pos) {
        const deltaX = obj.position.x - pos.x;
        const deltaZ = obj.position.z - pos.z;
        const distance = Math.sqrt(deltaX * deltaX + deltaZ * deltaZ);
        return distance;
    }
    
    autoplaceWallItems() {
        var scope = this;
        const visibleWallAssets = scope.sceneAssets.filter((asset)=>{
            return (asset.userData.visible && asset.userData.placementType == "wall");
        });

        if (visibleWallAssets.length > 0) {
            this.sceneLoader.autoPlaceWallAssets(visibleWallAssets);
        }
    }

    // Function to group objects based on threshold distance
    groupObjects(threshold) {
        let scope = this;
        const visibleAssets = scope.sceneAssets.filter((asset)=>{
            return (asset.userData.visible && asset.userData.placementType != "wall" && !asset.userData.isStacked);
        });

        const groups = [];
        const groupObject = new THREE.Group();
        groupObject.add(visibleAssets[0]);
        groups.push(groupObject);
    
        for (let i = 1; i < visibleAssets.length; i++) {
            const obj = visibleAssets[i];
            let grouped = false;
    
            for (let j = 0; j < groups.length; j++) {
                const group = groups[j];

                const groupBoundingBox = new THREE.Box3().setFromObject(group);
                const min = groupBoundingBox.min.clone();
                const max = groupBoundingBox.max.clone();

                const distanceMin = this.calculateDistanceToPoint(obj, min);
                const distanceMax = this.calculateDistanceToPoint(obj, max);

                // calculating size of the sapce
                const spaceArea = this.space.areas[Object.keys(this.space.areas)[0]];
                const spaceBoundingBox = new THREE.Box3().setFromObject(spaceArea.root);
                const spaceSize = new THREE.Vector3();
                spaceBoundingBox.getSize(spaceSize);

                if ((distanceMin < threshold || distanceMax < threshold)) {
                    grouped = true;
                    group.add(obj);

                    const groupBoundingBoxAfterAdd = new THREE.Box3().setFromObject(group);
                    const groupSize = new THREE.Vector3();
                    groupBoundingBoxAfterAdd.getSize(groupSize);

                    // check if the group size is larger than the space size with distance threshold .3
                    if ((groupSize.x + .3) > spaceSize.x || (groupSize.z + .3) > spaceSize.z) {
                        grouped = false;
                    }

                    break;
                }
            }
    
            // If not grouped, create a new group
            if (!grouped) {
                const groupObject = new THREE.Group();
                groupObject.add(obj);
                groups.push(groupObject);
            }
        }

        return groups;
    }

    unGroupObjects(groups) {
        for (let group of groups) {
            const childrenArray = [...group.children];
            childrenArray.forEach ( asset => {
                this.scene.attach(asset);
            } )
        }
        for (let group of groups) {
            this.scene.remove(group);
        }
    }
}