const EntityConstants = new Object();

EntityConstants.entity_data = {
    product: {
        title: "Product",
        className: "product-tag",
        select_id: "id",
    },
    prop: {
        title: "Prop",
        className: "prop-tag",
        select_id: "id",
    },
    room: {
        title: "Space",
        className: "space-tag",
        select_id: "room_id",
    },
    scene: {
        title: "Scene",
        className: "scene-tag",
    },
};

export default EntityConstants;