import React, { useState, useEffect }  from 'react';
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import axios from 'axios';

import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Select,message, Tooltip } from 'antd';
import {createRoom, updateRoom} from "../../../../../redux/actions";
import {connect} from "react-redux";
import $ from 'jquery';
import { withRouter, Link } from 'react-router-dom'
import ENVIRONMENT from '../../../../../environments'
import FileConstants from '../../../../../FileConstants';



const { Option } = Select;
const CUSTOMER_USERNAME = localStorage.getItem('username');

const RoomInformationForm = (props) => {

    const { getFieldDecorator , getFieldValue , setFieldsValue, resetFields} = props.form;
    const [submitType, setSubmitType] = useState(null);
    const [savedForm, setSavedForm] = useState(false);
    const [spaceCategories, setSpaceCategories] = useState([]);
    const [projects, setProjects] = useState([]);


    useEffect(() => {
        if(props.roomReducer.isCreated == true || props.roomReducer.isUpdated == true) {
            if(submitType == 'continue') {
                let onboarding_string = '';
                let onboarding = new URLSearchParams(window.location.search).get("onboarding");
                if(onboarding != undefined){
                    onboarding_string = '&onboarding=true'
                }
                let product_state_string = '';
                let product_state = new URLSearchParams(window.location.search).get("product_state");
                if(product_state != undefined){
                    product_state_string = '&product_state='+product_state
                }
                
                props.history.push({
                    pathname: '/add-new-room',
                    search: `?step=${submitType == 'continue' ? 1: 0}&room_id=${ props.roomReducer.room.room_id}${onboarding_string}${product_state_string}&origin=${props.redirectionPath}`
                });
                props.next();
            } else if (submitType == "save") {
                // message.info('Space saved successfully');
                // let currentUrl = window.location.pathname;
                // currentUrl = currentUrl + '?step=0&room_id=' + props.roomReducer.room.room_id;
                // props.history.replace(currentUrl)
                props.handlePageRedirection();
                document.activeElement.blur();
            }
        }
        if(props.roomReducer.error) {
            message.error(props.roomReducer.error);
        }
        setFieldsValue(props.roomReducer.room);
        if (props.roomReducer.room) {
            if (props.roomReducer.room.room_id) {
                setSavedForm(true);
            }
        }
    }, [props.roomReducer]);


    useEffect(()=> {
        setSpaceCategoriesData();
        resetFields();
        fetchProjectData();
    }, []);

    const setSpaceCategoriesData = () => {
        let payload = {
          'request_type': 'room_model'
        }
        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
        .then(res => {
          let categories = []
          if (res.data) {
            res.data.map((request) => {
              if (!categories.includes(request.category)) {
                categories.push(request.category);
              }
            })
            categories = categories.filter(item => item !== '')
            setSpaceCategories(categories);
          }
        })
      }

    const fetchProjectData = async () => {
        let payload = {
            order_by: "id desc",
            required_fields: [
              "id",
              "name"
            ],
            filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
          };
    
        await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {setProjects(res.data);});
        console.log("PROJECTS:",projects);
      };

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                let id = new URLSearchParams(window.location.search).get("room_id");
                if(id) {
                    props.updateRoom({...values, room_id: id});
                } else {
                    let payload = {...values, required_assets: ['3D Model']};
                    if (FileConstants.isMSProvider && (FileConstants.MANAGED_CUSTOMER_USERNAME != null || FileConstants.MANAGED_COMPANY_ID != null)) {
                        payload['requested_for'] = FileConstants.MANAGED_CUSTOMER_USERNAME
                    }
                    let company_id = localStorage.getItem('company_id');
                    if(company_id != undefined) {
                        payload.company_id = company_id;
                    }
                    props.createRoom(payload);
                }
            }
        });
    };

    const handleRedirection = () => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        let product_state_string = '';
        let product_state = new URLSearchParams(window.location.search).get("product_state");
        let step_no = '0';
        if(onboarding){
            if (product_state != undefined) {
                step_no = '4'
            } else {
                step_no = '14';
            }
            if(product_state != undefined){
                product_state_string = '&product_state='+product_state
            }
            props.history.push({
                pathname: '/home',
                search: `?step_no=${step_no}&room_state=added${product_state_string}`
            }); 
        } else {
            if(props.redirectionPath){
                props.history.push({
                    pathname: props.redirectionPath
                });  
            } else {
                props.history.push({
                    pathname: '/home'
                });  
            }
        }
       
    }

    const formItems =
        <Form.Item
            name = "reference_urls"
            className='form-category'
            label ={<span className='manrope f-14 black-00 w-700'>Reference URL(s)&nbsp;<Tooltip title={<span className='manrope f-12 white'>Please type public URL(s) and press 'Enter' - if the link is only available to you via a login, please take a screen shot and upload to Raw Files section on coming steps.</span>}><InfoCircleOutlined/></Tooltip></span>}
            style={{marginTop:"10px"}}
            colon={false}
            required={false}>
            {getFieldDecorator(`reference_urls`, {
                rules: [
                    {
                        message: "Please add reference urls",
                        type: 'array'
                    }
                ]
            })(
            <Select className='manrope f-10 black-55 selection-popup' mode="tags" style={{color:"#333333"}} placeholder="Please enter Reference URL(s) that you would like us to have a look at for your product model">
                <Option className='manrope f-10 black-55' value=""></Option>
            </Select>)}
        </Form.Item>;
    return (
        <div>
            {props.roomReducer.isLoading? <div className="text-center"> <LoadingOutlined spin /> </div>: "" }
            <Form className="input-form" onSubmit={handleSubmit}>
                <Row gutter={16}>
                    <Col span={12} style={{marginTop:"10px"}}>
                            <Form.Item name="room_name" label = {<span className='manrope f-14 black-00 w-700'>Name</span>} colon={false}>
                                {getFieldDecorator('room_name', {
                                    rules: [{ required: true, message: 'Please input space name' }],
                                })(
                                    <Input className='manrope f-10 black-55'
                                        placeholder="Please type your space name"
                                    />
                                )}
                            </Form.Item>
                    </Col>
                    <Col span={12} style={{marginTop:"10px"}}>
                        <Form.Item name="brand_id" label={(<span className='manrope f-14 black-00 w-700'>ID &nbsp;<Tooltip title={<span className='manrope f-12 white'>Any unique identifier if you have</span>}><InfoCircleOutlined /></Tooltip></span>)} colon={false}>
                            {getFieldDecorator('brand_id', {
                                rules: [{ required: false, message: 'Please input brand space ID' }],
                            })(
                                <Input className='manrope f-10 black-55'
                                    placeholder="Please type your brand space ID"
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{marginTop: '10px'}}>
                        <Form.Item className='form-category' name="project"  label={(<span className='manrope f-14 black-00 w-700'>Projects&nbsp;</span>)} colon={false}>
                            {getFieldDecorator('project', {
                                rules: [{ required: false, message: 'Please select project.' }],
                            })(
                                <Select
                                    className="manrope f-10 black-55"
                                    showSearch
                                    placeholder="Select or type in project name"
                                >
                                    {Object.keys(projects).map((key) => (
                                        <Select.Option
                                            key={projects[key].id}
                                            className="manrope f-10 select-view"
                                            value={projects[key].id}
                                        >
                                            {projects[key].name}
                                        </Select.Option>
                                        ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{marginTop:"10px"}}>
                        <Form.Item  className='form-category' name="tags" label={(<span className='manrope f-14 black-00 w-700'>Tags&nbsp;<Tooltip title={<span className='manrope f-10 white'>Searchable tags that you want to associate with your space</span>}><InfoCircleOutlined /></Tooltip></span>)}  colon={false}>
                            {getFieldDecorator('tags', {
                                rules: [
                                    { type: 'array' }, { required: false, message: "Please enter space tags"}
                                ],
                            })(
                                <Select notFoundContent={null} mode="tags" className='manrope f-10 black-55' placeholder="Add space tags">
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{marginTop:"10px", paddinRight: 10}}>
                        <Form.Item  className='form-category' name="style" label={(<span className='manrope f-14 black-00 w-700'>Style&nbsp;</span>)}  colon={false}>
                            {getFieldDecorator('style', {
                                rules: [
                                    { required: false, message: "Please enter space style"}
                                ],
                            })(
                                <Select showSearch={true} className='manrope f-10 black-55' placeholder="Please select space style or type and press enter to add new style">
                                    {FileConstants.SPACE_STYLES.map((style) => {
                                        return <Option className='manrope f-10 black-55' value={style}>{style}</Option> 
                                    })}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={12} style={{marginTop:"10px"}}>
                        <Form.Item name="category"  className='form-category' label={<span className='manrope f-14 black-00 w-700'>Category</span>} colon={false}>
                            {getFieldDecorator('category', {
                                rules: [{ required: true, message: 'Please select a category' }],
                            })(
                                <Select
                                showSearch={true}
                                className={`manrope f-10 black-55`}
                                disabled={savedForm && props.roomReducer.room.variant_of}
                                placeholder="Search and select your space category"
                                >
                                    {spaceCategories && spaceCategories.map((category,index) => {
                                        return <Option className="manrope f-10 black-55 select-view" value={category}>{category}</Option>

                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    
                    <Col span={24}>
                        {formItems}
                    </Col>

                    <Col span={24} style={{marginTop:"10px"}}>
                        <Form.Item  name="guidelines" label={<span className='manrope f-14 black-00 w-700'>Guidelines/ Comments</span>} colon={false} style={{height: "auto !important"}}>
                            {getFieldDecorator('guidelines', {
                            })(
                                <Input.TextArea rows={3} className='manrope f-10 black-55'
                                    placeholder = "Enter any guidelines or comments you want us to follow"
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{display:"flex",justifyContent:"space-between",marginTop: '15px'}}>
                        <Form.Item>
                            <Button onClick={handleRedirection} className="modal-okay-gray square font-12">
                                Cancel
                            </Button>
                        </Form.Item>
                        <Form.Item className="justify-buttons">
                            <Button onClick={() => setSubmitType('save')} className="modal-okay-gray square font-12" htmlType="submit" style={{marginRight: '5px'}}>
                            Save Draft & Exit {props.roomReducer.isLoading && submitType == 'save'?     <LoadingOutlined spin />: ''}
                            </Button>
                            <Button onClick={() => setSubmitType('continue')} className="modal-okay-green square font-12" htmlType="submit">
                                Continue {props.roomReducer.isLoading && submitType == 'continue'? <LoadingOutlined spin />: ''}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

const RoomInformation = Form.create({ name: 'room_info' })(RoomInformationForm);


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    createRoom: (room) => {
        dispatch(createRoom(room));
    },
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(RoomInformation)
)