import React, { useEffect, useState } from "react";
import {
    Magnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
} from "react-image-magnifiers";

const ImageMagnify = (props) => {
    const [image, setImage] = useState(null);
    const [className, setClassName] = useState('');


    useEffect(() => {
        if (image != null) {
            setImageStyle(props.index);
        }
    }, [props, image, className]);


    useEffect(() => {
        if (props.image) {
            let class_value = props.asset_viewer_modal ? `product-carousel-image-${props.index}` : 'zoomed-image';
            setClassName(class_value);
            setImage(props.image);
        }

    }, [props.image]);


    const setImageStyle = (index = 0) => {
        let class_value = props.asset_viewer_modal ? `product-carousel-image-${index}` : 'zoomed-image';
        let height_value = props.asset_viewer_modal ? "calc(100vh - 140px)" : "calc(100vh - 270px)";
        if (props.asset_viewer_modal) {
            let elements = document.querySelectorAll(`[class^="product-carousel-image-"]`);
            if (elements && elements.length > 0) {
                elements.forEach((element) => {
                    if (element != null) {
                        addStylesToAttributes(element, height_value);
                    }
                });
            }
        }
        else {
            let element = document.querySelector(`.${class_value}`);
            if (element != null) {
                addStylesToAttributes(element, height_value);
            }
        }

    }


    const addStylesToAttributes = (element, height_value) => {
        if (element.childNodes[0].style.cursor == "default") {
            element.childNodes[0].style.cursor = "zoom-in";
        }
        if (element.childNodes[0].firstChild.style.width == "100%") {
            element.childNodes[0].firstChild.style.width = navigator.userAgent.includes('Macintosh') ? height_value : "auto";
            element.childNodes[0].firstChild.style.height = height_value;
            element.childNodes[0].firstChild.style.objectFit = "scale-down";
            if (props.asset_viewer_modal) {
                element.childNodes[0].firstChild.style.margin = "auto";
            }
            if (props.product_qa_viewer) {
                element.childNodes[0].firstChild.style.margin = "auto";
                element.childNodes[0].firstChild.style.width = "100%";
            }
        }
    }


    return (
        <React.Fragment>
            <div>
                {(image != null) ?
                    <Magnifier
                        className={className}
                        imageSrc={image}
                        crossorigin="anonymous"
                        largeImageSrc={image}
                        mouseActivation={MOUSE_ACTIVATION.SINGLE_CLICK}
                        touchActivation={TOUCH_ACTIVATION.SINGLE_TAP}
                        dragToMove={false}
                    />
                    :
                    ""
                }
            </div>
        </React.Fragment>
    );

}

export default ImageMagnify;