import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import ENVIRONMENT from "../../../../environments";
import {
  LeftOutlined,
  RightOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Card,
  Tooltip,
  Carousel,
} from "antd";
import CustomerMainLayout from "../CustomerMainLayout";
import Header from "../SpaceComponents/Header";
import SpaceInfo from "../SpaceComponents/SpaceInfo";
import SpaceActions from "../SpaceComponents/SpaceActions";
import SpaceInfoCollapse from "../SpaceComponents/SpaceInfoCollapse";
import SpaceVariants from "../SpaceComponents/SpaceVariants";
import SpaceAssetViewer from "../SpaceComponents/SpaceAssetsViewer";
import "../SpaceComponents/SpaceComponents.scss";
import SpaceActionBar from "../SpaceComponents/SpaceActionBar";
import AssetPreviewGrid from "../SpaceComponents/AssetPreviewGrid";
import ReferenceInfoSection from "../SpaceComponents/ReferenceInfo";
import StoreActions from "../SpaceComponents/StoreAction";
import SameBrandOtherSpaces from "../SpaceComponents/SameBrandOtherSpaces";
import EditSpace from "../SpaceComponents/EditSpace";
import EntityAccessDenied from "../EntityAccessDenied/EntityAccessDenied";
import { EditOutlined } from "@ant-design/icons";

const SpaceDetailsView = (props) => {
  const companyId = localStorage.getItem("company_id");
  const [, updateState] = React.useState();
  const [imageUrl, setImageUrl] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [showDetailedRejection, setShowDetailedRejection] = useState(false);
  const [countRoomDimensions, setCountRoomDimensions] = useState(-1);
  const [sharingEmails, setSharingEmails] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [display360, setDisplay360] = useState(true);
  const [showButtomBar, setShowBottomBar] = useState(false);
  const [selected360, setSelected360] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  const [displayDetailType, setDisplayDetailType] = useState(null);

  const scrollref = useRef();

  const assetsCollection = useMemo(() => {

    console.log('OUTSIDE VIEW1 : ',props.roomDetails.outside_view);
    console.log('OUTSIDE VIEW2 : ',props.roomDetails.outside_view_custom);
    return props.roomDetails
      ? [
          ...props.roomDetails.floorplan,
          ...props.roomDetails.video,
          ...props.roomDetails.room_photos,
          ...props.roomDetails.raw_files,
          ...props.roomDetails.outside_view_custom,
          ...(Array.isArray(props.roomDetails.outside_view)
            ? [...props.roomDetails.outside_view]
            : [props.roomDetails.outside_view]),
        ]
      : [];
  }, [props.roomDetails]);


  useEffect(()=>{
    console.log('ASSETS COLLECTION: ',assetsCollection);
  },[assetsCollection])

  useEffect(() => {
    if (
      props.roomDetails.validation_report &&
      props.roomDetails.validation_report.High &&
      props.roomDetails.validation_report.High.room_dimensions &&
      Object.keys(props.roomDetails.validation_report.High.room_dimensions)
        .length > 0
    ) {
      setCountRoomDimensions(
        Object.keys(props.roomDetails.validation_report.High.room_dimensions)
          .length
      );
    }
  }, [props]);

  // Function to handle the scroll event
  const handleScroll = () => {
    // Define the scroll threshold at which you want the bottom bar to appear
    let buttonElement = document.getElementById("space-actions-container");
    if (buttonElement) {
      let rect = buttonElement.getBoundingClientRect();

      const scrollThreshold = rect.top; // Adjust this value as needed
      if (scrollThreshold < 0) {
        setShowBottomBar(true);
      } else {
        setShowBottomBar(false);
      }
    }
  };

  const handleImage = (event) => {
    event.preventDefault();
    setImageUrl(event.currentTarget.getAttribute("href"));
    setShowImage(true);
  };

  let rejections_data = "";
  if (props.rejectionDetails && props.rejectionDetails.length > 0) {
    console.log(props.rejectionDetails, "rejection data");
    let ele = props.rejectionDetails[0];
    rejections_data = (
      <div style={{ marginTop: 20 }}>
        <div
          className="manrope f-12 black-55 align-text"
          style={{ marginBottom: 8 }}
        >
          Date{" "}
          <span style={{ color: "#333333" }}>
            {moment(ele.qa_time, "YYYY-MM-DD HH:mm:ss")
              .toDate()
              .toLocaleString("en-us") || "-"}
          </span>
        </div>
        <div style={{ display: "flex" }}>
          <Col
            span={
              ele.reference_files != [] &&
              ele.reference_files != undefined &&
              typeof ele.reference_files == "object" &&
              Object.keys(ele.reference_files).length != 0
                ? 16
                : 24
            }
          >
            <span style={{ paddingRight: 5, overflow: "hidden" }}>
              <span
                className="manrope f-12 black-55"
                style={{ textAlign: "left", display: "block", marginBottom: 4 }}
              >
                Comment
              </span>
              <span style={{ display: "flex" }}>
                <span
                  className="manrope f-12 black-33"
                  style={{
                    marginBottom: 0,
                    width: "90%",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "left",
                  }}
                >
                  {ele.rejection_details}
                </span>
              </span>
            </span>
          </Col>

          {ele.reference_files != [] &&
          ele.reference_files != undefined &&
          typeof ele.reference_files == "object" &&
          Object.keys(ele.reference_files).length != 0 ? (
            <Col span={8}>
              <div
                className="category-artist"
                style={{ textAlign: "left", marginBottom: 7 }}
              >
                Uploads
              </div>
              {ele.reference_files && ele.reference_files.length > 0 ? (
                <a
                  className="upload-a-settings"
                  target="_blank"
                  href={
                    ENVIRONMENT.getBaseURL(props.roomDetails["platform"]) +
                    ENVIRONMENT.BASE_URI +
                    encodeURIComponent(
                      ele.reference_files[0]["uid"] +
                        "/" +
                        ele.reference_files[0]["name"]
                    )
                  }
                >
                  {ele.reference_files[0].name.includes(".jpg") ||
                  ele.reference_files[0].name.includes(".png") ||
                  ele.reference_files[0].name.includes(".PNG") ||
                  ele.reference_files[0].name.includes(".svg") ? (
                    <img
                      className="upload-image-settings"
                      src={
                        ENVIRONMENT.getBaseURL(props.roomDetails["platform"]) +
                        ENVIRONMENT.BASE_URI +
                        encodeURIComponent(
                          ele.reference_files[0]["uid"] +
                            "/" +
                            ele.reference_files[0]["name"]
                        )
                      }
                    />
                  ) : ele.reference_files[0].name.includes(".tiff") ? (
                    <img
                      className="upload-image-settings"
                      src={"/img/tiff_icon.png"}
                    />
                  ) : (
                    <img
                      className="upload-image-settings"
                      src={"/img/ele.reference_files[0]-icon.png"}
                    />
                  )}
                  <span
                    className="category-artist"
                    style={{
                      fontSize: 14,
                      textAlign: "left",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "90%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {ele.reference_files[0].name}
                  </span>
                </a>
              ) : (
                ""
              )}
            </Col>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  let detailed_rejection_data = "";
  if (props.rejectionDetails && props.rejectionDetails.length > 0) {
    detailed_rejection_data = props.rejectionDetails.map((ele, index) => (
      <div style={{ marginTop: 2, maxWidth: "941px" }}>
        <span
          style={{ marginTop: 15, display: "block" }}
          className="note-bg-artist manrope f-12"
        >
          Date:{" "}
          <span style={{ color: "#276DD7", paddingLeft: 3, marginRight: 25 }}>
            {moment(ele.qa_time, "YYYY-MM-DD HH:mm:ss")
              .toDate()
              .toLocaleString("en-us") || "-"}
          </span>{" "}
          Activity By:{" "}
          <span style={{ marginLeft: 5, color: "#276dd7" }}>
            {ele.qa_person}
          </span>
        </span>
        {ele.reference_files != [] &&
        ele.reference_files != undefined &&
        typeof ele.reference_files == "object" &&
        Object.keys(ele.reference_files).length != 0 ? (
          <div
            style={{ display: "block", margin: 2, padding: 2, width: "100%" }}
          >
            <div>
              <span
                className="manrope f-14 black-55"
                style={{
                  textAlign: "left",
                  display: "block",
                  marginBottom: 4,
                  width: "50%",
                  float: "left",
                }}
              >
                Comments:
              </span>
              <span
                className="manrope f-14 black-55"
                style={{ marginBottom: 4, width: "50%", float: "left" }}
              >
                Uploads:
              </span>
            </div>
            <div style={{ marginBottom: 10, width: "100%" }}>
              <span
                className="manrope f-12 black-33"
                style={{ textAlign: "left", width: "51%", float: "left" }}
              >
                {ele.rejection_details}
              </span>
              <div style={{ float: "left", width: "47%" }}>
                <Carousel
                  style={{ width: "100%" }}
                  arrows={true}
                  nextArrow={<RightOutlined />}
                  prevArrow={<LeftOutlined />}
                >
                  {ele.reference_files &&
                    ele.reference_files.map((file, index) => (
                      <a
                        onClick={handleImage}
                        style={{ color: "#276DD7", lineHeight: "120%" }}
                        className="upload-a-settings"
                        target="_blank"
                        href={
                          ENVIRONMENT.getBaseURL(
                            props.roomDetails["platform"]
                          ) +
                          ENVIRONMENT.BASE_URI +
                          file["uid"] +
                          "/" +
                          file["name"]
                        }
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {file.name.includes(".jpg") ||
                          file.name.includes(".png") ||
                          file.name.includes(".PNG") ||
                          file.name.includes(".svg") ? (
                            <img
                              className="upload-image-settings-reject"
                              src={
                                ENVIRONMENT.getBaseURL(
                                  props.roomDetails["platform"]
                                ) +
                                ENVIRONMENT.BASE_URI +
                                file["uid"] +
                                "/" +
                                file["name"]
                              }
                            />
                          ) : file.name.includes(".tiff") ? (
                            <img
                              className="upload-image-settings-reject"
                              src={"/img/tiff_icon.png"}
                            />
                          ) : (
                            <img
                              className="upload-image-settings-reject"
                              src={"/img/file-icon.png"}
                            />
                          )}
                        </div>
                        <p
                          className="manrope f-12"
                          style={{ textAlign: "center", color: "#555555" }}
                        >
                          {file.name}
                        </p>
                      </a>
                    ))}
                </Carousel>
              </div>
            </div>
          </div>
        ) : (
          <div className="justify-in-start" style={{ marginTop: 4 }}>
            <span
              className="manrope f-14 black-55"
              style={{ textAlign: "left", width: "10%", float: "left" }}
            >
              Comments:
            </span>
            <span
              className="manrope f-12 black-33"
              style={{ textAlign: "left", float: "left" }}
            >
              {ele.rejection_details}
            </span>
          </div>
        )}
      </div>
    ));
  }

  let total_file_size = 0;
  let high_poly_model_size = 0,
    glb_size = 0,
    archive_size = 0;
  if (props.roomDetails.model_info != undefined) {
    high_poly_model_size =
      props.roomDetails.model_info.size_high_poly_model || 0;
    glb_size = props.roomDetails.model_info.glb_size || 0;
    total_file_size = high_poly_model_size + glb_size;
  }

  if (high_poly_model_size == 0) {
    // high poly and Vray size not found in DB so doing the head request and calculating the archive size
    props.getArchiveSize();
    archive_size = props.archiveSize / (1024 * 1024); //converting in MBs
    total_file_size = total_file_size + archive_size;
  }

  if (glb_size == 0) {
    // glb size was not found in DB so doing the head request and calculating the glb size
    props.getGlbSize();
    glb_size = props.glbSize / (1024 * 1024); // converting the size in MBs
    total_file_size = total_file_size + glb_size;
  }

  const allImagesCount = useMemo(() => {
    const threeSixtiesLength =
      props.roomDetails.three_sixties.length > 0
        ? props.roomDetails.three_sixties.length
        : 1;
    return props.roomDetails.top_view_render
      ? props?.testRendersFiltered?.length + threeSixtiesLength + 1
      : props?.testRendersFiltered?.length + threeSixtiesLength;
  }, [
    props.testRendersFiltered,
    props.roomDetails.top_view_render,
    props.roomDetails.three_sixties,
  ]);

  const inProgress= useMemo(()=>props.roomDetails?.model_status &&
  props.roomDetails?.model_status != 4 &&
  props.roomDetails?.model_status != 5,[props.roomDetails]);

  return (
    <>{ 
      props.userAccess == "restricted" ? <EntityAccessDenied entityType={"space"} />
         : <div
      id="space-main-container"
      ref={scrollref}
      onScroll={!props.storePage ? handleScroll : null}
    >
      <CustomerMainLayout selected="5" space_page={true}>
        {inProgress ? (
          <Row className="model-inprogress-box">
            <Row
              type="flex"
              style={{ justifyContent: "flex-start" }}
              className="w-100"
            >
              <Col span={12}>
                <div className={"validation-uploaded-model-warning-div"}>
                  <Card
                    title={
                      <div>
                        <ClockCircleOutlined className="validation-uploaded-model-header-warning" />
                        <span
                          className={"validation-uploaded-model-header-warning"}
                        >
                          {props.roomDetails?.model_status == -4
                            ? "Space is being fixed"
                            : "Space model is in progress"}
                        </span>
                      </div>
                    }
                  >
                    <span className="manrope f-14 validation-uploaded-model-success">
                      {props.roomDetails?.model_status == -5
                        ? "Space is currently unavailable as it is being fixed by ALL3D"
                        : "Please wait while our system is processing your model"}
                    </span>
                  </Card>
                </div>
              </Col>
            </Row>
          </Row>
        ) : (
          <></>
        )}
        {/* Header*/}

        <div
          className="space-main-container"
        >
          <Header
            isLoading={props.isLaoding}
            userAccess={props.userAccess}
            roomDetails={props.roomDetails}
            storePage={props.storePage}
            inProgress={inProgress}
            folderParentID={props.folderParentID}
            params={props.params}
            setSharingModal={props.setSharingModal}
            downloadSceneImagesArchive={props.downloadSceneImagesArchive}
            platform={props.roomDetails.platform}
            download_links={props.roomDetails.download_links}
            customer_username={props.roomDetails.customer_username}
            company_id={props.roomDetails.company_id}
            is_store_item={props.roomDetails.is_store_item}
            match={props.match}
            savedID={props.savedID}
            spaceDetails={props.spaceDetails}
            userProjects={props.userProjects}
            performStoreAction={props.performStoreAction}
            downloadImagesByType={props.downloadImagesByType}
            downloadImagesMapping={props.downloadImagesMapping}
          />

          <Row
            style={{
              display: "flex",
              marginTop: "16px",
            }}
          >
            <Col
              span={13}
              className="w-full asset-grid-height"
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <AssetPreviewGrid
                three_sixties={props.roomDetails.three_sixties}
                test_renders={props.testRendersFiltered}
                top_view={props.roomDetails.top_view_render}
                platform={props.roomDetails.platform}
                setDisplayDetailType={setDisplayDetailType}
                setSelected360={setSelected360}
                setSelectedImageUrl={setSelectedImageUrl}
                roomDetails={props.roomDetails}
                allImagesCount={allImagesCount}
              />
            </Col>
            <Col
              span={11}
              className="w-full h-full"
              style={{
                display: "flex",
                paddingTop: "40px",
                paddingLeft: "2.4rem",
              }}
            >
              <div className="w-full flex-column-flex-start">
                <div className="display-flex j-start a-baseline row flex-row mx-none w-full">
                  <Tooltip
                    title={
                      <span className="manrope f-12 white">
                        {props.roomDetails.room_name}
                      </span>
                    }
                  >
                    <h6 className='manrope f-30 black-55 limit-lines limit-2'>
                      {props.roomDetails.room_name}
                    </h6>
                  </Tooltip>
                  <span>
                  {props.roomDetails.model_status == 5 &&
                    props.storePage == 0 &&
                    props.userAccess == "edit" ? (
                      <Tooltip
                        title={
                          <span className="manrope f-12 white">
                            Edit Space Info
                          </span>
                        }
                      >
                        <EditOutlined onClick={props.openEditForm} className='edit-icon-property ml-4' />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </span>
                   
                </div>
                <SpaceInfo
                  category={props.roomDetails.category}
                  itemId={props.roomDetails.brand_id}
                  companyId={props.roomDetails.company_id}
                  companyData={props.companyData}
                />
                {!props.storePage && !inProgress ? (
                  <Row
                    style={{ marginTop: "2.5rem" }}
                    id="space-actions-container"
                  >
                    <SpaceActions 
                      roomDetails={props.roomDetails} 
                      params={props.params}  
                      savedID={props.savedID}
                      spaceDetails={props.spaceDetails}
                      userProjects={props.userProjects}/>
                  </Row>
                ) : (
                  <></>
                )}
                <Row style={{ width: "100%" }}>
                  <SpaceInfoCollapse
                    model_info={props.roomDetails.model_info}
                    performStoreAction={props.performStoreAction}
                    platform={props.roomDetails.platform}
                  />
                </Row>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              height: "1px",
              width: "100%",
              background: "#d8d8d8",
              margin: "4rem 0",
            }}
          />

          <SpaceVariants userProjects={props.userProjects} />

          {props.storePage === 1 ? (
            <SameBrandOtherSpaces
              id={props.params.id}
              company_id={props.roomDetails.company_id}
              companyData={props.companyData}
            />
          ) : (
            <></>
          )}

          <SpaceAssetViewer
            setDisplay360={setDisplay360}
            renders={props.testRendersFiltered}
            top_view={props.roomDetails.top_view_render}
            three_sixties={props.roomDetails.three_sixties}
            selectedImage={props.selectedImage}
            setFullScreen={props.setFullScreen}
            isFullScreen={props.isFullScreen}
            platform={props.roomDetails.platform}
            selected360={selected360}
            displayDetailType={displayDetailType}
            setDisplayDetailType={setDisplayDetailType}
            selectedImageUrl={selectedImageUrl}
            storePage={props.storePage}
            id={props.roomDetails.room_id}
            roomDetails={props.roomDetails}
            downloadSceneImagesArchive={props.downloadSceneImagesArchive}
          />
        </div>

        <Row
          style={{
            display: "flex",
            marginTop: "16px",
          }}
        >
          <ReferenceInfoSection
            assetsCollection={assetsCollection}
            roomDetails={props?.roomDetails}
            storePage={props.storePage}
            platform={props.roomDetails["platform"]}
            elements={props.roomDetails.elements}
          />
        </Row>
        {showButtomBar ? (
          <SpaceActionBar roomDetails={props.roomDetails} />
        ) : (
          <></>
        )}

        <EditSpace
          editForm={props.editForm}
          closeEditForm={props.closeEditForm}
          buttonLoader={props.buttonLoader}
          roomDetails={props.roomDetails}
          saveSpaceData={props.saveSpaceData}
          spaceCategories={props.spaceCategories}
          errorMessage={props.errorMessage}
        />

        <StoreActions
          is_store_item={props.is_store_item}
          setRemoveFromStore={props.setRemoveFromStore}
          setSellLoader={props.setSellLoader}
          setRemoveLoader={props.setRemoveLoader}
          setSellVisible={props.setSellVisible}
          removeFromStore={props.removeFromStore}
          removeLoader={props.removeLoader}
          sellVisible={props.sellVisible}
          price={props.price}
          sellLoader={props.sellLoader}
          cancelSellModal={props.cancelSellModal}
          roomDetails={props.roomDetails}
          room_id={props.params.id}
          onChangePrice={props.onChangePrice}
        />
      </CustomerMainLayout>
      {props.roomDetails.model_status &&
      props.roomDetails.model_status != 4 &&
      props.roomDetails.model_status != 5 ? (
        <div
          style={{
            background: "#FFFFFF",
            boxShadow: "0px -8px 25px rgba(0, 0, 0, 0.04)",
            position: "absolute",
            bottom: "2px",
            right: 0,
            backgroundColor: "#D8D8D8",
            padding: "20px",
            borderRadius: "5px",
          }}
          className="bottom-bar"
        >
          <Row type="flex" style={{ justifyContent: "flex-end" }}>
            <h4></h4>
            <div>
              <Button
                disabled={true}
                type="default"
                size="large"
                className="inprogress-btn"
              >
                <div className="accept-btn-text red">In Progress</div>
              </Button>
            </div>
          </Row>
        </div>
      ) : (
        ""
      )}
    </div>}
    </>
   
  );
};

export default SpaceDetailsView;
