import React, { Component } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import {
  PasswordReset,
} from 'react-cognito';
import { Switch } from 'react-router';

import SpaceDetails from "./CustomerComponents/SpaceDetails";
import Dashboard from './Dashboard';
import RegisterForm from './AuthComponents/RegisterForm';
import ChangePasswordForm from './AuthComponents/ChangePasswordForm';
import UpdateEmailForm from './AuthComponents/UpdateEmailForm';
import PasswordResetForm from './AuthComponents/PasswordResetForm';
import MSProviderPrivateRoute from '../../routes/MSProviderPrivate';
import CustomerPrivateRoute from '../../routes/CustomerPrivate';
import AdminPrivateRoute from '../../routes/AdminPrivate';
import SuperAdminPrivateRoute from '../../routes/SuperAdminPrivate';
import ArtistPrivateRoute from '../../routes/ArtistPrivate';
import TestArtistPrivateRoute from '../../routes/TestArtistPrivate';
import AgencyAdminPrivateRoute from '../../routes/AgencyAdminPrivate';
import SuperArtistPrivateRoute from '../../routes/SuperArtistPrivate';
import MyProductsView from './CustomerComponents/MyProducts';
import RoomDetails from './CustomerComponents/RoomDetails';
import AdminRoomDetails from './AdminComponents/RoomDetails';
import MyRoomsView from './CustomerComponents/MyRooms';
import CreateRoom from './CustomerComponents/CreateRoom';
import EmbedProductDetail from './ProductDetailsComponent/EmbedProductDetail';
import DesignRoom from './CustomerComponents/DesignRoom';
import SceneCreatorWrapper from './CustomerComponents/SceneCreator';
import SceneCollaboratorWrapper from './CustomerComponents/SceneCreator/SceneCollaborator';
import SpaceTestStandalone from './CustomerComponents/SceneCreator/SpaceTestStandalone';
import SceneRenders from './CustomerComponents/SceneRenders';
import ArtistProductsView from './ArtistComponents/ArtistProducts';
import ArtistRoomsView from './ArtistComponents/ArtistRooms';
import ArtistProductGuidelines from './ArtistComponents/ArtistProductGuidelines';
import ArtistRoomGuidelines from './ArtistComponents/ArtistRoomGuidelines';
import ArtistJobDesk from './ArtistComponents/ArtistJobDesk';
import Analytics from './CustomerComponents/Analytics';
import AddNewProduct from './ProductForms/AddNewProduct/AddNewProduct';
import UploadProductModel from './ProductForms/UploadExistingModel/UploadProductModel';
import ProductModelRequest from './ProductForms/ProductModelRequest';
import SuperArtistComplaints from './ArtistComponents/SuperArtistComplaints';

import AddNewRoom from './CustomerComponents/AddNewRoom';
import ComingSoon from './ComingSoon';
import CustomerProducts from './CustomerComponents/CustomerProducts';
import CustomerRooms from './CustomerComponents/CustomerRooms';
import CustomerScenes from './CustomerComponents/CustomerScenes';
import ProductPLYViewer from './CustomerComponents/PLYViewer';

import ProductStaticGuidelines from './ArtistComponents/ProductStaticGuidelines';
import Store from './CustomerComponents/Store';
import AdminProductsData from './AdminComponents/AdminProducts';
import AdminProductDetails from './AdminComponents/ProductDetails';
import ArtistsList from './AdminComponents/ArtistsList';
import CustomerCollections from './CustomerComponents/CustomerCollections';
import CustomerCollectionProducts from './CustomerComponents/CustomerCollectionProducts';

import AdminJobDesk from './AdminComponents/AdminJobDesk';
import AdminScenesData from './AdminComponents/AdminScenes';
import ArtistDetails from './AdminComponents/ArtistDetails/ArtistDetails';
import ArtistInvoices from './ArtistComponents/ArtistInvoices';
import ArtistMonthDetails from './ArtistComponents/ArtistMonthDetails';
import ArtistSettings from './ArtistComponents/ArtistSettings/ArtistSettings';
import AdminRoomsData from './AdminComponents/AdminRooms/AdminRooms';
import ArtistMonthlyHours from './AdminComponents/ArtistMonthlyHours/ArtistMonthlyHours';
import ArtistMonthlyInvoice from './AdminComponents/ArtistMonthlyInvoice';
import CustomerLibrary from './CustomerComponents/CustomerLibrary';
import ScenePanoramaPublicViewer from './CustomerComponents/ScenePanoramaPublicViewer';
import AdminStore from './AdminComponents/AdminStore/AdminStore';
import ApproveScene from './CustomerComponents/ApproveScene';
import PublicProductGoogleViewer from './CustomerComponents/PublicProductGoogleViewer';
import RoomStaticGuidelines from './ArtistComponents/RoomStaticGuidelines';
import CameraControls from './CustomerComponents/CameraControls';
import AddSceneFlow from './CustomerComponents/AddSceneFlow';
import SceneDetails from './AdminComponents/SceneDetails/SceneDetails';
import ImageZoom from './CustomerComponents/ImageZoom';
import AdminCategories from './AdminComponents/AdminCategories/AdminCategories';
import AdminMaterials from './AdminComponents/AdminMaterials/AdminMaterials';
import AdminColors from './AdminComponents/AdminColors/AdminColors';
import AdminScansData from './AdminComponents/AdminScansControls/AdminScansControls';
import AddCollectionFlow from './CustomerComponents/AddCollectionFlow';
import CustomerHelp from './CustomerComponents/CustomerHelp';
import SiloPhotography from './SiloPhotography';
import SiloTool from './SiloPhotography/SiloTool';
import InvoicePayment from './CustomerComponents/InvoicePayment';
import AdminCreateInvoice from './AdminComponents/AdminCreateInvoice';
import AdminInvoiceData from './AdminComponents/AdminInvoices/AdminInvoices';
import AdminCustomersData from './AdminComponents/CustomersData/CustomersData';
import AdminTestSpace from './AdminComponents/AdminTestSpace';
import AdminComplaints from './AdminComponents/AdminComplaints';
import ComplaintDetail from './AdminComponents/ComplaintDetail/ComplaintDetail';
import PanoramaPublicViewer from './CustomerComponents/PanoramaPublicViewer';
import AutomationRequests from './AdminComponents/AutomationRequests/AutomationRequests';
import Public3DTourViewer from './CustomerComponents/Public3DTourViewer/Public3DTourViewer';
import ProductPublicViewer from './CustomerComponents/ProductPublicViewer';
import AdminRenderServices from './AdminComponents/AdminRenderServices';
import ENVIRONMENT from '../../environments';
import CustomerMaterials from './CustomerComponents/CustomerMaterials';
import UploadMaterial from './CustomerComponents/MaterialForms/MaterialUpload';
import ArtistMaterialGuidelines from './ArtistComponents/ArtistMaterialGuidelines';
import AdminMaterialGuidelines from './AdminComponents/AdminMaterialGuidelines';
import AdminMaterialJobs from './AdminComponents/AdminMaterialJobs';
import ArtistMaterials from './ArtistComponents/ArtistMaterials';
import ConfiguratorTool from './Configurator';
import AdminSceneRenders from './AdminComponents/AdminSceneRenders';
import StoreRoomDetails from './CustomerComponents/StoreRoomDetails';
import MaterialRequest from './CustomerComponents/MaterialForms/MaterialRequest';
import CustomerAccountUsage from './CustomerComponents/CustomerAccountUsage';
import DesignerData from './AdminComponents/DesignerData';
import GetProductFiles from './AdminComponents/GetProductFiles';
import ArtistTimeLog from './AdminComponents/ArtistTimeLog';
import AccountInvite from './AdminComponents/AccountInvite/AccountInvite';
import SaveCardFlow from './CustomerComponents/SaveCardFlow';
import SaveCardCallback from './CustomerComponents/SaveCardCallback';
import AccountDetails from './CustomerComponents/AccountDetails/AccountDetails';
import CustomerUsageQuota from './CustomerComponents/CustomerUsageQuota';
import CardSaveWindow from './CardSaveWindow';
import CreateProdutModel from './ProductForms/CreateProductModel/CreateProdutModel';
import AdminTemplateRenders from './AdminComponents/AdminTemplateRenders';
import CustomerTemplateRenders from './CustomerComponents/CustomerTemplateRenders/CustomerTemplateRenders';
import AdminSystemUsage from './AdminComponents/AdminSystemUsage/AdminSystemUsage';
import CustomerSystemUsage from './CustomerComponents/CustomerSystemUsage/CustomerSystemUsage';
import AdminAnalytics from './AdminComponents/Analytics/Analytics';
import CustomerReports from './CustomerComponents/CustomerReports/CustomerReports';
import RegisterArtist from './AuthComponents/RegisterArtist/RegisterArtist';
import TestArtistMainLayout from './NewArtistTestComponents/TestArtistMainLayout/TestArtistMainLayout';
import ArtistAssignment from './NewArtistTestComponents/ArtistAssignment/ArtistAssignment';
import ArtistAssignmentResults from './NewArtistTestComponents/ArtistAssignmentResults/ArtistAssignmentResults';
import ArtistTestDetails from './AdminComponents/ArtistTestDetails/ArtistTestDetails';
import TestArtistJobDesk from './NewArtistTestComponents/TestArtistJobDesk/TestArtistJobDesk';
import TestModelDetails from './NewArtistTestComponents/TestModelDetails/TestModelDetails';
import AgencyDetails from './AgencyDetails/AgencyDetails'
import ArtistGuidelines from './ArtistComponents/ArtistGuidelines/ArtistGuidelines';
import SkylineBulkUpload from './CustomerComponents/SkylineBulkUpload/SkylineBulkUpload';
import BulkConfiguration from './CustomerComponents/BulkConfiguration/BulkConfiguration'
import CollaborateTool from './CustomerComponents/CollaborateTool/CollaborateTool';
import GoogleUploadTest from './CustomerComponents/GoogleUploadTest/GoogleUploadTest';
import CustomerCollaborations from './CustomerComponents/CustomerCollaborations/CustomerCollaborations';
import ProductCustomerPage from './CustomerComponents/ProductCustomerPage/ProductCustomerPage';
import ProductQAPage from './CustomerComponents/ProdcutQAPage/ProductQAPage';
import SystemManagement from './AdminComponents/SystemManagement/SystemManagement';
import UserManagement from './AdminComponents/UserManagement/UserManagement';
import NotificationManagement from './AdminComponents/NotificationManagement/NotificationManagement';
import AdminHome from './AdminComponents/AdminHome/AdminHome';
import AddProductLifestyleFlow from './CustomerComponents/AddProductLifestyleFlow';
import ArtistTestAssessmentTable from './AdminComponents/ArtistTestAssessmentTable/ArtistTestAssessmentTable';
import DashboardProjects from './CustomerComponents/DashboardProjects';
import CustomerProject from './CustomerComponents/CustomerProject/CustomerProject';
import AddNewProject from './CustomerComponents/AddNewProject/AddNewProject';
import LibraryProjects from './CustomerComponents/LibraryProjects';
import AddNewSceneFlow from './CustomerComponents/AddNewSceneFlow/AddNewSceneFlow';
import ProductQAPageRedirect from './CustomerComponents/ProductQAPageRedirect/ProductQAPageRedirect';
import OtherProductPageRedirect from './CustomerComponents/OtherProductPageRedirect/OtherProductPageRedirect';
import ProjectsHome from './CustomerComponents/ProjectsHome/ProjectsHome';
import ActiveModels from './CustomerComponents/ActiveModels/ActiveModels';
import AmazonOauth from './CustomerComponents/AmazonOauth';
import SalesFunnel from './AdminComponents/AdminSalesFunnel/SalesFunnel';
import EntityUpdate from './AdminComponents/EntityUpdate';
import Intergrations from './CustomerComponents/Integrations/Integrations';
import TermsSigningPage from './TermsSigningPage';

const changePassword = () => (
  <div>
    <ChangePasswordForm />
  </div>
);

const updateEmail = () => (
  <div>
    <UpdateEmailForm />
  </div>
);

const passwordReset = () => (
  <PasswordReset>
    <PasswordResetForm />
  </PasswordReset>
);

const registerForm = () => (
  <div>
    <RegisterForm />
  </div>
);


class App extends Component {
  render() {
    return (

      <Router>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/register" component={registerForm} />
          <Route exact path="/reset" component={passwordReset} />
          <Route exact path="/change_password" component={changePassword} />
          <Route exact path="/change_email" component={updateEmail} />
          <CustomerPrivateRoute exact path="/products" component={MyProductsView} />
          <CustomerPrivateRoute exact path="/product-qa/:id" component={ProductQAPageRedirect} />

          <CustomerPrivateRoute exact path="/products/:id" component={ProductCustomerPage} />
          <CustomerPrivateRoute exact path="/product-assets/:id" component={ProductCustomerPage} />
          <CustomerPrivateRoute exact path="/inprogress-product/:id" component={OtherProductPageRedirect} />


          <CustomerPrivateRoute exact path="/rooms/:id" component={SpaceDetails} />
          <CustomerPrivateRoute exact path="/room-assets/:id" component={SpaceDetails} />
          <CustomerPrivateRoute exact path="/room-qa/:id" component={RoomDetails} />
          <CustomerPrivateRoute exact path="/room" component={MyRoomsView} />
          <CustomerPrivateRoute exact path="/rooms" component={CustomerRooms} />
          <CustomerPrivateRoute exact path="/tags" component={ComingSoon}/>

          <CustomerPrivateRoute exact path="/store" component={Store} />
          <CustomerPrivateRoute exact path="/store/:brand" component={Store} />

          <CustomerPrivateRoute exact path="/modeling" component={ComingSoon} />
          <CustomerPrivateRoute exact path="/about" component={ComingSoon} />
          <CustomerPrivateRoute exact path="/create_room" component={CreateRoom} />
          <CustomerPrivateRoute exact path="/room/:id" component={CreateRoom} />
          <CustomerPrivateRoute exact path="/design_room" component={DesignRoom} />
          <CustomerPrivateRoute exact path="/add_scene" component={AddSceneFlow} />
          <CustomerPrivateRoute exact path="/scene_builder" component={SceneCreatorWrapper} />
          <CustomerPrivateRoute exact path="/scene_builder/:id" component={SceneCreatorWrapper} />
          <CustomerPrivateRoute exact path="/scene_collaborator/:id" component={SceneCollaboratorWrapper} />
          <CustomerPrivateRoute exact path="/scene_renders/:id" component={SceneRenders} />
          <CustomerPrivateRoute exact path="/collaborate-tool/:id" component={CollaborateTool} />

          <CustomerPrivateRoute exact path="/template/:id" component={CustomerTemplateRenders}/>
          <CustomerPrivateRoute exact path="/approve_scene/:id" component={ApproveScene} />
          <CustomerPrivateRoute exact path="/my_scenes" component={CustomerScenes} />
          <CustomerPrivateRoute exact path="/collaborations" component={CustomerCollaborations} />

          <CustomerPrivateRoute exact path="/analytics" component={Analytics} />
          <CustomerPrivateRoute exact path="/card-save-test" component={SaveCardFlow} />
          <CustomerPrivateRoute exact path="/card-save-complete" component={SaveCardCallback} />

          <CustomerPrivateRoute exact path="/add-new-product" component={AddNewProduct} />
          <CustomerPrivateRoute exact path="/add-new-product/:id" component={AddNewProduct} />
          <CustomerPrivateRoute exact path="/create-existing-physical-product" component={AddNewProduct} />
          <CustomerPrivateRoute exact path="/create-existing-physical-product/:id" component={AddNewProduct} />
          <CustomerPrivateRoute path="/add-new-room" component={AddNewRoom} />
          <CustomerPrivateRoute path="/list-products" component={CustomerProducts} />
          <CustomerPrivateRoute path="/library" component={CustomerLibrary} />
          <CustomerPrivateRoute path="/collection/:id/products" component={CustomerCollectionProducts} />
          <CustomerPrivateRoute path="/list-collections" component={CustomerCollections} />
          <CustomerPrivateRoute exact path="/product-ply-viewer/:id" component={ProductPLYViewer}/>
          <CustomerPrivateRoute exact path="/home" component={ProjectsHome} />
          <CustomerPrivateRoute exact path="/active-models/" component={ActiveModels} />
          <CustomerPrivateRoute exact path="/add_collection" component={AddCollectionFlow} />
          <CustomerPrivateRoute exact path="/help" component={CustomerHelp} />
          <CustomerPrivateRoute exact path="/product-model" component={ProductModelRequest} />
          <CustomerPrivateRoute exact path="/new-product-model" component={CreateProdutModel} />
          <CustomerPrivateRoute exact path="/upload-product-model" component={UploadProductModel} />
          <CustomerPrivateRoute exact path="/upload-product-model/:id" component={UploadProductModel} />
          <CustomerPrivateRoute exact path="/payment/:id" component={InvoicePayment}/>
          <CustomerPrivateRoute exact path="/silo-photography/:id" component={SiloPhotography} />
          <CustomerPrivateRoute exact path="/silo-tool/:id" component={SiloTool} />
          <CustomerPrivateRoute exact path="/material-library" component={CustomerMaterials} />
          <CustomerPrivateRoute exact path="/create-new-material" name="create-new-material" component={UploadMaterial} />
          <CustomerPrivateRoute exact path="/create-new-material/:id" component={UploadMaterial}/>
          <CustomerPrivateRoute exact path="/upload-scanned-material" name="upload-scanned-material" component={UploadMaterial} />
          <CustomerPrivateRoute exact path="/upload-scanned-material/:id" component={UploadMaterial}/>
          <CustomerPrivateRoute exact path="/material-request" component={MaterialRequest}/>
          <CustomerPrivateRoute exact path="/add-configurations/:id" component={ConfiguratorTool} />
          <CustomerPrivateRoute exact path="/account-usage" component={CustomerSystemUsage} />
          <CustomerPrivateRoute exact path="/store-rooms/:id" component={StoreRoomDetails} />
          <CustomerPrivateRoute exact path="/account-details" component={AccountDetails}/>
          <CustomerPrivateRoute exact path='/customer-usage-quota' component={CustomerUsageQuota}/>
          <CustomerPrivateRoute exact path='/save-card' component={CardSaveWindow}/>
          <CustomerPrivateRoute exact path='/bulk-product-config-create' component={SkylineBulkUpload}/>
          <CustomerPrivateRoute exact path='/bulk-configurations' component={BulkConfiguration}/>
          <CustomerPrivateRoute exact path='/google_upload' component={GoogleUploadTest} />
          <CustomerPrivateRoute exact path='/add-lifestyle/:id' component={AddProductLifestyleFlow} />
          <CustomerPrivateRoute exact path='/projects' component={DashboardProjects} />
          <CustomerPrivateRoute exact path='/projects/:id' component={CustomerProject} />
          <CustomerPrivateRoute exact path='/library-projects' component={LibraryProjects} />
          <CustomerPrivateRoute exact path='/create-scene-from-template' component={AddNewSceneFlow} />
          <CustomerPrivateRoute exact path='/create-empty-scene' component={AddNewSceneFlow} />
          <CustomerPrivateRoute exact path='/create-new-scene-from-existing-products' component={AddNewSceneFlow} />
          <CustomerPrivateRoute exact path='/add-product-to-existing-scene' component={AddNewSceneFlow} />
          <CustomerPrivateRoute exact path='/product_page/:id' component={OtherProductPageRedirect} />

          <MSProviderPrivateRoute exact path='/customer_model_report' component={CustomerReports}/>
          <MSProviderPrivateRoute exact path="/msp_products/:id" component={OtherProductPageRedirect} />
          <MSProviderPrivateRoute exact path="/msp_spaces/:id" component={RoomDetails} />
          <CustomerPrivateRoute exact path='/add_project' component={DashboardProjects} />
          <CustomerPrivateRoute exact path='/new_project' component={AddNewProject} />

          <ArtistPrivateRoute exact path="/artist_products" component={ArtistProductsView} />
          <ArtistPrivateRoute exact path="/artist_products/:id" component={ArtistProductGuidelines} />
          <ArtistPrivateRoute exact path="/artist_job_desk" component={ArtistJobDesk} />
          {/* <ArtistPrivateRoute exact path="/product_guidelines/:id" component={ProductStaticGuidelines} /> */}
          <ArtistPrivateRoute exact path="/artist_rooms" component={ArtistRoomsView} />
          <ArtistPrivateRoute exact path="/artist_rooms/:id" component={ArtistRoomGuidelines} />
          <ArtistPrivateRoute exact path="/artist_invoices" component={ArtistInvoices} />
          <ArtistPrivateRoute exact path="/artist_month_details" component={ArtistMonthDetails} />
          <ArtistPrivateRoute exact path="/artist_settings" component={ArtistSettings} />
          <ArtistPrivateRoute exact path="/artist_materials" component={ArtistMaterials} />
          <ArtistPrivateRoute exact path="/artist_materials/:id" component={ArtistMaterialGuidelines} />
          <ArtistPrivateRoute exact path="/material_guidelines/:id" component={ArtistMaterialGuidelines} />
          <ArtistPrivateRoute exact path="/artist_space_test/:id" component={SpaceTestStandalone} />

          <Route exact path="/amazon" component={AmazonOauth} />
          <Route exact path="/integrations" component={Intergrations} />
          <Route exact path="/zoom" component={ImageZoom} />
          <Route exact path="/camera_controls" component={CameraControls}/>
          <Route exact path="/sofa_google_viewer" component={PublicProductGoogleViewer}/>
          <Route exact path="/360_viewer/abIal9ok)_ApI/:id" component={ScenePanoramaPublicViewer}/>
          <Route exact path="/product_guidelines/:id" component={ProductStaticGuidelines} />
          <Route path="/product-details/:id/:username?" component={EmbedProductDetail} />
          <Route exact path="/room_guidelines/:id" component={RoomStaticGuidelines} />
          <Route exact path="/360_public_viewer/:type/:id/:area" component={PanoramaPublicViewer}/>
          <Route exact path="/3d_tour_viewer/:id" component={Public3DTourViewer} />
          <Route exact path="/product_public_viewer/:type/:id/:username?" component={ProductPublicViewer}/>
          <Route exact path='/usdz/:id' component={(props) => {
              window.location.href = (ENVIRONMENT.USDZ_BASE_URL + props.match.params.id + '.usdz');
              return null;
          }}/>
          <Route exact path='/android/:id' component={(props) => {
              window.location.href = ('/product_public_viewer/product_3d_model/' + props.match.params.id);
              return null;
          }}/>
          <Route exact path='/usdz/g/:id' component={(props) => { 
              window.location.href = (ENVIRONMENT.getBaseURL('google') + ENVIRONMENT.USDZ_BASE_URI + props.match.params.id + '.usdz'); 
              return null;
          }}/>

          {/* <AdminPrivateRoute exact path="/admin" component={AdminDashboard}/> */}
          <AdminPrivateRoute exact path="/admin" component={AdminProductsData}/>
          <AdminPrivateRoute exact path="/admin_products" component={AdminProductsData}/>
          <AdminPrivateRoute exact path="/admin_scenes" component={AdminScenesData}/>
          <AdminPrivateRoute exact path="/admin_scenes/:id" component={SceneDetails}/>
          <AdminPrivateRoute exact path="/admin_templates/:id" component={AdminTemplateRenders}/>
          <AdminPrivateRoute exact path="/admin_products/:id" component={AdminProductDetails}/>
          <AdminPrivateRoute exact path="/admin_artist_job_desk" component={AdminJobDesk}/>
          <AdminPrivateRoute exact path="/admin_artist_details/:id" component={ArtistDetails}/>
          <AdminPrivateRoute exact path="/admin_artist_time_log/:id" component={ArtistTimeLog}/>
          <AdminPrivateRoute exact path="/admin_rooms" component={AdminRoomsData}/>
          <AdminPrivateRoute exact path="/admin_rooms/:id" component={AdminRoomDetails}/>
          <AdminPrivateRoute exact path="/admin_monthly_details/:artist" component={ArtistMonthlyHours}/>
          <AdminPrivateRoute exact path="/admin_artist_invoice/:artist/:month" component={ArtistMonthlyInvoice}/>
          <AdminPrivateRoute exact path="/admin_store" component={AdminStore} />
          <AdminPrivateRoute exact path="/admin_categories" component={AdminCategories} />
          {/* <AdminPrivateRoute exact path="/admin_materials" component={AdminMaterials} /> */}
          <AdminPrivateRoute exact path="/admin_colors" component={AdminColors} />
          <AdminPrivateRoute exact path="/customer_invite" component={AccountInvite} />
          <AdminPrivateRoute exact path="/admin_invoice_create" component={AdminCreateInvoice} />
          <AdminPrivateRoute exact path="/admin_invoices" component={AdminInvoiceData} />
          <AdminPrivateRoute exact path="/admin_customers" component={AdminCustomersData} />
          <AdminPrivateRoute exact path="/msproviders" component={DesignerData} />
          <AdminPrivateRoute exact path="/get_products_file" component={GetProductFiles} />

          <AdminPrivateRoute exact path="/scans" component={AdminScansData} />
          <AdminPrivateRoute exact path="/admin_space_test/:id" component={SceneCreatorWrapper} />
          <AdminPrivateRoute exact path="/admin_product_test/:id" component={SceneCreatorWrapper} />
          <AdminPrivateRoute exact path="/admin_scene_test/:id" component={SceneCreatorWrapper} />
          <AdminPrivateRoute exact path="/complaints" component={AdminComplaints} />
          <AdminPrivateRoute exact path="/complaints/:id" component={ComplaintDetail}/>
          <AdminPrivateRoute exact path="/render_service" component={AdminRenderServices} />
          <AdminPrivateRoute exact path="/admin_scene_builder/:id" component={SceneCreatorWrapper} />
          <AdminPrivateRoute exact path="/admin_materials/:id" component={AdminMaterialGuidelines} />
          <AdminPrivateRoute exact path="/admin_materials" component={AdminMaterialJobs} />
          <AdminPrivateRoute exact path="/admin-customer-usage/:username/:company_id" component={AdminSystemUsage}/>
          <AdminPrivateRoute exact path="/admin-company-usage/:company_id" component={AdminSystemUsage}/>
          <AdminPrivateRoute exact path="/admin_agency_details/:id" component={AgencyDetails}/>
          <AdminPrivateRoute exact path="/msprovider_invite" component={AccountInvite}/>
          <AdminPrivateRoute exact path="/admin-collaborate-tool/:id" component={CollaborateTool} />

          <AdminPrivateRoute exact path="/admin_scene_renders/:id" component={AdminSceneRenders} />
          <AdminPrivateRoute exact path="/system_management" component={SystemManagement} />
          <AdminPrivateRoute exact path="/user_management" component={UserManagement} />
          <AdminPrivateRoute exact path="/notification_management" component={NotificationManagement} />
          <AdminPrivateRoute exact path="/admin_home" component={AdminHome} />

          {/* All super admin routes below */}
          <SuperAdminPrivateRoute exact path="/automation_requests" component={AutomationRequests} />
          <AdminPrivateRoute exact path="/artist_invite" component={AccountInvite} />
          <AdminPrivateRoute exact path="/admin_artist_list" component={ArtistsList}/>
          <SuperAdminPrivateRoute exact path="/admin_analytics" component={AdminAnalytics}/>
          <AdminPrivateRoute exact path="/admin_score_artist/:id" component={ArtistTestDetails} />
          <AdminPrivateRoute exact path="/admin_sales_funnel" component={SalesFunnel} />

          <SuperAdminPrivateRoute exact path="/update_entity" component={EntityUpdate} />

          {/* Super Artist Private Routes */}
          <SuperArtistPrivateRoute exact path="/artist_test_list" component={() => <ArtistTestAssessmentTable isSuperArtist={true} />} />
          <SuperArtistPrivateRoute exact path="/super_artist_score_artist/:id" component={() => <ArtistTestDetails isSuperArtist={true} />} />
          <ArtistPrivateRoute exact path="/artist_complaints" component={SuperArtistComplaints} />
          <ArtistPrivateRoute exact path="/artist_complaint/:id" component={ComplaintDetail}/>
          <ArtistPrivateRoute exact path="/artist_scene_test/:id" component={SceneCreatorWrapper} />
          <Route exact path="/terms_agreement" component={TermsSigningPage} />

          {/* Artist Test Flow and Private Routes */}
          <Route exact path="/register_as_artist" component={RegisterArtist} />
          <TestArtistPrivateRoute exact path="/artist_test" component={TestArtistMainLayout}/>
          <TestArtistPrivateRoute exact path="/artist_assignment" component={ArtistAssignment}/>
          <TestArtistPrivateRoute exact path="/artist_assignment_results" component={ArtistAssignmentResults}/>
          <TestArtistPrivateRoute exact path="/test_job_desk" component={TestArtistJobDesk}/>
          <TestArtistPrivateRoute exact path="/test_model_details/:id" component={TestModelDetails}/>

          {/* Agency Admin Links */}
          <AgencyAdminPrivateRoute exact path="/agency" component={AgencyDetails} />

          {/* Artist Guidelines and Tutorial Links */}
          <ArtistPrivateRoute exact path="/artist_guidelines" component={ArtistGuidelines} />
        </Switch>
      </Router>
    );
  }
}

export default App;