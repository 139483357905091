import React, { useState, useEffect } from 'react';
import ENVIRONMENT from '../../../environments'
import { getProductRender, listProducts, updateProduct, rejectProduct } from "../../../redux/actions";
import {
    MoreOutlined,
    LoadingOutlined,
    RightOutlined,
    CameraOutlined
} from '@ant-design/icons';
import {
    Row,
    Col,
    Radio,
    Button,
    Modal,
    InputNumber,
    Dropdown,
    Popover,
    Layout,
    Input,
    Menu,
    Card,
    Switch,
    message
} from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import AWS from "aws-sdk";
import Inspector from '../../../Inspector';
import CameraHeightWidget from '../CustomerComponents/CameraHeightWidget';
import _ from "lodash" // Import the entire lodash library
import * as Utilities from '../Utilities';
import SiloPhotographyModals from './SiloPhotographyModals';
import PaymentFailureModal from '../PaymentFailureModal/PaymentFailureModal';
import { WHITE_BG_DROPSHADOW_ON, BLIND_MOUNT_RIG, BUCKET_NAME, SILO_BASE_URL, SILO_CONFIG_JSON, ASSET_GLB_URI } from '../../../environments/env';

const { Footer } = Layout;


var inspector = null;
const SiloPhotography = (props) => {
    const { match: { params } } = props;

    const [productID, setProductID] = useState('');
    const [productInfo, setProductInfo] = useState({});
    const [modelLoaded, setModelLoaded] = useState(false);
    const [selectedAspectRatio, setSelectedAspectRatio] = useState('');
    const [snapshotModal, showSnapshotModal] = useState(false);
    const [modalShot, setModalShot] = useState('');
    const [imageHeight, setImageHeight] = useState(1);
    const [imageWidth, setImageWidth] = useState(1);
    const [imageResArr, setImageResArr] = useState([]);
    const [perspective, setPerspective] = useState(0);
    const [successModal, setSuccessModal] = useState(false);
    const [customWRes, setCustomWRes] = useState(1);
    const [customHRes, setCustomHRes] = useState(1);
    const [camera, setCamera] = useState([]);
    const [cameraIndex,setCameraIndex] = useState(1);
    const [summaryModal,showSummaryModal] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [imageFormat,setImageFormat] = useState('jpg');
    const [dpi,setDpi] = useState('300');
    const [fovSwitch, setFovSwitch] = useState('fov');
    const [resolution, setResolution] = useState('1024x640');
    const [resolutionStatus,setResolutionStatus] = useState(3);
    const [snapshotArray, setSnapshotArray] = useState([]);
    const [editResFlag, setEditResFlag] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [orientation, setOrientation] = useState('Landscape');
    const [perspectiveLabels, setPerspectiveLabels] = useState([]);
    const [perspectiveImages, setPerspectiveImages] = useState([]);
    const [loadingProgress,setLoadingProgress] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [panToggle, setPanToggle] = useState(false);
    const [panChanged,setPanChanged] = useState(false);
    const [cameraSelected, setCameraSelected] = useState(false);
    const [cameraAngle, setCameraAngles] = useState(false);
    const [siloRequestLoader, setSiloRequestLoader] = useState(false);
    const [sliderPos,setSliderPos] = useState(300);
    const [orientationClicked, setOrientationClicked] = useState(false);
    const [aspectClicked,setAspectClicked] = useState(false);
    const [settingDone,setSettingDone] = useState(false);
    const [resetConfiguration, setResetConfiguration] = useState({});
    const [aspectRatioModal, setAspectRatioModal] = useState(false);
    const [selectedCameraName, setSelectedCameraName] = useState('');
    const [background, setBackground] = useState(1);
    const [shadows, setShadows] = useState(false);
    const [lightRig, setLightRig] = useState('white-bg-shadow-off');
    const [defaultChecked, setDefaultChecked] = useState(true);
    const [defaultLightRigCase, setDefaultLightRigCase] = useState('');
    const [popoverDefault, setPopoverDefault] = useState(false);
    const [goBackConfirmModal, setGoBackConfirmModal] = useState(false);
    const [fovValue, setFovValue] = useState(20);
    const [fovRange, setFovRange] = useState({'min': 20, 'max': 80})
    const [focalLengthRange, setFocalLengthRange] = useState({'min': 10, 'max': 100})
    const [focalLengthValue, setFocalLengthValue] = useState(100);
    const [gridState, setGridState] = useState(false);
    const [perspectiveCount, setPerspectiveCount] = useState(0);
    const [defaultSiloState, setDefaultSiloState] = useState(false);
    const [defaultSettingsState, setDefaultSettingsState] = useState(false);
    const [defaultSiloIndex, setDefaultSiloIndex] = useState(-1);
    const [defaultSilosModal, showDefaultSilosModal] = useState(false);
    const [configurationData, setConfigurationData] = useState(null);
    const [modelType, setModelType] = useState(null);
    const [defaultStartIndex, setDefaultStartIndex] = useState(0);
    const [confirmRequestState, setConfirmRequestState] = useState(false);
    const [swatchRenderExist, setSwatchRenderExist] = useState(false);
    const [blindImages, setBlindImages] = useState([])
    const [blindPerspectives, setBlindPerspectives] = useState([0])
    const insideMount = "Inside Mount";
    const outsideMount = "Outside Mount";
    const blindMount = "Mount";
    const [editCapturedDefaultSilos, setEditCapturedDefaultSilos ] = useState(false);
    const [requestBlindRenders, setRequestBlindRenders] = useState(false);
    const [blindRendersState, setBlindRendersState] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [overlaySettingsModal, setOverlaySettingsModal] = useState(false);
    const [tempSnapshotOverlay, setTempSnapshotOverlay] = useState('');
    const [overlaySwitch, setOverlaySwitch] = useState(true);
    const [overlayOpacity, setOverlayOpacity] = useState(50);
    const [overlayColor, setOverlayColor] = useState('#500000');
    const [overlayHeight, setOverlayHeight] = useState(100);
    const [overlayWidth, setOverlayWidth] = useState(100);
    const [overlaySizeUnit, setOverlaySizeUnit] = useState('pixels');
    const [overlayLimit, setOverlayLimit] = useState(200);
    const [defaultOverlaySettings, setDefaultOverlaySettings] = useState(true);
    const [dropShadows, setDropShadows] = useState(false);
    const [enableDefaultShadows, setEnableDefaultShadows] = useState(false);
    const [dropShadowShot, setDropShadowsShot] = useState('')
    const [regularShadowShot, setRegularShadowsShot] = useState('')
    const [isFloorItem, setIsFloorItem] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [cardPaymentFailure, setCardPaymentFailure] = useState(false);
    const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
    const [siloPayload, setSiloPayload] = useState(null);
    const [paymentRequestID, setPaymentRequestID] = useState(-1);
    const [requestType, setRequestType] = useState('product_silo');
    const [prices, setPrices] = useState([]);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [subscriptionLoader, setSubscriptionLoader] = useState(false);
    const [currentPackage, setCurrentPackage] = useState('');
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [confirmRequestSilo, setConfirmRequestSilo] = useState(false);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [defaultLoader, setDefaultLoader] = useState(false)
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [initialSiloData, setInitialSiloData] = useState([]);
    const [platform, setPlatform] = useState('aws');

    // ******************************** silo Presets ********************************

    useEffect(()=> {
        if (inspector != null) {
            for (let i = 0; i < perspectiveCount ; i++) {
                setPerspective(i);
                inspector.changePerspectives(i,setPerspectiveButton);
                setTriggerUpdate(!triggerUpdate);
            }
            inspector.enableAxes();
            setLoadingProgress(false);
            if (getCookie("silo-help")) {
                setIsTourOpen(false);
            }
            else {
                setIsTourOpen(true);
            }
        }
        window.addEventListener("resize", SetOverlayLines);
    },[perspectiveCount]);

    useEffect(()=>{
        if(overlayHeight > overlayLimit){
            setOverlayHeight(overlayLimit)
        }
        if(overlayWidth > overlayLimit){
            setOverlayWidth(overlayLimit)
        }
        SetOverlayLines();
    })


    const identifyOrientation = (aspectRatio) => {
        if (aspectRatio == "16:10" || aspectRatio == "16:9" || aspectRatio == "1.91:1") {
            return "Landscape";
        }
        else if (aspectRatio == "9:16" || aspectRatio == "10:16" || aspectRatio == "1:1.91") {
            return "Portrait";
        }
        else if (aspectRatio == "10:10") {
            return "Square";
        }
        return "Custom";

    }

    const showTempOverlayLines = () => {
        if(overlaySwitch){
            var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
            for (var i = 0 ; i < overlay_lines.length ; i++){
                overlay_lines[i].style.display = "inherit";
            }
        }
    }

    const setOverlayLimitFunc = (value = 'default') => {
        if(value=='default'){
            if(overlaySizeUnit == 'pixels'){
                var image_width =  (resolution.split('x')[0]);
                var image_height = (resolution.substring(resolution.indexOf('x') + 1));
                setOverlayLimit(Math.min(image_width/2,image_height/2))
            }
            else{
                setOverlayLimit(100)
            }
        }
        else{
            if(value == 'pixels'){
                var image_width =  (resolution.split('x')[0]);
                var image_height = (resolution.substring(resolution.indexOf('x') + 1));
                setOverlayLimit(Math.min(image_width/2,image_height/2))
            }
            else{
                setOverlayLimit(100)
            }
        }
    }

    const SetOverlayLines = () => {
        try{
            var canvas_image = document.getElementById('silo-inspector').getElementsByTagName('canvas')[0]
            if(overlayHeight > overlayLimit){
                setOverlayHeight(overlayLimit)
            }
            if(overlayWidth > overlayLimit){
                setOverlayWidth(overlayLimit)
            }

            if(canvas_image && overlaySwitch){

                var image_width =  (resolution.split('x')[0]);
                var image_height = (resolution.substring(resolution.indexOf('x') + 1));
                //Setting top and bottom of top bottom overlay
                document.getElementById('overlay-blur-top').style.top = canvas_image.offsetTop +'px'
                document.getElementById('overlay-blur-top').style.left = canvas_image.offsetLeft +'px'
                document.getElementById('overlay-blur-bottom').style.left = canvas_image.offsetLeft +'px'

                //setting width of top and bottom
                document.getElementById('overlay-blur-top').style.width = canvas_image.getBoundingClientRect().width + "px"
                document.getElementById('overlay-blur-bottom').style.width = canvas_image.getBoundingClientRect().width  + "px"

                //Setting left and right of left right overlay
                document.getElementById('overlay-blur-left').style.left = canvas_image.offsetLeft +'px'
                document.getElementById('overlay-blur-right').style.right = canvas_image.offsetLeft +'px'


                //handling case for in case of pixels
                if (overlaySizeUnit == 'pixels'){
                    //for lines on top and bottom we just set the height
                    document.getElementById("overlay-blur-top").style.height = (canvas_image.getBoundingClientRect().height/image_height*  overlayHeight) + "px";
                    document.getElementById("overlay-blur-bottom").style.height = (canvas_image.getBoundingClientRect().height/image_height* overlayHeight) + "px";

                    var height_of_top = 2* document.getElementById("overlay-blur-top").getBoundingClientRect().height;

                    //for lines on left and right, first we adjust their position and height, according to the lines on top and bottom
                    document.getElementById("overlay-blur-left").style.height = (canvas_image.getBoundingClientRect().height - height_of_top) + "px"
                    document.getElementById("overlay-blur-left").style.top = (canvas_image.getBoundingClientRect().height/image_height* overlayHeight) +"px";
                    document.getElementById("overlay-blur-right").style.height = (canvas_image.getBoundingClientRect().height - height_of_top) + "px"
                    document.getElementById("overlay-blur-right").style.top = (canvas_image.getBoundingClientRect().height/image_height* overlayHeight) +"px";

                    //then we adjust their width
                    document.getElementById("overlay-blur-left").style.width = (canvas_image.getBoundingClientRect().width/image_width* overlayWidth) + "px"
                    document.getElementById("overlay-blur-right").style.width = (canvas_image.getBoundingClientRect().width/image_width* overlayWidth) + "px"
                }
                else{
                    //for lines on top and bottom we just set the height
                    document.getElementById("overlay-blur-top").style.height = canvas_image.getBoundingClientRect().height * overlayHeight /200 + "px";
                    document.getElementById("overlay-blur-bottom").style.height = canvas_image.getBoundingClientRect().height * overlayHeight /200 + "px    ";

                    var height_factor = canvas_image.getBoundingClientRect().height * overlayHeight /200
                    //for lines on left and right, first we adjust their position and height, according to the lines on top and bottom
                    document.getElementById("overlay-blur-left").style.height = canvas_image.getBoundingClientRect().height-(2*height_factor) +"px"
                    document.getElementById("overlay-blur-left").style.top = document.getElementById('overlay-blur-top').getBoundingClientRect().height + "px"
                    document.getElementById("overlay-blur-right").style.height = canvas_image.getBoundingClientRect().height-(2*height_factor) +"px"
                    document.getElementById("overlay-blur-right").style.top = document.getElementById('overlay-blur-top').getBoundingClientRect().height + "px"

                    //then we adjust their width
                    document.getElementById("overlay-blur-left").style.width =  canvas_image.getBoundingClientRect().width * overlayWidth /200 + "px";
                    document.getElementById("overlay-blur-right").style.width =  canvas_image.getBoundingClientRect().width * overlayWidth /200 + "px";
                }
                document.getElementById('overlay-blur-bottom').style.top = canvas_image.offsetTop + canvas_image.getBoundingClientRect().height - document.getElementById("overlay-blur-top").getBoundingClientRect().height +'px'
            }

        }
        catch{
            console.log("Window resize error")
        }
    }

    const setTempOverlayLines = () => {
        if (defaultOverlaySettings) {
            applyOverlaySwitch(true)
            applyOverlayWidth(overlayWidth);
            applyOverlayHeight(overlayHeight);
            applyOverlayColor(overlayColor)
            setDefaultOverlaySettings(false)
        }
        if(document.getElementById('overlay-temp-top')){
            //DEFINING SOME VARIABLES TO BE USED
            var height_of_top_bottom = document.getElementById('overlay-temp-top').getBoundingClientRect().height;
            var bottom_offset =  document.getElementsByClassName('img-container')[0].getBoundingClientRect().height - document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().height
            var right_offset = document.getElementsByClassName('img-container')[0].getBoundingClientRect().width - document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().width

            //setting width
            document.getElementById('overlay-temp-top').style.width = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width + "px";
            document.getElementById('overlay-temp-bottom').style.width = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width + "px";

            //setting height
            document.getElementById('overlay-temp-right').style.height = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height - (2*height_of_top_bottom) + "px"
            document.getElementById('overlay-temp-left').style.height = document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height - (2*height_of_top_bottom) + "px"

            //setting top
            document.getElementById('overlay-temp-right').style.top = height_of_top_bottom + "px"
            document.getElementById('overlay-temp-left').style.top = height_of_top_bottom + "px"

            //setting bottom and right
            document.getElementById('overlay-temp-bottom').style.bottom = bottom_offset +"px"
            document.getElementById('overlay-temp-right').style.right = right_offset +"px"
        }
    }

    const OpenOverlaySettingsModal = () => {
        var img_data = inspector.getSceneRender().domElement.toDataURL();
        setOverlaySettingsModal(true);
        setTempSnapshotOverlay(img_data);
        showTempOverlayLines();
        setOverlayLimitFunc();
    }

    const handleApplyOverlaySettings = (e) => {
        SetOverlayLines();

        var overlay_lines = document.getElementsByClassName("overlay-blur");
        console.log("Check", overlayHeight, overlayWidth)
        if(overlaySwitch == true){
            for (var i = 0 ; i < overlay_lines.length ; i++) {
                overlay_lines[i].style.display = 'inherit';
                overlay_lines[i].style.opacity = 1 - overlayOpacity/100;
                overlay_lines[i].style.background = overlayColor;
            }

        }
        else{
            for (var i = 0 ; i < overlay_lines.length ; i++) {
                overlay_lines[i].style.display = 'none'
            }
        }
        setOverlaySettingsModal(false)
    }

    const onChangeOverlayOpacity = (value) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            overlay_lines[i].style.opacity = 1 - value/100;
        }
        setOverlayOpacity(value)

    }

    const applyOverlayColor = (value) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            overlay_lines[i].style.background = value;
        }
    }

    const onChangeOverlayColor = (value) => {
        applyOverlayColor(value);
        setOverlayColor(value)
    }

    const applyOverlayHeight = (value) => {
        if(overlaySizeUnit == 'pixels'){
            var canvas_image = document.getElementById('silo-inspector').getElementsByTagName('canvas')[0]
            var image_height = (resolution.substring(resolution.indexOf('x') + 1));
            var factor_height_pixels = document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().height / document.getElementById('silo-inspector').getBoundingClientRect().height ;


            //changing the height of lines on top and bottom
            //changing the height of lines on top and bottom
            document.getElementById("overlay-temp-top").style.height = factor_height_pixels*canvas_image.getBoundingClientRect().height/image_height*value +"px";
            document.getElementById("overlay-temp-bottom").style.height = factor_height_pixels*canvas_image.getBoundingClientRect().height/image_height*value +"px";

        }
        else{

            //calculating the height of top,bottom lines
            var height_of_snapshot = parseInt(document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().height*value/100);

            //for lines on top and bottom we set the height
            document.getElementById("overlay-temp-top").style.height = height_of_snapshot/2 +"px";
            document.getElementById("overlay-temp-bottom").style.height = height_of_snapshot/2 +"px";

        }
    }

    const onChangeOverlayHeight = (value) => {
        if(value<overlayLimit){
            applyOverlayHeight(value);
            setTempOverlayLines();
            setOverlayHeight(value)
        }
    }

    const onChangeOverlayUnit = (e) => {
        setOverlaySizeUnit (e.target.value)
        setOverlayLimitFunc(e.target.value)
        if(overlayWidth>100)
            setOverlayWidth(100)
        if(overlayHeight>100)
            setOverlayHeight(100)
    }

    const applyOverlayWidth = (value) => {
        if(overlaySizeUnit == 'pixels'){
            var canvas_image = document.getElementById('silo-inspector').getElementsByTagName('canvas')[0]
            var image_width =  (resolution.split('x')[0]);

            var factor_width_pixels =  document.getElementsByClassName('snapshot-overlay-img')[0].getBoundingClientRect().width / document.getElementById('silo-inspector').getBoundingClientRect().width ;

            //changing the height of lines on top and bottom
            document.getElementById("overlay-temp-right").style.width = factor_width_pixels*canvas_image.getBoundingClientRect().width/image_width*value +"px";
            document.getElementById("overlay-temp-left").style.width = factor_width_pixels*canvas_image.getBoundingClientRect().width/image_width*value +"px";
        }
        else{

            //calculating the height of left,right lines
            var width_of_snapshot = parseInt(document.getElementsByClassName("snapshot-overlay-img")[0].getBoundingClientRect().width*value/100);

            //for lines on left and right we just set the width
            document.getElementById("overlay-temp-right").style.width = width_of_snapshot/2 +"px";
            document.getElementById("overlay-temp-left").style.width = width_of_snapshot/2 +"px";

        }
    }

    const onChangeOverlayWidth = (value) => {
        if(value<overlayLimit){
            applyOverlayWidth(value)
            setTempOverlayLines();
            setOverlayWidth(value)
        }
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'save_card') {
              message.success('Payment Method Updated Successfully.');
              snapshotTaken();
            }
        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
        if(child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);
        }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    const defaultRequested = () => {
        let silo_cameras = inspector.getSilosInformation()
        silo_cameras = silo_cameras.silo_data
        let categories = []
        silo_cameras.map((cam)=> {
            if (cam.image_status == 'pending') {
                categories.push( returnPrice((cam.image_data.img_resolution),'category' ))
            }
        })
        setSelectedCategories(categories);
        loadUserRequestStatus(categories,null,true)
    }

    const applyOverlaySwitch = (checked) => {
        var overlay_lines = document.getElementsByClassName("overlay-temp-blur");
        for (var i = 0 ; i < overlay_lines.length ; i++){
            if(checked)
                overlay_lines[i].style.display = "inherit";
            else
            overlay_lines[i].style.display = "none";
        }
    }

    const setShowOverlaySwitch = (checked) => {
        applyOverlaySwitch(checked)
        setOverlaySwitch(checked);
        setTempOverlayLines()
    }

    const handleDiscardOverlaySettings = () =>{
        setOverlaySettingsModal(false)
    }

    const identifyResolutionStatus = (resolution) => {

        if (resolution == imageResArr[2]) {
            return 3
        }
        if (resolution == imageResArr[1]) {
            return 2
        }
        if (resolution == imageResArr[0]) {
            return 1
        }

        return 4;
    }

    const readCameraConfigurationData = () => {
        if (configurationData == null) {
            return;
        }
        let defaultOrientation = null;
        if (configurationData[modelType]["aspect_ratio"]) {
            let aspectRatio = selectedAspectRatio;
            if (typeof configurationData[modelType]["aspect_ratio"] === 'string' || configurationData[modelType]["aspect_ratio"] instanceof String) {
                aspectRatio = parseFloat(configurationData[modelType]["aspect_ratio"].split(':')[0]) / parseFloat(configurationData[modelType]["aspect_ratio"].substring(configurationData[modelType]["aspect_ratio"].indexOf(':') + 1));
            }
            defaultOrientation = identifyOrientation(configurationData[modelType]["aspect_ratio"]);
            selectOrientation(defaultOrientation);
            selectAspectRatio(configurationData[modelType]["aspect_ratio"],defaultOrientation);
            inspector.changeOrientation(configurationData[modelType]["aspect_ratio"], "Custom");
            setSettingDone(true);
        }
        if (configurationData[modelType]["resolution"]) {
            let width = 0;
            let height = 0;
            if (typeof configurationData[modelType]["resolution"] === 'string' || configurationData[modelType]["resolution"] instanceof String) {
                width = configurationData[modelType]["resolution"].split('x')[0];
                height = configurationData[modelType]["resolution"].substring(configurationData[modelType]["resolution"].indexOf('x') + 1);
            }
            setCustomWRes(parseFloat(width));
            setCustomHRes(parseFloat(height));
            setImageWidth(parseFloat(width));
            setImageHeight(parseFloat(height));
            setResolutionStatus(identifyResolutionStatus(configurationData[modelType]["resolution"]));
            setResolution(configurationData[modelType]["resolution"]);
        }
        if (configurationData[modelType]["default_rig"]) {
            setLightRig(defaultLightRigCase);
            setDefaultChecked(true);
        }
        if (configurationData[modelType]["dropShadows"]!=undefined) {
            setDropShadows(configurationData[modelType]["dropShadows"]);
            setDefaultChecked(false);
        }
        else if (configurationData[modelType]["shadows"]!=undefined) {
            setShadows(configurationData[modelType]["shadows"]);
            setDefaultChecked(false);
        }
        if (configurationData[modelType]["background"]) {
            setDefaultBackground(configurationData[modelType]["background"]);
            setDefaultChecked(false);
        }
        if (configurationData[modelType]["horizontal_fov"]!=undefined) {
            setFovValue(configurationData[modelType]["horizontal_fov"]);
            inspector.setHorizontalFOV(configurationData[modelType]["horizontal_fov"]);
            inspector.setDefaultHorizontalFOV(configurationData[modelType]["horizontal_fov"]);
            setFocalLengthValue(inspector.getCameraFocalLength())
        }
        if (configurationData[modelType]["focal_length"]) {
            setFocalLengthValue(configurationData[modelType]["focal_length"])
            inspector.setFocalLength(configurationData[modelType]["focal_length"])
            let horizontalFOV = inspector.getHorizontalFOV()
            setFovValue(horizontalFOV);
            inspector.setDefaultHorizontalFOV(horizontalFOV);
        }
        if (configurationData[modelType]["dpi"]) {
            setDpi(configurationData[modelType]["dpi"]);
        }

    }

    const setDefaultBackground = (value) => {
        if (value == "white" ) {
            setBackground(1);
            return 1;
        }
        else if (value == "grey" ) {
            setBackground(2);
            return 2;
        }
        else {
            setDefaultChecked(true);
            return 0;
        }
    }

    const resetPaymentsOnCancel = () => {
        if (defaultSiloState) {
            setSelectedCategories([]);
        }
    }

    const captureDefaultSilos = () => {
        for (let i = 0; i < perspectiveCount; i++) {
            changePerspective(i);
            inspector.changePerspectives(i);
            takeSnapshotsForDefaults(i, false, false);
        }
        changePerspective(0);
        editNextDefaultSilo();
        showDefaultSilosModal(true);

    }

    const editDefaultSilos = () => {
        for (let i = 0; i < perspectiveCount - blindPerspectives; i++) {
            changePerspective(i);
            takeSnapshotsForDefaults(i, true, false);
        }
        changePerspective(defaultSiloIndex)
        setModalShot(snapshotArray[defaultSiloIndex]["image_captured"])
        readCameraConfigurationData();
        if (blindPerspectives > 0) {
            if (defaultSiloIndex < (perspectiveCount - blindPerspectives))
            {
                if (editCapturedDefaultSilos == false) {
                    setDefaultSettingsState(false)
                }
                let camera_name = "camera_default_" + (defaultSiloIndex + defaultStartIndex);
                changeToEditModal(camera_name);
            }
            if (defaultSiloIndex == perspectiveCount - 2) {
                changeToEditModal(insideMount);
                setDefaultSettingsState(true)

            }
            else if (defaultSiloIndex == perspectiveCount - 1) {
                changeToEditModal(outsideMount);
                setDefaultSettingsState(true)
            }

        }

    }

    const editSingleDefaultSilo = (camera_index) => {
        takeSnapshotsForDefaults(camera_index, false, true);
    }

    const editNextDefaultSilo = (update = true) => {
        if (!defaultSettingsState) {
            if (defaultSiloIndex >= 0 && !defaultSettingsState) {
                editSingleDefaultSilo(defaultSiloIndex);
            }
        }
        if (update && (defaultSiloIndex + 1) < perspectiveCount){
            setPerspective(defaultSiloIndex + 1);
            setDefaultSiloIndex(defaultSiloIndex + 1);
        }
        rightPerspectiveScroll();

    }

    const editSelectedDefaultSilo = (cameraIndex) => {
        editSingleDefaultSilo(defaultSiloIndex);
        setPerspective(cameraIndex);
        setDefaultSiloIndex(cameraIndex);

    }

    const editPreviousDefaultSilo = () => {
        if (!defaultSettingsState && !defaultSettingsState) {
            editSingleDefaultSilo(defaultSiloIndex);
        }
        if (defaultSiloIndex > 0) {
            setPerspective(defaultSiloIndex - 1);
            setDefaultSiloIndex(defaultSiloIndex - 1);
        }
        leftPerspectiveScroll();
    }

    const takeSnapshotsForDefaults = async(cameraIndex, defaultSettingsState , editFlag = false) => {
        if ( configurationData[modelType]['images'][cameraIndex]['category'] == "blind") {
            blindSnapshotTaken(configurationData[modelType]['images'][cameraIndex]['display_name'], true);
            return;
        }
        let imageWidthTemp = 0;
        let imageHeightTemp = 0;
        let newRes = '';
        let resolution_value = '';
        if (resolutionStatus == 4 && orientation != 'Custom'){
            imageWidthTemp = customWRes;
            imageHeightTemp = customHRes;
            newRes= customWRes + 'x' + customHRes;
            inspector.setResolution(newRes);
            resolution_value = newRes;
        }
        else if (orientation == 'Custom') {
            imageWidthTemp = imageWidth;
            imageHeightTemp = imageHeight;
            newRes= imageWidth + 'x' + imageHeight;
            inspector.setResolution(newRes);
            resolution_value = newRes;
        }
        else {
            imageWidthTemp = resolution.split('x')[0];
		    imageHeightTemp = resolution.substring(resolution.indexOf('x') + 1);
            inspector.setResolution(resolution);
            resolution_value = resolution;
        }

        imageWidthTemp = parseFloat(imageWidthTemp);
        imageHeightTemp = parseFloat(imageHeightTemp);
        inspector.setHorizontalFOV(fovValue);
        inspector.setImgDPI(dpi);
        inspector.setImgFormat(imageFormat);
        inspector.disableAxes();
        var img_data = inspector.getSceneRender().domElement.toDataURL();
        inspector.enableAxes();
        let resolutionL = resolution_value;
        let shadowsL = shadows;
        let dropShadowsL = dropShadows;
        let backgroundL = background;
        let lightRigL = lightRig;
        let dpiL = dpi;
        let aspectRatioL = selectedAspectRatio;
        let fovL = fovValue;
        let defaultCheckedL = defaultChecked;
        let orientationL = orientation;
        let resolution_status = resolutionStatus;
        if (defaultSettingsState == true) {
            if (configurationData[modelType]["aspect_ratio"]) {
                orientationL = identifyOrientation(configurationData[modelType]["aspect_ratio"]);
                aspectRatioL = configurationData[modelType]["aspect_ratio"];
            }
            if (configurationData[modelType]["resolution"]) {
                if (typeof configurationData[modelType]["resolution"] === 'string' || configurationData[modelType]["resolution"] instanceof String) {
                    imageWidthTemp = parseFloat(configurationData[modelType]["resolution"].split('x')[0]);
                    imageHeightTemp = parseFloat(configurationData[modelType]["resolution"].substring(configurationData[modelType]["resolution"].indexOf('x') + 1));
                }
                resolutionL = configurationData[modelType]["resolution"];
                if (orientationL != "Custom") {
                    resolution_status = identifyResolutionStatus(resolutionL);
                }
            }
            if (configurationData[modelType]["default_rig"]) {
                defaultCheckedL = true;
                lightRigL = defaultLightRigCase;
            }
            if (configurationData[modelType]["shadows"]!=undefined) {
                shadowsL = configurationData[modelType]["shadows"];
                defaultCheckedL = false;
                lightRigL = getLightRig(shadowsL, backgroundL);
            }
            if (configurationData[modelType]["dropShadows"]!=undefined) {
                dropShadowsL = configurationData[modelType]["dropShadows"];
                defaultCheckedL = false;
                lightRigL = getLightRig(shadowsL, backgroundL, dropShadowsL);
            }
            if (configurationData[modelType]["background"]) {
                backgroundL = setDefaultBackground(configurationData[modelType]["background"]);
                defaultCheckedL = false;
                lightRigL = getLightRig(shadowsL, backgroundL, dropShadowsL);
            }
            if (configurationData[modelType]["horizontal_fov"]!=undefined) {
                fovL = configurationData[modelType]["horizontal_fov"];
                inspector.setHorizontalFOV(fovL);
            }
            if (configurationData[modelType]["focal_length"]) {
                inspector.setFocalLength(configurationData[modelType]["focal_length"])
            }
            if (configurationData[modelType]["dpi"]) {
                dpiL = configurationData[modelType]["dpi"];
            }
            hideShadows();
            if ( (!defaultCheckedL && dropShadowsL) || (defaultCheckedL && defaultLightRigCase == WHITE_BG_DROPSHADOW_ON)) {
                showShadows();
            }
            inspector.disableAxes();
            img_data = inspector.getSceneRender().domElement.toDataURL();
            inspector.enableAxes();
            var camera_index = cameraIndex;
            var camera_name = "camera_default_" + (camera_index + defaultStartIndex);
            camera[cameraIndex]['resolution'] = resolutionL;
            camera[cameraIndex]['thumbnail'] = img_data;
            snapshotArray[cameraIndex]['orientation'] = orientationL;
            snapshotArray[cameraIndex]['image_format'] = imageFormat;
            snapshotArray[cameraIndex]['selected_dpi'] = dpiL;
            snapshotArray[cameraIndex]['resolution_status'] = resolution_status;
            snapshotArray[cameraIndex]['selected_resolution'] = resolutionL;
            snapshotArray[cameraIndex]['image_width'] = imageWidthTemp;
            snapshotArray[cameraIndex]['image_height'] = imageHeightTemp;
            snapshotArray[cameraIndex]['aspect_ratio'] = aspectRatioL;
            snapshotArray[cameraIndex]['rig_name'] = lightRigL;
            snapshotArray[cameraIndex]['fov'] = fovL;
            snapshotArray[cameraIndex]['default_rig'] = defaultCheckedL;
            snapshotArray[cameraIndex]['image_captured'] = img_data;
            snapshotArray[cameraIndex]['captured_by'] = localStorage.getItem("username");
            uploadSiloPreview(params.id, snapshotArray[cameraIndex]['camera_name'] + '_preview', img_data);
            inspector.edit3DMaxValues(camera_name,resolutionL,imageFormat,dpiL,aspectRatioL, imageWidthTemp ,imageHeightTemp,lightRigL,fovL);

        }
        else if (editFlag) {
            var camera_index = cameraIndex;
            var camera_name = "camera_default_" + (camera_index + defaultStartIndex);
            inspector.setResolution(resolutionL);
            inspector.disableAxes();
            img_data = inspector.getSceneRender().domElement.toDataURL();
            inspector.enableAxes();
            if (defaultCheckedL) {
                lightRigL = defaultLightRigCase;
            }
            else {
                lightRigL = getLightRig(shadowsL, backgroundL, dropShadowsL);
            }
            camera[cameraIndex]['resolution'] = resolutionL;
            camera[cameraIndex]['thumbnail'] = img_data;
            snapshotArray[cameraIndex]['orientation'] = orientationL;
            snapshotArray[cameraIndex]['image_format'] = imageFormat;
            snapshotArray[cameraIndex]['selected_dpi'] = dpiL;
            snapshotArray[cameraIndex]['resolution_status'] = resolution_status;
            snapshotArray[cameraIndex]['selected_resolution'] = resolutionL;
            snapshotArray[cameraIndex]['image_width'] = imageWidthTemp;
            snapshotArray[cameraIndex]['image_height'] = imageHeightTemp;
            snapshotArray[cameraIndex]['aspect_ratio'] = aspectRatioL;
            snapshotArray[cameraIndex]['rig_name'] = lightRigL;
            snapshotArray[cameraIndex]['captured_by'] = localStorage.getItem("username");
            snapshotArray[cameraIndex]['fov'] = fovL;
            snapshotArray[cameraIndex]['default_rig'] = defaultCheckedL;
            snapshotArray[cameraIndex]['image_captured'] = img_data;
            uploadSiloPreview(params.id, snapshotArray[cameraIndex]['camera_name'] + '_preview', img_data);
            inspector.edit3DMaxValues(camera_name,resolutionL,imageFormat,dpiL,aspectRatioL, imageWidthTemp ,imageHeightTemp,lightRigL,fovL);

        }
        else {
            if ( (!defaultCheckedL && dropShadowsL) || (defaultCheckedL && defaultLightRigCase == WHITE_BG_DROPSHADOW_ON)) {
                showShadows();
            }
            var camera_index = cameraIndex;
            var camera_name = "camera_default_" + (camera_index + defaultStartIndex);
            addCamera(camera_name,img_data,'',resolution_value);
            uploadSiloPreview(params.id, camera_name + '_preview', img_data);

            let imgElement = {
                orientation: orientationL,
                resolution_status: resolution_status,
                camera_name: camera_name,
                camera_index: camera_index,
                image_format: imageFormat,
                selected_dpi: dpiL,
                selected_resolution: resolution_value,
                image_width: imageWidthTemp,
                image_height: imageHeightTemp,
                aspect_ratio: aspectRatioL,
                image_captured: img_data,
                rig_name: lightRigL,
                fov: fovL,
                default_rig: defaultCheckedL,
                captured_by: localStorage.getItem('username')
            }
            inspector.get3DMaxValues(resolution_value, camera_name, 'pending',lightRigL);
            setSnapshotArray(oldArray => [...oldArray, imgElement]);

        }
    }

    const leftPerspectiveScroll = () => {
        var elem = document.getElementById('default-silo-scrollable');
        var snapshotLength = document.getElementById('default-silo-snapshot');
        if (snapshotLength) {
            elem.scrollLeft -= snapshotLength.offsetWidth ;
        }

    }

    const rightPerspectiveScroll = () => {
        var elem = document.getElementById('default-silo-scrollable');
        var snapshotLength = document.getElementById('default-silo-snapshot');
        if (snapshotLength) {
            elem.scrollLeft += snapshotLength.offsetWidth ;
        }
    }

    const setSiloState = () => {
        if (modelType != "system") {
            setDefaultSiloState(true);
        }
        else {
            setDefaultSiloState(false);
        }
    }

    const showShadows = () => {
        if (inspector != null) {
            inspector.showShadows();
            inspector.disableAxes();
            let img_data = inspector.getSceneRender().domElement.toDataURL();
            setModalShot(img_data);
            inspector.enableAxes();
        }
    }

    const hideShadows = () => {
        if (inspector != null ) {
            inspector.hideShadows();
            inspector.disableAxes();
            let img_data = inspector.getSceneRender().domElement.toDataURL();
            setModalShot(img_data);
            inspector.enableAxes();
        }
    }

    useEffect(() => {
        if (props.productReducer.product.category != undefined && props.productReducer.product.customer_username != undefined) {
        axios.post(ENVIRONMENT.CUSTOMER_SILO_CONFIG, {"username" : props.productReducer.product.customer_username})
            .then(res => {
                let loadDefaultSettings = false;
                if (res.data.settings) {
                    let configData = res.data.settings;
                    let modelType = null;
                    // configData = require("../../../SiloConfig.json");
                    setDefaultSiloState(true);
                    if (configData[props.productReducer.product.category] != undefined) {
                        modelType = props.productReducer.product.category
                        setModelType(props.productReducer.product.category);
                        setConfigurationData(configData);
                        if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                            setBlindPerspectives(2);
                            setRequestBlindRenders(true);
                        }
                    }
                    else if (configData["default"] != undefined) {
                        modelType = "default"
                        setModelType("default");
                        setConfigurationData(configData);
                        if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                            setBlindPerspectives(2);
                            setRequestBlindRenders(true);
                        }
                    }
                    else if (props.productReducer.product.category.toLowerCase().includes("shade") && configData["Shades"] != undefined) {
                        modelType = "Shades"
                        setModelType(modelType);
                        setConfigurationData(configData);
                        if (configData[modelType]["blinds"] && configData[modelType]["blinds"] == true) {
                            setBlindPerspectives(2);
                            setRequestBlindRenders(true);
                        }
                    }
                    else {
                        loadDefaultSettings = true;
                    }
                }
                else {
                    loadDefaultSettings = true;
                }

                if (loadDefaultSettings) {
                    setDefaultSiloState(false);
                    async function loadDataFromConfigFile(){
                        const util = require('util')
                        const request = require("request");
                        const requestPromise = util.promisify(request);
                        const response = await requestPromise(SILO_CONFIG_JSON);
                        let configData = JSON.parse(response.body);
                        let polarAngleLimits = configData["polar_angle_limits"];
                        delete configData["polar_angle_limits"];
                        let data = {};
                        data["system"] = configData;
                        data["system"]["resolution"] = resolution;
                        data["system"]["aspect_ratio"] = "16:10";
                        data["system"]["default_rig"] = true;
                        data["polar_angle_limits"] = polarAngleLimits;
                        setConfigurationData(data);
                        setModelType("system");
                    }
                    loadDataFromConfigFile();
                }
            })
        }

    },[props.productReducer.product.category, props.productReducer.product.customer_username]);

    useEffect(() => {
        if (configurationData != null && inspector != null && modelType != null && modelLoaded != false) {
            readCameraConfigurationData();
            inspector.addPerspectives(configurationData, modelType);
            changePerspective(0)
            setIsFloorItem(inspector.checkIsFloorItem());
            inspector.showShadows();
            inspector.disableAxes();
            setDropShadowsShot(inspector.getSceneRender().domElement.toDataURL());
            inspector.enableAxes();

            inspector.hideShadows();
            inspector.disableAxes();
            setRegularShadowsShot(inspector.getSceneRender().domElement.toDataURL());
            inspector.enableAxes();

            if (enableDefaultShadows) {
                inspector.showShadows();
            }

        }
    },[configurationData, modelType, modelLoaded]);


    useEffect(() => {
        if (!defaultSettingsState) {
            if (defaultSiloIndex >= perspectiveCount - blindPerspectives) {
                setDefaultSettingsState(true)
            }
        }
    },[defaultSettingsState])

    useEffect(() => {
        if (editCapturedDefaultSilos) {
            setDefaultSettingsState(true);
            editDefaultSilos();
        }
        else {
            setDefaultSettingsState(false);
        }
    },[editCapturedDefaultSilos])

    useEffect(()=> {
        if (snapshotArray.length == 0) {
            setSiloState();
            setEditCapturedDefaultSilos(false);
            showSummaryModal(false);
        }
    },[snapshotArray]);

    useEffect(()=> {
        if (inspector != null && defaultSiloIndex != -1) {
            if (defaultSiloIndex < (perspectiveCount - blindPerspectives))
            {
                if (editCapturedDefaultSilos == false) {
                    setDefaultSettingsState(false)
                }
                let camera_name = "camera_default_" + (defaultSiloIndex + defaultStartIndex);
                changeToEditModal(camera_name);
            }
            else if (defaultSiloIndex == perspectiveCount - 2) {
                changeToEditModal(insideMount);
                setDefaultSettingsState(true)

            }
            else if (defaultSiloIndex == perspectiveCount - 1) {
                changeToEditModal(outsideMount);
                setDefaultSettingsState(true)

            }
        }
    },[defaultSiloIndex]);

    useEffect(()=> {
        if (summaryModal == false && defaultSiloState && confirmRequestState == false ){
            setCamera([]);
            setSnapshotArray([]);
            setBlindImages([]);
            setBlindRendersState([]);
            setSiloState();
            if (inspector != null) {
                inspector.removeAllDefaultSilos(defaultStartIndex);
            }
        }
    },[summaryModal]);

    useEffect(() => {

        if (successModal) {
            setCamera([]);
            setSnapshotArray([]);
            setBlindImages([]);
            setSiloState();
            setEditCapturedDefaultSilos(false);
            setConfirmRequestState(false);
            setBlindImages([]);
        }

    }, [successModal]);

    useEffect(()=> {
        if (inspector != null && !defaultSilosModal) {
            setDefaultSiloIndex(-1);
            setEditCapturedDefaultSilos(false);
            let element = document.getElementById("default-settings-checkbox")
            if (element) {
                element.checked = false;
            }
            readCameraConfigurationData();
            changePerspective(0);
        }
        if (defaultSilosModal == false && summaryModal == false && confirmRequestState == false){
            setCamera([]);
            setSnapshotArray([]);
            setBlindImages([]);
            setSiloState();
            setEditCapturedDefaultSilos(false);
            if (inspector != null) {
                inspector.removeAllDefaultSilos(defaultStartIndex);
            }
        }
    },[defaultSilosModal]);


    // ******************************** silo Presets ********************************

    useEffect(()=> {
        setProductID(params.id);
        loadUserRequestStatus(null,null);
    },[]);

    const loadUserRequestStatus = (request_category = null, confirm_request = null, default_silos = false) => {
        let payload = {
        "username": localStorage.getItem('username'),
        "action": "check",
        'request_type': 'product_silo',
        'categories': ['1k']
        }

        if (request_category != null) {
            payload.categories = request_category;
        }

        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then(res => {
            console.log('USERCHECK',res)
            setRequestStatus([res.data['request_allowed']]);
            setCurrentPackage(res.data['subscription_plan']);
            setIsSubscriptionActive(res.data['is_subscription_active'])
            setCardStatus(res.data['card_status']);
            loadPayments(res.data['payment_type']);
            if (confirmRequestSilo != null) {
                setConfirmRequestSilo(true);
            }
            if (default_silos == true){
                setDefaultLoader(false)
                showSummaryModal(true)
                showDefaultSilosModal(false)
            }
        });
    }

    const loadPayments = (payment_type) => {
        let payload = {
            "request_type": 'product_silo',
            "payment_type": payment_type,
        }

        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
        .then(res => {
            if (res.data) {
                setPrices(res.data);
            }
        })
    }


    const setPerspectiveSrc = (imagesCount) => {
        setPerspectiveCount(imagesCount);
    }

    useEffect(() => {
        getLightRig(shadows, background, dropShadows);
    },[shadows,background,dropShadows,defaultChecked,defaultLightRigCase]);


    const getLightRig = (shadows, background, dropShadow = false) => {
        if (defaultChecked) {
            if (defaultLightRigCase == WHITE_BG_DROPSHADOW_ON) {
                showShadows();
            }
            else {
                hideShadows();
            }
            setLightRig(defaultLightRigCase)
            return defaultLightRigCase;
        }
        else if (dropShadow == true && background == 1) {
            showShadows();
            setLightRig("white-bg-dropShadow-on")
            return "white-bg-dropShadow-on"
        }
        else if (shadows == false && background == 1) {
            hideShadows();
            setLightRig("white-bg-shadow-off");
            return "white-bg-shadow-off";
        }
        else if (shadows == false && background == 2) {
            hideShadows();
            setLightRig("grey-bg-shadow-off");
            return "grey-bg-shadow-off"
        }
        else if (shadows == true && background == 1) {
            hideShadows();
            setLightRig("white-bg-shadow-on");
            return "white-bg-shadow-on";
        }
        else if (shadows == true && background == 2) {
            hideShadows();
            setLightRig("grey-bg-shadow-on");
            return "grey-bg-shadow-on"
        }
    }

    const changeLightRig = (value) => {
        if (value == "white-bg-shadow-off") {
            setShadows(false);
            setDropShadows(false);
            setBackground(1);
        }
        else if (value == "grey-bg-shadow-off") {
            setShadows(false);
            setDropShadows(false);
            setBackground(2);
        }
        else if (value == "white-bg-shadow-on") {
            setShadows(true);
            setDropShadows(false);
            setBackground(1);
        }
        else if (value == "grey-bg-shadow-on") {
            setShadows(true);
            setDropShadows(false);
            setBackground(2);
        }
        else if (value == "white-bg-dropShadow-on") {
            setDropShadows(true);
            setShadows(false);
            setBackground(1);
        }
        else {
            setDefaultChecked(true);
        }
    }

    const getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    const setCookie =(cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


    const setPerspectiveButton = (index) => {
        inspector.disableAxes();
        let  imgElement= inspector.sceneRenderer.domElement.toDataURL();
        setPerspectiveImages(oldArray => [...oldArray, imgElement]);
        setPerspectiveLabels(inspector.getViewNames());
        if (index == (perspectiveCount-1)) {
            changePerspective(0);
        }
    }

    useEffect(() => {
        var product_id = params.id;
        var placementType = null;
        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                let company_id = null;
                if(res.data['company_id'] != undefined) {
                    let current_company = localStorage.getItem('company_id');
                    if (current_company === res.data['company_id'].toString()){
                        company_id = res.data['company_id'].toString();
                        setCompanyId(company_id)
                    }
                }
                if (res.data['placement_type'] != undefined) {
                    placementType = res.data['placement_type'];
                }
                loadProductAssets(company_id, placementType);
            });
    },[]);

    const loadProductAssets = (company_id, placementType) => {
        var product_id = params.id;

        let payload = { product_id: product_id, username: localStorage.getItem("username")};
        if (company_id != null) {
            payload.company_id = company_id
        }

        axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
            .then(res => {
                setProductInfo(res.data);
                console.log('&',res.data);
                let initial_silo_data = res.data.silo_data;
                setPlatform(res.data.platform)
                let assetBaseURL = ENVIRONMENT.getBaseURL(res.data.platform) + ASSET_GLB_URI;
                console.log('UPdated silo data', initial_silo_data)
                setInitialSiloData(_.cloneDeep(initial_silo_data));
                inspector = new Inspector(params.id,'silo-inspector', 0, initial_silo_data, placementType, setPerspectiveSrc, setModelLoaded, setSliderPosition, assetBaseURL);
                if (inspector != null) {
                    changeOrientation('Landscape');
                    changeAspectRatio('16:10','Landscape')
                    inspector.setDefaultHorizontalFOV(fovValue);
                    inspector.setHorizontalFOV(fovValue);
                    inspector.changeOrientation('16:10','Custom');
                    setCameraStartIndex(initial_silo_data);

                }
            });
        props.getProduct(payload);

    };

    useEffect(() => {
        if (props.productReducer.product.rig_file == undefined || props.productReducer.product.rig_file == '') {
            setDefaultLightRigCase('white-bg-shadow-off');
            setLightRig('white-bg-shadow-off');
        } else {
            setDefaultChecked(true);
            setDefaultLightRigCase(props.productReducer.product.rig_file);
            if (props.productReducer.product.rig_file == WHITE_BG_DROPSHADOW_ON) {
                setEnableDefaultShadows(true);
            }
        }

    },[props.productReducer.product]);




    const setSliderPosition = (value, flag = 1) => {
        if (inspector != null) {
            let sliderLimit = 200;
            setSliderPos(value);
            let arrowHandler = document.querySelector("#slider-silo");
            if (arrowHandler != null) {
                arrowHandler.style.transform = "translateY("+((value - sliderLimit))+"px)";
                if (flag == 1){
                    setSliderPos(sliderLimit - value);
                    inspector.setCameraHeight((sliderLimit - value)/900);
                    let arrowHandler = document.querySelector("#slider-silo");
                    arrowHandler.style.transform = "translateY("+((0 - value))+"px)";
                }
            }

        }
    }

    const handleVisibleChange = visible => {
        setPopoverDefault(visible);
    };

    const setCameraStartIndex = (initial_silo_data) => {
        if(initial_silo_data != undefined) {
            (initial_silo_data).forEach( (silo_image_datas) => {
                if (silo_image_datas.camera_name.includes("swatch")) {
                    setSwatchRenderExist(true);
                }
                else if (silo_image_datas.camera_name.includes("default")) {
                    let cameraToks = silo_image_datas.camera_name.split("_");
                    if(cameraToks[2] != undefined){
                        let index = parseInt(cameraToks[2]);
                        if(index >= defaultStartIndex){
                            setDefaultStartIndex(index + 1);
                        }
                    }
                }
                else if (!silo_image_datas.camera_name.includes("Mount")){
                    let cameraToks = silo_image_datas.camera_name.split("_");
                    if(cameraToks[1] != undefined){
                        let index = parseInt(cameraToks[1]);
                        if(index >= cameraIndex){
                            setCameraIndex(index + 1);
                        }
                    }
                }
            } );
        }
    }


    useEffect(()=> {
        if (inspector != null) {
            changeOrientation('Landscape');
            changeAspectRatio('16:10','Landscape')
            inspector.changeOrientation('16:10','Custom');
        }
    },[inspector]);

    useEffect(()=> {
        if (inspector != null){
            inspector.resetCameraPreserveSetting();
        }
    },[selectedAspectRatio]);


    const onImageFormatChange = (e) => {
        setImageFormat(e.target.value);
    }

    const onDPIChange = (e) => {
        setDpi(e.target.value);
    }

    const onFovSwitchChange = (e) => {
        setFovSwitch(e.target.value)
    }

    const onBackgroundChange = (e) => {
        setBackground(e.target.value);
    }

    const onChangeShadows = (value) => {
        setShadows(value);
        setDropShadows(false)
    }

    const onChangeDropShadows = (value) => {
        setDropShadows(value);
        setShadows(false);
    }

    const discardSnapshot = () => {
        setEditResFlag(false);
        showSnapshotModal(false);
        restoreInformation(true);
        inspector.resetCameraState();
    }


    const changeToEditModal = (name) => {
        if (name.includes(insideMount)) {
            setModalShot(perspectiveImages[perspectiveCount - 2]);
            setOrientation("Custom");
            setResolution("2100x1800");
            setImageWidth("2100");
            setImageHeight("1800");
            return;
        }
        if (name.includes(outsideMount)) {
            setModalShot(perspectiveImages[perspectiveCount - 1]);
            return;
        }
        inspector.changeCameraControls(name);
        inspector.saveCameraState();
        let image_name = name;
        for (var i=0; i< camera.length; i++){
            if (camera[i]['name'] == image_name)
            {
                setResolution(camera[i]['resolution']);
            }
        }
        for (var i=0; i < snapshotArray.length; i++){
            if (snapshotArray[i]['camera_name'] == image_name)
            {
                if (snapshotArray[i]['resolution_status'] && snapshotArray[i]['orientation'] != 'Custom'){
                    setCustomWRes(snapshotArray[i]['image_width']);
                    setCustomHRes(snapshotArray[i]['image_height']);
                }
                if (snapshotArray[i]['orientation']) {
                    setImageHeight(snapshotArray[i]['image_height']);
                    setImageWidth(snapshotArray[i]['image_width']);
                }
                setResolutionStatus(snapshotArray[i]['resolution_status']);
                setModalShot(snapshotArray[i]['image_captured']);
                setOrientation(snapshotArray[i]['orientation']);
                setImageFormat(snapshotArray[i]['image_format']);
                if (typeof(snapshotArray[i]['selected_dpi']) == "number") {
                    setDpi(snapshotArray[i]['selected_dpi'].toString());
                }
                else {
                    setDpi(snapshotArray[i]['selected_dpi']);
                }
                setFovValue(snapshotArray[i]['fov']);
                setDefaultChecked(snapshotArray[i]['default_rig']);
                setResolution(snapshotArray[i]['selected_resolution']);
                setSelectedAspectRatio(snapshotArray[i]['aspect_ratio']);
                setLightRig(snapshotArray[i]['rig_name']);
                changeLightRig(snapshotArray[i]['rig_name']);
                inspector.changeOrientation(snapshotArray[i]['aspect_ratio'],'Custom');
                inspector.setHorizontalFOV(snapshotArray[i]['fov']);
            }
        }
    }

    const snapshotTaken = () => {
        setDefaultSiloState(false);
        let imageWidthTemp = 0;
        let imageHeightTemp = 0;
        let newRes = '';
        let resolution_value = '';
        if (resolutionStatus == 4 && orientation != 'Custom'){
            imageWidthTemp = customWRes;
            imageHeightTemp = customHRes;
            newRes= customWRes + 'x' + customHRes;
            inspector.setResolution(newRes);
            resolution_value = newRes;
        }
        else if (orientation == 'Custom') {
            imageWidthTemp = imageWidth;
            imageHeightTemp = imageHeight;
            newRes= imageWidth + 'x' + imageHeight;
            inspector.setResolution(newRes);
            resolution_value = newRes;
        }
        else {
            imageWidthTemp = resolution.split('x')[0];
		    imageHeightTemp = resolution.substring(resolution.indexOf('x') + 1);
            inspector.setResolution(resolution);
            resolution_value = resolution;
        }

        imageWidthTemp = parseFloat(imageWidthTemp);
        imageHeightTemp = parseFloat(imageHeightTemp);
        inspector.setHorizontalFOV(fovValue);
        inspector.setImgDPI(dpi);
        inspector.setImgFormat(imageFormat);
        inspector.disableAxes();
        var img_data = inspector.getSceneRender().domElement.toDataURL();
        inspector.enableAxes();
        if (editResFlag) {
            setEditResFlag(false);
            for (var i=0; i<camera.length; i++){
                if (camera[i]['name'] == selectedImage.name)
                {
                    camera[i]['resolution'] = resolution_value;
                }
            }

            for (var i=0; i < snapshotArray.length; i++){
                if (snapshotArray[i]['camera_name'] == selectedImage.name)
                {
                    snapshotArray[i]['orientation'] = orientation;
                    snapshotArray[i]['image_format'] = imageFormat;
                    snapshotArray[i]['selected_dpi'] = dpi;
                    snapshotArray[i]['resolution_status'] = resolutionStatus;
                    snapshotArray[i]['selected_resolution'] = resolution_value;
                    snapshotArray[i]['image_width'] = imageWidthTemp;
                    snapshotArray[i]['image_height'] = imageHeightTemp;
                    snapshotArray[i]['aspect_ratio'] = selectedAspectRatio;
                    snapshotArray[i]['rig_name'] = lightRig;
                    snapshotArray[i]['captured_by'] = localStorage.getItem("username");
                    snapshotArray[i]['fov'] = fovValue;
                    snapshotArray[i]['default_rig'] = defaultChecked;

                    uploadSiloPreview(params.id, snapshotArray[i]['camera_name'] + '_preview', img_data);

                }
            }

            for (var i=0; i < camera.length; i++){
                if (camera[i]['name'] == selectedImage.name)
                {
                    camera[i]['thumbnail'] = img_data;
                }
            }

            inspector.edit3DMaxValues(selectedImage.name,resolution_value,imageFormat,dpi,selectedAspectRatio,parseFloat(imageWidth),parseFloat(imageHeight),lightRig,fovValue);
        }
        else {
            var camera_index = cameraIndex;
            setCameraIndex(camera_index + 1);
            var camera_name = "camera_" + camera_index;
            addCamera(camera_name,img_data,'',resolution_value);
            uploadSiloPreview(params.id, camera_name + '_preview', img_data);

            let imgElement = {
                orientation: orientation,
                resolution_status: resolutionStatus,
                camera_name: camera_name,
                camera_index: camera_index,
                image_format: imageFormat,
                selected_dpi: dpi,
                selected_resolution: resolution_value,
                image_width: imageWidthTemp,
                image_height: imageHeightTemp,
                aspect_ratio: selectedAspectRatio,
                image_captured: img_data,
                rig_name: lightRig,
                fov: fovValue,
                default_rig: defaultChecked,
                captured_by: localStorage.getItem("username")
            }
            inspector.get3DMaxValues(resolution_value, camera_name, 'pending',lightRig);
            setSnapshotArray(oldArray => [...oldArray, imgElement]);
        }
        showSnapshotModal(false);
        setOverlayLimitFunc();
        let camera_categories = selectedCategories;
        let category_value = returnPrice(resolution_value,'category');
        camera_categories.push(category_value);
        setSelectedCategories(camera_categories);
        loadUserRequestStatus(camera_categories, null);
    }

    const blindSnapshotTaken = ( blindName, defaultSilo = false ) => {
        if (blindImages.includes(blindName)) {
            return;
        }
        if (!defaultSilo) {
            setDefaultSiloState(false);
        }
        inspector.disableAxes();
        var img_data = null;
        if (blindName.includes(insideMount)) {
            img_data = perspectiveImages[perspectiveCount - 2];
        }
        else {
            img_data = perspectiveImages[perspectiveCount - 1];
        }
        var camera_index = null;
        var camera_name = blindName;
        addCamera(camera_name,img_data,'', "2100x1800");
        uploadSiloPreview(params.id, camera_name + '_preview', img_data);
        let imgElement = {
            orientation: orientation,
            resolution_status: resolutionStatus,
            camera_name: camera_name,
            camera_index: camera_index,
            image_format: imageFormat,
            selected_dpi: dpi,
            selected_resolution: "2100x1800",
            image_width: 2100.0,
            image_height: 1800.0,
            aspect_ratio: 1.16,
            image_captured: img_data,
            rig_name: lightRig,
            fov: fovValue,
            default_rig: defaultChecked
        }
        if (!blindImages.includes(camera_name)) {
            setBlindImages(oldArray => [...oldArray, camera_name]);
        }
        setSnapshotArray(oldArray => [...oldArray, imgElement]);
        showSnapshotModal(false);
    }
    const deleteSnapshot = (name,index) => {
        let new_camera = [];
        let newCategories = [];
        for (var i = 0; i < camera.length; i++) {
            if (camera[i]['name'] != name) {
                new_camera.push(camera[i]);
                newCategories.push( returnPrice((camera[i].resolution),'category' ))
            }
        }
        setCamera(new_camera);

        let newArray = [];
        for (var i=0; i<snapshotArray.length; i++){
            if (snapshotArray[i]['camera_name'] != name)
            {
                newArray.push(snapshotArray[i]);
            }
        }
        inspector.removeByCameraName(name);
        setSnapshotArray(newArray);

        if (name.includes(blindMount)) {
            let newBlindArray = []
            for (var i=0; i<blindImages.length; i++){
                if (blindImages[i] != name)
                {
                    newBlindArray.push(blindImages[i]);
                }
            }
            setBlindImages(newBlindArray)
        }
        setSelectedCategories(newCategories);
        loadUserRequestStatus(newCategories, null);

    }

    const addCamera = (camera_name, img_data, camera_render = '',resolution) => {
        let camera_obj = {
            name: camera_name,
            thumbnail: img_data,
            camera_render: camera_render,
            resolution: resolution
        };
        setCamera(oldArray => [...oldArray,camera_obj]);
    }

    const changeCameraControls = (name) => {
        setSelectedCameraName(name);
        changeToEditModal(name);
        inspector.changeCameraControls(name);
        SetOverlayLines()
    }

    const setFullscreen = () => {
        if(isFullscreen == false)
        {
            document.body.requestFullscreen();
        }
        if(isFullscreen == true)
        {
            document.exitFullscreen();
        }
        setIsFullscreen(!isFullscreen);
        SetOverlayLines();
    }

    document.addEventListener('fullscreenchange', (event) => {
        let elem = event.target;
        let isFullscreenState = document.fullscreenElement === elem;
        setIsFullscreen(isFullscreenState);
        SetOverlayLines();
    });

    const setCameraHeight = (value) => {
        if (inspector != null) {
            inspector.setCameraHeight(value);
        }
    }

    const getCameraHeight = (value) => {
        if (inspector != null) {
            return inspector.getCameraHeight();
        }
        return null;
    }

    const setPolarAngle = (angle) => {
        if (inspector != null) {
            inspector.setCameraPolarAngle(180 - angle)
        }
    }

    const getCameraAngle = () => {
        return cameraAngle;
    }

    const setCameraAngle = (value) => {
        setCameraAngles(value)
    }

    const uploadSiloPreview = (product_id, filename, blobData, callback = undefined) => {
        if (platform == 'google') {
            uploadSiloPreviewOnGoogle(product_id, filename, blobData, callback = callback);
        } else {
            uploadSiloPreviewOnAWS(product_id, filename, blobData, callback = callback);
        }
    }

    const uploadSiloPreviewOnGoogle = (product_id, filename, blobData, callback = undefined) => {
        let buf = Buffer.from(blobData.replace(/^data:image\/\w+;base64,/, ""),'base64')
        let key = "product_assets/perspective_renders/" + product_id + "/" + localStorage.getItem("username") + "/" + filename + ".jpg";
        if (companyId != null){
            key = "product_assets/perspective_renders/" + product_id + "/company/" + companyId + "/" + filename + ".jpg";
        }

        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': key
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response);
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': key
                    })
                    if (callback != undefined){
                        callback(true);
                    }
                  } else {
                    if (callback != undefined){
                        callback(false);
                    }
                  }
              };
        
              xhr.send(buf);
          });
    }
    
    const uploadSiloPreviewOnAWS = (product_id, filename, blobData, callback = undefined) => {

        let buf = Buffer.from(blobData.replace(/^data:image\/\w+;base64,/, ""),'base64')

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });
            const S3 = new AWS.S3();
            let key = "product_assets/perspective_renders/" + product_id + "/" + localStorage.getItem("username") + "/" + filename + ".jpg";
            if (companyId != null){
                key = "product_assets/perspective_renders/" + product_id + "/company/" + companyId + "/" + filename + ".jpg";

            }
            const objParams = {
                Bucket: BUCKET_NAME,
                Key: key,
                ACL: 'public-read',
                Body: buf,
                ContentEncoding: 'base64',
                ContentType: "image/jpeg",
                CacheControl: 'no-cache'
            };


            S3.upload(objParams, (err, data) => {
                if (callback != undefined){
                    if(!err) {
                        callback(true);
                    }
                    else{
                        callback(false);
                    }
                }
            });
        });
    }

    const confirmRequest = () =>  {
        let swatchRenderRequest = false;
        if (defaultSiloState) {
            if(configurationData != null && configurationData[modelType]["swatch_renders"] != undefined ) {
                swatchRenderRequest = configurationData[modelType]["swatch_renders"];
            }
        }
        setSiloRequestLoader(true);
        let payload = inspector.getSilosInformation();
        payload.silo_job_status = 'generate';
        payload.username = localStorage.getItem("username");
        payload.swatch_renders = swatchRenderRequest;
        payload.swatch_generated = swatchRenderExist;
        if (companyId != null) {
            payload.company_id = companyId;
        }
        if (requestBlindRenders == true) {
            let insideMountRequested = false;
            let outsideMountRequested = false;
            let requestInsideMount = blindImages.includes(insideMount);
            let requestOutsideMount = blindImages.includes(outsideMount);
            var requested_render_categories = [];
            for (let imageData of payload.silo_data) {
                let cam_height = imageData['image_height']
                let cam_width = imageData['image_width']
                let camera_res = cam_width.toString() + 'x' + cam_height.toString();
                imageData['price_category'] = returnPrice(camera_res,'category');
                requested_render_categories.push(imageData['price_category'])

                if (imageData.camera_name == productID + "_InsideMount" && requestInsideMount) {
                    imageData.image_status = 'pending';
                    imageData.captured_by = localStorage.getItem("username");
                    imageData.rig_name = BLIND_MOUNT_RIG;
                    imageData.rig_url = BLIND_MOUNT_RIG;
                    insideMountRequested = true;
                }
                if (imageData.camera_name == productID + "_OutsideMount" && requestOutsideMount) {
                    imageData.image_status = 'pending';
                    imageData.rig_name = BLIND_MOUNT_RIG;
                    imageData.captured_by = localStorage.getItem("username");
                    imageData.rig_url = BLIND_MOUNT_RIG;
                    outsideMountRequested = true;
                }
            }
            if (!insideMountRequested && requestInsideMount) {
                let imageInfo = {}
                imageInfo.camera_name = productID + "_InsideMount";
                imageInfo.rig_name = BLIND_MOUNT_RIG;
                imageInfo.rig_url = BLIND_MOUNT_RIG;
                imageInfo.captured_by = localStorage.getItem("username");
                imageInfo.image_status = "pending";
                imageInfo.image_type = "blind";
                imageInfo.image_data = {
                    "width" : 2100,
                    "height": 1800,
                    "img_format": "jpg"
                };
                payload.silo_data.push(imageInfo);
            }
            if (!outsideMountRequested && requestOutsideMount) {
                let imageInfo = {}
                imageInfo.camera_name = productID + "_OutsideMount";
                imageInfo.rig_name = BLIND_MOUNT_RIG;
                imageInfo.captured_by = localStorage.getItem("username");
                imageInfo.rig_url = BLIND_MOUNT_RIG;
                imageInfo.image_status = "pending";
                imageInfo.image_type = "blind";
                imageInfo.image_data = {
                    "width" : 2100,
                    "height": 1800,
                    "img_format": "jpg"
                };
                payload.silo_data.push(imageInfo);
            }
        }
        let pending_silo_requests = []
        let initial_silo_cameras = [];
        console.log(initialSiloData, 'Initial Silo')
        initialSiloData.map((camera) => {
            if (camera.image_status == 'pending') {
                initial_silo_cameras.push(camera.camera_name);
            }
        })
        console.log('Initial Silo Cmaeras', initial_silo_cameras)
        payload.silo_data.map((camera) => {
            if (camera.image_status == 'pending' && !initial_silo_cameras.includes(camera.camera_name)) {
                pending_silo_requests.push(camera);
            }
        })
        console.log('Payload Silo', pending_silo_requests)
        payload.silo_data = pending_silo_requests;
        let requestPayload = payload;
        requestPayload['username'] = localStorage.getItem('username');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": requestType,
            "action": "perform",
            "request_payload": requestPayload,
            "categories": selectedCategories,
            "request_id": Date.now().toString()
        }
        console.log('Gateway Payload')

        console.log(selectedCategories)


        if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed' || requestStatus == 'update_subscription') {
            siloSubmissionRequest(gateway_payload);
        }
        if (requestStatus == 'payment_required') {

            window.addEventListener('card_saved', (e) => {
                let action = e.detail.action;
                setCardSaveFlowTriggered(false);
                if (action == 'product_silo') {
                    siloSubmissionRequest(gateway_payload);
                }

            }, false);

            if(cardStatus ==1){
                siloSubmissionRequest(gateway_payload);
            }
            if(cardStatus == 0){
                let child_window = window.open('/save-card?action=product_silo', '', "width=600,height=600");
                let refreshInterval = setInterval(() => {
                  if(child_window.closed) {
                    setCardSaveFlowTriggered(false);
                    setSiloRequestLoader(false)
                    clearInterval(refreshInterval);
                  }
                }, 500);
                setCardSaveFlowTriggered(true);
            }
        }
        loadUserRequestStatus(null,null);
    }

    const cancelSubscriptionModal = () => {
        setSubscriptionModal(false);
    }

    useEffect(() => {
        setSiloRequestLoader(false);
        showSummaryModal(false);
    }, [inAppLimitExceededModal])

    const upgradeSubscription = () => {
        let payload ={
            username: localStorage.getItem('username'),
            subscription_type: subscriptionPlan
        }
          console.log('Subscription payload',payload);
          setSubscriptionLoader(true);
          axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
          .then( (response) => {
            console.log(response.data);
            setSubscriptionModal(false);
            setSubscriptionLoader(false);
            loadUserRequestStatus(null,'confirm');
            message.success('Your subscription plan has been successfully upgraded.');
        })
          .catch((error) => {
          });
    }


    const siloSubmissionRequest = (payload) => {
        console.log(JSON.stringify(payload))
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then(res => {
            if (res.data.status!=undefined && res.data.status == 1) {
                setConfirmRequestState(true);
                if (defaultSiloState && snapshotArray.length > 0) {
                    let latestSnapshot = snapshotArray[snapshotArray.length - 1]['camera_name'].split("_");
                    if(latestSnapshot[2] != undefined){
                        let index = parseInt(latestSnapshot[2]);
                        setDefaultStartIndex(index + 1);
                    }
                }
                    // if (swatchRenderRequest == true) {
                    //     setSwatchRenderExist(true);
                    // }
                    showSummaryModal(false);
                    setSuccessModal(true);
                    setSiloRequestLoader(false);
                    showDefaultSilosModal(false);

                    let payload = { product_id: params.id, username: localStorage.getItem("username")};
                    let company_id = localStorage.getItem("company_id")
                    if (company_id != null) {
                        payload.company_id = company_id
                    }

                    axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
                    .then(res => {
                        console.log('Updated Silo Data', res.data.silo_data)
                        let silo_data = res.data.silo_data;
                        setInitialSiloData(_.cloneDeep(silo_data));
                        if (inspector != null) {
                            inspector.receiveUpdatedData(silo_data);
                        }
                    });
            }
            else if (res.data.request_allowed!=undefined && (res.data.request_allowed == 'not_allowed' || res.data.request_allowed == 'update_subscription')) {
                setInAppLimitExceededModal(true);
            }
            else {
                setPaymentFailureMessage(res.data.error_message);
                setPaymentFailureModal(true);
                setSiloRequestLoader(false);
                showDefaultSilosModal(false);
            }
        })
    }


    const saveRequestIntermedietely = (callback = null) => {
        setSiloRequestLoader(true);
        console.log(JSON.stringify(siloPayload))
        let payload = {
            'request_type': requestType,
            'request_body': siloPayload,
            'processed': false
        }
        if (paymentRequestID == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
            .then(res => {
                console.log(res);
                setSiloRequestLoader(false);
                if (callback!= null)
                {
                   callback(res.data[0].request_id,requestType);
                }
            });
        } else {
            setSiloRequestLoader(false);
            if (callback!= null)
            {
                callback(paymentRequestID,requestType);
            }
        }

    }

    const changeAspectRatio = (ratio,type) => {
        let widthR = 0;
        let heightR = 0;
        let newAspect = 0;
        if (typeof ratio === 'string' || ratio instanceof String) {
			widthR = ratio.split(':')[0];
            heightR = ratio.substring(ratio.indexOf(':') + 1);
            widthR = widthR*100;
            heightR = heightR*100;
			newAspect = widthR/heightR;
            setSelectedAspectRatio(ratio);
            setImageWidth(widthR);
            setImageHeight(heightR);
            setCustomWRes(widthR);
            setCustomHRes(Math.round(inspector.setCustomResolutionHeight(widthR,newAspect)));
            setImageResArr(inspector.setResolutions(widthR,heightR,type));
		}
    }

    const changeOrientation = (value) => {
        if(value == 'Landscape')
        {
            setOrientation('Landscape');
            changeAspectRatio("16:10","Landscape");
        }
        else if(value == 'Portrait')
        {
            setOrientation('Portrait');
            changeAspectRatio("9:16","Portrait");
        }
        else if(value == 'Square')
        {
            setOrientation('Square');
            changeAspectRatio("10:10","Square");
        }
        else if (value == 'Custom') {
            setOrientation('Custom');
        }
    }

    const setAspects = (value,ratio = null) => {
        let aspect_ratio = selectedAspectRatio;
        if (ratio != null) {
            aspect_ratio = ratio;
        }
        if (inspector != null) {
            if (value != 'Custom') {
                inspector.changeOrientation(aspect_ratio,'Custom');
            }
            inspector.setHorizontalFOV(fovValue);
            inspector.disableAxes();
            let img_data = inspector.getSceneRender().domElement.toDataURL();
            setModalShot(img_data);
            inspector.enableAxes();
        }
    }

    const orientationSettings = () => {
        if (orientation == 'Landscape') {
            inspector.changeOrientation(selectedAspectRatio,'Custom');
        }
        else if(orientation == 'Portrait')
        {
            inspector.changeOrientation(selectedAspectRatio,'Custom');
        }
        else if(orientation == 'Square')
        {
            inspector.changeOrientation(selectedAspectRatio,'Custom');
        }
        else if (orientation == 'Custom') {
            let newAspect = imageWidth/imageHeight;
            setSelectedAspectRatio(newAspect);
            inspector.changeOrientation(newAspect,'Custom');
        }
        setAspectRatioModal(false);
        setSettingDone(true);
        inspector.setHorizontalFOV(fovValue);
        setOverlayLimitFunc();
        SetOverlayLines();
    }

    const closeAspectRatioModal = () => {
        if (settingDone == false) {
            setAspectClicked(false);
            setOrientationClicked(false);
        }
        setAspectRatioModal(false);
        restoreInformation(false);

    }

    const resetCamera = () => {
        if (inspector != null){
            inspector.resetCameraSetting();
        }
    }

    const alignCameraForSilos = () => {
        if (inspector != null){
            inspector.alignCameraForSilos();
        }
    }

    const alignCameraHorizontallyForSnapshot = () => {
        if (inspector != null){
            inspector.alignCameraHorizontallyForSnapshot();
        }
    }

    const showGridForSilos = () => {
        if (inspector != null){
            changeGridState();
            inspector.showGridForSilos();
        }

    }

    const changeCameraState = () => {
        setCameraSelected(!cameraSelected);
    }

    const changeGridState = () => {
        if (gridState == true) {
            setGridState(false);
        }
        else {
            setGridState(true);
        }

    }

    const onChangeWidth = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            setImageWidth(1);
            new_aspect = 1/imageHeight;
            res = '1x' + imageHeight;
        } else {
            setImageWidth(value);
            new_aspect = value/imageHeight;
            res = value + 'x' +imageHeight;
        }
        setResolution(res);
    }

    const onChangeHeight = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            setImageHeight(1);
            new_aspect = imageWidth/1;
            res = imageWidth + 'x1';
        } else {
            setImageHeight(value);
            new_aspect = imageWidth/value;
            res = imageWidth + 'x' + value;
        }
        setResolution(res);
    }

    const onChangeWidthDynamically = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            setImageWidth(1);
            new_aspect = 1/imageHeight;
            res = '1x' + imageHeight;
        } else {
            setImageWidth(value);
            new_aspect = value/imageHeight;
            res = value + 'x' +imageHeight;
        }
        setResolution(res);
        setSelectedAspectRatio(new_aspect);
        inspector.changeOrientation(new_aspect,'Custom');
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }

    const onChangeHeightDynamically = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            setImageHeight(1);
            new_aspect = imageWidth/1;
            res = imageWidth + 'x1';
        } else {
            setImageHeight(value);
            new_aspect = imageWidth/value;
            res = imageWidth + 'x' + value;
        }
        setResolution(res);
        setSelectedAspectRatio(new_aspect);
        inspector.changeOrientation(new_aspect,'Custom');
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }

    const onResolutionChange = (e) => {
        setResolutionStatus(e.target.value);
        if (e.target.value == 1) {
            setResolution(imageResArr[0]);
        }
        else if (e.target.value == 2) {
            setResolution(imageResArr[1]);
        }
        else if (e.target.value == 3){
            setResolution(imageResArr[2]);
        }
    }

    useEffect(() => {
        if (resolutionStatus == 1) {
            setResolution(imageResArr[0]);
        }
        else if (resolutionStatus == 2) {
            setResolution(imageResArr[1]);
        }
        else if (resolutionStatus == 3){
            setResolution(imageResArr[2]);
        }
    },[imageResArr]);

    const onChangeToggle = (e) => {
        setPanChanged(true);
        if (e.target.value == "pan") {
            setPanToggle(true);
            if (inspector != null) {
                inspector.togglePan(true);
            }
        }
        else {
            setPanToggle(false);
            if (inspector != null) {
                inspector.togglePan(false);
            }
        }
    }

    const zoomIn = () => {
        inspector.zoomInCamera();
    }

    const zoomOut = () => {
        inspector.zoomOutCamera();
    }

    const openSnapshotModal = () => {

        if ( configurationData[modelType]['images'][perspective]['category'] == "blind") {
            var imageName = configurationData[modelType]['images'][perspective]['display_name']
            blindSnapshotTaken(imageName);
            return;
        }
        inspector.saveCameraState();
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        setResetConfiguration(prevState => ({...prevState,
            img: inspector.getSceneRender().domElement.toDataURL(),
            dpi: dpi,
            image_format: imageFormat,
            resolution_status: resolutionStatus,
            orientation: orientation,
            resolution: resolution,
            aspect_ratio: selectedAspectRatio,
            image_width: imageWidth,
            image_height: imageHeight,
            custom_width: customWRes,
            custom_height: customHRes,
            fov: fovValue,
            rig_name: lightRig,
            default_rig: defaultChecked
        }));
        inspector.setHorizontalFOV(fovValue);
        showSnapshotModal(true);
        setEditResFlag(false);
        inspector.enableAxes();
    }

    const openOrientationModal = () => {
        setResetConfiguration(prevState => ({...prevState,
            img: inspector.getSceneRender().domElement.toDataURL(),
            dpi: dpi,
            image_format: imageFormat,
            resolution_status: resolutionStatus,
            orientation: orientation,
            resolution: resolution,
            aspect_ratio: selectedAspectRatio,
            image_width: imageWidth,
            image_height: imageHeight,
            custom_width: customWRes,
            custom_height: customHRes,
            fov: fovValue,
            rig_name: lightRig,
            default_rig: defaultChecked
        }));
        inspector.setHorizontalFOV(fovValue);
        setAspectRatioModal(true);
    }

    const onChangeFov = (value) => {
        if (isNaN(value)) {
            setFovValue(fovRange.min);
            inspector.updateHorizontalFOV(fovRange.min);
            setFocalLengthValue(inspector.getCameraFocalLength())
        }
        else {
            if (value < fovRange.min) {
                setFovValue(fovRange.min);
                inspector.updateHorizontalFOV(fovRange.min);
                setFocalLengthValue(inspector.getCameraFocalLength())
            } else if (value > fovRange.max) {
                setFovValue(fovRange.max);
                inspector.updateHorizontalFOV(fovRange.max);
                setFocalLengthValue(inspector.getCameraFocalLength())
            } else {
                setFovValue(value);
                inspector.updateHorizontalFOV(value);
                setFocalLengthValue(inspector.getCameraFocalLength())
            }
        }
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }

    const onChangeFocalLength = (value) => {
        if (isNaN(value)) {
            setFocalLengthValue(focalLengthRange.min)
            inspector.setFocalLength(focalLengthRange.min)
            setFovValue(inspector.getHorizontalFOV());
        } else {
            if (value < focalLengthRange.min) {
                setFocalLengthValue(focalLengthRange.min)
                inspector.setFocalLength(focalLengthRange.min)
                setFovValue(inspector.getHorizontalFOV());
            } else if (value > focalLengthRange.max) {
                setFocalLengthValue(focalLengthRange.max)
                inspector.setFocalLength(focalLengthRange.max)
                setFovValue(inspector.getHorizontalFOV());
            } else {
                setFocalLengthValue(value)
                inspector.setFocalLength(value)
                setFovValue(inspector.getHorizontalFOV());
            }
        }
        inspector.disableAxes();
        let img_data = inspector.getSceneRender().domElement.toDataURL();
        setModalShot(img_data);
        inspector.enableAxes();
    }


    const restoreInformation = (check) => {
        setModalShot(resetConfiguration.img);
        if (typeof(resetConfiguration.dpi) == "number") {
            setDpi(resetConfiguration.dpi.toString());
        }
        else {
            setDpi(resetConfiguration.dpi);
        }
        setImageFormat(resetConfiguration.image_format);
        setResolutionStatus(resetConfiguration.resolution_status);
        setResolution(resetConfiguration.resolution);
        setOrientation(resetConfiguration.orientation);
        setCustomHRes(resetConfiguration.custom_height);
        setCustomWRes(resetConfiguration.custom_width);
        setImageWidth(resetConfiguration.image_width);
        setImageHeight(resetConfiguration.image_height);
        setLightRig(resetConfiguration.rig_name);
        setDefaultChecked(resetConfiguration.default_rig);
        changeLightRig(resetConfiguration.rig_name);
        if (resetConfiguration.orientation == 'Custom') {
            let newAspect = resetConfiguration.image_width/resetConfiguration.image_height;
            setSelectedAspectRatio(newAspect);
            if (check) {
                inspector.changeOrientation(newAspect,'Custom');
            }
        }
        else {
            if (resetConfiguration.resolution_status == 4){
                setSelectedAspectRatio(resetConfiguration.aspect_ratio);
            }
            else {
                changeAspectRatio(resetConfiguration.aspect_ratio,resetConfiguration.orientation);
            }
            if (check) {
                inspector.changeOrientation(resetConfiguration.aspect_ratio,'Custom');
            }
        }
        setFovValue(resetConfiguration.fov);
        inspector.setHorizontalFOV(resetConfiguration.fov);
    }

    const changePerspective = (value) => {
        setPerspective(value);
        setSelectedCameraName('');
        inspector.changePerspectives(value);
        setFovValue(inspector.getDefaultHorizontalFOV());

        if (value >= perspectiveCount - blindPerspectives) {
            setBlindRendersState(true);
        }
        else {
            setBlindRendersState(false);
        }
    }

    const selectOrientation = (orientation) => {
        changeOrientation(orientation);
        setOrientationClicked(true);
    }

    const selectAspectRatio = (aspect_ratio, type) => {
        changeAspectRatio(aspect_ratio, type);
        setAspectClicked(true);
    }

    const onImageWidthRes = (value) => {
        if (typeof value != 'number') {
            value = 1;
        }
        setCustomHRes(inspector.setCustomResolutionHeight(value));
        setCustomWRes(value);

        setTriggerUpdate(!triggerUpdate);
    }

    const onImageHeightRes = (value) => {
        if (typeof value != 'number') {
            value = 1;
        }
        setCustomWRes(inspector.setCustomResolutionWidth(value));
        setCustomHRes(value);

        setTriggerUpdate(!triggerUpdate);
    }

    const onChangeDefault = (e) => {
        setDefaultChecked(e.target.value);
    }

    const leftScroll = () => {
        var elem = document.getElementById('silo-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                elem.scrollLeft -= 273;
            }
            else if (window.innerWidth <= 1110) {
                elem.scrollLeft -= 224;
            }
            var left = document.getElementById('left-button-scr');
            var right = document.getElementById('right-button-scr');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }
            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                if (right != null && elem.scrollLeft != 352) {
                    right.style.display = "inline-block";
                }
            } else if (window.innerWidth <= 1110){
                if (right != null && elem.scrollLeft != 582) {
                    right.style.display = "inline-block";
                }
            }
        }
    }

    const rightScroll = () => {
        var elem = document.getElementById('silo-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                elem.scrollLeft += 273;
            }
            else if (window.innerWidth <= 1110) {
                elem.scrollLeft += 224;
            }
            var left = document.getElementById('left-button-scr');
            var right = document.getElementById('right-button-scr');
            if (left != null) {
                left.style.display = "inline-block";
            }
            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                if (right != null && elem.scrollLeft == 352) {
                    right.style.display = "none";
                }
            } else if (window.innerWidth <= 1110){
                if (right != null && elem.scrollLeft == 582) {
                    right.style.display = "none";
                }
            }
        }
    }


    var el= document.getElementById('silo-scrollable');
    if (el != null) {
        el.style.scrollBehavior = "unset";

        var y1 = el.scrollTop;
        el.scrollTop += 1;
        var y2 = el.scrollTop;
        el.scrollTop -= 1;
        var y3 = el.scrollTop;
        el.scrollTop = y1;

        var x1 = el.scrollLeft;
        el.scrollLeft += 1;
        var x2 = el.scrollLeft;
        el.scrollLeft -= 1;
        var x3 = el.scrollLeft;
        el.scrollLeft = x1;
        var right = document.getElementById('right-button-scr');
        var left = document.getElementById('left-button-scr');
        if (right != null && (x1 !== x2 || x2 !== x3)) {
            right.style.display = "inline-block";
        }
        else if (right != null && !(x1 !== x2 || x2 !== x3) && left != null) {
            right.style.display = "none";
            left.style.display = "none";
        }
        if (left != null && el.scrollLeft == 0) {
            left.style.display = "none";
        }

        if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
            if (right != null && el.scrollLeft == 352) {
                right.style.display = "none";
            }
        } else if (window.innerWidth <= 1110){
            if (right != null && el.scrollLeft == 582) {
                right.style.display = "none";
            }
        }
    }

    const returnPrice = (resolution, type='price') => {
        let width = resolution.split('x')[0]
        let height = resolution.split('x')[1]
        let silo_prices = [];
        if (Utilities.nearestThousand(height) == 4000 || Utilities.nearestThousand(width) == 4000 || height >= 4000 || width >= 4000) {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "4k")
        } else if(Utilities.nearestThousand(height) == 3000 || Utilities.nearestThousand(width) == 3000) {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "4k")
        } else if (Utilities.nearestThousand(height) == 2000 || Utilities.nearestThousand(width) == 2000) {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "2k")
        } else if ((Utilities.nearestThousand(height) == 1000 || Utilities.nearestThousand(width) == 1000) && (height >= 1000 || width >= 1000)) {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "1k")
        } else {
            silo_prices =  prices.filter((request) => request.request_type == 'product_silo' && request.category == "1k")
        }
        if (silo_prices && silo_prices[0]) {
            if (type == 'price') {
                return parseInt(silo_prices[0].price);
            } else {
                return silo_prices[0].category;
            }

        }
    }

    const totalPrice = () => {
        let totalPrice = 0;
        camera.map((element,index) => {
            totalPrice = totalPrice + returnPrice(element.resolution);
        });
        return totalPrice;
    }

    const checkScroll = (e) => {
        var el= document.getElementById('silo-scrollable');
        if (el != null) {
            el.style.scrollBehavior = "unset";

            var y1 = el.scrollTop;
            el.scrollTop += 1;
            var y2 = el.scrollTop;
            el.scrollTop -= 1;
            var y3 = el.scrollTop;
            el.scrollTop = y1;

            var x1 = el.scrollLeft;
            el.scrollLeft += 1;
            var x2 = el.scrollLeft;
            el.scrollLeft -= 1;
            var x3 = el.scrollLeft;
            el.scrollLeft = x1;
            var right = document.getElementById('right-button-scr');
            var left = document.getElementById('left-button-scr');

            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
            }
            else if (right != null && !(x1 !== x2 || x2 !== x3) && left != null) {
                right.style.display = "none";
                left.style.display = "none";
            }
            if (left != null && el.scrollLeft == 0) {
                left.style.display = "none";
            }

            if (window.innerWidth > 1110 && window.innerWidth <= 1550) {
                if (right != null && el.scrollLeft == 352) {
                    right.style.display = "none";
                }
            } else if (window.innerWidth <= 1110){
                if (right != null && el.scrollLeft == 582) {
                    right.style.display = "none";
                }
            }

            if (e.axis == e.HORIZONTAL_AXIS) {
                e.stopPropagation();
                e.preventDefault();
                e.cancelBubble = false;
            }
            return false;
        }
    }

    window.addEventListener("resize", checkScroll);
    window.addEventListener('scroll', checkScroll);

    return <div>
            <Row type="flex" justify="center" >
                <div className="scene-creator-header">
                    <Row type="flex"  style={{width: '100%',justifyContent:"space-between"}}>
                        <Col span={8} style={{display: 'flex',justifyContent:"flex-start"}}>
                            <Popover placement="bottomLeft" content={productInfo.product_name}>
                                <div className="scene-creator-room-name" style={{minWidth: 77}}>{productInfo.product_name}</div>
                            </Popover>
                            <div className="separator"></div>
                        </Col>
                        <Col span={8} style={{display: 'flex',justifyContent:"center"}}>
                            <span data_tut="reactour__panrotate">
                                <Radio.Group
                                    disabled={loadingProgress ? true : false}
                                    className="silo-controls"
                                    value={panToggle ? "pan" : "rotate"}
                                    onChange={onChangeToggle}
                                    style={{marginTop: 13}}>
                                        <Radio.Button value="rotate"><img src={panToggle ? "/img/rotate_default.png" :  "/img/rotate_selected.png"} style={{width:30,height:30}}/></Radio.Button>
                                        <Radio.Button value="pan"><img src={panToggle ? "/img/pan_selected.png" : "/img/pan_default.png"} style={{width:30,height:30}}/></Radio.Button>
                                        <div style={{display:"flex",justifyContent:"space-between"}}>
                                            <div  className="icon-label-text2"  style={{textAlign: 'center',width: '100%'}}>Rotate</div>
                                            <div  className="icon-label-text2"  style={{textAlign: 'center',width: '100%'}}>Pan</div>
                                        </div>
                                </Radio.Group>
                            </span>
                        </Col>
                        <Col span={8} style={{display: "flex", justifyContent: "space-evenly"}}>
                            <div className="container-box" onClick={openOrientationModal} style={{cursor: loadingProgress ? "not-allowed" : "pointer",pointerEvents: loadingProgress ? "none" : "auto", marginRight: 12,display:"flex",flexDirection:"column",alignItems:"center"}}>
                                <img src={require("../../../assets/images/aspect-ratio-icon.svg")} className="contained-icon"></img>
                                <div className="icon-label-text4">Orientation</div>
                            </div>
                            <div className="container-box"
                                onClick={OpenOverlaySettingsModal}
                                style={{cursor: "pointer",marginLeft: 15,display:"flex",flexDirection:"column",alignItems:"center"}}>
                                    <img src={require("../../../assets/images/safeZone.svg")} className="contained-icon"></img>
                                    <div className="icon-label-text4" style={{whiteSpace:'nowrap'}}>Safe Zone</div>
                                </div>
                            <div>
                                <div className="double-container-box">
                                    <span style={{cursor: loadingProgress ? "not-allowed" : "pointer",pointerEvents: loadingProgress ? "none" : "auto"}}  onClick={() => zoomIn()}>
                                        <img src={require("../../../assets/images/zoom-in-icon.svg")} className="contained-icon-bigger"></img>
                                    </span>
                                    <div className="separator-no-margin"></div>
                                    <span style={{cursor: loadingProgress ? "not-allowed" : "pointer",pointerEvents: loadingProgress ? "none" : "auto"}}  onClick={() => zoomOut()}>
                                        <img src={require("../../../assets/images/zoom-out-icon.svg")} className="contained-icon-bigger"></img>
                                    </span>
                                </div>
                                <div className="icon-label-text2" style={{textAlign: 'center',width: '100%'}} >Zoom</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{cursor: loadingProgress ? "not-allowed" : "pointer",pointerEvents: loadingProgress ? "none" : "auto",marginRight:"14px"}} onClick={() => setIsTourOpen(true)}>
                                    <div className="container-box">
                                        <img src={require("../../../assets/images/help-icon-2.svg")} className="contained-icon"></img>
                                        <div className="icon-label-text4">Help</div>
                                    </div>
                                </div>
                                <div className="container-box">
                                    <div style={{cursor:loadingProgress ? "not-allowed" : "pointer",pointerEvents: loadingProgress ? "none" : "auto",display:"flex",flexDirection:"column",alignItems:"center"}} onClick={() => setFullscreen()}>
                                        <img src={isFullscreen == false ? require("../../../assets/images/full-screen-icon.svg") : require("../../../assets/images/full-screen-selected-icon.svg")} class="contained-icon"></img>
                                        <div className="icon-label-text3">Fullscreen</div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Col span={18} className="silo-content">
                    {loadingProgress ?
                    <div className="load-container-silo">
                        <LoadingOutlined style={{textAlign: "center", fontSize:"24px",marginBottom: 20}} size="large" spin />
                        <h3 className="load-message large">Setting up Product Model </h3>
                        <h3 className="load-message">Please wait a few moments while we set up your product model.</h3>
                    </div>: ""}
                    <div style={{filter: loadingProgress ? "blur(12px)": "" }} data_tut="reactour__dragrotate">
                        <div id="silo-inspector" style={{cursor: panToggle ? "move" : "default", position: "relative"}}>
                        <div className= "silo-camera-alignment-1 silo-camera-alignment-2 silo-camera-alignment-3 silo-camera-alignment-4 silo-camera-alignment-5" style={{position: 'absolute', bottom: 20, right: 0}}>
                            <Row>
                                <Col span={24} style={{padding: "2px",display:loadingProgress ? "none" : "flex"}}>
                                    <Popover placement="top" content={"Grid"}>
                                    <span style={{cursor: "pointer", marginTop: 5}} onClick={() => {showGridForSilos()}}>
                                        <img src={gridState == false ? "/img/grid_disabled.png" : "/img/grid_enabled.png"} class="contained-icon-large"/>
                                    </span>
                                    </Popover>
                                    <Popover placement="top" content={"Vertical Camera Align"}>
                                    <span style={{cursor: "pointer", marginTop: 5}} onClick={() => {alignCameraForSilos()}}>
                                        <img src={"/img/Vertical Align.svg"} class="contained-icon-large"/>
                                    </span>
                                    </Popover>
                                    <Popover placement="top" content={"Horizontal Camera Align"}>
                                    <span style={{cursor: "pointer", marginRight: 10, marginTop: 5}} onClick={() => {alignCameraHorizontallyForSnapshot()}}>
                                        <img src={"/img/Horizontal Align.svg"} class="contained-icon-large"/>
                                    </span>
                                    </Popover>
                                    <Button block className="sc-action-button round" type="danger d-inline" shape="round" style={{ textTransform:"uppercase"}} onClick={()=> openSnapshotModal()}>
                                        <span style={{fontSize: 24}}><CameraOutlined/></span>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div id = "grid">
                                <img id="grid-snapshot" src={require("../../../assets/images/grid.png")} style={{display: "none" , position: "absolute" ,zIndex:"1"}} />
                        </div>
                        <div id="overlay-blur-top" className='overlay-blur'>
                        </div>
                        <div id="overlay-blur-bottom" className='overlay-blur'>
                        </div>
                        <div id="overlay-blur-left" className='overlay-blur'>
                        </div>
                        <div id="overlay-blur-right" className='overlay-blur'>
                        </div>
                        </div>
                        <div data_tut="reactour__cameraheight--observe" style={{position: 'absolute', top: 5, left: 10}}>
                            <Popover placement="rightTop" content={"Camera Controls"}>
                                <span style={{cursor: "pointer",zIndex:"10"}} data_tut="reactour__cameraheight" onClick={changeCameraState}>
                                    <img src={cameraSelected == false ? "/img/camera.png" : "/img/camera_default.png"} class="contained-icon-large"/>
                                </span>
                            </Popover>
                            <div style={{display: (cameraSelected && !blindRendersState) ? "block" : "none"}} className="camera_controls_fov">
                                <div style={{position: 'relative', top: "-10px", left: 20}}>
                                    <CameraHeightWidget fovSwitch={fovSwitch} onFovSwitchChange={onFovSwitchChange}
                                    onChangeFocalLength={onChangeFocalLength} focalLengthRange={focalLengthRange} focalLengthValue={focalLengthValue}
                                    onChangeFov={onChangeFov} fovValue={fovValue} setSliderPosition={setSliderPosition}
                                    sliderPos={sliderPos}  inspectorVal={inspector} setPolarAngle={setPolarAngle}
                                    setCameraHeight={setCameraHeight} getCameraAngle={getCameraAngle}
                                    setCameraAngle={setCameraAngle} getCameraHeight={getCameraHeight}
                                     />
                                </div>
                            </div>
                        </div>

                        <Button disabled= {blindRendersState} data_tut="reactour__resetcamera" className="reset-camera-btn modal-okay-black" onClick={resetCamera} >
                            <span className="modal-okay-text black medium-margin">Reset Camera</span>
                        </Button>
                        <div className="snapshot-position-controls"  data_tut="reactour__defaultsnapshot">
                            <span className="manrope font-14" style={{fontSize: 15, marginLeft: 0, padding: "10px 0 10px 0"}}><b>Default Silo Images</b></span>
                        <div className="snapshot-controls" >
                            <div className="right-arrow-container" id="left-button-scr" onClick={leftScroll}>
                                <RightOutlined  className="left-button-scr" />
                            </div>
                            <div style={{display:"flex"}} id="silo-scrollable" className="silo-scrollable">
                            {perspectiveImages != [] ? perspectiveImages.map((view,index) => (
                                <Row>
                                    <Col span={24} style={{padding: "2px"}}>
                                        <div className="perspective-bg" style={{border: (perspective == index && selectedCameraName == '') ? "1px solid #333333" : "1px solid #e4e4e4" }} onClick={()=> changePerspective(index)}>
                                        <img className="perspective-img-dimensions" src={view}/>
                                            <div className="persp-img-txt">{perspectiveLabels[index]}</div>
                                        </div>
                                    </Col>
                                </Row>
                            )) : ""}
                            </div>
                            <div className="left-arrow-container" id="right-button-scr" onClick={rightScroll}>
                                <RightOutlined  className="right-button-scr-product" />
                            </div>
                        </div>
                    </div>
                    </div>

                </Col>
                <Col span={5} className="silo-snapshot-container">
                    <Card bordered={false}>
                        <div id="angles-images" style={{"padding-top": "10px"}}>
                        </div>
                        <div id="camera_names" style={{paddingTop: "10px"}}>
                            <h3 className='manrope f-20' style={{padding:"0px 10px"}}>Snapshots</h3>
                            <div className="scene-controls" style={{padding: "0px 5px 0px 10px"}}>
                                <Row type="flex" justify="space-around" className={snapshotArray.length == 0 ? "position-snapshot-fill" : ""}>
                                    {((defaultSiloState == true || snapshotArray.length == 0) && !loadingProgress) ?
                                    <div style={{padding: 5}}>
                                        <img src="/img/snapshot-empty.png"  className="justify-in-center" style={{margin: "0 auto 25px auto"}}></img>
                                        <div className="modal-text gray justify-in-center" style={{marginBottom: 18}}>No Snapshot Captured Yet!</div>
                                        <div style={{display:"inline-block",textAlign:"center"}}>
                                            <span className="category-artist medium" >Capture snapshots by clicking on</span>&nbsp;<span className="round-small-btn"><CameraOutlined style={{fontSize: 12}}/></span>&nbsp;<span  className="category-artist medium">button</span>
                                            { defaultSiloState == true ?
                                            <div className="category-artist medium" style={{marginBottom: 18, marginTop : 18}}>OR</div>
                                            : ''}
                                            { defaultSiloState == true ?
                                            <Button className="modal-okay square font-14 pd" style={{margin: "2px auto 0 auto",textTransform:"capitalize"}} onClick={()=> {
                                                captureDefaultSilos();
                                            }} >
                                            Request Default Silos
                                            </Button>
                                            : '' }
                                        </div>

                                    </div> :
                                    <div style={{padding: 5,width:"100%"}}>
                                        {camera.slice(0).reverse().map((element, index)=> (
                                            <span>
                                                {(element.camera_render != '') ?
                                                <div style={{position:"relative",marginBottom: 10}}>
                                                    <img  onClick={()=> {
                                                            if (element.name.includes(insideMount)) {
                                                                changePerspective(perspectiveCount-2)
                                                            }
                                                            else if (element.name.includes(outsideMount)) {
                                                                changePerspective(perspectiveCount-1)
                                                            }
                                                            else {
                                                                changeCameraControls(element.name)
                                                            }
                                                        }
                                                        } src={element.camera_render} className="ss-images" style={{border: element.name==selectedCameraName ? "1px solid #333333" :"1px solid #c4c4c4"}}/>
                                                    <Dropdown overlay={
                                                        <Menu style={{ width: "160px",fontFamily:"Avenir" }}>
                                                            <Menu.Item key={0}>
                                                                <a href="#"  className="select-aspect delete" onClick={() => deleteSnapshot(element.name,index)}>
                                                                    Delete
                                                                </a>
                                                            </Menu.Item>
                                                            <Menu.Divider />
                                                            <Menu.Item key={1}>
                                                                <a href="#"  className="select-aspect edit" onClick={() => {
                                                                    if (!element.name.includes(blindMount)) {
                                                                        changeToEditModal(element.name);
                                                                        setEditResFlag(true);
                                                                        setSelectedImage(element);
                                                                        showSnapshotModal(true);
                                                                    }

                                                                }}>
                                                                    Edit Resolution
                                                                </a>
                                                            </Menu.Item>
                                                        </Menu>
                                                        }
                                                        placement="bottomRight" trigger={["click"]}>
                                                        <MoreOutlined style={{position: "absolute", top: 10, fontSize:"18px",right: 8, cursor: "pointer"}} type="close" />
                                                    </Dropdown>
                                                </div> :
                                                <div style={{position:"relative",marginBottom: 10}}>
                                                    <img src={element.thumbnail}  onClick={()=> {
                                                            if (element.name.includes(insideMount)) {
                                                                changePerspective(perspectiveCount-2)
                                                            }
                                                            else if (element.name.includes(outsideMount)) {
                                                                changePerspective(perspectiveCount-1)
                                                            }
                                                            else {
                                                                changeCameraControls(element.name)
                                                            }
                                                        }} className="ss-images" style={{border: element.name==selectedCameraName ? "1px solid #333333" :"1px solid #c4c4c4"}}/>
                                                    <Dropdown overlay={
                                                        <Menu style={{ width: "160px",fontFamily:"Avenir" }}>
                                                            <Menu.Item key={0}>
                                                                <a href="#"  className="select-aspect delete" onClick={() => deleteSnapshot(element.name,index)}>
                                                                    Delete
                                                                </a>
                                                            </Menu.Item>
                                                            <Menu.Divider />
                                                            { !element.name.includes(blindMount) ? <Menu.Item key={1}>
                                                                <a href="#"  className="select-aspect edit" onClick={() => {

                                                                    changeToEditModal(element.name);
                                                                    setEditResFlag(true);
                                                                    setSelectedImage(element);
                                                                    showSnapshotModal(true);


                                                                }}>
                                                                    Edit Resolution
                                                                </a>
                                                            </Menu.Item> : ''}
                                                        </Menu>
                                                        }
                                                        placement="bottomRight" trigger={["click"]}>
                                                        <MoreOutlined style={{position: "absolute", top: 10, fontSize:"18px",right: 8, cursor: "pointer"}} type="close" />
                                                    </Dropdown>
                                                </div>}
                                            </span>
                                        ))
                                        }
                                    </div>}
                                </Row>
                            </div>
                        </div>
                    </Card>
                    {(defaultSiloState == true || snapshotArray.length == 0) ? '' :
                    <Row style={{height: 88}}>
                         {/* style={{position:"absolute",bottom:"48px",right: 0,left: 0}} */}
                        <Col span={24} style={{textAlign:"center"}}>
                            <Button className="modal-okay square font-14 pd" style={{margin: "2px auto 0 auto",textTransform:"capitalize"}} onClick={()=> showSummaryModal(true)} disabled={camera.length == 0 || loadingProgress}>
                            Request Silo Images
                            </Button>
                        </Col>
                    </Row>}

                </Col>
            </Row>

            <Footer style={{display: "flex", padding: 12}} className="silo-footer">
                <span style={{display: "flex", width: "100%", justifyContent: "flex-start"}}>
                    <div>

                        <Button className="modal-okay-gray square font-14" onClick={() => {
                            if (snapshotArray.length == 0) {
                                window.location.href = "/products/" + params.id;
                            } else {
                                setGoBackConfirmModal(true);
                            }
                            }}>
                            Exit
                        </Button>
                    </div>
                    {/*--- Will be used later---
                     <div style={{display:"flex",justifyContent:"center"}}>
                        <div style={{marginRight: 30, background: "rgba(39, 109, 215, 0.06)",borderRadius: "4px",padding:"10px 16px"}}>
                            <span className="scene-note">
                                Note: <span style={{color:"#333333"}}>The image perspectives you select here will be applied to all of your variations</span>
                            </span>
                        </div>
                    </div> */}
                </span>
            </Footer>

            <PaymentFailureModal
            paymentFailureMessage={paymentFailureMessage}
            setPaymentFailureModal={setPaymentFailureModal}
            updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
            paymentFailureModal={paymentFailureModal}
            />

            <SiloPhotographyModals
            {...props}
            closeAspectRatioModal={closeAspectRatioModal}
            aspectRatioModal={aspectRatioModal}
            aspectClicked={aspectClicked}
            orientation={orientation}
            orientationClicked={orientationClicked}
            orientationSettings={orientationSettings}
            selectOrientation={selectOrientation}
            selectAspectRatio={selectAspectRatio}
            selectedAspectRatio={selectedAspectRatio}
            onResolutionChange={onResolutionChange}
            resolutionStatus={resolutionStatus}
            imageResArr={imageResArr}
            onImageWidthRes={onImageWidthRes}
            customWRes={customWRes}
            onImageHeightRes={onImageHeightRes}
            customHRes={customHRes}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            onChangeWidth={onChangeWidth}
            onChangeHeight={onChangeHeight}
            snapshotModal={snapshotModal}
            showSnapshotModal={showSnapshotModal}
            setEditResFlag={setEditResFlag}
            restoreInformation={restoreInformation}
            editResFlag={editResFlag}
            snapshotTaken={snapshotTaken}
            modalShot={modalShot}
            changeOrientation={changeOrientation}
            setAspects={setAspects}
            changeAspectRatio={changeAspectRatio}
            onChangeWidthDynamically={onChangeWidthDynamically}
            onChangeHeightDynamically={onChangeHeightDynamically}
            dpi={dpi}
            onDPIChange={onDPIChange}
            fovSwitch = {fovSwitch}
            onFovSwitchChange={onFovSwitchChange}
            focalLengthValue={focalLengthValue}
            onImageFormatChange={onImageFormatChange}
            imageFormat={imageFormat}
            background={background}
            onBackgroundChange={onBackgroundChange}
            shadows={shadows}
            onChangeShadows={onChangeShadows}
            summaryModal={summaryModal}
            showSummaryModal={showSummaryModal}
            siloRequestLoader={siloRequestLoader}
            confirmRequest={confirmRequest}
            camera={camera}
            deleteSnapshot={deleteSnapshot}
            successModal={successModal}
            setSuccessModal={setSuccessModal}
            params={params}
            isTourOpen={isTourOpen}
            getCookie={getCookie}
            setCookie={setCookie}
            setIsTourOpen={setIsTourOpen}
            onChangeDefault={onChangeDefault}
            defaultChecked={defaultChecked}
            defaultLightRigCase={defaultLightRigCase}
            handleVisibleChange={handleVisibleChange}
            popoverDefault={popoverDefault}
            setPopoverDefault={setPopoverDefault}
            setGoBackConfirmModal={setGoBackConfirmModal}
            goBackConfirmModal={goBackConfirmModal}
            onChangeFov={onChangeFov}
            setFocalLengthRange={setFocalLengthRange}
            onChangeFocalLength={onChangeFocalLength}
            fovValue={fovValue}
            focalLengthRange = {focalLengthRange}
            discardSnapshot={discardSnapshot}
            editNextDefaultSilo = {editNextDefaultSilo}
            editSelectedDefaultSilo = {editSelectedDefaultSilo}
            editPreviousDefaultSilo = {editPreviousDefaultSilo}
            defaultSilosModal={defaultSilosModal}
            showDefaultSilosModal={showDefaultSilosModal}
            setDefaultLoader={setDefaultLoader}
            defaultLoader={defaultLoader}
            leftPerspectiveScroll = {leftPerspectiveScroll}
            rightPerspectiveScroll = {rightPerspectiveScroll}
            perspectiveImages = {perspectiveImages}
            perspective = {perspective}
            perspectiveLabels = {perspectiveLabels}
            setDefaultSettingsState = {setDefaultSettingsState}
            setEditCapturedDefaultSilos = {setEditCapturedDefaultSilos}
            editCapturedDefaultSilos = {editCapturedDefaultSilos}
            defaultSettingsState = {defaultSettingsState}
            overlaySettingsModal={overlaySettingsModal}
            tempSnapshotOverlay={tempSnapshotOverlay}
            handleDiscardOverlaySettings={handleDiscardOverlaySettings}
            setShowOverlaySwitch={setShowOverlaySwitch}
            overlaySwitch={overlaySwitch}
            onChangeOverlayOpacity={onChangeOverlayOpacity}
            overlayOpacity={overlayOpacity}
            onChangeOverlayColor={onChangeOverlayColor}
            overlayColor={overlayColor}
            onChangeOverlayHeight={onChangeOverlayHeight}
            overlayHeight={overlayHeight}
            onChangeOverlayWidth={onChangeOverlayWidth}
            overlayWidth={overlayWidth}
            onChangeOverlayUnit={onChangeOverlayUnit}
            overlaySizeUnit={overlaySizeUnit}
            handleApplyOverlaySettings={handleApplyOverlaySettings}
            dropShadows = {dropShadows}
            onChangeDropShadows={onChangeDropShadows}
            dropShadowShot = {dropShadowShot}
            regularShadowShot = {regularShadowShot}
            isFloorItem = {isFloorItem}
            overlayLimit={overlayLimit}
            setTempOverlayLines={setTempOverlayLines}
            cardStatus={cardStatus}
            requestStatus={requestStatus}
            returnPrice={returnPrice}
            setSiloRequestLoader={setSiloRequestLoader}
            totalPrice={totalPrice}
            cardSaveDrawer={cardSaveDrawer}
            setCardSaveDrawer={setCardSaveDrawer}
            saveRequestIntermedietely={saveRequestIntermedietely}
            setCardSaveFailure={setCardSaveFailure}
            subscriptionModal={subscriptionModal}
            subscriptionLoader={subscriptionLoader}
            upgradeSubscription={upgradeSubscription}
            cancelSubscriptionModal={cancelSubscriptionModal}
            currentPackage={currentPackage}
            isSubscriptionActive={isSubscriptionActive}
            subscriptionPlan={subscriptionPlan}
            defaultRequested={defaultRequested}
            setSubscriptionPlan={setSubscriptionPlan}
            cardSaveFlowTriggered={cardSaveFlowTriggered}
            inAppLimitExceededModal={inAppLimitExceededModal}
            setInAppLimitExceededModal={setInAppLimitExceededModal}
            resetPaymentsOnCancel={resetPaymentsOnCancel}
            />
        </div>
}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    updateProduct: (product) => {
        dispatch(updateProduct(product));
    },
    listProducts: () => {
        dispatch(listProducts());
    },
    rejectProduct: (product) => {
        dispatch(rejectProduct(product))
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SiloPhotography))