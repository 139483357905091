import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { Row, Col, Image, Card } from 'antd';
import DottedLoader from '../../DottedLoader';
import { getReadableNeedToModelFormat } from '../../Utilities';

function ModelInformation({ testID }) {
  const [color, setColor] = useState(null);
  const [similarColor, setSimilarColor] = useState(null);
  const [lightingRig, setLightingRig] = useState(null);
  const [referenceURLs, setReferenceURLs] = useState([]);
  const [referenceImages, setReferenceImages] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [platform, setPlatform] = useState('aws');
  const [needToModel, setNeedToModel] = useState('')
  
  // Get Model Dimensions given to the artist
  useEffect(() => {
    const payload = {
      test_id: testID,
    };
    setIsLoading(true);
    axios
        .post(ENVIRONMENT.GET_TEST_ARTIST_PRODUCT_DETAILS, payload)
        .then((res) => {
          console.log(res.data);
          setIsLoading(false);
          setColor(res.data.color_name);
          setSimilarColor(res.data.similar_color);
          setLightingRig(res.data.rig.rig_file);
          setReferenceURLs(res.data.reference_urls);
          setMaterials(res.data.materials);
          setPlatform(res.data.platform)
          setNeedToModel(getReadableNeedToModelFormat(res.data.need_to_model))
          const refImages = [];
          if(res.data.product_manuals){
            refImages.push(...res.data.product_manuals)
          }
          refImages.push(...res.data.product_photos);
          setReferenceImages(refImages);
        });
  }, []);
  return (
    <>
      {isLoading ? (
        <DottedLoader />
      ) : (
        <>
          {referenceURLs.length !== 0 ? (
            <Row style={{ marginTop: 30 }}>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '40px',
                }}
              >
                <span className="category-artist" style={{ marginRight: 16 }}>
                  Reference URL(s)
                </span>
                <div style={{ display: 'block' }}>{referenceURLs}</div>
              </Col>
            </Row>
          ) : (
            ''
          )}

          {lightingRig ? (
            <Row style={{ marginTop: 30 }}>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '40px',
                }}
              >
                <span className="category-artist" style={{ marginRight: 16 }}>
                  Product Lighting Rig
                </span>
                <div className="note-bg-artist gray">
                  <span className="note-text blue">{lightingRig}</span>
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}

          {color !== '' || similarColor !== '' ? (
            <Row style={{ marginTop: 30 }}>
              {color != '' ? (
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '40px',
                  }}
                >
                  <span className="category-artist" style={{ marginRight: 16 }}>
                    Product Color
                  </span>
                  <div className="note-bg-artist gray">
                    <span className="note-text blue">{color}</span>
                  </div>
                </Col>
              ) : (
                ''
              )}
              {similarColor != '' ? (
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="category-artist" style={{ marginRight: 16 }}>
                    Closest Color Match
                  </span>
                  <div className="note-bg-artist gray">
                    <span className="note-text blue">{similarColor}</span>
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
          ) : (
            ''
          )}

          {materials.length !== 0 ? (
            <Row style={{ marginTop: 30 }}>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '40px',
                }}
              >
                <span className="category-artist" style={{ marginRight: 16 }}>
                  Product Materials
                </span>
                <div className="note-bg-artist gray">
                  <span className="note-text blue">
                    {materials.map((material, index) => {
                      if (index > 0) {
                        return ', ' + material;
                      } else {
                        return material;
                      }
                    })}
                  </span>
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}
          
          <Row style={{ marginTop: 30 }}>
            <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '40px',
                  }}
                >
                  <span className="category-artist" style={{ marginRight: 16 }}>
                    Requirements
                  </span>
                  <div className="note-bg-artist gray">
                    <span className="note-text blue">
                      {needToModel}
                    </span>
                  </div>
              </Col>
            </Row>

          {referenceImages.length !== 0 ? (
            <>
              <Col span={24} style={{ marginTop: 30, marginBottom: 20 }}>
                <span
                  className="category-artist"
                  style={{ marginRight: 16, fontSize: 18 }}
                >
                  Reference Photos
                </span>
              </Col>
              <Row style={{ display: 'flex' }}>
                {referenceImages.map((item, index) => (
                  <Card className="product-artist-card" key={index}>
                    <Image.PreviewGroup>
                      <Image
                        className="product-photo-artist"
                        key={index}
                        src={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + item.uid + '/' + item.name}
                      />
                    </Image.PreviewGroup>
                  </Card>
                ))}
              </Row>
            </>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
}


export default ModelInformation;
