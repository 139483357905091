import React, {useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import DottedLoader from "../../DottedLoader";
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Card, Tooltip } from 'antd';
import {getCollectionProducts, getCollectionRooms, deleteCollectionProduct} from "../../../../redux/actions";
import {connect} from "react-redux";
import ENVIRONMENT from '../../../../environments';
import { ReactComponent as RestoreIcon } from '../../../../icons/arrows-counter-clockwise.svg';

import {withRouter, Link} from 'react-router-dom'

const SceneCreatorRestoreProducts = (props) => {
    const { match: { params } } = props;
    const [isLoading,setIsLoading] = useState(false);

    return (
        <span>
        <Row >
            <div style={{display: "flex", justifyContent: "space-between",alignItems:"baseline", marginBottom: 16}}>
                    <span className='manrope f-18 w-700'>Restore Product Models </span>
            </div>
        </Row>
        <Row gutter={12} className="sc-products-tab-content-scrollable">
                {props.collectionProducts.filter((ele) => props.deleted_asset_ids.includes(ele.id.toString())).map((ele, index) => {     
                    return (
                        <Col lg={6} sm={24} md={8} key={index} offset={0}>
                            <Card bodyStyle={{padding: "10px"}} className="card-shadow-product" bordered={false} style={{marginRight: "20px", marginBottom: "20px"}}>
                                
                                <div className='restore-product-container' onClick={() => props.unHideItem(ele.id)}>
                                    <div className='restore-product-btn'>
                                        <span><RestoreIcon /></span>
                                        <span className='restore-product-txt'>Restore</span>
                                    </div>
                                </div>
                                    <div className="box">    
                                        <img style={{objectFit: "contain",height:"275px",zIndex:1}} className="product-image" src={ele.thumbnail || require("../../../../assets/images/chair.png")} />
                                    </div>
                                    <hr style={{margin: 0}}/>
                                    <div className='justify-in-start' style= {{margin:"12px 12px 8px 12px"}}>
                                        <Tooltip title={<span className='manrope f-12 white'>{ele.name}</span>}>
                                            <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-50">
                                            {ele.name}
                                            </span>
                                        </Tooltip>
                                        
                                    </div>
                                    <div className="justify-in-start"> 
                                        <div className='manrope f-12 grey-77 capitalize' style={{marginLeft:"10px",marginBottom:"10px"}}>Category: <span className="manrope f-12 black-33 capitalize">{ele.category}</span></div> 
                                    </div>
                                    
                            </Card>
                        </Col>
                    );
                })}
            </Row>
            </span>
        );
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getCollectionProducts: (payload) => {
        dispatch(getCollectionProducts(payload));
    },
    getCollectionRooms: (payload) => {
        dispatch(getCollectionRooms(payload));
    },
    deleteCollectionProduct: (payload) => {
        dispatch(deleteCollectionProduct(payload));
    }
    
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(SceneCreatorRestoreProducts)
)
