import React from "react";
import "antd/dist/antd.css";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Input } from "antd";
import AdminMainLayout from '../AdminMainLayout';
import AdminCategoryTree from '../AdminCategoryTree';
import AdminCategoryOther from '../AdminCategoryOther';

const { TabPane } = Tabs;

class AdminCategories extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"5"}>
        <Input
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Search Categories"
            onChange={this.changeUsername}
        />
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} centered animated={false}>
          <TabPane tab="System Categories" key="1">
                <AdminCategoryTree />
          </TabPane>
          <TabPane tab="Customer Specific Categories" key="2">          
                <AdminCategoryOther />
          </TabPane>

        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminCategories;