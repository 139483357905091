import React from "react";
import { Tree } from "antd";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'


const treeData = [
    {
      title: 'parent 1',
      key: '0-0',
      children: [
        {
          title: 'parent 1-0',
          key: '0-0-0',
          disabled: true,
          children: [
            {
              title: 'leaf',
              key: '0-0-0-0',
            },
            {
              title: 'leaf',
              key: '0-0-0-1',
            },
          ],
        },
        {
          title: 'parent 1-1',
          key: '0-0-1',
          children: [
            {
              title: (
                <span
                  style={{
                    color: '#1890ff',
                  }}
                >
                  sss
                </span>
              ),
              key: '0-0-1-0',
            },
          ],
        },
      ],
    },
    {
        title: 'parent 2',
        key: '1-0',
    }
  ];

function getCategoriesData( catDataAray ){
    var catDataDict = {};

    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id == "") {
            catDataDict[ catDataAray[ i - 1 ].category_id ] = []
        }  
    }

    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id != "") {

            catDataDict[ catDataAray[ i - 1 ].parent_id ].push( {
                    title: catDataAray[ i - 1 ].name,
                    key: catDataAray[ i - 1 ].category_id,
                }
            );

        }  
    }

    return catDataDict;
}

class AdminCategoryTree extends React.Component {
    state = {
      treeData: {}
    };

    componentDidMount() {

      const payload = {
        output: 'tree',
        disable_parents: 'true'
      };
      axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
      .then(res => {
        this.setState({
          treeData: res.data
        })
      });

    }

    onChange = e => {
        this.setState({ size: e.target.value });
    };

    onSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
    };

    render() {
        const { size } = this.state;
        return (
            <Tree
                onSelect={this.onSelect}
                treeData={this.state.treeData}
            />
        );
    }
}

export default AdminCategoryTree;