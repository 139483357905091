import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ENVIRONMENT from "../../../../environments";
import CustomHeader from "../../CustomHeader";
import { Layout } from "antd";
import FileConstants from "../../../../FileConstants";
const { TabPane } = Tabs;

const { Header, Content } = Layout;
function TestArtistJobDesk() {
  const [status, setStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [jobDeskData, setJobDeskData] = useState([]);
  const [displayingProps, setDisplayingProps] = useState(null)
  const [note, setNote] = useState('')

  useEffect(() => {
    const payload = {
      username: localStorage.getItem("username"),
    };
    fetchJobDeskData();
  }, []);

  const fetchJobDeskData = () => {
    setIsLoading(true);
    axios.post(ENVIRONMENT.GET_TEST_ARTIST_JOB_DESK, {}).then((res) => {
      let models = res.data.props.concat(res.data.test_models)
      setJobDeskData(models)
      setIsLoading(false);
    });
  };

  const columns = [
    {
      title: "Model Name",
      key: 1,
      dataIndex: "product_name"
    },
    {
      title: "Category",
      key: 2,
      dataIndex: "category"
    },
    {
      title: "Model Type",
      key: 3,
      dataIndex: "need_to_model",
      render: text => <span>{FileConstants.MODEL_TYPE_DESCRIPTION[text]}</span>
    },
    {
        title: "",
        key: 4,
        dataIndex: "id",
        render: text => <a href={"/test_model_details/" + text}>View Details</a>
    }
  ];

  return (
    <>
      <Header className="header">
        <CustomHeader></CustomHeader>
      </Header>
      <Layout style={{ minHeight: "calc(100vh - 55px)" }}>
        <Content
          className="layout-container layout-snow"
          id="layout-section"
        >
          <h3 style={{ marginTop: 15, marginBottom: 15 }}>
            Choose Your Test Model
          </h3>
        <div>
            <Table
            columns={columns}
            dataSource={jobDeskData}
            bordered={true}
            loading={{indicator: <LoadingOutlined/>, spinning: isLoading}}
            />
        </div>
        </Content>
      </Layout>
    </>
  );
}

export default TestArtistJobDesk;
