import React from 'react';
import 'antd/dist/antd.css';
import ArtistMainLayout from '../ArtistMainLayout';
import ArtistsRoomsData from '../ArtistRoomsData';

class ArtistRoomsView extends React.Component {
    render() {
        return (
            <ArtistMainLayout selected='2'>
                <ArtistsRoomsData />
            </ArtistMainLayout>
        );
    }
}

export default ArtistRoomsView;
