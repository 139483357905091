import React, { useEffect, useState }  from 'react';

const ChangeLogConstants = new Object();

ChangeLogConstants.CHANGE_LOG = {
    'active_key': 'revamped-home-page',
    'feature_detail' : [{
    'key': 'product_collab_qa_change',
    'feature_name': 'Product Collaborate QA',
    'feature_type': 'New Feature',
    'main_action': 'Start Reviewing',
    'route': '/home',
    'detail': [{
        'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/1.png',
        'heading': 'Product QA: Now With Image Markup & Collaboration' ,
        'text': 'Reviewing your 3D product models is now easier than ever! And, we’ve streamlined the process for teams with collaboration mode.',
        'order': 1
    }, {
        'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/2.png',
        'heading': 'Easily compare models and reference images at the same angle with auto-matched views.' ,
        'text': 'The appropriate reference image will automatically be paired side-by-side with the same model angle for seamless annotations.',
        'order': 2
    }, {
        'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/3.png',
        'heading': 'Annotate your notes directly onto images to easily communicate your changes in context.' ,
        'text': 'Every model angle and side-by-side comparison are available for image markup making it easy to draw, write, or comment on any angle of your product.',
        'order': 3
    }, {
        'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/4.png',
        'heading': 'Invite your team to collaborate in the QA process and keep everyone in the loop' ,
        'text': 'Add people directly in any message thread or invite an individual or saved group to a specific product.',
        'order': 4
    }]}],   
    'feature_detail' : [{
        'key': 'revamped-home-page',
        'feature_name': 'Revamped Home Page',
        'feature_type': 'New Feature',
        'main_action': 'Continue',
        'route': '/home',
        'detail': [{
            'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/Frame 4.png',
            'heading': 'Revamped Homepage' ,
            'text': 'Our redesigned homepage now provides more comprehensive statistics, recent activity insights, and enhanced quick-action capabilities',
            'order': 1
        }, {
            'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/home-page-1.png',
            'heading': '' ,
            'text': 'Keep tabs on your libraries and easily access them to browse items or create new models.',
            'order': 2
        }, {
            'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/home-page-2.png',
            'heading': '' ,
            'text': "Quickly check your model's status at a glance. See what needs review, what's in progress with ALL3D, and any drafts of models that need more info.",
            'order': 3
        }, {
            'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/home-page-3.jpg',
            'heading': '' ,
            'text': 'Easily view image count and resolution breakdown for all generated and pending images.',
            'order': 4
        }, {
            'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/home-page-4.jpg',
            'heading': '' ,
            'text': 'Quickly access your most recent scenes and collaborations.',
            'order': 5
        }, {
            'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/home-page-5.png',
            'heading': '' ,
            'text': 'View your recently approved models to quickly generate assets.',
            'order': 6
        }, {
            'illustration_link': 'https://d48f7equ64qjl.cloudfront.net/static_files/change_log_images/home-page-6.jpg',
            'heading': '' ,
            'text': <span>Create projects to organize your models, designs, and deliverables. Watch this <a href='https://youtu.be/TCQvcM1FN0A' target="_blank">video</a> to learn more.</span>,
            'order': 7
        }
    ]}]

}




export default ChangeLogConstants;