import 'rxjs-compat/add/operator/switchMap';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/catch';
import { ajax } from 'rxjs-compat/observable/dom/ajax';
import ENVIRONMENT from '../../environments'

import {
  FETCH_ROOM,
  GET_SCANNED_ROOMS,
  RENDER_ROOM,
  CREATE_ROOM,
  LIST_ROOM,
  UPDATE_ROOM,
  REJECT_ROOM,
  roomFailureAction,
  roomSuccessAction,
  roomRejectSuccessAction,
  
} from '../actions';

export const fetchRoom = (action$) => {
  return action$
      .ofType(FETCH_ROOM)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.FETCH_ROOM,
            payload, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => roomSuccessAction({ ...data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(roomFailureAction({ message: 'Unable to get room.' })));
      });
};

export const renderRoom = (action$) => {
  return action$
      .ofType(RENDER_ROOM)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.RENDER_ROOM,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => roomSuccessAction({ ...data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(roomFailureAction({ message: 'Unable to get room.' })));
      });
};


export const listRooms = (action$) => {
  return action$
      .ofType(LIST_ROOM)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.LIST_ROOMS,
            { ...payload, username : localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => roomSuccessAction({ rooms: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(roomFailureAction({ message: 'Unable to create room. Fill missing fields.' })));
      });
};

export const getScannedRooms = (action$) => {
  return action$
      .ofType(GET_SCANNED_ROOMS)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.GET_SCANNED_ROOMS,
            { username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => roomSuccessAction({ scannedRooms: data.response.models, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(roomFailureAction({ message: 'Unable to get room' })));
      });
};


export const createRoom = (action$) => {
  return action$
      .ofType(CREATE_ROOM)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.CREATE_ROOM,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => roomSuccessAction({ ...data.response, isCreated: true, isUpdated: false }))
            .catch((error) => Promise.resolve(roomFailureAction({ message: 'Unable to create room. Fill missing fields.' })));
      });
};


export const updateRoom = (action$) => {
  return action$
      .ofType(UPDATE_ROOM)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.UPDATE_ROOM,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => roomSuccessAction({ ...data.response, isCreated: false, isUpdated: true }))
            .catch((error) => Promise.resolve(roomFailureAction({ message: 'Unable to update room. Fill missing fields.' })));
      });
};

export const rejectRoom = (action$) => {
  return action$
      .ofType(REJECT_ROOM)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.ENTITY_MODEL_REJECT,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => roomRejectSuccessAction({ ...data.response, isCreated: false, isUpdated: false }))
            // .map( (data) => getProductRender(payload))
            .catch((error) => Promise.resolve(roomFailureAction({ message: 'Unable to update room. Fill missing fields.' })));
      });
};
