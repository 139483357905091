import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Input} from "antd";
import AdminMainLayout from '../AdminMainLayout';
import AdminMaterialTree from '../AdminMaterialTree';

const { TabPane } = Tabs;

class AdminMaterials extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"10"}>
        <Input
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Search Materials"
            onChange={this.changeUsername}
        />
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} centered animated={false}>
          <TabPane tab="System Materials" key="1">
                <AdminMaterialTree />
          </TabPane>
          <TabPane tab="Customer Specific Materials" key="2">          
          </TabPane>

        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminMaterials;