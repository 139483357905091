import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import ArtistMainLayout from '../ArtistMainLayout';
import { Row, Col, Input, Button, Upload, Modal} from 'antd';
import * as Constants from '../../CustomerComponents/Constants'
import { getProductRender, updateProduct } from "../../../../redux/actions";
import MaterialComponents from '../../MaterialComponents/MaterialComponents';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { UPDATE_MATERIAL, MATERIAL_GET } from '../../../../environments/env';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import * as JSZip from 'jszip';
import { LoadingOutlined } from '@ant-design/icons';
import SuccessModal from '../../SuccessModal/SuccessModal';
import FileConstants from '../../../../FileConstants'
import { skip } from 'rxjs/operators';
import NotAuthorized from '../NotAuthorized';
import DottedLoader from '../../DottedLoader';
const { Dragger } = Upload;

// the following data is used for running validations on the material qa renders uploaded by the artists
const ORDER_OF_RENDERS = ['Front.jpg', 'Details.jpg', 'Swatch.jpg']
const THUMBNAIL_FILENAME = 'thumbnail'

// Declare the required number of renders that artist needs to upload
const REQUIRED_MATERIAL_RENDERS_COUNT = 2

const ArtistMaterialGuidelines = (props) => {
  const [materialFile, setMaterialFile] = useState([]);
  const [timeModal, setTimeModal] = useState(false);
  const [time, setTime] = useState(0);
  const [rejectionData, setRejectionData] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [rejectedState, setRejectedState] = useState(false);
  const [materialData, setMaterialData] = useState('');
  const [fileName, setFileName] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [, updateState] = React.useState();
  const [timeInvalidError, setTimeInvalidError] = useState('');
  const [materialStatus, setMaterialStatus] = useState(-1);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [productDetails, setProductDetails] = useState([]);
  const [productID, setProductID] = useState(-1);
  const [productData,setProductData] = useState([]);
  const [exportedMaterial, setExportedMaterial] = useState(false);
  const [materialGuidelinesPage, setMaterialGuidelinesPage] = useState(false)
  const [materialRenders, setMaterialRenders] = useState([]);
  const [materialRenderUploadError, setMaterialRenderUploadError] = useState('');
  const [materialRequiredOutput, setMaterialRequiredOutput] = useState('')
  const [materialRequiredOutputLabel, setMaterialRequiredOutputLabel] = useState('')
  const [validMaterialRenders, setValidMaterialRenders] = useState(false);
  const [pageLoader,setPageLoader] = useState(false);
  const [platform, setPlatform] = useState('aws');
  const { match: { params } } = props;

  const handleChange = (info) => {
    Constants.updateMaterialID(params.id);
    let fileList = [...info.fileList];
    setMaterialFile(fileList)
  }

  useEffect(() => {
    if(window.location.href.includes('material_guidelines'))
      setMaterialGuidelinesPage(true);
  }, [])

  useEffect(() => {
    setPageLoader(true);
    let payload = {
      material_id: params.id
    };
    axios.post(MATERIAL_GET, payload )
    .then(res => {
      let material_data = JSON.parse(res.data.body);
      setPlatform(material_data.platform)
      if (!Array.isArray(material_data.product_id)) {
        fetchProductData(material_data.product_id);
        setProductID(material_data.product_id);
      }
      loadRejectionData(params.id);
      setMaterialData(material_data);
      console.log(material_data);
      setMaterialStatus(parseInt(material_data.status));
      setMaterialRequiredOutput(material_data.material_output)
      setMaterialRequiredOutputLabel(FileConstants.MATERIAL_TYPE_LABELS[material_data.material_output])
      if (material_data.status == -4) {
        setRejectedState(true);
      }
      forceUpdate();
      setPageLoader(false);
    });
  },[]);

  const fetchProductData = (id) => {
    console.log(id);
    props.getProduct({ product_id: id, admin: true, debug: true });
    axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id: id })
        .then(res => {
          console.log(res.data)
          setProductData(res.data);
          setExportedMaterial(true);
        });
  }

  const loadRejectionData = (id) => {
    console.log('in load rejection data, ', id)
    let payload = {
      material_id: id
    };
    console.log('Rejection Payload',payload);
    axios.post(ENVIRONMENT.MATERIAL_REJECTIONS_GET_BATCH, payload )
    .then(res => {
        console.log(res.data);
        setRejectionData(res.data);
    });
  }

  useEffect(() => {
    console.log(props.productReducer.product)
    setProductDetails(props.productReducer.product);
  },[props.productReducer.product])

  const validateFileUploads = (files) => {
    let status_done = false;
    if (files != undefined) {
        for(var file of files) {
            if (file['status'] == 'uploading') {
                return 'uploading';
            }
            else if (file['status'] == 'error') {
                return 'error';
            }
            else if (file['status'] == 'done') {
                status_done = true;
            }
        }
    }
    if (status_done) {
        return 'done'
    }
    return 'not_started';
  }

  const openTimeModal = () => {
    setTimeModal(true);
  }

  const constructRendersPayload = () => {
    let payload = {
      'thumbnail': '',
      'QA_renders': [],
      'artist_renders': ''
    }
    let artist_renders = [];
    for(let val of materialRenders){
      if(val.name.split('.')[0].toLowerCase() == THUMBNAIL_FILENAME.toLowerCase())
        payload['thumbnail'] = val.name;
      else
        artist_renders.push(val.name);
    }
    // Sort the array based on the order
    let sorted_renders = ORDER_OF_RENDERS.filter(a => artist_renders.includes(a));

    // Append the extra renders in the sorted array
    for(let val of artist_renders){
      if(!sorted_renders.includes(val)){
          sorted_renders.push(val);
      }
    }
    payload['artist_renders'] = sorted_renders;

    return payload;
  }

  const submitMaterial = () => {
    let payload = {
      material_id: params.id,
      status: "3",
      artist_time: time,
      input_bundle: params.id + '/' + fileName,
      assigned_artist: localStorage.getItem("username")
    };
    payload['renders'] = constructRendersPayload();
    console.log({payload});
    if (time >= 0.01 && time <=30) {
      setTimeInvalidError('');
      if (validateFileUploads(materialFile) == "error") {
        setUploadError('An error occured while trying to upload file. Please try again.')
      } else if (validateFileUploads(materialFile) == "uploading") {
        setUploadError('Please wait while upload is still in progress.')
      } else if (validateFileUploads(materialFile) == "done") {
        setUploadError('');
        setButtonLoader(true);
        axios.post(UPDATE_MATERIAL,payload)
        .then(res => {
          console.log(res);
          setTimeModal(false);
          setButtonLoader(false);
          setSuccessModal(true);
        });
      }
    } else {
      setTimeInvalidError('Please enter a valid time value. The value you enter should be greater than 0 and less than 30.');
    }

  }

  const changeTime = (e) => {
    setTime(e.target.value);
  }

  const closeTimeModal = () => {
    setTimeModal(false);
  }


  const validateUploadedMaterialRenders = (fileList) => {
    let filenames = [];
    for(let val of fileList){
      filenames.push(val.name.split('.')[0].toLowerCase())
    }
    if( (FileConstants.containsVRAYMaterial(materialRequiredOutput)|| FileConstants.containsHighPBRMaterial(materialRequiredOutput) )
          && !filenames.includes(THUMBNAIL_FILENAME)){
      let error_message = "Your uploaded files are missing the following: thumbnail.jpg"
      setMaterialRenderUploadError(error_message);
    }
    else{
      setMaterialRenderUploadError("");
      setValidMaterialRenders(true)
    }
  }

  // Highlight renders that are required but not uploaded by the artist
  const highlightRequiredNonUploadedRenders = (requiredMaterialOutputs, rendersCount) => {
    let requiredRenders = ''
    for (let materialOutput in requiredMaterialOutputs) {
      if (!rendersCount[requiredMaterialOutputs[materialOutput]]
        || rendersCount[requiredMaterialOutputs[materialOutput]] < 2) {
        if (!requiredRenders) {
          requiredRenders = REQUIRED_MATERIAL_RENDERS_COUNT + ' ' + FileConstants.MATERIAL_TYPE_LABELS[requiredMaterialOutputs[materialOutput]]
        }
        else {
          requiredRenders += ' and '+ REQUIRED_MATERIAL_RENDERS_COUNT + ' ' + FileConstants.MATERIAL_TYPE_LABELS[requiredMaterialOutputs[materialOutput]]
        }
      }
    }
    let errorMessage = ''
    if (requiredRenders) {
      errorMessage = "You must upload atleast " + requiredRenders + " material renders."
    }
    return errorMessage
  }

  // Highlight non required renders uploaded by artists belonging to different categroy
  const highlightNonRequiredUploadedRenders = (requiredMaterialOutputs, rendersCount) => {
    let invalidUploadedRenders = ''
    let rendersMaterialCategories = Object.keys(rendersCount);
    for (let materialOutput in rendersMaterialCategories) {
      if (!requiredMaterialOutputs.includes(rendersMaterialCategories[materialOutput])) {
        if (!invalidUploadedRenders) {
          invalidUploadedRenders += FileConstants.MATERIAL_TYPE_LABELS[rendersMaterialCategories[materialOutput]]
        }
        else {
          invalidUploadedRenders += ' and ' + FileConstants.MATERIAL_TYPE_LABELS[rendersMaterialCategories[materialOutput]]
        }

      }
    }
    let errorMessage = ''
    if (invalidUploadedRenders) {
      errorMessage =  "You have uploaded " + invalidUploadedRenders + " renders when only " + materialRequiredOutputLabel + " are required. Please remove them."
    }
    return errorMessage;
  }

  const handleMaterialRendersUpload = info => {
    Constants.updateMaterialID(params.id);
    let fileList = info.fileList;
    let errorMessage =''
    if (fileList != undefined ) {
      setMaterialRenders(fileList);
      if (materialData.material_output != 'pbr'){
        // Get count of materials uploaded against each material type
        let rendersCount = {};
        for (let i in fileList) {
          let filename = fileList[i].name
          if (!filename.includes(THUMBNAIL_FILENAME)) {
            let materialRenderType = FileConstants.materialRenderTypeResolution(filename)
            if (!rendersCount[materialRenderType]) {
              rendersCount[materialRenderType] = [];
            }
            rendersCount[materialRenderType] += 1;
          }
        }
        // Highlight missing required valid and invalid uploaded renders
        let requiredMaterialOutputs = FileConstants.MATERIAL_TYPE_REQUIRED_OUTPUTS[materialRequiredOutput];
        errorMessage = highlightRequiredNonUploadedRenders(requiredMaterialOutputs,rendersCount);
        errorMessage += highlightNonRequiredUploadedRenders(requiredMaterialOutputs,rendersCount)
        setMaterialRenderUploadError(errorMessage);
      }
      if (!errorMessage){
        validateUploadedMaterialRenders(fileList);
      }
      else {
        setValidMaterialRenders(false)
      }
    }
  }

  return ( pageLoader ? <DottedLoader/> : <ArtistMainLayout selected="6">
  {materialData?.assigned_artist == localStorage.getItem("username") ? <React.Fragment>
    <Row style={{marginTop: 48}}>
      <Col span={20}>
        <div className="manrope f-32 black-33" style={{marginBottom: 35,textTransform:"capitalize"}}>{materialData.name}</div>
        {(materialData.status == "-4" || materialData.status == "2" && !materialGuidelinesPage) ? <div style={{display:"block"}}>
        <span className="note-bg-artist pd-8 gray manrope f-16 black-55" >
            Required Output: {materialRequiredOutputLabel}
        </span>
        <div style={{marginTop: 20}}>
          <h4 >Guidelines <br></br></h4>
          {FileConstants.containsVRAYMaterial(materialRequiredOutput) ? <div style={{marginTop: 10}}><span className="note-bg-artist pd-8 gray manrope f-16 black-55 capitalize" >For VRAY: </span><br></br>
          <span>-  You need to upload the model archive and the material renders generated by using the provided plugin.
          <br></br>-  You can download Material Export and Render Plugin and Video Tutorial by clicking <a href={ENVIRONMENT.MATERIAL_EXPORT_AND_RENDER_PLUGIN}>here</a>
          <br></br>-Visualization of your material will be in Real World Scale, set tiling accordingly.
          <br></br></span></div> : ''}
          {FileConstants.containsPBRMaterial(materialRequiredOutput) ? <div style={{marginTop: 10, marginBottom: 10}}><span className="note-bg-artist pd-8 gray manrope f-16 black-55 capitalize" >For PBR: </span><br></br>
          <span>- Read these <a href={ENVIRONMENT.MATERIAL_GUIDELINES} target='_blank'>Guidelines</a> before starting to make the material!
          <br></br>- You can use the PBR tool to test your material <a href={ENVIRONMENT.PBR_MATERIAL_RENDERS_TOOL} target='_blank'>here</a>.
          <br></br>-Visualization of your material will be in Real World Scale, set tiling accordingly.
          <br></br></span></div>
          :
          ''
          }
          {
          FileConstants.containsHighPBRMaterial(materialRequiredOutput) ?
          <div style={{marginTop: 10}}><span className="note-bg-artist pd-8 gray manrope f-16 black-55 capitalize" >For VRAY: </span><br></br>
          <span>-  You need to upload the model archive and the material renders generated by using the provided plugin.
          <br></br>-  You can download Material Export and Render Plugin and Video Tutorial by clicking <a href={ENVIRONMENT.MATERIAL_EXPORT_AND_RENDER_PLUGIN}>here</a>
          <br></br>- Read these <a href={ENVIRONMENT.MATERIAL_GUIDELINES} target='_blank'>Guidelines</a> before starting to make the material!
          <br></br>- Setup the Vray Material using PBR Textures, with BRDF set to "use roughness" as shown in the <a href={ENVIRONMENT.MATERIAL_GUIDELINES} target='_blank'>Guidelines</a>.
          <br></br>- Make sure that you only use bitmaps only.
          <br></br>- For Diffuse, Roughness and Metalness, make sure that you attach bitmaps directly into the material. Do not use any other nodes such as Fall-off, Color Correction etc. in between.
          <br></br>- For the normal map, use a Vray Normal Map and attach the bitmap into the "Normal" slot. Then Feed the Vray Normal Map into the "Bump" slot of the Vray Material.
          <br></br>-Visualization of your material will be in Real World Scale, set tiling accordingly.
          <br></br></span></div>
          :
          ''
          }
        </div>
        {(materialData.status == "-4" || materialData.status == "2") ?<div style={{display:"block"}}>
          <h4>Material Archive</h4>
          <div className="dragger-upload-div" style={{padding:"20px"}}>
          <Dragger style={{display: 'inline-block', width: "100%"}}
              className="upload-dragger-artist"
              multiple={false}
              accept=".zip"
              progress= {{
                strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
                }}
              onRemove={file => {
                setMaterialFile([]);
                setUploadError("");
              }}
              beforeUpload={file => {
                setFileName(file.name);
                setUploadError('');
                if(!file.name.includes('.zip')){
                  setUploadError('Error! Please upload a zip file!')
                  return false;
                }
                else{
                  if(file.name != params.id + '.zip'){
                    setUploadError(`Invalid archive name! Archive must be named ${params.id}.zip`)
                    return false;
                  } else {
                    let new_zip = new JSZip();
                    return new_zip.loadAsync(file)
                    .then((zip) => {
                      let flag = false;
                      if (FileConstants.containsVRAYMaterial(materialRequiredOutput) || FileConstants.containsHighPBRMaterial(materialRequiredOutput)) {
                        for(let key in zip['files']){
                          let key_tag = key.split('/');
                          if ((key_tag[0] && key_tag[0] === 'High') && ( key_tag[1] && key_tag[1].split('.').pop()=== 'max' && (key_tag[1].split('.')[0]===params.id))  ) {
                            flag = true;
                          }
                        }
                        if (!flag) {
                          setUploadError(`Upload Error! Your zip file should contain a .max file in the High folder of the zip file. The max file must be named ${params.id}.max.`)
                          return false;
                        }
                      }

                      if (FileConstants.containsPBRMaterial(materialRequiredOutput)) {
                        flag = false;
                        let texturesJsonFlag = false;
                        for(let key in zip['files']){
                          let key_tag = key.split('/');
                          if (key_tag[0] && key_tag[0] === 'PBRTextures') {
                            flag = true;
                          }
                          if(key.includes('material_info.json')){
                            texturesJsonFlag = true;
                          }
                        }
                        if (!flag) {
                          setUploadError(`Upload Error! Your zip file should contain a folder named PBRTextures containing PBR Materials`)
                          return false;
                        }
                        if(!texturesJsonFlag){
                          setUploadError(`Upload Error! Your zip file should contain a material_info.json file, as exported by the plugin.`)
                          return false;
                        }
                        flag = false;
                        for(let key in zip['files']){
                          let key_tag = key.split('/');
                          if ((key_tag[0] && key_tag[0] === 'Low') && ( key_tag[1] && key_tag[1].split('.').pop()=== 'max' && (key_tag[1].split('.')[0]===params.id))  ) {
                            flag = true;
                          }
                        }
                        if (!flag) {
                          setUploadError(`Upload Error! Your zip file should contain a .max file in the Low folder of the zip file. The max file must be named ${params.id}.max.`)
                          return false;
                        }
                      }
                      return true;
                    });
                  }
                }
              }}

              {...Constants.getMaterialUploadProps(platform)}
              onChange = {handleChange}
              listType="text"
              fileList = {materialFile}>
                {validateFileUploads(materialFile) == "uploading"  ?
                    <div className="justify-in-center">
                        <p className="justify-in-center artist-upload-txt large">Upload in Progress... </p>
                    </div>  :
                    validateFileUploads(materialFile) == "error" || uploadError != "" ?
                    <span>
                        <div className="justify-in-center">
                            <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                            <p className="artist-upload-txt large" style={{color:"#D93025"}}>Upload Failed</p>
                        </div>
                    </span>
                    :
                validateFileUploads(materialFile) == "done"  ?
                    <span>
                        <div className="justify-in-center">
                            <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                            <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                        </div>
                    </span>:
                  (validateFileUploads(materialFile) == "not_started" ?
                      <span>
                        <p className="ant-upload-drag-icon">
                        <img src="/img/exclude.png"></img>
                      </p>
                      <p className="artist-upload-txt">Drop your material archive folder here or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                      <p className="artist-upload-txt small">
                      Only .zip files are supported
                      </p>
                    </span>: '')}
            </Dragger>
          </div>

          {/* Material QA Renders */
          materialData.material_output.includes('vray') || materialData.material_output.includes('high_pbr')?
          <>
          <h4 style={{marginTop: 10}}>Material Renders</h4>
          <div style={{padding:"20px", marginBottom: 10}} className="dragger-upload-div">
              <Dragger multiple={true}
              className="upload-dragger-artist"
              accept=".jpg,.jpeg,.png,.json"
              progress= {{
                strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
                },
                strokeWidth: 3,
                format: percent => `${parseFloat(percent.toFixed(2))}%`,
                }}
              {...(Constants.getMaterialQARenderUploadProp(platform))}
              onChange = {handleMaterialRendersUpload}
              onRemove={file => {
                  const index = materialRenders.indexOf(file);
                  const newFileList = materialRenders.slice();
                  newFileList.splice(index, 1);
                  setMaterialRenders(newFileList);
                  // updateMaterialRenderFileNames(newFileList);
              }}
              listType="text"
              fileList = {materialRenders}>
                {validateFileUploads(materialRenders) == "uploading"  ?
                  <span>
                    <p className="artist-upload-txt large">Upload in Progress... </p>
                  </span> :
                  validateFileUploads(materialRenders) == "done" ?
                  <span>
                    <div className="justify-in-center">
                      <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                      <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                    </div>
                  </span> :
                <span>
                  <p className="ant-upload-drag-icon">
                  <img src="/img/exclude.png"></img>
                  </p>

                  <p className="artist-upload-txt">Drop your <span style={{color:"#333333"}}> material renders </span> here, or <span style={{color: "#276DD7"}}>Browse them</span>.</p>
                  <p className="artist-upload-txt small">
                    Only .PNG, .JPEG and .JPG files are supported  .
                  </p>
                </span>}
            </Dragger>
          </div>
          </>
          :
          ''
          }
        </div>: ''}
        {materialRenderUploadError == "" ? "" :
          <div style={{marginBottom: 8}}>
            <span className="category-artist red">{materialRenderUploadError}</span>
          </div>}
        {validateFileUploads(materialFile) == "error" || uploadError != "" ? <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginTop: 16,marginRight: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{uploadError}</span></div>: ''}
        {validateFileUploads(materialFile) == "not_started" && (FileConstants.containsVRAYMaterial(materialRequiredOutput) || FileConstants.containsHighPBRMaterial(materialRequiredOutput)) && !materialGuidelinesPage &&  (materialData.status == "-4" || materialData.status == "2") || validateFileUploads(materialFile) == "uploading" ? <div className="manrope f-16 blue" style={{marginTop: 16}}>Please make sure that you process your VRAY material using Material Export and Render Plugin before uploading.</div>: ''}
        {validateFileUploads(materialFile) == "not_started" && FileConstants.containsPBRMaterial(materialRequiredOutput) && !materialGuidelinesPage &&  (materialData.status == "-4" || materialData.status == "2") || validateFileUploads(materialFile) == "uploading" ? <div className="manrope f-16 blue" style={{marginTop: 16}}>Please make sure that you test your PBR material using Material Test tool before uploading.</div>: ''}
        { (materialData.material_output.includes('vray') || materialData.material_output.includes('high_pbr') ? validMaterialRenders : true ) && validateFileUploads(materialFile) == "done" ?
        <Row style={{marginTop: 16}}>
          <Col span={24} className="justify-in-end" >
            <Button className="modal-okay square font-16" disabled={buttonLoader || uploadError != "" || materialRenderUploadError != ""} onClick={openTimeModal}>
                Submit
            </Button>
          </Col>
        </Row>: ''}
        </div> : ''}
        {materialData && materialRequiredOutputLabel?
        <MaterialComponents guidelines_page={materialGuidelinesPage} rejectionData={rejectionData} artist={true} material_data={materialData} materialRequiredOutput={materialRequiredOutputLabel} exportedMaterial={exportedMaterial} productDetails={productDetails} productID={productID} productData={productData}/>: ''}
      </Col>
    </Row>

    <Modal
      className="aspect-ratio-modal small"
      destroyOnClose={false}
      visible={timeModal}
      maskClosable={true}
      onCancel={closeTimeModal}
      footer={[
        <span className="justify-in-end">
          <Button className="modal-okay square font-12" disabled={buttonLoader} htmlType="submit" onClick={submitMaterial}>
            Submit {buttonLoader ? <LoadingOutlined/> : ''}
          </Button>
        </span>
      ]}
    >
      <div style={{padding:"32px 32px 24px 32px"}}>
        <div className="manrope f-18 black-55" style={{marginBottom: 24}}>
          {materialStatus == -4 ? <span>Enter Fix Time (In Hours)</span> : <span>Enter Time (In Hours)</span>}
        </div>

          <Input
          onChange={changeTime}
          type="number"
          min="0.01" max="30"
          step="0.01"
          placeholder={`Please enter time (in hours) it took to ${materialStatus == -4 ? `fix` : `create`} this material.`} className="manrope f-12 library-search"/>
          {timeInvalidError != "" ? <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginTop: 16,width:'auto'}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{timeInvalidError}</span></div>: ''}

      </div>
    </Modal>

    <SuccessModal
    onCancel={() => setSuccessModal(false)}
    visible={successModal}
    heading={"Material File and Renders are Submitted Successfully!"}
    text={"The material has been submitted. Admin will review the materials shortly."}
    footer={[
      <div className="justify-in-center">
        <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
        window.location.reload();
        }}>
            Okay
        </Button>
      </div>
    ]}
    />
  </React.Fragment>
  :
  <NotAuthorized model_type="material" ></NotAuthorized>}
  </ArtistMainLayout>)


}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    updateProduct: (product) => {
        dispatch(updateProduct(product));
    }
});

export default (withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtistMaterialGuidelines)
));