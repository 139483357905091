import React, { useState } from 'react';
import { Checkbox, Modal} from 'antd';
import * as Utilities from "../Utilities";
 
const HelpMessageModal = (props) => {

    const [helpCheckbox, setHelpCheckbox] = useState(Utilities.getCookie(props.cookie_name) ? true : false);

    const onCancelModal = (defaultVal = 1) => {
        props.onCancel(defaultVal);
    }

    const showAgain = (e) => {
        console.log(e);
        setHelpCheckbox(e.target.checked);
        Utilities.setCookie(props.cookie_name, e.target.checked, 365)
    }

    return (
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={props.closable ? true : false}
        maskClosable={false}
        width={700}
        onCancel={onCancelModal}
        visible={props.visible}
        bodyStyle={{padding: 0}}
        footer={ <div className="justify-in-center">
            <Checkbox
                className='manrope f-14 black-00'
                checked={helpCheckbox}
                onChange={showAgain}>
                Don't Show Again
            </Checkbox>
        </div>}>
        <div style={{padding:"30px 30px 0 30px"}}>
            <div style={{textAlign: "center",margin: "20px auto"}}>
                <h2  className=" manrope f-16 black-00 w-600 justify-in-center" style={{marginBottom: 15}}>{props.text}</h2>
                {/* <p className=" manrope f-14 black-55" style={{margin: 'auto', width: '80%'}}>{props.text}</p> */}
                <div style={{textAlign: "center"}}>
                    <img style={{width: '100%',height: 300, objectFit: 'contain'}} src={props.help_image}></img>
                </div>
            </div>     
        </div>       
    </Modal>
      );
}

export default HelpMessageModal;