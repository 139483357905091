import React from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Tabs, Input } from "antd";

import RoomsTable from '../RoomsTable'

const { TabPane } = Tabs;

class RoomsData extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <div>
        <Input
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Search Products"
            onChange={this.changeUsername}
        />
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false}>
          <TabPane tab="FloorPlan" key="1">
            <RoomsTable />
          </TabPane>
          <TabPane tab="Models Pending" key="2">
            No items in this section
          </TabPane>
          <TabPane tab="QA" key="3">
            No items in this section
          </TabPane>
          <TabPane tab="Approved" key="4">
            No items in this section
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default RoomsData;