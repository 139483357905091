import React from 'react';
import { Collapse, Modal, Progress} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
 const {Panel} = Collapse
const InAppPurchaseLimitModal = (props) => {

    return (
        <Modal
        className="silo-success-modal"
        centered={true}
        closable={false}
        maskClosable={false}
        width={696}
        onCancel={props.onCancel}
        visible={props.visible}
        bodyStyle={{padding: 0}}
        footer={[
            <span style={{display: 'flex', justifyContent:'center'}}>
                <Button className="modal-okay-gray font-14 square" onClick={() => {
                    props.setVisible(false);
                }}>
                    Cancel
                </Button>
                <Button className="modal-okay font-14 square" onClick={() => {
                    window.open('https://all3d.ai/contact-us?origin=portal', '_blank');
                    props.setVisible(false);
                    }}>
                    Contact Us
                </Button>
            </span>
        ]}>
        <div style={{padding:"40px"}}>
            <div style={{textAlign: "center"}}>
                <img style={{width: 85,height:85}} src={require("../../../../assets/images/exclamation_icon.png")}></img>
            </div>

            <div style={{textAlign: "center",margin: "20px auto 12px auto"}}>
                <h2  className="manrope f-24 blue w-700 justify-in-center">
                    Limit Exceeded
                </h2>
                <p className="manrope f-18 grey-77">In-app purchases apply only to limited quantities. You have exceeded the limit for this service. Please contact us for enterprise subscription plans.</p>
            </div> 
        </div>
    </Modal>
      );
}

export default InAppPurchaseLimitModal;