import React, { useContext, useState } from 'react';
import _ from "lodash";
import axios from 'axios';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Button, Row, Col, Form, Select, InputNumber, Modal, Radio, message } from 'antd';
import ENVIRONMENT from '../../../../environments';
import SuccessModal from '../../SuccessModal/SuccessModal';
import { LoadingOutlined } from '@ant-design/icons';
import * as Styles from '../../../../Styles';

const { Option } = Select;

const radioStyle = Styles.radioStyle;

const ProductDimModal = () => {
    const { product_id, dimensionalDetailsModal, setDimensionalDetailsModal, requestStatus, cardStatus, productData, setStatusOfMp4Video,
        dimensionalPrice, selectedPriceCategory, setInAppLimitExceededModal, setPaymentFailureModal, setCardSaveFlowTriggered, setPaymentFailureMessage,
        cardSaveFlowTriggered, openDimensionalModal, openThreeSixtyModal, openVideoModal, customerDefaultDimensionSettings,
        customerDefaultDimDPI, customerDefaultDimResolution, statusLoader, currentId, variantSelected, setStatusLoader,
        setVariantSelected, setVariationProductData, setVariationAssetData, variationAssetData, setSavedVariationProductDetails, variationProductData
    } = useContext(CustomerProductContext);

    const [requestType, setRequestType] = useState('product_dimensional');
    const [dimensionalSuccessModal, setDimensionalSuccessModal] = useState(false);
    const [dimensionalResolution, setDimensionalResolution] = useState('1k');
    const [dimensionalImageType, setDimensionalImageType] = useState('system');
    const [dimensionalLoader, setDimensionalLoader] = useState(false);


    const changeDimRes = (value) => {
        console.log(value)
        setDimensionalResolution(value);
    }

    const handleDimensionalSubmit = values => {
        setDimensionalLoader(true);
        let payload = {}
        payload.product_id = variantSelected ? currentId : product_id;
        payload.username = localStorage.getItem("username");
        let dim_image_details = {
            dpi: values.custom_dimensional_dpi,
            resolution: values.custom_dimensional_resolution
        }

        if (dimensionalImageType == 'custom') {
            dim_image_details.unit = values.custom_dimensional_unit;
            dim_image_details.height = values.custom_dimensional_height;
            dim_image_details.width = values.custom_dimensional_width;
            dim_image_details.depth = values.custom_dimensional_depth;
        }

        //adding customer default settings in payload so can be sent to automation
        dim_image_details.customer_default_dim_settings = customerDefaultDimensionSettings;
        dim_image_details.captured_by = localStorage.getItem("username");
        dim_image_details.asset_status = 'pending';
        payload.dim_image_details = dim_image_details;
        payload.dim_job_status = 'generate'
        payload.generate_asset = 1
        let company_id = localStorage.getItem('company_id');
        let product_data = variantSelected ? variationProductData : productData;
        if (company_id != undefined && product_data.company_id != undefined) {
            if (company_id == product_data.company_id) {
                payload.company_id = company_id;
            }
        }
        setRequestType('product_dimensional');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_dimensional",
            "action": "perform",
            "request_payload": payload,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
            axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                .then(res => {
                    if (res.data.status != undefined && res.data.status == 1) {
                        setDimensionalSuccessModal(true);
                        closeDimModal();
                        setDimensionalLoader(false);
                    }
                    else {
                        if (res.data.request_allowed == 'not_allowed') {
                            closeDimModal();
                            setDimensionalLoader(false);
                            setInAppLimitExceededModal(true);
                        } else {
                            message.error('something went wrong');
                        }
                    }
                })
        }
        if (requestStatus == 'payment_required') {
            window.addEventListener('card_saved', (e) => {
                let action = e.detail.action;
                setCardSaveFlowTriggered(false);
                if (action == 'product_dimensional') {
                    dimensionalRequestAPICall(gateway_payload);
                }

            }, false);

            if (cardStatus == 1) {
                dimensionalRequestAPICall(gateway_payload);
            }
            if (cardStatus == 0) {
                let child_window = window.open('/save-card?action=product_dimensional', '', "width=600,height=600");
                let refreshInterval = setInterval(() => {
                    if (child_window.closed) {
                        setCardSaveFlowTriggered(false);
                        clearInterval(refreshInterval);
                    }
                }, 500);
                setCardSaveFlowTriggered(true);
            }
        }
    }

    const dimensionalRequestAPICall = (gateway_payload) => {
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    setDimensionalSuccessModal(true);
                    closeDimModal();
                    setDimensionalLoader(false);
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        setInAppLimitExceededModal(true);
                        closeDimModal();
                        setDimensionalLoader(false);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setDimensionalLoader(false);
                    }
                }
            })
    }

    const closeDimModal = () => {
        setDimensionalDetailsModal(false);
        setVariationProductData(null);
        setVariationAssetData(null);
        setVariantSelected(false);
        setSavedVariationProductDetails(null);
    }

    return (<>
        <Modal
            visible={dimensionalDetailsModal}
            onCancel={closeDimModal}
            className="modal-share"
            width={788}
            footer={statusLoader ? '' : [
                <div className={(requestStatus == "payment_required" || requestStatus == "not_allowed") ? "justify-space-between" : "justify-in-end"}>
                    {(requestStatus == "payment_required" || requestStatus == "not_allowed") ?
                        <div className="manrope f-14 black-55">
                            Total Price: <span className="manrope f-14 w-700" style={{ color: "#25D6A4" }}>{"$" + dimensionalPrice}</span>
                        </div> : ''}
                    <Button disabled={cardSaveFlowTriggered || dimensionalLoader} style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => document.getElementById("generate-dimensions-button").click()}>
                        {dimensionalLoader ? <>Submitting Request<LoadingOutlined style={{ marginLeft: 6 }} spin /></> :
                            <>
                                {cardStatus == 1 && (requestStatus == "payment_required" || requestStatus == "not_allowed") ? "Confirm & Process Payment" :
                                    cardStatus == 0 && (requestStatus == "payment_required" || requestStatus == "not_allowed") ? "Confirm & Enter Payment Details" : "Confirm"}
                            </>
                        }
                    </Button>
                </div>
            ]}>
            {statusLoader ? (
            <LoadingOutlined className="loading-center mt-10 mb-20" />
            ) :
            <div style={{ padding: "16px 16px 0 16px" }}>
                <h2 className="manrope f-18 black-14 w-700" style={{ marginBottom: 12 }}>Dimensional Image Configuration</h2>

                <Form onFinish={handleDimensionalSubmit} layout={"vertical"}>
                    <Col span={24}>
                        <Row type='flex' style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12, paddingBottom: 12 }}>
                            <Col span={24}>
                                <h1 className="manrope f-14 black-55 w-900" style={{ marginBottom: 0, marginTop: 8 }}>Dimensions</h1>
                                <Radio.Group onChange={(e) => setDimensionalImageType(e.target.value)} value={dimensionalImageType} style={{ marginTop: 12, marginBottom: 12, width: '80%' }}>
                                    <Radio style={radioStyle} value={'system'}>
                                        <span className="manrope f-14 grey-73">Use System Generated Dimensions</span>
                                    </Radio>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Radio style={radioStyle} value={'custom'}>
                                            <span className="manrope f-14 grey-73">Enter Dimensions Manually</span>
                                        </Radio>
                                    </div>
                                </Radio.Group>
                                {(dimensionalImageType == 'custom') ?
                                    <span>

                                        <div style={{ width: '80%', display: 'flex', justifyContent: "flex-end" }}>
                                            <div style={{ position: "absolute", bottom: 65 }}>
                                                <Form.Item name="custom_dimensional_unit" initialValue="inches" colon={false}>
                                                    <Select
                                                        className="manrope black-55 tree-select-material f-14"
                                                        defaultValue={'inches'}
                                                    >
                                                        <Option className="manrope f-12 select-view" value="feet">Feet</Option>
                                                        <Option className="manrope f-12 select-view" value="inches">Inches</Option>
                                                        <Option className="manrope f-12 select-view" value="meter">Meter</Option>
                                                        <Option className="manrope f-12 select-view" value="millimeter">millimeter</Option>
                                                        <Option className="manrope f-12 select-view" value="centimeter">centimeter</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div style={{ width: "80%", display: "flex", justifyContent: "space-between", marginTop: 12 }}>

                                            <div style={{ marginRight: 6 }}>
                                                <Form.Item name="custom_dimensional_height" colon={false} rules={[{ required: true, message: 'Input height' }]}>
                                                    <InputNumber
                                                        style={{ color: "#333333" }}
                                                        className="medium-input-box manrope f-12"
                                                        placeholder="Height"
                                                        min={0}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{ marginRight: 3 }}>
                                                <Form.Item name="custom_dimensional_width" colon={false} rules={[{ required: true, message: 'Input width' }]}>
                                                    <InputNumber
                                                        className="medium-input-box manrope f-12"
                                                        style={{ color: "#333333" }}
                                                        placeholder="Width"
                                                        min={0}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{ marginLeft: 3 }}>
                                                <Form.Item name="custom_dimensional_depth" colon={false} rules={[{ required: true, message: 'Input depth' }]}>
                                                    <InputNumber
                                                        style={{ color: "#333333" }}
                                                        className="medium-input-box manrope f-12"
                                                        min={0}
                                                        placeholder="Depth"
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>

                                    </span>
                                    : ""}
                            </Col>

                            <Col span={24}>
                                <Form.Item style={{ marginTop: 24 }} name="custom_dimensional_dpi" label={<span className="manrope f-14 black-55 w-900">DPI</span>} initialValue={customerDefaultDimDPI} colon={false}>
                                    <Select
                                        className="manrope black-55 tree-select-material f-12"
                                        defaultValue={customerDefaultDimDPI}>
                                        <Option className="manrope f-12 select-view" value="300">300 DPI (default)</Option>
                                        <Option className="manrope f-12 select-view" value="400">400 DPI</Option>
                                        <Option className="manrope f-12 select-view" value="600">600 DPI</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ marginTop: 24 }} name="custom_dimensional_resolution" label={<span className="manrope f-14 black-55 w-900">Resolution</span>} initialValue={customerDefaultDimResolution} colon={false}>
                                    <Select
                                        className="manrope black-55 tree-select-material f-12"
                                        onChange={(value) => changeDimRes(value)}
                                        defaultValue={customerDefaultDimResolution}>
                                        <Option className="manrope f-12 select-view" value="1k">1K</Option>
                                        <Option className="manrope f-12 select-view" value="2k">2K (Default)</Option>
                                        <Option className="manrope f-12 select-view" value="3k">3K</Option>
                                        <Option className="manrope f-12 select-view" value="4k">4K</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Button id="generate-dimensions-button" type="primary" htmlType="submit" style={{ "display": "none" }}>
                        Submit
                    </Button>
                </Form>
            </div>}
        </Modal>
        <SuccessModal
            visible={dimensionalSuccessModal}
            heading={"Dimensional Image Requested Successfully!"}
            closable={true}
            text={"You have successfully requested your new dimensional image. The current image will be replaced as soon as the new one is available. We will notify you once it is ready."}
            onCancel={() => setDimensionalSuccessModal(false)}
            footer={[
                <div className='justify-in-center'>
                    <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                        window.location.href = "/silo-tool/" + product_id;
                    }}>
                        Create Silo Images
                    </Button>

                    <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                        setDimensionalSuccessModal(false);
                        openThreeSixtyModal();
                    }}>
                        Create 360 Spin
                    </Button>
                    <Button className="modal-okay square font-12" htmlType="submit" onClick={() => {
                        setDimensionalSuccessModal(false);
                        openVideoModal();
                    }}>
                        Request MP4 Video
                    </Button>
                </div>
            ]} />


    </>)
}

export default ProductDimModal;