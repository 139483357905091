import React, { useState, useEffect } from 'react';
import {Redirect} from 'react-router';
import { Row, Col } from 'antd';
import CustomerMainLayout from '../../CustomerComponents/CustomerMainLayout/CustomerMainLayout';
import UploadExistingForm from "./UploadExistingForm"
import AddNewProductForm from "../AddNewProduct/AddNewProductForm"
import { fetchProduct } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import MspWarningModal from '../../CustomerComponents/MspWarningModal/MspWarningModal';
import FileConstants from '../../../../FileConstants';

const UploadProductModel = (props) => {
  const { match: {params} } = props;
  const [form, setForm] = useState({
    currentStep: 0
  });
  const [requestStatus, setRequestStatus] = useState("")
  const [usedUploadProps, setUsedUploadProps] = useState(0)
  const [availableUploadProps, setAvailableUploadProps] = useState(0)
  const [isMspUserWarningModal, setIsMspUserWarningModal] = useState(false);

  useEffect(() => {
    let product_id = params.id;
    if (product_id) {
      props.getProduct({ product_id: product_id });
    }

    let payload = {
      "username": localStorage.getItem('username'),
      "request_type": "existing_product_model",
      "action": "check"
    }

    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
    .then(res => {
        setRequestStatus(res.data['request_allowed']);
        setAvailableUploadProps(res.data['available_limit']);
        setUsedUploadProps(res.data['usage_count'])
    })

  }, []);

  useEffect(() => {
    if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') == null)) {
        setIsMspUserWarningModal(true);
    }
  },[])

  const handleSubmit = (values, e) => {
    e.preventDefault();
  }

  const goToNextForm = () => {
    let currentVal = { ...form };
    currentVal.currentStep = new URLSearchParams(window.location.search).get("step") || currentVal.currentStep + 1;
    setForm(currentVal);
  }

  const handleWarningModalClose = () => {
    setIsMspUserWarningModal(false);
  }

  return <CustomerMainLayout selected='10'>
          <div>
            <div className="add-product-layout-container   bg-color color-6b">
              <Row className="product justify-in-center" >
                {/* <Col span={4}></Col> */}
                <Col span={16} >
              {props.productReducer.product.admin_comments ?
              <div className="flagged-bg border pd-16" style={{marginBottom: 32}}>
                <Row type="flex" justify="space-between" style={{marginBottom: 10}}>
                    <div className="manrope f-14 red">Comments from ALL3D:&nbsp;<span className="manrope f-14 black-33">{props.productReducer.product.admin_comments}</span></div>
                </Row>
                <Row type="flex" justify="space-between">
                  {(props.productReducer.product.admin_attachments != undefined && props.productReducer.product.admin_attachments.length > 0) ?
                    <span>
                      <div className="manrope f-14 red">Attachments: </div>
                      {props.productReducer.product.admin_attachments && props.productReducer.product.admin_attachments.map(file => (
                        <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                          {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".svg")) ? 
                          <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(props.productReducer.product.platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}/> :
                          (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> : 
                          <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                          <div className="manrope f-14 blue link">{file.name}</div>
                        </a>
                        ))}
                    </span> : ""}
                </Row>
              </div>
           : ""}
          {params.id && props.productReducer.product.uploaded_model == false || (props.productReducer.product.variant_of) ? 
            <React.Fragment>
               <Redirect to={`/add-new-product/${params.id}`}/>            
            </React.Fragment>
           
            : params.id && props.productReducer.product.uploaded_model == true ? 
          <React.Fragment>
              <Row>
                <Col span={12}>
                  <div className="manrope f-20 black-33 w-700">Upload Product Model</div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <UploadExistingForm requestStatus={requestStatus} availableUploadProps={availableUploadProps} usedUploadProps={usedUploadProps} comments={props.productReducer.product.admin_comments} admin_attachments={props.productReducer.product.admin_attachments}  next={goToNextForm} onSubmit={handleSubmit} platform={props.productReducer.product.platform}/>
                </Col>
              </Row>
            </React.Fragment>
            : params.id == undefined ? 
            <React.Fragment>
            {
            (localStorage.getItem("is_msprovider") == 'true') && localStorage.getItem('managed_customer_username') &&
            <Row gutter={[12,15]}>
              <Col span={24}>
              <div className="custom-alert info">
              You are requesting this Product Model for customer {localStorage.getItem('managed_customer_username')}
              <>{(localStorage.getItem('managed_company_name') != undefined) ? (' (Company: ' + localStorage.getItem('managed_company_name') + ')'):''}</>
              </div>
              </Col>
            </Row>
            }
            <Row>
              <Col span={12}>
                <div className="manrope f-20 black-33 w-700">Upload Product Model</div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <UploadExistingForm requestStatus={requestStatus} availableUploadProps={availableUploadProps} usedUploadProps={usedUploadProps} comments={props.productReducer.product.admin_comments} admin_attachments={props.productReducer.product.admin_attachments}  next={goToNextForm} onSubmit={handleSubmit} platform={props.productReducer.product.platform}/>
              </Col>
            </Row>
          </React.Fragment>
          :""}
        </Col>
                {/* <Col span={4}></Col> */}
              </Row>
            </div>
          </div>
    <MspWarningModal
        visible={isMspUserWarningModal}
        handleModalClose={handleWarningModalClose}
    />
  </CustomerMainLayout>
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  getProduct: (product) => {
    dispatch(fetchProduct(product));
  },
});


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadProductModel)
)