import React, { useState, useEffect, useCallback } from 'react';
import { getProductRender, listProducts} from "../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Configurator from '../../../Configurator';
import {
    Menu,
    Dropdown,
    Card,
    Popover,
    Input,
    Form,
    Checkbox,
    Upload,
    Row,Col,Modal,
    message, Tooltip, Button
} from 'antd';
import { LoadingOutlined,DownOutlined, DownloadOutlined } from '@ant-design/icons';

const checkerboard_material = '/img/UVChecker.png';

var configurator_clay = null;
const ClayModel = (props) => {
    const { match: { params } } = props;
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [modelType, setModelType] = useState(1);
    const [clayImage, setClayImage] = useState('');
    const [checkboardImage, setCheckerboardImage] = useState('');

    useEffect(() => {
        props.getProduct({ product_id: params.id });
        if (configurator_clay == null) {
            configurator_clay = new Configurator(params.id,'configurator',200, 0, true, props.toggleLoader);
        }
    }, [params]);

    useEffect(() => {
        if (!props.loadingProgress) {
            if (configurator_clay != null) {
                configurator_clay.getThumbnails();               
                setClayImage(configurator_clay.getClayImage());
                
                setCheckerboardImage(configurator_clay.getCheckerboardImage());
            }
        }
    },[props.loadingProgress]);

   

    
    const selectClay = () => {
        setModelType(1);
        if (configurator_clay != null) {
            configurator_clay.removeMaterial();
        }
    }

    const selectCheckerboard = () => {
        setModelType(2);
        if (configurator_clay != null) {
            configurator_clay.applyCheckerboardMaterial();
        }
    }

    // '/img/clay.png'
    const menu = (
        <Menu style={{ width: "186px",fontFamily:"Avenir" }}>
            {(props.productReducer.product.download_links || []).map((ele, index) => {
                return (
                    <Menu.Item key={index}>
                        <a target="_blank" href={ele.model_url} style={{fontFamily:"Avenir"}}>{ele.display_name} File <DownloadOutlined style={{ float: "right" }} /></a>
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    return(
    <div>
        <Card className={((isFullScreen)? 'product-full-screen' : "product-viewer-normal")} bodyStyle={{padding: (isFullScreen) ? 0 : 12}} 
        style={{marginRight: (props.productReducer.product.model_status == 4) || (props.productReducer.product.model_status == 4) ? "3px" : "0px"}} id="pdp-card">
            <div style={{ position: "absolute", zIndex: 2, top: "20px", right:"20px"} } id="components-dropdown-demo-dropdown-button">
                <Dropdown overlay={menu} trigger={['click']} placement={"bottomRight"}>
                    <Button className="modal-okay square font-16 w-900">
                        Download <DownOutlined />
                    </Button> 
                </Dropdown>
            </div>
            <div id="configurator"></div>
            <div className="tag-bg large"><span className="tag-txt large">Configurable Model {modelType == 1 ? "(Clay)" : "(Checkerboard)"}</span></div>
            <div className="position-cards">
                <Card className="material-card" onClick={selectClay} style={{border: modelType == 1 ? "1px solid black" : "1px solid #f0f0f0"}}>  
                    <img className="material-img model" src={'/img/clay.png'}/>
                    <div className="manrope f-14 grey-77" style={{marginTop: 8, textAlign:"center"}}>Clay Model</div>
                </Card>
                <Card className="material-card" onClick={selectCheckerboard} style={{border: modelType == 2 ? "1px solid black" : "1px solid #f0f0f0"}}>  
                    <img className="material-img model" src={'/img/checkerboard.png'}/>
                    <div className="manrope f-14 grey-77" style={{marginTop: 8, textAlign:"center"}}>Checkerboard</div>
                </Card>
            </div>
        </Card>
    </div>
    );

}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    listProducts: () => {
        dispatch(listProducts());
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ClayModel))