import React, { useEffect, useState } from "react";
import {useStripe, useElements, Elements, PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import DottedLoader from "../../DottedLoader";
import $ from 'jquery';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(ENVIRONMENT.STRIPE_PUBLISH_KEY);

const SetupForm = (props) => {
    const [buttonLoader,setButtonLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [isFormReady, setIsFormReady] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    console.log(stripe,elements)

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async ( request_id, request_type) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      setButtonLoader(true);

      if (!stripe || !elements) {
        console.log(stripe,elements)
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      console.log('Request ID and Type: ', request_id, request_type);
      console.log(ENVIRONMENT.PORTAL_LINK + 'card-save-complete?request_id='+ request_id + '&request_type='+request_type)
      const {error} = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: ENVIRONMENT.PORTAL_LINK + 'card-save-complete?request_id='+ request_id + '&request_type='+request_type + '&action=' + (new URLSearchParams(window.location.search).get("action")),
        }
      });
      console.log('ERROR',error)
      
      if (error) {
        setButtonLoader(false);
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
        props.setErrorMessage(error.message);
        // if (props.setCardSaveFailure != undefined) {
        //   props.setCardSaveFailure(true);
        // }
        console.log(error.message);
      } else {
        setButtonLoader(false);
        // if (props.setCardSaveFailure != undefined) {
        //   props.setCardSaveFailure(true);
        // }
        console.log('Redirected URL')
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    };

    const handleEntityCreation = (event) => {
      event.preventDefault();
      setButtonLoader(true);
      if (props.account_details_form) {
        handleSubmit(-1,'account_details');
      } else {
        if (props.card_save_window) {
          handleSubmit(-1,'card_save_window');
        }
        if (props.saveRequestIntermedietely != undefined){
          props.saveRequestIntermedietely(handleSubmit)
        }
      }
    }

    const setPageLoaded  = () => {
      setIsFormReady(true);
    }
    
    return (
      stripe || elements ?
      <span>
        <form onSubmit={handleEntityCreation}>
          <PaymentElement id="payment-element" onReady={() => setPageLoaded()}/>
          <Button htmlType="submit" disabled={!stripe || !elements || buttonLoader} style={{marginTop: 30, display: (isFormReady? 'block': 'none')}} className={`modal-okay square font-16 justify-in-center ${props.account_details_form ? ``: `important-width`}`}>
            {props.account_details_form ? 'Save Card' : 'Pay'} {buttonLoader ? <LoadingOutlined/> : ''}
          </Button>
        </form>
        {!isFormReady?<DottedLoader/>:''}
      </span>
      
      : ''
    );
};

const SaveCardFlow = (props) => {

  const [options, setOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios.post(ENVIRONMENT.SAVE_CARD_FLOW, {
      username: localStorage.getItem('username')
    })
      .then(res => {
        setOptions({
          clientSecret: res.data,
          appearance: {/*...*/},
        });
    });
  },[]);
  return (
    <span>
      {(options != null) ?
      <Elements stripe={stripePromise} options={options}>
        <span style={{color: 'red'}}>{errorMessage}</span>
        <SetupForm setCardSaveFailure={props.setCardSaveFailure} saveRequestIntermedietely={props.saveRequestIntermedietely} account_details_form={props.account_details_form} card_save_window={props.card_save_window} setErrorMessage={setErrorMessage}/>
      </Elements> : props.account_details_form ? <DottedLoader/> : <LoadingOutlined className="manrope f-16 black-55"/>
      }
      
    </span>
  );
};

export default SaveCardFlow;