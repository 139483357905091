import React, { useEffect, useState } from "react";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import { Row, Modal, Button, Tabs, Col, message, Tooltip, Input } from "antd";
import { UndoOutlined, SearchOutlined } from "@ant-design/icons";
import DottedLoader from "../../DottedLoader/DottedLoader";
import "./SwapSpace.scss";
import FileConstants from "../../../../FileConstants";
import * as Utilities from "../../Utilities";
import RoomsList from "./RoomsList";
import WarningModal from "../../WarningModal/WarningModal";

const COMPANY_ID = FileConstants.COMPANY_ID;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const IS_MSPROVIDER = FileConstants.isMSProvider;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;;
const SEQUENCE_ID_OTHER = -1;
const SEQUENCE_ID_OWNED = -1;

const { TabPane } = Tabs;

const SwapSpace = (props) => {
    const {
      swap_space_modal,
      toggleSwapSpaceModal,
      room_id,
      handleSwapSpace,
      collection_id,
      scene_id,
      room_parent,
      setLoading,
      checkExistingSnapshots,
      deleteSnapshot,
      room_platform,
      swapButtonRef
    } = props;
    const [variantsData, setVariantsData] = useState([]);
    const [tabActiveKey, setTabActiveKey] = useState("variants");
    const [selected, setSelected] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [selectedElementDetials, setSelectedElementDetials] = useState({});
    const [initialLoadOwned, setInitialLoadOwned] = useState(true);
    const [initialLoadShared, setInitialLoadShared] = useState(true);
    const [ownedRoomLoader, setOwnedRoomLoader] = useState(true);
    const [sharedRoomLoader, setSharedRoomLoader] = useState(true);
    const [ownedOriginalData, setOwnedOriginalData] = useState([]);
    const [sharedOriginalData, setSharedOriginalData] = useState([]);
    const [storeSpaceData, setStoreSpaceData] = useState([]);
    const [storeSpacesLoader, setStoreSpacesLoader] = useState(true);
    const [mspUserData, setMspUserData] = useState([]);
    const [mspFilteredData, setMspFilteredData] = useState([]);
    const [initialLoadSpaces, setInitialLoadSpaces] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [filteredVariantsData, setFilteredVariantsData] = useState([]);
    const [filteredUserData, setFilteredUserData] = useState([]);
    const [filteredSharedData, setFilteredSharedData] = useState([]);
    const [filteredStoreData, setFilteredStoreData] = useState([]);
    const [requestStatusRoom, setRequestStatusRoom] = useState(false);
    const [isWarningModal, setIsWarningModal] = useState(false);
    const [existingSnapshots, setExistingSnapshots] = useState([]);
    const [resetRoomId, setResetRoomId] = useState(0);
    const [isResetSwap, setIsResetSwap] = useState(false);
    const tabs = [
        {
            type: 'msp',
            title: `${MANAGED_CUSTOMER_USERNAME}'s Products`,
            data: mspFilteredData,
            selectable: true,
        },
        {
            key: "variants",
            title: "Variants",
            data: filteredVariantsData,
            selectable: true,
        },
        {
            key: "owned",
            title: "Owned",
            data: filteredUserData,
            selectable: true,
        },
        {
            key: "shared",
            title: "Shared",
            data: filteredSharedData,
            selectable: true,
        },
        {
            key: "store",
            title: "Store",
            data: filteredStoreData,
            selectable: true,
            loader: storeSpacesLoader,
        }
    ];

    const getRoomVariantsPayload = (initial, type, sequence_id) => {
        const payload = {
            required_fields: [
                "room_id",
                "room_name",
                "brand_id",
                "category",
                "dimensions",
                "customer_username",
                "model_status",
                "thumbnail",
                "last_modified_stamp",
                "variant_of",
                "is_store_item",
                "company_id",
                "style",
                "platform",
                "tags",
                "price",
            ],
        };

        let filter_string = "";
        let parent_filter = ""
        if (room_parent) {
            parent_filter = `variant_of__exact='${room_parent}'||room_id__exact='${room_parent}'||`
        }
        filter_string = `(${parent_filter}variant_of__exact='${room_id}'||room_id__exact='${room_id}')&&(model_status__in=[5]&&is_hidden__not=true)`;
        payload["filter_string"] = filter_string;
        payload["order_by"] = "last_modified_stamp desc";
        return payload;
    };

    const getRoomPayload = (initial, type, sequence_id, current_username, current_company_id, shared_entities, is_customer_data = false) => {
        let payload = {
            "required_fields": ["room_id", "room_name", "brand_id", "category", "dimensions",
                "customer_username", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "company_id", "style", "platform", "tags"],
        }
        if (sequence_id != -1) {
            payload['required_fields'].push('folder_id', 'folder_name', 'parent_folder_id',
                'library_order', 'entity_order')
            payload["order_by"] = "library_order asc,entity_order asc"
        } else {
            payload["order_by"] = "last_modified_stamp desc"
        }
        let filter_string = "";
        let shared_username = "";
        let customer_username = "customer_username__exact='" + current_username + "'"
        let customer_username_notexact = "customer_username__notexact='" + current_username + "'"
        let company_id_notexact = ""

        let company_id = "";
        let model_status = "model_status__in=[5]"
        let is_hidden = "is_hidden__not=true"
        let shared_hidden = "shared_hidden__not=true"
        let sequence_id_value = "sequence_id__exact=" + sequence_id
        if (type == 'shared') {
            payload['required_fields'].push('shared_username')
            payload['required_fields'].push('shared_room_type')
            shared_username = "shared_username__exact='" + current_username + "'"
            filter_string = "(" + shared_username + "&&" + shared_hidden + ")"

            if (shared_entities != undefined && current_company_id && shared_entities.split("_").includes("room")) {
                company_id_notexact = "company_id__notexact=" + current_company_id + "||company_id__isnull=true"
                filter_string = filter_string + "&&(" + company_id_notexact + ")&&(" + customer_username_notexact + ")"
            } else {
                filter_string = filter_string + "&&(" + customer_username_notexact + ")"
            }

        } else if (shared_entities != undefined && current_company_id && shared_entities.split("_").includes("room")) {
            company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
            filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")"
        } else {
            if (IS_MSPROVIDER && !is_customer_data) {
                company_id = "company_id__isnull=true"
                let requested_for = "requested_for__isnull=true"
                filter_string = "(" + company_id + "&&" + customer_username + ")&&(" + is_hidden + ")&&(" + requested_for + ")"
            } else {
                filter_string = "(" + customer_username + "&&" + is_hidden + ")"
            }
        }
        filter_string = filter_string + "&&(" + model_status + ")"
        if (sequence_id != -1) {
            filter_string = filter_string + "&&(" + sequence_id_value + "||sequence_id__isnull=true)"
        }
        payload['filter_string'] = filter_string
        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }
        return payload;
    };

    const fetchRoomVariantsData = async () => {
        setDataLoading(true);
        const payload = getRoomVariantsPayload();
        const data = [];
        await axios.post(ENVIRONMENT.LIST_ROOMS, payload).then((res) => {
            if (res.data) {
                const dataWithKeywords = Utilities.getRoomsDataWithKeywords(res.data);
                setVariantsData(dataWithKeywords);
                setFilteredVariantsData(dataWithKeywords);
            }
            setDataLoading(false);
        });
        return data;
    };

    const fetchRoomData = (type, sequence_id, initial = false) => {
        if (!initial) {
            setDataLoading(true);
        } else {
            if (type == 'shared') {
                setInitialLoadShared(true);
            } else {
                setInitialLoadOwned(true);
            }
        }
        let payload = getRoomPayload(initial, type, sequence_id, CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES, false);
        axios.post(ENVIRONMENT.LIST_ROOMS, payload)
            .then(res => {
                if (res.data) {
                    let rooms_data = Utilities.getRoomsDataWithKeywords(res.data)
                    if (type == 'shared') {
                        setSharedOriginalData(rooms_data);
                        setFilteredSharedData(rooms_data);
                    } else {
                        setFilteredUserData(rooms_data);
                        setOwnedOriginalData(rooms_data);
                    }

                    // setting loaders
                    if (initial) {
                        if (type == 'shared') {
                            setInitialLoadShared(false);
                        } else {
                            setInitialLoadOwned(false);
                        }
                    } else {
                        if (type == 'shared') {
                            setSharedRoomLoader(false);
                        } else {
                            setOwnedRoomLoader(false);
                        }
                    }
                    setDataLoading(false);
                }
            });
    }

    const fetchStoreRoomData = async (initial = false) => {
        const data = [];
        setStoreSpacesLoader(true);
        let payload = {
            item_type: 'room',
            username: localStorage.getItem('username')
        }
        if (initial) {
            payload.limit = '';
            setInitialLoadSpaces(true);
        }
        axios.post(ENVIRONMENT.STORE_LIST, payload)
            .then((res) => {
                if (!initial) {
                    setStoreSpacesLoader(false);
                }
                const dataWithKeywords = Utilities.getRoomsDataWithKeywords(res.data);
                setStoreSpaceData(dataWithKeywords);
                setFilteredStoreData(dataWithKeywords);
            })
            .catch((error) => {
            });
        return data;
    };

    const getMSPRoomPayload = () => {
        const payload = {
            'required_fields': ['room_id', 'room_name', 'brand_id', 'category', 'customer_username',
                'model_status', 'thumbnail', 'last_modified_stamp', 'variant_of',
                'is_store_item', 'dimensions', 'company_id', 'is_shared', 'platform', 'tags'],
        };
        payload['order_by'] = 'is_shared desc, last_modified_stamp desc';
        let filterString = '';
        const customer_username = 'customer_username__exact=\'' + localStorage.getItem('managed_customer_username') + '\'';
        let company_id = '';
        const model_status = 'model_status__in=[5]';
        const is_hidden = 'is_hidden__not=true';
        const has_access_to = 'has_access_to__like=\'%"' + localStorage.getItem('managed_customer_username') + '"%\'';

        if (MANAGED_COMPANY_ID) {
            company_id = 'company_id__exact=' + parseInt(MANAGED_COMPANY_ID);
            filterString = '(' + company_id + '||' + customer_username + ')&&(' + is_hidden + ')';
        } else {
            filterString = '(' + customer_username + '&&' + is_hidden + ')';
        }
        filterString = filterString + '&&(' + model_status + ')' + '||(' + has_access_to + ')';

        payload['filter_string'] = filterString;

        return payload;
    };

    const getCustomerDataForMsprovider = async () => {
        const payload = getMSPRoomPayload();
        await axios.post(ENVIRONMENT.LIST_ROOMS, payload)
            .then((res) => {
                if (res.data) {
                    const data = Utilities.getRoomsDataWithKeywords(res.data);
                    setMspUserData(data);
                }
            });
    };

    const handleCancelSwapSpaceModal = () => {
        toggleSwapSpaceModal(false);
        setSelected([]);
        setSearchValue("");
    };

    const handleTabChange = (key) => {
        setTabActiveKey(key);
    };

    const swapSpace = async (isResetSwap = false) => {
        let roomId = selectedElementDetials.room_id;
        let selectedSpace = selectedElementDetials;
        if (isResetSwap) {
            roomId = resetRoomId;
            selectedSpace = {
                room_id: resetRoomId,
                platform: room_platform
            }
            setResetRoomId(0)
        } else {
            setResetRoomId(room_id);
        }
        setIsResetSwap(false);
        setLoading(true);
        handleCancelSwapSpaceModal();


        if (existingSnapshots.length) {
            setIsWarningModal(false);
            existingSnapshots.map((snapshot) => {
                deleteSnapshot(snapshot.name)
            })
            setExistingSnapshots([]);
        }

        const CollectionPayload = {
            room_id: roomId,
            collection_id: collection_id,
            username: CUSTOMER_USERNAME,
        };

        const ScenePayload = {
            room_id: roomId,
            scene_id: scene_id,
        };

        axios.post(ENVIRONMENT.UPDATE_COLLECTION, CollectionPayload)
            .then((res) => {
            })
            .catch(() => {
                setLoading(false);
                message.error("Failed to swap space");
                return;
            });

        await axios.post(ENVIRONMENT.UPDATE_SCENE, ScenePayload)
            .then((response) => {
            })
            .catch((error) => {
                setLoading(false);
                message.error("Failed to swap space");
                return;
            });

        handleSwapSpace(selectedSpace, isResetSwap, () => {
            setLoading(false);
            message.success("Space Swapped Successfully");
        });
    };

    useEffect(()=> {
        console.log("reset id", resetRoomId, swapButtonRef)
    }, [resetRoomId, swapButtonRef])

    const getTabTitle = (tab) => {
        if (tab.key == "store" && storeSpacesLoader)
            return tab.title;
        else return tab.title + ` (${tab.data.length})`;
    };

    const loadUserRequestStatusRooms = (confirm_request = null) => {
        const check_payload = {
            username: CUSTOMER_USERNAME,
            action: 'check',
            request_type: 'room_prop',
        };
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
            ;
            setRequestStatusRoom(res.data['request_allowed']);
        });
    };

    useEffect(() => {
        fetchRoomData('owned', SEQUENCE_ID_OWNED, true);
        fetchRoomData('shared', SEQUENCE_ID_OTHER, true);
        fetchStoreRoomData(true);
        if (IS_MSPROVIDER) {
            getCustomerDataForMsprovider();
        }
        loadUserRequestStatusRooms();
    }, []);

    useEffect(() => {
        if (!initialLoadOwned && ownedRoomLoader) {
            fetchRoomData('owned', SEQUENCE_ID_OWNED);
        }
      }, [initialLoadOwned]);
    
    useEffect(() => {
      if (!initialLoadShared && sharedRoomLoader) {
        fetchRoomData('shared', SEQUENCE_ID_OTHER);
      }
    }, [initialLoadShared]);

    const handleSubmit = (isResetSwap = false) => {
        let existingSnapshots = checkExistingSnapshots();
        if (isResetSwap) {
            setIsResetSwap(true);
        }
        if (existingSnapshots.length) {
            setExistingSnapshots(existingSnapshots);
            setIsWarningModal(true);
        } else {
            swapSpace(isResetSwap);
        }
    }

    const handleWarningModalClose = () => {
        setIsWarningModal(false);
    }

    useEffect(() => {
        if (room_id) {
            fetchRoomVariantsData();
        }
    }, [room_id]);

    useEffect(() => {
        if (initialLoadSpaces) {
            fetchStoreRoomData();
        }
    }, [initialLoadSpaces]);

    useEffect(() => {
        if (searchValue.length) {
            const filteredVariantsData =
                Utilities.getRelevanceBasedOrderedSearchResult(
                    searchValue,
                    variantsData
                );
            const filteredUserData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                ownedOriginalData
            );
            const filteredSharedData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                sharedOriginalData
            );
            const filteredStoreData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                storeSpaceData
            );
            const filteredMSPData = Utilities.getRelevanceBasedOrderedSearchResult(
                searchValue,
                mspUserData
            );
            setMspFilteredData(filteredMSPData);
            setFilteredSharedData(filteredSharedData);
            setFilteredUserData(filteredUserData);
            setFilteredVariantsData(filteredVariantsData);
            setFilteredStoreData(filteredStoreData);
        }
        else {
            setFilteredSharedData(sharedOriginalData);
            setFilteredUserData(ownedOriginalData);
            setFilteredVariantsData(variantsData);
            setFilteredStoreData(storeSpaceData);
        }
    }, [searchValue]);

    return (
        <>
            {
                !!resetRoomId &&
                <button ref = {swapButtonRef} style={{visibility: "hidden"}} onClick={() => handleSubmit(true)}>
                    Reset
                </button>
            }
            <Modal
                destroyOnClose={true}
                closable={true}
                onCancel={handleCancelSwapSpaceModal}
                className="swap-modal"
                bodyStyle={{ padding: 0 }}
                footer={null}
                visible={swap_space_modal}
            >
                <Row className="header-container">
                    <div className="swap-heading-tag">
                        <span className="manrope f-14">Swap Space</span>
                    </div>
                </Row>
                <Row className="justify-space-between swap-container">
                    <Col span={16} className="justify-in-start">
                        <span className="manrope f-20 w-600">Choose a space below to replace the current space</span>
                    </Col>
                    <Col span={8} className="justify-in-end">
                        <Input
                            className="manrope f-14 grey-99 pd-8"
                            prefix={
                                <SearchOutlined
                                    style={{
                                        marginleft: "4px",
                                        marginRight: "8px",
                                        fontSize: "16px",
                                    }}
                                />
                            }
                            placeholder="Search Spaces"
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                    </Col>
                </Row>
                <div className="swap-container">
                    <Tabs
                        onChange={(key) => handleTabChange(key)}
                        className="product-tab library"
                        tabBarGutter={16}
                        size="large"
                        activeKey={tabActiveKey}
                    >
                        {tabs?.map((tab, index) => {
                            return index === 0 && (!IS_MSPROVIDER || !MANAGED_CUSTOMER_USERNAME) ? null : (
                                <TabPane
                                    tab={getTabTitle(tab)}
                                    key={tab.key}
                                    style={{ padding: "0px 10px" }}
                                >
                                    <div className="product-tab library">
                                        {dataLoading ? (
                                            <div className="modal-tab">
                                                <DottedLoader />
                                            </div>
                                        ) : (
                                            <RoomsList
                                                data={tab.data}
                                                selectable={tab.selectable}
                                                selected={selected}
                                                setSelected={setSelected}
                                                setSelectedElementDetials={setSelectedElementDetials}
                                                room_id={room_id}
                                                loader={tab.loader}
                                                requestStatusRoom={requestStatusRoom}
                                            />
                                        )}
                                    </div>
                                </TabPane>
                            );
                        })}
                    </Tabs>
                </div>
                <Row className="swap-modal-footer justify-in-end" onClick={() => { }}>
                    <Button
                        style={{ marginRight: 10 }}
                        className="outline-red-btn square font-12 manrope"
                        onClick={handleCancelSwapSpaceModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="modal-okay square font-14"
                        onClick={()=> handleSubmit(false)}
                        disabled={selected.length === 0}
                    >
                        Continue
                    </Button>
                </Row>
            </Modal>
            {isWarningModal &&
                <WarningModal
                    visible={isWarningModal}
                    onCancel={handleWarningModalClose}
                    heading={"Unrendered Snapshots"}
                    text={"Your previous unrendered snapshots will be removed on space swap."}
                    footer={[
                        <div key="footer" className="justify-in-end">
                            <Button
                                key="back"
                                style={{ textAlign: "center" }}
                                className="modal-okay square font-14"
                                onClick={handleWarningModalClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                key="proceed"
                                style={{ textAlign: "center" }}
                                className="modal-okay-gray square font-14"
                                onClick={() => swapSpace(isResetSwap)}
                            >
                                Proceed Anyway
                            </Button>
                        </div>,
                    ]}
                />
            }
        </>
    );
};

export default SwapSpace;
