import React, { useState, useEffect, useCallback } from 'react';
import ENVIRONMENT from '../../../../environments';
import {
    message,
} from 'antd';
import { getProductRender, listProducts, loadPage } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import $ from 'jquery';
import ProductDetailsView from './ProductDetailsView';
import * as Utilities from '../../Utilities';
import { ADMIN_MATERIAL_BATCH, MATERIAL_GET } from '../../../../environments/env';
import FileConstants from '../../../../FileConstants';
import CustomerMainLayout from '../CustomerMainLayout';

const CUSTOMER_USERNAME = localStorage.getItem("username");
const COMPANY_ID = localStorage.getItem("company_id");
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

const ProductDetails = (props) => {
    //rejection history state
    const [rejectionDetails, setRejectionDetails] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [embedLink, setEmbedLink] = useState(null);
    const [publicLink, setPublicLink] = useState(null);
    const [successModal, setSuccessModal] = useState(false);
    const [successEmbedModal, setSuccessEmbedModal] = useState(false);
    const [sharingModal, setSharingModal] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState('embed');
    const [sharingLoader, setSharingLoader] = useState(false);
    const [dimensionalImageType, setDimensionalImageType] = useState('system');
    const [dimensionalDetailsModal, setDimensionalDetailsModal] = useState(false);
    const [mp4DetailsModal, setMp4DetailsModal] = useState(false);
    const [dimensionalLoader, setDimensionalLoader] = useState(false);
    const [mp4GenerationLoader, setMp4GenerationLoader] = useState(false);
    const [threesixtyGenerationLoader, setThreesixtyGenerationLoader] = useState(false);
    const [threesixtyDetailsModal, setThreesixtyDetailsModal] = useState(false);
    const [dimensionalSuccessModal, setDimensionalSuccessModal] = useState(false);
    const [mp4SuccessModal, setMp4SuccessModal] = useState(false);
    const [threesixtySuccessModal, setThreesixtySuccessModal] = useState(false);
    const [compareView, setCompareView] = useState(false);
    const [mp4Exists, setMp4Exists] = useState(false);
    const [mp4Status, setMp4Status] = useState("");
    const [status360, setStatus360] = useState("");
    const [exist360, setExist360] = useState(false);
    const [replace360Confirm, setReplace360Confirm] = useState(false);
    const [payload360, setPayload360] = useState('');
    const [replaceMP4Confirm, setReplaceMP4Confirm] = useState(false);
    const [mp4Payload, setMp4Payload] = useState('');
    const [disable360, setDisable360] = useState(false);
    const [disableMp4, setDisableMp4] = useState(false);
    const [disableDimensional, setDisableDimensional] = useState(false);
    const [res360, setRes360] = useState('2.0');
    const [categorySearchValue, setCategorySearchValue] = useState('');
    const [categoryValue, setCategoryValue] = useState('');
    const [categoriesData, setCategoriesData] = useState([]);
    const [frame360, setFrame360] = useState('36');
    const [mp4FrameCount, setMp4FrameCount] = useState('72');
    const [mp4FpsCount, setMp4FpsCount] = useState('10');
    const [mp4Resolution, setMp4Resolution] = useState('1000');
    const [isConfigurable, setIsConfigurable] = useState(false);
    const [customerDefaultDimDPI, setCustomerDefaultDimDPI] = useState('300');
    const [customerDefaultDimResolution, setCustomerDefaultDimResolution] = useState('2k');
    const [customerDefaultDimensionSettings, setCustomerDefaultDimensionSettings] = useState({});
    const [openEditModal, setOpenEditModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [saveButtonLoader, setSaveButtonLoader] = useState(false);
    const [successSave, setSuccessSave] = useState(false);
    const [variationModal, setVariationModal] = useState(false);
    const [materialsData, setMaterialsData] = useState([]);
    const [materialValue, setMaterialValue] = useState('');
    const [materialSearchValue, setMaterialSearchValue] = useState('');
    const [savedID, setSavedID] = useState(-1);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [requestSaved, setRequestSaved] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [product_details, setProductDetails] = useState({});
    const [paymentRequestID, setPaymentRequestID] = useState(-1);
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [rejectLoader, setRejectLoader] = useState(false)
    const [validationReport, setValidationReport] = useState({})
    const [showArtistModal, setShowArtistModal] = useState(false);
    const [uploadedModel, setUploadedModel] = useState(false)
    const [assignedArtist, setAssignedArtist] = useState('')
    const [productCompanyId, setProductCompanyId] = useState(-1);
    const [tags, setTags] = useState([]);
    const [rejectionModalVisible, setRejectionModalVisible] = useState(false);
    const [gtin, setGtin] = useState('');
    const [state, setState] = useState({
        reference_urls: [],
        product_photos: {
        },
        product_manuals: [],
        raw_files: [],
        components: [
            {
                componentName: '',
                componentImages: []
            }
        ],
        guidelines: "",
        scans: [],
        material_files: [],
        admin_text_guidelines: ""
    });
    const [archiveSize, setArchiveSize] = useState(0);
    const [glbSize, setGlbSize] = useState(0);
    const [fixesDetails, setFixesDetails] = useState([]);
    const [libraryMaterial, setLibraryMaterial] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [cryptoMatteExists, setCryptoMatteExists] = useState(false);
    const [dimensionCrypto, setDimensionCrypto] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [cardPaymentFailure, setCardPaymentFailure] = useState(false);
    const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
    const [siloSuccessModal, setSiloSuccessModal] = useState(false);
    const [gatewayPayload, setGatewayPayload] = useState(null);
    const [prices, setPrices] = useState([]);
    const [threeSixtyPrice, setThreeSixtyPrice] = useState(-1);
    const [mp4Price, setMp4Price] = useState(-1);
    const [dimensionalPrice, setDimensionalPrice] = useState(-1);
    const [requestType, setRequestType] = useState('');
    const [dimensionalResolution, setDimensionalResolution] = useState('1k');
    const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
    const [storePage, setStorePage] = useState(0);
    const [variationType, setVariationType] = useState('color');
    const [arVariationType, setARVariationType] = useState('')
    const [variationStep, setVariationStep] = useState(0);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [sellVisible, setSellVisible] = useState(false);
    const [sellLoader, setSellLoader] = useState(false);
    const [price, setPrice] = useState(0);
    const [removeFromStore, setRemoveFromStore] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const [imageVisible, setImageVisible] = useState(false);
    const [groupId, setGroupId] = useState('');
    const [customerGroupIds, setCustomerGroupIds] = useState([]);
    const [groupIdSearchValue, setGroupIdSearchValue] = useState('');
    const [rejectionPrice, setRejectionPrice] = useState(-1);
    const [folderParentID, setFolderParentID] = useState(null);
    const [firstTimeUser, setFirstTimeUser] = useState(false);
    const [nextActionPrompt, setNextActionPrompt] = useState(false);
    const [openGenerateButtonTour, setOpenGenerateButtonTour] = useState(false);
    const [openGenerateOption, setOpenGenerateOption] = useState(false);
    const [automatedProductVariant, setAutomatedProductVariant] = useState(false);
    const [variantModel, setVariantModel] = useState(false);
    const [approveLoader, setApproveLoader] = useState(false);
    const [restrictedItem, setRestrictedItem] = useState(false);
    const [baseVariantPlatform, setBaseVariantPlatform] = useState('aws');
    const [baseVariantPlatformLoader, setbaseVariantPlatformLoader] = useState(true);
    const [legacyViewLoader, setLegacyViewLoader] = useState(false);

    useEffect(() => {
        fetchProductInfo();
        setProductPageType();
        fetchCustomerGroups();
    }, []);
    
    const checkIfOpenGenerateButton = () => {
        let open_generate_button = new URLSearchParams(window.location.search).get("open_generate_button");
        if (open_generate_button) {
            setOpenGenerateButtonTour(true);
            setOpenGenerateOption(true);
        }
    }

    const closeTour = () => {
        setOpenGenerateButtonTour(false);
    }


    const checkIfNewUser = () => {
        let user_visits = Utilities.getCookie("user_product_visits")
        console.log('user', user_visits)
        if (user_visits && parseInt(user_visits) < 2) {
            Utilities.setCookie("user_product_visits", parseInt(user_visits) + 1, 365)
            setFirstTimeUser(true);

        } else if (!user_visits) {
            Utilities.setCookie("user_product_visits", 0, 365)
            setFirstTimeUser(true);
        }

    }

    useEffect(() => {
        if (!cardSaveFlowTriggered) {
            setThreesixtyGenerationLoader(false);
            setMp4GenerationLoader(false);
            setDimensionalLoader(false);
        }
    }, [cardSaveFlowTriggered]);

    const fetchCustomerGroups = () => {
        let payload = {
            customer_username: CUSTOMER_USERNAME
        }
        axios.post(ENVIRONMENT.GROUP_IDS_GET_BATCH, payload)
            .then(res => {
                console.log('group ids = ', res)
                let group_ids = []
                if (res['data']) {
                    res['data'].map(item => {
                        if (item['group_id']) {
                            group_ids.push({
                                key: item['group_id'],
                                value: item['group_id'],
                                title: item['group_id'],
                            })
                        }
                    })
                }
                setCustomerGroupIds(group_ids)
            })
    }

    const openGenerateButton = () => {
        let query_parameters = '';
        if (firstTimeUser) {
            query_parameters = '?open_generate_button=true';
        }
        if ((state.variant_of != '' || state.variant_of != undefined) 
        && (state.variation_type == 'ar_conversion') &&
        (props.productReducer.product.need_to_model == 'ar')) {
            window.location.href = '/home'
        } else {
            window.location.href = '/products/' + props.params.id + query_parameters;
        }
       
    }

    const setProductPageType = () => {
        //load store page detaills if link is for product-assets, otherwise load all details
        console.log(props.match)
        if (props && props.match) {
            if (props.match.path.includes("products")) {
                setStorePage(0);
            }
            else if (props.match.path.includes("product-assets")) {
                setStorePage(1);
            }
        }
    }


    const performStoreAction = () => {
        //remove item from store if
        if (state.is_store_item == 1) {
            setRemoveFromStore(true);
        } else {
            setSellVisible(true);
            setSellLoader(false);
        }
    }

    const cancelSellModal = () => {
        setSellVisible(false);
        setSellLoader(false);
    }

    const onChangePrice = (e) => {
        console.log(e.target.value)
        setPrice(e.target.value);
    }

    const removeItemFromStore = () => {
        let payload = {
            is_store_item: 0,
            product_id: props.params.id,
        }
        console.log(payload)
        setRemoveLoader(true);
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
            .then(res => {
                setStoreRequests();
            })
            .catch((error) => {
                setRemoveLoader(false);
                console.log(error);
                message.error('Error in removing from store.');
            });
    }

    const onChangeImageVisibility = (visible) => {
        setImageVisible(visible);
    }

    const setStoreRequests = () => {
        let payload = {
            product_id: props.params.id,
        }
        if (state.is_store_item == -2) {
            payload.is_store_item = -2;
        } else if (state.is_store_item == 1) {
            payload.is_store_item = 0;
        } else {
            payload.is_store_item = -2;
        }

        let price_payload = {
            entity_id: props.params.id,
            customer_username: CUSTOMER_USERNAME,
            entity_type: 'product',
            proposed_price: price
        }
        console.log(payload)
        setRemoveLoader(true);
        setSellLoader(true);
        console.log(price_payload)
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
            .then(res => {
                if (state.is_store_item == -2) {
                    price_payload.action = 'update';
                } else if (state.is_store_item == 1) {
                    price_payload.action = 'remove';
                } else {
                    price_payload.action = 'insert';
                }

                updateStore(price_payload);
            })
            .catch((error) => {
                setRemoveLoader(false);
                console.log(error);
                message.error('Error in removing from store.');
            });
    }

    const updateStore = (payload) => {
        axios.post(ENVIRONMENT.SET_STORE_PRICE, payload)
            .then((response) => {
                setSellLoader(false);
                setRemoveLoader(false);
                setRemoveFromStore(false);
                if (state.is_store_item == -2) {
                    message.info('Price updated successfully!');
                } else if (state.is_store_item == 1) {
                    message.info('Item removed from store successfully!');
                } else {
                    message.info('Price submitted successfully!');
                }
                window.location.href = '/list-products';
            })
            .catch((error) => {
                setSellLoader(false);
                console.log(error);
                message.error('Error.');
            });
    }

    const fetchProductInfo = () => {
        var product_id = 0;
        product_id = props.params.id;
        getSavedProduct(props.params.id);
        props.loadPage();
        const payload = {
            'product_id': product_id,
            'username': CUSTOMER_USERNAME,
            'customer_company_id': COMPANY_ID
        }
        axios.post(ENVIRONMENT.FETCH_PRODUCT, payload)
            .then(res => {
                if (res.data.restricted && res.data.restricted == true) {
                    setRestrictedItem(true);
                }
                if (res.data.variation_type) {
                    setVariationType(res.data.variation_type);
                }
                let company_id = null;
                if (res.data['company_id'] != undefined) {
                    setProductCompanyId(res.data['company_id']);
                    let current_company = localStorage.getItem('company_id');
                    if (current_company === res.data['company_id'].toString() || FileConstants.isMSProvider) {
                        company_id = res.data['company_id'].toString();
                        setCompanyId(company_id)
                    }
                }
                if (res.data.group_id) {
                    setGroupId(res.data.group_id)
                }
                loadProductAssets(company_id, res.data);
                let is_configurable = res.data.is_configurable;
                setIsConfigurable(is_configurable);
                console.log("Product", res.data);
                setValidationReport(res.data.validation_report)
                setUploadedModel(res.data.uploaded_model)
                setAssignedArtist(res.data.assigned_artist)
                setGtin(res.data.gtin)
                setTags(res.data.tags)
                setVariantModel(res.data.variant_of ? true : false);

                let payload = {
                    product_id: props.params.id
                };
                axios.post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH, payload)
                    .then(res => {
                        let response = res.data;
                        if (response) {
                            response = response.body;
                            setLibraryMaterial(response);
                            console.log('Materials', response)

                        }
                    });

                if (res.data.variant_of != null) {
                    let variant_payload = {
                        product_id: props.params.id
                    };
                    axios.post(ENVIRONMENT.PRODUCT_VARIATION_REQUEST_GET, variant_payload)
                        .then(res => {
                            let response = res.data;
                            if (response) {
                                if (response['status'] == FileConstants.PRODUCT_VARIANT_AUTOMATION_STATUS["COMPLETED"]) {
                                    setAutomatedProductVariant(true)
                                }
                            }
                        });

                    let product_id = res.data.variant_of;
                    axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
                        .then(res => {
                            setBaseVariantPlatform(res.data.platform);
                            setbaseVariantPlatformLoader(false);
                        })
                }
            });
        loadUserRequestStatus();
    }

    const loadPayments = () => {
        let payload = {
            "request_type": 'product',
        }

        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
            .then(res => {
                console.log('Prices', res.data);
                if (res.data) {
                    setPrices(res.data);
                }
            })
    }

    const fetchProduct = (id) => {

        let product_id = id;
        console.log('Progress Previosuly Saved')

        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
            .then(res => {
                console.log(res.data);
                if (res.data.variation_type) {
                    setVariationType(res.data.variation_type);
                }
                setProductDetails(res.data);
            });
    }

    //Function to set rejection history
    useEffect(() => {
        axios.post(ENVIRONMENT.GET_PRODUCT_REJECTIONS, { product_id: props.params.id })
            .then(res => {
                setRejectionDetails(res.data);
            })
    }, []);

    useEffect(() => {
        //This function is responsible to set dimentional setting default values to customer default values if set on backend
        axios.post(ENVIRONMENT.GET_CUSTOMER_DEFAULT_SETTINGS, { customer_username: localStorage.getItem("username") })
            .then(res => {
                if (res.data.dimensional_settings != undefined) {
                    setCustomerDefaultDimensionSettings(res.data.dimensional_settings);
                    if (res.data.dimensional_settings.dpi != undefined) {
                        setCustomerDefaultDimDPI(res.data.dimensional_settings.dpi);
                    }
                    if (res.data.dimensional_settings.resolution != undefined) {
                        setCustomerDefaultDimResolution(res.data.dimensional_settings.resolution);
                    }
                }
            });
    }, []);

    useEffect(() => {
        //This function is responsible to set dimentional setting default values to customer default values if set on backend
        axios.post(ENVIRONMENT.GET_CUSTOMER_DEFAULT_SETTINGS, { customer_username: localStorage.getItem("username") })
            .then(res => {
                if (res.data.dimensional_settings != undefined) {
                    setCustomerDefaultDimensionSettings(res.data.dimensional_settings);
                    if (res.data.dimensional_settings.dpi != undefined) {
                        setCustomerDefaultDimDPI(res.data.dimensional_settings.dpi);
                    }
                    if (res.data.dimensional_settings.resolution != undefined) {
                        setCustomerDefaultDimResolution(res.data.dimensional_settings.resolution);
                    }
                }
            });
    }, []);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_PRODUCT_FIXES_LIST, { product_id: props.params.id })
            .then(res => {
                setFixesDetails(res.data);
            })
    }, []);

    const loadUserRequestStatus = (requestType = null) => {
        let payload = {
            "username": localStorage.getItem('username'),
            "action": "check",
            "request_type": "variation_product_model",
            "category": "color"
        }
        if (requestType != null) {
            payload = {
                "username": localStorage.getItem('username'),
                "action": "check",
                "request_type": requestType,
            }
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                console.log('USERCHECK', res)
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
                loadPayments();
                if (requestType == 'customer_model_fix') {
                    setRejectionPrice(res.data['price'])
                }
            });
    }

    const loadProductAssets = (company_id, product_data) => {
        let payload = { product_id: props.params.id, username: localStorage.getItem("username") }
        if (company_id != null) {
            payload.company_id = company_id
        }
        props.getProduct(payload);
        setState(product_data)
    }

    const getSavedProduct = (id) => {
        if (savedID == -1) {
            let payload = {
                variant_of: props.params.id,
                model_status: "1"
            }
            axios.post(ENVIRONMENT.GET_PRODUCT_VARIANTS, payload)
                .then(res => {
                    console.log(res.data, 'Saved');
                    if (res.data && res.data[0]) {
                        setSavedID(res.data[0].id);
                        fetchProduct(res.data[0].id);
                    }
                }).catch(err => {
                    console.log(err, 'An error occured in getting variants');
                });
        }
    }

    useEffect(() => {
        getCategoriesBatch();
    }, []);

    const getCategoriesBatch = () => {
        const payload = {
            output: 'tree',
            disable_parents: 'true'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
        .then(res => {
            setCategoriesData(res.data);
        });

        let data = [];
        axios.post(ADMIN_MATERIAL_BATCH, {
        })
            .then(res => {
                let categoriesData = Utilities.getMaterialsData(res.data);
                for (let i = 1; i <= (res.data).length; i++) {
                    if (res.data[i - 1].parent_id == "") {
                        data.push({
                            key: res.data[i - 1]['material_id'],
                            title: res.data[i - 1]['name'],
                            value: res.data[i - 1]['name'],
                            children: categoriesData[res.data[i - 1]['material_id']],
                        });
                    }

                }
                setMaterialsData(data);
            });
    }


    useEffect(() => {
        if (props.productReducer.product != undefined) {
            if (props.productReducer.product.model_status == 4) {
                checkIfNewUser();
            }
            if (props.productReducer.product.model_status == 5) {
                checkIfOpenGenerateButton();
            }
            if (props.productReducer.product.required_assets != undefined) {
                if (props.productReducer.product.required_assets.includes("ar")) {
                    // setMobileEligible(true);
                }
            }
            if (props.productReducer.product.mp4_details != undefined) {
                if (Object.entries(props.productReducer.product.mp4_details).length !== 0) {
                    setMp4Exists(true);
                    setMp4FrameCount(props.productReducer.product.mp4_details.frame_count);
                    setMp4Resolution(props.productReducer.product.mp4_details.resolution);
                    setMp4FpsCount(props.productReducer.product.mp4_details.frame_rate);
                    setMp4Status(props.productReducer.product.mp4_details.asset_status);
                    // setDisableMp4(true);
                } else {
                    setMp4Exists(false);
                    setMp4Status('');
                }
            } else {
                setMp4Status('')
            }

            if (props.productReducer.product.threesixty_spin_details != undefined) {
                if (Object.entries(props.productReducer.product.threesixty_spin_details).length !== 0) {
                    setStatus360(props.productReducer.product['threesixty_spin_details'].asset_status);
                } else {
                    setExist360(false);
                    setStatus360('');
                }
            } else {
                setStatus360('');
            }
           

            

            checkCryptomatte();
        }
        console.log(props.productReducer.product)

    }, [props.productReducer.product]);

    const changeDimRes = (value) => {
        console.log(value)
        setDimensionalResolution(value);
    }

    const checkCryptomatte = () => {
        if (props.productReducer.product != undefined) {
            if (props.productReducer.product['2d'] != undefined && props.productReducer.product['2d'].length > 0) {
                props.productReducer.product['2d'].map((url) => {
                    let splitted_url = url.split('/')
                    let last_token = splitted_url[splitted_url.length - 1];
                    let image_name = last_token.split('.');
                    let image_name_w_format = image_name[0] + '_cMatte.jpg'
                    let new_url = ENVIRONMENT.getBaseURL(props.productReducer.product.platform) + ENVIRONMENT.QA_IMG_URI + image_name_w_format;
                    if (image_name[0].includes('_Dimensions')) {
                        new_url = ENVIRONMENT.getBaseURL(props.productReducer.product.platform) + ENVIRONMENT.IMAGE_DIMENSIONAL_URI + image_name_w_format;
                    }
                    if (!cryptoMatteExists) {
                        axios.head(new_url, {})
                            .then(res => {
                                console.log(res)
                                setCryptoMatteExists(true);

                                if (image_name[0].includes('_Dimensions')) {
                                    setDimensionCrypto(true);
                                }
                            })
                    }
                });
            }
        }
    }

    const addCategoryOption = (e) => {
        if (e.keyCode == 13) {
            if (!categoriesData.some(e => e.key == categorySearchValue)) {
                setCategoriesData(categoriesData.concat([{
                    key: categorySearchValue,
                    value: categorySearchValue,
                    title: categorySearchValue,
                    children: [],
                }]));
            }
        }
    }

    const addGroupIdOption = () => {
        if (!customerGroupIds.some(e => e.key == groupIdSearchValue)) {
            setCustomerGroupIds(customerGroupIds.concat([{
                key: groupIdSearchValue,
                value: groupIdSearchValue,
                title: groupIdSearchValue,
            }]));
        }
    }

    const closeVariationModal = () => {
        setVariationModal(false);
        setVariationStep(0);
    }

    const openVariationModal = () => {
        setVariationModal(true);
    }

    const variationCreated = () => {
        setVariationStep(0);
        setVariationModal(false);
        setRequestSubmitted(true);
    }

    const variationSaved = () => {
        setVariationStep(0);
        setVariationModal(false);
        setRequestSaved(true);
    }


    const cancelEdit = () => {
        setOpenEditModal(false);
    }

    const openEditForm = () => {
        setOpenEditModal(true);
    }

    const onChangeVariationType = (e) => {
        setVariationType(e.target.value);
        if (e.target.value == 'ar_conversion' && arVariationType == '') {
            setARVariationType('ar');
        }
        if (e.target.value != 'ar_conversion') {
            setARVariationType('');
        }
    }

    const onChangeARVariationType = (e) => {
        setARVariationType(e.target.value)
    }

    useEffect(() => {
        if (inAppLimitExceededModal == false) {
            setDimensionalLoader(false);
            setDimensionalDetailsModal(false);
            setMp4DetailsModal(false);
            setMp4GenerationLoader(false);
            setThreesixtyDetailsModal(false);
            setThreesixtyGenerationLoader(false);
        }
    }, [inAppLimitExceededModal])

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'save_card') {
                message.success('Payment Method Updated Successfully.');
                loadUserRequestStatus();
            }
        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if (child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for (var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const saveProductData = (values) => {
        if ((values.product_name == props.productReducer.product.product_name) && (values.brand_id == props.productReducer.product.brand_id)
            && (values.category == props.productReducer.product.category) && (values.style_category == state.style_category) && (values.reference_urls == state.reference_urls) &&
            (values.color_name == state.color_name) && (values.similar_color == state.similar_color) && (values.guidelines == state.guidelines) && (values.group_id == state.group_id) && (values.tags == state.tags)
            && (values.gtin == state.gtin) && (values.product_model_type == state.product_model_type)) {
            setErrorMessage("You have not made any change to your product model's data. Kindly make changes to the data and 'Save'.")
        } else {
            setErrorMessage('');
            setSaveButtonLoader(true);
            if (values.product_name && values.category && values.similar_color) {
                let payload = {
                    product_id: props.params.id,
                    product_name: values.product_name == "" ? props.productReducer.product.product_name : values.product_name,
                    brand_id: values.brand_id,
                    gtin: values.gtin,
                    category: values.category == "" ? props.productReducer.product.category : values.category,
                    style_category: values.style_category,
                    reference_urls: values.reference_urls,
                    color_name: values.color_name,
                    similar_color: values.similar_color == "" ? state.similar_color : values.similar_color,
                    guidelines: values.guidelines,
                    group_id: values.group_id,
                    tags: values.tags,
                    product_model_type: values.product_model_type == "" ? state.product_model_type : values.product_model_type,
                    username: CUSTOMER_USERNAME
                }
                axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
                    .then(res => {
                        setSaveButtonLoader(false);
                        setOpenEditModal(false);
                        setSuccessSave(true);
                    })
                    .catch((error) => {
                        message.error('Error in saving product details.');
                    });
            } else {
                setErrorMessage('You cannot leave mandatory fields empty.');
            }

        }
    }

    const handleEmbedCancel = e => {
        setEmbedLink(false);
    };
    const handlePublicCancel = e => {
        setPublicLink(false)
    }
    const handleEmbedOk = e => {
        setEmbedLink(false);
        setSuccessEmbedModal(true);
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($("#iframe-text").text()).select();
        document.execCommand("copy");
        $temp.remove();
        message.success('Embed link copied to clipboard!');
    };

    const showEmbedModel = () => {
        setEmbedLink(true);
    }
    const showPublicModel = () => {
        setPublicLink(true);
    }

    const openThreeSixtyModal = () => {
        let prices360 = [];
        if (prices.length > 0) {
            if (res360 == "2.0") {
                prices360 = prices.filter((request) => request.request_type == 'product_360_spin' && request.category == "2k");
            } else {
                prices360 = prices.filter((request) => request.request_type == 'product_360_spin' && request.category == "4k");
            }
        }
        if (prices360 && prices360[0]) {
            setThreeSixtyPrice(parseInt(prices360[0].price));
            setSelectedPriceCategory(prices360[0].category);
        }
        setThreesixtyDetailsModal(true);
    }

    const openVideoModal = () => {
        let pricesmp4 = [];
        if (prices.length > 0) {
            pricesmp4 = prices.filter((request) => request.request_type == 'product_video' && request.category == "1k");
        }
        if (pricesmp4 && pricesmp4[0]) {
            setMp4Price(parseInt(pricesmp4[0].price));
            setSelectedPriceCategory(pricesmp4[0].category);
        }
        setMp4DetailsModal(true);
    }

    const openDimensionalModal = () => {
        let pricesdim = [];
        if (prices.length > 0) {
            pricesdim = prices.filter((request) => request.request_type == 'product_dimensional' && request.category == dimensionalResolution);
        }
        if (pricesdim && pricesdim[0]) {
            setDimensionalPrice(parseInt(pricesdim[0].price));
            setSelectedPriceCategory(pricesdim[0].category);
        }
        setDimensionalDetailsModal(true);
    }


    const onReject = (values, reject = true) => {
        if (uploadedModel == true && assignedArtist == '' && reject == true && variantModel == false) {
            let model_status = "2";
            if (automatedProductVariant == true) {
                model_status = FileConstants.AUTOMATED_VARIANT_CUSTOMER_REJECT_STATUS[variationType]
            }
            let payload = {
                "product_id": props.params.id,
                "username": localStorage.getItem('username'),
                "rejection_details": values.msg,
                "reference_files": values.reference_files,
                "customer_rejection": !reject, //should only be true in save comment case
                "model_status": model_status
            }

            let gateway_payload = {
                "username": localStorage.getItem('username'),
                "request_type": "customer_model_fix",
                "action": "perform",
                "request_payload": payload,
                "request_id": Date.now().toString()
            }
            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status != undefined && res.data.status == 1) {
                            // success of rejection
                            message.success("Model Rejected!");
                            setRejectLoader(false);
                            setShowArtistModal(false);
                            setRejectionModalVisible(false);
                            window.location.href = "/home";
                        }
                        else {
                            if (res.data.request_allowed == 'not_allowed') {
                                setInAppLimitExceededModal(true);
                                //in-app limit exceeded
                            } else {
                                message.error('Something went wrong');
                                //error in API Call
                            }
                        }
                    })
            }
            if (requestStatus == 'payment_required') {
                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'customer_model_fix') {
                        onRejectRequestAPICall(gateway_payload);
                    }

                }, false);

                if (cardStatus == 1) {
                    onRejectRequestAPICall(gateway_payload);
                }
                if (cardStatus == 0) {
                    let child_window = window.open('/save-card?action=customer_model_fix', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }
        }
        else {
            let payload = {
                "product_id": props.params.id,
                "rejection_details": values.msg,
                "rejection_against_artist": props.productReducer.product.assigned_artist,
                "reference_files": values.reference_files,
                "username": localStorage.getItem('username'),
                "customer_rejection": !reject, //should only be true in save comment case
            }
            axios.post(ENVIRONMENT.ENTITY_MODEL_REJECT, payload)
                .then(res => {
                    if (reject === true) {
                        let model_status = "-4";
                        if (automatedProductVariant == true) {
                            model_status = FileConstants.AUTOMATED_VARIANT_CUSTOMER_REJECT_STATUS[variationType]
                        }
                        message.success("Model Rejected!");
                        let payload = {
                            product_id: props.params.id,
                            model_status: model_status,
                            currently_assigned_artist: assignedArtist,
                            username: localStorage.getItem('username'),
                        }
                        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
                            .then(res => {
                                window.location.href = "/home";
                            })
                            .catch((error) => {
                                message.error('Error in rejecting product.');
                            });
                    }
                    setRejectLoader(false);
                    setShowArtistModal(false);
                })
                .catch((error) => {
                    message.error('Error in rejecting product.');
                });

        }
    }

    const approveAutomatedVariantRequestStatus = () => {
        let payload = {
            product_id: props.params.id,
            status: FileConstants.PRODUCT_VARIANT_AUTOMATION_STATUS["APPROVED"]
        }
        axios.post(ENVIRONMENT.PRODUCT_VARIATION_REQUEST_UPDATE, payload)
    }

    const onApprove = () => {
        setApproveLoader(true);
        let payload = {
            product_id: props.params.id,
            username: localStorage.getItem("username"),
            model_status: "5"
        }
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
        .then(res => {
            if (IS_MS_PROVIDER && state.requested_for != undefined && state.requested_for != '') {
                const transfer_payload = {
                    entity_type : 'product',
                    product_id: props.params.id,
                    requested_for: state.requested_for
                }
                axios.post(ENVIRONMENT.TRANSFER_OWNERSHIP, transfer_payload)
                .then(res => {
                    if (automatedProductVariant == true) {
                        approveAutomatedVariantRequestStatus();
                    } 
                    markCollaborationAsResolved();
                    setNextActionPrompt(true);
                })
                .catch((error) => {
                    message.error('Error in transferring product.');
                });
            } else {
                if (automatedProductVariant == true) {
                    approveAutomatedVariantRequestStatus()
                }
                markCollaborationAsResolved();
                setNextActionPrompt(true);
            }
        })
        .catch((error) => {
            message.error('Error in approving product.');
        });
    }

    const markCollaborationAsResolved = () => {
        let collab_payload = {
            'product_id': props.params.id,
            'is_resolved': true,
            'is_hidden': true,
            'last_modified_by': CUSTOMER_USERNAME,
            'action': 'resolve_collaboration_product'
        }

        axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
        .then(res=> {
            // successMessage();
        })
    }
    const successMessage = () => {
        setApproveLoader(false);
        message.success("Product successfully approved and moved to your library!");
        window.location.href = '/products/' + props.params.id;
    }

    const handleShare = values => {
        setSharingLoader(true);
        let body = values;
        body.product_id = props.params.id;
        body.username = localStorage.getItem("username");
        let lowercase_emails = body.emails.map(function (email) {
            return email.toLowerCase();
        });
        body.emails = lowercase_emails;
        let permissions = {}
        permissions[selectedPermission] = 1;
        body.permissions = permissions;
        axios.post(ENVIRONMENT.SHARE_PRODUCT, { body })
            .then(res => {
                setSuccessModal(true)
                setSharingModal(false);
                setSharingLoader(false);
            });
    };

    const addMaterialOption = (e) => {
        if (e.keyCode == 13) {
            if (!materialsData.some(e => e.key == materialSearchValue)) {
                setMaterialsData(materialsData.concat([{
                    key: materialSearchValue,
                    value: materialSearchValue,
                    title: materialSearchValue,
                    children: [],
                }]));
            }
        }
    }

    const saveRequestIntermedietely = (callback = null) => {

        if (requestType == 'product_360_spin') {
            setThreesixtyGenerationLoader(true);
        } else if (requestType == 'product_dimensional') {
            setDimensionalLoader(true);
        } else if (requestType == 'product_video') {
            setMp4GenerationLoader(true);
        }
        console.log(JSON.stringify(gatewayPayload))
        let payload = {
            'request_type': requestType,
            'request_body': gatewayPayload,
            'processed': false
        }
        if (paymentRequestID == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
                .then(res => {
                    console.log(res);

                    if (requestType == 'product_360_spin') {
                        setThreesixtyGenerationLoader(false);
                    } else if (requestType == 'product_dimensional') {
                        setDimensionalLoader(false);
                    } else if (requestType == 'product_video') {
                        setMp4GenerationLoader(false);
                    }
                    if (callback != null) {
                        callback(res.data[0].request_id, requestType);
                    }
                });
        } else {
            if (requestType == 'product_360_spin') {
                setThreesixtyGenerationLoader(false);
            } else if (requestType == 'product_dimensional') {
                setDimensionalLoader(false);
            } else if (requestType == 'product_video') {
                setMp4GenerationLoader(false);
            }
            if (callback != null) {
                callback(paymentRequestID, requestType);
            }
        }

    }

    const handleDimensionalSubmit = values => {
        setDimensionalLoader(true);
        let payload = {}
        payload.product_id = props.params.id;
        payload.username = localStorage.getItem("username");
        let dim_image_details = {
            dpi: values.custom_dimensional_dpi,
            resolution: values.custom_dimensional_resolution
        }

        if (dimensionalImageType == 'custom') {
            dim_image_details.unit = values.custom_dimensional_unit;
            dim_image_details.height = values.custom_dimensional_height;
            dim_image_details.width = values.custom_dimensional_width;
            dim_image_details.depth = values.custom_dimensional_depth;
        }

        //adding customer default settings in payload so can be sent to automation
        dim_image_details.customer_default_dim_settings = customerDefaultDimensionSettings;
        dim_image_details.captured_by = localStorage.getItem("username");
        dim_image_details.asset_status = 'pending';
        payload.dim_image_details = dim_image_details;
        payload.dim_job_status = 'generate'
        payload.generate_asset = 1
        let company_id = localStorage.getItem('company_id');
        if (company_id != undefined && state.company_id != undefined) {
            if (company_id == state.company_id) {
                payload.company_id = company_id;
            }
        }
        setRequestType('product_dimensional');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_dimensional",
            "action": "perform",
            "request_payload": payload,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
            axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                .then(res => {
                    if (res.data.status != undefined && res.data.status == 1) {
                        setDimensionalSuccessModal(true);
                        setDimensionalDetailsModal(false);
                        setDimensionalLoader(false);
                    }
                    else {
                        if (res.data.request_allowed == 'not_allowed') {
                            setDimensionalDetailsModal(false);
                            setDimensionalLoader(false);
                            setInAppLimitExceededModal(true);
                        } else {
                            message.error('something went wrong');
                        }
                    }
                })
        }
        if (requestStatus == 'payment_required') {
            window.addEventListener('card_saved', (e) => {
                let action = e.detail.action;
                setCardSaveFlowTriggered(false);
                if (action == 'product_dimensional') {
                    dimensionalRequestAPICall(gateway_payload);
                }

            }, false);

            if (cardStatus == 1) {
                dimensionalRequestAPICall(gateway_payload);
            }
            if (cardStatus == 0) {
                let child_window = window.open('/save-card?action=product_dimensional', '', "width=600,height=600");
                let refreshInterval = setInterval(() => {
                    if (child_window.closed) {
                        setCardSaveFlowTriggered(false);
                        clearInterval(refreshInterval);
                    }
                }, 500);
                setCardSaveFlowTriggered(true);
            }
        }
    }

    const onRejectRequestAPICall = (gateway_payload) => {
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    // Success of rejection
                    message.success("Model Rejected!");
                    setRejectLoader(false);
                    setShowArtistModal(false);
                    setRejectionModalVisible(false);
                    window.location.href = "/home";
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        setInAppLimitExceededModal(true);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                    }
                }
            })
    }

    const dimensionalRequestAPICall = (gateway_payload) => {
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    setDimensionalSuccessModal(true);
                    setDimensionalDetailsModal(false);
                    setDimensionalLoader(false);
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        setInAppLimitExceededModal(true);
                        setDimensionalDetailsModal(false);
                        setDimensionalLoader(false);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setDimensionalLoader(false);
                    }
                }
            })
    }

    const handleMp4Submit = values => {
        let payload = {};
        payload.product_id = props.params.id;
        payload.username = localStorage.getItem("username");
        payload.mp4_details = values;
        payload.mp4_details.captured_by = localStorage.getItem("username");
        payload.mp4_details.asset_status = 'pending';
        payload.mp4_job_status = 'generate';
        payload.generate_asset = 1;
        let company_id = localStorage.getItem('company_id');
        if (company_id != undefined && state.company_id != undefined) {
            if (company_id == state.company_id) {
                payload.company_id = company_id;
            }
        }
        setMp4Payload(payload);
        setRequestType('product_video');
        let requestPayload = payload;
        requestPayload['username'] = localStorage.getItem('username');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_video",
            "action": "perform",
            "request_payload": requestPayload,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if (!mp4Exists) {
            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
                setMp4GenerationLoader(true);
                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status != undefined && res.data.status == 1) {
                            setMp4SuccessModal(true);
                            setMp4DetailsModal(false);
                            setMp4GenerationLoader(false);
                            setMp4Status("pending");
                        }
                        else {
                            if (res.data.request_allowed == 'not_allowed') {
                                setInAppLimitExceededModal(true);
                                setMp4DetailsModal(false);
                                setMp4GenerationLoader(false);
                            } else {
                                message.error('something went wrong');
                            }
                        }
                    });
            }
            if (requestStatus == 'payment_required') {
                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'product_video') {
                        mp4RequestAPICall(gateway_payload);
                    }

                }, false);

                if (cardStatus == 1) {
                    mp4RequestAPICall(gateway_payload);
                }
                if (cardStatus == 0) {
                    let child_window = window.open('/save-card?action=product_video', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }


            }

        } else {
            setReplaceMP4Confirm(true);
            setMp4DetailsModal(false);
            setMp4GenerationLoader(false);
        }
    }

    const mp4RequestAPICall = (gateway_payload) => {
        setMp4GenerationLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    setMp4SuccessModal(true);
                    setMp4DetailsModal(false);
                    setMp4GenerationLoader(false);
                    setMp4Status("pending");
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        setInAppLimitExceededModal(true);
                        setMp4DetailsModal(false);
                        setMp4GenerationLoader(false);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setMp4GenerationLoader(false);
                    }
                }
            });
    }

    const replaceMP4 = () => {
        setRequestType('product_video');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_video",
            "action": "perform",
            "request_payload": mp4Payload,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }

        if (mp4Exists) {
            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
                setMp4GenerationLoader(true);
                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status != undefined && res.data.status == 1) {
                            setReplaceMP4Confirm(false);
                            setMp4SuccessModal(true);
                            setMp4DetailsModal(false);
                            setMp4Status("pending");
                            setMp4GenerationLoader(false);
                        }
                        else {
                            if (res.data.request_allowed == 'not_allowed') {
                                setInAppLimitExceededModal(true);
                                setMp4DetailsModal(false);
                                setMp4GenerationLoader(false);
                            } else {
                                message.error('something went wrong');
                            }
                        }
                    });
            }
            if (requestStatus == 'payment_required') {
                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'product_video') {
                        mp4ReplaceRequestAPICall(gateway_payload);
                    }

                }, false);

                if (cardStatus == 1) {
                    mp4ReplaceRequestAPICall(gateway_payload);
                }
                if (cardStatus == 0) {
                    let child_window = window.open('/save-card?action=product_video', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }

        }
    }

    const mp4ReplaceRequestAPICall = (gateway_payload) => {
        setMp4GenerationLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    setReplaceMP4Confirm(false);
                    setMp4SuccessModal(true);
                    setMp4DetailsModal(false);
                    setMp4Status("pending");
                    setMp4GenerationLoader(false);
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        setInAppLimitExceededModal(true);
                        setMp4DetailsModal(false);
                        setMp4GenerationLoader(false);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setMp4GenerationLoader(false);
                    }
                }

            });
    }

    const handleThreesixtySubmit = values => {
        let payload = {};
        payload.product_id = props.params.id;
        payload.username = localStorage.getItem("username");
        payload.threesixty_spin_details = values;
        payload.threesixty_spin_details.captured_by = localStorage.getItem("username");
        payload.threesixty_spin_details.asset_status = 'pending';
        payload.threesixty_job_status = 'generate';
        payload.generate_asset = 1;
        let company_id = localStorage.getItem('company_id');
        if (company_id != undefined && state.company_id != undefined) {
            if (company_id == state.company_id) {
                payload.company_id = company_id;
            }
        }
        setPayload360(payload);
        setRequestType('product_360_spin');
        let requestPayload = payload;
        requestPayload['username'] = localStorage.getItem('username');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_360_spin",
            "action": "perform",
            "request_payload": requestPayload,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if (!exist360) {
            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
                setThreesixtyGenerationLoader(true);
                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status != undefined && res.data.status == 1) {
                            setThreesixtySuccessModal(true);
                            setThreesixtyDetailsModal(false);
                            setThreesixtyGenerationLoader(false);
                            setStatus360("pending");
                        }
                        else {
                            if (res.data.request_allowed == 'not_allowed') {
                                setThreesixtyDetailsModal(false);
                                setThreesixtyGenerationLoader(false);
                                setInAppLimitExceededModal(true);
                            } else {
                                message.error('something went wrong');
                            }
                        }
                    })
            }
            if (requestStatus == 'payment_required') {
                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'product_360_spin') {
                        threesixtyAPICall(gateway_payload);
                    }

                }, false);

                if (cardStatus == 1) {
                    threesixtyAPICall(gateway_payload);
                }
                if (cardStatus == 0) {
                    let child_window = window.open('/save-card?action=product_360_spin', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }


        } else {
            setReplace360Confirm(true);
            setThreesixtySuccessModal(false);
            setThreesixtyDetailsModal(false);
            setThreesixtyGenerationLoader(false);
        }

    }

    const threesixtyAPICall = (gateway_payload) => {
        setThreesixtyGenerationLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    setThreesixtySuccessModal(true);
                    setThreesixtyDetailsModal(false);
                    setThreesixtyGenerationLoader(false);
                    setStatus360("pending");
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        setThreesixtyDetailsModal(false);
                        setThreesixtyGenerationLoader(false);
                        setInAppLimitExceededModal(true);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setThreesixtyGenerationLoader(false);
                    }
                }
            })
    }

    const replace360 = () => {
        setRequestType('product_360_spin');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": "product_360_spin",
            "action": "perform",
            "request_payload": payload360,
            "category": selectedPriceCategory,
            "request_id": Date.now().toString()
        }
        if (exist360) {
            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
                setThreesixtyGenerationLoader(true);
                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status != undefined && res.data.status == 1) {
                            setReplace360Confirm(false);
                            setThreesixtySuccessModal(true);
                            setThreesixtyDetailsModal(false);
                            setThreesixtyGenerationLoader(false);
                            setStatus360("pending");
                        }
                        else {
                            if (res.data.request_allowed == 'not_allowed') {
                                setThreesixtyDetailsModal(false);
                                setThreesixtyGenerationLoader(false);
                                setInAppLimitExceededModal(true);
                            } else {
                                message.error('something went wrong');
                            }
                        }
                    });
            }
            if (requestStatus == 'payment_required') {
                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'product_360_spin') {
                        threesixtyReplaceAPICall(gateway_payload);
                    }

                }, false);

                if (cardStatus == 1) {
                    threesixtyReplaceAPICall(gateway_payload);
                }
                if (cardStatus == 0) {
                    let child_window = window.open('/save-card?action=product_360_spin', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }

        }
    }

    const threesixtyReplaceAPICall = (gateway_payload) => {
        setThreesixtyGenerationLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    setReplace360Confirm(false);
                    setThreesixtySuccessModal(true);
                    setThreesixtyDetailsModal(false);
                    setThreesixtyGenerationLoader(false);
                    setStatus360("pending");
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        setThreesixtyDetailsModal(false);
                        setThreesixtyGenerationLoader(false);
                        setInAppLimitExceededModal(true);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setThreesixtyGenerationLoader(false);
                    }
                }
            });
    }


    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setIsFullScreen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    useEffect(() => {
        window.CI360.destroy();
        window.CI360.init();
    }, [isFullScreen]);

    const convertTimeToHoursString = (hours) => {
        hours = Math.round(hours * 1000) / 1000 // rounding to 2 decimal places only if needed
        var result = "";
        if (hours > 0) {
            result += hours;
            result += hours == 1 ? " Hour " : " Hours ";
        }
        else {
            result = "0 Hours"
        }
        return result;
    }

    const totalModelingTime = () => {
        var hours = +props.productReducer.product.time1 +
            +props.productReducer.product.time2 +
            +props.productReducer.product.time3 +
            +props.productReducer.product.time4;
        return convertTimeToHoursString(hours);
    }

    const getArchiveSize = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name === 'MAX');
        let size = 0;

        if (link) {
            axios.head(link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product['platform']))).then(res => {
                if (res.headers['content-length']) {
                    size = parseInt(res.headers['content-length']);
                    setArchiveSize(size);
                }
            });
        }
    }

    const getGlbSize = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name === 'GLB');
        let size = 0;
        if (link) {
            axios.head(link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product['platform']))).then(res => {
                if (res.headers['content-length']) {
                    size = parseInt(res.headers['content-length']);
                    setGlbSize(size);
                }
            });
        }
    }

    const convertByteSize = (size_in_mbs) => {
        let result = size_in_mbs.toFixed(2) + " MBs";
        if (size_in_mbs / 1024 > 1024) // means more then 1 GB
        {
            size_in_mbs = (size_in_mbs / (1024)).toFixed(1); // size in GBs
            result = size_in_mbs + " GBs";
        }
        return result;
    }

    const totalFixesTime = () => {
        var hours = 0;
        for (var i = 0; i < fixesDetails.length; i++) {
            hours += +fixesDetails[i].fix_time;
        }
        return convertTimeToHoursString(hours)
    }

    const handleFrameChange = (value) => {
        setFrame360(value);
    }

    const handleResChange = (value) => {
        setRes360(value);
    }

    const handleMp4FrameChange = (value) => {
        setMp4FrameCount(value);
    }

    const handleFpsChange = (value) => {
        setMp4FpsCount(value);
    }

    const handleMp4ResChange = (value) => {
        setMp4Resolution(value);
    }

    useEffect(() => {
        if (props.productReducer.product != undefined) {
            if (props.productReducer.product.mp4_details != undefined) {
                if (Object.entries(props.productReducer.product.mp4_details).length !== 0) {
                    setMp4Exists(true);
                    // use for later
                    // if (mp4FrameCount == props.productReducer.product.mp4_details.frame_count && mp4FpsCount == props.productReducer.product.mp4_details.frame_rate && mp4Resolution == props.productReducer.product.mp4_details.frame_resolution) {
                    // } else {
                    // }
                }
            }

            if (props.productReducer.product.threesixty_spin_details != undefined) {
                if (Object.entries(props.productReducer.product.threesixty_spin_details).length !== 0) {
                    setExist360(true);
                    if (frame360 == props.productReducer.product.threesixty_spin_details.frame_count && res360 == props.productReducer.product.threesixty_spin_details.frame_resolution) {
                        setDisable360(true);
                    } else {
                        setDisable360(false);
                    }
                }
            }
        }
    }, [frame360, res360]);

    const onMouseOut = (evt) => {
        let element = document.getElementById('edit-icon');
        if (element) {
            element.setAttribute("src", "/img/edit-w-hover.png");
        }
    }

    const onMouseOver = (evt) => {
        let element = document.getElementById('edit-icon')
        if (element) {
            element.setAttribute("src", "/img/edit-icon.png");
        }
    }

    // get folder id of product from parameters and set it
    const getURLParamsAndSetFolderParentID = () => {
        const url = new URL(window.location);
        let id = new URLSearchParams(url.search).get("folder_id");
        setFolderParentID(id);
    };

    useEffect(() => {
        getURLParamsAndSetFolderParentID();
    }, [folderParentID]);

    const switchToNewView = () => {
        const hide = message.loading('Switching to new Product QA View..', 0, () => {
            message.success('Switched to new view');
        });
       
        let payload = {
            'product_legacy_view': false,
            'username': CUSTOMER_USERNAME,
            'action': 'set_product_legacy_view'
        }
        if (COMPANY_ID != undefined) {
            payload['company_id'] = COMPANY_ID;
        }
        setLegacyViewLoader(true);
        axios.post(ENVIRONMENT.USER_PREFERENCE_CONTROLLER, payload)
            .then(res => {
                setTimeout(hide, 0);
                setLegacyViewLoader(false);
                window.location.reload();
            });
    }


    return (
            <ProductDetailsView {...props}
                switchToNewView={switchToNewView}
                legacyViewLoader={legacyViewLoader}
                legacyView={props.legacyView}
                // userPreferenceLoader={userPreferenceLoader}
                folderParentID={folderParentID}
                disableMp4={disableMp4}
                groupId={groupId}
                gtin={gtin}
                restrictedItem={restrictedItem}
                setGroupId={setGroupId}
                customerGroupIds={customerGroupIds}
                groupIdSearchValue={groupIdSearchValue}
                setGroupIdSearchValue={setGroupIdSearchValue}
                addGroupIdOption={addGroupIdOption}
                rejectionDetails={rejectionDetails}
                mp4FpsCount={mp4FpsCount}
                mp4FrameCount={mp4FrameCount}
                mp4Resolution={mp4Resolution}
                handleFpsChange={handleFpsChange}
                handleMp4ResChange={handleMp4ResChange}
                handleMp4FrameChange={handleMp4FrameChange}
                productCompanyId={productCompanyId}
                res360={res360}
                frame360={frame360}
                disable360={disable360}
                handleFrameChange={handleFrameChange}
                handleResChange={handleResChange}
                setMp4DetailsModal={setMp4DetailsModal}
                setDimensionalDetailsModal={setDimensionalDetailsModal}
                setThreesixtyDetailsModal={setThreesixtyDetailsModal}
                productReducer={props.productReducer}
                compareView={compareView}
                setCompareView={setCompareView}
                params={props.params}
                showEmbedModel={showEmbedModel}
                showPublicModel={showPublicModel}
                setSharingModal={setSharingModal}
                state={state}
                onApprove={onApprove}
                onReject={onReject}
                sharingModal={sharingModal}
                sharingLoader={sharingLoader}
                handleShare={handleShare}
                setSelectedPermission={setSelectedPermission}
                successModal={successModal}
                setSuccessModal={setSuccessModal}
                embedLink={embedLink}
                handleEmbedCancel={handleEmbedCancel}
                handleEmbedOk={handleEmbedOk}
                publicLink={publicLink}
                handlePublicCancel={handlePublicCancel}
                dimensionalDetailsModal={dimensionalDetailsModal}
                dimensionalLoader={dimensionalLoader}
                handleDimensionalSubmit={handleDimensionalSubmit}
                dimensionalImageType={dimensionalImageType}
                setDimensionalImageType={setDimensionalImageType}
                mp4DetailsModal={mp4DetailsModal}
                mp4GenerationLoader={mp4GenerationLoader}
                handleMp4Submit={handleMp4Submit}
                threesixtyDetailsModal={threesixtyDetailsModal}
                threesixtyGenerationLoader={threesixtyGenerationLoader}
                handleThreesixtySubmit={handleThreesixtySubmit}
                dimensionalSuccessModal={dimensionalSuccessModal}
                setDimensionalSuccessModal={setDimensionalSuccessModal}
                mp4SuccessModal={mp4SuccessModal}
                setMp4SuccessModal={setMp4SuccessModal}
                threesixtySuccessModal={threesixtySuccessModal}
                setThreesixtySuccessModal={setThreesixtySuccessModal}
                getGlbSize={getGlbSize}
                getArchiveSize={getArchiveSize}
                convertByteSize={convertByteSize}
                totalFixesTime={totalFixesTime}
                totalModelingTime={totalModelingTime}
                archiveSize={archiveSize}
                glbSize={glbSize}
                mp4Exists={mp4Exists}
                mp4Status={mp4Status}
                replaceMP4Confirm={replaceMP4Confirm}
                setReplaceMP4Confirm={setReplaceMP4Confirm}
                replaceMP4={replaceMP4}
                replace360={replace360}
                exist360={exist360}
                status360={status360}
                replace360Confirm={replace360Confirm}
                setReplace360Confirm={setReplace360Confirm}
                customerDefaultDimDPI={customerDefaultDimDPI}
                isConfigurable={isConfigurable}
                customerDefaultDimResolution={customerDefaultDimResolution}
                openEditModal={openEditModal}
                cancelEdit={cancelEdit}
                categoriesData={categoriesData}
                categoryValue={categoryValue}
                setCategorySearchValue={setCategorySearchValue}
                addCategoryOption={addCategoryOption}
                setCategoryValue={setCategoryValue}
                saveProductData={saveProductData}
                openEditForm={openEditForm}
                errorMessage={errorMessage}
                saveButtonLoader={saveButtonLoader}
                successSave={successSave}
                onMouseOut={onMouseOut}
                onMouseOver={onMouseOver}
                variationModal={variationModal}
                openVariationModal={openVariationModal}
                closeVariationModal={closeVariationModal}
                validateFileUploads={validateFileUploads}
                setErrorMessage={setErrorMessage}
                materialsData={materialsData}
                materialValue={materialValue}
                setMaterialValue={setMaterialValue}
                setMaterialSearchValue={setMaterialSearchValue}
                addMaterialOption={addMaterialOption}
                requestSubmitted={requestSubmitted}
                setRequestSubmitted={setRequestSubmitted}
                variationCreated={variationCreated}
                savedID={savedID}
                requestSaved={requestSaved}
                variationSaved={variationSaved}
                setRequestSaved={setRequestSaved}
                companyId={companyId}
                product_details={product_details}
                libraryMaterial={libraryMaterial}
                cryptoMatteExists={cryptoMatteExists}
                dimensionCrypto={dimensionCrypto}
                requestStatus={requestStatus}
                cardStatus={cardStatus}
                setSuccessSave={setSuccessSave}
                cardSaveFailure={cardSaveFailure}
                setCardSaveFailure={setCardSaveFailure}
                cardSaveDrawer={cardSaveDrawer}
                setCardSaveDrawer={setCardSaveDrawer}
                siloSuccessModal={siloSuccessModal}
                setSiloSuccessModal={setSiloSuccessModal}
                saveRequestIntermedietely={saveRequestIntermedietely}
                paymentRequestID={paymentRequestID}
                requestType={requestType}
                openVideoModal={openVideoModal}
                openDimensionalModal={openDimensionalModal}
                openThreeSixtyModal={openThreeSixtyModal}
                threeSixtyPrice={threeSixtyPrice}
                dimensionalPrice={dimensionalPrice}
                mp4Price={mp4Price}
                changeDimRes={changeDimRes}
                storePage={storePage}
                loadUserRequestStatus={loadUserRequestStatus}
                variationType={variationType}
                arVariationType={arVariationType}
                setVariationType={setVariationType}
                variationStep={variationStep}
                setVariationStep={setVariationStep}
                onChangeVariationType={onChangeVariationType}
                onChangeARVariationType={onChangeARVariationType}
                updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
                setPaymentFailureModal={setPaymentFailureModal}
                paymentFailureMessage={paymentFailureMessage}
                cardSaveFlowTriggered={cardSaveFlowTriggered}
                inAppLimitExceededModal={inAppLimitExceededModal}
                setInAppLimitExceededModal={setInAppLimitExceededModal}
                sellVisible={sellVisible}
                price={price}
                onChangePrice={onChangePrice}
                cancelSellModal={cancelSellModal}
                sellLoader={sellLoader}
                performStoreAction={performStoreAction}
                setStoreRequests={setStoreRequests}
                removeFromStore={removeFromStore}
                setRemoveFromStore={setRemoveFromStore}
                removeLoader={removeLoader}
                removeItemFromStore={removeItemFromStore}
                onChangeImageVisibility={onChangeImageVisibility}
                imageVisible={imageVisible}
                validationReport={validationReport}
                uploadedModel={uploadedModel}
                rejectionPrice={rejectionPrice}
                rejectLoader={rejectLoader}
                setRejectLoader={setRejectLoader}
                setShowArtistModal={setShowArtistModal}
                showArtistModal={showArtistModal}
                assignedArtist={assignedArtist}
                openGenerateButton={openGenerateButton}
                nextActionPrompt={nextActionPrompt}
                setNextActionPrompt={setNextActionPrompt}
                firstTimeUser={firstTimeUser}
                setFirstTimeUser={setFirstTimeUser}
                setOpenGenerateButtonTour={setOpenGenerateButtonTour}
                openGenerateButtonTour={openGenerateButtonTour}
                closeTour={closeTour}
                openGenerateOption={openGenerateOption}
                setOpenGenerateOption={setOpenGenerateOption}
                rejectionModalVisible={rejectionModalVisible}
                setRejectionModalVisible={setRejectionModalVisible}
                variantModel={variantModel}
                approveLoader={approveLoader}
                baseVariantPlatform={baseVariantPlatform}
                baseVariantPlatformLoader={baseVariantPlatformLoader}
            />
    );


}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    },
    listProducts: () => {
        dispatch(listProducts());
    },
    loadPage: () => {
        dispatch(loadPage())
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails)
)