import {
  CREATE_PRODUCT,
  PRODUCT_FAILURE,
  RENDER_PRODUCT,
  FETCH_PRODUCT,
  PRODUCT_REJECT_SUCCESS,
  REJECT_PRODUCT,
  GET_SCANNED_PRODUCTS,
  PRODUCT_SUCCESS, UPDATE_PRODUCT, LIST_PRODUCT, LIST_STORE_PRODUCT,
  LOAD_PAGE
} from '../actions';

const initialState = {
  product: {
    required_assets: []
  },
  products: [],
  scannedProducts: [],
  isLoading: false,
  isCreated: false,
  isUpdated: false,
  isRejected: false,
  error: null,
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_PRODUCT:
      return {
        product: { ...action.payload },
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case LIST_STORE_PRODUCT:
      return {
        product: { ...action.payload },
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case GET_SCANNED_PRODUCTS:
      return {
        product: { ...action.payload },
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case RENDER_PRODUCT:
      return {
        product: { ...action.payload },
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case FETCH_PRODUCT:
      return {
        product: { ...action.payload },
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case CREATE_PRODUCT:
      return {
        product: { ...action.payload },
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case UPDATE_PRODUCT:
      return {
        product: { ...state.product, ...action.payload },
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case REJECT_PRODUCT:
      return {
        product: state.product,
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: true,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case PRODUCT_REJECT_SUCCESS:
      return {
        product: state.product,
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: null,
      };
    case PRODUCT_SUCCESS:
      return {
        product: { ...action.payload, ...state.product },
        products: action.payload.products || state.products,
        scannedProducts: action.payload.scannedProducts || state.scannedProducts,
        isLoading: false,
        isCreated: action.payload.isCreated,
        isUpdated: action.payload.isUpdated,
        isRejected: false,
        error: null,
      };
    case PRODUCT_FAILURE:
      return {
        product: state.product,
        products: state.products,
        scannedProducts: state.scannedProducts,
        isLoading: false,
        isCreated: false,
        isUpdated: false,
        isRejected: false,
        error: action.payload.message,
      };
    case LOAD_PAGE:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
}
