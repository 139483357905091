import React, { useState, useEffect } from "react";
import { Logout } from "react-cognito";
import { Layout, Button, Progress, Modal, message } from "antd";
import axios from "axios";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import ENVIRONMENT from "../../../../environments";
import ModelDetails from "./ModelDetails";
import DottedLoader from "../../DottedLoader";
import CustomHeader from "../../CustomHeader";
import WarningModal from "../../WarningModal/WarningModal";

const { Header, Content, Footer } = Layout;

function ProductDetails(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [productId, setProductId] = useState(null);
  const [productDetails, setProductDetails] = useState({});
  const [lightingRig, setLightingRig] = useState(null);
  const [chooseModelButtonLoader, setChooseModelButtonLoader] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false)

  useEffect(() => {
    setProductId(props.match.params.id);
  }, []);

  useEffect(() => {
    if (productId) {
      const payload = {
        product_id: productId,
      };
      setIsLoading(true);
      axios.post(ENVIRONMENT.FETCH_PRODUCT, payload).then((res) => {
        if (res["data"]) {
          setProductDetails(res["data"]);
        }
        axios
          .post(ENVIRONMENT.GET_PRODUCT_LIGHTING_RIG, payload)
          .then((res) => {
            if (res["data"]) {
              setLightingRig(res["data"]["rig_file"]);
              setIsLoading(false);
            }
          });
      });
    }
  }, [productId]);

  const selectTestModel = () => {
    setChooseModelButtonLoader(true);
    let model_type = "test_model";
    // whether it is a test model of an all3d props
    if (productDetails["customer_username"] == "all3d_props")
      model_type = "all3d_props";
    let payload = {
      action: "artist_choose_test_model",
      product_id: productId,
      model_type: model_type,
      username: localStorage.getItem("username"),
    };

    axios.post(ENVIRONMENT.TEST_ARTIST_CONTROLLER, payload).then((res) => {
      if (res.data.status == 200) {
        setChooseModelButtonLoader(false);
        props.history.push("/artist_assignment");
      } else if (res.data.status == 400) {
        setWarningModalVisible(true)
      }
    });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <DottedLoader />
      ) : (
        <React.Fragment>
          <Header className="header">
            <CustomHeader></CustomHeader>
          </Header>
          <Layout className="mb-90">
            <Content className="layout-container layout-snow overflowY-scroll p-0">
              <ModelDetails
                requirements={productDetails["guidelines"]}
                outputRequired={productDetails["need_to_model"]}
                height={productDetails["height"]}
                width={productDetails["width"]}
                depth={productDetails["length"]}
                color={productDetails["color_name"]}
                similarColor={productDetails["similar_color"]}
                lightingRig={lightingRig}
                referenceURLs={productDetails["reference_urls"]}
                referenceImages={
                  productDetails["product_photos"] &&
                  productDetails["product_photos"].length > 0
                    ? productDetails["product_photos"]
                    : productDetails["product_manuals"] &&
                      productDetails["product_manuals"].length > 0
                    ? productDetails["product_manuals"]
                    : []
                }
                colorExtractedImages={productDetails["color_extracted_images"]}
                productID={productId}
                testID={productId}
                platform={ProductDetails["platform"]}
              />
            </Content>
          </Layout>
          <Layout className="footer-layout">
            <Footer className="footer footer-container">
              <Button
                className="modal-okay square font-14 float-right"
                key="select_model"
                onClick={selectTestModel}
                disabled={chooseModelButtonLoader}
              >
                Choose Model{" "}
                {chooseModelButtonLoader ? <LoadingOutlined /> : ""}
              </Button>
            </Footer>
          </Layout>
        </React.Fragment>
      )}

      <WarningModal
        visible={warningModalVisible}
        onCancel={() => setWarningModalVisible(false)}
        heading={"Modal not Available"}
        text={
          "This model is no longer available for you to pick. Please choose another model"
        }
        footer={[
          <div className="justify-in-end">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-14"
              onClick={() => {
                props.history.push("/test_job_desk");
              }}
            >
              Okay
            </Button>
          </div>,
        ]}
      />
    </React.Fragment>
  );
}

export default ProductDetails;
