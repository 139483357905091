import React, {useState, useEffect, useCallback} from 'react';
import ENVIRONMENT from '../../../../environments';
import {
    message,
} from 'antd';
import { fetchRoom } from "../../../../redux/actions";
import { withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import RoomDetailsView from './RoomDetailsView';
import ThreeSixtyViewer from '../../../../ThreeSixtyViewer';
import FileConstants from '../../../../FileConstants';
import SharingModal from '../SharingModal/SharingModal';
import * as Utilities from '../../Utilities';


function saveToZip (filename, urls) {
    let loader = message.loading('Preparing to download..',0)
    const zip = new JSZip()
    urls.forEach((url)=> {
        const blobPromise = fetch(url, {cache: 'no-store'}).then(r => {
            if (r.status === 200) return r.blob()
            return Promise.reject(new Error(r.statusText))
        })
        const name = url.split('/').slice(-1)[0] 
        zip.file(name, blobPromise)
    })
  
    zip.generateAsync({type:"blob"})
        .then(blob => {
            saveAs(blob, filename)
            setTimeout(loader);
        })
        .catch(e => console.log(e));
}


const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_CUSTOMER_EMAIL = FileConstants.MANAGED_CUSTOMER_EMAIL;

const RoomDetails = (props) =>  {

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [sharingModal, setSharingModal] = useState(false);
    const [successModal,setSuccessModal] = useState(false)
    const [selectedPermission, setSelectedPermission] = useState('embed');
    const [sharingLoader, setSharingLoader] = useState(false);
    const [embedLink, setEmbedLink] = useState(false);
    const [threeSixtyViews, setThreeSixtyViews] = useState({});
    const [rejectionDetails, setRejectionDetails] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [editForm, setEditForm] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [saveSuccessModal, setSaveSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [glbSize, setGlbSize] = useState(0);
    const [archiveSize, setArchiveSize] = useState(0);
    const [variationModal, setVariationModal] = useState(false);
    const [savedID, setSavedID] = useState(-1);
    const [spaceDetails, setSpaceDetails] = useState({});
    const [variationSuccessModal, setVariationSuccessModal] = useState(false);
    const [saveModal, setSaveModal] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [paymentRequestID,setPaymentRequestID] = useState(-1);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
    const [requestType, setRequestType] = useState('variation_room_model');
    const [storePage, setStorePage] = useState(0);
    const [compareView, setCompareView] = useState(false);
    const [spaceCategories, setSpaceCategories] = useState([]);
    const [sellVisible, setSellVisible] = useState(false);
    const [sellLoader, setSellLoader] = useState(false);
    const [price, setPrice] = useState(0);
    const [removeFromStore, setRemoveFromStore] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const [folderParentID, setFolderParentID] = useState(null);
    const [selectedSpace, setSelectedSpace] = useState({});
    const [approveLoader, setApproveLoader] = useState(false);
    const [rejectionLoader, setRejectionLoader] = useState(false);
    const [approvalSuccess, setApprovalSuccess] = useState(false);
    const [baseVariantPlatform, setBaseVariantPlatform] = useState('aws');
    const [baseVariantPlatformLoader, setbaseVariantPlatformLoader] = useState(true);
    const [baseImmidiateVariantPlatform, setBaseImmidiateVariantPlatform] = useState('aws');
    const [baseImmidiateVariantPlatformLoader, setbaseImmidiateVariantPlatformLoader] = useState(true);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [updatingAccessEmails, setUpdatingAccessEmails] = useState({});
    const [accessUpdateLoader, setAccessUpdateLoader] = useState(false);
    const [inviteLoader, setInviteLoader] = useState(false);
    const [publicLinks, setPublicLinks] = useState({});
    const [userAccess, setUserAccess] = useState(null);
    const [requestCategory, setRequestCategory] = useState('')
    const [customerRendersGenerated, setCustomerRendersGenerated] = useState([])
    
    const { match: { params } } = props;

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [isFullScreen]);

    useEffect(() => {
        axios.post(ENVIRONMENT.GET_ROOM_REJECTIONS, { room_id: params.id })
        .then(res => {
            console.log(res);
            setRejectionDetails(res.data);
        })
        let payment_redirected = new URLSearchParams(window.location.search).get("payment_redirected");
        let payment_failure = new URLSearchParams(window.location.search).get("payment_failure");
        let payment_request_id = new URLSearchParams(window.location.search).get("request_id");
        setRequestType('variation_room_model');
        if (payment_redirected) {
        //    submitVariation(0);
        }
        if (payment_failure) {
            // setCardSaveFailure(true);
            if (payment_request_id) {
                setPaymentRequestID(payment_request_id);
                setCardSaveDrawer(true);
            }
        }
        Utilities.getUserAccess('room', params.id, setUserAccess);
        setRequestType('variation_room_model');
        loadUserRequestStatus();
        setSpaceCategoriesData();
        setRoomPageType();
    }, []);

    const getCompanyUsersEmails = () => {
        let emails = []
        if(MANAGED_CUSTOMER_USERNAME){
          emails.push({
              'email': MANAGED_CUSTOMER_EMAIL,
              'username': MANAGED_CUSTOMER_USERNAME
          })
          setCompanyUsers(emails)
        }
        if(COMPANY_ID){
            let payload = {
                "required_fields": ["email", "username"],
                "filter_string": `(company_id__exact=${COMPANY_ID})`,
                "order_by": "email asc"
            }
            axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
            .then(res => {
                if(res && res.data){
                    console.log('Company User Emails', res.data);
                    emails = res.data.filter(item => item.username != CUSTOMER_USERNAME)
                    setCompanyUsers(emails)
                  }
            })
        }
      }

    const performStoreAction = () => {
        //remove item from store if 
        if (props.roomReducer.room.is_store_item == 1) {
            setRemoveFromStore(true);
        } else {
            setSellVisible(true);
            setSellLoader(false);
        }
    }

    const cancelSellModal = () => {
        setSellVisible(false);
        setSellLoader(false);
    }

    const onChangePrice = (e) => {
        console.log(e.target.value)
        setPrice(e.target.value);
    }

    const removeItemFromStore = () => {
        let payload = {
            is_store_item: 0,
            room_id: params.id
          }
          console.log(payload)
          setRemoveLoader(true);
          axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
          .then(res => {
            setStoreRequests();
          })
          .catch((error) => {
            setRemoveLoader(false);
            console.log(error);
            message.error('Error in removing from store.');
          });
    }

    const setStoreRequests = () => {
        let payload = {
            room_id: params.id
        }
        if (props.roomReducer.room.is_store_item == -2) {
            payload.is_store_item = -2;
        } else if (props.roomReducer.room.is_store_item == 1) {
            payload.is_store_item = 0;
        } else {
            payload.is_store_item = -2;
        }
     
        let price_payload = {
            entity_id: params.id,
            customer_username: localStorage.getItem("username"),
            entity_type: 'room',
            proposed_price: price
        }
        setRemoveLoader(true);
        setSellLoader(true);
        console.log(price_payload)
        axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
        .then(res => {
            if (props.roomReducer.room.is_store_item == -2) {
                price_payload.action = 'update';
            } else if (props.roomReducer.room.is_store_item == 1) {
                price_payload.action = 'remove';
            } else {
                price_payload.action = 'insert';
            }
    
            updateStore(price_payload);
        })
        .catch((error) => {
            setRemoveLoader(false);
            console.log(error);
            message.error('Error in removing from store.');
        }); 
    }

    const updateStore = (payload) => {
        axios.post(ENVIRONMENT.SET_STORE_PRICE, payload)
        .then( (response) => {
            setSellLoader(false);
            setRemoveLoader(false);
            setRemoveFromStore(false);
            if (props.roomReducer.room.is_store_item == -2) {
                message.info('Price updated successfully!');
            } else if (props.roomReducer.room.is_store_item == 1) {
                message.info('Item removed from store successfully!');
            } else {
                message.info('Price submitted successfully!');
            }
            window.location.href = '/rooms';
        })
        .catch((error) => {
            setSellLoader(false);
            console.log(error);
            message.error('Error.');
        });
    }

    const setSpaceCategoriesData = () => {
        let payload = {
          'request_type': 'room_model'
        }
        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
        .then(res => {
          let categories = []
          if (res.data) {
            res.data.map((request) => {
              if (!categories.includes(request.category)) {
                categories.push(request.category);
              }
            })
            setSpaceCategories(categories);
          }
        })
      }


    const loadUserRequestStatus = () => {
        let payload = {
          "username": localStorage.getItem('username'),
          "action": "check",
          'request_type': 'variation_room_model'
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
            });
      }


    const setRoomPageType = () => {
        //load store page detaills if link is for room-assets, otherwise load all details
        console.log(props.match)
        if (props && props.match) {
            if (props.match.path.includes("room-assets")) {
                setStorePage(1);
            }
            else if (props.match.path.includes("room")) {
                setStorePage(0);
            }
        }
    }
    
    useEffect(() => {
        props.getRoom({room_id: params.id});
        getCompanyUsersEmails()
    }, [params]);

    useEffect(() => {
        let room_id = params.id.toString();
        if (savedID != -1) {
            room_id = savedID.toString();
            console.log('Progress Previosuly Saved');
            axios.post(ENVIRONMENT.FETCH_ROOM, { room_id: room_id })
            .then(res => {
                console.log('Saved ',res.data);
                setSpaceDetails(res.data);
            });
        }
        
    },[savedID]);

    useEffect(() => {
        let room_id = params.id.toString();
        axios.post(ENVIRONMENT.FETCH_ROOM, { room_id: room_id })
        .then(res => {
            console.log('Saved ',res.data);
            setSelectedSpace(res.data);

            let room_id = null;
            if(res.data.immediate_parent_variant){
                room_id = res.data.immediate_parent_variant;
                axios.post(ENVIRONMENT.FETCH_ROOM, {room_id})
                .then(res => {
                    setBaseImmidiateVariantPlatform(res.data.platform);
                    setbaseImmidiateVariantPlatformLoader(false);
                })}
            if(res.data.variant_of){
                room_id = res.data.variant_of;    
                axios.post(ENVIRONMENT.FETCH_ROOM, {room_id})
                    .then(res => {
                        setBaseVariantPlatform(res.data.platform);
                        setbaseVariantPlatformLoader(false);
                    })
            }
        });
    },[]);

    useEffect(() => {
        if ((props.roomReducer.room.model_status == 4 || props.roomReducer.room.model_status == 5) && userAccess != 'restricted' ){
            if (props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length == 0) {
                if (threeSixtyViews['360Camera'] == null) {
                    setSelectedImage('360Camera');
                    let temp_arr = threeSixtyViews;
    
                    temp_arr['360Camera'] = new ThreeSixtyViewer('360Camera',`loading-container-360Camera`,ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/' + '360Camera.jpg',false);
                    setThreeSixtyViews(temp_arr);    
                }   
            } else if (props.roomReducer.room.three_sixties) {
                if (props.roomReducer.room.three_sixties[0]) {
                    let url = props.roomReducer.room.three_sixties[0].split('.')[0];
                    setSelectedImage(url);
                    if (threeSixtyViews[url] == null) {
                        setTimeout(() => {
                            let temp_arr = threeSixtyViews;
                            temp_arr[url] = new ThreeSixtyViewer(url,`loading-container-${url}`,ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/' + props.roomReducer.room.three_sixties[0],false);
                            setThreeSixtyViews(temp_arr);
                        }, 1000);
                    }
                }     
            }

            if (savedID == -1) {
                let payload = {
                    variant_of: params.id.toString(),
                    model_status:"1"
                }
                axios.post(ENVIRONMENT.GET_ROOM_VARIANTS ,payload)
                .then (res => {
                    console.log(res.data);
                    if (res.data && res.data[0]) {
                        setSavedID(res.data[0].id);
                    }
                }).catch(err => {
                    console.log(err,'An error occured in getting variants.');
                });
            }
        }

        // set pubic links dictionary
        let publicLinks = {
            'Default Space': `${ENVIRONMENT.PORTAL_LINK}360_public_viewer/space/${params.id}/360Camera.jpg`,
            'Space Model File': (ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.SPACE_LOW_POLY_URI + params.id + '.zip')
        }
        if(props.roomReducer.room.three_sixties)
        {
            props.roomReducer.room.three_sixties.map((three_sixty) => {
                publicLinks[three_sixty] = `${ENVIRONMENT.PORTAL_LINK}360_public_viewer/space/${params.id}/${three_sixty}` 
            })
        }

        let customerRenderURLs = [];
        if (props.roomReducer.room.test_renders != undefined) {
            Object.keys(props.roomReducer.room.test_renders).forEach((key) => {
                let ele = props.roomReducer.room.test_renders[key];
                if ((!ele.is_hidden) && ele.status == 'rendered' && ele.generated_by == 'Customer') {
                    let ele = props.roomReducer.room.test_renders[key];
                    let file_url = ENVIRONMENT.getBaseURL(ele.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.roomReducer.room.customer_dummy_scene_id + '/' + ele.filename;
                    publicLinks[ele.filename] = file_url
                    customerRenderURLs.push(file_url)
                }
            });
        }

        setCustomerRendersGenerated(customerRenderURLs);
        

        setPublicLinks(publicLinks)
        
    },[props.roomReducer]);

    const submitVariation = (status) => {

        setVariationModal(false);
        if (status == 0) {
            setVariationSuccessModal(true);
        } else {
            setSaveModal(true);
        }

    }

    const setFullScreen = (value) => {
        setIsFullScreen(value);
        if (value) {
            if (threeSixtyViews[selectedImage]) {
                threeSixtyViews[selectedImage].toggleFull(true);
            //    message.info('Press ESC to exit full screen');
            }
        }
        else {
            if (threeSixtyViews[selectedImage]) {
                threeSixtyViews[selectedImage].toggleFull(false);
            }
        }
    }

    const openVariationModal = () => {
        setVariationModal(true);
    }

    const cancelVariationModal = () => {
        setVariationModal(false);
    }

    const changeSelectedImage = (name) => {
        props.roomReducer.room.three_sixties.map((x, index) => {
            if ((threeSixtyViews[x.split('.')[0]] != null) && (x != name)) {
                threeSixtyViews[x.split('.')[0]].changeDisplay("none");
            }
        });
        setSelectedImage(name.split('.')[0]);
           
        if (threeSixtyViews[name.split('.')[0]] == null) {

            setTimeout(() => {
                let temp_arr = threeSixtyViews;
                temp_arr[name.split('.')[0]] = new ThreeSixtyViewer(name.split('.')[0],`loading-container-${name.split('.')[0]}`,ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/' + name,false);
                setThreeSixtyViews(temp_arr);
            }, 1000);
        }
        else if (threeSixtyViews[name.split('.')[0]] != null) {
            threeSixtyViews[name.split('.')[0]].changeDisplay("block");
        }
        
    }

    const getGlbSize = () => {
        let link = (props.roomReducer.room.download_links || []).find((ele) => ele.display_name === 'GLB');
        let size = 0;
        if(link)
        {
            axios.head(link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']))).then(res => {
                size = parseInt(res.headers['content-length']);
                setGlbSize(size);
            });
        }
    }

    const getArchiveSize = () => {
        let link = (props.roomReducer.room.download_links || []).find((ele) => ele.display_name === 'MAX');
        let size = 0;
        
        if(link)
        {
            axios.head(link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']))).then(res => {
                size = parseInt(res.headers['content-length']);
                setArchiveSize(size);
            });
        }
    }

    const convertByteSize = (size_in_mbs) => {
        let result = size_in_mbs.toFixed(2) + " MBs";
        if(size_in_mbs/1024 > 1024) // means more then 1 GB
        {
            size_in_mbs = (size_in_mbs/(1024)).toFixed(2); // size in GBs
            result =  size_in_mbs+" GBs";
        }
        return result;
    }

    const onReject = (values) => {
        let assigned_artist = props.roomReducer.room.assigned_artist;
        let payload = {
            "room_id": params.id,
            "rejection_details": values.msg,
            "rejection_against_artist": assigned_artist,
            "reference_files": values.reference_files,
            "username": localStorage.getItem("username")
        }
        axios.post(ENVIRONMENT.ENTITY_MODEL_REJECT, payload)
            .then(res => {
                let update_payload = {
                    room_id: params.id,
                    model_status: "-4",
                    username: localStorage.getItem("username")
                }
                axios.post(ENVIRONMENT.UPDATE_ROOM, update_payload)
                    .then(res => {
                        window.location.href = "/home";
                    })
                    .catch((error) => {
                        message.error('Error in rejecting room.');
                    });

            }).catch((error) => {
                message.error('Error in rejection room APi.');
            });
    }

    const handleEmbedCancel = e => {
        setEmbedLink(false);
    };


    const onApprove = () => {
        setApproveLoader(true);
        let payload = {
            room_id: params.id,
            model_status: "5",
            username: localStorage.getItem("username")
        }
        axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
        .then(res => {
            if (res) {
                if (FileConstants.isMSProvider && selectedSpace.requested_for != undefined && selectedSpace.requested_for != '') {
                        const transfer_payload = {
                            entity_type : 'room',
                            room_id: params.id,
                            requested_for: selectedSpace.requested_for
                        }
                        axios.post(ENVIRONMENT.TRANSFER_OWNERSHIP, transfer_payload)
                        .then(result => {
                            setApprovalSuccess(true);
                        });
                } else {
                    setApprovalSuccess(true);
                }
            }
        });
    }

    const handleShare = email => {
        setAccessUpdateLoader(true);
        // Keep track of email address whose access is being updated
        let emails = updatingAccessEmails;
        emails[email] = true
        setUpdatingAccessEmails(emails);
        forceUpdate();
        let body = {};
        body.room_id = params.id;
        body.username = localStorage.getItem("username");
        let lowercase_emails = [email.toLowerCase()]
        body.emails = lowercase_emails;
        let permissions = {}
        permissions[selectedPermission] = 1;
        body.permissions = permissions;
        console.log(JSON.stringify(body));
        axios.post(ENVIRONMENT.SHARE_ROOM, { body })
            .then(res => {
                setAccessUpdateLoader(false);
                let updating_emails=updatingAccessEmails
                updating_emails[email] = false
                setUpdatingAccessEmails(updating_emails);
                console.log(updatingAccessEmails)
                message.success('Space Shared Successfully!');
                setInviteLoader(false);
                forceUpdate();
            });
    };

    const downloadSceneImagesArchive = () => {
        let downloadData = [];
        if (props.roomReducer.room.three_sixties != undefined) {
          if(props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length == 0) {
              downloadData.push(ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/360Camera.jpg');
            }
            else {
                if (props.roomReducer.room.three_sixties) {
                    props.roomReducer.room.three_sixties.map((url) => {
                        downloadData.push(ENVIRONMENT.getBaseURL(props.roomReducer.room['platform']) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/' + url);
                    });
                }
            }
        }
        saveToZip ('Space 360s.zip', downloadData);
    }

    const downloaCustomerGeneratedImagesArchive = () => {
        saveToZip ('Generated Images.zip', customerRendersGenerated);
    }

    const openEditForm = () => {
        setEditForm(true);
    }

    const closeEditForm = () => {
        setEditForm(false);
    }

    const saveSpaceData = (values) => {
        console.log(values);
        
        if ((values.room_name == props.roomReducer.room.room_name) && (values.reference_urls == props.roomReducer.room.reference_urls) && (values.brand_id == props.roomReducer.room.brand_id) && (values.tags == props.roomReducer.room.tags) && (props.roomReducer.room.category == values.category) &&  (props.roomReducer.room.style == values.style)) {
            setErrorMessage("You have not made any change to your space model's data. Kindly make changes to the data and 'Save'.")
        } else {
            
            if (values.room_name && values.category) {
                setErrorMessage('');
                setButtonLoader(true);
                let payload = {
                    room_id: params.id,
                    room_name: values.room_name == "" ? props.roomReducer.room.room_name : values.room_name,
                    reference_urls: values.reference_urls,
                    brand_id: values.brand_id,
                    tags: values.tags,
                    style: values.style,
                    category: values.category == "" ? props.roomReducer.room.category : values.category,
                    username: localStorage.getItem("username")
                }
                axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
                .then(res => {
                    setButtonLoader(false);
                    setEditForm(false);
                    setSaveSuccessModal(true);
                })
                .catch((error) => {
                    message.error('Error in approving product.');
                });
            } else {
                setErrorMessage('You cannot leave mandatory fields empty.');
            }
            
        }
    }

    const onMouseOut = (evt) => {
        let element = document.getElementById('edit-icon');
        if (element) {
            element.setAttribute("src","/img/edit-w-hover.png");
        }
    }

    const onMouseOver = (evt) => {
        let element = document.getElementById('edit-icon')
        if (element) {
            element.setAttribute("src","/img/edit-icon.png");
        }
    }

    // get folder parent id from parameters and set it
    const getURLParamsAndSetVariationParentID = () => {
        const url = new URL(window.location);
        let id = new URLSearchParams(url.search).get("folder_id");
        setFolderParentID(id);
    };

    useEffect(() => {
        getURLParamsAndSetVariationParentID();
    }, [folderParentID]);


    return (
        <>
      <RoomDetailsView
        folderParentID={folderParentID}
        roomDetails={props.roomReducer.room}
        roomReducer={props.roomReducer}
        compareView={compareView}
        setCompareView={setCompareView}
        downloadSceneImagesArchive={downloadSceneImagesArchive}
        setEmbedLink={setEmbedLink}
        setSharingModal={setSharingModal}
        setFullScreen={setFullScreen}
        isFullScreen={isFullScreen}
        params={params}
        onApprove={onApprove}
        onReject={onReject}
        embedLink={embedLink}
        handleEmbedCancel={handleEmbedCancel}
        sharingModal={sharingModal}
        sharingLoader={sharingLoader}
        handleShare={handleShare}
        successModal={successModal}
        setSelectedPermission={setSelectedPermission}
        setSuccessModal={setSuccessModal}
        selectedImage={selectedImage}
        changeSelectedImage={changeSelectedImage}
        openEditForm={openEditForm}
        closeEditForm={closeEditForm}
        editForm={editForm}
        saveSpaceData={saveSpaceData}
        buttonLoader={buttonLoader}
        saveSuccessModal={saveSuccessModal}
        errorMessage={errorMessage}
        onMouseOut={onMouseOut}
        getArchiveSize={getArchiveSize}
        getGlbSize={getGlbSize}
        onMouseOver={onMouseOver}
        rejectionDetails={rejectionDetails}
        convertByteSize={convertByteSize}
        variationModal={variationModal}
        openVariationModal={openVariationModal}
        cancelVariationModal={cancelVariationModal}
        savedID={savedID}
        spaceDetails={spaceDetails}
        submitVariation={submitVariation}
        variationSuccessModal={variationSuccessModal}
        saveModal={saveModal}
        setVariationSuccessModal={setVariationSuccessModal}
        setSaveModal={setSaveModal}
        setSaveSuccessModal={setSaveSuccessModal}
        paymentRequestID={paymentRequestID}
        requestType={requestType}
        cardStatus={cardStatus}
        requestStatus={requestStatus}
        cardSaveFailure={cardSaveFailure}
        setCardSaveFailure={setCardSaveFailure}
        cardSaveDrawer={cardSaveDrawer}
        setCardSaveDrawer={setCardSaveDrawer}
        storePage={storePage}
        spaceCategories={spaceCategories}
        performStoreAction={performStoreAction}
        sellVisible={sellVisible}
        price={price}
        onChangePrice={onChangePrice}
        cancelSellModal={cancelSellModal}
        sellLoader={sellLoader}
        setStoreRequests={setStoreRequests}
        removeFromStore={removeFromStore}
        setRemoveFromStore={setRemoveFromStore}
        removeLoader={removeLoader}
        removeItemFromStore={removeItemFromStore}
        selectedSpace={selectedSpace}
        approveLoader={approveLoader}
        rejectionLoader={rejectionLoader}
        setRejectionLoader={setRejectionLoader}
        approvalSuccess={approvalSuccess}
        setApprovalSuccess={setApprovalSuccess}
        baseVariantPlatform={baseVariantPlatform}
        baseVariantPlatformLoader={baseVariantPlatformLoader}
        baseImmidiateVariantPlatform={baseImmidiateVariantPlatform}
        baseImmidiateVariantPlatformLoader={baseImmidiateVariantPlatformLoader}
        companyUsers={companyUsers}
        updatingAccessEmails={updatingAccessEmails}
        inviteLoader={inviteLoader}
        accessUpdateLoader={accessUpdateLoader}
        userAccess={userAccess}
        setRequestCategory={setRequestCategory}
        customerRendersGenerated={customerRendersGenerated}
        downloaCustomerGeneratedImagesArchive={downloaCustomerGeneratedImagesArchive}
      />
      
      <SharingModal 
            entityId={params.id}
            entityType={'room'}
            setSharingModal={setSharingModal}
            modalVisible={sharingModal}
            publicLinks={publicLinks}
            loggedInUserAccess={userAccess}
        />
      </>
      
    );
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getRoom: (payload) => {
        dispatch(fetchRoom(payload))
    },
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(RoomDetails)
)
