import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Table, Divider } from 'antd';
import ENVIRONMENT from '../../../../environments'
import axios from 'axios';

const columns = [
  {
    title: 'Room Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Action',
    key: 'action',

    render: (text, record) => (
      <span>
        <a href={'../room/' + record.id}>Edit</a>
        <Divider type="vertical" />
        <a href={'../design_room'}>Design</a>
        <Divider type="vertical" />
        <a className="ant-dropdown-link">
          More actions <DownOutlined />
        </a>
      </span>
    ),
  },
];

const expandedRowRender = record => <p>{record.description}</p>;
const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class ItemsTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    expandedRowRender,
    title: undefined,
    showHeader,
    footer: false,
    rowSelection: {},
    scroll: undefined,
    hasData: true,
    dataSource: null
  };

  design_room = (id) => {
    alert(id)
  }

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    axios.post(ENVIRONMENT.GET_ROOM_RECORDS)
      .then(res => {
        console.log(res);
        console.log(res.data);
        var data = [];
        for (let i = 1; i <= (res.data).length; i++) {
          console.log(i);
          data.push({
            key: i,
            name: res.data[i - 1]['name'],
            id: res.data[i - 1]['id'],
          });
        }

        this.setState({
          dataSource: data
        })
      })
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = enable => {
    this.setState({ expandedRowRender: enable ? expandedRowRender : undefined });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = enable => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource &&
          <Table {...this.state} columns={columns} dataSource={dataSource} />
        }
      </div>
    );
  }
}

export default ItemsTable;
