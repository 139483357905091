import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { Row, Col, Button, Upload, Form, Input, Card} from 'antd';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';
import * as Utilities from '../../Utilities';
import AWS from 'aws-sdk';
import * as Constants from '../Constants';
import { ArrowRightOutlined, LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';
import DottedLoader from '../../DottedLoader';
import { Link } from 'react-router-dom';

const COMPANY_ID = FileConstants.COMPANY_ID;
const CompanyProfile = (props) => {
   
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [loading, setLoading] = useState(false);
    const [companyLogo, setCompanyLogo] = useState(null);
    const [uploadedFilelist, setUploadedFilelist] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [webstieURL, setWebsiteURL] = useState('');
    const [logoWidth, setLogoWidth] = useState(0);
    const [successMessage, setSuccessMessage] = useState(false);
    const [existingCompanyData, setExistingCompanyData] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [loader, setLoader] = useState(true);
    const [form] = Form.useForm();
    const [imageLoader, setImageLoader] = useState(false);

    useEffect(() => {
      getCompany();
    }, []);

    const getCompany = () => {
        let company_id = COMPANY_ID;
        if (company_id) {            
            axios.post(ENVIRONMENT.COMPANY_GET, { company_id })
            .then(res => {
                console.log(res)
                if (res.data) {
                    if (!Array.isArray(res.data)) {
                        setCompanyLogo(res.data.logo);
                        let arr = []
                        arr.push(res.data.logo)
                        setUploadedFilelist(arr);
                    }
                    setExistingCompanyData(res.data);
                    setWebsiteURL(res.data.website);
                    
                    let aspect_ratio = 0;
                    const img = new Image();
                    img.onload = function() {
                        aspect_ratio = 200 / this.height;
        
                        let new_width = aspect_ratio * this.height;
                        new_width = new_width/10;
                        
                        setLogoWidth(new_width);
                    }
                    img.src = ENVIRONMENT.BASE_URL + res.data.logo.uid + '/' + res.data.logo.name;
                    setLoader(false);

                } else {
                    setLoader(false);
                }
            })
        }
    }

    useEffect(() => {
        if (form && uploadedFilelist && uploadedFilelist.length > 0) {
            form.setFieldsValue({
                website: existingCompanyData.website,
                logo: existingCompanyData.logo && existingCompanyData.logo.length == 0 ? [] : uploadedFilelist
            });
        }
    },[existingCompanyData,form,uploadedFilelist])

    const handleCompanyLogoChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);

        setCompanyLogo(fileList[0]);
        setUploadedFilelist(fileList);
       
        if (info.file.status === "done") {
            let aspect_ratio = 0;
            const img = new Image();
            img.onload = function() {
                aspect_ratio = 200 / this.height;
                let new_width = aspect_ratio * this.height;
                new_width = new_width/10;
                setLogoWidth(new_width);
                setImageLoader(false);
            }
            img.src = ENVIRONMENT.BASE_URL + fileList[0].uid + '/' + fileList[0].name;
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }          
            setUploading(false);
        } else {
            setImageLoader(true);
        }
    }

    const onChangeWebsiteURL = (e) => {
        let url = e.target.value;
        if (!url.includes('https://') && !url.includes('http://')){
            console.log('doesnt includes')
            url = 'https://'.concat(url);
            console.log(url)
        }
        setWebsiteURL(url);
    }

    const handleSubmit = (values) => {
        console.log(values);
        
        let payload = values;
        payload.logo = companyLogo;
        payload.featured_brand = true;
        payload.company_id = COMPANY_ID;
        console.log(payload)
        console.log(JSON.stringify(payload))
        let uploadStatus = Utilities.validateFileUploads(uploadedFilelist);
        if(uploadedFilelist && uploadedFilelist.length == 0 && uploadStatus == 'not_started') {
            setUploadError('Please upload your company logo!');
        }
        else if (uploadStatus == 'uploading') {
            setUploadError('Upload is in progress, please wait till file upload is completed.')
        }
        else if (uploadStatus == 'error') {
            setUploadError('Error occured in uploading file, please re-upload your file.')
        }
        else if(uploadedFilelist && uploadedFilelist.length > 0 || uploadStatus == 'done') {
            setUploadError('');
            setLoading(true);
            axios.post(ENVIRONMENT.COMPANY_UPDATE, payload)
            .then(res => {
                setLoading(false);
                setSuccessMessage(true);
                console.log(res);
            });
        }
        
    }
    
    return (
       <div style={{padding: '0px 10px'}}>
        {loader ? <DottedLoader/> : <>
        <h3 className='manrope f-16 black-14'>Personalize Company Banner</h3>
        <p className='manrope f-14 black-55'>You can personalize your company banner by adding the company logo and a link to your company’s website.  </p>
        <div className='justify-space-between'>
            <Form initialValues={{
                website: existingCompanyData && existingCompanyData.website,
                logo: uploadedFilelist && uploadedFilelist.length > 0 ? uploadedFilelist : [],
            }} ref={form} name='company_profile' layout='vertical' style={{width: '100%'}} onFinish={handleSubmit}>
                <Row className='justify-space-between' style={{alignItems: 'flex-start'}}>
                    <Col span={12}>
                        <Form.Item label={<span className='manrope f-14 black-14'>Enter Website URL</span>}  name="website" colon={false} rules={[{ required: true, message: "Please enter website url." }]}>
                            <Input defaultValue={webstieURL} placeholder={'Enter a URL'} onChange={onChangeWebsiteURL}
                            className="manrope f-12 black-55 library-search"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{paddingLeft: 20}}>
                        <Form.Item 
                        className="share-modal-input" 
                        style={{display:"inline-block",width: '100%',margin: "0 !important"}}
                        label={<span className='manrope f-14 black-14'>Upload Company Logo</span>} 
                        name="logo" rules={[{ required: true, message: "Please upload your company's logo." }]}>
                            <Upload defaultFileList={uploadedFilelist} 
                            accept=".png,.jpg,.jpeg"
                            onRemove={file => {
                                setCompanyLogo(null);
                                setUploadedFilelist([]);
                                return true;
                            }}
                            fileList={uploadedFilelist}
                            onChange = {handleCompanyLogoChange}
                            openFileDialogOnClick={!(Utilities.validateFileUploads(uploadedFilelist) == 'error')}
                            {...Constants.upload_props} 
                            multiple={false}
                            listType="text" 
                            className="material-upload-box scroll-upload"
                            >
                           
                            <Button className="material-photos-btn flag"><span className="material-photos-btn-txt">Attach your company's logo file <PaperClipOutlined/></span></Button>
                            {(Utilities.validateFileUploads(uploadedFilelist) == 'error') && (<Button
                                onClick={() => Constants.triggerFailedFilesUpload('logo', uploadedFilelist, setUploadedFilelist)}
                                disabled={uploadedFilelist.length === 0}
                                loading={uploading}
                                className="retry-btn flag" ghost
                                >
                                {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                            </Button>)}
                            </Upload>

                        </Form.Item>
                        {(uploadError != '')?
                            <div className="manrope f-12 red" style={{ textAlign: "left",marginTop: '-4px'}}>{uploadError}</div>
                        : ""}                        
                    </Col>
                </Row>
                <Button id="submit_company_profile" type="primary" htmlType="submit" style={{"display": "none"}}>
                    Submit
                </Button>
            </Form>
        </div>

        <Row style={{marginTop: 10}}>
            <Col span={24}>
                <Card className='banner-preview-card'>
                    <div className='justify-in-start' style={{marginBottom: 12}}>
                        <p className='manrope f-16 black-14' style={{marginBottom: 0}}>Banner Preview</p>&nbsp;{imageLoader && <LoadingOutlined/>}
                    </div>
                    <Col span={24} style={{marginBottom: 20}}>
                        <div className='banner-container'>
                            <img className='banner-style' src='/img/Banner.png'/>
                            <div className='logo-website-container'>
                                {companyLogo && (Utilities.validateFileUploads(uploadedFilelist) == 'done') &&
                                <div className='logo-banner'>
                                    <img className='logo-placement' style={{width: logoWidth + '%'}} src={ENVIRONMENT.BASE_URL + companyLogo.uid + '/' + companyLogo.name}/>
                                </div>}
                                {webstieURL &&
                                <div className='website-container'>
                                    <Link className='manrope f-16'  to={{ pathname: webstieURL }} target="_blank">Visit Website <ArrowRightOutlined/></Link>
                                </div>}
                            </div>
                        </div>
                    </Col>
                    <div className='justify-in-end'>
                        <Button className='modal-okay square font-16' disabled={loading} onClick={() => document.getElementById("submit_company_profile").click()}>{existingCompanyData.featured_brand ? 'Update' : 'Publish Brand Page'} {loading && <LoadingOutlined/>}</Button>
                    </div>
                </Card>
            </Col>
        </Row>
        <SuccessModal
        visible={successMessage}
        heading={"Profile Settings Saved Successfully!"}
        text={<span>Your profile settings have successfully been saved. <br/><br/>Next step: You can sell your Brand's products or spaces on the ALL3D Store using the Sell on Store option for that item.</span>}
        footer={[
           <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14" htmlType="submit" onClick={() => {
                   window.location.href = '/home' }}>
                   Go To Dashboard
               </Button>
               <Button className="modal-okay square font-14" htmlType="submit" onClick={() => {
                   window.location.href = 'https://all3d.ai/store' }}>
                   Visit Store
               </Button>
           </div>]}
        />
        </>}
       </div>
      );

}

export default CompanyProfile;
  