import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { Route } from 'react-router-dom';

import { CognitoState } from 'react-cognito';

function PrivateRouteSuperArtist({
  state,
  user,
  component: Component,
  ...rest
}) {
  function verifyUser() {
    try {
      if (state == CognitoState.LOGGING_IN || state == CognitoState.LOGGED_IN) {
        if ('signInUserSession' in user) {
          if ('idToken' in user['signInUserSession']) {
            if (
              'cognito:groups' in
              user['signInUserSession']['idToken']['payload']
            ) {
              let groups =
                user['signInUserSession']['idToken']['payload'][
                    'cognito:groups'
                ];
              let token = localStorage.getItem('modified_super_admin_token')
              if(token){
                const decoded = jwtDecode(token)
                if(decoded['cognito:groups']){
                  groups = decoded['cognito:groups']
                }
              }
              if (groups.indexOf('SuperArtist') != -1) {
                const jwtToken = localStorage.getItem('all3d_jwt_token');
                const decoded = jwtDecode(jwtToken);

                if (!(decoded.exp < Date.now() / 1000)) {
                  return 1;
                } else {
                  console.log('jwt token has been expired.');
                }
              } else {
                return 0;
              }
            }
          }
        }
      }
    } catch (err) {}
    console.log('user not verified');
    return -1;
  }

  const userVerified = verifyUser();
  if (userVerified == 1) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    if (userVerified == -1) {
      window.location.href = '/?redirectURL=' + window.location.pathname;
    } else {
      window.location.href = '/';
    }
  }
}

PrivateRouteSuperArtist.propTypes = {
  user: PropTypes.object,
  component: PropTypes.object,
  attributes: PropTypes.object,
  state: PropTypes.string,
};

const mapStateToProps = (state) => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});

const SuperArtistPrivateRoute = connect(
    mapStateToProps,
    null,
)(PrivateRouteSuperArtist);

export default SuperArtistPrivateRoute;
