import React, { useState } from "react";
import _ from "lodash";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import { Button } from "antd";
import SpaceVariantAction from "./CreateVariantAction";
import { LoadingOutlined } from "@ant-design/icons";

const companyId = localStorage.getItem("company_id");
const SpaceActions = ({ roomDetails,
  params,
  savedID,
  spaceDetails,
  userProjects, }) => {
  const [generateImageLoading , setGenerateImageLoading] = useState(false);
  const [dummySceneSetup, setDummySceneSetup] = useState(false);
  const [dummySceneError, setDummySceneError] = useState(false);

  const handleCustomerDummyScene = () => {
    if (roomDetails.customer_dummy_scene_id == "") {
      setGenerateImageLoading(true);
      setDummySceneSetup(true);
      createCollection(
        "Dummy-collection-" + roomDetails.room_id,
        roomDetails.room_id
      );
    } else {
      setGenerateImageLoading(true);
      window.location.href =
        "/scene_builder/" +
        roomDetails.customer_dummy_scene_id +
        "?customer_dummy_scene=true";
    }
  };

  const createCollection = (collectionName, roomId) => {
    const payload = {
      collection_name: collectionName,
      username: localStorage.getItem("username"),
    };

    if (companyId != undefined) {
      payload.company_id = companyId;
    }

    axios
      .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
      .then((res) => {
        addRoomToCollection(res.data.collection_id, roomId);
      })
      .catch(() => {
        setDummySceneError(true);
      });
  };

  const addRoomToCollection = (collectionId, roomId) => {
    const payload = {
      collection_id: collectionId,
      room_id: roomId,
      username: localStorage.getItem("username"),
    };
    axios
      .post(ENVIRONMENT.UPDATE_COLLECTION, payload)
      .then((res) => {
        createScene(collectionId, roomId);
      })
      .catch(() => {
        console.log("Room could not be added to collection ", collectionId);
        setDummySceneError(true);
      });
  };

  const createScene = (collectionId, roomId) => {
    {
      const payload = {
        scene_name: "Dummy-scene-" + roomId,
        collection_id: collectionId,
        room_id: roomId,
        is_hidden: true,
        username: localStorage.getItem("username"),
      };

      if (companyId != undefined) {
        payload.company_id = companyId;
      }

      axios
        .post(ENVIRONMENT.UPDATE_SCENE, payload)
        .then((res) => {
          const sceneId = res.data["scene_id"];

          let update_payload = {
            room_id: roomId,
            customer_dummy_scene_id: sceneId,
          };
          axios
            .post(ENVIRONMENT.UPDATE_ROOM, update_payload)
            .then((response) => {
              console.log(response);
              setDummySceneSetup(false);
              setGenerateImageLoading(false);
              window.location.href =
                "/scene_builder/" + sceneId + "?customer_dummy_scene=true";
            });
        })
        .catch(() => {
          setDummySceneError(true);
        });
    }
  };

  return (
    <>
      <Button
        className="product-btn dark-blue f-16 br-4 mr-16"
        onClick={handleCustomerDummyScene}
        style={{height: "42px"}}
        disabled={generateImageLoading}
      >
        Generate Image {generateImageLoading? <LoadingOutlined/>:<></>}
      </Button>
      <SpaceVariantAction roomDetails={roomDetails}
        params={params}
        savedID={savedID}
        spaceDetails={spaceDetails}
        userProjects={userProjects} />
    </>
  );
};

export default SpaceActions;
