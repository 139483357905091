import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import _ from "lodash";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ENVIRONMENT from "../../../../environments";
import { useQuery } from "react-query";
import { Row, Col } from "antd";
import { variant_grid_break_points } from "./SpacePageConstants";
import SpaceVariantCard from "./SpaceVariantCard.jsx";

const CUSTOMER_USERNAME = localStorage.getItem("username");
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const IS_MS_PROVIDER =
  localStorage.getItem("is_msprovider") === "true" ? true : false;

const SameBrandOtherSpaces = ({
  companyData,
  companyDomain,
  id,
  company_id,
  is_store_item,
}) => {
  const [carousel, setCarousel] = useState(null);

  const getRoomPayload = (
    current_username,
    current_company_id,
    shared_entities,
    is_customer_data = false,
    store_items = true
  ) => {
    let payload = {
      required_fields: [
        "room_id",
        "room_name",
        "brand_id",
        "category",
        "dimensions",
        "customer_username",
        "model_status",
        "thumbnail",
        "last_modified_stamp",
        "variant_of",
        "is_store_item",
        "company_id",
        "style",
        "platform",
      ],
    };
    payload["order_by"] = "last_modified_stamp desc";
    let filter_string = "";
    let customer_username =
      "customer_username__exact='" + current_username + "'";
    let company_id = "";
    let model_status = "model_status__in=[5]";
    let is_hidden = "is_hidden__not=true";
    if (store_items) {
      company_id = "company_id__exact=" + current_company_id;
      filter_string = "(" + company_id + ")&&(" + is_hidden + ")";
      filter_string =
        filter_string +
        "&&(" +
        model_status +
        ")&&(" +
        company_id +
        ")";
      payload["pagination_filters"] = {
        limit: 8,
        offset: 0,
      };
    } else {
      if (
        shared_entities != undefined &&
        current_company_id &&
        shared_entities?.split("_").includes("room")
      ) {
        company_id =
          "company_id__exact=" +
          current_company_id +
          "||additional_company_ids__contains='" +
          current_company_id +
          "'";
        filter_string =
          "(" +
          company_id +
          "||" +
          customer_username +
          ")&&(" +
          is_hidden +
          ")";
      } else {
        if (IS_MS_PROVIDER && !is_customer_data) {
          company_id = "company_id__isnull=true";
          let requested_for = "requested_for__isnull=true";
          filter_string =
            "(" +
            company_id +
            "&&" +
            customer_username +
            ")&&(" +
            is_hidden +
            ")&&(" +
            requested_for +
            ")";
        } else {
          filter_string = "(" + customer_username + "&&" + is_hidden + ")";
        }
      }
    }
    filter_string = filter_string + "&&(" + model_status + ")";
    filter_string = filter_string;
    payload["filter_string"] = filter_string;
    payload["pagination_filters"] = {
      limit: 8,
      offset: 0,
    };
    return payload;
  };

  const roomRequestPayload = useMemo(
    () =>
      getRoomPayload(
        CUSTOMER_USERNAME,
        company_id,
        SHARED_ENTITIES,
        false,
        true
      ),
    [CUSTOMER_USERNAME, COMPANY_ID, SHARED_ENTITIES]
  );

  const brandOtherSpacesFetcher = useCallback(
    (roomRequestPayload) => {
      return axios
        .post(ENVIRONMENT.LIST_ROOMS, roomRequestPayload)
        .then((res) => {
          return res?.data;
        });
    },
    [roomRequestPayload]
  );

  const {
    data: sameBrandOtherSpaces,
    error: sameBrandOtherSpacesError,
    isLoading: sameBrandOtherSpacesLoading,
  } = useQuery(
    [`${ENVIRONMENT.FETCH_ROOM}/related_rooms`, roomRequestPayload],
    () => brandOtherSpacesFetcher(roomRequestPayload),
    {
      enabled: !!roomRequestPayload,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );


  const redirectToStore = () => {
    window.location.href = `/store/${companyData.company_domain}?item_type=spaces`;
  };

  return (
    <>
      {sameBrandOtherSpaces && sameBrandOtherSpaces.length > 0 && (
        <Row className="mb-40">
          <Col span={24}>
            <div className="display-flex j-s a-c w-100 mb-30">
              <h3 className="mb-0 manrope f-24 black-26">
                More from {companyData?.company_display_name}
              </h3>
            </div>

            <Carousel
              ref={(el) => {
                setCarousel(el);
              }}
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={variant_grid_break_points}
              ssr={true} // means to render carousel on server-side.
              infinite={false}
              autoPlay={false}
              arrows={true}
              autoPlaySpeed={8000}
              keyBoardControl={false}
              containerClass="carousel-container-store variant-grid"
            >
              {sameBrandOtherSpaces.map((space, index) => (
                <SpaceVariantCard key={index} spaceVariant={space} full />
              ))}
              <Col span={24}>
                <div
                  className="see-more-button"
                  onClick={redirectToStore}
                  style={{ height: "380px" }}
                >
                  <div className="see-more-txt manrope f-14 blue justify-in-center">
                    See More Items
                  </div>
                </div>
              </Col>
            </Carousel>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SameBrandOtherSpaces;
