import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Row, Col, Typography } from 'antd';

const { Paragraph } = Typography;

const PhoneLinkContainer = (props) => {
    return (<div className="phone-div">
        <img alt={props.phone_type} src={props.phone_icon} />
        <div className="name-container">
            <div className="manrope f-16 black-26 lh-16">AR for {props.phone_type}</div>
            <Paragraph className="manrope f-14 blue lh-14" copyable={{ text: props.getUrl }}>
                Copy link
            </Paragraph>
        </div>
    </div>)
}

const ARView = () => {

    const { getUSDZUrl, getAndroidUrl } = useContext(CustomerProductContext);

    return (<Row>
        <Col span={24}>
            <p className='manrope f-16 lh-16 black-26 mb-0'>
                Open links on your device to view this product in your space.
            </p>
            <div className="phone-container">
                {getUSDZUrl() != '' &&
                    <PhoneLinkContainer
                        phone_type={"iPhone"}
                        phone_icon={"/img/iphone.png"}
                        getUrl={getUSDZUrl()}
                    />}
                {getAndroidUrl() != '' &&
                    <PhoneLinkContainer
                        phone_type={"Android"}
                        phone_icon={"/img/android.png"}
                        getUrl={getAndroidUrl()}
                    />}
            </div>
        </Col>
    </Row>);
}

export default ARView;