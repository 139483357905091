import React from 'react';
import { Select, Col,  Modal, Tooltip, Button, Form, Row, TreeSelect, Radio, Input } from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import * as Utilities from "../../Utilities";
import FileConstants from '../../../../FileConstants';

const { Option } = Select;

const proficiency_dropdown = <Select placeholder='Please select artist proficiency' className="manrope f-12 black-55 tree-select-material" style={{marginBottom: 15}}>
    <Option value={'Good'}>Good</Option>
    <Option value={'Average'}>Average</Option>
    <Option value={'Bad'}>Bad</Option>
</Select>

class EditSkillMatrixModal extends React.Component {
  formRef = React.createRef();

  state = {
    buttonLoader: false,
    categories_display: false,
    tree_formatted_catagories: [],
    artist_selected_categories: [],
    billing_type: this.props.billing_type,
    billing_rate: this.props.billing_rate
  };

  setSelectedAndFormattedCategories = () => {
    let tree_formatted_catagories = Utilities.getTreeFormattedCategories(
      this.props.all_categories
    );
    let artist_selected_categories = [];
    for (let item of this.props.proficient_categories) {
      if (item["parent_id"]) {
        // only select child items, if all children are selected the parent will be selected automatically
        artist_selected_categories.push(item["name"]);
      }
    }
    this.setState({
      artist_selected_categories: artist_selected_categories,
      tree_formatted_catagories: tree_formatted_catagories,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps != this.props &&
      this.props.proficient_categories &&
      this.state.tree_formatted_catagories.length == 0
    ) {
      this.setSelectedAndFormattedCategories();
    }
  };
  componentDidMount = () => {
    this.setSelectedAndFormattedCategories();
  };
  
  getProficientCategoriesIDs = (update_payload) => {
    if (
      update_payload["proficient_categories"] &&
      update_payload["proficient_categories"].length > 0
    ) {
      let proficient_categories_ids = [];
      for (let artist_category of update_payload["proficient_categories"]) {
        for (let category of this.props.all_categories) {
          if (artist_category == category["name"]) {
            proficient_categories_ids.push(category["category_id"]);
            // add parent category as well.
            if (
              category["parent_id"] != "" &&
              category["parent_id"] != null &&
              !proficient_categories_ids.includes(category["parent_id"])
            ) {
              proficient_categories_ids.push(category["parent_id"]);
            }
          }
        }
      }
      update_payload["proficient_categories"] = proficient_categories_ids;
    } else {
      // if no proficient categories set for artist pass empty array
      update_payload["proficient_categories"] = [];
    }
    for (let key in update_payload) {
      if (update_payload[key] == "" && key != "proficient_categories") {
        delete update_payload[key];
      }
    }
  }

  updateSkillMatrix = (update_payload) => {
    this.setState({
      buttonLoader: true,
    });
    update_payload["profile_id"] = this.props.profile_id;
    update_payload["artist_username"] = this.props.artist_username;
    this.getProficientCategoriesIDs(update_payload);
    axios
      .post(ENVIRONMENT.UPDATE_ARTIST_PROFILE, update_payload)
      .then((res) => {
        this.setState({
          buttonLoader: false,
        });
        this.props.set_visibility(false);
        this.props.update_parent_state(res.data);
      });
  };

  submitBillingRate = (values) => {
    let payload = this.createPayloadForSkillMatrix(values)
    payload["action"]="add_artist_to_portal";
    payload["artist_username"]=this.props.username;
    payload["test_id"]=this.props.testID;
    if(this.props.skype && this.props.skype != ''){
      payload['skype_username'] = this.props.skype
    }
    if(this.props.hideBillingFormInput){
      // Set billing details that were passed as props
      payload['hourly_rate'] = this.props.billing_rate
      payload['billing_type'] = this.props.billing_type
    }

    this.setState({
      buttonLoader: true,
    });
    axios.post(ENVIRONMENT.TEST_ARTIST_CONTROLLER, payload).then((res) => {
      this.setState({
        buttonLoader: false,
      });
      console.log(res.data);
      this.props.set_visibility(false);
      window.location.href="/admin_artist_list";
    });
  };

  createPayloadForSkillMatrix = (update_payload) => {
    // design the payload for update API request
    update_payload['skills'] = {}
    for(let skill of this.props.skill_list){
        if(update_payload[skill['name']]){
            update_payload['skills'][skill['name']] = update_payload[skill['name']]
            delete update_payload[skill['name']]
        }
    }
    update_payload['profile_id'] = this.props.profile_id
    update_payload['artist_username'] = this.props.artist_username
    if(update_payload['proficient_categories'] && update_payload['proficient_categories'].length > 0){
        let proficient_categories_ids = [];
        for(let artist_category of update_payload['proficient_categories']){
            for(let category of this.props.all_categories){
                if(artist_category == category['name']){
                    proficient_categories_ids.push(category['category_id'])
                    // add parent category as well.
                    if(category['parent_id'] != '' && category['parent_id'] != null && !proficient_categories_ids.includes(category['parent_id'])){
                        proficient_categories_ids.push(category['parent_id'])
                    }
                }
            }
        }
        update_payload['proficient_categories'] = proficient_categories_ids;
    }
    else{
        // if no proficient categories set for artist pass empty array
        update_payload['proficient_categories'] = []
    }
    for(let key in update_payload){
        if(update_payload[key] == '' && key != 'proficient_categories'){
            delete update_payload[key]
        }
    }
    return update_payload
  }

  updateSkillMatrix = (update_payload) => {
      this.setState({
          buttonLoader: true
      })
      let payload = this.createPayloadForSkillMatrix(update_payload)
      axios.post(ENVIRONMENT.UPDATE_ARTIST_PROFILE, payload)
      .then(res=> {
          this.setState({
              buttonLoader: false
          })
          this.props.set_visibility(false)
          this.props.update_parent_state(res.data)
      })
      
  }
  handleCategorySelection = (e) => {
    this.setState({
      artist_selected_categories: e,
    });
  };

    getSkillsInitialValuesForForm = () => {
        let initial_values = {}
        this.props.skill_list.map(ele => {
            if(this.props.artist_skills)
                initial_values[ele['name']] = this.props.artist_skills[ele['name']] || ''
        })

        return initial_values;
    } 

    setInviteDetails = (payload) => {
      console.log('In set invite details lambda')
      payload = this.createPayloadForSkillMatrix(payload)
      delete payload['artist_username']
      delete payload['profile_id']
      console.log("PPP = ", payload)
      this.props.update_parent_state(payload)
      this.props.set_visibility(false)
    }

    render() {
        
        return (
          <Modal
            visible={this.props.visible}
            closable={true}
            destroyOnClose={true}
            style={{ width: "80%", minWidth: "50vw" }}
            maskClosable={true}
            onCancel={() => {
              this.props.set_visibility(false);
              this.setState({
                artist_selected_categories: [],
                tree_formatted_catagories: [],
              });
            }}
            footer={[
              <div className="justify-in-end">
                <Button
                  className="modal-okay-gray square font-14"
                  htmlType="submit"
                  onClick={() => this.props.set_visibility(false)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={this.state.buttonLoader}
                  className="modal-okay square font-14"
                  htmlType="submit"
                  onClick={() =>
                    document.getElementById("update_skill_matrix").click()
                  }
                >
                  Save&nbsp;{this.state.buttonLoader ? <LoadingOutlined /> : ""}
                </Button>
              </div>,
            ]}
          >
            <div style={{ padding: "16px 16px 24px 16px" }}>
              <div
                className="manrope f-20 black-14"
                style={{ marginBottom: 16, textTransform: "capitalize" }}
              >
                {this.props.isTestArtist
                  ? "Artist Skill Level"
                  : this.props.inputInviteDetails
                  ? "Artist Additional Details"
                  : "Edit Skill Matrix"}
              </div>
              {this.state.tree_formatted_catagories.length > 0 &&
              !this.props.loading_data ? (
                <Form
                  ref={this.formRef}
                  name="material-form"
                  onFinish={
                    this.props.inputInviteDetails == true
                      ? this.setInviteDetails
                      : this.props.isTestArtist
                      ? this.submitBillingRate
                      : this.updateSkillMatrix
                  }
                  initialValues={{
                    expertise_level:
                      FileConstants.REVERSE_MAPPING_FOR_EXPERTISE[
                        this.props.expertise_level
                      ],
                    ...this.getSkillsInitialValuesForForm(),
                    proficient_categories:
                      this.state.artist_selected_categories,
                    billing_type: this.props.billing_type,
                  }}
                >
                  <Row>
                    {this.props.isTestArtist && !this.props.inputInviteDetails ? (
                      !this.props.hideBillingFormInput ? (
                        // This is a test artist
                        <span style={{ color: "red" }}>
                          Note: The pre-populated skills were entered by artist
                          at the time of sign up. Please make edits if you need.
                          Please set the billing rate for artist as well.
                        </span>
                      ) : (
                        // This is an agency artist, we don't allow changing the billing rate and billing type for the agency artists
                        <span style={{ color: "red" }}>
                          Note: The pre-populated skills were entered by artist
                          at the time of sign up. Please make edits if you need.
                        </span>
                      )
                    ) : (
                      ""
                    )}
                    <Col span={11}>
                      <div
                        className="manrope f-14 black-55"
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        Expertise Level{" "}
                        <Tooltip
                          className="skill-matrix-tooltip"
                          title={
                            <span>
                              Bad: Artist cannot pick any models. <br />
                              Average: Artist can pick models of non-enterprise
                              customers. <br />
                              Good: Artist can pick models of enterprise
                              customers.
                            </span>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                      <Form.Item
                        style={{ marginBottom: 32 }}
                        name="expertise_level"
                        className="material-category-form"
                        rules={[
                          {
                            required: true,
                            message: "Please select artist's expertise level ",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Artist Expertise Level"
                          className="manrope f-12 black-55 tree-select-material"
                          style={{ marginBottom: 15 }}
                        >
                          <Option value={3}>Good</Option>
                          <Option value={2}>Average</Option>
                          <Option value={1}>Bad</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {this.props.skill_list.map((skill, index) => {
                      return (
                        <Col span={11} offset={index % 2 == 0 ? 2 : 0}>
                          <div
                            className="manrope f-14 black-55"
                            style={{ marginTop: 10, marginBottom: 10 }}
                          >
                            {skill["display_name"]}{" "}
                            <Tooltip
                              className="skill-matrix-tooltip"
                              title={
                                <span>
                                  Good: Artist can pick models of this category
                                  from the job desk.
                                  <br /> Average and Bad: Artist cannot pick
                                  models of this category. Admin can assign
                                  manually if needed.{" "}
                                </span>
                              }
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </div>
                          <Form.Item
                            style={{ marginBottom: 32 }}
                            name={skill["name"]}
                            className="material-category-form"
                            rules={[
                              {
                                required: true,
                                message: "This Field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Please select artist proficiency"
                              className="manrope f-12 black-55 tree-select-material"
                              style={{ marginBottom: 15 }}
                            >
                              <Option value={"Good"}>Good</Option>
                              <Option value={"Average"}>Average</Option>
                              <Option value={"Bad"}>Bad</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      );
                    })}

                    <Col span={24}>
                      <div
                        className="manrope f-14 black-55"
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        Proficient Categories{" "}
                        <Tooltip
                          className="skill-matrix-tooltip"
                          title={
                            <span>
                              Artist will only be shown products belonging to
                              the proficient categories
                            </span>
                          }
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                      <Form.Item
                        style={{ marginBottom: 32 }}
                        name="proficient_categories"
                        className="material-category-form"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please select the categories that artist is expert in creating",
                          },
                        ]}
                      >
                        <TreeSelect
                          className="selectable-categories"
                          showSearch={true}
                          maxTagCount={0}
                          onChange={(e) => this.handleCategorySelection(e)}
                          value={this.state.artist_selected_categories}
                          treeCheckable={true}
                          placeholder={"Artist Proficient Categories"}
                          treeData={this.state.tree_formatted_catagories}
                        ></TreeSelect>
                      </Form.Item>
                    </Col>
                    {(this.props.inputInviteDetails ||
                      this.props.isTestArtist) &&
                    this.props.hideBillingFormInput == false ? (
                      <React.Fragment>
                        <Col span={11}>
                          <div
                            className="manrope f-14 black-55"
                            style={{ marginBottom: 8, marginTop: 22 }}
                          >
                            Billing Type
                          </div>
                          <Form.Item
                            name="billing_type"
                            className="material-category-form"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please Select the Billing Type of Artist!",
                              },
                            ]}
                          >
                            <Select
                              className="tree-select-material"
                              style={{ color: "#333333" }}
                              placeholder="Please Select Billing Type"
                            >
                              <Option key={1} value="hourly">
                                Hourly
                              </Option>
                              <Option key={2} value="per_model">
                                Per Model
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={11} offset={2}>
                          <div
                            className="manrope f-14 black-55"
                            style={{ marginBottom: 8, marginTop: 22 }}
                          >
                            Billing Rate{" "}
                            {this.props.billing_rate ?
                            <span
                              style={{
                                fontSize: 11,
                                color: "red",
                                background: "#f3cdcd",
                                padding: "3px 5px 3px 5px",
                                borderRadius: 4,
                              }}
                            >
                                Artist Proposed Rate:  {this.props.billing_rate}</span>
                                : ""}
                          </div>
                          <Form.Item
                            style={{ marginBottom: 32 }}
                            name="hourly_rate"
                            className="material-category-form"
                            rules={[
                              { required: true, message: "Enter Amount" },
                            ]}
                          >
                            <Input
                              type="number"
                              min="1"
                              className="input-number-test-admin"
                              style={{ lineHeight: "30px" }}
                              placeholder="Enter Billing Rate"
                            />
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Form.Item style={{ display: "none" }}>
                    <Button
                      id="update_skill_matrix"
                      style={{ display: "none" }}
                      className="modal-okay square font-14"
                      htmlType="submit"
                    >
                      Submit&nbsp;
                      {this.state.buttonLoader ? <LoadingOutlined /> : ""}
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <LoadingOutlined
                  style={{ justifyContent: "center", display: "flex" }}
                />
              )}
            </div>
          </Modal>
        );
    }
}

export default EditSkillMatrixModal;