import React from "react";
import axios from 'axios';
import "antd/dist/antd.css";
import ENVIRONMENTS from '../../../../environments'
import '@ant-design/compatible/assets/index.css';
import {calcTotalModelsWorked, getNeedToModelString} from '../../Utilities'
import {  Row, Col, Select, Typography, Table, DatePicker, Tooltip  } from "antd";
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import DottedLoader from "../../DottedLoader";
import moment from 'moment';

const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const DATE_FORMAT = 'MM/DD/YYYY';

class InvoicingTimeLog extends React.Component {
  state = {
    profile_id: '',
    artist_username: '',
    profile_name: '',
    billing_type: '',
    hourly_rate: "",
    availability: {},
    data_source: [],
    invoice_summary: {},
    artist_data_fetched: false,
    // Invoicing attributes
    start_date: moment().startOf('month').format(DATE_FORMAT),
    end_date: moment().endOf('month').format(DATE_FORMAT),
    invoice_data: [],
    filtered_invoice_date: [],
    loading_invoice_date: false,
    model_type_filter: '',
    total_hours_worked: 0,
    total_models_worked: 0,
    total_amount_invoiced: 0,
    required_invoicing_data: 'all'
  };

  componentDidMount() {
    axios.post(ENVIRONMENTS.GET_ARTIST_PROFILE, {
      artist_username: localStorage.getItem('username')
    })
      .then(res => {
        console.log('artist = ', res.data);
        this.setState({
          profile_id: res.data['artist_id'],
          artist_username: res.data['artist_username'],
          profile_name: res.data['profile_name'],
          is_super_artist: res.data['is_superartist'],
          billing_type: res.data['billing_type'],
          hourly_rate: res.data['hourly_rate'],
          availability: res.data['availability'],
          artist_data_fetched: true
        }, () =>{this.fetchArtictInvoice()})
      })
  }


  invoiceSummary = () => {
    let total_hours = 0;
    let total_amount = 0;
    let total_models = 0;

    this.state.invoice_data.map((ele) => {
      total_hours += parseFloat(ele['hours']) || 0;
    })
    total_hours = total_hours.toFixed(2);
    total_models = calcTotalModelsWorked(this.state.invoice_data);

    if(this.state.billing_type == 'hourly' && this.state.hourly_rate)
      total_amount = total_hours * this.state.hourly_rate;
    else if(this.state.billing_type == 'per_model' && this.state.hourly_rate)
      total_amount = total_models * this.state.hourly_rate;
    
    total_amount = total_amount.toFixed(2);
    this.setState({
      total_amount_invoiced: total_amount,
      total_hours_worked: total_hours,
      total_models_worked: total_models
    })
  }

  onCalenderChange = (date_values) => {
    if (date_values && date_values[0] && date_values[1]) {
        this.setState({
          start_date: date_values[0].format(DATE_FORMAT),
          end_date: date_values[1].format(DATE_FORMAT)
        }, () => {this.fetchArtictInvoice()})
    }
  }

  fetchArtictInvoice = () => {
    let payload = {
      'start_date': moment(this.state.start_date).format('YYYY-MM-DD'),
      'end_date': moment(this.state.end_date).format('YYYY-MM-DD'),
      'artist_usernames': [this.state.artist_username],
      'artist_invoice': true,
    }
    console.log('payload = ', payload)
    this.setState({
      loading_invoice_date: true
    })
    axios.post(ENVIRONMENTS.GET_ARTIST_INVOICE, payload)
    .then(res => {
      console.log('invoicing data = ', res);
      this.setState({
        invoice_data: res.data['invoice_data'],
        filtered_invoice_date: res.data['invoice_data'],
        invoice_summary: res.data['summary'],
        loading_invoice_date: false
      }, ()=> {this.filterInvoiceData()})
    })
  }


  getInvoicingColumns = () => [
    {
      title: <span className="manrope f-16 black-14">Model ID</span>,
      dataIndex: 'model_id',
      key: 'model_id',
      render: text => <span  className="manrope f-14">{text}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">Model Name</span>,
      dataIndex: 'model_name',
      key: 'model_name',
      render: text => <span  className="manrope f-14 ">{text}</span>,
    
    },
    {
      title: <span className="manrope f-16 black-14">Model Type</span>,
      dataIndex: 'model_type',
      key: 'model_type',
      render: (text, index) => <span className="manrope f-14">{text} {index['variant_of'] != null ? ' Variation' : ''} {getNeedToModelString(index)}</span>,
    },
    {
        title: <span className="manrope f-16 black-14">Task Type</span>,
        dataIndex: 'request_type',
        key: 'request_type',
        render: text => <span  className="manrope f-14">{text}</span>,
    },
    {
      title: <span className="manrope f-16 black-14">Date</span>,
      dataIndex: 'date',
      key: 'date',
      render: text => <span  className="manrope f-14 ">{text || 'N/A'}</span>,
    },
    {
        title: <span className="manrope f-16 black-14">Hours Worked</span>,
        dataIndex: 'hours',
        key: 'hours',
        render: text => <span className="manrope f-14">{parseFloat(text || 0) + ' Hours'}</span>,
    },
];

  filterInvoiceData = () => {
    let filtered_data = [];
    let filters = {};
    if(this.state.model_type_filter != ''){
      filters['model_type'] = this.state.model_type_filter;
    }
    filtered_data = this.state.invoice_data.filter(function(item) {
      for (var key in filters) {
        if (item[key] != filters[key])
          return false;
      }
      return true;
    });

    this.setState({filtered_invoice_date: filtered_data})
  }

  getTotalModelsWorked = () => {
    let total_models = 0;
    total_models = this.state.invoice_summary['spaces'] + this.state.invoice_summary['space_variants'] + this.state.invoice_summary['product_variants'] + this.state.invoice_summary['products'] + this.state.invoice_summary['materials'] + this.state.invoice_summary['complaints']
    if(!total_models)
      total_models = 0;
    return total_models;
  }

  getBillingRateString = () => {
    let billing_rate = "N/A";
    if (this.state.hourly_rate && (this.state.hourly_rate == 0 || this.state.hourly_rate == "")){
      billing_rate = "N/A";
    }
    else if(this.state.hourly_rate){
      billing_rate = "$" + this.state.hourly_rate;
      if (this.state.billing_type == "hourly")
        billing_rate += " / hour";
      else if(this.state.billing_type == "per_model")
        billing_rate += " / model";
    }
    return billing_rate;
  }

  render() {
    return (
        this.state.artist_data_fetched ? 
        <React.Fragment>
          {/* Artist Time Log */}
          <Row>
            <Col span={24}>
              <p className="manrope f-24" style={{marginTop: 20, marginBottom: 10}}>My Time Log</p>
            </Col>
            </Row>

            <Row className="justify-in-start">
              <Col span={5}>
                <RangePicker style={{marginRight: 20}} size={"large"} defaultValue={[moment(this.state.start_date, DATE_FORMAT), moment(this.state.end_date, DATE_FORMAT)]}
                        format={DATE_FORMAT} className="date-picker" popupStyle={{fontFamily: 'Manrope', fontSize: 14,color:"#40a9ff",background:"#e6efff" }} placeholder={['Date (From)', 'Date (To)']} 
                        value={[moment(this.state.start_date, DATE_FORMAT), moment(this.state.end_date, DATE_FORMAT)]}
                        onCalendarChange={val => this.onCalenderChange(val)}/>
              </Col>
              
            <Col span={4}>
                <Select placeholder="Model Type: All" size={"large"} style={{width: '90%', marginRight: 20}} onChange={val => {this.setState({model_type_filter: val}, ()=>this.filterInvoiceData())}}>
                  <Option className="manrope f-14 black-55 select-view" value="">Model Type: All</Option>
                  <Option className="manrope f-14 black-55 select-view" value="Space">Space</Option>
                  <Option className="manrope f-14 black-55 select-view" value="Product">Product</Option>
                  <Option className="manrope f-14 black-55 select-view" value="Material">Material</Option>
                  {this.state.is_super_artist ?
                  <Option className="manrope f-14 black-55 select-view" value="Complaint">Complaint</Option>
                  : ""}
                </Select>
              </Col>
            </Row>
            
            <Col className='items-table' span={24}>
                <Table loading={{indicator: <LoadingOutlined/>, spinning: this.state.loading_invoice_date}} columns={this.getInvoicingColumns()} dataSource={this.state.filtered_invoice_date} pagination={{ pageSize: 7}}/>
            </Col>

          {/* Artist Work Summary */}
          <Row style={{display: 'block'}}>
            <span className="manrope f-20">Work Summary</span>
            <Row className="artist-details-info" style={{marginBottom: 20, marginTop :20}}>
              <Col span={6}>
                <div className="manrope f-16">
                  <p className="artist-details-heading">Total Hours Worked</p>
                  <p className="artist-details-value">{this.state.invoice_summary['hours'] ? this.state.invoice_summary['hours'] + ' Hours' : '0 Hours'}</p>
                </div>
              </Col>

              <Col span={6}>
              <div className="manrope f-16">
                    <p className="artist-details-heading">Total Models Made
                    {this.getTotalModelsWorked() > 0 ?
                    <Tooltip title={<span className='manrope f-12 white'>
                      {this.state.invoice_summary['spaces'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Spaces: {this.state.invoice_summary['spaces']}</span> : ''
                      }
                      {this.state.invoice_summary['space_variants'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Space Variants: {this.state.invoice_summary['space_variants']}</span> : ''
                      }
                      {this.state.invoice_summary['products'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Products: {this.state.invoice_summary['products']}</span> : ''
                      }
                      {this.state.invoice_summary['product_variants'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Product Variants: {this.state.invoice_summary['product_variants']}</span> : ''
                      }
                      {this.state.invoice_summary['complaints'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Complaints: {this.state.invoice_summary['complaints']}</span> : ''
                      }
                      {this.state.invoice_summary['materials'] > 0 ?
                        <span style={{display: 'block', marginBottom: 1, marginTop: 1}}>Materials: {this.state.invoice_summary['materials']}</span> : ''
                      }
                    </span>}>
                      <InfoCircleOutlined style={{marginLeft: 3}}/>
                    </Tooltip> : ''}
                    </p>
                    <p className="artist-details-value">{this.getTotalModelsWorked()}</p>
                  </div>
              </Col>

              <Col span={6}>
                <div className="manrope f-16">
                  <p className="artist-details-heading">Billing Rate</p>
                  <p className="artist-details-value">{this.getBillingRateString()}</p>
                </div>
              </Col>

              <Col span={6}>
                <div className="manrope f-16">
                  <p className="artist-details-heading">Total Amount Invoiced</p>
                  <p className="artist-details-value">{this.state.invoice_summary['amount'] || '$0.0'}</p>
                </div>
              </Col>
            </Row>
          </Row>

        </React.Fragment>
        :  <DottedLoader/>
    );
  }
}

export default InvoicingTimeLog;