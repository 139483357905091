import React, { useState, useEffect, useMemo } from "react";
import "antd/dist/antd.css";
import {
  Col,
  Card,
  Menu,
  Dropdown,
  Button,
  Tooltip,
  message,
  Modal,
  Select,
} from "antd";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as MoreIcon } from "../../../../icons/more.svg";
import ENVIRONMENT from "../../../../environments";
import * as Utilities from "../../Utilities";
import _ from "lodash";
import axios from "axios";

const COMPANY_ID = localStorage.getItem("company_id");
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem(
  "managed_customer_username"
);
const MANAGED_COMPANY_ID = localStorage.getItem("managed_company_id");
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem("managed_customer_email");
const IS_MS_PROVIDER =
  localStorage.getItem("is_msprovider") === "true" ? true : false;
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const MANAGED_SHARED_ENTITES = localStorage.getItem("managed_shared_entites");
const SEQUENCE_IDS = localStorage.getItem("sequence_ids");
const { Option } = Select;
const FILTER_KEYS_FOR_QUERY_PARAMS = ["category"];

let SEQUENCE_ID_OTHER = -1;
let SEQUENCE_ID_OWNED = -1;

const SpaceVariantCard = ({
  spaceVariant,
  setselectedSpaceId,
  setProjectModalVisible,
  setSelectedSpace,
  performStoreAction,
  full,
  optionsEnabled,
}) => {

  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [currentId, setCurrentId] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [companyID, setCompanyID] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(null);
  const [customerSequences, setCustomerSequences] = useState({});

  const currentRoute= window.location.href;

  const deleteRoom = (id, customerUsername, companyID) => {
    setCurrentId(id);
    setCurrentCustomer(customerUsername);
    setCompanyID(companyID);
    setDeleteModal(true);
  };

  const onDelete = () => {
    setDeleteLoader(true);
    let api_end_point = ENVIRONMENT.UPDATE_ROOM;
    let payload = {
      room_id: currentId,
      is_hidden: true,
      username: CUSTOMER_USERNAME,
    };

    let company_id = COMPANY_ID;
    if (CUSTOMER_USERNAME != currentCustomer && company_id != companyID) {
      //case of customer deleting the room added from store or shared by some other customer
      api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
      payload = {
        room_id: currentId,
        username: CUSTOMER_USERNAME,
      };
    }

    axios
      .post(api_end_point, payload)
      .then((response) => {
        message.info("Space Deleted.");
        setDeleteLoader(false);
        setDeleteModal(false);
        window.location.href = currentRoute;
      })
      .catch((error) => {
        console.log(error);
        message.error("Error in deleting space.");
        setDeleteLoader(false);
        setDeleteModal(false);
      });
  };

  const get_options = (
    id,
    customerUsername,
    managed_customer,
    room_data,
    companyID,
    setSelectedSpace,
    performStoreAction
  ) => {
    return (
      <Menu>
        {(Utilities.companyMatch(room_data.company_id, COMPANY_ID) ||
          CUSTOMER_USERNAME == customerUsername) &&
        room_data.is_store_item != 1 ? (
          <Menu.Item>
            <a
              className="manrope f-14"
              onClick={() => {
                setSelectedSpace(spaceVariant);
                performStoreAction();
              }}
            >
              Sell on Store
            </a>
          </Menu.Item>
        ) : (
          ""
        )}
        {(Utilities.companyMatch(room_data.company_id, COMPANY_ID) ||
          CUSTOMER_USERNAME == customerUsername) &&
        room_data.is_store_item == "owned" ? (
          <Menu.Item>
            <a
              className="manrope f-14"
              onClick={() => {
                setSelectedSpace(spaceVariant);
                performStoreAction();
              }}
            >
              Remove From Store
            </a>
          </Menu.Item>
        ) : (
          ""
        )}
        {managed_customer ? (
          ""
        ) : (
          <Menu.Item>
            <a
              className="manrope f-14"
              onClick={() => deleteRoom(id, customerUsername, companyID)}
            >
              Delete Space
            </a>
          </Menu.Item>
        )}
        <Menu.Item>
          <a
            className="manrope f-14"
            onClick={() => {
              setProjectModalVisible(true);
              setselectedSpaceId(id);
            }}
          >
            Add to Project
          </a>
        </Menu.Item>
      </Menu>
    );
  };

  const options = useMemo(
    () =>
      get_options(
        spaceVariant.room_id,
        spaceVariant.customer_username,
        spaceVariant.managed_customer,
        spaceVariant,
        spaceVariant.company_id,
        setSelectedSpace,
        performStoreAction
      ),
    [spaceVariant, setSelectedSpace, performStoreAction]
  );

  const getThumbnail = () => {
    if (Array.isArray(spaceVariant)) {
      return spaceVariant[0].thumbnail.replace(
        ENVIRONMENT.DEFAULT_BASE_URL,
        ENVIRONMENT.getBaseURL(spaceVariant[0].platform)
      );
    }
    return spaceVariant.thumbnail.replace(
      ENVIRONMENT.DEFAULT_BASE_URL,
      ENVIRONMENT.getBaseURL(spaceVariant.platform)
    );
  };

  const getLink = () => {
    return `/rooms/${spaceVariant.room_id}/${window.location.search}`;
  };

  const getID = () => {
    //return folder id if item is a folder, otherwise return
    // item id
    return `element-${spaceVariant.room_id}`;
  };

  return (
    <Col
      className="draggable-element mt-10"
      id={getID()}
      lg={full ? 24 : 6}
      sm={24}
      md={24}
      offset={0}
    >
      <Card
        bodyStyle={{ padding: "5px" }}
        className={`card-shadow-product`}
        bordered={false}
        style={{
          width: "97%",
          border: "solid 1px #e3e3e3",
        }}
      >
        {optionsEnabled ? (
          <Dropdown
            className="group-settings more-icon"
            overlay={options}
            trigger={["click"]}
          >
            <Button
              shape="circle"
              size={"large"}
              className="more-option-library font-14 w-900"
              icon={<MoreIcon />}
            ></Button>
          </Dropdown>
        ) : (
          <></>
        )}
        <div className="stacked-card-inner">
          <Link to={getLink()}>
            <div
              className="suggested-product"
              style={{ zIndex: "1", cursor: "pointer" }}
            >
              <div className="box">
                {getThumbnail() ? (
                  <img
                    src={getThumbnail()}
                    style={{ objectFit: "cover", width: "100%", height: 280 }}
                  />
                ) : (
                  <React.Fragment>
                    <img
                      src={require("../../../../assets/images/space_incomplete.svg")}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: 280,
                        opacity: "0.5",
                        filter: "blur(0px)",
                      }}
                    />
                    <div className="incomplete-request-text">
                      <span>Image not Found</span>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div
                className="justify-in-start"
                style={{ margin: "12px 12px 8px 12px" }}
              >
                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      {spaceVariant.room_name}{" "}
                    </span>
                  }
                >
                  <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100">
                    {spaceVariant.room_name}
                  </span>
                </Tooltip>

                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      {" "}
                      {Utilities.timestampToTimeSince(
                        spaceVariant.last_modified_stamp,
                        "tooltip"
                      )}{" "}
                    </span>
                  }
                >
                  <span
                    className="manrope f-12 grey-77 w-500 time-clock-bg"
                    style={{ marginLeft: 4 }}
                  >
                    <img
                      src="/img/carbon_time.svg"
                      style={{ marginRight: 2 }}
                    />{" "}
                    {Utilities.timestampToTimeSince(
                      spaceVariant.last_modified_stamp
                    )}
                  </span>
                </Tooltip>
              </div>

              <div
                className="justify-in-start"
                style={{
                  marginLeft: "12px",
                  marginRight: "12px",
                  marginBottom: "4px",
                  textAlign: "left",
                }}
              >
                <span
                  className="manrope f-12 grey-77 capitalize"
                  style={{ marginRight: 4 }}
                >
                  Category
                </span>
                <span className="manrope f-12 black-33 capitalize">
                  {spaceVariant.category}
                </span>
              </div>

              <div
                className="justify-in-start manrope f-12 grey-77 capitalize"
                style={{
                  marginBottom: "4px",
                  marginLeft: "12px",
                  marginRight: "12px",
                  textAlign: "left",
                }}
              >
                <div style={{ marginRight: 4 }}>Item ID</div>
                <div style={{ color: "#333333" }}>
                  {spaceVariant.brand_id || "N/A"}
                </div>
              </div>
              <div
                className="justify-in-start manrope f-12 grey-77 capitalize"
                style={{
                  marginBottom: "12px",
                  marginLeft: "12px",
                  marginRight: "12px",
                  textAlign: "left",
                }}
              >
                <div style={{ marginRight: 4 }}>Dimensions:</div>
                {Array.isArray(spaceVariant.dimensions) ? (
                  <div className="manrope f-12 black-33">N/A</div>
                ) : (
                  <div className="manrope f-12 black-33">
                    D {Math.round(spaceVariant.dimensions.depth)}" W{" "}
                    {Math.round(spaceVariant.dimensions.width)}" H{" "}
                    {Math.round(spaceVariant.dimensions.height)}"
                  </div>
                )}
              </div>
            </div>
          </Link>
        </div>
      </Card>

      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        visible={deleteModal}
        closable={false}
        footer={[
          <span className="justify-in-center">
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay-gray square font-12"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              key="back"
              style={{ textAlign: "center" }}
              className="modal-okay square font-12"
              onClick={() => onDelete()}
            >
              {deleteLoader ? (
                <span>
                  {" "}
                  Deleting <LoadingOutlined spin />
                </span>
              ) : (
                <span> Okay </span>
              )}
            </Button>
          </span>,
        ]}
      >
        <div
          style={{
            margin: "30px auto 0px auto",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="manrope f-16 black-00 w-600">
            You're about to delete a space
          </h2>
          {companyID == COMPANY_ID ? (
            <p className="manrope f-14 black-55">
              This space and its assets will be deleted for the users of your
              company. Are you sure you want to delete?
            </p>
          ) : (
            <p className="manrope f-14 black-55">
              Deleting this space would delete all assets generated with it. Are
              you sure you want to delete?
            </p>
          )}
        </div>
      </Modal>
    </Col>
  );
};

export default SpaceVariantCard;
