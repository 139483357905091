import React, { useContext, useState } from 'react';
import _ from "lodash";
import ENVIRONMENT from '../../../../environments';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button, Card, Menu, Dropdown } from 'antd';
import ProductPageConstants from './ProductPageConstants.js';
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'

const COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const QUERY_PARAMS_TO_RETAIN = ['category', 'style', 'color', 'type', 'material', 'search']

const ProductVariantGrid = (props) => {
    const { key, product } = props;
    const { onAddCollectionClick,
        setCompanyID, setCurrentCustomer, setDeleteModal, isStorePage, setCurrentId, setVariantSelected,
        openVideoModal, openVariationModal, openThreeSixtyModal, openDimensionalModal, getProductData, performStoreAction } = useContext(CustomerProductContext);

    let get_options = (id, customerUsername, companyID, product_data, onAddToCollection, onDelete) => {
        return <Menu>
            <Menu.Item>
                <a className="manrope f-14" onClick={() => onAddToCollection(id)}>Add to Scene</a>
            </Menu.Item>
            {(Utilities.companyMatch(companyID, localStorage.getItem('company_id')) || localStorage.getItem('username') == customerUsername) && product_data.is_store_item != 1 ?
                <Menu.Item>
                    <a className="manrope f-14" onClick={() => performStoreAction(id, product_data)}>Sell on Store</a>
                </Menu.Item> : ''}
            {(Utilities.companyMatch(companyID, localStorage.getItem('company_id')) || localStorage.getItem('username') == customerUsername) && product_data.is_store_item == 1 ?
                <Menu.Item>
                    <a className="manrope f-14" onClick={() => performStoreAction(id, product_data)}>Remove From Store</a>
                </Menu.Item> : ''}
            {isStorePage ? '' : <Menu.Item>
                <a className="manrope f-14" onClick={() => onDelete(id, customerUsername, companyID)}>Delete Product</a>
            </Menu.Item>}
        </Menu>
    };

    const takeToSiloScreen = (product_id) => {
        window.location.href = "/silo-tool/" + product_id;
    }

    let menu = (id, company_id, username, openProductVideoModal, openProductDimModal, openThreeSixtyModal, openProductVariationModal, folder_id) => {
        return <Menu style={{ width: "148px", marginLeft: "10px", backgroundColor: '#E6F7FF' }}>
            <Menu.Item className='manrope f-14' key={0} onClick={() => takeToSiloScreen(id)}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>Silo Images</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={1} onClick={() => openProductVideoModal(id, company_id, username)}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>MP4 Video</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key={2} className='manrope f-14' onClick={() => openProductDimModal(id, company_id, username)}>
                <span className='cursor-pointer' style={{ color: '#276DD7' }}>Dimensional Image</span>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={3} onClick={() => openThreeSixtyModal(id, company_id, username)}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>Product 360 Spin</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={4} onClick={() => openProductVariationModal(id, company_id, username, folder_id)}>
                <Tooltip>
                    <span className='cursor-pointer' style={{ color: '#276DD7' }}>Product Variant</span>
                </Tooltip>
            </Menu.Item>
        </Menu>
    }

    const deleteProduct = (id, customerUsername, companyID) => {
        setCurrentId(id);
        setCompanyID(companyID);
        setCurrentCustomer(customerUsername);
        setDeleteModal(true);
    }

    const getThumbnail = () => {
        return product.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(product.platform));
    }

    const getLink = () => {
        if (isStorePage) {
            return '/product-assets/' + product.id;
        } else {
            console.log("PRODUCT VARIANT",product)
            console.log('VARIANT URL', '/products/' + product.id + '?variant_of=' + product.variant_of);
            return '/products/' + product.id + '?variant_of=' + product.variant_of;
        }
    }


    const openProductVariationModal = (id, company_id, username, folder_id) => {
        getProductData(id, company_id, username, true, true);
        setCurrentId(id);
        setCompanyID(company_id);
        setCurrentCustomer(username);
        setVariantSelected(true);
        openVariationModal();
    }

    const openProductVideoModal = (id, company_id, username) => {
        getProductData(id, company_id, username, true, true);

        setCurrentId(id);
        setCompanyID(company_id);
        setCurrentCustomer(username);
        openVideoModal();
        setVariantSelected(true);
    }

    const openProductDimModal = (id, company_id, username) => {
        getProductData(id, company_id, username, false, true);

        setCurrentId(id);
        setCompanyID(company_id);
        setCurrentCustomer(username);
        openDimensionalModal();
        setVariantSelected(true);
    }

    const openProductThreeSixtyModal = (id, company_id, username) => {
        getProductData(id, company_id, username, true, true);
        setCurrentId(id);
        setCompanyID(company_id);
        setCurrentCustomer(username);
        openThreeSixtyModal();
        setVariantSelected(true);
    }

    const getAssetImageCount = () => {
        let local_company_id = COMPANY_ID;
        if (FileConstants.isMSProvider) {
            if (MANAGED_COMPANY_ID) {
                local_company_id = MANAGED_COMPANY_ID;
            }
        }
        if (product.company_id && product.company_id == local_company_id) {
            if (product.company_render_count) {
                return product.company_render_count;
            } else {
                return 0;
            }
        } else {
            if (product.user_render_count) {
                return product.user_render_count;
            } else {
                return 0;
            }
        }
            
    }

    return isStorePage && product.is_store_item != 1 ? '' : (isStorePage && product.is_store_item == 1) || (!isStorePage) ? (
        <Col span={24}>
            <a href={getLink()} >
                <Card className='variant-grid-card'>
                    <Dropdown className="group-settings more-icon" overlay={get_options(product.id, product.customer_username, product.company_id, product, onAddCollectionClick, deleteProduct)} trigger={['click']}>
                        <MoreIcon />
                    </Dropdown>
                    <img src={getThumbnail()} className='product-variant-img' />

                    <hr style={{ margin: 0 }} />

                    <div className='justify-in-start' style={{ margin: "12px 12px 8px 12px" }}>
                        <Tooltip title={<span className='manrope f-12 white'>{product.name}</span>}>
                            <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100">{product.name}</span>
                        </Tooltip>
                        <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(product.last_modified_stamp, 'tooltip')}</span>}>
                            <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                                <img src='/img/carbon_time.svg' style={{ marginRight: 2 }} />{Utilities.timestampToTimeSince(product.last_modified_stamp)}
                            </span>
                        </Tooltip>
                    </div>

                    <div className="justify-in-start" style={{ marginLeft: "12px", marginRight: "12px", marginBottom: "4px", textAlign: "left" }}>
                        <span className="manrope f-12 grey-77 capitalize" style={{ marginRight: 4 }}>Category:</span>
                        <Tooltip title={<span className='manrope f-12 white'>{product.category}</span>}>
                            <span className="manrope f-12 black-33 capitalize clamp-w-width">{product.category}</span>
                        </Tooltip>
                    </div>
                    <div className="justify-in-start" style={{ marginLeft: "12px", marginRight: "12px", textAlign: "left" }}>
                        <span className="manrope f-12 grey-77 capitalize" style={{ marginRight: 4 }}>Item ID:</span>
                        <Tooltip title={<span className='manrope f-12 white'>{product.brand_id || 'N/A'}</span>}>
                            <span className="manrope f-12 black-33 capitalize clamp-w-width">{product.brand_id || 'N/A'}</span>
                        </Tooltip>
                    </div>

                    <div style={{ display: 'flex', justifyContent: product.is_store_item == 1 ? 'flex-end' : 'space-between', padding: '10px', alignItems: 'center' }}>
                        {product.is_store_item == 1 ? '' : <Dropdown overlay={
                            menu(product.id.toString(), product.company_id, product.customer_username, openProductVideoModal, openProductDimModal, openProductThreeSixtyModal, openProductVariationModal, product.folder_id)
                        } trigger={'click'} placement="topLeft">
                            <span className="justify-in-start generate-asset-btn"> Generate Assets + </span>
                        </Dropdown>}
                        <div className="justify-in-end" >
                            <span className="manrope f-12 black-33 capitalize">{getAssetImageCount()} Images</span>
                            <img src='/img/ArrowRight.png' className="icon-instance-node" style={{ marginLeft: '4px' }} />
                        </div>
                    </div>
                </Card>
            </a>
        </Col>
    ) : null;
};

export default ProductVariantGrid;