import React, { useState, useEffect,  useCallback  }  from 'react';
import _ from "lodash" // Import the entire lodash library
import axios from 'axios';
import '@ant-design/compatible/assets/index.css';
import { CheckOutlined, InfoCircleFilled, LoadingOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Tabs, Slider, Upload, Modal, Card, Tooltip, message } from 'antd';
import 'rc-color-picker/assets/index.css';
import CustomerMainLayout from '../CustomerComponents/CustomerMainLayout';
import { ADD_MATERIALS_IN_COMPONENT, BASE_URL, FETCH_PRODUCT, MATERIAL_GET_BATCH, PRODUCT_COMPONENT_GET_BATCH, PRODUCT_COMPONENT_GET } from '../../../environments/env';
import ENVIRONMENT from '../../../environments';
import { ReactComponent as EmptyBox } from '../../../icons/empty-box.svg'
import DottedLoader from '../DottedLoader/DottedLoader';
import Configurator from '../../../Configurator';

const { TabPane } = Tabs;


const materials = [
    {
        id: 1,
        category: "Fabric",
        name: 'Mat 1',
        status: 5,
        renders: {data: {thumbnail: '/img/misc05L.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 2,
        category: "Fabric",
        name: 'Mat 2',
        status: 5,
        renders: {data: {thumbnail: '/img/floral03L.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 3,
        category: "Fabric",
        name: 'Mat 3',
        status: 5,
        renders: {data: {thumbnail: '/img/pattern10L.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 4,
        category: "Wood",
        name: 'Mat 4',
        status: 5,
        renders: {data: {thumbnail: '/img/wood.jpg'}},
        type: 'plain',
        length: 20,
        width: 20
    },
    {
        id: 5,
        category: "Gravel",
        name: 'Mat 5',
        status: 5,
        renders: {data: {thumbnail: '/img/gravel.jpg'}},
        type: 'plain',
        length: 20,
        width: 20
    }, {
        id: 6,
        category: "Marble",
        name: 'Mat 6',
        status: 5,
        renders: {data: {thumbnail: '/img/marble.jpg'}},
        type: 'plain',
        length: 20,
        width: 20
    }, {
        id: 7,
        category: "Ceramic",
        name: 'Mat 7',
        status: 5,
        renders: {data: {thumbnail: '/img/tiles.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    }, {
        id: 8,
        category: "Fabric",
        name: 'Mat 8',
        status: 5,
        renders: {data: {thumbnail: '/img/red.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    }, {
        id: 9,
        category: "Fabric",
        name: 'Mat 9',
        status: 5,
        renders: {data: {thumbnail: '/img/fabric1.jpg'}},
        type: 'plain',
        length: 20,
        width: 20
    }
    , {
        id: 10,
        category: "Fabric",
        name: 'Mat 10',
        status: 5,
        renders: {data: {thumbnail: '/img/fabric2.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    }
    , {
        id: 11,
        category: "Wool",
        name: 'Mat 11',
        status: 5,
        renders: {data: {thumbnail: '/img/green.jpg'}},
        type: 'plain',
        length: 20,
        width: 20
    }, {
        id: 12,
        category: "Wool",
        name: 'Mat 12',
        status: 5,
        renders: {data: {thumbnail: '/img/yellow.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 13,
        category: "Ceramic",
        name: 'Mat 13',
        status: 5,
        renders: {data: {thumbnail: '/img/blue_tile.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 13,
        category: "Fabric",
        name: 'Mat 13',
        status: 5,
        renders: {data: {thumbnail: '/img/blue.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 14,
        category: "Ceramic",
        name: 'Mat 14',
        status: 5,
        renders: {data: {thumbnail: '/img/black_tile.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 15,
        category: "Ceramic",
        name: 'Mat 15',
        status: 5,
        renders: {data: {thumbnail: '/img/black_square.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 16,
        category: "Ceramic",
        name: 'Mat 16',
        status: 5,
        renders: {data: {thumbnail: '/img/versace.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 17,
        category: "Fabric",
        name: 'Mat 17',
        status: 5,
        renders: {data: {thumbnail: '/img/green_wool.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 18,
        category: "Fabric",
        name: 'Mat 18',
        status: 5,
        renders: {data: {thumbnail: '/img/carpet.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 19,
        category: "Fabric",
        name: 'Mat 19',
        status: 5,
        renders: {data: {thumbnail: '/img/green_white.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
    {
        id: 20,
        category: "Fabric",
        name: 'Mat 20',
        status: 5,
        renders: {data: {thumbnail: '/img/wicker.jpg'}},
        type: 'pattern',
        length: 20,
        width: 20
    },
]

    const MaterialCards = (props) => {
        let componentMaterials = props.componentMaterials();
        let componentsExist = componentMaterials.length > 0 && componentMaterials[0].data;
        let checkIfAllCategoryVar = props.checkIfAllCategoryVar(props.data.category);
        
        return (
        <Row style={{marginBottom: ((componentsExist && !checkIfAllCategoryVar) || (!componentsExist && props.data.category.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1)) ? 30 :0}}>
            {((componentsExist && !checkIfAllCategoryVar) || (!componentsExist && props.data.category.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1)) ?
            <Col span={24}>
                <h4 className="manrope f-24 black-14">
                    {props.data.category}
                </h4>
            </Col> 
            : ''}
            <Row>
            {props.data.material.map((material,index) => (
            
            (componentsExist && !props.checkIfMaterialExists(componentMaterials[0].data,material) && ((props.data.category.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1) || (material.name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1))) 
            || (!componentsExist && ((props.data.category.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1) || (material.name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1))) ?
                <Col>
                    <Tooltip title={<span className="manrope f-12 white">{material.name}</span>}>
                        <Card className="material-card" onClick={() => props.selectMaterial(material)} style={{border: props.selectedMaterial.includes(material) ? "1px solid black" : "1px solid #f0f0f0"}}>
                            {material.status == 5 && material.renders.data && material.renders.data.thumbnail ?
                            <img id={'material-'+material.id} src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)} className={material.status == 5 ? 'material-img' : 'material-img blur'}/>
                            // MATERIAL_FILE_URL + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)
                            :<img id={'material-'+material.id} src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)} className={material.status == 5 ? 'material-img' : 'material-img blur'} />
                            }
                            {props.selectedMaterial.includes(material) ?
                            <img className="success-img" src="/img/success.png"/> : ''}
                            <h5 className="manrope f-18 black-00 j-end clamp-text" style={{marginTop: 8}}>{material.name}</h5>
                            <p className="manrope f-14 grey-77">{material.width} x {material.length} inches</p>
                        </Card>
                    </Tooltip>
                </Col> : 
                ''
            
            ))
            }
            </Row>
        </Row>)
    };

    const RenderedMaterials = (props) => {
        let componentMaterials = props.componentMaterials();
        return (
            <Row style={{marginBottom: 30}}>    
                {props.searchValue == '' ?
                <Col span={24}>
                    <h4 className="manrope f-24 black-14">
                        Previously Selected Materials
                    </h4>
                </Col> : ''}
                <Row>
                {props.data.map((material,index) => (
                ((componentMaterials.length > 0 && componentMaterials[0].data && props.checkIfMaterialExists(componentMaterials[0].data,material)) && ((material.category.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1) || (material.name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1))) ?
                    <Col>
                        <Tooltip title={<span className="manrope f-12 white">Renders already generated for this material.</span>}>
                            <Card className="material-card" style={{border: "1px solid black", cursor: "not-allowed"}}>
                                {material.status == 5 && material.renders.data && material.renders.data.thumbnail ?
                                <img id={'material-'+material.id} src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)} className={material.status == 5 ? 'material-img' : 'material-img blur'}/>
                                // MATERIAL_FILE_URL + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)
                                :<img id={'material-'+material.id} src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)} className={material.status == 5 ? 'material-img' : 'material-img blur'} />
                                }
                                <img className="success-img" src="/img/success.png"/>
            
                                <h5 className="manrope f-18 black-00 j-end clamp-text" style={{marginTop: 8}}>{material.name}</h5>
                                <p className="manrope f-14 grey-77">{material.width} x {material.length} inches</p>
                            </Card>
                        </Tooltip>
                    </Col> : 
                    ''
                ))}
                </Row>
            </Row>)
    }
    
var configurator_3d = null;
var configurator_pattern = null;
const ConfiguratorTool = (props) => {
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [productData, setProductData] = useState('');
    const [materialInfo, setMaterialInfo] = useState([]);
    const [materialData, setMaterialData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [totalSteps, setTotalSteps] = useState(0);
    const [selectedComponent, setSelectedComponent] = useState('');
    const [selectedMaterial, setSelectedMaterial] = useState([]);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [step, setStep] = useState(0);
    const [vVal, setVVal] = useState(0);
    const [hVal, setHVal] = useState(0);
    const [repeat, setRepeat] = useState(1);
    const [toggleHelp, setToggleHelp] = useState(true);
    const [loadingProgress, setLoadingProgress] = useState(true);
    const [restorePrev, setRestorePrev] = useState(false);
    const [componentSave,setComponentSave] = useState([]);
    const [currentMaterial, setCurrentMaterial] = useState('');
    const [patternedScreen, setPatternedScreen] = useState(false);
    const [helpModal, setHelpModal] = useState(true);
    const [isPlain, setIsPlain] = useState(true);
    const [renderSuccess, setRenderSuccess] = useState(false);
    const [patternEligible, setPatternEligible] = useState({});
    const [enableTab, setEnableTab] = useState([]);
    const [clockwise, setClockwise] = useState(0);
    const [antiClockwise, setAntiClockwise] = useState(0);
    const [editAdj, setEditAdj] = useState(false);
    const [requestPatternedRender, setRequestPatternedRender] = useState(false);
    const [toggleInfo, setToggleInfo] = useState(false);
    const [defaultAppliedMaterial, setDefaultAppliedMaterial] = useState('');
    const [patternHelp, setPatternHelp] = useState(false);
    const [adjustmentSuccess, setAdjustmentSuccess] = useState(false);
    const [componentInfo, setComponentInfo] = useState([]);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [noMatSelected, setNoMatSelected] = useState(true);
    const [variationData, setVariationData] = useState([]);
    const [prevRenderMat, setPrevRenderMat] = useState([]);
    const [allAdjusted, setAllAdjusted] = useState(false);

    const { match: { params } } = props;

    useEffect(() => {
        var product_id = 0;
        product_id = params.id;
        axios.post(FETCH_PRODUCT, { product_id })
        .then(res => {
            console.log('Product Data ',res.data);
            setProductData(res.data);
        }).catch(err => {
            console.log(err, ' Error in fetching product');
            message.error('Error in fetching product!')
        });
        
        let component_materials = [];
        axios.post(PRODUCT_COMPONENT_GET_BATCH,{ product_id })
        .then(res => {
            let component_info = JSON.parse(res.data.body);
            
            component_info.map((component, index) => {
                let payload_comp_get = {
                    product_id: product_id,
                    component_id: component.id
                }
                axios.post(PRODUCT_COMPONENT_GET, payload_comp_get).then(res => {
                    let material_info = JSON.parse(res.data.body);
                    console.log('Material Component Info ', material_info)
                    if (material_info.length > 0) {
                        component_materials.push(material_info);
                    }
                    
                    setPrevRenderMat(component_materials);
                    setMaterialVar(component_materials);
                }).catch(err => {
                    console.log(err, ' Error in PRODUCT_COMPONENT_GET.')
                })
            });
            
            console.log('Component Info', component_info);
            console.log('Pre mat', component_materials);
            setComponentInfo(component_info);

            setTotalSteps(component_info.length);
            setSelectedComponent(component_info[step]);

            if (configurator_3d == null) {
                configurator_3d = new Configurator(params.id,'configurator',200,0, true, toggleLoader);
                configurator_3d.selectedComponent(component_info[step].name);
            }
        }).catch(err => {
            message.error('Error in getting components!')
            console.log(err, ' Error in PRODUCT_COMPONENT_GET_BATCH');
        });

        let payload = {
            username: localStorage.getItem('username'),
            status: '5'
        };

        axios.post(MATERIAL_GET_BATCH, payload)
        .then(res => {
            let parsedData = JSON.parse(res.data.body);
            console.log('All Materials ', parsedData);
            setMaterialInfo(parsedData);
            setLoader(false);
            forceUpdate();
            
        }).catch(err => {
            message.error('Error in getting materials!')
            console.log(err, ' Error in MATERIAL_GET_BATCH');
            setLoader(false);
        });

        // setMaterialInfo(materials);

        
    },[]);

    const setMaterialVar = (render_arr) => {
        let temp_arr = [];
        render_arr.map((arr, index) => {
            arr.map(component_detail => {
                temp_arr.push(component_detail);
            })
        })

        let groups = temp_arr.reduce((groups, component) => {
            let id = component.component_id;
            if (!groups[id]) {
              groups[id] = [];
            }
            groups[id].push(component);
            return groups;
        }, {});
          
        // To add it in the array format instead
        let groupArrays = Object.keys(groups).map((id) => {
        return {
            id,
            data: groups[id]
        };
        });
        setVariationData(groupArrays);
    }

    const toggleLoader = () => {
        setLoadingProgress(false);
    }

    const toggleHelpMessage = () => {
        setToggleHelp(!toggleHelp);
    }

    const toggleInformation = () => {
        setToggleInfo(!toggleInfo);
    }

    const cancelAdjustmentSuccess = () => {
        setAdjustmentSuccess(false);
        setRequestPatternedRender(true);  
    }

    useEffect(() => {
        let approvedArray = materialInfo;
        let groups = approvedArray.reduce((groups, material) => {
            let category = material.category;
            if (!groups[category]) {
              groups[category] = [];
            }
            groups[category].push(material);
            return groups;
          }, {});
          
          // To add it in the array format instead
          let groupArrays = Object.keys(groups).map((category) => {
            return {
              category,
              material: groups[category]
            };
          });
          
        if (groupArrays[0]) {
            setDefaultAppliedMaterial(groupArrays[0].material[0].id);
        }

        setMaterialData(groupArrays);
        forceUpdate();
    },[materialInfo]);

    useEffect(() => {
        if (!loadingProgress && materialInfo.length > 0) {
            if (configurator_3d && loadingProgress == false && !patternedScreen) {
                console.log('Default Applied Material-' + defaultAppliedMaterial);
                configurator_3d.selectedMaterialImage('material-' + defaultAppliedMaterial);
            } else if (configurator_pattern && loadingProgress == false && patternedScreen) {
                configurator_pattern.selectedMaterialImage(currentMaterial.id);
            }
        }
    },[loadingProgress,defaultAppliedMaterial,selectedComponent])

    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
    }

    const componentMaterials = (component_info = null) => {
        let component_materials = [];
        if (variationData && variationData.length > 0) {
            component_materials = variationData.filter((x) => { 
                if (component_info == null) {
                    return x.id == (selectedComponent.id);
                }
                else {
                    return x.id == (component_info.id);
                }
             });
        }
       
        return component_materials;
    }

    const prevPatternMaterial = () => {
        let patterned_materials = [];
        if (variationData && variationData.length > 0) {
            variationData.filter((x) => { 
                if (x.id == selectedComponent.id) {
                    x.data.map((component_info) => {
                        console.log(component_info)
                        patterned_materials = materialInfo.filter(mat => {
                            console.log(mat)
                            return ((component_info.material_id == mat.id) && mat.type == "patterned");
                        })
                    })
                }
             });
        }
        console.log(patterned_materials,'Patterned')
        return patterned_materials;
    }

    const goToNextStep = () => {

        let temp = selectedMaterials;
        let temp_material = selectedMaterial;
        let component = componentInfo[step].name;
        temp[component] = temp_material;
        setSelectedMaterials(temp);
        console.log('Selected Material' , temp);       

        let pattern_screen = false;
        let component_w_pattern = patternEligible;
        let first_component_w_ptrn = 0;
         
        let check_if_first = false;
        let first_material = false;
        
        componentInfo.map((comp, index1) => {
            if (index1 <= step) {
                if (temp[comp.name] && temp[comp.name].length > 0) {
                    setNoMatSelected(false);
                } 
            }
            temp[comp.name] && temp[comp.name].map((material, index2) => {
                if (material.type == "patterned") {
                    if (!first_material) {
                        setCurrentMaterial(material);
                        first_material = true;
                    }
                    pattern_screen = true;
                    component_w_pattern[comp.name] = true;
                }
                if (!check_if_first && (material.type == "patterned")) {
                    check_if_first = true;
                    first_component_w_ptrn = index1;
                }
            })
        });

        setPatternEligible(component_w_pattern);
        if ((step + 1 < totalSteps) && selectedMaterials[componentInfo[step + 1].name]) {//Saves already selected materials here
            console.log('Already Selected Materials',selectedMaterials[componentInfo[step + 1].name])
            setSelectedMaterial(selectedMaterials[componentInfo[step + 1].name]);
        } else {
            setSelectedMaterial([]);
        }
        
        if (step + 1 < totalSteps) {
            setStep(step + 1);
            setSelectedComponent(componentInfo[step + 1]);
            configurator_3d.selectedComponent(componentInfo[step + 1].name);
            configurator_3d.highLightSelectedComponent();
            configurator_3d.selectedMaterialImage('material-' + defaultAppliedMaterial);
        } else if (pattern_screen) {
            setPatternedScreen(true);
            setPatternHelp(true);
            setStep(first_component_w_ptrn);
            setSelectedComponent(componentInfo[first_component_w_ptrn]);
            setLoadingProgress(true);
            setTimeout(() => {
                if (configurator_pattern == null) {
                    configurator_pattern = new Configurator(params.id,'configurator-pattern',500, 0, true, toggleLoader);
                    configurator_pattern.selectedComponent(componentInfo[first_component_w_ptrn].name);
                }
            },1000);
        } else {
            setButtonLoader(true);
            let temp_arr = [];
            let data = {};
            let component_materials = [];
            componentInfo.map((comp,index) => {
                if (selectedMaterials && selectedMaterials[comp.name]) {
                    selectedMaterials[comp.name].map((materials,index) => {
                        data = {
                            component_id: comp.id,
                            material_id: materials.id
                        }
                        temp_arr.push(data);
                    });
                }
                
                component_materials = componentMaterials(comp);
                let componentsExist = component_materials && component_materials.length > 0 && component_materials[0].data;
                if (componentsExist) {
                    component_materials[0].data.map(info => {
                        data = {
                            component_id: comp.id,
                            material_id: info.material_id
                        }
                        temp_arr.push(data);
                    });
                }
            });
            
            console.log('Final Material Variations', temp_arr);

            createVariations(temp_arr)
            
        }
        
    }

    const createVariations = (material_arr) => {
        let payload = {
            product_id: params.id,
            customer_username: localStorage.getItem("username"),
            component_materials: material_arr
        };
        axios.post(ADD_MATERIALS_IN_COMPONENT,payload)
        .then(res => {
            console.log('API response for creating variations ', res.data);
            setRenderSuccess(true);
            setButtonLoader(false);
            setAdjustmentSuccess(false);
        })
        .catch(err => {
            message.error('Error in requesting renders!')
            console.log(err, ' Error in CREATE_VARIATION API');
        });
    }

    const prevSegment = () => {
        setStep(step - 1);
        configurator_3d.selectedComponent(componentInfo[step - 1].name);
        configurator_3d.highLightSelectedComponent();
        configurator_3d.selectedMaterialImage('material-' + defaultAppliedMaterial);
        setSelectedMaterial(selectedMaterials[componentInfo[step - 1].name]);
        setSelectedComponent(componentInfo[step - 1]);
    }
    

    const checkIfMaterialExists = (data,material) => {
        let exists = false;
        if (data && data.length > 0) {
            
            data.map((info, index) => {
                if (info.material_id == material.id) {
                    exists = true;
                }
            });
            
        }
        if (exists) {
            return true;
        }
        return false;
    }

    const checkIfAllCategoryVar = (category) => {
        
        let materialArr = materialData.filter((x) => {
            
            return x.category == category;
        });
        let dataLength = materialArr.length;

        let component_materials = componentMaterials();
        
        if (component_materials && component_materials[0] && component_materials[0].data) {
            let count = 0;
            component_materials[0].data.map(info => {
                let categories = materialArr[0].material.filter((x) => {
                    return ((x.id == info.material_id) && (x.category == category));
                });
                
                if (categories.length > 0) {
                    
                    count = count + 1;
                }
            });

            if (count == dataLength) {
                return true;
                
            }
        }

       
        return false;
    }

    const selectMaterial = (material) => {
        let selected_mat = selectedMaterial.filter((x) => { 
            return x.id == (material.id);
         });
        if (selected_mat.length != 0) {
            let new_arr = selectedMaterial.filter((x) => { return x.id != (material.id) });
            setSelectedMaterial(new_arr);
            let flag = true;
            new_arr.map((mat,index) => {
                if (mat.type == "patterned") {
                    flag = false;
                }
            });
            setIsPlain(flag);
        }
        else {
            let temp_arr = selectedMaterial;
            temp_arr.push(material);
            let flag = true;
            temp_arr.map((mat,index) => {
                if (mat.type == "patterned") {
                    flag = false;
                }
            });
            setIsPlain(flag);
            setSelectedMaterial(temp_arr);
        }

        componentInfo.map((comp, index1) => {
            selectedMaterials[comp.name] && selectedMaterials[comp.name].map((material, index2) => {
                if (material.type == "patterned") {
                    setIsPlain(false);
                }
            })
        });
           
        forceUpdate();
    }

    useEffect(() => {
        if (checkIfMaterialSaved(currentMaterial.id) && !restorePrev) {
            setEditAdj(true);
        } else {
            setRestorePrev(false);
        }
    },[vVal,hVal,repeat, clockwise, antiClockwise]);

    const restorePrevSettings = () => {
        if (checkIfMaterialSaved(currentMaterial.id)) {
            
            let prevSettings =  getSettings(currentMaterial.id);
            setVVal(prevSettings[0].settings.vertical);
            setHVal(prevSettings[0].settings.horizontal);
            setClockwise(prevSettings[0].settings.clockwise);
            setAntiClockwise(prevSettings[0].settings.anti_clocwise);
            setRepeat(prevSettings[0].settings.repeat_pattern);
            
            setEditAdj(false);
            setRestorePrev(true);
            forceUpdate();
        }
    }

    const checkIfMaterialSaved = (id) => {
        let savedMaterial = componentSave && componentSave[selectedComponent.name] && componentSave[selectedComponent.name].filter((x) => { return x.material_id == (id)});
        if (savedMaterial && savedMaterial.length != 0) {
            return true;
        }
       return false;
    }

    const getSettings = (id) => {
        let savedMaterial = componentSave && componentSave[selectedComponent.name] && componentSave[selectedComponent.name].filter((x) => { return x.material_id == (id)});
        return savedMaterial;
    }

    const changeTab = (key) => {
        setSelectedComponent(componentInfo[key]);
        let first_material =  selectedMaterials[componentInfo[key].name].filter(x => {return x.type == "patterned"});
        setCurrentMaterial(first_material[0]);
        configurator_pattern.selectedComponent(componentInfo[key].name);
        configurator_pattern.highLightSelectedComponent();
        applyMaterial(first_material[0]);// set first material for component
        forceUpdate();
    }


    const applyMaterial = (material) => {
        if (configurator_pattern != null) {
            configurator_pattern.selectedMaterialImage(material.id);
            setCurrentMaterial(material);
            if (checkIfMaterialSaved(material.id)) {
                let prevSettings =  getSettings(material.id);
                setVVal(prevSettings[0].settings.vertical);
                setHVal(prevSettings[0].settings.horizontal);
                setClockwise(prevSettings[0].settings.clockwise);
                setAntiClockwise(prevSettings[0].settings.anti_clocwise);
                setRepeat(prevSettings[0].settings.repeat_pattern);
                setRestorePrev(true);
            } else {
                setVVal(0);
                setHVal(0);
                setClockwise(0);
                setAntiClockwise(0);
                setRepeat(1);
                setRestorePrev(true);
            }
        }
    }
   
    const onChangeH = (value) => {
        if (isNaN(value)) {
            return;
        }
        setHVal(value);
        if (configurator_pattern != null) {
            configurator_pattern.xAxis(value);
        }
    }

    const onChangeV = (value) => {
        if (isNaN(value)) {
            return;
        }
        setVVal(value);
        if (configurator_pattern != null) {
            configurator_pattern.yAxis(value);
        }
    }

    const onChangeRepeat = (value) => {
        if (isNaN(value)) {
            return;
        }
        setRepeat(value);
        if (configurator_pattern != null) {
            configurator_pattern.repeatPattern(value);
        }
    }

    const moveClockwise = () => {
        if (configurator_pattern != null) {
            configurator_pattern.textureRotation();
            setClockwise(configurator_pattern.getClockwiseValue());
        }
    }

    const moveAntiClockwise = () => {
        if (configurator_pattern != null) {
            configurator_pattern.textureRotationAnti();
            setAntiClockwise(configurator_pattern.getAntiClockwiseValue());
        }
    }

    const saveAdjustmentForMaterial = () => {
        let values = {
            vertical: vVal,
            horizontal: hVal,
            repeat_pattern: repeat,
            clockwise: clockwise,
            anti_clocwise: antiClockwise
        }
        let savedMaterial = checkIfMaterialSaved(currentMaterial.id); 
        let data = {
            material_id: currentMaterial.id,
            component_name: selectedComponent.name,
            settings: values
        }
        if (!savedMaterial) {
            
            let temp_arr = [];

            //add data for new material settings 
            if (componentSave[selectedComponent.name]) {
                temp_arr = componentSave;
                temp_arr[selectedComponent.name].push(data);
            } else {
                temp_arr = componentSave;
                temp_arr[selectedComponent.name] = []
                temp_arr[selectedComponent.name].push(data);
            }
            console.log('New Material Settings Array',temp_arr)
            setComponentSave(temp_arr);

            let patternsSelected = selectedMaterials[selectedComponent.name].filter((x) => {return x.type == "patterned"});//return list of selected patterned materials for component
            let lengthOfPatternedComponents = componentInfo.filter(x => {return patternEligible[x.name]});

            console.log(lengthOfPatternedComponents.length,componentInfo.length)
            //check if length of materials with saved adjustments is equal to the list of selected patterned materials for that component, then enable next tab
            if (temp_arr[selectedComponent.name].length == patternsSelected.length) {
                if (step + 1 < totalSteps && (lengthOfPatternedComponents.length == componentInfo.length)) {
                    let name = componentInfo[step + 1].name;
                    setStep(step + 1);

                    if (patternEligible[name]) {//check if component has patterned materials
                        let temp = enableTab;
                        temp[name] = true;
                        temp[selectedComponent.name] = true;
                        setEnableTab(temp);  //enable next tab (component) if all material settings for this component are saved
                    } else {
                        let flag = false;
                        componentInfo && componentInfo.map((component, index) => {
                            let name = component.name;
                            if (patternEligible[name] && !flag && !enableTab[name]  && name != selectedComponent.name) {
                                let temp = enableTab;
                                temp[name] = true;
                                temp[selectedComponent.name] = true;
                                setEnableTab(temp);
                                flag = true;
                                setStep(index);
                            }
                        });
                    }
                    
                } else if ((lengthOfPatternedComponents.length != componentInfo.length) && !allAdjusted){
                    let flag = false;
                    componentInfo && componentInfo.map((component, index) => {
                        let name = component.name;
                        if (patternEligible[name] && !flag && !enableTab[name] && name != selectedComponent.name) {
                            let temp = enableTab;
                            temp[name] = true;
                            temp[selectedComponent.name] = true;
                            setEnableTab(temp);
                            flag = true;
                            setStep(index);
                        }
                    });
                    if (!flag) {
                        setAllAdjusted(true);
                        if (!requestPatternedRender) {
                            setAdjustmentSuccess(true);
                        }
                    }
                }  else {
                    if (!requestPatternedRender) {
                        setAdjustmentSuccess(true);
                    }
                }
                forceUpdate();
            }
        }
        else if (savedMaterial) {
            setEditAdj(false);
            //set pattern settings for saved material again (set new pattern settings or save old ones again)
            let existing_material  = getSettings(currentMaterial.id);
            let temp_mat = _.cloneDeep(componentSave);
            temp_mat[selectedComponent.name] = [];
            console.log('Current Material',currentMaterial)
            console.log('Existing Material', existing_material)
            componentSave[selectedComponent.name].map((mat,index) => {
                console.log(mat.material_id,existing_material[0].material_id)
                if (mat.material_id != existing_material[0].material_id) {
                    temp_mat[selectedComponent.name].push(mat);
                    console.log('inside here')
                }
            });
            temp_mat[selectedComponent.name].push(data);
            console.log('Saved Material Settings Array', temp_mat);
            setComponentSave(temp_mat);
        }
    
        forceUpdate();
    } 

    const cancelHelpModal = () => {
        setHelpModal(false);
    }

    const requestRender = () => {
        console.log('Final Materials',selectedMaterials);
        setButtonLoader(true);
        let temp_arr = [];
        let data = {};
        let component_materials = [];
        componentInfo.map((comp,index) => {
            if (selectedMaterials && selectedMaterials[comp.name]) {
                selectedMaterials[comp.name].map((materials,index) => {
                    data = {
                        component_id: comp.id,
                        material_id: materials.id
                    }
                    if (materials.type == "pattern" || materials.type == "patterned") {
                        if (componentSave && componentSave[comp.name]) {
                            componentSave[comp.name].map((setting, index) => {
                                if (setting.material_id == materials.id) {
                                    data.pattern_config = {
                                        uOffset : setting.settings.horizontal,
                                        vOffset: setting.settings.vertical,
                                        uRepeat: setting.settings.repeat_pattern,
                                        vRepeat: setting.settings.repeat_pattern,
                                        rotationInRadian: setting.settings.clockwise
                                    }
                                }
                            })
                        }
                    }
                    temp_arr.push(data);
                });
            }
            
            component_materials = componentMaterials(comp);
            let componentsExist = component_materials && component_materials.length > 0 && component_materials[0].data;
            if (componentsExist) {
                component_materials[0].data.map(info => {
                    data = {
                        component_id: comp.id,
                        material_id: info.material_id
                    }
                    temp_arr.push(data);
                });
            }
        });
        
        console.log('Temporary Array', temp_arr);
        createVariations(temp_arr)
    }

    return (
          <CustomerMainLayout selected='10' style={{overflow:"hidden"}}>
            <div style={{cursor: loadingProgress ? "not-allowed" : "default"}}>
                <div style={{pointerEvents: loadingProgress ? "none" : "auto"}}>
                {!toggleHelp && !patternedScreen ?
                <InfoCircleFilled className="info-settings sticky" style={{marginBottom: 8, color: "#A2A2A2"}}  onClick={toggleHelpMessage}/> : ''}

                <Row style={{marginBottom: 8,marginTop: 32}}>
                    <div className="manrope f-18 black-14" >Step {patternedScreen ? '2' : '1'} of 2</div>
                </Row>
                <Row  style={{marginBottom: patternedScreen ? 12: 8}}>
                    {patternedScreen ?
                    <span className="justify-space-between align-start">
                        <Col span={12}>
                            <div className="manrope f-32 black-14">
                                Review your pattern materials for <span className="manrope f-32 blue capitalize">{selectedComponent.name}</span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="note-bg-specs font-18" style={{flexDirection: 'column',alignItems:"flex-start"}}>
                            We have already saved your Plain Materials (if selected) with us. Make any changes to your patterned materials at this step.
                            </div>
                        </Col>
                    </span>:
                    <div className="manrope f-32 black-14">Assign Materials to <span className="manrope f-32 blue capitalize">{selectedComponent.name}</span></div>}
                </Row>
                     
                {!patternedScreen ?   
                <Row style={{marginBottom: 12}}>
                            
                    <div className="manrope f-18 grey-77">Click on a material image to select</div>
                    
                    {/* <div className="manrope f-18 grey-77">Make any changes to your patterned materials if seems necessary</div> */}
                    
                </Row>: ''}

                {patternedScreen ?                 
                    <Row>
                        <Tabs tabBarStyle={{display:'flex',justifyContent:'center'}} centered tabBarGutter={6} onChange={changeTab} defaultActiveKey="0" className="product-tab mat-tab" size="large">
                            {componentInfo && componentInfo.map((component, index) => (
                                patternEligible[component.name] ? 
                                    <TabPane disabled={enableTab[component.name] ? false: true} 
                                    tab={<Tooltip title={enableTab[component.name] ? '' : <span className="manrope f-12 white">Review all materials for current segment first.</span> } placement="bottom">
                                        {component.name}
                                        </Tooltip>} 
                                        key={index} 
                                        style={{padding: 10}}>
                                        <Col span={24}>
                                        </Col>
                                    </TabPane>
                                : ''
                            ))}
                                        
                        </Tabs>
                        
                        <Col span={18} style={{borderRight: "1px solid #e4e4e4",padding: 16}}>
                            <div className="confiugrator-container">
                                <div id="configurator-pattern"></div>
                                {loadingProgress ? <LoadingOutlined className="model-loader large"/> : ''}
                                {toggleInfo ? "" :
                                <InfoCircleFilled className="info-settings" onClick={toggleInformation}/>}
                                {toggleInfo ? <div className="info-settings text manrope f-12" onClick={toggleInformation}>
                                    <InfoCircleFilled style={{marginBottom: 8}}/>
                                    <div>This is low res model, only for you to apply materials and create model variations. You will get high res renders of your variations.</div>
                                </div> : ''}
                            </div>

                            {currentMaterial == '' ? ''
                            :<Row style={{marginTop: 32,marginBottom: 32}}>
                                <Col span={24}>
                                    <div className="manrope f-24 black-14">Pattern Adjustment</div>
                                    <Row className="justify-in-start" gutter={32}>
                                        <Col span={6} style={{marginTop: 12}}>
                                            <div className="manrope f-16 grey-73">Horizontal Movement</div>
                                            <Slider 
                                            className="adjust-pattern" 
                                            min={0}
                                            max={1}
                                            onChange={onChangeH}
                                            value={typeof hVal === 'number' ? hVal : 0}
                                            step={0.01}></Slider>
                                        </Col>
                                    
                                        <Col span={6} style={{marginTop: 12}}>
                                            <div className="manrope f-16 grey-73">Vertical Movement</div>
                                            <Slider className="adjust-pattern" 
                                            min={0}
                                            max={1}
                                            onChange={onChangeV}
                                            value={typeof vVal === 'number' ? vVal : 0}
                                            step={0.01}></Slider>
                                        </Col>
                                    
                                        <Col span={6} style={{marginTop: 12}}>
                                            <div className="manrope f-16 grey-73">Repetition</div>
                                            <Slider className="adjust-pattern" 
                                            min={1}
                                            max={10}
                                            onChange={onChangeRepeat}
                                            value={typeof repeat === 'number' ? repeat : 1}
                                            step={1.50}></Slider>
                                        </Col>

                                        <Col lg={12} xl={6} style={{marginTop: 12}}>
                                            <div className="manrope f-16 grey-73">Rotation</div>
                                            <div style={{display:"flex"}}>
                                                <div className="manrope f-16 black-00" style={{marginRight: 8}}>Counterclockwise 
                                                    <img src="/img/anticlockwise.png" id="rotateTextureAnti" onClick={moveAntiClockwise} style={{width:28,height:28, cursor: "pointer"}}/>
                                                </div>
                                                <div className="manrope f-16 black-00">
                                                    <img src="/img/clockwise.png" id="rotateTexture" onClick={moveClockwise} style={{width:28,height:28, cursor: "pointer"}}/>Clockwise</div>
                                                </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>}
                        {currentMaterial == '' ? '' :
                        <Row>
                                <Col span={24}  className="justify-in-end">
                                    {checkIfMaterialSaved(currentMaterial.id) ?
                                    editAdj ?
                                    <span className="justify-in-end">
                                        <Button className="modal-okay-gray square font-14" onClick={restorePrevSettings} style={{marginRight: 16}}>
                                        Restore Previous Adjustments
                                        </Button> 
                                        <Button className="modal-okay square font-14" onClick={saveAdjustmentForMaterial}>
                                        Save Latest Adjustments
                                        </Button> 
                                    </span> :
                                    <Button className="modal-okay square font-14">
                                    <CheckOutlined/>&nbsp;Adjustments Saved
                                    </Button> 
                                    :
                                    <Button className="modal-okay-invert square font-14" onClick={saveAdjustmentForMaterial}>
                                    Save Adjustments
                                    </Button>}
                                </Col>
                            </Row>}
                        </Col>
                        <Col span={6} style={{padding: 16}} className="vertical-scroll-config pattern-materials">
                            {prevRenderMat && prevRenderMat.length > 0 && prevPatternMaterial().length > 0 ?
                                <div className="justify-space-between">
                                    <div className="manrope f-18 black-14" style={{marginBottom: 16}}>Previously Selected Materials</div>
                                </div>: ''}
                                {prevRenderMat && prevRenderMat.length > 0 && prevPatternMaterial().length > 0 ?
                                <div style={{display:"flex",flexFlow:"wrap",marginBottom: 24}}>
                                    {prevPatternMaterial().map((data,index) => ( 
                                        <Tooltip title={<span className="manrope f-12 white">Renders already generated for this material.</span>}>
                                            <Card className="material-card select" style={{border: "none"}}>
                                                {data.status == 5 && data.renders.data && data.renders.data.thumbnail ?
                                                <img id={data.id} src={ENVIRONMENT.getBaseURL(data.platform) + ENVIRONMENT.MATERIAL_FILE_URI + data.id + '/' +  encodeURIComponent(data.renders.data.thumbnail)} className={'material-img pattern-select'}/>
                                                // MATERIAL_FILE_URL + data.id + '/' +  encodeURIComponent(data.renders.data.thumbnail)
                                                :<img id={data.id} src={ENVIRONMENT.getBaseURL(data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(data.material_files[0].uid + '/' + data.material_files[0].name)} className={'material-img pattern-select'} />
                                                }
                                                <img className="success-img small" src="/img/success.png"/>
                                            </Card>
                                        </Tooltip>
                                    ))}
                                </div>: ''}
                            <div className="justify-space-between">
                                <div className="manrope f-18 black-14" style={{marginBottom: 16}}>Selected Materials</div>
                                {componentSave && componentSave[selectedComponent.name] && selectedMaterials && selectedMaterials[selectedComponent.name] ?
                                 <div className="manrope f-16 grey-77" style={{marginBottom: 16}}>{componentSave[selectedComponent.name].length}/{selectedMaterials[selectedComponent.name].filter(x => {return x.type == "patterned"}).length} Adjusted</div>: ''}
                            </div>
                            <div style={{display:"flex",flexFlow:"wrap"}}>
                                {selectedMaterials && selectedMaterials[selectedComponent.name] && selectedMaterials[selectedComponent.name].map((data,index) => ( 
                                    (data.type == "pattern" || data.type == "patterned") ?
                                    <Card className="material-card select" onClick={() => applyMaterial(data)} style={{border: data.id == currentMaterial.id ? "1px solid black" : "1px solid #f0f0f0"}}>
                                        {data.status == 5 && data.renders.data && data.renders.data.thumbnail ?
                                        <img id={data.id} src={ENVIRONMENT.getBaseURL(data.platform) + ENVIRONMENT.MATERIAL_FILE_URI + data.id + '/' +  encodeURIComponent(data.renders.data.thumbnail)} className={'material-img pattern-select'}/>
                                        // MATERIAL_FILE_URL + data.id + '/' +  encodeURIComponent(data.renders.data.thumbnail)
                                        :<img id={data.id} src={ENVIRONMENT.getBaseURL(data.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(data.material_files[0].uid + '/' + data.material_files[0].name)} className={'material-img pattern-select'} />
                                        }
                                        {checkIfMaterialSaved(data.id) ?
                                            <img className="success-img small" src="/img/success.png"/> : ''}
                                    </Card> : ''
                                ))}
                            </div>
                                                    
                        </Col>
                    </Row>
                : 
                <Row>
                    <Col xxl={5} xl={7} lg={9} style={{borderRight: "1px solid #e4e4e4",paddingRight: 16}}>
                        <div className="small-configurator">
                            <div id="configurator"></div>
                            {loadingProgress ? <LoadingOutlined className="model-loader small"/> : ''}
                            {selectedComponent && selectedMaterial ?
                            <div className="manrope f-18 grey-77" style={{marginTop: 16}}>Selected Materials for {selectedComponent.name}: 
                                <span className="manrope f-18 black-14">{selectedMaterial.length}</span>
                            </div>: ''}
                        </div>
                        
                        {/* {!loadingProgress ? 
                        <div className="loader-config"> 
                            <LoadingOutlined style={{textAlign: "center", fontSize:"14px",marginBottom: 20}} size="large" spin />
                            <h3 className="manrope f-14 w-600 black-33">Setting up your Configurable Product Model</h3> 
                            <h3 className="manrope f-12 grey-73">Please wait a few moments while we set up your product model and {patternedScreen ? "patterned" : ""} materials...</h3>
                        </div>: ""} */}
                        {toggleHelp ? 
                        <div className="black-bg-text sticky">
                            <div className="justify-space-between">
                                <span>
                                    <InfoCircleFilled style={{marginBottom: 8, color: "#A2A2A2"}}/>&nbsp;<span className="manrope title white">Help</span>
                                </span>
                                <span>
                                    <Tooltip title={<span className="manrope f-14 white">Close Help</span>}>
                                        <MinusCircleOutlined style={{marginBottom: 8, color: "#A2A2A2"}} onClick={toggleHelpMessage}/>
                                    </Tooltip>
                                </span>
                            </div>
                            <div className="manrope text white" style={{marginBottom: 20}}>Select Materials for a component of your model by clicking on a material thumbnail.</div>
                            <div className="manrope title white">What is a Component?</div>
                            <div className="manrope text white">A component is a constituting part of your model, specified by you at the time of product creation.</div>
                        </div>: ''
                        }
                    
                        
                    </Col>
                    {materialData.length > 0 && !loader ?
                    <Col xxl={19} xl={17} lg={15} style={{paddingLeft: 32}}>
                        <Row>
                            <Col span={24} style={{marginBottom: 32}}>
                                <Input.Group compact size="large" className="d-inline">
                                    <Input placeholder="Search Materials" onChange={e => {changeSearchValue(e)}} className="manrope f-14 mat-search-bar"></Input>
                                </Input.Group>
                            </Col>
                        </Row> 
                        <div className="material-library-v-scroll">
                            {prevRenderMat && prevRenderMat.length > 0 && materialInfo.length > 0 ?
                                <RenderedMaterials data={materialInfo} checkIfMaterialExists={checkIfMaterialExists} componentMaterials={componentMaterials} searchValue={searchValue}/> : ''
                            }
                            
                            {materialData.map((data,index) => (
                                <MaterialCards checkIfAllCategoryVar={checkIfAllCategoryVar} data={data} checkIfMaterialExists={checkIfMaterialExists} componentMaterials={componentMaterials} searchValue={searchValue} selectMaterial={selectMaterial} selectedMaterial={selectedMaterial}/>
                            ))}
                        </div>
                    </Col> :
                    !loader ?
                    <Col xxl={19} xl={17} lg={15} style={{paddingLeft: 32}}>
                        <Row>
                            <Col span={24} className="center-align-h center-align-v center-align-box">
                               <EmptyBox/>
                                <div className="manrope f-18 black-55 j-center">You have no materials in your material library currently.</div>
                                <div className="manrope f-16 black-55">Please click on 'Create New Material' to get started!</div>

                                <Button className="modal-okay-orange font-16" style={{marginTop: 8}} onClick={() => {
                                        window.location.href = "/material-request"
                                    }}>Create / Upload New Material</Button>
                               
                            </Col>
                        </Row>
                    </Col>: 
                    <DottedLoader/>}
                </Row>}
                
                {patternedScreen ? 
                (requestPatternedRender ?
                <div className="sticky-banner">
                <div className={"justify-in-end"}>   
                    <Button className="modal-okay square font-18" onClick={requestRender}>
                        Request Renders&nbsp;{buttonLoader ? <LoadingOutlined/> : ""}
                    </Button>
                </div>
                </div>   : '')
                :
                materialData.length > 0 ?
                <div className="sticky-banner prev-next-pd">
                    {step + 1 >= totalSteps ? 
                    <div className={step == 0 ? "justify-in-end" : "justify-space-between"}>   
                    {step > 0 ? 
                        <span style={{display:'flex',alignItems:"center"}}>
                            <Button className="modal-okay-gray square font-18" onClick={prevSegment}>
                                Previous
                            </Button>
                            <div className="manrope f-18 grey-77" style={{marginLeft: 48}}>Previous: <span className="manrope f-18 black-14">{componentInfo[step - 1].name}</span></div>
                        </span>
                    : ''}
                    <Tooltip title={((componentMaterials().length == 0 && selectedMaterial.length == 0) || (componentMaterials().length > 0 && noMatSelected && selectedMaterial.length == 0))  ? <span className="manrope f-12 white">Select at least one material to continue</span> : ''} placement="top">
                        <span>
                            <Button className="modal-okay square font-18" onClick={goToNextStep} disabled={((componentMaterials().length == 0 && selectedMaterial.length == 0) || (componentMaterials().length > 0 && noMatSelected  && selectedMaterial.length == 0))}>
                                {isPlain ? "Request Renders" : "Continue to Step 2"}&nbsp;{buttonLoader ? <LoadingOutlined/> : ''}
                            </Button>   
                        </span>
                    </Tooltip>
                    </div>:
                    <div className={step == 0 ? "justify-in-end" : "justify-space-between"}>   
                        {step > 0 ? 
                        <span style={{display:'flex',alignItems:"center"}}>
                            <Button className="modal-okay-gray square font-18" onClick={prevSegment}>
                                Previous
                            </Button>
                            <div className="manrope f-18 grey-77" style={{marginLeft: 48}}>Previous: <span className="manrope f-18 black-14">{componentInfo[step - 1].name}</span></div>
                        </span>
                        : ''}
                        <span style={{display:'flex',alignItems:"center"}}>
                            <div className="manrope f-18 grey-77" style={{marginRight: 48}}>Next: <span className="manrope f-18 black-14">{componentInfo[step + 1].name}</span></div>
                            <Tooltip title={selectedMaterial.length == 0 && (componentMaterials().length == 0) ? <span className="manrope f-12 white">Select at least one material to continue</span> : ''} placement="top">
                                <span>
                                    <Button className="modal-okay-invert square font-18" onClick={goToNextStep} disabled={selectedMaterial.length == 0 && (componentMaterials().length == 0)}>
                                        Continue
                                    </Button>
                                </span>
                            </Tooltip>
                        </span>
                    
                    </div>}
                </div>: ''
                }
                </div>
            </div>
            <Modal className="success-modal-setting"
            closable={false}
            visible={helpModal}
            centered={true}
            footer={[
                <div className="justify-in-center">
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-16" onClick={cancelHelpModal}>
                        Start Configuring
                    </Button>
                </div>
            ]}>
            <div style={{padding:"32px 32px 0 32px",textAlign:"center"}}>
                <div style={{textAlign:'center'}}>
                    <h2 className="manrope f-24 black-14 justify-in-center" >Your Model is Ready for Configuration</h2>
                    <div className="manrope f-16 grey-77" style={{marginTop: 8}}>Your configurable model has been created and is ready for configuration. Follow the steps mentioned below to request your renders. 
                    </div>
                    <Row className="space-between-start" style={{marginTop: 32, marginBottom: 32}}>
                        <Col span={12} style={{paddingRight: 24}}>
                            <Card style={{padding: 16,textAlign:'left'}}>
                                <div className="manrope f-16 black-14" style={{marginBottom: 8}}>
                                    Step 1
                                </div>
                                <div className="manrope f-16 grey-77">
                                Assign materials to different segments of your configurable model. 
                                </div>
                            </Card>
                        </Col>
                       <Col span={12}>
                           <Card  style={{padding: 16,textAlign:'left'}}>
                                <div className="manrope f-16 black-14" style={{marginBottom: 8}}>
                                    Step 2
                                </div>
                                <div className="manrope f-16 grey-77">
                                Review the materials applied to your model and make any adjustments if required. 
                                </div>
                           </Card>
                           <div className="manrope f-14 blue" style={{textAlign:'left',marginTop: 8}}>
                               Step 2 is only required for patterned materials.
                           </div>
                       </Col>
                    </Row>
                </div>
            </div>    
            </Modal>
            <Modal
            className="silo-success-modal"
            centered={true}
            closable={false}
            width={800}
            onCancel={() => setRenderSuccess(false)}
            visible={renderSuccess}
            bodyStyle={{padding: 0}}
        
            footer={[
            <span className="justify-in-center">
                <Button className="modal-okay square font-14" onClick={() => window.location.href="/home"}>
                Okay
                </Button>
            
            </span>
            ]}>
                <div style={{padding:"40px"}}>
                    <div style={{textAlign: "center"}}>
                        <img style={{width: 85,height:85}} src={require("../../../assets/images/success-icon.png")}></img>
                    </div>
                    <div style={{textAlign: "center",margin: "20px auto"}}>
                        <h2 className="manrope f-24 black-14 justify-in-center">Renders Requested Successfully!</h2>
                        <p className="manrope f-16 grey-77">Variations have been requested successfully. You will be notified once they are ready.</p>

                    </div>     
                </div>       
            </Modal>
            <Modal
            className="silo-success-modal"
            centered={true}
            closable={false}
            width={800}
            onCancel={() => setAdjustmentSuccess(false)}
            visible={adjustmentSuccess}
            bodyStyle={{padding: 0}}
        
            footer={[
                <div className="justify-in-center">
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-16" onClick={cancelAdjustmentSuccess}>
                    Back to Adjustments
                    </Button>
                    <Button className="modal-okay square font-16" onClick={requestRender}>
                    Request Renders&nbsp;{buttonLoader ? <LoadingOutlined/> : ''}
                    </Button>
            
                </div>
            ]}>
                <div style={{padding:"40px"}}>
                    <div style={{textAlign: "center"}}>
                        <img style={{width: 85,height:85}} src={require("../../../assets/images/success-icon.png")}></img>
                    </div>
                    <div style={{textAlign: "center",margin: "20px auto"}}>
                        <h2 className="manrope f-24 black-14 justify-in-center">Materials have been adjusted!</h2>
                        <p className="manrope f-16 grey-77">You have adjusted the patterned materials for your product components. Request renders using the button below.</p>

                    </div>     
                </div>       
            </Modal>
            <Modal
            className="silo-success-modal"
            centered={true}
            closable={false}
            width={800}
            onCancel={() => setPatternHelp(false)}
            visible={patternHelp}
            bodyStyle={{padding: 0}}
        
            footer={[
            <span className="justify-in-center">
                <Button className="modal-okay square font-14" onClick={() => setPatternHelp(false)}>
                Okay
                </Button>
            
            </span>
            ]}>
                <div style={{padding:"40px"}}>
                    <div style={{textAlign: "center",margin: "0 auto"}}>
                        <h2 className="manrope f-24 black-14 justify-in-center" style={{marginBottom: 32}}>Step 2: Adjust Materials</h2>
                        <p className="manrope f-16 grey-77">Make any adjustments if seems necessary using the pattern adjustment tool.</p>
                        <p className="manrope f-16 blue">This step needs to be performed for Patterned Materials only. We have your Plain Materials, from the previous step, already saved with us.</p>
                    </div>     
                </div>       
            </Modal>
          </CustomerMainLayout>
          
      );
}

export default ConfiguratorTool;