import React, { useEffect, useState } from "react";
import axios from "axios";
import ENVIRONMENT from "../../../../environments";
import { Row, Modal, Button, Tabs, Col } from "antd";
import * as Utilities from "../../Utilities";
import DottedLoader from "../../DottedLoader/DottedLoader";
import ProductsList from "./ProductsList";

const { TabPane } = Tabs;

const UndoLostAsset = (props) => {
  const {
    undo_product_modal,
    toggleSwapProductModal,
    product_id,
    collection_id,
    UndoPosition,
  } = props;
  const [tabActiveKey, setTabActiveKey] = useState("scene");
  const [selected, setSelected] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [sceneAssets, setSceneAssets] = useState([]);

  const tabs = [
    {
      key: "scene",
      type: "scene",
      title: "Scene Products",
      data: sceneAssets,
      selectable: true,
    },
  ];

  const fetchVariantsData = async () => {
    let payload = {
      collection_id: collection_id,
    };

    await axios
      .post(ENVIRONMENT.FETCH_COLLECTION_PRODUCTS, payload)
      .then((res) => {
        if (res.data) {
          const dataWithKeywords = Utilities.getProductsDataWithKeywords(
            res.data
          );
          setSceneAssets(dataWithKeywords);
        }
      });
  };

  const handleSubmit = async () => {
    UndoPosition(selected);
    handleCancelSwapProductModal();
  };

  const handleCancelSwapProductModal = () => {
    toggleSwapProductModal(false);
    setSelected([]);
  };

  const handleTabChange = (key) => {
    setTabActiveKey(key);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (collection_id && undo_product_modal) {
        setDataLoading(true);
        await fetchVariantsData();
        setDataLoading(false);
      }
    };
    fetchData();
  }, [collection_id, undo_product_modal]);

  return (
    <Modal
      destroyOnClose={true}
      closable={true}
      onCancel={handleCancelSwapProductModal}
      className="swap-modal"
      bodyStyle={{ padding: 0 }}
      footer={null}
      visible={undo_product_modal}
    >
      <Row className="header-container">
        <div className="swap-heading-tag reset-heading-tag">
          <span className="manrope f-14">Reset Product Position</span>
        </div>
      </Row>
      <Row className="justify-space-between swap-container">
        <Col span={16} className="justify-in-start">
          <span className="manrope f-20 w-600">
            Choose one or more product(s) to reset their position
          </span>
        </Col>
      </Row>
      <div className="swap-container">
        <Tabs
          onChange={(key) => handleTabChange(key)}
          className="product-tab library"
          tabBarGutter={16}
          size="large"
          activeKey={tabActiveKey}
        >
          {tabs?.map((tab, index) => {
            return (
              <TabPane
                tab={tab.title}
                key={tab.key}
                style={{ padding: "0px 10px" }}
              >
                {dataLoading ? (
                  <div className="modal-tab">
                    <DottedLoader />
                  </div>
                ) : (
                  <div className="product-tab library">
                    <ProductsList
                      data={tab.data}
                      selectable={tab.selectable}
                      store={tab.store}
                      selected={selected}
                      setSelected={setSelected}
                      setSelectedElementDetials={()=>{}}
                      product_id={product_id}
                      loader={tab.loader}
                      requestStatusProduct={''}
                    />
                  </div>
                )}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
      <Row className="swap-modal-footer justify-in-end" onClick={() => {}}>
        <Button
          style={{ marginRight: 10 }}
          className="outline-red-btn square font-12 manrope"
          onClick={handleCancelSwapProductModal}
        >
          Cancel
        </Button>
        <Button
          className="modal-okay square font-14"
          disabled={selected.length === 0}
          onClick={handleSubmit}
        >
          Reset Position
        </Button>
      </Row>
    </Modal>
  );
};

export default UndoLostAsset;
