import React from 'react';
// import { Form } from '@ant-design/compatible';
import { SearchOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Table, message, Space, Select, Button, Input, Collapse, Row, Col, Tooltip } from 'antd';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import AssignArtist from '../AssignArtist/AssignArtist';
import DeleteEntityModal from '../../DeleteEntityModal';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';
import FileConstants from '../../../../FileConstants';

const Option = { Select };
const { TextArea } = Input;
const { Panel } = Collapse;

const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class JobDeskTableMaterials extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    showHeader,
    footer: false,
    scroll: undefined,
    tableLayout: 'fixed',
    hasData: true,
    dataSource: null,
    unAssignActionClass: 'hide',
    assignActionClass: 'hide',
    selectedMaterial: '-1',
    artists: [],
    filter_customers: [],
    filter_artists: [],
    filter_companies: [],
    filter_categories: [],
    filter_model_types: [],
    assignmentModelVisible: false,
    buttonLoader: false,
    deleteModalVisible: false,
    deletingModelLoader: false,
    deleteSuccessModal: false,
    deleteWarningModal: false
  };

  handleAssign = values => {

      let payload = {
        assigned_artist : values.artist_username,
        material_id: this.state.selectedMaterial.toString(),
        admin_username: localStorage.getItem('username')
      }
      this.setState({
        buttonLoader: true
      });

      axios.post(ENVIRONMENT.UPDATE_MATERIAL, payload)
        .then(res => {
          console.log(res);
          console.log(res.data);
          message.info('Material has been assigned to Specified Artist');
          this.setState({
            assignmentModelVisible: false,
            buttonLoader: false,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500)
          this.handleUnAssignedDataSource();
        })
        
  }

  delete_model = (id) =>{
    console.log('in delete material function')
    this.setState({
      deletingModelLoader: true
    })
    axios.post(
      ENVIRONMENT.DELETE_INCOMPLETE_REQUEST, {
        'entity_type': 'Material',
        'entity_id': id
      }).then(res => {
        console.log(res);
        if (res && res.data.length == 0) {
          this.setState({
            deleteWarningModal: true
          })
        } else {
          this.setState({
            deleteSuccessModal: true
          });
        }
        this.setState({
          deletingModelLoader: false,
          deleteModalVisible: false,
        });
      })
  }

  onCancelDelete = () => {
    this.setState({
      deletingModelLoader: false,
      deleteModalVisible: false
    })
  };

  handleCancel = () => {
    this.setState({
      assignmentModelVisible: false
    });
  }

  showAssignmentModal = (id) => {
    this.setState({
      selectedMaterial: id,
      assignmentModelVisible: true
    });
  }

  getColumns = () => [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ...this.getColumnSearchProps('name'),
      render: (text, record) => <Row><Col span={3}>{record.hidden_from_artist ? <Tooltip  title="Not visible to artists for picking up"><a><img src={"/img/crossed-eye-icon.png"} style={{height: 20, width: 20}}></img></a></Tooltip> : ''}</Col><Col span={18} offset={1}><span>{text}</span></Col></Row>,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id-b.id,
      ...this.getColumnSearchProps('id'),
      render: text => <a target="_blank" href={'/admin_materials/' + text }>{text}</a>,
    },
    {
      title: 'Customer Username',
      dataIndex: 'username',
      key: 'username',
      filters: this.state.filter_customers,
      onFilter: (value, record) => record.username === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: this.state.filter_categories,
      onFilter: (value, record) => record.category === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      key: 'created_on',
      sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      sorter: (a, b) => new Date(a.last_modified) - new Date(b.last_modified),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: this.state.filter_model_type,
      onFilter: (value, record) => record.model_type === value,
     render: text => <span>{text}</span>,
    },
    {
      title: 'Requirement',
      dataIndex: 'need_to_model',
      key: 'need_to_model',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Assigned Artist',
      dataIndex: 'assigned_artist',
      key: 'assigned_artist',
      filters: this.state.filter_artists,
      onFilter: (value, record) => record.assigned_artist === value,
    },
    {
      title: 'Action',
      key: 'action',
      className: this.state.unAssignActionClass,
      render: (text, record) => (
        <span>
          <a onClick={() => this.unAssignRoom(record.id)}>UnAssign</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'assign_action',
      className: this.state.assignActionClass,
      render: (text, record) => (
        <span>
          <a onClick={() => this.showAssignmentModal(record.id)}>Assign to Artist</a>
        </span>
      ),
    },
    this.props.delete_model ? {
      title: 'Action',
      key: 'delete_model',
      className: 'delete-model',
      render: (text, record) => (
        <span>
          <a onClick={() => this.setState({
            selected_id: record.id,
            deleteModalVisible: record.model_status == -4 ? false : true,
            deleteWarningModal: record.model_status == -4 ? true : false
          })}>Delete</a>
        </span>
      ),
    } :{}
  ];


  // Search in table functionality
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  unAssignRoom = (id) => {
    console.log(id);
    axios.post(ENVIRONMENT.UPDATE_MATERIAL, {
      assigned_artist: '',
      material_id: id,
      admin_username: localStorage.getItem('username')
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        message.info('Material Model has been unassigned.');
        this.handleAssignedDataSource("2");
      })
  }

  loadArtistsData = () => {

    axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
    })
      .then(res => {
        console.log(res.data)
        let artist_list = [];
        res.data.map(artist => {
          if (artist.is_active == true || artist.is_active == "True") {
            artist_list.push(artist);
          }
        });
        this.setState({
          artists: artist_list
        });
      });
  }

  componentDidMount = () => {
    if (this.props.state == 'assigned_not_modeled') {
      this.setState({
        unAssignActionClass: ''
      });
      this.handleAssignedDataSource("2");
    }
    if (this.props.state == 'assigned_modeled') {
      this.setState({
        unAssignActionClass: 'hide',
      });
      this.handleAssignedDataSource("3");
    }
    if (this.props.state == 'unassigned') {
      this.handleUnAssignedDataSource();
      this.loadArtistsData();
      this.setState({
        unAssignActionClass: 'hide',
        assignActionClass: '',
      });
      
    }
  }

  populate_filtering_data = (data) => {
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = ['plain', 'patterned'];

    for(let i = 1; i <= (data).length; i++){
      let category_value = data[i - 1]['category'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let customer_value = data[i - 1]['username'];
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let company_value = data[i - 1]['company'];
      if(company_value != "" && !filter_company_values.includes(company_value)){
        filter_company_values.push(company_value);
      }

      let artist_value = data[i - 1]['assigned_artist']
      if(artist_value != "" && !filter_artist_values.includes(artist_value)){
        filter_artist_values.push(artist_value)
      }
    }

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values)
  }

  handleFilteringOptions = (filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values) => {
    
    let filter_categories_objects = [];
    let filter_customer_objects = [];
    let filter_company_objects = [];
    let filter_artist_objects = [];
    let filter_model_type_objects = [];

    filter_categories_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_customer_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_company_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_artist_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_model_type_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });


    filter_categories_values.forEach( filter_categories_value => {
      filter_categories_objects.push({
        text: filter_categories_value,
        value: filter_categories_value,
      })
    });

    filter_customer_values.forEach( filter_customer_value => {
      filter_customer_objects.push({
        text: filter_customer_value,
        value: filter_customer_value,
      })
    });

    filter_company_values.forEach( filter_company_value => {
      filter_company_objects.push({
        text: filter_company_value,
        value: filter_company_value,
      })
    });
    filter_artist_values.forEach( filter_artist_value => {
      filter_artist_objects.push({
        text: filter_artist_value,
        value: filter_artist_value,
      })
    });
    filter_model_type_values.forEach( filter_model_type_value => {
      filter_model_type_objects.push({
        text: filter_model_type_value,
        value: filter_model_type_value,
      })
    });

    this.setState({
      filter_categories: filter_categories_objects,
      filter_customers: filter_customer_objects,
      filter_companies: filter_company_objects,
      filter_artists: filter_artist_objects,
      filter_model_type: filter_model_type_objects
    });

  }

  create_dic = (data, model_status, i) => {
    let res = {}
    res = {
        key: i,
        id: data['id'],
        name: data['name'],
        username: data['username'],
        assigned_artist: data["assigned_artist"],
        category: data['category'],
        type:  data['type'],
        status: data['status'],
        last_modified: data['last_modified'],
        need_to_model: FileConstants.MATERIAL_TYPE_LABELS[data['material_output']],
        created_on: data['created_on'],
        model_status: model_status,
        hidden_from_artist: data['hidden_from_artist']
    }

    return res;
  }



  handleAssignedDataSource = (model_status) => {
    let data = [];
    let filter_customer_values = [];
    let filter_artist_values = [];

    axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, {
        status: model_status,
    })
      .then(res => {
        console.log(res.data)
        let parsedData = JSON.parse(res.data.body);
        console.log(parsedData);
        var data = [];
        for (let i = 1; i <= (parsedData).length; i++) {
          console.log(parsedData);
          if (parsedData[i - 1]["assigned_artist"] != "") {
            data.push(this.create_dic(parsedData[i - 1], model_status, i));
          }
        }
        data.sort((a,b) => {
          return b.id - a.id
        })
        this.setState({
          dataSource: data
        })
        this.populate_filtering_data(data);
      });

  }

  handleUnAssignedDataSource = () => {
    let data = [];

    axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, {
        status: "2",
    })
      .then(res => {
        let parsedData = JSON.parse(res.data.body);
        for (let i = 1; i <= (parsedData).length; i++) {
            if (parsedData[i - 1]["assigned_artist"] == "") {
            data.push(this.create_dic(parsedData[i - 1], 2, i));
          }
        }
        axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, {
          status: "-4",
        })
        .then(res => {
          let parsedData = JSON.parse(res.data.body);
          for (let i = 1; i <= (parsedData).length; i++) {
              if (parsedData[i - 1]["assigned_artist"] == "") {
              data.push(this.create_dic(parsedData[i - 1], -4, i));
            }
          }
          data.sort((a,b) => {
            return b.id - a.id
          })
          this.setState({
            dataSource: data
          })
          this.populate_filtering_data(data);
        })
      });

  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {
          dataSource &&
          <Table {...this.state} columns={this.getColumns()} dataSource={dataSource} />
        }
        <AssignArtist
        entity={'material'}
        handleCancel={this.handleCancel}
        buttonLoader={this.state.buttonLoader}
        handleAssign={this.handleAssign}
        assignmentModelVisible={this.state.assignmentModelVisible}
        artists={this.state.artists}
        />

        <DeleteEntityModal
        onCancel={this.onCancelDelete}
        visible={this.state.deleteModalVisible}
        deletingModelLoader={this.state.deletingModelLoader}
        selected_id={this.state.selected_id}
        delete_model={this.delete_model}
        heading={'You are about to delete a Material'}
        subText={'Once deleted, it cannot be recovered. Are you sure you want to delete it?'}
        />
        <SuccessModal
         visible={this.state.deleteSuccessModal}
         heading={"Material Deleted Successfully!"}
         text={"The material has been deleted successfully."}
         footer={[
            <div className="justify-in-center">
                <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                    window.location.reload();}}>
                    Okay
                </Button>
            </div>
         ]}/>
        <WarningModal
        visible={this.state.deleteWarningModal}
        onCancel={() => this.setState({ deleteWarningModal: false })}
        heading={"Oops! You cannot delete this material."}
        text={"This material cannot be deleted directly since it has assets and information associated with it. Please contact the engineering team to get it removed."}
        footer={[
            <div className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => this.setState({ deleteWarningModal: false })}>
                    Okay
                </Button>
            </div>
        ]}
        />
      </div>
    );
  }
}

export default JobDeskTableMaterials;
