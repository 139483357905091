import React, { useState, useEffect, useRef } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {
    Col,
    Card,
    Checkbox,
    Menu,
    Dropdown,
    Tooltip,
    Row,
    Empty
} from 'antd'
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'
import * as Utilities from '../../Utilities'
import * as Styles from '../../../../Styles'
import '../../../../styles/helper.scss'
import FileConstants from '../../../../FileConstants';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMove } from 'react-sortable-hoc';
import _ from "lodash";
import { reorderData } from "../../../../redux/actions";
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import ENVIRONMENT from '../../../../environments'
import { Grid, AutoSizer } from 'react-virtualized';
import axios from 'axios';
import LibraryGrid from '../LibraryGrid/LibraryGrid';
import AddEntityToProject from '../AddEntityToProject/AddEntityToProject';

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem('username');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');


let get_folder_options = (renameFolderOnclick, unGroupFolderItemsOnclick, ele) => {
    return <Menu>
        <Menu.Item>
            <a className="manrope f-14" onClick={() => renameFolderOnclick(ele)}>Rename Folder</a>
        </Menu.Item>
        <Menu.Item>
            <a className="manrope f-14" onClick={() => unGroupFolderItemsOnclick(ele)}>Ungroup Items</a>
        </Menu.Item>
    </Menu>
};

let menu = (id, entity, selectAssetAndSetBasicInfo) => {
    return <Menu  style= {{ width: "148px", marginLeft:"10px", backgroundColor:'#E6F7FF'}}>
        <Menu.Item className='manrope f-14' key={0} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'silo')}>
            <Tooltip>
                <span className='cursor-pointer' style={{color:'#276DD7'}}>Silo Images</span>
            </Tooltip>
        </Menu.Item>
        <Menu.Item className='manrope f-14' key={1} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'mp4')}>
            <Tooltip>
                <span className='cursor-pointer' style={{color:'#276DD7'}}>MP4 Video</span>
            </Tooltip>
        </Menu.Item>
        <Menu.Item key={2} className='manrope f-14' onClick={() => selectAssetAndSetBasicInfo(id, entity, 'dim')}>
        <span className='cursor-pointer' style={{color:'#276DD7'}}>Dimensional Image</span>
        </Menu.Item>
        <Menu.Item className='manrope f-14' key={3} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'lifestyle')}>
            <Tooltip>
                <span className='cursor-pointer' style={{color:'#276DD7'}}>Lifestyle Images</span>
            </Tooltip>
        </Menu.Item>
        <Menu.Item className='manrope f-14' key={4} onClick={() => selectAssetAndSetBasicInfo(id, entity, '360')}>
            <Tooltip>
                <span className='cursor-pointer' style={{color:'#276DD7'}}>Product 360 Spin</span>
            </Tooltip>
        </Menu.Item>
        <Menu.Item className='manrope f-14' key={5} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'variant')}>
            <Tooltip>
                <span className='cursor-pointer' style={{color:'#276DD7'}}>Product Variant</span>
            </Tooltip>
        </Menu.Item>
    </Menu>
}

const ProductItemLinkList = (props) => {
    const [items, setItems] = useState([]);
    const [orderUpdated, setOrderUpdated] = useState(false);
    const [entityArrangement, setEntityArrangement] = useState(null);
    const [folderArrangement, setFolderArrangement] = useState(null);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [columnCount, setColumnCount] = useState(4);
    const [rowCount, setRowCount] = useState(1);
    const previousProductsLength = useRef(null);
    const [data, setData] = useState([]);
    const maxColumnWidth = 280;
    const rowSize = 430;

    // column widths are stored in a state by index
    const [columnWidths, setColumnWidths] = useState(() => {
        const containerWidth = window.innerWidth - 80; // Get the width of the container element
        const columnWidthPercentage = 25; // Set the desired column width percentage

        const columnWidth = ((containerWidth * columnWidthPercentage) / 100) - 38;
        const initialWidths = new Array(columnCount).fill(columnWidth); // Set initial column widths here
        return initialWidths;
    });

    // function to handle column width changes
    const handleColumnWidthChange = ({ index, width }) => {
        setColumnWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[index] = width;
            return newWidths;
        });
    };


    const getColumnWidth = ({ index }) => columnWidths[index];

    // function to render an individual cell in the grid
    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        // style.top = ((parseInt(style.top, 10) + (16 * (rowIndex)))) + 'px';

        const itemIndex = (rowIndex * columnCount) + columnIndex;
        const ele = data[itemIndex] && data[itemIndex];

        if (!ele) {
            return null;
        }

        return (
        <div key={key} style={{...style, marginLeft: '9px'}}>
        <ProductItems
                selectAssetAndSetBasicInfo={props.selectAssetAndSetBasicInfo}
                product_type={props.product_type}
                searchValue={props.searchValue}
                products={props.products}
                folderState={props.folderState}
                enterFolderState={props.enterFolderState}
                onAddCollectionClick={props.onAddCollectionClick}
                sellOnStore={props.sellOnStore}
                ele={ele} key={itemIndex}
                managed_customer={props.managed_customer}
                link={`${props.link}${ele.id}`}
                selectable={props.selectable}
                selectedProductIds={props.selectedProductIds}
                setSelectedProductIds={props.setSelectedProductIds}
                setCurrentId={props.setCurrentId}
                setCompanyID={props.setCompanyID}
                setWarningModal={props.setWarningModal}
                setCurrentCustomer={props.setCurrentCustomer}
                setDeleteModal={props.setDeleteModal}
                removeItemFromStore={props.removeItemFromStore}
                reorderItems={props.reorderItems}
                setSelectedFolderProducts={props.setSelectedFolderProducts}
                selectedFolderProducts={props.selectedFolderProducts}
                folderArrangement= {props.folderArrangement}
                renameFolderOnclick= {props.renameFolderOnclick}
                unGroupFolderItemsOnclick= {props.unGroupFolderItemsOnclick}
                projects={props.projects}
                />
            </div>
        )
    };

    useEffect(() => {
        if (!props.saveOrderedItems) {
            populateArrangementInfo();
        }
    }, [props.products])

    const populateArrangementInfo = () => {
        const entityArr = _.cloneDeep(props.entityArrangement);
        const folderArr = _.cloneDeep(props.folderArrangement);
        setEntityArrangement(entityArr);
        setFolderArrangement(folderArr);
        props.setHashUpdated(false);
        forceUpdate();
    }

    useEffect(() => {
        if (props.hashUpdated && !props.saveOrderedItems) {
            populateArrangementInfo();
        }
    }, [props.hashUpdated, props.reorderItems])

    useEffect(() => {
        if (props.products) {
            setItems(props.products);
        }

    }, [props.products]);

    //called when wants to save the arrangement
    const updateEntitiesArrangement = () => {
        let new_entity_arrangement = [];
        let new_folder_arrangement = [];
        let arrangement_info = {};
        let folder_arrangement_info = {};
        items.map((item, index) => {
            if (Array.isArray(item)) {
                folder_arrangement_info = {
                    folder_id: parseInt(item[0].folder_id),
                    ordering_number: index,
                    folder_name: item[0].folder_name,
                    sequence_id: props.productTab == "owned" ? props.SEQUENCE_ID_OWNED : props.SEQUENCE_ID_OTHER
                }
                new_folder_arrangement.push(folder_arrangement_info);
                item.map((folder_item, folder_index) => {
                    arrangement_info = {
                        entity_id: parseInt(folder_item.id),
                        ordering_number: folder_index,
                        parent_folder_id: parseInt(folder_item.folder_id),
                        sequence_id: props.productTab == "owned" ? props.SEQUENCE_ID_OWNED : props.SEQUENCE_ID_OTHER
                    }
                    new_entity_arrangement.push(arrangement_info);
                })
            } else {
                arrangement_info = {
                    entity_id: parseInt(item.id),
                    ordering_number: index,
                    parent_folder_id: item.folder_id,
                    sequence_id: props.productTab == "owned" ? props.SEQUENCE_ID_OWNED : props.SEQUENCE_ID_OTHER
                }
                new_entity_arrangement.push(arrangement_info);
            }
        })

        setEntityArrangement(new_entity_arrangement);
        setFolderArrangement(new_folder_arrangement);
    }

    useEffect(() => {
        if (props.saveOrderedItems && (entityArrangement !== null || folderArrangement !== null) &&
            ((props.tabKey && props.tabKey == props.tabActiveKey) || props.tabKey === undefined)) {
            props.setSaveOrderedItems(false);
            props.reorderArrangementData(entityArrangement, folderArrangement);
            props.setOrderUpdated(false);
        }
    }, [props.saveOrderedItems, entityArrangement, folderArrangement])


    useEffect(() => {
        if (items.length > 0 && entityArrangement && !props.saveOrderedItems && orderUpdated) {
            updateEntitiesArrangement();
            setOrderUpdated(false);
        }
    }, [orderUpdated])

    useEffect(()=>{
        // no of rows based on the data length
        let productsData = [];

        // validationbs to apply filters and search
        if (props.searchValue == '' && !props.filtersApplied) {
            productsData = [...props.products];
            if (!props.folderState && productsData.length !== data.length) {
                props.updateTabCount(-1, props.tabKey)
            }
        } 
        else if (props.searchValue != '' || props.filtersApplied) {
            productsData = props.checkFilteredResults(props.products);
            console.log(props.tabChangeThroughClick, props.searchValue, props.filtersApplied)
            if (!props.folderState && productsData.length == 0 && props.filtersApplied && props.searchValue == "") {
                props.switchTabIfEmpty();
            }
            if (!props.folderState  && props.tabChangeThroughClick == true && productsData.length > 0) {
                props.setTabChangeThroughClick(false);
            }
            const newProuctListLength = productsData.length;
            // Call updateTabCount if new length is different or new length is 0 and previous length is not 0
            if(!props.folderState) {
                if (newProuctListLength !== previousProductsLength.current) {
                    props.updateTabCount(newProuctListLength, props.tabKey);
                }
            }
        }
        // Update the ref after the check
        previousProductsLength.current = productsData.length;
        setData(productsData);
        setRowCount(Math.ceil(productsData.length / columnCount));
    }, [props.searchValue, props.filtersApplied, props.products, props.filtersAppliedFlag]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems(arrayMove(items, oldIndex, newIndex));
        setOrderUpdated(true);
        if (oldIndex != newIndex) {
            props.setOrderUpdated(true);
        }
    };

    if (!props.reorderItems) {
        if (props.searchValue == '') {
            return (
                <>
                    {data.length > 0 ?
                    <LibraryGrid
                        setColumnCount = {setColumnCount}
                        setRowCount = {setRowCount}
                        maxColumnWidth = {maxColumnWidth}
                        data = {data}
                        cellRenderer={cellRenderer}
                        columnCount={columnCount}
                        getColumnWidth={getColumnWidth}
                        rowCount={rowCount}
                        getEstimatedRowSize={rowSize}
                        handleColumnWidthChange={handleColumnWidthChange}
                    /> :
                    <div className="no-models-div" style={{ marginTop: "25%"}}>
                        <Empty description={"No Models To Show"} className="manrope f-14 no-models-span" />
                    </div>
                    }
                </>
            );
        }
        else {
            if ((props.product_type == 'your_models' && props.ownedProductLoader) ||
                (props.product_type == 'shared_models' && props.sharedProductLoader)) {
                return <div className="justify-in-center" style={{ width: '100%' }}>
                    <p className="empty-section-text manrope f-16 black-55">
                        Please wait while we load all your products.
                        <LoadingOutlined style={{ fontSize: 24, marginLeft: 14 }} />
                    </p>
                </div>
            } else {
                return (
                    <>
                        {
                            data.length > 0 ?
                            <LibraryGrid
                                setColumnCount = {setColumnCount}
                                setRowCount = {setRowCount}
                                maxColumnWidth = {maxColumnWidth}
                                data = {data}
                                cellRenderer={cellRenderer}
                                columnCount={columnCount}
                                getColumnWidth={getColumnWidth}
                                rowCount={rowCount}
                                getEstimatedRowSize={rowSize}
                                handleColumnWidthChange={handleColumnWidthChange}
                            /> :
                            <div className="no-models-div" style={{ marginTop: "25%"}}>
                                <Empty description={"No Models To Show"} className="manrope f-14 no-models-span" />
                            </div>
                        }
                    </>
                )
            }
        }

    }
    else {
        return items && <SortableList axis={"xy"} props={props} items={items} onSortEnd={onSortEnd}
            getContainer={() => document.getElementById('product-scrollable-' + props.product_type)}
        />
    }
}

const SortableList = SortableContainer(({ items, props }) => {
    return (<Row className="product-tab-content-scrollable draggable-div" id={`product-scrollable-${props.product_type}`} type="flex" style={{ justifyContent: "start" }}>
        {items && items.map((item, index) => (
            props.searchValue == '' ?
                <SortableListItem props={props}
                    axis={'xy'}
                    key={`item-${index}`}
                    useWindowAsScrollContainer={true}
                    index={index} item={item} />
                : (Utilities.isMatching(props.searchValue.toLowerCase(), item.name.toLowerCase())
                    || Utilities.isMatching(props.searchValue.toLowerCase(), item.category.toLowerCase())
                    || Utilities.isMatching(props.searchValue.toLowerCase(), item.brand_id.toLowerCase())
                    || Utilities.isMatching(props.searchValue.toLowerCase(), item.group_id.toLowerCase())
                    || props.searchValue == item.id) ?
                    <SortableListItem props={props}
                        useWindowAsScrollContainer={true}
                        key={index} index={index} item={item} /> : ''
        ))}
    </Row>
    );
});

const SortableListItem = SortableElement(({ item, index, props }) => {
    return <ProductItems
        key={`item-${index}`}
        product_type={props.product_type}
        searchValue={props.searchValue}
        products={props.products}
        folderState={props.folderState}
        enterFolderState={props.enterFolderState}
        onAddCollectionClick={props.onAddCollectionClick}
        sellOnStore={props.sellOnStore}
        ele={item}
        managed_customer={props.managed_customer}
        link={`${props.link}${item.id}`}
        selectable={props.selectable}
        selectedProductIds={props.selectedProductIds}
        setSelectedProductIds={props.setSelectedProductIds}
        setCurrentId={props.setCurrentId}
        setCompanyID={props.setCompanyID}
        setWarningModal={props.setWarningModal}
        setCurrentCustomer={props.setCurrentCustomer}
        setDeleteModal={props.setDeleteModal}
        removeItemFromStore={props.removeItemFromStore}
        reorderItems={props.reorderItems}
        setSelectedFolderProducts={props.setSelectedFolderProducts}
        selectedFolderProducts={props.selectedFolderProducts}
        folderArrangement={props.folderArrangement}
        renameFolderOnclick={props.renameFolderOnclick}
        unGroupFolderItemsOnclick={props.unGroupFolderItemsOnclick}
        projects={props.projects}
    />;
});

const ProductItems = (props) => {

    const { ele } = props;
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [projectModalVisible, setProjectModalVisible] = useState(false)
    const [selectedProduct, setSelectedProductId] = useState(null)

    const deleteProduct = (id, customerUsername, companyID) => {
        props.setCurrentId(id);
        props.setCompanyID(companyID);
        props.setCurrentCustomer(customerUsername);
        props.setDeleteModal(true);

    }

    let get_options = (onAddToCollection, onDelete, id, customerUsername, companyID, managed_customer, sellOnStore, product_data, product_type, removeItemFromStore, addProductToExistingScenes) => {
        return <Menu>
            <Menu.Item>
                <a className="manrope f-14" onClick={() => addProductToExistingScenes(id)}>Add to Scene</a>
            </Menu.Item>
            {(Utilities.companyMatch(companyID, COMPANY_ID) || CUSTOMER_USERNAME == customerUsername) && product_data.is_store_item != 1 ?
                <Menu.Item>
                    <a className="manrope f-14" onClick={() => sellOnStore(product_data)}>Sell on Store</a>
                </Menu.Item> : ''}
            {(Utilities.companyMatch(companyID, COMPANY_ID) || CUSTOMER_USERNAME == customerUsername) && product_data.is_store_item == 1 ?
                <Menu.Item>
                    <a className="manrope f-14" onClick={() => removeItemFromStore(product_data)}>Remove From Store</a>
                </Menu.Item> : ''}
            {managed_customer ? '' :
                <Menu.Item>
                    <a className="manrope f-14" onClick={() => onDelete(id, customerUsername, companyID)}>Delete Product</a>
                </Menu.Item>}
            <Menu.Item>
                <a className="manrope f-14" onClick={() => {setProjectModalVisible(true); setSelectedProductId(id)}}>Add to Project</a>
            </Menu.Item>
        </Menu>
    };

    const onChange = (e) => {
        Utilities.updateEntityInUrl(e.target.productSelected);
        let productIds = props.selectedProductIds;
        if (e.target.checked) {
            productIds.push(e.target.productSelected);
        } else {
            productIds = props.selectedProductIds.filter((ele) => ele != e.target.productSelected)
        }
        props.setSelectedProductIds([...productIds]);

        setTriggerUpdate(!triggerUpdate);
        forceUpdate();
    };

    // select products for folder
    const selectForFolderMove = (e) => {
        Utilities.updateEntityInUrl(e.target.productSelected);
        let productIds = props.selectedFolderProducts;
        if (e.target.checked) {
            productIds.push(e.target.productSelected);
        } else {
            productIds = props.selectedFolderProducts.filter((ele) => ele != e.target.productSelected)
        }
        props.setSelectedFolderProducts([...productIds]);
        setTriggerUpdate(!triggerUpdate);
        forceUpdate();

    };

    const toggleChecked = (product) => {

        if (!isFolder()) {
            if (props.reorderItems) {
                let productIds = props.selectedFolderProducts;
                if (props.selectedFolderProducts.includes(product.id)) {
                    productIds = props.selectedFolderProducts.filter((ele) => ele != product.id)
                } else {
                    productIds.push(product.id);
                }
                props.setSelectedFolderProducts([...productIds]);
            } else if (props.selectable) {
                let productIds = props.selectedProductIds;
                if (props.selectedProductIds.includes(product.id)) {
                    productIds = props.selectedProductIds.filter((ele) => ele != product.id)
                } else {
                    productIds.push(product.id);
                }
                props.setSelectedProductIds(productIds);
            }
            Utilities.updateEntityInUrl(product.id);

            setTriggerUpdate(!triggerUpdate);
            forceUpdate();
        } else {
            Utilities.updateFolderIDInUrl(product[0].folder_id);
        }
    };


    // get folder id of the product selected
    const getFolderID = (element) => {
        if (Array.isArray(element)) {
            return element[0].folder_id;
        }
        return -1;
    }


    const goInsideFolder = (element) => {
        //if product is in a folder, and it's clicked, then enter 
        // inside folder
        if (isFolder()) {
            props.enterFolderState(element)
        }
    }

    const checkIfFolderPresent = () => {
        if (Array.isArray(ele)) {
            return true;
        }
        return false
    }

    const isFolder = () => {
        if (Array.isArray(ele)) {
            return true;
        }
        return false;
    }

    const getName = () => {
        if (Array.isArray(ele)) {
            return ele[0].folder_name;
        }
        return ele.name;
    }

    const getThumbnail = () => {
        if (Array.isArray(ele)) {
            return ele[0].thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(ele[0].platform));
        }
        return ele.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(ele.platform));
    }

    const isFolderItem = () => {
        if (ele.folder_id != "")
            return true;
        return false;
    }

    const getLink = () => {
        return `${props.link + window.location.search}`
    }

    const getID = () => {
        //return folder id if item is a folder, otherwise return
        // item id
        if (isFolder()) {
            return `folder-${ele[0].folder_id}`
        } else {
            return `element-${ele.id}`
        }
    }

    const performAction = (ele) => {
        // perform action when user clicks on a card
        if (props.selectable || props.reorderItems || !isFolder()) {
            toggleChecked(ele);
        } else {
            goInsideFolder(ele);
        }
        if (props.selectable || props.reorderItems || isFolder()) {
            window.location.href = "#";
        } else {
            window.location.href = getLink()
        }

    }

    const addProductToExistingScenes = (product_id) => {
        window.location.href = `/add-product-to-existing-scene?product_id=${product_id}`
    }


    const getAssetImageCount = () => {
        let local_company_id = COMPANY_ID;
        if (IS_MS_PROVIDER) {
            if (MANAGED_COMPANY_ID) {
                local_company_id = MANAGED_COMPANY_ID;
            }
        }
        if (ele.company_id && ele.company_id == local_company_id) {
            if (ele.company_render_count) {
                return ele.company_render_count;
            } else {
                return 0;
            }
        } else {
            if (ele.user_render_count) {
                return ele.user_render_count;
            } else {
                return 0;
            }
        }
            
    }



    return (
        <Col className='draggable-element' lg={props.reorderItems ? 6 : 24} id={getID()} sm={24} md={props.reorderItems ? 8 : 24} key={props.key} style={props.reorderItems ? { display: 'inline-block' } : {}}>
            <Card  bodyStyle={{ padding: "15px" }}
            className={`${isFolder() ? `stacked-card stacked-card-top-right card-margins stack` : `card-margins`} card-shadow-product`}
            bordered={false}
            style={isFolder() ?  
            Styles.folder_border :  
            {  width: '97%', 
            border: props.selectedFolderProducts.includes(ele.id) ? "solid 1px #276DD7" : props.selectedProductIds.includes(ele.id) ? "solid 1px #333333" : "solid 1px #e3e3e3" }}>
                {props.reorderItems ?
                    <span>
                        <div className="group-settings more-icon" >
                            <img src={require("../../../../assets/images/Move.svg")} />
                        </div>
                        {isFolder() ?
                            <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                                <span className={`group-settings-checkbox-reorder-items`}>
                                    <img src={'/img/folder_white_bg.svg'} />
                                </span>
                            </Tooltip> : <span className={`group-settings-checkbox-reorder-items`}>
                                <Checkbox className="blue-checkbox" onChange={selectForFolderMove} productSelected={ele.id} checked={props.selectedFolderProducts.includes(ele.id)} />
                            </span>}
                    </span>
                    : props.selectable ? isFolder() ?
                        <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                            <span className={`group-settings-checkbox-reorder-items`}>
                                <img src={'/img/folder_white_bg.svg'} />
                            </span>
                        </Tooltip> :
                        <span className={`group-settings-checkbox`}>
                            <Checkbox className="black-checkbox" onChange={onChange} productSelected={ele.id} checked={props.selectedProductIds.includes(ele.id)} />
                        </span> :
                        (<>
                            {isFolder() ?
                                <Tooltip title={<span className='manrope f-12 white'>This is a folder.</span>}>
                                    <span className={`group-settings-checkbox-reorder-items`}>
                                        <img src={'/img/folder_white_bg.svg'} />
                                    </span>
                                </Tooltip> : ''}

                            <Dropdown className="group-settings more-icon" overlay={isFolder() ? get_folder_options(props.renameFolderOnclick, props.unGroupFolderItemsOnclick, ele) : get_options(props.onAddCollectionClick, deleteProduct, ele.id, ele.customer_username, ele.company_id, props.managed_customer, props.sellOnStore, ele, props.product_type, props.removeItemFromStore, addProductToExistingScenes)} trigger={['click']}>
                                <MoreIcon />
                            </Dropdown>
                        </>)
                }

                <div className="stacked-card-inner">
                    <Link to={
                        (props.selectable || props.reorderItems || isFolder(ele)) ?
                            "#" :
                            (
                                getLink()
                            )
                    } >
                        <div

                            className="suggested-product" style={{ zIndex: "1", cursor: "pointer" }}
                            onMouseDown={(e) => {
                                // on left click call the performAction function instead of directly navigating to link
                                if ((e.button === 0 && !(e.ctrlKey || e.metaKey)) || isFolder(ele)) {
                                    performAction(ele)
                                }
                            }
                        
                    }
                    onClick={(e) => {
                        if (e.button === 0 && !(e.ctrlKey || e.metaKey))
                        {
                            e.preventDefault();
                        }
                    }}
                    >
                    <div className="box"> 
                       {getThumbnail() ? (                      
                        <img
                        src={getThumbnail()}
                        style={{ objectFit: "contain", width: "100%", height: "275px", zIndex:1 }}/>
                        ) : (
                        <img style={{objectFit: "contain",height:"275px",zIndex:1}} className="product-image" src={require("../../../../assets/images/chair.png")}/>)}
                    </div>
                    {isFolder() ?
                       <span className="justify-in-start manrope f-14 w-500 grey-77 clamp-text w-100" style={{textAlign:'left', margin:'10px 10px 0px 10px'}}>Product Folder</span> 
                    : ''}
                    <div className='justify-in-start' style= {{margin: isFolder() ? "12px 12px 0px 12px" : "12px"}}>
                        <Tooltip title={<span className='manrope f-12 white'>{isFolder()  ? getName() : ele.name}</span>}>
                            {isFolder() ? 
                            <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100">{getName()}</span> :  
                            // <span className="justify-in-start manrope f-14 w-500 grey-6b clamp-text w-100">Variations of &nbsp;<span className="manrope f-14 w-500 black-00">{ele.name}</span></span> : 
                            <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-100">{ele.name}</span>}
                        </Tooltip>
                        {isFolder() ? '' :
                        <Tooltip title={ <span className="manrope f-12 white"> { Utilities.timestampToTimeSince( ele.last_modified_stamp, "tooltip")} </span>}>
                        <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                            <img src="/img/carbon_time.svg" style={{ marginRight: 2 }} /> {Utilities.timestampToTimeSince(ele.last_modified_stamp)}
                        </span>
                        </Tooltip>}
                    </div>
                    
                    <div className="justify-in-start" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"8px",textAlign:"left"}}> 
                        <span className="manrope f-12 grey-77 capitalize" style={{marginRight: 4}}>{isFolder() ? '': 'Category:'}</span>
                        
                        {isFolder() ? '': <Tooltip title={<span className='manrope f-12 white'>{ele.category}</span>}>
                            <span className="manrope f-12 black-33 capitalize clamp-w-width">{ele.category}</span>
                        </Tooltip>}
                    </div>
                    <div className="justify-in-start"  style= {{marginBottom:"8px", marginLeft:"12px",marginRight:"12px",textAlign:"left"}}> 
                    {isFolder() ? '' :
                        <>
                        <span style={{marginRight: 4 }} className='manrope f-12 grey-77 capitalize'>Item&nbsp;ID:</span> 
                        <Tooltip title={<span className='manrope f-12 white'>{ele.brand_id}</span>}>
                            <span className="manrope f-12 black-33 capitalize clamp-w-width">{ele.brand_id || 'N/A'}</span>
                        </Tooltip>
                        </>}
                    </div>  
                    <div className="justify-in-start manrope f-12 grey-77 capitalize"  style= {{marginBottom: !isFolder() && props.folderState ? 4 : 
                        isFolder() ? 18 : 4, marginLeft:"12px",marginRight:"12px",textAlign:"left"}}> 
                        <div style={{marginRight: 4}}>{isFolder() ?  '' : 'Dimensions:'}</div> 
                        {isFolder() ?  '' :
                         (Array.isArray(ele.dimensions)) ? <div className="manrope f-12 black-33">N/A</div> :<div className="manrope f-12 black-33">
                            D {Math.round(ele.dimensions.depth)}" W {Math.round(ele.dimensions.width)}" H {Math.round(ele.dimensions.height)}"</div>}
                    </div>
                </div>
                </Link>
                <div style={{ display:'flex', justifyContent: isFolder() ? 'end' : 'space-evenly', padding:'10px'}}>
                {isFolder() ? '' : <Dropdown overlay={
                    menu(ele.id.toString(), ele, props.selectAssetAndSetBasicInfo)
                } trigger={'click'} placement="topLeft">
                    <span className="justify-in-start generate-asset-btn"> Generate Assets + </span>
                </Dropdown>}
                <div className="justify-in-end"  style= {{ width:'50%' }}
                onMouseDown={(e) => {
                        // on left click call the performAction function instead of directly navigating to link
                        if((e.button === 0 && !(e.ctrlKey || e.metaKey)) || isFolder(ele)){
                            performAction(ele)
                            }
                        }
                    }
                    onClick={(e) => {
                        if (e.button === 0 && !(e.ctrlKey || e.metaKey))
                        {
                            e.preventDefault();
                        }
                    }}
                    > 
                        <Link to={
                            (props.selectable || props.reorderItems || isFolder(ele)) ?
                                "#" :
                                (
                                    getLink()
                                )
                        } >
                        <span className="manrope f-12 black-33 capitalize">
                            {isFolder() ? (ele.length + ' products') : getAssetImageCount() + ' images'}
                        </span>
                        <img src='/img/ArrowRight.png' className="icon-instance-node" style={{marginLeft:'4px', marginRight:'5px'}}/>
                        </Link>
                </div>
                </div>
                </div>
            </Card>

            <AddEntityToProject 
                entityId={selectedProduct}
                entityType='Product'
                modalVisible={projectModalVisible}
                setModalVisible={setProjectModalVisible}
                projects={props.projects}
            />
        </Col>

    )
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    reorderData: (payload) => {
        dispatch(reorderData(payload))
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductItemLinkList)
)
