import React from 'react';
import { Modal,Button, Input, Tooltip } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import FileConstants from '../../../FileConstants';

const AddItemToStore = (props) => {

  return (
    <Modal
    className="sell-on-store"
    maskClosable={true}
    destroyOnClose={true}
    visible={props.visible}
    onCancel={props.onCancel}
    footer={[props.selected_item != null && props.selected_item.is_store_item != 1 &&<div className="justify-in-end">
      <Button className="modal-okay-gray square font-14" onClick={props.onCancel}>
        Cancel
      </Button>
      <Button className="modal-okay square font-14" onClick={props.addToStore}>
      {props.type=='admin' ? "Approve" : "Sell Item"} {props.add_store_item_loader && <LoadingOutlined/>}
      </Button>
    </div>]}>
    {props.selected_item == null ? '' :
      <div>
        <h2 className="manrope f-18 black-55 w-700" style={{marginBottom: 0}}>{props.type=='admin' ? "Add Item to Store" : 
        props.selected_item.is_store_item == -1 ?
         "Price Already Added" : "Sell on Store"}</h2>
        {props.selected_item.is_store_item == -2 ? <p className="manrope f-14 black-55" style={{marginBottom: 20, marginTop: 5}}>You have already added price for this item. Enter new price to update it.</p>: ''}


        <p className="manrope f-14 grey-6b" style={{ marginTop: 20 }}>{props.entity_type=='product' ? "Product" : "Space"} Name: <span className="manrope f-14 black-33">{props.entity_type=='product' ? props.type=='customer_details' ? props.selected_item.product_name : props.selected_item.name : props.selected_item.room_name}</span></p>

        {props.selected_item.category &&
        <>
          <p className="manrope f-14  grey-6b" >Category: <span className="manrope f-14 black-33">{props.selected_item.category}</span></p>
        </>}

        {props.selected_item.licensing_options && props.selected_item.licensing_options.license &&
        <>
          <p className="manrope f-14 grey-6b justify-in-start" >License:&nbsp; 
          <span className="manrope f-14 black-33 capitalize">
          {props.selected_item.licensing_options.license == 'private' ? props.selected_item.licensing_options.license : ''}
          {props.selected_item.licensing_options.license == 'public' ? props.selected_item.licensing_options.public_option == 'forCreativeCommon' ? 
            <Tooltip title={<span className='manrope f-12 white'>{props.selected_item.licensing_options.creative_common}</span>}>
                <img style={{marginLeft: 5}} src={FileConstants.getLicensingIcon(props.selected_item.licensing_options.creative_common)}/>
            </Tooltip>:
            <span>
                <Tooltip title={<span className='manrope f-12 white'>Only For ALL3D Usage</span>}>
                    <img src={FileConstants.getLicensingIcon(props.selected_item.licensing_options.public_option)}/>
                </Tooltip>
                &nbsp;<Tooltip title={<span className='manrope f-12 white'>Not Downloadable</span>}><img src='/img/not_downloadable.png'/></Tooltip>
            </span> 
            : '' }
          </span></p>
        </>}

        <p className="manrope f-14 grey-6b" style={{marginBottom: 5}}>{props.entity_type=='product' ? "Product" : "Space"} Price {props.type=='admin'  ? <span>(Suggested by Customer) </span> : 
        <span><Tooltip title={<span className='manrope f-12 white'>Your licensing type would change to For Sale once your item is added to store.</span>}><InfoCircleOutlined/></Tooltip></span>}</p>
        <Input defaultValue={props.selected_item.price ? parseFloat(props.selected_item.price) : ''} value={props.price ? props.price : ''} className="manrope f-14 grey-6b library-search" onChange={props.onChangePrice} type="number" placeholder="Enter a Price in $"/>

      </div>}

    </Modal>
    
    );
}

export default AddItemToStore;