import React from 'react';
import { Modal, Form, Input, Select, Row, Col, Checkbox, Card, Button, Tooltip } from 'antd';
import { getBaseURL, SCENE_THUMBNAIL_URI } from '../../../../environments/env';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import StyleCategory from '../../FormUtilities/StyleCategorySelect';

const { Option } = Select;
const { Meta } = Card;


const TemplateRequestModal = (props) => {

    return (<Modal
        className='template-modal-setting'
        closable={true}
        visible={props.visible}
        onCancel={props.onCancel}
        footer={[
            <div className='justify-in-end'>
                {(props.admin_page && !props.template_details.admin_removed_on) &&

                    <Button disabled={props.submit_button_loader || props.reject_button_loader || props.getting_details_loader} className='modal-okay-orange square font-14' onClick={props.rejectTemplate}>
                        Reject Request {props.reject_button_loader ? <LoadingOutlined spin /> : ""}
                    </Button>}
                <Button disabled={props.submit_button_loader || props.reject_button_loader || (props.renders && props.renders.length == 0) || props.getting_details_loader} className='modal-okay square font-14' onClick={() => document.getElementById("send-template-request").click()}>
                    Add to Store {props.submit_button_loader ? <LoadingOutlined spin /> : ""}
                </Button>

            </div>
        ]}
        title={<span className='manrope f-14 black-33 w-600'>Add Scene To Store</span>}>
        {props.getting_details_loader ? <div className='justify-in-center'><LoadingOutlined style={{ fontSize: 32 }} /></div> :
            props.renders && props.renders.length > 0 ?
                <>
                    <h2 className='manrope f-14 black-55'>Please note that only the scene and associated collection will be shared on the store. No products and spaces will be added as a part of this scene</h2>
                    <Form
                        initialValues={{
                            name: props.template_details && props.template_details.name,
                            room_name: props.template_details && props.template_details.room_name,
                            style: props.template_details && props.template_details.style,
                            price: props.template_details && props.template_details.price,
                            lifestyle_renders: props.template_details && props.preselected_renders
                        }}
                        onFinish={props.onOk}
                        layout='vertical'>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-500">Scene Name</span>} name="name" rules={[{ required: true, message: 'Please enter scene name.' }]}>
                                    <Input placeholder={'Enter the name of your scene'} className="manrope f-12 black-55 library-search" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-500">Space Category</span>} name="room_name" rules={[{ required: true, message: 'Please select space category.' }]}>
                                    <Select
                                        disabled={props.admin_page}
                                        className={`manrope f-12 black-55 tree-select-material f-12`}
                                        placeholder="Select space category">
                                        {props.space_categories && props.space_categories.map((category, index) => {
                                            return <Option className="manrope f-12 black-55 select-view" value={category}>{category}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-500">Style</span>} name="style" rules={[{ required: true, message: 'Please enter style.' }]}>
                                    <StyleCategory placeholder={'Select style'}  className='tree-select-material f-12'/>
                                    {/* <Input disabled={props.admin_page} placeholder={'Enter style'} className="manrope f-12 black-55 library-search" /> */}
                                </Form.Item>
                            </Col>
                            {props.admin_page &&
                                <Col span={12}>
                                    <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-500">Price</span>} name="price" rules={[{ required: true, message: 'Please enter price.' }]}>
                                        <Input type='number' step={0.1} min={0} placeholder={'Please enter price'} className="manrope f-12 black-55 library-search" />
                                    </Form.Item>
                                </Col>}
                            {props.renders && props.renders.length > 0 &&
                                <Col span={24}>
                                    <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-500">Select Lifestyle Images</span>} name="lifestyle_renders" rules={[{ required: true, message: 'Please select lifestyle images.' }]}>
                                        <Checkbox.Group style={{
                                            width: '95%',
                                            flexWrap: 'wrap'
                                        }} className='justify-in-start'>

                                            {props.renders.map((render, index) => (
                                                <Col span={7} style={{ marginRight: 16, marginBottom: 16 }}>
                                                    <Card cover={
                                                        <Checkbox className='render-checkbox lifestyle-border' value={render['filename']}>
                                                            <img
                                                                className='render-checkbox-img'
                                                                alt={render['filename']}
                                                                src={getBaseURL(props.platform) + SCENE_THUMBNAIL_URI + props.scene_id + '/' + render['filename']}
                                                            />
                                                        </Checkbox>
                                                    }>
                                                        <Meta
                                                            style={{ padding: 16 }}
                                                            title={<span className='manrope f-14 black-55 capitalize'>{render['filename'].split('.')[0]}</span>}
                                                        />
                                                    </Card>
                                                </Col>
                                            ))}

                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>}
                            <Form.Item>
                                <Button id="send-template-request" type="primary" htmlType="submit" disabled={props.submit_button_loader || props.reject_button_loader} className='modal-okay square font-14' style={{ display: 'none' }}>
                                    Add to Store {props.submit_button_loader ? <LoadingOutlined spin /> : ""}
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </> :
                <Col span={24}>
                    <div className='manrope f-14 bg grey'><InfoCircleOutlined />&nbsp;Your scene must have at least one lifestyle image before you can add it on store.</div>
                </Col>
        }
    </Modal>)
}

export default TemplateRequestModal;