import React, {useEffect, useState} from 'react';
import CustomerMainLayout from '../CustomerMainLayout'
import {
    Col,
    Row,
    Card,
    Input,
    Tabs,
    Button,
    Radio,
    Modal,
    message
} from 'antd';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import {listCollections} from "../../../../redux/actions";
import ENVIRONMENT from '../../../../environments'
import {connect} from "react-redux";
import DottedLoader from "../../DottedLoader";
import {withRouter} from 'react-router-dom'
import '../../../../styles/helper.scss'
import CollectionsList from './CollectionList';
import FileConstants from '../../../../FileConstants';
const { TabPane } = Tabs;
const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
const IS_MS_PROVIDER = FileConstants.isMSProvider;

const CustomerCollections = (props) => {

    const [searchValue, setSearchValue] = useState('');
    const [onboarding, showOnboarding] = useState(false);
    const [libraryCollection, setLibraryCollection] = useState([]);
    const [loader, setLoader] = useState(true);
    const [selectedCustomerCollections, setSelectedCustomerCollections] = useState([]);
    const [sharedTabType, setSharedTabType] = useState('all');
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedCollectionId, setSelectedCollectionId] = useState(-1);
    const [selectedCollectionCompany, setSelectedCollectionCompany] = useState(-1);
    const [selectedCollectionUsername, setSelectedCollectionUsername] = useState('');
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [ownedCollectionData, setOwnedCollectionData] = useState([]);
    const [sharedCollectionData, setSharedCollectionData] = useState([]);
    const [ownedOriginalData, setOwnedOriginalData] = useState([]);
    const [sharedOriginalData, setSharedOriginalData] = useState([]);
    const [initialLoadShared, setInitialLoadShared] = useState(true);
    const [initialLoadOwned, setInitialLoadOwned] = useState(true);
    const [sharedCollectionLoader, setSharedCollectionLoader] = useState(true);
    const [ownedCollectionLoader, setOwnedCollectionLoader] = useState(true);
    const [tabCount, setTabCount] = useState({selected_customer: -1, your_models: -1, shared_models: -1});

    const openDeleteModal = (collectionId, collectionUsername, collectionCompanyId) => {
        setDeleteModal(true);
        setSelectedCollectionId(collectionId);
        setSelectedCollectionCompany(collectionCompanyId);
        setSelectedCollectionUsername(collectionUsername);
    }

    const changeSearchValue = (value) => {
        setSearchValue(value);
    }

    const getCollectionPayload = (initial, type) => 
    {
        let payload = {
            "required_fields": ["id", "name", 
            "created_on", "customer_username", "company_id","spaces_count", "products_count", "template_collection_id", 
            "product_thumbnails", "thumbnail_link"],
            "order_by" : "id desc"
          }
        
        let filter_string = "";
        let shared_username = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let customer_username_notexact = "customer_username__notexact='" + CUSTOMER_USERNAME + "'"
        let company_id_notexact = ""
        let company_id = "";
        let is_hidden = "is_hidden__not=true"
        let shared_hidden = "shared_hidden__not=true"
        let template_id = "template_collection_id__not=NULL"

        if (type == 'shared') {
            shared_username = "shared_username__exact='" + CUSTOMER_USERNAME + "'"
            filter_string = "("+shared_username + "&&" + shared_hidden + ")"
      
            if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("collection")) {
              company_id_notexact = "company_id__notexact=" + COMPANY_ID + "||company_id__isnull=true"
              filter_string = filter_string + "&&(" + company_id_notexact + ")&&("+customer_username_notexact+")"
            } else {
              filter_string = filter_string + "&&("+customer_username_notexact+")"
            }
            filter_string = filter_string + "||(" + template_id  + "&&" + customer_username + "&&" + is_hidden + ")"
          } else if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("collection")) {
            company_id = "company_id__exact=" + COMPANY_ID
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")&&(template_collection_id__is=null)"
          } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")&&(template_collection_id__is=null)"
          }

          if(IS_MS_PROVIDER)
          {
            filter_string = filter_string + "&&(designed_for__is=null)"
          }
         
          payload['filter_string'] = filter_string
          if (initial) {
            payload['pagination_filters'] = {
              'limit': 100,
              'offset': 0
            }
          }
          return payload;
    }

    const getMSPCollectionPayload = () =>
    {
        let payload = {
            "required_fields": ["id", "name", "customer_username","company_id", 
            "created_on", "spaces_count", "products_count", 
            "product_thumbnails", "thumbnail_link"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let designed_for = "designed_for__exact='" + MANAGED_CUSTOMER_USERNAME + "'"
        let is_hidden = "is_hidden__not=true"
        
        if (MANAGED_COMPANY_ID) {
            company_id = "company_id__exact=" + parseInt(MANAGED_COMPANY_ID)
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden + ")&&(" + designed_for + ")"   
          } else {
            filter_string = "("+customer_username+ "&&" + is_hidden + ")&&(" + designed_for + ")" 
          }
         
          payload['filter_string'] = filter_string
      
          return payload;
    }

    const getMSPCustomerData = () => {
        if (FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
          let payload = getMSPCollectionPayload();
          axios.post(ENVIRONMENT.LIST_COLLECTION, payload)
          .then(res => {
            if (res.data) {
              let owned_data = res.data;
              setSelectedCustomerCollections(owned_data)
            }
          });
        }
      }

      const fetchCollectionData = (type, initial = false) => {
        if (!initial) {
            setLoader(true);
          } else {
            if (type == 'shared') {
              setInitialLoadShared(true);
            } else {
              setInitialLoadOwned(true);
            }
          }
          let payload = getCollectionPayload(initial, type);
          axios.post(ENVIRONMENT.LIST_COLLECTION, payload)
          .then(res => {
            if (res.data) {
                // setting data
                if (type == 'shared') {
                  setSharedOriginalData(res.data);
                } else {
                  setOwnedOriginalData(res.data);
                }
        
                // setting loaders
                if (initial) {
                  if (type == 'shared') {
                    setInitialLoadShared(false);
                  } else {
                    setInitialLoadOwned(false);
                  }
                } else {
                  if (type == 'shared') {
                    setSharedCollectionLoader(false);
                  } else {
                    setOwnedCollectionLoader(false);
                  }
                }
                setLoader(false);
              }
          });
      }
    
      const triggerRoomFetchAPI = () => {
        fetchCollectionData('owned', true);  
        fetchCollectionData('shared', true);
      }

    useEffect(() => {
        if(IS_MS_PROVIDER)
        {
            getMSPCustomerData();
        }
        triggerRoomFetchAPI();
    }, []);

    useEffect(() => {
        if (!initialLoadOwned && ownedCollectionLoader) {
          fetchCollectionData('owned');
        }
      }, [initialLoadOwned]);
    
      useEffect(() => {
        if (!initialLoadShared && sharedCollectionLoader) {
          fetchCollectionData('shared');
        }
      }, [initialLoadShared]);

    useEffect(() => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        if(onboarding != undefined){
            if (typeof(onboarding == 'string') || onboarding instanceof String) {
                onboarding = (onboarding.toLowerCase() === 'true');
            }

            if (onboarding == false) {
                showOnboarding(false);
            }
            else {
                showOnboarding(true);
            }
           
        }
    },[]);

    useEffect(() => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        if(onboarding != undefined){
            if (typeof(onboarding == 'string') || onboarding instanceof String)
            {
                onboarding = (onboarding.toLowerCase() === 'true');
            }
            if (onboarding == false) {
                showOnboarding(false);
            }
            else{  
               showOnboarding(true);
            }
        }
    },[window.location]);

  
    const onChangeSharedTabType =(e) => { 
        setSharedTabType(e.target.value)
    }

    const addCollectionRedirect = () => {
        console.log(props.location);
        if(onboarding){
            props.history.push({
                pathname: '/add_collection',
                search: `${props.location.search}&scene_state=added`
            });
        }
        else{
            window.location.href="/add_collection?origin=/list-collections";
        }
    }

    const checkIfCompanyExisits = (company_id) => {
        if (company_id != "" && company_id != null) {
            return true;
          } 
        return false;
      }

    const getCollections = (type) => {
        if (type == 'selected_customer') {
          return selectedCustomerCollections;
        } 
        else if (type == 'your_models') {
            return ownedOriginalData
        } 
        else if (type == 'shared_models') {
            let collection_data = sharedOriginalData
            if(sharedTabType == 'all'){
                return collection_data;
            }
            else if(sharedTabType == 'shared'){
                return collection_data.filter((collection) => collection.template_collection_id == "");
            }
            else{
                return collection_data.filter((collection) => collection.template_collection_id != "");
            }
        }
    }

    const getCollectionCount = () => {
        return getCollections('selected_customer').length + getCollections('your_models').length + getCollections('shared_models').length
    }

    const onDelete = () => {
        console.log('perform delete')
        setDeleteLoader(true);
        console.log(selectedCollectionId);
        console.log(selectedCollectionUsername);
        console.log(selectedCollectionCompany);
        let api_end_point = ENVIRONMENT.UPDATE_COLLECTION;
        let username = selectedCollectionUsername;
        let currentCustomer = localStorage.getItem('username');
        
        let payload = {
            "collection_id": selectedCollectionId,
            "is_hidden": true,
            "edit_flag": true,
            "username": selectedCollectionUsername

        };
        
        let company_id = selectedCollectionCompany;

        let isCollectionShared = ((username !=  currentCustomer) && (company_id != COMPANY_ID));

        if (isCollectionShared) {
            //case of customer deleting the product added from store or shared by some other customer
            api_end_point = ENVIRONMENT.DELETE_SHARED_ENTITY;
            payload = {
                "collection_id": selectedCollectionId,
                "username": currentCustomer,
            };
        }

        console.log(api_end_point);
        axios.post(api_end_point, payload)
        .then((response) => {
            if (isCollectionShared === false) {
                //in some edge cases collection is owned but is also shared 
                payload = {
                    "collection_id": selectedCollectionId,
                    "username": currentCustomer,
                };
                axios.post(ENVIRONMENT.DELETE_SHARED_ENTITY, payload)
                .then(() => {
                    message.info('Collection Deleted.');
                    setDeleteLoader(false);
                    setDeleteModal(false);
                    window.location.href = '/list-collections';
                });
            }
            else
            {
                message.info('Collection Deleted.');
                setDeleteLoader(false);
                setDeleteModal(false);
                window.location.href = '/list-collections';
            }
            
            
        })
        .catch((error) => {
            console.log(error);
            message.error('Error in deleting collection');
            setDeleteLoader(false);
            setDeleteModal(false);
        });
    }

    console.log(libraryCollection, 'Library Collection')

    const updateTabCount = (count, tabName) => {
      setTabCount(prevTabCount => ({
        ...prevTabCount,
        [tabName]: count
      }));
    };

    const getCollectionTabCount = (type) => {
      return tabCount[type] !== -1 ? tabCount[type] : getCollections(type).length;
    };

    return (
      <CustomerMainLayout selected='13'>
      {loader && ownedCollectionLoader && sharedCollectionLoader ?  <DottedLoader/> :
          <>
            <Row className="justify-space-between"  style={{marginTop:20,marginBottom: 20}}>            
                <Col> 
                    <div className='manrope f-24 w-700' style={{display:'flex',alignItems:"flex-end"}}><span>Collection Library</span>{libraryCollection ? getCollectionCount()>0 ? <span className="manrope f-20"  style={{marginLeft: 4,paddingBottom: 2}}>({getCollectionCount()} items)</span>: '': ''}</div>

                </Col>
                {getCollectionCount() > 0 ? 
                <Col  lg={10} xl={10}>
                    <Input.Group compact size="large" className="d-inline">
                        <Input placeholder="Search All Collections" onChange={e => {changeSearchValue(e.target.value)}} className="manrope f-14 mat-search-bar"></Input>
                    </Input.Group>
                </Col>
                : 
                ''
                }
            </Row>
            <div className="text-center" >  
                <Col span={24}>
                    {getCollectionCount() >0 ?  
                    <Tabs className="product-tab library" tabBarGutter={16} defaultActiveKey={(FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) ? "selected_customer": getCollections('your_models').length > 0 ? "your_models" : "shared_models"}  size="large">
                        {FileConstants.isMSProvider && MANAGED_CUSTOMER_USERNAME != null ?
                        <TabPane forceRender={true} tab={`Designed For ` + MANAGED_CUSTOMER_USERNAME + ` (${getCollectionTabCount('selected_customer')})`} key="selected_customer" style={{padding: "10px"}}>
                                <>
                                <CollectionsList
                                    loader={loader}
                                    libraryCollection={getCollections("selected_customer")}
                                    searchValue={searchValue}
                                    openDeleteModal={openDeleteModal}
                                    addCollectionRedirect={addCollectionRedirect}
                                    collection_type = {"selected_customer"}
                                    updateTabCount={updateTabCount}
                                />
                                </>
                        </TabPane>: ''}
                        
                        <TabPane forceRender={true} tab={"Your Collections " + `(${getCollectionTabCount('your_models')})`} key="your_models"  style={{padding: "10px"}}>
                        {
                        getCollections('your_models').length > 0 ?
                            <>
                                <CollectionsList
                                    loader={loader}
                                    libraryCollection={getCollections("your_models")}
                                    searchValue={searchValue}
                                    openDeleteModal={openDeleteModal}
                                    addCollectionRedirect={addCollectionRedirect}
                                    collection_type = {"your_models"}
                                    updateTabCount={updateTabCount}
                                />
                            </>
                            :
                        <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                          <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Collections.svg"/>
                          <p className="empty-section-text manrope f-14 black-55">
                            Nothing to show
                          </p>
                          <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                            There are no collections of your own. To get started create a new collection.
                          </p>
                          <Button onClick={addCollectionRedirect}  className="outline-red-btn square font-14  pd-10-18" >Create New <PlusOutlined/></Button>

                        </div>
                        }
                        </TabPane>
                        
                        
                        <TabPane forceRender={true} tab={"Other Collections " + `(${getCollectionTabCount("shared_models")})`} key="shared_models"  style={{padding: "10px"}}>
                        <Row>
                            <div className="shared-models-tab manrope f-14 black-55">
                                <span className="shared-models-tab-span">
                                    Show Items:
                                </span>
                                <Radio.Group onChange={onChangeSharedTabType} value={sharedTabType}>
                                    <Radio value = 'all'>
                                    All
                                    </Radio>
                                    <Radio value = 'store'>
                                    Added from Store
                                    </Radio>
                                    <Radio value = 'shared'>
                                    Shared
                                    </Radio>
                                </Radio.Group>
                            </div>
                        </Row>
                        {getCollections('shared_models').length > 0 ?
                            <>
                                <CollectionsList
                                    loader={loader}
                                    libraryCollection={getCollections("shared_models")}
                                    searchValue={searchValue}
                                    openDeleteModal={openDeleteModal}
                                    collection_type = {"shared_models"}
                                    updateTabCount={updateTabCount}
                                />
                            </>
                            :
                            <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
                            <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Collections.svg"/>
                            <p className="empty-section-text manrope f-14 black-55">
                            Nothing to show
                            </p>
                            <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                            There are no collections that have been shared with you.
                            </p>
    
                            </div>
                            }
                        </TabPane>
                        
                    </Tabs>
                    :
                    <div className="empty-section" style={{marginTop:"10%", position:"relative" ,textAlign:'-webkit-center'}}>
                        <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Collections.svg"/>
                        <p className="empty-section-text manrope f-14 black-55">
                            Nothing to show
                        </p>
                        <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
                        There are no collections. To get started create a new collection.
                        </p>
                        <Button onClick={addCollectionRedirect}  className="outline-red-btn square font-14  pd-10-18" >Create New <PlusOutlined/></Button>
                    </div>
                    }
                </Col>
            </div>
          </>
          }
            <Modal
            className="modal-share-success"
            width={600}
            centered={true}
            visible={deleteModal}
            closable={false}
            footer={[
                <span className="justify-in-center">
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-12" onClick={() => setDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-12" onClick={() => onDelete()}>
                        {deleteLoader? <span> Deleting <LoadingOutlined spin /></span>:<span>Yes, Delete Collection </span>}
                    </Button>
                </span>
            ]}> 
                <div style={{margin:"30px auto 0px auto",textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <h2 className="manrope f-16 black-00 w-600">You are about to delete this collection</h2>
                    {selectedCollectionCompany == COMPANY_ID ?
                    // another user from the company is deleting a product so display specific warning message 
                    <p className="manrope f-14 black-55">This collection will be deleted for all the users in your company. Are you sure you want to proceed?</p>
                    :
                    <p className="manrope f-14 black-55">Are you sure you want to delete this collection? </p>
                    }
                </div>           
            </Modal>
        </CustomerMainLayout>
    );
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    listCollections: (payload) => {
        dispatch(listCollections(payload));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(CustomerCollections)
)
