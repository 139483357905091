import React from "react";
import "antd/dist/antd.css";
import { EyeOutlined, LinkOutlined, SearchOutlined, StarOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Input, Card } from "antd";
import ItemsTable from '../../ItemsTable'

const { TabPane } = Tabs;
const { Meta } = Card;

class ProductsData extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <div>
        <Input
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Search Products"
            onChange={this.changeUsername}
        />
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false}>
          <TabPane tab="Guidelines" key="1">
            <ItemsTable state="guidelines"/>
          </TabPane>
          <TabPane tab="Models Pending" key="2">
            <ItemsTable state="customer_pending"/>
          </TabPane>
          <TabPane tab="QA" key="3">
            <ItemsTable state="qa"/>
          </TabPane>
          <TabPane tab="Approved" key="4">
          <Card
                style={{ width: 300 }}
                cover={
                <img
                    alt="example"
                    src="http://storage.3dconfigurator.net.s3.amazonaws.com/staging_bbby_google_ar/bbby_automation_backend/items/thumbnails/thumbnail_3342554_44934552.jpg"
                />
                }
                actions={[
                <StarOutlined key="star" />,
                <LinkOutlined key="link" />,
                <EyeOutlined key="eye" />
                ]}
            >
                <Meta
                title="561020"
                description="Varick 5-Piece Microsuede Sectional Sofa with Ottoman in Silver"
                />
            </Card>
            
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ProductsData;