import React from 'react';
import { Input, Col, Row, Select, Form, Tooltip, Drawer } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { useState } from 'react';

const { Option } = Select;

const Filters = (props) => {
  const {
    filters,
    handleValueChange,
    selectedValue,
    setSearchValue,
    title,
    searchPlaceholder,
  } = props;
  const [isFilterModal, setIsFilterModal] = useState(false);

  const handleFilterModalClose = () => {
    setIsFilterModal(false);
  };

  const handleFilterOpen = () => {
    setIsFilterModal(true);
  };

  return (
    <>
      <Row className="filters-bar">
        <Col span={10}>
          <span className="heading">{title}</span>
        </Col>
        <Col span={14}>
          <Row justify="end">
            <div className="search-bar-width w-40 mr-8">
              <Form>
                <Input.Group compact size="large" className="d-inline">
                  {
                    <Input
                      placeholder={searchPlaceholder}
                      onChange={(e) => setSearchValue(e.target.value)}
                      className="manrope f-14 mat-search-bar"
                    />
                  }
                </Input.Group>
              </Form>
            </div>
            <div>
              <Tooltip
                title={
                  <span className="manrope f-12 white">Apply Filters</span>
                }
              >
                <div
                  className="icon-button-container"
                  onClick={handleFilterOpen}
                >
                  <FilterOutlined />
                </div>
              </Tooltip>
            </div>
          </Row>
        </Col>
      </Row>
      <Drawer
        width={360}
        visible={isFilterModal}
        onClose={handleFilterModalClose}
        placement="right"
      >
        <div style={{ padding: 20 }}>
          <h2 className="manrope f-18 black-14 w-700">Filters</h2>
          <div>
            <h5 className="manrope f-16 black-14" style={{ marginTop: 20 }}>
              Categories
            </h5>
            <Select
              className={`tree-select-material f-14 filter bg-light`}
              showSearch={true}
              value={selectedValue}
              onChange={handleValueChange}
              placeholder="Search and select category"
              mode="multiple"
            >
              {filters &&
                filters.map((category, index) => {
                  return (
                    <Option
                      key={category}
                      className="manrope f-12 select-view"
                      value={category}
                    >
                      {category}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Filters;
