import React from "react";
import Pdf from "react-to-pdf";
import ENVIRONMENT from '../../../../environments'
import axios from 'axios';

const ref = React.createRef();

class ArtistMonthlyInvoice extends React.Component {
  state = {
    artist: this.props.match.params.artist,
    month: this.props.match.params.month,
    modeling_time: '',
    hourly_rate: '',
    products_count: ''
  }

  componentDidMount() {
    axios.post(ENVIRONMENT.ARTIST_HOURS_BATCH, {
      artist: this.state.artist,
      month: this.state.month
    })
      .then(res => {
        console.log(res.data)
        this.setState({
          modeling_time: res.data[0]['modeling_time'],
          hourly_rate: res.data[0]['hourly_rate'],
          products_count: res.data[0]['products_count']
        });
      });
  }

  render() {
    return (
      <div>
        <Pdf targetRef={ref} filename="invoice.pdf">
          {({ toPdf }) => <button onClick={toPdf}>Export Pdf</button>}
        </Pdf>
        <div ref={ref}>
          <h2>Artist Name: {this.state.artist}</h2>
          <h2>Month: {this.state.month}</h2>
          <h2>Modeling Time: {this.state.modeling_time}</h2>
          <h2>Hourly Rate: {this.state.hourly_rate}</h2>
          <h2>Products Count: {this.state.products_count}</h2>
        </div>
      </div>
    );
  }
}

export default ArtistMonthlyInvoice;
