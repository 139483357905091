import React, { useContext, useState } from 'react';
import ENVIRONMENT from '../../../../environments';
import { Button, Dropdown, Space, Card, Tooltip, Checkbox } from 'antd';
import './ProductGrid.scss'
import _ from "lodash" // Import the entire lodash library
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Grid, AutoSizer } from 'react-virtualized';
import AddProductsToSceneContext from '../../ContextFiles/AddProductsToSceneContext';
import { useEffect } from 'react';


const SpaceAreaSelection = (props) => {

    const toggleAreaSelection = (area) => {
        // Clone the products array from props
        let products = _.cloneDeep(props.productsAddedToScene);

        // Find the product with the matching product_id
        const productIndex = products.findIndex(product => product.product_id === props.id);

        if (productIndex !== -1) {
            const product = products[productIndex];
            const areas = product.areas;

            // Check if the area exists in the areas array
            const areaIndex = areas.indexOf(area);

            if (areaIndex !== -1) {
                // If the area exists, remove it
                areas.splice(areaIndex, 1);

                // If all areas are removed, delete the product entry
                if (areas.length === 0) {
                    products.splice(productIndex, 1);
                }
            } else {
                // If the area doesn't exist, add it
                areas.push(area);
            }
        } else {
            // If the product_id doesn't exist, create a new entry
            products.push({ product_id: props.id, area: area, placement_type: props.product.placement_type, 
                platform: props.product.platform, category: props.product.category, material_type: props.product.material_type});
        }

        props.setProductsAddedToScene(products);
    };


    const checkIfAreaSelected = (area) => {
        const productIndex = props.productsAddedToScene.findIndex(product => product.product_id == props.id && product.areas.includes(area));

        if (productIndex !== -1) {
            return true;
        }
        return false;
    }


    return (<Dropdown overlay={<div className='comment-option-dropdown'>
        {props.space_areas.map((area, index) => (
            <Space key={index}
                className='area-pd-space justify-space-between'>
                <p className='manrope f-14 lh-28 mb-0'>{area}</p>
                <Button ghost className={`item-add-btn justify-in-center manrope f-14 lh-28 ${checkIfAreaSelected(area) ? `red` : `blue`}`} onClick={() => toggleAreaSelection(area)}>
                    {checkIfAreaSelected(area) ? 'Remove' : 'Add'}
                </Button>
            </Space>
        ))}
    </div>}
        trigger={['click']}>
        <PlusOutlined
            title="Add To Scene"
            className="item-icons plus"
        />
    </Dropdown>);
}

const ProductGrid = () => {
    const { searchValue, displayedData, space_areas, collection_products, deletedProducts, libraryType, collection_id,
        productsAddedToScene, setProductsAddedToScene, filtersApplied,
        returnSearchValFunction, productsForLibrary, setProductsForLibrary, appliedFiltersList,
        productsRestoredToScene, setProductsRestoredToScene, requestStatusProduct } = useContext(AddProductsToSceneContext);

    const [, updateState] = React.useState();
    const [productsData, setProductsData] = useState([])
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [columnCount, setColumnCount] = useState(4);
    const [rowCount, setRowCount] = useState(0);
    const maxColWidth = 280;

    useEffect(() => {
        let data = getDataForProduct()
        setProductsData(data)
    }, [appliedFiltersList, searchValue, displayedData])

    const handleAddAction = (product) => {
        if(productsAddedToScene.find(ele => ele.product_id === product.id)){
            setProductsAddedToScene(productsAddedToScene.filter((ele) => ele.product_id !== product.id));
        }
        else
        {
            let products = _.cloneDeep(productsAddedToScene);
            products.push({ product_id: product.id, placement_type: product.placement_type, 
                platform: product.platform, category: product.category, material_type: product.material_type});
            setProductsAddedToScene(products);
        }
    }

    const handleRestoreAction = (product) => {
        if(productsRestoredToScene.find(ele => ele.id === product.id)){
            setProductsRestoredToScene(productsRestoredToScene.filter((ele) => ele.id !== product.id));
        }
        else{
            let products = _.cloneDeep(productsRestoredToScene);
            products.push(product);
            setProductsRestoredToScene(products);
        }
    }

    const checkIfProductExists = (product) => {
        const productIndex = productsAddedToScene.findIndex(ele => ele.product_id == product.id);
        if (productIndex !== -1) {
            return true;
        }
        return false;
    }

    const checkIfItemNotFreeOrAddedToLibrary = (product) => {
        return libraryType == "store" && (product.item_type == 'not_shared') && (product.price != 0 || product.price != '');
    }


    const checkIfItemInLibrary = (product) => {
        return product.item_type != "shared";
    }

    const getDataForProduct = () => {
        let product_data = displayedData;
        if (libraryType == "store") {
            product_data = product_data.filter(product => checkIfItemInLibrary(product));
        }
        if (searchValue == '' && !filtersApplied) {
            product_data = product_data;
        } else {
            if (searchValue != '' || filtersApplied) {
                if (searchValue != '' && !filtersApplied) {
                    product_data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, product_data)
                } else if (searchValue == '' && filtersApplied) {
                    product_data = product_data.filter((entity) => { return returnSearchValFunction(entity) == true })
                    product_data = Utilities.getRelevanceBasedFilteredResults(product_data, appliedFiltersList)
                } else if (searchValue != '' && filtersApplied) {
                    let filter_data = product_data.filter((entity) => { return returnSearchValFunction(entity) == true })
                    product_data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, filter_data);
                    product_data = Utilities.getRelevanceBasedFilteredResults(product_data, appliedFiltersList)
                }
            }
        }
        return product_data;
    }

    // no of rows based on the data length
    useEffect(() => {   
        setRowCount(Math.ceil(productsData.length / columnCount));
    }, [productsData])

    // column widths are stored in a state by index
    const [columnWidths, setColumnWidths] = useState(() => {
        const containerWidth = window.innerWidth - 96; // Get the width of the container element
        const columnWidthPercentage = 25; // Set the desired column width percentage

        const columnWidth = ((containerWidth * columnWidthPercentage) / 95) - 24;
        const initialWidths = new Array(columnCount).fill(columnWidth); // Set initial column widths here
        return initialWidths;
    });

    // function to handle column width changes
    const handleColumnWidthChange = ({ index, width }) => {
        setColumnWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[index] = width;
            return newWidths;
        });
    };

    const addProductToLibrary = (e) => {
        let product_id = e.target.product_id;
        let already_added_products = _.cloneDeep(productsForLibrary);
        if (productsForLibrary.includes(product_id)) {
            already_added_products = productsForLibrary.filter((ele) => ele != product_id);
            setProductsForLibrary(already_added_products);
        } else {
            already_added_products.push(product_id);
            setProductsForLibrary(already_added_products);
        }

    }

    const getColumnWidth = ({ index }) => columnWidths[index];

    // function to render an individual cell in the grid
    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const itemIndex = rowIndex * columnCount + columnIndex;
        const product = productsData[itemIndex] && productsData[itemIndex];

        if (!product) {
            return null;
        }


        return (
            <div key={key} style={style}>
                <Card className={`card-shadow-product mr-16 mb-16 ${(!(requestStatusProduct == 'always_allowed' || requestStatusProduct == 'allowed') && checkIfItemNotFreeOrAddedToLibrary(product)) || collection_products.includes(product.id.toString()) ? `product-hover` : ``}`}
                    bordered={false}>
                    {!(requestStatusProduct == 'always_allowed' || requestStatusProduct == 'allowed') && checkIfItemNotFreeOrAddedToLibrary(product) ?
                        <a href='/store' target='_blank'>
                            <Tooltip title={<span className='manrope f-12 white'>Please go to store to purchase this item</span>}>
                                <img src='/img/redirect-icon.svg' className="item-icons plus" />
                            </Tooltip>
                        </a>
                        : 
                        deletedProducts.includes(product.id.toString()) ? 
                            productsRestoredToScene.find( restoredProduct => restoredProduct.id == product.id) ?
                                <CheckOutlined title="Added To Scene"
                                    className="item-icons tick" 
                                    onClick={() => handleRestoreAction(product)}/>
                            :
                                <img src='/img/Restore.svg' className="item-icons plus" onClick={() => handleRestoreAction(product)}/>
                        :
                        collection_products.includes(product.id.toString()) ?
                        <Tooltip title={<span className='manrope f-12 white'>Please go to scene creator to delete this product</span>}>
                            <CheckOutlined title="Added To Scene"
                                className="item-icons tick" />
                        </Tooltip>
                        : 
                        (checkIfProductExists(product)) || collection_products.includes(product.id.toString()) ?
                            <CheckOutlined title="Added To Scene"
                                className="item-icons tick" 
                                onClick={() => handleAddAction(product)} />
                            :
                            <span>
                                {(space_areas.length == 0) ?
                                    <PlusOutlined
                                        title="Add To Scene"
                                        className="item-icons plus"
                                        onClick={() => handleAddAction(product)}
                                    /> :
                                    <SpaceAreaSelection
                                        productsAddedToScene={productsAddedToScene}
                                        setProductsAddedToScene={setProductsAddedToScene}
                                        libraryType={libraryType}
                                        product={product}
                                        collection_id={collection_id}
                                        space_areas={space_areas}
                                        id={product.id} />
                                }
                            </span>
                    }
                    <a className="suggested-product z-1">
                        <div className="box">
                            <img key={product.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(product.platform)) || require("../../../../assets/images/chair.png")}
                                className="product-image z- product-img-specs"
                                src={product.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(product.platform)) || require("../../../../assets/images/chair.png")} />
                        </div>

                        <hr className='m-0' />

                        <div className='justify-in-start mt-12 mr-12 mb-8 ml-12'>
                            <Tooltip title={<span className='manrope f-12 white'>{product.name}</span>}>
                                <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-50">{product.name}</span>
                            </Tooltip>
                            <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(product.last_modified_stamp, 'tooltip')}</span>}>
                                <span className="manrope f-12 grey-77 w-500 time-clock-bg ml-4">
                                    <img src='/img/carbon_time.svg' className='mr-2' />{Utilities.timestampToTimeSince(product.last_modified_stamp)}
                                </span>
                            </Tooltip>
                        </div>
                        <div className={`justify-in-start ml-12 ${libraryType == "store" && (!(requestStatusProduct == 'always_allowed' || requestStatusProduct == 'allowed') && checkIfItemNotFreeOrAddedToLibrary(product)) ? `mb-49` : `mb-12`} text-left`}>
                            <span className="manrope f-12 grey-77 capitalize mr-4">Category:</span>
                            <span className="manrope f-12 black-33 capitalize">{product.category}</span>
                        </div>
                        {libraryType == "store" && ((requestStatusProduct == 'always_allowed' || requestStatusProduct == 'allowed') || !checkIfItemNotFreeOrAddedToLibrary(product)) &&
                            <div className="justify-in-start ml-12 mb-12 text-left">
                                <Checkbox disabled={(!checkIfProductExists(product)) && !collection_products.includes(product.id)} className="manrope f-12 grey-77"
                                    product_id={product.id}
                                    defaultChecked={productsForLibrary.includes(product.id)}
                                    onChange={e => addProductToLibrary(e)} >Add product to library as well</Checkbox>
                            </div>}
                    </a>
                </Card>
            </div>
        );
    };

    return (
        <>
            <AutoSizer>
                {({ height, width }) => {
                    const colCount =
                    (Math.floor(width / maxColWidth) > 4 ?
                      4 :
                      Math.floor(width / maxColWidth)) || 1;
                    setColumnCount(colCount);
                    setRowCount(Math.ceil(productsData.length / colCount));
                    const colWidth = width / colCount;
                    return (
                        <Grid
                            className='product-tab-content-scrollable'
                            cellRenderer={cellRenderer}
                            columnCount={columnCount}
                            columnWidth={colWidth}
                            height={height}
                            rowCount={rowCount}
                            estimatedRowSize={95}
                            rowHeight={libraryType == "store" ? 315 : 291}
                            width={width + 10}
                            onColumnResized={handleColumnWidthChange}
                        />)
                }}
            </AutoSizer>
        </>
    );

}

export default ProductGrid;
