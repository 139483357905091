import React from 'react';
import { Table, Divider, message, Input, Space, Button, Col, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as SmallEmptyBox } from '../../../icons/small-empty-box.svg'
import axios from 'axios';
import ENVIRONMENT from '../../../environments'
import DottedLoader from '../DottedLoader/DottedLoader'
import AddItemToStore from '../AddItemToStore';

const title = () => 'Here is title';
const showHeader = true;
const footer = () => 'Here is footer';
const scroll = { y: 240 };
const pagination = { position: 'bottom' };

class RoomsTable extends React.Component {
  state = {
    bordered: false,
    loading: false,
    pagination,
    size: 'default',
    title: undefined,
    showHeader,
    footer: false,
    scroll: undefined,
    tableLayout: 'fixed',
    hasData: true,
    dataSource: null,
    customerColumn: 'hide',
    companyColumn: 'hide',
    subscriptionColumn:'hide',
    artistAction: 'hide',
    jobDeskAction: 'hide',
    adminQAAction: 'hide',
    customerColumn: 'hide',
    adminViewAction: 'hide',
    removeFromStoreAction: 'hide',
    addToStoreAction: 'hide',
    filter_categories: [],
    filter_customers: [],
    filter_companies: [],
    filter_artists: [],
    filter_model_type: [],
    loader: true,
    status: 2,
    add_store_item: false,
    add_store_item_loader: false,
    addItemToStoreAction: 'hide',
    selected_item: '',
    price: 0
  };

  getColumns = () => [
    {
      title: 'Space Name',
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'ALL3D ID',
      dataIndex: 'id',
      key: 'id',
      ...this.getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Item ID',
      dataIndex: 'brand_id',
      key: 'brand_id',
      ...this.getColumnSearchProps('brand_id'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      filters: this.state.filter_customers,
      onFilter: (value, record) => record.customer === value,
      defaultFilteredValue: this.getAppliedFilterValue('customer'),
      className: this.state.customerColumn,
      render: text => <span>{text}</span>,

    },
    {
      title: 'Date Created',
      dataIndex: 'created_on',
      key: 'created_on',
      sorter: (a, b) => a.key - b.key,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      className: this.state.companyColumn,
      filters: this.state.filter_companies,
      onFilter: (value, record) => record.company === value,
      defaultFilteredValue: this.getAppliedFilterValue('company'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription_name',
      className: this.state.subscriptionColumn,
      key: 'subscription_name',
      render: text => <span>{text}</span>,
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      sorter: (a, b) => a.key - b.key,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Artist',
      dataIndex: 'assigned_artist',
      key: 'assigned_artist',
      className: this.state.customerColumn,
      filters: this.state.filter_artists,
      onFilter: (value, record) => record.assigned_artist === value,
      defaultFilteredValue: this.getAppliedFilterValue('assigned_artist'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Model Type',
      dataIndex: 'model_type',
      key: 'model_type',
      filters: this.state.filter_model_type,
      onFilter: (value, record) => record.model_type === value,
      defaultFilteredValue: this.getAppliedFilterValue('model_type'),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: this.state.filter_categories,
      onFilter: (value, record) => record.category === value,
      defaultFilteredValue: this.getAppliedFilterValue('category'),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      className: this.state.jobDeskAction,
      render: (text, record) => (
        <span>
          <a onClick={() => window.open('../room_guidelines/' + record.room_id, 'Space Guidelines', "height=600,width=1000")}>View Details</a>
          <Divider type="vertical" />
          <a onClick={() => this.assignRoomToArtist(record.room_id)}>Assign to Self</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'artist_action',
      dataIndex: 'action',
      className: this.state.artistAction,
      render: (text, record) => (
        <span>
          <a href={'../artist_rooms/' + record.room_id}>{(this.state.status == 2) ? "Upload" : (this.state.status == -4) ? 'View and Resubmit' : (this.state.status == 6 || this.state.status == 11) ? 'View' : "View and Submit"}</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'admin_qa_action',
      dataIndex: 'action',
      className: this.state.adminQAAction,
      render: (text, record) => (
        <span>
          <a href={'../admin_rooms/' + record.room_id}>QA</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'admin_view_action',
      dataIndex: 'action',
      className: this.state.adminViewAction,
      render: (text, record) => (
        <span>
          <a href={'../admin_rooms/' + record.room_id}>View</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'in_store',
      dataIndex: 'action',
      className: this.state.removeFromStoreAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.removeRoomFromStore(record.room_id)}>Remove from Store</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'not_in_store',
      dataIndex: 'action',
      className: this.state.addToStoreAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.addRoomToStore(record.room_id)}>Add to Store</a>
        </span>
      ),
    },
    {
      title: 'Action',
      key: 'add_in_store',
      dataIndex: 'action',
      className: this.state.addItemToStoreAction,
      render: (text, record) => (
        <span>
          <a onClick={() => this.addItemInStore(record)}>Add Item to Store</a>
        </span>
      ),
    }
  ];

  componentDidMount = () => {
    this.handleDataSource();
  }

  onChangePrice = (e) => {
    console.log(e.target.value)
    this.setState({
      price: e.target.value
    });
  }

  addRoomToStore = (room_id) => {
    const hide = message.loading('Adding space to store..', 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);
    axios.post(ENVIRONMENT.UPDATE_ROOM, {
      is_store_item: 1,
      room_id: room_id.toString(),
    })
      .then(res => {
        this.handleNotInStoreData(1);
        console.log(res);
        console.log(res.data);
        const success = message.success('Space has been added to store successfully!');
        setTimeout(success, 2500);
        window.location.reload();
      })
  }

  setFilterQueryParams = (filters) => {
    const url = new URL(window.location);

    for (const [key, value] of Object.entries(filters)) {
      console.log(key, value);
      if(value){
        url.searchParams.set(key, value)
      }
      else if(url.searchParams.has(key)){
          url.searchParams.delete(key);
        }
    }
    console.log('url = ', url)
    window.history.replaceState({}, '', url)
  }

  getAppliedFilterValue = (filter) => {
    // return already applied filter value or null
    const url = new URL(window.location);
    let value = null;
    value = url.searchParams.get(filter)
    if(value)
      value = value.split(',')
    
    // console.log('filtering = ', filter,'returning = ', value)
    return value
  }
  addItemInStore = (item) => {
    console.log(item)
  
    this.setState({
      add_store_item: true,
      selected_item: item,
      price: item.price,
    });
  }

  cancelStoreItem = () => {
    this.setState({
      add_store_item: false,
      add_store_item_loader: false
    })
  }

  addToStore = () => {
    this.setState({
      add_store_item_loader: true
    });
    let payload = {
      is_store_item: 1,
      room_id: this.state.selected_item.room_id.toString(),
      price: this.state.price,
      licensing_options: {
        "license": "public",
        "public_option": "forSale"
      }
    }
    console.log(payload)
    axios.post(ENVIRONMENT.UPDATE_ROOM, payload)
      .then(res => {
        console.log(res);
        this.setItemPriceAsApproved();
        
      })
  }

  setItemPriceAsApproved = () => {
    let store_price_payload = {
      customer_username: this.state.selected_item.customer,
      entity_id: this.state.selected_item.room_id.toString(),
      entity_type: 'room',
      action: 'approve',
      proposed_price: this.state.price,
      brand_id: this.state.selected_item.brand_id
    }
    axios.post(ENVIRONMENT.SET_STORE_PRICE, store_price_payload)
    .then(res => {
      this.setState({
        add_store_item: false,
        add_store_item_loader: false
      });
      message.success('Space has been added to store successfully!');
      window.location.reload();
    });
  }

  // Search in table functionality
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          defaultValue={this.getDefaultSearchValue(dataIndex, setSelectedKeys, selectedKeys)}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    defaultFilteredValue: this.getAppliedFilterValue(dataIndex),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  getDefaultSearchValue = (dataIndex, setSelectedKeys, selectedKeys) => {
    let filteredValue = this.getAppliedFilterValue(dataIndex);
    if(!selectedKeys)
      setSelectedKeys(filteredValue)
    return filteredValue
  }

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  removeRoomFromStore = (room_id) => {
    const hide = message.loading('Removing space from store..', 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);
    axios.post(ENVIRONMENT.UPDATE_ROOM, {
      is_store_item: 0,
      room_id: room_id.toString(),
    })
      .then(res => {
        
        this.handleStoreData(1);
        console.log(res);
        console.log(res.data);
        const success = message.success('Space has been removed from store successfully!');
        setTimeout(success, 2500);
        window.location.reload();

      })
  }

  handleDataSource = () => {
    this.setState({
      loader: true
    })
    if (this.props.state === 'pending') {
      this.handleAssignedData();
    }

    if (this.props.state === 'job_desk') {
      this.handleJobDeskData();
    }

    if (this.props.state === 'artist_review') {
      this.handleArtistReviewData();
    }

    if (this.props.state === 'admin_qa') {
      this.handleAdminQAAction();
    }

    if (this.props.state === 'admin_view') {
      this.handleAdminViewAction();
    }

    if (this.props.state == 'being_fixed') {
      this.handleBeingFixedAction();
    }

    if (this.props.state == 'artist_rejected') {
      this.handleArtistRejectedData();
    }

    if(this.props.state == 'artist_failed_aborted') {
      this.handleArtistFailedAbortedData();
    }

    if (this.props.state === 'failed_aborted') {
      this.handleFailedAbortedAction();
    }

    if (this.props.state == 'store') {
      this.handleStoreData(0);
    }

    if (this.props.state == 'store_eligible') {
      this.handleNotInStoreData(0);
    }

    if (this.props.state == 'requested_by_customers') {
      this.handleStoreRequests();
    }

    if (this.props.state == 'being_processed') {
      this.handleBeingProcessedsAction();
    }

    if (this.props.state == 'admin_review') {
      this.handleAdminReviewAction();
    }

    if (this.props.state == 'artist_review_for_admin') {
      this.handleArtistReviewDataForAdmin();
    }

    if (this.props.state == 'enqueued') {
      this.handleEnqueuedViewAction();
    }
  }


  handleFilteringOptions = (filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values) => {
    
    let filter_categories_objects = [];
    let filter_customer_objects = [];
    let filter_company_objects = [];
    let filter_artist_objects = [];
    let filter_model_type_objects = [];

    console.log('========>', filter_model_type_values)

    filter_categories_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_customer_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_company_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_artist_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });
    filter_model_type_values.sort(function(a , b){
      return (a.toLowerCase() > b.toLowerCase()) ? 1:-1
    });


    filter_categories_values.forEach( filter_categories_value => {
      filter_categories_objects.push({
        text: filter_categories_value,
        value: filter_categories_value,
      })
    });

    filter_customer_values.forEach( filter_customer_value => {
      filter_customer_objects.push({
        text: filter_customer_value,
        value: filter_customer_value,
      })
    });

    filter_company_values.forEach( filter_company_value => {
      filter_company_objects.push({
        text: filter_company_value,
        value: filter_company_value,
      })
    });
    filter_artist_values.forEach( filter_artist_value => {
      filter_artist_objects.push({
        text: filter_artist_value,
        value: filter_artist_value,
      })
    });
    filter_model_type_values.forEach( filter_model_type_value => {
      filter_model_type_objects.push({
        text: filter_model_type_value,
        value: filter_model_type_value,
      })
    });

    this.setState({
      filter_categories: filter_categories_objects,
      filter_customers: filter_customer_objects,
      filter_companies: filter_company_objects,
      filter_artists: filter_artist_objects,
      filter_model_type: filter_model_type_objects
    });

  }

  getStoreItems = (response_data) => {
    let data = [];
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = [];
    for (let i = 1; i <= (response_data).length; i++) {
      if (this.props.customer_username_filter == undefined || this.props.customer_username_filter == "" || this.props.customer_username_filter == response_data[i - 1]['customer_username'])
      {
        data.push({
          key: i,
          room_id: response_data[i - 1]['room_id'],
          name: response_data[i - 1]['room_name'],
          id: response_data[i - 1]['room_id'],
          category: response_data[i - 1]['category'],
          model_type: response_data[i - 1]['variant_of'] ? 'Variation' : 'Simple',
          customer: response_data[i - 1]['customer_username'],
          company: response_data[i - 1]['company_display_name'],
          assigned_artist: response_data[i - 1]['assigned_artist'],
          created_on: response_data[i - 1]['created_on'],
          last_modified: response_data[i - 1]['last_modified'],
          description: '',
          brand_id: response_data[i - 1]['brand_id'],
          price: response_data[i - 1]['price'],
          subscription_name : ''
        });
      }

      let category_value = response_data[i - 1]['category'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let customer_value = response_data[i - 1]['customer_username'];
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let company_value = response_data[i - 1]['company_name'];
      if(company_value != "" && !filter_company_values.includes(company_value)){
        filter_company_values.push(company_value);
      }

      let artist_value = response_data[i - 1]['assigned_artist']
      if(artist_value != "" && !filter_artist_values.includes(artist_value)){
        filter_artist_values.push(artist_value)
      }

      let model_type_value = response_data[i - 1]['variant_of'] ? 'Variation' : 'Simple'
      console.log('model type value = ', model_type_value)
      if(model_type_value != '' && !filter_model_type_values.includes(model_type_value)){
        filter_model_type_values.push(model_type_value)
      }
    }

    if (this.props.setCount != undefined){
      this.props.setCount(data.length);
    }


    this.setState({
      dataSource: data,
      loader: false
    })

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values);
  }

  getNotInStoreItems = (response_data) => {
    let data = [];
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = [];
    for (let i = 1; i <= (response_data).length; i++) {
      if (this.props.customer_username_filter == undefined || this.props.customer_username_filter == "" || this.props.customer_username_filter == response_data[i - 1]['customer_username'])
      {
        data.push({
          key: i,
          room_id: response_data[i - 1]['room_id'],
          name: response_data[i - 1]['room_name'],
          id: response_data[i - 1]['room_id'],
          category: response_data[i - 1]['category'],
          model_type: '',
          customer: response_data[i - 1]['customer_username'],
          company: '',
          assigned_artist: '',
          created_on: '',
          last_modified: '',
          description: '',
          brand_id: '',
          price: '',
          subscription_name : ''
        });
      }

      let category_value = response_data[i - 1]['category'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let customer_value = response_data[i - 1]['customer_username'];
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let company_value = response_data[i - 1]['company_name'];
      if(company_value != "" && !filter_company_values.includes(company_value)){
        filter_company_values.push(company_value);
      }

      let artist_value = response_data[i - 1]['assigned_artist']
      if(artist_value != "" && !filter_artist_values.includes(artist_value)){
        filter_artist_values.push(artist_value)
      }

      let model_type_value = response_data[i - 1]['variant_of'] ? 'Variation' : 'Simple'
      console.log('model type value = ', model_type_value)
      if(model_type_value != '' && !filter_model_type_values.includes(model_type_value)){
        filter_model_type_values.push(model_type_value)
      }
    }

    if (this.props.setCount != undefined){
      this.props.setCount(data.length);
    }


    this.setState({
      dataSource: data,
      loader: false
    })

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values);
  }

  getTableDataFromReponse = (response_data) => {
    let data = [];
    let filter_categories_values = [];
    let filter_customer_values = [];
    let filter_company_values = [];
    let filter_artist_values = [];
    let filter_model_type_values = [];
    for (let i = 1; i <= (response_data).length; i++) {
      if (this.props.customer_username_filter == undefined || this.props.customer_username_filter == "" || this.props.customer_username_filter == response_data[i - 1]['customer_username'])
      {
        let requested_for = response_data[i - 1]['requested_for']
        let customer_username = response_data[i - 1]['customer_username']
        let company = response_data[i - 1]['company_name']
        let requested_for_company = response_data[i - 1]['requested_for_company']
        data.push({
          key: i,
          room_id: response_data[i - 1]['room_id'],
          name: response_data[i - 1]['room_name'],
          id: response_data[i - 1]['room_id'],
          category: response_data[i - 1]['category'],
          model_type: response_data[i - 1]['variant_of'] ? 'Variation' : 'Simple',
          customer: (requested_for == '' || requested_for == customer_username) ? customer_username : `${customer_username} (${requested_for})`,
          company: (requested_for == '' || requested_for == customer_username) ? company : requested_for_company,
          assigned_artist: response_data[i - 1]['assigned_artist'],
          created_on: response_data[i - 1]['created_on'],
          last_modified: response_data[i - 1]['last_modified'],
          description: '',
          brand_id: response_data[i - 1]['brand_id'],
          price: response_data[i - 1]['price'],
          subscription_name : response_data[i - 1]['subscription_display_name']
        });
      }

      let category_value = response_data[i - 1]['category'];
      if(category_value != "" && !filter_categories_values.includes(category_value)){
        filter_categories_values.push(category_value);
      }

      let requested_for = response_data[i - 1]['requested_for'];
      let customer_username = response_data[i - 1]['customer_username'];
      let company = response_data[i - 1]['company_name']
      let requested_for_company = response_data[i - 1]['requested_for_company']
      
      let customer_value = (requested_for == '' || requested_for == customer_username) ? customer_username : `${customer_username} (${requested_for})`;
      if(customer_value != "" && !filter_customer_values.includes(customer_value)){
        filter_customer_values.push(customer_value);
      }

      let company_value = (requested_for == '' || requested_for == customer_username) ? company : requested_for_company
      if(company_value != "" && !filter_company_values.includes(company_value)){
        filter_company_values.push(company_value);
      }

      let artist_value = response_data[i - 1]['assigned_artist']
      if(artist_value != "" && !filter_artist_values.includes(artist_value)){
        filter_artist_values.push(artist_value)
      }

      let model_type_value = response_data[i - 1]['variant_of'] ? 'Variation' : 'Simple'
      console.log('model type value = ', model_type_value)
      if(model_type_value != '' && !filter_model_type_values.includes(model_type_value)){
        filter_model_type_values.push(model_type_value)
      }
    }

    if (this.props.setCount != undefined){
      this.props.setCount(data.length);
    }


    this.setState({
      dataSource: data,
      loader: false
    })

    this.handleFilteringOptions(filter_categories_values, filter_customer_values, filter_company_values, filter_artist_values, filter_model_type_values);
  }

  getRoomPayload = () => 
  {
    let payload = {
        "required_fields": ["room_id", "room_name", "brand_id", "category", "assigned_artist", 
        "customer_username", "model_status", "last_modified", "created_on", "company_name",
          "variant_of", "subscription_display_name", "requested_for", "requested_for_company"],
        "order_by": "room_id desc",
      }
    return payload
  }

  handleStoreData = (updating_view) => {
    axios.post(ENVIRONMENT.STORE_LIST, {
      item_type: 'room'
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        if (this.props.state == 'store') {
          this.getStoreItems(res.data)
        }        

        if (updating_view == 0) {
          this.setState(
            {
              removeFromStoreAction: "hide",
              loader: false
            }
          );
        }
        
      })
  }

  handleNotInStoreData = (updating_view) => {
    axios.post(ENVIRONMENT.ADMIN_STORE_HELPER, {
      entity: 'room',
      model_status: "5",
      eligible: ''
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        if (this.props.state == 'store_eligible') {
          this.getNotInStoreItems(res.data);
        }

        if (updating_view == 0) {
          this.setState(
            {
              addToStoreAction: "",
              adminViewAction: "",
              loader: false
            }
          );
        }
        
      })
  }

  handleStoreRequests = () => {
    axios.post(ENVIRONMENT.GET_PRICE_REQUESTS, {
      type: "room",
    })
      .then(res => {
        console.log(res.data);
        this.getTableDataFromReponse(res.data);
        
        this.setState({
          addItemToStoreAction: "",
        });
      })
  }


  assignRoomToArtist = (room_id) => {
    axios.post(ENVIRONMENT.UPDATE_ROOM, {
      assigned_artist: localStorage.getItem('username'),
      room_id: room_id.toString(),
    })
      .then(res => {
        // We should probably not call this line
        this.handleStoreData(0);
        this.removeRowFromTable(room_id)
        console.log(res);
        console.log(res.data);
        message.info('Model has been assigned to you');

      })
  }

  removeRowFromTable = (model_id) => {
    this.setState({
      dataSource: this.state.dataSource.filter(item => item.id !== model_id)
    })
  }
  
  handleArtistRejectedData = () => {
    let payload = this.getRoomPayload()
    let assigned_artist = "assigned_artist__exact='" + localStorage.getItem('username') + "'"
    payload["filter_string"] =  "(model_status__in=[-4]&&" + assigned_artist + ")"
    this.setState({
      loader: true
    })
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState(
          {
            artistAction: "",
            status: -4
          }
        );
      })
  }

  handleAssignedData = () => {
    let payload = this.getRoomPayload()
    let assigned_artist = "assigned_artist__exact='" + localStorage.getItem('username') + "'"
    payload["filter_string"] =  "(model_status__in=[2]&&" + assigned_artist + ")"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState(
          {
            artistAction: "",
            status: 2
          }
        );
      })
  }

  handleJobDeskData = () => {
    let data = []
    if(this.props.job_desk_data){
      data = this.props.job_desk_data['rooms']
    }
    this.getTableDataFromReponse(data);
    this.setState(
      {
        jobDeskAction: "",
        status: 2
      })
  }

  handleArtistReviewData = () => {
    let payload = this.getRoomPayload()
    let assigned_artist = "assigned_artist__exact='" + localStorage.getItem('username') + "'"
    payload["filter_string"] =  "(model_status__in=[8]&&" + assigned_artist + ")"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState(
          {
            artistAction: "",
            status: 8
          }
        );
      })
  }

  handleAdminQAAction = () => {
    let payload = this.getRoomPayload()
    payload["filter_string"] =  "(model_status__in=[3])"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.setState(
          {
            adminQAAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn:""
          }
        );
        this.getTableDataFromReponse(res.data);
      })
  }

  handleFailedAbortedAction = () => {
    let payload = this.getRoomPayload()
    payload["filter_string"] =  "(model_status__in=[11,12])"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.setState(
          {
            adminViewAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn:""

          }
        );
        this.getTableDataFromReponse(res.data);
      })
  }

  handleAdminViewAction = () => {
    let payload = this.getRoomPayload()
    payload["filter_string"] =  "(model_status__in=[4])"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.setState(
          {
            adminViewAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn:""
          }
        );
        this.getTableDataFromReponse(res.data);
      })
  }

  handleEnqueuedViewAction = () => {
    let payload = this.getRoomPayload()
    payload["filter_string"] =  "(model_status__in=[6])"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.setState(
          {
            adminViewAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn:""
          }
        );
        this.getTableDataFromReponse(res.data);
      })
  }

  handleBeingFixedAction = () => {
    let payload = this.getRoomPayload()
    payload["filter_string"] =  "(model_status__in=[-4])"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.setState(
          {
            adminViewAction: "",
            customerColumn: "",
            companyColumn: "",
            subscriptionColumn:""
          }
        );
        this.getTableDataFromReponse(res.data);
      })
  }

  handleBeingProcessedsAction = () => {
    let payload = this.getRoomPayload()
    let assigned_artist = "assigned_artist__exact='" + localStorage.getItem('username') + "'"
    payload["filter_string"] =  "(model_status__in=[6]&&" + assigned_artist + ")"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.setState({
          status: 6,
          artistAction: ""
        });
        this.getTableDataFromReponse(res.data);
      })
  }

  handleArtistFailedAbortedData = () => {
    let payload = this.getRoomPayload()
    let assigned_artist = "assigned_artist__exact='" + localStorage.getItem('username') + "'"
    payload["filter_string"] =  "(model_status__in=[11,12]&&" + assigned_artist + ")"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.setState({
          status: 11,
          artistAction: ""
        });
        this.getTableDataFromReponse(res.data);
      })
  }

  handleAdminReviewAction = () => {
    let payload = this.getRoomPayload()
    let assigned_artist = "assigned_artist__exact='" + localStorage.getItem('username') + "'"
    payload["filter_string"] =  "(model_status__in=[3]&&" + assigned_artist + ")"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.setState({
          status: 3
        });
        this.getTableDataFromReponse(res.data);
      })
  }

  handleArtistReviewDataForAdmin = () => {
    let payload = this.getRoomPayload()
    payload["filter_string"] =  "(model_status__in=[8])"
    axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then(res => {
        this.getTableDataFromReponse(res.data);
        this.setState({
          adminViewAction: "",
          customerColumn: "",
          companyColumn: "",
          subscriptionColumn:""
        })
      })
  }

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? footer : undefined });
  };


  handleScollChange = enable => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  render() {
    const { state } = this;
    const { dataSource } = state;

    return (
      <div>
        {this.state.loader ? <DottedLoader/> :
          (dataSource && dataSource.length > 0) ?
          <Table {...this.state} onChange={
            (pagination, filters, sorter, extra) => {
              console.log('filters = ', filters)
              this.setFilterQueryParams(filters)
            } 
       } columns={this.getColumns()} dataSource={dataSource} />
       : 
       <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <div>
            <SmallEmptyBox style={{marginTop: '50%', marginLeft: '44%'}} />
            <div>
                <span style={{fontSize: 16, color: '#555555', paddingLeft: 20}}>No Data Available</span>
            </div>
          </div>
       </div>
        }
        <AddItemToStore
         type={"admin"}
         entity_type={"room"}
         addToStore={this.addToStore}
         visible={this.state.add_store_item}
         add_store_item_loader={this.state.add_store_item_loader}
         onCancel={this.cancelStoreItem}
         selected_item={this.state.selected_item}
         onChangePrice={this.onChangePrice}
         price={this.state.price}
        />
      </div>
    );
  }
}

export default RoomsTable;
