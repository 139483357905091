import React from 'react';
import axios from 'axios';
import { ExclamationCircleOutlined,LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Card,Tooltip,message } from 'antd';
import ENVIRONMENT from '../../../../environments';
import ImageMagnify from '../../CustomerComponents/ImageMagnify/ImageMagnify';
import ImageZoomAdmin from '../../CustomerComponents/ImageZoomAdmin';
import Three360Viewer from "../../CustomerComponents/Three360Viewer"
import { ROOM_PANORAMA_ASSET, SPACE_TOP_VIEW_RENDER_URI } from '../../../../environments/env';

const base_uri = ENVIRONMENT.BASE_URI + 'space_test_renders/';
const image_style = { width: '100%',height: "calc(100vh -  202px)",objectFit:"contain" };
const image_style_in_progress = { width: '100%',height: "calc(100vh -  202px)",objectFit:"contain", filter:"blur(8px)" };
const fullscreen_image_style = { width: "100vw",height: "100vh",objectFit:"contain" };
const fullscreen_image_style_in_progress = { width: "100vw",height: "100vh",objectFit:"contain",  filter:"blur(8px)" };

class ThreesixtyTestRenderViewer extends React.Component {
    state = {
        room_id: 0,
        test_renders: [],
        top_view_render :"",
        card_image: '',
        box_check: 'card',
        is_fullscreen: false,
        image: [],
        display_360: true,
        platform: '',
        final_test_renders: [],
        dummy_scene_platform: '',
        dummy_scene_id: 0,
        customer_dummy_scene_id: 0,
        render_in_progress: false,
        isCustomer: false
    }


    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                is_fullscreen: false
            });
        }
    }

    constructScenePayload = (scene_id) => {
        let payload = {
            "order_by": "id desc",
            "filter_string": `(id__exact=${scene_id})&&(model_type__exact='glb'||model_type__exact='low_poly_max'||model_type__isnull=true)`,
            "required_fields": [
                "id",
                "final_renders",
                "platform"
            ]
        }
        return payload;
    }
    
    componentDidMount() {
        var room_id = 0;
        if (this.props !== undefined) {
            room_id = this.props.params.id;
    
       this.setState({ room_id: room_id, isCustomer:  this.props.isCustomer || false});
        axios.post(ENVIRONMENT.FETCH_ROOM, { room_id: String(this.props.params.id) })
            .then(res => {
                console.log(JSON.stringify(res.data.test_renders))
                this.setState({ test_renders: res.data.test_renders,
                                top_view_render : res.data.top_view_render,
                                platform: res.data.platform,
                                dummy_scene_id: res.data.dummy_scene_id,
                                customer_dummy_scene_id: res.data.customer_dummy_scene_id}, () => {
                    this.checkScroll();
                });
                if(res.data.dummy_scene_id){
                    axios.post(ENVIRONMENT.GET_SCENE_BATCH, this.constructScenePayload(res.data.dummy_scene_id))
                    .then(res => {
                        console.log(JSON.stringify(res.data.final_renders))
                        this.setState({ 
                            final_test_renders: res.data.final_renders,
                            dummy_scene_platform: res.data.platform
                        }, () => {
                            this.checkScroll();
                        });
                    })
                }
            });
        }
        console.log(this.state)
        document.addEventListener("keydown", this.escFunction, false);
        window.addEventListener("resize", this.checkScroll);
        document.addEventListener("wheel", () => {
            this.checkScroll();
        }, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps!= this.props) {
          this.checkScroll();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);

        window.removeEventListener("resize", this.checkScroll);
        document.removeEventListener("wheel", () => {
            this.checkScroll();
        }, false);
    }
    

    leftScroll = () => {
        var elem = document.getElementById('review-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft -= 273;
            var left = document.getElementById('left-scroll-btn');
            var right = document.getElementById('right-scroll-btn');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }
            
            if (right!=null) {
                right.style.visibility = 'visible';
                elem.style.left = "20px";
                // forceUpdate();
            }
        }
    }

    rightScroll = () => {
        var elem = document.getElementById('review-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft += 273;
            var left = document.getElementById('left-scroll-btn');
            var right = document.getElementById('right-scroll-btn');
            elem.style.left = "94px";
            if (right != null) {
                if (left != null) {
                    left.style.display = "inline-block";
                }
                
                var newScrollLeft = elem.scrollLeft;
                var divWidth = elem.offsetWidth;
                var scrollwidth = elem.scrollWidth;
                if(newScrollLeft === scrollwidth - divWidth){
                    right.style.visibility = 'hidden';
                }
            }
            
        }
    }

    checkScroll = () => {
        var elem = document.getElementById('review-scrollable');
        var carousel_elem = document.getElementById('pdp-carousel-ref'); 
        if (elem != null) {
            elem.style.scrollBehavior = "unset";

            var y1 = elem.scrollTop;  
            elem.scrollTop += 1; 
            var y2 = elem.scrollTop ; 
            elem.scrollTop -= 1; 
            var y3 = elem.scrollTop; 
            elem.scrollTop = y1; 
        
            var x1 = elem.scrollLeft;  
            elem.scrollLeft += 1; 
            var x2 = elem.scrollLeft; 
            elem.scrollLeft -= 1; 
            var x3 = elem.scrollLeft; 
            elem.scrollLeft = x1; 
            var right = document.getElementById('right-scroll-btn');
            var left = document.getElementById('left-scroll-btn');
            let nextValue = elem.scrollLeft - 273;

            if (elem.style.left == "94px") {
                elem.style.left = "94px";
            }
            else {
                if ( elem.scrollLeft == 0) {
                    elem.style.left = "20px";
                }
            }
            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
                if (left != null) {
                    if (elem.style.left == "20px" &&  elem.scrollLeft == 0) {
                        left.style.display = "none"; 
                    }
                }
                if (elem.style.left == "20px"  &&  nextValue <= 0) {// for right scroll
                    elem.style.width = "100%";
                    if (this.props.compareView) {
                        carousel_elem.style.width = "80%";
                    }
                }
                else { // for left scroll
                    elem.style.width = "100%";
                    if (this.props.compareView) {
                        carousel_elem.style.width = "80%";
                    }
                }
            }
            if (!this.props.compareView && this.props.adminScrollCheck) {
                elem.style.width = "100%";
                elem.style.left = "20px";
            }

            if (right != null  && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
            }
            if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                // right.style.visibility = 'hidden';
                left.style.display = "none";
            }
            // ---- NEED FOR LATER USE ----
            // console.log("H",x1 !== x2 || x2 !== x3); 
            // console.log("V",y1 !== y2 || y2 !== y3);
        }
    }      

    render() {
        let test_renders_photos = []
        if(this.state.test_renders != undefined){
            Object.keys(this.state.test_renders).forEach((key) => {
                let photos_array = this.state.test_renders[key];
                if(!Array.isArray(photos_array))
                {
                    if(key in photos_array){
                        photos_array = this.state.test_renders[key][key];
                    }
                    else {
                        photos_array = []
                    }
                }
                let test_renders_photos_subset = (photos_array || []).map((ele, index) => {
                    let file_url = ENVIRONMENT.getBaseURL(this.state.platform) + base_uri + ele.uid + '/' + ele.name;
                    return <Col span={12}>
                        <Card>
                            <a href={file_url} target='_blank'>
                                <img style={image_style} src={file_url} alt="References" />
                            </a>
                        </Card>
                    </Col>
                });
                if (key === 'test_renders_photos_all')
                {
                    test_renders_photos.unshift(test_renders_photos_subset);
                }
                else{
                    test_renders_photos.push(test_renders_photos_subset);
                }
            });
        }
        
        let card_img = []
        let index = 0
        if(this.state.test_renders != undefined){
            Object.keys(this.state.test_renders).forEach((key) => {
                if(!this.state.isCustomer || !this.state.test_renders[key].is_hidden) {
                    let ele = this.state.test_renders[key];
                    let file_url = ENVIRONMENT.getBaseURL(this.state.platform) + base_uri + ele.uid + '/' + ele.name;
                    this.state.image[index] = file_url;
                    if (index == 0) {
                        this.state.dummy_image = ENVIRONMENT.getBaseURL(this.state.platform) + base_uri + ele.uid + '/' + ele.name
                    }
                    let in_progress = false;
                    const user_type = ele.generated_by === 'Customer' ? 'Cust.' : ele.generated_by ? ele.generated_by : 'Artist'
                    const scene_id = user_type == 'Cust.' ? this.state.customer_dummy_scene_id : this.state.dummy_scene_id
                    if(ele.status == 'rendered'){
                        file_url = ENVIRONMENT.getBaseURL(ele.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + scene_id + '/' + ele.filename;
                    }
                    else if(ele.status == 'pending'){
                        in_progress = true;
                        file_url = ENVIRONMENT.getBaseURL(ele.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + scene_id + '/' + ele.preview_render.replace('tiff','jpg');
                    }
                    index++;
                    let test_renders_subset = 
                        <Col>
                            <Card key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} className="product-thumbnail-card" style={{ border: "1px solid #f0f0f0" }}>
                                <div onClick={() => (displayImage(file_url,in_progress), this.setState({display_360: false}), this.forceUpdate())} style={{ cursor: "pointer" }}>
                                    <img style={{ cursor: "pointer", height: "40px", width: "40px", objectFit: "contain", display: "block" }} src={file_url} />
                                    <div style={{ position: "absolute", left: "0px", top: "0px", width: "100%", padding: "5px 0px" }}>
                                        <div style={{
                                            borderRadius: "4px",
                                            background: "rgba(0, 0, 0, 0.3)",
                                            width: "70%",
                                            margin: "auto",
                                        }}><span className="tag-txt">{user_type}</span></div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    if (key === 'product_photos_all')
                    {
                        card_img.unshift(test_renders_subset);
                    }
                    else{
                        card_img.push(test_renders_subset);
                    }
                }
            });
        }
        if(this.state.top_view_render != ""){
            let top_view_render_url = ENVIRONMENT.getBaseURL(this.state.platform) + SPACE_TOP_VIEW_RENDER_URI + this.state.top_view_render
            let top_view_render_html = <Col>
                                            <Card key={index} bodyStyle={{ padding: "2px", minWidth: 40, textAlign: "-webkit-center" }} className="product-thumbnail-card" style={{ border: "1px solid #f0f0f0" }}>
                                            <div onClick={() => (displayImage(top_view_render_url), this.setState({display_360: false}), this.forceUpdate())} style={{ cursor: "pointer" }}>
                                                <img style={{ cursor: "pointer", height: "40px", width: "40px", objectFit: "contain", display: "block" }} src={top_view_render_url} />
                                                <div style={{ position: "absolute", left: "0px", top: "0px", width: "100%", padding: "5px 0px" }}>
                                                    <div style={{
                                                        borderRadius: "4px",
                                                        background: "rgba(0, 0, 0, 0.3)",
                                                        width: "70%",
                                                        margin: "auto",
                                                    }}><span className="tag-txt">Top</span></div>
                                                </div>
                                            </div>
                                        </Card>
                                        </Col>
            card_img.push(top_view_render_html);
            index++;
        }
        
        
        const displayImage = (URL, in_progress= false) => {
            this.setState({
                card_image: URL,
                dummy_image: '',
                box_check: 'card',
                render_in_progress: in_progress
            });
        } 

        const display360 = () => {
            this.setState({
                dummy_image: '',
                display_360: true});
                this.forceUpdate();
        }

        return (
            <React.Fragment>
                {this.state.display_360 == true ? 
                <React.Fragment>
                        <Three360Viewer customClass={this.props.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} fullScreen={this.props.isFullScreen} url={this.props.url}/>
                        <Card className='product-viewer-normal'>
                        </Card>
                </React.Fragment>
                        :
                <Card className={this.props.isFullScreen && !this.state.display_360 ? "product-full-screen-card" : "product-viewer-normal"} bodyStyle={{padding: this.props.isFullScreen ? 0 : 12}}>
                    { card_img != [] ?
                        <React.Fragment>
                            <div>
                                <div style={{ display: 'flex', justifyContent: "space-around" }}>
                                    <Col>
                                    <Card bordered={false}>
                                        {this.state.box_check === 'card' ? (
                                            <>
                                                {this.state.render_in_progress ? (
                                                    <div className="thumbnail-btn-bg blur">
                                                        <span className="thumbnail-btn">Render in Progress...</span>
                                                    </div>
                                                ) : null}
                                                {this.props.adminCheck === true ? (
                                                    <div>
                                                     <ImageZoomAdmin
                                                        custom_class={this.props.isFullScreen ? this.state.render_in_progress ? fullscreen_image_style_in_progress :fullscreen_image_style : this.state.render_in_progress ? image_style_in_progress : image_style}
                                                        compare={true}
                                                        notShowToolButtons={true}
                                                        image={this.state.card_image || this.state.dummy_image}
                                                    />
                                                    </div>
                                                ) : (
                                                    null
                                                )}
                                            </>
                                        ) : ""}
                                    </Card>
                                    </Col>
                                </div>
                            </div>
                        </React.Fragment>:""}
                </Card>}

                    <div className='pdp-carousel-scroll' id='pdp-carousel-ref' style={{zIndex: 999}}>
                    <div className="icon-con-left" id="left-scroll-btn" onClick={this.leftScroll}>
                        <RightOutlined  className="left-button-scr" />
                    </div>
                    <div id="review-scrollable" className="pdp-scrollable">
                        <Tooltip title={"360"} placement="top">
                            <Card className='pdp-card' bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{marginRight:"10px"}}>
                                <div className="product-view-icon" onClick={() => {display360();}}>
                                    <span style={{background: "url(/img/360-tn.jpg)",objectFit:"contain",height:"40px",width:"40px"}}><b style={{color: "white"}}></b></span>
                                </div>
                            </Card>
                        </Tooltip>
                        {card_img}
                    </div>
                    <div className="icon-con" id="right-scroll-btn" onClick={this.rightScroll}>
                        <RightOutlined  className="right-button-scr" />
                    </div>
                    </div>
            </React.Fragment>
            );



    } // render close

}


export default ThreesixtyTestRenderViewer;