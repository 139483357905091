import React from "react";
import { Tabs, Card } from "antd";
import AdminMainLayout from '../AdminMainLayout';
import ScansTable from './ScansTable';

const { TabPane } = Tabs;

class AdminScansData extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"19"}>
        
          <br />
          <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false}>
            <TabPane tab="Enqueued" key="1">
                <ScansTable job_state={["Enqueued"]}/>
            </TabPane>
            <TabPane tab="InProgress" key="2">  
                <ScansTable job_state={["InProgress", "PreProcessing", "RunningFusion", "RunningIntrinsic3D", "PostProcessing"]}/>        
            </TabPane>
            <TabPane tab="Completed" key="3">
                <ScansTable job_state={["Completed"]}/>      
            </TabPane>
            <TabPane tab="Failed" key="4">          
                <ScansTable job_state={["Failed"]}/>
            </TabPane>
            <TabPane tab="Cancelled" key="5">    
                <ScansTable job_state={["Cancelled"]}/>      
            </TabPane>
          </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminScansData;