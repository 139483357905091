import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Table, Select, Empty, Radio, Card, Divider } from "antd";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Line } from "@ant-design/plots";
import moment from "moment";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";
import useTableFilter from "../../UseTableFilter/useTableFilter";
import FileConstants from "../../../../FileConstants";

const DATE_FORMAT = "DD/MM/YYYY";
const { Option } = Select;

const RESOLUTION_ORDERS = [
  "512x512", "1K", "2K", "3K", "4K", "5K", "6K", "7K", "8K",
]

const Actions = {
  get_overall_renders_throughput: "get_overall_renders_throughput",
  get_customer_renders_throughput: "get_customer_renders_throughput",
  get_ms_provider_renders_throughput: "get_ms_provider_renders_throughput",
  get_specific_user_renders_resolution_summary:
    "get_specific_user_renders_resolution_summary",
  get_customer_renders_summary: "get_customer_renders_summary",
  get_ms_provider_renders_summary: "get_ms_provider_renders_summary",
};

const RendersThroughput = () => {
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [renderType, setRenderType] = useState("overall");
  const [rendersThroughput, setRendersThroughput] = useState([]);
  const [isRendersChartLoading, setIsRendersChartLoading] = useState(false);
  const [resolutionWiseList, setResolutionWiseList] = useState([]);
  const [totalResolutionWiseModels, setTotalResolutionWiseModels] = useState(0);
  const [
    customerRendersSummaryDataSource,
    setCustomerRendersSummaryDataSource,
  ] = useState(null);
  const [
    msProviderRendersSummaryDataSource,
    setMsProviderRendersSummaryDataSource,
  ] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedMSProvider, setSelectedMSProvider] = useState(null);
  const [isCustomerRendersSummaryLoading, setIsCustomerRendersSummaryLoading] =
    useState(false);
  const [
    isMSProviderRendersSummaryLoading,
    setIsMSProviderRendersSummaryLoading,
  ] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [msProvidersList, setMsProvidersList] = useState([]);
  const [isRendersModalOpen, setIsRendersModalOpen] = useState(false);
  const [userRendersSummaryDataSource, setUserRendersSummaryDataSource] =
    useState(null);
  const [userRenderType, setUserRenderType] = useState(null);
  const [rendersUsername, setRendersUsername] = useState(null);
  const [totalUserRenders, setTotalUserRenders] = useState(0);
  const [rendersStartDate, setRendersStartDate] = useState(
    moment().startOf("month").format(DATE_FORMAT)
  );
  const [rendersEndDate, setRendersEndDate] = useState(
    moment().endOf("month").format(DATE_FORMAT)
  );
  const [getColumnSearchProps] = useTableFilter();
  const [companies, setCompanies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customersSelectedCompany, setCustomersSelectedCompany] = useState(null);
  const [msProvidersSelectedCompany, setMsProvidersSelectedCompany] = useState(null);
  const [customersSelectedCustomer, setCustomersSelectedCustomer] = useState(null);
  const [msProvidersSelectedCustomer, setMsProvidersSelectedCustomer] = useState(null);
  const [customerType, setCustomerType] = useState("external");
  const [internalUsers, setInternalUsers] = useState([]);
  const [externalUsers, setExternalUsers] = useState([]);
  const [customerRendersUsers, setCustomerRendersUsers] = useState([]);

  // ====> Renders Throughput

  const onRendersCalenderChange = (date_values) => {
    if (date_values && date_values[0] && date_values[1]) {
      setRendersStartDate(date_values[0].format(DATE_FORMAT));
      setRendersEndDate(date_values[1].format(DATE_FORMAT));
    }
  };

  // Get unique sets from Array of Dictionaries
  const getUniqueSets = (arr) => {
    const unique_sets = Array.from(
      arr.reduce(
        (m, { type, value }) => m.set(type, (m.get(type) || 0) + value),
        new Map()
      ),
      ([type, value]) => ({ type, value })
    );
    const orderedData = unique_sets.sort((a, b) => {
      const indexA = RESOLUTION_ORDERS.indexOf(a.type);
      const indexB = RESOLUTION_ORDERS.indexOf(b.type);
      if (indexA < indexB) return -1;
      if (indexA > indexB) return 1;
      return 0;
    });
    return orderedData;
  };

  const changeRendersType = (e) => {
    setRenderType(e.target.value);
  };

  const changeCustomerType = (e) => {
    const type = e.target.value;
    setCustomerType(type);
    if (type === 'internal') {
      setCustomerRendersUsers(internalUsers);
    }
    else {
      setCustomerRendersUsers(externalUsers);
    }
  };

  const getRendersThroughput = () => {
    let user = selectedUser;
    const start = moment(moment(rendersStartDate, "DD/MM/YYYY")).format(
      "YYYY-MM-DD"
    );
    const end = moment(moment(rendersEndDate, "DD/MM/YYYY")).format(
      "YYYY-MM-DD"
    );
    let payload = {};
    if (renderType == "overall") {
      payload = {
        action: Actions["get_overall_renders_throughput"],
        start_date: start,
        end_date: end,
      };
    } else if (renderType == "customer") {
      if (user && user != "all") {
        payload = {
          action: Actions["get_customer_renders_throughput"],
          start_date: start,
          end_date: end,
          username: user,
        };
      } else {
        payload = {
          action: Actions["get_customer_renders_throughput"],
          start_date: start,
          end_date: end,
        };
      }
    } else if (renderType == "ms_provider") {
      user = selectedMSProvider;
      if (user && user != "all") {
        payload = {
          action: Actions["get_ms_provider_renders_throughput"],
          start_date: start,
          end_date: end,
          username: user,
        };
      } else {
        payload = {
          action: Actions["get_ms_provider_renders_throughput"],
          start_date: start,
          end_date: end,
        };
      }
    }
    setIsRendersChartLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      const response = res.data;
      console.log("Renders Throughput", response);

      const resolution_unique_models = getUniqueSets(response);
      console.log("Resolution Wise Breakdown", resolution_unique_models);

      setResolutionWiseList(resolution_unique_models);

      const total_res_wise_models = resolution_unique_models
        .map((item) => item["value"])
        .reduce((prev, curr) => prev + curr, 0);

      setTotalResolutionWiseModels(total_res_wise_models);
      setRendersThroughput(response);
      setIsRendersChartLoading(false);
    });
  };

  useEffect(() => {
    getRendersThroughput();
    console.log("SELECTED", selectedUser, selectedMSProvider);
  }, [
    renderType,
    rendersStartDate,
    rendersEndDate,
    selectedUser,
    selectedMSProvider,
  ]);

  useEffect(() => {
    setRendersThroughput([]);
    setResolutionWiseList([]);
    setTotalResolutionWiseModels(0);
    setSelectedUser(null);
    setSelectedMSProvider(null);
  }, [renderType]);

  const showRendersModal = (name, type, total_renders) => {
    const start = moment(moment(rendersStartDate, "DD/MM/YYYY")).format(
      "YYYY-MM-DD"
    );
    const end = moment(moment(rendersEndDate, "DD/MM/YYYY")).format(
      "YYYY-MM-DD"
    );
    setRendersUsername(name);
    setUserRenderType(type);
    setTotalUserRenders(total_renders);
    setIsRendersModalOpen(true);
    const payload = {
      action: Actions["get_specific_user_renders_resolution_summary"],
      username: name,
      renders_type: type,
      start_date: start,
      end_date: end,
    };
    setIsTableDataLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      console.log("User Renders Summary", res.data);
      setUserRendersSummaryDataSource(res.data);
      setIsTableDataLoading(false);
    });
  };

  const closeRendersModal = () => {
    setIsRendersModalOpen(false);
    setUserRendersSummaryDataSource([]);
    setRendersUsername("");
    setUserRenderType("");
    setTotalUserRenders(0);
  };

  const rendersModalTableColumns = [
    {
      title: "Resolution",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type"),
    },
    {
      title: "Count",
      dataIndex: "value",
      key: "value",
    },
  ];

  const rendersResolutionCountTableColumns = [
    {
      title: "Resolution",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps("type"),
    },
    {
      title: "Count",
      dataIndex: "value",
      key: "value",
    },
  ];

  const customerRendersSummaryColumns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      ...getColumnSearchProps("company"),
    },
    {
      title: "Username",
      dataIndex: "customer",
      key: "customer",
      ...getColumnSearchProps("customer"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Renders Completed",
      dataIndex: "renders_completed",
      key: "renders_completed",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            onClick={() =>
              showRendersModal(
                record.customer,
                "Completed",
                record.renders_completed
              )
            }
          >
            {record.renders_completed}
          </a>
        </span>
      ),
    },
    {
      title: "Renders Flagged",
      dataIndex: "renders_flagged",
      key: "renders_flagged",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            onClick={() =>
              showRendersModal(
                record.customer,
                "Flagged",
                record.renders_flagged
              )
            }
          >
            {record.renders_flagged}
          </a>
        </span>
      ),
    },
    {
      title: "Renders Deleted",
      dataIndex: "renders_hidden",
      key: "renders_hidden",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            onClick={() =>
              showRendersModal(
                record.customer,
                "Deleted",
                record.renders_hidden
              )
            }
          >
            {record.renders_hidden}
          </a>
        </span>
      ),
    },
    {
      title: "Renders Complaint Resolved",
      dataIndex: "renders_complaint_resolved",
      key: "renders_complaint_resolved",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            onClick={() =>
              showRendersModal(
                record.customer,
                "Complaint Resolved",
                record.renders_complaint_resolved
              )
            }
          >
            {record.renders_complaint_resolved}
          </a>
        </span>
      ),
    },
  ];

  const msProviderRendersSummaryColumns = [
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      ...getColumnSearchProps("company"),
    },
    {
      title: "Username",
      dataIndex: "customer",
      key: "customer",
      ...getColumnSearchProps("customer"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Renders Completed",
      dataIndex: "renders_completed",
      key: "renders_completed",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            onClick={() =>
              showRendersModal(
                record.customer,
                "Completed",
                record.renders_completed
              )
            }
          >
            {record.renders_completed}
          </a>
        </span>
      ),
    },
    {
      title: "Renders Flagged",
      dataIndex: "renders_flagged",
      key: "renders_flagged",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            onClick={() =>
              showRendersModal(
                record.customer,
                "Flagged",
                record.renders_flagged
              )
            }
          >
            {record.renders_flagged}
          </a>
        </span>
      ),
    },
    {
      title: "Renders Deleted",
      dataIndex: "renders_hidden",
      key: "renders_hidden",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            onClick={() =>
              showRendersModal(
                record.customer,
                "Deleted",
                record.renders_hidden
              )
            }
          >
            {record.renders_hidden}
          </a>
        </span>
      ),
    },
    {
      title: "Renders Complaint Resolved",
      dataIndex: "renders_complaint_resolved",
      key: "renders_complaint_resolved",
      render: (_, record) => (
        <span>
          <a
            className="onhover-underline"
            onClick={() =>
              showRendersModal(
                record.customer,
                "Complaint Resolved",
                record.renders_complaint_resolved
              )
            }
          >
            {record.renders_complaint_resolved}
          </a>
        </span>
      ),
    },
    // Commented Intentionally will be implemented soon
    // {
    //   title: "Renders Shared",
    //   dataIndex: "date",
    //   key: "date",
    // },
  ];

  useEffect(() => {
    getCustomerRendersSummary();
  }, [rendersStartDate, rendersEndDate, 
    customersSelectedCompany, customersSelectedCustomer, customerType
  ]);

  useEffect(() => {
    getMSProviderRendersSummary();
  }, [rendersStartDate, rendersEndDate, 
    msProvidersSelectedCompany, msProvidersSelectedCustomer
  ])

  const getCustomerRendersSummary = () => {
    const start = moment(moment(rendersStartDate, "DD/MM/YYYY")).format(
      "YYYY-MM-DD"
    );
    const end = moment(moment(rendersEndDate, "DD/MM/YYYY")).format(
      "YYYY-MM-DD"
    );
    let payload = {
      action: Actions["get_customer_renders_summary"],
      start_date: start,
      end_date: end,
      customer_type: customerType,
    };

    if (customersSelectedCompany != null) {
      payload["company"] = customersSelectedCompany
    }

    if (customersSelectedCustomer != null) {
      payload["username"] = customersSelectedCustomer
    }
    console.log(payload);
    setIsCustomerRendersSummaryLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      console.log("Customer Renders Summary", res.data);
      setCustomerRendersSummaryDataSource(res.data);
      let customers = [];
      customers = res.data.map((item) => item["customer"]);
      setCustomersList(customers);
      setIsCustomerRendersSummaryLoading(false);
    });
  };

  const getMSProviderRendersSummary = () => {
    const start = moment(moment(rendersStartDate, "DD/MM/YYYY")).format(
      "YYYY-MM-DD"
    );
    const end = moment(moment(rendersEndDate, "DD/MM/YYYY")).format(
      "YYYY-MM-DD"
    );

    let payload = {
      action: Actions["get_ms_provider_renders_summary"],
      start_date: start,
      end_date: end,
    };

    if (msProvidersSelectedCompany != null) {
      payload["company"] = msProvidersSelectedCompany
    }

    if (msProvidersSelectedCustomer != null) {
      payload["username"] = msProvidersSelectedCustomer
    }
    console.log(payload);
    setIsMSProviderRendersSummaryLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      console.log("MS Provider Renders Summary", res.data);
      setMsProviderRendersSummaryDataSource(res.data);
      let ms_providers = [];
      ms_providers = res.data.map((item) => item["customer"]);
      setMsProvidersList(ms_providers);
      setIsMSProviderRendersSummaryLoading(false);
    });
  };

  // Select (Companies Dropdown) functions
  const getCompaniesList = () => {
    let payload = {};
    axios.post(ENVIRONMENT.COMPANY_GET_BATCH, payload).then((response) => {
      console.log("Companies Data", response.data);
      let companiesData = [{label : "Default (All Companies)", value: "all"}];
      if (response.data) {
        response.data.map((request) => {
          companiesData.push({label:request.company_display_name, value:request.company_display_name});
        });
        const uniqueCompaniesList = Array.from(new Set(companiesData.map(JSON.stringify)), JSON.parse);
        setCompanies(uniqueCompaniesList);
        console.log("Companies List", uniqueCompaniesList);
      }
    });
  };

  // Select (Customers Dropdown) functions
  const getUsersList = () => {
    let payload = {};
    axios.post(ENVIRONMENT.CUSTOMER_GET_BATCH, payload).then((response) => {
      console.log("Customers Data",response.data)
      filterCustomers(response.data)
      let users = [{label : "Default (All Customers)", value: "all"}];
      if (response.data) {
        response.data.map((request) => {
          users.push({label:request.customer_username, value:request.customer_username});
        });
        setCustomers(users);
        console.log("Users List", users);
      }
    });
  };

  const filterCustomers = (customersData) => {
    const emailPattern = /tintash/;
    const usernamePattern = /test/;
    let internalCustomers = [{label : "Default (All Customers)", value: "all"}];
    let externalCustomers = [{label : "Default (All Customers)", value: "all"}];
    customersData.map((customer) => {
      if (emailPattern.test(customer.email) || usernamePattern.test(customer.customer_username)) {
        internalCustomers.push({label:customer.customer_username, value:customer.customer_username});
      } else {
        externalCustomers.push({label:customer.customer_username, value:customer.customer_username});
      }
    });
    setInternalUsers(internalCustomers);
    setExternalUsers(externalCustomers);
    setCustomerRendersUsers(externalCustomers);
  };

  useEffect(() => {
    getUsersList();
    getCompaniesList();
  }, []);
  
  const handleCompanyChange = (value, type) => {
    if (type === 'customer') {
      if (value === 'all') {
        setCustomersSelectedCompany(null);
      } 
      else {
        setCustomersSelectedCompany(value);
      }
    } 
    else if (type === 'ms_provider') {
      if (value === 'all') {
        setMsProvidersSelectedCompany(null);
      } 
      else {
        setMsProvidersSelectedCompany(value);
      }
    }
  };

  const handleCustomerChange = (value, type) => {
    if (type === 'customer') {
      if (value === 'all') {
        setCustomersSelectedCustomer(null);
      } 
      else {
        setCustomersSelectedCustomer(value);
      }
    } 
    else if (type === 'ms_provider') {
      if (value === 'all') {
        setMsProvidersSelectedCustomer(null);
      } 
      else {
        setMsProvidersSelectedCustomer(value);
      }
    }
  };

  // Renders Throughput <====

  return (
    <>
      <Card
        className="no-hover section-container"
        size="small"
        title={
          <Row className="justify-in-start">
            <Col span={16}>
              <span className="sub-heading">Lifestyle Renders Throughput</span>
            </Col>
            <Col span={3} className="justify-in-end">
              {renderType == "customer" && (
                <Select
                  placeholder="Select User"
                  size={"medium"}
                  style={{ width: "100%", marginRight: 20 }}
                  onChange={(val) => {
                    setSelectedUser(val);
                  }}
                  value={selectedUser}
                >
                  <Option
                    className="manrope f-14 black-55 select-view"
                    value="all"
                  >
                    Default:All
                  </Option>
                  {customersList
                    ? customersList.map((username) => (
                        <Option
                          className="manrope f-14 black-55 select-view"
                          value={username}
                        >
                          {username}
                        </Option>
                      ))
                    : ""}
                </Select>
              )}
              {renderType == "ms_provider" && (
                <Select
                  placeholder="Select User"
                  size={"medium"}
                  style={{ width: "100%", marginRight: 20 }}
                  onChange={(val) => {
                    setSelectedMSProvider(val);
                  }}
                  value={selectedMSProvider}
                >
                  <Option
                    className="manrope f-14 black-55 select-view"
                    value="all"
                  >
                    Default:All
                  </Option>
                  {msProvidersList
                    ? msProvidersList.map((username) => (
                        <Option
                          className="manrope f-14 black-55 select-view"
                          value={username}
                        >
                          {username}
                        </Option>
                      ))
                    : ""}
                </Select>
              )}
            </Col>
            <Col span={5} className="justify-in-end">
              <DateRangePicker
                start={rendersStartDate}
                end={rendersEndDate}
                date_format={DATE_FORMAT}
                onCalChange={onRendersCalenderChange}
              />
            </Col>
          </Row>
        }
      >
        <Row className="justify-in-start mt-20" gutter={16}>
          <Col span={15}>
            <Radio.Group
              value={renderType}
              onChange={changeRendersType}
              size="medium"
              className="manrope f-14 black-55"
            >
              <Radio.Button value="overall">Overall</Radio.Button>
              <Radio.Button value="customer">Customer</Radio.Button>
              <Radio.Button value="ms_provider">MS Provider</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row className="mb-20"
         style={{paddingBottom:'20px'}}>
          <div className="chart-container">
          {isRendersChartLoading ? (
              <LoadingOutlined className="loading-center top-20" />
            ) : rendersThroughput.length > 0 ? (
              <Row gutter={16}>
                <Col span={19}>
                  <Line
                    {...FileConstants.LINE_PLOT_CONFIG(
                      rendersThroughput,
                      "date",
                      "value",
                      "type"
                    )}
                    className="line-plot"
                  />
                </Col>
                <Col span={5}>
                  <Table 
                  style={{marginTop: 20, overflow: "scroll"}}
                  dataSource={resolutionWiseList}
                  columns={rendersResolutionCountTableColumns}
                  bordered
                  size="small"
                  sticky
                  ></Table>
                </Col>
              </Row>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </Row>
        <Divider className="mt-20"></Divider>
        <Row gutter={12} className="mt-20">
          <Col span={16}>
            <span className="sub-heading">Customer Renders Summary</span>
          </Col>
          <Col span={4} className="justify-in-end">
            <Select
            showSearch
            placeholder="Select Company"
            style={{width: "100%"}}
            options={companies}
            onChange={(value) => {
              handleCompanyChange(value,'customer')
            }}
            >
            </Select>
          </Col>
          <Col span={4} className="justify-in-end">
            <Select
            showSearch
            placeholder="Select Customer"
            style={{width: "100%"}}
            options={customerRendersUsers}
            onChange={(value) => {
              handleCustomerChange(value,'customer')
            }}
            >
            </Select>
          </Col>
        </Row>
        <Row className="justify-in-start mt-20" gutter={16}>
          <Col span={15}>
            <Radio.Group
              value={customerType}
              onChange={changeCustomerType}
              size="medium"
              className="manrope f-14 black-55"
              >
              <Radio.Button value="external">External</Radio.Button>
              <Radio.Button value="internal">Internal</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {isCustomerRendersSummaryLoading ? (
              <LoadingOutlined className="loading-center" />
            ) : (
              <Table
                dataSource={customerRendersSummaryDataSource}
                columns={customerRendersSummaryColumns}
                scroll
                style={{ overflowX: "scroll", marginTop: 20 }}
              />
            )}
          </Col>
        </Row>

        <Divider className="mt-20"></Divider>
        <Row gutter={12} className="mt-20">
          <Col span={16}>
            <span className="sub-heading">Managed Service Provider Renders Summary</span>
          </Col>
          <Col span={4} className="justify-in-end">
            <Select
            showSearch
            placeholder="Select Company"
            style={{width: "100%"}}
            options={companies}
            onChange={(value) => {
              handleCompanyChange(value,'ms_provider')
            }}
            >
            </Select>
          </Col>
          <Col span={4} className="justify-in-end">
            <Select
            showSearch
            placeholder="Select Customer"
            style={{width: "100%"}}
            options={customers}
            onChange={(value) => {
              handleCustomerChange(value,'ms_provider')
            }}
            >
            </Select>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
          {isMSProviderRendersSummaryLoading ? (
              <LoadingOutlined className="loading-center" />
            ) : (
              <Table
                dataSource={msProviderRendersSummaryDataSource}
                columns={msProviderRendersSummaryColumns}
                scroll
                style={{ overflowX: "scroll", marginTop: 20 }}
              />
            )}
          </Col>
        </Row>
      </Card>

      {/* User Renders Details Modal */}
      <Modal
        title={
          userRenderType +
          ` Renders Resolution wise Summary for ` +
          rendersUsername +
          ` (` +
          totalUserRenders +
          `) `
        }
        className=""
        maskClosable={false}
        onCancel={closeRendersModal}
        visible={isRendersModalOpen}
        width={"70%"}
        centered={true}
        style={{ paddingTop: 20, minWidth: "80%" }}
        footer={[]}
      >
        {isTableDataLoading ? (
          <LoadingOutlined className="loading-center" />
        ) : (
          <Table
            dataSource={userRendersSummaryDataSource}
            columns={rendersModalTableColumns}
            scroll
            style={{ overflowX: "scroll" }}
          />
        )}
      </Modal>
    </>
  );
};

export default RendersThroughput;
