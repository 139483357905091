import React  from 'react';
import { Form } from '@ant-design/compatible';
import {
    Row,
    Col,
    Button,
    Modal,
    Upload,
    Menu,
    Dropdown,
    Select,
    Collapse,
    Image,
    Card,
    Tabs,
    Input,
    Checkbox,
    Typography,
    List,
    Radio,
    Carousel,
    TreeSelect,
    Tooltip
} from 'antd';
import * as Constants from "../../CustomerComponents/Constants";


const GoogleUploadTestForm = (props) => {
    const { getFieldDecorator} = props.form;

    return <Form className="input-form">
    <Row type="flex">
        <Col span={12} style={{padding: 12}}>
            <div className="d-flex" style={{justifyContent:"center"}}>
                <div style={{textAlign:"center",minWidth:"100%"}}>
                    <Form.Item name={'perspective_images'} label="Perspective Images" colon={false}>
                    {getFieldDecorator('perspective_images', {
                        valuePropName: 'perspective_images',
                        getValueFromEvent: e => e && e.fileList,
                        rules: [{ required: true, message: 'Please upload perspective renders to replace' }]
                    })(
                        <Upload
                            accept=".jpg,.jpeg"
                            {...(Constants.getUploadProps())}
                            multiple="true"
                            listType="text"
                            fileList = {props.perspectiveRenders}
                            onChange = {props.handlePerspectiveRenderChange}
                            className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-admin"
                        >
                        <div className="d-flex">
                            <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                            <div className="ant-upload-text">
                            <div className="up-info">Drop your Files</div>
                            <div>Support: JPG</div>
                            </div>
                        </div>
                        </Upload>
                    )}
                    </Form.Item>
                </div>
                </div>
            <p>Please drop your images in the order you want them to appear on product page.</p>
        </Col>
        </Row>
    </Form>
}

const GoogleUploadTest = Form.create({ name: 'product_info' })(GoogleUploadTestForm);

export default GoogleUploadTest;