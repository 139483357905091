import React, { useContext, useState, useRef } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';

import { Modal, Form, Button, Typography, Checkbox, Tabs, Row, Col, Input, message } from 'antd';
import { LoadingOutlined, DownloadOutlined, FlagOutlined, MailOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import './ProductComponents.scss'
import { Link } from "react-router-dom";
import ENVIRONMENT from '../../../../environments';
import { getBaseURL } from '../../../../environments/env';
import EmailSelect from '../../FormUtilities/EmailSelect';
import SuccessModal from '../../SuccessModal/SuccessModal';
import axios from 'axios';

const { Paragraph } = Typography;
const { TabPane } = Tabs;
const formRef = { useRef };

const ShareProductModal = (props) => {
    const { product_id, sharingModal, productAssetData, setSharingModal, companyUsers } = useContext(props.context_for == "product_page" ? CustomerProductContext : CollaborateQAContext);
    const [form] = Form.useForm();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [emailError, setEmailError] = useState('');
    const [sharingEmails, setSharingEmails] = useState([]);
    const [successModal, setSuccessModal] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState('embed');
    const [sharingLoader, setSharingLoader] = useState(false);
    const [updatingAccessEmails, setUpdatingAccessEmails] = useState({});
    const [accessUpdateLoader, setAccessUpdateLoader] = useState(false);
    const [inviteLoader, setInviteLoader] = useState(false);


    const handleShare = email => {
        setAccessUpdateLoader(true);
        // Keep track of email address whose access is being updated
        let emails = updatingAccessEmails;
        emails[email] = true
        setUpdatingAccessEmails(emails);
        forceUpdate();

        let body = {};
        body.product_id = product_id;
        body.username = localStorage.getItem("username");
        let lowercase_emails = [email.toLowerCase()]
        body.emails = lowercase_emails;
        let permissions = {}
        permissions[selectedPermission] = 1;
        body.permissions = permissions;
        axios.post(ENVIRONMENT.SHARE_PRODUCT, { body })
            .then(res => {
                setAccessUpdateLoader(false);
                let updating_emails = updatingAccessEmails
                updating_emails[email] = false
                setUpdatingAccessEmails(updating_emails);
                console.log(updatingAccessEmails)
                message.success('Scene Shared Successfully!');
                setInviteLoader(false);
                forceUpdate();
            });
    };

    const handleClearEmails = () => {
        form.setFieldsValue({ emails: [] });
        setEmailError('');
        setSharingEmails([])
    };

    const validateEmails = (_, value) => {
        // Check if the value is an array
        if (Array.isArray(value)) {
            // Regular expression for email validation
            const emailRegex = /^\S+@\S+\.\S+$/;
            const invalidEmails = value.filter(email => !emailRegex.test(email));

            // Clear previous error message
            setEmailError('');

            if (value.length === 0) {
                return Promise.reject();
            }

            if (invalidEmails.length > 0) {
                setEmailError('Please enter valid email address');
                return Promise.reject();
            }
        }

        return Promise.resolve();
    };

    const handleShareEmailChange = (e) => {
        setSharingEmails(e)
    }


    const onChangeAllowDownloading = (e) => {
        let default_sharing_value = 'embed'
        if (e.target.checked) {
            setSelectedPermission(e.target.value)
        }
        else {
            setSelectedPermission(default_sharing_value)
        }
    }


    return (<>
        <Modal
            visible={sharingModal}
            onCancel={() => setSharingModal(false)}
            className="modal-share"
            footer={null}>
            <div>
                <h2 className="manrope f-18 black-00 w-600">{productAssetData && productAssetData['model_status'] == 5 ? "Share and Embed Product" : "View Links & Embed Product"}</h2>
                <Tabs>
                    {productAssetData && productAssetData['model_status'] == 5 &&
                        <TabPane tab="Share With" key="1" >
                            <Checkbox className='mt-20' style={{ fontWeight: 400, display: 'flex' }} value='download_and_embed' defaultChecked={false} onChange={e => onChangeAllowDownloading(e)} >
                                Allow Downloading
                            </Checkbox>
                            {companyUsers.length > 0 ?
                                <div className="collaborators-list custom-scroll">
                                    {companyUsers.map((item, index) => {
                                        return (
                                            <>
                                                <div key={index} className="collaborators-list-item">
                                                    <div className="collaborator-email-username">
                                                        <span className="manrope f-14 w-700" style={{ display: 'block' }}>{item.username}</span>
                                                        <span className="manrope f-12 w-400" style={{ display: 'block' }}>{item.email}</span>
                                                    </div>
                                                    <Button ghost
                                                        className='action-icon-btn blue manrope f-14 blue hover-change'
                                                        disabled={updatingAccessEmails[item.email] && accessUpdateLoader}
                                                        onClick={() => handleShare(item.email)}>
                                                        {updatingAccessEmails[item.email] ? <LoadingOutlined /> : ''}&nbsp;Share</Button>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div> : ''}

                            <div className="mt-20">
                                <p className="manrope f-14 mt-10 mb-5">Invite</p>
                                <Form
                                    ref={formRef}
                                    onFinish={(values) => { handleShare(values['email']); setInviteLoader(true) }}
                                >
                                    <Row gutter={8}>
                                        <Col span={19}>
                                            <Form.Item
                                                name="email"
                                                rules={[{ type: 'email', message: 'Please enter a valid email.' },
                                                { required: true, message: 'Please enter an email.' },
                                                { pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: " Spaces are not allowed, whitespace found at the end." },
                                                ]}>
                                                <Input
                                                    className='collab-access-form-fields'
                                                    placeholder="Type email here" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={5}>
                                            <Button htmlType="submit" disabled={accessUpdateLoader || inviteLoader} ref={formRef} className="modal-okay square font-12" >
                                                Share {inviteLoader ? <LoadingOutlined /> : ''}
                                            </Button>
                                        </Col>

                                    </Row>
                                </Form>
                            </div>
                        </TabPane>}

                    <TabPane tab="Links" key="2" >
                        <span>
                            <h4 className="manrope f-14 black-33">URL for Public Viewer</h4>
                            <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product-details/${product_id}/${localStorage.getItem("username")}` }}>
                                <Link to={'/product-details/' + product_id + '/' + localStorage.getItem("username")} target="_blank">
                                    Public Viewer
                                </Link>
                            </Paragraph>
                            {productAssetData && productAssetData['360'] &&
                                <div style={{ marginTop: 10 }}>
                                    <h4 className="manrope f-14 black-33">URL for Product 360</h4>
                                    <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product_public_viewer/product_360/${product_id}/${localStorage.getItem("username")}${productAssetData.company_id && productAssetData.company_id != "" ? ("?company_id=" + productAssetData.company_id) : ""}` }}>
                                        <Link to={'/product_public_viewer/product_360/' + product_id + '/' + localStorage.getItem("username") + (productAssetData.company_id != "" ? ("?company_id=" + productAssetData.company_id) : "")} target="_blank">
                                            Product 360
                                        </Link>
                                    </Paragraph>

                                </div>}

                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <h4 className="manrope f-14 black-33">URL for Product 3D Model</h4>
                                <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/product_public_viewer/product_3d_model/${product_id}` }}>
                                    <Link to={'/product_public_viewer/product_3d_model/' + product_id} target="_blank">
                                        Product 3D Model
                                    </Link>
                                </Paragraph>

                            </div>

                            <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <h4 className="manrope f-14 black-33">Embed in your site</h4>
                                <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: '<iframe allow-popups style="width: 700px; height: 700px;" src="' + 'http://app.all3d.ai/product-details/' + product_id + "/" + localStorage.getItem("username") + '"></iframe>' }}>
                                    <p className="modal-radio-anchor manrope f-12" style={{ color: "#999999" }} id="iframe-text">{'<iframe allow-popups style="width: 700px; height: 700px;" src="' + 'http://app.all3d.ai/product-details/' + product_id + "/" + localStorage.getItem("username") + '"></iframe>'}</p>
                                </Paragraph>
                            </div>
                        </span>
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
        <SuccessModal
            visible={successModal}
            heading={"Product Shared Successfully!"}
            onCancel={() => setSuccessModal(false)}
            footer={[
                <div className="justify-in-center">
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => { setSuccessModal(false); handleClearEmails() }}>
                        Okay
                    </Button>
                </div>
            ]}
        />
    </>)
}

export default ShareProductModal;