import React, { useState, useEffect } from "react";
import ENVIRONMENT from '../../../../environments';
import { loadStripe } from "@stripe/stripe-js";
import CustomHeader from "../../CustomHeader";
import { Layout, Button } from 'antd';
import {
  LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import "./App.css";

const { Header } = Layout;

const stripePromise = loadStripe(ENVIRONMENT.STRIPE_PUBLISH_KEY);

class ProductDisplay extends React.Component {

  state  ={
    is_loaded: false,
    invoice_name: '',
    amount: '',
    status: '',
    pay_button_loader: false
  }

  componentDidMount() {
    axios.post(ENVIRONMENT.INVOICE_GET, {
      invoice_id: this.props.invoice_id
    })
      .then(res => {
        console.log(res);
        console.log(res.data);
        this.setState({
          is_loaded: true,
          invoice_name: "Invoice#" + res.data.invoice_id + " - Issued on " + res.data.time_issued,
          amount: "$" + res.data.amount,
          external_link: res.data.external_link,
          status: res.data.status
        });
    });


  }

  payButtonhandleClick = () => {
    this.setState({
      pay_button_loader: true
    });
    this.props.handleClick();
  }

  render() {
    return (
      <span>
      {(this.state.is_loaded == true) ?
        <span>
          { (this.state.status == 'pending')?    
          <section>
            <div style={{textAlign: "center", marginTop: 30, paddingLeft: 200, paddingRight: 200}}>
              <img
                src="https://all3d.s3.amazonaws.com/static_files/all3d_logo.png"
                alt="All3d"
                style={{height: 50}}
              />
              <div className="description">
                <h3>{this.state.invoice_name}</h3>
                {(this.state.external_link != "")?
                <h3><a href={this.state.external_link} target="_blank">Details</a></h3>
                :""
                }
                <h3>{this.state.amount}</h3>
              </div>
            </div>
            <button id="checkout-button" role="link" onClick={this.props.handleClick}>
              Pay {(this.state.pay_button_loader)?<LoadingOutlined spin />:""}
            </button>
          </section>
          :""
          }
          {(this.state.status == 'verification_pending')?
          <Message message={"Payment Verification Pending for this invoice."} />
          :
          ""
          }
          {(this.state.status == 'verified')?
          <Message message={"This invoice has successfully been paid."} />
          :
          ""
          }
        </span>
        :
        <div style={{textAlign: "center", marginTop: 30}}>
          <LoadingOutlined spin />
        </div>
      }
      </span>
    )
  }
}

const Message = ({ message }) => (
    <div style={{textAlign:"center" ,marginTop: 24, padding: 24}}>
        <h5>
        {message}
        </h5>
        <Button style={{textAlign:"center" ,marginTop: 24}} type="danger" onClick={() => window.location.href = "/home"}>Goto Home Page</Button>
    </div>
);
export default function InvoicePayment(props) {
  const [message, setMessage] = useState("");
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setMessage("Please wait . . .");
      axios.post(ENVIRONMENT.INVOICE_UPDATE, {
        invoice_id: props.match.params.id,
        status: "verification_pending"
      })
        .then(res => {
          console.log(res);
          console.log(res.data);
          setMessage("ALL3D will verify your payment very soon.");
      });
    }
    if (query.get("canceled")) {
      setMessage(
        "Invoice Payment cancelled."
      );
    }
  }, []);
  const handleClick = async (event) => {
    const stripe = await stripePromise;
    const response = await fetch(ENVIRONMENT.INVOICE_PAY, {
      method: "POST",
      body: JSON.stringify({"invoice_id": props.match.params.id})
    });
    const session = await response.json();
    console.log(session)
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: JSON.parse(session).id,
    });
    if (result.error) {
      setMessage("Something went wrong, please contact Admin.");
    }
  };
  return message ? (
    <div>
    <Header className="header">
        <CustomHeader></CustomHeader>
    </Header>
    <Message message={message} />
    </div>
  ) : (
    <div>
    <Header className="header">
        <CustomHeader></CustomHeader>
    </Header>
        <ProductDisplay handleClick={handleClick} invoice_id={props.match.params.id} />
    </div>
  );
}