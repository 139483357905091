import React from 'react';
import 'antd/dist/antd.css';
import RoomsData from '../RoomsData'
import CustomerMainLayout from '../CustomerMainLayout'

class MyRoomsView extends React.Component {
    render() {
        return (
            <CustomerMainLayout selected='5'>
                <RoomsData />
            </CustomerMainLayout>
        );
    }
}

export default MyRoomsView;
