import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Row, Col, Card, Checkbox, Tooltip } from 'antd';
const type = "Image"; 

const Image = ({ image, index, moveImage, updateImageInArray }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Move the content
      if(moveImage(dragIndex, hoverIndex))
      // Update the index for dragged item directly to avoid flickering when half dragged
        item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type, id: image.id, index },
    canDrag: !image.qa_image && !image.debug_image,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  // initialize drag and drop into the element
  drag(drop(ref));

  return (
    <Col span={8}>
    <div
      ref={ref}
      className="file-item"
    >
      <Card className={image.qa_image || image.debug_image ? 'ordering-img-card qa-img-card' : 'ordering-img-card'} bordered={true} style={{borderColor: isDragging ? 'blue' : '', opacity: isDragging ? 0.5 : 1 }}>
        {image.qa_image ? 
        <Tooltip title={"Preview Images cannot be unselected"} placement="top">
          <Checkbox style={{position: 'absolute', top: 5, right: 5}} checked={image.selected} disabled={image.qa_image} onChange={(e)=>(image.selected = e.target.checked, updateImageInArray(index, image))}></Checkbox>
        </Tooltip>  :
        <Checkbox style={{position: 'absolute', top: 5, right: 5}} checked={image.selected} disabled={image.qa_image} onChange={(e)=>(image.selected = e.target.checked, updateImageInArray(index, image))}></Checkbox>
      }
        
        <div className="tag-bg"><span className="tag-txt">{image.qa_image ? 'Preview image' : image.debug_image ? 'Debug' : 'Rig image'}</span></div>
        <img alt={`img - ${image.id}`} src={image.src} className="ordering-img"/>
      </Card>
    </div>
    </Col>
  );
};

const ImageList = ({images, moveImage, setImages, updateImageInArray}) => {
  setImages(images);
  console.log('images = ', images);

  const renderImage = (image, index) => {
    return (
          <Image image={image} index={index} key={`${image.id}-image`} moveImage={moveImage} updateImageInArray={updateImageInArray}/>
    );
  };

  return (
    <Row type='flex'>
      <Col span={24} style={{display: 'contents'}}>
        {images.map(renderImage)}
      </Col>
    </Row>
  )
  
  
  
};

export default ImageList;