import React, { useEffect, useState } from "react";
import AdminMainLayout from "../AdminMainLayout";
import { Row, Col, Card } from "antd";
import ENVIRONMENT from "../../../../environments";
import 
{ 
    AuditOutlined, 
    BookOutlined, 
    BorderOutlined, 
    ContainerOutlined, 
    ExclamationCircleOutlined, 
    GatewayOutlined, 
    LineChartOutlined, 
    SlidersOutlined, 
} from '@ant-design/icons';
import DottedLoader from "../../DottedLoader";
import axios from "axios";

const AdminHome = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const iconSize = 50;
    const iconColor = 'black';
    const iconStyle = {fontSize: iconSize, color: iconColor}
    const [systemManagementOptions, setSystemManagementOptions] = useState([
        {
            "name"          : "Products",
            "icon"          : <GatewayOutlined style={iconStyle} />,
            "uri"           : "/admin_products",
            "description"   : "(0 Products to QA)",
            "type"          : "product",
        },
        {
            "name"          : "Spaces",
            "icon"          : <ContainerOutlined style={iconStyle} />,
            "uri"           : "/admin_rooms",
            "description"   : "(0 Spaces to QA)",
            "type"          : "space",
        },
        {
            "name"          : "Materials",
            "icon"          : <BorderOutlined style={iconStyle} />,
            "uri"           : "/admin_materials",
            "description"   : "(0 Materials to QA)",
            "type"          : "material",
        },
        {
            "name"          : "Complaints",
            "icon"          : <ExclamationCircleOutlined style={iconStyle} />,
            "uri"           : "/complaints",
            "description"   : "(0 Complaints to QA)",
            "type"          : "complaints",
        },
        {
            "name"          : "Artist Job Desk",
            "icon"          : <BookOutlined style={iconStyle} />,
            "uri"           : "/admin_artist_job_desk",
            "description"   : "(0 Unassigned Products)",
            "type"          : "artist_job",
        },
        {
            "name"          : "Automation Jobs",
            "icon"          : <AuditOutlined style={iconStyle} />,
            "uri"           : "/automation_requests",
            "description"   : "(0 Jobs in Progress)",
            "type"          : "automation",
        },
        {
            "name"          : "System Management",
            "icon"          : <SlidersOutlined style={iconStyle} />,
            "uri"           : "/system_management",
            "description"   : "(Test Renders, Store, etc)",
            "type"          : "sys_mng",
        },
        {
            "name"          : "User Management",
            "icon"          : <img style={{width: iconSize, height: iconSize, color: iconColor}} src={require("../../../../icons/UserSettings.svg")}></img>,
            "uri"           : "/user_management",
            "description"   : "(Customers, MSPs, Artists)",
            "type"          : "user_mng",
        },
        {
            "name"          : "Analytics",
            "icon"          : <LineChartOutlined style={iconStyle} />,
            "uri"           : "/admin_analytics",
            "description"   : "(Throughput, Automation, etc)",
            "type"          : "analytics",
        },
    ]);

    const getAdminHome = () => {
        axios.post(ENVIRONMENT.GET_ADMIN_HOME, {}).then((response) => {
            const responseData = response.data;
            let options = systemManagementOptions;
            responseData.map((request) => {
                let firstIndex = options.findIndex(obj => obj.type === request.type);
                options[firstIndex].description = `(${request.description})`
            });
            setSystemManagementOptions(options);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getAdminHome()
    }, []);

    return (
        <>
        <AdminMainLayout selected={"1"} color={true}>
            {isLoading ? <DottedLoader/> :
            <div>
                <div style={{marginTop: 30}}>
                    <Row gutter={[10,10]}>
                        {
                            systemManagementOptions.map ( option =>
                                <Col span={6}>
                                    <Card 
                                    style={{padding:50}} 
                                    bodyStyle={{textAlign:"center"}}
                                    hoverable={true}
                                    onClick={ () => {
                                        props.history.push(option.uri);
                                    }}>
                                        {option.icon}
                                        <span style={{marginTop: 20}}>
                                            <h2 style={{marginTop: 20}} className="manrope f-16 black-14 w-600 justify-in-center">{option.name}</h2>
                                            <h2 style={{marginTop: 5}} className="manrope f-12 black-14 w-600 justify-in-center">{option.description}</h2>
                                        </span>
                                    </Card>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            </div>
        }
        </AdminMainLayout>
        </>
    );
};

export default AdminHome;