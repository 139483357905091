import React, { useCallback, useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import ENVIRONMENT from "../../../../environments";

import ImageList from "../ImageList";

const backendForDND = HTML5Backend;
let last_draggable_index = 0;

const ImageOrdering =({props, images, setImages}) => {

  const imageExistInList = (list, img_url) => {
    let already_exist = false;
    for(let i=0; i<list.length && !already_exist; i++){
      if(list[i].src == img_url)
        already_exist = true;
    }
    return already_exist;
  }

  useEffect(() => {
    console.log('current state of images = ', images);
    let imgs = [];
    if(images.length ==0){
      if(props.productReducer.product && props.productReducer.product.lighting_renders){
        // add the lighting rig images
        props.productReducer.product.lighting_renders.map((item, index) => {
            item = item.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform));
          if(!imageExistInList(imgs, item)){
            imgs.push({
              'id': index,
              'src': item,
              'selected': true,
              'qa_image': false
            })
          }
        })
        setImages(imgs);
        last_draggable_index = imgs.length-1;
      }
      if(props.productReducer.product && props.productReducer.product['2d']){
        // add the QA Images at the end of the list
        props.productReducer.product['2d'].map((item, index) => {
          if(!props.productReducer.product.lighting_renders.includes(item)){
            item = item.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(props.productReducer.product.platform));
            if(!imageExistInList(imgs, item)){
              let img_details = {
                'id': index + imgs.length,
                'src': item,
                'selected': true,
              }
              if(item.includes('_Debug')){
                img_details['debug_image'] = true;
                img_details['selected'] = false;
              }
              else
              {
                img_details['qa_image'] = true;
              }
              imgs.push(img_details);
            }
          }
          })
          setImages(imgs);
      }
  }
  }, [props])



  const moveImage = (dragIndex, hoverIndex) => {
    const draggedImage = images[dragIndex];
    if(images[hoverIndex].qa_image || images[hoverIndex].debug_image){
      console.log('returning')
      return false;
    }
    setImages(
      update(images, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, draggedImage]]
      })
    );
    return true;
  };

  const updateImageInArray = (index, image) => {
    // image will be the image object(dict)
    setImages(
      update(images, {
        $splice: [[index, 1], [index, 0, image]]
      })
    );
  }

  return (
      <DndProvider backend={backendForDND}>
        <ImageList images={images} moveImage={moveImage} setImages={setImages} updateImageInArray={updateImageInArray}/>
      </DndProvider>
  );
}

export default ImageOrdering;
