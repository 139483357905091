import React, { useState, useEffect } from "react";
import { Row, Col, Table, Radio, Card } from "antd";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import useTableFilter from "../../UseTableFilter/useTableFilter";

const Actions = {
  get_models_sla_data: "get_models_sla_data",
};

const SLAHealthReport = () => {
  const [modelType, setModelType] = useState("Product");
  const [slaHeathDataSource, setSlaHeathDataSource] = useState(
    {
      'Product' : [],
      'Space' : [],
      'Material' : []
    }
  );
  const [isSLAHealthDataLoading, setIsSLAHealthDataLoading] = useState(false);
  const [getColumnSearchProps] = useTableFilter();
  const [currentModelTypeData, setCurrentModelTypeData] = useState([]);
  const [companyFilterObjects, setCompanyFilterObjects] = useState([]);
  
  const changeModelType = (e) => {
    setModelType(e.target.value);
  };

  const setCompanyDataFilters = () => {
    let filterCompanyObjects = [];
    setCompanyFilterObjects([]);
    currentModelTypeData.forEach ( model => {
      if (!filterCompanyObjects.find(obj => obj.value === model.company_name)) {
        filterCompanyObjects.push({
          text: model.company_name,
          value: model.company_name,
        })
      }
    })
    setCompanyFilterObjects(filterCompanyObjects)
  }

  useEffect(() => {
    setCompanyDataFilters()
  }, [currentModelTypeData])

  useEffect(() => {
    setCurrentModelTypeData(slaHeathDataSource[modelType])
  }, [modelType]);

  const getAppliedFilterValue = (filter) => {
    // return already applied filter value or null
    const url = new URL(window.location);
    let value = null;
    value = url.searchParams.get(filter)
    if(value)
      value = value.split(',')
    return value
  }

  const getSlaHealthDataColumns = () => {
    let columns = [
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      filters: companyFilterObjects,
      defaultFilteredValue: getAppliedFilterValue('company_name'),
      onFilter: (value, record) => record.company_name === value,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Model ID",
      dataIndex: "model_id",
      key: "model_id",
      ...getColumnSearchProps("model_id"),
      render: (text, record) => (
        <a
          className="onhover-underline"
          href={getModelLink(text, record.model_type)}>
        {text}
        </a>
      ),
    },
    {
      title: "Exceeded SLA Time",
      dataIndex: "exceeded_time",
      key: "exceeded_time",
      render: (text) => text + ' days over',
      sorter: (a, b) => a.exceeded_time - b.exceeded_time,
    },
  ]
  return columns
}

  useEffect(() => {
    getSLAHealthData();
  }, []);

  const getSLAHealthData = () => {
    let payload = {
      action: Actions["get_models_sla_data"]
    };
    console.log(payload);
    setIsSLAHealthDataLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_SLA, payload).then((res) => {
      setSlaHeathDataSource(res.data);
      setCurrentModelTypeData(res.data['Product'])
      setIsSLAHealthDataLoading(false);
    });
  };

  const getModelLink = (id, model_type)  => {
    if (model_type === 'Material') {
      return '/admin_materials/' + id
    } 
    else if (model_type === 'Space') {
      return '/admin_rooms/' + id
    }
    else {
      return '/admin_products/' + id
    }
  };

  return (
    <>
      <Card
        className="no-hover section-container"
        size="small"
        title={
          <Row className="justify-in-start">
            <Col span={24}>
              <span className="sub-heading">System SLA Health</span>
            </Col>
          </Row>
        }
      >
        <Row className="justify-in-start mt-20" gutter={16}>
          <Col span={15}>
            <Radio.Group
              value={modelType}
              onChange={changeModelType}
              size="medium"
              className="manrope f-14 black-55"
            >
              <Radio.Button value="Product">Product</Radio.Button>
              <Radio.Button value="Space">Space</Radio.Button>
              <Radio.Button value="Material">Material</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row className="mb-20"
         style={{paddingBottom:'20px'}}>
          <div className="chart-container">
          <Row>
          <Col span={24}>
            {isSLAHealthDataLoading ? (
              <LoadingOutlined className="loading-center" />
            ) : (
              <Table
                dataSource={currentModelTypeData}
                columns={getSlaHealthDataColumns()}
                scroll
                style={{ overflowX: "scroll", marginTop: 20 }}
              />
            )}
          </Col>
        </Row>
          </div>
        </Row>
      </Card>
    </>
  );
};

export default SLAHealthReport;
