import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CodeOutlined } from '@ant-design/icons';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, Row, Form } from "antd";
import {Link} from "react-router-dom";

class MyConfirmForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      verificationCode: '',
    };
  }

  onSubmit = (event) => {
    console.log(this.props.verifyUser)
    if (this.props.verifyUser != undefined) {
      let error = this.props.verifyUser(this.state.verificationCode);
      if (error != null) {
        this.setState({ error });
      }
    }
    else {
      this.props.onSubmit(this.state.verificationCode)
        .then((user) => {
          if (this.props.loginUser != undefined) {
            this.props.loginUser();
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
    
  }

  performResendCode = () => {
    this.props.onResend()
      .then((user) => {
        this.setState({ error: 'Code resent' });
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  onResend = (event) => {
    event.preventDefault();
    if (this.props.verifyUser == undefined)
    {
      this.performResendCode();
    } else {
      this.props.sendVerificationCode();
    }
    

  }

  changeVerificationCode = (event) => {
    this.setState({ verificationCode: event.target.value });
  }

  performCancel = () => {
    if (this.props.onCancel != undefined) {
      this.props.onCancel();
    } else {
      window.location.href = "/";
    }
  }

  render = () => {
    // const { getFieldDecorator } = this.props.form;
    const formDiv = {
      'display': 'flex',
      'justify-content': 'space-around',
      'margin-top': '40px',
    }

    const formStyle = {
      'background-color': 'white',
      'border-radius': '5px'

    }

    const logoStyle = {
      'display': 'flex',
      'justify-content': 'space-around',
      'margin-bottom': '30px'
    }
    return (
      <Row>
        <Col span={12}>
          <img className="branding-image" src={require("../../../../assets/images/login-branding.jpg")} />
        </Col>
        <Col span={12}>
          <div className="input-form verify-email-form">
                  <h3 className="text-center text-login-logo"> <span>All</span><span className="text-red">3D</span> </h3>
                  <p>You must verify your email address. Please check your email for code</p>
                  <Form onFinish={this.onSubmit} onFinishFailed={(e) => console.log(e) } className="login-form custom-frm">
                    <Form.Item
                      name="Verification Code"
                      rules= {[{ required: true, message: 'Input verification code!' },
                            {pattern: '^[0-9]{1,6}$', message: 'Verification Code must consist of 6 digits'},
                            {pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: " Spaces are not allowed, whitespace found at the end."},
                      ]}
                    >
                      <Input
                              onChange={this.changeVerificationCode}
                              prefix={<CodeOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                              placeholder="Verification Code"
                          />
                    </Form.Item>

                    <Button type="danger" htmlType="submit" className="login-form-button">
                     Submit
                    </Button>

                    <Form.Item>
                      <Button onClick={(e) => this.onResend(e)} type="danger" htmlType="submit" className="login-form-button">
                        Resend
                      </Button>

                      <Button onClick={() => this.performCancel()} type="danger" htmlType="submit" className="login-form-button">
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
            <div className="text-danger text-center"> {(this.props.codeError != undefined) ? this.props.codeError:this.state.error}</div>
          </div>
        </Col>
      </Row>
    );
  }
}
MyConfirmForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onResend: PropTypes.func,
  error: PropTypes.string,
};

const mapStateToProps = state => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});


const ConfirmForm = connect(mapStateToProps, null)(MyConfirmForm);

// export default  Form.create({ name: 'normal_login' })(ConfirmForm);
export default ConfirmForm;
