import * as THREE from 'three'
import Constants from "../Constants.js"
import {radians_to_degrees} from "../HelperFunctions.js"
import {degrees_to_radians} from "../HelperFunctions.js"

/**
 * Create a new sun controls instance.
 * Sun controls are used for controlling the position & direction of the sun light in scene. 
 * @param {THREE.Scene} object - The object to use as sun object.
 * @param {Dictionary} managersDict - A dictionary that contains all the available managers.
 */
export default function SunControls ( object, managersDict ) {
	
	// Private members
	var scope = this;

	var changeEvent = { type: 'change' };
	var startEvent = { type: 'start' };
	var endEvent = { type: 'end' };

	// current position in spherical coordinates
	var spherical = new THREE.Spherical();

	// Public members
	this.object = object;

	// Set to false to disable this control
	this.enabled = true;

	// "target" sets the location of focus, where the object orbits around
	this.target = null;

	// How far you can orbit vertically, upper and lower limits.
	// Range is 0 to Math.PI radians.
	this.minPolarAngle = 0; // radians
	this.maxPolarAngle = Math.PI; // radians

	// How far you can orbit horizontally, upper and lower limits.
	// If set, must be a sub-interval of the interval [ - Math.PI, Math.PI ].
	this.minAzimuthAngle = - Infinity; // radians
	this.maxAzimuthAngle = Infinity; // radians

	// for reset
	this.target0 = null;
	this.position0 = this.object.position.clone();

	// Reference to space manager
	this.spaceManager = null;

	// Private Methods
	/**
	 * Initialize sun controls.
	 * @param {Object} managersDict - A dictionary that contains all the available managers.
	 */
	this.init = function(managersDict) {
		this.setupRequiredManagers(managersDict);	

		scope.target = scope.spaceManager.sun.targetPosition;
		scope.target0 = scope.target.clone();

		let sphericalAngles = scope.getSphericalAngles();

		scope.spaceManager.sun.azimuthalAngle = sphericalAngles.azimuthal;
		scope.spaceManager.sun.polarAngle 	= sphericalAngles.polar;
		
		this.update(); // force an update at start
	}

	/**
     * Setup the managers required by this module to work.
	 * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    this.setupRequiredManagers = function (managersDict) {
        scope.spaceManager = managersDict[Constants.Manager.SpaceManager];
	}

	/**
	 * Update controls according to the current values.
	 */
	this.update = function ( updatedTheta = null, updatedPhi = null ) {

		var offset = new THREE.Vector3();

		// so camera.up is the orbit axis
		var quat = new THREE.Quaternion().setFromUnitVectors( object.up, new THREE.Vector3( 0, 1, 0 ) );
		var quatInverse = quat.clone().inverse();

		var lastPosition = new THREE.Vector3();
		var lastQuaternion = new THREE.Quaternion();

		var position = scope.object.position;

		offset.copy( position ).sub( scope.target );

		// rotate offset to "y-axis-is-up" space
		offset.applyQuaternion( quat );

		// angle from z-axis around y-axis
		spherical.setFromVector3( offset );

		if ( updatedTheta != null ) {
			spherical.theta = updatedTheta;			
		}

		if ( updatedPhi != null ) {
			spherical.phi = updatedPhi;			
		}

		// restrict theta to be between desired limits
		spherical.theta = Math.max( scope.minAzimuthAngle, Math.min( scope.maxAzimuthAngle, spherical.theta ) );

		// restrict phi to be between desired limits
		spherical.phi = Math.max( scope.minPolarAngle, Math.min( scope.maxPolarAngle, spherical.phi ) );

		spherical.makeSafe();

		// spherical.radius = 1.0;

		offset.setFromSpherical( spherical );

		// rotate offset back to "Sun-up-vector-is-up" space
		offset.applyQuaternion( quatInverse );

		position.copy( scope.target ).add( offset );

		scope.object.lookAt( scope.target );
	};
	
	// Public methods
	/**
	 * Get current polar angle.
	 */
	this.getPolarAngle = function () {
		return spherical.phi;
	};

	/**
	 * Get current azimuthal angle.
	 */
	this.getAzimuthalAngle = function () {
		return spherical.theta;
	};

	/**
	 * Get current spherical angle.
	 */
	this.getSphericalAngles = function () {
		return { azimuthal : radians_to_degrees( spherical.theta ), polar : radians_to_degrees( spherical.phi ) };
	}

	/**
	 * Update controls according to some azimuthal angle.
	 * @param {Number} angle - Angle to use.
	 */
	this.setAzimuthalAngle = function ( angle ) {
		let radAngle = degrees_to_radians ( angle );
		this.update(radAngle, null);

		scope.spaceManager.sun.azimuthalAngle = angle;
	}

	/**
	 * Update controls according to some polar angle.
	 * @param {Number} angle - Angle to use.
	 */
	this.setPolarAngle = function ( angle ) {
		let radAngle = degrees_to_radians ( angle );
		this.update(null, radAngle);	

		scope.spaceManager.sun.polarAngle = angle;
	}

	/**
	 * Reset controls to their default values.
	 */
	this.reset = function () {
		scope.target.copy( scope.target0 );
		scope.object.position.copy( scope.position0 );

		scope.object.updateProjectionMatrix();
		scope.dispatchEvent( changeEvent );

		this.update();
	};

	this.dispose = function () {
	};

	this.init(managersDict);
};
