import React from "react";
import { Layout } from "antd";
import CostSummary from "../CostSummary/CostSummary";
import CostMonthlyBreakdown from "../CostMonthlyBreakdown/CostMonthlyBreakdown";

const { Content } = Layout;

const ModelingCostReport = () => {
  return (
    <>
      <Layout>
        <Content className="analytics-content">
            <CostMonthlyBreakdown />
            <CostSummary />
        </Content>
      </Layout>
    </>
  );
};

export default ModelingCostReport;