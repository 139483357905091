import React, { useContext, useState } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import ProductPageConstants from './ProductPageConstants.js';
import FileConstants from '../../../../FileConstants';
import ProductVariantGrid from './ProductVariantGrid';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const ProductVariantsContainer = () => {
    const { openVariationModal, variantModels } = useContext(CustomerProductContext);
    const [carousel, setCarousel] = useState(null);

    return (<>
        {variantModels && variantModels.length > 0 &&
            <Row className='product-variant-container'>
                <Col span={24}>
                    <div className='display-flex j-s-b a-c w-100 mb-30'>
                        <h3 className='mb-0 manrope f-24 black-26'>Product Variants</h3>
                        <Tooltip title={"You can make adjustements like, change the colour, size, textures, etcetera and request a variation of this model."}>
                            <Button onClick={openVariationModal}
                                className='product-btn br-4 f-14 red'><PlusCircleFilled />Create New Variant</Button>
                        </Tooltip>
                    </div>

                    <Carousel
                        ref={(el) => { setCarousel(el) }}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={ProductPageConstants.variant_grid_break_points}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        arrows={true}
                        autoPlaySpeed={8000}
                        keyBoardControl={false}
                        containerClass="carousel-container-store variant-grid">
                        {variantModels.map((product, index) => (
                            <ProductVariantGrid
                                key={index}
                                product={product}
                            />
                        ))}
                    </Carousel>
                </Col>
            </Row>
        }</>);
};

export default ProductVariantsContainer;