import React, { useState, useEffect } from 'react';
import { Card, Tooltip, Button, Row, Col, Input, Modal, message, Checkbox, Popover, Divider, Pagination, Tag, Drawer, Select, TreeSelect, Form, Tabs, Alert } from 'antd';
import { CheckOutlined, LoadingOutlined, PlusOutlined, ShoppingCartOutlined, ContainerOutlined, FilterOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom'
import ENVIRONMENT from '../../../../environments'
import { connect } from 'react-redux';
import { listProducts, listForStoreProducts, LIST_FOR_STORE_PRODUCT } from '../../../../redux/actions';
import graphOptions from "../../../../utils/graph"
import barOptionsConfig from "../../../../utils/barchart";
import * as Utilities from '../../Utilities';
import FileConstants from '../../../../FileConstants'
import axios from 'axios';
import SuccessModal from '../../SuccessModal/SuccessModal';
import { Grid, AutoSizer } from 'react-virtualized';
import ColorSelect from '../../FormUtilities/ColorSelect';
import StyleCategory from '../../FormUtilities/StyleCategorySelect';
import * as Styles from '../../../../Styles';
import DottedLoader from '../../DottedLoader';

const { TabPane } = Tabs;

let barOptions = barOptionsConfig;
let options = graphOptions;

const COMPANY_ID = FileConstants.COMPANY_ID;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
const IS_MSPROVIDER = FileConstants.isMSProvider;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;

const { Option } = Select;
const categoryStyle = Styles.categoryStyle;

const RoomsListVirtualized = (props) => {
    const [columnCount, setColumnCount] = useState(4);
    const [rowCount, setRowCount] = useState(0);
    const [roomsData, setRoomsData] = useState([]);
    const maxColWidth = 280;

    useEffect(() => {
        let data = [];

        if (props.roomSearch == '' && !props.roomFiltersApplied) {
            data = [...props.rooms_data];
        } else if (props.roomSearch != '' || props.roomFiltersApplied) {
            if(props.roomSearch != '' && !props.roomFiltersApplied){
                data = Utilities.getRelevanceBasedOrderedSearchResult(props.roomSearch, props.rooms_data)
            } else if (props.roomSearch == '' && props.roomFiltersApplied) {
                data = props.rooms_data.filter(props.returnSearchValue)
            } else if (props.roomSearch != '' && props.roomFiltersApplied) {
                let filter_data = props.rooms_data.filter(props.returnSearchValue)
                data = Utilities.getRelevanceBasedOrderedSearchResult(props.roomSearch, filter_data)
            }
        }
        setRoomsData(data)
    },[props.roomSearch, props.roomFiltersApplied, props.rooms_data])
    
    useEffect(() => {
        setRowCount(Math.ceil(roomsData.length / columnCount));
      }, [roomsData])

    // column widths are stored in a state by index
    const [columnWidths, setColumnWidths] = useState(() => {
        const containerWidth = window.innerWidth - 80; // Get the width of the container element
        const columnWidthPercentage = 25; // Set the desired column width percentage

        const columnWidth = ((containerWidth * columnWidthPercentage) / 100) - 24;
        const initialWidths = new Array(columnCount).fill(columnWidth); // Set initial column widths here
        return initialWidths;
    });

    // function to handle column width changes
    const handleColumnWidthChange = ({ index, width }) => {
        setColumnWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[index] = width;
            return newWidths;
        });
    };

    const getColumnWidth = ({ index }) => columnWidths[index];

    const getEstimatedRowSize = ({ index }) => {
        const itemIndex = index * columnCount;
        const item = roomsData[itemIndex] && roomsData[itemIndex];
        if (item) {
            const rowHeight = 366;
            return rowHeight;
        }
        return 366;
    };

    // function to render an individual cell in the grid
    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const itemIndex = rowIndex * columnCount + columnIndex;
        const ele = roomsData[itemIndex] && roomsData[itemIndex];

        if (!ele) {
            return null;
        }

        return (
            <div key={key} style={style}>
                <Card bodyStyle={{ padding: "10px" }} className="card-shadow-product" bordered={false} style={{ marginRight: "16px", marginBottom: "16px", cursor: "pointer", border: "solid 1px #e3e3e3" }}>
                    {(props.roomsAdded.includes(ele.room_id)) ?
                        <CheckOutlined title="Added To Collection"
                            className="group-settings plus-icon"
                        />
                        :
                        <span>
                            {(props.roomsLoading.includes(ele.room_id)) ?
                                <LoadingOutlined
                                    title="Adding To Collection"
                                    className="group-settings plus-icon"
                                    spin
                                />
                                :
                                <PlusOutlined
                                    title="Add To Collection"
                                    className="group-settings plus-icon"
                                    onClick={() => props.setLoadingRooms(ele, props.roomListType)} />
                            }
                        </span>
                    }
                    <a className='suggested-product' style={{ zIndex: "1" }}>
                        <div className="box">
                            {ele.thumbnail ? <img src={ele.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(ele.platform))} style={{ objectFit: 'cover', width: "100%", height: 280 }} />
                                :
                                <React.Fragment>
                                    <img src={require("../../../../assets/images/space_incomplete.svg")} style={{ objectFit: 'cover', width: "100%", height: 280, opacity: "0.5", filter: "blur(0px)" }} />
                                    <div className="incomplete-request-text">
                                        <span>
                                            Image not Found
                                        </span>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        <div className='justify-in-start' style={{ margin: "12px 12px 8px 12px" }}>
                            <Tooltip title={<span className='manrope f-12 white'>{ele.room_name ?? ele.name}</span>}>
                                <span className='manrope f-14 w-500 black-00 clamp-text w-50' >
                                    {ele.room_name ?? ele.name}
                                </span>
                            </Tooltip>
                            <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(ele.last_modified, 'tooltip')}</span>}>
                                <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                                    <img src='/img/carbon_time.svg' style={{ marginRight: 2 }} />{Utilities.timestampToTimeSince(ele.last_modified)}
                                </span>
                            </Tooltip>
                        </div>
                        <div className="justify-in-start" style={{ marginLeft: "12px", marginRight: "12px", marginBottom: "12px", textAlign: "left" }}>
                            <span className="manrope f-12 grey-77 capitalize" style={{ marginRight: 4 }}>Category:</span>
                            <span className="manrope f-12 black-33 capitalize">{ele.category}</span>
                        </div>

                    </a>
                </Card>
            </div>
        );
    };

    return (
        <>
            {props.isLoading ?
                <div style={{ textAlign: "center" }}> <LoadingOutlined style={{ textAlign: "center", fontSize: "20px" }} size="large" spin /> </div>
                :
                <div style={{ width: '100%', height: 'calc(100vh - 250px)' }}>
                    <AutoSizer>
                        {({ height, width }) => {
                            const colCount =
                                (Math.floor(width / maxColWidth) > 4 ?
                                4 :
                                Math.floor(width / maxColWidth)) || 1;
                            setColumnCount(colCount);
                            setRowCount(Math.ceil(roomsData.length / colCount));
                            const colWidth = width / colCount;
                            return(
                            <Grid
                                className='product-tab-content-scrollable'
                                cellRenderer={cellRenderer}
                                columnCount={columnCount}
                                columnWidth={colWidth}
                                height={height}
                                rowCount={rowCount}
                                estimatedRowSize={100}
                                rowHeight={getEstimatedRowSize}
                                width={width + 10}
                                onColumnResized={handleColumnWidthChange}
                            />
                            )
                        }}
                    </AutoSizer>
                </div>
            }
        </>
    );
};


class RoomModels extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rooms_data: [],
            original_rooms_data: [],
            store_rooms_data: [],
            my_rooms: [],
            roomsAdded: [],
            roomsLoading: [],
            isLoading: false,
            currentRoomTab: '5'
        };
    }

    getRoomPayload = () => {
        let payload = {
            "required_fields": ["room_id", "room_name", "brand_id", "category", "dimensions",
                "customer_username", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "company_id", "platform"],
        }

        payload["order_by"] = "last_modified_stamp desc"

        let filter_string = "";
        let customer_username = "customer_username__exact='" + localStorage.getItem('username') + "'"

        let company_id = "";
        let model_status = "model_status__in=[5]"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + localStorage.getItem('username') + "\"%'"


        if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("room")) {
            company_id = "company_id__exact=" + COMPANY_ID + "||additional_company_ids__contains='" + COMPANY_ID + "'"
            filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")"
        } else {
            filter_string = "(" + customer_username + "&&" + is_hidden + ")"
        }
        filter_string = filter_string + "&&(" + model_status + ")" + "||(" + has_access_to + ")"
        payload['filter_string'] = filter_string
        return payload;
    }

    getMSPRoomPayload = () => {
        let payload = {
            "required_fields": ["room_id", "room_name", "brand_id", "category", "customer_username",
                "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "dimensions", "company_id", "is_shared", "platform"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        let filter_string = "";
        let customer_username = "customer_username__exact='" + localStorage.getItem('managed_customer_username') + "'"
        let company_id = "";
        let model_status = "model_status__in=[3,4,5]"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + localStorage.getItem('managed_customer_username') + "\"%'"

        if (MANAGED_COMPANY_ID) {
            company_id = "company_id__exact=" + parseInt(MANAGED_COMPANY_ID) + "||additional_company_ids__contains='" + parseInt(MANAGED_COMPANY_ID) + "'"
            filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")"
        } else {
            filter_string = "(" + customer_username + "&&" + is_hidden + ")"
        }
        filter_string = filter_string + "&&(" + model_status + ")" + "||(" + has_access_to + ")"

        payload['filter_string'] = filter_string

        return payload;
    }

    addThumbnailPrefix = (rooms) => {
        const tranformedData = rooms.map((room) => {
            return {
                ...room,
                room_id: room.id,
                thumbnail: ENVIRONMENT.getBaseURL(room.platform) + ENVIRONMENT.SPACE_THUMBNAIL_URI + room.id + "/" + room.thumbnail
            }
        })
        console.log(tranformedData,"tranformedData");
        return tranformedData;
    }

    getRoomsList = (spaces = []) => {
        let payload = this.getRoomPayload();
        axios.post(ENVIRONMENT.LIST_ROOMS, payload)
            .then((response) => {
                if (response.data) {
                    let current_spaces = response.data;
                    if (spaces.length > 0) {
                        current_spaces = current_spaces.concat(spaces);
                    }
                    this.setState({
                        rooms_data: Utilities.getRoomsDataWithKeywords(current_spaces),
                        original_rooms_data: Utilities.getRoomsDataWithKeywords(current_spaces),
                        isLoading: false,
                    }, () => {
                        this.getStoreRoomsList();
                    });
                }
            })
            .catch((error) => {
            });
    }

    getProjectEntitiesPayload = (project_id) => {
        let payload = {
          order_by: "id desc",
          required_fields: [
            "id",
            "name",
            "customer_username",
            "company_id",
            "thumbnail",
            "status",
            "project_id",
            "entity_type",
            "product_model_type",
            "last_modified",
            "category",
            "platform",
          ],
          filter_string: `(project_id__exact='${project_id}'&&entity_type__exact='room'&&status__in=[5, 4])`,
        };
    
        return payload;
    };

    getProjectDataWithKeywords = (projects) => {
        const result = projects.map((obj) => {
          let keywords = [];
    
          for (const keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, ' ').split(' ')) {
            if (!keywords.includes(keyword)) {
              if (keyword.length > 1) {
                keywords.push(keyword.toLowerCase());
              }
            }
          }
    
          for (const keyword of obj.category?.replace(/[^a-zA-Z0-9 ]/g, ' ')?.split(' ')) {
            if (!keywords.includes(keyword)) {
              if (keyword.length > 1) {
                keywords.push(keyword.toLowerCase());
              }
            }
          }
    
          return { ...obj, 'keywords': keywords, room_name: obj.name};
        });
        return result;
      };

    getProjectRoomsList = (project_id) => {
        let payload = this.getProjectEntitiesPayload(project_id);
        axios.post(ENVIRONMENT.GET_PROJECTS_ENTITY_BATCH, payload)
            .then((response) => {
                if (response.data) {
                    let current_spaces = this.addThumbnailPrefix(response.data);
                    let spaces_with_keywords = this.getProjectDataWithKeywords(current_spaces);
                    this.setState({
                        rooms_data: spaces_with_keywords,
                        original_rooms_data: spaces_with_keywords,
                        isLoading: false,
                    }, () => {
                        this.getStoreRoomsList();
                    });
                }
            })
            .catch((error) => {
            });
    }

    getCustomerDataForMsprovider = () => {
        let payload = this.getMSPRoomPayload();
        axios.post(ENVIRONMENT.LIST_ROOMS, payload)
            .then(res => {
                if (res.data) {
                    this.setState({
                        rooms_data: Utilities.getRoomsDataWithKeywords(res.data),
                        original_rooms_data: Utilities.getRoomsDataWithKeywords(res.data),
                        isLoading: false,
                    }, () => {
                        this.getStoreRoomsList();
                    });
                    this.getRoomsList(res.data);
                }
            });
    }

    getStoreRoomsList = () => {
        axios.post(ENVIRONMENT.STORE_LIST, {
            item_type: "room"
        })
            .then((response) => {
                let rooms = response.data
                rooms = rooms.filter(room => !this.state.rooms_data.filter(library_room => library_room.room_id === room.room_id).length);
                this.setState({
                    store_rooms_data: Utilities.getRoomsDataWithKeywords(rooms),
                    isLoading: false
                });
                this.props.setRoomsDataLength(rooms.length)
            })
            .catch((error) => {
            });
    }

    getCollectionRooms = () => {
        axios.post(ENVIRONMENT.COLLECTION_GET_ROOMS, {
            collection_id: this.props.getCollectionId()?.toString()
        })
            .then((response) => {
                for (var i = 0; i < response.data.length; i++) {
                    this.setAlreadyRoomsAdded(response.data[i].room_id);
                }

            })
            .catch((error) => {

            });
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        const project_id = new URLSearchParams(window.location.search).get("project_id");
        console.log(project_id,"project id");
        if(!this.props.getCollectionId())
        {
            window.location.href = 'projects/' + project_id
        }
        if (project_id != null) {
            this.getProjectRoomsList(project_id);
        } else if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null || MANAGED_COMPANY_ID != null)) {
            this.getCustomerDataForMsprovider();
        } else {
            this.getRoomsList();
        }
        this.getCollectionRooms();
        this.props.setRoomsVisited(true);
    }

    onRoomSelect = (e) => {
        this.props.setRoomId(e.target.id);
        this.props.next();
    }


    setLoadingRooms = (room, type) => {

        let rooms = room.room_id;
        this.setState({
            roomsLoading: [rooms]
        });
        this.addRoomToCollection(room, type);
    }

    setAlreadyRoomsAdded = (room_id) => {
        let rooms = this.state.roomsAdded;
        rooms.push(room_id);
        this.setState({
            roomsAdded: rooms
        });
        this.props.setRoomsAdded(rooms);
    }

    setRoomsAdded = (room_id) => {
        let rooms = [room_id];
        this.setState({
            roomsAdded: rooms,
            roomsLoading: []
        });
        this.props.setRoomsAdded(rooms);
    }

    addRoomToCollectionCallback = (is_succesful, room_id) => {
        if (is_succesful) {
            this.setRoomsAdded(room_id);
        }
        else {
            this.setState({
                roomsLoading: []
            })
        }
    }

    addRoomToCollection = (room, type) => {
        let payload = {}
        payload.room_id = room.room_id;
        payload.collection_id = this.props.getCollectionId();
        payload.room = room;
        let is_library_item = false;
        if (type == 'library') {
            is_library_item = true
        }
        this.props.processRequest(payload, "room", is_library_item, this.addRoomToCollectionCallback)
    }

    onRoomTabChange = (key) => {
        let currentDisplayedData = this.getFilteredLibraryData(this.state.original_rooms_data, parseInt(key));
        this.setState({
            rooms_data: currentDisplayedData,
            currentRoomTab: key
        })
    }

    getFilteredLibraryData = (currentData, modelStatus) => {
        let newData = []
        currentData.filter ((item) => {
            if (item.model_status == modelStatus) {
                newData.push(item)
            }
        })
        return newData
    }

    getNoDataDiv = () => {
        let message = ""
        if (this.state.currentRoomTab == "5") {
            message = `There are no spaces in ${MANAGED_CUSTOMER_USERNAME}'s Library.`
        } else if (this.state.currentRoomTab == "3") {
            message = `There are no spaces in Admin QA for ${MANAGED_CUSTOMER_USERNAME}`
        } else if (this.state.currentRoomTab == "4") {
            message = `There are no spaces in ${MANAGED_CUSTOMER_USERNAME}'s Customer QA.`
        }
        return <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
        <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Materials.svg"/>
        <p className="empty-section-text manrope f-14 black-55">
          Nothing to show
        </p>
            <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
            {message}
            </p>
        </div>
    }

    getDataDiv = () => {
        return <RoomsListVirtualized
        roomListType={this.props.roomListType}
        roomSearch={this.props.roomSearch}
        roomFiltersApplied={this.props.filtersApplied}
        returnSearchValue={this.props.returnSearchValue}
        isLoading={this.state.isLoading}
        roomsAdded={this.state.roomsAdded}
        roomsLoading={this.state.roomsLoading}
        rooms_data={this.state.rooms_data}
        setLoadingRooms={this.setLoadingRooms}
        />
    }

    getStoreDataDiv = () => {
        return <RoomsListVirtualized
            roomListType={this.props.roomListType}
            roomSearch={this.props.roomSearch}
            roomFiltersApplied={this.props.filtersApplied}
            returnSearchValue={this.props.returnSearchValue}
            isLoading={this.state.isLoading}
            roomsAdded={this.state.roomsAdded}
            roomsLoading={this.state.roomsLoading}
            rooms_data={this.state.store_rooms_data}
            setLoadingRooms={this.setLoadingRooms}
        />
    }

    getModelsData = () => {
        return this.state.rooms_data.length > 0 ? 
            this.getDataDiv() : this.getNoDataDiv()
    }

    getInfoAlert = () => {
        return <Alert
        message="Please note that these items are not yet approved. Any changes made to the model files will automatically apply to the item used in the scene."
        type="info"
        showIcon
        />
    }
    
    render() {
        return (
            <span>
                {
                    this.state.isLoading == false ?
                    IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME != undefined ?
                    this.props.roomListType == 'library' ?
                        <Tabs
                            onChange={this.onRoomTabChange}
                            defaultActiveKey={this.state.currentRoomTab}
                            size="small"
                            tabBarStyle={{ textAlign: "center" }}
                            animated={false}
                            tabBarGutter={32}
                        >
                            <TabPane tab="Approved Spaces" key="5" className='w-100'>
                                {this.getModelsData()}
                            </TabPane>
                            <TabPane tab="In Admin QA" key="3" className='w-100'>
                                <Row>
                                    <Col span={24} style={{marginBottom: 10}}>
                                        {this.getInfoAlert()}
                                    </Col>
                                    <Col span={24}>
                                        {this.getModelsData()}
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="In Customer QA" key="4" className='w-100'>
                                <Row>
                                    <Col span={24} style={{marginBottom: 10}}>
                                        {this.getInfoAlert()}
                                    </Col>
                                    <Col span={24}>
                                        {this.getModelsData()}
                                    </Col>
                                </Row>
                            </TabPane>
                        </Tabs> :
                        this.getStoreDataDiv() :
                    this.props.roomListType == 'library' ?
                        this.getModelsData() :
                        this.getStoreDataDiv()
                    : ""
                }

            </span>
        );
    }
}


class SelectionPopover extends React.Component {
    state = {
        popOverVisible: false,
        selectedAreas: [],
        checked: [],
        disabled_action: true
    }
    componentDidMount() {
        (this.props.getSpaceAreas()).map((ele, index) => {
            this.state.checked[ele] = false;
        });
    }
    handleVisibleChange = popOverVisible => {
        this.setState({ popOverVisible });
    };

    handleClose = () => {
        this.props.handleSpacecAddAction(this.props.id, this.state.selectedAreas, this.props.is_library_item, this.props.product);
        this.setState({ popOverVisible: false });
    }

    onChange = (e) => {
        if (e.target.checked) {
            let selectedAreas = this.state.selectedAreas;
            selectedAreas.push(e.target.value);
            this.setState({
                selectedAreas: selectedAreas
            });
        } else {
            let selectedAreas = [];
            let currentSelectedAreas = this.state.selectedAreas;
            for (let currentSelectedArea of currentSelectedAreas) {
                if (currentSelectedArea != e.target.value) {
                    selectedAreas.push(currentSelectedArea);
                }
            }
            this.setState({
                selectedAreas: selectedAreas
            });
        }
    }

    toggleChecked = (ele) => {
        if (this.state.checked[ele]) {
            this.state.checked[ele] = !this.state.checked[ele];
            let selectedAreas = [];
            let currentSelectedAreas = this.state.selectedAreas;
            for (let currentSelectedArea of currentSelectedAreas) {
                if (currentSelectedArea != ele) {
                    selectedAreas.push(currentSelectedArea);
                }
            }
            this.setState({
                selectedAreas: selectedAreas
            });
        }
        else {
            this.state.checked[ele] = !this.state.checked[ele];
            let selectedAreas = this.state.selectedAreas;
            selectedAreas.push(ele);
            this.setState({
                selectedAreas: selectedAreas
            });
        }
        let flag = false;
        this.props.getSpaceAreas().map((ele, index) => {
            if (this.state.checked[ele]) {
                flag = true;
            }
        });
        if (flag) {
            this.setState({
                disabled_action: false
            });
        } else {
            this.setState({
                disabled_action: true
            });
        }
    };
    render() {
        return <Popover
            content={
                (
                    <span>
                        <span>
                            {(this.props.getSpaceAreas()).map((ele, index) => (
                                <span>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Checkbox className="space-pp-txt" onChange={this.onChange} checked={this.state.checked[ele]} value={ele}>{ele}</Checkbox>
                                        <Button ghost className={this.state.checked[ele] ? 'space-pp-btn red' : 'space-pp-btn'} onClick={() => this.toggleChecked(ele)}><span className="space-pp-btn-txt" >{this.state.checked[ele] ? 'Remove' : 'Add'}</span></Button>
                                    </div>

                                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                                </span>
                            ))}
                        </span>
                        <Button type="danger" disabled={this.state.disabled_action} className="small-btn" onClick={() => this.handleClose()}><span className="small-btn-txt">Done</span></Button>
                    </span>
                )
            }
            title="Add to Space"
            trigger="click"
            className="add-in-space-popover"
            visible={this.state.popOverVisible}
            onVisibleChange={this.handleVisibleChange}
        >
            <PlusOutlined
                title="Add To Collection"
                className="group-settings plus-icon"
            />
        </Popover>
    }
}

const ProductsListVirtualized = (props) => {

    // useEffect(() => {
    //     props.loadProducts()
    // }, [])

    const [productsData, setProductsData] = useState([])
    const [columnCount, setColumnCount] = useState(4);
    const [rowCount, setRowCount] = useState(0);
    const maxColWidth = 280;

    useEffect(() => {
        let data = null;
        if (props.productSearch == '' && !props.filtersApplied) {
            data = [...props.approvedProducts];
        } 
        else if (props.productSearch != '' || props.filtersApplied) {
            if(props.productSearch != '' && !props.filtersApplied){
                data = Utilities.getRelevanceBasedOrderedSearchResult(props.productSearch, props.approvedProducts)
            } else if (props.productSearch == '' && props.filtersApplied) {
                data = props.approvedProducts.filter(props.returnSearchValue)
                data = Utilities.getRelevanceBasedFilteredResults(data, props.appliedFiltersList)
            } else if (props.productSearch != '' && props.filtersApplied) {
                let filter_data = props.approvedProducts.filter(props.returnSearchValue)
                filter_data = Utilities.getRelevanceBasedFilteredResults(filter_data, props.appliedFiltersList)
                data = Utilities.getRelevanceBasedOrderedSearchResult(props.productSearch, filter_data)
            }
        }
        setProductsData(data)
    }, [props.appliedFiltersList, props.productSearch, props.is_library_item, props.approvedProducts])
    
    

    // no of rows based on the data length
    useEffect(() => {   
        setRowCount(Math.ceil(productsData.length / columnCount));
    }, [productsData])

    // column widths are stored in a state by index
    const [columnWidths, setColumnWidths] = useState(() => {
        const containerWidth = window.innerWidth - 80; // Get the width of the container element
        const columnWidthPercentage = 25; // Set the desired column width percentage

        const columnWidth = ((containerWidth * columnWidthPercentage) / 100) - 24;
        const initialWidths = new Array(columnCount).fill(columnWidth); // Set initial column widths here
        return initialWidths;
    });

    // function to handle column width changes
    const handleColumnWidthChange = ({ index, width }) => {
        setColumnWidths(prevWidths => {
            const newWidths = [...prevWidths];
            newWidths[index] = width;
            return newWidths;
        });
    };

    const getColumnWidth = ({ index }) => columnWidths[index];

    const getEstimatedRowSize = ({ index }) => {

        const itemIndex = index * columnCount;
        const item = productsData[itemIndex] && productsData[itemIndex];
        if (item) {
            const rowHeight = 366;
            return rowHeight;
        }
        return 366;
    };

    // function to render an individual cell in the grid
    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const itemIndex = rowIndex * columnCount + columnIndex;
        const product = productsData[itemIndex] && productsData[itemIndex];

        if (!product) {
            return null;
        }

        return (
            <div key={key} style={style}>
                <Card className="card-shadow-product"
                    bordered={false}
                    style={{ marginRight: "16px", marginBottom: "16px", border: "solid 1px #e3e3e3" }}>
                    {(props.productsAdded.includes(product.id)) ?
                        props.addedToCollection.includes(product.id) ?
                            <CheckOutlined title="Added To Collection"
                                className="group-settings plus-icon" /> :
                            <LoadingOutlined title="Adding To Collection"
                                className="group-settings plus-icon" />
                        :
                        <span>
                            {(props.getSpaceAreas().length == 0) ?
                                <PlusOutlined
                                    title="Add To Collection"
                                    className="group-settings plus-icon"
                                    onClick={() => props.handleAddAction(product, "library")}
                                /> :
                                <SelectionPopover is_library_item={props.is_library_item} product={product} handleSpacecAddAction={props.handleSpacecAddAction} getSpaceAreas={props.getSpaceAreas} id={product.id} />
                            }
                        </span>
                    }
                    <a className="suggested-product" style={{ zIndex: "1" }}>
                        <div className="box">
                            <img style={{ objectFit: "contain", height: "275px", zIndex: 1 }} className="product-image" src={product.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(product.platform)) || require("../../../../assets/images/chair.png")} />
                        </div>

                        <hr style={{ margin: 0 }} />

                        <div className='justify-in-start' style={{ margin: "12px 12px 8px 12px" }}>
                            <Tooltip title={<span className='manrope f-12 white'>{product.name}</span>}>
                                <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-50">{product.name}</span>
                            </Tooltip>
                            <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(product.last_modified_stamp, 'tooltip')}</span>}>
                                <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                                    <img src='/img/carbon_time.svg' style={{ marginRight: 2 }} />{Utilities.timestampToTimeSince(product.last_modified_stamp)}
                                </span>
                            </Tooltip>
                        </div>
                        <div className="justify-in-start" style={{ marginLeft: "12px", marginRight: "12px", marginBottom: "12px", textAlign: "left" }}>
                            <span className="manrope f-12 grey-77 capitalize" style={{ marginRight: 4 }}>Category:</span>
                            <span className="manrope f-12 black-33 capitalize">{product.category}</span>
                        </div>


                    </a>
                </Card>
            </div>
        );
    };

    return (
        <div style={{ width: '100%', height: 'calc(100vh - 250px)' }}>
            <AutoSizer>
                {({ height, width }) => {
                    const colCount =
                        (Math.floor(width / maxColWidth) > 4 ?
                        4 :
                        Math.floor(width / maxColWidth)) || 1;
                    setColumnCount(colCount);
                    setRowCount(Math.ceil(productsData.length / colCount));
                    const colWidth = width / colCount;
                    return(
                    <Grid
                        className='product-tab-content-scrollable'
                        cellRenderer={cellRenderer}
                        columnCount={columnCount}
                        columnWidth={colWidth}
                        height={height}
                        rowCount={rowCount}
                        estimatedRowSize={100}
                        rowHeight={getEstimatedRowSize}
                        width={width + 10}
                        onColumnResized={handleColumnWidthChange}
                    />
                    )
                }}
            </AutoSizer>
        </div>
    );
};

const ProductsList = (props) => {

    // useEffect(() => {
    //     props.loadProducts()
    // }, [])

    return <Row className="product-tab-content-scrollable" type="flex" style={{ justifyContent: "start" }}>
        {props.approvedProducts.map((product, index) => (
            (props.productSearch == '' || Utilities.isMatching(props.productSearch.toLowerCase(), product.name.toLowerCase())) ?
                <Col span={6} key={index}>
                    <Card className="card-shadow-product"
                        bordered={false}
                        style={{ marginRight: "16px", marginBottom: "16px", border: "solid 1px #e3e3e3" }}>
                        {(props.productsAdded.includes(product.id)) ?
                            props.addedToCollection.includes(product.id) ?
                                <CheckOutlined title="Added To Collection"
                                    className="group-settings plus-icon" /> :
                                <LoadingOutlined title="Adding To Collection"
                                    className="group-settings plus-icon" />
                            :
                            <span>
                                {(props.getSpaceAreas().length == 0) ?
                                    <PlusOutlined
                                        title="Add To Collection"
                                        className="group-settings plus-icon"
                                        onClick={() => props.handleAddAction(product, "library")}
                                    /> :
                                    <SelectionPopover is_library_item={props.is_library_item} product={product} handleSpacecAddAction={props.handleSpacecAddAction} getSpaceAreas={props.getSpaceAreas} id={product.id} />
                                }
                            </span>
                        }
                        <a className="suggested-product" style={{ zIndex: "1" }}>
                            <div className="box">
                                <img style={{ objectFit: "contain", height: "275px", zIndex: 1 }} className="product-image" src={product.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(product.platform)) || require("../../../../assets/images/chair.png")} />
                            </div>

                            <hr style={{ margin: 0 }} />

                            <div className='justify-in-start' style={{ margin: "12px 12px 8px 12px" }}>
                                <Tooltip title={<span className='manrope f-12 white'>{product.name}</span>}>
                                    <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-50">{product.name}</span>
                                </Tooltip>
                                <Tooltip title={<span className='manrope f-12 white'>{Utilities.timestampToTimeSince(product.last_modified_stamp, 'tooltip')}</span>}>
                                    <span className="manrope f-12 grey-77 w-500 time-clock-bg" style={{ marginLeft: 4 }}>
                                        <img src='/img/carbon_time.svg' style={{ marginRight: 2 }} />{Utilities.timestampToTimeSince(product.last_modified_stamp)}
                                    </span>
                                </Tooltip>
                            </div>
                            <div className="justify-in-start" style={{ marginLeft: "12px", marginRight: "12px", marginBottom: "12px", textAlign: "left" }}>
                                <span className="manrope f-12 grey-77 capitalize" style={{ marginRight: 4 }}>Category:</span>
                                <span className="manrope f-12 black-33 capitalize">{product.category}</span>
                            </div>


                        </a>
                    </Card>
                </Col> : ""
        ))}
    </Row>
}

const StoreProductsList = (props) => {

    return <Row className="product-tab-content-scrollable" type="flex" style={{ justifyContent: "start" }}>
        {props.products.map((product, index) => (
            (
                ((props.productSearch.length != '' && Utilities.isMatching(props.productSearch.toLowerCase(), product.name.toLowerCase())))
                ||
                ((props.productSearch == '' || (index >= ((props.currentPage - 1) * props.pageSize)) && (index < (props.pageSize * props.currentPage))))
            ) ?
                <Col span={6} key={index}>
                    <Card className="card-shadow-product"
                        bordered={false}
                        style={{ marginRight: "16px", marginBottom: "16px", border: "solid 1px #e3e3e3" }}>
                        {(props.productsAdded.includes(product.id)) ?
                            props.addedToCollection.includes(product.id) ?
                                <CheckOutlined title="Added To Collection"
                                    className="group-settings plus-icon" /> : <LoadingOutlined title="Adding To Collection"
                                        className="group-settings plus-icon" /> :
                            <span>
                                {(props.getSpaceAreas().length == 0) ?
                                    <PlusOutlined
                                        title="Add To Collection"
                                        className="group-settings plus-icon"
                                        onClick={() => props.handleAddAction(product, "store")}
                                    /> :
                                    <SelectionPopover is_library_item={props.is_library_item} product={product} handleSpacecAddAction={props.handleSpacecAddAction} getSpaceAreas={props.getSpaceAreas} id={product.id} />
                                }
                            </span>
                        }
                        <a className="suggested-product" style={{ zIndex: "1" }}>
                            <div className="box">
                                <img style={{ objectFit: "contain", height: "275px", zIndex: 1 }} className="product-image" src={product.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(product.platform)) || require("../../../../assets/images/chair.png")} />
                            </div>
                            <hr style={{ margin: 0 }} />

                            <div className='justify-in-start' style={{ margin: "12px 12px 8px 12px" }}>
                                <Tooltip title={<span className='manrope f-12 white'>{product.name}</span>}>
                                    <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-50">{product.name}</span>
                                </Tooltip>

                            </div>
                            <div className="justify-in-start" style={{ marginLeft: "12px", marginRight: "12px", marginBottom: "4px", textAlign: "left" }}>
                                <span className="manrope f-12 grey-77 capitalize" style={{ marginRight: 4 }}>Category:</span>
                                <span className="manrope f-12 black-33 capitalize">{product.category}</span>
                            </div>
                            <div className="justify-in-start" style={{ marginLeft: "12px", marginRight: "12px", marginBottom: "12px", textAlign: "left" }}>
                                <span className="manrope f-12 grey-77 capitalize" style={{ marginRight: 4 }}>Price:</span>
                                <span className="manrope f-12 black-33 capitalize">{product.price == 0 || product.price == '' ? "Free" : '$' + product.price}</span>
                            </div>
                            <div className="justify-in-start" style={{ marginLeft: "12px", marginRight: "12px", marginBottom: "12px", textAlign: "left" }}>
                                <span className="manrope f-12 grey-77 capitalize" style={{ marginRight: 4 }}>Brand:</span>
                                <span className="manrope f-12 black-33 capitalize">{product.company_display_name}</span>
                            </div>
                        </a>
                    </Card>
                </Col> : ""
        ))}

    </Row>
}

const ModelsSelection = (props) => {

    barOptions.chart.width = window.innerWidth * 0.70;
    options.chart.width = window.innerWidth * 0.35;

    const [selectedRoomIdForCollection, setSelectedRoomIdForCollection] = useState(null);
    const [collectionModalVisible, setCollectionModalVisible] = useState(false);
    const [menuOption, setMenuOption] = useState('1');
    const [productsAdded, setProductsAdded] = useState([]);
    const [addedToCollection, setAddedToCollection] = useState([]);
    const [onboardingVar, showOnboarding] = useState(props.onboarding);
    const [roomsAdded, setRoomsAdded] = useState([]);
    const project_id = new URLSearchParams(window.location.search).get("project_id");

    let initialProductListType = 'library';
    if (props.adminTestFlow != undefined) {
        initialProductListType = 'free'
    }
    const [roomListType, setRoomListType] = useState('library');
    const [productListType, setProductListType] = useState(initialProductListType);
    const [, updateState] = React.useState();
    const [isLoading, setIsLoading] = useState(false);
    const [storeProducts, setStoreProducts] = useState([])

    const modifyRoomListType = (value) => {
        setRoomListType(value);
        updateState();
    }

    const modifyProductListType = (value) => {
        setProductListType(value);
        updateState();
    }

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [roomListType, productListType]);

    useEffect(() => {
        if (props.update_collection) {
            setRoomsAdded(props.room_id);
        }
    }, [])

    useEffect(() => {
        if(props.storeReducer.products && storeProducts.length == 0){
            let storeProducts = props.storeReducer.products.filter((product) => (product.model_status == "5" && product.item_type != 'shared'))
            storeProducts = Utilities.getProductsDataWithKeywords(storeProducts, 'store')
            setStoreProducts(storeProducts)
        }
    }, [props.storeReducer])

    const setCookie = (cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    let prod_model = true;
    let room_model = false;

    if (props.getModelSelectionStage() == 2) {
        prod_model = false;
        room_model = true;
    }



    let space_checkbox = false;
    if (getCookie("space-check")) {
        prod_model = false;
        space_checkbox = true;
    }
    else {
        prod_model = true;
        space_checkbox = false;
    }

    let product_checkbox = false;
    if (getCookie("product-check")) {
        room_model = false;
        product_checkbox = true;
    }
    else {
        product_checkbox = false;
    }

    if (props.adminTestFlow != undefined) {
        prod_model = false;
        room_model = false;
    }

    const [modelVisible, setModelVisible] = useState(prod_model);
    const [modelProductVisible, setModelProductVisible] = useState(false);
    const [roomModelVisible, setRoomModelVisible] = useState(room_model);
    const [roomValidationModelVisible, setRoomValidationModelVisible] = useState(false);
    const [roomsVisited, setRoomsVisited] = useState(false);
    const [successPopupVisible, setSuccessPopupVisible] = useState(false);
    const [productSearch, setProductSearch] = useState('');
    const [roomSearch, setRoomSearch] = useState('');
    const [createSceneLoader, setCreateSceneLoader] = useState(false);
    const [spaceCheck, setSpaceCheck] = useState(space_checkbox);
    const [productCheck, setProductCheck] = useState(product_checkbox);
    const [addingProduct, setAddingProduct] = useState(false);
    const [approvedProducts, setApprovedProducts] = useState([]);
    const [originalProductsData, setOriginalProductsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageRoom, setCurrentPageRoom] = useState(1)
    const [roomsDataLength, setRoomsDataLength] = useState(1)
    const [pageSize, setPageSize] = useState(33);
    const [renderGeneration, setRenderGeneration] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [spaceCategoryValue, setSpaceCategoryValue] = useState([]);
    const [filtersDrawer, setFiltersDrawer] = useState(false);
    const [spaceCategories, setSpaceCategories] = useState([]);
    const [productCategoriesData, setProductCategoriesData] = useState([])
    const [productParentCateogries, setProductParentCateogries] = useState({})
    const [productCategoryValue, setProductCategoryValue] = useState([]);
    const [productCategoryValueWithChilds, setProductCategoryValueWithChilds] = useState([]);
    const [categorySearchValue, setCategorySearchValue] = useState([]);
    const [productStyleCategory, setProductStyleCategory] = useState([]);
    const [colorValue, setColorValue] = useState([]);
    const [productMaterialsData, setProductMaterialsData] = useState([]);
    const [productMaterials, setProductMaterials] = useState([]);
    const [materialsCategoryValueWithChilds, setMaterialsCategoryValueWithChilds] = useState([]);
    const [productModelType, setProductModelType] = useState([]);
    const [appliedFiltersList, setAppliedFiltersList] = useState([]);
    const [productsLoader, setProductsLoader] = useState(true);
    const [currentTabType, setCurrentTabType] = useState("5");
  
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const onAddCollectionClickForRoom = (id) => {
        setSelectedRoomIdForCollection(id);
        setCollectionModalVisible(true);
    }

    const createScene = (payload) => {
        setCreateSceneLoader(true);
        console.log("Scene flow", payload);
        if (props.scene_flow || (new URL(window.location.href).searchParams.get("scene"))) {
            axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then((res) => {
                    message.info('Scene created successfully');
                    var scene_id = res.data["scene_id"];
                    if (props.adminTestFlow != undefined) {
                        window.location.href = '/admin_scene_builder/' + scene_id;
                    }
                    else {
                        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
                        let scene_state = new URLSearchParams(window.location.search).get("scene_state");
                        if (onboarding != undefined) {
                            if (typeof (onboarding == 'string') || onboarding instanceof String) {
                                onboarding = (onboarding.toLowerCase() === 'true');
                            }
                            if (onboarding) {
                                if (scene_state != undefined) {
                                    window.location.href = '/scene_builder/' + scene_id + window.location.search + '?origin=/home';
                                }
                                else {
                                    window.location.href = '/scene_builder/' + scene_id + "?onboarding=true&origin=/home" ;
                                }
                            } else {
                                window.location.href = '/scene_builder/' + scene_id + '?origin=/home';
                            }
                        }
                        else {
                            if(project_id != null){
                                window.location.href = '/scene_builder/' + scene_id + '?origin=/home&&project_id=' + project_id
                            }
                            else{
                                window.location.href = '/scene_builder/' + scene_id + '?origin=/home';
                            }
                        }
                    }
                })
                .catch((error) => {
                    message.error('Error in creating scene');
                    setCreateSceneLoader(false);
                    setIsLoading(false);
                });
        }
        else {
            let payload = {
                "scene_name": props.getCollectionName(),
                "collection_id": props.getCollectionId(),
                "room_id": roomsAdded[0],
                "username": localStorage.getItem("username")
            };
            if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null || MANAGED_COMPANY_ID != null)) {
                payload.designed_for = localStorage.getItem('managed_customer_username');
            }
            console.log("Collection flow", payload);

            let company_id = COMPANY_ID;
            if (company_id != undefined) {
                payload.company_id = company_id
            }

            axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then((res) => {
                    message.info('Scene created successfully');
                    var scene_id = res.data["scene_id"];
                    let onboarding = new URLSearchParams(window.location.search).get("onboarding");
                    let scene_state = new URLSearchParams(window.location.search).get("scene_state");

                    if (onboarding != undefined) {
                        if (typeof (onboarding == 'string') || onboarding instanceof String) {
                            onboarding = (onboarding.toLowerCase() === 'true');
                        }
                        if (onboarding) {
                            if (scene_state != undefined) {
                                window.location.href = '/scene_builder/' + scene_id + window.location.search + '?origin=/home';
                            }
                            else {
                                window.location.href = '/scene_builder/' + scene_id + "?onboarding=true&origin=/home";
                            }
                        } else {
                            window.location.href = '/scene_builder/' + scene_id + '?origin=/home';
                        }
                    }
                    else {
                        window.location.href = '/scene_builder/' + scene_id + '?origin=/home';
                    }
                })
                .catch((error) => {
                    message.error('Error in creating scene');
                    setCreateSceneLoader(false);
                    setIsLoading(false);
                });
        }
    }

    const onChangeSpaceCheckbox = (e) => {
        setSpaceCheck(e.target.checked);
        if (e.target.checked) {
            setCookie("space-check", true, 365);
        }
        else if (e.target.checked == false) {
            setCookie("space-check", false, 365);
        }
    }

    const onChangeProductCheckbox = (e) => {
        setProductCheck(e.target.checked);
        if (e.target.checked) {
            setCookie("product-check", true, 365);
        }
        else if (e.target.checked == false) {
            setCookie("product-check", false, 365);
        }
    }

    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const handleSubmit = () => {
        setIsLoading(true);
        console.log('produccts', productsAdded)
        if (props.getModelSelectionStage() == 1) {
            if (roomsAdded.length > 0) {
                props.setModelSelectionStage(2);
                showRoomModal();
                setIsLoading(false);
                setTriggerUpdate(!triggerUpdate);
                resetEverything();
            }
            else {
                setRoomValidationModelVisible(true);
                setIsLoading(false);
            }
        }
        else {
            if (productsAdded.length > 0) {
                triggerPostCollectionUpdate();
                if (props.scene_flow || (new URL(window.location.href).searchParams.get("scene"))) {
                    let room_id = roomsAdded[0];

                    if (props.adminTestFlow == undefined) {
                        props.setRoomId(roomsAdded[0]);
                        room_id = roomsAdded[0];
                    }
                    let payload = {}
                    if (props.getSceneName()) {
                        if (props.adminTestFlow == undefined) {
                            payload = {
                                "scene_name": props.getSceneName(),
                                "collection_id": props.getCollectionId(),
                                "room_id": room_id,
                                "username": localStorage.getItem("username")
                            };
                        }
                        else {
                            payload = {
                                "scene_name": props.getSceneName(),
                                "collection_id": props.getCollectionId(),
                                "room_id": props.getRoomId(),
                                "username": localStorage.getItem("username")
                            };
                        }
                    } else {
                        payload = {
                            "scene_name": (new URL(window.location.href).searchParams.get("scene")),
                            "collection_id": props.getCollectionId(),
                            "room_id": room_id,
                            "username": localStorage.getItem("username")
                        };
                    }
                    if (project_id) {
                        payload.project_id = project_id;
                    }
                    let company_id = COMPANY_ID;
                    if (company_id != undefined) {
                        payload.company_id = company_id
                    }
                    if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null || MANAGED_COMPANY_ID != null)) {
                        payload.designed_for = localStorage.getItem('managed_customer_username');
                    }
                    console.log('payload', payload)
                    createScene(payload);
                }
                else {
                    setSuccessPopupVisible(true);
                    setIsLoading(false);
                }
            }
            else {
                setModelProductVisible(true);
                setIsLoading(false);
            }
        }
    }

    const handleCollectionAndSceneUpdate = () => {
        let payload = {}
        if (props.scene_id) {
            payload = {
                "scene_id": props.scene_id,
                "render_generation": true
            };
            console.log(payload)
            axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then((res) => {
                    let collection_payload = {
                        "collection_id": props.getCollectionId().toString(),
                        "render_generation": true
                    }
                    console.log(payload)
                    axios.post(ENVIRONMENT.UPDATE_COLLECTION, collection_payload)
                        .then((res) => {
                            message.success('Product added to collection and scene.');
                        })
                });
        }
    }

    const goToSceneCreatorView = () => {
        props.setRoomId(props.room_id);
        if (!renderGeneration) {
            setModelProductVisible(true);
        } else {
            setIsLoading(true);
            window.location.href = '/scene_builder/' + props.scene_id + '?origin=/home';
        }
    }

    const triggerPostCollectionUpdate = () => {
        console.log("collection id", props.getCollectionId())
        let collection_payload= {
            "collection_id": props.getCollectionId()  
        }
        axios.post(ENVIRONMENT.POST_COLLECTION_UPDATE, collection_payload)
            .then((res) => {
            })
    }

    const handleCancellation = () => {
        if (props.getModelSelectionStage() == 1) {
            let onboarding = new URLSearchParams(window.location.search).get("onboarding");
            let scene_state = new URLSearchParams(window.location.search).get("scene_state");
            let room_state = new URLSearchParams(window.location.search).get("room_state");
            let product_state = new URLSearchParams(window.location.search).get("product_state");

            if (onboarding != undefined) {
                if (typeof (onboarding == 'string') || onboarding instanceof String) {
                    onboarding = (onboarding.toLowerCase() === 'true');
                }
                if (onboarding) {
                    if (scene_state != undefined && room_state != undefined && product_state != undefined) {
                        props.history.push({
                            pathname: '/home',
                            search: `?step_no=15`
                        });
                    } else if (scene_state != undefined && room_state == undefined && product_state == undefined) {
                        props.history.push({
                            pathname: '/home',
                            search: `?step_no=4&scene_state=added`
                        });
                    }
                    else if (scene_state != undefined && room_state != undefined && product_state == undefined) {
                        props.history.push({
                            pathname: '/home',
                            search: `?step_no=4&scene_state=added&room_state=added`
                        });
                    } else if (scene_state != undefined && room_state == undefined && product_state != undefined) {
                        props.history.push({
                            pathname: '/home',
                            search: `?step_no=4&scene_state=added&product_state=added`
                        });
                    }
                    else {
                        props.history.push({
                            pathname: '/home',
                            search: `?step_no=15`
                        });
                    }
                } else {
                    if(props.reredirection_path){
                        props.history.push({
                            pathname: props.redirection_path
                        });
                    } else {
                       props.history.push({
                        pathname: '/home'
                    });
                    }
                }
            }
            else {
                if(props.reredirection_path){
                    props.history.push({
                        pathname: props.redirection_path
                    });
                } else {
                   props.history.push({
                        pathname: '/home'
                    });
                }
            }
        }
        else {
            if (roomsAdded.length > 0) {
                props.setModelSelectionStage(1);
                setTriggerUpdate(!triggerUpdate);
            }
            else {
                setRoomValidationModelVisible(true);
            }
        }
    }

    const goBackToSceneDetailPage = () => {
        if (props.update_collection) {
            if (renderGeneration) {
                window.location.href = '/scene_renders/' + props.scene_id;
            } else {
                props.setCollectionView(false);
            }

        }
    }

    const redirectToCollection = () => {        
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        let scene_state = new URLSearchParams(window.location.search).get("scene_state");
        let room_state = new URLSearchParams(window.location.search).get("room_state");
        let product_state = new URLSearchParams(window.location.search).get("product_state");


        if (onboarding != undefined) {
            if (typeof (onboarding == 'string') || onboarding instanceof String) {
                onboarding = (onboarding.toLowerCase() === 'true');
            }
            if (onboarding) {
                if (scene_state != undefined && room_state != undefined && product_state != undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=15`
                    });
                } else if (scene_state != undefined && room_state == undefined && product_state == undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added`
                    });
                }
                else if (scene_state != undefined && room_state != undefined && product_state == undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added&room_state=added`
                    });
                } else if (scene_state != undefined && room_state == undefined && product_state != undefined) {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=4&scene_state=added&product_state=added`
                    });
                }
                else {
                    props.history.push({
                        pathname: '/home',
                        search: `?step_no=15`
                    });
                }
            } else {
                if(props.redirection_path){
                    props.history.push({
                        pathname: props.redirection_path
                    })
                } else {
                    props.history.push({
                        pathname: '/home'
                    });
                }
            }
        }
        else {
            if(props.redirection_path){
                props.history.push({
                    pathname: props.redirection_path
                })
            } else {
                props.history.push({
                    pathname: '/home'
                });
            }
        }
    }

    const handleOk = e => {
        setModelVisible(false);
    };

    const handleProductCancel = e => {
        setModelProductVisible(false);
    }

    const productModelOk = e => {
        setModelProductVisible(false);
    }

    const handleCancel = e => {
        setModelVisible(false);
    };

    const showRoomModal = () => {
        if (getCookie("product-check")) {
            setProductCheck(true);
            setRoomModelVisible(false);
        }
        else {
            setProductCheck(false);
            setRoomModelVisible(true);
        }
    };

    const handleRoomModelOk = e => {
        setMenuOption('2');
        setRoomModelVisible(false);
    };

    const handleRoomModelCancel = e => {
        setMenuOption('2');
        setRoomModelVisible(false);
    };

    const addProductToCollectionCallback = (is_successful, product_id) => {
        if (is_successful == true) {
            setAddingProduct(false);
            setAddedToCollection(productsAdded => [...productsAdded, product_id]);
        }
        else {
            setAddingProduct(false);
            let productsAddedTemp = productsAdded
            productsAddedTemp.filter((value) => { return product_id != value })
            setProductsAdded(productsAddedTemp)
        }

    }

    const addProductToCollection = (product, type) => {
        let payload = {}
        setAddingProduct(true);
        payload.product_id = product.id;
        payload.collection_id = props.getCollectionId();
        payload.product = product
        let is_library_item = false;
        if (type == 'library') {
            is_library_item = true
        }
        if (!renderGeneration) {
            setRenderGeneration(true);
            handleCollectionAndSceneUpdate();
        }

        props.processRequest(payload, "product", is_library_item, addProductToCollectionCallback)

    }

    const addSpaceAllocationToCollection = (product_id, selectedAreas, is_library_item, product) => {
        let payload = {}
        setAddingProduct(true);
        payload.product_id = product_id;
        payload.collection_id = props.getCollectionId();
        payload.product = product
        console.log("Update Collection", payload);
        if (!renderGeneration) {
            setRenderGeneration(true);
            handleCollectionAndSceneUpdate();
        }
        props.processRequest(payload, "product", is_library_item, addProductToCollectionCallback, selectedAreas)
    }

    const handleAddAction = (product, type) => {
        setProductsAdded(productsAdded => [...productsAdded, product.id]);
        addProductToCollection(product, type);

    }

    const handleSpacecAddAction = (product_id, selectedAreas, is_library_item, product) => {
        setProductsAdded(productsAdded => [...productsAdded, product_id]);
        addSpaceAllocationToCollection(product_id, selectedAreas, is_library_item, product);
    }

    let getMSPProductPayload = (type = 'owned') => {
        let payload = {
          "required_fields": ["id","name","brand_id", "category", "height",  "customer_username",
          "width","depth", "model_status","thumbnail","last_modified_stamp", "variant_of",
          "is_store_item","group_id", "dimensions", "company_id", "is_shared", "platform", "style_category",
          "color_name", "materials", "product_model_type"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        let filter_string = "";
        let customer_username = "customer_username__exact='" + localStorage.getItem('managed_customer_username') + "'"
        let company_id = "";
        let model_status = "model_status__in=[3,4,5]"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + localStorage.getItem('managed_customer_username') + "\"%'"
       
        if (MANAGED_COMPANY_ID) {
            company_id = "company_id__exact=" + parseInt(MANAGED_COMPANY_ID) + "||additional_company_ids__contains='" + MANAGED_COMPANY_ID + "'"
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")"
          } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")"
          }
          filter_string = filter_string + "&&(" + model_status + ")" + "||(" + has_access_to + ")"
         
          payload['filter_string'] = filter_string
      
          return payload;
      }
    
    const getCustomerDataForMsprovider = () => {
        let payload = getMSPProductPayload();
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
        .then(res => {
          if (res.data) {
            let owned_data = Utilities.getProductsDataWithKeywords(res.data);
            setApprovedProducts(owned_data);
            setOriginalProductsData(owned_data);
            getProductData(owned_data);
          }
        });
    }

    const getProductData = (products = []) => {
        let payload = getProductPayload();
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then(res => {
            if (res.data) {
                let owned_products = Utilities.getProductsDataWithKeywords(res.data);;
                if (products.length > 0) {
                    owned_products = owned_products.concat(products);
                }
                setApprovedProducts(owned_products);
                setOriginalProductsData(owned_products);
                setProductsLoader(false);
            }
        });
    }


    let getProductPayload = () => {
        let payload = {
          "required_fields": ["id","name","brand_id", "category", "height",  "customer_username",
          "width","depth", "model_status","thumbnail","last_modified_stamp", "variant_of",
          "is_store_item","group_id", "dimensions", "company_id", "is_shared", "platform", "style_category",
          "color_name", "materials", "product_model_type"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        let filter_string = "";
        let customer_username = "customer_username__exact='" + localStorage.getItem('username') + "'"
        let company_id = "";
        let model_status = "model_status__in=[5]"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + localStorage.getItem('username') + "\"%'"
    
        if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("product")) {
          company_id = "company_id__exact=" + parseInt(COMPANY_ID) + "||additional_company_ids__contains='" + parseInt(COMPANY_ID) + "'"
          filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")"
        } else {
            filter_string = "(" + customer_username + "&&" + is_hidden + ")"
        }
        filter_string = filter_string + "&&(" + model_status + ")" + "||(" + has_access_to + ")"
       
        payload['filter_string'] = filter_string

        return payload;
    }

    const getProjectProductsPayload = (project_id) => {
        let payload = {
          order_by: "id desc",
          required_fields: [
            "id",
            "name",
            "customer_username",
            "company_id",
            "thumbnail",
            "status",
            "project_id",
            "entity_type",
            "product_model_type",
            "last_modified",
            "category",
            "platform",
          ],
          filter_string: `(project_id__exact='${project_id}'&&entity_type__exact='product'&&status__in=[5, 4])`,
        };
    
        return payload;
    };

    const addThumbnailPrefixtoProduct = (rooms) => {
        const tranformedData = rooms.map((room) => {
            return {
                ...room,
                room_id: room.id,
                thumbnail: ENVIRONMENT.getBaseURL(room.platform) +
                ENVIRONMENT.THUMBNAIL_URI +
                room.thumbnail
            }
        })
        console.log(tranformedData,"tranformedData");
        return tranformedData;
    }

    const getProjectProductsList = (project_id) => {
        let payload = getProjectProductsPayload(project_id);
        axios.post(ENVIRONMENT.GET_PROJECTS_ENTITY_BATCH, payload)
            .then((response) => {
                if (response.data) {
                    let current_products = addThumbnailPrefixtoProduct(response.data);
                    let owned_products = Utilities.getProductsDataWithKeywords(current_products);;
                    setApprovedProducts(owned_products);
                    setOriginalProductsData(owned_products);
                    setProductsLoader(false);
                }
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        loadProducts();
    }, [])

    const loadProducts = () => {
        if (project_id != null) {
            getProjectProductsList(project_id);
        } else if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null || MANAGED_COMPANY_ID != null) && !props.update_collection) {
            getCustomerDataForMsprovider();
        } else {
            getProductData();
        }

        props.listForStoreProducts();

        axios.post(ENVIRONMENT.FETCH_COLLECTION_PRODUCTS, {
            collection_id: props.getCollectionId().toString()
        })
            .then((response) => {
                console.log(response.data)
                let productsAdded = [];
                for (var i = 0; i < response.data.length; i++) {
                    productsAdded.push(response.data[i].id);
                }
                setProductsAdded(productsAdded);
                if (props.update_collection) {
                    setAddedToCollection(productsAdded);
                }

            })
            .catch((error) => {

            });

    }


    const camel2title = (camelCase) => camelCase
        .replace(/([A-Z])/g, (match) => ` ${match}`)
        .replace(/^./, (match) => match.toUpperCase());
    let productGraph = JSON.parse(JSON.stringify(options));
    let roomsGraph = JSON.parse(JSON.stringify(options));
    let scenesGraph = JSON.parse(JSON.stringify(barOptions));
    productGraph.series[0].data = [];
    roomsGraph.series[0].data = [];
    if (props.rootReducer.dashboardData.productsGraph) {
        Object.keys(props.rootReducer.dashboardData.productsGraph).forEach(key => {
            if (props.rootReducer.dashboardData.productsGraph[key] && key != 'total') {
                productGraph.series[0].data.push({
                    name: camel2title(key),
                    y: props.rootReducer.dashboardData.productsGraph[key],
                    color: "#DA3926"
                });
            }
        });
        Object.keys(props.rootReducer.dashboardData.roomsGraph).forEach(key => {
            if (props.rootReducer.dashboardData.roomsGraph[key] && key != 'total') {
                roomsGraph.series[0].data.push({
                    name: camel2title(key),
                    y: props.rootReducer.dashboardData.roomsGraph[key],
                    color: "#DA3926"
                });
            }
        });
        Object.keys(props.rootReducer.dashboardData.scenesGraph).forEach(key => {
            if (props.rootReducer.dashboardData.scenesGraph[key] && key != 'total') {
                scenesGraph.xAxis.categories.push(camel2title(key));
                scenesGraph.series[0].data.push(props.rootReducer.dashboardData.scenesGraph[key]);
            }
        });

        productGraph.title.text = `Products<br>total<br>${props.rootReducer.dashboardData.productsGraph.total}`
        roomsGraph.title.text = `Products<br>total<br>${props.rootReducer.dashboardData.roomsGraph.total}`
    }

    const setSpaceCategoriesData = () => {
        let payload = {
          'request_type': 'room_model'
        }
        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
        .then(res => {
          let categories = []
          if (res.data) {
            res.data.map((request) => {
              if (!categories.includes(request.category)) {
                categories.push(request.category);
              }
            })
            categories = categories.filter(item => item !== '')
            setSpaceCategories(categories);
          }
        })
      }
    
      const setProductCategoryData = () => {

        let payload = {
            output: 'tree'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
        .then(res => {
          setProductCategoriesData(res.data);
        });
    
        payload = {
          output: 'serial'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
        .then(res => {
          setProductParentCateogries(res.data);
        });
      }
    
      const loadProductMaterialsData = () => {
        let data = [];
            axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
            })
            .then(res => {
                let categoriesData = Utilities.getMaterialsData( res.data );
                for (let i = 1; i <= (res.data).length; i++) {
                    if( res.data[i - 1].parent_id == "")
                    {
                        data.push({
                            key: res.data[i - 1]['material_id'],
                            title: res.data[i - 1]['name'],
                            value: res.data[i - 1]['name'],
                            children: categoriesData[res.data[i - 1]['material_id']],
                        });
                    }
    
                }
                setProductMaterialsData(data);
            });
      }

      useEffect(() => {
        setSpaceCategoriesData();
        setProductCategoryData();
        loadProductMaterialsData();
      }, []);
    
      useEffect(() => {
        //display tags when this is clicked
        if (spaceCategoryValue.length > 0
            || (productCategoryValue.length > 0)
            || (productStyleCategory.length > 0)
            || (colorValue.length > 0)
            || (productMaterials.length > 0)
            || (productModelType.length > 0)) {
          setFiltersApplied(true);
          setAppliedFiltersList(getAppliedFilters())
          forceUpdate();
        } else {
          setFiltersApplied(false);
          setAppliedFiltersList([])
        }
      },[spaceCategoryValue, productCategoryValue, productStyleCategory, colorValue, productMaterials, productModelType])
    
      const openFilters = () => {
        setFiltersDrawer(true);
      };
    
      const closeFiltersDrawer = () => {
        setFiltersDrawer(false);
      }
    
      const changeSpaceCategory = (value) => {
        setSpaceCategoryValue(value);
      }

      const changeProductCategory = (value) => {
        let values = []
    
        value.map((name)=>{
          const targetItemName = name;
          const targetItem = productParentCateogries.find(item => item.name === targetItemName);
    
        if (targetItem) {
            const result = findItemsByParentId(productParentCateogries, targetItemName, targetItem.category_id);
            result.unshift(name);
            values = [...values,...result]
        }});
    
        if (value.length > 0
          || (productStyleCategory.length > 0)
          || (colorValue.length > 0)
          || (productMaterials.length > 0)
          || (productModelType.length > 0)) {
          setFiltersApplied(true);
        } else {
          setFiltersApplied(false);
        }
        setProductCategoryValue(value);
        setProductCategoryValueWithChilds(values);
      }
    
      const changeProductStyleCategory = (value) => {
        if (value.length > 0
          || (colorValue.length > 0)
          || (productMaterials.length > 0)
          || (productModelType.length > 0)) {
          setFiltersApplied(true);
        } else {
          setFiltersApplied(false);
        }
    
        setProductStyleCategory(value);
      }
    
      const changeColorValue = (value) => {
        if (value.length > 0
          || (productStyleCategory.length > 0)
          || (productMaterials.length > 0)
          || (productModelType.length > 0)) {
          setFiltersApplied(true);
        } else {
          setFiltersApplied(false);
        }
    
        setColorValue(value);
      }
    
      const changeProductModelType = (value) => {
        if (value.length > 0
          || (productStyleCategory.length > 0)
          || (productMaterials.length > 0)
          || (colorValue.length > 0)) {
          setFiltersApplied(true);
        } else {
          setFiltersApplied(false);
        }
        setProductModelType(value);
      }
    
      const findChildrenByParentTitle = (parentTitle, node) => {
        if (node.title === parentTitle) {
            return node.children.map(child => child.title);
        } else if (node.children) {
            let result = [];
            for (const childNode of node.children) {
                result = result.concat(findChildrenByParentTitle(parentTitle, childNode));
            }
            return result;
        } else {
            return [];
        }
      }
    
      const changeProductMaterial = (value) => {
    
        let values = []
    
        value.map((name)=>{
          const targetItemTitle = name;
          const targetItem = productMaterialsData.find(item => item.title === targetItemTitle);
    
        if (targetItem) {
            const result = findChildrenByParentTitle(targetItemTitle, targetItem);
            result.unshift(name);
            values = [...values,...result]
        }});
    
        if (value.length > 0
          || (productStyleCategory.length > 0)
          || (colorValue.length > 0)
          || (productModelType.length > 0)) {
          setFiltersApplied(true);

        } else {
          setFiltersApplied(false);
        }
    
        if(values.length <= 0){
          values = value 
        }
    
        setProductMaterials(value);
        setMaterialsCategoryValueWithChilds(values);
        
      }

      const getAppliedFilters = () => {
        let appliedFilters = [...productCategoryValueWithChilds, ...productStyleCategory, ...colorValue,
                              ...materialsCategoryValueWithChilds, ...productModelType]
        appliedFilters = appliedFilters.map(item => item.toLowerCase())
        return appliedFilters;
      }

      const getProductSearchValue = (entity) => {
        let category = entity.category;
        let style_category = entity.style_category;
        let color = entity.color_name;
        let materials = []
        if (entity.materials && entity.materials.length > 0){
          materials = entity.materials
        }
        let product_model_type = entity.product_model_type;
        let product_category = false;
        let style_category_matched = false;
        let color_matched = false;
        let material_matched = false;
        let model_type_matched = false;
    
        if (category != '') {
          if ((productCategoryValueWithChilds.length > 0 && productCategoryValueWithChilds.includes(category)) || productCategoryValueWithChilds.length == 0){
            product_category = true;
          }
        }
    
        if ((productStyleCategory.length > 0 && productStyleCategory.includes(style_category)) || productStyleCategory.length == 0){
          style_category_matched = true;
        }
    
        if ((colorValue.length > 0 && colorValue.includes(color)) || colorValue.length == 0){
          color_matched = true;
        }
    
        if ((materialsCategoryValueWithChilds.length > 0 && materialsCategoryValueWithChilds.some(element => materials.includes(element))) || materialsCategoryValueWithChilds.length == 0){
          material_matched = true;
        }
    
        if ((productModelType.length > 0 && productModelType.includes(product_model_type) && entity.is_store_item != 1) || productModelType.length == 0){
          model_type_matched = true;
        }
    
        if (product_category && style_category_matched && material_matched && color_matched && model_type_matched) {
          return true;
        }
      }
    
      const changeProductSearchCategory = (value) => {
        setCategorySearchValue(value);
      }
    
      const findItemsByParentId = (data, targetItemName, parentId) => {
        const items = [];
        for (const item of data) {
            if (item.parent_id === parentId) {
                items.push(item.name);
                items.push(...findItemsByParentId(data, targetItemName, item.category_id));
            }
        }
        return items;
      }    
    
      const returnSearchValue = (entity) => {
        //return value according to the filters applied
        if(props.getModelSelectionStage() == 1){
            if (getSpaceSearchValue(entity)){
                return true;
              }
        } else if (props.getModelSelectionStage() == 2){
            if(getProductSearchValue(entity)){
                return true;
            }
        } else {
            return false;
        }
      }
    
      const getSpaceSearchValue = (entity) => {  
        let category = entity.category;
        let space_category = false;
        if ((spaceCategoryValue.length > 0 && spaceCategoryValue.includes(category)) || spaceCategoryValue.length == 0) {
          space_category = true;
        }
        if (space_category) {
          return true;
        }
      }
    
      const resetEverything = () => {
        setFiltersApplied(false);
        setAppliedFiltersList([])
        setProductModelType([]);
        setSpaceCategoryValue([]);
        setProductCategoryValue([]);
        setProductCategoryValueWithChilds([]);
        setCategorySearchValue([]);
        setProductStyleCategory([]);
        setColorValue([]);
        setProductMaterials([]);
        setMaterialsCategoryValueWithChilds([]);
      }

      const getFilteredTags =()=> {
        let filteredTags = <>
          {filtersApplied && <Col span={24} style={{marginTop: 20, textAlign: "left"}}>
            <h4 className='manrope f-14 black-55 italic'>Showing results for</h4>
            <div style={{marginBottom: 20}}>

                {spaceCategoryValue.length > 0 && spaceCategoryValue.map((category) => {
                    return <Tag onClose={() => closeTags(category,'space_category')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                    }) 
                }

                {productCategoryValue.length > 0 && productCategoryValue.map((category) => {
                    return <Tag onClose={() => closeTags(category,'product_category')} 
                    closable visible={true} 
                    className='manrope f-12 black-55 tag-filter'>Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                    })}

                {productStyleCategory.length > 0 && productStyleCategory.map((category) => {
                    return <Tag onClose={() => closeTags(category,'product_style_category')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Style Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                    })}

                {colorValue.length > 0 && colorValue.map((category) => {
                    return <Tag onClose={() => closeTags(category,'product_color')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Color: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                })}

                {productMaterials.length > 0 && productMaterials.map((category) => {
                    return <Tag onClose={() => closeTags(category,'product_material')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Material: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                })}

                {productModelType.length > 0 && productModelType.map((category) => {
                    return <Tag onClose={() => closeTags(category,'product_model_type')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Product Type: <span className='manrope f-12 grey-77'>{FileConstants.PRODUCT_MODEL_TYPE[category]}</span></Tag>
                })}
            </div>
          </Col>}
        </>
    
        return filteredTags;
      }
    
      const closeTags = (entity,type) => {
        //remove the tag when this is clicked from showing result section
        if(props.getModelSelectionStage() == 1){
            if (type == 'space_category') {
            let arr = spaceCategoryValue.filter(category => {return entity != category});
            setSpaceCategoryValue(arr);
            }
        }
        if(props.getModelSelectionStage() == 2){
            if (type == 'product_category') {
                let arr = productCategoryValue.filter(category => {return entity != category});
                changeProductCategory(arr)
              } else if (type == 'product_style_category') {
                let arr = productStyleCategory.filter(style_category => {return entity != style_category});
                setProductStyleCategory(arr);
              } else if (type == 'product_color') {
                let arr = colorValue.filter(color => {return entity != color});
                setColorValue(arr);
              } else if (type == 'product_material') {
                let arr = productMaterials.filter(material => {return entity != material});
                setProductMaterials(arr);
              } else if (type == 'product_model_type') {
                let arr = productModelType.filter(model=>{return entity !=model});
                setProductModelType(arr);
              }
        }
    }

    const onProductTabChange = (key) => {
        setCurrentTabType(key)
    }

    useEffect(() => {
        let currentDisplayedData = getFilteredLibraryData(originalProductsData, parseInt(currentTabType));
        setApprovedProducts(currentDisplayedData);
    }, [currentTabType]);

    const getFilteredLibraryData = (currentData, modelStatus) => {
        let newData = []
        currentData.filter ((item) => {
            if (item.model_status == modelStatus) {
                newData.push(item)
            }
        })
        return newData
    }

    const noDataDiv = () => {
        let message = ""
        if (currentTabType == "5") {
            message = `There are no products in ${MANAGED_CUSTOMER_USERNAME}'s Library.`
        } else if (currentTabType == "3") {
            message = `There are no products in Admin QA for ${MANAGED_CUSTOMER_USERNAME}`
        } else if (currentTabType== "4") {
            message = `There are no products in ${MANAGED_CUSTOMER_USERNAME}'s Customer QA.`
        }
        return <div className="empty-section" style={{marginTop:"10%", position:"relative",textAlign:'-webkit-center'}}>
        <img id="empty-section-image" style={{width:"10%"}}  src = "../../../../img/Materials.svg"/>
        <p className="empty-section-text manrope f-14 black-55">
          Nothing to show
        </p>
            <p style={{marginTop:"-1%"}} className="empty-section-text manrope f-12 grey-6b">
            {message}
            </p>
        </div>
    }

    const ProductTabs = <ProductsListVirtualized
        approvedProducts={approvedProducts}
        productSearch={productSearch}
        productsAdded={productsAdded}
        addedToCollection={addedToCollection}
        getSpaceAreas={props.getSpaceAreas}
        handleAddAction={handleAddAction}
        is_library_item={true}
        handleSpacecAddAction={handleSpacecAddAction}
        loadProducts={loadProducts}
        filtersApplied={filtersApplied}
        returnSearchValue={returnSearchValue}
        appliedFiltersList={appliedFiltersList}
    />

    const InfoAlert = 
        <Alert
        message="Please note that these items are not yet approved. Any changes made to the model files will automatically apply to the item used in the scene."
        type="info"
        showIcon
        />


    return (
        <div style={{ marginTop: "10px"}}>
            <div id="hellodiv" style={{ left: 0, zIndex: "1", width: "100%" }}>
                <div style={{ marginBottom: 20 }}>
                    <Row className="justify-space-between" style={{ padding: "10px 36px 10px 36px"}} >
                        <Col lg={10} xl={10} className="justify-in-start">
                            <h3 className='manrope f-24 black-00 w-600 mt-10'> {props.getModelSelectionStage() == 1 ? <span>Choose Space</span> : <span>Choose Products</span>}</h3>
                        </Col>
                        <Col lg={9} xl={7} className="justify-in-end" >
                            <Col lg={13} xl={15}  style={{ marginRight: 8 }}>
                            <Form>
                                <Input.Group compact size="large" className="d-inline">
                                {props.getModelSelectionStage() == 1 ?
                                    <Input placeholder="Search All Spaces" onChange={e => setRoomSearch(e.target.value)} className="manrope f-14 mat-search-bar"></Input>
                                    :
                                    <Input placeholder="Search All Products" onChange={e => setProductSearch(e.target.value)} className="manrope f-14 mat-search-bar"></Input>
                                }
                                </Input.Group>
                            </Form>
                            </Col>

                            {props.getModelSelectionStage() == 1 ?
                                <>
                                    <Col>
                                        <Tooltip title={<span className="manrope f-12 white">Apply Filters</span>}>
                                        <div className="icon-button-container mr-8" onClick={openFilters}>
                                            <FilterOutlined />
                                        </div>
                                        </Tooltip>
                                    </Col>
                                    {roomListType == 'library' ?
                                    <Col>
                                        <Tooltip title={<span className="manrope f-12 white">Browse Store</span>}>
                                        <Button
                                            className="modal-okay square font-12"
                                            onClick={() => modifyRoomListType('free')}>
                                            Add from Store
                                        </Button>
                                        </Tooltip>
                                    </Col>
                                        :
                                    <Col>
                                        <Tooltip title={<span className="manrope f-12 white">Space Library</span>}>
                                        <Button
                                            className="modal-okay square font-12"
                                            onClick={() => modifyRoomListType('library')}>
                                            Add from Library
                                        </Button>
                                        </Tooltip>
                                    </Col>
                                    }
                                </>
                                :
                                props.update_collection ? '' :
                                <>
                                    <Col>
                                        <Tooltip title={<span className="manrope f-12 white">Apply Filters</span>}>
                                        <div className="icon-button-container mr-8" onClick={openFilters}>
                                            <FilterOutlined />
                                        </div>
                                        </Tooltip>
                                    </Col>
                                    {productListType == 'library' ?
                                    <Col>
                                        <Tooltip title={<span className="manrope f-12 white">Browse Store</span>}>
                                            <Button
                                                className="modal-okay square font-12"
                                                onClick={() => {modifyProductListType('free'); resetEverything()}}>
                                                Add from Store
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                        :
                                    <Col>
                                        <Tooltip title={<span className="manrope f-12 white">Product Library</span>}>
                                            <Button
                                                className="modal-okay square font-12"
                                                onClick={() => {modifyProductListType('library'); resetEverything()}}>
                                                Add from Library
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                    }
                                </>
                        }
                        </Col>
                    </Row>

                </div>
            </div>
            {props.getModelSelectionStage() == 1 ?
                <div style={{paddingLeft: 34}}>
                    <>{getFilteredTags()}</>
                    <RoomModels
                        onAddCollectionClickForRoom={onAddCollectionClickForRoom}
                        setRoomsAdded={setRoomsAdded}
                        getCollectionId={props.getCollectionId}
                        setRoomsVisited={setRoomsVisited}
                        setSpaceAreas={props.setSpaceAreas}
                        roomListType={roomListType}
                        roomSearch={roomSearch}
                        processRequest={props.processRequest}
                        currentPageRoom={currentPageRoom}
                        setCurrentPageRoom={setCurrentPageRoom}
                        setRoomsDataLength={setRoomsDataLength}
                        pageSize={pageSize}
                        loadProducts={loadProducts}
                        filtersApplied={filtersApplied}
                        returnSearchValue={returnSearchValue}
                    />
                </div>
                :
                ""
            }

            {props.getModelSelectionStage() == 2 ?
                <div style={{paddingLeft: 34}}>
                    <>{getFilteredTags()}</>
                    {
                        (IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME != undefined) ?
                        productListType == 'library' ?
                            <Tabs
                                onChange={onProductTabChange}
                                defaultActiveKey={currentTabType}
                                size="small"
                                tabBarStyle={{ textAlign: "center" }}
                                animated={false}
                                tabBarGutter={32}
                            >
                                <TabPane tab="Approved Models" key="5" className='w-100'>
                                    {approvedProducts.length > 0 ? ProductTabs : noDataDiv()}
                                </TabPane>
                                <TabPane tab="In Admin QA" key="3" className='w-100'>
                                    <Row>
                                        <Col span={24} style={{marginBottom: 10}}>
                                            {InfoAlert}
                                        </Col>
                                        <Col span={24}>
                                            {approvedProducts.length > 0 ? ProductTabs : noDataDiv()}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tab="In Customer QA" key="4" className='w-100'>
                                    <Row>
                                        <Col span={24} style={{marginBottom: 10}}>
                                            {InfoAlert}
                                        </Col>
                                        <Col span={24}>
                                            {approvedProducts.length > 0 ? ProductTabs : noDataDiv()}
                                        </Col>
                                    </Row>
                                </TabPane>
                            </Tabs> :
                            <ProductsListVirtualized
                                approvedProducts={storeProducts}
                                productSearch={productSearch}
                                productsAdded={productsAdded}
                                addedToCollection={addedToCollection}
                                getSpaceAreas={props.getSpaceAreas}
                                handleAddAction={handleAddAction}
                                is_library_item={false}
                                handleSpacecAddAction={handleSpacecAddAction}
                                loadProducts={loadProducts}
                                filtersApplied={filtersApplied}
                                returnSearchValue={returnSearchValue}
                                appliedFiltersList={appliedFiltersList}
                            />
                        :
                        productListType == 'library' && !productsLoader ?
                        <ProductsListVirtualized
                            approvedProducts={approvedProducts}
                            productSearch={productSearch}
                            productsAdded={productsAdded}
                            addedToCollection={addedToCollection}
                            getSpaceAreas={props.getSpaceAreas}
                            handleAddAction={handleAddAction}
                            is_library_item={true}
                            handleSpacecAddAction={handleSpacecAddAction}
                            loadProducts={loadProducts}
                            filtersApplied={filtersApplied}
                            returnSearchValue={returnSearchValue}
                            appliedFiltersList={appliedFiltersList}
                        />
                        : !productsLoader ?
                        <ProductsListVirtualized
                            approvedProducts={storeProducts}
                            productSearch={productSearch}
                            productsAdded={productsAdded}
                            addedToCollection={addedToCollection}
                            getSpaceAreas={props.getSpaceAreas}
                            handleAddAction={handleAddAction}
                            is_library_item={false}
                            handleSpacecAddAction={handleSpacecAddAction}
                            loadProducts={loadProducts}
                            filtersApplied={filtersApplied}
                            returnSearchValue={returnSearchValue}
                            appliedFiltersList={appliedFiltersList}
                        /> : <DottedLoader/>
                    }
                </div>
                :
                ""
            }

            <div style={{ position: "fixed", height: "unset", padding: "0px", width: "100%", backgroundColor: "white", zIndex: 3, bottom: 0, boxShadow: "0px -8px 25px rgba(0, 0, 0, 0.04)" }}>

                <div style={{ margin: "20px 119px 20px 36px" }} >
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button disabled={isLoading}
                            className="outline-red-btn square font-12 pd-10-24"
                            onClick={props.update_collection ? goBackToSceneDetailPage : handleCancellation}>
                            {props.getModelSelectionStage() == 1? `Cancel`:`Back`}
                        </Button>
                        <Tooltip title={(addingProduct ? "Please wait. Your product is being added to the collection." : "")} placement="top">
                            <span>
                                <Button
                                    disabled={isLoading}
                                    className="modal-okay square font-12"
                                    style={{ marginLeft: 10, cursor: addingProduct ? "not-allowed" : "pointer", opacity: addingProduct ? "0.5" : "1" }}
                                    onClick={addingProduct ? '' : props.update_collection ? goToSceneCreatorView : handleSubmit}>
                                    {props.update_collection ? 'Go to Scene Creator' : 'Continue'}  {isLoading ? <LoadingOutlined /> : ""}
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Modal
                visible={modelVisible && !props.update_collection}
                width={800}
                className="modal-space"
                onCancel={handleCancel}
                bodyStyle={{ padding: 0 }}
                footer={[
                    <div className="flex-cookie-settings">
                        <Checkbox
                            className='manrope f-14 black-00'
                            checked={spaceCheck}
                            onChange={onChangeSpaceCheckbox}>
                            Don't Show Again
                        </Checkbox>,
                        <Button className="modal-okay square font-14" key="ok" onClick={handleOk}>
                            Okay
                        </Button>
                    </div>
                ]}>
                <div style={{ padding: "30px" }}>
                    <h2 className="modal-heading manrope f-16 black-00 w-600">Add Space Model(s) to your Collection</h2>
                    <p className="modal-text manrope f-14 black-55">Please add at least one Space Model to your Collection. Add by clicking on the “+” icon in the top right corner.</p>
                </div>
            </Modal>

            <Modal
                visible={roomModelVisible}
                width={800}
                className="modal-space"
                onCancel={handleRoomModelCancel}
                bodyStyle={{ padding: 0 }}
                footer={[
                    <div className="flex-cookie-settings">
                        <Checkbox
                            className='manrope f-14 black-00'
                            checked={productCheck}
                            onChange={onChangeProductCheckbox}>
                            Don't Show Again
                        </Checkbox>,
                        <Button className="modal-okay square font-14" key="ok" onClick={handleRoomModelOk}>
                            Okay
                        </Button>
                    </div>
                ]}>
                <div style={{ padding: "30px" }}>
                    <h2 className="modal-heading manrope f-16 black-00 w-600">Add Product(s) to your Collection</h2>
                    <p className="modal-text manrope f-14 black-55">Please add at least one Product to your Collection. Add by clicking on the “+” icon in the top right corner.</p>
                </div>
            </Modal>
            <Modal
                visible={roomValidationModelVisible && !props.update_collection}
                onCancel={() => setRoomValidationModelVisible(false)}
                width={800}
                className="model-space-alert"
                bodyStyle={{ padding: 0 }}
                footer={[
                    <div className='justify-in-end'>
                        <Button className="modal-okay square font-14" key="ok" onClick={() => setRoomValidationModelVisible(false)}>
                            Okay
                        </Button>
                    </div>
                ]}>
                <div style={{ padding: "30px", display: "flex" }}>
                    <img src='/img/alert-triangle.svg' style={{ width: "64px", height: "64px", marginRight: "20px" }} alt="Alert" />
                    <div>
                        <h2 className="modal-heading manrope f-16 black-00 w-600">No Space Model has been added to your Collection</h2>
                        <p className="modal-text manrope f-14 black-55">You have not added any Space Model to your Collection. Please add at least one Space Model to your Collection. Add by clicking on the “+” icon in the top right corner.</p>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={modelProductVisible}
                onCancel={handleProductCancel}
                width={800}
                className="model-space-alert"
                bodyStyle={{ padding: 0 }}
                footer={[
                    <div className='justify-in-end'>
                        <Button className="modal-okay square font-14" key="ok" onClick={productModelOk}>
                            Okay
                        </Button>
                    </div>
                ]}>
                <div style={{ padding: "30px", display: "flex" }}>
                    <img src='/img/alert-triangle.svg' style={{ width: "64px", height: "64px", marginRight: "20px" }} alt="Alert" />
                    <div>
                        <h2 className="modal-heading manrope f-16 black-00 w-600">No Product Model has been added to your Collection</h2>
                        <p className="modal-text manrope f-14 black-55">You have not added any Product Model to your Collection. Please add at least one Product Model to your Collection. Add by clicking on the “+” icon in the top right corner.</p>
                    </div>
                </div>
            </Modal>
            <SuccessModal
                onCancel={() => redirectToCollection()}
                visible={successPopupVisible}
                heading={<span>Collection Created Successfully!</span>}
                text={<span>You have successfully created your Collection. You can now go to the Scene Creator to create a Scene with your Collection to generate Lifestyle 360s and Images.</span>}
                footer={[
                    <div className='justify-in-center'>
                        <Button style={{ marginTop: 12 }} type="default" className="modal-okay-gray square font-14" onClick={() => redirectToCollection()} >
                            Not Now</Button>
                        <Button style={{ marginTop: 12, marginLeft: 6 }} className="modal-okay square font-14" key="ok" onClick={() => createScene()} >
                            Create Scene {createSceneLoader ? <LoadingOutlined spin /> : ''}</Button>
                    </div>
                ]}
            />

        <Drawer
        width={360}
        visible={filtersDrawer}
        onClose={closeFiltersDrawer}
        placement="right">
            <div style={{padding: 20}}>
            <h2 className="manrope f-18 black-14 w-700">Filters</h2>
            {props.getModelSelectionStage() == 1 &&
            <div>
                <h5 className='manrope f-16 black-14' style={{marginTop: 20}}>
                    Space Category
                </h5>
                <Select
                className={`tree-select-material f-14 filter bg-light`}
                showSearch={true}
                value={spaceCategoryValue}
                mode="multiple"
                onChange={changeSpaceCategory}
                placeholder="Search and select space category">
                    {spaceCategories && spaceCategories.map((category,index) => {
                    return <Option className="manrope f-12 select-view" value={category}>{category}</Option>
                    })}
                </Select>
            </div>}
            {props.getModelSelectionStage() == 2 &&
            <div>
            <h5 className='manrope f-16 black-14' style={{marginTop: 20}}>
                Product Category
            </h5>
            <TreeSelect
                value={productCategoryValue}
                showSearch={true}
                className="tree-select-material f-14 filter bg-light"
                style={{ width: '100%' }}
                multiple
                dropdownStyle={categoryStyle}
                placeholder="Search and select category"
                treeData={productCategoriesData}
                onChange={changeProductCategory}
                onSearch={changeProductSearchCategory}
            />

            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Style Category</h5>
            <StyleCategory
            className="tree-select-material f-14 filter bg-light"
            onChange={changeProductStyleCategory}
            value={productStyleCategory}
            placeholder="Search and select style category" 
            mode="multiple"
            />

            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Product Color</h5>
            <ColorSelect  
            className="tree-select-material f-14 filter bg-light"
            value={colorValue}
            onChange={changeColorValue}
            placeholder="Search and select color"
            mode="multiple"
            />

            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Product Material</h5>
            <TreeSelect
            value={productMaterials}
            showSearch={true}
            className="tree-select-material f-14 filter bg-light"
            style={{ width: '100%' }}
            multiple
            dropdownStyle={categoryStyle}
            placeholder="Search and select material"
            treeData={productMaterialsData}
            onChange={changeProductMaterial}
            onSearch={changeProductSearchCategory}
        >
        </TreeSelect>
        <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Product Type</h5>
        <Select
            className="tree-select-material f-14 filter bg-light"
            value={productModelType}
            onChange={changeProductModelType}
            showSearch
            placeholder="Search and select product type"
            mode="multiple"
        >
            {Object.keys(FileConstants.PRODUCT_MODEL_TYPE).map((key) => (
                <Select.Option
                    key={key}
                    className="manrope f-10"
                    value={key}
                >
                    {FileConstants.PRODUCT_MODEL_TYPE[key]}
                </Select.Option>
                ))}                                             
        </Select>
            </div>}
            </div>
        </Drawer>
        </div>
    );

};

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
    listProducts: () => {
        dispatch(listProducts());
    },
    listForStoreProducts: () => {
        dispatch(listForStoreProducts(LIST_FOR_STORE_PRODUCT));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ModelsSelection))