import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import { ajax } from 'rxjs/observable/dom/ajax';
import ENVIRONMENT from '../../environments'
import {
  LIST_SCENE,
  sceneSuccessAction,
  sceneFailureAction,
} from '../actions';

export const listScenes = (action$) => {
  return action$
      .ofType(LIST_SCENE)
      .switchMap(({ payload }) => {
        return ajax.post(ENVIRONMENT.GET_SCENE_BATCH,
            payload, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => sceneSuccessAction({ scenes: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(sceneFailureAction({ message: 'Scene Error' })));
      });
};
