import React, { useContext, useState } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Modal, Form, Row, Button, Typography, Col, Input, Select, TreeSelect, message } from 'antd';
import { LoadingOutlined, PlusOutlined, FlagOutlined, MailOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import './ProductComponents.scss'
import { Link } from "react-router-dom";
import ENVIRONMENT from '../../../../environments';
import { getBaseURL } from '../../../../environments/env';
import EmailSelect from '../../FormUtilities/EmailSelect';
import SuccessModal from '../../SuccessModal/SuccessModal';
import FileConstants from '../../../../FileConstants';
import * as Styles from '../../../../Styles';
import axios from 'axios';
import StyleCategory from '../../FormUtilities/StyleCategorySelect';
import ColorSelect from '../../FormUtilities/ColorSelect';

const { Paragraph } = Typography;
const { Option } = Select;

const categoryStyle = Styles.categoryStyle;

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");

const EditProductModal = () => {
    const { product_id, productEditModal, productAssetData,
        productData, setProductEditModal, customerGroupIds,
        setCustomerGroupIds, categoriesData, setCategoriesData, folderParentID } = useContext(CustomerProductContext);
    const [form] = Form.useForm();
    const [saveButtonLoader, setSaveButtonLoader] = useState(false);
    const [successSave, setSuccessSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [categorySearchValue, setCategorySearchValue] = useState('');
    const [categoryValue, setCategoryValue] = useState('');
    const [groupIdSearchValue, setGroupIdSearchValue] = useState('');
    const [groupid, setGroupId] = useState('');


    const updateProductInformation = async (payload) => {
        await axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
        return new Promise()
    }

    const saveProductData = async (values) => {
        if ((values.product_name == productAssetData.product_name) && (values.brand_id == productAssetData.brand_id)
            && (values.category == productAssetData.category) && (values.style_category == productData.style_category) && (values.reference_urls == productData.reference_urls) &&
            (values.color_name == productData.color_name) && (values.similar_color == productData.similar_color) && (values.guidelines == productData.guidelines) && (values.group_id == productData.group_id) && (values.tags == productData.tags)
            && (values.gtin == productData.gtin) && (values.product_model_type == productData.product_model_type)) {
            setErrorMessage("You have not made any change to your product model's data. Kindly make changes to the data and 'Save'.")
        } else {
            setErrorMessage('');
            setSaveButtonLoader(true);
            if (values.product_name && values.category && values.similar_color) {
                let payload = {
                    product_id: product_id,
                    product_name: values.product_name == "" ? productAssetData.product_name : values.product_name,
                    brand_id: values.brand_id,
                    gtin: values.gtin,
                    category: values.category == "" ? productAssetData.category : values.category,
                    style_category: values.style_category,
                    reference_urls: values.reference_urls,
                    color_name: values.color_name,
                    similar_color: values.similar_color == "" ? productData.similar_color : values.similar_color,
                    guidelines: values.guidelines,
                    group_id: values.group_id,
                    tags: values.tags,
                    product_model_type: values.product_model_type == "" ? productData.product_model_type : values.product_model_type,
                    username: CUSTOMER_USERNAME
                }
                Promise.allSettled([
                    updateProductInformation(payload),
                    triggerProductMetadataUpdate(values),
                    triggerSceneMetadataUpdate(values),
                ]).then(() => {
                    setSaveButtonLoader(false)
                    setSuccessSave(true)
                })

            } else {
                setErrorMessage('You cannot leave mandatory fields empty.');
            }

        }
    }


    const triggerProductMetadataUpdate = async(values) => {
        // Check if any of the following attrs are updated then update product renders metadata
        if( values.product_name != productAssetData.product_name || 
            values.category != productAssetData.category ||
            values.color_name != productData.color_name || 
            values.similar_color != productData.similar_color ||
            values.gtin != productData.gtin ||
            values.product_model_type == productData.product_model_type)
        {

            let update_lambda_payload = {
                'entity_type': 'product',
                'lambda_payload': {
                    'product_id': product_id
                }
            }
            await axios.post(ENVIRONMENT.TRIGGER_ENTITY_METADATA_UPDATE, update_lambda_payload)
        }
        return new Promise()
    }

    const triggerSceneMetadataUpdate = async(values) => {
        if(values.brand_id != productAssetData.brand_id || values.gtin != productAssetData.gtin){
            let update_lambda_payload = {
                'entity_type': 'scene',
                'lambda_payload': {
                    'product_id': product_id
                }
            }
            await axios.post(ENVIRONMENT.TRIGGER_ENTITY_METADATA_UPDATE, update_lambda_payload)
        }
        return new Promise()
    }


    const cancelEdit = () => {
        setProductEditModal(false);
    }

    const addCategoryOption = (e) => {
        if (e.keyCode == 13) {
            if (!categoriesData.some(e => e.key == categorySearchValue)) {
                setCategoriesData(categoriesData.concat([{
                    key: categorySearchValue,
                    value: categorySearchValue,
                    title: categorySearchValue,
                    children: [],
                }]));
            }
        }
    }


    const addGroupIdOption = () => {
        if (!customerGroupIds.some(e => e.key == groupIdSearchValue)) {
            setCustomerGroupIds(customerGroupIds.concat([{
                key: groupIdSearchValue,
                value: groupIdSearchValue,
                title: groupIdSearchValue,
            }]));
        }
    }

    return (<>

        <Modal
            visible={productEditModal}
            className="modal-share"
            width={788}
            maskClosable={false}
            closable={false}
            destroyOnClose={true}
            onCancel={cancelEdit}
            footer={[
                <div className="justify-in-end">
                    <Button disabled={saveButtonLoader} className="modal-okay square font-12" onClick={() => document.getElementById("save-product-data").click()}>
                        Save&nbsp;{saveButtonLoader ? <LoadingOutlined /> : ''}
                    </Button>
                </div>
            ]}
        >
            <div style={{ padding: "16px 6px 0 14px" }}>
                <div className="justify-space-between" style={{ marginBottom: 20 }}>
                    <h2 className="manrope f-18 black-14 w-700">Edit Information</h2>
                    <h2 className="manrope f-18 grey-77" style={{ cursor: "pointer" }} onClick={cancelEdit}>X</h2>
                </div>

                <Form
                    className="product-edit-form" initialValues={{
                        product_name: productAssetData.product_name,
                        brand_id: productAssetData.brand_id,
                        group_id: productData.group_id,
                        gtin: productData.gtin,
                        category: productAssetData.parent_category && FileConstants.HIDDEN_CATEGORY[productAssetData.parent_category] ? productAssetData.parent_category : productAssetData.category,
                        style_category: productData && productData.style_category,
                        product_model_type: productData && productData.product_model_type,
                        reference_urls: productData && productData.reference_urls,
                        color_name: productData && productData.color_name,
                        similar_color: productData && productData.similar_color,
                        guidelines: productData && productData.guidelines,
                        tags: productData && productData.tags
                    }}
                    onFinish={saveProductData} layout="vertical">
                    <Row type='flex' gutter={12}>
                        <Col span={24} style={{ marginBottom: 10 }}>
                            <Form.Item colon={false} label={<span className="manrope f-14 black-55">Name</span>} name="product_name" rules={[{ required: true, message: 'Please enter product name.' }]}>
                                <Input disabled={saveButtonLoader} placeholder={'Enter Product Name'} className="manrope f-12 black-55 library-search" />
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: 10 }}>
                            <div className="material-field">
                                <Form.Item label={<span className="manrope f-14 black-55">Product Model Category</span>} name="category" className="material-category-form"
                                    rules={[{ required: true, message: 'Please select a product model category.' }]}>
                                    <TreeSelect
                                        disabled={saveButtonLoader}
                                        className="tree-select-material f-12  item-height-35"
                                        showSearch
                                        style={{ width: '100%', color: "#333333" }}
                                        dropdownStyle={categoryStyle}
                                        placeholder={'Select or type in to search category'}
                                        treeData={categoriesData}
                                        value={categoryValue}
                                        onChange={(value) => setCategoryValue(value)}
                                        onSearch={(e) => { setCategorySearchValue(e) }}
                                        onInputKeyDown={(e) => { addCategoryOption(e) }}
                                        notFoundContent={<span className='tree-select-material f-12'>No such category found. Press enter to add your category.</span>}>
                                    </TreeSelect>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12} style={{ marginBottom: 10 }}>
                            <div className="material-field">
                                <Form.Item label={<span className="manrope f-14 black-55">Product Type</span>} name="product_model_type" className="material-category-form"
                                    rules={[{ required: false, message: 'Please select a product type.' }]}>
                                    <Select
                                        className={`tree-select-material f-12`}
                                        disabled={saveButtonLoader}
                                        value={FileConstants.PRODUCT_MODEL_TYPE[productData.product_model_type]}
                                        showSearch
                                        placeholder={`Select or type in your product model type`}
                                    >
                                        {Object.keys(FileConstants.PRODUCT_MODEL_TYPE).map((key) => (
                                            <Select.Option
                                                key={key}
                                                className="manrope f-10 select-view"
                                                value={key}
                                            >
                                                {FileConstants.PRODUCT_MODEL_TYPE[key]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12} style={{ marginBottom: 10 }}>
                            <div className="material-field">
                                <Form.Item label={<span className="manrope f-14 black-55">Product Style Category</span>} name="style_category" className="material-category-form"
                                    rules={[{ required: false, message: 'Please select a product model style sategory.' }]}>
                                    <StyleCategory disabled={saveButtonLoader} className="tree-select-material f-12" />
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24} style={{ marginBottom: 10 }}>
                            <div className="material-field">
                                <Form.Item label={<span className="manrope f-14 black-55">Product Group</span>} name="group_id" className="material-category-form"
                                    rules={[{ required: false, message: 'Please select product group.' }]}>
                                    <TreeSelect
                                        disabled={saveButtonLoader}
                                        className="tree-select-material f-12  item-height-35"
                                        showSearch
                                        style={{ width: '100%', color: "#333333" }}
                                        dropdownStyle={categoryStyle}
                                        placeholder={'Select or type in your product group name'}
                                        treeData={customerGroupIds}
                                        value={productData.group_id}
                                        onChange={(value) => setGroupId(value)}
                                        onSearch={(e) => { setGroupIdSearchValue(e) }}
                                        onInputKeyDown={(e) => { addGroupIdOption(e) }}
                                        notFoundContent={<span style={{ textAlign: 'center' }}><h5 style={{ textAlign: 'center' }} className='manrope f-14 black-55'>No Group Found</h5><p className='manrope f-14 blue' style={{ textAlign: 'center', cursor: 'pointer' }} onClick={addGroupIdOption}><PlusOutlined />&nbsp;Add As Group</p></span>}
                                    >
                                    </TreeSelect>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12} style={{ marginBottom: 10 }}>
                            <Form.Item colon={false} label={<span className="manrope f-14 black-55">Item ID</span>} name="brand_id" rules={[{ required: false, message: 'Please enter item id.' }]}>
                                <Input disabled={saveButtonLoader} placeholder={'Enter Item ID'} className="manrope f-12 black-55 library-search" />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ marginBottom: 10 }}>
                            <Form.Item colon={false} label={<span className="manrope f-14 black-55">GTIN</span>} name="gtin" rules={[{ required: false, message: 'Please enter GTIN for the product.' }]}>
                                <Input disabled={saveButtonLoader} placeholder={'Enter product GTIN'} className="manrope f-12 black-55 library-search" />
                            </Form.Item>
                        </Col>


                        <Col span={12} style={{ marginBottom: 10 }}>
                            <Form.Item colon={false} label={<span className="manrope f-14 black-55">Product Color</span>} name="color_name" rules={[{ required: false, message: 'Please enter product color.' }]}>
                                <Input disabled={saveButtonLoader} placeholder={'Enter Product Color'} className="manrope f-12 black-55 library-search" />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ marginBottom: 10 }}>
                            <Form.Item colon={false} label={<span className="manrope f-14 black-55">Closest Color Match</span>} name="similar_color" rules={[{ required: true, message: 'Please enter the closest color match of your product.' }]}>
                                <ColorSelect disabled={saveButtonLoader} className="tree-select-material f-12" />
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: 10 }}>
                            <Form.Item colon={false} label={<span className="manrope f-14 black-55">Reference URL(s)</span>} name="reference_urls" rules={[{ required: false, type: 'array', message: 'Please add reference url(s).' }]}>
                                <Select disabled={saveButtonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter Reference URL(s) that you would like us to have a look at, for your product model">
                                    <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: 10 }}>
                            <Form.Item colon={false} label={<span className="manrope f-14 black-55">Tag(s)</span>} name="tags" rules={[{ required: false, type: 'array', message: 'Please add tag(s).' }]}>
                                <Select disabled={saveButtonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter tag(s)">
                                    <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item colon={false} label={<span className="manrope f-14 black-55">Guidelines/ Comments</span>} name="guidelines" rules={[{ required: false, message: 'Please enter guidelines/comments.' }]}>
                                <Input disabled={saveButtonLoader} placeholder={'Enter guidelines/comments'} className="manrope f-12 black-55 library-search" />
                            </Form.Item>
                        </Col>
                        {errorMessage == "" ? '' :
                            <Col span={24} style={{ marginTop: 10 }}>
                                <div className="justify-space-between err-bg manrope f-12 red-error" style={{ marginRight: 8 }}><img src="/img/error-small.png" style={{ marginRight: 8 }} /><span>{errorMessage}</span></div>
                            </Col>}
                        <Button id="save-product-data" type="primary" htmlType="submit" style={{ "display": "none" }}>
                            Submit
                        </Button>
                    </Row>
                </Form>

            </div>
        </Modal>

        <SuccessModal
            visible={successSave}
            onCancel={() => setSuccessSave(false)}
            heading={"Product Data Updated Successfully!"}
            text={"You were successfully able to update product data."}
            footer={[
                <div className="justify-in-center">
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => window.location.href = "/products/" + product_id + `?folder_id=${folderParentID}`}>
                        Okay
                    </Button>
                </div>
            ]} />
    </>)
}

export default EditProductModal;