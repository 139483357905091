import React, {useState, useEffect} from 'react';
import { InfoCircleOutlined, MinusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import AWS from 'aws-sdk';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Upload, message, Tooltip, Checkbox, Input, Collapse, Card } from 'antd';
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import $ from 'jquery'
import axios from 'axios';
import SuccessModal from '../../../SuccessModal/SuccessModal';
import WarningModal from '../../../WarningModal/WarningModal';
import PaymentConfirmationModal from '../../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import {updateRoom, fetchRoom} from "../../../../../redux/actions";
import ENVIRONMENT  from '../../../../../environments'
import * as Constants from "../../Constants";
import { BUCKET_NAME } from '../../../../../environments/env';
import FileConstants from '../../../../../FileConstants';
import CardSaveDrawer from '../../../CardSaveDrawer/CardSaveDrawer';
import UpdateSubscriptionPackageModal from '../../../UpdateSubscriptionPackageModal/UpdateSubscriptionPackageModal.jsx';
import LicenseFields from '../../../FormUtilities/LicenseFields';
import PaymentFailureModal from '../../../PaymentFailureModal/PaymentFailureModal';
import InAppPurchaseLimitModal from '../../InAppPurchaseLimitModal';

const { STATIC_FILES_BASE_URL } = ENVIRONMENT;
const { Panel } = Collapse;

const PanelHeaderCheckbox = (props) => {
    return (
        <Checkbox
            checked={props.licenseChecked}
            onChange={(e) => {props.setLicenseChecked(e.target.checked)}}
            className="green-checkbox"
        >
        <span style={{color: (props.licenseChecked)?"black":"rgb(183 178 178)", fontWeight: 600, whiteSpace:"nowrap" }}>{props.text}</span>
        </Checkbox>
    );
}

const RoomOutsideViewForm = (props) => {

    const [modalState, setModalState] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [outsideFileList, setOutsideFileList] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [publicLicensePanel, setPublicLicensePanel] = useState(["1"]);
    const [privateLicensePanel, setPrivateLicensePanel] = useState(["1"]);
    const [publicLicenseChecked, setPublicLicenseChecked] = useState(false);
    const [privateLicenseChecked, setPrivateLicenseChecked] = useState(false);
    const [forSale, setForSale] = useState(false);
    const [forCreativeCommon, setForCreativeCommon] = useState(false);
    const [creativeCommon, setCreativeCommon] = useState('');
    const [formValidationFailed, setFormValidationFailed] = useState(false);
    const [roomSuccess, setRoomSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const [spaceCreatePayload, setSpaceCreatePayload] = useState(null);
    const [categoryValue, setCategoryValue] = useState('');
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
    const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [requestType, setRequestType] = useState('');
    const [roomID, setRoomID] = useState(new URLSearchParams(window.location.search).get("room_id"));
    const [paymentRequestID, setPaymentRequestID] = useState(-1);
    const twoDAssetsOptions = ['Perspective Images', '360 Spin'];
    const threeDAssetsOptions = ['GLTF (AR - Mobile - Web) Images', 'USDZ (AR - Mobile - Web)',
        'OBJ (AR - Mobile - Web)', 'High Poly Max (AR - Mobile - Web)'
    ];
    const required_assets = ['3D Model', 'HD Images', 'AR Compatible Model', 'Web Compatible Model'];
    const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFieldsValue} = props.form;
    const [submitType, setSubmitType] = useState('submit')
    const [selectedView, setSelectedView] = useState(0);
    const [requestID, setRequestID] = useState(-1);
    const [requestPrice, setRequestPrice] = useState(-1);
    const [spaceName, setSpaceName] = useState('');
    const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
    const [cardStatus, setCardStatus] = useState(null);
    const [requestStatus, setRequestStatus] = useState(null);
    const [price, setPrice] = useState(-1);
    const [priceError, setPriceError] = useState('');
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    

    useEffect(() => {
        setRequestType('room_model');
        let payment_redirected = new URLSearchParams(window.location.search).get("payment_redirected");
        let payment_failure = new URLSearchParams(window.location.search).get("payment_failure");
        let payment_request_id = new URLSearchParams(window.location.search).get("request_id");
        console.log(payment_redirected,payment_failure)
        if (payment_redirected) {
            // setRoomSuccess(true);
        }
        if (payment_failure) {
            // setCardSaveFailure(true);
            if (payment_request_id) {
                setPaymentRequestID(payment_request_id);
                setCardSaveDrawer(true);
            }
        }
        let room_id = new URLSearchParams(window.location.search).get("room_id");
        setRoomID(room_id);
        props.getRoom({room_id: roomID});
    },[]);

    const loadUserRequestStatus = () => {
        let payload = {
          "username": localStorage.getItem('username'),
          "request_type": "room_model",
          "action": "check",
          "category": categoryValue
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                console.log(res.data)
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
    
            });
    }

    const getRoomCategories = () => {
        let payload = {
            "request_type": requestType,
            "category": categoryValue
        }
   
        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
        .then(res => {
            console.log(res);
            if (res.data) {
                setRequestPrice(res.data[0].price);
                setSelectedPriceCategory(res.data[0].category);
            }
        })
    }

    const cancelPaymentConfirmation = () => {
        setPaymentConfirmationModal(false);
        setSubmitType('submit');
    }

    const changePrice = (e) => {
        setPrice(e.target.value);
    }

    useEffect(() => {
        if(props.roomReducer.room.outside_view_custom && props.roomReducer.room.outside_view_custom){
            setOutsideFileList(props.roomReducer.room.outside_view_custom);
            
        }
        if(props.roomReducer.isCreated == true || props.roomReducer.isUpdated == true) {
            if (!formSubmitted) {
                if(submitType == 'continue') {
                    setModalState(true);
                } else if (submitType == "save") {
                    // message.info('Space saved successfully');
                    props.handlePageRedirection();
                    document.activeElement.blur();
                }
            }
            
        }
        if(props.roomReducer.error) {
            message.error(props.roomReducer.error);
        }
        if (props.roomReducer.room) {
            console.log(props.roomReducer.room)
            setCategoryValue(props.roomReducer.room.category);
            if (props.roomReducer.room.room_name) {
                setSpaceName(props.roomReducer.room.room_name);
            }
            if (props.roomReducer.room.variant_of) {
                setRequestType('variation_room_model');
            } else{
                setRequestType('room_model');
            }
        }
        setFieldsValue(props.roomReducer.room);
    }, [props.roomReducer.room]);

    useEffect(() => {
        if (requestType && categoryValue) {
            getRoomCategories();
            loadUserRequestStatus();
        }
    },[categoryValue,requestType, props]);

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const handleRedirection = () => {
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
                        

        let product_state_string = '';
        let product_state = new URLSearchParams(window.location.search).get("product_state");
        let step_no = '0';
        if(onboarding){
            if (product_state != undefined) {
                step_no = '4'
            } else {
                step_no = '14';
            }
        }
        if(product_state != undefined){
            product_state_string = '&product_state='+product_state
        }
        props.history.push({
            pathname: '/home',
            search: ``
        }); 
    }

    const handleCancelBtn = () => {
        if(props.redirectionPath){
            props.history.push({
                pathname: props.redirectionPath
            });  
        } else {
            props.history.push({
                pathname: '/home'
            }); 
        }
    }

    const cancelConfirmationPayload = () => {
        setSubmitType('submit');
        setLoader(false);
        setPaymentConfirmationModal(false);
    }

    const uploadAnotherSpace = () => {
        let url = new URL(window.location.href);
        let step_no = '0';
        url.searchParams.set('step',step_no);
        url.searchParams.delete('room_id');
        console.log(url)
        window.location.href = url;
    }

    const confirmPaymentAndSubmit = () => {
        setLoader(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, spaceCreatePayload)
            .then(res => {
                if (res.data.status!=undefined && res.data.status == 1) {
                    setRoomSuccess(true);
                    setLoader(false);
                    setPaymentConfirmationModal(false);
                }
                else{
                    setPaymentFailureMessage(res.data.error_message);
                    setPaymentFailureModal(true);
                    setLoader(false);
                }
            });
    }

    const saveRequestIntermedietely = (callback = null) => {
        setLoader(true);
        console.log(JSON.stringify(spaceCreatePayload))
        let payload = {
            'request_type': requestType,
            'request_body': spaceCreatePayload,
            'processed': false
        }
        if (paymentRequestID == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
                .then(res => {
                    console.log(res);
                    setLoader(false);
                    setPaymentConfirmationModal(false);
                    if (callback!= null)
                    {
                    callback(res.data[0].request_id,requestType);
                    }
                });
        } else {
            setLoader(false);
            setPaymentConfirmationModal(false);
            if (callback!= null)
            {
                callback(paymentRequestID,requestType);
            }
        }
    }


    const handleChangeOutside = (info) => {
        let fileList = [...info.fileList];
        setOutsideFileList(fileList);
        setUploading(true);
        console.log(info,info.file);
        if (info.file.status === "done") {
            setHasError(false);
            setSelectedView(-1);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    }

    const triggerUpload = () => {
        $('#room_outside_outside_view_custom').trigger('click');
        let button = document.getElementById('room_outside_outside_view_custom');
        button.disabled = false;
    }

    const handleSubmit = e => {
        console.log('handlesubmit')
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log(values)
                if (publicLicenseChecked && forSale && price == -1) {
                    setPriceError('Please enter a price')
                } 
                else if(validateCustomFields()){
                    setPriceError('');
                    values.licensing_options = getLicensingOptions();

                    if(selectedView != -1)
                    {
                        values.outside_view = FileConstants.viewOutsideImages[selectedView];
                        let id = new URLSearchParams(window.location.search).get("room_id");
                        props.updateRoom({...values, room_id: id});
                    }
                    if (outsideFileList && outsideFileList.length > 0) {
                        values.outside_view_custom = outsideFileList;
                    }
                    let uploadStatus = validateFileUploads(values['outside_view_custom']);
                    if (uploadStatus == 'uploading') {
                        setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                    }
                    else if (uploadStatus == 'error') {
                        setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
                    }
                    else {
                        let room_id = new URLSearchParams(window.location.search).get("room_id");
                        let requestPayload = values;
                        setRoomID(room_id);
                        requestPayload['username'] = localStorage.getItem('username');
                        requestPayload['room_id'] = room_id;
                        requestPayload['model_status'] = 2;
                        requestPayload["user_type"] = "customer"
                        let payload = {
                            "username": localStorage.getItem('username'),
                            "request_type": requestType,
                            "update": "",
                            "action": "perform",
                            "request_payload": requestPayload,
                            "category": selectedPriceCategory,
                            "request_id": Date.now().toString()
                        }
                        if (FileConstants.isMSProvider && (FileConstants.MANAGED_CUSTOMER_USERNAME != null || FileConstants.MANAGED_COMPANY_ID != null)) {
                            payload["request_payload"]['requested_for'] = FileConstants.MANAGED_CUSTOMER_USERNAME
                        }
                        console.log(payload,requestStatus)
                        if (submitType == 'continue') {
                            console.log('Contimure',requestStatus)
                            if (requestStatus == 'allowed' || requestStatus == 'always_allowed') {
                                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
                                .then(res => {
                                    if (res.data.status!=undefined && res.data.status == 1) {
                                        setLoader(true);
                                        setFormSubmitted(true);
                                        handleCancel();
                                        setRoomSuccess(true);
                                    }
                                    else{
                                        setPaymentFailureMessage(res.data.error_message);
                                        setPaymentFailureModal(true);       
                                    }
                                });
                            }
                            if (requestStatus == 'payment_required') {
                                setSpaceCreatePayload(payload);
                                setPaymentConfirmationModal(true);
                            }
                            if (requestStatus == 'not_allowed') {
                                setInAppLimitExceededModal(true);
                            }
                        } else {
                            props.updateRoom({...values, room_id: room_id});
                        }
                        
                    }
                } else {
                    setSubmitType('submit');
                    setLoader(false);
                    setFormValidationFailed(true);
                }
            } else {
                setSubmitType('submit');
                setLoader(false);
                setFormValidationFailed(true);
            }
        });
    };

    const initiateConfirmation = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'room_model') {
                confirmPaymentAndSubmit();
            }
            
        }, false);

        if (cardStatus == 1) {
            confirmPaymentAndSubmit();
        } 
        if (cardStatus == 0) {
            let child_window = window.open('/save-card?action=room_model', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);  
              }
            }, 500);
            setCardSaveFlowTriggered(true);
        }
    }

    let viewImagesGrid = '';
    if(FileConstants.viewOutsideImages){
        viewImagesGrid = FileConstants.viewOutsideImages.map((ele, index) => {
            let css = {};
            if (index == selectedView){
                css = {"box-shadow": "rgba(0, 0, 0, 0.8) 0px 0px 21px -1px", "transition": "all 0.3s ease 0s", opacity: 0.7,margin: "10px 0 30px 0"};
            }
            else {
                css = {margin: "10px 0 30px 0"}
            }
            return (
                <Col span={12}>
                    <MinusOutlined />&nbsp;<span className="outside-view-name manrope f-12 black-55">{ele.name}</span>
                    <img style={css} height="300px" width="100%" onClick={() => { 
                        setSelectedView(index)
                        setOutsideFileList([]);
                    }} className="" src={ele.image} alt=""></img>
                </Col>
            );
        });
    }


    const showModal = () => {
        setModalState(true);
    }

    const handleCancel = () => {
        setModalState(false);
    }

    const checkIfValidAgain = (e) => {
        if(e != undefined) {
            e.preventDefault();
        }
        if(formValidationFailed){
            props.form.validateFields((err) => {
                if (!err){
                    if(validateCustomFields()){
                        setFormValidationFailed(false);
                    }
                }
            });
            
        }
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'room_model') {
                confirmPaymentAndSubmit();
            }
            
        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=room_model', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if(child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);  
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    const controlLicenseDetailPanels = (value) => {
        if(value){
            setPublicLicensePanel(["1"]);
            setPrivateLicensePanel(["1"]);
        }
        else{
            setPublicLicensePanel();
            setPrivateLicensePanel();
        }
    }

    const changePrivateLicenseChecked = (value) => {
        setPrivateLicenseChecked(value);
        controlLicenseDetailPanels(!value);
        if(value){
            setPublicLicenseChecked(!value);   
        }
    }

    const changePublicLicenseChecked = (value) => {
        setPublicLicenseChecked(value);
        controlLicenseDetailPanels(!value);
        if(value){
            setPrivateLicenseChecked(!value);
        }
    }

    const changeForSale = (value) => {
        setForSale(value);
        if(value){
            setForCreativeCommon(!value);
        }
    }

    const changeForCreativeCommon = (value) => {
        setForCreativeCommon(value);
        if(value) {
            setForSale(!value);
        }
    }

    useEffect(()=> {
        checkIfValidAgain();
    }, [publicLicenseChecked, privateLicenseChecked, forSale, forCreativeCommon, creativeCommon])

    const validateCustomFields = () => {
        if (!publicLicenseChecked && !privateLicenseChecked){
            return false;
        }
        else if (publicLicenseChecked && (!forSale && !forCreativeCommon) ){
            return false;
        }
        else if (publicLicenseChecked && forCreativeCommon && creativeCommon == '') {
            return false;
        }
        else{
            return true;
        }
    }

    const getLicensingOptions = () => {
        let licensing_options = {
            license: publicLicenseChecked?'public':'private'
        }

        if(publicLicenseChecked){
            licensing_options.public_option = forSale?'forSale':'forCreativeCommon';
            if(forCreativeCommon){
                licensing_options.creative_common = creativeCommon; 
            }
        }

        return licensing_options;
    }

    const onContinue= () => {
        setSubmitType('continue')
        setModalState(true)
    } 
   

    return (
        <React.Fragment>
            <Form  className="input-form"  onSubmit={handleSubmit}>
                <Row gutter={16}>


                    <Col span={24}>
                        <h5 style={{textAlign: 'left',marginBottom: 10}}  className='manrope f-14 black-00'>You can select outside window view fo your space by either selecting from below or uploading your own outside window view image.</h5>
                        <Form.Item name ="outside_view" label={(<span className="manrope f-14 black-55 w-600">Outside Views&nbsp;<Tooltip title={<span className='manrope f-12 white'>Click on image to select</span>}><InfoCircleOutlined /></Tooltip></span>)} colon={false}>
                            {props.roomReducer.room.floorplan && getFieldDecorator('outside_view', {
                                valuePropName: 'outside_view',
                                getValueFromEvent: e => e && e.fileList,
                            })(
                                <span></span>
                            )}
                        </Form.Item>
                        <Row gutter={12}>
                            {viewImagesGrid}
                        </Row>
                    </Col>


                    <Col span={24} id="floor-upload">
                    <h5 style={{textAlign: 'center', marginTop: 20}} className="manrope f-14 black-55">(OR)</h5>
                    <h5 style={{textAlign: 'center'}} className="manrope f-14 black-55">Upload your own</h5>
                    <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerUpload}>
                        <div style={{textAlign:"left",minWidth:"100%"}}>
                            <Form.Item name ="outside_view_custom" label={(<span className="manrope f-14 black-55 w-600">Outside View Image&nbsp;<Tooltip title={<span className='manrope f-12 white'>Space floorplan just like sample provided above.</span>}><InfoCircleOutlined /></Tooltip></span>)} colon={false}>
                            
                                {props.roomReducer.room.floorplan && getFieldDecorator('outside_view_custom', {
                                    valuePropName: 'outside_view_custom',
                                    getValueFromEvent: e => e && e.fileList,
                                })(
                                    
                                    <Upload {...(Constants.getUploadProps(props.roomReducer.room.platform))} multiple={true}  listType="text" className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-adjust-form-heights modal-archive-3d"
                                    fileList={outsideFileList}
                                    openFileDialogOnClick={false}
                                    // openFileDialogOnClick={!(validateFileUploads(outsideFileList) == 'error')} 
                                    onChange={handleChangeOutside}
                                    onRemove={file => {
                                        let button = document.getElementById('room_outside_outside_view_custom');
                                        button.disabled = true;
                                        const index = outsideFileList.indexOf(file);
                                        const newFileList = outsideFileList.slice();
                                        newFileList.splice(index, 1);
                                        setOutsideFileList(newFileList);
                                        console.log(newFileList)
                                        newFileList.forEach((file) => {
                                            if (file.status !== "error"){
                                                setHasError(false)
                                            }
                                            else {
                                                setHasError(true)
                                            }
                                        })
                                        return true;
                                    }}
                                    accept=".jpg,.jpeg,.png"
                                    progress= {{
                                        strokeColor: {
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                        },
                                        strokeWidth: 3,
                                        format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                    }}>
                                        
                            
                                        <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                <div style={{textAlign:"center"}}>
                                                        <img className="" src={require("../../../../../assets/images/upload.png")} alt=""></img>
                                                        <div className="ant-upload-text ">
                                                            <div style={{lineHeight:"150%"}} className="up-info manrope f-10">Drop your <span className="highlight-text"> space outside window view </span> photo, or browse</div>
                                                            <div className='manrope f-10'>Supported formats: JPG, PNG</div>   
                                                        </div>
                                                        {(validateFileUploads(outsideFileList) == 'error') && (<Button
                                                        onClick={() => Constants.triggerFailedFilesUpload('room_outside_outside_view_custom', outsideFileList, setOutsideFileList)}
                                                        disabled={outsideFileList.length === 0}
                                                        loading={uploading}
                                                        style={{top: 0}}
                                                        className="retry-btn manrope f-12" ghost
                                                        >
                                                        {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                    </Button>)}
                                                </div>
                                            </div>    
                                    </Upload>
                                    
                                )}
                            </Form.Item>
                            </div>
                        </div>
                        {(validateFileUploads(outsideFileList) == 'error') ? 
                        <div className="manrope f-12 red" style={{marginBottom: "10px", textAlign: "center"}}>
                            {errorMessage}
                        </div>: ''}
                    </Col>
   
                    <LicenseFields
                     changePrice={changePrice}
                     creative_commons_text={FileConstants.creative_commons_text}
                     creativeCommon={creativeCommon}
                     setCreativeCommon={setCreativeCommon}
                     forSale={forSale}
                     changeForSale={changeForSale}
                     publicLicenseChecked={publicLicenseChecked}
                     forCreativeCommon={forCreativeCommon}
                     changeForCreativeCommon={changeForCreativeCommon}
                     formValidationFailed={formValidationFailed}
                     publicLicensePanel={publicLicensePanel}
                     changePublicLicenseChecked={changePublicLicenseChecked}
                     privateLicenseChecked={privateLicenseChecked}
                     privateLicensePanel={privateLicensePanel}
                     changePrivateLicenseChecked={changePrivateLicenseChecked}/>      
                     {priceError != '' ?
                    <div className="justify-space-between err-bg manrope f-14 red-error" style={{marginTop: 10,marginBottom: 20}}>
                        <img src="/img/error-small.png" style={{marginRight: 8}}/>
                        <span>{priceError}</span>
                    </div>
                    :""}
                    {(uploadError != '')?
                        <div className="manrope f-12 red" style={{marginBottom: "10px", textAlign: "center"}}>{uploadError}</div>
                    :""}
                    <Col span={24} style={{display:"flex",justifyContent:"space-between",marginTop: '15px'}}>
                        <Form.Item>
                            <Button onClick={handleCancelBtn} className="modal-okay-gray square font-14">
                                Cancel
                            </Button>
                        </Form.Item>
                        <Form.Item className="justify-buttons">
                            <Button onClick={() => setSubmitType('save')} className="modal-okay-gray square font-14" htmlType="submit" style={{marginRight: '5px'}}>
                            Save Draft & Exit {props.roomReducer.isLoading && submitType == 'save'?     <LoadingOutlined spin />: ''}
                            </Button>
                            <Button onClick={onContinue} className="modal-okay-green square font-14" htmlType="submit">
                                Continue
                                {submitType == 'continue' && (modalState) ? <LoadingOutlined spin />: ''}
                                
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <PaymentFailureModal 
            paymentFailureMessage={paymentFailureMessage}
            setPaymentFailureModal={setPaymentFailureModal}
            updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
            paymentFailureModal={paymentFailureModal}
            />
            <InAppPurchaseLimitModal visible={inAppLimitExceededModal} setVisible={setInAppLimitExceededModal} />
            <SuccessModal
            visible={roomSuccess}
            onCancel={() => setRoomSuccess(false)}
            heading={"Space Request Submitted Successfully!"}
            text={"Your Space creation request has been successfully submitted. You will be notified via email once your space is ready to be reviewed."}
            footer={[
                <div className="justify-in-center">
                    <Button className="modal-okay-gray square font-14" onClick={uploadAnotherSpace}>
                        Upload Another Space
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={handleRedirection}>
                        Go To Dashboard
                    </Button>
                </div>
                
            ]}
            />
            <PaymentConfirmationModal
            name={spaceName}
            visible={paymentConfirmationModal}
            amount={"$" + requestPrice}
            footer={[
            <span style={{display: 'flex', justifyContent:'center'}}>
                <Button disabled={loader} className="modal-okay-gray font-14 square" onClick={cancelPaymentConfirmation}>
                Cancel
                </Button>
                <Button disabled={loader || cardSaveFlowTriggered} className="modal-okay font-14 square" onClick={initiateConfirmation}>
                {cardStatus == 1 && requestStatus == "payment_required" ? "Confirm & Process Payment" :
                cardStatus == 0 && requestStatus == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm"} {loader == true ? <LoadingOutlined type="sync" spin />: ''}
                </Button>
            </span>
            ]}
            text={"Please pay the amount below to proceed. It can take 24 to 72+ hours for completion of the model based on complexity"}
            onCancel={cancelPaymentConfirmation}/>
            <CardSaveDrawer
             setCardSaveFailure={setCardSaveFailure}
             saveRequestIntermedietely={saveRequestIntermedietely}
             visible={cardSaveDrawer} 
             onClose={() => setCardSaveDrawer(false)} 
            />
            <WarningModal
            visible={cardSaveFailure}
            onCancel={() => setCardSaveFailure(false)}
            heading={"Unable to Save Payment Details"}
            text={"Failed to process payment details. Please try another payment method."}
            footer={[
            <div className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => setCardSaveFailure(false)}>
                    Okay
                </Button>
            </div>
            ]}/>
            <UpdateSubscriptionPackageModal

            />
        </React.Fragment>
    );
}

const RoomOutsideView = Form.create({name: 'room_outside'})(RoomOutsideViewForm);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
    getRoom: (payload) => {
        dispatch(fetchRoom(payload))
    },
});
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(RoomOutsideView)
)