import React from "react";
import _ from "lodash";
import { Row } from "antd";
import SpaceActions from "./SpaceActions";

const SpaceActionBar = ({ roomDetails }) => {
  return (
    <Row className={`space-action-bottom-bar`}>
          <SpaceActions roomDetails={roomDetails} />
    </Row>
  );
};

export default SpaceActionBar;
