import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Button, message, Input  } from 'antd';
import { listCollections, updateCollection } from "../../../../redux/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FileConstants from '../../../../FileConstants';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;

const FirstCollection = (props) => {
    const [visible, setVisible] = useState(false);
    const [collectionNames, setCollectionNames] = useState([]);
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState('');
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFsieldsValue, resetFields } = props.form;

    const handleCancel = () => {
        setVisible(false);
        props.setCollectionModalVisible(false);
        resetFields();
    }

    useEffect(() => {
        setVisible(props.visible);
        if (props.visible === false) {
            resetFields();
        }
    }, [props.visible])

    const getCollectionPayload = () => {
        let payload = {
            "required_fields": ["id", "name", 
            "created_on", "spaces_count", "products_count", "template_collection_id", 
            "product_thumbnails", "thumbnail_link"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"
    
        if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("collection")) {
            company_id = "company_id__exact=" + COMPANY_ID
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")&&(template_collection_id__is=null)"
        } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")&&(template_collection_id__is=null)"
        }
        filter_string = filter_string + "||(" + has_access_to + ")||(template_collection_id__not=NULL&&" + customer_username+ "&&"+ is_hidden +")"
       
        payload['filter_string'] = filter_string
    
        return payload;
      }

    useEffect(() => {
        props.listCollections(getCollectionPayload());
        getCollectionNames();
    }, []);

    const getCollectionNames = () => {
        let payload = {}
        if (FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null)) {
          payload.username = CUSTOMER_USERNAME;
          payload.designed_for = MANAGED_CUSTOMER_USERNAME;
        } else {
          payload.username = CUSTOMER_USERNAME;
       
        }
        let shared_entities = SHARED_ENTITIES;
        let company_id = COMPANY_ID;
        if (shared_entities != undefined && company_id != undefined) {
            if (shared_entities.includes('collection')) {
                payload.company_id = company_id
            }
        }
        axios.post(ENVIRONMENT.GET_COLLECTION_NAMES, payload)
        .then((response) => {
            console.log(response);
            let names = []
            response.data && response.data.map((coll => {
                names.push(coll.name);
            }))
            setCollectionNames(names);
            setLoader(false);
        });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                let payload = {};
                payload.username = localStorage.getItem("username");

                if (values.collection_name) {
                    payload.collection_name = values.collection_name;
                }

                if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
                    payload.designed_for = localStorage.getItem('managed_customer_username');
                }

                let company_id = localStorage.getItem("company_id");
                if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null)) {
                    company_id = localStorage.getItem("managed_company_id");
                }
                if (company_id != undefined) {
                    payload.company_id = company_id
                }

                if (collectionNames.includes(values.collection_name)) {
                    setError('Please enter a different name as a collection with this name already exists in your library.')
                } else {
                    setError('');
                    axios.post(ENVIRONMENT.UPDATE_COLLECTION, payload)
                        .then((response) => {
                            console.log(response);
                            let collection_id = response.data["collection_id"]
                            props.setCollectionId(collection_id);
                            props.setModelSelectionView();
                            message.successc('Collection Added!');

                        })
                        .catch((error) => {
                        });
                }
            }
        });
    }

    return (
        <div>
            <div style={{textAlign: "center"}}>
                <img style={{marginTop: 20,width:90 }} src={require("../../../../assets/images/collection-icon-empty.svg")}></img>
                <h3 className='manrope f-14 black-00 w-600'>You have no Collections!</h3>
                <h3 className='manrope f-12 black-00'>Create a Collection to get started!</h3>
            </div>
            <div>
                <Form className="input-form" onSubmit={handleSubmit}>
                    
                <Form.Item name="collection_name" label={<span className='manrope f-14 black-00 w-600'>Collection Name</span>} colon={false}>
                    {getFieldDecorator('collection_name', {
                        rules: [{ required: true, message: 'Please input collection name' }],
                    })(
                        <Input  className="manrope f-12 black-55"
                            placeholder="Please type your collection name"
                        />
                    )}
                </Form.Item></Form>
                <Row type="flex" style={{ marginTop: 20, justifyContent: "space-around" }}>
                    <div style={{display:'flex'}}>
                        <Button className="outline-red-btn square font-14" onClick={handleCancel}>Cancel</Button>
                        <Button className="modal-okay-green square font-14" style={{ marginLeft: 4 }} disabled={loader} onClick={handleSubmit}>Continue</Button>
                    </div>

                </Row>
                {error != '' ? <Row style={{marginTop: 10}}>
                    <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8,marginBottom: 8}}><img src="/img/error-small.png" style={{marginRight: 8,marginBottom:8}}/><span>{error}</span></div>
                </Row>: ''}
            </div>
            <div style={{textAlign: "center", marginTop: 20}}>
            <p className='manrope f-12 grey-73'>
            Space and Product Models make up a Collection. Use your Collection to layout your Scene to generate beautiful Lifestyle Images.
            </p>
            </div>
        </div>
    );

}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    listCollections: () => {
        dispatch(listCollections());
    },
    updateCollection: (payload) => {
        dispatch(updateCollection(payload));
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create({ name: 'add-first-collection' })(FirstCollection))
)
