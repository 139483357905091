import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { GET_SCENE_DETAIL } from '../../../../environments/env';
import VirtualShowroom from '../../../../VirtualShowroom';
import { Card } from 'antd';
import axios from 'axios';

class Public3DTourViewer extends React.Component {

    constructor(props){
        super(props);
        this.virtualShowroom = null;
        this.state={
            id: this.props.match.params.id,
            threed_tour: ""
        }
        
    }

    componentDidMount() {
        var payload = { "scene_id": this.state.id };

        axios.post(GET_SCENE_DETAIL, payload)
        .then(res => {
            let threed_tour = "";
            if("threed_tour" in res.data) {
                threed_tour = JSON.stringify(JSON.parse(res.data["threed_tour"])["threed_tour"]);
            }
            this.setState({
                threed_tour: threed_tour
            }, () => {
                setTimeout(function() {
                    console.log("State",this.state.id);
                    this.virtualShowroom = new VirtualShowroom(this.state.threed_tour, this.state.id, true, "hotspot","vs-container");
                    this.virtualShowroom.toggleFull(true);
                }.bind(this), 1000);
            });
        });
    }


    render () {
        return (
            <div>
                <Card id={`vs-container`} className="tour-fullscreen" style={{cursor:"grabbing"}}>
                    <div className="hotspot" id="hotspot">
                        <img className="hotspotImage" src="https://all3d-demos.s3.amazonaws.com/Virtual-Showroom/Resources/hotspot.gif" alt="Hotspot" />
                        <span className="tooltiptext" id="toolTip"></span>
                    </div>
                </Card>
                <div id="loading-container" style={{width: "100vw",height: "100vh"}}>
                    <img id="loadingImage" className="loadingImage" src="" style={{width: "100%",height: "100%"}}></img>
                    <div class="profile-main-loader tour">
                        <div class="loader">
                        <svg class="circular-loader"viewBox="25 25 50 50" >
                            <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#808080" stroke-width="2" />
                        </svg>
                        </div>
                    </div>

                    <div style={{position: "absolute",bottom: 160,width: "100%"}}>
                        <div style={{display: "flex", justifyContent: "center"}} className="my_font">
                        powered by
                        </div>
                        <div style={{display: "flex", justifyContent: "center", marginTop: 10}} >
                            <img className="logo-loader-screen" src="/img/logo.svg" width="111px" />
                        </div>
                    </div>   
                </div>
            </div>
             
        );
    }
}

export default Public3DTourViewer;