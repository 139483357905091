import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Tooltip, Row, Col, Button, Card, Menu, Dropdown } from 'antd';
import ProductPageConstants from './ProductPageConstants.js';
import FileConstants from '../../../../FileConstants';
import ProductVariantGrid from './ProductVariantGrid';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const SameBrandOtherProducts = () => {
    const { moreStoreModels, companyName, companyDomain } = useContext(CustomerProductContext);
    const [carousel, setCarousel] = useState(null);

    const redirectToStore = () => {
        window.location.href = '/store/' + companyDomain;
    }

    return (<>
        {moreStoreModels && moreStoreModels.length > 0 &&
            <Row className='product-variant-container mb-40'>
                <Col span={24}>
                    <div className='display-flex j-s a-c w-100 mb-30'>
                        <h3 className='mb-0 manrope f-24 black-26'>More from {companyName}</h3>
                    </div>

                    <Carousel
                        ref={(el) => { setCarousel(el) }}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={ProductPageConstants.variant_grid_break_points}
                        ssr={true} // means to render carousel on server-side.
                        infinite={false}
                        autoPlay={false}
                        arrows={true}
                        autoPlaySpeed={8000}
                        keyBoardControl={false}
                        containerClass="carousel-container-store variant-grid">
                        {moreStoreModels.map((product, index) => (
                            <ProductVariantGrid key={index}
                                product={product}
                            />
                        ))}
                        <Col span={24}>
                            <div className='see-more-button' onClick={redirectToStore}>
                                <div className='see-more-txt manrope f-14 blue justify-in-center'>See More Items</div>
                            </div>
                        </Col>
                    </Carousel>
                </Col>
            </Row>
        }</>);
};

export default SameBrandOtherProducts;