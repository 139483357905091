import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import { PlusOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Layout, Menu, Button, Row, Dropdown } from 'antd';
import { ReactComponent as HomeIcon } from '../../../../icons/home.svg'
import { ReactComponent as ProductIcon } from '../../../../icons/my-products.svg'
import { ReactComponent as CollectionIcon } from '../../../../icons/my-collections.svg'
import { ReactComponent as RoomIcon } from '../../../../icons/my-rooms.svg'
import { ReactComponent as SceneIcon } from '../../../../icons/my-scenes.svg'
import { ReactComponent as HelpIcon } from '../../../../icons/help-icon.svg'
import { ReactComponent as MaterialIcon } from '../../../../icons/my-materials.svg'
import { ReactComponent as BulkIcon } from '../../../../icons/Bulk.svg'
import { ReactComponent as UsageIcon } from '../../../../icons/account-usage.svg'
import { ReactComponent as CanvasIcon } from '../../../../icons/canvas.svg'
import { ReactComponent as CustomerReportsIcon } from "../../../../icons/customer-reports.svg";
import CustomHeader from '../../CustomHeader';
import FileConstants from '../../../../FileConstants';

const { Header, Content, Sider } = Layout;

const menu = (
    <Menu>
        <Menu.Item> <Link to="/product-model">Create / Upload Product Model</Link> </Menu.Item>
        <Menu.Item> <Link to="/add-new-room?origin=/home">Create Space Model</Link> </Menu.Item>
        <Menu.Item> <Link to="/add_collection?origin=/home">Create Collection</Link> </Menu.Item>
        <Menu.Item> <Link to="/add_scene?origin=/home">Create Scene</Link> </Menu.Item>
        <Menu.Item> <Link to="/material-request">Create / Upload Material</Link> </Menu.Item>
        {FileConstants.BULK_REQUEST_ELIGIBLE_COMPANIES?.includes(localStorage.getItem('company_id')) ? 
        <Menu.Item> <Link to="/bulk-product-config-create">Create Bulk Request</Link> </Menu.Item>
        :''}
        <Menu.Item> <Link to="/new_project">Create Project</Link> </Menu.Item>
    </Menu>
);
const { SubMenu } = Menu;

class CustomerMainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            selected: props.selected,
            current_page: this.props.match.url
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };


    handleCreateNewFormChange = (e) => {
        if (e.key == 1) {
            window.location.href = '/upload-product-model?origin=' + this.state.current_page;
        }
        if (e.key == 2) {
            window.location.href = '/add-new-room?origin=' + this.state.current_page;
        }
        if (e.key == 3) {
            window.location.href = '/create-empty-scene?origin=' + this.state.current_page;
        }
        if (e.key == 4) {
            window.location.href = '/create-scene-from-template?origin=' + this.state.current_page;
        }
        if (e.key == 5) {
            window.location.href = '/create-new-material?origin=' + this.state.current_page;
        }
        if (e.key == 6) {
            window.location.href = '/upload-scanned-material?origin=' + this.state.current_page;
        }
        if (e.key == 7) {
            window.location.href = '/bulk-product-config-create'
        }
        if (e.key == 8) {
            window.location.href = '/create-existing-physical-product?origin=' + this.state.current_page;
        }
        if (e.key == 9) {
            window.location.href = '/add-new-product?origin=' + this.state.current_page;
        }
        if (e.key == 10) {
            window.location.href = '/new_project?origin=' + this.state.current_page;
        }
    }

    handleMenuItemChange = (e) => {
        if (e.key == 10) {
            window.location.href = '/list-products';
        }
        if (e.key == 9) {
            window.location.href = '/home';
        }
        if (e.key == 1) {
            this.props.history.push('/add_product');
        }
        if (e.key == 2) {
            this.props.history.push('/create_room');
        }
        if (e.key == 4) {
            window.location.href = '/products';
        }
        if (e.key == 6) {
            window.location.href = '/my_scenes';
        }
        if (e.key == 5) {
            window.location.href = '/rooms';
        }
        if (e.key == 7) {
            this.props.history.push('/tags?selected=7');
        }
        if (e.key == 11) {
            window.location.href = '/store?selected=11';
        }
        if (e.key == 8) {
            this.props.history.push('/modeling?selected=8');
        }
        if (e.key == 12) {
            this.props.history.push('/about?selected=12');
        }
        if (e.key == 13) {
            window.location.href = '/list-collections';
        }
        if (e.key == 14) {
            this.props.history.push('/library');
        }
        if (e.key == 15) {
            this.props.history.push('/help');
        }
        if (e.key == 16) {
            window.location.href = '/material-library';
        }
        if (e.key == 17) {
            window.location.href = '/account-usage';
        }
        if (e.key == 18) {
          window.location.href = "/customer_model_report";
        }
        if (e.key == 19) {
            window.location.href = "/bulk-configurations";
        }
        if (e.key == 20) {
            window.location.href = "/collaborations";
        }
        if (e.key == 21) {
            window.location.href = "/library-projects";
        }

    }

    handleMouseOverSlider = () => {
        this.setState({
            collapsed: false,
        });
    }

    handleMouseLeaveSlider = () => {
        this.setState({
            collapsed: true,
        });
    }

    

    render() {

        const menu = (
            <Menu triggerSubMenuAction="hover">
                <SubMenu title={<span>Product</span>} popupOffset={[0, 0]} >
                    <SubMenu title={<span>Create New Model</span>} popupOffset={[0, 0]} >
                        <Menu.Item key="8" onClick={this.handleCreateNewFormChange}>
                            <span>Existing Physical Product</span>
                        </Menu.Item>
                        <Menu.Item key="9" onClick={this.handleCreateNewFormChange}>
                            <span>Design A New Product</span>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="1" onClick={this.handleCreateNewFormChange}>
                        <span>Upload Existing Model</span>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="2" onClick={this.handleCreateNewFormChange}>
                    <span>Space</span>
                </Menu.Item>
                {/* Create SubMenu with create_new_scene_options as children */}
                <SubMenu title={<span>Scene</span>} popupOffset={[0, 10]}>
                <Menu.Item key="3" onClick={this.handleCreateNewFormChange}>
                    <span>From Empty Space</span>
                </Menu.Item>
                <Menu.Item key="4" onClick={this.handleCreateNewFormChange}>
                    <span>From Template</span>
                </Menu.Item>
                </SubMenu>
                <SubMenu title={<span>Material</span>} popupOffset={[0, 10]}>
                    <Menu.Item key="5" onClick={this.handleCreateNewFormChange}>
                        <span>Create New Material </span>
                    </Menu.Item>
                    <Menu.Item key="6" onClick={this.handleCreateNewFormChange}>
                        <span>Upload Scanned Material</span>
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="10" onClick={this.handleCreateNewFormChange}>
                    <span>Project</span>
                </Menu.Item>
                
                {/* Use conditional rendering to include Menu.Item based on condition */}
                {FileConstants.BULK_REQUEST_ELIGIBLE_COMPANIES.includes(localStorage.getItem('company_id')) && 
                    <Menu.Item key="7" onClick={this.handleCreateNewFormChange}>
                        <span>Bulk Request</span>
                    </Menu.Item>
                }
            </Menu>
        );
        return (
            <div>
                <Header className="header">
                    <CustomHeader toggleMethod={this.toggle}></CustomHeader>
                </Header>
                <Layout style={{ minHeight: "calc(100vh - 60px)" }}>
                    <Sider
                        trigger={null}
                        collapsible
                        collapsed={this.state.collapsed}
                        style={{ background: '#fff', zIndex: "3", margin: 0, minHeight: "calc(100vh - 55px)", position: "absolute" }}
                        onMouseEnter={this.handleMouseOverSlider}
                        onMouseLeave={this.handleMouseLeaveSlider}
                    >
                        <span>

                            <Row>
                                <Dropdown overlay={menu} trigger={['hover']}>
                                {this.state.collapsed ? 
                                <Button type="danger" shape="circle" className="create-new-color" style={{ marginTop: "1.75vw",marginBottom:"20px",marginLeft:"24px",marginRight:"24px"}} icon={<PlusOutlined />}></Button>
                                : 
                                <Button type="danger" className="justify-in-center modal-okay-orange square font-14" shape="circle" style={{ marginTop: "1.75vw",marginBottom:"20px",marginLeft:"24px",marginRight:"24px"}} size={'default'}>
                                    <PlusOutlined />&nbsp;&nbsp;Create New
                                </Button>
                                }

                                </Dropdown>
                            </Row>
                            <Menu theme="light" mode="inline" defaultSelectedKeys={[this.state.selected]}>
                                <Menu.Item key="9" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><HomeIcon /></span>
                                    <span className="manrope f-14">Home</span>
                                </Menu.Item>
                                <Menu.Item key="10" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><ProductIcon /></span>
                                    <span className="manrope f-14">Products</span>
                                </Menu.Item>
                                <Menu.Item key="5" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><RoomIcon /></span>
                                    <span>Spaces</span>
                                </Menu.Item>
                                {/* Commented to remove create collection option from create new dropdown */}
                                {
                                    localStorage.getItem("is_msprovider") === 'true' ? 
                                    <Menu.Item key="13" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><CollectionIcon /></span>
                                    <span className="manrope f-14">Collections</span>
                                    </Menu.Item> : ''}
                                <Menu.Item key="6" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><SceneIcon /></span>
                                    <span className="manrope f-14">Scenes</span>
                                </Menu.Item>
                                <Menu.Item key="16" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><MaterialIcon /></span>
                                    <span className="manrope f-14">Materials</span>
                                </Menu.Item>
                                <Menu.Item key="20" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><CanvasIcon /></span>
                                    <span className="manrope f-14">Collaborations</span>
                                </Menu.Item>
                                {FileConstants.BULK_REQUEST_ELIGIBLE_COMPANIES.includes(localStorage.getItem('company_id')) ?
                                <Menu.Item key="19" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><BulkIcon /></span>
                                    <span className="manrope f-14">Bulk Requests</span>
                                </Menu.Item> : ''}
                                
                                <Menu.Item key="17" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><UsageIcon /></span>
                                    <span className="manrope f-14">Usage History</span>
                                </Menu.Item>
                                {localStorage.getItem("is_msprovider") == 'true' ?
                                 <Menu.Item key="18" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><CustomerReportsIcon /></span>
                                    <span className="manrope f-14">Customer Reports</span>
                                </Menu.Item> : ""}
                                 <Menu.Item key="21" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <AppstoreOutlined style={{fontSize: "26px" , color: '#6B6B6B', stroke: "white", strokeWidth: "24.1px"}} />
                                    <span className="manrope f-14">Projects</span>
                                </Menu.Item>
                                {/* <Menu.Item key="15" onClick={this.handleMenuItemChange} style={{marginBottom:"20px"}}>
                                    <span className='anticon'><HelpIcon /></span>
                                    <span className="manrope f-14">Help</span>
                                </Menu.Item> */}
                            </Menu>
                        </span>
                    </Sider>
                    <Layout>
                        <Content className={`layout-container bg-color color-6b ${this.props.product_page || this.props.space_page ? `customer-main-layout-pd-0` : `customer-main-layout-pd`}`} id="layout-section">
                            {this.props.children}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

export default withRouter(CustomerMainLayout);
