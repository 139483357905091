import './EntityUpdate.scss'
import React, { useEffect, useState } from 'react';
import AdminMainLayout from '../AdminMainLayout';
import ProductActionForm from './ProductActionForm';
import { Tabs, Input, Card, Col } from "antd";
const { TabPane } = Tabs;
const EntityUpdate = () => {
    const [tabActiveKey, setTabActiveKey] = useState('product');
    const tabs = [
        {
          key: 'product',
          title: 'Product',
          Component: (() => {
            return (
            <ProductActionForm>
            </ProductActionForm>
            )
          })
        },
        {
          key: 'space',
          title: 'Space',
          Component: (() => {
            return (
            <div className='input-id'>
                    <h3>
                    </h3>
                </div>
            )
          })
        }
      ];
    const handleTabChange = (key) => {
        setTabActiveKey(key);
    };
    return (
        <AdminMainLayout selected={'22'}>
            <Col span={24}>
                <Tabs
                onChange={(key) => handleTabChange(key)}
                tabBarGutter={16}
                size="large"
                activeKey={tabActiveKey}>
                    {
                        tabs?.map((tab) => {
                            return (
                                <TabPane tab={tab.title} key={tab.key} style={{ padding: "0px 10px" }}> 
                                {
                                    tab.Component()
                                }
                                </TabPane>
                            )
                        })
                    }  
                </Tabs>
            </Col>
        </AdminMainLayout>
    )
};
export default EntityUpdate;