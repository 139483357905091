import React, {useState, useEffect} from 'react'
import {Dropdown, Card, Menu, Input, Button } from 'antd';
import { ReactComponent as MoreIcon } from '../../../icons/more.svg'
import { DownOutlined, EditOutlined } from '@ant-design/icons';

export default function SiloShots(props) {
    
    const [editCameraName, setEditCameraName] = useState(false);
    const [cameraName, setCameraName] = useState('');
    const [cameraDisplayEdit, setCameraDisplayEdit] = useState([])
    
    useEffect(() => {
        let names = []
        for (let index in props.camera)
            names.push(false)
            setCameraDisplayEdit(names)
    },[props.camera])
    
    const getMenuOptions = (name, index) => {
        return (
            <Menu>
                <Menu.Item>
                    <a className="manrope f-14 black" onClick={() => props.deleteSnapshot(name,index)}>Delete</a>
                </Menu.Item>
            </Menu>
        )
    }
    
    const getImageFormatOptions = (name,index) => {
        return (
            <Menu>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onImageFormatChangeCamera('jpg', name, index)}}>JPG</a>
                </Menu.Item>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onImageFormatChangeCamera('png', name, index)}}>PNG</a>
                </Menu.Item>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onImageFormatChangeCamera('tiff', name, index)}}>TIFF</a>
                </Menu.Item>
            </Menu>
        )
    }
    const getImageDpiOptions = (name,index) => {
        return (
            <Menu>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onChangeDPICamera('300', name, index)}}>300</a>
                </Menu.Item>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onChangeDPICamera('400', name, index)}}>400</a>
                </Menu.Item>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onChangeDPICamera('600', name, index)}}>600</a>
                </Menu.Item>
            </Menu>
        )
    }
    
    const getImageResolutionOptions = (res,name,index) => {
        return (
            <Menu>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onChangeResolutionCamera(props.getImageResolutionArray(res)[0], name, index)}}>1K({props.getImageResolutionArray(res)[0]})</a>
                </Menu.Item>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onChangeResolutionCamera(props.getImageResolutionArray(res)[1], name, index)}}>2K({props.getImageResolutionArray(res)[1]})</a>
                </Menu.Item>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onChangeResolutionCamera(props.getImageResolutionArray(res)[2], name, index)}}>3K({props.getImageResolutionArray(res)[2]})</a>
                </Menu.Item>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black" onClick={() => {props.onChangeResolutionCamera(props.getImageResolutionArray(res)[3], name, index)}}>4K({props.getImageResolutionArray(res)[3]})</a>
                </Menu.Item>
                <Menu.Item onClick={() => {props.setEditCamera(true);props.setTakeSiloShotModal(true);}} className='setting-silo-dropdown'>
                    <a className="manrope f-12 black">Custom</a>
                </Menu.Item>
            </Menu>
        )
    }
    
    const getImageResolution = (res,name, index) => {
        return (
            <Menu>
                <Menu.Item className='setting-silo-dropdown'>
                    <a className="manrope f-12 black">{res}</a>
                </Menu.Item>
                <Menu.Item onClick={() => {props.setEditCamera(true);props.setTakeSiloShotModal(true);}} className='setting-silo-dropdown'>
                    <a className="manrope f-12 black">Custom</a>
                </Menu.Item>
            </Menu>
        )
    }
    
  return (
    <>
    <div className='default-silos-snapshots custom-scroll'>
        { props.camera.map ( (view, index) => (
            props.selectedCameras.includes(view.name)?
            <Card style={{border: (props.perspective == index) ? "2px solid #000000" : "2px solid #CCCCCC", borderRadius:"4px"}} key={index} className={ props.setResolutionBar ? 'card-silo-default': 'card-silo-edit'}>
                <div style={{background:''+props.hexColor+'', textAlign:'center'}} onClick={()=> props.changePerspective(view.name, index)}>
                    <img src={view.thumbnail}/>
                </div>
                <div>
                    <Dropdown overlay={getMenuOptions(view.name, index)} className='delete-silo'>
                        <MoreIcon style={{background:"#FAFAFA", padding:"2px", borderRadius:"4px"}}/>
                    </Dropdown>
                </div>
                <div style={{display:"flex", alignItems:"center", position:"relative", padding:"0% 8%",background:''+props.hexColor+''}}>
                    <Input id ='input-camera-name' className='manrope f-14 black' disabled={!editCameraName} style={{padding:0, cursor:'text', color:'black', fontSize:"14px",width:(props.camera[index]['camera_display_name'].length+1)*8+'px'}} bordered={false} value={cameraDisplayEdit[index]  == true ?cameraName : props.camera[index]['camera_display_name']} 
                    onChange={(event) => {
                        setCameraName(event.target.value);
                        document.getElementById('input-camera-name').style.width = (document.getElementById('input-camera-name').value.length+1)*8 +'px'
                    }}
                    />
                    {
                        editCameraName &&  cameraDisplayEdit[index] == true ?
                        <Button onClick={()  => {
                            let cameras = props.camera
                            cameras[index]['camera_display_name'] = cameraName;
                            setEditCameraName(false);
                            props.updateCameraDisplayName(cameras[index]['name'], cameraName)
                            props.setCamera(cameras)
                        }}>
                            Save
                        </Button>
                        :
                        <EditOutlined onClick={() => {
                            setEditCameraName(true); 
                            let names = cameraDisplayEdit;
                            for (let n in names ) {
                                names[n] = false;
                            }
                            names[index] = true;
                            setCameraDisplayEdit(names);
                            setCameraName(props.camera[index]['camera_display_name']);
                        }}
                        style={{color:'#276DD7'}} />
                    }   
                </div>
                <div className='manrope f-12' style={{padding:"2% 8%", marginBottom:"2%"}}>
                    <div className='silo-shots-setting-div'>
                        <span className='manrope f-14 black w-500'>
                            Resolution
                        </span>
                        <div className='silo-shot-setting'>
                            <Dropdown onClick={() => {props.changePerspective(view.name, index)}} trigger={'click'} placement={'bottomCenter'} overlay={props.defaultResolutions.includes(view.resolution) ? getImageResolutionOptions(view.resolution, view.name, index): getImageResolution(view.resolution, view.name, index) }>
                                <span style={{cursor:"pointer"}} className='manrope f-12'>
                                    {view.resolution}
                                    <DownOutlined style={{position:"absolute", right:"5%"}}/>
                                </span>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='silo-shots-setting-div'>
                        <span className='manrope f-14 black w-500'>
                            DPI
                        </span>
                        <div className='silo-shot-setting'>
                            <Dropdown onClick={() => {props.changePerspective(view.name, index)}} trigger={'click'} placement={'bottomCenter'} overlay={getImageDpiOptions(view.name, index)}>
                                <span style={{cursor:"pointer"}} className='manrope f-12'>
                                    {view.dpi}
                                    <DownOutlined style={{position:"absolute", right:"5%"}}/>
                                </span>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='silo-shots-setting-div'>
                      <span className='manrope f-14  black w-500'>
                            Image Format
                        </span>
                        <div className='silo-shot-setting'>
                            <Dropdown onClick={() => {props.changePerspective(view.name, index)}} trigger={'click'} placement={'bottomCenter'} overlay={getImageFormatOptions(view.name, index)}>
                                <span style={{cursor:"pointer"}} className='manrope f-12'>
                                    {view.image_format.toUpperCase()}
                                    <DownOutlined style={{position:"absolute", right:"5%"}}/>
                                </span>
                            </Dropdown>
                        </div>                        
                    </div>
                    <div className='silo-shots-setting-div'>
                      <span className='manrope f-14  black w-500'>
                            Lighting
                        </span>
                        <div className='silo-shot-setting-span'>
                            <span style={{cursor:"pointer"}} className='manrope f-12'>
                                {view.rig_name == 'default'? view.lighting.replaceAll("_", " ").toUpperCase() : view.rig_name}
                            </span>
                        </div>                        
                    </div>
                </div>
            </Card>
            :
            ''
        ))
        }
    </div>
    </>
  )
}
