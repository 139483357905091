export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const GET_SCANNED_PRODUCTS = 'GET_SCANNED_PRODUCTS';
export const RENDER_PRODUCT = 'RENDER_PRODUCT';
export const LIST_PRODUCT = 'LIST_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const REJECT_PRODUCT = 'REJECT_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_REJECT_SUCCESS = 'PRODUCT_REJECT_SUCCESS';
export const PRODUCT_FAILURE = 'PRODUCT_FAILURE';
export const LIST_STORE_PRODUCT = 'LIST_STORE_PRODUCT';
export const LOAD_PAGE = 'LOAD_PAGE';

export const fetchProduct = (product) => ({
  type: FETCH_PRODUCT,
  payload: product,
});

export const loadPage = () => ({
  type: LOAD_PAGE
});

export const getProductRender = (product) => ({
  type: RENDER_PRODUCT,
  payload: product,
});

export const getScannedProducts = (product) => ({
  type: GET_SCANNED_PRODUCTS,
  payload: product,
});


export const listProducts = (product) => ({
  type: LIST_PRODUCT,
  payload: product,
});

export const listStoreProducts = (product) => ({
  type: LIST_STORE_PRODUCT,
  payload: product,
});

export const createProduct = (product) => ({
  type: CREATE_PRODUCT,
  payload: product,
});


export const rejectProduct = (product) => ({
  type: REJECT_PRODUCT,
  payload: product,
});

export const updateProduct = (product) => ({
  type: UPDATE_PRODUCT,
  payload: product,
});

export const productSuccessAction = (product) => ({
  type: PRODUCT_SUCCESS,
  payload: product,
});

export const productRejectSuccessAction = (product) => ({
  type: PRODUCT_REJECT_SUCCESS,
  payload: product,
});

export const productFailureAction = (error) => ({
  type: PRODUCT_FAILURE,
  payload: error,
});
