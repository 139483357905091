export const FETCH_STORE_PRODUCT = 'FETCH_STORE_PRODUCT';
export const GET_SCANNED_STORE_PRODUCTS = 'GET_SCANNED_STORE_PRODUCTS';
export const RENDER_STORE_PRODUCT = 'RENDER_STORE_PRODUCT';
export const LIST_STORE_PRODUCT = 'LIST_STORE_PRODUCT';
export const CREATE_STORE_PRODUCT = 'CREATE_STORE_PRODUCT';
export const REJECT_STORE_PRODUCT = 'REJECT_STORE_PRODUCT';
export const UPDATE_STORE_PRODUCT = 'UPDATE_STORE_PRODUCT';
export const STORE_PRODUCT_SUCCESS = 'STORE_PRODUCT_SUCCESS';
export const STORE_PRODUCT_REJECT_SUCCESS = 'STORE_PRODUCT_REJECT_SUCCESS';
export const STORE_PRODUCT_FAILURE = 'STORE_PRODUCT_FAILURE';
export const LIST_FOR_STORE_PRODUCT = 'LIST_FOR_STORE_PRODUCT';

export const fetchStoreProduct = (store_product) => ({
  type: FETCH_STORE_PRODUCT,
  payload: store_product,
});

export const getStoreProductRender = (store_product) => ({
  type: RENDER_STORE_PRODUCT,
  payload: store_product,
});

export const getScannedStoreProducts = (store_product) => ({
  type: GET_SCANNED_STORE_PRODUCTS,
  payload: store_product,
});


export const listForStoreProducts = (store_product) => ({
  type: LIST_FOR_STORE_PRODUCT,
  payload: store_product,
});

export const listStoreProduct = (store_product) => ({
  type: LIST_STORE_PRODUCT,
  payload: store_product,
});

export const createStoreProduct = (store_product) => ({
  type: CREATE_STORE_PRODUCT,
  payload: store_product,
});


export const rejectStoreProduct = (store_product) => ({
  type: REJECT_STORE_PRODUCT,
  payload: store_product,
});

export const updateStoreProduct = (store_product) => ({
  type: UPDATE_STORE_PRODUCT,
  payload: store_product,
});

export const storeProductSuccessAction = (store_product) => ({
  type: STORE_PRODUCT_SUCCESS,
  payload: store_product,
});

export const storeProductRejectSuccessAction = (store_product) => ({
  type: STORE_PRODUCT_REJECT_SUCCESS,
  payload: store_product,
});

export const storeProductFailureAction = (error) => ({
  type: STORE_PRODUCT_FAILURE,
  payload: error,
});
