import React, { useContext, useState } from 'react';
import _ from "lodash";
import {
    Form, Modal, message, Col, Checkbox, Button, Card, Row,
    Input, Tooltip, Upload
} from 'antd';
import SuccessModal from '../../SuccessModal/SuccessModal';
import { LoadingOutlined, PaperClipOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import * as Utilities from '../../Utilities';
import * as Constants from "../Constants";
import FileConstants from '../../../../FileConstants'

const CUSTOMER_USERNAME = localStorage.getItem('username')
const ComplaintModal = (props) => {
    const [buttonLoader, setButtonLoader] = useState(false);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [screenshotImageList, setScreenshotImageList] = useState([]);
    const [checkedImage, setCheckedImage] = useState([]);
    const [singleImage, setSingleImage] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [checkedImageState, setCheckedImageState] = useState(false);
    const [selectAllImagesFlag, setSelectAllImagesFlag] = useState(false);
    const [imageInput, setImageInput] = useState('')
    const cancelReportImageIssue = () => {
        setImageInput('')
        props.setComplaintModal(false);
        setScreenshotImageList([]);
        setCheckedImage([]);
        setCheckedImageState(false);
        setSelectAllImagesFlag(false);
        props.setSelectedRender(null);
        setSingleImage(true);
    }

    const closeSuccessModal = () => {
        setSuccessModalVisible(false);
    }

    const changeImageInput = (e) => {
        setImageInput(e.target.value);
    }


    const handleImageReport = (values) => {
        setButtonLoader(true);

        let payload = {};
        let image_upload_status = Utilities.validateFileUploads(values["upload_image_screenshots"]);
        if (singleImage) {
            let image = [];
            image.push(props.selectedRender.split('.')[0]);
            payload = {
                complaint_against: "scene_lifestyle",
                entity_id: props.sceneId,
                issue: values.image_flag_reason,
                attachment: image,
                reference_images: values.upload_image_screenshots,
                status: "pending",
                username: CUSTOMER_USERNAME,
                by_msprovider: FileConstants.isMSProvider
            }
        }
        else {
            let selected_images = [];
            props.renders.map((x, index) => {
                if (hideFlaggedImages(x)) {
                    if (checkedImage[x.unique_filename]) {
                        selected_images.push(x.unique_filename);
                    }
                }
            });
            payload = {
                complaint_against: "scene_lifestyle",
                entity_id: props.sceneId,
                issue: values.image_flag_reason,
                attachment: selected_images,
                reference_images: values.upload_image_screenshots,
                status: "pending",
                username: CUSTOMER_USERNAME,
                by_msprovider: FileConstants.isMSProvider
            }
        }


        if (image_upload_status == "uploading") {
            setButtonLoader(false);
            message.info('Uploads are in progress, please wait till file uploads are complete.');
        }
        else if (image_upload_status == "error") {
            setButtonLoader(false);
            message.error('Error occured in uploading file(s), please re-upload your file(s).');

        }
        else if (image_upload_status == "done" || image_upload_status == "not_started") {
            axios.post(ENVIRONMENT.FLAG_IF_UNHAPPY, payload)
                .then((res) => {
                    setImageInput('');
                    props.setComplaintModal(false);
                    setButtonLoader(false);
                    setScreenshotImageList([]);
                    setCheckedImage([]);
                    setSingleImage(false);
                    setSuccessModalVisible(true);
                    setCheckedImageState(false);
                    setSelectAllImagesFlag(false);
                    props.setSelectedRender(null);
                })
                .catch((error) => {
                    message.error('Error in sending your request to the admin.');
                    setButtonLoader(false);
                });
        }
    }

    const handleImageScreenshots = info => {
        let fileList = [...info.fileList];
        setScreenshotImageList(fileList);

        if (info.file.status === "done") {
            setErrorMessage('');
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.message.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.message.concat('. Your session has expired. Please reload the page.'));
            } else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.message.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const selectAllImages = () => {
        let imagesTickBoxed = checkedImage;
        if (selectAllImagesFlag == false) {
            props.renders.map((x) => {
                if (FileConstants.isMSProvider) {
                    if (!x.template_render && (x.complaint_status == 'resolved') && (x.status == 'rendered' && x.filename != '360Camera.jpg')) {
                        imagesTickBoxed[x.unique_filename] = true;
                    }
                }
                else {
                    if (((x.flagged_by_user === undefined || (!props.checkIfFlagIsDisabled(x))) && x.status == 'rendered' && x.filename != '360Camera.jpg') && !props.checkIfComplaintInProgress(x)) {
                        imagesTickBoxed[x.unique_filename] = true;
                    }
                }
            });

            setCheckedImage(imagesTickBoxed);
            setCheckedImageState(true);
            setSelectAllImagesFlag(true);
        }
        else {
            props.renders.map((name, index) => {
                if (((name.flagged_by_user === undefined || (FileConstants.isMSProvider || !props.checkIfFlagIsDisabled(name))) && name.status == 'rendered' && name.filename != '360Camera.jpg') && !props.checkIfComplaintInProgress(name)) {
                    imagesTickBoxed[name.unique_filename] = false;
                }
            });
            setCheckedImage(imagesTickBoxed);
            setCheckedImageState(false);
            setSelectAllImagesFlag(false);
        }
    }

    const onChangeFlagImageCheck = (name) => {

        let render_name = name.unique_filename;
        let imagesTickBoxed = checkedImage;

        if (checkedImage[render_name]) {
            imagesTickBoxed[render_name] = false;
            setCheckedImage(imagesTickBoxed);
        }
        else {
            imagesTickBoxed[render_name] = true;
            setCheckedImage(imagesTickBoxed);
        }

        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;
        props.renders.map((x, index) => {
            if (x.status == 'rendered' && x.filename != '360Camera.jpg') {
                length = length + 1;
                if (imagesTickBoxed[x.unique_filename]) {
                    countTrue = countTrue + 1;
                    flag = true
                    setCheckedImageState(true);
                }
                else if (imagesTickBoxed[x.unique_filename] == false) {
                    countFalse = countFalse + 1;
                }
            }
        });

        if (countTrue == length) {
            setSelectAllImagesFlag(true);
        } else {
            setSelectAllImagesFlag(false);
        }
        if (countFalse == length) {
            setSelectAllImagesFlag(false);
        }

        if (!flag) {
            setCheckedImageState(false);
        }
    }


    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const hideFlaggedImages = (x) => {
        if (FileConstants.isMSProvider) {
            return (!x.template_render && (x.complaint_status == 'resolved') && (x.status == 'rendered' && x.filename != '360Camera.jpg'))
        }
        return (((x.flagged_by_user === undefined || !props.checkIfFlagIsDisabled(x)) && x.status == 'rendered' && x.filename != '360Camera.jpg') && !props.checkIfComplaintInProgress(x))
    }



    return (<>{props.selectedRender != null && <Modal
        destroyOnClose={true}
        visible={props.complaintModal}
        onCancel={cancelReportImageIssue}
        width={800}
        maskClosable={false}
        className="modal-flag">
        <div style={{ padding: "0" }}>
            <h2><span className="manrope f-18 w-700">Report an Issue</span></h2>
            {singleImage ?
                <h3 className="flag-text" style={{ display: "flex", justifyContent: "start" }}>
                    <span>Selected Lifestyle Image</span>
                    {/* <span onClick={changeSingleImageState} style={{ color: "#276DD7", cursor: "pointer" }}>Select More Images</span> */}
                </h3> :
                <h3 className="flag-text" style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Select the lifestyle images you would like to flag</span>
                    <span onClick={selectAllImages} style={{ color: "#276DD7", cursor: "pointer" }}>{selectAllImagesFlag == false ? `Select All` : `Deselect All`}</span>
                </h3>}

            <Form onFinish={handleImageReport}>
                <div>
                    <Row gutter={16}>
                        {singleImage ?
                            <Col span={24} className="flag-col-margin single">
                                <img src={ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.sceneId + '/' + props.selectedRender.replace('tiff', 'jpg')}
                                    className="flag-image-thumbnail-single" />
                            </Col> :
                            props.renders.slice(0).reverse().map(x => (
                                hideFlaggedImages(x) ?
                                    <Col span={8} className="flag-col-margin">
                                        <span className="flag-checkbox-settings" style={{ zIndex: "10" }}>
                                            <Checkbox className="black-checkbox" onChange={() => onChangeFlagImageCheck(x)} checked={checkedImage[x.filename.split('.')[0]]} />
                                        </span>
                                        <Card className='fiu-selection-card' style={{ flexDirection: 'column' }}>
                                            <img src={((ENVIRONMENT.getBaseURL(props.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + props.sceneId + '/' + x.filename.replace('tiff', 'jpg')))}
                                                className="flag-image-thumbnail" style={{ border: 'none' }} />
                                            <Tooltip title={<span className='manrope f-12 white'>
                                                {x.display_name ? x.display_name :
                                                    "Untitled" + x.filename.split('.')[0].split('_')[1]}
                                            </span>}>
                                                <p className='manrope f-12 black-33 fiu-text'>
                                                    {x.display_name ? x.display_name :
                                                        "Untitled" + x.filename.split('.')[0].split('_')[1]}
                                                </p>
                                            </Tooltip>
                                        </Card>
                                    </Col> : ""
                            ))}
                    </Row>
                </div>
                <span className="flag-form" style={{ marginTop: 22, display: (checkedImageState || singleImage) ? "flex" : "none" }}>
                    <Form.Item name="image_flag_reason" label="I Am Flagging Because..." className="share-modal-input" style={{ display: "inline-block", width: 350, margin: "0 !important" }} colon={false}>
                        <Input.TextArea
                            className='manrope f-12 w-300'
                            onChange={changeImageInput} id="image-text-row"
                            autoSize={{
                                maxRows: 4,
                                minRows: 4
                            }} rows={4} placeholder="Describe the issue, example lighting etc." />
                    </Form.Item>
                    <Form.Item
                        className="share-modal-input"
                        style={{ display: "inline-block", width: 350, margin: "0 !important" }}
                        name="upload_image_screenshots"
                        colon={false}
                        label="Additional Information"
                        valuePropName="image_filelist"
                        getValueFromEvent={normFile}>
                        <Upload {...Constants.getUploadProps(props.platform)}
                            progress={{
                                strokeColor: {
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                },
                                strokeWidth: 3,
                                format: percent => `${parseFloat(percent.toFixed(2))}%`,
                            }}
                            onRemove={file => {
                                message.success(`${file.name} removed successfully!`)
                                const index = screenshotImageList.indexOf(file);
                                const newFileList = screenshotImageList.slice();
                                newFileList.splice(index, 1);
                                setScreenshotImageList(newFileList);

                                return true;
                            }}
                            openFileDialogOnClick={!(Utilities.validateFileUploads(screenshotImageList) == 'error')}
                            accept="application/msword,.zip,.rar,image/jpeg,image/tiff,image/gif,image/svg,image/png,application/pdf"
                            fileList={screenshotImageList}
                            onChange={handleImageScreenshots}
                            className="material-upload-box scroll-upload"
                            name="logo" multiple={true} listType="text">
                            <Button className="material-photos-btn flag"><span className="material-photos-btn-txt">Attach files or screenshots here <PaperClipOutlined /></span></Button>
                        </Upload>
                    </Form.Item>
                </span>
                {(Utilities.validateFileUploads(screenshotImageList) == 'error') ?
                    <div className='retry-error-message'>{errorMessage}</div>
                    : ''}
                <div style={{ padding: "10px 16px", marginTop: (singleImage ? 18 : 18) }} className="note-flag">
                    <span className="scene-note flag">
                        Note: <div style={{ color: "#333333" }}>Your issue will be reported to All3D admin. We will reach out to you via email. </div>
                    </span>
                </div>
                <Col className="justify-in-end">
                    <Form.Item style={{ textAlign: "right", marginTop: 32, marginRight: "12px" }}>
                        <div>
                            <Button onClick={cancelReportImageIssue} className='modal-okay-gray square font-14'>
                                Cancel
                            </Button>
                        </div>
                    </Form.Item>
                    <Form.Item style={{ textAlign: "right", marginTop: 32 }}>
                        <Tooltip placement="top" title={<span className="manrope f-12 white">Please add a description of your issue to continue</span>}>
                            <span>
                                <Button className="modal-okay square font-14" htmlType="submit" disabled={((checkedImageState == false && singleImage == false) || imageInput === '' || buttonLoader)}>
                                    Send {buttonLoader ? <LoadingOutlined /> : ""}
                                </Button>
                            </span>
                        </Tooltip>
                    </Form.Item>

                </Col>
            </Form>
        </div>
    </Modal>}
        <SuccessModal
            visible={successModalVisible}
            onCancel={closeSuccessModal}
            heading={"Issue Reported Successfully!"}
            text={"Your issue has been reported to the admin successfully. We will get back to you as soon as possible."}
            footer={[
                <div className="justify-in-center">
                    <Button className="modal-okay square font-14" key="submit" onClick={() => {
                        closeSuccessModal();
                        window.location.reload();
                    }}>
                        Okay
                    </Button>
                </div>
            ]}
        />
    </>)
}

export default ComplaintModal;