import React, { useContext, useState } from 'react';
import _ from "lodash";
import axios from 'axios';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Button, message, Modal } from 'antd';
import ENVIRONMENT from '../../../../environments';
import { LoadingOutlined } from '@ant-design/icons';
import AddItemToStore from '../../AddItemToStore';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");


const SellProductOnStore = () => {
    const { product_id, sellVisible, productData,
        setSellVisible, setRemoveFromStore, removeFromStore,
        variantSelected, currentId, productID, showProductDetails
    } = useContext(CustomerProductContext);

    const [sellLoader, setSellLoader] = useState(false);
    const [removeLoader, setRemoveLoader] = useState(false);
    const [price, setPrice] = useState(0);


    const setStoreRequests = () => {
        let id = productID;
        let payload = {
            product_id: id,
        }
        let product_data = showProductDetails;
        if (product_data.is_store_item == -2) {
            payload.is_store_item = -2;
        } else if (product_data.is_store_item == 1) {
            payload.is_store_item = 0;
        } else {
            payload.is_store_item = -2;
        }

        let price_payload = {
            entity_id: id,
            customer_username: CUSTOMER_USERNAME,
            entity_type: 'product',
            proposed_price: price
        }
        console.log(payload)
        setRemoveLoader(true);
        setSellLoader(true);
        console.log(price_payload)
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
            .then(res => {
                if (product_data.is_store_item == -2) {
                    price_payload.action = 'update';
                } else if (product_data.is_store_item == 1) {
                    price_payload.action = 'remove';
                } else {
                    price_payload.action = 'insert';
                }

                updateStore(price_payload);
            })
            .catch((error) => {
                setRemoveLoader(false);
                console.log(error);
                message.error('Error in removing from store.');
            });
    }

    const updateStore = (payload) => {
        let product_data = showProductDetails;

        axios.post(ENVIRONMENT.SET_STORE_PRICE, payload)
            .then((response) => {
                setSellLoader(false);
                setRemoveLoader(false);
                setRemoveFromStore(false);
                if (product_data.is_store_item == -2) {
                    message.info('Price updated successfully!');
                } else if (product_data.is_store_item == 1) {
                    message.info('Item removed from store successfully!');
                } else {
                    message.info('Price submitted successfully!');
                }
                window.location.href = '/list-products';
            })
            .catch((error) => {
                setSellLoader(false);
                console.log(error);
                message.error('Error.');
            });
    }


    const onChangePrice = (e) => {
        console.log(e.target.value)
        setPrice(e.target.value);
    }


    const cancelSellModal = () => {
        setSellVisible(false);
        setSellLoader(false);
    }

    const removeItemFromStore = () => {
        let id = productID;

        let payload = {
            is_store_item: 0,
            product_id: id,
        }
        console.log(payload)
        setRemoveLoader(true);
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
            .then(res => {
                setStoreRequests();
            })
            .catch((error) => {
                setRemoveLoader(false);
                console.log(error);
                message.error('Error in removing from store.');
            });
    }


    return (<>
        <AddItemToStore
            type={"customer_details"}
            entity_type={"product"}
            visible={sellVisible}
            price={price}
            add_store_item_loader={sellLoader}
            onCancel={cancelSellModal}
            selected_item={showProductDetails}
            onChangePrice={onChangePrice}
            addToStore={setStoreRequests}
        />
        <Modal
            className="modal-share-success"
            width={600}
            centered={true}
            onCancel={() => setRemoveFromStore(false)}
            visible={removeFromStore}
            closable={false}
            footer={[
                <span className="justify-in-center">
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setRemoveFromStore(false)}>
                        Cancel
                    </Button>
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => removeItemFromStore()}>
                        {removeLoader ? <span> Removing <LoadingOutlined spin /></span> : <span> Okay </span>}
                    </Button>
                </span>
            ]}
        >
            <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <h2 className="manrope f-16 black-00 w-600">You're about to remove this product from store. Are you sure you want to remove?</h2>
            </div>
        </Modal>

    </>)
}

export default SellProductOnStore;