import * as THREE from 'three';
class ThreeSixtyViewer {
    
    init(containerID,loadingID, URL,fullscreen,autoRotate) {
        this.camera = null;
        this.scene = null;
        this.renderer = null;
        this.isUserInteracting = false;
        this.onPointerDownMouseX = 0;
        this.onPointerDownMouseY = 0;
        this.displayProperty = "flex"
        this.lon = 0;
        this.onPointerDownLon = 0;
        this.lat = 0;
        this.onPointerDownLat = 0;
        this.phi = 0;
        this.fullscreenFlag = fullscreen;
        this.theta = 0;
        this.autoRotate = autoRotate;
        this.sceneWidth = 0;
        this.sceneHeight = 0;
        this.aspect = 0.0;
        this.containerID = containerID;
        this.loadingID = loadingID;
        this.loadingProgress = true;
        this.URL = URL;
        this.clock = new THREE.Clock();
        this.rotate = autoRotate;
        if (this.fullscreenFlag === "" || this.fullscreenFlag === undefined) {
            this.fullscreenFlag = false;
        }
        else {
            this.fullscreenFlag = fullscreen;
        }
        this.container = document.getElementById( this.containerID );
        this.loadingContainer = document.getElementById( this.loadingID );
        this.updateScreenProps();
        console.log(this.container,this.containerID,this.loadingContainer);
        this.scene = this.buildScene();
        this.renderer = this.buildSceneRenderer( this.sceneWidth, this.sceneHeight );
        this.renderer.domElement.style.margin = "0 auto"
        this.renderer.domElement.style.display = this.displayProperty;
        this.renderer.domElement.style.flexDirection = "row";
        this.renderer.domElement.style.justifyContent = "center";
        this.renderer.domElement.style.alignItems = "center";
        
        this.container.appendChild( this.renderer.domElement );
        this.camera = new THREE.PerspectiveCamera( 75, this.sceneWidth / this.sceneHeight, 1, 1100 );
        this.camera.target = new THREE.Vector3( 0, 0, 0 );
        this.loadingContainer.style.display = "block";
        const geometry = new THREE.SphereBufferGeometry( 500, 60, 40 );
        geometry.scale( - 1, 1, 1 ); // invert the geometry on the x-axis so that all of the faces point inward
        const manager = new THREE.LoadingManager();
        manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
            console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        manager.onLoad = function ( ) {
            console.log( 'Loading complete!');
            this.loadingProgress = false;
        };
        manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
            console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        manager.onError = function ( url ) {
            console.log( 'There was an error loading ' + url );
        };
        const texture = new THREE.TextureLoader(manager).load( this.URL,() => {
            this.loadingContainer.style.display = "none";
        } );
        const material = new THREE.MeshBasicMaterial( { 
            map: texture,
            color: 0xffffff
        } );
        const mesh = new THREE.Mesh( geometry, material );
        this.scene.add( mesh );
        this.setUpEventListeners();
        this.updateCamera();
    }
    constructor(containerID,loadingID, URL,fullscreen, autoRotate=true) {
        this.init(containerID, loadingID, URL,fullscreen, autoRotate);
        this.animate();
    }
    setUpEventListeners() {
        this.container.addEventListener( 'wheel', ( event ) => { this.onWheel( event ); }, false );
        this.container.addEventListener( 'mousedown', ( event ) => { this.onPointerDown(event); }, false );
        this.container.addEventListener( 'mousemove', ( event ) => { this.onPointerMove(event); }, false );
        this.container.addEventListener( 'mouseup', ( event ) => { this.onPointerUp(event); }, false );
        this.container.addEventListener( 'touchstart', ( event ) => { this.onPointerDown(event); }, false );
        this.container.addEventListener( 'touchmove', ( event ) => { this.onPointerMove(event); }, false );
        this.container.addEventListener( 'touchend', ( event ) => { this.onPointerUp(event); }, false );
        window.addEventListener( 'resize', () => { this.onWindowResize(); }, false );
    }
    toggleFull (flag) {
        console.log('toggle flag',flag);
        this.fullscreenFlag = flag;
        this.updateScreenProps();
        this.onWindowResize();
        this.update();
    }
    updateCamera() {
        this.lat = Math.max( - 85, Math.min( 85, this.lat ) );
        this.phi = THREE.MathUtils.degToRad( 90 - this.lat );
        this.theta = THREE.MathUtils.degToRad( this.lon );
        this.camera.target.x = 500 * Math.sin( this.phi ) * Math.cos( this.theta );
        this.camera.target.y = 500 * Math.cos( this.phi );
        this.camera.target.z = 500 * Math.sin( this.phi ) * Math.sin( this.theta );
        this.camera.lookAt( this.camera.target );
    }
    updateScreenProps() {
        
        this.sceneWidth = window.innerWidth;
            
        if (this.fullscreenFlag) {
            this.sceneHeight = window.innerHeight;
        }
        else {
            this.sceneHeight = window.innerHeight - 165;
        }
        
        this.aspect = this.sceneWidth / this.sceneHeight;
        
    }
    buildScene() {
        const scene         = new THREE.Scene();
        scene.background    = new THREE.Color("#ffffff");
        return scene;
    }
    buildSceneRenderer( width, height ) {
        const renderer              = new THREE.WebGLRenderer( { alpha: true } );// antialias: true, preserveDrawingBuffer: true
        renderer.outputEncoding     = THREE.LinearEncoding;
        renderer.gammaFactor        = 2.2;
        renderer.setClearColor( 0xffffff, 0 );
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( width, height );
        return renderer;
    }
    changeDisplay(value) {
        this.displayProperty = value;
        this.renderer.domElement.style.display = this.displayProperty;
    }
    onWindowResize() {
        this.updateScreenProps();
        this.renderer.domElement.style.margin = "0 auto"
        this.renderer.domElement.style.display = this.displayProperty;
        this.renderer.domElement.style.flexDirection = "row";
        this.renderer.domElement.style.justifyContent = "center";
        this.renderer.domElement.style.alignItems = "center";
        this.renderer.setSize( this.sceneWidth, this.sceneHeight );
       
        this.camera.aspect = this.aspect;
        this.camera.updateProjectionMatrix();
    }
    onPointerDown( event ) {
        if ( event.isPrimary === false ) return;
        this.isUserInteracting = true;
        this.autoRotate = false;
        
        var clientX = event.clientX || event.touches[ 0 ].clientX;
        var clientY = event.clientY || event.touches[ 0 ].clientY;
        this.onPointerDownMouseX = clientX;
        this.onPointerDownMouseY = clientY;
        this.onPointerDownLon = this.lon;
        this.onPointerDownLat = this.lat;
    }
    onPointerMove( event ) {
        event.preventDefault();
        if ( event.isPrimary === false ) return;
        if (this.isUserInteracting == true) {
            var clientX = event.clientX || event.touches[ 0 ].clientX;
            var clientY = event.clientY || event.touches[ 0 ].clientY;
            this.lon = ( this.onPointerDownMouseX - clientX ) * 0.1 + this.onPointerDownLon;
            this.lat = ( clientY - this.onPointerDownMouseY ) * 0.1 + this.onPointerDownLat;
    
            this.updateCamera();
        }
    }
    onPointerUp( event ) {
        if ( event.isPrimary === false ) return;
        if ( event.type != 'touchend' ) {
            event.preventDefault();
        }
        this.isUserInteracting = false;
        if (this.rotate)
            this.autoRotate = true;
        // this.container.removeEventListener( 'pointermove', this.onPointerMove );
        // this.container.removeEventListener( 'pointerup', this.onPointerUp );
    }
    toggleAutoRotate() {
        this.autoRotate = !this.autoRotate ;
        this.rotate = !this.rotate;
    }
    onWheel( event ) {
        event.preventDefault();
        const fov = this.camera.fov + ( -event.deltaY * 0.05 );
        this.camera.fov = THREE.MathUtils.clamp( fov, 10, 75 );
        this.camera.updateProjectionMatrix();
    }
    animate() {
        requestAnimationFrame( ()=>{ this.animate(); } );
        this.update();
    }
    update() {
        if (this.autoRotate) {
            let delta = this.clock.getDelta();
            if ( this.isUserInteracting === false ) {
                this.lon += (3.0 * delta);
            }
            this.lat = Math.max( - 85, Math.min( 85, this.lat ) );
            this.phi = THREE.MathUtils.degToRad( 90 - this.lat );
            this.theta = THREE.MathUtils.degToRad( this.lon );
    
        }
        const x =  Math.sin( this.phi ) * Math.cos( this.theta ) ;
        const y =  Math.cos( this.phi ) ;
        const z =  Math.sin( this.phi ) * Math.sin( this.theta ) ;
        this.camera.lookAt( x, y, z );
        this.renderer.render( this.scene, this.camera );
    }
}
export default ThreeSixtyViewer;