import React, { useEffect, useState } from "react";
import LibraryGrid from "../LibraryGrid/LibraryGrid";
import EntityItem from "../EntityCard/EntityCard";
import { LoadingOutlined } from "@ant-design/icons";

const RoomsList = (props) => {
  const [columnCount, setColumnCount] = useState(4);
  const [rowCount, setRowCount] = useState(1);
  const maxColumnWidth = 280;
  const rowSize = 460;
  const { data, selected, setSelected, setSelectedElementDetials, room_id, loader, requestStatusRoom } = props;

  useEffect(() => {
    setRowCount(Math.ceil(data.length / columnCount));
  }, [data]);

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const itemIndex = rowIndex * columnCount + columnIndex;
    const ele = data[itemIndex] && data[itemIndex];
    console.log(ele, "ele");
    if (!ele) {
      return null;
    }
    return (
      <div key={key} style={style}>
        <EntityItem
          key={key}
          ele={ele}
          selectable={true}
          selected={selected}
          setSelected={setSelected}
          singleSelect={true}
          entityType="room"
          setSelectedElementDetials={setSelectedElementDetials}
          checked={ele.room_id == room_id}
          requestStatus={requestStatusRoom}
        />
      </div>
    );
  };

  if (data.length == 0) {
    return (
      <div
        className="empty-section modal-tab justify-in-center"
      >
        <div className="justify-in-center" style={{flexDirection:"column"}}>
          <img
              id="empty-section-image"
              style={{ width: "50%" }}
              src="../../../../img/Collections.svg"
          />
          <p className="empty-section-text manrope f-14 black-55">
              Nothing to show
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <LibraryGrid
          setColumnCount={setColumnCount}
          setRowCount={setRowCount}
          maxColumnWidth={maxColumnWidth}
          data={data}
          cellRenderer={cellRenderer}
          columnCount={columnCount}
          getColumnWidth={() => {}}
          rowCount={rowCount}
          getEstimatedRowSize={rowSize}
          handleColumnWidthChange={() => {}}
          height={280}
          isDisplayOnly={true}
        />
        {
          loader && <div className="swap-list-loader justify-in-center">
            <span className="manrope f-14 w-500">
              Please wait while we load all products... <LoadingOutlined />
              </span>
          </div>
        }
      </>
    );
  }
};

export default RoomsList;
