import React, { useState, useEffect,useCallback }  from 'react';
import { withRouter, Link } from 'react-router-dom'
import {connect} from "react-redux";
import AWS from 'aws-sdk';
import _ from "lodash" // Import the entire lodash library
import axios from 'axios';
import '@ant-design/compatible/assets/index.css';
import { InfoCircleOutlined, LoadingOutlined, PaperClipOutlined, PlusOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Radio, Select, Upload, message, TreeSelect, Collapse, Checkbox, Modal, Card, Tooltip, Form, Popover, List } from 'antd';
import 'rc-color-picker/assets/index.css';
import * as Constants from "../../CustomerComponents/Constants";
import { createProduct, updateProduct, fetchProduct } from "../../../../redux/actions";
import ColorSelect from '../../FormUtilities/ColorSelect';
import StyleCategory from '../../FormUtilities/StyleCategorySelect';
import $ from 'jquery';
import FileConstants from "../../../../FileConstants";
import ENVIRONMENT from '../../../../environments';
import * as Styles from "../../../../Styles";
import HiddenCategory from '../../ProductForms/HiddenCategory/HiddenCategory';
import * as Utilities from '../../Utilities';
import PaymentConfirmationModal from '../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import WarningModal from '../../WarningModal/WarningModal';
import ProductOutputSelection from '../../ProductForms/ProductOutputSelection';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import ColorSelection from '../../ProductForms/ColorSelection';
import CustomModelViewer from '../../../../CustomModelViewer';

const { Option } = Select;
const { Panel } = Collapse;

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;

const categoryStyle = Styles.categoryStyle;
const borderStyle = Styles.borderStyle;
const borderStyleEmpty = Styles.borderStyleEmpty
const progress_bar = Styles.progress_bar;

let canvasContainer = null;
let upload_obj = {};


const ModelVariationRequest = (props) => {
    const [materialsData, setMaterialsData] = useState([])
    const [categoriesData, setCategoriesData] = useState([])
    const [publicLicenseChecked, setPublicLicenseChecked] = useState(false);
    const [privateLicenseChecked, setPrivateLicenseChecked] = useState(false);
    const [forSale, setForSale] = useState(false);
    const [forCreativeCommon, setForCreativeCommon] = useState(false);
    const [creativeCommon, setCreativeCommon] = useState('');
    const [formValidationFailed, setFormValidationFailed] = useState(false);
    const [categoryValue, setCategoryValue] = useState('');
    const [categorySearchValue, setCategorySearchValue] = useState('');
    const [materialValue, setMaterialValue] = useState([]);
    const [materialSearchValue, setMaterialSearchValue] = useState('');
    const [modelFileList, setModelFileList] = useState([]);
    const [rawFileList, setRawFileList] = useState([]);
    const [userModelType, setUserModelType] = useState('high_res');
    const [modelTypes, setModelTypes] = useState(['high_res']);
    const [modelingRequired, setModelingRequired] = useState(false);
    const [rawAttachments, setRawAttachments] = useState(false);
    const [productPhotosList, setProductPhotosList] = useState([]);
    const [materialPhotosList, setMaterialPhotosList] = useState([]);
    const [submittingSaveRequest, setSubmittingSaveRequest] = useState(false);
    const [submittingCreateRequest, setSubmittingCreateRequest] = useState(false);
    const [, updateState] = React.useState();
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [makeConfig, setMakeConfig] = useState(false);
    const [materialModal, setMaterialModal] = useState(false);
    const [finalSelectedMaterials, setFinalSelectedMaterials] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState([]);
    const [lib_selected, setLibSelected] = useState([]);
    const [compValidation, setCompValidation] = useState([]);
    const [unselectFlag, setUnselectFlag] = useState(false);
    const [selectedComp, setSelectedComp] = useState('');
    const [selectedImage, setSelectedImage] = useState([]);
    const [componentFileList, setComponentFileList] = useState([]);
    const [showConfigNote, setShowConfigNote] = useState(false);
    const [modelType, setModelType] = useState(1);
    const [referenceList, setReferenceList] = useState([]);
    const [componentArray, setComponentArray] = useState([]);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [configurableModal, setConfigurableModal] = useState(false);
    const [isConfigurable, setIsConfigurable] = useState(false);
    const [requestID, setRequestID] = useState(0);
    const [manualFileList, setManualFileList] = useState([]);
    const [initialFormValue, setInitialValues] = useState({});
    const [materialInfo,setMaterialInfo] = useState([]);
    const [productID, setProductID] = useState(-1);
    const [materialsReturned, setMaterialsReturned] = useState([]);
    const [hiddenFile, setHiddenFile] = useState([]);
    const [hiddenCategoryFilelist, setHiddenCategoryFilelist] = useState([]);
    const [hiddenCategoryIndex, setHiddenCategoryIndex] = useState(0);
    const [hiddenCategoryType, setHiddenCategoryType] = useState('');
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
    const [productCreatePayload,setProductCreatePayload] = useState(null);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [requestType,setRequestType] = useState('variation_product_model');
    const [productName, setProductName] = useState('');
    const [requestStatus, setRequestStatus] = useState(null);
    const [requestPrice, setRequestPrice] = useState(-1);
    const [cardStatus, setCardStatus] = useState(null);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [price, setPrice] = useState(-1);
    const [priceError, setPriceError] = useState('');
    const [variantOf, setVariantOf] = useState('');
    const [segmented, setSegmented] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [colorSelectionType, setColorSelectionType] = useState(2);
    const [colorFileList, setColorFileList] = useState([]);
    const { match: { params } } = props;
    const [form] = Form.useForm();
    const [searchValue, setSearchValue] = useState('');
    const [hexColor, setHexColor] = useState('#00000');
    const [pantoneColor, setPantoneColor] = useState('');
    const [materialSelectionType, setMaterialSelectionType] = useState(0);
    const [canvasObj, setCanvasObj] = useState(null);
    const [blob_data, setBlobData] = useState(false);
    const [productCreated, setProductCreated] = useState(false);
    const [elementArray, setElementArray] = useState([]);
    const [uploadMaterialUrls, setUploadMaterialUrls] = useState([]);
    const [canvasImageUploaded, setCanvasImageUploaded] = useState(true);
    const [textField, setTextField] = useState('');
    const [errorMessages, setErrorMessages] = useState([]);
    const [uploadError, setUploadError] = useState('');
    const [initialMaterials, setInitialMaterials] = useState(false);
    const [initialElements, setInitialElements] = useState(false);
    const [modelRequiredMaterialOutput, setModelRequiredMaterialOutput] = useState('vray');
    const [automatedMaterialVariation, setAutomatedMaterialVariation] = useState(false)
    const [existingAutomationRequest, setExistingAutomationRequest] = useState(false)
    const [modelComponentMaterial, setModelComponentMaterial] = useState({})
    const [selectedModelComponent, setSelectedModelComponent] = useState(null)
    const [modelComponents, setModelComponents] = useState([]);
    const [modelViewer, setModelViewer] = useState(null);
    const [modelLoaded, setModelLoaded] = useState(false)
    const [projects, setProjects] = useState([]);


    const setCanvas = () => {
        if (canvasObj == null) {
            setCanvasImageUploaded(true)
            setCanvasObject();
        }
    }

    const clearCanvas = () => {
        if (canvasObj != null) {
            canvasObj.clear();
        }
    }

    const adjustCanvasSize = () => {
        if (canvasObj && (props.variationType == 'material' || props.variationType == 'state')) {
            canvasContainer = document.getElementById("canvas-settings");
            if (canvasContainer != null && (props.variationType == 'material' || props.variationType == 'state')) {
                let url = ENVIRONMENT.getBaseURL(props.product_details.platform) + ENVIRONMENT.QA_IMG_URI + props.product_id + "_HeadOn.jpg";
                setCanvasBackground(url, canvasContainer);

            }
        }
    }
    const setMaterialPhotoSettings = (fileList) => {
        if (Utilities.validateFileUploads(fileList) == 'done') {
            if (canvasObj && fileList.length > 0) {
                let old_materials = [];
                fileList.map((file)=> {
                    let new_material = {
                        name: file.name,
                        url: ENVIRONMENT.getBaseURL(ENVIRONMENT.getUploadPlatform()) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)
                    }
                    old_materials.push(new_material)
                })
                setUploadMaterialUrls(old_materials);
            }
        }
    }

    const deleteGroup = () => {
        if (canvasObj) {
            var activeGroup = canvasObj.getActiveGroup();
            console.log(activeGroup)
            if (activeGroup) {
                var activeObjects = activeGroup.getObjects();
                console.log(activeObjects)
                for (let i in activeObjects) {
                    canvasObj.remove(activeObjects[i]);
                }
                canvasObj.discardActiveGroup();
                canvasObj.renderAll();
            } else {
                console.log(canvasObj.getActiveObject())

                if (canvasObj.getActiveObject() != undefined) {
                    canvasObj.remove(canvasObj.getActiveObject());
                }
            }
        }
    }

    const fetchProjectData = async () => {
        let payload = {
            order_by: "id desc",
            required_fields: [
              "id",
              "name"
            ],
            filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
          };
    
        await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {setProjects(res.data);});
        console.log("PROJECTS:",projects);
      };


    const addImageElements = (imgUrl, name) => {

        var img = new Image();

        // onload fires when the image is fully loadded, and has width and height

        img.onload = function(){
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            let text = new window.fabric.Text(name, {
                top: -20,
                left: 0,
                fontSize: 12,
                fontFamily: 'Roboto',
                originX: 'left',
                originY: 'top'
            });

            let newImg = new window.fabric.Image.fromURL(imgUrl, (oImg) => {
                console.log('Image', oImg);
                oImg.scaleToHeight(100);
                oImg.scaleToWidth(100);
                let group = new window.fabric.Group([oImg, text], {
                    top: 10,
                    hasBorders: true,
                    hasControls: true,
                    borderColor: 'black'
                });
                canvasObj.add(group);
            }, { crossOrigin: 'anonymous'});

            forceUpdate();

        };

        // set attributes and src
        img.setAttribute('crossOrigin', 'anonymous'); //
        img.src = imgUrl;

    }

    const deleteTextField = () => {
        var activeGroup = canvasObj.getActiveGroup();
        if (activeGroup) {
            var activeObjects = activeGroup.getObjects();
            for (let i in activeObjects) {
                canvasObj.remove(activeObjects[i]);
            }
            canvasObj.discardActiveGroup();
            canvasObj.renderAll();
        } else
        {
            if (canvasObj.getActiveObject() != undefined)
            {
                canvasObj.getActiveObject().remove();
            }
        }
    }

    const setCanvasObject = () => {
        let canvas_object = new window.fabric.Canvas('tagging-canvas');
        setCanvasObj(canvas_object);
    }

    const update_tagging_photo = (product_id, show_modal = false) => {
        if ((materialPhotosList && materialPhotosList.length > 0)
        || (finalSelectedMaterials && finalSelectedMaterials.length > 0) || (elementArray && elementArray.length > 0)){
            let canvas = document.getElementById("tagging-canvas");
            let dataUrl = canvas.toDataURL("image/png");
            let blobData = Utilities.dataURItoBlob(dataUrl);
            setBlobData(blobData);
            if (product_id != -1 && show_modal) {
                upload_photos(product_id, blobData, show_modal);
            }
        }
    }


    const setText = (text, action) => {
        console.log(text,action)
        if (canvasObj != null) {
            let t = new window.fabric.IText(text, {
                fontFamily: 'Manrope',
                stroke: '#D40003'
            });
            canvasObj.add(t);
            setTextField('');
            let elements = elementArray;
            elements.push(text);
            setElementArray(elements);
            forceUpdate();
        }
        if (!action) {
            setInitialElements(true);
        }
    }

    const insertText = (value) => {
        setTextField(value)
    }


    useEffect(() => {
        adjustCanvasSize();
        forceUpdate();
    },[window.devicePixelRatio]);

    useEffect(() => {
        adjustCanvasSize();
    },[canvasObj]);

    window.addEventListener('resize', adjustCanvasSize, false);

    const uploadLabelledProductImageOnAWS = (product_id, blobData, show_modal) => 
    {
        console.log('Uploading on S3...')

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
        () => {
            AWS.config.update({
                region: 'us-west-2',
                credentials: cognito_credentials
            });
            const S3 = new AWS.S3();
            const objParams = {
                Bucket: ENVIRONMENT.BUCKET_NAME,
                Key: "product_assets/annotated_product/" + product_id + ".jpg",
                ACL: 'public-read',
                Body: blobData,
                ContentType: "image/jpeg",
                CacheControl: 'no-cache'
            };

            S3.upload(objParams, function (err, data) {
                if (err) {
                    message.error('Unable to upload tagged photo, please check your internet connection');
                } else {
                    if (show_modal) {
                        if (submittingSaveRequest) {
                            props.variationSaved();
                        } else {
                            props.variationCreated();
                        }
                    }
                }
                console.log(err ? 'ERROR!' : 'Tagged Photo Uploaded.');
            });
        });
    }

    const uploadLabelledProductImageOnGoogle = (product_id, blobData, show_modal) =>
    {
        console.log('Uploading on Google...')
        let key = "product_assets/annotated_product/" + product_id + ".jpg";
        axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
            'action': 'get_asigned_urls',
            'file_uri': key
        })
          .then(res => {
              let signedUrl = res.data;
              const xhr = new XMLHttpRequest();
              xhr.open('PUT', signedUrl, true); 
        
              xhr.onload = function() {
                  console.log(xhr)
                  if (xhr.status === 200) {
                    console.log(xhr);
                    console.log("SEND FINISHED", xhr.response);
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                      'action': 'make_file_public',
                      'file_uri': key
                    })
                    if (show_modal) {
                        if (submittingSaveRequest) {
                            props.variationSaved();
                        } else {
                            props.variationCreated();
                        }
                  } else {
                        message.error('Unable to upload tagged photo, please check your internet connection');
                    }
                  }
              };
        
              xhr.send(blobData);
          });
    } 

    const upload_photos = (product_id, blobData, show_modal) => {
        if(ENVIRONMENT.getUploadPlatform() == 'google')
        {
            uploadLabelledProductImageOnGoogle(product_id,blobData,show_modal);
        }
        else
        {
            uploadLabelledProductImageOnAWS(product_id, blobData, show_modal);
        }
    }


    const setCanvasBackground = (url, cDim) => {
        window.fabric.Image.fromURL(url, (oImg) => {
            let scaleW = cDim.clientWidth / oImg.width;
            let scaleH = cDim.clientHeight / oImg.width;

            if (oImg.width < oImg.height) {
                if (scaleH > scaleW) {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleW,
                        scaleY: scaleW,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
                else {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleH,
                        scaleY: scaleH,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
            }
            else if (oImg.width > oImg.height) {
                if (scaleH > scaleW) {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleW,
                        scaleY: scaleW,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
                else {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleH,
                        scaleY: scaleH,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
            }
            else if (oImg.width > 949) {
                if (scaleH > scaleW) {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleW,
                        scaleY: scaleW,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
                else {
                    oImg.set({
                        opacity: 1,
                        scaleX: scaleH,
                        scaleY: scaleH,
                        objectFit: "contain",
                        stroke : 'black',
                        strokeWidth : 1,
                    });
                }
            }
            else if(oImg.width < 949 ){
                if (oImg.width == oImg.height) {
                    if (scaleH > scaleW) {
                        oImg.set({
                            opacity: 1,
                            scaleY: scaleW,
                            scaleX: scaleW,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    } else {
                        oImg.set({
                            opacity: 1,
                            scaleY: scaleH,
                            scaleX: scaleH,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }

                } else {
                    if (scaleH > scaleW) {
                        oImg.set({
                            opacity: 1,
                            scaleY: scaleH,
                            scaleX: scaleH,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                    else {
                        oImg.set({
                            opacity: 1,
                            scaleX: scaleW,
                            scaleY:scaleW,
                            objectFit: "contain",
                            stroke : 'black',
                            strokeWidth : 1,
                        });
                    }
                }

            }
            if (canvasObj != null) {
                canvasObj.setBackgroundImage(oImg);
                canvasObj.renderAll();
                setCanvasImageUploaded(false);
            }

        }, { crossOrigin: 'anonymous'});
    }

    const getMaterialPayload = () => {

        let payload = {
            status: "5",
            username: CUSTOMER_USERNAME
        }

        let shared_entities = SHARED_ENTITIES;
        let company_id = COMPANY_ID;
        if (shared_entities != undefined && company_id != undefined) {
            if (shared_entities.includes('material')) {
                payload.company_id = company_id
            }
        }

        return payload;
    }

    const getMSPMaterialPayload = () => {
        let payload = {
            status: "5",
        }

        if (FileConstants.MANAGED_CUSTOMER_USERNAME != null) {
            payload.username = FileConstants.MANAGED_CUSTOMER_USERNAME
        }

        if (FileConstants.MANAGED_COMPANY_ID != null) {
            payload.company_id = FileConstants.MANAGED_COMPANY_ID
        }

        return payload;
    }

    useEffect(() => {
        
        let payload = {}

        if (FileConstants.isMSProvider) {
            payload = getMSPMaterialPayload();
        }
        else {
            payload = getMaterialPayload();
        }
        
        axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, payload)
        .then(res => {
            let parsedData = JSON.parse(res.data.body);
            setMaterialInfo(parsedData);
            forceUpdate();
        });
        setModelRequiredMaterialOutput(FileConstants.MATERIAL_OUTPUT_FOR_MODEL[userModelType])
        setRequestType('variation_product_model');
        loadUserRequestStatus();

        if(userModelType == 'high_res'){
            setModelingRequired(false)
        }
        else{
            setModelingRequired(true)
        }

    },[userModelType,modelType]);


    useEffect(() => {
        if (modelType == 2) {
            setIsConfigurable(true);
        } else {
            setIsConfigurable(false);
        }
    },[modelType]);

    const loadUserRequestStatus = () => {
        let payload = {
        "username": localStorage.getItem('username'),
        "action": "check",
        "request_type": requestType,
        "category": props.variationType
        }
        console.log('payload',payload)
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then(res => {
            console.log('res',res.data)
            setRequestStatus(res.data['request_allowed']);
            setRequestPrice(res.data['price']);
            setCardStatus(res.data['card_status']);
        });
    }

    const onChangeColorSelectionType = (e) => {
        setColorSelectionType(e.target.value);
    }

    const onChangeMaterialSelectionType= (e) => {
        if (e.target.value != materialSelectionType) {
            if (canvasObj != null) {
                if (finalSelectedMaterials.length > 0 || materialPhotosList.length > 0) {
                    console.log('fadas')
                    materialInfo.map((material) => {
                        if (finalSelectedMaterials.length > 0 && finalSelectedMaterials.includes(material.id)) {
                            if (material.renders  && material.renders.data && material.renders.data.thumbnail) {
                                let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail);
                                removeMaterialImages(url);
                            } else if (material.material_files[0] && !material.is_scanned) {
                                let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)
                                removeMaterialImages(url);
                            }
                        }
                    });
                    materialPhotosList.map((file) => {
                        let url = ENVIRONMENT.getBaseURL(file.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name);
                        removeMaterialImages(url);
                    });
                }
            }
            setFinalSelectedMaterials([]);
            setSelectedMaterial([]);
            setUploadMaterialUrls([])
            setMaterialPhotosList([]);
        }
        if (props.variationType == 'material') {
            updateAutomatedMaterialVariation(e.target.value == 1)
        }
        setMaterialSelectionType(e.target.value);
    }

    const onModelLoaded = (modelComponents) => {
        setModelLoaded(true)
        setModelComponents(modelComponents)
    }

    const setupModelViewer = () => {
        setModelLoaded(false)
        setModelComponents([])
        forceUpdate();
        const customModelViewer = new CustomModelViewer(props.productReducer.product.product_id, onModelLoaded, props.productReducer.product.platform);
        setModelViewer(customModelViewer)
    }

    const updateAutomatedMaterialVariation = (state) => {
        if (automatedMaterialVariation == state) {
            clearCanvas();
        }
        setAutomatedMaterialVariation(state);
    }


    useEffect(() => {
        if (automatedMaterialVariation == true) {
            if (props.variationType == 'material') {
                setupModelViewer();
            }
            setCanvasObj(null);
            setUploadMaterialUrls([])
            setSelectedMaterial([]);
            forceUpdate();
        }
        else{
            setCanvas();
            setSelectedModelComponent(null);
            setUploadMaterialUrls([])
            setSelectedMaterial([]);
            forceUpdate();
        }

    },[automatedMaterialVariation]);

    const modifyAttachmentsStatus = () => {
        if(rawAttachments){
            setRawAttachments(false);
        } else {
            setRawAttachments(true);
        }
    }

    const addComponents = (value) => {
        setComponentArray(value);
    }

    const onChangeModelType = (e) => {
        setModelType(e.target.value);
    }

    const changeModelType = () => {
        setModelType(1);
        forceUpdate();
    }

    const goBack = () => {
        if (props.page_format) {
            window.location.href = '/home';
        } else {
            props.setVariationStep(0);
        }
    }

    const addAsCategory = () => {

        if(!categoriesData.some(e => e.key == categorySearchValue)){
            setCategoriesData(categoriesData.concat([{
                key: categorySearchValue,
                value: categorySearchValue,
                title: categorySearchValue,
                children: [],
            }]));
            setCategoryValue(categorySearchValue);
            if (form && form.current) {
                form.current.setFieldsValue({
                    category: categorySearchValue
                });
            }

        }
    }

    const changeHexColor = (e) => {
        setColorSelectionType(0);
        setHexColor(e.target.value);
    }

    const changePantoneColor = (e) => {
        setColorSelectionType(1);
        setPantoneColor(e.target.value);
    }

    const makeConfigChange = (e) => {
        setMakeConfig(e.target.checked);
        if (e.target.checked) {
            setModelType(2);
            forceUpdate();
        }
    }

    const openMaterialModal = () => {
        setSearchValue('')
        setMaterialModal(true);
        let selected_materials = [...finalSelectedMaterials];
        setSelectedMaterial(selected_materials);
    }

    const changeModelOutput = (checkedValue) => {
        if (checkedValue.length == 0) {
            return
        }
        setModelTypes(checkedValue);
        console.log(checkedValue);

        if (checkedValue.find(a => a.includes('ar')) && checkedValue.find(a => a.includes('high_res'))) {
            setUserModelType('high_res_and_ar');
            setModelingRequired(true);
        }
        else if (checkedValue.find(a => a.includes('ar')) && checkedValue.find(a => a.includes('high_pbr'))) {
            setUserModelType('high_pbr_and_ar');
            setModelingRequired(true);
        }
         else {
            setUserModelType(checkedValue[0]);
            setModelingRequired(false);
            if (checkedValue.includes('ar_segmented'))  {
                setUserModelType('ar');
            }
            if (checkedValue.includes('high_res_segmented'))  {
                setUserModelType('high_res');
            }
            if (checkedValue.includes('high_pbr_segmented')){
                setUserModelType('high_pbr')
            }
        }
        if (checkedValue.find(a => a.includes('segmented'))) {
            setSegmented(true);
        }
    }

    const cancelMaterialModal = () => {
        setSelectedMaterial([]);
        setMaterialModal(false);
        forceUpdate();
    }

    const addMaterials = () => {
        setFinalSelectedMaterials(selectedMaterial);
        if (materialInfo && materialInfo.length > 0) {
            let old_materials = uploadMaterialUrls;
            materialInfo.map((material) =>  {
                if (selectedMaterial.includes(material.id) && !finalSelectedMaterials.includes(material.id)) {
                    if (material.renders  && material.renders.data && material.renders.data.thumbnail) {
                        let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail);
                        
                        let new_material = {
                            name: material.name,
                            url
                        }
                        old_materials.push(new_material)
                        console.log(old_materials)
                    } else if (material.material_files[0] && !material.is_scanned) {
                        let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)
                        let new_material = {
                            name: material.name,
                            url
                        }
                        old_materials.push(new_material)
                        console.log(old_materials)
                    }
                }
            })
            setUploadMaterialUrls(old_materials);
        }
        setMaterialModal(false);
    }

    const selectMaterialImages = (material) => {

        let material_arr = []
        if (selectedMaterial.includes(material.id)) {
            material_arr = selectedMaterial.filter(id => {return id != material.id});
            setSelectedMaterial(material_arr);
        } else {
            material_arr = selectedMaterial;
            material_arr.push(material.id);
            setSelectedMaterial(material_arr);
        }
        forceUpdate();
    }

    const addMaterial = (newSelectedMaterial) => {
       if (automatedMaterialVariation == true) {
           let modelComponentMaterialMapping = modelComponentMaterial
           modelComponentMaterialMapping[selectedModelComponent] = newSelectedMaterial.id;
           setModelComponentMaterial(modelComponentMaterialMapping)
           let updatedMaterials = [];
           for (let component in modelComponentMaterialMapping) {
               updatedMaterials.push(modelComponentMaterialMapping[component])
           }
           setSelectedMaterial(updatedMaterials)
           setFinalSelectedMaterials(updatedMaterials)
       }
       else {
            selectMaterialImages(newSelectedMaterial);
            setFinalSelectedMaterials(selectedMaterial);
       }
        if (materialInfo && materialInfo.length > 0) {
            let old_materials = uploadMaterialUrls;
            materialInfo.map((material) =>  {
                if (material.id == newSelectedMaterial.id) {
                    if (material.renders  && material.renders.data && material.renders.data.thumbnail) {
                        let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail);
                        
                        let new_material = {
                            name: material.name,
                            url
                        }
                        old_materials.push(new_material)
                        console.log(old_materials)
                    } else if (material.material_files[0] && !material.is_scanned) {
                        let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)
                        let new_material = {
                            name: material.name,
                            url
                        }
                        old_materials.push(new_material)
                        console.log(old_materials)
                    }
                }
            })
            setUploadMaterialUrls(old_materials);
        }
    }

    const removeMaterials = (material) => {
        if (automatedMaterialVariation == true) {
            let modelComponentMaterialMapping = modelComponentMaterial
            delete modelComponentMaterialMapping[selectedModelComponent]
            setModelComponentMaterial(modelComponentMaterialMapping)
        }
        let material_arr = selectedMaterial.filter(id => {return id != material.id});
        setSelectedMaterial(material_arr);
        setFinalSelectedMaterials(material_arr);
        if (material.renders  && material.renders.data && material.renders.data.thumbnail) {
            let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail);
            removeMaterialImages(url);
        }  else if (material.material_files[0] && !material.is_scanned) {
            let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)
            removeMaterialImages(url);
        }
    }

    const removeMaterialImages = (value) => {
        if (canvasObj) {
            let canvas_obj = canvasObj.getObjects();
            console.log(value,canvas_obj)
            canvas_obj = canvas_obj.filter(obj => {
                if (obj && obj._objects[0] && obj._objects[0]._element && obj._objects[0]._element.currentSrc) {
                    if (obj._objects[0]._element.currentSrc == value) {
                        console.log(value,'deleted')
                        canvasObj.remove(obj);
                    }
                }
            });
        }
        let new_materials = uploadMaterialUrls.filter(ele => ele.url != value);
        setUploadMaterialUrls(new_materials);
    }

    const handleRedirection = () =>{
       window.location.reload();
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'variation_product_model') {
                confirmPaymentAndSubmit();
            }

        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=variation_product_model', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if(child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    useEffect(() => {
        if((props.productReducer.isCreated == true || props.productReducer.isUpdated == true) && submittingCreateRequest == true) {

            if (props.productReducer.product && props.productReducer.product.product_id) {
                let product_id = props.productReducer.product.product_id;
                setProductID(product_id);
                if (componentArray.length > 0 && modelType == 2) {
                    addConfigurableInfo();
                }
            }
        }
        if((props.productReducer.isCreated == true || props.productReducer.isUpdated == true) && submittingSaveRequest == true){
            if (props.productReducer.product && props.productReducer.product.product_id) {
                setProductID(props.productReducer.product.product_id);
            }

            if (((props.variationType == 'state' && elementArray.length > 0) || props.variationType == 'material') && blob_data) {
                upload_photos(props.productReducer.product.product_id, blob_data, true);
            } else {
                props.variationSaved();
            }


        }
        if(props.productReducer.error) {
            message.error(props.productReducer.error);
        }

        let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
        .then(res => {
            let categoriesData = Utilities.getMaterialsData( res.data );
            for (let i = 1; i <= (res.data).length; i++) {
                if( res.data[i - 1].parent_id == "")
                {
                    data.push({
                        key: res.data[i - 1]['material_id'],
                        title: res.data[i - 1]['name'],
                        value: res.data[i - 1]['name'],
                        children: categoriesData[res.data[i - 1]['material_id']],
                    });
                }

            }
            setMaterialsData(data);
        });

        const payload = {
            output: 'tree',
            disable_parents: 'true'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
        .then(res => {
            setCategoriesData(res.data);
        });

        let product_id = props.product_id;
        if (product_id != undefined) {

            if (form && form.current) {
                console.log('Initial Values', form.current.getFieldsValue());
                setInitialValues(form.current.getFieldsValue());

            }
            if (props.product_details != undefined){
                if (props.product_details.price) {
                    setPrice(props.product_details.price);
                }
                if (props.product_details.elements) {
                    setElementArray(props.product_details.elements);
                }
                setVariantOf(props.product_details.variant_of);
                console.log('Product details', props.product_details)


                if (props.product_details.color_photos && props.savedID != -1) {
                    setColorFileList(props.product_details.color_photos);
                }
                if (props.product_details.hex_color) {
                    setHexColor(props.product_details.hex_color);
                }
                if (props.product_details.materials) {
                    setMaterialValue(props.product_details.materials)
                }

                if (props.product_details.pantone_color) {
                    setPantoneColor(props.product_details.pantone_color);
                }
                setProductName(props.product_details.product_name);

                let need_to_model = props.product_details.need_to_model
                let model_type = props.product_details.model_type;

                if((need_to_model == "high_res" && model_type == "ar") || 
                   (need_to_model == "ar" && model_type == "high_res") ){
                    setUserModelType('high_res_and_ar');
                }
                else if((need_to_model == "high_pbr" && model_type == "ar") ||
                    (need_to_model == "ar" && model_type == "high_pbr")){
                    setUserModelType('high_pbr_and_ar');
                }
                else if(need_to_model && !model_type){
                    setUserModelType(need_to_model);
                }
                else if(!need_to_model && model_type){
                    setUserModelType(model_type);
                }
                else{
                    setUserModelType('high_res');
                }

                if(props.productReducer.product.user_usecase){
                    setModelTypes(props.productReducer.product.user_usecase)
                }
                else{
                    if(model_type && need_to_model){
                        setModelTypes([model_type,need_to_model])
                    }
                    else if(model_type){
                        setModelTypes([model_type])
                    }
                    else if(need_to_model){
                        setModelTypes([need_to_model])
                    }
                    else {
                        setModelTypes(["high_res"])
                    }
                }
                if(props.productReducer.product.segmented){
                    setSegmented(props.productReducer.product.segmented)
                }

                setHiddenCategoryInitialValue();
                let product_photos = [];
                let product_photo_list = [];
                if (props.product_details.product_photos) {
                    product_photos = props.product_details.product_photos;
                }
                console.log('LOAD PHOTOS',product_photos)
                product_photo_list = product_photos;

                if (product_photos && product_photos.product_photos_all && Object.keys(product_photos.product_photos_all).length > 0) {
                    product_photo_list = product_photos.product_photos_all;

                    if (product_photos.product_photos_all.product_photos_all != undefined && product_photos.product_photos_all.product_photos_all && Array.isArray(product_photos.product_photos_all.product_photos_all)) {
                        product_photo_list = product_photos.product_photos_all.product_photos_all;
                    }
                    if (props.savedID != -1) {
                         setProductPhotosList(product_photo_list);

                        if (form && form.current) {
                            form.current.setFieldsValue({product_photos: product_photo_list});
                        }
                    }
                } else {
                    let temp_photos = [];
                    let product_photos_new = [];
                    let old_version = false;
                    if (product_photos.product_photos_back && product_photos.product_photos_back.length != 0) {
                        old_version = true;
                        temp_photos.push(product_photos.product_photos_back);
                    } if(product_photos.product_photos_left && product_photos.product_photos_left.length != 0){
                        old_version = true;
                        temp_photos.push(product_photos.product_photos_left);
                    } if(product_photos.product_photos_right && product_photos.product_photos_right.length != 0){
                        old_version = true;
                        temp_photos.push(product_photos.product_photos_right);
                    } if (product_photos.product_photos_top && product_photos.product_photos_top.length != 0) {
                        old_version = true;
                        temp_photos.push(product_photos.product_photos_top);
                    } if (product_photos.product_photos_front && product_photos.product_photos_front.length != 0) {
                        old_version = true;
                        temp_photos.push(product_photos.product_photos_front);
                    } if (product_photos.product_photos_bottom && product_photos.product_photos_bottom.length != 0) {
                        old_version = true;
                        temp_photos.push(product_photos.product_photos_bottom);
                    }
                    if (!old_version) {
                        product_photos_new = product_photos;
                        if (product_photos.product_photos_all) {
                            product_photos_new = product_photos.product_photos_all;
                        }
                    }
                    else {
                        temp_photos && temp_photos.map((photo => {
                            photo && photo.map(new_photo => {
                                product_photos_new.push(new_photo);
                            })
                        }));
                    }
                    product_photo_list = product_photos_new;

                    if (props.savedID != -1) {
                        setProductPhotosList(product_photo_list);

                       if (form && form.current) {
                           form.current.setFieldsValue({product_photos: product_photo_list});
                       }
                   }
                    console.log('Photos With Old Settings ',product_photos_new)
                }

                if (props.product_details.product_manuals && props.product_details.product_manuals.length > 0) {
                    let photos = props.product_details.product_manuals;
                    if (props.variationType != 'hardware' && props.variationType != 'other' && props.savedID != -1) {
                        setManualFileList(photos);
                    }

                }

                if (props.product_details.licensing_options != undefined) {
                    let licensing_options = props.product_details.licensing_options;
                    if (licensing_options.license != undefined) {
                        if (licensing_options.license == 'public') {
                            setPublicLicenseChecked(true);
                            if (licensing_options.public_option != undefined) {
                                if (licensing_options.public_option == 'forSale') {
                                    setForSale(true);
                                } else {
                                    setForCreativeCommon(true);
                                    if (licensing_options.creative_common != undefined) {
                                        setCreativeCommon(licensing_options.creative_common);
                                    }
                                }
                            }
                        }
                        else {
                            setPrivateLicenseChecked(true);
                        }
                    }
                }
            }

            let product_materials = props.product_details.material_files;
            if (product_materials != undefined && product_materials.length > 0 && props.savedID != -1) {
                setMaterialPhotosList(product_materials);
            }
        }
    }, [props.productReducer,props.product_details]);

    const addConfigurableInfo = () => {
        let product_id = props.productReducer.product.product_id;
        if (isConfigurable) {
            let paylod_request_id = product_id;
            axios.post(ENVIRONMENT.CREATE_REQUEST_ID, {"product_id" : paylod_request_id})
            .then(res => {
                setRequestID(res.data.confiq_req_id);
            });
        }
        componentArray.map((cmp, index) => {
            let used_library = 0;
            if (lib_selected[cmp]) {
                used_library = 1;
            }
            let payload = {
                product_id: product_id,
                name: cmp,
                using_material_library: used_library
            }
            axios.post(ENVIRONMENT.PRODUCT_COMPONENT_CREATE, payload)
            .then(res => {

                if (index == (componentArray.length - 1)) {
                    setConfigurableModal(true);
                }

            });
        });
    }

    const fetchProductMaterialVariationInfo = (productID, material_ids) => {
        let payload = {
            product_id: productID
        };
        axios.post(ENVIRONMENT.PRODUCT_VARIATION_REQUEST_GET, payload)
            .then(res => {
                let response = res.data;
                if (response) {
                    if (response['status'] == FileConstants.PRODUCT_VARIANT_AUTOMATION_STATUS["PENDING"]) {
                        setExistingAutomationRequest(true)
                        setAutomatedMaterialVariation(true)
                        let componentMaterialMapping = {}
                        for (let config of response.request_details.data)
                        {
                            if (material_ids.includes(config.material_id)) {
                                componentMaterialMapping[config.model_component] = config.material_id
                            }
                        }
                        setModelComponentMaterial(componentMaterialMapping)
                        forceUpdate();
                    }
                }
            });
    }

    const setHiddenCategoryInitialValue = () => {
        let category = props.product_details.category;
        let parent_category = props.product_details.parent_category
        setHiddenCategoryType(props.product_details.category_type);
        if (category) {
            setCategoryValue(category);
            if (FileConstants.HIDDEN_CATEGORY[category]) {
                if (props.savedID != -1) {
                    setHiddenCategoryFilelist(props.product_details.category_attachment || []);
                    setHiddenFile(props.product_details.category_attachment || []);
                }

                let default_category = true;
                FileConstants.HIDDEN_CATEGORY[category].map((cat,index) => {
                    if (cat == props.product_details.category_type) {
                        default_category = false;
                        setHiddenCategoryIndex(index);
                    }
                })
                if (default_category) {
                    setHiddenCategoryIndex(1);
                }
            } else if (parent_category && FileConstants.HIDDEN_CATEGORY[parent_category]) {//this will set Bed Frame only which is a hidden category in the table
                setCategoryValue(parent_category);
                if (props.savedID != -1) {
                    setHiddenCategoryFilelist(props.product_details.category_attachment || []);
                    setHiddenFile(props.product_details.category_attachment || []);
                }

                let default_category = true;
                FileConstants.HIDDEN_CATEGORY[parent_category].map((cat,index) => {
                    if (cat == props.product_details.category) {
                        default_category = false;
                        setHiddenCategoryIndex(index);
                    }
                })
                if (default_category) {
                    setHiddenCategoryIndex(1);
                }
            }
        }
    }

    const cancelPaymentConfirmation = () => {
        setPaymentConfirmationModal(false);
        setSubmittingCreateRequest(false);
    }


    useEffect(() => {
        let product_id = -1;
        if (props.savedID != -1) {
            product_id = props.savedID;
        } else {
            if (props.variationType == 'material') {
                setAutomatedMaterialVariation(true);
                setMaterialSelectionType(1)
            }
            product_id = props.product_id;
        }
        if (product_id != -1) {
            let payload = {
                product_id: product_id
            };
            axios.post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH ,payload)
            .then(res => {
                let response = res.data;
                if (response && response.body && response.body.length > 0) {
                    console.log(response)
                    response = response.body;
                    let material_ids = [];
                    response && response.map((material) => {
                        material_ids.push(material.material_id)
                    });
                    if (props.savedID != -1) {
                        setSelectedMaterial(material_ids)
                        setFinalSelectedMaterials(material_ids);
                        setSelectedMaterial(material_ids)
                        if (props.variationType == 'material') {
                            fetchProductMaterialVariationInfo(product_id, material_ids);
                        }
                        if (material_ids.length > 0){
                            setMaterialSelectionType(1);
                        }
                        setMaterialsReturned(material_ids);
                    }

                    console.log(material_ids)
                }
            });
        }
    },[]);

    useEffect(() => {
        //add items on canvas when saved form is fetched
        if (canvasObj && props.savedID != -1) {
            if (props.variationType == 'material') {
                if (materialInfo && materialInfo.length > 0 && !initialMaterials) {
                    if (materialsReturned && materialsReturned.length > 0) {
                        populateMaterials();
                    }
                }
                if (materialPhotosList && materialPhotosList.length > 0 && !initialMaterials) {
                    populateMaterials(false);
                }
            } else if (props.variationType == 'state') {
                if (elementArray && elementArray.length > 0 && !initialElements) {
                    elementArray.map((text) => {
                        setText(text, true);
                        setInitialElements(true);
                    })
                }
            }
        }
    }, [materialsReturned, materialInfo, materialPhotosList, canvasObj, elementArray])

    const populateMaterials = (selected_from_library = true) => {
        if (selected_from_library) {
            addSelectedMaterialsToCanvas();
        }  else {
            addUploadedMaterialsToCanvas();
        }
    }

    const addSelectedMaterialsToCanvas = () => {
        if (materialInfo && materialInfo.length > 0) {
            setMaterialSelectionType(1);
            let old_materials = uploadMaterialUrls;
            materialInfo.map((material) =>  {
                if (materialsReturned.includes(material.id)) {
                    if (material.renders  && material.renders.data && material.renders.data.thumbnail) {
                        let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail);
                        let new_material = {
                            name: material.name,
                            url
                        }
                        if (props.variationType == 'material') {
                            addImageElements(url, material.name)
                        }
                        old_materials.push(new_material)
                    } else if (material.material_files[0] && !material.is_scanned) {
                        let url = ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)
                        let new_material = {
                            name: material.name,
                            url
                        }
                        if (props.variationType == 'material') {
                            addImageElements(url, material.name)
                        }
                        old_materials.push(new_material)
                    }
                }})
            setInitialMaterials(true);
            setUploadMaterialUrls(old_materials);

        }
    }

    const addUploadedMaterialsToCanvas = () => {
        setMaterialSelectionType(0);
        let old_materials = [];
        materialPhotosList.map((file)=> {
            let new_material = {
                name: file.name,
                url: ENVIRONMENT.getBaseURL(file.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name)
            }
            if (props.variationType == 'material') {
                addImageElements(ENVIRONMENT.getBaseURL(file.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name), file.name)
            }
            old_materials.push(new_material)
        })
        setInitialMaterials(true);
        setUploadMaterialUrls(old_materials);
    }

    const onChangeProductName = (e) => {
        setProductName(e.target.value);
    }

    const onChangeCategoryValue = (value) => {
        setCategoryValue(value);
    }

    useEffect(()=> {
        if (form && form.current) {
            form.current.resetFields();
        }
        fetchProjectData();
    }, []);
    const checkIfValidAgain = (e) => {
        if(e != undefined) {
            e.preventDefault();
        }
        if(formValidationFailed){
            form.current.validateFields().then(values => {
                if(validateCustomFields()){
                    setFormValidationFailed(false);
                }
            });

        }
    }

    const addMaterialOption = (e) => {
        if(e.keyCode == 13){
            if(!materialsData.some(e => e.key == materialSearchValue)){
                setMaterialsData(materialsData.concat([{
                    key: materialSearchValue,
                    value: materialSearchValue,
                    title: materialSearchValue,
                    children: [],
                }]));
            }
        }
    }

    useEffect(()=> {
        checkIfValidAgain();
    }, [publicLicenseChecked, privateLicenseChecked, forSale, forCreativeCommon, creativeCommon])

    const validateCustomFields = () => {
        if (!publicLicenseChecked && !privateLicenseChecked){
            return false;
        }
        else if (publicLicenseChecked && (!forSale && !forCreativeCommon) ){
            return false;
        }
        else if (publicLicenseChecked && forCreativeCommon && creativeCommon == '') {
            return false;
        }
        else{
            return true;
        }
    }

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const handleChangeHiddenCategoryFile = info => {
        let fileList = [...info.fileList];
        setHiddenCategoryFilelist(fileList);
        console.log(info)

        if (info.file.status === "done") {
            setHasError(false);
            setUploadError('');
            setUploading(false);
            let listWithoutImage = _.cloneDeep(fileList);            ;
            listWithoutImage.map((file,index) => {
                listWithoutImage[index]['thumbUrl'] = ''
            })
            console.log(listWithoutImage);
            setHiddenFile(listWithoutImage);
        } else if (info.file.status === "error") {
            setUploadError(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setUploadError(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setUploadError(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    }

    const handleChangeColorSelection = info => {
        let fileList = [...info.fileList];
        setColorFileList(fileList);
        console.log(info)

        if (info.file.status === "done") {
            setHasError(false);
            setUploadError('');
            setUploading(false);
            setColorFileList(fileList);
        } else if (info.file.status === "error") {
            setUploadError(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setUploadError(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setUploadError(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
        }
    }

    const convertDimensionValue = (value, dimension) => {
        let converted_value = value;
        if (dimension == 'meter') {
            converted_value = converted_value * 39.37;
        }
        if (dimension == 'millimeter') {
            converted_value = converted_value / 25.4;
        }
        if (dimension == 'feet') {
            converted_value = converted_value * 12;
        }
        if (dimension == "centimeter") {
            converted_value = converted_value / 2.54;
        }
        return converted_value;
    }


    const createFormData = (form) => {
        let product_form_data = {...form};
        let product_id = props.savedID;
        if (props.variationType == 'hardware' || props.variationType == 'other') {
            product_form_data.dimensions_text = props.product_details.dimensions_text;
        }

        if (props.variationType == 'state') {
            product_form_data.elements = elementArray
        }
        if (props.variationType != 'hardware' && props.variationType != 'other') {
            product_form_data.style_category = props.product_details.style_category;
            if (props.variationType != 'color') {
                product_form_data.color_name = props.product_details.color_name;
                product_form_data.similar_color = props.product_details.similar_color;
            }
            if (props.variationType != 'material') {
                product_form_data.materials = props.product_details.materials;
            }
        }
        if (forSale && price && price != -1) {
            product_form_data.price = price;
        }
        if (props.variationType == 'size' || props.variationType == 'hardware' || props.variationType == 'other') {
            if (product_form_data.dimensions != 'inches') {
                product_form_data.height = convertDimensionValue(product_form_data.height, product_form_data.dimensions)
                product_form_data.width = convertDimensionValue(product_form_data.width, product_form_data.dimensions)
                product_form_data.length = convertDimensionValue(product_form_data.length, product_form_data.dimensions)
                if (props.variationType == 'size') {
                    product_form_data.guidelines = "";
                }
            }
        } else {
            product_form_data.height = props.product_details  && props.model_height !="N/A" ? Math.round(props.model_height): props.product_details && props.product_details.height;
            product_form_data.length = props.product_details && props.model_depth !="N/A" ? Math.round(props.model_depth): props.product_details && props.product_details.length;
            product_form_data.width = props.product_details && props.model_width !="N/A" ? Math.round(props.model_width): props.product_details && props.product_details.width;
            product_form_data.dimensions_text = props.product_details.dimensions_text;
        }
        product_form_data.components = [];
        product_form_data.product_photos = productPhotosList;
        product_form_data.raw_files = rawFileList;
        product_form_data.licensing_options = props.product_details.licensing_options;
        product_form_data.segmented = segmented;
        product_form_data.user_usecase = modelTypes;
        product_form_data.is_configurable = isConfigurable;
        product_form_data.need_to_model = userModelType;
        product_form_data.modeling_required = true;
        if (props.variationType == 'ar_conversion') {
            if (props.arVariationType == 'high_pbr'){
                product_form_data.need_to_model = 'high_pbr';
                product_form_data.user_usecase = ['high_pbr'];
            }
            else{
                product_form_data.need_to_model = 'ar';
                product_form_data.user_usecase = ['ar'];
            }
        } else if (props.variationType == 'high_conversion') {
            product_form_data.need_to_model = 'high_res';
            product_form_data.user_usecase = ['high_res'];
        }
        if (props.variationType == 'color' || props.variationType == 'hardware' || props.variationType == 'other') {
            if (colorSelectionType == 0 && hexColor != "") {
                product_form_data.hex_color = hexColor;
                product_form_data.pantone_color = "";
                product_form_data.color_photos = [];
            } else if (colorSelectionType == 1 && pantoneColor != "") {
                product_form_data.pantone_color = pantoneColor;
                product_form_data.hex_color = "";
                product_form_data.color_photos = [];
            } else if (colorSelectionType == 2 && colorFileList && colorFileList.length > 0) {
                product_form_data.pantone_color = "";
                product_form_data.hex_color = "";
                let listWithoutImage = _.cloneDeep(colorFileList);
                listWithoutImage.map((file,index) => {
                    listWithoutImage[index]['thumbUrl'] = '';
                })
                product_form_data.color_photos = listWithoutImage;
            }
        } else {
            if (props.product_details.hex_color) {
                product_form_data.hex_color = props.product_details.hex_color;
            }
            if (props.product_details.pantone_color) {
                product_form_data.pantone_color = props.product_details.pantone_color;
            }
            if (props.product_details.color_photos && props.product_details.color_photos.length > 0) {
                product_form_data.color_photos = props.product_details.color_photos;
            }
        }

        if (props.product_details.uploaded_model) {
            product_form_data.uploaded_model = true;
        } else if (!props.product_details.uploaded_model) {
            product_form_data.uploaded_model = false;
        }
        product_form_data.variant_of = variantOf ? variantOf : props.product_id;
        product_form_data.immediate_parent_variant = props.product_id;
        product_form_data.variation_type = props.variationType;

        if (FileConstants.HIDDEN_CATEGORY[categoryValue] && hiddenCategoryIndex != 1) {//if category is Bed and selected hidden category is with Pillows and Mattress then mark it as default
            if (hiddenCategoryIndex != 0) {
                product_form_data.hidden_category_type = FileConstants.HIDDEN_CATEGORY[categoryValue][hiddenCategoryIndex];
                product_form_data.hidden_category_attachment = hiddenCategoryIndex == 2 ? hiddenFile : [];
            } else {
                product_form_data.category = FileConstants.HIDDEN_CATEGORY[categoryValue][hiddenCategoryIndex];
            }

        } else if (product_id != -1 && props.product_details.category_type) {
            product_form_data.delete_hidden_category = true;
        }
        return product_form_data;
    }

    const getSubmitErrors = (values) => {
        let error_messages = [];
        setErrorMessages([]);
        
        let colorPhotoStatus = 'not_started';
        values['color_photos'] = colorFileList;
        colorPhotoStatus = validateFileUploads(values['color_photos']);

        let referenceImageStatus = 'not_started';
        values['product_manuals'] = manualFileList;
        referenceImageStatus = validateFileUploads(values['product_manuals']);

        let materialUploadStatus = 'not_started';
        values['material_files'] = materialPhotosList;
        materialUploadStatus = validateFileUploads(values['material_files']);

        let hiddenCustomFileStatus = 'not_started';
        values['hidden_custom_file'] = hiddenCategoryFilelist;
        hiddenCustomFileStatus = validateFileUploads(values['hidden_custom_file']);

        if (props.variationType == 'color' || props.variationType == 'hardware' || props.variationType == 'other') {
            if (colorSelectionType == 2 && colorPhotoStatus != 'done') {
                error_messages.push("Please upload color photos.");
            } else if (colorSelectionType == 1 && pantoneColor == '') {
                error_messages.push('Please add a pantone color.')
            } else if (colorSelectionType == 0 && hexColor == '') {
                error_messages.push('Please add a hex color.')
            }
        }
        if (materialUploadStatus == 'uploading' || hiddenCustomFileStatus == 'uploading' || colorPhotoStatus == 'uploading' || referenceImageStatus == 'uploading') {
            error_messages.push('Uploads are in progress, please wait till file uploads are completed.')
        } else if (materialUploadStatus == 'error' || hiddenCustomFileStatus == 'error' || colorPhotoStatus == 'error' || referenceImageStatus == 'error') {
            error_messages.push('Error occured in uploading files, please re-upload your files.')
        }
        if (props.variationType == 'state') {
            if (elementArray.length == 0) {
                error_messages.push('Add labels and annotate the product photo with the changes you want us to make.')
            }
        }
        if (props.variationType == 'material' || props.variationType == 'hardware' || props.variationType == 'other') {
            if (materialPhotosList.length == 0 && finalSelectedMaterials.length == 0) {
                error_messages.push('Either select a material from your material library or upload a swatch.');
            }
            else if (materialPhotosList.length > 0 && materialUploadStatus != 'done') {
                error_messages.push("Please upload material swatch");
            }
            if (materialValue && materialValue.length == 0) {
                error_messages.push('Please select a material category');
            }
        }
        if (manualFileList && manualFileList.length == 0 &&  (props.variationType == 'hardware' || props.variationType == 'other' || props.variationType == 'state')) {
            if (props.variationType == "state") {
                error_messages.push('Please upload reference photos.');
            } else {
                error_messages.push('Please upload reference photos in Product Sketches prompt.');
            }
            
        }
        if (props.variationType == 'bedding' || props.variationType == 'hardware' || props.variationType == 'other') {
            if (FileConstants.HIDDEN_CATEGORY[categoryValue] && hiddenCategoryIndex == 2) {
                if (hiddenFile && hiddenFile.length == 0) {
                    error_messages.push('Please upload an image for bedding.');
                }
            }
        }
        return error_messages;

    }

    const migrateData = (product_id) => {
        if(props.productReducer.product.platform != ENVIRONMENT.getUploadPlatform())
        {
            let copy_to = "";
            let copy_from = "";
            if(props.productReducer.product.platform == "aws"){
                copy_from = "aws";
                copy_to = "google";
            }else{
                copy_from = "google";
                copy_to = "aws";
            }

            let payload = {
                "entity_type" : "product",
                "id" : product_id,
                "copy_from" : copy_from,
                "copy_to" : copy_to
            }

            axios.post(ENVIRONMENT.VARIANT_DATA_MIGRATOR, payload)
                .then(res => {});
        }
    }

    const handleSubmit = (values) => {
        console.log(values);
        let error_messages = [];
        error_messages = getSubmitErrors(values);
        if ((error_messages && error_messages.length > 0) || uploadError != '') {
            setErrorMessages(error_messages);
            setSubmittingCreateRequest(false);
            return;
        }


        if (modelFileList.length == 0 && props.product_details.uploaded_model) {
            setModelFileList(props.product_details.model_files);
            values['model_files'] = props.product_details.model_files;
        }
        if (colorFileList && colorFileList.length > 0) {
            let listWithoutImage = _.cloneDeep(colorFileList);
            listWithoutImage.map((file,index) => {
                listWithoutImage[index]['thumbUrl'] = '';
            })
            values['color_photos'] = listWithoutImage;
        }

        values['product_manuals'] = manualFileList;
        if (materialPhotosList) {
            let listWithoutImage = _.cloneDeep(materialPhotosList);
            listWithoutImage.map((file,index) => {
                listWithoutImage[index]['thumbUrl'] = '';
            })
            values['material_files'] = listWithoutImage;
        }
        values['hidden_custom_file'] = hiddenCategoryFilelist;
        values['product_photos'] = productPhotosList;
        values['raw_files'] = rawFileList;

        let product_form_data = formatProduct(values);
        product_form_data = createFormData(product_form_data);
        console.log("form data here", product_form_data)

        let product_id = props.savedID;

        let payload = {}
        if (product_id != -1) {
            product_form_data.product_id = product_id;
            if (modelType == 1) {
                product_form_data = createMaterialData(product_form_data);
            }
            console.log("Update Product");
            let requestPayload = formatProduct(product_form_data);
            requestPayload['username'] = localStorage.getItem('username');
            payload = {
                "username": localStorage.getItem('username'),
                "request_type": requestType,
                "update": "",
                "action": "perform",
                "request_payload": requestPayload,
                "category": props.variationType,
                "request_id": Date.now().toString()
            }
            console.log("update payload", JSON.stringify(requestPayload))
        }
        else {
            console.log("Create Product");
            let company_id = COMPANY_ID;
            if(company_id != undefined) {
                product_form_data.company_id = company_id;
            }
            if (modelType == 1 && finalSelectedMaterials.length > 0) {
                let materials_to_create = finalSelectedMaterials.filter(x => materialsReturned.indexOf(x) === -1);
                product_form_data['materials_to_create'] = materials_to_create;
            }
            let requestPayload = formatProduct(product_form_data);
            requestPayload['username'] = localStorage.getItem('username');
            payload = {
                "username": localStorage.getItem('username'),
                "request_type": requestType,
                "action": "perform",
                "request_payload": requestPayload,
                "category": props.variationType,
                "request_id": Date.now().toString()
            }
        }
        if (FileConstants.isMSProvider && (FileConstants.MANAGED_CUSTOMER_USERNAME != null || FileConstants.MANAGED_COMPANY_ID != null)) {
            payload["request_payload"]['requested_for'] = FileConstants.MANAGED_CUSTOMER_USERNAME
        }
        if (requestStatus == "allowed" || requestStatus == "always_allowed") {
            migrateData(props.productReducer.product.product_id);
            setSubmittingCreateRequest(true);
            axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
                .then(res => {
                    console.log(res.data)
                    if (res.data.status!=undefined && res.data.status == 1) {
                        setProductCreated(true);
                        let new_product_id = -1;
                        if (product_id != -1) {
                            new_product_id = product_id;
                        } else {
                            new_product_id = res.data.action_response.product_id;
                        }
                        if (props.variationType == 'state' && elementArray.length > 0) {
                            update_tagging_photo(new_product_id, true);
                        }
                        else if (props.variationType == 'material' && automatedMaterialVariation == false) {
                            update_tagging_photo(new_product_id, true);
                        }
                        else {
                            props.variationCreated();
                        }
                        setSubmittingCreateRequest(false);
                    }
                    else{
                        setPaymentFailureMessage(res.data.error_message);
                        setPaymentFailureModal(true);
                        setSubmittingCreateRequest(false);
                    }
                });
        }
        if (requestStatus=="payment_required") {
            setProductCreatePayload(payload);
            setPaymentConfirmationModal(true);
        }
        if (props.requestStatus == 'not_allowed') {
            setInAppLimitExceededModal(true);
        }

        console.log(payload, values)

}

    const confirmPaymentAndSubmit = () => {
        migrateData(props.productReducer.product.product_id);
        setSubmittingCreateRequest(true);
        console.log(JSON.stringify(productCreatePayload))
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, productCreatePayload)
            .then(res => {
                console.log(res)
                if (res.data.status!=undefined && res.data.status == 1) {
                    setProductID(res.product_id);
                    props.variationCreated();
                    setSubmittingCreateRequest(false);
                    setPaymentConfirmationModal(false);
                }
                else{
                    setPaymentFailureMessage(res.data.error_message);
                    setPaymentFailureModal(true);
                    setSubmittingCreateRequest(false);
                }
            });
    }

    const initiateConfirmation = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'variation_product_model') {
                confirmPaymentAndSubmit();
            }

        }, false);

        if (cardStatus == 1) {
            confirmPaymentAndSubmit();
        }
        if (cardStatus == 0) {
            let child_window = window.open('/save-card?action=variation_product_model', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
              }
            }, 500);
            setCardSaveFlowTriggered(true);
        }
    }

    const getSaveErrors = (values) => {
        setErrorMessages([]);
        let error_messages = [];
        let materialUploadStatus = 'not_started';
        materialUploadStatus = validateFileUploads(materialPhotosList);

        let rawFileStatus = 'not_started';
        values['raw_files'] = rawFileList;
        rawFileStatus = validateFileUploads(values['raw_files']);

        let colorPhotoStatus = 'not_started';
        colorPhotoStatus = validateFileUploads(colorFileList);

        let referenceImageStatus = 'not_started';
        values['product_manuals'] = manualFileList;
        referenceImageStatus = validateFileUploads(values['product_manuals']);

        let productPhotoStatus = '';
        values['product_photos'] = productPhotosList;
        productPhotoStatus = validateFileUploads(values['product_photos']);
        if (productPhotoStatus == 'uploading' || materialUploadStatus == 'uploading' || rawFileStatus == 'uploading' || referenceImageStatus == 'uploading' || colorPhotoStatus == 'uploading') {
            error_messages.push(FileConstants.getErrors['uploading'])
        }
        else if (productPhotoStatus == 'error' || materialUploadStatus == 'error' || rawFileStatus == 'error' || referenceImageStatus == 'error' || colorPhotoStatus == 'error') {
            error_messages.push(FileConstants.getErrors['error'])
        }
        return error_messages;
    }

    const handleSave = () => {
        setSubmittingSaveRequest(true);
        let values = form.current.getFieldsValue();
        console.log('handleSave ',values);

        if((values['product_name'] != "" && values['product_name'] != undefined) && (values['category'] != "" && values['category'] != undefined)){
            if (modelFileList.length == 0 && props.product_details.uploaded_model) {
                setModelFileList(props.product_details.model_files);
                values['model_files'] = props.product_details.model_files;
            }
            setErrorMessages([]);
            let error_messages = getSaveErrors(values);

            if (materialPhotosList) {
                let listWithoutImage = _.cloneDeep(materialPhotosList);
                listWithoutImage.map((file,index) => {
                    listWithoutImage[index]['thumbUrl'] = '';
                })
                values['material_files'] = listWithoutImage;
            }
            values['raw_files'] = rawFileList;
            console.log(values, 'before')
            if (colorFileList && colorFileList.length > 0) {
                let listWithoutImage = _.cloneDeep(colorFileList);
                listWithoutImage.map((file,index) => {
                    listWithoutImage[index]['thumbUrl'] = '';
                })
                values['color_photos'] = listWithoutImage;
            }
            values['product_manuals'] = manualFileList;
            values['product_photos'] = productPhotosList;

            if (uploadError != '' || error_messages.length > 0){
                setSubmittingSaveRequest(false);
                return;
            }

            let product_form_data = formatProduct(values);
            if (!product_form_data.height) {
                product_form_data.height = 0;
            }
            if (!product_form_data.length) {
                product_form_data.length = 0;
            }
            if (!product_form_data.width) {
                product_form_data.width = 0;
            }
            product_form_data = createFormData(product_form_data);

            let product_id = props.savedID;
            console.log(product_id);

            if (product_id != -1) {
                if (props.variationType == 'state' && elementArray.length > 0) {
                    update_tagging_photo(product_id);
                }
                else if (props.variationType == 'material' && automatedMaterialVariation == false) {
                    update_tagging_photo(product_id);
                }
                product_form_data.product_id = product_id;
                console.log("Update Product");
                if (modelType == 1) {
                    product_form_data = createMaterialData(product_form_data);
                }
                props.updateProduct(formatProduct(product_form_data, true));
            }
            else {
                console.log("Create Product");
                let company_id = COMPANY_ID;
                if(company_id != undefined) {
                    product_form_data.company_id = company_id;
                }
                if (modelType == 1 && finalSelectedMaterials.length > 0) {
                    let materials_to_create = finalSelectedMaterials.filter(x => materialsReturned.indexOf(x) === -1);
                    product_form_data['materials_to_create'] = materials_to_create;
                }
                setProductCreated(true);
                props.createProduct(formatProduct(product_form_data, true));
                if ((props.variationType == 'state' && elementArray.length > 0)) {
                    update_tagging_photo(-1);
                }
                else if (props.variationType == 'material' && automatedMaterialVariation == false) {
                    update_tagging_photo(-1);
                }
            }
        }
        else{
            let error_messages = []
            if (values['product_name'] == "" || values['product_name'] == undefined) {
                error_messages.push('You must enter product name to save your progress.');
            } else if (values['category'] == ""|| values['category'] == undefined) {
                error_messages.push('You must enter product category to save your progress.');
            }
            setErrorMessages(error_messages);
        }

    }

    const createMaterialData = (form) => {
        let product = {...form}
        // materials to be created
        let materials_to_create = finalSelectedMaterials.filter(x => materialsReturned.indexOf(x) === -1);
        //materials to be deleted
        var materials_to_delete = materialsReturned.filter(x => finalSelectedMaterials.indexOf(x) === -1);

        product.materials_to_create = materials_to_create;
        product.materials_to_delete = materials_to_delete;

        return product;
    }

    const formatProduct = (form, save_request=false) => {
        let product = {...form};
        if(save_request == true)
            product.model_status = 1;
        else
            product.model_status = 2;

        if (automatedMaterialVariation) {
            product = setRequestParamsForAutomatedVariation(product, save_request)
        }
        if (existingAutomationRequest) {
            product['product_variation_request'] = true
        }
        product['user_type'] = "customer"
        console.log('Final Product data',product);
        console.log(JSON.stringify(product));

        return product;
    }

    const setRequestParamsForAutomatedVariation = (payload, save_request) => {
        let updatedPayload = payload;
        updatedPayload['product_material_variation'] = modelComponentMaterial
        if (!save_request) {
            updatedPayload['automated_material_variation'] = true
            updatedPayload['model_status'] = 6
        }
        else {
            updatedPayload['automated_material_variation'] = false
        }
        return updatedPayload
    }

    const handleChangeManualFiles = info => {
        let fileList = [...info.fileList];
        setManualFileList(fileList);

        if (info.file.status === "done") {
            setHasError(false);
            setUploadError('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setUploadError(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setUploadError(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setUploadError(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    };

    const handleChangeRawFiles = info => {
        let fileList = [...info.fileList];
        setRawFileList(fileList);

        if (info.file.status === "done") {
            setHasError(false);
            setUploadError('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setUploadError(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setUploadError(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setUploadError(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    };


    const handleChangeMaterialPhotos = info => {
        let fileList = [...info.fileList];
        if (info.file.status === "done") {
            setHasError(false);
            setUploadError('');
            setUploading(false);
            setMaterialPhotosList(fileList);
            setMaterialPhotoSettings(fileList);
        } else if (info.file.status === "error") {
            setUploadError(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setUploadError(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setUploadError(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        } else if (info.file.status == 'uploading') {
            setInitialMaterials(true);
        }
        setMaterialPhotosList(fileList);
    };


    const triggerProductArchive = () => {
        $('#product_info_model_files').trigger('click');
        let button = document.getElementById('product_info_model_files');
        button.disabled = false;
    }

    const triggerProductPhotoUpload = () => {
        $('#product_info_product_photos').trigger('click');
        let button = document.getElementById('product_info_product_photos');
        button.disabled = false;
    }

    const triggerMaterialPhotoUpload = () => {
        $('#product_info_material_files').trigger('click');
        let button = document.getElementById('product_info_material_files');
        button.disabled = false;
    }

    const triggerProductRawFiles = () => {
        $('#product_info_raw_files').trigger('click');
        let button = document.getElementById('product_info_raw_files');
        button.disabled = false;
    }


    const onChangeHiddenCategory = (e) => {
        console.log(e.target.value);
        setHiddenCategoryIndex(e.target.value);
        console.log(e.target.value,FileConstants.HIDDEN_CATEGORY, hiddenCategoryIndex)
        setHiddenCategoryType(FileConstants.HIDDEN_CATEGORY[categoryValue][hiddenCategoryIndex]);
        setHiddenCategoryIndex(e.target.value);
    }

    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
    }

    // Find missing material type from the selected material -
    // A material can be only vray/pbr or both
    const findMissingMaterialTypeFromSelection = (currentMaterialType) => {
        let missingMaterialTypes = '';
        let requiredMaterialTypes = FileConstants.MATERIAL_TYPE_REQUIRED_OUTPUTS[modelRequiredMaterialOutput];
        let selectedMaterialTypes = FileConstants.MATERIAL_TYPE_REQUIRED_OUTPUTS[currentMaterialType];
        for (let materialType in requiredMaterialTypes) {
            if (!selectedMaterialTypes.includes(requiredMaterialTypes[materialType])) {
                if (missingMaterialTypes) {
                    missingMaterialTypes += ' , '
                }
                missingMaterialTypes += FileConstants.MATERIAL_TYPE_LABELS[requiredMaterialTypes[materialType]] + ' Materials'
            }
        }
        return missingMaterialTypes
    }

    return <div>
        {props.productReducer.isLoading?
        <div className="text-center">
        <LoadingOutlined type="sync" spin /> </div>: "" }
        <Form
        scrollToFirstError
        onKeyDown={(e)=> e.keyCode == 13 ? e.preventDefault(): ''}
        onFinishFailed={file => {
            let error_messages = []
            error_messages.push('Please enter all required information before submitting.')
            setErrorMessages(error_messages);
        }}
        ref={form}
        className="product-variation-form" initialValues={{
            product_name: props.product_details && props.product_details.product_name,
            brand_id: props.product_details && props.product_details.brand_id,
            category: props.product_details && props.product_details.parent_category && FileConstants.HIDDEN_CATEGORY[props.product_details.parent_category] ? props.product_details && props.product_details.parent_category : props.product_details && props.product_details.category,
            style_category:  props.product_details && props.product_details.style_category,
            product_model_type: props.product_details && props.product_details.product_model_type,
            height: props.product_details  && props.model_height !="N/A" ? Math.round(props.model_height): props.product_details && props.product_details.height,
            length: props.product_details && props.model_depth !="N/A" ? Math.round(props.model_depth): props.product_details && props.product_details.length,
            width: props.product_details && props.model_width !="N/A" ? Math.round(props.model_width): props.product_details && props.product_details.width,
            reference_urls: props.product_details && props.product_details.reference_urls,
            color_name:  props.product_details && props.product_details.color_name,
            similar_color:  props.product_details && props.product_details.similar_color,
            guidelines:  props.savedID == -1 ? "" : props.product_details && props.product_details.guidelines,
            materials: props.product_details && props.product_details.materials,
            elements: props.product_details && props.product_details.elements,
            product_photos: productPhotosList,
            dimensions: 'inches',
            raw_files: rawFileList,
            product_manuals: manualFileList,
            dimensions_text: props.savedID == -1 ? "" : props.product_details && props.product_details.dimensions_text,
            material_files: props.product_details && props.product_details.material_files,
            licensing_options: props.product_details && props.product_details.licensing_options,
            model_files: [],
            hidden_custom_file: (props.product_details && props.productReducer.product.category_attachment) || []
        }}
        onFinish={handleSubmit}
        layout="vertical">
            <div>
                <div className={props.page_format ? '' : 'restrict-height'}>
                    <Row type='flex' gutter={[12, 0]}>
                        <Col span={24}>
                            <Form.Item  colon={false} label={<span className="manrope f-14 black-55 w-600"> Name</span>} name="product_name" rules={[{ required: true, message: 'Please enter product name.' }]}>
                                <Input onChange={onChangeProductName} placeholder={'Enter Name'} className="manrope f-12 black-55 library-search"/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="project"  label={(<span className='manrope f-14 black-55 w-600'>Projects&nbsp;</span>)} colon={false} rules={[{ required: false, message: 'Please select project.' }]}>
                                    <Select
                                        style ={{lineHeight: "220%"}}
                                        className="tree-select-material adjust-height-46 f-12"
                                        showSearch
                                        placeholder="Select or type in project name"
                                    >
                                        {Object.keys(projects).map((key) => (
                                            <Select.Option
                                                key={projects[key].id}
                                                className="manrope f-12 black-55 select-view"
                                                value={projects[key].id}
                                            >
                                                {projects[key].name}
                                            </Select.Option>
                                            ))}
                                    </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <div className="material-field">
                                <Form.Item  label={<span className="manrope f-14 black-55 w-600">Category</span>} name="category" className="material-category-form"
                                    rules={[{ required: (!props.product_details.category), message: 'Please select a category.' }]}>
                                    <TreeSelect
                                    className="tree-select-material adjust-height-46 f-12"
                                    showSearch
                                    disabled={props.product_details.category && (!['hardware', 'other'].includes(props.variationType))}
                                    style={{ width: '100%', color:"#333333", height: 47 }}
                                    dropdownStyle={categoryStyle}
                                    placeholder={'Select or type in to search category'}
                                    treeData={categoriesData}
                                    value={categoryValue}
                                    onChange={(value) => onChangeCategoryValue(value)}
                                    onSearch={(e) => {setCategorySearchValue(e)}}
                                    notFoundContent={<span  style={{textAlign: 'center'}}><h5 style={{textAlign: 'center'}} className='manrope f-14 black-55'>No Category Found</h5><p className='manrope f-14 blue' style={{textAlign: 'center',cursor: 'pointer'}} onClick={addAsCategory}><PlusOutlined/>&nbsp;Add As Category</p></span>}>
                                    </TreeSelect>
                                </Form.Item>
                            </div>
                        </Col>
                        {((props.product_details.parent_category && FileConstants.HIDDEN_CATEGORY[props.product_details.parent_category]) || FileConstants.HIDDEN_CATEGORY[categoryValue]) && (props.variationType == 'bedding' || props.variationType == 'other'
                         || props.variationType == 'hardware') ?
                            <HiddenCategory
                            categoryValue={categoryValue}
                            hiddenCategoryIndex={hiddenCategoryIndex}
                            onChangeHiddenCategory={onChangeHiddenCategory}
                            handleChangeHiddenCategoryFile={handleChangeHiddenCategoryFile}
                            hiddenCategoryFilelist={hiddenCategoryFilelist}
                            setHiddenCategoryFilelist={setHiddenCategoryFilelist}
                            hasError={hasError}
                            setHasError={setHasError}
                            platform={props.product_details.platform}
                            />
                        : ''}
                        {(props.variationType == 'hardware'
                        || props.variationType == 'other') &&
                        <Col span={12}>
                            <div className='material-field'>
                                <Form.Item label={<span className='manrope f-14 black-55 w-600'>Style Category</span>}
                                name='style_category' className='material-category-form'
                                rules={[{ required: false, message: 'Please select a style category'}]}>
                                    <StyleCategory className='tree-select-material f-12 adjust-height-46'/>
                                </Form.Item>
                            </div>
                        </Col>}

                        <Col span={12}>
                            <Form.Item  colon={false} label={<span className="manrope f-14 black-55 w-600">Item ID</span>} name="brand_id" rules={[{ required: false, message: 'Please enter item id.' }]}>
                                <Input  placeholder={'Enter Item ID'} className="manrope f-12 black-55 library-search"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="product_model_type" label={<span className='manrope f-14 black-55 w-600'>Product Type</span>}  className='material-category-form' colon={false}
                                rules={[{ required: false, message: 'Please select product type.' }]}
                            >
                                <Select
                                    className="manrope f-10 black-55 tree-select-material adjust-height-46 f-12"
                                    showSearch
                                    placeholder="Select or type in your product type"
                                >
                                    {Object.keys(FileConstants.PRODUCT_MODEL_TYPE).map((key) => (
                                        <Select.Option
                                            key={key}
                                            className="manrope f-10 select-view"
                                            value={key}
                                        >
                                            {FileConstants.PRODUCT_MODEL_TYPE[key]}
                                        </Select.Option>
                                        ))}                                             
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item  colon={false} label={<span className="manrope f-14 black-55 w-600">Reference URL(s)</span>} name="reference_urls" rules={[{ required: false, type:'array', message: 'Please add reference url(s).' }]}>
                                <Select style ={{lineHeight: "220%"}} className="manrope f-12 black-55 select-tags selection-popup" mode="tags" placeholder="Enter Reference URL(s) that you would like us to have a look at, for your model">
                                    <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        {(props.variationType == 'hardware'
                        || props.variationType == 'other') &&
                            <ProductOutputSelection
                            changeModelOutput={changeModelOutput}
                            modelTypes={modelTypes}
                        />}



                        {props.variationType != 'size' &&
                        <Col span={24}>
                            <Form.Item  name="guidelines" label={<span className="manrope f-14 black-55 w-600">What are the changes that you want us to make for this model? Please mention below.</span>} colon={false}>
                                <Input.TextArea rows={3} className="manrope f-12 grey-77 library-search"
                                    placeholder = "Enter details for the changes you want us to make" suffix={<Tooltip title={<span className="manrope f-12 white">Attach Raw Files</span>}><PaperClipOutlined onClick={modifyAttachmentsStatus} /></Tooltip>}/>
                            </Form.Item>
                        </Col>}

                        {rawAttachments &&
                        <Col span={24} className="upload-pd" style={{marginBottom: 20,cursor:"default"}}>
                            <Form.Item
                                    colon={false}
                                    label={<span className="manrope f-14 black-55 w-600">Additional Raw Attachments</span>}
                                    name="raw_files"
                                    rules={[{ required: false, message: 'Please upload raw attachments.' }]}>
                                    <Upload
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-references"
                                        accept=".zip,.jpg,.jpeg,.png,.tiff"
                                        multiple={true}
                                        onChange = {handleChangeRawFiles}
                                        fileList = {rawFileList}
                                        progress= {progress_bar}
                                        {...(Constants.getUploadProps())}
                                        onRemove={file => {
                                            setUploadError('');
                                            const index = rawFileList.indexOf(file);
                                            const newFileList = rawFileList.slice();
                                            newFileList.splice(index, 1);
                                            setRawFileList(newFileList);
                                            newFileList.forEach((file) => {
                                                if (file.status !== "error"){
                                                    setHasError(false)
                                                }
                                                else {
                                                    setHasError(true)
                                                }
                                            })
                                            return true;
                                        }}
                                        listType="text">
                                            {validateFileUploads(rawFileList) == "uploading"  ?
                                                <div className="justify-in-center">
                                                    <p className="justify-in-center manrope f-12">Upload in Progress... </p>
                                                </div> :
                                            validateFileUploads(rawFileList) == "done" ?
                                            <span>
                                                <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>

                                                <p className="manrope f-12 grey-77">Drop your raw attachments, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                                                <p className="manrope f-12 grey-77">
                                                Supported formats: zip, jpg, png, tiff
                                                </p>
                                            </span>:
                                            validateFileUploads(rawFileList) == "error" ?
                                            <span>
                                                <div className="justify-in-center">
                                                    <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                    <p className="manrope f-12" style={{color:"#D93025"}}>Upload Failed</p>
                                                </div>
                                            </span> :
                                            (validateFileUploads(rawFileList) == "not_started" ?
                                                <span>
                                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>

                                                    <p className="manrope f-12 grey-77">Drop your raw attachments, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                                                    <p className="manrope f-12 grey-77">
                                                    Supported formats: zip, jpg, png, tiff
                                                    </p>
                                                </span>: '')}
                                    </Upload>
                                </Form.Item>
                            </Col>}

                            {(props.variationType == 'hardware' || props.variationType == 'other' || props.variationType == 'size') &&
                            <Col span={24} className="upload-pd" style={{marginBottom: 20,cursor:"default"}}>
                                <Form.Item
                                    colon={false}
                                    label={<span className="manrope f-14 black-55 w-600">{(props.variationType == 'hardware' || props.variationType == 'other') && <span className="manrope f-18 red-ff w-600">*&nbsp;</span>}Product Sketches, CADs & Designs and/or Similar Item Images</span>}
                                    name="product_manuals"
                                    rules={[{ required: false, message: 'Please upload manuals.' }]}>
                                    <Upload
                                        className={"upload-lg-btn ant-upload-picture-card-wrapper upload-box-references"}
                                        multiple={true}
                                        fileList={manualFileList}
                                        onChange = {handleChangeManualFiles}
                                        progress= {progress_bar}
                                        {...(Constants.getUploadProps())}
                                        onRemove={file => {
                                            const index = manualFileList.indexOf(file);
                                            const newFileList = manualFileList.slice();
                                            newFileList.splice(index, 1);
                                            setManualFileList(newFileList);
                                            setUploadError('');
                                            return true;
                                        }}
                                        listType={"text"}>
                                            {validateFileUploads(manualFileList) == "uploading"  ?
                                                <div className="justify-in-center">
                                                    <div className="manrope f-12 grey-99 justify-in-center">Uploading...</div>
                                                </div> :
                                            validateFileUploads(manualFileList) == "done" || validateFileUploads(manualFileList) == "not_started" ?
                                            <span>
                                                <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>

                                                <p className="manrope f-12 grey-77">Drop your product sketches and designs, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>

                                            </span>:
                                            validateFileUploads(manualFileList) == "error"  ?
                                            <span>
                                                <div className="justify-in-center">
                                                    <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                    <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                                </div>
                                            </span> :
                                            ''}
                                    </Upload>
                                </Form.Item>
                            </Col>}


                            <Col span={24}>
                                <Collapse style={{marginTop: 20}} defaultActiveKey={['1','2','3','4','5']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                                    {(props.variationType == 'size'
                                    || props.variationType == 'hardware'
                                    || props.variationType == 'other') &&
                                    ((props.product_details && props.model_height !="N/A")
                                    || (props.product_details && props.model_depth !="N/A" )
                                    || (props.product_details && props.model_width !="N/A")) &&
                                    <Panel  header={<span className="manrope f-14 grey-77 w-700">Add Dimensions of your Product Model</span>} key="1">
                                        <Row className="justify-space-between" style={{marginBottom: 0, marginTop: 20, alignItems:'baseline'}}>
                                            <Col span={6} style={{padding:"0 6px"}}>
                                                <Form.Item colon={false} name="height" label={<span className="manrope f-14 black-55 w-600" style={{marginRight: 12}}>Height</span>} rules={[{ required: modelFileList.length > 0 ? false : true, message: 'Please enter height.' }]}>
                                                    <Input className="manrope f-12 black-55 library-search"
                                                    type="number"
                                                    step="0.01"
                                                    min="0.01"
                                                    placeholder="Add your product height"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}  style={{padding:"0 6px"}}>
                                                <Form.Item colon={false} name="width" label={<span className="manrope f-14 black-55 w-600" style={{marginRight: 12}}>Width</span>} rules={[{ required: modelFileList.length > 0 ? false : true, message: 'Please enter width.' }]}>
                                                    <Input className="manrope f-12 black-55 library-search"
                                                    type="number"
                                                    step="0.01"
                                                    min="0.01"
                                                    placeholder={"Add your product width"}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}  style={{padding:"0 6px"}}>
                                                <Form.Item colon={false} name="length" label={<span className="manrope f-14 black-55 w-600" style={{marginRight: 12}}>Depth</span>} rules={[{ required: modelFileList.length > 0 ? false : true, message: 'Please enter depth.' }]}>
                                                    <Input className="manrope f-12 black-55 library-search"
                                                    type="number"
                                                    step="0.01"
                                                    min="0.01"
                                                    placeholder="Add your product depth"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}  style={{padding:"0 6px"}}>
                                                <Form.Item name="dimensions" colon={false} label={<span className="manrope f-14 black-55 w-600">Unit</span>}  rules={[{ required: false, message: 'Please select a unit' }]}>
                                                    <Select className="manrope f-10 black-55 tree-select-material adjust-height-46 f-12" style={{color:"#333333"}} defaultValue={'inches'} buttonStyle="solid">
                                                        <Option className="manrope f-12 select-view" value="inches">Inches</Option>
                                                        <Option className="manrope f-12 select-view" value="meter">Meter</Option>
                                                        <Option className="manrope f-12 select-view" value="millimeter">Millimeter</Option>
                                                        <Option className="manrope f-12 select-view" value="feet">Feet</Option>
                                                        <Option className="manrope f-12 select-view" value="centimeter">Centimeter</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}  style={{marginBottom: 20, padding:"0 6px"}}>
                                                <Form.Item  name="dimensions_text" label={<span className="manrope f-14 black-55 w-600">Additional Dimensions</span>} colon={false}>
                                                    <Input.TextArea rows={3} className="manrope f-12 grey-77 library-search"
                                                        placeholder = "Add any additional dimensions detail that are not already represented above"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>}
                                {(props.variationType == 'hardware'
                                || props.variationType == 'other') &&
                                <Panel  header={<span className="manrope f-14 grey-77 w-700">Add Materials and Swatches of your Product Model</span>} key="2">
                                        <Row  className="justify-align-start" style={{marginBottom: 20, marginTop: 20}}>
                                            {modelType == 1 ?
                                            <Col  span={24} className="justify-space-between align-start" style={{marginTop: 16}}>
                                            <Col span={24}>
                                            <div style={borderStyleEmpty}>
                                                <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-600 justify-in-start"><span className="manrope f-18 red-ff w-600">*</span>&nbsp;<span>Product Model Materials</span></span>} name="materials" rules={[{ required: false, message: 'Please enter materials.',type:'array' }]}>
                                                <TreeSelect
                                                    className="tree-select-material f-12 item-height-32"
                                                    showSearch
                                                    showArrow
                                                    multiple
                                                    allowClear
                                                    treeDefaultExpandAll
                                                    style={{ width: '100%', color:"#333333" }}
                                                    dropdownStyle={categoryStyle}
                                                    placeholder={'Select or type in to search material'}
                                                    treeData={materialsData}
                                                    value={materialValue}
                                                    onChange={(value) => {setMaterialValue(value); console.log(value)}}
                                                    onSearch={(e) => {setMaterialSearchValue(e)}}
                                                    onInputKeyDown={(e) => { addMaterialOption(e)} }
                                                    notFoundContent={<span className='tree-select-material f-12'>No such material found. Press enter to add your material.</span>}>
                                                    </TreeSelect>
                                                </Form.Item>
                                                <Row style={{marginTop: 20,width: "100%",padding: "0 6px"}}>
                                                    <Col span={24}>
                                                        {modelType == 1 && (materialInfo && materialInfo.length > 0) ?
                                                        <div style={{marginTop: 0}} className="manrope f-14 black-55 w-600 justify-in-start">
                                                            <span className="manrope f-18 red-ff w-600">*</span>&nbsp;
                                                            <span>Upload or Select Materials from Material Library for your Model</span>&nbsp;
                                                            <Popover placement="bottom" trigger="hover" className="manrope f-14 black-55 centered-popup"
                                                                content={<img  src={'/img/material_guideline.png'} className="sample-image-popup contain"/>}>
                                                                    <InfoCircleOutlined style={{lineHeight: 0}}/>
                                                            </Popover>
                                                        </div>
                                                        : ''}
                                                        <Radio.Group value={materialSelectionType}  onChange={onChangeMaterialSelectionType} className="model-type-radio justify-align-start" style={{flexWrap:"unset"}}>
                                                            <Radio style={{width: "100%",whiteSpace:"break-spaces"}} value={0}>
                                                                <span className='manrope f-14 black-55'>
                                                                {materialSelectionType == 0 && <span className='manrope f-14 red-ff w-600'>*&nbsp;</span>}

                                                                    Upload Material and Texture Swatches&nbsp;<Tooltip title={<span className='manrope f-12 white'>Make sure all material swatches that you upload here are at least 300 DPI</span>}><InfoCircleOutlined/></Tooltip>
                                                                    <div style={{width: "100%",marginTop: 12}} className={materialPhotosList.length == 0 ? "bedding-card center" : 'bedding-card'}>
                                                                        <Form.Item
                                                                        style={{ display: materialPhotosList && materialPhotosList.length == 1 ? 'content' : 'flex'}}
                                                                        colon={false}
                                                                        label={''}
                                                                        className="picture-card-custom-88"
                                                                        name="material_files"
                                                                        rules={[{ required: false, message: 'You must upload an image.' }]}>
                                                                            <Upload
                                                                                disabled={props.variationType == 'material' && canvasImageUploaded}
                                                                                className={"upload-picture-card-88"}
                                                                                accept=".jpg,.jpeg,.png,.tiff"
                                                                                multiple={true}
                                                                                onChange = {handleChangeMaterialPhotos}
                                                                                fileList = {materialPhotosList}
                                                                                progress= {progress_bar}
                                                                                {...(Constants.getUploadProps())}
                                                                                onRemove={file => {
                                                                                    let url = ENVIRONMENT.getBaseURL(props.product_details.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name);
                                                                                    console.log(file, url)
                                                                                    removeMaterialImages(url);
                                                                                    const index = materialPhotosList.indexOf(file);
                                                                                    const newFileList = materialPhotosList.slice();
                                                                                    newFileList.splice(index, 1);
                                                                                    setMaterialPhotosList(newFileList);
                                                                                    setUploadError('');
                                                                                    return true;
                                                                                }}
                                                                                listType={"picture-card"}>
                                                                                    {Utilities.validateFileUploads(materialPhotosList) == "not_started" ||
                                                                                    Utilities.validateFileUploads(materialPhotosList) == "done" || Utilities.validateFileUploads(props.colorFileList) == "uploading" ?
                                                                                    <div className="manrope f-12 blue w-600">
                                                                                        <UploadOutlined style={{marginBottom: 2}}/>
                                                                                        <div>Upload</div>
                                                                                        <div className="manrope f-12 grey-99 italic">(jpg,png)</div>
                                                                                    </div> :
                                                                                    Utilities.validateFileUploads(materialPhotosList) == "error" ?
                                                                                    <span>
                                                                                        <div className="justify-in-center">
                                                                                            <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                                                            <div className="manrope f-12 red justify-in-center">Upload Failed</div>
                                                                                        </div>
                                                                                    </span> :
                                                                                    ''}
                                                                            </Upload>
                                                                        </Form.Item>
                                                                    </div>
                                                                </span>
                                                            </Radio>
                                                            {(materialInfo && materialInfo.length > 0) ?
                                                            <Radio style={{width: "100%",whiteSpace:"break-spaces"}} value={1}>
                                                                <span className="manrope f-14 black-55" >
                                                                    Select From Material Library

                                                                        <Col span={24} style={{paddingTop: 8}}>

                                                                                {finalSelectedMaterials.length == 0?
                                                                                <div className={`background fafc pointer pd-3 flex-column ${props.variationType == 'material' && canvasImageUploaded ? `not-allowed`: ``}`} onClick={openMaterialModal}>
                                                                                    <img src="/img/material-lib.png" style={{marginBottom: 10}}/>
                                                                                    <div className="manrope f-12 grey-73">Select From Material Library</div>
                                                                                </div>:
                                                                                <>
                                                                                <div className={`background fafc pd-1 flex-row ${props.variationType == 'material' && canvasImageUploaded ? `not-allowed`: ``}`} style={{height: 166, alignItems: 'flex-start', overflowY: 'scroll'}}>
                                                                                    <div style={{display:'block'}}>
                                                                                        {materialInfo && materialInfo.map((material,index) => (
                                                                                            finalSelectedMaterials.includes(material.id) ?
                                                                                            <div>
                                                                                            <div className="justify-in-start" style={{marginBottom: 10}}>
                                                                                                {material.renders  && material.renders.data && material.renders.data.thumbnail ?
                                                                                                <img src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)} className="lib-mat small" style={{marginRight: 10}}/>: 
                                                                                                material.material_files[0] && !material.is_scanned ?
                                                                                                <img src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)} className="lib-mat small" style={{marginRight: 10}}/> : ''
                                                                                                }
                                                                                                <span>
                                                                                                    <div className="manrope f-12 black-00">{material.name}&nbsp;
                                                                                                    <Tooltip title={<span className="manrope f-12 white">Remove Material</span>}>
                                                                                                        <DeleteOutlined  className="manrope f-12 grey-77" style={{position:'relative',zIndex: "5"}} onClick={() => {removeMaterials(material)}}/>
                                                                                                    </Tooltip>
                                                                                                    </div>
                                                                                                    <div className="manrope f-12 grey-77">{material.width}x{material.length} inches</div>
                                                                                                </span>

                                                                                            </div>
                                                                                            {modelRequiredMaterialOutput != material.material_output && modelRequiredMaterialOutput.includes(material.material_output) ?
                                                                                            <div className="manrope f-14 w-600 text-red d-block" >Note: The selected material is missing {findMissingMaterialTypeFromSelection(material.material_output)}</div>: ''}
                                                                                            </div>:''

                                                                                        ))}
                                                                                    </div>
                                                                                    <img src="/img/success-small.png" style={{margin: "auto 0"}}/>
                                                                                </div>
                                                                                <div onClick={openMaterialModal} className="background light-blue pd-1 pointer justify-in-center manrope f-16 blue" style={{marginTop: 16}}>
                                                                                    <PlusOutlined/>
                                                                                    <div className="manrope f-12 blue" style={{marginLeft: 10}}>Add From Material Library</div>
                                                                                </div>
                                                                                </>}

                                                                        </Col>
                                                                </span>
                                                            </Radio>: ''}
                                                        </Radio.Group>

                                                    </Col>
                                                </Row>

                                                </div>
                                            </Col>

                                        </Col>: ''}
                                        </Row>
                                    </Panel>}



                                {(props.variationType == 'material') &&
                                <Panel  header={<span className="manrope f-14 grey-77 w-700">Select Materials from your library or upload</span>} key="2">
                                        <Row  className="justify-align-start" style={{marginBottom: 20, marginTop: 20}}>
                                            {modelType == 1 ?
                                            <Col  span={24} className="justify-space-between align-start" style={{marginTop: 16}}>
                                            <Col span={24}>
                                            <div style={borderStyleEmpty}>
                                                <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-600 justify-in-start"><span className="manrope f-18 red-ff w-600">*</span>&nbsp;<span>Product Model Materials</span></span>} name="materials" rules={[{ required: false, message: 'Please enter materials.',type:'array' }]}>
                                                <TreeSelect
                                                    className="tree-select-material f-12 item-height-32"
                                                    showSearch
                                                    showArrow
                                                    multiple
                                                    allowClear
                                                    treeDefaultExpandAll
                                                    style={{ width: '100%', color:"#333333" }}
                                                    dropdownStyle={categoryStyle}
                                                    placeholder={'Select or type in to search material'}
                                                    treeData={materialsData}
                                                    value={materialValue}
                                                    onChange={(value) => {setMaterialValue(value); console.log(value)}}
                                                    onSearch={(e) => {setMaterialSearchValue(e)}}
                                                    onInputKeyDown={(e) => { addMaterialOption(e)} }
                                                    notFoundContent={<span className='tree-select-material f-12'>No such material found. Press enter to add your material.</span>}>
                                                    </TreeSelect>
                                                </Form.Item>
                                                <Row style={{marginTop: 20,width: "100%",padding: "0 6px"}}>
                                                <Col span={12}>
                                                    {automatedMaterialVariation == false ?
                                                        <>
                                                        <div >
                                                            <Row>
                                                                <Col span={8} style={{"background-color": "#d3d3d3"}}>
                                                                    <div className="adjust-height h700px" style={{textAlign: 'center', padding: 15}}>
                                                                        {uploadMaterialUrls.length == 0 ?
                                                                            <div className='manrope f-14 black-55'>Upload or Select Materials from the options provided to get started</div>
                                                                        :
                                                                        <>
                                                                            <List
                                                                                header={<div className="manrope f-14 black-55">Materials (Click & drag materials to place them on the model)</div>}
                                                                                bordered
                                                                                style = {{"border-color": "cornsilk"}}>
                                                                                <div id= "scroll-style">
                                                                                    <div className={uploadMaterialUrls.length>2 ? "ant-spin-nested-loading adjust-overflow scroller-style":"scroller-style align-center"}>
                                                                                        {uploadMaterialUrls && uploadMaterialUrls.map((mat, index) => (
                                                                                            <Tooltip title={<span className='manrope f-12 white'>{mat.name}</span>}>
                                                                                                <Card className="tagging-img-card"  style={{marginRight: 5, marginBottom: 8}}>
                                                                                                    <img style={{display: "inline-block", cursor: 'pointer'}} className="material-canvas-image" onClick={() => addImageElements(mat.url, mat.name)} src={mat.url}/>
                                                                                                    <div className='manrope f-12 black-55 clamp-text w-50px' >{mat.name}</div>
                                                                                                </Card>
                                                                                            </Tooltip>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </List>
                                                                            <br/>
                                                                            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                                                <Button onClick={deleteGroup} id="delete-text" className='outline-red-btn square adjust-size' >Delete Selected Material</Button>
                                                                            </div>
                                                                        </>}
                                                                    </div>
                                                                </Col>
                                                                <Col span={16}>
                                                                    <Card className="adjust-height h700px" >
                                                                        {canvasImageUploaded ? <LoadingOutlined className='tagging-loader'/> : ''}
                                                                        <div id="canvas-settings" className="adjust-height h700px override-max-width" style={{display: 'block', maxHeight:"none"}}>
                                                                            <canvas height="500" width="949" style={{objectFit:"contain"}} id="tagging-canvas"></canvas>
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            </div>
                                                        </>
                                                    : ''}

                                                    {automatedMaterialVariation == true ?
                                                    <div>
                                                        <div className="manrope f-14 black-55 material-variation-label-instructions">
                                                            <div className="mySlides"><div className="p-10 p-right"><InfoCircleOutlined/></div>Click on any part of the 3d model and select a material from library against it.</div>
                                                            <div className="warning-message no-padding font-adjust">Note: If your target model parts are not visible in 3d model then switch to "Label Custom Parts" mode to create variation</div>
                                                        </div>
                                                    <div id='material-variation-model-viewer'>
                                                        {!modelLoaded ? <LoadingOutlined className='tagging-loader'/> : ''}
                                                        {modelComponents && modelComponents.map((component,index) => (
                                                            <div id={component} className="material-variation-model-viewer-tags" style={{backgroundColor: component == selectedModelComponent ? "#276DD7": '#FFFFFF', color: component == selectedModelComponent ? "#FFFFFF": ''}}  onClick={()=>{
                                                                if (selectedModelComponent == component) {
                                                                    setSelectedModelComponent(null);
                                                                    modelViewer.setSelectedComponent(null)
                                                                }
                                                                else {
                                                                    setSelectedModelComponent(component);
                                                                    modelViewer.setSelectedComponent(component)
                                                                }
                                                            }}>{component}</div>
                                                        ))}
                                                    </div>
                                                    </div>
                                                    : ''}

                                                    </Col>
                                                    <Col span={12}>
                                                        <Radio.Group value={materialSelectionType}  onChange={onChangeMaterialSelectionType} className="model-type-radio" style={{marginBottom: 10,flexWrap:"unset"}}>

                                                            {(materialInfo && materialInfo.length > 0) ?
                                                            <Radio className="adjust-height h500px" style={{width: "100%",whiteSpace:"break-spaces"}} value={1}>
                                                                <span className="manrope f-14 black-55" >
                                                                Select From Material Library
                                                                {materialSelectionType == 1 ?
                                                                <div className="material-variation-category-container">
                                                                <Tooltip title={<span className='manrope f-12 white'>Use this option to create variation if all the target model parts are visible in 3d model.</span>}><div className="material-variation-category-tabs" style={{color:  automatedMaterialVariation == true ? '#FFFFFF': '', backgroundColor:  automatedMaterialVariation == true ? '#276DD7': ''}} onClick={()=>{updateAutomatedMaterialVariation(true)}}><div>View Default Parts</div><div><InfoCircleOutlined/></div></div></Tooltip>
                                                                <Tooltip title={<span className='manrope f-12 white' >Use this option to create variation if any target model parts are not visible in 3d model.</span>}><div className="material-variation-category-tabs" style={{color:  automatedMaterialVariation == false ? '#FFFFFF': '', backgroundColor:  automatedMaterialVariation == false ? '#276DD7': ''}} onClick={()=>{updateAutomatedMaterialVariation(false)}}><div>Label Custom Parts</div><div><InfoCircleOutlined/></div></div></Tooltip>
                                                                </div>: ''}
                                                                <div className="justify-in-start wrap align-start request-silo-scrollable default-height" style={{marginTop: 32}}>
                                                                    {materialInfo && materialInfo.map((material,index) => (
                                                                        ((automatedMaterialVariation && material.material_output.includes(modelRequiredMaterialOutput)) || (!automatedMaterialVariation && (modelRequiredMaterialOutput.includes(material.material_output) || material.material_output.includes(modelRequiredMaterialOutput)))) ?
                                                                        <Card style={{marginRight: 16, marginBottom: 16, padding: 4, cursor: "pointer",pointerEvents: "auto" ,border: ((automatedMaterialVariation == true && modelComponentMaterial[selectedModelComponent] == material.id) || (automatedMaterialVariation == false && selectedMaterial.includes(material.id))) ? "1px solid #276DD7" : "1px solid #e4e4e4" }}
                                                                            onClick={
                                                                                ()=> {
                                                                                    if (automatedMaterialVariation == false || (automatedMaterialVariation == true && selectedModelComponent != null)) {
                                                                                        if (selectedMaterial.includes(material.id)) {
                                                                                            removeMaterials(material)
                                                                                        }
                                                                                        else {
                                                                                            addMaterial(material)
                                                                                        }
                                                                                    }

                                                                                }
                                                                            }
                                                                            className='material-lib-card'>
                                                                            {material && material.status == 5 && material.renders  && material.renders.data && material.renders.data.thumbnail ?
                                                                            <img src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)} className="lib-mat"/>:
                                                                            material.material_files[0] && material.material_files[0].uid ?<img className="lib-mat" src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)}/>: ''}                            
                                                                            {(automatedMaterialVariation == false && selectedMaterial.includes(material.id)) || (automatedMaterialVariation == true && modelComponentMaterial[selectedModelComponent]) == material.id ?
                                                                            <img className="success-img" src="/img/success.png"/> : ''}
                                                                            <Tooltip title={<span className='manrope f-12 white'>{material.name}</span>}>
                                                                            <div className="manrope f-12 black-00 clamp-text clamp-w-width justify-in-start" style={{paddingTop: 8}}>{material.name}</div>
                                                                            </Tooltip>
                                                                            <div className="manrope f-12 grey-77 justify-in-start" style={{paddingTop: 4}}>{material.width}x{material.length} inches</div>
                                                                        </Card> : ''
                                                                    ))}
                                                                </div>
                                                            </span>
                                                            </Radio>: ''}
                                                            <Radio className="adjust-height h200px" style={{width: "100%",whiteSpace:"break-spaces"}} value={0}>
                                                                <span className='manrope f-14 black-55'>
                                                                    Upload Material and Texture Swatches&nbsp;<Tooltip title={<span className='manrope f-12 white'>Make sure all material swatches that you upload here are at least 300 DPI</span>}><InfoCircleOutlined/></Tooltip>
                                                                    <div style={{width: "100%", marginTop:32}} className={materialPhotosList.length == 0 ? "bedding-card center" : 'bedding-card'}>
                                                                        <Form.Item
                                                                        style={{ display: materialPhotosList && materialPhotosList.length == 1 ? 'content' : 'flex'}}
                                                                        colon={false}
                                                                        label={''}
                                                                        className="picture-card-custom-88"
                                                                        name="material_files"
                                                                        rules={[{ required: false, message: 'You must upload an image.' }]}>
                                                                            <Upload
                                                                                disabled={props.variationType == 'material' && canvasImageUploaded}
                                                                                className={"upload-picture-card-88"}
                                                                                accept=".jpg,.jpeg,.png,.tiff"
                                                                                multiple={true}
                                                                                onChange = {handleChangeMaterialPhotos}
                                                                                fileList = {materialPhotosList}
                                                                                progress= {progress_bar}
                                                                                {...(Constants.getUploadProps())}
                                                                                onRemove={file => {
                                                                                    let url = ENVIRONMENT.getBaseURL(props.product_details.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file.uid + '/' + file.name);
                                                                                    console.log(file, url)
                                                                                    removeMaterialImages(url);
                                                                                    const index = materialPhotosList.indexOf(file);
                                                                                    const newFileList = materialPhotosList.slice();
                                                                                    newFileList.splice(index, 1);
                                                                                    setMaterialPhotosList(newFileList);
                                                                                    setUploadError('');
                                                                                    return true;
                                                                                }}
                                                                                listType={"picture-card"}>
                                                                                    {Utilities.validateFileUploads(materialPhotosList) == "not_started" ||
                                                                                    Utilities.validateFileUploads(materialPhotosList) == "done" || Utilities.validateFileUploads(props.colorFileList) == "uploading" ?
                                                                                    <div className="manrope f-12 blue w-600">
                                                                                        <UploadOutlined style={{marginBottom: 2}}/>
                                                                                        <div>Upload</div>
                                                                                        <div className="manrope f-12 grey-99 italic">(jpg,png)</div>
                                                                                    </div> :
                                                                                    Utilities.validateFileUploads(materialPhotosList) == "error" ?
                                                                                    <span>
                                                                                        <div className="justify-in-center">
                                                                                            <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                                                            <div className="manrope f-12 red justify-in-center">Upload Failed</div>
                                                                                        </div>
                                                                                    </span> :
                                                                                    ''}
                                                                            </Upload>
                                                                        </Form.Item>
                                                                    </div>
                                                                </span>
                                                            </Radio>
                                                        </Radio.Group>

                                                    </Col>
                                                </Row>

                                                </div>
                                            </Col>

                                        </Col>: ''}
                                        </Row>
                                    </Panel>}




                                    {(props.variationType == 'state'
                                    ) &&
                                    <Panel  header={<span className="manrope f-14 grey-77 w-700">Label the Changes on the Image </span>} key="3">
                                        <Row  className="justify-align-start" style={{marginBottom: 20, marginTop: 20}}>

                                                <Col span={24} className="upload-pd" style={{marginBottom: 20,cursor:"default"}}>
                                                    <Form.Item
                                                        colon={false}
                                                        label={<span className="manrope f-14 black-55 w-600"><span className="manrope f-18 red-ff w-600">*</span>&nbsp;Upload a reference image which represents the state you want your model to be in</span>}
                                                        name="product_manuals"
                                                        rules={[{ required: false, message: 'Please upload manuals.' }]}>
                                                        <Upload
                                                            className={"upload-lg-btn ant-upload-picture-card-wrapper upload-box-references"}
                                                            multiple={true}
                                                            accept=".jpg,.jpeg,.png"
                                                            fileList={manualFileList}
                                                            onChange = {handleChangeManualFiles}
                                                            progress= {progress_bar}
                                                            {...(Constants.getUploadProps())}
                                                            onRemove={file => {
                                                                const index = manualFileList.indexOf(file);
                                                                const newFileList = manualFileList.slice();
                                                                newFileList.splice(index, 1);
                                                                setManualFileList(newFileList);
                                                                setUploadError('');
                                                                return true;
                                                            }}
                                                            listType={"text"}>
                                                                {validateFileUploads(manualFileList) == "uploading"  ?
                                                                    <div className="justify-in-center">
                                                                        <div className="manrope f-12 grey-99 justify-in-center">Uploading...</div>
                                                                    </div> :
                                                                validateFileUploads(manualFileList) == "done" || validateFileUploads(manualFileList) == "not_started" ?
                                                                <span>
                                                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>

                                                                    <p className="manrope f-12 grey-77">Drop your reference image or <span style={{color: "#276DD7"}}>Browse it</span>.</p>

                                                                </span>:
                                                                validateFileUploads(manualFileList) == "error"  ?
                                                                <span>
                                                                    <div className="justify-in-center">
                                                                        <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                                                        <div className="manrope f-12 red justify-in-center">Upload Failed</div>

                                                                    </div>
                                                                </span> :
                                                                ''}
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>

                                            <>
                                            <Col span={24} className="justify-in-start manrope f-14 black-55 w-600" style={{marginBottom: 0,marginTop: 20}}>Annotate the image below with the changes you want us to make</Col>

                                            <Row>
                                                <Col span={6} style={{height: 500, "padding-top": 24, "background-color": "#d3d3d3"}}>
                                                    <div style={{textAlign: 'center', padding: 15}}>
                                                        <>
                                                            <List
                                                                header={<div className="manrope f-14 black-55">Annotate by adding text and dragging it on the image</div>}
                                                                bordered
                                                                style = {{"border-color": "cornsilk"}}>
                                                                <div id= "scroll-style">

                                                                </div>
                                                            </List>
                                                            <br/>
                                                            <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                                <Input style={{marginBottom: 10, padding: 14}}  className="manrope f-12 black-55" onPressEnter={(e) => setText(e.target.value, false)} value={textField} placeholder="Enter Text" onChange={ (e) => insertText(e.target.value)} />
                                                                <Button  style={{marginBottom: 10}} onClick={() => setText(textField, false)} className='modal-okay square font-12' type="button">Add Text</Button>
                                                                <Button onClick={deleteTextField} id="delete-text" className='outline-red-btn square font-12'>Delete Selected Text</Button>
                                                            </div>
                                                        </>
                                                    </div>
                                                </Col>
                                                <Col span={18}>
                                                    <Card style={{marginBottom: 20}}>
                                                        {canvasImageUploaded ? <LoadingOutlined className='tagging-loader'/> : ''}
                                                        <div id="canvas-settings" style={{display: 'block'}}>
                                                            <canvas height="500" width="949" style={{objectFit:"contain"}} id="tagging-canvas"></canvas>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            </>
                                        </Row>
                                    </Panel>
                                    }
                                    {(props.variationType == 'color'
                                    || props.variationType == 'hardware'
                                    || props.variationType == 'other') &&
                                    <Panel  header={<span className="manrope f-14 grey-77 w-700">Add Colors of your Product Model</span>} key="3">
                                        <Row className="justify-space-between" style={{marginBottom: 20, marginTop: 20}}>
                                            <Col span={12}  style={{padding:"0 6px"}}>
                                                <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-600">Product Color</span>} name="color_name" rules={[{ required: false, message: 'Please enter product color.' }]}>
                                                    <Input placeholder={'Enter Product Color'} className="manrope f-12 black-55 library-search"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}   style={{padding:"0 6px"}}>
                                                <Form.Item colon={false} label={<span className="manrope f-14 black-55 w-600">Closest Color Match</span>} name="similar_color" rules={[{ required: true, message: 'Please enter the closest color match of your product.' }]}>
                                                    <ColorSelect  className="tree-select-material adjust-height-46 f-12"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    <ColorSelection
                                    mandatory={props.variationType == "color" ? true : false}
                                    changePantoneColor={changePantoneColor}
                                    pantoneColor={pantoneColor}
                                    changeHexColor={changeHexColor}
                                    hexColor={hexColor}
                                    handleChangeColorSelection={handleChangeColorSelection}
                                    colorSelectionType={colorSelectionType}
                                    onChangeColorSelectionType={onChangeColorSelectionType}
                                    colorFileList={colorFileList}
                                    setColorFileList={setColorFileList}
                                    platform={props.product_details.platform}
                                    />
                                    </Panel>}
                                </Collapse>
                            </Col>

                            {uploadError == "" ? '' :
                                <Col span={24} style={{marginTop: 10,marginBottom: 20}}>
                                    <div className="justify-space-between err-bg manrope f-12 red-error">
                                        <img src="/img/error-small.png" style={{marginRight: 8}}/>
                                        <span>{uploadError}</span>
                                    </div>
                                </Col>}

                            {errorMessages && errorMessages.length == 0 ? '' :
                            <Col span={24} style={{marginBottom: 10}}>
                                {errorMessages && errorMessages.map((err) => (
                                    <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginTop: 10}}>
                                        <img src="/img/error-small.png" style={{marginRight: 8}}/>
                                        <span>{err}</span>
                                    </div>
                                ))}
                            </Col>}
                            {priceError == "" ? '' :
                            <Col span={24} style={{marginTop: 10,marginBottom: 20}}>
                                <div className="justify-space-between err-bg manrope f-12 red-error">
                                    <img src="/img/error-small.png" style={{marginRight: 8}}/>
                                    <span>{priceError}</span>
                                </div>
                            </Col>}

                    </Row>
                </div>
                {props.page_format && <div style={{height: 100}}></div>}
                <Row className={props.page_format ?  "fix-buttons" : "stick-buttons"}>
                    <Col span={24} className="justify-space-between">
                        <div className='manrope f-16'>
                            <Button style={{marginBottom: 10,marginRight: 10}} onClick={goBack} className="modal-okay-gray square font-14" disabled={submittingCreateRequest || submittingSaveRequest}>
                                {props.page_format ? 'Cancel' : 'Back'}
                            </Button>
                        </div>
                        <div className="justify-in-end">
                            <Button style={{marginBottom: 10,marginRight: 10}} className="modal-okay-blue square font-14" disabled={submittingSaveRequest || submittingCreateRequest} onClick={handleSave}>
                                Save Progress&nbsp;{submittingSaveRequest == true ? <LoadingOutlined type="sync" spin />: ''}
                            </Button>

                            <Form.Item>
                                <Button className="modal-okay square font-14" type="primary" htmlType='submit' disabled={submittingCreateRequest || submittingSaveRequest}>
                                    Submit&nbsp;{submittingCreateRequest == true ? <LoadingOutlined type="sync" spin />: ''}
                                </Button>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </div>
        </Form>

            <Modal visible={materialModal}
                onCancel={cancelMaterialModal}
                closable={true}
                maskClosable={true}
                destroyOnClose={true}
                className="aspect-ratio-modal-closable"
                footer={[
                    <span className="justify-in-end">
                      <Button disabled={selectedMaterial.length == 0} className="modal-okay font-14 square" htmlType="submit" onClick={addMaterials}>
                        Done
                      </Button>
                    </span>
                  ]}
                >
            <div style={{padding:"32px 32px 24px 32px"}}>
                <div className="manrope f-16 black-55 w-600" style={{marginBottom: 8,textTransform:"capitalize"}}>
                Select Material from Material Library
                <Row className="justify-space-between"  style={{marginTop:"20px",marginBottom: 20}}>
                    <Col>
                        <div className='manrope f-24 w-700' style={{display:'flex',alignItems:"flex-end"}}><span>Material Library</span>{materialInfo ? materialInfo.length > 0 ?  <span  style={{marginLeft: 4,paddingBottom: 2}} className="manrope f-20">({materialInfo.length} items)</span>: '': ''}</div>
                    </Col>
                    <Col lg={10} xl={10}>
                       {materialInfo && materialInfo.length > 0 ?
                       <Input.Group compact size="large" className="d-inline">
                            <Input placeholder="Search All Materials" onChange={e => {changeSearchValue(e)}} className="manrope f-14 mat-search-bar"></Input>
                        </Input.Group>: ''}
                    </Col>
                </Row>
                </div>
                 {materialInfo && materialInfo.length == 0 ?
                <div className="note-bg-artist red" style={{marginTop: 32, width: 'fit-content'}}><span className="manrope f-14 red">Oops. Looks like you don't have any materials in your material library. You can request new materials through this <a target="_blank" href="/material-request" className="manrope f-14 red underline">link</a>.</span></div>
                :
                <Col span={24} className="justify-in-start wrap request-silo-scrollable" style={{marginTop: 32}}>
                    {materialInfo && materialInfo.map((material,index) => (
                        ((modelRequiredMaterialOutput.includes(material.material_output) || material.material_output.includes(modelRequiredMaterialOutput))
                        && (searchValue == '' || (Utilities.isMatching(searchValue.toLowerCase(), material.category.toLowerCase())
                        || Utilities.isMatching(searchValue.toLowerCase(), material.name.toLowerCase())))) ?
                        <Card style={{marginRight: 16, marginBottom: 16, padding: 4, cursor: "pointer",pointerEvents: "auto" ,border: (selectedMaterial.includes(material.id)) ? "1px solid #276DD7" : "1px solid #e4e4e4" }} onClick={() => selectMaterialImages(material)} className='material-lib-card'>
                            {material && material.status == 5 && material.renders  && material.renders.data && material.renders.data.thumbnail ?
                            <img src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)} className="lib-mat"/>:
                            material.material_files[0] && material.material_files[0].uid ?<img className="lib-mat" src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)}/>: ''}                            
                            {selectedMaterial.includes(material.id) ?
                            <img className="success-img" src="/img/success.png"/> : ''}
                            <Tooltip title={<span className='manrope f-12 white'>{material.name}</span>}>
                            <div className="manrope f-12 black-00 clamp-text w-50 justify-in-start" style={{paddingTop: 8}}>{material.name}</div>
                            </Tooltip>
                            <div className="manrope f-12 grey-77 justify-in-start" style={{paddingTop: 4}}>{material.width}x{material.length} inches</div>
                        </Card> : ''
                    ))}
                </Col>}
            </div>
        </Modal>

        <PaymentFailureModal
        paymentFailureMessage={paymentFailureMessage}
        setPaymentFailureModal={setPaymentFailureModal}
        updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
        paymentFailureModal={paymentFailureModal}
        />

        <InAppPurchaseLimitModal visible={inAppLimitExceededModal} setVisible={setInAppLimitExceededModal} />

        <PaymentConfirmationModal
        name={productName}
        visible={paymentConfirmationModal}
        amount={"$" + requestPrice}
        onCancel={cancelPaymentConfirmation}
        text={"Please pay the amount below to proceed. It can take 24 to 72+ hours for completion of the model based on complexity"}
        footer={[
        <span style={{display: 'flex', justifyContent:'center'}}>
            <Button disabled={submittingCreateRequest} className="modal-okay-gray font-14 square" onClick={cancelPaymentConfirmation}>
            Cancel
            </Button>
            <Button disabled={submittingCreateRequest || cardSaveFlowTriggered} className="modal-okay font-14 square" onClick={initiateConfirmation}>
            {cardStatus == 1 && requestStatus=="payment_required" ? "Confirm & Process Payment" :
                cardStatus == 0 && requestStatus=="payment_required" ?"Confirm & Enter Payment Details" : "Confirm"} {submittingCreateRequest == true ? <LoadingOutlined type="sync" spin />: ''}
            </Button>
        </span>
        ]}/>

        <WarningModal
        visible={cardSaveFailure}
        onCancel={() => setCardSaveFailure(false)}
        heading={"Unable to Save Payment Details"}
        text={"Failed to process payment details. Please try another payment method."}
        footer={[
        <div className="justify-in-end">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => setCardSaveFailure(false)}>
                Okay
            </Button>
        </div>
        ]}/>


    </div>
}



const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(fetchProduct(product));
    },
    createProduct: (product) => {
        dispatch(createProduct(product));
    },
    updateProduct: (product) => {
        dispatch(updateProduct(product));
    },
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(ModelVariationRequest)
)