import React from "react";
import { Row, Col, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

function TestScoreDetails({
    geometry_accuracy,
    uv_accuracy,
    color_accuracy,
    dimensional_accuracy,
    vray_accuracy,
    pbr_accuracy,
    additional_notes
}) {
  const totalScore=10;
  return (
    <>
      <div className="details-container">
        <Row gutter={20}>
          <Col span={12} className="gutter-row">
            <span className="product-txt-heading">Artist Test Score</span>
            <table
              className="stats-table dimension-table mb-20"
              style={{ marginTop: 12, width: "60%" }}
            >
              <tr>
                <th></th>
                <th>Obtained</th>
                <th>Total</th>
              </tr>
              <tr>
                <td style={{ fontSize: 16 }}>Geometry Accuracy</td>
                <td> {geometry_accuracy}</td>
                <td>{totalScore}</td>
              </tr>
              <tr>
                <td style={{ fontSize: 16 }}>Dimensional Accuracy</td>
                <td> {dimensional_accuracy}</td>
                <td>{totalScore}</td>
              </tr>
              <tr>
                <td style={{ fontSize: 16 }}>UV Quality</td>
                <td> {uv_accuracy}</td>
                <td>{totalScore}</td>
              </tr>
              <tr>
                <td style={{ fontSize: 16 }}>Color Accuracy</td>
                <td> {color_accuracy}</td>
                <td>{totalScore}</td>
              </tr>
              <tr>
                <td style={{ fontSize: 16 }}>PBR Accuracy</td>
                <td> {pbr_accuracy}</td>
                <td>{totalScore}</td>
              </tr>
              <tr>
                <td style={{ fontSize: 16 }}>VRAY Accuracy</td>
                <td> {vray_accuracy}</td>
                <td>{totalScore}</td>
              </tr>
            </table>
          </Col>
          <Col span={12} className="gutter-row">
            {additional_notes ? (
              <>
                <span className="product-txt-heading">
                  Additional Notes &nbsp;
                  <Popover
                    placement="topLeft"
                    content="These notes will be visible to the artist."
                  >
                    <InfoCircleOutlined style={{ color: "#276DD7" }} />
                  </Popover>
                </span>
                <div className="mb-20" style={{ marginTop: 12 }}>
                  <div className="tags-container manrope w-500 f-14 text-grey">
                    {additional_notes}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default TestScoreDetails;
