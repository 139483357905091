import React from "react";
import AdminMainLayout from "../AdminMainLayout";
import { Row, Col } from "antd";

const NotificationManagement = () => {
  return (
    <>
      <AdminMainLayout selected={"19"} color={true}>
        <Row style={{ marginTop: 30 }} gutter={24}>
          <Col>
            <p className="analytics-txt-heading">
                Notification Management
            </p>
          </Col>
        </Row>
        <div className="manrope f-32 black-14">Coming Soon</div>
      </AdminMainLayout>
    </>
  );
};

export default NotificationManagement;