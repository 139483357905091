import React from 'react';
import { Drawer } from 'antd';
import SaveCardFlow from '../CustomerComponents/SaveCardFlow';
 
const CardSaveDrawer = (props) => {

    return (
        <Drawer
        className="silo-success-modal"
        placement="right"
        closable={true}
        maskClosable={false}
        width={"50%"}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{padding: 0}}
        centered={true}
        footer={props.footer}>
        <div style={{padding:"40px"}}>
            <h1 className="manrope f-24 black-14 w-600" style={{marginBottom: 30}}>Card Information</h1>
            <SaveCardFlow setCardSaveFailure={props.setCardSaveFailure} saveRequestIntermedietely={props.saveRequestIntermedietely}/>
        </div>       
    </Drawer>
      );
}

export default CardSaveDrawer;