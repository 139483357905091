import React, { useEffect, useMemo, useState } from "react";
import { Tooltip, Row, Col, Card, Menu, Dropdown,Button} from "antd";
import "./SpaceComponents.scss";
import ENVIRONMENT from "../../../../environments";
import ThreeSixtyViewer from "../../../../ThreeSixtyViewer";
import DottedLoader from "../../DottedLoader";
import { withRouter } from "react-router-dom";

import { ArrowLeftOutlined, ArrowRightOutlined, DownOutlined } from "@ant-design/icons";


const SpaceThreeSixtyViews = ({
  setDisplay360,
  three_sixties,
  setFullScreen,
  isFullScreen,
  platform,
  match,
  selected360,
  storePage,
  status,
  downloadSceneImagesArchive
}) => {
  const [threeSixtyViews, setThreeSixtyViews] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  const threeSixtyRendersList = useMemo(
    () => (three_sixties.length > 0 ? three_sixties : ["360Camera.jpg"]),
    [three_sixties]
  );

  const threeSixtiesCount= useMemo(()=> threeSixtyRendersList.length ,[threeSixtyRendersList])

  useEffect(() => {
    if (selected360) setSelectedImage(selected360);
    else setSelectedImage(threeSixtyRendersList[0]);
  }, [threeSixtyRendersList, selected360]);

  const changeSelectedImage = (name) => {

    if (threeSixtyRendersList) {
      threeSixtyRendersList.map((x, index) => {
        if (threeSixtyViews[x.split(".")[0]] != null && x != name) {
          threeSixtyViews[x.split(".")[0]].changeDisplay("none");
        }
      });
    }

    const slicedName= name?.split(".")[0];

    if (threeSixtyViews[slicedName] == null) {
      setTimeout(() => {
        let temp_arr = threeSixtyViews;
        temp_arr[slicedName] = new ThreeSixtyViewer(
          `modal-${slicedName}`,
          `modal-loading-container-${slicedName}`,
          ENVIRONMENT.getBaseURL(platform) +
            ENVIRONMENT.ROOM_PANORAMA_ASSET_URI +
            match.params.id +
            "/" +
            name,
          false
        );
        setThreeSixtyViews(temp_arr);
      }, 1000);
    } else if (threeSixtyViews[slicedName]) {
      threeSixtyViews[slicedName].changeDisplay("block");
    }
  };

  useEffect(()=>{
    if(selectedImage)
    changeSelectedImage(selectedImage);
  },[selectedImage])



  const next = () => {
    let image_index = threeSixtyRendersList.findIndex(
      (img) => img == selectedImage
    );

    if (image_index + 1 == threeSixtiesCount) {
      setSelectedImage(threeSixtyRendersList[0]);
    } else {
      setSelectedImage(threeSixtyRendersList[image_index + 1]);
    }
  };

  const prev = () => {
    let image_index = threeSixtyRendersList.findIndex(
      (img) => img === selectedImage
    );

    //if user moves back from step from carousel then close the carousel
    if (image_index - 1 < 0) {
      setSelectedImage(threeSixtyRendersList[threeSixtiesCount - 1]);
    } else {
      setSelectedImage(threeSixtyRendersList[image_index - 1]);
    }
  };


  const imageNumber = useMemo(()=>{
    if (selectedImage) {
      let image_index = threeSixtyRendersList.findIndex(
        (img) => {  
          return img === selectedImage}
      );
      return image_index + 1;
    }
    return 1;
  },[threeSixtyRendersList,selectedImage])

  const menu = (
    <Menu style={{ width: "148px" }}>
      <Menu.Item key={0}>
        <a
          href={`${ENVIRONMENT.getBaseURL(platform)}${ENVIRONMENT.ROOM_PANORAMA_ASSET_URI}${match.params.id}/${selectedImage}`}
          className="manrope f-14 black-55"
          target="_blank"
        >
          This 360 only
        </a>
      </Menu.Item>
      <Menu.Item key={1}>
        <div
          className="manrope f-14 black-55"
          onClick={() =>downloadSceneImagesArchive()}
        > 
          All 360s
        </div>
      </Menu.Item>
    </Menu>
  );


  return (
    <Row className="h-full">
      <Col
        md={7}
        lg={7}
        xl={4}
        className="display-flex j-s a-s wrap sa-s gg-16 custom-scroll product-grid-list h-full"
      >
        {threeSixtyRendersList.map((name, index) => (
          <Tooltip title={name?.split(".")[0]} placement="top">
            <Card
              className={`pdp-card summary-img-card ${
                selectedImage === name ? "selected" : ""
              }`}
              bodyStyle={{
                padding: "2px",
                minWidth: 40,
                textAlign: "-webkit-center",
              }}
              style={{ marginRight: "10px" }}
            >
              <div
                className="product-view-icon"
                onClick={() => {
                  setSelectedImage(name);
                  setDisplay360(true);
                }}
              >
                <span
                  style={{
                    background: "url(/img/360-tn.jpg)",
                    objectFit: "contain",
                    height: "40px",
                    width: "40px",
                  }}
                >
                  <b style={{ color: "white" }}></b>
                </span>
              </div>
            </Card>
          </Tooltip>
        ))}
      </Col>
      <Col md={17} lg={17} xl={20} className="w-100 h-full">
      {storePage != 1 && status !== "pending" && (
          <div className="space-download-container">
            <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="space-download-button"
          >
            <Button className="product-btn dark-blue f-14 br-4">
              Download <DownOutlined />
            </Button>
          </Dropdown>
          </div>
          
        )}
      <div style={{margin: "auto"}} className="h-full w-full">
      <div className="carousel-btns right-btn" onClick={next}>
          <ArrowRightOutlined className="arrow" />
        </div>
              <Card
                className={
                  isFullScreen
                    ? "room-full-screen children-full"
                    : "space-room-viewer-normal children-full"
                }
                style={{ overflowY: "hidden" }}
                bodyStyle={{
                  padding: isFullScreen ? 0 : 8,
                  overflow: "hidden",
                }}
              >
                <div
                  id={selectedImage?`modal-${selectedImage.split('.')[0]}`: 'loading'}
                  style={{
                    cursor: "grabbing",
                    background: "white",
                    height: "100%",
                    // display: name === selectedImage ? "block" : "none",
                  }}
                  className="children-full"
                />
                <div id={selectedImage ? `modal-loading-container-${selectedImage.split('.')[0]}`: 'loading-1'}>
                  <DottedLoader id={selectedImage ? `loadingImage-${selectedImage.split('.')[0]}`: 'loading-2'} />
                </div>
                <Tooltip
                  title={isFullScreen ? "Exit Full Screen" : "Full Screen"}
                  placement="left"
                >
                  <img
                    src={
                      isFullScreen
                        ? "/img/fullScreenIcon-2.svg"
                        : "/img/fullScreenIcon.jpg"
                    }
                    alt="Fullscreen Icon"
                    style={{
                      position: "absolute",
                      top: "20px",
                      zIndex: "8",
                      cursor: "pointer",
                      opacity: "0.8",
                      borderRadius: "4px",
                      right: "20px",
                    }}
                    className="product-full-screen-icon"
                    onClick={() => setFullScreen(!isFullScreen)}
                    type={isFullScreen ? "fullscreen-exit" : "fullscreen"}
                  />
                </Tooltip>
        </Card> 
        <div className="carousel-btns left-btn" onClick={prev}>
          <ArrowLeftOutlined className="arrow" />
        </div>
        <div className="count-tag">
          <div className="manrope f-14 white lh-28">
            {imageNumber} of {threeSixtiesCount}
          </div>
        </div>
      </div>
      
      </Col>
    </Row>
  );
};

export default withRouter(SpaceThreeSixtyViews);
