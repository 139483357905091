import React from "react";
import ArtistMainLayout from '../ArtistMainLayout';
import InvoicingTimeLog from '../InvoicingTimeLog';

class ArtistsInvoices extends React.Component {

  render() {
    return (
      <ArtistMainLayout selected={"4"}>
         <InvoicingTimeLog />
      </ArtistMainLayout>
    );
  }
}

export default ArtistsInvoices;