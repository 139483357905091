import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import { LoadingOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Row, Button, message, Input, Tooltip } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader';
import FileConstants from '../../../../FileConstants'

const COMPANY_ID = FileConstants.COMPANY_ID;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;

const NewProject = (props) => {
    const [visible, setVisible] = useState(false);
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue, setFieldsValue, resetFields } = props.form;
    const [loader, setLoader] = useState(true);
    const [continueButtonloader, setContinueButtonloader] = useState(false);
    const [projectNames, setProjectNames] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        setVisible(props.visible);
        if (props.visible === false) {
            resetFields();
        }
    }, [props.visible])

    useEffect(() => {
        getProjectNames();
    }, []);

    const getProjectNames = () => {
        let payload = {"order_by":"id desc","required_fields":["id", "name"]}
        let customer_username_value = localStorage.getItem("username");
        let filter_string = `(customer_username__exact='${customer_username_value}')`
        payload.filter_string = filter_string
        axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload)
        .then((response) => {
            let names = []
            response.data.length > 0 && response.data.map((project => {
                names.push(project.name);
            }))
            setProjectNames(names);
            setLoader(false);
        });
    }

    const resetSelectBox = () => {
        setFieldsValue({
            project_name: getFieldValue("project_name"),
            project_id: null
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setContinueButtonloader(true);
                let payload = {};
                payload.username = localStorage.getItem("username");

                if (values.project_name) {
                    payload.name = values.project_name;
                }
                //adding company id in payload for company users
                let company_id = localStorage.getItem("company_id");
                if (company_id != undefined) {
                    payload.company_id = company_id
                }

                payload.status = 'in_progress'
                setError('');
                axios.post(ENVIRONMENT.CREATE_PROJECT, payload)
                    .then((response) => {
                        let project_id = response.data["body"]["project_id"]
                        props.setProjectId(project_id);
                        props.setStep(2);
                        props.setProjectName(values.project_name);
                        message.success('Project Added!');
                    })
                    .catch((error) => {
                        setContinueButtonloader(false);
                    });
            }
            else {
                setContinueButtonloader(false);
            }
        });
    }

    const handleCancel = (e) => {
        if(props.redirectionPath){
            props.history.push({
                pathname: props.redirectionPath
            });  
        } else {
            props.history.push({
                pathname: '/home'
            }); 
        }
    }

    return (
        <div>
            {
                loader ?
                <DottedLoader/>
                :
            <React.Fragment>
            <div>
                <Form className="input-form" >
                    <Form.Item name="project_name" label={<span className='manrope f-14 black-00 w-600'>Project Name</span>} colon={false}>
                        {getFieldDecorator('project_name', {
                            rules: [
                                { required: true, message: 'Please input project name' },
                                {
                                    validator: async(_, value) => {
                                    if(value){
                                        if (projectNames.includes(value)){
                                        throw new Error('Please enter a different name as a project with this name already exists in your library.');
                                      }
                                  }
                                  }
                                }
                            ],
                        })(
                            <Input className="manrope f-10 black-55"
                                placeholder="Please type your project name"
                                onChange={() => resetSelectBox()}
                            />
                        )}
                    </Form.Item>
                </Form>
                <Row type="flex" style={ projectNames.length == 0?{ marginTop: 20, justifyContent: "flex-end" } : { marginTop: 20, justifyContent: "flex-end"}}>
                    <div style={{display:'flex'}}>
                    <Button className="outline-red-btn square font-12" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className="modal-okay-green square font-12" disabled={continueButtonloader} style={{ marginLeft: 10 }} onClick={handleSubmit}>
                        Continue {continueButtonloader ? <LoadingOutlined/> : ""}
                    </Button>
                    </div>
                </Row>
                {error != '' ? <Row style={{marginTop: 10}}>
                    <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8,marginBottom: 8}}><img src="/img/error-small.png" style={{marginRight: 8,marginBottom:8}}/><span>{error}</span></div>
                </Row>: ''}
            </div>
            </React.Fragment>
        }
        </div>
    );
}

const mapStateToProps = state => state;

export default withRouter(connect(
    mapStateToProps,
)(Form.create({ name: 'add-project' })(NewProject))
)
