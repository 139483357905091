import React, { useEffect, useMemo, useState } from "react";

import { Row, Button } from "antd";

import ENVIRONMENT from "../../../../environments";
import { withRouter } from "react-router-dom";
import AssetPreviewCard from "./AssetPreviewCard";
import { ArrowRightOutlined } from "@ant-design/icons";

const base_uri = ENVIRONMENT.BASE_URI + "space_test_renders/";
const AssetPreviewGrid = ({
  three_sixties,
  top_view,
  test_renders,
  platform,
  match,
  setDisplayDetailType,
  setSelected360,
  setSelectedImageUrl,
  roomDetails,
  allImagesCount,
  setFullScreen,
}) => {
  const [threeSixtyViews, setThreeSixtyViews] = useState([]);

  const threeSixties = useMemo(() => {
    const arr = three_sixties.length > 0 ? three_sixties : ["360Camera.jpg"];
    const finalResult = arr.map((obj) => ({
      id: obj.split(".")[0],
      image: obj,
      url: `${ENVIRONMENT.getBaseURL(platform)}${
        ENVIRONMENT.ROOM_PANORAMA_ASSET_URI
      }${match.params.id}/${obj}`,
      type: "360",
    }));

    return finalResult;
  }, [three_sixties]);


  const testRendersData = useMemo(
    () =>
      test_renders.map((obj) => {
        const user_type =
          obj.generated_by === "Customer"
            ? "Cust."
            : obj.generated_by
            ? obj.generated_by
            : "Artist";

        const scene_id =
          user_type == "Cust."
            ? roomDetails.customer_dummy_scene_id
            : roomDetails.dummy_scene_id;

        let url =
          ENVIRONMENT.getBaseURL(obj.platform) +
          base_uri +
          obj.uid +
          "/" +
          obj.name;
        if (obj.status == "rendered") {
          url =
            ENVIRONMENT.getBaseURL(obj.platform) +
            ENVIRONMENT.SCENE_THUMBNAIL_URI +
            scene_id +
            "/" +
            obj.filename;
        } else if (obj.status == "pending") {
          url =
            ENVIRONMENT.getBaseURL(obj.platform) +
            ENVIRONMENT.SCENE_THUMBNAIL_URI +
            scene_id +
            "/" +
            obj.preview_render.replace("tiff", "jpg");
        }
        return {
          url: url,
          image: obj,
          id: obj.uid,
          type: "testRender",
        };
      }),
    [test_renders]
  );

  const topviewRenderData = useMemo(
    () =>
      top_view
        ? {
            url: `${ENVIRONMENT.getBaseURL(platform)}${
              ENVIRONMENT.SPACE_TOP_VIEW_RENDER_URI
            }${top_view}`,
          }
        : null,
    [top_view]
  );

  const displayModelList = useMemo(() => {
    const arr = [null, null, null];

    if (threeSixties.length > 0)
      threeSixties.forEach((obj, index) => {
        if (index < 3) arr[index] = obj;
      });

    if (testRendersData.length > 0)
      testRendersData.forEach((obj, index) => {
        if (index < 2) arr[index + 1] = obj;
      });

    if (topviewRenderData) arr[2] = topviewRenderData;

    const filteredList = arr.filter((obj) => obj);
    return filteredList;
  }, [threeSixties, topviewRenderData, testRendersData]);

  return (
    <Row className="w-full flex">
      {displayModelList.map((obj, ind) => (
        <AssetPreviewCard
          key={ind}
          id={obj.id}
          name={obj.image}
          url={obj.url}
          type={obj.type}
          num={ind}
          setDisplayDetailType={setDisplayDetailType}
          setSelected360={setSelected360}
          setSelectedImageUrl={setSelectedImageUrl}
          length={displayModelList.length}
          setFullScreen={setFullScreen}
        />
      ))}
      <Button
        className="space-btn-large mt-16"
        onClick={() => setDisplayDetailType("3d_preview")}
      >
        See All {allImagesCount} Images <ArrowRightOutlined />
      </Button>
    </Row>
  );
};

export default withRouter(AssetPreviewGrid);
