import React from "react";

export default function ReorderIcon({ className }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.2222 10.2229V14.2229C10.2222 14.3407 10.1754 14.4538 10.0921 14.5372C10.0086 14.6205 9.89561 14.6673 9.77776 14.6673H1.77776C1.65988 14.6673 1.54684 14.6205 1.46349 14.5372C1.38014 14.4538 1.33331 14.3407 1.33331 14.2229V6.22287C1.33331 6.105 1.38014 5.99195 1.46349 5.90861C1.54684 5.82525 1.65988 5.77843 1.77776 5.77843H5.77776M14.6666 1.77843V9.77843C14.6666 9.89628 14.6198 10.0093 14.5365 10.0927C14.4531 10.1761 14.3401 10.2229 14.2222 10.2229H6.2222C6.10433 10.2229 5.99128 10.1761 5.90793 10.0927C5.82458 10.0093 5.77776 9.89628 5.77776 9.77843V1.77843C5.77776 1.66055 5.82458 1.54751 5.90793 1.46416C5.99128 1.38081 6.10433 1.33398 6.2222 1.33398H14.2222C14.3401 1.33398 14.4531 1.38081 14.5365 1.46416C14.6198 1.54751 14.6666 1.66055 14.6666 1.77843Z"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
