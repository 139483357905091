import React, { useState, useEffect } from "react";
import {
    Button,
    Layout,
    Row,
    Table,
    Col,
    Form,
    Select,
    Input,
    Checkbox,
} from "antd";
import axios from "axios";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import ENVIRONMENT from "../../../environments";
import SuccessModal from "../SuccessModal/SuccessModal";
import WarningModal from "../WarningModal/WarningModal";
import EditSkillMatrixModal from "../AdminComponents/EditSkillMatrixModal/EditSkillMatrixModal";
import "./AgencyDetails.scss";

const { Header, Content, Footer } = Layout;
const { Option } = Select;

function AgencyArtistInvite(props) {
    const [buttonLoader, setButtonLoader] = useState(false);
    const [agencyArtists, setAgencyArtists] = useState([]);
    const [userGroup, setUserGroup] = useState("artist");
    const [testRequired, setTestRequired] = useState(false);
    const [email, setEmail] = useState('');
    const [successModal, setSuccessModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [skillList, setSkillList] = useState([]);
    const [allCategories, setAllCategories] = useState([])
    const [skillModalVisible, setSkillModalVisible] = useState(false);
    const [adminBillingType, setAdminBillingType] = useState('hourly')
    const [adminBillingRate, setAdminBillingRate] = useState(0)
    const [additionalInviteDetails, setAdditionalInviteDetails] = useState(null)

    useEffect(() => {
        axios.post(ENVIRONMENT.SKILL_GET_BATCH, {})
        .then(res => {setSkillList(res['data'])})

        const payload = {
            output: 'serial'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
        .then(res => {
            setAllCategories(res.data);
        });

        axios.post(ENVIRONMENT.GET_ARTIST_PROFILE, {
            artist_username: localStorage.getItem('username')
        }).then(res => {
            console.log('artist profile', res['data'])
            if(res['data']){
                setAdminBillingRate(res['data']['hourly_rate'])
                setAdminBillingType(res['data']['billing_type'])
            }
            
        })
    }, [])

    useEffect(() => {
        if (testRequired) setUserGroup("test_artist");
        else {
            setUserGroup("artist");
        }
    }, [testRequired]);

    const updateAdditionalInviteDetailsAndSendInvite = (payload) => {
        handleSendInvite(payload)
    }

    const handleSendInvite = (additional_invite_details) => {
        setButtonLoader(true)
        let payload = {}
        payload.email = email.toLowerCase();
        payload.type =  userGroup;
        payload.agency_name = props.agencyName;
        payload.test_required = testRequired;
        payload.invited_by =  localStorage.getItem('username');
        payload.additional_details = {}
        if(adminBillingRate){
            payload.additional_details['hourly_rate'] = adminBillingRate
        }
        if(adminBillingType){
            payload.additional_details['billing_type'] = adminBillingType
        }
        if (additional_invite_details){
            for(let key in additional_invite_details){
                payload.additional_details[key] = additional_invite_details[key]
            }
        }
        console.log('Payload', payload);
        axios.post(ENVIRONMENT.INVITE_USER, payload)
        .then(res => {
            console.log(res);
            if(res.data == '1') {
                setSuccessModal(true)
            }
            else if(res.data == '0') {
               setWarningModal(true)
            }
            setButtonLoader(false)
        });
    }

    return (
        <React.Fragment>
            <div className="content-container" style={{ paddingTop: 20 }}>
                <div
                    className="manrope f-24 w-700"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <img
                        src="/img/back_arrow.png"
                        onClick={() => props.setInviteAgencyArtist(false)}
                        style={{ marginRight: 20, cursor: "pointer" }}
                    />{" "}
                    Invite New Artist
                </div>

                <div className="custom-frm" style={{ maxWidth: 400 }}>
                    <Form
                        className="input-form login-form"
                        onFinish={
                            testRequired
                                ? () => handleSendInvite({})
                                : () => setSkillModalVisible(true)
                        }
                    >
                        <div
                            className="manrope f-14 black-55"
                            style={{ marginTop: 10, marginBottom: 10 }}
                        >
                            Email <span style={{ color: "red" }}>*</span>
                        </div>
                        <Form.Item
                            name="email"
                            rules={[
                                { type: "email", message: "Please enter a valid email." },
                                { required: true, message: "Please enter an email." },
                                {
                                    pattern: "^[^\\s]+(\\s+[^\\s]+)*$",
                                    message:
                                        " Spaces are not allowed, whitespace found at the end.",
                                },
                            ]}
                        >
                            <Input
                                disabled={buttonLoader}
                                className="manrope f-16 black-55 library-search"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="test_required"
                            initialValue={testRequired}
                        >
                            <Checkbox
                                checked={testRequired}
                                onChange={(e) => setTestRequired(e.target.checked)}
                            >
                                Require test on Sign up
                            </Checkbox>
                        </Form.Item>

                        <Button
                            style={{ marginTop: 15 }}
                            disabled={buttonLoader}
                            id="assignment-submit-button"
                            className="modal-okay square font-16"
                            type="primary"
                            htmlType="submit"
                        >
                            Send Invite {buttonLoader ? <LoadingOutlined /> : ""}
                        </Button>
                    </Form>
                </div>
                <SuccessModal
                    visible={successModal}
                    heading={"Account Invite Sent!"}
                    text={"Account Invite has been sent on the entered email."}
                    footer={[
                        <div className="justify-in-center">
                            <Button
                                style={{ margin: "auto" }}
                                className="modal-okay square font-14"
                                htmlType="submit"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Okay
                            </Button>
                        </div>,
                    ]}
                />
                <WarningModal
                    visible={warningModal}
                    onCancel={() => setWarningModal(false)}
                    heading={"User already exists!"}
                    text={"Oops. A user already exists on the email you entered."}
                    footer={[
                        <div className="justify-in-end">
                            <Button
                                key="back"
                                style={{ textAlign: "center" }}
                                className="modal-okay square font-14"
                                onClick={() => setWarningModal(false)}
                            >
                                Okay
                            </Button>
                        </div>,
                    ]}
                />

                <EditSkillMatrixModal
                    isTestArtist={true}
                    inputInviteDetails={true}
                    skill_list={skillList}
                    visible={skillModalVisible}
                    set_visibility={setSkillModalVisible}
                    all_categories={allCategories}
                    proficient_categories={allCategories}
                    pricing_method={null}
                    username={null}
                    testID={null}
                    billing_type={adminBillingType}
                    billing_rate={adminBillingRate}
                    hideBillingFormInput={true}
                    update_parent_state={updateAdditionalInviteDetailsAndSendInvite}
                />
            </div>
        </React.Fragment>
    );
}

export default AgencyArtistInvite;
