
import * as THREE from 'three'
import {ConvexGeometry} from "../ConvexGeometry";
import Constants from "./Constants.js"

/**
 * Convert angle from degrees to radians.
 * @param {Number} degrees - Angle in degrees.
 */
export function degrees_to_radians ( degrees ) {
    return degrees * (Math.PI / 180);
  }

/**
 * Convert angle from radians to dgrees.
 * @param {Number} radians - Angle in radians.
 */
export function radians_to_degrees ( radians ) {
    return radians * (180 / Math.PI);
}

/**
 * Round number to fixed decimal places.
 * @param {Number} decimalPlace - Number of decimal places to round to.
 */
export function rounded ( num, decimalPlace = 2 ) {
  return ( Math.round( ( num + Number.EPSILON ) * ( 10 * decimalPlace ) ) / ( 10 * decimalPlace ) );
}

/**
 * Check if two Three.Vector3 are equal after rounding each dimension to two places.
 * @param {THREE.Vector3} v1 - First vector3.
 * @param {THREE.Vector3} v2 - Second vector3.
 */
export function roundedEqual ( v1, v2 ) {
  if ( rounded( v1.x ) ==  rounded( v2.x ) && rounded( v1.y ) ==  rounded( v2.y ) && rounded( v1.z ) ==  rounded( v2.z ) ){
      return true;
  }

  return false;
}

/**
 * Multiply corresponding values of two vectors
 * @param {THREE.Vector3} vec1 - Vector 1
 * @param {THREE.Vector3} vec2 - Vector 2
 * @returns - The resultant vector
 */
export function multiplyVectorsElementWise (vec1, vec2) {
  const result = new THREE.Vector3();
  result.x = vec1.x * vec2.x;
  result.y = vec1.y * vec2.y;
  result.z = vec1.z * vec2.z;

  return result;
}

/**
 * Get root node of some scene asset.
 * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
 * @param {THREE.Scene} obj - Scene asset for which root node is required.
 */
export function getRootNodeFromObject( scene, obj ) {
  if(scene && obj) {
    return scene.getObjectById(obj.userData.rootNode);
  }
  else {
    return false;
  }
}

/**
 * Get object node from the root by name
 * @param {THREE.Scene} rootNode - The root node from which the object is required.
 * @param {String} objName - The name of object to search for
 */
export function getObjectFromRootByName( rootNode, objName ) {
  for (let child of rootNode.children) {
    if (child.isObject3D && (child.name == objName || child.name == "RootNode")) {
      return child;
    }
  }
  return null;
}

export function disposeScene(scene) {
  // Dispose of geometries
  scene.traverse((object) => {
      if (object.isMesh) {
          // Dispose of geometry
          if (object.geometry) {
              object.geometry.dispose();
          }
          // Dispose of materials
          if (object.material) {
              // If the material is an array of materials (MultiMaterial), dispose each of them
              if (Array.isArray(object.material)) {
                  object.material.forEach((material) => {
                      if (material.map) {
                          material.map.dispose();
                      }
                      if (material.roughnessMap) {
                          material.roughnessMap.dispose();
                      }
                      if (material.metalnessMap) {
                          material.metalnessMap.dispose();
                      }
                      if (material.normalMap) {
                          material.normalMap.dispose();
                      }
                      if (material.bumpMap) {
                          material.bumpMap.dispose();
                      }
                      if (material.emissiveMap) {
                          material.emissiveMap.dispose();
                      }
                      if (material.lightMap) {
                          material.lightMap.dispose();
                      }
                      material.dispose();
                  });
              } else {
                  // Dispose of a single material
                  if (object.material.map) {
                      object.material.map.dispose();
                  }
                  if (object.material.roughnessMap) {
                      object.material.roughnessMap.dispose();
                  }
                  if (object.material.metalnessMap) {
                      object.material.metalnessMap.dispose();
                  }
                  if (object.material.normalMap) {
                      object.material.normalMap.dispose();
                  }
                  if (object.material.bumpMap) {
                      object.material.bumpMap.dispose();
                  }
                  if (object.material.emissiveMap) {
                      object.material.emissiveMap.dispose();
                  }
                  if (object.material.lightMap) {
                      object.material.lightMap.dispose();
                  }
                  object.material.dispose();
              }
          }
      }
  });
}


/**
 * Detach Scene Assets from Asset
 * @param {THREE.Scene} scene - The main scene node.
 * @param {THREE.Object3D} baseAsset - The base asset from which the assets are to be detached
 */
export function detachSceneAssets( scene, baseAsset ) {
  let childrenToDetach = [];
  for (let child of baseAsset.children) {
    if (child.isScene && child.userData.isStacked) {
      childrenToDetach.push(child);
    }
  }
  for (let child of childrenToDetach) {
    scene.attach(child);
  }
  return childrenToDetach;
}

/**
 * Attach Scene Assets from Asset
 * @param {THREE.Object3D} baseAsset - The base asset to which the assets are to be attached
 * @param {Object} childrenToDetach - The list of assets to attach to base asset
 */
export function attachSceneAssets( baseAsset, childrenToDetach ) {
  for (let child of childrenToDetach) {
    baseAsset.attach(child);
  }
}

/**
 * Check if a scene asset is a rug.
 * @param {THREE.Scene} asset - Asset to check.
 */
export function isRug ( asset ) {
    let size = asset.userData.size;
    let coveredArea = size.x * size.z;

    if ( coveredArea > Constants.rugMinCoveredAreaThreshold && size.y < Constants.rugMaxHeightThreshold ) {
        return true;
    }
    else {
        return false;
    }
}

/**
 * Check if an object is a convex hull object.
 * @param {THREE.Scene} object - Object to check.
 */
export function isConvexHull(object) {
  if(object.geometry != null) {
    return object.geometry instanceof ConvexGeometry;
  }

  return false;
}

/**
 * Check if a asset node is an ancestor of another object node.
 * @param {THREE.Scene} ancestor - Object to use as ancestor node.
 * @param {THREE.Scene} decendant - Object node whose ancestor needs to be checked.
 * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
 */
export function isAncestor ( ancestor, decendant, scene ) {
  while ( decendant.parent != scene ) {
      if ( decendant.parent == ancestor ) {
          return true;
      }
      decendant = decendant.parent;
  }

  return false;
}

/**
 * Check if an asset is currently snapped.
 * @param {THREE.Scene} asset - Asset to check.
 */
export function isSnapped ( asset ) {
  if( asset.userData.snapped == undefined ) {
      return false
  }
  else{
      return asset.userData.snapped;
  }
}

/**
 * Remove an element from an array.
 * @param {Array} arr - Array to remove the element from.
 * @param {Object} arr - Element to remove.
 */
export function removeElementFromArray( arr, element ) {
  let index = arr.indexOf( element );
  if ( index != -1 ) {
      arr.splice( index, 1 );
      return true;
  }
  else {
      return false;
  }
}

/**
 * Update the highlight state (color) of asset.
 * @param {THREE.Scene} asset - Asset whose state is to be updated.
 * @param {Boolean} highlightState - When true highlightColor is used to set the color, else color is set to default.
 * @param {THREE.Color} higlightColor - Color to use when highlightState is set. 
 */
export function setHighlightedState( asset, highlightState, highlightColor = Constants.defaultHighLightColor ) {
  asset.traverse( ( child ) => {
          // Conex hull check added because convex hulls use basic matertial type
          if ( child.isMesh && !isConvexHull(child)) {
              if ( highlightState ) {
                  child.material.emissive.copy( highlightColor );
                  child.material.emissiveIntensity = 0.5;
              }
              else {
                  child.material.emissive.setRGB( 0.0, 0.0, 0.0 );
                  child.material.emissiveIntensity = 0.0;
              }
          }
  } );
}

/**
 * Get THREE JS supported normal vector for the intersect. This handles conversion of normals from 3DS Max format to THREE JS format (if needed).
 * Conversion is not needed for intersects with Lost and Found area child nodes.
 * @param {Object} intersect - Intersect object for which the normal is required.
 * @param {LostAndFoundArea} LFAAreaManager - Reference to lost and found area manager, required for accessing LFA area root.
 */
export function getNormalForIntersect(intersect, LFAAreaManager, fixNormal = true) {
  if(intersect != null) {
      let object = intersect.object;
      let normal = new THREE.Vector3();

      let LFAAreaRoot = null;
      if(LFAAreaManager != null) {
        LFAAreaRoot = LFAAreaManager.getRootNode();
      } 

      if ( LFAAreaRoot != null && object.parent === LFAAreaRoot ) {
          normal.copy( intersect.face.normal );
      }
      else if(fixNormal) {
          normal = new THREE.Vector3( intersect.face.normal.x, intersect.face.normal.z, -intersect.face.normal.y );
      }
      else if(!fixNormal) {
        normal = new THREE.Vector3( intersect.face.normal.x, intersect.face.normal.y, intersect.face.normal.z );
      }

      return normal;
  }
}


 /**
  * retreive position and direction for the x and z coordinates for the vector
  * @param {THREE.Vector3} x_pos 
  * @param {THREE.Vector3} z_pos 
  */
export function retreivePositions(x_pos, z_pos) {
    var xz_value = [Constants._unit.X, Constants._unit.Z, 1.0, 1.0];
    if (x_pos < 0) {
      // get direction
      xz_value[0] = Constants._unit.aX;
      // get sign for direction
      xz_value[2] = -1.0;
    }
    if (z_pos < 0) {
      // get direction
      xz_value[1] = Constants._unit.aZ;
      // get sign for direction
      xz_value[3] = -1.0;
    }
    return xz_value;
 }

 /**
 * Apply color on the space component
 * @param {THREE.Mesh} spaceComponent - The space component (wall/floor/ceiling) to apply color on
 * @param {HEXCode} colorHexCode - The color hex code to set
 */
export function changeColorToSpaceComponent(spaceComponent, colorHexCode) {
  if (colorHexCode) {
    spaceComponent.material = new THREE.MeshStandardMaterial();
    const color = new THREE.Color(colorHexCode).convertSRGBToLinear();
    spaceComponent.material.color.set(color);
  }
}

/**
 * Apply the texture and its properties to the space component
 * @param {THREE.Mesh} spaceComponent - The space component (wall/floor/ceiling) to apply material on
 * @param {THREE.Texture} texture - The texture to be applied
 * @param {Number} horizontalRepeat - The u tiling value for the texture
 * @param {Number} verticalRepeat - The v tiling value for the texture
 * @param {Number} roughness - The roughness value for the texture
 * @param {Function} onMaterialApplied - The callback function to call when the material is updated
 */
export function changeMaterialToSpaceComponent(spaceComponent, texture, horizontalRepeat, verticalRepeat, roughness,
                horizontalOffset, verticalOffset, rotation, onMaterialApplied = null) {
  if (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set( horizontalRepeat, verticalRepeat );
    texture.encoding = THREE.sRGBEncoding;
    texture.center.set(0.5 + horizontalOffset, 0.5 + verticalOffset)
    texture.flipY = false;
    texture.rotation = rotation;
  }
  spaceComponent.material = new THREE.MeshStandardMaterial();
  spaceComponent.material.map = texture;
  spaceComponent.material.toneMapped = false;
  spaceComponent.material.roughness  = roughness;
  spaceComponent.material.needsUpdate = true;
  if (onMaterialApplied) {
    onMaterialApplied();
  }
  
}
