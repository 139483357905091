import React from "react";
import { Row, Col, Button, Select, Form, Modal, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import FileConstants from "../../../../FileConstants";

const { Option } = Select;

const EditSpace = ({
  editForm,
  closeEditForm,
  buttonLoader,
  roomDetails,
  saveSpaceData,
  spaceCategories,
  errorMessage,
}) => {
  return (
    <Modal
      visible={editForm}
      className="modal-share"
      width={788}
      closable={false}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={closeEditForm}
      footer={[
        <div className="justify-in-end">
          <Button
            disabled={buttonLoader}
            className="modal-okay square font-14"
            onClick={() => document.getElementById("save-space-data").click()}
          >
            Save&nbsp;{buttonLoader ? <LoadingOutlined /> : ""}
          </Button>
        </div>,
      ]}
    >
      <div style={{ padding: "16px 6px 0 14px" }}>
        <div className="justify-space-between" style={{ marginBottom: 20 }}>
          <h2 className="manrope f-18 black-14">Edit Information</h2>
          <h2
            className="manrope f-18 grey-77"
            style={{ cursor: "pointer" }}
            onClick={closeEditForm}
          >
            X
          </h2>
        </div>

        <Form
          initialValues={{
            room_name: roomDetails.room_name,
            brand_id: roomDetails.brand_id,
            category: roomDetails.category,
            tags: roomDetails.tags,
            reference_urls: roomDetails.reference_urls,
            style: roomDetails.style,
          }}
          onFinish={saveSpaceData}
          layout="vertical"
        >
          <Row type="flex" gutter={12}>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Form.Item
                label={<span className="manrope f-14 black-55">Name</span>}
                name="room_name"
                rules={[
                  { required: true, message: "Please enter space name." },
                ]}
              >
                <Input
                  disabled={buttonLoader}
                  placeholder={"Enter Space Name"}
                  className="manrope f-12 black-55 library-search"
                />
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginBottom: 20 }}>
              <Form.Item
                label={<span className="manrope f-14 black-55">Space ID</span>}
                name="brand_id"
                rules={[{ required: false, message: "Please enter space ID." }]}
              >
                <Input
                  disabled={buttonLoader}
                  placeholder={"Enter Space ID"}
                  className="manrope f-12 black-55 library-search"
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Form.Item
                label={<span className="manrope f-14 black-55">Tags</span>}
                name="tags"
                rules={[
                  {
                    type: "array",
                    required: false,
                    message: "Please add space tags.",
                  },
                ]}
              >
                <Select
                  disabled={buttonLoader}
                  className="manrope f-12 black-55 select-tags"
                  mode="tags"
                  placeholder="Enter space tags"
                >
                  <Option
                    value=""
                    className="manrope f-12 black-55 select-view"
                  ></Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginBottom: 20 }}>
              <Form.Item
                label={<span className="manrope f-14 black-55">Category</span>}
                name="category"
                rules={[
                  {
                    required: true,
                    message: "Please select a space category.",
                  },
                ]}
              >
                <Select
                  className={`manrope f-12 black-55 tree-select-material f-12`}
                  disabled={buttonLoader}
                  placeholder="Select your space category"
                >
                  {spaceCategories &&
                    spaceCategories.map((category) => {
                      return (
                        <Option
                          className="manrope f-12 black-55 select-view"
                          value={category}
                        >
                          {category}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} style={{ marginBottom: 20 }}>
              <Form.Item
                label={<span className="manrope f-14 black-55">Style</span>}
                name="style"
                rules={[
                  {
                    required: false,
                    message: "Please select a space style.",
                  },
                ]}
              >
                <Select
                  className={`manrope f-12 black-55 tree-select-material item-height-35`}
                  disabled={buttonLoader}
                  placeholder="Select your space style"
                >
                  {FileConstants.SPACE_STYLES.map((style) => {
                    return (
                      <Option
                        className="manrope f-12 black-55 select-view"
                        value={style}
                      >
                        {style}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={
                  <span className="manrope f-14 black-55">
                    Reference URL(s)
                  </span>
                }
                name="reference_urls"
                rules={[
                  {
                    type: "array",
                    required: false,
                    message: "Please add reference url(s).",
                  },
                ]}
              >
                <Select
                  disabled={buttonLoader}
                  className="manrope f-12 black-55 select-tags"
                  mode="tags"
                  placeholder="Enter Reference URL(s) that you would like us to have a look at, for your space model"
                >
                  <Option
                    value=""
                    className="manrope f-12 black-55 select-view"
                  ></Option>
                </Select>
              </Form.Item>
            </Col>
            {errorMessage == "" ? (
              ""
            ) : (
              <Col span={24} style={{ marginTop: 20 }}>
                <div
                  className="justify-space-between err-bg manrope f-12 red-error"
                  style={{ marginRight: 8 }}
                >
                  <img src="/img/error-small.png" style={{ marginRight: 8 }} />
                  <span>{errorMessage}</span>
                </div>
              </Col>
            )}
            <Button
              id="save-space-data"
              type="primary"
              htmlType="submit"
              style={{ display: "none" }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EditSpace;
