import React, { useContext } from 'react';
import FileConstants from '../../../../FileConstants';
import {
    Button,
    Col,
    Row,
    Tooltip
} from 'antd';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import { QuestionCircleOutlined } from '@ant-design/icons';

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem("company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const SEQUENCE_IDS = FileConstants.SEQUENCE_IDS;

const ProductQAActionButtons = () => {
    const { toggleAnnotateDisplay, collaborateMode, submitFeedback, approveLoader, saveCollaboration, setProductAnnouncementModal,
        setCollaborateMode, closeCommentHistoryDrawer, changeAssetTab, approveModel, save_loader, switchToLegacy, legacyViewLoader,
        displayLoader, annotateLoader, canvasLoader, refreshLoader, autoSaveLoader, changesMadeInCollab, selected_action, canvas_editor } = useContext(CollaborateQAContext);

    const leaveCollaborateMode = () => {
        if (canvas_editor && (selected_action == "annotate")) {
            canvas_editor.removePrevHotspot();
        }
        setCollaborateMode(false);
        toggleAnnotateDisplay("none");
        closeCommentHistoryDrawer();
        saveCollaboration(true);
        let url = new URL(window.location);
        url.searchParams.set("changes_made", "true");
        window.history.replaceState({}, "", url);
    }

    const enterCollaborateMode = () => {
        changeAssetTab("images");
        setCollaborateMode(true);
        toggleAnnotateDisplay("none");
    }

    const openProductAnnouncementModal = () => {
        setProductAnnouncementModal(true);
    }

    return (<Row className='product-qa-action-bar'>
        <Col span={24} className={collaborateMode ? 'justify-space-between' : 'justify-space-between'}>
            {collaborateMode ?
                <div className='justify-in-start'>
                    <Button disabled={save_loader || annotateLoader || refreshLoader || canvasLoader || displayLoader || autoSaveLoader} className='basic-grey-btn f-14' onClick={leaveCollaborateMode}>Save & Go Back</Button>
                    <Button disabled={save_loader || annotateLoader || refreshLoader || canvasLoader || displayLoader || autoSaveLoader} loading={save_loader} className='basic-grey-btn f-14 ml-12' onClick={() => saveCollaboration(false)}>Save</Button>
                </div> : <div className='justify-in-start'>
                    <img src='/img/collaborate_qa/wand.png' className='mr-8' />
                    <div>You are using the new and improved Product QA collaboration mode
                        <Tooltip title={<span className='manrope f-12 white'>
                            Easily leave notes and comments directly on side-by-side model and reference image comparisons.
                            You can also invite team members to collaborate with you.&nbsp;
                            <span className='manrope f-12 underline cursor-pointer' onClick={openProductAnnouncementModal}>Learn more</span>
                        </span>}>
                            <QuestionCircleOutlined className='ml-4' />
                        </Tooltip>
                    </div>
                    <Button className='basic-grey-btn f-14 ml-12'
                        onClick={switchToLegacy}
                        loading={legacyViewLoader}
                        disabled={legacyViewLoader}>Switch to old version</Button>
                </div>}

            <div className='justify-in-end'>
                {collaborateMode ?
                    <Tooltip title={(save_loader || annotateLoader || refreshLoader || displayLoader || canvasLoader || autoSaveLoader) ? <span className='manrope f-12 white'>Please wait while we save your data</span> : ''}>
                        <span>
                            <Button className='basic-blue-btn f-14' disabled={save_loader || annotateLoader || refreshLoader || displayLoader || canvasLoader || autoSaveLoader} onClick={submitFeedback}>Submit Feedback</Button>
                        </span>
                    </Tooltip> :
                    <Button className='basic-blue-btn f-14' onClick={enterCollaborateMode}>{changesMadeInCollab ? "Continue Adding Feedback" : "Add Feedback"}</Button>}
                <Button className='basic-green-btn f-14 ml-12' onClick={approveModel} loading={approveLoader}>Approve Model</Button>
            </div>
        </Col>
    </Row>)

}

export default ProductQAActionButtons;