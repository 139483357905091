import React from 'react';
import ENVIRONMENT from '../../../../environments'
import 'antd/dist/antd.css';
import ModelsSelection from '../../CustomerComponents/AddCollectionFlow/ModelsSelection';
import AdminMainLayout from '../AdminMainLayout/AdminMainLayout';
import '@ant-design/compatible/assets/index.css';
import axios from 'axios';

class AdminTestSpace extends React.Component {
    state={
        step: 1,
        collection_id: -1, //204, //null,
        scene_name: 'test scene',
        room_id: this.props.match.params.id,
        space_areas: [],
        model_selection_stage: 2,
        onboarding: false,
    }

    createCollectionAndAddRoom = () => {
        let room_id = this.props.match.params.id;
        let payload = {};
        payload.username = localStorage.getItem("username");
        payload.collection_name = 'test_' + new Date().getTime();
        axios.post(ENVIRONMENT.UPDATE_COLLECTION, payload)
            .then((response) => {
                let collection_id = response.data["collection_id"]
                this.setCollectionId(collection_id);
                this.addRoomToCollection(collection_id, room_id)
            })
            .catch((error) => {
            });

    }

    addRoomToCollection = (collection_id, room_id) => {
        let payload = {}
        payload.room_id = room_id;
        payload.collection_id = collection_id;
        axios.post(ENVIRONMENT.COLLECTION_ADD_ROOM, payload)
            .then((response) => {
                this.setSpaceAreas(response.data.space_areas);
            })
            .catch((error) => {

            });
    }

    componentDidMount() {
        this.createCollectionAndAddRoom();        
    }

    setCollectionId = (id) => {
        this.setState({
            collection_id: id
        });
    }

    getCollectionId = () => {
        return this.state.collection_id;
    }

    setRoomId = (id) => {
        this.setState({
            room_id: id
        });
    }

    getRoomId = () => {
        return this.state.room_id;
    }

    setSceneName = (name) => {
        this.setState({
            scene_name: name
        });
    }

    getSceneName = () =>{
        return this.state.scene_name;
    }

    getSpaceAreas = () => {
        return this.state.space_areas;
    }

    setSpaceAreas = (space_areas) => {
        this.setState({
            space_areas: space_areas
        });
    }

    getModelSelectionStage = () => {
        return this.state.model_selection_stage;
    }

    setModelSelectionStage = (stage) => {
        return this.setState({
            model_selection_stage: stage
        });
    }

    setCollectionSelectionView = () =>
    {
        this.setState({
            step: 2
        });
    }
    
    setNewCollectionView = () =>
    {
        this.setState({
            step: 3
        });
    }

    setFirstCollectionView = () =>
    {
        this.setState({
            step: 4
        });
    }
    
    setModelSelectionView = () =>
    {
        this.setState({
            step: 5
        });
    }

    setRoomsSelectionView = () =>
    {
        this.setState({
            step: 6
        });
    }

    setSpaceUnitAllocationView = () =>
    {
        this.setState({
            step: 7
        });
    }

    render() {
        return ( 
        <AdminMainLayout selected='3'>
            <div>
                <div style={{marginLeft: "-25px", marginRight: "-25px"}}>
                    <ModelsSelection
                    scene_flow={true}
                    setRoomsSelectionView={this.setRoomsSelectionView}
                    getCollectionId={this.getCollectionId}
                    getSceneName={this.getSceneName}
                    setSpaceUnitAllocationView = {this.setSpaceUnitAllocationView}
                    setRoomId={this.setRoomId}
                    getSpaceAreas={this.getSpaceAreas}
                    setSpaceAreas={this.setSpaceAreas}
                    getModelSelectionStage={this.getModelSelectionStage}
                    setModelSelectionStage={this.setModelSelectionStage}
                    adminTestFlow={true}
                    getRoomId={this.getRoomId}
                    scene_flow={true}
                    />
                </div>
    
            </div> 
        </AdminMainLayout>
        )
    }
}

export default AdminTestSpace;