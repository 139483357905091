import React, { useContext, useEffect } from 'react';
import _ from "lodash";
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import { Row, Col, Modal, Carousel } from 'antd';
import './ProductQA.scss';
import ENVIRONMENT from '../../../../environments';
import * as Utilities from '../../Utilities';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';


const RejectionDetailsModals = () => {
    const { setShowDetailedRejection, showDetailedRejection, showRejectionImage, product_id,
        customerRejectionComments, rejectionImageUrl, setRejectionImageUrl, setShowRejectionImage, platform } = useContext(CollaborateQAContext);

    const handleCancelRejectionModal = () => {
        setShowDetailedRejection(false);
    }

    const cancelImageModal = () => {
        setRejectionImageUrl('');
        setShowRejectionImage(false);
    }

    const handleImage = (event) => {
        event.preventDefault();
        setRejectionImageUrl(event.currentTarget.getAttribute('href'));
        setShowRejectionImage(true);
    }

    let detailed_rejection_data = '';
    if (customerRejectionComments.length > 0) {
        detailed_rejection_data = customerRejectionComments.map((ele, index) => (
            <div style={{ marginTop: 2, maxWidth: "941px" }}>
                <span style={{ marginTop: 15, display: "block" }} className='note-bg-artist manrope f-12'>Date: <span style={{ color: "#276DD7", paddingLeft: 3, marginRight: 25 }}>{Utilities.convertUtcToLocalTimezone(ele.qa_time) || '-'}</span>  Activity By: <span style={{ marginLeft: 5, color: "#276dd7" }}>{ele.qa_person}</span></span>
                {ele.feedback_link ?
                    <div className='justify-in-start w-100 mb-4' style={{ marginTop: 2, padding: 2 }}>
                        <span className="manrope f-14 black-55 text-left ml-8" style={{ display: 'block', float: 'left' }}>
                            Feedback:
                        </span>
                        <a className='manrope f-14 blue hover-change ml-8' href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.PRODUCT_FEEDBACK_URI + product_id + "/" + ele.feedback_link + '.pdf'}
                            target='_blank'>
                            {ele.feedback_link + '.pdf'}
                        </a>
                    </div> : ''}
                {(ele.reference_files != [] && ele.reference_files != undefined) && (typeof (ele.reference_files) == "object" && Object.keys(ele.reference_files).length != 0) ?
                    <div className='w-100 ml-8' style={{ display: "block", marginTop: 2, marginBottom: 2, padding: 2 }} >
                        <div>
                            <span className="manrope f-14 black-55 text-left mb-4" style={{ display: 'block', width: "50%", float: 'left' }}>
                                Comments:
                            </span>
                            <span className="manrope f-14 black-55 mb-4" style={{ width: "50%", float: 'left' }}>
                                Uploads:
                            </span>
                        </div>
                        <div className='mb-10 w-100'>
                            <span className="manrope f-12 black-33 text-left" style={{ width: "51%", float: 'left' }}>
                                {ele.rejection_details}
                            </span>
                            <div style={{ float: 'left', width: '47%' }}>
                                <Carousel className='w-100' arrows={true} nextArrow={<RightOutlined />} prevArrow={<LeftOutlined />}>
                                    {ele.reference_files && ele.reference_files.map((file, index) => (
                                        <a onClick={handleImage} style={{ color: "#276DD7", lineHeight: '120%' }}
                                            className="upload-a-settings" target="_blank"
                                            href={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']}>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".PNG") || file.name.includes(".svg")) ?
                                                    <img className="upload-image-settings-reject" src={ENVIRONMENT.getBaseURL(platform) + ENVIRONMENT.BASE_URI + file['uid'] + '/' + file['name']} /> :
                                                    (file.name.includes(".tiff")) ? <img className="upload-image-settings-reject" src={'/img/tiff_icon.png'} /> :
                                                        <img className="upload-image-settings-reject" src={'/img/file-icon.png'} />}
                                            </div>
                                            <p className="manrope f-12" style={{ textAlign: 'center', color: '#555555' }}>{file.name}</p>
                                        </a>

                                    ))}
                                </Carousel>
                            </div>

                        </div>
                    </div>
                    :
                    <>

                        <div className='justify-in-start mt-4 ml-8' style={{ marginTop: 4 }}>
                            <span className="manrope f-14 black-55 text-left" style={{ width: "10%", float: 'left' }}>
                                Comments:
                            </span>
                            <span className="manrope f-12 black-33" style={{ textAlign: "left", float: 'left' }}>
                                {ele.rejection_details}
                            </span>
                        </div>
                    </>
                }
            </div>
        ));
    }


    return (<>
        <Modal
            className="rejection-image"
            style={{ width: '100vw' }}
            closeIcon={
                <img src={"/img/closeIcon.png"} alt="Close Modal Icon"
                    style={{ marginTop: 28, marginRight: 20, cursor: "pointer", borderRadius: "4px" }}

                />
            }
            footer={false}
            visible={showRejectionImage}
            onCancel={cancelImageModal}>
            <a href={rejectionImageUrl} target="_blank" >
                <img src={rejectionImageUrl} style={{ width: '100%', height: "100%" }} />
            </a>
        </Modal>

        <Modal
            visible={showDetailedRejection}
            closable={true}
            title={<span className='manrope f-16 black-33 w-700'>Rejection Details</span>}
            onCancel={handleCancelRejectionModal}
            footer={false}
            width={1000}>
            <div className="rejection-modal" style={{ height: '60vh', overflowY: 'scroll' }}>
                <div style={{ display: 'grid' }}>
                    {detailed_rejection_data}
                </div>
            </div>
        </Modal>
    </>)
}

export default RejectionDetailsModals;