import React, { useContext } from 'react';
import _ from "lodash";
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import ProductPageNavigation from './ProductPageNavigation';
import FeatureProductImages from './FeatureProductImages';
import DottedLoader from '../../DottedLoader';
import ProductAssetViewer from './ProductAssetViewer';
import ProductTextualInfo from './ProductTextualInfo';
import { Link } from "react-router-dom";
import { Row } from 'antd';
import ModelReferenceInfo from './ModelReferenceInfo';
import FlagSiloModal from './FlagSiloModal';
import ShareProductModal from './ShareProductModal';
import EditProductModal from './EditProductModal';
import OpenVariationModal from './OpenVariationModal';
import ProductMP4Modal from './ProductMP4Modal';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import InAppPurchaseLimitModal from '../InAppPurchaseLimitModal';
import ProductDimModal from './ProductDimModal';
import Product360Spin from './Product360Spin';
import SiloComplaints from '../SiloComplaints';
import SellProductOnStore from './SellProductOnStore';
import ProductActionBar from './ProductActionBar';
import ProductVariantsContainer from './ProductVariantsContainer';
import SameBrandOtherProducts from './SameBrandOtherProducts';
import SharingModal from '../SharingModal/SharingModal';
import EntityAccessDenied from '../EntityAccessDenied/EntityAccessDenied';

const ProductPage = (props) => {
    const { product_id, cognito_username, companyId, complaints,
        productDataLoader, inAppLimitExceededModal, setInAppLimitExceededModal,
        paymentFailureModal, productAssetData,
        updatePaymentDetailsOnFailure, paymentFailureMessage, setPaymentFailureModal,
        complaintsView, setComplaintsView, viewComplaintsListener, isStorePage, setSharingModal, 
        sharingModal, publicLinks, userAccessLevel } = useContext(CustomerProductContext);

    const goToLibrary = () => {
        window.location.href = '/list-products?entity_id=' + product_id;
    }
    let complaints_bread_crumbs = !productDataLoader && <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
        <h2 className="manrope f-14 w-600" style={{ marginBottom: 10, display: 'flex', cursor: 'pointer' }}>
            <span onMouseDown={goToLibrary} className="store-bd">{"Product Library"}</span>
            {complaintsView ?
                <span style={{ color: "#9A9A9A", display: 'flex' }}>
                    <Link className='manrope clamp-text w-60 active-route-link-color' to={`/products/${product_id}`} onClick={() => setComplaintsView(false)}>
                        {' / ' + productAssetData.product_name}</Link>{' / Complaints'}
                </span> :
                <span className="active-route-link-color">{' / ' + productAssetData.product_name}</span>
            }
        </h2>
    </div>



    return (<>{
        productDataLoader ? <DottedLoader /> :
        userAccessLevel == 'restricted' ? 
            <EntityAccessDenied entityType='product' />
        :
            !complaintsView ? <>
                <ProductPageNavigation />
                <Row className='display-flex j-s a-s no-wrap product-section-1'>
                    <FeatureProductImages />
                    <ProductTextualInfo />
                </Row>
                <ProductVariantsContainer />
                {isStorePage && <SameBrandOtherProducts />}
                {!isStorePage &&
                    <ModelReferenceInfo context_for={"product_page"} />}
                <ProductAssetViewer />
                {!isStorePage &&
                    <ProductActionBar />}
                <FlagSiloModal context_type={"product"}/>
                <SharingModal 
                    entityId={product_id}
                    entityType={'product'}
                    setSharingModal={setSharingModal}
                    modalVisible={sharingModal}
                    publicLinks={publicLinks}
                    loggedInUserAccess={userAccessLevel}
                />
                <EditProductModal />
                <ProductMP4Modal />
                <ProductDimModal />
                <Product360Spin />
                <SellProductOnStore />

                <PaymentFailureModal
                    paymentFailureMessage={paymentFailureMessage}
                    setPaymentFailureModal={setPaymentFailureModal}
                    updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
                    paymentFailureModal={paymentFailureModal}
                />
                <InAppPurchaseLimitModal visible={inAppLimitExceededModal} setVisible={setInAppLimitExceededModal} />
            </> : <SiloComplaints
                productAssetData={productAssetData}
                bread_crumbs={complaints_bread_crumbs}
                companyId={companyId}
                username={cognito_username}
                product_id={product_id}
                complaints={complaints}
                viewComplaintsListener={viewComplaintsListener}
                setComplaintsView={setComplaintsView} />}
    </>)
}

export default ProductPage;