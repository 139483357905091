
import { Vector3 } from 'three/src/math/Vector3.js';
import { Euler } from 'three/src/math/Euler.js';
import { Raycaster} from 'three/src/core/Raycaster.js'
import { EventDispatcher } from 'three/src/core/EventDispatcher';
import { Box3 } from 'three/src/math/Box3.js';
import * as THREE from "three";
import Constants from './SceneCreator/Constants.js'
import FileConstants from './FileConstants.js';

export default function CameraControls( camera, domElement ) {
  if ( domElement === undefined ) {
    console.warn( 'CameraControls: The second parameter "domElement" is now mandatory.' );
    domElement = document.body;
  }

  this.domElement = domElement;
  this.isConnected = false;

  this.maxPanX = 0;
  this.minPanX = 0;
  this.maxPanZ = 0;
  this.minPanZ = 0;

  var camPositionHolder = new Vector3();
  var navPlanes = null;
  var bbNavPlanes = null;

  var euler = new Euler( 0, 0, 0, 'YXZ' );
  var vec = new Vector3();

  var raycaster = new Raycaster();

  this.near = FileConstants.cameraNearDefaultValue;
  this.clipping = false;
  this.lastCameraPositionOnFloor = 0;

  const scope = this;
  const PI_2 = Math.PI / 2;

  function onMouseMove( event ) {
    event.preventDefault();

		const movementX = - (event.movementX || event.mozMovementX || event.webkitMovementX || 0);
    const movementY = - (event.movementY || event.mozMovementY || event.webkitMovementY || 0);

    scope.rotateCamera(movementX, movementY);
  }

  this.toggleClipping = (clip) => { 
    this.clipping = clip;
  }

  this.setNear = (near) => {
    if (near != camera.near && near != 0){
      this.near = near;
      camera.near = this.near;
    }
  }

  this.setLastCameraPositionOnFloor = (camera_position) => {
    let new_camera_position = new THREE.Vector3( camera_position.x, camera_position.y, camera_position.z );
    this.lastCameraPositionOnFloor = new_camera_position;
  }

  this.getLastCameraPositionOnFloor = () => {
    return this.lastCameraPositionOnFloor;
  }

  this.rotateCamera = function(movementX, movementY) {
    euler.setFromQuaternion( camera.quaternion );

    euler.y -= movementX * 0.002;
    euler.x -= movementY * 0.002;

    euler.x = Math.max( - PI_2, Math.min( PI_2, euler.x ) );

    camera.quaternion.setFromEuler( euler );
  }

  this.connect = function() {
    this.isConnected = true;
    document.addEventListener( 'mousemove', onMouseMove, false );
  };

  this.disconnect = function() {
    this.isConnected = false;
    document.removeEventListener( 'mousemove', onMouseMove, false );
  };

  this.dispose = function() {
    this.disconnect();
  };

  this.setCamera = function( cam ) {
    camera = cam;
    this.near = camera.near
  };

  this.setNavPlanes = function ( navPlanesToUse ) {
  navPlanes = navPlanesToUse;
  bbNavPlanes = new Box3();
  for (let index = 0; index < navPlanesToUse.length; index++) {
    const navPlane = navPlanesToUse[index];
    bbNavPlanes.expandByObject(navPlane);
  }
  };

  this.getObject = function() {
    return camera;
  };

  this.getDirection = function () {

		var direction = new Vector3( 0, 0, - 1 );

		return function ( v ) {

			return v.copy( direction ).applyQuaternion( camera.quaternion );

		};

	}();

	this.moveForward = function ( distance ) {

		// move forward parallel to the xz-plane
		// assumes camera.up is y-up

		vec.setFromMatrixColumn( camera.matrix, 0 );

		vec.crossVectors( camera.up, vec );

    camPositionHolder.copy(camera.position);
		camPositionHolder.addScaledVector( vec, distance );

		this.setCameraPosition(camPositionHolder, false, vec.multiplyScalar(Math.sign(distance)));

	};

	this.moveRight = function ( distance ) {

		vec.setFromMatrixColumn( camera.matrix, 0 );

		camPositionHolder.copy(camera.position);
		camPositionHolder.addScaledVector( vec, distance );

		this.setCameraPosition(camPositionHolder, false, vec.multiplyScalar(Math.sign(distance)));

	};

  this.resetCameraPositionToLastFloorPosition = function () {
    camera.position.copy(this.lastCameraPositionOnFloor);
  }

	var rayOrigin = new Vector3();
	var rayDirection = new Vector3(0, -1, 0).normalize();
	this.setCameraPosition = function (newCameraPosition, allowMovementAlongBoundary = false, directionOfMovement) {
		// Keep the new position inside visible bounds
		if(allowMovementAlongBoundary) {
			if ( newCameraPosition.x > this.maxPanX ) {
                newCameraPosition.x = this.maxPanX;
            }
            else if ( newCameraPosition.x < this.minPanX ) {
                newCameraPosition.x = this.minPanX;
            }

            if ( newCameraPosition.z > this.maxPanZ ) {
                newCameraPosition.z = this.maxPanZ;
            }
            else if ( newCameraPosition.z < this.minPanZ ) {
                newCameraPosition.z = this.minPanZ;
			}

			camera.position.copy( newCameraPosition );
		}
		else {
			// If nav planes are defined, only allow movement on nav planes
			// Else allow movement inside complete bounding box of space
			if( navPlanes) {
				// get nav place intersect to act as origin for detecting collision
        if (!this.clipping){
          raycaster.set(camera.position, Constants._unit.aY);
          let navPlaneIntersect =  raycaster.intersectObjects( navPlanes, true )[ 0 ] || false;
          if (navPlaneIntersect) {
            rayOrigin.copy(navPlaneIntersect.point.clone());
            rayOrigin.y += 0.15;
          }
          else {
            rayOrigin.copy(newCameraPosition);
            rayOrigin.y = bbNavPlanes.max.y + 0.15;
          }

          if(directionOfMovement) {
            rayDirection.copy(directionOfMovement);
            rayDirection.y = -0.25;
            rayDirection.normalize();
          }

          raycaster.set(rayOrigin, rayDirection);
          raycaster.far = 10;

          let intersect =  raycaster.intersectObjects( navPlanes, true )[ 0 ] || false;
          if(intersect) {
            camera.position.copy(newCameraPosition);
            this.lastCameraPositionOnFloor = new THREE.Vector3(newCameraPosition.x, newCameraPosition.y, newCameraPosition.z)
          }
        }
        else{
          camera.position.copy(newCameraPosition);
        }
        camera.near = this.near
			}
			else if ( newCameraPosition.x <= this.maxPanX && newCameraPosition.x >= this.minPanX && newCameraPosition.z <= this.maxPanZ && newCameraPosition.z >= this.minPanZ ) {
				camera.position.copy( newCameraPosition );
			}
		}

	};
};

CameraControls.prototype = Object.create( EventDispatcher.prototype );
CameraControls.prototype.constructor = CameraControls;
