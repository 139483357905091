import React, { useEffect, useState } from "react";
import axios from "axios";
import AWS from "aws-sdk";
import { Upload, Tabs, Row, Col, Button, Select, Card, Table,message } from "antd";
import { LoadingOutlined, CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import ENVIRONMENT from "../../../../environments";
import CustomHeader from "../../CustomHeader";
import { Layout } from "antd";
import FileConstants from "../../../../FileConstants";
import CustomerMainLayout from "../CustomerMainLayout";
import * as Constants from "../../CustomerComponents/Constants";
import SuccessModal from "../../SuccessModal/SuccessModal";
import * as Styles from "../../../../Styles";

const { TabPane } = Tabs;
const { Footer } = Layout;
const { Dragger } = Upload;
const progress_bar = Styles.progress_bar;

const sample_sheet_name = 'Sample Spec Sheet.csv'

const validationStatus = {
    failed: "failed",
    success: "success",
};

const errorType = {
    missing_column: 'missing_column',
    blank_value: 'blank_value',
    missing_header: 'missing_header'
}

const { Header, Content } = Layout;
function SkylineBulkUpload() {
    const [csvValidated, setCsvValidated] = useState(false);
    const [csvValidationInprogress, setCsvValidationInprogress] = useState(false);
    const [uploadError, setUploadError] = useState("");
    const [uploadFailedMessage, setUploadFailedMessage] = useState("");
    const [specFileList, setSpecFileList] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [skylineCustomers, setSkylineCustomer] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [csvValidationStatus, setCsvValidationStatus] = useState("");
    const [csvRecordsError, setCsvRecordsError] = useState([]);
    const [csvValidationError, setCsvValidationError] = useState([]);
    const [bulkRequestId, setBulkRequestId] = useState(null);
    const [jobsCreatedModal, setJobsCreatedModal] = useState(false);
    const [apiInprogress, setAPIinProgress] = useState(false);
    const [loadingCustomer, setLoadingCustomers] = useState(false)

    useEffect(() => {
        getSkylineCustomers();
    }, []);

    const getSkylineCustomers = () => {
        let payload = {
            'company_id': FileConstants.COMPANY_ID
        }
        setLoadingCustomers(true);
        axios.post(ENVIRONMENT.CUSTOMER_PRESET_GET_BATCH, payload).then((res) => {
            if (res && res.data) {
                console.log('res.data', res.data)
                let customers = []
                res.data['data'].map((item) => {
                    customers.push(item['customer_username'])
                })
                setLoadingCustomers(false);
                setSkylineCustomer(customers);
            }
        });
    };

    const handleChangeSpecFile = (info, check = null) => {
        console.log(info);
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setSpecFileList(fileList);
        setUploading(true);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage("");
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(
                info.file.error.code.concat("   " + info.file.error.message)
            );
            if (info.file.error.code.includes("Credentials")) {
                setErrorMessage(
                    info.file.error.code.concat(
                        ". Your session has expired. Please reload the page."
                    )
                );
            } else if (info.file.error.code.includes("Network")) {
                setErrorMessage(
                    info.file.error.code.concat(
                        ". We are unable to upload due to an issue with your internet connection."
                    )
                );
            }
            setHasError(true);
            setUploading(false);
        }
    };

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for (const file of files) {
                if (file["status"] == "uploading") {
                    return "uploading";
                } else if (file["status"] == "error") {
                    return "error";
                } else if (file["status"] == "done") {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return "done";
        }
        return "not_started";
    };

    const handleRetry = (type_of_file) => {
        setUploading(true);
        let fileList = [];
        if (type_of_file == "spec_file") {
            fileList = [...specFileList];
        }

        fileList.map((file, index) => {
            if (file.status == "error") {
                const jwt_token = localStorage.getItem("all3d_jwt_token");
                const cognito_credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: "us-west-2:5f6370ec-44f1-4603-918e-afcdee671162",
                    Logins: {
                        "cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j":
                            jwt_token,
                    },
                });

                cognito_credentials.refresh(() => {
                    AWS.config.update({
                        region: "us-west-2",
                        credentials: cognito_credentials,
                    });
                    const S3 = new AWS.S3({
                        httpOptions: {
                            timeout: 240000,
                        },
                    });
                    const objParams = {
                        Bucket: ENVIRONMENT.BUCKET_NAME,
                        Key: "test_files" + "/" + file.uid + "/" + file.name,
                        ACL: "public-read",
                        Body: file.originFileObj,
                        ContentType: file.originFileObj.type,
                        CacheControl: 'no-cache'
                    };
                    S3.upload(objParams)
                        .on("httpUploadProgress", ({ loaded, total }) => {
                            setUploading(true);
                            file.status = "uploading";
                            const percent = Math.round((loaded / total) * 100);
                            file.percent = percent;
                            if (type_of_file == "spec_file") {
                                setSpecFileList(fileList);
                            }
                        })
                        .send((err, data) => {
                            if (err) {
                                file.status = "error";
                                setErrorMessage(err.code.concat(".  " + err.message));

                                if (err.code.includes("Credentials")) {
                                    setErrorMessage(
                                        err.code.concat(
                                            ". Your credentials have expired. Please refresh the page."
                                        )
                                    );
                                } else if (err.code.includes("Network")) {
                                    setErrorMessage(
                                        err.code.concat(
                                            ". We are unable to upload due to an issue with your internet connection."
                                        )
                                    );
                                }

                                setUploading(false);
                                setHasError(true);
                            } else {
                                file.status = "done";
                                file.error = "";
                                setUploading(false);
                                setHasError(false);
                                setErrorMessage("");
                            }
                            if (type_of_file == "spec_file" && index == fileList.length - 1) {
                                setSpecFileList(fileList);
                            }
                        });
                });
            }
        });
    };

    const validateCSVFile = () => {
        let payload = {
            csv_file: specFileList,
            customer_username: selectedCustomer,
            created_by: FileConstants.CUSTOMER_USERNAME
        };
        if(FileConstants.COMPANY_ID){
            payload['company_id'] = FileConstants.COMPANY_ID
        }

        setCsvValidationInprogress(true);
        axios.post(ENVIRONMENT.SKYLINE_CSV_VALIDATOR, payload).then((res) => {
            setCsvValidationInprogress(false);
            setCsvValidated(true);
            console.log(res);
            if (res.data["error_messages"]) {
                let validation_error = []
                let records_error = []
                for (let key in res.data["error_messages"]) {
                    let error = res.data['error_messages'][key]
                    if (error.error_type == errorType.missing_column) {
                        validation_error.push(error.error_messages[0])
                    }
                    else {
                        records_error.push(error)
                    }
                }
                setCsvValidationError(validation_error)
                console.log('recorddd = ', records_error)
                setCsvRecordsError(records_error)
                setCsvValidationStatus(validationStatus.failed);
            } else {
                setCsvValidationError([])
                setCsvRecordsError([])
                if (res['data']['bulk_job_id']) {
                    setBulkRequestId(res['data']['bulk_job_id'])
                }
                setCsvValidationStatus(validationStatus.success);
            }
        });
    };

    const submitRenderRequest = () => {
        let payload = {
            'bulk_request_id': bulkRequestId,
            'retailer_name': selectedCustomer,
            'customer_username': FileConstants.CUSTOMER_USERNAME,
            'company_id': FileConstants.COMPANY_ID,
            'use_customer_preset': true,
        }
        setAPIinProgress(true)
        axios.post(ENVIRONMENT.CREATE_AUTOMATION_JOBS_FROM_CSV, payload)
            .then(res => {
                if(res?.data?.statusCode == 200){
                    message.success(res.data.message);
                }
                else{
                    message.success(res?.data?.message);
                }

                setJobsCreatedModal(true);
                setAPIinProgress(false)
            })
            .catch(error => {
                message.success("Successfully created jobs");
                setJobsCreatedModal(true);
                setAPIinProgress(false);
            });
        return null;
    };

    const columns = [
        {
            title: 'Max Name',
            dataIndex: 'max_name',
            align: 'center'
        },
        {
            title: 'Fabric Name',
            dataIndex: 'fabric_name',
            align: 'center'
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            align: 'center'
        },
        {
            title: 'Product Type',
            dataIndex: 'category',
            align: 'center'
        },
        {
            title: 'Error',
            dataIndex: 'error_messages',
            align: 'left',
            render: text => <span>{text.length > 0 ?
                <ul>
                    {text.map(item => {
                        return <li>{item}</li>
                    })}
                </ul> : text}</span>
        }
    ]

    return (
        <CustomerMainLayout>
            <Row style={{ marginTop: 20 }}>
                <Col span={19} offset={3}>
                    <h3>Bulk Render Request</h3>
                    {!csvValidated ? (
                        <React.Fragment>
                            <h4 style={{ marginTop: 20 }}>
                                Select Customer for Bulk Upload{" "}
                                <span style={{ color: "red" }}>*</span>
                            </h4>
                            <Row>

                                <Col span={12}>
                                    <Select
                                        className="manrope f-10 black-55 tree-select-material"
                                        style={{ marginBottom: 20, width: "100%" }}
                                        placeholder="Select Customer"
                                        loading={loadingCustomer}
                                        onChange={(e) => setSelectedCustomer(e)}
                                        options={skylineCustomers.map((item) => ({
                                            label: item,
                                            value: item,
                                        }))}
                                    ></Select>
                                </Col>
                            </Row>

                            <h4 style={{marginBottom: 2}}>
                                Upload Spec Sheet <span style={{ color: "red" }}>*</span>
                            </h4>
                            <span style={{fontSize: 12,}}><a href={ENVIRONMENT.STATIC_FILES_BASE_URL + sample_sheet_name} >Download Sample Spec Sheet </a></span>
                            <div className="dragger">
                                <Dragger
                                    {...Constants.upload_bulk_request_csv}
                                    multiple={true}
                                    fileList={specFileList}
                                    listType="text"
                                    onDrop={(e) => {
                                        console.log(e);
                                        console.log("file dropped");
                                    }}
                                    onRemove={(file) => {
                                        const index = specFileList.indexOf(file);
                                        const newFileList = specFileList.slice();
                                        newFileList.splice(index, 1);
                                        setSpecFileList(newFileList);
                                        newFileList.forEach((file) => {
                                            if (file.status !== "error") {
                                                setHasError(false);
                                            } else {
                                                setHasError(true);
                                            }
                                        });
                                        return true;
                                    }}
                                    onChange={handleChangeSpecFile}
                                    accept=".csv"
                                    className="upload-box-hover-color dragger-size"
                                    progress={progress_bar}
                                >
                                    <p className="">
                                        <img
                                            className=""
                                            src={require("../../../../assets/images/upload.png")}
                                            alt=""
                                        ></img>
                                    </p>
                                    <p className="ant-upload-text manrope f-12 w-500 ">
                                        Drop your <b>Spec sheet</b> here , or &nbsp;
                                        <a href="#">Browse</a>
                                    </p>
                                    <p className="ant-upload-hint">SUPPORTED FORMATS: CSV</p>

                                    {validateFileUploads(specFileList) == "error" && (
                                        <Button
                                            onClick={() => handleRetry("spec_file")}
                                            disabled={specFileList.length === 0 ? true : false}
                                            loading={uploading}
                                            className="retry-btn multiple manrope f-12"
                                            ghost
                                        >
                                            {uploading ? (
                                                <span>Retrying...</span>
                                            ) : (
                                                "Upload Failed. Click to Retry."
                                            )}
                                        </Button>
                                    )}
                                </Dragger>
                            </div>
                        </React.Fragment>
                    ) : csvValidationStatus == validationStatus.success ? (
                        <Card
                            style={{ marginTop: 20 }}
                            title={
                                <span style={{ color: "green" }}>
                                    <CheckCircleFilled style={{ color: "green", fontSize: 25 }} />{" "}
                                    Validation Successful
                                </span>
                            }
                        >
                            <p style={{ padding: 25 }}>
                                Your csv file has been validated successfully. You can now
                                submit the render request.
                            </p>
                        </Card>
                    ) : (
                        <Card
                            style={{ marginTop: 20, marginBottom: 100 }}
                            title={
                                <span style={{ color: "red" }}>
                                    <CloseCircleFilled style={{ color: "red", fontSize: 25 }} />{" "}
                                    Validation Failed
                                </span>
                            }
                        >
                            <div style={{ padding: 25 }}>
                                {
                                    csvValidationError && csvValidationError.length > 0 ?
                                        <>
                                            <p>Validations on the spec sheet failed with the following errors</p>
                                            <p style={{ color: 'red' }}>
                                                <ul>
                                                    {csvValidationError.map(item => {
                                                        return <li>{item}</li>
                                                    })}
                                                </ul>
                                            </p>
                                        </>
                                        : csvRecordsError && csvRecordsError.length > 0 ?
                                            <>
                                                {console.log('csvRecordsError = ', csvRecordsError)}
                                                <p>Following errors occurred on the below mentioned records in the CSV. Please fix these errors and re-upload.</p>
                                                <Table
                                                    columns={columns}
                                                    bordered={true}
                                                    dataSource={csvRecordsError}
                                                />
                                            </> :
                                            <p>Something Went wrong please try reuploading the file or contact support if the issue persists.</p>
                                }
                            </div>

                            <Button className="modal-okay square font-14 float-right" style={{ margin: 20, marginTop: 0 }} onClick={() => { setCsvValidationError([]); setCsvRecordsError([]); setCsvValidated(false); setSelectedCustomer(''); setSpecFileList([]) }}>Reupload File</Button>
                        </Card>
                    )}
                </Col>
            </Row>

            <Layout className="footer-layout">
                <Footer className="footer footer-container">
                    {!csvValidated ? (
                        <Button
                            className="modal-okay square font-14 float-right"
                            key="upload_file"
                            disabled={
                                !selectedCustomer ||
                                validateFileUploads(specFileList) != "done" ||
                                csvValidationInprogress
                            }
                            onClick={validateCSVFile}
                        >
                            {csvValidationInprogress ? <LoadingOutlined spin /> : ""}
                            Upload File
                        </Button>
                    ) : (
                        <Button
                            className="modal-okay square font-14 float-right"
                            key="render"
                            disabled={csvValidationStatus == validationStatus.failed || apiInprogress}
                            onClick={submitRenderRequest}
                        >
                            {apiInprogress ? <LoadingOutlined spin /> : ""}
                            Submit Render Request
                        </Button>
                    )}
                </Footer>
            </Layout>
            <SuccessModal
                visible={jobsCreatedModal}
                heading={"Your request has been submitted successfully!"}
                text={"When the renders are generated you will be notified via email and the completed renders will show up in the bulk request section."}
                footer={[
                    <div className="justify-in-center">
                        <Button style={{ margin: "auto" }} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                            window.location.reload();
                        }}>
                            Okay
                        </Button>
                    </div>
                ]} />
        </CustomerMainLayout>
    );
}

export default SkylineBulkUpload;
