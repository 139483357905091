import React, { useEffect } from "react";
import { Row, Col, Tooltip } from "antd";
import { snakeToCamelWithSpaces } from "./utils";

const TextContainer = (props) => {
  return (
    <Tooltip title={<span className="manrope f-12 white">{props.value}</span>}>
      <Col span={8}>
        <div className="manrope f-14 w-500 grey-8c text-left">
          {props.name}
        </div>
        <div className="manrope f-16 w-500 black-26">
          {props.value} {props.showMBs ? "MBs" : ""}
        </div>
      </Col>
    </Tooltip>
  );
};

const ModelSpecifications = ({ model_info }) => {

  return (
    <div className="w-full">
      <Row className="basic-detail-container j-s-b">
        {Object.entries(model_info).map(([key, value]) =>
          value ? (
            <TextContainer
              name={snakeToCamelWithSpaces(key)}
              value={parseFloat(value.toFixed(2)).toLocaleString()}
              showMBs={key !== "vertex_count"}
            />
          ) : (
            <></>
          )
        )}
      </Row>
    </div>
  );
};

export default ModelSpecifications;
