import React from 'react';
import 'antd/dist/antd.css';
import ArtistMainLayout from '../ArtistMainLayout';
import ArtistMaterialsData from '../ArtistMaterialData';

class ArtistMaterials extends React.Component {
    render() {
        return (
            <ArtistMainLayout selected='6'>
                <ArtistMaterialsData />
                {/* Artist Data */}
            </ArtistMainLayout>
        );
    }
}

export default ArtistMaterials;
