import React from "react";
import "antd/dist/antd.css";
import { SearchOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Input} from "antd";
import AdminMainLayout from '../AdminMainLayout';

const { TabPane } = Tabs;

class AdminColors extends React.Component {
  state = { size: "small" };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"11"}>
        <Input
            prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Search Materials"
            onChange={this.changeUsername}
        />
        <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} centered animated={false}>
          <TabPane tab="System Colors" key="1">
            <p>Beige</p>
            <p>Black</p>
            <p>Blue</p>
            <p>Brass</p>
            <p>Bronze</p>
            <p>Brown</p>
            <p>Chrome</p>
            <p>Copper</p>
            <p>Gold</p>
            <p>Gray</p>
            <p>Green</p>
            <p>Iron</p>
            <p>Ivory/Cream</p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            
          </TabPane>
          <TabPane tab="Customer Specific Colors" key="2">          
          </TabPane>

        </Tabs>
      </AdminMainLayout>
    );
  }
}

export default AdminColors;