import React from 'react';
import { Input, Button, Form, Row, Col, Select, Checkbox } from 'antd';
import { EnvironmentFilled, LoadingOutlined } from '@ant-design/icons';
import AdminMainLayout from '../AdminMainLayout';
import axios from 'axios';
import { INVITE_USER, SKILL_GET_BATCH, AGENCY_GET_BATCH } from '../../../../environments/env';
import FileConstants from '../../../../FileConstants';
import SuccessModal from '../../SuccessModal/SuccessModal';
import WarningModal from '../../WarningModal/WarningModal';
import EditSkillMatrixModal from '../EditSkillMatrixModal/EditSkillMatrixModal';
import ENVIRONMENT from '../../../../environments'

const { Option } = Select;

const side_menu_selected_option = {
    customer: "9",
    test_artist: "8",
    artist: "8",
    agency_admin: "8",
    msprovider: "17",
    '': "9"
}

class AccountInvite extends React.Component {
    formRef = React.createRef();
    state = {
        email: '',
        agency_name: '',
        button_loader: false,
        user_type: '',
        failure_modal: false,
        success_modal: false,
        test_required: true,
        agency_admin: false,
        additional_invite_details: null,
        skill_list: [],
        artist_skills_modal_visible: false,
        all_categories: [],
        agencies: []
    }

    componentDidMount() {
        if (this.props && this.props.match) {
            console.log(this.props)
            if (this.props.match.path.includes("customer_invite")) {
                this.setState({
                    user_type: 'customer'
                });
            } else if (this.props.match.path.includes("artist_invite")) {
                let user_type = '';
                if(this.state.test_required)
                    user_type = 'test_artist'
                else
                    user_type = 'artist'
                this.setState({
                    user_type: user_type
                });
            } else {
                this.setState({
                    user_type: 'msprovider'
                });
            }
        }

        axios.post(SKILL_GET_BATCH, {})
        .then(res => {this.setState({skill_list: res['data']})})

        const payload = {
            output: 'serial'
        };

        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
          .then(res => {
            this.setState({
                all_categories: res.data
            })
        });

        axios.post(AGENCY_GET_BATCH, {})
        .then(res => {this.setState({agencies: res['data']})})
    }

    update_user_group = () => {
        if(this.state.test_required) 
            this.setState({
                user_type: 'test_artist'
            })
        else if(this.state.agency_admin)
            this.setState({
                user_type: 'agency_admin'
            })
        else
            this.setState({
                user_type: 'artist'
            })
    }

    isAgencyExist = (agency_name) => {
        let agency_exist = false;
        if (agency_name){
            for(let item of this.state.agencies){
                console.log('item = ', item)
                if(item.agency_name == agency_name && item.admin_username){
                    agency_exist = true
                }
            }
        }

        return agency_exist
    }

    handleSendInvite = () => {
        if(this.isAgencyExist(this.state.agency_name)){
            this.setState({error: "An agency with this name already exist."})
        }
        else{
            this.setState({
                button_loader: true
            });
            let payload = {}
            payload.email = this.state.email.toLowerCase();
            payload.type =  this.state.user_type;
            if(this.state.agency_admin)
                payload.agency_admin = this.state.agency_admin;
            if(this.state.agency_name)          
                payload.agency_name = this.state.agency_name;
            if(this.state.user_type == 'artist' || this.state.user_type == 'test_artist' || this.state.user_type == 'agency_admin')
                payload.test_required = this.state.test_required;
            payload.invited_by =  localStorage.getItem('username');
            if (this.state.user_type == 'customer') {
                payload.subscription_type = this.state.subscription_type
            }
            if (this.state.additional_invite_details){
                payload.additional_details = this.state.additional_invite_details;
            }
            console.log('Payload', payload);
            axios.post(INVITE_USER, payload)
            .then(res => {
                console.log(res);
                console.log(res.data);
                if(res.data == '1') {
                    this.setState({
                        success_modal: true
                    });
                }
                else if(res.data == '0') {
                    this.setState({
                        failure_modal: true
                    });
                }
                this.setState({
                    button_loader: false
                });
            });
        }

    }

    changeEmail = (event) => {
        this.setState({email: event.target.value})
    }

    changeAgencyName = (event) => {
        if(event.target.value){
            this.setState({agency_admin: true})
        }
        else{
            this.setState({agency_admin: false})
        }
        this.setState({agency_name: event.target.value})
    }

    selectSubscription = (value) => {
        this.setState({subscription_type: value})
    }

    setAdditionalInviteDetails = (payload) => {
        console.log('setting the details', payload)
        this.setState({additional_invite_details: payload}, () => {this.handleSendInvite()})
    }

    setArtistSkillModalVisibility = (visible) => {
        this.setState({artist_skills_modal_visible: visible})
    }

    render (){
        return (
            <AdminMainLayout selected={side_menu_selected_option[this.state.user_type]}>
                <Row  className="justify-space-between" style={{marginTop:30,marginBottom: 10}}>
                    <Col>
                        <h3 className="manrope f-36 w-900">{FileConstants.getUser(this.state.user_type)} Account Invite</h3>
                    </Col>
                </Row>
                <div className="custom-frm" style={{maxWidth: 400}}>
                    <Form className="input-form login-form" ref={this.formRef} onFinish={this.state.test_required ? this.handleSendInvite : () => this.setArtistSkillModalVisibility(true)}>
                        <div className="manrope f-14 black-55" style={{marginTop: 10, marginBottom: 10}}>Email <span style={{color: 'red'}}>*</span></div>
                        <Form.Item
                        name="email"
                        rules={[{type: 'email', message: 'Please enter a valid email.'}, 
                        {required: true, message: 'Please enter an email.'},
                        {pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: " Spaces are not allowed, whitespace found at the end."},
                        ]}
                       >
                        <Input
                            disabled={this.state.button_loader}
                            className="manrope f-16 black-55 library-search"
                            onChange={this.changeEmail}
                            placeholder="Email"
                            />
                        
                        </Form.Item>
                        {this.props.match.path.includes("artist_invite") ? 
                            <React.Fragment>
                                <div className="manrope f-14 black-55" style={{marginTop: 10, marginBottom: 10}}>Agency Name (if any)</div>
                                <Form.Item
                                    name="agency_name"
                                    rules={[{required: this.state.agency_admin, message: "Please enter agency name."}]}
                                >
                                    <Input
                                        disabled={this.state.button_loader}
                                        onChange={e => this.changeAgencyName(e)}
                                        className="manrope f-16 black-55 library-search"
                                        placeholder="Agency Name"
                                    />
                                </Form.Item>
                            </React.Fragment>
                        : ""}
                        
                       {this.state.user_type == 'customer' ? 
                       <Form.Item rules={[{required: this.state.user_type == 'customer', message: 'Please select a valid subscription package.'}]} name="payment_type">
                            <Select showArrow disabled={this.state.button_loader} onSelect={e => this.selectSubscription(e)} className="manrope f-16 black-55 tree-select-material" placeholder="Subscription Package">
                                <Option value={"freemium"} className="manrope f-16 select-view">Freemium</Option>
                                <Option disabled={true} value={"basic"} className="manrope f-16 select-view">Basic</Option>
                                <Option disabled={true} value={"pro"} className="manrope f-16 select-view">Pro</Option>
                                <Option disabled={true} value={"premium"} className="manrope f-16 select-view">Premium</Option>
                                <Option value={"enterprise"} className="manrope f-16 select-view">Enterprise</Option>
                            </Select>
                        </Form.Item>: ''}
                        {this.props.match.path.includes("artist_invite") ?
                        <React.Fragment>
                        <Form.Item
                            name="test_required"
                            initialValue={this.state.test_required}
                        >
                            <Checkbox checked={this.state.test_required} onChange={e => this.setState({test_required: e.target.checked}, ()=> this.update_user_group())}>
                                Require test on Sign up
                            </Checkbox>
                        </Form.Item>
                        
                        <Form.Item
                            name="agency_admin"
                            initialValue={this.state.agency_admin}
                        >
                            <Checkbox checked={this.state.agency_admin} onChange={
                                e => this.setState({agency_admin: e.target.checked}, 
                                ()=> {
                                        this.update_user_group(); 
                                        if(!this.state.agency_admin)
                                        {
                                            this.setState({agency_name: ''});
                                            this.formRef.current.setFieldsValue({
                                                agency_name: ''
                                              })
                                        }
                                    })
                                }
                            >
                                Invite as Agency Admin
                            </Checkbox>
                        </Form.Item>
                        </React.Fragment>
                        : ""}
                        {this.state.error ? (
                            <div
                                className="text-danger"
                            >
                                {this.state.error}
                            </div>
                            ) : (
                            ""
                        )}
                        <Button style={{marginTop: 15}} disabled={this.state.button_loader} id="assignment-submit-button" className="modal-okay square font-16"  type="primary" htmlType="submit">
                            Send Invite {this.state.button_loader  ? <LoadingOutlined/> : ''}
                        </Button>
                    </Form>
                </div>
                <SuccessModal
                 visible={this.state.success_modal}
                 heading={"Account Invite Sent!"}
                 text={"Account Invite has been sent on the entered email."}
                 footer={[
                    <div className="justify-in-center">
                        <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                            window.location.reload();}}>
                            Okay
                        </Button>
                    </div>
                 ]}/>
                 <WarningModal
                 visible={this.state.failure_modal}
                 onCancel={() => this.setState({failure_modal: false})}
                 heading={"User already exists!"}
                 text={"Oops. A user already exists on the email you entered."}
                 footer={[
                    <div className="justify-in-end">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => this.setState({failure_modal: false})}>
                            Okay
                        </Button>
                    </div>
                 ]}/>

            <EditSkillMatrixModal
                isTestArtist={true}
                inputInviteDetails={true}
                skill_list={this.state.skill_list}
                visible={this.state.artist_skills_modal_visible}
                set_visibility={this.setArtistSkillModalVisibility}
                all_categories={this.state.all_categories}
                proficient_categories={this.state.all_categories}
                pricing_method={null}
                username={null}
                testID={null}
                hideBillingFormInput={false}
                update_parent_state={this.setAdditionalInviteDetails}
                
            />
            </AdminMainLayout>
        );
    }
}

export default AccountInvite;