import React from 'react';
import {Modal, Button} from 'antd';

const PaymentFailureModal = (props) => {
    return (
        <Modal
        visible={props.paymentFailureModal}
        centered={true}
        onCancel={() => props.setPaymentFailureModal(false)}
        text={""}
        closable={false}

        footer={null}>
            <div style={{padding:"30px", textAlign:"center", paddingBottom: 24}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img style={{width: 50,height:50}} src={"/img/alert-triangle.svg"}></img>
                </div>
                <div>
                    <h2  className="manrope f-16 black-00" style={{marginTop: 6}}>{"Payment Failed"}</h2>
                    <p className="manrope f-14 black-55">{props.paymentFailureMessage + " Click on button below to try different payment method."}</p>
                </div>     
            </div>
            <div style={{display: "flex", justifyContent:"center"}}>
            
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => props.updatePaymentDetailsOnFailure()}>
                Update Payment Details
            </Button>
        </div>
        </Modal>
    )
}

export default PaymentFailureModal