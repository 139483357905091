// PaymentStatus.jsx

import React, {useState, useEffect} from 'react';
import {Elements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import FileConstants from '../../../../FileConstants';
import { Button } from 'antd/lib/radio';
import IntermediateMessage from '../../IntermediateMessage/IntermediateMessage';

const stripePromise = loadStripe(ENVIRONMENT.STRIPE_PUBLISH_KEY);

const PaymentStatus = (props) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState('in_progress');
  const [requestType, setRequestType] = useState('');
  const [requestUrl, setRequestUrls] = useState('');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({setupIntent}) => {
        
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (setupIntent.status) {
          case 'succeeded':
            setMessage('');
            let setupIntentValue = new URLSearchParams(window.location.search).get(
              'setup_intent'
            );
            console.log(props.request_id)
            axios.post(ENVIRONMENT.SAVE_CARD_CALLBACK, {
              username: localStorage.getItem('username'),
              request_id: props.request_id,
              setup_intent_secret: setupIntentValue
            })
              .then(res => {
                axios.post(ENVIRONMENT.GET_USER_PAYMENT_INFORMATION, {'action': 'delete_previous', 'username': localStorage.getItem('username')}).then((res) => {

                console.log('Response Data ', res);
                if (props.request_type == 'card_save_window')
                {
                  let action = new URLSearchParams(window.location.search).get("action");
                  window.opener.dispatchEvent(new CustomEvent('card_saved', {'detail': {'action': action}}));
                  window.close();
                }
                else {                
                  let id = -1;
                  let createdURL = '';
                  if (res.data && res.data.request) {
                    let id_type = FileConstants.getidType(props.request_type)
                    setRequestType(props.request_type);
                    if (id_type == 'material_id') {
                      id = res.data.request['body'][id_type];
                    } else {
                      if (res.data.request["variant_of"] && props.request_type.includes('variation')) {
                        id = res.data.request["variant_of"];
                      } else {
                        id = res.data.request[id_type];
                      }
                      
                    }
                  }
                  if (id== -1) {
                    setRequestType('account_details');
                  }
                  console.log(id)
                  createdURL = FileConstants.getRedirectUrls(props.request_type,id);
                  console.log(createdURL)
                  setStatus("succeeded");
                  if (createdURL.includes('request_type') || createdURL.includes('step')) {
                    setRequestUrls(createdURL+'&payment_redirected=true');
                  } else {
                    setRequestUrls(createdURL+'?payment_redirected=true');
                  }
                }});
                
            });
            break;

          case 'processing':
            setStatus("processing");
            setMessage("Processing payment details. We'll update you when processing is complete.");
            break;

          case 'requires_payment_method':
            
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Failed to process payment details. Please try another payment method.');
            axios.post(ENVIRONMENT.SAVE_CARD_CALLBACK, {
              username: localStorage.getItem('username'),
              request_id: props.request_id,
              setup_intent_secret: setupIntent
            })
              .then(res => {
                setStatus("requires_payment_method");
                console.log('Response Data ', res);
                let id = -1;
                let createdURL = '';
                if (res.data && res.data.request) {
                  let id_type = FileConstants.getidType(props.request_type)
                  if (id_type == 'material_id') {
                    id = res.data.request['body'][id_type];
                  } else {
                    id = res.data.request[id_type];
                  }
                }
                createdURL = FileConstants.getRedirectUrls(props.request_type,id);
                console.log(createdURL)
                let urls = '';
                if (createdURL.includes('request_type')) {
                  urls = createdURL+'&payment_failure=true&request_id='+props.request_id;
                } else {
                  urls = createdURL+'?payment_failure=true&request_id='+props.request_id;
                }
                setRequestUrls(urls);
            });
            break;
        }
      });
  }, [stripe]);

  useEffect(() => {
    console.log(status)
  },[status])

  // return message
  return (
    <div>
      {status == "succeeded" ?
      requestType == 'room_model' ?
      <IntermediateMessage
      icon={"/img/success_animation.gif"}
      heading={"Space Request Submitted Successfully!"}
      text={"Your Space creation request has been successfully submitted. You will be notified via email once your space is ready to be reviewed."}
      buttons={
      <div className="justify-in-center">
        <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = "/add-new-room"}>
          Upload Another Space
        </Button>
        <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
            window.location.href="/home"}}>
          Go To Dashboard
        </Button>
      </div>}/> : 
      requestType == 'variation_room_model' ? 
      <IntermediateMessage
        icon={"/img/success_animation.gif"}
        heading={"Space Request Submitted Successfully!"}
        text={"Your Space creation request has been successfully submitted. You will be notified via email once your space is ready to be reviewed."}
        buttons={
        <div className="justify-in-center">
          <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = "/add-new-room"}>
            Upload Another Space
          </Button>
          <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
              window.location.href="/home"}}>
            Go To Dashboard
          </Button>
        </div>}/>: 
        requestType == 'variation_product_model' ? 
        <IntermediateMessage
          icon={"/img/success_animation.gif"}
          heading={"Model Variation Requested Successfully!"}
          text={"Your Product Model's variation request has been successfully submitted. You will be notified via email once your Product Model is ready to be reviewed."}
          buttons={
          <div className="justify-in-center">
            <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
            Go Back to Product Page
            </Button>
            <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                window.location.href="/home"}}>
              Go To Dashboard
            </Button>
          </div>}/>: 
        requestType == 'product_model' ? 
        <IntermediateMessage
          icon={"/img/success_animation.gif"}
          heading={"Request Submitted Successfully!"}
          text={"Your Product Model creation request has been successfully submitted. You will be notified via email once your Product Model is ready to be reviewed."}
          buttons={
          <div className="justify-in-center">
            <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = "/add-new-product"}>
              Upload Another Product
            </Button>
            <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                window.location.href="/home"}}>
              Go To Dashboard
            </Button>
          </div>}/>: 
          requestType == 'existing_product_model' ? 
          <IntermediateMessage
            icon={"/img/success_animation.gif"}
            heading={"Request Submitted Successfully!"}
            text={"Your Product Model creation request has been successfully submitted. You will be notified via email once your Product Model is ready to be reviewed."}
            buttons={
            <div className="justify-in-center">
              <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = "/upload-product-model"}>
              Upload Another Product
              </Button>
              <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                  window.location.href="/home"}}>
                Go To Dashboard
              </Button>
            </div>}/>: 
            requestType == 'new_material' ? 
        <IntermediateMessage
          icon={"/img/success_animation.gif"}
          heading={"Material Creation Request Submitted Successfully!"}
          text={"Your request to create new material is now in progress. You will be informed via email as soon as the material is added to your library and ready to be used."}
          buttons={
          <div className="justify-in-center">
            <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = "/create-new-material"}>
            Create New Material
            </Button>
            <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                window.location.href="/home"}}>
              Go To Dashboard
            </Button>
          </div>}/>: 
      requestType == 'variation_room_model' ? 
      <IntermediateMessage
        icon={"/img/success_animation.gif"}
        heading={"Material Creation Request Submitted Successfully!"}
        text={"Your request to upload scanned material is now in progress. You will be informed via email as soon as the material is added to your library and ready to be used."}
        buttons={
        <div className="justify-in-center">
          <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = "/upload-scanned-material"}>
          Upload Another Material
          </Button>
          <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
              window.location.href="/home"}}>
            Go To Dashboard
          </Button>
        </div>}/>: 
        requestType == 'collection_room' ? 
        <IntermediateMessage
          icon={"/img/success_animation.gif"}
          heading={"Space Added Successfully!"}
          text={<span>Space has been added to your collection successfully! Go to your <a className="manrope f-16 blue link" href="/list-collections">Library</a> to start using this collection.</span>}
          buttons={
          <div className="justify-in-center">
            <Button className="modal-okay-gray square font-16" onClick={() => window.location.href =requestUrl}>
            Go To Store
            </Button>
            <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                window.location.href="/home"}}>
              Go To Dashboard
            </Button>
          </div>}/>: 
        requestType == 'room_prop' ? 
        <IntermediateMessage
          icon={"/img/success_animation.gif"}
          heading={"Space Added Successfully!"}
          text={<span>Space has been added to your collection successfully! Go to your <a className="manrope f-16 blue link" href="/list-collections">Library</a> to start using this collection.</span>}
          buttons={
          <div className="justify-in-center">
            <Button className="modal-okay-gray square font-16" onClick={() => window.location.href =requestUrl}>
            Go To Store
            </Button>
            <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                window.location.href="/home"}}>
              Go To Dashboard
            </Button>
          </div>}/>: 
        requestType == 'product_silo' ? 
        <IntermediateMessage
          icon={"/img/success_animation.gif"}
          heading={"Silo Images Requested Successfully!!"}
          text={"You have successfully requested your Silo Images. You will be notified via email once they are ready."}
          buttons={
          <div className="justify-in-center">
            {/* <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
            Go Back To Silo Tool
            </Button> */}
            <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                window.location.href="/home"}}>
              Go To Dashboard
            </Button>
          </div>}/>: 
          requestType == 'collection_product' ? 
          <IntermediateMessage
            icon={"/img/success_animation.gif"}
            heading={"Product Added Successfully!"}
            text={<span>Product has been added to your collection successfully! Go to your <a className="manrope f-16 blue link" href="/list-collections">Library</a> to start using this collection.</span>}
            buttons={
            <div className="justify-in-center">
              <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
              Go To Store
              </Button>
              <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                  window.location.href="/home"}}>
                Go To Dashboard
              </Button>
            </div>}/>: 
          requestType == 'product_prop' ? 
          <IntermediateMessage
            icon={"/img/success_animation.gif"}
            heading={"Product Added Successfully!"}
            text={<span>Product has been added to your collection successfully! Go to your <a className="manrope f-16 blue link" href="/list-collections">Library</a> to start using this collection.</span>}
            buttons={
            <div className="justify-in-center">
              <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
              Go To Store
              </Button>
              <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                  window.location.href="/home"}}>
                Go To Dashboard
              </Button>
            </div>}/>: 
          requestType == 'product_360_spin' ? 
          <IntermediateMessage
            icon={"/img/success_animation.gif"}
            heading={"360 Spin Requested Successfully!"}
            text={"You have successfully requested your new 360 spin. The current 360 spin will be replaced as soon as the new one is available. We will notify you once it is ready."}
            buttons={
            <div className="justify-in-center">
              <Button className="modal-okay-gray square font-16" onClick={() => window.location.href =requestUrl}>
              Go Back To Products Page
              </Button>
              <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                  window.location.href="/home"}}>
                Go To Dashboard
              </Button>
            </div>}/>: 
            requestType == 'product_video' ? 
            <IntermediateMessage
              icon={"/img/success_animation.gif"}
              heading={"MP4 Video Requested Successfully!"}
              text={"You have successfully requested your new mp4 video. The current mp4 video will be replaced as soon as the new one is available. We will notify you once it is ready."}
              buttons={
              <div className="justify-in-center">
                <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
                Go Back To Products Page
                </Button>
                <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                    window.location.href="/home"}}>
                  Go To Dashboard
                </Button>
              </div>}/>: 
            requestType == 'product_dimensional' ? 
            <IntermediateMessage
              icon={"/img/success_animation.gif"}
              heading={"Dimensional Image Requested Successfully!"}
              text={"You have successfully requested your new dimensional image. The current image will be replaced as soon as the new one is available. We will notify you once it is ready."}
              buttons={
              <div className="justify-in-center">
                <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
                Go Back To Products Page
                </Button>
                <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                    window.location.href="/home"}}>
                  Go To Dashboard
                </Button>
              </div>}/>: 
          requestType == 'scene_lifestyle_image' ? 
          <IntermediateMessage
            icon={"/img/success_animation.gif"}
            heading={"Lifestyle Images Requested Successfully!"}
            text={"You have successfully requested Lifestyle images. You will be notified via email once your renders ready."}
            buttons={
            <div className="justify-in-center">
              <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
              Go Back to Scene Creator
              </Button>
              <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                  window.location.href="/home"}}>
                Go To Dashboard
              </Button>
            </div>}/>: 
            requestType == 'scene_lifestyle_360' ? 
            <IntermediateMessage
              icon={"/img/success_animation.gif"}
              heading={"Lifestyle 360 Requested Successfully!"}
              text={"You have successfully requested Lifestyle 360s. You will be notified via email once your renders ready."}
              buttons={
              <div className="justify-in-center">
                <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
                Go Back to Scene Creator
                </Button>
                <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                    window.location.href="/home"}}>
                  Go To Dashboard
                </Button>
              </div>}/>: 
        requestType == 'scene_lifestyle_3dtour' ? 
        <IntermediateMessage
          icon={"/img/success_animation.gif"}
          heading={"3D Tour Requested Successfully!"}
          text={"You have successfully requested 3D Tour. You will be notified via email once the tour is ready."}
          buttons={
          <div className="justify-in-center">
            <Button className="modal-okay-gray square font-16" onClick={() => window.location.href = requestUrl}>
            Go Back to Scene Creator
            </Button>
            <Button style={{marginLeft: 10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
                window.location.href="/home"}}>
              Go To Dashboard
            </Button>
          </div>}/>: 
           requestType == 'account_details' ? <IntermediateMessage
           icon={"/img/success_animation.gif"}
           heading={"Card Saved Successfully!"}
           text={"You have successfully saved your payment details in this account."}
           buttons={
           <div className="justify-in-center">
             <Button className="modal-okay square font-16" htmlType="submit" onClick={() => {
                 window.location.href="/home"}}>
               Go To Dashboard
             </Button>
           </div>}/> : ''
      : 
      status == "in_progress" ?
      <IntermediateMessage
      status={'in_progress'}
      icon={ "/img/red_loader.gif"}
      heading={""}
      text={""}
      buttons={''}
      /> : 
      <IntermediateMessage
      status={'error'}
      icon={"/img/error.png"}
      heading={"An Error Occured In Saving Your Card Details"}
      text={"An error occured while processing the card information. You can either try again right now or save your current progress and try again later."}
      buttons={
      <div className="justify-in-center">
      {requestType.includes("material") || requestType.includes("product_model") || requestType.includes("room_model") ?
      <Button className="modal-okay square font-16" htmlType="submit" onClick={() => {
         window.location.href="/home"}}>
         Save Progress and Exit
      </Button>: ''}
      <Button style={{marginLeft:10}} className="modal-okay square font-16" htmlType="submit" onClick={() => {
       window.location.href=requestUrl}}>
       Try Again
      </Button>
      </div>}
      />
    }
    </div>
  );
};

function SaveCardCallback() {
  let all3d_redirect_url = new URLSearchParams(window.location.search).get("all3d_redirect_url");
  let request_id = new URLSearchParams(window.location.search).get("request_id");
  let request_type = new URLSearchParams(window.location.search).get("request_type");
  console.log(request_id)
    return (
        <Elements stripe={stripePromise}>
            <PaymentStatus all3d_redirect_url={all3d_redirect_url} request_type={request_type} request_id={request_id}/>
        </Elements>      
    );
  };

export default SaveCardCallback;