import React, { useContext } from 'react';
import { Row, Col, Tooltip, Radio, Popover, Input, Button } from 'antd';
import './AddProductsToScene.scss'
import { FilterOutlined, SyncOutlined } from '@ant-design/icons';
import AddProductsToSceneContext from '../../ContextFiles/AddProductsToSceneContext'

const ProductSearch = () => {
    const { libraryType, onChangeLibraryType,
        changeSearchValue, openFilters, 
        filterTagFunction, refreshProducts, project_ids
     } = useContext(AddProductsToSceneContext);

    return (
        <>
            <Row className='justify-space-between w-100 mb-20'>
                <Col span={8} className='justify-in-start'>
                    <Radio.Group
                        value={libraryType}
                        onChange={onChangeLibraryType}
                        buttonStyle="solid">
                        {project_ids.length > 0 ? 
                        <Popover content={"Add products from Project Products"}>
                            <Radio.Button className='manrope f-14 lh-28' value="ProjectProducts">Project Products</Radio.Button>
                        </Popover>
                        : ''}
                        <Popover content={"Add products from library"}>
                            <Radio.Button className='manrope f-14 lh-28' value="library">Product Library</Radio.Button>
                        </Popover>
                        <Popover content={"Add products from Store"}>
                            <Radio.Button className='manrope f-14 lh-28 store-text' value="store">Store</Radio.Button>
                        </Popover>
                    </Radio.Group>
                </Col>
                <Col span={16} className="justify-in-end">
                    <Input className="manrope f-14 grey-99 pd-8 search-bar-width search-bar-border" placeholder="Search Products"
                        onChange={e => { changeSearchValue(e.target.value) }} />

                    <Tooltip title={<span className="manrope f-12 white">Apply Filters</span>}>
                        <Button icon={<FilterOutlined className='justify-in-center filter-icon' />} className="icon-button-container filter-btn-icon ml-8" onClick={openFilters}>
                        </Button>
                    </Tooltip>
                    <Tooltip title={<span className="manrope f-12 white">Refresh</span>}>
                        <Button icon={<SyncOutlined className='justify-in-center filter-icon' />} className="icon-button-container filter-btn-icon ml-8" onClick={refreshProducts}>
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            <Row className='w-100'>
                {filterTagFunction}
            </Row>
        </>
    );

}

export default ProductSearch;
