import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { BorderOuterOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, Breadcrumb } from 'antd';

import CustomHeader from '../../CustomHeader';

import { ReactComponent as MaterialIcon } from '../../../../icons/material.svg'
import { ReactComponent as ProductIcon } from '../../../../icons/product.svg'
import { ReactComponent as RoomIcon } from '../../../../icons/space.svg'
import { ReactComponent as JobDeskIcon } from '../../../../icons/job desk.svg'
import { ReactComponent as TimeLogIcon } from '../../../../icons/time log.svg'
import { ReactComponent as SettingIcon } from '../../../../icons/settings.svg'
import { ReactComponent as AgencyIcon } from '../../../../icons/agency-icon.svg'
import { ReactComponent as GuidelinesIcon } from '../../../../icons/guidelines-icon.svg'
import { ReactComponent as ComplaintIcon } from '../../../../icons/complaint.svg'
import { ReactComponent as EvaluateArtistIcon } from '../../../../icons/evaluate-artist.svg'

import { withRouter } from "react-router"
import { CognitoState } from 'react-cognito';

const { Header, Content, Sider } = Layout;

class ArtistMainLayoutContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            selected: props.selected,
            is_superartist: false,
            is_test_artist: false,
            agency_admin: false
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    collapseSlider = () => {
        this.setState({
            collapsed: true,
        });
    }

    expandSlider = () => {
        this.setState({
            collapsed: false,
        });
    }
    

    componentDidMount = () => {
        // Check if the current user is super Artist
        var groups = []
        if (this.props.state === CognitoState.LOGGED_IN || this.props.state === CognitoState.LOGGING_IN) {
          if ('cognito:groups' in this.props.user['signInUserSession']['idToken']['payload']) {
              var groups = this.props.user['signInUserSession']['idToken']['payload']['cognito:groups'];
          }
        }
    
        if(groups.indexOf('SuperArtist') !== -1){
          this.setState({
            is_superartist: true
          })
        }
        else if(groups.indexOf('TestArtist') !== -1){
            this.setState({
              is_test_artist: true
            })
          }
        else if(groups.indexOf('AgencyAdmin') !== -1){
            this.setState({
              agency_admin: true
            })
          }
      }

    handleMenuItemChange = (e) => {
        console.log(e.key);
        if (e.key == 1) {
            this.props.history.push('/artist_products');
        }
        if (e.key == 2) {
            this.props.history.push('/artist_rooms');
        }
        if (e.key == 3) {
            this.props.history.push('/artist_job_desk');
        }
        if (e.key == 4) {
            this.props.history.push('/artist_invoices');
        }
        if (e.key == 5) {
            this.props.history.push('/artist_settings');
        }
        if (e.key == 6) {
            this.props.history.push('/artist_materials');
        }
        if (e.key == 7) {
            this.props.history.push('/artist_complaints');
        }
        if (e.key == 8) {
            this.props.history.push('/agency');
        }
        if (e.key == 9) {
            this.props.history.push('/artist_guidelines');
        }
        if (e.key == 10) {
            this.props.history.push('/artist_test_list');
        }

    }

    render() {
        return (
            <div>
                <Header className="header">
                    <CustomHeader toggleMethod={this.toggle}></CustomHeader>
                </Header>
                <Layout  style={{ minHeight: "calc(100vh - 55px)" }}>
                    <Sider trigger={null} collapsible collapsed={this.state.collapsed} style={{ background: '#ffffff',margin : 0}} onMouseEnter={this.expandSlider} onMouseLeave={this.collapseSlider}>
                        <span>
                        <Menu theme="light" mode="inline" defaultSelectedKeys={[this.state.selected]}>
                            <Menu.Item className='artist-menu-icon' key="1" onClick={this.handleMenuItemChange} style={{marginTop:"20px"}}>
                                <span className='anticon'><ProductIcon/></span>
                                <span className='manrope f-14'>Products</span>
                            </Menu.Item>
                            <Menu.Item className='artist-menu-icon' key="2" onClick={this.handleMenuItemChange}>
                                <span className='anticon'><RoomIcon/> </span>
                                <span className='manrope f-14'>Rooms</span>
                            </Menu.Item>
                            <Menu.Item className='artist-menu-icon' key="6" onClick={this.handleMenuItemChange}>
                                <span className='anticon'><MaterialIcon/></span>
                                <span className='manrope f-14'>Materials</span>
                            </Menu.Item>
                            {!this.state.is_test_artist ?
                            <Menu.Item className='artist-menu-icon' key="3" onClick={this.handleMenuItemChange}>
                                <span className='anticon'><JobDeskIcon/></span>
                                <span className='manrope f-14'>Job Desk</span>
                            </Menu.Item> : ''}
                            <Menu.Item className='artist-menu-icon' key="4" onClick={this.handleMenuItemChange}>
                                <span className='anticon'><TimeLogIcon/></span>
                                <span className='manrope f-14'>Time Log</span>
                            </Menu.Item>
                            {this.state.is_superartist ?
                            <Menu.Item className='artist-menu-icon' key="7" onClick={this.handleMenuItemChange}>
                                <span className='anticon'><ComplaintIcon/></span>
                                <span className='manrope f-14'>Complaints</span>
                            </Menu.Item>
                            : ""}
                            {this.state.is_superartist ?
                            <Menu.Item className='artist-menu-icon' key="10" onClick={this.handleMenuItemChange}>
                                <span className='anticon'>
                                <EvaluateArtistIcon />
                                </span>
                                <span className='manrope f-14'>Evaluate Artist</span>
                            </Menu.Item>
                            : ""}
                            {this.state.agency_admin ?
                            <Menu.Item className='artist-menu-icon' key="8" onClick={this.handleMenuItemChange}>
                                <span className='anticon'><AgencyIcon /></span>
                                <span className='manrope f-14'>Agency</span>
                            </Menu.Item>
                            : ""}
                            <Menu.Item className='artist-menu-icon' key="9" onClick={this.handleMenuItemChange}>
                            <span className='anticon'><GuidelinesIcon/></span>
                                <span className='manrope f-14'>Guidelines</span>
                            </Menu.Item>
                            <Menu.Item className='artist-menu-icon' key="5" onClick={this.handleMenuItemChange}>
                            <span className='anticon'><SettingIcon/></span>
                                <span className='manrope f-14'>Settings</span>
                            </Menu.Item>
                        </Menu>
                        </span>
                    </Sider>
                    <Layout>
                        <Content className="layout-container" id="layout-section">
                            {this.props.children}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

ArtistMainLayoutContainer.propTypes = {
    user: PropTypes.object,
    attributes: PropTypes.object,
    state: PropTypes.string,
  };
  
  const mapStateToProps = state => ({
    state: state.cognito.state,
    user: state.cognito.user,
    attributes: state.cognito.attributes,
  });

const ArtistMainLayout = connect(mapStateToProps, null)(ArtistMainLayoutContainer);

export default withRouter(ArtistMainLayout);
