import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import $ from 'jquery';
import { ArrowRightOutlined, ExclamationCircleOutlined,DownloadOutlined, PaperClipOutlined, LoadingOutlined, DownOutlined,} from '@ant-design/icons';
import {    Dropdown, Row, Col, Button, message, Card, Modal, Tabs, Select, Checkbox, Radio, Form, Tooltip, Image, Input,Upload, Typography, Menu, InputNumber, Skeleton} from 'antd';
import AdminMainLayout from '../AdminMainLayout';
import DottedLoader from "../../DottedLoader";
import PanoramaThumbnailViewer from '../../CustomerComponents/PanoramaThumbnailViewer';
import VirtualShowroom from '../../../../VirtualShowroom';
import ENVIRONMENT from '../../../../environments';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as Constants from "../../CustomerComponents/Constants";
import ThreeSixtyViewer from '../../../../ThreeSixtyViewer';
import _ from "lodash" // Import the entire lodash library
import AWS, { Support } from "aws-sdk";
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Paragraph } = Typography;

const baseURL = ENVIRONMENT.SCENE_BASE_URL;
var sceneInput= '';
var tourInput='';
var imageInput = '';

function saveToZip (filename, urls) {
    message.info('Generating Images Archive, your download will start shortly.', 6);
    const zip = new JSZip()
    urls.forEach((url)=> {
        const blobPromise = fetch(url, {cache: 'no-store'}).then(r => {
            if (r.status === 200) return r.blob()
            return Promise.reject(new Error(r.statusText))
        })
        const name = url.split('/').slice(-1)[0] 
        zip.file(name, blobPromise)
    })
  
    zip.generateAsync({type:"blob"})
        .then(blob => saveAs(blob, filename))
        .catch(e => console.log(e));
}

const radioStyleInline = {
    display: 'inline',
    height: '20px',
    lineHeight: '120%'
};

class AdminSceneRenders extends React.Component {

    virtualShowroom = null;
    virtualShowroomFlag = null;
    threeSixtyViews = [];
    state = {
        renders: [],
        isLoading: false,
        isFullScreen: false,
        visible: false,
        embed_visible: false,
        name: '',
        edit_permission: false,
        three_sixties: [],
        threeSixtyClick: true,
        percent: 0,
        threed_tour: "",
        activeTabKey: 1,
        display360: false,
        displayimg: false,
        displayTour: false,
        scene_shared: false,
        scene_embed: false,
        flag_scene_modal: false,
        screenshotSceneList: [],
        screenshotImageList: [],
        screenshotTourList: [],
        checkedScene: [],
        checkSceneState: false,
        selectAllScenesFlag: false,
        selectAllImagesFlag: false,
        flag_image_modal: false,
        checkedImage: [],
        checkImageState: false,
        single_image: false,
        selected_image: '',
        selected_scene: '',
        single_scene: false,
        flag_tour_modal: false,
        username: '',
        buttonLoader: false,
        triggerUpdate: false,
        reportSuccess: false,
        image_visible: false,
        expand_image: false,
        config_modal: false,
        dpi: 300,
        image_format: 'jpg',
        resolution_status: 1,
        resolution_value: '4096x2560',
        imageResArr: [],
        aspect_ratio_selected: 1.6,
        orientation: 'Landscape',
        image_width: 1,
        image_height: 1,
        image_width_res: 1,
        image_height_res: 1,
        success_res: false,
        camera_index: 1,
        cameras: [],
        display_width: 1,
        display_height: 1,
        request_image_loader: false,
        design: '',
        render_by_date: [],
        config_exists: -1,
        config_data: {},
        uploading: false,
        errorMessage: '',
        hasError: false,
        customer_username: '',
        platform: ''
    };

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                isFullScreen: false
            });
        }
    }

    cancelSuccessModal = () => {
        this.setState({
            success_res: false
        }, () => {
            window.location.reload();
        });
    }

    setResolutions(width,height,type) {
        // calculate high, medium, low resolutions;
        let imgResolutions = [];
		if (type == 'Portrait') {
			let res4k = Math.round(4096 * (width/height));
			let res2k = Math.round(2048 * (width/height));
			imgResolutions.push(res4k + 'x4096'); // high
            imgResolutions.push(res2k + 'x2048'); // medium
        
		}
		else {
			let res4k = Math.round(4096 * (height/width));
			let res2k = Math.round(2048 * (height/width));			
			imgResolutions.push('4096x' + res4k); // high
			imgResolutions.push('2048x' + res2k); // medium
        }
        
		return imgResolutions;
    }

    onResolutionChange = (e) => {
        this.setState({
            resolution_status: e.target.value
        });
        console.log(e.target.value);    
        if (e.target.value == 1) {
            this.setState({
                resolution_value: this.state.imageResArr[0]
            });
        }
        else if (e.target.value == 2) {
            this.setState({
                resolution_value: this.state.imageResArr[1]
            });
        }
    }

    onImageWidthChange = (value) => {
        let widthR = 1;
        let heightR = 1;
        let aspect_ratio = 1;
        if (this.state.aspect_ratio_selected == 1.6) {
            widthR = 16;
            heightR = 10;
        } else if (this.state.aspect_ratio_selected == 1.78) {
            widthR = 16;
            heightR = 9;
        } else if (this.state.aspect_ratio_selected == 1.91) {
            widthR = 1.91;
            heightR = 1;
        } else if (this.state.aspect_ratio_selected == 0.56) {
            widthR = 9;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.63) {
            widthR = 10;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.52) {
            widthR = 1;
            heightR = 1.91;
        }
        else if (this.state.aspect_ratio_selected == 1) {
            widthR = 1;
            heightR = 1;
        }
        aspect_ratio = widthR / heightR;
        
        this.setState({
            image_width: value,
            image_height: value / aspect_ratio
        });
    }

    onImageHeightChange = (value) => {
        let widthR = 1;
        let heightR = 1;
        let aspect_ratio = 1;
        if (this.state.aspect_ratio_selected == 1.6) {
            widthR = 16;
            heightR = 10;
        } else if (this.state.aspect_ratio_selected == 1.78) {
            widthR = 16;
            heightR = 9;
        } else if (this.state.aspect_ratio_selected == 1.91) {
            widthR = 1.91;
            heightR = 1;
        } else if (this.state.aspect_ratio_selected == 0.56) {
            widthR = 9;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.63) {
            widthR = 10;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.52) {
            widthR = 1;
            heightR = 1.91;
        }
        else if (this.state.aspect_ratio_selected == 1) {
            widthR = 1;
            heightR = 1;
        }
        aspect_ratio = widthR / heightR;
        let recalculated_width = value * aspect_ratio
        this.setState({
            image_width: recalculated_width,
            image_height: value,
        });
    }

    onChangeWidthResolution = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 10000) {
            this.setState({
                image_width_res: 1
            });
            new_aspect = 1/this.state.image_height_res;
            res = '1x' + this.state.image_height_res;
        } else {
            this.setState({
                image_width_res: value
            });
            new_aspect = value/this.state.image_height_res;
            res = value + 'x' + this.state.image_height_res;
        }
        this.setState({
            resolution_value: res,
            aspect_ratio_selected: new_aspect
        }); 
    }

    onChangeHeightResolution = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 10000) {
            this.setState({
                image_height_res: 1
            });
            new_aspect = this.state.image_width_res/1;
            res = this.state.image_width_res + 'x1';
        } else {
            this.setState({
                image_height_res: value
            });
            new_aspect = this.state.image_width_res/value;
            res = this.state.image_width_res + 'x' + value;
        }
        this.setState({
            resolution_value: res,
            aspect_ratio_selected: new_aspect
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    handleSceneScreenshots = info => {
        let fileList = [...info.fileList];
        this.setState({
            screenshotSceneList: fileList,
            uploading: true
        });
        if (info.file.status === "done") {
            this.setState({
                hasError: false,
                uploading: false,
                errorMessage: ''
            });
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            this.setState({
                hasError: true,
                uploading: false,
                errorMessage: info.file.error.message.concat('   ' + info.file.error.message)
            });
            if (info.file.error.code.includes('Credentials')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. Your session has expired. Please reload the page.')
                });
            }  else if (info.file.error.code.includes('Network')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. We are unable to upload due to an issue with your internet connection.')
                });
            }
           
        }
    };

    handleImageScreenshots = info => {
        let fileList = [...info.fileList];
        this.setState({
            screenshotImageList: fileList
        });

        this.setState({
            screenshotImageList: fileList,
            uploading: true
        });
        if (info.file.status === "done") {
            this.setState({
                hasError: false,
                uploading: false,
                errorMessage: ''
            });
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            this.setState({
                hasError: true,
                uploading: false,
                errorMessage: info.file.error.message.concat('   ' + info.file.error.message)
            });
            if (info.file.error.code.includes('Credentials')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. Your session has expired. Please reload the page.')
                });
            }  else if (info.file.error.code.includes('Network')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. We are unable to upload due to an issue with your internet connection.')
                });
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    handleTourScreenshots = info => {
        let fileList = [...info.fileList];

        this.setState({
            screenshotTourList: fileList,
            uploading: true
        });
        if (info.file.status === "done") {
            this.setState({
                hasError: false,
                uploading: false,
                errorMessage: ''
            });
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            this.setState({
                hasError: true,
                uploading: false,
                errorMessage: info.file.error.message.concat('   ' + info.file.error.message)
            });
            if (info.file.error.code.includes('Credentials')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. Your session has expired. Please reload the page.')
                });
            }  else if (info.file.error.code.includes('Network')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. We are unable to upload due to an issue with your internet connection.')
                });
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    cancelReportSceneIssue = () => {
        sceneInput = '';
        this.setState({triggerUpdate: !this.state.triggerUpdate});
        this.setState({
            flag_scene_modal: false,
            screenshotSceneList: [],
            checkedScene: [],
            checkSceneState: false,
            selectAllScenesFlag: [],
            single_scene: false
            //selected_scene: '',
        });
    }

    cancelReportImageIssue = () => {
        imageInput = '';
        this.setState({
            flag_image_modal: false,
            single_image: false,
            screenshotImageList: [],
            checkedImage: [],
            checkImageState: false,
            selected_image: '',
            selectAllImagesFlag: false
        });
    }

    expandImage = (name) => {
        let temp_arr = this.state.image_visible;
        this.state.renders.map(x=> {
            if (x.filename == name) {
                temp_arr[x.filename.split(".")[0]] = true;
            } else {
                temp_arr[x.filename.split(".")[0]] = false;
            }
        });

        this.setState({
            image_visible: temp_arr,
            expand_image: true
        });
    }

    changeVisibility = (visible, prevVisible) => {
        let temp_arr = [];
        if (this.state.flag_image_modal || this.state.config_modal) {
            this.state.renders.map(x => {
                temp_arr[x.filename.split(".")[0]] = false;
            });
    
            this.setState({
                image_visible: temp_arr
            });
        } 
        else {
            if (this.state.expand_image) {
                this.state.renders.map(x=> {
                    if (this.state.image_visible[x.filename.split(".")[0]] && !visible) {
                        temp_arr[x.filename.split(".")[0]] = false;
                    }
                    else if (this.state.image_visible[x.filename.split(".")[0]] && visible) {
                        temp_arr[x.filename.split(".")[0]] = true;
                    } else {
                        temp_arr[x.filename.split(".")[0]] = false;
                    }
                });
        
                this.setState({
                    image_visible: temp_arr,
                    expand_image: false
                });
            }
        }
    }

    handleEmbedOk = e => {
        this.setState({
            embed_visible: false,
            scene_embed: true
        });
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($("#iframe-text").text()).select();
        document.execCommand("copy");
        $temp.remove();
        message.success('Embed link copied to clipboard!');
    };

    showEmbedModel = () => {
        this.setState({
            embed_visible: true,
        });
    }

    sceneShare = () => {
        this.setState({
            scene_shared: false
        })
    }

    	
    downloadSceneImagesArchive = () => {
        let downloadData = [];
        if (this.state.three_sixties != undefined) {
            if (this.state.three_sixties.length == 1) {
                downloadData.push((baseURL + this.props.match.params.id + '/' + this.state.three_sixties[0].filename).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform)));
            }
            else if (this.state.three_sixties.length > 0) {
                this.state.three_sixties.map((url) => {
                downloadData.push((baseURL + this.props.match.params.id + '/' + url.filename).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform)));
                });
            }
        }
        saveToZip ('Scene Images.zip', downloadData);
    }
    
    sceneEmbed = () => {
        this.setState({
            scene_embed: false,
            buttonLoader: true
        })
    }

    handleShare = values => {
        let body = this.generateSharingPayload(values);
        axios.post(ENVIRONMENT.SHARE_SCENE, { body })
            .then(res => {
                this.setState({
                    visible: false,
                    scene_shared: true
                });
            });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    embedCancel = () => {
        this.setState({
            scene_embed: false
        })
    }
    handleEmbedCancel = e => {
        this.setState({
            embed_visible: false,
        });
    };

    componentDidMount() {
        this.setState({ isLoading: true });
        var payload = { "scene_id": this.props.match.params.id };
        axios.post(ENVIRONMENT.GET_SCENE_DETAIL, payload)
            .then(res => {
                this.setState({
                    username: localStorage.getItem('username'),
                    customer_username: res.data.customer_username
                });
                var final_renders_json = res.data['final_renders'];
                var three_sixties_json = res.data['three_sixties'];
                var scene_platform = res.data['platform']
                var final_renders = [];
                var edit_permission = true;
                if(localStorage.getItem("username") !== res.data['customer_username']){
                    edit_permission = false;
                }
                var final_renders_obj = {};
                final_renders_obj = [];
                let dispimg = false;
                if (final_renders_json !== "" && final_renders_json !== undefined) {
                    final_renders_obj = JSON.parse(final_renders_json);
                    console.log(final_renders_obj.final_renders);

                    if(final_renders_obj.final_renders != undefined) {
                        final_renders = final_renders_obj.final_renders;
                        final_renders.map((name,index) => {
                            let camera_tokens = name.filename.split("_");
                            if(camera_tokens[1] != undefined){
                                let cameraIndex = parseInt(camera_tokens[1]);
                                if(cameraIndex >= this.state.camera_index){
                                    this.setState({
                                        camera_index: cameraIndex + 1
                                    });
                                }
                            }
                        });
                        if (final_renders_obj["final_renders"].length != 0) {
                            dispimg = true;
                        } 
                    }
                }

                let groups = final_renders.reduce((groups, render) => {
                    let date = new Date(render.time_updated).toDateString();
                    if (!groups[date]) {
                      groups[date] = [];
                    }
                    groups[date].push(render);
                    return groups;
                  }, {});

                  console.log(groups);
                  
                  // To add it in the array format instead
                  let groupArrays = Object.keys(groups).map((date) => {
                    return {
                      date,
                      render: groups[date]
                    };
                  });
                  
                console.log(groupArrays);

                this.setState({
                    render_by_date: groupArrays
                });

            
                console.log("Final Renders &", final_renders);
                console.log(res.data);
                var three_sixties_object = {};
                three_sixties_object["three_sixties"] = [];
                if (three_sixties_json !== "" && three_sixties_json !== undefined) {
                    three_sixties_object = JSON.parse(three_sixties_json);
                }
                
                for (let percent = 0 ; percent <= 100 ; percent ++) {
                    this.setState({percent: percent});
                }
                let disp3D=false;
                let threed_tour = "";
                if("threed_tour" in res.data) {
                    threed_tour = JSON.stringify(JSON.parse(res.data["threed_tour"])["threed_tour"]);
                    disp3D=true;
                }
                
                let disp360 = false;
                if (three_sixties_object["three_sixties"].length != 0 && this.props.match.params.id != undefined) {
                    disp360 = true;
                } 
               

                var design = res.data['design'];
                if (design) {
                    this.setState({
                        design: design
                    });
                    var design_obj = JSON.parse(design);
                    console.log(design)
                    design_obj['design']['cameras'].forEach((cameraObj) => {
                        if(cameraObj.camera_type == "still"){
                            let cameraToks = cameraObj['camera_name'].split("_");
                            if(cameraToks[1] != undefined){
                                let cameraIndex = parseInt(cameraToks[1]);
                                if(cameraIndex >= this.state.camera_index){
                                    this.setState({
                                        camera_index: cameraIndex + 1
                                    });
                                }
                            }
                            let camera_render = null;
                            
                            let camera_render_file_name = cameraObj['camera_name'] + "." + cameraObj['image_format'];
                            if (final_renders.includes(camera_render_file_name)){
                                camera_render = ENVIRONMENT.getBaseURL(scene_platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + cameraObj['camera_name'] + "." + cameraObj['image_format'];
                            }
                            else {
                                camera_render = ENVIRONMENT.getBaseURL(scene_platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + cameraObj['camera_name'] + "_preview.png";
                            }
                            this.addCamera(cameraObj['camera_name'], '', cameraObj.image_width, cameraObj.image_height, camera_render);
                        }
                    });
                    console.log("Design obj",design_obj);
                }
                console.log('three sixty',three_sixties_object["three_sixties"]);
                
                this.setState({
                    isLoading: false,
                    renders: final_renders,
                    name: res.data['name'],
                    edit_permission: edit_permission,
                    three_sixties: three_sixties_object["three_sixties"],
                    threed_tour: threed_tour,
                    activeTabKey: 1,
                    displayTour: disp3D,
                    display360: disp360,
                    displayimg: dispimg,
                    platform: scene_platform
                }, () => {
                    console.log(this.state.renders);
                    if (this.state.three_sixties != "") {
                        this.state.three_sixties.map((name, index) => {
                            this.state.checkedScene[name.filename.split('.')[0]] = false;
                            this.threeSixtyViews[name.filename.split('.')[0]] = null;
                        });
                        this.setState({
                            selected_scene: this.state.three_sixties[0].filename.split('.')[0]
                        }, () => {
                            if (this.threeSixtyViews[this.state.selected_scene] == null) {
                                this.threeSixtyViews[this.state.selected_scene] = new ThreeSixtyViewer(this.state.three_sixties[0].filename.split('.')[0],`loading-container-${this.state.three_sixties[0].filename.split('.')[0]}`,(baseURL + this.props.match.params.id + '/' + this.state.three_sixties[0].filename).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform)),false);
                            }
                        });
                    }
                    let temp_arr = [];
                    this.state.renders.map(x=> {
                        let temp_cam = x.filename.split(".")[0];
                        temp_arr[temp_cam] = false;
                    });
                    
                    this.setState({
                        image_visible: temp_arr
                    });

                    this.setState({
                        imageResArr: this.setResolutions(1920,1080,'Landscape')
                    });
                });

                this.setState({
                    checkedScene: this.state.checkedScene
                });
                
            })

        document.addEventListener("keydown", this.escFunction, false);

        //Following is a temporary hack
        var tabsDivs = document.getElementsByClassName("ant-tabs-nav-container");
        
        for (var i=0; i< tabsDivs.length;i++){
            tabsDivs[i].style.zIndex = 99;
        }

    }

    componentDidUpdate () {
        window.dispatchEvent(new Event('resize'));
    }

    addCamera = (camera_name, img_data,width,height, camera_render = '') => {
        const {cameras} = this.state;
        let resolution = Math.round(width) + 'x' + Math.round(height);
        let camera_obj = {
            name: camera_name,
            thumbnail: img_data,
            camera_render: camera_render,
            resolution: resolution
        };
        return this.setState({
            cameras: cameras.concat(camera_obj),
        },() => {
            console.log("Add Camera Function &", this.state.cameras)
        });
    }

    timestampToTimeSince = (timestamp) => {
        let timeSince = ""
        let currentTimestamp = new Date()
        
        var difference = currentTimestamp - (new Date(timestamp));
        difference += (currentTimestamp.getTimezoneOffset()*1000*60);
    
        var daysDifference = Math.floor(difference/1000/60/60/24);
        difference -= daysDifference*1000*60*60*24
    
        var hoursDifference = Math.floor(difference/1000/60/60);
        difference -= hoursDifference*1000*60*60
    
        var minutesDifference = Math.floor(difference/1000/60);
        difference -= minutesDifference*1000*60
    
        if (minutesDifference  <= 1) {
            timeSince = "a minute ago"
        } else {
            timeSince = minutesDifference + " minutes ago"
        }
    
        if (hoursDifference != 0) {
            if (hoursDifference  == 1) {
                timeSince = "an hour ago"
            } else {
                timeSince = hoursDifference + " hours ago"
            }
        }
    
        if (daysDifference != 0) {
            if (daysDifference  == 1) {
                timeSince = "a day ago"
            } else {
                timeSince = daysDifference + " days ago"
            }
        }
    
        
        return timeSince; 
    }

    showConfirm = () => {
        confirm({
          title: 'Are you sure you want to redesign your scene?',
          icon: <ExclamationCircleOutlined />,
          content: 'This will move your scene back to Incomplete Scenes section.',
          onOk: this.rejectRender(),
          onCancel() {},
        });
      }

    rejectRender = () => {
        let payload = {
            "scene_id": this.props.match.params.id,
            "scene_status": '1',
        };
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then( (response) => {
                message.info('Render Rejeced.');
                window.location.href = '/scene_builder/' + this.props.match.params.id;
            })
            .catch((error) => {
                message.error('Error in submitting Render Request');
            });
    }

    setFullScreenState = (name,index) => {
        if (this.state.isFullScreen) {
            this.setState({ isFullScreen: false });
            if (this.threeSixtyViews[name] != null || this.threeSixtyViews[name] != undefined) {
                this.threeSixtyViews[name].toggleFull(false);
            }
        }
        else {
            this.setState({ isFullScreen: true });
            message.info('Press ESC to exit full screen');
            if (this.threeSixtyViews[name] != null || this.threeSixtyViews[name] != undefined) {
                this.threeSixtyViews[name].toggleFull(true);
            }
        }
    }

    generateSharingPayload = (values) => {
        let payload = {};

        let permissions = {};
        permissions['360'] = values['360'];
        permissions['images'] = values['images'];
        permissions['editable'] = values['editable'];
        if (values['download_options'] == 'download_and_embed') {
            permissions['download'] = true;
            permissions['embed'] = true;
        }
        if (values['download_options'] == 'embed') {
            permissions['embed'] = true;
        }

        payload['permissions'] = permissions;
        payload['emails'] = values['emails'];
        payload['username'] = localStorage.getItem("username");
        payload['scene_id'] = this.props.match.params.id;
        payload['share_company_wide'] = false

        return payload;
    }

    callback = (key) => {
        if (this.virtualShowroom === null){
            if(this.state.threed_tour != "" ) {
                if (key === "3") {
                    this.setState({activeTabKey: key});
                    setTimeout(function() {
                        this.virtualShowroom = new VirtualShowroom(this.state.threed_tour, this.props.match.params.id, this.state.isFullScreen, "hotspot","vs-container");
                    }.bind(this), 1000)
                }
            }
        }
        else if (!this.state.isFullScreen) {
        }
    }

    callbackFlagTour = () => {
        if (this.virtualShowroomFlag === null){
            setTimeout(function() {
                this.virtualShowroomFlag = new VirtualShowroom(this.state.threed_tour, this.props.match.params.id, this.state.isFullScreen, "3d-tour-flag","vs-container-flag");
            }.bind(this), 1000);
        }
        else if (!this.state.isFullScreen) {
        }
    }

    getFullScreenTour = () =>  {
        if (this.state.isFullScreen) {
            this.setState({ isFullScreen: false });
            if(this.state.threed_tour != "" ) {
            
                setTimeout(function() {
                    this.virtualShowroom.toggleFull(false);
                }.bind(this), 1000)
            }
        }
        else {
            this.setState({ isFullScreen: true });
            message.info('Press ESC to exit full screen');
            if(this.state.threed_tour != "" ) { 
                this.virtualShowroom.toggleFull(true);
            }
        }
    }

    changeSingleImageState = () => {;
        this.state.checkedImage[this.state.selected_image.split('.')[0]] = true;
        this.setState({
            checkedImage: this.state.checkedImage,
            single_image: false
        });
        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;
        this.state.renders.map((x, index) => {
            if (x.status == 'rendered' && x.filename != '360Camera.jpg') {
                length = length + 1;
                if (this.state.checkedImage[x.filename.split('.')[0]]) {
                    countTrue = countTrue + 1;
                    flag = true
                    this.setState({
                        checkImageState: true
                    });
                }
                else if (this.state.checkedImage[x.filename.split('.')[0]] == false) {
                    countFalse = countFalse + 1;
                }
            }
        });

        if (countTrue == length) {
            this.setState({
                selectAllImagesFlag: true
            });
        } else {
            this.setState({
                selectAllImagesFlag: false
            });
        }
        if (countFalse == length) {
            this.setState({
                selectAllImagesFlag: false
            });
        }

        if (!flag) {
            this.setState({
                checkImageState: false
            });
        }
    }

    changeTourInput = (e) => {
        tourInput = e.target.value;
        this.setState({triggerUpdate: !this.state.triggerUpdate});
    }

    changeImageInput = (e) => {
        imageInput = e.target.value;
        this.setState({triggerUpdate: !this.state.triggerUpdate});
    }

    changeSceneInput = (e) => {
        if (e.target != undefined) {
            sceneInput = e.target.value;
        } else {
            sceneInput = e;
        }
        this.setState({triggerUpdate: !this.state.triggerUpdate});
    }

    goToSceneCreator = () => {
        this.setState({
            request_image_loader: true
        }, () => {
            window.location.href = "/scene_builder/" + this.props.match.params.id;
        });
    }

    changeLifestyle = (key) => {
        this.state.three_sixties.map((name, index) => {
            if ((key-1) == (index)) {
                if (this.threeSixtyViews[index] == null) {
                    setTimeout(function() {
                        this.threeSixtyViews[index] = new ThreeSixtyViewer(name.filename.split('.')[0],`loading-container-${name.split('.')[0]}`,(baseURL + this.props.match.params.id + '/' + name.filename).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform)),false);
                    }.bind(this), 1000);
                }
            }
        });
    }
    changeSelectedScene = (name) => {
        this.state.three_sixties.map((x, index) => {
            if ((this.threeSixtyViews[x.filename.split('.')[0]] != null) && (x != name)) {
                this.threeSixtyViews[x.filename.split('.')[0]].changeDisplay("none");
            }
        });
        this.setState({
            selected_scene: name.filename.split('.')[0]
        }, () => {          
            if (this.threeSixtyViews[name.filename.split('.')[0]] == null) {
                this.threeSixtyViews[name.filename.split('.')[0]] = new ThreeSixtyViewer(name.filename.split('.')[0],`loading-container-${name.filename.split('.')[0]}`,(baseURL + this.props.match.params.id + '/' + name.filename).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform)),false);
            }
            else if (this.threeSixtyViews[name.filename.split('.')[0]] != null) {
                this.threeSixtyViews[name.filename.split('.')[0]].changeDisplay("block");
            }
        });
    }

    openSingleFlagScene = (name) => {
        sceneInput = '';
        this.setState({triggerUpdate: !this.state.triggerUpdate});
        this.setState({
            flag_scene_modal: true,
            single_scene: true,
            selected_scene: name,
        });
    }

    changeSingleSceneState = () => {
        this.state.checkedScene[this.state.selected_scene] = true;
        this.setState({
            checkedScene: this.state.checkedScene,
            single_scene: false
        });
        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;

        this.state.three_sixties.map((name, index) => {
            length = length + 1;
            if (this.state.checkedScene[name.filename.split('.')[0]]) {
                countTrue = countTrue + 1;
                flag = true
                this.setState({
                    checkSceneState: true
                });
            }
            else if (this.state.checkedScene[name.filename.split('.')[0]] == false) {
                countFalse = countFalse + 1;
            }
            
        });
        
        if (countTrue == length) {
            this.setState({
                selectAllScenesFlag: true
            });
        } else {
            this.setState({
                selectAllScenesFlag: false
            });
        }
        if (countFalse == length) {
            this.setState({
                selectAllScenesFlag: false
            });
        }

        if (!flag) {
            this.setState({
                checkSceneState: false
            });
        }
    }

    render() {
        const menu = (
            <Menu style={{width: "200px"}}>
                <Menu.Item key={2}>
                    <a target="_blank" onClick={() => this.downloadSceneImagesArchive()}> 360 Image(s) <DownloadOutlined style={{float: "right"}} type="download" /></a>
                </Menu.Item>
            </Menu>
        );
        return (
            <AdminMainLayout selected='14' >
                {this.state.isLoading ?  <DottedLoader/> :
                <div className="scene-side-margins">
                <Row style={{ padding:" 12px 0px"}}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', zIndex: "1" ,marginTop:"28px",marginBottom:"30px"}}>
                        <h3 style={{fontFamily:"Avenir", fontSize:"16px",color: "#737373",marginBottom:0,alignSelf:"center"}}>
                            <Link className="store-bd " to={`/automation_requests`} style={{color:"#999999"}}>Customer Scenes</Link> / <span className="active-route-link-color">{this.state.customer_username}</span> / <span className="active-route-link-color">{this.state.name}</span>
                        </h3>
                        <div>
                            
                            <Tooltip title="Embed" placement="bottom">
                                <Button onClick={() => this.showEmbedModel()} style={{marginRight: 2,width:"30px",height:"30px"}}>
                                    <span style={{margin:"0",position:"absolute", top:"13px", left:"15.5px",transform: "translate(-50%,-50%)",fontSize:"17px"}}>{"</>"}</span>
                                </Button>
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                {/* <PanoramaViewer customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} threeSixtyUrl={baseURL + this.props.match.params.id + '/360Camera.jpg'} /> */}
                
                <Tabs
                    defaultActiveKey="1"
                    tabBarStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:"0 0 18px 0",
                        zIndex:"2"
                    }}
                    style={{marginTop: "-30px"}}
                    onChange={this.callback}>
                     
                     {this.state.display360 ?   
                    <TabPane tab="Lifestyle 360" key="1" className="tabAttr1" >
                        <div style={{textAlign:"right"}}>
                            
                            <div id="components-dropdown-demo-dropdown-button" style={{position:"relative",float:'left',top: '0',zIndex: 3}}>
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button style={{fontFamily:"Avenir",width:"188px",height:"40px"}}>Download Links <DownOutlined /></Button> 
                                </Dropdown>
                            </div>
                        {
                            (this.state.three_sixties.length == 1) ?
                            <Card className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                                <Tooltip title={(this.state.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                    <img src={this.state.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"}  alt="Fullscreen Icon" style={{position:"absolute", top:"12px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px"}} className="product-full-screen-icon" onClick={() => this.setFullScreenState(this.state.three_sixties[0].filename.split('.')[0],0)} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                </Tooltip>
                                {!this.state.three_sixties[0].generated_by_admin && !this.state.three_sixties[0].flagged_by_user ? "" : 
                                <div className="flag-tag three-sixty"><span className={`category-artist ${this.state.three_sixties[0].generated_by_admin  ? `green` : this.state.three_sixties[0].flagged_by_user ? `red`: ``}`}>{this.state.three_sixties[0].generated_by_admin  ? 'Complaint Resolved' : this.state.three_sixties[0].flagged_by_user ?  'Flagged' : ''}</span></div>}

                                <div id={this.state.three_sixties[0].filename.split('.')[0]} style={{cursor:"grabbing",background:"white"}}>                           
                                {/* <Three360Viewer customClass={this.state.isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} fullScreen={this.state.isFullScreen} url={[baseURL + this.props.match.params.id + '/' + this.state.three_sixties[0]]}/> }   */}
                                </div>
                                <div id={`loading-container-${this.state.three_sixties[0].filename.split('.')[0]}`}>
                                    <DottedLoader id={`loadingImages-${this.state.three_sixties[0].filename.split('.')[0]}`}/>
                                </div>
                            </Card>
                            :
                            <span>
                                <div
                                style={{marginTop: "-20px"}} >
                                    <Card className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                                        <Tooltip title={(this.state.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                            <img src={this.state.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"}  alt="Fullscreen Icon" style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} className="product-full-screen-icon" onClick={() => this.setFullScreenState(this.state.selected_scene)} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                        </Tooltip>
                                        {this.state.three_sixties.map((name,index) => (
                                            (name.filename.split('.')[0] == this.state.selected_scene) ?
                                            <span>
                                                {!name.generated_by_admin && !name.flagged_by_user ? "" : 
                                                <div className="flag-tag three-sixty">
                                                    <span className={`category-artist ${name.generated_by_admin  ? `green` : name.flagged_by_user ? `red`: ``}`}>{name.generated_by_admin  ? 'Complaint Resolved' : name.flagged_by_user ?  'Flagged' : ''}</span>
                                                </div>}
                                            </span>: ''

                                        ))}
                                       
                                        <div id={this.state.selected_scene} style={{cursor:"grabbing",background:"white"}}>
                                        </div>
                                        <div id={`loading-container-${this.state.selected_scene}`}>
                                            <DottedLoader id={`loadingImage-${this.state.selected_scene}`}/>
                                        </div>
                                    </Card>
                                </div>
                                <div style={{display:"flex"}}> 
                                    {this.state.three_sixties.map((name,index) => (
                                    <Card onClick={() => this.changeSelectedScene(name)} className="scene-card-bg card-render" key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{border: name.filename.split(".")[0] == this.state.selected_scene ? "1px solid #ffffff" : "1px solid #333333"}}> 
                                        <p className="flag-card-settings"> {name.filename.split(".")[0]}</p>
                                    </Card>
                                    ))}
                                </div>
                            </span>
                            
                        }
                        
                        </div>
                    </TabPane> 
                   : "" }
                   
                   {this.state.displayimg ?   
                    <TabPane tab="Lifestyle Images" key="2" className="tabAttr1" >
                        <div>
                            <Row type="flex" style={{ padding: 12, margin: 5, paddingTop: 0,marginTop: 0,position:"relative"}}>
                                
                                {this.state.render_by_date && this.state.render_by_date.slice(0).reverse().map(camera => (
                                <Col span={24}>
                                    <Row>                                    
                                        <div className="modal-text grey-black" style={{margin: "24px 0"}}>Date Created: {camera.date}</div>
                                    </Row>
                                    <Row gutter={[16,16]}>
                                        {camera.render && camera.render.slice(0).reverse().map(x => (
                                        (x.status == 'rendered' && x.filename != '360Camera.jpg') ?
                                            <Col span={12}>

                                                <Card className="lifestyle-image-card">
                                                    <div className="position-flag-dim">
                                                    <div className="resolution-btn"><span className="category-artist white">{Math.round(x.image_width) + ' x ' + Math.round(x.image_height)}</span></div>
                                                    {!x.generated_by_admin && !x.flagged_by_user ? "" : <div className="flag-tag"><span className={`category-artist ${x.generated_by_admin  ? `green` : x.flagged_by_user ? `red`: ``}`}>{x.generated_by_admin  ? 'Complaint Resolved' : x.flagged_by_user ?  'Flagged' : ''}</span></div>}
                                                    </div>

                                                    <Image preview={{
                                                        visible: this.state.image_visible[x.filename.split(".")[0]],
                                                        maskClassName: 'customize-mask',
                                                        mask: (
                                                        <span>
                                                            <img className="image-thumbnail" src="/img/expand_thumbnail.png" onClick={() => this.expandImage(x.filename)} style={{marginRight: 20}}/>
                                                            <img className="image-thumbnail" src="/img/flag_thumbnail.png"  onClick={() => this.openSingleFlagImage(x.filename)}/>
                                                            <div className="thumbnail-btn-bg" onClick={() => this.openConfigModal(x.filename)}><span className="thumbnail-btn">Generate High Resolution Image</span></div>
                                                        </span>
                                                        ),
                                                        onVisibleChange: this.changeVisibility,
                                                    }}
                                                    className="lifestyle-image-settings" src={(baseURL + this.props.match.params.id + '/' + x.filename).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform))} 
                                                    />
                                                </Card> 
                                            </Col>
                                            : (x.status == 'pending' && x.filename != '360Camera.jpg') ? 
                                            <Col span={12}>
                                                
                                                <Card className="lifestyle-image-card">
                                                    <div className="thumbnail-btn-bg blur"><span className="thumbnail-btn">{x.preview_render.includes("preview") ? 'Render in Progress...' : 'High Resolution Render in Progress...'}</span></div>
                                                    <Image preview={false}
                                                    className="lifestyle-image-settings preview" src={(baseURL + this.props.match.params.id + '/' + x.preview_render).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform))} 
                                                    />
                                                </Card>
                                                
                                            </Col>
                                        :""
                                        ))}
                                    </Row>
                                </Col>)) }
                            </Row>
                        </div>
                      
                    </TabPane> 
                    : "" }
                    
                     {this.state.displayTour ?   
                    
                    <TabPane tab="3D Tour" key="3" className="tabAttr1" >
                        <div style={{textAlign:"right"}}>
                           
                            <Card id={`vs-container`} className={this.state.isFullScreen ? "tour-fullscreen" : ""} style={{cursor:"grabbing"}}>
                                <Tooltip title={(this.state.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                    <img src={this.state.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"} alt="Fullscreen Icon" style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",borderRadius:"4px",right:"20px"}} className="product-full-screen-icon" onClick={() => {this.getFullScreenTour()}} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                </Tooltip>  
                                <div className="hotspot" id="hotspot">
                                    <img className="hotspotImage" src="https://all3d-demos.s3.amazonaws.com/Virtual-Showroom/Resources/hotspot.gif" alt="Hotspot" />
                                    <span className="tooltiptext" id="toolTip"></span>
                                </div>
                            </Card>

                            <div id="loading-container">
                                <img id="loadingImage" className="loadingImage" src="" style={{width: "100%",height: "100%"}}></img>
                                <div class="profile-main-loader tour">
                                    <div class="loader">
                                    <svg class="circular-loader"viewBox="25 25 50 50" >
                                        <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#808080" stroke-width="2" />
                                    </svg>
                                    </div>
                                </div>

                                <div style={{position: "absolute",bottom: 160,width: "100%"}}>
                                    <div style={{display: "flex", justifyContent: "center"}} className="my_font">
                                    powered by
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center", marginTop: 10}} >
                                    <img className="logo" src="/img/logo.svg" width="111px" />
                                    </div>
                                </div>   
                            </div>

                            {/* <div id="loading-container">
                                <DottedLoader id="loadingImage"/>
                            </div> */}
                        </div>
                        </TabPane> : ""}
                </Tabs>
                <Modal
                    visible={this.state.embed_visible}
                    onOk={this.handleEmbedOk}
                    onCancel={this.handleEmbedCancel}
                    className="modal-share"
                    footer={[
                        <div className="justify-in-end">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay font-14" onClick={this.handleEmbedOk}>
                            Embed
                        </Button>
                        </div>
                    ]}
                >
                    <div style={{padding:"16px 6px 0 14px"}}>
                      <h2 className="share-modal-heading">Embedding Details</h2>
                    <span>
                        {this.state.displayimg ? 
                        <span>
                           
                            <h4 className="modal-text">URL(s) for Lifestyle Images</h4>
                            {this.state.renders.map(x => (   
                                (x.status == 'rendered')?
                                <Paragraph className="modal-radio-anchor bg"  copyable={{ text: (`${baseURL}${this.props.match.params.id}/${x.filename}`).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform)) }}>
                                    <a href={(baseURL + this.props.match.params.id + '/' + x.filename).replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(this.state.platform))} target="_blank">
                                    {x.filename}
                                    </a>
                                </Paragraph>:''
                            ))}
                        </span>: ''}
                        {this.state.display360 ? 
                        <span>
                            <br />
                            <h4 className="modal-text">URL(s) for Lifestyle 360s</h4>
                            {this.state.three_sixties.length == 1 ? 
                            <Paragraph className="modal-radio-anchor bg" copyable={{ text: `https://app.all3d.ai/360_public_viewer/scene/${this.props.match.params.id}/${this.state.three_sixties[0].filename}` }}>
                                <Link to={'/360_public_viewer/scene/' + this.props.match.params.id + '/360Camera.jpg'} target="_blank">
                                    Default Space
                                </Link>
                            </Paragraph> :
                            this.state.three_sixties.map((url) => (
                                <Paragraph className="modal-radio-anchor bg"  copyable={{ text: `https://app.all3d.ai/360_public_viewer/scene/${this.props.match.params.id}/${url.filename}` }}>
                                    <Link to={'/360_public_viewer/scene/' + this.props.match.params.id + '/' + url.filename} target="_blank">
                                    {url.filename}
                                    </Link>
                                </Paragraph>
                            ))}
                        </span> : ''}
                        
                        {this.state.displayTour ?
                            <span>
                                <br />
                                <h4 className="modal-text">URL for 3D Tour</h4>
                                    <Paragraph className="modal-radio-anchor bg" copyable={{ text: `https://app.all3d.ai/3d_tour_viewer/${this.props.match.params.id}` }}>
                                        <Link to={'/3d_tour_viewer/' + this.props.match.params.id} target="_blank">
                                            3D Tour
                                        </Link>
                                    </Paragraph>:
                                    
                            </span> : ''}
                        <br />
                        <h4 className="modal-text">Embed in your site</h4>
                        <p className="modal-radio-anchor" style={{color:"#999999"}} id="iframe-text">{'<iframe style="width: 500px; height: 300px;" src="' + 'http://app.all3d.ai/360_viewer/abIal9ok)_ApI/' + this.props.match.params.id + '"></iframe>'}</p>
                    </span>
                    </div>
                </Modal>
                
                
                </div>}
            </AdminMainLayout>
        );
    }
}

export default AdminSceneRenders;
