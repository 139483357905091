import React from 'react';
import 'antd/dist/antd.css';
import { getBaseURL, SCENE_THUMBNAIL_URI } from '../../../../environments/env';


class Scene360Viewer extends React.Component {
    
    constructor(props){
        super(props);
    }


    componentDidMount() {
        // Create viewer.
        var viewer = new window.Marzipano.Viewer(this.el);
        var autorotate = window.Marzipano.autorotate({
            yawSpeed: 0.1,         // Yaw rotation speed
            targetPitch: 0,        // Pitch value to converge to
            targetFov: Math.PI/2   // Fov value to converge to
        });

        // Create source.
        var source = window.Marzipano.ImageUrlSource.fromString(
            getBaseURL(this.props.platform) + SCENE_THUMBNAIL_URI + this.props.id + "/" + this.props.area +".jpg"
        );
        console.log("imagesource",(getBaseURL(this.props.platform) + SCENE_THUMBNAIL_URI + this.props.id + "/" + this.props.area +".jpg"));

        // Create geometry.
        var geometry = new window.Marzipano.EquirectGeometry([{ width: 4000 }]);

        // Create view.
        var limiter = window.Marzipano.RectilinearView.limit.traditional(1024, 100*Math.PI/180);
        var view = new window.Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

        // Create scene.
        var scene = viewer.createScene({
        source: source,
        geometry: geometry,
        view: view,
        pinFirstLevel: true
        });

        // Display scene.
        scene.switchTo();
        viewer.startMovement(autorotate);

    }

    componentDidUpdate() {
        // Create viewer.
        var viewer = new window.Marzipano.Viewer(this.el);
        var autorotate = window.Marzipano.autorotate({
            yawSpeed: 0.1,         // Yaw rotation speed
            targetPitch: 0,        // Pitch value to converge to
            targetFov: Math.PI/2   // Fov value to converge to
        });

        // Create source.
        var source = window.Marzipano.ImageUrlSource.fromString(
            getBaseURL(this.props.platform) + SCENE_THUMBNAIL_URI + this.props.id + "/" + this.props.area +".jpg"
        );
        console.log("imagesource",(getBaseURL(this.props.platform) + SCENE_THUMBNAIL_URI + this.props.id + "/" + this.props.area +".jpg"));

        // Create geometry.
        var geometry = new window.Marzipano.EquirectGeometry([{ width: 4000 }]);

        // Create view.
        var limiter = window.Marzipano.RectilinearView.limit.traditional(1024, 100*Math.PI/180);
        var view = new window.Marzipano.RectilinearView({ yaw: Math.PI }, limiter);

        // Create scene.
        var scene = viewer.createScene({
        source: source,
        geometry: geometry,
        view: view,
        pinFirstLevel: true
        });

        // Display scene.
        scene.switchTo();
        viewer.startMovement(autorotate);
    }

    render () {
        return (
            <div className={this.props.customClass ? this.props.customClass : 'panorama-default-styles large'} ref={ref => (this.el = ref)} />
        );
    }
}

export default Scene360Viewer;