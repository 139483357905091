import React from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import ENVIRONMENT from '../../../../environments'
import { Button, Tabs, Row, Col, Modal, DatePicker, Checkbox, Tooltip } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import AdminMainLayout from '../AdminMainLayout';
import ArtistsTable from '../ArtistsTable';
import AvailabilitySummary  from "../AvailabilitySummary";
import ArtistTestAssessmentTable from "../ArtistTestAssessmentTable/ArtistTestAssessmentTable";
import ArtistPendingOnboardingTable from "../ArtistPendingOnboardingTable/ArtistPendingOnboardingTable";
import TestArtitstsStatusTable from "../TestArtistsStatusTable/TestArtitstsStatusTable";
import Agencies from "../Agencies/Agencies"
import { withRouter } from "react-router-dom";

import { CSVLink } from "react-csv";
import moment from 'moment';
import SkillMatrix from "../SkillMatrix/SkillMatrix";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const DATE_FORMAT = 'MM/DD/YYYY';

class ArtistsList extends React.Component {
  csvLink = React.createRef();
  csvLink2 = React.createRef();
  csvLink3 = React.createRef();
  state = {
    size: "small",
    start_date: moment().startOf('month').format(DATE_FORMAT),
    end_date: moment().endOf('month').format(DATE_FORMAT),
    invoice_data: [],
    button_loader: false,
    invoice_filename: '',
    model_count_filename: '',
    csv_data_source: [],
    export_invoice_modal: false,
    export_hourly_report: true,
    summary_report: true,
    export_model_count_report: true,
    artist_model_worked: [],
    csv_models_count_data: [],
    summary: {},
    csv_summary_data: [],
    summary_report_filename: '',
    artists_data: [],
    artists_data_loading: false
   };

  componentDidMount = () => {
    this.setState({
      artists_data_loading: true
    })
    axios.post(ENVIRONMENT.ARTIST_PROFILE_LIST, {
    })
      .then(res => {
        res.data.map(ele => {
          ele['proficient_categories'] = ele["proficient_categories"];
        })
        this.setState({ artists_data: res.data, artists_data_loading: false })
      })
  }

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  prepareCSVData = () => {
    let data = [];
    let dataSource = Array.from(this.state.invoice_data);
    for (let i = 0; i < dataSource.length; i++) {
        data.push({
            'Model ID': dataSource[i]['model_id'],
            'Artist Username': dataSource[i]['artist_username'],
            'Model Name': dataSource[i]['model_name'].replace('"', '""'),
            'Model Type': dataSource[i]['model_type'],
            'Task Type': dataSource[i]['request_type'],
            'Customer': dataSource[i]['customer_username'],
            'Hours Worked': parseFloat(dataSource[i]['hours'] || 0)
        });

    }
    let filename = "Hourly data report from " + this.state.start_date + "_to_" + this.state.end_date + "_.csv";

    this.setState({
        csv_data_source: data,
        invoice_filename: filename,
        export_invoice_modal: false
    }, () => {
        this.csvLink.current.link.click()
      })
  }

  exportModelCountReport = () => {
    let data = [];
    let dataSource = Array.from(this.state.artist_model_worked);
    for (let i = 0; i < dataSource.length; i++) {
        data.push({
            'Artist Username': dataSource[i]['artist_username'],
            'No. of Models Worked': parseFloat(dataSource[i]['model_worked'])
        });

    }
    let filename = "Model Count Report from " + this.state.start_date + "_to_" + this.state.end_date + "_.csv";

    this.setState({
        csv_models_count_data: data,
        model_count_filename: filename,
        export_invoice_modal: false
    }, () => {
        this.csvLink2.current.link.click()
      })
  }


  exportSummaryReport = () => {
    let data = [];
    for (const [key, value] of Object.entries(this.state.summary)) {
      data.push({
        'Artist Username': key,
        'Profile Name': value['profile_name'],
        'Billing Rate': value['billing_rate'],
        'Space Models': value['spaces'],
        'Space Variants': value['space_variants'],
        'Product Models': value['products'],
        'Product Variants': value['product_variants'],
        'Materials': value['materials'],
        'Complaints': value['complaints'],
        'Total Hours': value['hours'],
        'Total Amount': value['billing_rate'] != 'N/A' ? value['amount'] : 'N/A',
      })
    }
    let filename = "Summary Report " + this.state.start_date + "_to_" + this.state.end_date + "_.csv";

    this.setState({
        csv_summary_data: data,
        summary_report_filename: filename,
        export_invoice_modal: false
    }, () => {
        this.csvLink3.current.link.click()
      })
  }


  onCalenderChange = (date_values) => {
    if (date_values && date_values[0] && date_values[1]) {
        this.setState({
          start_date: date_values[0].format(DATE_FORMAT),
          end_date: date_values[1].format(DATE_FORMAT)
        })
    }
}

  fetchInvoiceAndExport = () => {
    this.setState({
      button_loader: true
    })
    let payload = {
      'start_date': moment(this.state.start_date).format('YYYY-MM-DD'),
      'end_date': moment(this.state.end_date).format('YYYY-MM-DD'),
      'combined_invoice': true,
    }
    axios.post(ENVIRONMENT.GET_ARTIST_INVOICE, payload)
    .then(res => {
      this.setState({
        invoice_data: res.data['invoice_data'],
        artist_model_worked: res.data['artist_models_worked'],
        summary: res.data['summary'],
        button_loader: false,
      }, ()=>{
        if(this.state.export_hourly_report)
          this.prepareCSVData();
        if(this.state.export_model_count_report)
          this.exportModelCountReport();
        if(this.state.summary_report)
          this.exportSummaryReport();
        })
    })
  }
  handlebackclick=()=>{
    this.props.history.goBack();
}

  render() {
    const { size } = this.state;
    return (
      <AdminMainLayout selected={"20"}>
        <br />
        <div  >
          <Row style={{justifyContent:'space-between',marginBottom:10}}>
          <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={`${process.env.PUBLIC_URL}/img/back_arrow.png`}
                alt="Arrow Back"
                style={{ cursor: 'pointer', marginRight: 10 }}
                onClick={this.handlebackclick}
              />
              <span className='manrope f-36 w-900'>
                Artists
              </span>
            </div>
            <div>
            <Col
              style={{
                alignSelf: "center",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                className="save-button font-16"
                onClick={() => (window.location.href = "/artist_invite")}
              >
                Invite Artist
              </Button>

              <Button
                className="save-button okay font-16"
                style={{ marginLeft: 5 }}
                onClick={() => this.setState({ export_invoice_modal: true })}
              >
                Export Reports
              </Button>
              <CSVLink
                data={this.state.csv_data_source}
                filename={this.state.invoice_filename}
                style={{ display: "none" }}
                ref={this.csvLink}
              ></CSVLink>

              <CSVLink
                data={this.state.csv_models_count_data}
                filename={this.state.model_count_filename}
                style={{ display: "none" }}
                ref={this.csvLink2}
              ></CSVLink>

              <CSVLink
                data={this.state.csv_summary_data}
                filename={this.state.summary_report_filename}
                style={{ display: "none" }}
                ref={this.csvLink3}
              ></CSVLink>
            </Col>
            </div>
          </Row>
        </div>

        <Tabs
          defaultActiveKey="1"
          size={size}
          tabBarStyle={{ textAlign: "center" }}
          animated={false}
        >
          <TabPane tab="Profiles" key="1">
            <Tabs
              defaultActiveKey="1"
              size={size}
              tabBarStyle={{ textAlign: "center" }}
              animated={false}
            >
              {/* From the API is_active is either true or "False" */}
              <TabPane tab="Active Artists" key="1">
                <ArtistsTable is_loading={this.state.artists_data_loading} artists_data={this.state.artists_data} is_active={true} />
              </TabPane>
              <TabPane tab="Inactive Artists" key="2">
                <ArtistsTable is_loading={this.state.artists_data_loading} artists_data={this.state.artists_data} is_active={false} />
              </TabPane>
            </Tabs>
          </TabPane>
          <TabPane tab="Agencies" key="5">
            <Agencies />
          </TabPane>
          <TabPane tab="Availability Summary" key="2">
            <AvailabilitySummary is_loading={this.state.artists_data_loading} artists_data={this.state.artists_data} availability={this.state.availability} />
          </TabPane>
            <TabPane tab="Skill Matrix" key="3">
              <SkillMatrix is_loading={this.state.artists_data_loading} artists_data={this.state.artists_data}/>
            </TabPane>
          <TabPane tab="Test Artists" key="4">
            <Tabs
              defaultActiveKey="1"
              size={size}
              tabBarStyle={{ textAlign: "center" }}
              animated={false}
            >
              {/* From the API is_active is either true or "False" */}
              <TabPane tab="Pending Evaluation" key="1">
                <ArtistTestAssessmentTable is_active={true} />
              </TabPane>
              {
                this.props.user['signInUserSession']['idToken']['payload']['cognito:groups'].includes('SuperAdmin') ?
                <TabPane tab="Pending Onboarding" key="2">
                  <ArtistPendingOnboardingTable is_active={false} />
                </TabPane>
                :
                ''
              }
              <TabPane tab="Status" key="3">
                <TestArtitstsStatusTable is_active={false} />
              </TabPane>
            </Tabs>
          </TabPane>
        </Tabs>

        <Modal
          visible={this.state.export_invoice_modal}
          width={500}
          closable={false}
          footer={[
            <div className="justify-in-end">
              <span>
                <Button
                  className="modal-okay-gray square font-14"
                  htmlType="submit"
                  onClick={() => this.setState({ export_invoice_modal: false })}
                >
                  Cancel
                </Button>
              </span>
              <Tooltip
                title={
                  !this.state.export_hourly_report &&
                  !this.state.export_model_count_report
                    ? "Please Select the type of Report you want to Export"
                    : ""
                }
                placement="top"
              >
                <span style={{ marginLeft: 6 }}>
                  <Button
                    disabled={
                      this.state.button_loader ||
                      (!this.state.export_hourly_report &&
                        !this.state.export_model_count_report &&
                        !this.state.summary_report)
                    }
                    className="modal-okay square font-14"
                    htmlType="submit"
                    onClick={() => this.fetchInvoiceAndExport()}
                  >
                    Export&nbsp;
                    {this.state.button_loader ? <LoadingOutlined /> : ""}
                  </Button>
                </span>
              </Tooltip>
            </div>,
          ]}
        >
          <div style={{ padding: "32px 32px 24px 32px" }}>
            <div
              className="manrope f-20 black-14"
              style={{ marginBottom: 16, textTransform: "capitalize" }}
            >
              Export Reports
            </div>
            <div
              className="manrope f-16 black-14"
              style={{ marginBottom: 5, textTransform: "capitalize" }}
            >
              Select Date Range
            </div>
            <Row className="justify-in-start">
              <Col>
                <RangePicker
                  size={"large"}
                  defaultValue={[
                    moment(this.state.start_date, DATE_FORMAT),
                    moment(this.state.end_date, DATE_FORMAT),
                  ]}
                  format={DATE_FORMAT}
                  className="date-picker"
                  popupStyle={{
                    fontFamily: "Manrope",
                    fontSize: 14,
                    color: "#40a9ff",
                    background: "#e6efff",
                  }}
                  placeholder={["Date (From)", "Date (To)"]}
                  value={[
                    moment(this.state.start_date, DATE_FORMAT),
                    moment(this.state.end_date, DATE_FORMAT),
                  ]}
                  onCalendarChange={(val) => this.onCalenderChange(val)}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 9 }}>
              <Checkbox
                checked={this.state.export_hourly_report}
                onChange={() => {
                  this.setState({
                    export_hourly_report: !this.state.export_hourly_report,
                  });
                }}
              >
                Export Hourly Report
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 9 }}>
              <Checkbox
                checked={this.state.export_model_count_report}
                onChange={() => {
                  this.setState({
                    export_model_count_report:
                      !this.state.export_model_count_report,
                  });
                }}
              >
                Export Model Count Report
              </Checkbox>
            </Row>

            <Row style={{ marginTop: 9 }}>
              <Checkbox
                checked={this.state.summary_report}
                onChange={() => {
                  this.setState({ summary_report: !this.state.summary_report });
                }}
              >
                Export Invoicing Summary
              </Checkbox>
            </Row>
          </div>
        </Modal>
      </AdminMainLayout>
    );
  }
}

const mapStateToProps = state => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});

const ArtistsListContainer = connect(mapStateToProps, null)(ArtistsList);

export default withRouter(ArtistsListContainer);