import React from "react";
import { Tabs, Row, Col } from "antd";
import CustomerMainLayout from "../CustomerMainLayout";
import FileConstants from "../../../../FileConstants";
import CustomerProductReports from "./CustomerProductReports";
import CustomerSpaceReports from "./CustomerSpaceReports";

const { TabPane } = Tabs;

const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const IS_MS_PROVIDER = FileConstants.isMSProvider;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;

const CustomerReports = () => {
  return (
    <>
      <CustomerMainLayout selected={"18"} color={true}>
        <Row style={{ marginTop: 30, marginBottom: 30 }} gutter={24}>
            <Col lg={10} xl={10}>
                <div
                  className="manrope f-24 w-700"
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                }}>
                  {MANAGED_CUSTOMER_USERNAME ? `Model Details for ${MANAGED_CUSTOMER_USERNAME}` : 'Model Details'}
                </div>
            </Col>
        </Row>
        <Tabs
          defaultActiveKey="1"
          size="small"
          tabBarStyle={{ textAlign: "center" }}
          animated={false}
          tabBarGutter={32}>
          <TabPane tab="Products" key="1" style={{ color: "#000000" }}>
            <CustomerProductReports />
          </TabPane>

          <TabPane tab="Spaces" key="2" style={{ color: "#000000" }}>
            <CustomerSpaceReports />
          </TabPane>
        </Tabs>
      </CustomerMainLayout>
    </>
  );
};

export default CustomerReports;