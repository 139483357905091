import React, { useState, useRef, useEffect, Suspense } from "react";
import * as THREE from "three";
import {
  Canvas,
  extend,
  useThree,
  useFrame,
  useLoader,
  Dom
} from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import DottedLoader from "../../DottedLoader";

extend({ OrbitControls });

var maps = '';
var texture= '';

function Controls(props) {
  const { camera, gl } = useThree();
  const orbitRef = useRef();

  useFrame(() => {
    orbitRef.current.update();
  });

  const mouseWheel = (e) => {
      e.preventDefault();
      e.stopPropagation();
      let delta = e.wheelDelta;
      let fov = camera.fov + delta*0.03;
      if (navigator.userAgent.includes('Firefox')) {
        delta = e.deltaY;
        fov = camera.fov - delta*0.5;
      }
      camera.fov = THREE.MathUtils.clamp(fov,10,75)
      camera.updateProjectionMatrix();
  };

  React.useEffect(() => {
    document.addEventListener('wheel', mouseWheel, false);
    return () => {
      document.removeEventListener('wheel', mouseWheel, false);
    }
  });

  return (
    <orbitControls
      ref={orbitRef}
      target={[0, 0, 0]}
      args={[camera, gl.domElement]}
      {...props}
    />
  );
}

function Dome({ texture }) {
  return (
    <group>
      <mesh>
        <sphereBufferGeometry attach="geometry" args={[500, 60, 40]} scale={[-1,1,1]} />
        <meshBasicMaterial
          attach="material"
          map={texture}
          side={THREE.BackSide}
        />
      </mesh>
    </group>
  );
}


function Portals(props) {
  const [which, set] = useState(0);
  maps = useLoader(THREE.TextureLoader, props.url);
  console.log(props.url);
  texture = maps[which]
  texture.wrapS = THREE.RepeatWrapping;
  texture.repeat.x = - 1;  
  return (
    <Dome
      texture={texture}
    />
  );
}

function Preload(props) {
  const { gl } = useThree(); 
  const maps = useLoader(THREE.TextureLoader, props.url);
  useEffect(() => maps.forEach(gl.initTexture), [maps, gl.initTexture]);
  return null;
}

function Three360Viewer (props){
    const [clicked, setClicked] = useState(true);

    const onClick = (e) => {
      console.log('button clicked!', e)
      if (clicked) {
        setClicked(false);
      }
    }
  
    React.useEffect(() => {
      document.addEventListener('click', onClick);
      return () => {
        document.removeEventListener('click', onClick);
      }
  });

  return (
    <div id="panorama-viewer"> 
      {/* <DottedLoader style={{zIndex:"1"}}/> */}
      <Canvas pixelRatio={window.devicePixelRatio}  concurrent  camera={{ position: [0, 0, 0.1], fov:75 }} style={{width:"100%",position:"absolute",zIndex:"2",height:"100%"}}>
        <Controls
          enablePan={true}
          panSpeed={0.2}
          enableDamping
          dampingFactor={0.2}
          rotateSpeed={0.2}
          autoRotateSpeed={0.3}
          enableZoom={false}
          autoRotate={clicked} 
        />
        <Suspense fallback={<Dom>Loading</Dom>}>
          <Preload url={props.url}/>
          <Portals url={props.url}/>
        </Suspense>
      </Canvas> 
    </div>
  );
}

export default Three360Viewer;
