import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SpaceTags = ({ value = undefined, onChange, className, disabled }) => {
    return (
        <Select
            className={`manrope f-12 black-55 ${className}`}
            mode="tags"
            onChange={onChange}
            disabled={disabled}
            value={value}
            placeholder="Enter space tags or type and press enter to add a new tag"
        >
            <Option className="manrope f-12 black-55 select-view" value="Studio">Studio</Option>
            <Option className="manrope f-12 black-55 select-view" value="Space">Space</Option>
            <Option className="manrope f-12 black-55 select-view" value="Interior">Interior</Option>
            <Option className="manrope f-12 black-55 select-view" value="West">West</Option>
            <Option className="manrope f-12 black-55 select-view" value="Seasonal">Seasonal</Option>
           
        </Select>
    );
}
export default SpaceTags;