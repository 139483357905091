import 'rxjs-compat';
// import { Observable } from 'rxjs-compat';
import 'rxjs-compat/add/operator/switchMap';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/catch';
import { ajax } from 'rxjs-compat/observable/dom/ajax';
import ENVIRONMENT from '../../environments';

import {
  FETCH_DASHBOARD,
  fetchDashboardSuccess,
  fetchDashboardFailure,
} from '../actions';



export const fetchDashboard = (action$) => {
  return action$
      .ofType(FETCH_DASHBOARD)
      .switchMap(({ payload }) => {
        // return ajax.post(ENVIRONMENT.DASHBOARD_ANALYTICS,
        return ajax.post(ENVIRONMENT.HOME_SCREEN_DATA,
            { ...payload, username: localStorage.getItem('username') }, { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('all3d_jwt_token') })
            .map((data) => fetchDashboardSuccess(data.response))
            .catch((error) => Promise.resolve(fetchDashboardFailure({ message: 'Unable to get product.' })));
      });
};
