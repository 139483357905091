export const LIST_SCENE = 'LIST_SCENE';
export const SCENE_SUCCESS = 'SCENE_SUCCESS';
export const SCENE_FAILURE = 'SCENE_FAILURE';

export const listScenes = (scene) => ({
  type: LIST_SCENE,
  payload: scene,
});

export const sceneSuccessAction = (scene) => ({
  type: SCENE_SUCCESS,
  payload: scene,
});

export const sceneFailureAction = (error) => ({
  type: SCENE_FAILURE,
  payload: error,
});
