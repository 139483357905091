import React, {useState, useEffect, useCallback} from 'react';
import ENVIRONMENT from '../../../../environments';
import DottedLoader from "../../DottedLoader";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    Row,
    Col,
    Tabs,
    message,
    Card,
    Tooltip,
} from 'antd';
import CustomerMainLayout from '../CustomerMainLayout';
import Three360Viewer from "../Three360Viewer"
import {updateRoom, fetchRoom, rejectRoom} from "../../../../redux/actions";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";

const { TabPane } = Tabs;

const StoreRoomDetails = (props) =>  {

    const [isFullScreen, setIsFullScreen] = useState(false);

    const { match: { params } } = props;

    const setFullScreen = (value) => {
        if (value == true){
            message.info('Press ESC to exit full screen');
        }
        setIsFullScreen(value);
    }

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [isFullScreen]);
    
    useEffect(() => {
        props.getRoom({room_id: params.id});
    }, [params]);

    useEffect(() => {
        if(props.roomReducer.isUpdated) {
            props.history.push('/store-rooms');
        }
    }, [props.roomReducer.isUpdated])

    const escFunction = useCallback((event) => {
        if(event.keyCode == 27) {
            setIsFullScreen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return <CustomerMainLayout>
            {props.roomReducer.isLoading ? <DottedLoader/> :
                (<Row type="flex" justify="space-around" className="space-side-margins" style={{marginTop:"40px"}}>
                <Col span={24}>
                    { (props.roomReducer.room.model_status == '5') ?
                        <Row type="flex" style={{justifyContent: "space-between",marginBottom:"30px"}}>
                            <p style={{fontFamily:"Avenir", fontSize:"16px",color: "#999999",marginBottom: 0,alignSelf:"center"}}>
                                <Link className="store-bd" to={`/store`} style={{color:"#999999"}}>Store</Link> / <span className="active-route-link-color">{props.roomReducer.room.room_name}</span>
                            </p>

                            <span>
                            </span>
                        </Row>
                        :
                        ""
                    }
                    
                    { ( props.roomReducer.room.three_sixties && props.roomReducer.room.three_sixties.length == 0) ?
                        <Card className={isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: isFullScreen ?0:8}}>
                            <Tooltip title={(isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                            <img 
                                            src={isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}  
                                            alt="Fullscreen Icon" 
                                            style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} 
                                            className="product-full-screen-icon" 
                                            onClick={() => setFullScreen(!isFullScreen)} type={(isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                            </Tooltip>
                            <Three360Viewer customClass={isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} fullScreen={isFullScreen} url={[ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/360Camera.jpg']}/>
                        </Card>
                        :
                        (props.roomReducer.room.three_sixties) ?
                            <Tabs
                                    tabBarStyle={{ 
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                }}
                                centered
                                defaultActiveKey='1'>
                                {props.roomReducer.room.three_sixties.map((name, index) => (
                                    <TabPane tab={name.split(".")[0]} key={index + 1} className="tabAttr" >
                                        <Card className={isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: isFullScreen ?0:8}}>
                                        <Tooltip title={(isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                            <img 
                                            src={isFullScreen ? "/img/fullScreenIcon-2.svg" : "/img/fullScreenIcon.jpg"}  
                                            alt="Fullscreen Icon" 
                                            style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} 
                                            className="product-full-screen-icon" 
                                            onClick={() => setFullScreen(!isFullScreen)} type={(isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                        </Tooltip>
                                            {props.roomReducer.isLoading ? <LegacyIcon className="model-pending-msg" type="sync" spin />: <div>
                                                {props.roomReducer.room.model_status == '2' ? <h3 className="model-pending-msg">Model will be ready by 24 hours.</h3>
                                                : ''}
                                            </div>}

                                            <Three360Viewer customClass={isFullScreen ? 'room-panorama-full-screen': 'room-panorama-view'} url={[ENVIRONMENT.getBaseURL(props.roomReducer.room.platform) + ENVIRONMENT.ROOM_PANORAMA_ASSET_URI + params.id + '/' + name]} fullScreen={isFullScreen}/>
                                        </Card>
                                    </TabPane>
                                ))}
                                
                            </Tabs>
                            :
                            ""
                    }
                    
                </Col>
                
                </Row>)}
            </CustomerMainLayout>


}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateRoom: (room) => {
        dispatch(updateRoom(room));
    },
    getRoom: (payload) => {
        dispatch(fetchRoom(payload))
    },
    rejectRoom: (room) => {
        dispatch(rejectRoom(room))
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(StoreRoomDetails)
)
