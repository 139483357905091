import React from 'react';
import { Table, Input, Divider, Space, Modal, Button, Col, Form, Radio, Row, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader';
import SuccessModal from '../../SuccessModal/SuccessModal';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
class CustomersTable extends React.Component {
  formRef = React.createRef();
  state = {
    bordered: false,
    loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    tableLayout: 'fixed',
    title: undefined,
    showHeader: true,
    footer: false,
    scroll: undefined,
    hasData: true,
    dataSource: null,
    loading: true,
    change_payment_type_modal: false,
    selected_payment_type: '',
    selected_customer: '',
    button_loader: false,
    success_payment_change: false,
    filter_user_types: [],
    filter_subscription_types: [],
    show_subscription_modal: false,
    selected_customer_email: '',
    buttonLoader: false,
    email: '',
    subscription_package: '',
    success_modal: false
  };

  getColumns = () => [
    {
      title: 'Customer Username',
      dataIndex: 'customer_username',
      key: 'customer_username',
      ...this.getColumnSearchProps('customer_username','Username'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Customer Profile Name',
      dataIndex: 'profile_name',
      key: 'profile_name',
      ...this.getColumnSearchProps('profile_name','Profile Name'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...this.getColumnSearchProps('email','Email'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      ...this.getColumnSearchProps('company_name','Company Name'),
      render: text => <span>{text}</span>,
    },
    {
      title: 'Type',
      dataIndex: 'user_type',
      key: 'user_type',
      filters: this.state.filter_user_types,
      onFilter: (value, record) => record.user_type === value,
      render: text => <span>{text}</span>,
    },
    {
      title: 'Subscription Type',
      dataIndex: 'subscription_package',
      key: 'subscription_package',
      filters: this.state.filter_subscription_types,
      onFilter: (value, record) => record.subscription_package === value,
      render: (text, record) => 
        <span>
          {text} (<a onClick={() => this.openUserSubscriptionModal(record)}>Change</a>)
        </span>,
    },
    {
      title: 'Actions',
      key: 'action_usage',
      dataIndex: 'action_usage',
      render: (text, record) => (
        <span>
          {record.company_id ?
            <a target="_blank" onClick={() => window.location.href='/admin-customer-usage/' + record.customer_username + "/" + record.company_id}>View System Usage</a>
            :
            <a target="_blank" onClick={() => window.location.href='/admin-customer-usage/' + record.customer_username+ "/" + -1}>View System Usage</a>}
        </span>
      ),
    },
  ];

  openPaymentTypeModal = (record) => {
    console.log(record)
    this.setState({
      payment_type: record.payment_type,
      selected_customer: record.customer_username
    }, () => {
      this.setState({
        change_payment_type_modal: true,
      })
    })
  };

  closePaymentTypeModal = () => {
    this.setState({
      change_payment_type_modal: false
    })
  }

  selectSubscription = (value) => {
    this.setState({subscription_type: value})
  }

  openUserSubscriptionModal = (record) => {
    console.log(record)
    this.setState({
      selected_customer_email: record.email,
      email: record.email,
      show_subscription_modal: true,
      selected_customer: record.customer_username
    }, () => {
      this.formRef.current.setFieldsValue({
        email: this.state.selected_customer_email,
      });
    });
  }

  closeUserSubscriptionModal = () => {
    this.formRef.current.resetFields();
    this.setState({
      show_subscription_modal: false,
      selected_customer_email: '',
    })
  }

  componentDidMount = () => {
    this.handleDataSource();
  }

  handleDataSource = () => {
    let filter_user_values = [];
    let filter_subscription_values = [];
    axios.post(ENVIRONMENT.CUSTOMER_GET_BATCH, {})
      .then(res => {
        console.log(res);
        console.log(res.data);
        var data = [];
        let user_type_value = null;
        let subscription_value = null;
        for (let i = 1; i <= (res.data).length; i++) {
          if (res.data[i - 1]['user_group'] === 'customer') {
            data.push({
              key: i,
              customer_username: res.data[i - 1]['customer_username'],
              profile_name: res.data[i - 1]['profile_name'],
              company_name: res.data[i - 1]['company_name'],
              user_type: res.data[i - 1]['user_type'],
              email: res.data[i - 1]['email'],
              payment_type: res.data[i - 1]['payment_type'],
              subscription_package: res.data[i - 1]['subscription_package']
            });
            user_type_value = res.data[i - 1]['user_type'];
            subscription_value = res.data[i - 1]['subscription_package'];
            if (user_type_value && !filter_user_values.includes(user_type_value)) {
              filter_user_values.push(user_type_value);
            }
            if (subscription_value && !filter_subscription_values.includes(subscription_value)) {
              filter_subscription_values.push(subscription_value);
            }
          }
        }
        // sort alphabetically
        data = data.sort(function(a, b) {
          return a.customer_username.toLowerCase().localeCompare(b.customer_username.toLowerCase());
        });
        this.handleFilteringOptions(filter_user_values, filter_subscription_values);
        this.setState({
          dataSource: data,
          loading: false
        })
      })
  }

  handleFilteringOptions = (filter_user_type_values, filter_subscription_values) => {
    let filter_user_type_objects = [];
    filter_user_type_values.forEach(filter_user_type_value => {
      filter_user_type_objects.push({
        text: filter_user_type_value,
        value: filter_user_type_value,
      })
    });

    let filter_subscription_objects = [];
    filter_subscription_values.forEach(filter_subscription_value => {
      filter_subscription_objects.push({
        text: filter_subscription_value,
        value: filter_subscription_value,
      })
    });

    this.setState({
      filter_user_types: filter_user_type_objects,
      filter_subscription_types: filter_subscription_objects,
    });
  }

  // Search in table functionality
  getColumnSearchProps = (dataIndex,placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleToggle = prop => enable => {
    this.setState({ [prop]: enable });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  handleTitleChange = enable => {
    this.setState({ title: enable ? 'Here is title' : undefined });
  };

  handleHeaderChange = enable => {
    this.setState({ showHeader: enable });
  };

  handleFooterChange = enable => {
    this.setState({ footer: enable ? 'Here is footer' : undefined });
  };

  handleScrollChange = enable => {
    this.setState({ scroll: enable ? { y: 240 } : undefined });
  };

  handleDataChange = hasData => {
    this.setState({ hasData });
  };

  handlePaginationChange = e => {
    const { value } = e.target;
    this.setState({
      pagination: value === 'none' ? false : { position: value },
    });
  };

  changePaymentType = (e) => {
    console.log(e.target.value)
    this.setState({
      payment_type: e.target.value
    });
  }

  handleSubscriptionTypeChange = () => {
    this.setState({
      buttonLoader: true
    });
    let payload = {}
    payload.email = this.state.email.toLowerCase();
    payload.subscription_type = this.state.subscription_type
    payload.username = this.state.selected_customer
    console.log('Payload', payload);
    axios.post(ENVIRONMENT.PERFORM_SUBSCRIPTION_ACTIVATION, payload)
    .then(res => {
        console.log(res);
        console.log(res.data);
        this.setState({
          show_subscription_modal: false,
          success_modal: true
        });
        this.setState({
          buttonLoader: false
        });
    });
  }

  handlePaymentTypeChange = () => {
    this.setState({
      button_loader: true,
    });
    const data = {
      'customer_username': this.state.selected_customer,
      'payment_type': this.state.payment_type
    }

    axios.post(ENVIRONMENT.CHANGE_PAYMENT_TYPE, data).then((res) => {
      console.log(res);
      console.log(res.data);
      this.setState({
        button_loader: false,
        change_payment_type_modal: false,
        success_payment_change: true
      });
    }).catch((err) => {
      console.log(err);
      this.setState({
        button_loader: false,
        change_payment_type_modal: false
      });
    })
  }

  render() {
    const {
      bordered,
      loading,
      pagination,
      size,
      tableLayout,
      title,
      showHeader,
      footer,
      scroll,
      hasData,
      dataSource
    } = this.state;
    const tableColumns = this.getColumns().map(item => ({ ...item, ellipsis: false }));
    if (this.state.loading) {
      return <DottedLoader />;
    }
    return (
      <div>
        <Table
          {...this.state}
          pagination={{ position: ['bottomCenter'], defaultPageSize: 50, showSizeChanger: false }}
          columns={tableColumns}
          dataSource={hasData ? dataSource : null}
          scroll={{ y: 800 }}
        />
        <Modal
          title="Change Payment Type"
          visible={this.state.change_payment_type_modal}
          onCancel={this.closePaymentTypeModal}
          footer={null}
          width={500}
        >
          <Form
            name="basic"
            onFinish={this.handlePaymentTypeChange}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="payment_type"
                >
                  <Radio.Group value={this.state.payment_type} onChange={this.changePaymentType}>
                    <Radio value="standard">Standard</Radio>
                    <Radio value="custom">Custom</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                {this.state.button_loader ?
                  <Button type="primary" htmlType="submit" loading>
                    Submit
                  </Button>
                  :
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                }
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Change User Subscription"
          visible={this.state.show_subscription_modal}
          onCancel={this.closeUserSubscriptionModal}
          footer={null}
          width={800}
          style={{ top: 200 }}
        >
          <Form className="input-form login-form" ref={this.formRef} onFinish={this.handleSubscriptionTypeChange}>
          <div style={{ maxWidth: 500 }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div className="manrope f-24 black-55" style={{marginTop: 10, marginBottom: 10}}>Email: </div>
              <span className='f-16' style={{ fontSize: '18px', marginLeft: '5px' }}>{this.state.email}</span>
            </div>
            <Form.Item rules={[{required: true, message: 'Please select a valid subscription package.'}]} name="payment_type">
                <Select showArrow disabled={this.state.buttonLoader} onSelect={e => this.selectSubscription(e)} className="manrope f-16 black-55 tree-select-material" placeholder="Subscription Package">
                    <Option value={"freemium"} className="manrope f-16 select-view">Freemium</Option>
                    <Option value={"enterprise"} className="manrope f-16 select-view">Enterprise</Option>
                </Select>
            </Form.Item>
          </div>
          <span className="justify-in-end mt-20">
              <Button disabled={this.state.buttonLoader} className="modal-okay-gray square font-14 mr-10" onClick={() => this.closeUserSubscriptionModal()}>
                  Cancel
              </Button>

              <Button htmlType="submit" disabled={this.state.buttonLoader} className="modal-okay square font-14">
                  Update&nbsp;{this.state.buttonLoader ? <LoadingOutlined /> : ''}
              </Button>
          </span>
          </Form>
        </Modal>

        <SuccessModal
          visible={this.state.success_modal}
          heading={"Account Subscription Updated!"}
          text={"Account subscription has been updated for the entered email."}
          footer={[
            <div className="justify-in-center">
                <Button style={{margin:"auto"}} className="modal-okay square font-14" htmlType="submit" onClick={() => {
                    window.location.reload();}}>
                    Okay
                </Button>
            </div>
          ]}/>
        <SuccessModal visible={this.state.success_payment_change} title="Payment Type Changed Successfully" onCancel={() => this.setState({ success_payment_change: false })} />
      </div>
    );
  }
}

export default CustomersTable;
