import React from "react";
import "@ant-design/compatible/assets/index.css";
import { Button } from "antd";
import WarningModal from "../../WarningModal/WarningModal";

const MspWarningModal = (props) => {
  const { visible, handleModalClose, isUserIncorrect = false } = props;
  const heading = isUserIncorrect
    ? "Wrong customer selected"
    : "No customer selected";
  const text = isUserIncorrect
    ? "You are about to take a system action with an incorrect customer selected. Please go back to the Home Page and select the correct customer from the dropdown at the top of the page."
    : "You are about to take a system action with no customer selected. Please go back to the Home Page and select a customer from the dropdown at the top of the page.";

  const handleHomeRedirect = () => {
    window.location.href = "/home";
  };

  return (
    <WarningModal
      visible={visible}
      onCancel={handleModalClose}
      heading={heading}
      text={text}
      footer={[
        <div key="footer" className="justify-in-end">
          <Button
            key="back"
            style={{ textAlign: "center" }}
            className="modal-okay square font-14"
            onClick={handleHomeRedirect}
          >
            Go Back to the Home Page
          </Button>
          <Button
            key="proceed"
            style={{ textAlign: "center" }}
            className="modal-okay-gray square font-14"
            onClick={handleModalClose}
          >
            Proceed Anyway
          </Button>
        </div>,
      ]}
    />
  );
};

export default MspWarningModal;
