import React from "react";
import { Tabs, Card } from "antd";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AdminMainLayout from '../AdminMainLayout';
import JobDeskTable from '../JobDeskTable';
import JobDeskTableRooms from '../JobDeskTableRooms';
import JobDeskTableMaterials from "../JobDeskTableMaterials/JobDeskTableMaterials";
import JobDeskTableScannedProducts from "../JobDeskTableScannedProducts/JobDeskTableScannedProducts";
import JobDeskTableUploadedProducts from "../JobDeskTableUploadedProducts/JobDeskTableUploadedProducts";

import {
  CognitoState,
} from 'react-cognito';

const { TabPane } = Tabs;

class AdminJobDeskContainer extends React.Component {
  state = {
      size: "small",
      current_product_tab: "1",
      selected_product_id: "-1",
      super_admin_logged_in: false
    };

  onChange = e => {
    this.setState({ size: e.target.value });
  };

  setCurrentTab = (tabId) => {
    this.setState({
      current_product_tab: tabId
    });
  }

  setSelectedID = (id) => {
    this.setState({
      selected_product_id: id
    })
  }

  componentDidMount = () => {
    // Check if the current user is super_admin
    var groups = []
    console.log('this.props.state = ')
    if (this.props.state === CognitoState.LOGGED_IN || this.props.state === CognitoState.LOGGING_IN) {
      if ('cognito:groups' in this.props.user['signInUserSession']['idToken']['payload']) {
          var groups = this.props.user['signInUserSession']['idToken']['payload']['cognito:groups'];
      }
    }

    if(groups.indexOf('SuperAdmin') !== -1){
      this.setState({
        super_admin_logged_in: true
      })
      console.log('User is super Admin')
    }
  }

  render() {
    const { size } = this.state;
    var product_active_tab = "1";
    var scanned_product_tab = "1";
    var space_active_tab = "1";
    var material_active_tab = "1";
    var main_active_tab = "1";
    var uploaded_product_tab = "1";
    var active_tab = "p1";

    var query = window.location.search.substring(1);
    console.log('in component did mount', query)

    // Select main active tab i.e product, space, material, scanned_products
    var tab = query.split('=')[1]
    if(tab && tab.includes('sp'))
      main_active_tab = "4"; // scanned_products
    else if(tab && tab.includes('up'))
      main_active_tab = "5"; // uploaded_products
    else if(tab && tab.includes('s'))
      main_active_tab = "2"; // spaces
    else if(tab && tab.includes('m'))
      main_active_tab = "3"; // material
    else if(tab && tab.includes('p'))
      main_active_tab = "1"; // products

    // Select product active tab
    if(query === 'tab=p1'){
      product_active_tab = "1";
    }
    else if(query === 'tab=p2'){
      product_active_tab = "2";
    }
    else if(query === 'tab=p3'){
      product_active_tab = "3";
    }
    else if(query === 'tab=p4'){
      product_active_tab = "4";
    }

    // Select space active tab
    if(query === 'tab=s1'){
      space_active_tab = "1";
    }
    else if(query === 'tab=s2'){
      space_active_tab = "2";
    }
    else if(query === 'tab=s3'){
      space_active_tab = "3";
    }

    // Select material active tab
    if(query === 'tab=m1'){
      material_active_tab = "1";
    }
    else if(query === 'tab=s2'){
      material_active_tab = "2";
    }
    else if(query === 'tab=s3'){
      material_active_tab = "3";
    }

    // For scanned products tab
    if(query === 'tab=sp1'){
      scanned_product_tab = "1";
    }
    else if(query === 'tab=sp2'){
      scanned_product_tab = "2";
    }
    else if(query === 'tab=sp3'){
      scanned_product_tab = "3";
    }

    // For uploaded product tab
    if(query === 'tab=up1'){
      uploaded_product_tab = "1";
    }
    const setCurrentTabURL = (tab_index) => {
      if(tab_index == 1)
        active_tab = "p" + this.state.current_product_tab;
      else if(tab_index == 2)
        active_tab = "s" + this.state.current_product_tab;
      else if(tab_index == 3)
        active_tab = "m" + this.state.current_product_tab;
      else if(tab_index == 4)
        active_tab = "sp" + this.state.current_product_tab;
      else if(tab_index == 5)
        active_tab = "up" + this.state.current_product_tab;
      
      this.props.history.push('/admin_artist_job_desk/?tab='+active_tab)
    }
    
    return (
      <AdminMainLayout selected={"7"}>
        
          <br />
          <Tabs defaultActiveKey={main_active_tab} size={size} tabBarStyle={{textAlign: 'center'}}  animated={false} onChange={(e)=>{setCurrentTabURL(e)}}>
            <TabPane tab="Products" key="1">
              <Tabs defaultActiveKey= {product_active_tab} size={size} tabBarStyle={{textAlign: 'center'}}  animated={false} onChange={(e) => this.setCurrentTab(e), (e)=>{this.props.history.push('/admin_artist_job_desk/?tab=p'+e)}} >
              {/* Key for preprocessing images tab should always be 1 */}
              <TabPane tab="Preprocessing Images" key="1">          
                  <JobDeskTable state='preprocessing_images' currentTab={parseInt(product_active_tab)} selected_id={this.state.selected_product_id} set_id={this.setSelectedID}/>
                </TabPane>
                <TabPane tab="UnAssigned" key="2">
                  <JobDeskTable state='unassigned' currentTab={parseInt(product_active_tab)} selected_id={this.state.selected_product_id} set_id={this.setSelectedID} delete_model={this.state.super_admin_logged_in}/>
                </TabPane>
                <TabPane tab="Assigned (Being Modeled)" key="3">          
                  <JobDeskTable state='assigned_not_modeled' currentTab={parseInt(product_active_tab)} selected_id={this.state.selected_product_id} set_id={this.setSelectedID}/>
                </TabPane>
                <TabPane tab="Assigned (Model Submitted)" key="4">          
                  <JobDeskTable state='assigned_modeled' currentTab={parseInt(product_active_tab)} selected_id={this.state.selected_product_id} set_id={this.setSelectedID}/>
                </TabPane>
              </Tabs>
            </TabPane>

            <TabPane tab="Spaces" key="2">
              <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false} onChange={(e) => this.setCurrentTab(e), (e)=>{this.props.history.push('/admin_artist_job_desk/?tab=s'+e)}}>
                <TabPane tab="UnAssigned" key="1">
                  <JobDeskTableRooms state='unassigned' currentTab={parseInt(space_active_tab)} delete_model={this.state.super_admin_logged_in}/>
                </TabPane>
                <TabPane tab="Assigned (Being Modeled)" key="2">          
                  <JobDeskTableRooms state='assigned_not_modeled' currentTab={parseInt(space_active_tab)}/>
                </TabPane>
                <TabPane tab="Assigned (Model Submitted)" key="3">          
                  <JobDeskTableRooms state='assigned_modeled' currentTab={parseInt(space_active_tab)}/>
                </TabPane>
              </Tabs>
            </TabPane>

            <TabPane tab="Materials" key="3">
              <Tabs defaultActiveKey="1" size={size} tabBarStyle={{textAlign: 'center'}} animated={false} onChange={(e) => this.setCurrentTab(e), (e)=>{this.props.history.push('/admin_artist_job_desk/?tab=m'+e)}}>
                <TabPane tab="UnAssigned" key="1">
                  <JobDeskTableMaterials state='unassigned' currentTab={parseInt(material_active_tab)} delete_model={this.state.super_admin_logged_in}/>
                </TabPane>
                <TabPane tab="Assigned (Being Modeled)" key="2">          
                  <JobDeskTableMaterials state='assigned_not_modeled' currentTab={parseInt(material_active_tab)}/>
                </TabPane>
                <TabPane tab="Assigned (Model Submitted)" key="3">          
                  <JobDeskTableMaterials state='assigned_modeled' currentTab={parseInt(material_active_tab)}/>
                </TabPane>
              </Tabs>
            </TabPane>

            <TabPane tab="Scanned Products" key="4">
              <Tabs defaultActiveKey={scanned_product_tab} size={size} tabBarStyle={{textAlign: 'center'}} animated={false} onChange={(e) => this.setCurrentTab(e), (e)=>{this.props.history.push('/admin_artist_job_desk/?tab=sp'+e)}} >
                <TabPane tab="UnAssigned" key="1">
                  <JobDeskTableScannedProducts state='unassigned' currentTab={parseInt(scanned_product_tab)} selected_id={this.state.selected_product_id} set_id={this.setSelectedID} delete_model={this.state.super_admin_logged_in}/>
                </TabPane>
                <TabPane tab="Assigned (Being Modeled)" key="2">          
                  <JobDeskTableScannedProducts state='assigned_not_modeled' currentTab={parseInt(scanned_product_tab)} selected_id={this.state.selected_product_id} set_id={this.setSelectedID}/>
                </TabPane>
                <TabPane tab="Assigned (Model Submitted)" key="3">          
                  <JobDeskTableScannedProducts state='assigned_modeled' currentTab={parseInt(scanned_product_tab)} selected_id={this.state.selected_product_id} set_id={this.setSelectedID}/>
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Uploaded Products" key="5">
              <Tabs defaultActiveKey={uploaded_product_tab} size={size} tabBarStyle={{textAlign: 'center'}} animated={false} onChange={(e) => this.setCurrentTab(e), (e)=>{this.props.history.push('/admin_artist_job_desk/?tab=up'+e)}} >
                <TabPane tab="Failed" key="1">
                  <JobDeskTableUploadedProducts state='failed' currentTab={parseInt(uploaded_product_tab)} selected_id={this.state.selected_product_id} set_id={this.setSelectedID}/>
                </TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
          
          
      </AdminMainLayout>
    );
  }
}

AdminJobDeskContainer.propTypes = {
  user: PropTypes.object,
  attributes: PropTypes.object,
  state: PropTypes.string,
};

const mapStateToProps = state => ({
  state: state.cognito.state,
  user: state.cognito.user,
  attributes: state.cognito.attributes,
});

const AdminJobDesk = connect(mapStateToProps, null)(AdminJobDeskContainer);

export default AdminJobDesk;