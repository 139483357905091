import React, { useContext } from 'react';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';

const CollaborateQACanvas = () => {
    const { initialLoader, access_level, selected_action } = useContext(CollaborateQAContext);

    return (
        <div id="collaborate-container" className={`collaborate-container ${(["co-owner", "owner"].includes(access_level)) ? `qa` : `qa-editor`} ${initialLoader  || access_level == "restricted" ? `blur-canvas` : ``}`}>
            <div id={`fabric-canvas`} className={`fabric-container ${selected_action}`}>
                <canvas id="canvas-editor-qa"></canvas>
            </div>
        </div>
    );
}


export default CollaborateQACanvas;
