import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Table, Card } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import useTableFilter from "../../UseTableFilter/useTableFilter";
import './ActiveSubscriptionUsers.scss';

const Actions = {
  get_subscription_user_details: "get_subscription_user_details",
  get_current_customers_count: "get_current_customers_count",
};

const PLANS_ORDER = [
  "Free Trial",
  "Basic",
  "Pro",
  "Premium",
  "Enterprise"
]

const ActiveSubscriptionUsers = () => {
  const [currentCustomersData, setCurrentCustomersData] = useState([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [isTableDataLoading, setIsTableDataLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [getColumnSearchProps] = useTableFilter();

  // ====> Subscription Users

  const getSubscriptionTableColumns = () => {
    const subscriptionTableColumns = [
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        ...getColumnSearchProps("username"),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Company",
        dataIndex: "company_name",
        key: "company_name",
        ...getColumnSearchProps("company_name"),
      },
      {
        title: "Subscription End Date",
        dataIndex: "subscription_end_date",
        key: "subscription_end_date",
      },
      {
        title: "Uploaded Models Used",
        dataIndex: "existing_product_model",
        key: "existing_product_model",
      },
      {
        title: "Product Props Used",
        dataIndex: "product_prop",
        key: "product_prop",
      },
      {
        title: "Room Props Used",
        dataIndex: "room_prop",
        key: "room_prop",
      },
      {
        title: "Scene Renders Used",
        dataIndex: "scene_lifestyle_image",
        key: "scene_lifestyle_image",
      },
    ];
    if (subscriptionType == 'Enterprise') {
      subscriptionTableColumns.splice(3, 1)
    } else {
      subscriptionTableColumns.splice(2, 1)
    }
    return subscriptionTableColumns
  }
  

  const showModal = (type) => {
    setIsModalOpen(true);
    const payload = {
      action: Actions["get_subscription_user_details"],
      subscription_plan: type,
    };
    setIsTableDataLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      console.log("Subscription User Details", res.data);
      setDataSource(res.data);
      setIsTableDataLoading(false);
    });
    setSubscriptionType(type);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSubscriptionType(null);
  };

  useEffect(() => {
    const payload = {
      action: Actions["get_current_customers_count"],
    };
    setIsLoading(true);
    axios.post(ENVIRONMENT.GET_SYSTEM_ANALYTICS, payload).then((res) => {
      console.log("Active User Subscriptions", res.data);
      const orderedData = res.data.sort((a, b) => {
        const indexA = PLANS_ORDER.indexOf(a.subscription_preset_name);
        const indexB = PLANS_ORDER.indexOf(b.subscription_preset_name);
        if (indexA < indexB) return -1;
        if (indexA > indexB) return 1;
        return 0;
      });             
      setCurrentCustomersData(orderedData);
      setIsLoading(false);
    });
  }, []);

  // Subscription Users <====

  return (
    <>
      {isLoading ? (
        <LoadingOutlined className="loading-center mt-10 mb-20" />
      ) : (
        <>
          <Row justify="start" className="mb-20 mt-10">
            {currentCustomersData.map((item) => (
              <Col span={4} className="mr-20">
                <Card
                  bordered={false}
                  onClick={() => showModal(item["subscription_preset_name"])}
                  className="subscription-cards cursor-pointer"
                >
                  <div className="justify-space-between">
                    <div className="manrope w-600 f-16">
                      {item["subscription_preset_name"]}
                    </div>
                    <div className="manrope f-14">{item["total_users"]}</div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}

      {/* Subscription User Details Modal */}
      <Modal
        title={subscriptionType + ` Subscription User Details`}
        maskClosable={false}
        onCancel={handleCancel}
        visible={isModalOpen}
        width={"86%"}
        centered={true}
        style={{ paddingTop: 20, minWidth: "80%" }}
        footer={null}
      >
        {isTableDataLoading ? (
          <LoadingOutlined className="loading-center" />
        ) : (
          <Table
            dataSource={dataSource}
            columns={getSubscriptionTableColumns()}
            scroll
            style={{ overflowX: "scroll" }}
          />
        )}
      </Modal>
    </>
  );
};

export default ActiveSubscriptionUsers;
