import React from 'react';
import ReactImageZoom from 'react-image-zoom';
 
const props = {};
class ImageZoom extends React.Component{
    state = {
        image: this.props.image,
        height: window.innerHeight-202,
        width: ""
    }
    componentDidMount(){
        console.log(this.props);
        this.setState({
            image: this.props.image,
            height: window.innerHeight-202,
        });
        window.addEventListener("resize", () => {
            this.setState({
                height: window.innerHeight-202,
            })
        });
        console.log(navigator.userAgent)
        navigator.userAgent.includes('Macintosh') ? this.setState({
            width: window.innerHeight-202
        }) : this.setState({
            width: ""
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {
            this.setState({
                height: window.innerHeight-202,
            })
        });
    }

    render() {
        return (
            <span style={{width:"auto",height: "calc(100vh - 202px)",objectFit:"scale-down"}} id="zoom-image"
            >
                {(this.props.image != undefined) ?
                <ReactImageZoom
                    img={this.props.image}
                    imageStyle={{objectFit:"scale-down"}}
                    width={this.state.width}
                    style={{width:"auto",objectFit:"scale-down"}}
                    height={window.innerHeight-202}
                    zoomPosition="original"
                />
                :
                ""
                }
            </span>
        );
    }
}

export default ImageZoom;